/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@mantine/carousel';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import AppCarousel from '../../../../common/AppCarousel';
import AppStack from '../../../../common/AppStack';
import AppMarquee from '../../../../common/AppMarquee';
import { ANNOUNCEMENT_DISPLAY_TYPE_ENUM } from './wsAnnouncementBarConfig';
import WsSectionContainer from '../WsSectionContainer';
import WsBlock from '../../blocks/WsBlock';

const WsAnnouncementBar = ({
  baseStoreUrl,
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  sort,
  onNavigate,
  ecomStoreTheme,
  ...rest
}) => {
  const pauseOnHover = config.pauseOnHover === 'true';
  const autoRotate = config.autoRotate === 'true';
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      isFirstComponent={sort === 1}
      mediaQueries={mediaQueries}
      noPadding
      themeConfig={themeConfig}
      {...rest}
    >
      {config.displayType === ANNOUNCEMENT_DISPLAY_TYPE_ENUM.SCROLLING ? (
        <AppMarquee
          pauseOnHover={config.pauseOnHover === 'true'}
          reverse={config.reverseScroll === 'true'}
          scrollSpeed={config.scrollSpeed}
          sections={
            blocks?.map((b) => (
              <WsBlock
                baseStoreUrl={baseStoreUrl}
                colorScheme={colorScheme}
                ecomStoreTheme={ecomStoreTheme}
                onNavigate={onNavigate}
                {...b}
              />
            )) ?? []
          }
        />
      ) : blocks.length === 1 ? (
        <AppStack
          style={{
            flex: 1,
            width: '100%',
            minHeight: 25,
            overflow: 'hidden',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: 14
          }}
        >
          <WsBlock
            baseStoreUrl={baseStoreUrl}
            colorScheme={colorScheme}
            ecomStoreTheme={ecomStoreTheme}
            onNavigate={onNavigate}
            {...blocks[0]}
          />
        </AppStack>
      ) : (
        <AppCarousel
          autoRotate={autoRotate}
          changeEvery={config.changeEvery}
          nextControlIcon={
            <ChevronRight color={colorScheme.textColor} size={20} />
          }
          pauseOnHover={pauseOnHover}
          previousControlIcon={
            <ChevronLeft color={colorScheme.textColor} size={20} />
          }
          slides={
            blocks?.map((b, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Carousel.Slide key={i}>
                <AppStack
                  style={{
                    flex: 1,
                    width: '100%',
                    minHeight: 25,
                    overflow: 'hidden',
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontSize: 14,
                    padding: '0px 25px'
                  }}
                >
                  <WsBlock
                    baseStoreUrl={baseStoreUrl}
                    colorScheme={colorScheme}
                    ecomStoreTheme={ecomStoreTheme}
                    onNavigate={onNavigate}
                    {...b}
                  />
                </AppStack>
              </Carousel.Slide>
            )) ?? []
          }
          withControls={blocks.length > 1}
        />
      )}
    </WsSectionContainer>
  );
};

WsAnnouncementBar.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsAnnouncementBar;
