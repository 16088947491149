import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Checkbox,
  Skeleton,
  Tabs,
  TextInput,
  Tooltip
} from '@mantine/core';
import { ExternalLink, InfoCircle } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import PaginationList from '../../common/PaginationList';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const StoreFundraisingRosterPlayerModal = ({
  isOpen,
  onClose,
  ecomStoreRosterPlayer,
  onSuccessCallback
}) => {
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchStoreTeamUsers,
    createStoreRosterPlayers,
    updateStoreRosterPlayers
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    name: '',
    loading: false,
    activeTab: 'team',
    teamSearch: '',
    selectedTeamUsers: [],
    pageIndex: 1
  });
  const selectedStore = authState.ecomStores.find(
    (s) => s.pkEcomStore.toString() === authState.pkEcomStore.toString()
  );

  const filteredTeamUsers = state.ecomStoreTeamUsers.value.filter(
    (tu) =>
      !formState.teamSearch ||
      tu.name.toLowerCase().includes(formState.teamSearch.toLowerCase())
  );

  const availableTeamUsers = filteredTeamUsers.filter(
    (tu) =>
      !state.ecomStoreRosterPlayers.value.some(
        (s) => s.teamUser?.pkTeamUser !== tu.pkTeamUser
      )
  );
  const loadingTeamUsers =
    !hasFetched.current || state.ecomStoreTeamUsers.loading;

  const allFilteredTeamUsersSelected = availableTeamUsers.every((tu) =>
    formState.selectedTeamUsers.some((stu) => stu.pkTeamUser === tu.pkTeamUser)
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        name: ecomStoreRosterPlayer?.name || '',
        fkTeamUser: ecomStoreRosterPlayer?.fkTeamUser || null,
        loading: false,
        activeTab: 'team',
        teamSearch: '',
        selectedTeamUsers: [],
        pageIndex: 1
      });

      if (!hasFetched.current && !!selectedStore.fkTeam) {
        fetchStoreTeamUsers(authState.pkEcomStore);
        hasFetched.current = true;
      }
    }
  }, [isOpen]);

  const onSuccess = () => {
    triggerNotification(
      ecomStoreRosterPlayer ? 'Player updated!' : 'Player Created',
      'success'
    );
    onClose();
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const onError = (error) => {
    setFormState({
      ...formState,
      loading: false
    });
    triggerNotification(error);
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        padding: 0,
        cancelTitle: 'Cancel',
        onCancel: onClose,
        isLoading: formState.loading,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          if (ecomStoreRosterPlayer) {
            updateStoreRosterPlayers(
              authState.pkEcomStore,
              {
                ecomStoreRosterPlayers: [
                  {
                    pkEcomStoreRosterPlayer:
                      ecomStoreRosterPlayer.pkEcomStoreRosterPlayer,
                    name: formState.name,
                    fkTeamUser: ecomStoreRosterPlayer.fkTeamUser
                  }
                ]
              },
              onSuccess,
              onError
            );
          }
          else {
            createStoreRosterPlayers(
              authState.pkEcomStore,
              {
                ecomStoreRosterPlayers:
                  formState.activeTab === 'custom'
                    ? [{ name: formState.name }]
                    : formState.selectedTeamUsers.map((tu) => ({
                        name: tu.name,
                        fkTeamUser: tu.pkTeamUser
                      })) || []
              },
              onSuccess,
              onError
            );
          }
        },
        submitTitle: ecomStoreRosterPlayer
          ? 'Save changes'
          : formState.activeTab === 'custom'
          ? 'Create player'
          : 'Import players'
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={550}
      title={
        ecomStoreRosterPlayer
          ? 'Update roster player'
          : formState.activeTab === 'custom'
          ? 'Create roster player'
          : 'Import roster players'
      }
    >
      <AppStack style={{ gap: 0, paddingTop: ecomStoreRosterPlayer ? 0 : 16 }}>
        {!ecomStoreRosterPlayer && (
          <Tabs
            onChange={(v) =>
              setFormState({
                ...formState,
                activeTab: v
              })
            }
            value={formState.activeTab}
            variant="outline"
          >
            <Tabs.List>
              <Tabs.Tab value="team">Your team</Tabs.Tab>
              <Tabs.Tab value="custom">Custom Player</Tabs.Tab>
            </Tabs.List>
          </Tabs>
        )}

        <AppStack style={{ gap: 16, padding: 16 }}>
          {ecomStoreRosterPlayer || formState.activeTab === 'custom' ? (
            <TextInput
              description="This name will be visible to the public when browsing your store."
              disabled={formState.loading}
              label="Display name"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.currentTarget.value
                })
              }
              required
              value={formState.name}
            />
          ) : !selectedStore?.fkTeam ? (
            <Alert
              color="dodgerblue"
              icon={<InfoCircle size={20} />}
              title="No team connected."
              variant="outline"
            >
              <AppStack style={{ gap: 12 }}>
                <AppText style={{ fontSize: 14 }}>
                  You need to connect a team to your store to import team
                  members. You can do this in the settings page.
                </AppText>
                <AppFlexbox>
                  <Button
                    color="blue"
                    component={Link}
                    leftSection={<ExternalLink size={18} />}
                    to="/merchant/settings?modal=team"
                    variant="outline"
                  >
                    Connect team
                  </Button>
                </AppFlexbox>
              </AppStack>
            </Alert>
          ) : (
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Team members
                </AppText>
                <Checkbox
                  checked={
                    availableTeamUsers.length > 0 &&
                    allFilteredTeamUsersSelected
                  }
                  disabled={
                    formState.loading ||
                    loadingTeamUsers ||
                    availableTeamUsers.length === 0
                  }
                  label={
                    allFilteredTeamUsersSelected ? 'Unselect all' : 'Select all'
                  }
                  onChange={() =>
                    setFormState({
                      ...formState,
                      selectedTeamUsers: allFilteredTeamUsersSelected
                        ? formState.selectedTeamUsers.filter(
                            (stu) =>
                              !availableTeamUsers.some(
                                (tu) => tu.pkTeamUser === stu.pkTeamUser
                              )
                          )
                        : [...availableTeamUsers]
                    })
                  }
                />
              </AppFlexbox>
              <TextInput
                disabled={formState.loading || loadingTeamUsers}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    teamSearch: e.currentTarget.value
                  });
                }}
                placeholder="Search for team members"
                value={formState.teamSearch}
              />
              {loadingTeamUsers ? (
                <PaginationList
                  emptyMessage="No team members found."
                  items={Array.from(Array(5)).map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <AppFlexbox key={i} style={{ padding: 10 }}>
                      <Skeleton height={12} width="40%" />
                    </AppFlexbox>
                  ))}
                />
              ) : (
                <PaginationList
                  emptyMessage="No team members found."
                  items={filteredTeamUsers.map((p) => {
                    const selected = formState.selectedTeamUsers.some(
                      (tu) => tu.pkTeamUser === p.pkTeamUser
                    );
                    const alreadyImported = state.ecomStoreRosterPlayers.value.some(
                      (s) => s.teamUser?.pkTeamUser === p.pkTeamUser
                    );

                    return (
                      <Tooltip
                        key={p.pkTeamUser}
                        disabled={!alreadyImported}
                        label="Already imported"
                        withArrow
                      >
                        <AppFlexbox
                          onClick={() => {
                            if (!alreadyImported) {
                              setFormState({
                                ...formState,
                                selectedTeamUsers: selected
                                  ? [
                                      ...formState.selectedTeamUsers.filter(
                                        (tu) => tu.pkTeamUser !== p.pkTeamUser
                                      )
                                    ]
                                  : [...formState.selectedTeamUsers, p]
                              });
                            }
                          }}
                          style={{
                            gap: 8,
                            padding: 10,
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <Checkbox
                            checked={alreadyImported || selected}
                            disabled={alreadyImported || formState.loading}
                            onChange={() => {}}
                          />
                          <AppText
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              color: alreadyImported ? '#666' : '#000'
                            }}
                          >
                            {p.name}
                          </AppText>
                        </AppFlexbox>
                      </Tooltip>
                    );
                  })}
                  itemsPerPage={10}
                  onPageChange={(pageIndex) =>
                    setFormState({
                      ...formState,
                      pageIndex
                    })
                  }
                  pageIndex={formState.pageIndex}
                />
              )}
            </AppStack>
          )}
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

StoreFundraisingRosterPlayerModal.propTypes = {
  ecomStoreRosterPlayer: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccessCallback: PropTypes.func
};

export default StoreFundraisingRosterPlayerModal;
