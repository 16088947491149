import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import AdminVendorDetailsView from './AdminVendorDetailsView';
import AdminVendorPayoutView from './AdminVendorPayoutView';
import { triggerNotification } from '../../../helpers/notification';

const AdminVendorView = () => {
  const fetchedPkEcomVendor = useRef(null);
  const { pkEcomVendor } = useParams();
  const { state, fetchEcomVendor } = useContext(AdminContext);
  const ecomVendorDetails = state.ecomVendor.value;
  const loading =
    !ecomVendorDetails ||
    state.ecomVendor.loading ||
    ecomVendorDetails.ecomVendor.pkEcomVendor.toString() !==
      fetchedPkEcomVendor.current;

  useEffect(() => {
    if (pkEcomVendor && pkEcomVendor !== fetchedPkEcomVendor.current) {
      fetchedPkEcomVendor.current = pkEcomVendor;
      fetchEcomVendor(pkEcomVendor, null, (e) => triggerNotification(e));
    }
  }, [pkEcomVendor]);

  return (
    <Routes>
      <Route
        element={<AdminVendorPayoutView isCreate loading={loading} />}
        path="/payouts/create"
      />
      <Route
        element={<AdminVendorPayoutView loading={loading} />}
        path="/payouts/:pkEcomVendorPayout"
      />
      <Route element={<AdminVendorDetailsView loading={loading} />} path="/*" />
      <Route
        element={<Navigate replace to={`/admin/vendors/${pkEcomVendor}`} />}
        path="*"
      />
    </Routes>
  );
};

export default AdminVendorView;
