import { Message } from 'tabler-icons-react';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import WsEmailFormBlock from './WsEmailFormBlock';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  title: 'Email form',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.EMAIL_FORM,
  icon: Message,
  Component: WsEmailFormBlock,
  configOptions,
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
