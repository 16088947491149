/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, Grid } from '@mantine/core';
import AppStack from '../../../../common/AppStack';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppText from '../../../../common/AppText';
import WsSectionContainer from '../WsSectionContainer';
import WsBlock from '../../blocks/WsBlock';

const WsFooter = ({
  config,
  colorSchemes,
  themeConfig,
  mediaQueries,
  ecomStoreTheme,
  blocks,
  baseStoreUrl,
  onNavigate,
  ecomStoreMenus,
  ...rest
}) => {
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const showPivacyPolicy = config.showPrivacyPolicy === 'true';
  const showSupportEmail = config.showSupportEmail === 'true';
  const centerBlocks = blocks.length === 1;

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack
        style={{
          flex: 1,
          justifyContent: 'space-between',
          margin: 'auto',
          width: '100%'
        }}
      >
        <Grid grow={isTabletOrSmaller || blocks.length < 6}>
          {blocks.map((b) => (
            <Grid.Col
              key={b.key}
              span={
                isLargeMobileOrSmaller
                  ? 12
                  : isTabletOrSmaller
                  ? 6
                  : blocks.length < 6 && blocks.length !== 4
                  ? 4
                  : 3
              }
            >
              <AppFlexbox
                style={{
                  justifyContent: centerBlocks ? 'center' : 'start',
                  textAlign: centerBlocks ? 'center' : 'start'
                }}
              >
                <WsBlock
                  baseStoreUrl={baseStoreUrl}
                  colorScheme={colorScheme}
                  ecomStoreMenus={ecomStoreMenus}
                  ecomStoreTheme={ecomStoreTheme}
                  mediaQueries={mediaQueries}
                  onNavigate={onNavigate}
                  themeConfig={themeConfig}
                  {...b}
                />
              </AppFlexbox>
            </Grid.Col>
          ))}
        </Grid>

        <AppFlexbox
          style={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: isLargeMobileOrSmaller ? 'start' : 'end',
            flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
          }}
        >
          <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
            <AppText
              style={{
                color: colorScheme.textColor,
                fontSize: 12
              }}
            >
              © Powered by{' '}
              <Anchor
                href="https://www.sportsheadz.com/"
                style={{ color: colorScheme.textColor, fontSize: 12 }}
                target="_blank"
              >
                SportsHeadz
              </Anchor>
              {showPivacyPolicy && (
                <>
                  {' '}
                  ·{' '}
                  <Anchor
                    href="https://www.sportsheadz.com/privacy-policy"
                    style={{ color: colorScheme.textColor, fontSize: 12 }}
                    target="_blank"
                  >
                    Privacy Policy
                  </Anchor>
                </>
              )}
            </AppText>
          </AppFlexbox>
          {showSupportEmail && (
            <AppFlexbox>
              <AppStack style={{ gap: 0 }}>
                <AppText
                  style={{
                    color: colorScheme.textColor,
                    fontSize: 12,
                    fontWeight: 500
                  }}
                >
                  Need help? Contact us at{' '}
                </AppText>
                <Anchor
                  href={`mailto:${ecomStoreTheme.ecomStore.supportEmail}`}
                  style={{ color: colorScheme.textColor, fontSize: 12 }}
                >
                  {ecomStoreTheme.ecomStore.supportEmail ??
                    'support@sportsheadz.com'}
                </Anchor>
              </AppStack>
            </AppFlexbox>
          )}
        </AppFlexbox>
      </AppStack>
    </WsSectionContainer>
  );
};

WsFooter.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreMenus: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsFooter;
