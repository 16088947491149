import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Loader,
  Menu,
  Overlay,
  ScrollArea,
  Skeleton
} from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AnalyticsLineChart from './AnalyticsLineChart';
import AnalyticsBarChart from './AnalyticsBarChart';
import AppCard from '../../common/AppCard';
import * as classes from '../../../styles/nestedStyles.module.css';
import AppMenu from '../../common/AppMenu';

const AnalyticsChartBase = ({
  mainDataLabel,
  compareDataLabel,
  chart,
  chartData,
  redirectPath,
  chartableHeaders,
  selectedChartField,
  onChangeChartHeader,
  loading,
  loadingVariant
}) => {
  const totalDataAmounts = chartData?.reduce(
    (r, c) => ({
      total: r.total + (c[mainDataLabel] || 0),
      compareTotal: r.compareTotal + (c[compareDataLabel] || 0)
    }),
    {
      total: 0,
      compareTotal: 0
    }
  ) ?? { total: 0, compareTotal: 0 };
  const { total, compareTotal } =
    !loading && chart?.getOverviewData
      ? chart.getOverviewData(chartData, mainDataLabel, compareDataLabel)
      : {};

  const selectedChartHeader =
    selectedChartField && chartableHeaders
      ? chartableHeaders.find((h) => h.value === selectedChartField)
      : null;

  return loadingVariant || (!loading && chartData) ? (
    <AppCard
      radius="md"
      shadow="lg"
      style={{ minHeight: '100%', padding: 0 }}
      withBorder
    >
      {loading && (
        <Overlay backgroundOpacity={0.3} color="#000">
          <AppStack
            style={{
              width: '100%',
              height: '100%',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Loader color="white" size={32} />
          </AppStack>
        </Overlay>
      )}

      <AppStack style={{ flex: 1, gap: 0 }}>
        {chartableHeaders && onChangeChartHeader ? (
          <AppMenu
            closeOnClickOutside
            closeOnItemClick
            control={
              <AppFlexbox
                className={classes['hover-action-card']}
                component={redirectPath ? Link : null}
                style={{
                  padding: 8,
                  cursor: 'pointer',
                  color: '#000',
                  textDecoration: 'none'
                }}
                to={redirectPath}
              >
                <AppText
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: '22px',
                    borderBottom: 'dotted 2px #B1B1B1'
                  }}
                >
                  {selectedChartHeader?.label ?? chart.title}
                </AppText>
              </AppFlexbox>
            }
            offset={0}
            position="bottom-start"
            radius={0}
            shadow="xl"
            styles={{ dropdown: { padding: 0 } }}
            width="target"
          >
            <ScrollArea.Autosize
              style={{ width: '100%', height: '100%', maxHeight: 300 }}
            >
              <AppStack style={{ gap: 0, flex: 1 }}>
                {chartableHeaders.map((h) => (
                  <Menu.Item
                    key={h.value}
                    onClick={(e) => {
                      e.preventDefault();
                      onChangeChartHeader(h);
                    }}
                  >
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        gap: 12,
                        padding: '5px 0px'
                      }}
                    >
                      <Checkbox
                        checked={selectedChartField === h.value}
                        onChange={() => {}}
                      />
                      <AppText style={{ fontSize: 14 }}>{h.label}</AppText>
                    </AppFlexbox>
                  </Menu.Item>
                ))}
              </AppStack>
            </ScrollArea.Autosize>
          </AppMenu>
        ) : (
          <AppFlexbox
            className={classes['hover-action-card']}
            component={redirectPath ? Link : null}
            style={{
              padding: 8,
              cursor: 'pointer',
              color: '#000',
              textDecoration: 'none'
            }}
            to={redirectPath}
          >
            <AppText
              style={{
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '22px',
                borderBottom: 'dotted 2px #B1B1B1'
              }}
            >
              {chart.title}
            </AppText>
          </AppFlexbox>
        )}

        {!chart.noOverview && (
          <AppFlexbox
            style={{
              gap: 5,
              alignItems: 'center',
              textAlign: 'center',
              padding: 8
            }}
          >
            <AppText
              style={{
                fontSize: 24,
                fontWeight: 500,
                lineHeight: '22px'
              }}
            >
              {total ||
                (chart.valueFormatter
                  ? chart.valueFormatter(totalDataAmounts.total)
                  : totalDataAmounts.total)}
            </AppText>
            {compareDataLabel && (
              <AppText
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  lineHeight: '22px',
                  color: '#B1B1B1'
                }}
              >
                {' - '}
                {compareTotal ||
                  (chart.valueFormatter
                    ? chart.valueFormatter(totalDataAmounts.compareTotal)
                    : totalDataAmounts.compareTotal)}
              </AppText>
            )}
          </AppFlexbox>
        )}

        <AppFlexbox style={{ padding: 8, marginTop: 'auto' }}>
          {chart.type === 'line' ? (
            <AnalyticsLineChart
              chartData={chartData}
              compareDataLabel={compareDataLabel}
              dataKey={chart.dataKey}
              mainDataLabel={mainDataLabel}
              valueFormatter={chart.valueFormatter}
            />
          ) : (
            chart.type === 'bar' && (
              <AnalyticsBarChart
                chartData={chartData}
                compareDataLabel={compareDataLabel}
                dataKey={chart.dataKey}
                mainDataLabel={mainDataLabel}
                valueFormatter={chart.valueFormatter}
              />
            )
          )}
        </AppFlexbox>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard style={{ minHeight: '100%' }}>
      <AppStack style={{ flex: 1, gap: 8 }}>
        <AppFlexbox style={{}}>
          <Skeleton height={24} width="20%" />
        </AppFlexbox>
        <AppFlexbox style={{}}>
          <Skeleton height={30} width="30%" />
        </AppFlexbox>
        <AppFlexbox style={{}}>
          <Skeleton height={250} width="100%" />
        </AppFlexbox>
      </AppStack>
    </AppCard>
  );
};

AnalyticsChartBase.propTypes = {
  chart: PropTypes.object,
  chartData: PropTypes.array,
  chartableHeaders: PropTypes.array,
  compareDataLabel: PropTypes.string,
  loading: PropTypes.bool,
  loadingVariant: PropTypes.string,
  mainDataLabel: PropTypes.string,
  onChangeChartHeader: PropTypes.func,
  redirectPath: PropTypes.string,
  selectedChartField: PropTypes.string
};

export default AnalyticsChartBase;
