import React, { useState } from 'react';
import { GripVertical, Plus } from 'tabler-icons-react';
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Divider,
  NumberFormatter
} from '@mantine/core';
import PropTypes from 'prop-types';
import EditProductCard from './EditProductCard';
import EditProductInputDrawer from './EditProductInputDrawer';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import SortableList from '../../common/SortableList';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';

const ManageProductInputs = ({
  disabled,
  ecomVendorProductVariants,
  ecomVendorProductInputs,
  onAddProductInput,
  onRemoveProductInput,
  onUpdateProductInput,
  onSortProductInputs
}) => {
  const [formState, setFormState] = useState({
    showDrawer: false,
    ecomVendorProductInput: null
  });

  return (
    <>
      <EditProductCard contentProps={{ style: { padding: 0 } }} title="Inputs">
        {ecomVendorProductInputs.length > 0 ? (
          <AppCard
            radius="md"
            shadow="none"
            style={{ margin: 16, marginTop: 0, padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <SortableList
                items={ecomVendorProductInputs}
                onChange={onSortProductInputs}
                renderItem={(item, sortableProps) => (
                  <React.Fragment key={item.key}>
                    <AppFlexbox
                      ref={sortableProps?.setNodeRef}
                      style={{
                        ...sortableProps?.style,
                        flex: 1,
                        padding: '16px 8px',
                        outline: sortableProps?.isOverlay
                          ? 'solid 1px #dee2e6'
                          : 'none',
                        backgroundColor: sortableProps?.isDragging
                          ? 'rgba(56, 56, 56, 0.1)'
                          : '#FFF',
                        opacity: sortableProps?.isDragging ? 0.4 : 1,
                        cursor: sortableProps?.isDragging
                          ? 'grabbing'
                          : 'default',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <AppFlexbox>
                        <AppFlexbox style={{ width: 30 }}>
                          <ActionIcon
                            color="dark"
                            size="sm"
                            style={{
                              height: '100%',
                              borderRadius: 8,
                              width: 20,
                              border: 'none',
                              cursor: sortableProps?.isOverlay
                                ? 'grabbing'
                                : 'pointer'
                            }}
                            variant="subtle"
                            {...sortableProps?.attributes}
                            {...sortableProps?.listeners}
                          >
                            <GripVertical size={18} />
                          </ActionIcon>
                        </AppFlexbox>
                        <AppStack style={{ gap: 5 }}>
                          <AppFlexbox style={{ gap: 5 }}>
                            <AppText style={{ fontWeight: 500, fontSize: 14 }}>
                              {item.label}{' '}
                              {item.adjustedCost && item.adjustedCost > 0 && (
                                <NumberFormatter
                                  decimalScale={2}
                                  fixedDecimalScale
                                  prefix="+$"
                                  style={{ fontSize: 12, whiteSpace: 'nowrap' }}
                                  thousandSeparator
                                  value={item.adjustedCost / 100}
                                />
                              )}
                            </AppText>
                            {item.isRequired && (
                              <AppText
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: 'red'
                                }}
                              >
                                *
                              </AppText>
                            )}
                          </AppFlexbox>

                          <AppFlexbox style={{ gap: 5 }}>
                            {item.ecomVendorProductInputOptions.length > 0 &&
                              item.ecomVendorProductInputOptions.map(
                                (option) => (
                                  <Badge
                                    key={option.key}
                                    color="dark"
                                    size="sm"
                                    variant="light"
                                  >
                                    {option.value}{' '}
                                    {option.adjustedCost > 0 && (
                                      <NumberFormatter
                                        decimalScale={2}
                                        fixedDecimalScale
                                        prefix="+$"
                                        thousandSeparator
                                        value={option.adjustedCost / 100}
                                      />
                                    )}
                                  </Badge>
                                )
                              )}
                          </AppFlexbox>
                        </AppStack>
                      </AppFlexbox>
                      <AppFlexbox>
                        <Button
                          color="red"
                          disabled={disabled}
                          onClick={() => onRemoveProductInput(item)}
                          size="compact-sm"
                          type="button"
                          variant="outline"
                        >
                          Remove
                        </Button>
                        <Button
                          color="dark"
                          disabled={disabled}
                          onClick={() =>
                            setFormState({
                              ...formState,
                              showDrawer: true,
                              ecomVendorProductInput: item
                            })
                          }
                          size="compact-sm"
                          type="button"
                          variant="outline"
                        >
                          Edit
                        </Button>
                      </AppFlexbox>
                    </AppFlexbox>
                    <Divider />
                  </React.Fragment>
                )}
              />
              <AppFlexbox
                style={{ alignItems: 'center', gap: 5, padding: '16px 8px' }}
              >
                <Plus color="dodgerblue" size={18} />
                <Anchor
                  onClick={() =>
                    setFormState({
                      ...formState,
                      showDrawer: true,
                      ecomVendorProductInput: null
                    })
                  }
                  style={{ color: 'dodgerblue', fontSize: 14 }}
                >
                  Add another input
                </Anchor>
              </AppFlexbox>
            </AppStack>
          </AppCard>
        ) : (
          <AppFlexbox style={{ gap: 5, padding: 16, paddingTop: 8 }}>
            <Plus color="dodgerblue" size={18} style={{ marginTop: 2 }} />
            <Anchor
              onClick={() =>
                setFormState({
                  ...formState,
                  showDrawer: true,
                  ecomVendorProductInput: null
                })
              }
              style={{ color: 'dodgerblue', fontSize: 14 }}
            >
              Add custom inputs such as jersey number, name, etc.
            </Anchor>
          </AppFlexbox>
        )}
      </EditProductCard>

      <EditProductInputDrawer
        ecomVendorProductInput={formState.ecomVendorProductInput}
        ecomVendorProductVariants={ecomVendorProductVariants}
        isOpen={formState.showDrawer}
        onClose={() => {
          setFormState({ ...formState, showDrawer: false });
        }}
        onConfirm={(inputData) => {
          if (formState.ecomVendorProductInput) {
            onUpdateProductInput(inputData);
          }
          else {
            onAddProductInput(inputData);
          }
          setFormState({ ...formState, showDrawer: false });
        }}
        productPrice={formState.price}
      />
    </>
  );
};

ManageProductInputs.propTypes = {
  disabled: PropTypes.bool,
  ecomVendorProductInputs: PropTypes.array,
  ecomVendorProductVariants: PropTypes.array,
  hasUnsavedChanges: PropTypes.bool,
  onAddProductInput: PropTypes.func,
  onRemoveProductInput: PropTypes.func,
  onSortProductInputs: PropTypes.func,
  onUpdateProductInput: PropTypes.func
};

export default ManageProductInputs;
