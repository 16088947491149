import React, { useContext, useEffect, useRef } from 'react';
import { Receipt } from 'tabler-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import TableView from '../../common/TableView';
import { usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';

const TABLE_COLUMNS = [
  {
    label: 'Order ID',
    value: 'orderId',
    sortable: true
  },
  {
    label: 'Date',
    value: 'date',
    sortable: true
  },
  {
    label: 'Merchant',
    value: 'store',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Total',
    value: 'total',
    sortable: true
  }
];

const VendorOrdersBulkTable = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state: authState } = useContext(AuthContext);
  const { state } = useContext(VendorContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: orderData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      status: ''
    },
    (newFilter) => {},
    state.paginatedEcomVendorOrders
  );
  const loading = !hasFetched.current || dataLoading;

  useEffect(() => {
    if (authState.pkEcomVendor) {
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Bulk orders',
          to: '/vendor/orders/bulk'
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled
        disabledContent={{
          title: 'No bulk orders found.',
          description:
            'Bulk shipment Orders will appear here once customers begin to place orders.',
          icon: <Receipt color="#000" size={125} />
        }}
        emptyMessage="No bulk orders found."
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (search) =>
              onFilterChange({
                ...filter,
                search
              })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={[].map((a) => {
          const orderTotal = a.ecomStoreCartProducts.reduce(
            (acc, item) =>
              acc + (item.productCost + item.addonCost) * item.count,
            0
          );

          return {
            key: a.pkEcomStoreCart,
            columns: [
              {
                key: 1,
                label: `#${a.pkEcomStoreCart}`,
                weight: 500,
                component: Link,
                to: `/vendor/orders/${a.pkEcomStoreCart}`,
                onClick: () => navigate(`/vendor/orders/${a.pkEcomStoreCart}`)
              },
              {
                key: 2,
                label: `${dayjs(formatUtcDate(a.purchaseDate)).format(
                  'MMM D, YYYY'
                )} ${dayjs(formatUtcDate(a.purchaseDate)).format('h:mm A')}`
              },
              {
                key: 3,
                label: 'Unfulfilled',
                color: 'orange'
              },
              {
                key: 5,
                label: a.ecomStore.name,
                component: Link,
                to: `/vendor/merchants/${a.ecomStore.pkEcomStore}`,
                onClick: () =>
                  navigate(`/vendor/merchants/${a.ecomStore.pkEcomStore}`)
              },
              {
                key: 6,
                label: currencyFormat(orderTotal / 100)
              }
            ]
          };
        })}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Orders"
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </HeaderView>
  );
};

export default VendorOrdersBulkTable;
