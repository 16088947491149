import React from 'react';
import {
  BrandFacebook,
  BrandInstagram,
  BrandLinkedin,
  BrandTwitter
} from 'tabler-icons-react';
import { Image } from '@mantine/core';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppStack from '../../common/AppStack';
import sellWithUsLight from '../../../images/sportsheadz-shop-greyscale.png';

const Footer = () => {
  const { getResponsiveStyle: rs } = useMediaQueryIndex();

  return (
    <AppStack
      style={{
        position: 'static',
        maxWidth: '100%',
        gap: rs([40, 60]),
        padding: 60,
        color: '#FFF',
        backgroundColor: '#000'
      }}
    >
      <AppFlexbox
        style={{
          flexDirection: rs(['column', 'column', 'row']),
          width: rs(['100%', '100%', 'unset'])
        }}
      >
        <AppFlexbox
          style={{
            flex: 2,
            gap: 20,
            alignItems: 'start',
            padding: 0,
            maxWidth: 800,
            flexDirection: rs(['column', 'row']),
            alignContent: 'start',
            textWrap: 'nowrap'
          }}
        >
          <AppFlexbox
            style={{
              flex: 1,
              marginTop: rs([20, 0])
            }}
          >
            <AppFlexbox style={{ flexDirection: 'column' }}>
              <AppText weight={500}>Help</AppText>
              <AppText
                component="a"
                href="https://www.sportsheadz.com/contact"
                target="_blank"
              >
                Contact Support
              </AppText>
            </AppFlexbox>
          </AppFlexbox>

          <AppFlexbox style={{ flex: 1 }}>
            <AppFlexbox
              style={{
                flexDirection: 'column',
                marginTop: rs([20, 0])
              }}
            >
              <AppText weight={500}>About</AppText>
              <AppText
                component="a"
                href="https://www.sportsheadz.com/about-us"
                target="_blank"
              >
                Our Story
              </AppText>
            </AppFlexbox>
          </AppFlexbox>

          <AppFlexbox style={{ flex: 1 }}>
            <AppFlexbox
              style={{
                flexDirection: 'column',
                marginTop: rs([20, 0])
              }}
            >
              <AppText weight={500}>Legal</AppText>
              <AppText
                component="a"
                href="https://www.sportsheadz.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </AppText>
            </AppFlexbox>
          </AppFlexbox>
        </AppFlexbox>
        <AppFlexbox
          style={{
            flex: 1,
            justifyContent: rs(['start', 'start', 'flex-end']),
            marginTop: rs([20, 20, 0])
          }}
        >
          <AppFlexbox style={{ flexDirection: 'column' }}>
            <AppText weight={500}>Follow SportsHeadz</AppText>
            <AppFlexbox>
              <ActionableIcon
                component="a"
                href="https://www.facebook.com/SportsHeadzCanada/"
                size={35}
                style={{
                  borderRadius: 50,
                  color: '#000'
                }}
                target="_blank"
                variant="white"
              >
                <BrandFacebook size={25} />
              </ActionableIcon>

              <ActionableIcon
                component="a"
                href="https://www.instagram.com/sportsheadzcanada/"
                size={35}
                style={{
                  borderRadius: 50,
                  color: '#000'
                }}
                target="_blank"
                variant="white"
              >
                <BrandInstagram size={24} />
              </ActionableIcon>

              <ActionableIcon
                component="a"
                href="https://twitter.com/sportsheadzcan"
                size={35}
                style={{
                  borderRadius: 50,
                  color: '#000'
                }}
                target="_blank"
                variant="white"
              >
                <BrandTwitter size={25} />
              </ActionableIcon>

              <ActionableIcon
                component="a"
                href="https://ca.linkedin.com/company/sportsheadz"
                size={35}
                style={{
                  borderRadius: 50,
                  color: '#000'
                }}
                target="_blank"
                variant="white"
              >
                <BrandLinkedin size={25} />
              </ActionableIcon>
            </AppFlexbox>
          </AppFlexbox>
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox
        style={{
          flexDirection: rs(['column-reverse', 'row']),
          gap: rs([40, 60]),
          alignItems: rs(['start', 'end'])
        }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppText style={{ fontSize: 12 }} weight={500}>
            © SportsHeadz Inc. All Rights Reserved
          </AppText>
        </AppStack>

        <AppStack style={{ height: 75, opacity: 0.4 }}>
          <Image height="100%" src={sellWithUsLight} width="100%" />
        </AppStack>
        <AppStack style={{ flex: 1 }} visibleFrom="md" />
      </AppFlexbox>
    </AppStack>
  );
};

export default Footer;
