import Auth from '@aws-amplify/auth';
import createDataProvider from './createDataProvider';
import sportsheadzApi from '../apis/sportsheadzApi';
import { getFromStorage, saveToStorage } from '../helpers/storage';

const initialState = {
  email: '',
  password: '',
  isAuthenticated: false,
  isWaitingConfirmSignUp: false,
  tokenAttempted: false,
  userData: null,
  userAssociations: { value: null, loading: false, error: null },
  ecomStores: [],
  ecomVendors: [],
  ecomStoreAdmins: [],
  ecomVendorAdmins: [],
  pkEcomVendor: null,
  pkEcomStore: null,
  error: null,
  loading: false
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'success':
      return { ...state, error: null, loading: false, ...action.payload };
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case 'logout':
      return { ...initialState, tokenAttempted: true };
    case 'reset': {
      return {
        ...initialState,
        tokenAttempted: state.tokenAttempted
      };
    }
    case 'ecom-admin-success': {
      const ecomVendors = action.payload.ecomVendorAdmins.reduce((r, c) => {
        if (r.some((a) => a.pkEcomVendor === c.ecomVendor.pkEcomVendor)) {
          return r;
        }
        return [...r, c.ecomVendor];
      }, []);
      const ecomStores = action.payload.ecomStoreAdmins.reduce((r, c) => {
        if (r.some((a) => a.pkEcomStore === c.ecomStore.pkEcomStore)) {
          return r;
        }
        return [...r, c.ecomStore];
      }, []);

      return {
        ...state,
        ecomVendors,
        ecomStores,
        pkEcomVendor: action.payload.pkEcomVendor,
        pkEcomStore: action.payload.pkEcomStore,
        ecomVendorAdmins: action.payload.ecomVendorAdmins,
        ecomStoreAdmins: action.payload.ecomStoreAdmins,
        loading: false
      };
    }
    case 'associated-associations-fetching': {
      return {
        ...state,
        userAssociations: {
          ...state.userAssociations,
          loading: true
        }
      };
    }
    case 'associated-associations-success': {
      return {
        ...state,
        userAssociations: {
          value: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case 'associated-associations-error': {
      return {
        ...state,
        userAssociations: {
          ...state.userAssociations,
          loading: false,
          error: action.payload
        }
      };
    }
    default:
      return state;
  }
};

const fetchUserAdminData = async (
  dispatch,
  onSuccessCalback = null,
  onErrorCallback = null
) => {
  try {
    const response = await sportsheadzApi.get(`/api/me/ecommerce-admins`);

    const pkEcomVendorString = getFromStorage('pkEcomVendor');
    const selectedVendor =
      response.data.ecomVendorAdmins.find(
        (a) => a.ecomVendor.pkEcomVendor.toString() === pkEcomVendorString
      ) ?? response.data.ecomVendorAdmins[0];
    if (
      selectedVendor &&
      selectedVendor.ecomVendor.pkEcomVendor.toString() !== pkEcomVendorString
    ) {
      saveToStorage(
        'pkEcomVendor',
        selectedVendor.ecomVendor.pkEcomVendor.toString()
      );
    }

    const pkEcomStoreString = getFromStorage('pkEcomStore');
    const selectedStore =
      response.data.ecomStoreAdmins.find(
        (a) => a.ecomStore.pkEcomStore.toString() === pkEcomStoreString
      ) ?? response.data.ecomStoreAdmins[0];
    if (
      selectedStore &&
      selectedStore.ecomStore.pkEcomStore.toString() !== pkEcomStoreString
    ) {
      saveToStorage(
        'pkEcomStore',
        selectedStore.ecomStore.pkEcomStore.toString()
      );
    }

    dispatch({
      type: 'ecom-admin-success',
      payload: {
        pkEcomVendor:
          selectedVendor?.ecomVendor.pkEcomVendor.toString() ?? null,
        pkEcomStore: selectedStore?.ecomStore.pkEcomStore.toString() ?? null,
        ecomVendorAdmins: response.data.ecomVendorAdmins,
        ecomStoreAdmins: response.data.ecomStoreAdmins
      }
    });

    if (onSuccessCalback) {
      onSuccessCalback(response.data);
    }
  } catch (e) {
    dispatch({
      type: 'error',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback('Oops something went wrong');
    }
  }
};

const fetchEcommerceAdminData = (dispatch) => async (
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  fetchUserAdminData(dispatch, onSuccessCallback, onErrorCallback);
};

const selectEcomVendor = (dispatch) => async (pkEcomVendor) => {
  saveToStorage('pkEcomVendor', pkEcomVendor?.toString() ?? '');
  dispatch({
    type: 'success',
    payload: { pkEcomVendor: pkEcomVendor?.toString() }
  });
};

const selectEcomStore = (dispatch) => async (pkEcomStore) => {
  saveToStorage('pkEcomStore', pkEcomStore?.toString() ?? '');
  dispatch({
    type: 'success',
    payload: { pkEcomStore: pkEcomStore?.toString() }
  });
};

const tokenSignIn = (dispatch) => async () => {
  try {
    await Auth.currentAuthenticatedUser();
    const response = await sportsheadzApi.get('/api/user/auth');
    fetchUserAdminData(dispatch);
    dispatch({
      type: 'success',
      payload: {
        isAuthenticated: true,
        tokenAttempted: true,
        userData: response.data,
        loading: true
      }
    });
  } catch (e) {
    dispatch({ type: 'logout' });
  }
};

const signIn = (dispatch) => async (
  { email, password },
  onSuccessCallback,
  onErrorCallback
) => {
  if (email && password) {
    try {
      dispatch({ type: 'fetching' });
      await Auth.signIn(email, password);
      const response = await sportsheadzApi.get('/api/user/auth');
      // if (!response.data.isAdmin) {
      //   await Auth.signOut();
      //   dispatch({ type: 'reset' });
      //   if (onErrorCallback) {
      //     onErrorCallback('Invalid Login.');
      //   }
      //   return;
      // }
      dispatch({
        type: 'success',
        payload: {
          email,
          isAuthenticated: true,
          tokenAttempted: true,
          userData: response.data
        }
      });

      fetchUserAdminData(dispatch);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    } catch (err) {
      dispatch({ type: 'logout' });
      let errorMessage = '';
      switch (err.code) {
        case 'UserNotConfirmedException':
          dispatch({
            type: 'success',
            payload: { email, password, isWaitingConfirmSignUp: true }
          });
          if (onSuccessCallback) {
            onSuccessCallback();
          }
          return;
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
          errorMessage = 'Your password is incorrect';
          break;
        default:
          errorMessage = 'An unknown error occured. Please try again.';
      }

      if (errorMessage) {
        dispatch({
          type: 'error',
          payload: errorMessage
        });
        if (onErrorCallback) {
          onErrorCallback(errorMessage);
        }
      }
    }
  }
};

const checkForExistingUser = (dispatch) => async (
  email,
  successCallback = null,
  onErrorCallback = null
) => {
  dispatch({ type: 'fetching' });

  try {
    await Auth.confirmSignUp(email, '0');
  } catch (e) {
    if (e.code === 'UserNotFoundException') {
      if (onErrorCallback) {
        onErrorCallback('User not found.');
      }
      dispatch({ type: 'error', payload: 'Oops something went wrong' });
    }
    else {
      if (successCallback) {
        successCallback(email);
      }
      dispatch({
        type: 'success',
        payload: { email }
      });
    }
  }
};

const createAccount = (dispatch) => async (
  { firstName, lastName, email, password },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  dispatch({ type: 'fetching' });
  try {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: firstName,
        family_name: lastName
      }
    });
    dispatch({
      type: 'success',
      payload: { email, password, isWaitingConfirmSignUp: true }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (err) {
    let errorMessage;
    switch (err.code) {
      case 'UsernameExistsException':
        errorMessage = 'User with email already exists.';
        break;
      default:
        errorMessage = 'Oops something went wrong';
    }
    dispatch({ type: 'error', payload: errorMessage });
    if (onErrorCallback) {
      onSuccessCallback(errorMessage);
    }
  }
};

const resendVerificationCode = (dispatch) => async (
  email,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  dispatch({ type: 'fetching' });

  try {
    await Auth.resendSignUp(email);
    if (onSuccessCallback) {
      onSuccessCallback();
    }

    dispatch({
      type: 'success',
      payload: {
        email,
        isWaitingConfirmSignUp: true
      }
    });
  } catch (e) {
    const errorMessages =
      'Oops something went wrong. Are you already verified?';
    dispatch({
      type: 'error',
      payload: errorMessages
    });
    if (onErrorCallback) {
      onErrorCallback(errorMessages);
    }
  }
};

const confirmAccount = (dispatch) => async (
  { email, confirmationCode },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  dispatch({ type: 'fetching' });
  try {
    await Auth.confirmSignUp(email, confirmationCode);
    dispatch({
      type: 'success',
      payload: { email, isWaitingConfirmSignUp: false }
    });

    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (err) {
    let errorMessage;
    switch (err.code) {
      case 'ExpiredCodeException':
        errorMessage = 'Code is expired. Are you already verified?';
        break;
      case 'LimitExceededException':
        errorMessage = 'Attempt limit exceeded, please try after some time.';
        break;
      case 'CodeMismatchException':
        errorMessage = 'Invalid verification code provided, please try again.';
        break;
      default:
        errorMessage = 'Oops something went wrong';
    }
    dispatch({ type: 'error', payload: errorMessage });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const sendForgotPasswordConfirmation = (dispatch) => async (
  email,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  dispatch({ type: 'fetching' });

  try {
    await Auth.forgotPassword(email);
    dispatch({ type: 'success', payload: { email } });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    if (e.code === 'InvalidParameterException') {
      dispatch({
        type: 'success',
        payload: {
          email,
          isWaitingConfirmSignUp: true
        }
      });
    }
    else {
      let errorMessage = 'Oops something went wrong';
      switch (e.code) {
        case 'UserNotFoundException':
          errorMessage =
            "We're sorry. We weren't able to identify you given the information provided.";
          break;
        case 'LimitExceededException':
          errorMessage = 'Attempt limit exceeded, please try after some time.';
          break;
        default:
          break;
      }
      dispatch({ type: 'error', payload: errorMessage });
      if (onErrorCallback) {
        onErrorCallback(errorMessage);
      }
    }
  }
};

const resetPassword = (dispatch) => async (
  { email, code, newPassword },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  dispatch({ type: 'fetching' });

  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    dispatch({
      type: 'success',
      payload: { email }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    let errorMessage;
    switch (e.code) {
      case 'ExpiredCodeException':
        errorMessage = 'Code is expired. Are you already verified?';
        break;
      case 'LimitExceededException':
        errorMessage = 'Attempt limit exceeded, please try after some time.';
        break;
      case 'CodeMismatchException':
        errorMessage = 'Invalid verification code provided, please try again.';
        break;
      default:
        errorMessage = 'Error changing password';
    }
    dispatch({ type: 'error', payload: errorMessage });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const logout = (dispatch) => async (callbackFunction = null) => {
  dispatch({ type: 'fetching' });
  await Auth.signOut();
  dispatch({ type: 'reset' });
  if (callbackFunction) {
    callbackFunction();
  }
};

const reset = (dispatch) => async () => {
  dispatch({ type: 'reset' });
};

const clearErrors = (dispatch) => async () => {
  dispatch({ type: 'error', payload: null });
};

const fetchAssociatedTeamsAndAssociations = (dispatch) => async (
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    dispatch({ type: 'associated-associations-fetching' });
    const response = await sportsheadzApi.get(
      '/api/me/associated-associations'
    );
    dispatch({
      type: 'associated-associations-success',
      payload: response.data
    });

    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch {
    const errorMessage = 'Oops something went wrong.';
    dispatch({
      type: 'associated-associations-error',
      payload: errorMessage
    });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const changePassword = (dispatch) => async (
  { oldPassword, newPassword },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    let errorMessage;
    switch (e.code) {
      case 'NotAuthorizedException':
        errorMessage = 'Old password is invalid.';
        break;
      default:
        errorMessage = 'Error changing password';
    }
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const updateProfile = (dispatch) => async (
  { firstName, lastName, phone, avatar },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.put(
      `/api/me/profile`,
      { firstName, lastName, phone, avatar },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    const response = await sportsheadzApi.get('/api/user/auth', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      payload: {
        isAuthenticated: true,
        tokenAttempted: true,
        userData: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

const updateAvatar = (dispatch) => async (
  { avatar },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.put(
      `/api/me/avatar`,
      { avatar },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    const response = await sportsheadzApi.get('/api/user/auth', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      payload: {
        isAuthenticated: true,
        tokenAttempted: true,
        userData: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

export const { Provider, Context } = createDataProvider(
  authReducer,
  {
    tokenSignIn,
    signIn,
    checkForExistingUser,
    createAccount,
    resendVerificationCode,
    confirmAccount,
    sendForgotPasswordConfirmation,
    resetPassword,
    logout,
    reset,
    clearErrors,
    selectEcomStore,
    selectEcomVendor,
    fetchEcommerceAdminData,
    fetchAssociatedTeamsAndAssociations,
    changePassword,
    updateProfile,
    updateAvatar
  },
  initialState
);
