import { Message } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsSubheadingBlock from './WsSubheadingBlock';

const configNameValues = {
  text: 'text',
  textSize: 'textSize'
};

const configDefaultValues = {
  [configNameValues.text]: 'Subheading text',
  [configNameValues.textSize]: '20'
};

const configOptions = {
  [configNameValues.text]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Description'
  },
  textSize: {
    [configNameValues.label]: 'Subheading Size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 36,
    unit: 'px'
  }
};

const config = {
  title: 'Subheading',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.SUBHEADING,
  icon: Message,
  Component: WsSubheadingBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
