import React from 'react';
import { NumberInput } from '@mantine/core';

const CustomNumberInput = React.forwardRef(({ ...props }, ref) => (
  <NumberInput ref={ref} onFocus={(e) => e.target.select()} {...props} />
));

CustomNumberInput.propTypes = {};

export default CustomNumberInput;
