/* eslint-disable import/no-cycle */
import { TextSize } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM,
  WEB_SAFE_FONT_OPTIONS
} from '../../../../../config/constants';
import WsRichText from './WsRichText';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';

const ALIGNMENT_POSITION_ENUM = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end'
};

const alignmentOptions = [
  {
    value: ALIGNMENT_POSITION_ENUM.LEFT,
    label: 'Left'
  },
  {
    value: ALIGNMENT_POSITION_ENUM.CENTER,
    label: 'Center'
  },
  {
    value: ALIGNMENT_POSITION_ENUM.RIGHT,
    label: 'Right'
  }
];

const configNameValues = {
  desktopContentPosition: 'desktopContentPosition',
  desktopContentAlignment: 'desktopContentAlignment',
  contentSpacing: 'contentSpacing',
  colorScheme: 'colorScheme',
  limitContentWidth: 'limitContentWidth',
  maxContentWidth: 'maxContentWidth',
  fontFamily: 'fontFamily'
};

const configDefaultValues = {
  [configNameValues.desktopContentPosition]: ALIGNMENT_POSITION_ENUM.CENTER,
  [configNameValues.desktopContentAlignment]: ALIGNMENT_POSITION_ENUM.CENTER,
  [configNameValues.contentSpacing]: '16',
  [configNameValues.colorScheme]: '1',
  [configNameValues.limitContentWidth]: 'true',
  [configNameValues.maxContentWidth]: '800',
  [configNameValues.fontFamily]: ''
};

const configOptions = {
  [configNameValues.desktopContentPosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Content position',
    options: alignmentOptions
  },
  [configNameValues.desktopContentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Content alignment',
    options: alignmentOptions
  },
  [configNameValues.fontFamily]: {
    label: 'Font family',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: WEB_SAFE_FONT_OPTIONS,
    searchable: true,
    clearable: true
  },
  [configNameValues.contentSpacing]: {
    label: 'Content spacing',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 0,
    max: 50,
    unit: 'px'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.limitContentWidth]: {
    label: 'Limit content width',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.maxContentWidth]: {
    label: 'Max content width',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 300,
    max: 1600,
    unit: 'px',
    isVisible: (configValues) =>
      configValues[configNameValues.limitContentWidth] === 'true'
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.RICH_TEXT,
  title: 'Rich text',
  icon: TextSize,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsRichText,
  configOptions,
  maxItemCount: null,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.HEADING
      ],
      defaultCount: 1,
      maxCount: 3
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CAPTION
      ],
      defaultCount: 0,
      type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CAPTION,
      maxCount: 3
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT
      ],
      defaultCount: 1,
      maxCount: 3
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUTTON_GROUP
      ],
      defaultCount: 1,
      maxCount: 3
    }
  ],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
