import { Badge } from '@mantine/core';
import React from 'react';
import {
  Basket,
  BuildingStore,
  Coin,
  Dashboard,
  Home,
  Report,
  ReportAnalytics,
  Settings,
  Shirt,
  TruckDelivery,
  User
} from 'tabler-icons-react';

const BASE_NAVIGATION_LINKS = {
  public: [],
  profile: [],
  admin: [
    {
      to: '/admin',
      title: 'Admin Dashboard',
      icon: User,
      isSelected: (pathname) => pathname === '/admin',
      hasAccess: (authState) => authState.userData?.isAdmin
    },
    {
      to: '/merchant',
      linkOverride: (authState) =>
        !authState.pkEcomStore ? '/merchant/switch' : '/merchant',
      title: 'Store Dashboard',
      icon: BuildingStore,
      isSelected: (pathname) => pathname === '/merchant',
      hasAccess: (authState) => true
    },
    {
      to: '/vendor',
      title: 'Vendor Dashboard',
      icon: TruckDelivery,
      isSelected: (pathname) => pathname === '/vendor',
      hasAccess: (authState) =>
        authState.ecomVendors.length > 0 || authState.userData?.isAdmin
    }
  ]
};

const VENDOR_NAVIGATION_LINKS = {
  main: [
    {
      to: '/vendor',
      title: 'Home',
      icon: Home,
      isSelected: (pathname) =>
        pathname === '/vendor' || pathname === '/vendor/'
    },
    {
      to: '/vendor/products',
      title: 'Products',
      icon: Shirt,
      isSelected: (pathname) => pathname.startsWith(`/vendor/products`),
      links: [
        {
          to: '/vendor/collections',
          title: 'Collections',
          isSelected: (pathname) => pathname.startsWith(`/vendor/collections`)
        },
        {
          to: '/vendor/inventory',
          title: 'Inventory',
          isSelected: (pathname) => pathname.startsWith(`/vendor/inventory`)
        }
      ]
    },
    {
      to: '/vendor/orders',
      title: 'Orders',
      icon: Basket,
      isSelected: (pathname) =>
        pathname.startsWith('/vendor/orders') &&
        !pathname.startsWith(`/vendor/orders/bulk`),
      getRightSection: (state) =>
        state.ecomVendorSetupSummary.value?.unfulfilledOrderCount > 0 && (
          <Badge color="orange">
            {state.ecomVendorSetupSummary.value?.unfulfilledOrderCount}
          </Badge>
        ),
      links: [
        {
          to: '/vendor/orders/bulk',
          title: 'Bulk orders',
          isSelected: (pathname) => pathname.startsWith(`/vendor/orders/bulk`)
        }
        // {
        //   to: '/vendor/customers',
        //   title: 'Customers',
        //   isSelected: (pathname) => pathname.startsWith(`/vendor/customers`)
        // }
        // {
        //   to: '/vendor/merchants',
        //   title: 'Merchants',
        //   isSelected: (pathname) => pathname.startsWith(`/vendor/merchants`)
        // }
      ]
    },
    {
      to: '/vendor/reports',
      title: 'Reports',
      icon: Report,
      isSelected: (pathname) => pathname.startsWith('/vendor/reports')
    },
    {
      to: '/vendor/settings',
      title: 'Settings',
      icon: Settings,
      isSelected: (pathname) => pathname.startsWith('/vendor/settings')
    }
  ]
};

const MERCHANT_NAVIGATION_LINKS = {
  main: [
    {
      to: '/merchant',
      title: 'Home',
      icon: Home,
      isSelected: (pathname) =>
        pathname === '/merchant' || pathname === '/merchant/'
    },
    {
      to: '/merchant/products',
      title: 'Products',
      icon: Shirt,
      isSelected: (pathname) => pathname.startsWith('/merchant/products'),
      links: [
        {
          to: '/merchant/collections',
          title: 'Collections',
          isSelected: (pathname) => pathname.startsWith(`/merchant/collections`)
        }
      ]
    },
    {
      to: '/merchant/fundraising',
      title: 'Fundraising',
      icon: Coin,
      isSelected: (pathname) => pathname.startsWith('/merchant/fundraising')
    },
    {
      to: '/merchant/orders',
      title: 'Orders',
      icon: Basket,
      isSelected: (pathname) => pathname.startsWith('/merchant/orders'),
      links: [
        {
          to: '/merchant/customers',
          title: 'Customers',
          isSelected: (pathname) => pathname.startsWith(`/merchant/customers`)
        }
      ]
    },
    {
      to: '/merchant/analytics',
      title: 'Analytics',
      icon: ReportAnalytics,
      isSelected: (pathname) => pathname.startsWith('/merchant/analytics'),
      links: [
        {
          to: '/merchant/reports',
          title: 'Reports',
          isSelected: (pathname) => pathname.startsWith('/merchant/reports')
        }
      ]
    },
    {
      to: '/merchant/settings',
      title: 'Settings',
      icon: Settings,
      isSelected: (pathname) => pathname.startsWith('/merchant/settings')
    }
  ],
  sections: [
    {
      title: 'Store',
      links: [
        {
          to: '/merchant/webstore/themes',
          title: 'Online Store',
          icon: BuildingStore,
          isSelected: (pathname) => false,
          links: [
            {
              to: '/merchant/webstore/themes',
              title: 'Themes',
              isSelected: (pathname) =>
                pathname.startsWith(`/merchant/webstore/themes`)
            },
            // {
            //   to: '/merchant/webstore/pages',
            //   title: 'Pages',
            //   isSelected: (pathname) =>
            //     pathname.startsWith(`/merchant/webstore/pages`)
            // },
            {
              to: '/merchant/webstore/menus',
              title: 'Navigation',
              isSelected: (pathname) =>
                pathname.startsWith(`/merchant/webstore/menus`)
            }
          ]
        }
      ]
    }
  ]
};

const ADMIN_NAVIGATION_LINKS = {
  main: [
    {
      to: '/admin',
      title: 'Overview',
      icon: Dashboard,
      isSelected: (pathname) => pathname === '/admin' || pathname === '/admin/'
    },
    {
      to: '/admin/stores',
      title: 'Stores',
      icon: BuildingStore,
      isSelected: (pathname) => pathname.startsWith('/admin/stores')
    },
    {
      to: '/admin/vendors',
      title: 'Vendors',
      icon: TruckDelivery,
      isSelected: (pathname) => pathname.startsWith('/admin/vendors')
    }
  ]
};

export {
  BASE_NAVIGATION_LINKS,
  VENDOR_NAVIGATION_LINKS,
  MERCHANT_NAVIGATION_LINKS,
  ADMIN_NAVIGATION_LINKS
};
