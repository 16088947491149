/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput } from '@mantine/core';

const WsQuantitySelectorBlock = ({
  quantity,
  onQuantityChange,
  submitting,
  config,
  ecomStoreProduct,
  selectedVariant,
  ecomStoreCart
}) => {
  const isUnavailable =
    ecomStoreProduct?.deleted ||
    ecomStoreProduct?.ecomVendorProduct.deleted ||
    selectedVariant?.deleted ||
    selectedVariant?.unavailable ||
    selectedVariant?.ecomVendorProductVariant.deleted;
  const quantityLeft = selectedVariant
    ? selectedVariant.ecomVendorProductVariant.quantity
    : null;
  const isOutOfStock = quantityLeft !== null && quantityLeft <= 0;

  const productInCart = ecomStoreCart?.ecomStoreCartProducts.find(
    (p) =>
      p.ecomStoreProductVariant.ecomVendorProductVariant.uuid ===
      selectedVariant?.ecomVendorProductVariant.uuid
  );
  const maxQuantity = selectedVariant?.ecomVendorProductVariant.quantity
    ? selectedVariant.ecomVendorProductVariant.quantity -
      (productInCart?.count ?? 0)
    : null;

  return (
    <NumberInput
      disabled={submitting || isUnavailable || isOutOfStock}
      label={`Quantity ${
        productInCart ? `(${productInCart.count} in cart)` : ''
      }`}
      min={1}
      onChange={(value) => {
        let newQuantity =
          maxQuantity !== null && value > maxQuantity ? maxQuantity : value;
        if (newQuantity <= 0) {
          newQuantity = 1;
        }
        onQuantityChange(newQuantity);
      }}
      radius={0}
      size="md"
      style={{ maxWidth: 150 }}
      styles={{
        label: {
          fontSize: 14,
          // color: textColorVariants.lighter,
          fontWeight: 'normal'
        }
      }}
      value={quantity}
    />
  );
};

WsQuantitySelectorBlock.propTypes = {
  config: PropTypes.object,
  ecomStoreCart: PropTypes.object,
  ecomStoreProduct: PropTypes.object,
  onQuantityChange: PropTypes.func,
  quantity: PropTypes.number,
  selectedVariant: PropTypes.object,
  submitting: PropTypes.bool
};

export default WsQuantitySelectorBlock;
