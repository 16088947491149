import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Button,
  Loader,
  Menu,
  ScrollArea,
  Skeleton,
  TextInput
} from '@mantine/core';
import {
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  CircleCheck,
  CurrencyDollar,
  Plus
} from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppImage from '../../common/AppImage';
import sportsHeadzLogo from '../../../images/sportsheadz-logo.png';
import CustomNavLink from '../../common/CustomNavLink';
import { getInitialsFromName } from '../../../helpers/format';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notification';
import AnimatedBackgroundView from '../../common/AnimatedBackgroundView';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppMenu from '../../common/AppMenu';
import SettingSelector from '../../common/SettingSelector';
import {
  CURRENCY_TYPE_ENUM,
  CURRENCY_TYPE_LIST
} from '../../../config/constants';

const VendorSwitchView = () => {
  const navigate = useNavigate();
  const { isMobileOrSmaller, isLargeMobileOrSmaller } = useMediaQueryIndex();
  const {
    state: authState,
    selectEcomVendor,
    fetchEcommerceAdminData
  } = useContext(AuthContext);
  const { createVendor } = useContext(VendorContext);
  const [formState, setFormState] = useState({
    name: '',
    fkCurrencyType: CURRENCY_TYPE_ENUM.CAD,
    showNewVendorForm: false,
    loading: false
  });

  const selectedCurrency =
    CURRENCY_TYPE_LIST.find(
      (currency) => currency.value === formState.fkCurrencyType
    ) || CURRENCY_TYPE_LIST[0];

  return (
    <AnimatedBackgroundView>
      <AppStack
        style={{
          flex: 1,
          alignItems: 'start',
          justifyContent: 'center',
          paddingBottom: isLargeMobileOrSmaller ? 0 : 60,
          paddingTop: isLargeMobileOrSmaller ? 0 : 10
        }}
      >
        <Button
          color="white"
          component={Link}
          leftSection={<ArrowLeft size={18} />}
          to={authState.pkEcomVendor ? '/vendor/' : '/'}
          variant="outline"
          visibleFrom="xsm"
        >
          Back to vendor
        </Button>
        <AppCard
          flex={{ base: 1, xsm: 'unset' }}
          mah={{ base: '100vh', xsm: 750 }}
          maw={{ base: '100%', xsm: 475 }}
          mih={{ base: '100vh', xsm: 750 }}
          radius={isLargeMobileOrSmaller ? 0 : 'lg'}
          shadow="xl"
          style={{
            height: '100%',
            width: '100vw',
            padding: 0,
            overflow: 'auto'
          }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox
              style={{
                padding: 10,
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppFlexbox
                component={Link}
                style={{
                  alignItems: 'center',
                  textDecoration: 'none',
                  paddingLeft: 10,
                  gap: 5,
                  maxWidth: 240
                }}
                to="/"
              >
                <AppImage
                  alt="SportsHeadz Main Logo"
                  fit="contain"
                  height={80}
                  src={sportsHeadzLogo}
                  style={{ minWidth: 'unset', maxWidth: 125 }}
                  width="100%"
                />
              </AppFlexbox>
              <Avatar
                color="dark"
                radius="xl"
                size={50}
                src={authState.userData.user.avatar}
                style={{ backgroundColor: '#eca70a', marginRight: 10 }}
                styles={{ root: { border: 'solid 1px lightgrey' } }}
              >
                <AppText>
                  {getInitialsFromName(authState.userData.user.name)}
                </AppText>
              </Avatar>
            </AppFlexbox>
          </AppStack>

          {authState.userData.isAdmin && formState.showNewVendorForm ? (
            <AppStack
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setFormState({
                  ...formState,
                  loading: true
                });
                createVendor(
                  {
                    name: formState.name,
                    fkCurrencyType: formState.fkCurrencyType,
                    sandboxEnabled: true
                  },
                  (data) => {
                    fetchEcommerceAdminData(
                      () => {
                        selectEcomVendor(data.pkEcomVendor);
                        triggerNotification('Vendor created!', 'success');
                        navigate('/vendor/');
                      },
                      () => {
                        window.location.reload();
                      }
                    );
                  },
                  (error) => {
                    triggerNotification(error);
                    setFormState({
                      ...formState,
                      loading: false
                    });
                  }
                );
              }}
              style={{
                flex: 1,
                gap: 10,
                overflow: 'hidden',
                height: '100%'
              }}
            >
              {formState.error && (
                <AppStack style={{ padding: 20 }}>
                  <Alert
                    color="#c40000"
                    icon={<AlertTriangle style={{ height: 28, width: 28 }} />}
                    radius={10}
                    styles={{
                      body: { gap: 5 },
                      icon: { height: 28, width: 28 },
                      title: { fontSize: 17, fontWeight: 500 }
                    }}
                    title="There was a problem"
                    variant="outline"
                  >
                    <AppText style={{ fontSize: 13 }}>
                      {formState.error}
                    </AppText>
                  </Alert>
                </AppStack>
              )}

              <AppFlexbox
                style={{
                  padding: '0px 20px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontWeight: 500, fontSize: 22 }}>
                  Add new vendor
                </AppText>
              </AppFlexbox>
              <AppStack
                style={{ gap: 10, padding: 20, paddingTop: 10, flex: 1 }}
              >
                <TextInput
                  disabled={formState.loading}
                  label="Vendor name"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      name: e.currentTarget.value
                    })
                  }
                  placeholder="My vendor"
                  required
                  value={formState.name}
                />
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Currency
                  </AppText>

                  <AppMenu
                    control={
                      <AppFlexbox>
                        <SettingSelector
                          disabled={formState.loading}
                          leftSection={
                            <CurrencyDollar
                              size={isLargeMobileOrSmaller ? 26 : 32}
                            />
                          }
                          leftSectionWidth={35}
                          onClick={() => {}}
                          rightSection={
                            <AppFlexbox
                              style={{
                                gap: 5,
                                alignItems: 'center',
                                color: '#999'
                              }}
                            >
                              <AppText
                                style={{ fontSize: 14, fontWeight: 500 }}
                              >
                                {isLargeMobileOrSmaller
                                  ? 'Change'
                                  : 'Click to change'}
                              </AppText>
                              <ChevronRight size={18} />
                            </AppFlexbox>
                          }
                          style={{ gap: 8 }}
                          title={selectedCurrency.fullLabel}
                          withBorder
                        />
                      </AppFlexbox>
                    }
                    position="bottom-start"
                    styles={{
                      dropdown: {
                        padding: 0,
                        zIndex: 1000
                      },
                      item: { padding: 2 }
                    }}
                    width="target"
                  >
                    <AppStack style={{ padding: 2, gap: 1 }}>
                      {CURRENCY_TYPE_LIST.map((c) => (
                        <Menu.Item>
                          <SettingSelector
                            disabled={formState.loading}
                            leftSection={
                              c.value === selectedCurrency.value ? (
                                <CircleCheck color="green" size={32} />
                              ) : (
                                <></>
                              )
                            }
                            leftSectionWidth={35}
                            onClick={() => {
                              setFormState({
                                ...formState,
                                fkCurrencyType: c.value
                              });
                            }}
                            rightSection={<></>}
                            style={{ gap: 8 }}
                            title={c.fullLabel}
                            withBorder
                          />
                        </Menu.Item>
                      ))}
                    </AppStack>
                  </AppMenu>
                </AppStack>

                {formState.loading && (
                  <Loader color="dark" size={50} style={{ margin: 'auto' }} />
                )}
              </AppStack>
              {!formState.loading && (
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 10
                  }}
                >
                  <Button
                    color="dark"
                    disabled={formState.loading}
                    leftSection={<ChevronLeft size={18} />}
                    onClick={() =>
                      setFormState({
                        ...formState,
                        showNewVendorForm: false
                      })
                    }
                    size="sm"
                    type="button"
                    variant="subtle"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="dark"
                    disabled={!formState.name}
                    loading={formState.loading}
                    rightSection={<ArrowRight size={18} />}
                    size="sm"
                    type="submit"
                    variant="filled"
                  >
                    Create
                  </Button>
                </AppFlexbox>
              )}
            </AppStack>
          ) : (
            <>
              <AppFlexbox
                style={{
                  padding: '0px 20px',
                  justifyContent: 'space-between',
                  alignItems: isMobileOrSmaller ? 'stretch' : 'center',
                  flexDirection: isMobileOrSmaller ? 'column' : 'row'
                }}
              >
                <AppText style={{ fontWeight: 500, fontSize: 22 }}>
                  Hello, {authState.userData.user.name.split(' ')[0]}
                </AppText>
                {authState.userData.isAdmin && (
                  <Button
                    color="dark"
                    leftSection={<Plus size={18} />}
                    onClick={() =>
                      setFormState({
                        ...formState,
                        showNewVendorForm: true
                      })
                    }
                    radius="sm"
                    size="compact-md"
                    variant="filled"
                  >
                    New vendor
                  </Button>
                )}
              </AppFlexbox>
              <AppStack
                style={{
                  padding: 20,
                  gap: 10,
                  height: '100%',
                  overflow: 'hidden'
                }}
              >
                {authState.loading ? (
                  <>
                    <AppFlexbox
                      style={{ padding: 8, gap: 10, alignItems: 'center' }}
                    >
                      <Skeleton height={38} radius={100} width={38} />
                      <Skeleton height={12} width={100} />
                    </AppFlexbox>
                    <AppFlexbox
                      style={{ padding: 8, gap: 10, alignItems: 'center' }}
                    >
                      <Skeleton height={38} radius={100} width={38} />
                      <Skeleton height={12} width={100} />
                    </AppFlexbox>
                  </>
                ) : (
                  <ScrollArea.Autosize
                    style={{ width: '100%', height: '100%', maxHeight: '100%' }}
                  >
                    {authState.ecomVendors
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((s) => (
                        <CustomNavLink
                          key={s.pkEcomVendor}
                          label={s.name}
                          leftSection={
                            <Avatar
                              color="dark"
                              radius="xl"
                              size="md"
                              src={s.logoImageUrl}
                              style={{ backgroundColor: '#eca70a' }}
                              styles={{root: { border: 'solid 1px lightgrey' }}}
                            >
                              <AppText>{getInitialsFromName(s.name)}</AppText>
                            </Avatar>
                          }
                          onClick={() => {
                            selectEcomVendor(s.pkEcomVendor);
                            navigate('/vendor/');
                          }}
                        />
                      ))}
                  </ScrollArea.Autosize>
                )}
              </AppStack>
            </>
          )}
        </AppCard>
      </AppStack>
    </AnimatedBackgroundView>
  );
};

export default VendorSwitchView;
