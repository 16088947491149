import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Plus,
  Template
} from 'tabler-icons-react';
import { ActionIcon, Button, ColorSwatch, Menu } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import AppMenu from '../../common/AppMenu';
import {
  ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM,
  ECOM_WEBSTORE_PAGE_CONFIG_LIST
} from './pages/wsPageConfig';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import WebstoreBuilderCreatePageTemplateModal from './WebstoreBuilderCreatePageTemplateModal';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const WebstoreBuilderHeaderNavigationMenu = ({
  ecomStoreTheme,
  onSelectPage,
  controlState
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const [menuState, setMenuState] = useState({
    isOpen: false,
    selectedPageType: null,
    selectedSubCategory: null,
    showTemplateCreate: false
  });

  const selectedPage = ECOM_WEBSTORE_PAGE_CONFIG_LIST.find(
    (p) => p.type === controlState.selectedPageType
  );
  const selectedMenuPage = ECOM_WEBSTORE_PAGE_CONFIG_LIST.find(
    (p) => p.type === menuState.selectedPageType
  );

  useEffect(() => {
    if (!selectedPage) {
      onSelectPage(ECOM_WEBSTORE_PAGE_CONFIG_LIST[0].type);
    }
  }, [selectedPage]);

  const onNavigate = (url) => {
    const searchParams = new URLSearchParams();
    searchParams.set('previewPath', url);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <AppMenu
      closeOnItemClick={false}
      control={
        selectedPage &&
        (isTabletOrSmaller ? (
          <AppFlexbox
            onClick={() => {
              setMenuState({
                isOpen: true,
                selectedPageType: null,
                showTemplateCreate: false
              });
            }}
            style={{
              height: '100%',
              width: '100%',
              maxWidth: '100%',
              padding: '0px 12px',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer'
            }}
          >
            <AppFlexbox
              style={{
                alignItems: 'center',
                gap: 12,
                width: '100%'
              }}
            >
              <selectedPage.icon size={18} />
              <AppStack style={{ gap: 0, width: '100%', overflow: 'hidden' }}>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    overflow: 'hidden',
                    maxWidth: '100%'
                  }}
                >
                  <AppText
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      maxWidth: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                  >
                    {ecomStoreTheme.name}
                  </AppText>
                  <ColorSwatch
                    color={ecomStoreTheme.active ? '#40C057' : 'dodgerblue'}
                    size={14}
                  />
                </AppFlexbox>

                <AppText
                  style={{
                    fontSize: 14,
                    color: '#666',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%'
                  }}
                >
                  {selectedPage.title}
                </AppText>
              </AppStack>
            </AppFlexbox>
            <ChevronDown color="#666" size={18} />
          </AppFlexbox>
        ) : (
          <Button
            color="dark"
            leftSection={<selectedPage.icon size={18} />}
            onClick={() => {
              setMenuState({
                isOpen: true,
                selectedPageType: null,
                showTemplateCreate: false
              });
            }}
            rightSection={<ChevronDown color="#666" size={16} />}
            variant="subtle"
          >
            {selectedPage.title}
          </Button>
        ))
      }
      onClose={() => {
        if (!menuState.showTemplateCreate) {
          setMenuState({
            ...menuState,
            isOpen: false
          });
        }
      }}
      opened={menuState.isOpen}
      position="bottom-center"
      width={300}
    >
      {selectedMenuPage ? (
        <>
          <AppFlexbox
            style={{ padding: '5px 10px', gap: 10, alignItems: 'center' }}
          >
            <ActionIcon
              color="dark"
              onClick={() =>
                setMenuState({
                  ...menuState,
                  selectedPageType: null
                })
              }
              radius="md"
              size={28}
              variant="subtle"
            >
              <ChevronLeft color="#666" size={18} />
            </ActionIcon>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              {selectedMenuPage.title}
            </AppText>
          </AppFlexbox>
          <Menu.Divider />
          <Menu.Item
            leftSection={<Template size={18} />}
            onClick={() => {
              onSelectPage(selectedMenuPage.type, null);
              setMenuState({
                ...menuState,
                isOpen: false
              });
              onNavigate(selectedMenuPage.path);
            }}
          >
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14 }}>
                Default {selectedMenuPage.title.toLowerCase()}
              </AppText>
              <AppText style={{ fontSize: 14, color: '#666' }}>
                Assigned to 0 {selectedMenuPage.title.toLowerCase()}
              </AppText>
            </AppStack>
          </Menu.Item>
          <Menu.Item
            leftSection={<Plus color="dodgerblue" size={18} />}
            onClick={(e) => {
              e.preventDefault();
              setMenuState({
                ...menuState,
                showTemplateCreate: true
              });
            }}
            style={{ color: 'dodgerblue' }}
          >
            Create template
          </Menu.Item>
        </>
      ) : menuState.selectedSubCategory ? (
        <>
          <AppFlexbox
            style={{ padding: '5px 10px', gap: 10, alignItems: 'center' }}
          >
            <ActionIcon
              color="dark"
              onClick={() =>
                setMenuState({
                  ...menuState,
                  selectedSubCategory: null
                })
              }
              radius="md"
              size={28}
              variant="subtle"
            >
              <ChevronLeft color="#666" size={18} />
            </ActionIcon>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              {menuState.selectedSubCategory.title}
            </AppText>
          </AppFlexbox>
          <Menu.Divider />
          {ECOM_WEBSTORE_PAGE_CONFIG_LIST.filter(
            (p) =>
              p.subCategory &&
              p.subCategory.value === menuState.selectedSubCategory.value
          )
            .sort((a, b) => a.sort - b.sort)
            .map((page) => (
              <Menu.Item
                key={page.type}
                leftSection={page.icon && <page.icon size={18} />}
                onClick={(e) => {
                  if (page.canCreateTemplate) {
                    e.preventDefault();
                    setMenuState({
                      ...menuState,
                      selectedPageType: page.type
                    });
                  }
                  else {
                    onNavigate(page.path);
                    setMenuState({
                      ...menuState,
                      isOpen: false
                    });
                  }
                }}
                rightSection={
                  page.canCreateTemplate ? (
                    <ChevronRight color="#666" size={18} />
                  ) : null
                }
              >
                {page.title}
              </Menu.Item>
            ))}
        </>
      ) : (
        Object.keys(ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM).map((key, index) => {
          const categoryPages = ECOM_WEBSTORE_PAGE_CONFIG_LIST.filter(
            (p) => p.categoryType === ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM[key]
          ).reduce((r, c) => {
            if (c.subCategory) {
              const existing = r.find(
                (f) =>
                  f.subCategory && f.subCategory.value === c.subCategory.value
              );
              if (!existing) {
                r.push({
                  subCategory: c.subCategory,
                  pages: [c],
                  sort: c.sort
                });
              }
              else {
                existing.pages.push(c);
              }
            }
            else {
              r.push({
                pages: [c],
                subCategory: null,
                sort: c.sort
              });
            }
            return r;
          }, []);

          return (
            <React.Fragment key={key}>
              {index > 0 && <Menu.Divider />}
              {categoryPages
                .sort((a, b) => a.sort - b.sort)
                .map((c) => {
                  if (!c.subCategory) {
                    const page = c.pages[0];
                    return (
                      <Menu.Item
                        key={page.type}
                        leftSection={page.icon && <page.icon size={18} />}
                        onClick={(e) => {
                          if (page.canCreateTemplate) {
                            e.preventDefault();
                            setMenuState({
                              ...menuState,
                              selectedPageType: page.type
                            });
                          }
                          else {
                            onNavigate(page.path);
                            setMenuState({
                              ...menuState,
                              isOpen: false
                            });
                          }
                        }}
                        rightSection={
                          page.canCreateTemplate ? (
                            <ChevronRight color="#666" size={18} />
                          ) : null
                        }
                      >
                        {page.title}
                      </Menu.Item>
                    );
                  }

                  return (
                    <Menu.Item
                      key={c.subCategory.value}
                      leftSection={
                        c.subCategory.icon && <c.subCategory.icon size={18} />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setMenuState({
                          ...menuState,
                          selectedSubCategory: c.subCategory
                        });
                      }}
                      rightSection={<ChevronRight color="#666" size={18} />}
                    >
                      {c.subCategory.title}
                    </Menu.Item>
                  );
                })}
            </React.Fragment>
          );
        })
      )}
      <WebstoreBuilderCreatePageTemplateModal
        isOpen={menuState.showTemplateCreate}
        onClose={() =>
          setMenuState({
            ...menuState,
            showTemplateCreate: false
          })
        }
      />
    </AppMenu>
  );
};

WebstoreBuilderHeaderNavigationMenu.propTypes = {
  controlState: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  onSelectPage: PropTypes.func
};

export default WebstoreBuilderHeaderNavigationMenu;
