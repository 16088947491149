import React from 'react';
import { Anchor, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';

const AdminVendorInfoCard = ({ ecomVendor }) => {
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();

  return ecomVendor ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Vendor
              </AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox>
                <AppText style={{ fontSize: 13 }}>{ecomVendor.name}</AppText>
              </AppFlexbox>
            </AppStack>
          </AppStack>

          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Contact information
              </AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 13 }}>{ecomVendor.name}</AppText>
              {ecomVendor.domain && (
                <AppFlexbox>
                  <Anchor
                    href={ecomVendor.domain}
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                    target="_blank"
                  >
                    {ecomVendor.domain}
                  </Anchor>
                </AppFlexbox>
              )}

              <AppFlexbox>
                <Anchor
                  href={`mailto:${ecomVendor.supportEmail}`}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  target="_blank"
                >
                  {ecomVendor.supportEmail}
                </Anchor>
              </AppFlexbox>
            </AppStack>
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton height={22} width="30%" />
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox>
                <Skeleton height={16} width="40%" />
              </AppFlexbox>
            </AppStack>
          </AppStack>

          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton height={22} width="60%" />
            </AppFlexbox>
            <AppStack style={{ gap: 5 }}>
              <Skeleton height={16} width="40%" />
              <Skeleton height={16} width="40%" />
              <Skeleton height={16} width="40%" />
            </AppStack>
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

AdminVendorInfoCard.propTypes = { ecomVendor: PropTypes.object };

export default AdminVendorInfoCard;
