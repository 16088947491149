import React, { useContext, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import { storeAnalyticsAndReportsConfig } from '../analytics/storeAnalyticsAndReportsConfig';
import AnalyticsReportView from '../analytics/AnalyticsReportView';
import StoreReportTable from './StoreReportTable';

const StoreReportsView = () => {
  const { state: authState } = useContext(AuthContext);
  const { state, generateStoreReport } = useContext(StoreContext);
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const [reportLoading, setReportLoading] = useState(false);

  const onFetchAnalyticsReport = (uuid, requestData) => {
    setReportLoading(true);
    generateStoreReport(
      authState.pkEcomStore,
      { uuid, ...requestData },
      () => setReportLoading(false),
      (error) => {
        setReportLoading(false);
        triggerNotification(error);
      }
    );
  };

  const getUrlParams = () => {
    switch (fromLocation?.toLocaleLowerCase()) {
      case 'analytics':
        return '/merchant/analytics';
      default:
        return '/merchant/reports';
    }
  };

  return (
    <Routes>
      <Route element={<StoreReportTable />} path="/" />
      <Route
        element={
          <AnalyticsReportView
            entityId={authState.pkEcomStore}
            loading={reportLoading}
            onFetchReport={onFetchAnalyticsReport}
            redirectPath={getUrlParams()}
            reportConfigs={storeAnalyticsAndReportsConfig.reports}
            reportResult={state.ecomStoreReport.value}
          />
        }
        path="/:uuid"
      />
      <Route element={<Navigate replace to="/merchant/reports" />} path="*" />
    </Routes>
  );
};

export default StoreReportsView;
