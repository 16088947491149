import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import TreeItem from './TreeItem';
import { iOS } from '../../helpers/sortableTreeHelper';

const animateLayoutChanges = ({ isSorting, wasDragging }) =>
  !(isSorting || wasDragging);

const SortableTreeItem = ({ id, depth, ...props }) => {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    animateLayoutChanges
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      depth={depth}
      disableInteraction={isSorting}
      disableSelection={iOS}
      ghost={isDragging}
      handleProps={{
        ...attributes,
        ...listeners
      }}
      style={style}
      wrapperRef={setDroppableNodeRef}
      {...props}
    />
  );
};

SortableTreeItem.propTypes = {
  depth: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
};

export default SortableTreeItem;
