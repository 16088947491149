/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, Receipt } from 'tabler-icons-react';
import { ActionIcon, Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import ConfirmModal from '../../../../common/ConfirmModal';
import WsAccountOrderInfo from './WsAccountOrderInfo';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../../../helpers/notification';

const WsAccountOrderDetails = ({
  ecomStoreTheme,
  baseStoreUrl,
  mediaQueries,
  onNavigate,
  pathname,
  search
}) => {
  const fetchedPkEcomStoreCart = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    sendEcomStoreCartOrderReceipt,
    fetchEcomStoreOrderHistory
  } = useContext(StoreUserContext);
  const [emailReceiptModal, setEmailReceipt] = useState({
    isOpen: false,
    loading: false
  });
  const { isTabletOrSmaller } = mediaQueries;
  const pkEcomStoreCart = pathname.split('/')[3];
  const searchParams = new URLSearchParams(search);
  const guestEmail = searchParams.get('email');
  const guestUuid = searchParams.get('uuid');

  const ecomStoreCart = state.ecomStoreCartOrderHistory.value;

  const isOrderDetailsLoading =
    !fetchedPkEcomStoreCart.current || state.ecomStoreCartOrderHistory.loading;

  useEffect(() => {
    if (pkEcomStoreCart && fetchedPkEcomStoreCart.current !== pkEcomStoreCart) {
      fetchEcomStoreOrderHistory(ecomStoreTheme.ecomStore.pkEcomStore, {
        pkEcomStoreCart,
        guestEmail,
        guestUuid
      });
      fetchedPkEcomStoreCart.current = pkEcomStoreCart;
    }
  }, [pkEcomStoreCart]);

  return (
    <AppStack style={{ flex: 1, gap: 16 }}>
      <AppCard
        radius={isTabletOrSmaller ? 0 : 'md'}
        shadow="xs"
        style={{ padding: 16 }}
        withBorder
      >
        <AppStack style={{ flex: 1, gap: 0 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppFlexbox style={{ gap: 8 }}>
              <ActionIcon
                color="dark"
                component={Link}
                onClick={onNavigate}
                to={
                  !authState.isAuthenticated
                    ? `${baseStoreUrl}/account/login?redirect=/account/orders`
                    : `${baseStoreUrl}/account/orders`
                }
                variant="subtle"
              >
                <ChevronLeft size={20} />
              </ActionIcon>

              <AppText style={{ fontSize: 18, fontWeight: 700 }}>
                Order Details
              </AppText>
            </AppFlexbox>

            {/* <Button
              color="dark"
              disabled={isOrderDetailsLoading || !ecomStoreCart}
              leftSection={<Receipt size={18} />}
              onClick={() =>
                setEmailReceipt({
                  isOpen: true,
                  loading: false
                })
              }
              size="compact-md"
              variant="light"
            >
              Email Receipt
            </Button> */}
          </AppFlexbox>
        </AppStack>
      </AppCard>
      {isOrderDetailsLoading ? (
        <WsAccountOrderInfo
          baseStoreUrl={baseStoreUrl}
          mediaQueries={mediaQueries}
          onNavigate={onNavigate}
          pathname={pathname}
        />
      ) : !ecomStoreCart ? (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack
            style={{
              flex: 1,
              gap: 5,
              alignItems: 'center',
              textAlign: 'center',
              padding: 40
            }}
          >
            <Receipt color="#000" size={56} />
            <AppText style={{ fontWeight: 500 }}>
              Order could not be found
            </AppText>
            <Button
              color="dark"
              component={Link}
              onClick={onNavigate}
              style={{ marginTop: 5 }}
              to={baseStoreUrl}
              variant="outline"
            >
              Back to orders
            </Button>
          </AppStack>
        </AppCard>
      ) : (
        <WsAccountOrderInfo
          baseStoreUrl={baseStoreUrl}
          ecomStoreCart={ecomStoreCart}
          mediaQueries={mediaQueries}
          onNavigate={onNavigate}
          pathname={pathname}
        />
      )}

      <ConfirmModal
        isLoading={emailReceiptModal.loading}
        isOpen={emailReceiptModal.isOpen}
        message="Would you like to receive a copy of this receipt via email?"
        onCancel={() =>
          setEmailReceipt({
            ...emailReceiptModal,
            isOpen: false
          })
        }
        onConfirm={() => {
          setEmailReceipt({
            ...emailReceiptModal,
            loading: true
          });
          sendEcomStoreCartOrderReceipt(
            ecomStoreCart.fkEcomStore,
            { pkEcomStoreCart: ecomStoreCart.pkEcomStoreCart },
            () => {
              triggerNotification('Email receipt sent!', 'success');
              setEmailReceipt({
                ...emailReceiptModal,
                isOpen: false,
                loading: false
              });
            },
            (error) => {
              triggerNotification(error);
              setEmailReceipt({
                ...emailReceiptModal,
                loading: false
              });
            }
          );
        }}
        title="Email copy of receipt?"
      />
    </AppStack>
  );
};

WsAccountOrderDetails.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string
};

export default WsAccountOrderDetails;
