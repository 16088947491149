import React from 'react';
import PropTypes from 'prop-types';
import BulkEditDataColumn from './BulkEditDataColumn';

const isSameAsBefore = (prevProps, nextProps) =>
  prevProps.displayedFields.length === nextProps.displayedFields.length &&
  prevProps.row.columns.length === nextProps.row.columns.length &&
  prevProps.row.selected === nextProps.row.selected &&
  prevProps.row.columns.every(
    (c, index) => c.value === nextProps.row.columns[index].value
  ) &&
  (!prevProps.row.subrows ||
    (prevProps.row.subrows.length === nextProps.row.subrows.length &&
      prevProps.row.subrows.every(
        (sr, index) =>
          sr.selected === nextProps.row.subrows[index].selected &&
          sr.columns.every(
            (c, i) => c.value === nextProps.row.subrows[index].columns[i].value
          )
      )));

const BulkEditDataRow = React.memo(
  ({
    row,
    displayedFields,
    dataFields,
    optionsState,
    onlySubrows,
    onRowChange,
    onSubrowChange,
    onSelectRow,
    onSelectSubrow
  }) => (
    <>
      {!onlySubrows &&
        row.columns
          .filter((f, index) => dataFields[index]?.displayed)
          .map((c, index) => (
            <BulkEditDataColumn
              key={c.key}
              altValue={c.altValue}
              disabled={c.disabled}
              disabledMessage={
                displayedFields[index].getDisabledMessage
                  ? displayedFields[index].getDisabledMessage(row.columns)
                  : null
              }
              errorMessage={
                displayedFields[index].getErrorMessage
                  ? displayedFields[index].getErrorMessage(row.columns)
                  : null
              }
              fieldData={displayedFields[index]}
              onChange={(value) => {
                onRowChange(row.key, c.key, value, c.adjustSubrows);
              }}
              onSelect={(selected) => onSelectRow(row.key, selected)}
              optionsState={optionsState}
              prefix={c.prefix}
              selected={displayedFields[index].firstPosition && row.selected}
              suffix={c.suffix}
              value={c.value ?? (c.getValue ? c.getValue(row.columns) : '')}
            />
          ))}
      {row.subrows &&
        row.subrows.map((sr) =>
          sr.columns
            .filter((f, index) => dataFields[index]?.displayed)
            .map((sc, index) => (
              <BulkEditDataColumn
                key={sc.key}
                altValue={sc.altValue}
                disabled={sc.disabled}
                disabledMessage={
                  displayedFields[index].getDisabledMessage
                    ? displayedFields[index].getDisabledMessage(
                        row.columns,
                        sr.columns
                      )
                    : null
                }
                errorMessage={
                  displayedFields[index].getErrorMessage
                    ? displayedFields[index].getErrorMessage(
                        row.columns,
                        sr.columns
                      )
                    : null
                }
                fieldData={displayedFields[index]}
                onChange={(value) => {
                  onSubrowChange(row.key, sr.key, sc.key, value);
                }}
                onSelect={() => onSelectSubrow(row.key, sr.key)}
                optionsState={optionsState}
                paddingLeft={!onlySubrows && index === 0 ? 70 : null}
                prefix={sc.getPrefix ? sc.getPrefix(sr.columns) : null}
                selected={sr.selected && displayedFields[index].firstPosition}
                suffix={sc.getSuffix ? sc.getSuffix(sr.columns) : null}
                value={sc.value ?? (sc.getValue ? sc.getValue(sr.columns) : '')}
              />
            ))
        )}
    </>
  ),
  isSameAsBefore
);

BulkEditDataRow.propTypes = {
  dataFields: PropTypes.array,
  displayedFields: PropTypes.array,
  onRowChange: PropTypes.func,
  onSelectRow: PropTypes.func,
  onSelectSubrow: PropTypes.func,
  onSubrowChange: PropTypes.func,
  onlySubrows: PropTypes.bool,
  optionsState: PropTypes.object,
  row: PropTypes.object
};

export default BulkEditDataRow;
