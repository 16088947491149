import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import WebstoreMenusView from './WebstoreMenusView';
import WebstoreThemesView from './WebstoreThemesView';
import WebstoreThemeView from './WebstoreThemeView';
import WebstorePagesView from './WebstorePagesView';
import WebstoreBlogsView from './WebstoreBlogPostsView';

const WebstoreViews = () => (
  <Routes>
    <Route
      element={<WebstoreThemeView />}
      path="/themes/:ecomStoreThemeUuid/*"
    />
    <Route element={<WebstoreThemesView />} path="/themes/*" />
    <Route element={<WebstoreBlogsView />} path="/blogs/*" />
    <Route element={<WebstorePagesView />} path="/pages/*" />
    <Route element={<WebstoreMenusView />} path="/menus/*" />
    <Route
      element={<Navigate replace to="/merchant/webstore/themes" />}
      path="*"
    />
  </Routes>
);

export default WebstoreViews;
