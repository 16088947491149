/* eslint-disable import/no-cycle */
import { LayoutNavbar, TextRecognition } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsAnnouncementBar from './WsAnnouncementBar';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';

const configNameValues = {
  displayType: 'displayType',
  colorScheme: 'colorScheme',
  autoRotate: 'autoRotate',
  pauseOnHover: 'pauseOnHover',
  reverseScroll: 'reverseScroll',
  changeEvery: 'changeEvery',
  scrollSpeed: 'scrollSpeed'
};

const configDefaultValues = {
  [configNameValues.displayType]: 'carousel',
  [configNameValues.colorScheme]: '2',
  [configNameValues.autoRotate]: 'true',
  [configNameValues.pauseOnHover]: 'true',
  [configNameValues.reverseScroll]: 'false',
  [configNameValues.changeEvery]: '5',
  [configNameValues.scrollSpeed]: '10'
};

const ANNOUNCEMENT_DISPLAY_TYPE_ENUM = {
  CAROUSEL: 'carousel',
  SCROLLING: 'scrolling'
};

const configOptions = {
  [configNameValues.displayType]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Display type',
    options: [
      { value: ANNOUNCEMENT_DISPLAY_TYPE_ENUM.CAROUSEL, label: 'Carousel' },
      { value: ANNOUNCEMENT_DISPLAY_TYPE_ENUM.SCROLLING, label: 'Scrolling' }
    ]
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.autoRotate]: {
    label: 'Auto-rotate announcements',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    isVisible: (config) =>
      config.displayType === ANNOUNCEMENT_DISPLAY_TYPE_ENUM.CAROUSEL
  },
  [configNameValues.pauseOnHover]: {
    label: 'Pause on hover',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    isVisible: (config) =>
      config.displayType === ANNOUNCEMENT_DISPLAY_TYPE_ENUM.SCROLLING ||
      (config.displayType === ANNOUNCEMENT_DISPLAY_TYPE_ENUM.CAROUSEL &&
        config.autoRotate === 'true')
  },
  [configNameValues.reverseScroll]: {
    label: 'Reverse scroll animation',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    isVisible: (config) =>
      config.displayType === ANNOUNCEMENT_DISPLAY_TYPE_ENUM.SCROLLING
  },
  [configNameValues.changeEvery]: {
    label: 'Change every',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 3,
    max: 10,
    unit: 's',
    isVisible: (config) =>
      config.displayType === ANNOUNCEMENT_DISPLAY_TYPE_ENUM.CAROUSEL &&
      config.autoRotate === 'true'
  },
  [configNameValues.scrollSpeed]: {
    label: 'Scroll speed',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 10,
    max: 50,
    unit: 's',
    isVisible: (config) =>
      config.displayType === ANNOUNCEMENT_DISPLAY_TYPE_ENUM.SCROLLING
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.ANNOUNCEMENT_BAR,
  title: 'Announcement bar',
  icon: LayoutNavbar,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.HEADER],
  Component: WsAnnouncementBar,
  configOptions,
  maxItemCount: null,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT_LINK
      ],
      title: 'Announcement',
      icon: TextRecognition,
      defaultCount: 1,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  ANNOUNCEMENT_DISPLAY_TYPE_ENUM
};
