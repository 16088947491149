import React from 'react';
import PropTypes from 'prop-types';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from './sectionBlockConfig';

const WsBlock = ({
  fkEcomStoreThemeSectionBlockType,
  config,
  colorScheme,
  ...rest
}) => {
  const themeSectionBlock =
    ECOM_WEBSTORE_SECTION_BLOCKS[fkEcomStoreThemeSectionBlockType];
  const WsBlockComponent = themeSectionBlock?.Component;

  return WsBlockComponent ? (
    <WsBlockComponent
      colorScheme={colorScheme}
      config={config}
      displayFunctions={themeSectionBlock.displayFunctions}
      {...rest}
    />
  ) : null;
};

WsBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  fkEcomStoreThemeSectionBlockType: PropTypes.number
};

export default WsBlock;
