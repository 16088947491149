/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Image, NumberFormatter, Skeleton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import { Shirt } from 'tabler-icons-react';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppFlexbox from '../../../../common/AppFlexbox';
import { useWebstoreUserHelper } from '../../../../../helpers/webstoreHelper';
import { generateColorVariants } from '../../../../../helpers/styles';
import { useCartController } from '../../../../../helpers/webstoreCheckoutHelper';
import { triggerNotification } from '../../../../../helpers/notification';

const WsProductCard = ({
  ecomStoreProduct,
  showSecondImageOnHover,
  showVendorName,
  colorScheme,
  portraitRatio,
  isQuickAddEnabled,
  baseStoreUrl,
  onNavigate,
  noProduct
}) => {
  const { viewProductOverviewModal } = useWebstoreUserHelper();
  const { addStoreProducts } = useCartController();
  const { hovered, ref: hoverRef } = useHover();
  const [loading, setLoading] = useState(false);
  const { ecomVendorProduct } = ecomStoreProduct ?? {};
  const images =
    ecomVendorProduct?.previewImages ??
    ecomVendorProduct?.ecomVendorProductMedia
      .sort((a, b) => a.sort - b.sort)
      .map((m) => m.src) ??
    [];
  const secondImage = images[1];
  const showSecondImage = !!secondImage && hovered && showSecondImageOnHover;
  const lowestPriceVariant = ecomStoreProduct?.ecomStoreProductVariants.reduce(
    (acc, variant) => {
      if (variant.price < acc.price) {
        return variant;
      }
      return acc;
    }
  );
  const highestPriceVariant = ecomStoreProduct?.ecomStoreProductVariants.reduce(
    (acc, variant) => {
      if (variant.price > acc.price) {
        return variant;
      }
      return acc;
    }
  );

  const hasAvailableQuantity = ecomStoreProduct?.ecomStoreProductVariants.some(
    (variant) =>
      variant.ecomVendorProductVariant.quantity == null ||
      variant.ecomVendorProductVariant.quantity > 0
  );
  const textColorVariants = generateColorVariants(colorScheme.textColor, 100);

  const productUnavailable =
    ecomStoreProduct?.ecomVendorProduct.deleted ||
    ecomStoreProduct?.deleted ||
    ecomStoreProduct?.ecomStoreProductVariants.every(
      (v) => v.deleted || v.ecomVendorProductVariant.deleted || v.unavailable
    );

  const onAddProductToCart = () => {
    setLoading(true);
    addStoreProducts(
      ecomStoreProduct.fkEcomStore,
      {
        ecomStoreCartProducts: [
          {
            uuid:
              ecomStoreProduct.ecomStoreProductVariants[0]
                .ecomVendorProductVariant.uuid,
            count: 1
          }
        ]
      },
      () => {
        setLoading(false);
      },
      (e) => {
        triggerNotification(e);
        setLoading(false);
      }
    );
  };

  return (
    <AppStack
      ref={hoverRef}
      component={ecomStoreProduct ? Link : null}
      onClick={ecomStoreProduct ? onNavigate : null}
      style={{
        gap: 10,
        flex: 1,
        padding: 0,
        height: '100%',
        cursor: ecomStoreProduct
          ? 'pointer'
          : noProduct
          ? 'not-allowed'
          : 'unset',
        textDecoration: 'none',
        color: colorScheme.textColor
      }}
      to={
        ecomStoreProduct
          ? `${baseStoreUrl}/products/${ecomStoreProduct?.urlHandle}`
          : null
      }
    >
      {ecomStoreProduct ? (
        <>
          <AppStack
            style={{
              border: !images[0] ? 'solid 1px lightgrey' : 'none',
              position: 'relative',
              width: '100%',
              backgroundColor: '#f2f2f2',
              overflow: 'hidden',
              aspectRatio: portraitRatio ? '3 / 4' : '1 / 1'
            }}
          >
            <Image
              fit="cover"
              h="100%"
              loading="lazy"
              src={images[0]}
              style={{
                opacity: showSecondImage ? 0 : 1,
                transition: 'opacity 0.4s ease-in-out',
                position: 'absolute'
              }}
              w="100%"
            />
            <Image
              fit="cover"
              h="100%"
              loading="lazy"
              src={secondImage}
              style={{
                opacity: showSecondImage ? 1 : 0,
                transition: 'opacity 0.4s ease-in-out',
                position: 'absolute'
              }}
              w="100%"
            />

            <Badge
              color="green"
              size="md"
              style={{ position: 'absolute', right: 8, top: 8 }}
              variant="filled"
            >
              Free Shipping
            </Badge>
          </AppStack>
          <AppStack style={{ flex: 1, gap: 0 }}>
            <AppStack style={{ flex: 1, gap: 0 }}>
              <AppText
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  textDecoration: hovered ? 'underline' : 'unset'
                }}
              >
                {ecomVendorProduct.name}
              </AppText>{' '}
              {showVendorName && (
                <AppText
                  style={{
                    fontSize: 12,
                    color: textColorVariants.lighter
                  }}
                >
                  {ecomVendorProduct.vendorName}
                </AppText>
              )}
            </AppStack>

            <AppFlexbox style={{ gap: 12, alignItems: 'end' }}>
              {lowestPriceVariant.compareAtPrice > 0 && (
                <AppText
                  style={{
                    fontSize: 16,
                    fontWeight: 450,
                    color: textColorVariants.lighter,
                    textDecoration: 'line-through'
                  }}
                >
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    suffix=" CAD"
                    thousandSeparator
                    value={lowestPriceVariant.compareAtPrice / 100}
                  />
                </AppText>
              )}

              <AppText style={{ fontSize: 16, fontWeight: 450 }}>
                {lowestPriceVariant.price !== highestPriceVariant.price
                  ? 'From '
                  : ''}
                <NumberFormatter
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  suffix=" CAD"
                  thousandSeparator
                  value={lowestPriceVariant.price / 100}
                />
              </AppText>
            </AppFlexbox>
          </AppStack>
          {isQuickAddEnabled && (
            <AppStack>
              <Button
                color={colorScheme.outlineButtonColor}
                disabled={!hasAvailableQuantity || productUnavailable}
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (
                    ecomStoreProduct.ecomStoreProductVariants.length > 1 ||
                    ecomStoreProduct.ecomVendorProductInputCount > 0
                  ) {
                    setLoading(true);
                    viewProductOverviewModal(
                      ecomStoreProduct.fkEcomStore,
                      { urlHandle: ecomStoreProduct.urlHandle },
                      () => {
                        setLoading(false);
                      }
                    );
                  }
                  else {
                    onAddProductToCart();
                  }
                }}
                radius={0}
                size="md"
                styles={{ label: { textDecoration: 'none' } }}
                variant="outline"
              >
                {productUnavailable
                  ? 'Unavailable'
                  : !hasAvailableQuantity
                  ? 'Sold out'
                  : ecomStoreProduct.ecomStoreProductVariants.length > 1 ||
                    ecomStoreProduct.ecomVendorProductInputCount > 0
                  ? 'Choose options'
                  : 'Add to cart'}
              </Button>
            </AppStack>
          )}
        </>
      ) : noProduct ? (
        <>
          <AppStack
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              backgroundColor: '#f2f2f2',
              overflow: 'hidden',
              aspectRatio: portraitRatio ? '3 / 4' : '1 / 1',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Shirt color="#444" size="65%" />
          </AppStack>
          <AppStack style={{ flex: 1, gap: 0 }}>
            <AppStack style={{ flex: 1, gap: 0 }}>
              <AppText
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  textDecoration: hovered ? 'underline' : 'unset'
                }}
              >
                Product Name
              </AppText>
            </AppStack>

            <AppFlexbox style={{ gap: 12, alignItems: 'end' }}>
              <AppText
                style={{
                  fontSize: 16,
                  fontWeight: 450
                }}
              >
                <NumberFormatter
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  suffix=" CAD"
                  thousandSeparator
                  value={0}
                />
              </AppText>
            </AppFlexbox>
          </AppStack>
        </>
      ) : (
        <>
          <AppStack
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              backgroundColor: '#f2f2f2',
              overflow: 'hidden',
              aspectRatio: portraitRatio ? '3 / 4' : '1 / 1'
            }}
          >
            <Skeleton height="100%" width="100%" />
          </AppStack>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={18} width="70%" />
            {showVendorName && <Skeleton height={16} width="45%" />}
            <Skeleton height={18} width="35%" />
          </AppStack>
          {isQuickAddEnabled && <Skeleton height={42} width="100%" />}
        </>
      )}
    </AppStack>
  );
};

WsProductCard.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorScheme: PropTypes.object,
  ecomStoreProduct: PropTypes.object,
  isQuickAddEnabled: PropTypes.bool,
  noProduct: PropTypes.bool,
  onNavigate: PropTypes.func,
  portraitRatio: PropTypes.bool,
  productNotFound: PropTypes.bool,
  showSecondImageOnHover: PropTypes.bool,
  showVendorName: PropTypes.bool
};

export default WsProductCard;
