import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Table } from '@mantine/core';
import { ArrowLeft, ArrowRight } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { singularPluralFormat } from '../../../helpers/format';
import { triggerNotification } from '../../../helpers/notification';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import CustomNumberInput from '../../common/CustomNumberInput';

const ADJUST_TYPES = {
  ADD: 'add',
  SET: 'set'
};

const MAX_PAGE_LENGTH = 10;

const VendorInventoryAdjustQuantityModal = ({
  isOpen,
  onClose,
  ecomVendorProductVariants,
  onComplete
}) => {
  const { state: authState } = useContext(AuthContext);
  const { updateVendorProducts } = useContext(VendorContext);
  const [formState, setFormState] = useState({
    adjustType: ADJUST_TYPES.ADD,
    quantity: 0,
    pageIndex: 1,
    loading: false
  });
  const maxPageLength = Math.ceil(
    ecomVendorProductVariants.length / MAX_PAGE_LENGTH
  );

  const variantProducts =
    ecomVendorProductVariants?.reduce(
      (r, c) =>
        !r.find((f) => f.uuid === c.productUuid)
          ? [...r, { uuid: c.productUuid }]
          : r,
      []
    ) ?? [];

  useEffect(() => {
    if (isOpen) {
      setFormState({
        adjustType: ADJUST_TYPES.ADD,
        quantity: 0,
        pageIndex: 1,
        loading: false
      });
    }
  }, [isOpen]);

  const onSaveQuantity = () => {
    setFormState({ ...formState, loading: true });
    updateVendorProducts(
      authState.pkEcomVendor,
      {
        ecomVendorProducts: variantProducts.map((p) => ({
          uuid: p.uuid,
          ecomVendorProductVariants: ecomVendorProductVariants
            .filter((v) => v.productUuid === p.uuid)
            .map((v) => ({
              ...v,
              quantity:
                formState.adjustType === ADJUST_TYPES.SET
                  ? formState.quantity
                  : v.quantity + formState.quantity
            }))
        }))
      },
      onComplete,
      () => {
        triggerNotification('Error updating inventory', 'error');
        setFormState({ ...formState, loading: false });
      }
    );
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        isSubmitDisabled: formState.quantity === 0,
        onCancel: onClose,
        onSubmit: onSaveQuantity,
        submitTitle: 'Save',
        padding: 0
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Update variant quantities"
    >
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          style={{
            gap: 5,
            padding: 16,
            alignItems: 'center'
          }}
        >
          <AppFlexbox
            style={{
              padding: 2,
              gap: 2,
              backgroundColor: '#f0f0f0',
              border: 'solid 1px #ced4da',
              borderRadius: 5
            }}
          >
            <Button
              color={
                formState.adjustType === ADJUST_TYPES.ADD ? 'blue' : 'dark'
              }
              disabled={formState.loading}
              onClick={() => {
                setFormState({ ...formState, adjustType: ADJUST_TYPES.ADD });
              }}
              size="sm"
              style={{ height: 30 }}
              variant={
                formState.adjustType === ADJUST_TYPES.ADD ? 'filled' : 'subtle'
              }
            >
              Add
            </Button>
            <Button
              color={
                formState.adjustType === ADJUST_TYPES.SET ? 'blue' : 'dark'
              }
              disabled={formState.loading}
              onClick={() => {
                setFormState({ ...formState, adjustType: ADJUST_TYPES.SET });
              }}
              size="sm"
              style={{ height: 30 }}
              variant={
                formState.adjustType === ADJUST_TYPES.SET ? 'filled' : 'subtle'
              }
            >
              Set
            </Button>
          </AppFlexbox>
          <CustomNumberInput
            allowNegative={formState.adjustType === ADJUST_TYPES.ADD}
            decimalScale={0}
            disabled={formState.loading}
            fixedDecimalScale
            onChange={(value) =>
              setFormState({ ...formState, quantity: value })
            }
            size="sm"
            value={formState.quantity}
          />
        </AppFlexbox>

        <AppStack style={{ gap: 0 }}>
          <Divider />
          <AppFlexbox
            style={{
              padding: '8px 16px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 16 }}>
              {singularPluralFormat(
                ecomVendorProductVariants.length,
                'variant',
                'variants'
              )}{' '}
              will be updated.
            </AppText>

            {maxPageLength > 1 && (
              <AppFlexbox style={{ gap: 8 }}>
                <AppText style={{ fontSize: 16 }}>
                  ({formState.pageIndex}/{maxPageLength})
                </AppText>
                <Button.Group>
                  <Button
                    color="dark"
                    onClick={() => {
                      const prevIndex = formState.pageIndex - 1;
                      setFormState({
                        ...formState,
                        pageIndex: prevIndex <= 0 ? maxPageLength : prevIndex
                      });
                    }}
                    size="compact-sm"
                    variant="outline"
                  >
                    <ArrowLeft size={18} />
                  </Button>
                  <Button
                    color="dark"
                    onClick={() => {
                      const nextIndex = formState.pageIndex + 1;
                      setFormState({
                        ...formState,
                        pageIndex: nextIndex > maxPageLength ? 1 : nextIndex
                      });
                    }}
                    size="compact-sm"
                    variant="outline"
                  >
                    <ArrowRight size={18} />
                  </Button>
                </Button.Group>
              </AppFlexbox>
            )}
          </AppFlexbox>
          <Table
            horizontalSpacing={16}
            style={{ borderTop: 'solid 1px  #dee2e6' }}
            verticalSpacing={8}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: '100%' }}>
                  <AppText style={{ fontSize: 14 }}>Variant</AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ fontSize: 14 }}>Available</AppText>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {ecomVendorProductVariants
                .slice(
                  -MAX_PAGE_LENGTH + formState.pageIndex * MAX_PAGE_LENGTH,
                  formState.pageIndex * MAX_PAGE_LENGTH
                )
                .map((v) => (
                  <Table.Tr key={v.pkEcomVendorProductVariant}>
                    <Table.Td>
                      <AppStack style={{ gap: 0 }}>
                        <AppText style={{ fontSize: 14 }}>
                          {v.productName} -{' '}
                          {v.ecomVendorProductVariantOptions
                            .map((op) => op.value)
                            .join(' / ')}
                        </AppText>
                        <AppText style={{ fontSize: 13, color: '#666' }}>
                          {v.sku}
                        </AppText>
                      </AppStack>
                    </Table.Td>
                    <Table.Td>
                      <AppFlexbox
                        style={{
                          gap: 5,
                          alignItems: 'center',
                          justifyContent: 'end'
                        }}
                      >
                        <AppText style={{ fontSize: 14 }}>{v.quantity}</AppText>
                        {!!formState.quantity && (
                          <>
                            <ArrowRight color="#666" size={16} />
                            <AppStack
                              style={{
                                padding: '0px 5px',
                                backgroundColor: '#1e90ff'
                              }}
                            >
                              <AppText
                                style={{
                                  fontSize: 14,
                                  color: '#FFF'
                                }}
                              >
                                {formState.adjustType === ADJUST_TYPES.SET
                                  ? formState.quantity < 0
                                    ? 0
                                    : formState.quantity
                                  : v.quantity + formState.quantity < 0
                                  ? 0
                                  : v.quantity + formState.quantity}
                              </AppText>
                            </AppStack>
                          </>
                        )}
                      </AppFlexbox>
                    </Table.Td>
                  </Table.Tr>
                ))}
            </Table.Tbody>
          </Table>
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

VendorInventoryAdjustQuantityModal.propTypes = {
  ecomVendorProductVariants: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func
};

export default VendorInventoryAdjustQuantityModal;
