import { createTheme } from '@mantine/core';

const breakpoints = {
  xxs: '25em', // 400px
  xs: '31.25em', // 500px
  xsm: '35.9375em', // 575px
  sm: '50em', // 800px
  md: '62.5em', // 1000px
  lg: '83.75em', // 1340px
  xlg: '100em', // 1600px
  xl: '120em' // 1920px
};

const colors = {
  blue: [
    '#E7F5FF',
    '#D0EBFF',
    '#A5D8FF',
    '#74C0FC',
    '#4DABF7',
    '#339AF0',
    'dodgerblue',
    '#1C7ED6',
    '#1971C2',
    '#1864AB'
  ],
  red: [
    '#FFF5F5',
    '#FFE3E3',
    '#FFC9C9',
    '#FFA8A8',
    '#FF8787',
    '#FF6B6B',
    '#FA5252',
    '#F03E3E',
    '#E03131',
    '#C92A2A'
  ],
  green: [
    '#EBFBEE',
    '#D3F9D8',
    '#B2F2BB',
    '#8CE99A',
    '#69DB7C',
    '#51CF66',
    '#40C057',
    '#37B24D',
    '#2F9E44',
    '#2B8A3E'
  ],
  lime: [
    '#cadf9a',
    '#c6dc92',
    '#c1d989',
    '#bdd781',
    '#b9d479',
    '#b4d170',
    '#b0ce68',
    '#abcc5f',
    '#a7c957',
    '#96b54e'
  ],
  yellow: [
    '#f8da6c',
    '#f7d760',
    '#f7d454',
    '#f6d147',
    '#f5ce3b',
    '#f5cb2f',
    '#f4c822',
    '#f4c516',
    '#f3c20a',
    '#e7b80a',
    '#dbaf09'
  ]
};

const colorScheme = 'light';

export const theme = createTheme({ breakpoints, colors, colorScheme });
