import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminStoreDetailsView from './AdminStoreDetailsView';
import AdminStoresTableView from './AdminStoresTableView';

const AdminStoresView = () => (
  <Routes>
    <Route element={<AdminStoreDetailsView />} path="/:pkEcomStore/*" />
    <Route element={<AdminStoresTableView />} path="/" />
    <Route element={<Navigate replace to="/merchant/orders" />} path="*" />
  </Routes>
);

export default AdminStoresView;
