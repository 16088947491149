import { Article } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM
} from '../../../../../config/constants';
import WsBlogPosts from './WsBlogPosts';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.BLOG_POSTS,
  title: 'Blog posts',
  icon: Article,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsBlogPosts,
  configOptions,
  maxItemCount: null,
  cannotCreate: true,
  blocks: [],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
