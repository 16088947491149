import { Versions } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsVariantPickerBlock from './WsVariantPickerBlock';

const VARIANT_STYLE_ENUM = {
  DROPDOWN: 'dropdown',
  PILLS: 'pills',
  BUTTON_GROUP: 'buttonGroup'
};

const configNameValues = { variantStyle: 'variantStyle' };

const configDefaultValues = {
  [configNameValues.variantStyle]: VARIANT_STYLE_ENUM.DROPDOWN
};

const configOptions = {
  [configNameValues.variantStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop content alignment',
    options: [
      {
        value: VARIANT_STYLE_ENUM.DROPDOWN,
        label: 'Dropdown'
      },
      {
        value: VARIANT_STYLE_ENUM.PILLS,
        label: 'Pills'
      },
      {
        value: VARIANT_STYLE_ENUM.BUTTON_GROUP,
        label: 'Buttons'
      }
    ]
  }
};

const config = {
  title: 'Variant picker',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VARIANT_PICKER,
  icon: Versions,
  Component: WsVariantPickerBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  VARIANT_STYLE_ENUM
};
