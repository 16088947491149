import { Message } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsRowContentBlock from './WsRowContentBlock';

const configNameValues = {
  image: 'image',
  caption: 'caption',
  heading: 'heading',
  description: 'description',
  buttonLabel: 'buttonLabel',
  buttonLink: 'link',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  image: '',
  caption: 'caption',
  heading: 'Column',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur accumsan semper odio quis lacinia. Pellentesque sagittis nibh id quam ultricies, eget sagittis lacus congue. Ut ligula augue, vehicula et porttitor vel, malesuada nec lorem.',
  buttonLabel: 'Button label',
  buttonLink: '',
  colorScheme: '1'
};

const configOptions = {
  [configNameValues.image]: {
    label: 'Image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  },
  [configNameValues.caption]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Caption',
    controlType: 'text'
  },
  [configNameValues.heading]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Heading',
    controlType: 'text'
  },
  [configNameValues.description]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Description',
    controlType: 'text'
  },
  [configNameValues.buttonLabel]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Button label',
    controlType: 'text'
  },
  [configNameValues.buttonLink]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Button link',
    controlType: 'text'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  title: 'Row',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ROW_CONTENT,
  icon: Message,
  Component: WsRowContentBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
