import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsCollectionGrid from './WsCollectionGrid';

const QUICK_ADD_STYLE_ENUM = {
  NONE: 'NONE',
  STANDARD: 'STANDARD',
  BULK: 'BULK'
};

const IMAGE_DISPLAY_ENUM = {
  PORTRAIT: 'PORTRAIT',
  SQUARE: 'SQUARE'
};

const SORT_BY_ENUM = {
  NAME: 'name',
  NAME_DESC: 'name_desc',
  DATE: 'price',
  DATE_DESC: 'price_desc',
  COUNT: 'count',
  COUNT_DESC: 'count_desc'
};

const configNameValues = {
  headingText: 'headingText',
  sortBy: 'sortBy',
  desktopColumnCount: 'desktopColumnCount',
  colorScheme: 'colorScheme',
  imageRatio: 'imageRatio',
  mobileColumnCount: 'mobileColumnCount'
};

const configDefaultValues = {
  [configNameValues.headingText]: 'Collections',
  [configNameValues.sortBy]: 'name_desc',
  [configNameValues.desktopColumnCount]: '4',
  [configNameValues.colorScheme]: '1',
  [configNameValues.imageRatio]: IMAGE_DISPLAY_ENUM.SQUARE,
  [configNameValues.mobileColumnCount]: '2'
};

const configOptions = {
  [configNameValues.headingText]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    isTextArea: true,
    label: 'Heading'
  },
  [configNameValues.desktopColumnCount]: {
    label: 'Desktop column count',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 1,
    max: 6,
    unit: ''
  },
  [configNameValues.sortBy]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Sort by',
    options: [
      {
        value: SORT_BY_ENUM.NAME,
        label: 'Alphabetically, A-Z'
      },
      {
        value: SORT_BY_ENUM.NAME_DESC,
        label: 'Alphabetically, Z-A'
      },
      {
        value: SORT_BY_ENUM.DATE,
        label: 'Date, new to old'
      },
      {
        value: SORT_BY_ENUM.DATE_DESC,
        label: 'Price, old to new'
      },
      {
        value: SORT_BY_ENUM.COUNT_DESC,
        label: 'Product count, high to low'
      },
      {
        value: SORT_BY_ENUM.COUNT,
        label: 'Product count, low to high'
      }
    ]
  },
  [configNameValues.imageRatio]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Image ratio',
    options: [
      {
        value: IMAGE_DISPLAY_ENUM.PORTRAIT,
        label: 'Portrait'
      },
      {
        value: IMAGE_DISPLAY_ENUM.SQUARE,
        label: 'Square'
      }
    ]
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.mobileColumnCount]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Mobile column count',
    category: 'Mobile layout',
    options: [
      {
        value: '1',
        label: '1 column'
      },
      {
        value: '2',
        label: '2 columns'
      }
    ]
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLECTION_GRID,
  title: 'Collection grid',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsCollectionGrid,
  configOptions,
  isPermanent: true,
  cannotCreate: true,
  maxItemCount: null,
  blocks: [],
  defaultConfig: configDefaultValues,
  onFetchDataConfig: () => ({
    type: 'ecomStoreCollections',
    entityId: null,
    filter: {}
  })
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  IMAGE_DISPLAY_ENUM,
  QUICK_ADD_STYLE_ENUM,
  SORT_BY_ENUM
};
