import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import { ArrowLeft } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import {
  PRODUCT_CATEGORY_LIST,
  SPECIAL_PRODUCT_CATEGORIES
} from '../../../config/productCategories';
import VendorCatalogSearchFilter from './VendorCatalogSearchFilter';
import VendorCatalogProductSearchList from './VendorCatalogProductSearchList';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';

const VendorCatalogCategoryView = ({ loading, vendorPreview, baseUrl }) => {
  const {
    isLargeMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isLargeDesktopOrSmaller
  } = useMediaQueryIndex();
  const { categoryName } = useParams();
  const category = PRODUCT_CATEGORY_LIST.find(
    (c) =>
      c.name.toLowerCase().replace(' ', '-') === categoryName?.toLowerCase()
  );
  const specialCategory = SPECIAL_PRODUCT_CATEGORIES.find(
    (c) => c.value === categoryName?.toLowerCase()
  );
  const categoryInfo = specialCategory || category;

  return (
    (category || specialCategory) && (
      <AppStack
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 1600,
          margin: isLargeDesktopOrSmaller ? 0 : '16px 0px 70px 0px',
          gap: 0,
          alignSelf: 'center'
        }}
      >
        <AppCard
          radius="md"
          shadow="xl"
          style={{
            background: categoryInfo.gradientColor,
            borderRadius: 0,
            padding: 16,
            color: '#FFF',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 200,
            position: 'relative'
          }}
        >
          <AppStack
            style={{ position: 'absolute', left: 0, top: 0, padding: 16 }}
          >
            <Button
              color="dark"
              component={Link}
              leftSection={<ArrowLeft size={18} />}
              to={baseUrl}
              visibleFrom="sm"
            >
              Back to search
            </Button>
          </AppStack>

          <AppStack
            style={{
              flex: 1,
              maxWidth: 500,
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0
            }}
          >
            <AppStack style={{}}>
              <categoryInfo.Icon size={80} />
            </AppStack>
            <AppText style={{ fontSize: 32, fontWeight: 700 }}>
              {categoryInfo.name}
            </AppText>
            <AppText
              style={{
                fontSize: 14,
                fontWeight: 500,
                textAlign: 'center'
              }}
            >
              {categoryInfo.description}
            </AppText>
          </AppStack>
        </AppCard>

        <AppStack
          style={{
            gap: 16,
            padding: isTabletOrSmaller ? 16 : '0px 16px 16px 16px'
          }}
        >
          <AppFlexbox style={{ flex: 1 }}>
            <Button
              color="dark"
              component={Link}
              hiddenFrom="sm"
              leftSection={<ArrowLeft size={18} />}
              style={{ flex: 1 }}
              to={baseUrl}
              variant="outline"
            >
              Back to search
            </Button>
          </AppFlexbox>
          <VendorCatalogSearchFilter fkEcomCategory={category?.value} />
          <VendorCatalogProductSearchList
            baseUrl={baseUrl}
            fkEcomCategory={category?.value}
            fromLocation="categories"
            fromResource={categoryName}
            loading={loading}
            title="Products"
            vendorPreview={vendorPreview}
            {...specialCategory?.searchProps}
          />
        </AppStack>
      </AppStack>
    )
  );
};

VendorCatalogCategoryView.propTypes = {
  baseUrl: PropTypes.string,
  loading: PropTypes.bool,
  vendorPreview: PropTypes.bool
};

export default VendorCatalogCategoryView;
