import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Check, Selector } from 'tabler-icons-react';
import { Menu, ScrollArea } from '@mantine/core';
import AppMenu from './AppMenu';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import AppStack from './AppStack';

const CustomMultiSelect = ({
  data,
  value,
  onChange,
  controlProps,
  variant
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <AppMenu
      closeOnClickOutside
      control={
        <AppFlexbox
          style={{
            flex: 1,
            height: 36,
            minHeight: 36,
            padding: '0px 12px 0px 8px',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: opened
              ? '1px solid #1e90ff'
              : variant === 'default'
              ? 'solid 1px #dee2e6'
              : 'none'
          }}
          {...controlProps}
        >
          <AppText style={{ fontSize: 14 }}>
            {value[0] &&
              `${data.find((d) => d.value === value[0])?.label} ${
                value.length > 1 ? `+${value.length - 1}` : ''
              }`}
          </AppText>

          <Selector color="#868e96" size={15} />
        </AppFlexbox>
      }
      onChange={setOpened}
      opened={opened}
      position="bottom-start"
      width={250}
    >
      {opened && (
        <ScrollArea.Autosize mah={225} mx="auto" scrollbarSize={5}>
          <AppStack style={{ gap: 0 }}>
            {data?.map((d) => (
              <Menu.Item
                key={d.value}
                closeMenuOnClick={false}
                onClick={() =>
                  onChange(
                    value.find((v) => v === d.value)
                      ? [...value.filter((v) => v !== d.value)]
                      : [...value, d.value]
                  )
                }
              >
                <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                  {value.find((v) => v === d.value) && (
                    <Check color="#868e96" size={15} />
                  )}
                  <AppText style={{ fontSize: 14 }}>{d.label}</AppText>
                </AppFlexbox>
              </Menu.Item>
            ))}
          </AppStack>
        </ScrollArea.Autosize>
      )}
    </AppMenu>
  );
};

CustomMultiSelect.propTypes = {
  controlProps: PropTypes.object,
  data: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchValue: PropTypes.any,
  value: PropTypes.array,
  variant: PropTypes.string
};

export default CustomMultiSelect;
