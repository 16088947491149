import React, { useContext } from 'react';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { setupGuideConfig } from './vendorSetupGuideConfig';
import SetupGuideView from '../../common/SetupGuideView';

const VendorDashboardOverview = () => {
  const { state: authState } = useContext(AuthContext);
  const { state } = useContext(VendorContext);
  const loading =
    !authState.pkEcomVendor ||
    state.ecomVendorSetupSummary.loading ||
    state.ecomVendorSetupSummary.value?.ecomVendor.pkEcomVendor.toString() !==
      authState.pkEcomVendor?.toString();

  return (
    <SetupGuideView
      id={`skippedEcomVendorSetupStepsString-${authState.pkEcomVendor}`}
      loading={loading}
      setupGuideConfig={setupGuideConfig}
      state={state}
    />
  );
};

export default VendorDashboardOverview;
