import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Menu, Skeleton } from '@mantine/core';
import {
  ArrowLeft,
  Copy,
  DotsVertical,
  Template,
  Trash
} from 'tabler-icons-react';
import AppFlexbox from '../../common/AppFlexbox';
import WebstoreBuilderConfigSidebarInput from './WebstoreBuilderConfigSidebarInput';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import {
  ECOM_WEBSTORE_SECTIONS,
  globalConfigOptions as globalSectionConfigOptions
} from './sections/wsSectionConfig';
import { Context as StoreUserContext } from '../../../providers/StoreUserContextProvider';
import AppMenu from '../../common/AppMenu';

const WebstoreBuilderConfigSidebar = ({
  loading,
  ecomStoreTheme,
  editState,
  onUpdateEditState,
  onCopySection,
  onCopySectionBlock,
  onRemoveSection,
  onRemoveSectionBlock,
  onUnselectSection,
  controlState,
  noHeader,
  onAddColorScheme
}) => {
  const { state } = useContext(StoreUserContext);
  const selectedSection = editState.ecomStoreThemeSections?.find(
    (s) => s.key === controlState.selectedSection
  );
  const selectedSectionBlock = selectedSection?.ecomStoreThemeSectionBlocks?.find(
    (i) => i.key === controlState.selectedSectionBlock
  );
  const selectedSectionConfig =
    ECOM_WEBSTORE_SECTIONS[selectedSection?.fkEcomStoreThemeSectionType];
  const selectedSectionBlockConfig = selectedSectionConfig?.blocks?.find(
    (i) => i.type === selectedSectionBlock?.fkEcomStoreThemeSectionBlockType
  );
  const configOptions = selectedSectionBlockConfig?.configOptions ?? {
    ...selectedSectionConfig?.configOptions,
    ...globalSectionConfigOptions
  };

  const currentConfig = selectedSectionBlock
    ? selectedSectionBlock?.config
    : selectedSection?.config;

  const settingCategories = Object.values(configOptions).reduce((r, c) => {
    if (c.category && !r.includes(c.category)) {
      r.push(c.category);
    }
    return r;
  }, []);

  const onHandleConfigChange = (values = []) => {
    if (selectedSectionBlock) {
      for (let index = 0; index < values.length; index += 1) {
        const { key, value } = values[index];
        selectedSectionBlock.config[key] = value;
      }
      onUpdateEditState({
        ...editState,
        ecomStoreThemeSections: [
          ...editState.ecomStoreThemeSections.filter(
            (section) => section.key !== selectedSection.key
          ),
          {
            ...selectedSection,
            ecomStoreThemeSectionBlocks: [
              ...selectedSection.ecomStoreThemeSectionBlocks.filter(
                (block) => block.key !== selectedSectionBlock.key
              ),
              selectedSectionBlock
            ]
          }
        ]
      });
    }
    else {
      for (let index = 0; index < values.length; index += 1) {
        const { key, value } = values[index];
        selectedSection.config[key] = value;
      }
      onUpdateEditState({
        ...editState,
        ecomStoreThemeSections: [
          ...editState.ecomStoreThemeSections.filter(
            (section) => section.key !== selectedSection.key
          ),
          selectedSection
        ]
      });
    }
  };

  const renderOptions = (category = null) =>
    Object.keys(configOptions)
      .filter(
        (key) =>
          ((!category && !configOptions[key].category) ||
            category === configOptions[key].category) &&
          (!configOptions[key]?.isVisible ||
            configOptions[key].isVisible(currentConfig))
      )
      .map((key) => (
        <WebstoreBuilderConfigSidebarInput
          key={key}
          onAddColorScheme={onAddColorScheme}
          {...configOptions[key]}
          entities={{
            ecomStoreThemeColorSchemes: editState.ecomStoreThemeColorSchemes,
            ecomStoreCollections: []
          }}
          fkEcomStore={ecomStoreTheme.fkEcomStore}
          onChange={(value) => {
            if (configOptions[key].getOnChangeValues) {
              onHandleConfigChange(configOptions[key].getOnChangeValues(value));
            }
            else {
              onHandleConfigChange([{ key, value }]);
            }
          }}
          options={
            configOptions[key].getOptions
              ? configOptions[key].getOptions(state, currentConfig)
              : configOptions[key].options
          }
          value={currentConfig[key]}
          {...(configOptions[key].getValueProps
            ? configOptions[key].getValueProps(currentConfig)
            : null)}
        />
      ));

  return !loading && ecomStoreTheme ? (
    selectedSection || selectedSectionBlock ? (
      <AppStack
        style={{
          flex: 1,
          gap: 0,
          overflow: 'hidden'
        }}
      >
        {!noHeader && (
          <>
            <AppFlexbox
              style={{
                gap: 5,
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 10
              }}
            >
              <AppFlexbox style={{ gap: 10 }}>
                {onUnselectSection && (
                  <ActionIcon
                    color="dark"
                    onClick={onUnselectSection}
                    variant="subtle"
                  >
                    <ArrowLeft size={18} />
                  </ActionIcon>
                )}
                <AppText style={{ fontWeight: 700 }}>
                  {selectedSectionBlockConfig?.title ||
                    selectedSectionConfig?.title}
                </AppText>
              </AppFlexbox>

              {!selectedSectionConfig.isPermanent && (
                <AppMenu
                  control={
                    <ActionIcon
                      color="dark"
                      onClick={() => {}}
                      variant="subtle"
                    >
                      <DotsVertical size={18} />
                    </ActionIcon>
                  }
                  styles={{ dropdown: { padding: 0 } }}
                >
                  <Menu.Item
                    disabled={
                      selectedSectionBlock
                        ? (selectedSectionConfig.maxItemCount !== null &&
                            selectedSection.ecomStoreThemeSectionBlocks
                              .length >= selectedSectionConfig.maxItemCount) ||
                          (selectedSectionBlockConfig.maxCount !== null &&
                            selectedSection.ecomStoreThemeSectionBlocks.filter(
                              (i) =>
                                i.fkEcomStoreThemeSectionBlockType ===
                                selectedSectionBlockConfig.type
                            ).length >= selectedSectionBlockConfig.maxCount)
                        : false
                    }
                    leftSection={<Copy size={18} />}
                    onClick={() => {
                      if (selectedSectionBlock) {
                        onCopySectionBlock(
                          selectedSection,
                          selectedSectionBlock
                        );
                      }
                      else {
                        onCopySection(selectedSection);
                      }
                    }}
                  >
                    Duplicate
                  </Menu.Item>
                  <Menu.Item
                    color="red"
                    disabled={
                      selectedSectionConfig.isPermanent ||
                      editState.ecomStoreThemeSections.filter(
                        (s) =>
                          s.fkEcomStoreThemeSectionLocationType ===
                          selectedSection.fkEcomStoreThemeSectionLocationType
                      ).length <= 1
                    }
                    leftSection={<Trash size={18} />}
                    onClick={() => {
                      if (selectedSectionBlock) {
                        onRemoveSectionBlock(
                          selectedSection,
                          selectedSectionBlock
                        );
                      }
                      else {
                        onRemoveSection(selectedSection);
                      }
                    }}
                  >
                    Delete
                  </Menu.Item>
                </AppMenu>
              )}
            </AppFlexbox>
            {((selectedSectionBlockConfig &&
              selectedSectionBlockConfig.description) ||
              (selectedSectionConfig &&
                selectedSectionConfig?.description)) && (
              <AppFlexbox
                style={{
                  padding: 10,
                  paddingTop: 0
                }}
              >
                <AppText style={{ fontSize: 14 }}>
                  {selectedSectionBlockConfig
                    ? selectedSectionBlockConfig.description
                    : selectedSectionConfig?.description}
                </AppText>
              </AppFlexbox>
            )}
          </>
        )}

        <AppStack
          style={{
            flex: 1,
            padding: 10,
            paddingTop: 0,
            overflow: 'auto',
            paddingBottom: 40
          }}
        >
          {Object.keys(currentConfig).length === 0 ? (
            <AppStack style={{ padding: '40px 0px', textAlign: 'center' }}>
              <AppText style={{ fontWeight: 500, color: '#666', fontSize: 14 }}>
                No configuration options available.
              </AppText>
            </AppStack>
          ) : (
            <>
              {renderOptions()}

              {settingCategories.map((category) => (
                <AppStack key={category} style={{ gap: 5 }}>
                  <AppText
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      margin: '20px 0px 8px 0px'
                    }}
                  >
                    {category}
                  </AppText>
                  <AppStack>{renderOptions(category)}</AppStack>
                </AppStack>
              ))}
            </>
          )}
        </AppStack>
      </AppStack>
    ) : (
      <AppStack
        style={{
          padding: 20,
          gap: 10
        }}
      >
        <Template color="#333" size={40} />
        <AppStack style={{ gap: 5 }}>
          <AppText style={{ fontWeight: 500 }}>Edit your theme</AppText>
          <AppText style={{ color: '#666', fontSize: 14 }}>
            Select a section or block to configure.
          </AppText>
        </AppStack>
      </AppStack>
    )
  ) : (
    <AppStack
      style={{
        padding: 20,
        gap: 12
      }}
    >
      <Skeleton height={40} radius="md" width={40} />
      <AppStack style={{ gap: 10 }}>
        <Skeleton height={18} radius="md" width={110} />
        <Skeleton height={16} radius="md" width={230} />
      </AppStack>
    </AppStack>
  );
};

WebstoreBuilderConfigSidebar.propTypes = {
  controlState: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editState: PropTypes.object,
  loading: PropTypes.bool,
  noHeader: PropTypes.bool,
  onAddColorScheme: PropTypes.func,
  onCopySection: PropTypes.func,
  onCopySectionBlock: PropTypes.func,
  onRemoveSection: PropTypes.func,
  onRemoveSectionBlock: PropTypes.func,
  onUnselectSection: PropTypes.func,
  onUpdateEditState: PropTypes.func
};

export default WebstoreBuilderConfigSidebar;
