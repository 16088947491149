import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Alert, Badge, Button, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CircleCheck, InfoCircle } from 'tabler-icons-react';
import { Context as AuthContext } from '../../providers/AuthContextProvider';
import { Context as HelperContext } from '../../providers/HelperContextProvider';
import AppStack from './AppStack';
import AppText from './AppText';
import AppCard from './AppCard';
import AppFlexbox from './AppFlexbox';
import { getFromStorage, saveToStorage } from '../../helpers/storage';
import { useMediaQueryIndex } from '../../helpers/hooks';

const SetupGuideView = ({ loading, state, setupGuideConfig, id }) => {
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = useMediaQueryIndex();
  const hasSetSkippedSteps = useRef(false);
  const skippedStepsString = getFromStorage(id);
  const { state: authState } = useContext(AuthContext);
  const { toggleSetupGuideSidebar } = useContext(HelperContext);
  const [activeStep, setActiveStep] = useState('');
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = setupGuideConfig.sections.flatMap((s) => s.steps);
  const completedSteps = steps.filter(
    (s) => s.isCompleted(state) || skippedSteps.includes(s.value)
  );
  const isGuideCompleted =
    steps.length === completedSteps.length ||
    setupGuideConfig.isCompleted(state);

  useEffect(() => {
    if (!loading && hasSetSkippedSteps.current) {
      const nextStep =
        steps.filter(
          (f) => !f.isCompleted(state) && !skippedSteps.includes(f.value)
        )[0]?.value ?? setupGuideConfig.sections[0].steps[0].value;
      setActiveStep(nextStep);
    }
  }, [loading, hasSetSkippedSteps.current]);

  useEffect(() => {
    setSkippedSteps(skippedStepsString?.split(',') ?? []);
    hasSetSkippedSteps.current = true;
  }, [skippedStepsString]);

  const onStepButtonClick = (e, action, url, step) => {
    toggleSetupGuideSidebar(true, step.value);
    if (action === 'skip') {
      e.preventDefault();
      e.stopPropagation();
      saveToStorage(id, [...skippedSteps, step.value].join(','));
    }
  };

  return (
    <AppStack style={{ flex: 1 }}>
      <AppStack
        p={{ base: 0, sm: '16px 16px 80px 16px' }}
        style={{
          flex: 1,
          margin: 'auto',
          width: '100%',
          maxWidth: 950,
          paddingTop: 16,
          gap: 16
        }}
      >
        {!loading ? (
          <>
            {isGuideCompleted && (
              <AppCard
                radius={isTabletOrSmaller ? 0 : 'md'}
                shadow="xs"
                style={{ padding: 16 }}
                withBorder
              >
                <Alert
                  color="#067D62"
                  icon={<InfoCircle color="#067D62" size={26} />}
                  styles={{ title: { fontSize: 16 } }}
                  title="Setup complete!"
                  variant="outline"
                >
                  <AppStack style={{ gap: 10 }}>
                    <AppText style={{ fontSize: 14 }}>
                      Congratulations! You have completed all the steps in the
                      setup guide. You can now start using the platform.
                    </AppText>
                    <AppText style={{ fontSize: 14 }}>
                      You can always revist the home page to access the setup
                      guide again.
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      If you need any help, please contact our support team.
                    </AppText>
                  </AppStack>
                </Alert>
              </AppCard>
            )}

            <AppStack p={{ base: '0px 16px', sm: 0 }} style={{ gap: 0 }}>
              <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                Getting started
              </AppText>
              <AppText style={{ fontSize: 14, color: '#666' }}>
                Welcome, {authState.userData.user.name.split(' ')[0]}!{' '}
                {setupGuideConfig.intro}
              </AppText>
            </AppStack>

            <AppCard
              radius={isTabletOrSmaller ? 0 : 'md'}
              shadow="xs"
              style={{ padding: 0 }}
              withBorder
            >
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox
                  style={{ padding: 16, gap: 16, alignItems: 'center' }}
                >
                  {isGuideCompleted && (
                    <AppStack visibleFrom="xsm">
                      <CircleCheck color="#067D62" size={50} />
                    </AppStack>
                  )}
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 18, fontWeight: 700 }}>
                      {setupGuideConfig.title}
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      {setupGuideConfig.description}
                    </AppText>
                    <Badge
                      color={isGuideCompleted ? '#067D62' : 'dark'}
                      style={{ marginTop: 5, border: 'solid 1px #dee2e6' }}
                      variant={isGuideCompleted ? 'filled' : 'light'}
                    >
                      {isGuideCompleted ? steps.length : completedSteps.length}{' '}
                      / {steps.length} steps completed
                    </Badge>
                  </AppStack>
                </AppFlexbox>

                <Accordion
                  onChange={(value) =>
                    setActiveStep(
                      value
                        ? setupGuideConfig.sections.find(
                            (s) => s.value === value
                          ).steps[0].value
                        : ''
                    )
                  }
                  styles={{
                    control: { backgroundColor: 'rgba(245, 245, 245, 1)' },
                    item: { border: 'none', borderTop: 'solid 1px #dee2e6' },
                    content: { padding: 5, borderRadius: 16 }
                  }}
                  value={
                    setupGuideConfig.sections.find((s) =>
                      s.steps.some((step) => step.value === activeStep)
                    )?.value
                  }
                >
                  {setupGuideConfig.sections.map((s) => {
                    const openStep =
                      s.steps.find((f) => f.value === activeStep)?.value ??
                      s.steps[0].value;

                    const allStepsCompleted = s.steps.every(
                      (step) =>
                        isGuideCompleted ||
                        step.isCompleted(state) ||
                        skippedSteps.includes(step.value)
                    );

                    return (
                      <Accordion.Item key={s.value} value={s.value}>
                        <Accordion.Control>
                          <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                            {allStepsCompleted && (
                              <AppStack
                                style={{
                                  height: 20,
                                  width: 20
                                }}
                              >
                                <CircleCheck
                                  color="#067D62"
                                  style={{
                                    width: '100%',
                                    height: '100%'
                                  }}
                                />
                              </AppStack>
                            )}

                            <AppText style={{ fontWeight: 500 }}>
                              {s.title}
                            </AppText>
                          </AppFlexbox>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Accordion
                            onChange={setActiveStep}
                            styles={{
                              content: { padding: 0 },
                              item: { border: 'none' },
                              control: { padding: 0 },
                              label: { padding: 0 },
                              chevron: { display: 'none' },
                              panel: { padding: 0 }
                            }}
                            value={openStep}
                            variant="filled"
                          >
                            {s.steps.map((step) => {
                              const isStepCompleted =
                                isGuideCompleted ||
                                step.isCompleted(state) ||
                                skippedSteps.includes(step.value);

                              const isStepDisabled =
                                step.disableAfterCompletion && isStepCompleted;

                              return (
                                <Accordion.Item
                                  key={step.value}
                                  style={{
                                    backgroundColor:
                                      openStep === step.value
                                        ? 'rgba(245, 245, 245, 1)'
                                        : 'unset'
                                  }}
                                  value={step.value}
                                >
                                  <Accordion.Control>
                                    <AppFlexbox
                                      style={{
                                        gap: 10,
                                        padding: 10,
                                        paddingBottom:
                                          openStep === step.value ? 0 : 10,
                                        alignItems: 'center'
                                      }}
                                    >
                                      {isStepCompleted ? (
                                        <AppStack
                                          style={{
                                            height: 20,
                                            width: 20
                                          }}
                                        >
                                          <CircleCheck
                                            color="#067D62"
                                            style={{
                                              width: '100%',
                                              height: '100%'
                                            }}
                                          />
                                        </AppStack>
                                      ) : (
                                        <AppStack
                                          style={{
                                            height: 20,
                                            width: 20,
                                            borderRadius: 100,
                                            border: 'dashed 2px #999'
                                          }}
                                        />
                                      )}
                                      <AppText
                                        style={{
                                          fontWeight: 500,
                                          color: '#333',
                                          fontSize: 14
                                        }}
                                      >
                                        {step.title}
                                      </AppText>
                                    </AppFlexbox>
                                  </Accordion.Control>
                                  <Accordion.Panel>
                                    <AppFlexbox
                                      style={{
                                        padding: 10,
                                        paddingTop: 0,
                                        gap: 10
                                      }}
                                    >
                                      <AppStack
                                        style={{
                                          height: 20,
                                          width: 20
                                        }}
                                      />
                                      <AppFlexbox
                                        style={{
                                          flex: 1,
                                          alignItems: 'start',
                                          justifyContent: 'space-between'
                                        }}
                                      >
                                        <AppStack style={{ gap: 0, flex: 1 }}>
                                          <AppText
                                            style={{
                                              fontSize: 14,
                                              color: '#666'
                                            }}
                                          >
                                            {step.description}
                                          </AppText>
                                          {step.subDescription && (
                                            <AppText
                                              style={{
                                                fontSize: 14,
                                                color: '#666'
                                              }}
                                            >
                                              {step.subDescription}
                                            </AppText>
                                          )}
                                          <AppFlexbox
                                            style={{
                                              marginTop: 10,
                                              flexDirection: isLargeMobileOrSmaller
                                                ? 'column'
                                                : 'row'
                                            }}
                                          >
                                            {step.primaryButton &&
                                              (step.primaryButton?.action !==
                                                'skip' ||
                                                !isStepCompleted) && (
                                                <Button
                                                  color="dark"
                                                  component={
                                                    isStepDisabled ? null : Link
                                                  }
                                                  disabled={isStepDisabled}
                                                  leftSection={
                                                    step.primaryButton.icon && (
                                                      <step.primaryButton.icon
                                                        size={18}
                                                      />
                                                    )
                                                  }
                                                  onClick={(e) => {
                                                    if (!isStepDisabled) {
                                                      onStepButtonClick(
                                                        e,
                                                        step.primaryButton
                                                          .action,
                                                        step.primaryButton.url,
                                                        step
                                                      );
                                                    }
                                                  }}
                                                  radius="md"
                                                  size="compact-sm"
                                                  to={step.primaryButton.url}
                                                >
                                                  {step.primaryButton.title}
                                                </Button>
                                              )}
                                            {step.secondaryButton &&
                                              (step.secondaryButton?.action !==
                                                'skip' ||
                                                !isStepCompleted) && (
                                                <Button
                                                  color="dark"
                                                  component={
                                                    isStepDisabled ? null : Link
                                                  }
                                                  disabled={isStepDisabled}
                                                  leftSection={
                                                    step.secondaryButton
                                                      .icon && (
                                                      <step.secondaryButton.icon
                                                        size={18}
                                                      />
                                                    )
                                                  }
                                                  onClick={(e) => {
                                                    if (!isStepDisabled) {
                                                      onStepButtonClick(
                                                        e,
                                                        step.secondaryButton
                                                          .action,
                                                        step.secondaryButton
                                                          .url,
                                                        step
                                                      );
                                                    }
                                                  }}
                                                  radius="md"
                                                  size="compact-sm"
                                                  to={step.secondaryButton.url}
                                                  variant="outline"
                                                >
                                                  {step.secondaryButton.title}
                                                </Button>
                                              )}
                                          </AppFlexbox>
                                        </AppStack>
                                        <AppStack>
                                          {step.icon && <step.icon size={65} />}
                                        </AppStack>
                                      </AppFlexbox>
                                    </AppFlexbox>
                                  </Accordion.Panel>
                                </Accordion.Item>
                              );
                            })}
                          </Accordion>
                        </Accordion.Panel>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </AppStack>
            </AppCard>
          </>
        ) : (
          <>
            <AppStack style={{ gap: 5 }}>
              <Skeleton height={35} width={190} />
              <Skeleton height={20} width="50%" />
            </AppStack>
            <AppCard
              radius={isTabletOrSmaller ? 0 : 'md'}
              shadow="xs"
              style={{ padding: 0 }}
              withBorder
            >
              <AppStack style={{ gap: 0 }}>
                <AppStack style={{ padding: 16, gap: 5 }}>
                  <Skeleton height={24} width={190} />
                  <Skeleton height={20} width="50%" />
                  <Skeleton height={18} radius={100} width={150} />
                </AppStack>
                <Accordion
                  onChange={() => {}}
                  styles={{
                    control: { backgroundColor: 'rgba(245, 245, 245, 1)' },
                    item: { border: 'none', borderTop: 'solid 1px #dee2e6' },
                    content: { padding: 5, borderRadius: 16 }
                  }}
                  value={
                    setupGuideConfig.sections.find((s) =>
                      s.steps.some((step) => step.value === activeStep)
                    )?.value
                  }
                >
                  {setupGuideConfig.sections.map((s) => {
                    const openStep =
                      s.steps.find((f) => f.value === activeStep)?.value ??
                      s.steps[0].value;
                    return (
                      <Accordion.Item key={s.value} value={s.value}>
                        <Accordion.Control>
                          <Skeleton height={24} width={s.title.length * 8} />
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Accordion
                            onChange={setActiveStep}
                            styles={{
                              content: { padding: 0 },
                              item: { border: 'none' },
                              control: { padding: 0 },
                              label: { padding: 0 },
                              chevron: { display: 'none' },
                              panel: { padding: 0 }
                            }}
                            value={openStep}
                            variant="filled"
                          >
                            {s.steps.map((step) => (
                              <Accordion.Item
                                key={step.value}
                                style={{
                                  backgroundColor:
                                    openStep === step.value
                                      ? 'rgba(245, 245, 245, 1)'
                                      : 'unset'
                                }}
                                value={step.value}
                              >
                                <Accordion.Control>
                                  <AppFlexbox
                                    style={{
                                      gap: 10,
                                      padding: 10,
                                      paddingBottom:
                                        openStep === step.value ? 0 : 10,
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Skeleton
                                      height={20}
                                      radius={100}
                                      width={20}
                                    />
                                    <Skeleton
                                      height={20}
                                      width={step.title.length * 7}
                                    />
                                  </AppFlexbox>
                                </Accordion.Control>
                                <Accordion.Panel>
                                  <AppFlexbox
                                    style={{
                                      padding: 10,
                                      gap: 10
                                    }}
                                  >
                                    <AppStack
                                      style={{
                                        height: 20,
                                        width: 20
                                      }}
                                    />
                                    <AppFlexbox
                                      style={{
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                      }}
                                    >
                                      <AppStack style={{ gap: 0, flex: 1 }}>
                                        <Skeleton height={12} width="50%" />
                                        <AppFlexbox style={{ marginTop: 10 }}>
                                          {step.primaryButton && (
                                            <Skeleton
                                              height={26}
                                              width={
                                                step.primaryButton.title
                                                  .length *
                                                  7 +
                                                42
                                              }
                                            />
                                          )}
                                          {step.secondaryButton && (
                                            <Skeleton
                                              height={26}
                                              width={
                                                step.secondaryButton.title
                                                  .length *
                                                  7 +
                                                42
                                              }
                                            />
                                          )}
                                        </AppFlexbox>
                                      </AppStack>
                                      <AppStack>
                                        {step.icon && (
                                          <Skeleton height={65} width={65} />
                                        )}
                                      </AppStack>
                                    </AppFlexbox>
                                  </AppFlexbox>
                                </Accordion.Panel>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </Accordion.Panel>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </AppStack>
            </AppCard>
          </>
        )}
      </AppStack>
    </AppStack>
  );
};

SetupGuideView.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  setupGuideConfig: PropTypes.object,
  state: PropTypes.object
};

export default SetupGuideView;
