import { BuildingStore } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsBrandInformationBlock from './WsBrandInformationBlock';
import {
  configOptions as wsTextConfigOptions,
  configDefaultValues as wsTextDefaultConfigValues
} from '../text/wsTextConfig';

const HEADING_SIZE_STYLE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  HUGE: 'huge'
};

const configNameValues = {
  headingSize: 'headingSize',
  showSocials: 'showSocials',
  showDomain: 'showDomain',
  facebookUrl: 'facebookUrl',
  twitterUrl: 'twitterUrl',
  instagramUrl: 'instagramUrl',
  linkedinUrl: 'linkedinUrl',
  youtubeUrl: 'youtubeUrl'
};

const configDefaultValues = {
  [configNameValues.headingSize]: 'medium',
  showSocials: 'true',
  showDomain: 'true',
  facebookUrl: '',
  twitterUrl: '',
  instagramUrl: '',
  linkedinUrl: '',
  youtubeUrl: '',
  ...wsTextDefaultConfigValues,
  fontSize: '14'
};

const configOptions = {
  headingSize: {
    label: 'Heading size',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      {
        value: 'small',
        label: 'Small'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.HUGE,
        label: 'Huge'
      }
    ]
  },
  ...wsTextConfigOptions,
  showDomain: {
    label: 'Show domain',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  showSocials: {
    label: 'Show socials',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  facebookUrl: {
    label: 'Facebook URL',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    placeholder: 'https://www.facebook.com/sportsheadz',
    isVisible: (config) => config.showSocials === 'true'
  },
  twitterUrl: {
    label: 'Twitter URL',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    placeholder: 'https://twitter.com/sportsheadz',
    isVisible: (config) => config.showSocials === 'true'
  },
  instagramUrl: {
    label: 'Instagram URL',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    placeholder: 'https://www.instagram.com/sportsheadz',
    isVisible: (config) => config.showSocials === 'true'
  },
  linkedinUrl: {
    label: 'LinkedIn URL',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    placeholder: 'https://www.linkedin.com/sportsheadz',
    isVisible: (config) => config.showSocials === 'true'
  },
  youtubeUrl: {
    label: 'YouTube URL',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    placeholder: 'https://www.youtube.com/sportsheadz',
    isVisible: (config) => config.showSocials === 'true'
  }
};

const config = {
  title: 'Store information',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BRAND_INFORMATION,
  icon: BuildingStore,
  Component: WsBrandInformationBlock,
  configOptions,
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
