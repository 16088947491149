import { AlignLeft } from 'tabler-icons-react';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import WsDescriptionBlock from './WsDescriptionBlock';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  title: 'Description',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.DESCRIPTION,
  icon: AlignLeft,
  Component: WsDescriptionBlock,
  configOptions,
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
