import React, { useContext, useEffect, useRef } from 'react';
import { Anchor, Skeleton } from '@mantine/core';
import { Link, Route, Routes } from 'react-router-dom';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import { triggerNotification } from '../../../helpers/notification';
import WebstoreBlogPostEditView from './WebstoreBlogPostEditView';

const WebstoreBlogPostsView = () => {
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreBlogPosts } = useContext(StoreContext);
  const isViewLoading = !hasFetched.current || state.ecomStoreBlogPosts.loading;

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchStoreBlogPosts(authState.pkEcomStore, null, (error) =>
        triggerNotification(error)
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  return !isViewLoading ? (
    <AppStack
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 900,
        marginTop: 30,
        marginBottom: 70,
        gap: 30
      }}
    >
      <Routes>
        <Route element={<WebstoreBlogPostEditView />} path="/new" />
        <Route
          element={<WebstoreBlogPostEditView />}
          path="/:pkEcomStoreBlogPost/*"
        />
        <Route
          element={
            <>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                  <AppText style={{ fontSize: 22, fontWeight: 500 }}>
                    Blog posts
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
              <AppStack style={{ gap: 16 }}>
                <AppCard
                  radius="md"
                  shadow="xs"
                  style={{ padding: 0 }}
                  withBorder
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppFlexbox
                      style={{
                        gap: 5,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 16
                      }}
                    >
                      <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                        Blog posts
                      </AppText>
                      <Anchor
                        component={Link}
                        style={{ fontSize: 14 }}
                        to="/merchant/webstore/blogs/new"
                      >
                        Add blog post
                      </Anchor>
                    </AppFlexbox>
                    {state.ecomStoreBlogPosts.value.length === 0 ? (
                      <AppStack
                        style={{
                          padding: '40px 20px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center'
                        }}
                      >
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          Add a blog post to customize your webstore
                        </AppText>
                      </AppStack>
                    ) : (
                      <AppStack style={{ gap: 0 }}>
                        <AppFlexbox
                          style={{
                            padding: '0px 16px 10px 16px',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <AppFlexbox style={{ gap: 10, flex: 1 }}>
                            <AppText style={{ color: '#666', fontSize: 14 }}>
                              Title
                            </AppText>
                          </AppFlexbox>

                          <AppFlexbox style={{ gap: 10, flex: 1 }}>
                            <AppText style={{ color: '#666', fontSize: 14 }}>
                              Description
                            </AppText>
                          </AppFlexbox>
                        </AppFlexbox>
                        {state.ecomStoreBlogPosts.value.map((t) => (
                          <AppFlexbox
                            key={t.pkEcomStoreBlogPost}
                            style={{
                              padding: 16,
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              borderTop: '1px solid #dee2e6'
                            }}
                          >
                            <AppFlexbox style={{ gap: 10, flex: 1 }}>
                              <AppStack style={{ gap: 0 }}>
                                <Anchor
                                  component={Link}
                                  style={{ fontSize: 14 }}
                                  to={`/merchant/webstore/blogs/${t.pkEcomStoreBlogPost}`}
                                >
                                  {t.title}
                                </Anchor>
                              </AppStack>
                            </AppFlexbox>

                            <AppFlexbox style={{ gap: 10, flex: 1 }}>
                              <AppText style={{ fontSize: 14, color: '#666' }}>
                                {t.description}
                              </AppText>
                            </AppFlexbox>
                          </AppFlexbox>
                        ))}
                      </AppStack>
                    )}
                  </AppStack>
                </AppCard>
              </AppStack>
            </>
          }
          path="*"
        />
      </Routes>
    </AppStack>
  ) : (
    <AppStack
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 900,
        marginTop: 30,
        marginBottom: 70,
        gap: 30
      }}
    >
      <AppFlexbox
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Skeleton height={30} width={200} />
        </AppFlexbox>

        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Skeleton height={30} width={125} />
        </AppFlexbox>
      </AppFlexbox>

      <AppStack style={{ gap: 16 }}>
        <AppCard radius="md" shadow="xs" style={{ padding: 16 }} withBorder>
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
        <AppCard radius="md" shadow="xs" style={{ padding: 16 }} withBorder>
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
        <AppCard radius="md" shadow="xs" style={{ padding: 16 }} withBorder>
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
      </AppStack>
    </AppStack>
  );
};

WebstoreBlogPostsView.propTypes = {};

export default WebstoreBlogPostsView;
