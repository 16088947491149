import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionIcon, Button, Divider, Menu } from '@mantine/core';
import {
  Archive,
  Copy,
  Dots,
  Download,
  Eye,
  Pencil,
  Plus,
  Shirt,
  Trash
} from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import TableView from '../../common/TableView';
import {
  ECOM_PRODUCT_STATUS_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import {
  currencyFormat,
  formatUtcDate,
  singularPluralFormat
} from '../../../helpers/format';
import { triggerNotification } from '../../../helpers/notification';
import HeaderView from '../../common/HeaderView';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { useModalState, usePaginationFilter } from '../../../helpers/hooks';
import AppMenu from '../../common/AppMenu';
import ChangeProductStatusModal from '../products/ChangeProductStatusModal';
import ConfirmModal from '../../common/ConfirmModal';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import ExportCsvModal from '../bulkEdit/ExportCsvModal';
import {
  PRODUCT_FIELD_SECTION_ENUM,
  vendorProductCsvConfig
} from './vendorProductCsvConfig';
import ImportCsvModal from '../bulkEdit/ImportCsvModal';
import { getEcomVendorProductStatus } from '../../../helpers/vendorHelper';

const TABLE_COLUMNS = [
  {
    label: 'Product',
    value: 'name',
    sortable: true
  },
  {
    label: 'Category',
    value: 'category',
    sortable: true
  },
  {
    label: 'Price',
    value: 'price',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Schedule date',
    value: 'schedule_date',
    sortable: true
  },
  {
    label: 'Date created',
    value: 'created',
    sortable: true
  }
];

const VIEW_ACTIONS = [
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.EDIT,
    section: 1,
    icon: <Pencil size={18} />
  },
  {
    label: 'Duplicate',
    value: VIEW_ACTIONS_ENUM.DUPLICATE,
    section: 1,
    icon: <Copy size={18} />
  },
  {
    label: 'Change status',
    value: VIEW_ACTIONS_ENUM.CHANGE_STATUS,
    section: 1,
    icon: <Eye size={18} />
  },
  {
    label: 'Archive product',
    value: VIEW_ACTIONS_ENUM.ARCHIVE,
    icon: <Archive size={18} />,
    section: 2
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.DELETE,
    icon: <Trash color="red" size={18} />,
    section: 2,
    color: 'red'
  }
];

const VendorProductTable = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const modalAction = new URLSearchParams(search).get('modal');
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchPaginatedVendorProducts,
    updateVendorProducts,
    deleteVendorProducts,
    fetchVendorProductExportData
  } = useContext(VendorContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: productData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      fkEcomCategory: null,
      fkEcomProductStatus: null
    },
    (newFilter) => {
      fetchPaginatedVendorProducts(
        authState.pkEcomVendor,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedEcomVendorProducts
  );
  const loading = !hasFetched.current || dataLoading;
  const noProductsCreated = !loading && totalNoFilterCount === 0;
  const selectedProducts = productData.filter((f) =>
    selectedRows.includes(f.pkEcomVendorProduct)
  );

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchPaginatedVendorProducts(
        authState.pkEcomVendor,
        {},
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor]);

  useEffect(() => {
    if (modalAction) {
      switch (modalAction.toLowerCase()) {
        case 'import':
          onOpenModal(VIEW_ACTIONS_ENUM.IMPORT);
          break;
        case 'export':
          onOpenModal(VIEW_ACTIONS_ENUM.EXPORT);
          break;
        default:
          break;
      }
    }
  }, [modalAction]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Products',
          to: '/vendor/products'
        }
      ]}
      rightSection={
        !loading && (
          <>
            {!noProductsCreated && (
              <Button
                color="dark"
                onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EXPORT)}
                radius="md"
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="outline"
                visibleFrom="xsm"
              >
                Export
              </Button>
            )}

            <Button
              color="dark"
              onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.IMPORT)}
              radius="md"
              size="compact-md"
              style={{ fontSize: 14 }}
              variant="outline"
              visibleFrom="xsm"
            >
              Import
            </Button>
            <AppMenu
              control={
                <ActionIcon
                  color="dark"
                  radius="md"
                  size="lg"
                  style={{ height: '100%', maxHeight: '100%' }}
                  variant="light"
                >
                  <Dots color="#000" />
                </ActionIcon>
              }
              hiddenFrom="xsm"
              styles={{ dropdown: { padding: 0 } }}
            >
              <Menu.Item onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EXPORT)}>
                Export
              </Menu.Item>
              <Menu.Item onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.IMPORT)}>
                Import
              </Menu.Item>
            </AppMenu>
            <Button
              color="dark"
              leftSection={<Plus size={18} />}
              onClick={() => navigate('/vendor/products/new')}
              radius="md"
              size="compact-md"
              style={{ fontSize: 14 }}
            >
              Add product
            </Button>
          </>
        )
      }
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={noProductsCreated}
        disabledContent={{
          title: 'Add products',
          description: 'Start by adding a your first product.',
          icon: <Shirt color="#000" size={125} />,
          primaryButton: {
            label: 'Add product',
            icon: <Plus size={18} />,
            link: '/vendor/products/new'
          },
          secondaryButton: {
            label: 'Import',
            icon: <Download size={18} />,
            onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.IMPORT)
          }
        }}
        emptyMessage="No products found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by product',
            value: filter.search,
            onChange: (value) => onFilterChange({ ...filter, search: value })
          },
          {
            key: 2,
            label: 'Category',
            type: 'select',
            value: filter.fkEcomCategory ?? null,
            placeholder: 'Filter by category',
            searchable: true,
            clearable: true,
            data: PRODUCT_CATEGORY_LIST.sort((a, b) =>
              a.name.localeCompare(b.name)
            ).map((c) => ({
              value: c.value.toString(),
              label: c.name
            })),
            onChange: (value) =>
              onFilterChange({ ...filter, fkEcomCategory: value }, true)
          },
          {
            key: 3,
            label: 'Status',
            type: 'select',
            value: filter.fkEcomProductStatus ?? null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: ECOM_PRODUCT_STATUS_ENUM.ACTIVE, label: 'Active' },
              { value: ECOM_PRODUCT_STATUS_ENUM.DRAFT, label: 'Draft' },
              { value: ECOM_PRODUCT_STATUS_ENUM.ARCHIVED, label: 'Archived' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, fkEcomProductStatus: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onAction={(action, item) => {
          const product = productData.find(
            (s) => s.pkEcomVendorProduct === item.key
          );
          if (product) {
            switch (action) {
              case VIEW_ACTIONS_ENUM.EDIT:
                navigate(`/vendor/products/${product.uuid}`);
                break;
              case VIEW_ACTIONS_ENUM.DUPLICATE:
                navigate(
                  `/vendor/products/${product.uuid}/duplicate?from=products`
                );
                break;
              case VIEW_ACTIONS_ENUM.CHANGE_STATUS:
              case VIEW_ACTIONS_ENUM.ARCHIVE:
              case VIEW_ACTIONS_ENUM.DELETE:
              default:
                onOpenModal(action, [product]);
                break;
            }
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        onSelect={setSelectedRows}
        pageIndex={pageIndex}
        rows={productData.map((a) => ({
          key: a.pkEcomVendorProduct,
          uuid: a.uuid,
          actions: VIEW_ACTIONS,
          columns: [
            {
              key: 1,
              label: a.name,
              onClick: () => navigate(`/vendor/products/${a.uuid}`),
              weight: 500,
              subLabel: singularPluralFormat(
                a.ecomVendorProductVariantCount,
                'variant',
                'variants'
              ),
              onSubLabelClick: () =>
                navigate(`/vendor/products/${a.uuid}/variants?from=products`),
              src: a.previewImage ?? null,
              showImage: true
            },
            {
              key: 2,
              label:
                PRODUCT_CATEGORY_LIST.find(
                  (c) => c.value.toString() === a.fkEcomCategory?.toString()
                )?.name ?? '',
              weight: 500,
              subLabel: a.productType
            },
            {
              key: 3,
              label:
                a.maxPrice === a.minPrice
                  ? currencyFormat(a.minPrice / 100)
                  : `${currencyFormat(a.minPrice / 100)} - ${currencyFormat(
                      a.maxPrice / 100
                    )}`
            },
            {
              key: 4,
              ...getEcomVendorProductStatus(a)
            },
            {
              key: 5,
              label: a.scheduleDate
                ? dayjs(formatUtcDate(a.scheduleDate)).format('MMMM D, YYYY')
                : ''
            },
            {
              key: 6,
              label: dayjs(formatUtcDate(a.createdAt)).format('MMMM D, YYYY')
            }
          ]
        }))}
        selectActionSection={
          <AppMenu
            control={
              <ActionIcon color="#ced4da" size="lg" variant="outline">
                <Dots color="#000" />
              </ActionIcon>
            }
            styles={{ dropdown: { padding: 0 } }}
          >
            <AppStack style={{ padding: 2, gap: 1 }}>
              <Menu.Item
                leftSection={<Pencil size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate(
                    `/vendor/products/bulk-edit?ids=${selectedProducts
                      .map((f) => f.uuid)
                      .join(',')}`
                  );
                }}
                visibleFrom="xsm"
              >
                Bulk edit
              </Menu.Item>
              <Menu.Item
                leftSection={<Eye size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onOpenModal(VIEW_ACTIONS_ENUM.CHANGE_STATUS, [
                    ...selectedProducts
                  ]);
                }}
              >
                Change status
              </Menu.Item>
            </AppStack>
            <Divider />
            <AppStack style={{ padding: 2, gap: 1 }}>
              {filter.status !== ECOM_PRODUCT_STATUS_ENUM.ARCHIVED && (
                <Menu.Item
                  leftSection={<Archive size={18} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onOpenModal(VIEW_ACTIONS_ENUM.ARCHIVE, [
                      ...selectedProducts
                    ]);
                  }}
                >
                  Archive products
                </Menu.Item>
              )}

              <Menu.Item
                leftSection={<Trash size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onOpenModal(VIEW_ACTIONS_ENUM.DELETE, [...selectedProducts]);
                }}
                style={{ color: 'red' }}
              >
                Delete products
              </Menu.Item>
            </AppStack>
          </AppMenu>
        }
        selectedRows={selectedProducts.map((p) => p.pkEcomVendorProduct)}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Products"
        totalCount={totalCount}
        totalPages={totalPages}
      />
      <ImportCsvModal
        descriptor="products"
        importConfig={vendorProductCsvConfig}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.IMPORT
        }
        onClose={onCloseModal}
        onImport={({ data }, onSuccessCallback, onErrorCallback) => {
          updateVendorProducts(
            authState.pkEcomVendor,
            {
              ecomVendorProducts: data.map((d) => ({
                ...d,
                fkEcomCategory: !d.fkEcomCategory ? 0 : d.fkEcomCategory,
                ecomVendorProduct: {
                  ...d,
                  minimumQuantity:
                    d.minimumQuantity && d.minimumQuantity >= 1
                      ? d.minimumQuantity
                      : null
                }
              })),
              createProductsNotFound: true
            },
            onSuccessCallback,
            onErrorCallback
          );
        }}
        onImportCsvData={(data, onSuccesCallback, onErrorCallback) => {
          const urlHandles = data.map((i) => i.urlHandle);
          if (urlHandles.length > 0) {
            fetchVendorProductExportData(
              {
                fkEcomVendor: authState.pkEcomVendor,
                urlHandles,
                skipStateUpdate: true
              },
              (products) => {
                onSuccesCallback(
                  data.map((d) => {
                    const existingProduct = products.find(
                      (p) => p.urlHandle === d.urlHandle
                    );
                    return {
                      ...d,
                      uuid: existingProduct?.uuid ?? null,
                      foundExisting: !!existingProduct,
                      ecomVendorProduct: existingProduct ?? null,
                      ecomVendorProductVariants: [
                        ...d.ecomVendorProductVariants.map((v) => {
                          const existingVariant = existingProduct?.ecomVendorProductVariants.find(
                            (ev) =>
                              ev.ecomVendorProductVariantOptions.every((op) =>
                                v.ecomVendorProductVariantOptions.some(
                                  (vop) =>
                                    vop.name.toLowerCase() ===
                                      op.name.toLowerCase() &&
                                    vop.value.toLowerCase() ===
                                      op.value.toLowerCase()
                                )
                              )
                          );
                          return {
                            ...v,
                            uuid: existingVariant?.uuid ?? null,
                            foundExisting: !!existingVariant
                          };
                        })
                      ]
                    };
                  })
                );
              },
              onErrorCallback
            );
          }
          else {
            onSuccesCallback(data);
          }
        }}
      />

      <ExportCsvModal
        descriptor="products"
        exportConfig={vendorProductCsvConfig}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EXPORT
        }
        itemType="product"
        itemTypePlural="products"
        onClose={onCloseModal}
        onExport={(
          { items, excludedFieldSections },
          downloadCsvCallback,
          onErrorCallback
        ) => {
          fetchVendorProductExportData(
            {
              fkEcomVendor: authState.pkEcomVendor,
              uuids: items.map((i) => i.uuid),
              skipStateUpdate: true
            },
            (data) => {
              const variantsExcluded = excludedFieldSections.includes(
                PRODUCT_FIELD_SECTION_ENUM.VARIANT
              );
              downloadCsvCallback(
                data
                  .map((p) => {
                    const variantData = variantsExcluded
                      ? [[p, p.ecomVendorProductVariants[0], 0]]
                      : p.ecomVendorProductVariants
                          .sort((a, b) => {
                            const aOptionsSorted = a.ecomVendorProductVariantOptions.sort(
                              (a1, b1) => a1.nameSort - b1.nameSort
                            );
                            const bOptionsSorted = b.ecomVendorProductVariantOptions.sort(
                              (a1, b1) => a1.nameSort - b1.nameSort
                            );

                            return (
                              (aOptionsSorted[0]?.valueSort ?? 0) -
                                (bOptionsSorted[0]?.valueSort ?? 0) ||
                              (aOptionsSorted[1]?.valueSort ?? 0) -
                                (bOptionsSorted[1]?.valueSort ?? 0) ||
                              (aOptionsSorted[2]?.valueSort ?? 0) -
                                (bOptionsSorted[2]?.valueSort ?? 0)
                            );
                          })
                          .map((v, index) => [p, v, index]);
                    return variantData;
                  })
                  .flat()
              );
            },
            onErrorCallback
          );
        }}
        selectedItems={selectedProducts}
      />

      <ChangeProductStatusModal
        archiveProducts={modalState.action === VIEW_ACTIONS_ENUM.ARCHIVE}
        fkEcomVendor={authState.pkEcomVendor}
        isOpen={
          modalState.isOpen &&
          (modalState.action === VIEW_ACTIONS_ENUM.CHANGE_STATUS ||
            modalState.action === VIEW_ACTIONS_ENUM.ARCHIVE)
        }
        onClose={onCloseModal}
        onStatusChangeSuccess={onRefresh}
        products={modalState.item ?? []}
      />

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete products"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to delete{' '}
              <b>
                {modalState.item?.length === 1
                  ? modalState.item[0]?.name
                  : `${modalState.item?.length} products`}
              </b>
              ?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteVendorProducts(
            authState.pkEcomVendor,
            modalState.item.map((i) => i.pkEcomVendorProduct),
            () => {
              onRefresh();
              triggerNotification('Products deleted.', 'success');
              onCloseModal();
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${
          modalState.item?.length === 1
            ? modalState.item[0]?.name
            : `${modalState.item?.length} products`
        }?
        `}
      />
    </HeaderView>
  );
};

export default VendorProductTable;
