import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import EditTrackingDetailsFormSection from './EditTrackingDetailsFormSection';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import { generateTrackingLink } from '../../../helpers/vendorHelper';

const EditShippingDetailsModal = ({
  pkEcomStoreCart,
  ecomStoreCartShipping,
  isOpen,
  onClose
}) => {
  const { state: authState } = useContext(AuthContext);
  const { fetchVendorOrder, updateEcomStoreCartShippings } = useContext(
    VendorContext
  );
  const [formState, setFormState] = useState({
    trackingNumber: '',
    trackingUrl: '',
    shippingCarrier: '',
    notifyCustomer: false,
    additionalShipping: [],
    loading: false,
    error: null
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        trackingNumber: ecomStoreCartShipping?.trackingNumber ?? '',
        trackingUrl: ecomStoreCartShipping?.trackingUrl ?? '',
        shippingCarrier: ecomStoreCartShipping?.shippingCarrier ?? '',
        notifyCustomer: ecomStoreCartShipping?.notifyCustomer ?? false,
        additionalShipping: ecomStoreCartShipping?.additionalShipping ?? [],
        ecomStoreCartProducts:
          ecomStoreCartShipping?.ecomStoreCartShippingProducts.map((p) => ({
            pkEcomStoreCartProduct: p.fkEcomStoreCartProduct,
            count: p.count
          })) ?? [],
        loading: false,
        error: null
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        isLoading: formState.loading,
        submitTitle: 'Save changes',
        isSubmitDisabled: !ecomStoreCartShipping,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          updateEcomStoreCartShippings(
            authState.pkEcomVendor,
            [
              {
                pkEcomStoreCartShipping:
                  ecomStoreCartShipping.pkEcomStoreCartShipping,
                pkEcomStoreCart,
                ...formState,
                trackingUrl: generateTrackingLink(
                  formState.shippingCarrier,
                  formState.trackingNumber,
                  formState.trackingUrl
                )
              },
              ...formState.additionalShipping.map((as) => ({
                pkEcomStoreCart,
                ...as,
                ecomStoreCartProducts: formState.ecomStoreCartProducts.map(
                  (p) => ({
                    ...p,
                    count: 0
                  })
                ),
                trackingUrl: generateTrackingLink(
                  as.shippingCarrier,
                  as.trackingNumber,
                  as.trackingUrl
                )
              }))
            ],
            () => {
              triggerNotification('Shipping updated', 'success');
              fetchVendorOrder(authState.pkEcomVendor, { pkEcomStoreCart });
              onClose();
            },
            (e) => {
              triggerNotification(e);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        }
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Shipping details"
    >
      <AppStack style={{ gap: 20 }}>
        <EditTrackingDetailsFormSection
          formState={formState}
          setFormState={setFormState}
        />
      </AppStack>
    </ResponsiveModal>
  );
};

EditShippingDetailsModal.propTypes = {
  ecomStoreCartShipping: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkEcomStoreCart: PropTypes.number
};

export default EditShippingDetailsModal;
