/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import { IMAGE_DISPLAY_ENUM, SORT_BY_ENUM } from './wsCollectionGridConfig';
import WsCollectionCard from './WsCollectionCard';
import AppText from '../../../../common/AppText';

const WsCollectionGrid = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  pathname,
  ecomStoreTheme,
  onNavigate,
  baseStoreUrl,
  editModeEnabled,
  search,
  ...rest
}) => {
  const { state } = useContext(StoreUserContext);
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const squareRatio = config.imageRatio === IMAGE_DISPLAY_ENUM.SQUARE;
  const hasFetched =
    state.ecomStoreCollections.filter?.pkEcomStore ===
    ecomStoreTheme.ecomStore.pkEcomStore;

  const loading = !hasFetched || state.ecomStoreCollections.loading;

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack style={{ width: '100%' }}>
        {config.headingText && (
          <AppText style={{ fontSize: 34, fontWeight: 500 }}>
            {config.headingText}
          </AppText>
        )}

        {!loading ? (
          state.ecomStoreCollections.value.length === 0 ? (
            <AppStack style={{ alignItems: 'center', padding: 40 }}>
              <AppText
                style={{
                  color: colorScheme.textColor,
                  fontSize: 18,
                  fontWeight: '500'
                }}
              >
                No collections found
              </AppText>
              <Button
                color={colorScheme.solidButtonColor}
                component={Link}
                onClick={onNavigate}
                size="md"
                style={{ color: colorScheme.solidButtonLabel }}
                to={`${baseStoreUrl}/products`}
                variant="filled"
              >
                Shop all products
              </Button>
            </AppStack>
          ) : (
            <Grid columns={config.desktopColumnCount * 1 ?? 6}>
              {state.ecomStoreCollections.value
                .sort((a, b) => {
                  switch (config.sortBy) {
                    case SORT_BY_ENUM.NAME:
                      return a.name.localeCompare(b.name);
                    case SORT_BY_ENUM.NAME_DESC:
                      return b.name.localeCompare(a.name);
                    case SORT_BY_ENUM.DATE:
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    case SORT_BY_ENUM.DATE_DESC:
                      return new Date(a.createdAt) - new Date(b.createdAt);
                    case SORT_BY_ENUM.COUNT:
                      return (
                        b.ecomStoreCollectionProductCount -
                        a.ecomStoreCollectionProductCount
                      );
                    case SORT_BY_ENUM.COUNT_DESC:
                      return (
                        a.ecomStoreCollectionProductCount -
                        b.ecomStoreCollectionProductCount
                      );
                    default:
                      return 0;
                  }
                })
                .map((c) => (
                  <Grid.Col key={c.pkEcomStoreCollection} span={1}>
                    <WsCollectionCard
                      baseStoreUrl={baseStoreUrl}
                      colorScheme={colorScheme}
                      ecomStoreCollection={c}
                      onNavigate={onNavigate}
                      portraitRatio={!squareRatio}
                    />
                  </Grid.Col>
                ))}
            </Grid>
          )
        ) : (
          <Grid columns={config.desktopColumnCount * 1 ?? 6}>
            {Array.from(Array(config.desktopColumnCount * 1 ?? 6)).map(
              (x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid.Col key={i} span={1}>
                  <WsCollectionCard portraitRatio={!squareRatio} />
                </Grid.Col>
              )
            )}
          </Grid>
        )}
      </AppStack>
    </WsSectionContainer>
  );
};

WsCollectionGrid.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string,
  themeConfig: PropTypes.object
};

export default WsCollectionGrid;
