/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@mantine/core';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import { WS_TEXT_ICONS } from './wsTextIconOptions';

const WsIconWithTextBlock = ({ config, colorScheme }) => {
  const selectedIcon1 = WS_TEXT_ICONS.find(
    (icon) => icon.value === config.icon1Ref
  );
  const selectedIcon2 = WS_TEXT_ICONS.find(
    (icon) => icon.value === config.icon2Ref
  );
  const selectedIcon3 = WS_TEXT_ICONS.find(
    (icon) => icon.value === config.icon3Ref
  );

  return (
    (config.icon1Text || config.icon2Text || config.icon3Text) && (
      <AppFlexbox
        style={{
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: config.layout === 'vertical' ? 'column' : 'row'
        }}
      >
        {config.icon1Text && (
          <AppStack
            style={{
              gap: 8,
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <AppStack
              style={{
                width: 50,
                height: 50,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {config.icon1Image ? (
                <Image
                  fit="contain"
                  h="100%"
                  src={config.icon1Image}
                  w="100%"
                />
              ) : (
                selectedIcon1 && (
                  <selectedIcon1.component
                    color={colorScheme.textColor}
                    size="100%"
                  />
                )
              )}
            </AppStack>
            <AppText
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: colorScheme.textColor
              }}
            >
              {config.icon1Text}
            </AppText>
          </AppStack>
        )}

        {config.icon2Text && (
          <AppStack
            style={{
              gap: 8,
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <AppStack
              style={{
                width: 50,
                height: 50,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {config.icon2Image ? (
                <Image
                  fit="contain"
                  h="100%"
                  src={config.icon2Image}
                  w="100%"
                />
              ) : (
                selectedIcon2 && (
                  <selectedIcon2.component
                    color={colorScheme.textColor}
                    size="100%"
                  />
                )
              )}
            </AppStack>
            <AppText
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: colorScheme.textColor
              }}
            >
              {config.icon2Text}
            </AppText>
          </AppStack>
        )}

        {config.icon3Text && (
          <AppStack
            style={{
              gap: 8,
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <AppStack
              style={{
                width: 50,
                height: 50,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {config.icon3Image ? (
                <Image
                  fit="contain"
                  h="100%"
                  src={config.icon3Image}
                  w="100%"
                />
              ) : (
                selectedIcon3 && (
                  <selectedIcon3.component
                    color={colorScheme.textColor}
                    size="100%"
                  />
                )
              )}
            </AppStack>
            <AppText
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: colorScheme.textColor
              }}
            >
              {config.icon3Text}
            </AppText>
          </AppStack>
        )}
      </AppFlexbox>
    )
  );
};

WsIconWithTextBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object
};

export default WsIconWithTextBlock;
