import { Message } from 'tabler-icons-react';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import WsVideoBlock from './WsVideoBlock';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  title: 'Video',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VIDEO,
  icon: Message,
  Component: WsVideoBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
