import React from 'react';
import { ActionIcon, Loader, Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import { X } from 'tabler-icons-react';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import FormSection from '../../common/FormSection';

const ResponsiveWebstoreModal = ({
  isOpen,
  onClose,
  title,
  size,
  isLoading,
  children,
  noCloseButton,
  onSubmit,
  formSectionProps,
  bodyProps,
  isDrawer,
  mediaQueries,
  noHeader,
  fullScreen,
  closeOnClick,
  editModeEnabled,
  alignModal,
  ...rest
}) => {
  const { isTabletOrSmaller } = mediaQueries;

  return (
    <Modal.Root
      fullScreen={fullScreen}
      onClose={onClose}
      opened={isOpen}
      portalProps={{ target: '#ws-app-container' }}
      position="left"
      withinPortal
      {...rest}
      styles={{
        ...rest.styles,
        header: {
          padding: 16,
          minHeight: 60,
          backgroundColor: '#f3f3f3',
          borderBottom: 'solid 1px #dee2e6',
          ...rest.styles?.header
        },
        title: { flex: 1, padding: 0, ...rest.styles?.title },
        inner: {
          width: '100%',
          height: '100%',
          top: 0,
          padding:
            fullScreen || isDrawer || isTabletOrSmaller ? 0 : '100px 0px',
          position: editModeEnabled ? 'absolute' : 'fixed',
          alignItems: alignModal,
          ...rest.styles?.inner
        },
        body: {
          height: '100%',
          maxHeight: fullScreen ? 'unset' : '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: 0,
          overflow: 'auto',
          ...rest.styles?.body
        },
        root: {
          flex: 1,
          zIndex: 1000,
          ...rest.styles?.root
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          maxWidth: size ?? 600,
          minHeight: 'unset',
          maxHeight: '100%',
          borderRadius: 0,
          overflow: 'hidden',
          ...(fullScreen || isDrawer || isTabletOrSmaller
            ? {
                maxWidth: '100%',
                borderRadius: 0
              }
            : {}),
          ...rest.styles?.content
        },
        overlay: {
          ...rest.styles?.overlay,
          position: editModeEnabled ? 'absolute' : 'fixed'
        }
      }}
    >
      <Modal.Overlay />
      <Modal.Content onClick={closeOnClick ? onClose : null}>
        {!noHeader && (
          <Modal.Header>
            {typeof title === 'string' ? (
              <AppText style={{ fontSize: 16, fontWeight: 650 }}>
                {title}
              </AppText>
            ) : (
              title
            )}

            {!noCloseButton && <Modal.CloseButton />}
          </Modal.Header>
        )}
        <Modal.Body>
          {isLoading ? (
            <AppFlexbox style={{ padding: 40, margin: 'auto' }}>
              <Loader color="dark" size="xl" />
            </AppFlexbox>
          ) : !formSectionProps ? (
            <AppStack
              {...bodyProps}
              style={{
                gap: 16,
                padding: 16,
                flex: 1,
                height: '100%',
                overflow: 'auto',
                position: 'relative',
                ...bodyProps?.style
              }}
            >
              {children}
            </AppStack>
          ) : (
            <FormSection {...formSectionProps}>{children}</FormSection>
          )}
        </Modal.Body>

        {noHeader && !noCloseButton && (
          <ActionIcon
            color="#E1E1E1"
            onClick={onClose}
            radius={100}
            size={40}
            style={{
              position: 'absolute',
              top: 16,
              right: fullScreen ? 32 : 16,
              backgroundColor: '#FFF',
              zIndex: 100
            }}
            variant="outline"
          >
            <X color="#BBB" />
          </ActionIcon>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};

ResponsiveWebstoreModal.propTypes = {
  alignModal: PropTypes.string,
  bodyProps: PropTypes.object,
  children: PropTypes.any,
  closeOnClick: PropTypes.bool,
  editModeEnabled: PropTypes.bool,
  formSectionProps: PropTypes.object,
  fullScreen: PropTypes.bool,
  isDrawer: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  mediaQueries: PropTypes.object,
  noCloseButton: PropTypes.bool,
  noHeader: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  sidePadding: PropTypes.number,
  size: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default ResponsiveWebstoreModal;
