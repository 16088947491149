/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from '@mantine/core';
import AppText from '../../../../common/AppText';
import AppStack from '../../../../common/AppStack';
import AppFlexbox from '../../../../common/AppFlexbox';
import placeholderImage from '../../../../../images/sportsheadz-header-bg.png';
import AppCard from '../../../../common/AppCard';

const WsTextOverImageBlock = ({
  config,
  colorScheme,
  objectPosition,
  hideImage
}) => {
  const containerPosition = config.desktopContentPosition?.split('-') ?? [
    'center',
    'center'
  ];
  const isOutlineVariant = config.useOutlinedButton === 'true';
  const showContainerOnDesktop = config.showContainerOnDesktop === 'true';
  const flipLeftToRight = config?.flipLeftToRight === 'true';
  const flipUpsideDown = config?.flipUpsideDown === 'true';
  return (
    <>
      {!hideImage && (
        <Image
          fit="cover"
          src={config.image ? config.image : placeholderImage}
          style={{
            transform: `scaleX(${flipLeftToRight ? -1 : 1}) scaleY(${
              flipUpsideDown ? -1 : 1
            })`,
            display: 'block',
            maxWidth: '100%',
            postion: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            minHeight: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: objectPosition || 'center center',
            transition: 'opacity .4s cubic-bezier(.25,.46,.45,.94)',
            boxSizing: 'inherit',
            overflow: 'clip',
            overflowClipMargin: 'content-box'
          }}
        />
      )}
      <AppFlexbox
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          opacity: config.imageOverlayOpacity / 100 ?? 0,
          backgroundColor: '#000'
        }}
      />
      <AppFlexbox
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <AppStack
          style={{
            maxWidth:
              config.fullWidthContainerPlacement === 'true' ? '100%' : 1100,
            flex: 1,
            alignItems: containerPosition[1],
            justifyContent: containerPosition[0]
          }}
        >
          <AppCard
            radius="xl"
            style={{
              minWidth: 475,
              maxWidth: 575,
              padding: 35,
              margin: `${config.desktopContentTopMargin}% ${config.desktopContentSideMargin}% ${config.desktopContentBottomMargin}% ${config.desktopContentSideMargin}%`,
              backgroundColor: showContainerOnDesktop
                ? colorScheme.backgroundColor
                : 'unset',
              color: colorScheme.textColor
            }}
          >
            <AppStack
              style={{
                justifyContent: 'start',
                alignItems: config.desktopContentAlignment
              }}
            >
              {config.heading && (
                <AppText
                  style={{
                    fontWeight: 'bold',
                    fontSize: config.headingSize * 1 ?? 24
                  }}
                >
                  {config.heading}
                </AppText>
              )}

              {config.subheading && (
                <AppText style={{ fontSize: `${config.subheadingSize}px` }}>
                  {config.subheading}
                </AppText>
              )}

              {config.buttonLabel &&
                (isOutlineVariant ? (
                  <Button
                    color={colorScheme.outlineButtonColor}
                    component={config.buttonLink ? 'a' : 'button'}
                    href={config.buttonLink}
                    radius="md"
                    size="lg"
                    style={{ color: colorScheme.outlineButtonColor }}
                    type="button"
                    variant="outline"
                  >
                    {config.buttonLabel}
                  </Button>
                ) : (
                  <Button
                    color={colorScheme.solidButtonColor}
                    component={config.buttonLink ? 'a' : 'button'}
                    href={config.buttonLink}
                    radius="md"
                    size="lg"
                    style={{ color: colorScheme.solidButtonLabel }}
                    type="button"
                    variant="filled"
                  >
                    {config.buttonLabel}
                  </Button>
                ))}
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </>
  );
};

WsTextOverImageBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  hideImage: PropTypes.bool,
  objectPosition: PropTypes.string
};

export default WsTextOverImageBlock;
