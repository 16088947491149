import React from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';

const WebstoreColorSchemeDisplay = ({ smallDisplay, colorScheme }) => (
  <AppCard
    radius={10}
    shadow="none"
    style={{
      backgroundColor: colorScheme.backgroundColor,
      color: colorScheme.textColor,
      padding: smallDisplay ? 2 : 5,
      paddingBottom: smallDisplay ? 4 : 10,
      minWidth: smallDisplay ? 55 : 75,
      height: smallDisplay ? 40 : 60,
      maxHeight: smallDisplay ? 40 : 60
    }}
    withBorder
  >
    <AppStack
      style={{
        gap: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <AppText style={{ fontSize: smallDisplay ? 14 : 18 }}>Aa</AppText>
      <AppFlexbox style={{ gap: 5 }}>
        <AppFlexbox
          style={{
            width: smallDisplay ? 10 : 25,
            height: smallDisplay ? 5 : 10,
            borderRadius: 100,
            backgroundColor: colorScheme.solidButtonColor
          }}
        />
        <AppFlexbox
          style={{
            width: smallDisplay ? 10 : 25,
            height: smallDisplay ? 5 : 10,
            borderRadius: 100,
            border: `solid 1px ${colorScheme.outlineButtonColor}`
          }}
        />
      </AppFlexbox>
    </AppStack>
  </AppCard>
);

WebstoreColorSchemeDisplay.propTypes = {
  colorScheme: PropTypes.object,
  smallDisplay: PropTypes.bool
};

export default WebstoreColorSchemeDisplay;
