const AVAILABLE_ECOM_REPORTS = [
  {
    key: 1,
    uuid: 'PRODUCT_SALES',
    name: 'Product sales report',
    description: 'A report of all product sales',
    isGlobal: true
  },
  {
    key: 2,
    uuid: 'PRODUCT_HISTORY',
    name: 'Product history report',
    description: 'A report of all products added to your store.',
    isStoreReport: true
  }
];

export { AVAILABLE_ECOM_REPORTS };
