import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, Image } from '@mantine/core';
import { ChevronDown, ChevronUp, Plus } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import WebstoreBuilderSidebarButton from './WebstoreBuilderSidebarButton';
import AppCard from '../../common/AppCard';
import MediaDropzone from '../media/MediaDropzone';
import WebstoreBuilderConfigSidebarInput from './WebstoreBuilderConfigSidebarInput';
import WebstoreColorSchemeEdit from './WebstoreColorSchemeEdit';
import {
  configSections,
  configDefaultValues,
  WEBSTORE_SETTING_TYPE_ENUM
} from './wsAppSettingConfig';

const WebstoreBuilderSidebarSettings = ({
  ecomStoreTheme,
  onSelectSetting,
  onSelectSettingItem,
  onAddColorScheme,
  onChangeColorScheme,
  onChangeLogoImage,
  onChangeSetting,
  editState,
  controlState,
  onDeleteColorScheme,
  viewState
}) =>
  ecomStoreTheme && (
    <AppStack
      style={{
        flex: 1,
        gap: 0,
        overflow: 'hidden'
      }}
    >
      {controlState.selectedSettingItem ? (
        <AppStack
          style={{
            flex: 1,
            gap: 0
          }}
        >
          {controlState.selectedSetting ===
          WEBSTORE_SETTING_TYPE_ENUM.COLORS ? (
            <WebstoreColorSchemeEdit
              ecomStoreThemeColorSchemes={editState.ecomStoreThemeColorSchemes}
              editState={editState}
              onChangeColorScheme={onChangeColorScheme}
              onClose={() =>
                onSelectSettingItem(null, controlState.selectedSetting)
              }
              onDeleteColorScheme={onDeleteColorScheme}
              onDuplicateColorScheme={onAddColorScheme}
              selectedColorKey={controlState.selectedSettingItem}
            />
          ) : (
            <></>
          )}
        </AppStack>
      ) : (
        <>
          <AppFlexbox style={{ padding: 10 }}>
            <AppText style={{ fontSize: 18, fontWeight: 500 }}>
              Settings
            </AppText>
          </AppFlexbox>
          <Divider />
          <AppStack style={{ flex: 1, gap: 0, overflow: 'auto' }}>
            {Object.values(configSections).map((configSection) => {
              const currentSettingConfig = {
                ...configDefaultValues,
                ...editState.ecomStoreThemeConfig
              };

              const isOpen =
                controlState.selectedSetting === configSection.value;
              return (
                <React.Fragment key={configSection.value}>
                  <WebstoreBuilderSidebarButton
                    fontSize={16}
                    fontWeight={500}
                    height={50}
                    label={configSection.title}
                    onClick={() => onSelectSetting(configSection.value)}
                    radius={0}
                    rightSection={
                      isOpen ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )
                    }
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                  />
                  <AppStack
                    style={{
                      gap: 0,
                      borderBottom: 'solid 1px #dee2e6'
                    }}
                  >
                    {isOpen && (
                      <AppStack style={{ padding: '5px 20px 10px 20px' }}>
                        {configSection.value ===
                        WEBSTORE_SETTING_TYPE_ENUM.COLORS ? (
                          <Grid>
                            {editState.ecomStoreThemeColorSchemes.map(
                              (c, index) => (
                                <Grid.Col key={c.key} span={6}>
                                  <AppStack
                                    onClick={() => {
                                      onSelectSettingItem(
                                        c.key,
                                        configSection.value
                                      );
                                    }}
                                    style={{
                                      gap: 5,
                                      cursor: 'pointer',
                                      height: '100%'
                                    }}
                                  >
                                    <AppCard
                                      radius={10}
                                      shadow="none"
                                      style={{
                                        backgroundColor: c.backgroundColor,
                                        color: c.textColor,
                                        padding: 5,
                                        paddingBottom: 10,
                                        flex: 1
                                      }}
                                      withBorder
                                    >
                                      <AppStack
                                        style={{
                                          gap: 0,
                                          flex: 1,
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <AppText style={{ fontSize: 26 }}>
                                          Aa
                                        </AppText>
                                        <AppFlexbox style={{ gap: 5 }}>
                                          <AppFlexbox
                                            style={{
                                              width: 25,
                                              height: 10,
                                              borderRadius: 100,
                                              backgroundColor:
                                                c.solidButtonColor
                                            }}
                                          />
                                          <AppFlexbox
                                            style={{
                                              width: 25,
                                              height: 10,
                                              borderRadius: 100,
                                              border: `solid 1px ${c.outlineButtonColor}`
                                            }}
                                          />
                                        </AppFlexbox>
                                      </AppStack>
                                    </AppCard>
                                    <AppText
                                      style={{
                                        textAlign: 'center',
                                        fontWeight: 500,
                                        fontSize: 14
                                      }}
                                    >
                                      Scheme {index + 1}
                                    </AppText>
                                  </AppStack>
                                </Grid.Col>
                              )
                            )}
                            <Grid.Col span={6}>
                              <AppStack
                                onClick={() => {
                                  onAddColorScheme();
                                }}
                                style={{
                                  gap: 5,
                                  cursor: 'pointer',
                                  color: 'dodgerblue',
                                  height: '100%'
                                }}
                              >
                                <AppCard
                                  radius={10}
                                  shadow="none"
                                  style={{
                                    border: 'dashed 1px dodgerblue',
                                    flex: 1,
                                    minHeight: 70
                                  }}
                                >
                                  <AppStack
                                    style={{
                                      gap: 0,
                                      flex: 1,
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Plus color="dodgerblue" size={18} />
                                  </AppStack>
                                </AppCard>
                                <AppText
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 500,
                                    fontSize: 14
                                  }}
                                >
                                  Add Scheme
                                </AppText>
                              </AppStack>
                            </Grid.Col>
                          </Grid>
                        ) : (
                          <>
                            {configSection.value ===
                              WEBSTORE_SETTING_TYPE_ENUM.LOGO && (
                              <AppStack style={{}}>
                                <AppStack style={{ gap: 5 }}>
                                  <AppText
                                    style={{ fontSize: 14, fontWeight: 500 }}
                                  >
                                    Logo
                                  </AppText>

                                  {editState.logoImageUrl ? (
                                    <AppStack style={{ gap: 10 }}>
                                      <AppStack
                                        style={{ width: '100%', height: 120 }}
                                      >
                                        <Image
                                          fit="contain"
                                          h={120}
                                          src={editState.logoImageUrl}
                                        />
                                      </AppStack>
                                      <Button
                                        color="dark"
                                        onClick={() => onChangeLogoImage(null)}
                                        size="compact-md"
                                        style={{
                                          fontSize: 14,
                                          outline: 'solid 1px #666'
                                        }}
                                        variant="white"
                                      >
                                        Remove logo
                                      </Button>
                                    </AppStack>
                                  ) : (
                                    <AppStack
                                      style={{ width: '100%', height: 120 }}
                                    >
                                      <MediaDropzone
                                        enableMediaSelect
                                        files={[]}
                                        fkEcomStore={ecomStoreTheme.fkEcomStore}
                                        height={120}
                                        onUpload={(media) =>
                                          onChangeLogoImage(media[0])
                                        }
                                        skipMediaFetch
                                        uploadOnDrop
                                      />
                                    </AppStack>
                                  )}
                                </AppStack>
                              </AppStack>
                            )}
                            {Object.keys(currentSettingConfig)
                              .filter(
                                (key) =>
                                  !configSection.configOptions[key]
                                    ?.isVisible ||
                                  configSection.configOptions[key].isVisible(
                                    currentSettingConfig,
                                    editState
                                  )
                              )
                              .map((key) => (
                                <WebstoreBuilderConfigSidebarInput
                                  key={key}
                                  fkEcomStore={ecomStoreTheme.fkEcomStore}
                                  onAddColorScheme={onAddColorScheme}
                                  onChange={(value) => {
                                    onChangeSetting(key, value);
                                  }}
                                  value={currentSettingConfig[key]}
                                  {...configSection.configOptions[key]}
                                />
                              ))}
                          </>
                        )}
                      </AppStack>
                    )}
                  </AppStack>
                </React.Fragment>
              );
            })}
          </AppStack>
        </>
      )}
    </AppStack>
  );

WebstoreBuilderSidebarSettings.propTypes = {
  controlState: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editState: PropTypes.object,
  onAddColorScheme: PropTypes.func,
  onChangeColorScheme: PropTypes.func,
  onChangeLogoImage: PropTypes.func,
  onChangeSetting: PropTypes.func,
  onDeleteColorScheme: PropTypes.func,
  onSelectSetting: PropTypes.func,
  onSelectSettingItem: PropTypes.func,
  viewState: PropTypes.object
};

export default WebstoreBuilderSidebarSettings;
