import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  ECOM_ENTITY_TYPES,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsFeaturedProduct from './WsFeaturedProduct';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';

const MEDIA_POSITION_ENUM = {
  LEFT: 'left',
  RIGHT: 'right'
};

const MEDIA_SIZE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

const configNameValues = {
  ecomStoreProduct: 'ecomStoreProduct',
  colorScheme: 'colorScheme',
  desktopMediaPosition: 'desktopMediaPosition',
  mediaSize: 'mediaSize',
  switchToVariantImage: 'switchToVariantImage'
};

const configDefaultValues = {
  [configNameValues.ecomStoreProduct]: '',
  [configNameValues.colorScheme]: '1',
  [configNameValues.desktopMediaPosition]: MEDIA_POSITION_ENUM.LEFT,
  [configNameValues.mediaSize]: MEDIA_SIZE_ENUM.MEDIUM,
  [configNameValues.switchToVariantImage]: 'true'
};

const configOptions = {
  [configNameValues.ecomStoreProduct]: {
    label: 'Product',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreProduct
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.desktopMediaPosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop content alignment',
    category: 'Media',
    options: [
      {
        value: MEDIA_POSITION_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: MEDIA_POSITION_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  },
  [configNameValues.mediaSize]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Media size',
    category: 'Media',
    options: [
      {
        value: MEDIA_SIZE_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: MEDIA_SIZE_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: MEDIA_SIZE_ENUM.LARGE,
        label: 'Large'
      }
    ]
  },
  [configNameValues.switchToVariantImage]: {
    label: 'Switch to variant image after selecting',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Media'
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.FEATURED_PRODUCT,
  title: 'Featured product',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsFeaturedProduct,
  configOptions,
  maxItemCount: null,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT
      ],
      defaultCount: 0,
      maxCount: null
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.INVENTORY_STATUS
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VENDOR_TITLE
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TITLE
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.PRICE
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VARIANT_PICKER
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.QUANTITY_SELECTOR
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUY_BUTTONS
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.SKU
      ],
      defaultCount: 0,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ICON_WITH_TEXT
      ],
      defaultCount: 0,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues,
  onFetchDataConfig: (sectionConfigValues) => ({
    type: 'ecomStoreProduct',
    entityId: sectionConfigValues.ecomStoreProduct,
    filter: {}
  })
};

export { config, configOptions, configNameValues, configDefaultValues };
