import React from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Indicator } from '@mantine/core';

const ActionableIcon = ({
  size,
  children,
  indicatorValue,
  indicatorProps,
  ...rest
}) => (
  <ActionIcon
    size={size ?? 30}
    variant="transparent"
    {...rest}
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      overflow: 'visible',
      ...rest.style
    }}
  >
    <Indicator
      color="red"
      disabled={!indicatorValue}
      label={indicatorValue}
      size={16}
      {...indicatorProps}
    >
      {children}
    </Indicator>
  </ActionIcon>
);

ActionableIcon.propTypes = {
  children: PropTypes.node,
  indicatorProps: PropTypes.object,
  indicatorValue: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ActionableIcon;
