/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Button, Skeleton } from '@mantine/core';
import { ArrowRight, Shirt } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppStack from '../../../../common/AppStack';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import AppFlexbox from '../../../../common/AppFlexbox';
import { useCartController } from '../../../../../helpers/webstoreCheckoutHelper';
import { triggerNotification } from '../../../../../helpers/notification';
import {
  MEDIA_POSITION_ENUM,
  MEDIA_SIZE_ENUM
} from './wsProductInformationConfig';
import { getVariantOptions } from '../../../../../helpers/webstoreHelper';
import WsBlock from '../../blocks/WsBlock';
import WsProductMediaShowcase from './WsProductMediaShowcase';
import AppText from '../../../../common/AppText';

const WsProductInformationDisplay = ({
  config,
  ecomStoreProduct,
  colorSchemes,
  baseStoreUrl,
  onNavigate,
  ecomStoreTheme,
  mediaQueries,
  blocks,
  editModeEnabled,
  productNotFound,
  isPreview
}) => {
  const { state: authState } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    selectedOptions: [],
    ecomStoreCartProductInputAnswers: [],
    optionErrors: [],
    count: 1,
    loading: false
  });
  const { state } = useContext(StoreUserContext);
  const { ecomStoreCart, addStoreProducts } = useCartController();
  const { isTabletOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const isSmall = config.mediaSize === MEDIA_SIZE_ENUM.SMALL;
  const isMedium = config.mediaSize === MEDIA_SIZE_ENUM.MEDIUM;
  const switchToVariantImageOnSelect = config.switchToVariantImage !== 'false';

  const variantOptions = getVariantOptions(ecomStoreProduct);
  const selectedVariant = ecomStoreProduct?.ecomStoreProductVariants.find(
    (v) =>
      formState.selectedOptions.length ===
        v.ecomVendorProductVariant.ecomVendorProductVariantOptions.length &&
      v.ecomVendorProductVariant.ecomVendorProductVariantOptions.every((op) =>
        formState.selectedOptions.some(
          (f) => f.name === op.name && f.value === op.value
        )
      )
  );

  useEffect(() => {
    if (ecomStoreProduct) {
      setFormState({
        selectedOptions: variantOptions.map((op) => ({
          nameSort: op.sort,
          name: op.name,
          value: op.values[0].value
        })),
        ecomStoreCartProductInputAnswers: ecomStoreProduct.ecomStoreProductInputAnswers
          .map((a) => {
            const foundInput = ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs.find(
              (i) =>
                i.fkRegFormControlType === a.fkRegFormControlType &&
                (i.pkEcomVendorProductInput === a.fkEcomVendorProductInput ||
                  i.label.toLowerCase() === a.label.toLowerCase())
            );
            return foundInput
              ? {
                  isAutopopulated: true,
                  fkEcomVendorProductInput: foundInput.pkEcomVendorProductInput,
                  fkRegFormControlType: foundInput.fkRegFormControlType,
                  file: null,
                  value: a.value
                }
              : null;
          })
          .filter((f) => !!f),
        optionErrors: [],
        count: 1,
        loading: false
      });
    }
  }, [ecomStoreProduct]);

  const onAddProductToCart = () => {
    setFormState({
      ...formState,
      optionErrors: [],
      loading: true
    });
    addStoreProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      {
        ecomStoreCartProducts: [
          {
            ecomStoreProduct,
            uuid: selectedVariant.ecomVendorProductVariant.uuid,
            count: formState.count,
            ecomStoreCartProductInputAnswers:
              formState.ecomStoreCartProductInputAnswers
          }
        ]
      },
      () => {
        setFormState({
          ...formState,
          optionErrors: [],
          loading: false
        });
      },
      (error, type) => {
        if (type === 'input') {
          setFormState({
            ...formState,
            optionErrors: [error],
            loading: false
          });
        }
        else {
          triggerNotification(error);
          setFormState({
            ...formState,
            optionErrors: [],
            loading: false
          });
        }
      }
    );
  };

  return productNotFound ? (
    <AppStack
      style={{
        gap: 0,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Shirt color={colorScheme.textColor} size="35%" />

      <AppStack style={{ gap: 20 }}>
        <AppText
          style={{
            fontSize: 22,
            fontWeight: 500,
            color: colorScheme.textColor
          }}
        >
          Product not found
        </AppText>
        <Button
          color={colorScheme.outlineButtonColor}
          component={Link}
          onClick={onNavigate}
          style={{ color: colorScheme.outlineButtonColor }}
          to={`${baseStoreUrl}/products`}
          variant="outline"
        >
          View all products
        </Button>
      </AppStack>
    </AppStack>
  ) : ecomStoreProduct ? (
    <AppFlexbox
      style={{
        width: '100%',
        padding: '20px 0px',
        flexDirection: isTabletOrSmaller
          ? 'column'
          : config.desktopMediaPosition === MEDIA_POSITION_ENUM.LEFT
          ? 'row'
          : 'row-reverse'
      }}
    >
      <AppStack
        style={{
          width: '100%',
          maxWidth: isTabletOrSmaller
            ? 'unset'
            : isSmall
            ? '45%'
            : isMedium
            ? '55%'
            : '65%',
          gap: 5
        }}
      >
        <WsProductMediaShowcase
          colorScheme={colorScheme}
          desktopMediaLayout={config.desktopMediaLayout}
          ecomVendorProductMedia={
            ecomStoreProduct.ecomVendorProduct.ecomVendorProductMedia
          }
          editModeEnabled={editModeEnabled}
          isPreview={isPreview}
          mediaQueries={mediaQueries}
          mediaSize={config.mediaSize}
          selectedVariant={selectedVariant}
          switchToVariantImage={switchToVariantImageOnSelect}
          thumbnailColumns={isSmall ? 4 : isMedium ? 5 : 6}
        />
      </AppStack>

      <AppStack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onAddProductToCart();
        }}
        style={{
          width: '100%',
          maxWidth: isTabletOrSmaller
            ? 'unset'
            : isSmall
            ? '55%'
            : isMedium
            ? '45%'
            : '35%',
          marginRight:
            isTabletOrSmaller ||
            config.desktopMediaPosition === MEDIA_POSITION_ENUM.LEFT
              ? 0
              : 50,
          marginLeft:
            isTabletOrSmaller ||
            config.desktopMediaPosition !== MEDIA_POSITION_ENUM.LEFT
              ? 0
              : 50,
          gap: 5
        }}
      >
        <AppStack style={{ gap: 8 }}>
          {blocks.map((b) => (
            <WsBlock
              baseStoreUrl={baseStoreUrl}
              colorScheme={colorScheme}
              ecomStoreCart={ecomStoreCart}
              ecomStoreCartProductInputAnswers={
                formState.ecomStoreCartProductInputAnswers
              }
              ecomStoreProduct={ecomStoreProduct}
              ecomStoreTheme={ecomStoreTheme}
              entity={{
                title: ecomStoreProduct.ecomVendorProduct.name,
                description: ecomStoreProduct.ecomVendorProduct.description
              }}
              mediaQueries={mediaQueries}
              onAddToCart={onAddProductToCart}
              onInputChange={(answers) =>
                setFormState({
                  ...formState,
                  optionErrors: [],
                  ecomStoreCartProductInputAnswers: answers
                })
              }
              onNavigate={onNavigate}
              onQuantityChange={(count) =>
                setFormState({
                  ...formState,
                  optionErrors: [],
                  count
                })
              }
              onVariantChange={(options) =>
                setFormState({
                  ...formState,
                  optionErrors: [],
                  selectedOptions: options
                })
              }
              optionErrors={formState.optionErrors}
              quantity={formState.count}
              selectedOptions={formState.selectedOptions}
              selectedVariant={selectedVariant}
              submitting={formState.loading}
              variantOptions={variantOptions}
              {...b}
            />
          ))}
          {isPreview && (
            <AppFlexbox
              style={{
                gap: 8,
                alignItems: 'center',
                marginTop: 5
              }}
            >
              <Anchor
                c="#000"
                component={Link}
                onClick={onNavigate}
                style={{ fontSize: 14, color: colorScheme.textColor }}
                to={`${baseStoreUrl}/products/${ecomStoreProduct.urlHandle}`}
              >
                View full details
              </Anchor>
              <ArrowRight color={colorScheme.textColor} size={16} />
            </AppFlexbox>
          )}
        </AppStack>
      </AppStack>
    </AppFlexbox>
  ) : (
    <AppFlexbox
      style={{
        width: '100%',
        padding: '20px 0px',
        flexDirection: isTabletOrSmaller
          ? 'column'
          : config.desktopMediaPosition === MEDIA_POSITION_ENUM.LEFT
          ? 'row'
          : 'row-reverse'
      }}
    >
      <AppStack
        style={{
          width: '100%',
          maxWidth: isTabletOrSmaller
            ? 'unset'
            : isSmall
            ? '45%'
            : isMedium
            ? '55%'
            : '65%',
          gap: 5
        }}
      >
        <Skeleton height={600} width="100%" />
      </AppStack>

      <AppStack
        style={{
          width: '100%',
          maxWidth: isSmall ? '55%' : isMedium ? '45%' : '35%',
          marginRight:
            isTabletOrSmaller ||
            config.desktopMediaPosition === MEDIA_POSITION_ENUM.LEFT
              ? 0
              : 50,
          marginLeft:
            isTabletOrSmaller ||
            config.desktopMediaPosition !== MEDIA_POSITION_ENUM.LEFT
              ? 0
              : 50,
          gap: 5
        }}
      >
        <AppStack style={{ gap: 8 }}>
          <Skeleton height={20} width="100%" />
          <Skeleton height={20} width="40%" />
          <Skeleton height={20} width="40%" />
          <Skeleton height={20} width="80%" />
        </AppStack>
      </AppStack>
    </AppFlexbox>
  );
};

WsProductInformationDisplay.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreProduct: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  isPreview: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  productNotFound: PropTypes.bool,
  themeConfig: PropTypes.object
};

export default WsProductInformationDisplay;
