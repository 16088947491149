import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@mantine/core';
import ReportTableColumn from './ReportTableColumn';
import { getDateRangeLabel } from '../../../helpers/format';

const isRenderSameAsBefore = (prevProps, nextProps) =>
  prevProps.showFirstColumnBorder === nextProps.showFirstColumnBorder &&
  prevProps.showSummary === nextProps.showSummary &&
  prevProps.pageIndex === nextProps.pageIndex &&
  prevProps.maxPageCount === nextProps.maxPageCount &&
  prevProps.data.length === nextProps.data.length &&
  prevProps.headers.length === nextProps.headers.length &&
  prevProps.headers.every((d, i) => nextProps.headers[i].field === d.field) &&
  (!prevProps.compareData ||
    !nextProps.compareData ||
    (prevProps.compareData.length === nextProps.compareData.length &&
      prevProps.compareData.every((d, i) =>
        d.every((c, j) => c === nextProps.compareData[i][j])
      ))) &&
  prevProps.data.every((d, i) => d.every((c, j) => c === nextProps.data[i][j]));

const summarizeData = (data, headers) =>
  data.reduce((accumulator, current) => {
    if (accumulator.length === 0) {
      headers.forEach((h) => {
        if (h.dataType === 'number' || h.dataType === 'currency') {
          accumulator.push(0);
        }
        else {
          accumulator.push(null);
        }
      });
    }

    headers.forEach((h, i) => {
      switch (h.dataType) {
        case 'number':
        case 'currency':
          accumulator[i] += Number(current[i]);
          break;
        case 'percentage':
          break;
        case 'date':
          if (!accumulator[i]) {
            const firstDate = data[0][i];
            const lastDate = data[data.length - 1][i];
            accumulator[i] = getDateRangeLabel(firstDate, lastDate);
          }
          break;
        default:
          accumulator[i] = '-';
          break;
      }
    });

    return accumulator; // Return the accumulator
  }, []);

const ReportTableBody = React.memo(
  ({
    data,
    compareData,
    headers,
    showFirstColumnBorder,
    showSummary,
    pageIndex,
    maxPageCount
  }) => {
    const dataSummarized = summarizeData(data, headers);
    const compareDataSummarized = compareData
      ? summarizeData(compareData, headers)
      : null;

    return (
      <Table.Tbody style={{ position: 'relative' }}>
        {showSummary && (
          <Table.Tr style={{ backgroundColor: '#F1F1F1' }}>
            {dataSummarized.map((c, i) => (
              <ReportTableColumn
                key={`${c}-${headers[i].field}`}
                compareValue={
                  compareDataSummarized ? compareDataSummarized[i] : null
                }
                header={headers[i]}
                index={i}
                isSummary
                showFirstColumnBorder={showFirstColumnBorder}
                value={c}
              />
            ))}
          </Table.Tr>
        )}

        {data.slice(0, pageIndex * maxPageCount).map((d, rowIndex) => {
          const compareDataRow = compareData ? compareData[rowIndex] : null;

          return (
            <Table.Tr key={d[0]}>
              {d.map((c, i) => (
                <ReportTableColumn
                  key={`${c}-${headers[i].field}`}
                  compareValue={compareDataRow && compareDataRow[i]}
                  header={headers[i]}
                  index={i}
                  showFirstColumnBorder={showFirstColumnBorder}
                  value={c}
                />
              ))}
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    );
  },
  isRenderSameAsBefore
);

ReportTableBody.propTypes = {
  compareData: PropTypes.array,
  data: PropTypes.array,
  headers: PropTypes.array,
  maxPageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  showFirstColumnBorder: PropTypes.bool,
  showSummary: PropTypes.bool
};

export default ReportTableBody;
