import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import StoreProductTable from './StoreProductTable';
import VendorCatalogView from '../vendorCatalog/VendorCatalogView';
import BulkEditStoreProductsView from './BulkEditStoreProductsView';

const StoreProductsManageView = () => (
  <Routes>
    <Route element={<VendorCatalogView />} path="/discover/*" />
    <Route element={<></>} path="/:ecomStoreProductUuid" />
    <Route element={<StoreProductTable />} path="/" />
    <Route element={<Navigate replace to="/merchant/products" />} path="*" />
  </Routes>
);

const StoreProductsView = () => (
  <Routes>
    <Route element={<BulkEditStoreProductsView />} path="/bulk-edit/*" />
    <Route element={<StoreProductsManageView />} path="*" />
  </Routes>
);

export default StoreProductsView;
