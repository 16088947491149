import { Article } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM,
  ECOM_ENTITY_TYPES
} from '../../../../../config/constants';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';
import WsCollapsibleContent from './WsCollapsibleContent';

const ALIGNMENT_ENUM = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
};

const IMAGE_PLACEMENT_ENUM = {
  LEFT: 'left',
  RIGHT: 'right'
};

const LAYOUT_ENUM = {
  DEFAULT: 'default',
  CONTAINED: 'contained',
  FILLED: 'filled',
  SEPERATED: 'seperated'
};

const IMAGE_SIZE_ENUM = {
  ADAPT_TO_IMAGE: 'adapt-to-image',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  CUSTOM: 'custom'
};

const configNameValues = {
  caption: 'caption',
  heading: 'heading',
  headingSize: 'headingSize',
  headingAlignment: 'headingAlignment',
  layoutStyle: 'layoutStyle',
  filledContainer: 'filledContainer',
  colorScheme: 'colorScheme',
  containerColorScheme: 'containerColorScheme',
  openFirstRow: 'openFirstRow',
  image: 'image',
  imageSize: 'imageSize',
  imagePlacement: 'imagePlacement'
};

const configDefaultValues = {
  [configNameValues.caption]: '',
  [configNameValues.heading]: 'Collapsible content',
  [configNameValues.headingSize]: '24',
  [configNameValues.headingAlignment]: ALIGNMENT_ENUM.CENTER,
  [configNameValues.layoutStyle]: LAYOUT_ENUM.DEFAULT,
  [configNameValues.filledContainer]: 'false',
  [configNameValues.colorScheme]: '1',
  [configNameValues.containerColorScheme]: '1',
  [configNameValues.openFirstRow]: 'false',
  [configNameValues.image]: '',
  [configNameValues.imageSize]: IMAGE_SIZE_ENUM.SMALL,
  [configNameValues.imagePlacement]: IMAGE_PLACEMENT_ENUM.LEFT
};

const configOptions = {
  [configNameValues.caption]: {
    label: 'Caption',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.heading]: {
    label: 'Heading',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.headingSize]: {
    label: 'Heading Size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 100,
    unit: 'px'
  },
  [configNameValues.headingAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Heading alignment',
    options: [
      { value: ALIGNMENT_ENUM.START, label: 'Left' },
      { value: ALIGNMENT_ENUM.CENTER, label: 'Center' },
      { value: ALIGNMENT_ENUM.END, label: 'Right' }
    ]
  },
  [configNameValues.layoutStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Layout style',
    options: [
      { value: LAYOUT_ENUM.DEFAULT, label: 'Default' },
      { value: LAYOUT_ENUM.CONTAINED, label: 'Contained' },
      { value: LAYOUT_ENUM.FILLED, label: 'Filled' },
      { value: LAYOUT_ENUM.SEPERATED, label: 'Seperated' }
    ]
  },
  [configNameValues.filledContainer]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Filled container'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.containerColorScheme]: {
    label: 'Container color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.openFirstRow]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Open first row'
  },
  [configNameValues.image]: {
    label: 'Image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  },
  [configNameValues.imageSize]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Image size',
    options: [
      {
        value: IMAGE_SIZE_ENUM.ADAPT_TO_IMAGE,
        label: 'Adapt to first image'
      },
      { value: IMAGE_SIZE_ENUM.SMALL, label: 'Small' },
      { value: IMAGE_SIZE_ENUM.MEDIUM, label: 'Medium' },
      { value: IMAGE_SIZE_ENUM.LARGE, label: 'Large' },
      { value: IMAGE_SIZE_ENUM.CUSTOM, label: 'Custom' }
    ]
  },
  [configNameValues.imagePlacement]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Image placement',
    options: [
      { value: IMAGE_PLACEMENT_ENUM.LEFT, label: 'Left' },
      { value: IMAGE_PLACEMENT_ENUM.RIGHT, label: 'Right' }
    ]
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLAPSIBLE_CONTENT,
  title: 'Collapsible content',
  icon: Article,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsCollapsibleContent,
  configOptions,
  maxItemCount: null,
  cannotCreate: true,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ACCORDION
      ],
      title: 'Collapsible row',
      defaultCount: 3,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
