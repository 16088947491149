import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Button, Divider, Menu } from '@mantine/core';
import {
  Archive,
  Dots,
  Eye,
  Pencil,
  Search,
  Shirt,
  Trash
} from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import TableView from '../../common/TableView';
import {
  ECOM_PRODUCT_PRICING_TYPE_ENUM,
  ECOM_PRODUCT_PRICING_TYPE_LIST,
  ECOM_PRODUCT_STATUS_LIST,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import {
  currencyFormat,
  formatUtcDate,
  singularPluralFormat
} from '../../../helpers/format';
import { triggerNotification } from '../../../helpers/notification';
import HeaderView from '../../common/HeaderView';
import { useModalState, usePaginationFilter } from '../../../helpers/hooks';
import AppMenu from '../../common/AppMenu';
import AppStack from '../../common/AppStack';
import ConfirmModal from '../../common/ConfirmModal';
import AppText from '../../common/AppText';
import ChangeProductStatusModal from '../products/ChangeProductStatusModal';
import { getEcomStoreProductStatus } from '../../../helpers/vendorHelper';

const TABLE_COLUMNS = [
  {
    label: 'Product',
    value: 'productName',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Vendor price',
    value: 'vendor_price',
    sortable: true
  },
  {
    label: 'Pricing',
    value: 'pricing',
    sortable: true
  },
  {
    label: 'Rounding adjustment',
    value: 'rounding',
    sortable: true
  },
  {
    label: 'Store price',
    value: 'store_price',
    sortable: true
  },
  {
    label: 'Service fees',
    value: 'fees',
    sortable: true
  },
  {
    label: 'Webstore price',
    value: 'webstore',
    sortable: true
  },
  {
    label: 'Profit',
    value: 'profit',
    sortable: false
  },
  {
    label: 'Added',
    value: 'created',
    sortable: true
  }
];

const VIEW_ACTIONS = [
  {
    label: 'View',
    value: VIEW_ACTIONS_ENUM.VIEW,
    section: 1,
    icon: <Search size={18} />
  },
  {
    label: 'Change status',
    value: VIEW_ACTIONS_ENUM.CHANGE_STATUS,
    section: 1,
    icon: <Eye size={18} />
  },
  {
    label: 'Archive product',
    value: VIEW_ACTIONS_ENUM.ARCHIVE,
    icon: <Archive size={18} />,
    section: 2
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.DELETE,
    icon: <Trash color="red" size={18} />,
    section: 2,
    color: 'red'
  }
];

const StoreProductTable = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchPaginatedStoreProducts,
    deleteStoreProducts
  } = useContext(StoreContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    pageIndex,
    pageSize,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: productData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      status: ''
    },
    (newFilter) => {
      fetchPaginatedStoreProducts(
        authState.pkEcomStore,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedEcomStoreProducts
  );
  const loading = !hasFetched.current || dataLoading;
  const noProductsCreated = !loading && totalNoFilterCount === 0;
  const selectedData = productData.filter((f) =>
    selectedRows.includes(f.pkEcomStoreProduct)
  );

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchPaginatedStoreProducts(authState.pkEcomStore, {}, null, (error) => {
        triggerNotification(error);
      });
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Products',
          to: '/merchant/products'
        }
      ]}
      rightSection={
        !loading && (
          <>
            {!noProductsCreated && (
              <Button
                color="dark"
                onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EXPORT)}
                radius="md"
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="outline"
                visibleFrom="xsm"
              >
                Export
              </Button>
            )}

            <Button
              color="dark"
              onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.IMPORT)}
              radius="md"
              size="compact-md"
              style={{ fontSize: 14 }}
              variant="outline"
              visibleFrom="xsm"
            >
              Import
            </Button>
            <AppMenu
              control={
                <ActionIcon
                  color="dark"
                  radius="md"
                  size="lg"
                  style={{ height: '100%', maxHeight: '100%' }}
                  variant="light"
                >
                  <Dots color="#000" />
                </ActionIcon>
              }
              hiddenFrom="xsm"
              styles={{ dropdown: { padding: 0 } }}
            >
              <Menu.Item onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EXPORT)}>
                Export
              </Menu.Item>
              <Menu.Item onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.IMPORT)}>
                Import
              </Menu.Item>
            </AppMenu>
            <Button
              color="dark"
              leftSection={<Search size={18} />}
              onClick={() => navigate('/merchant/products/discover')}
              radius="md"
              size="compact-md"
              style={{ fontSize: 14 }}
            >
              Find products
            </Button>
          </>
        )
      }
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={noProductsCreated}
        disabledContent={{
          title: 'Find products',
          description: 'Start by adding a product to your store.',
          icon: <Shirt color="#000" size={125} />,
          primaryButton: {
            label: 'Find product',
            icon: <Search size={18} />,
            link: '/merchant/products/discover'
          }
        }}
        emptyMessage="No products found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by product',
            value: filter.search,
            onChange: (value) => onFilterChange({ ...filter, search: value })
          },
          {
            key: 3,
            label: 'Pricing type',
            type: 'select',
            value: filter.fkEcomPricingType ?? null,
            placeholder: 'Filter by pricing',
            searchable: true,
            clearable: true,
            data: ECOM_PRODUCT_PRICING_TYPE_LIST,
            onChange: (value) =>
              onFilterChange({ ...filter, fkEcomPricingType: value }, true)
          },
          {
            key: 2,
            label: 'Status',
            type: 'select',
            value: filter.fkEcomProductStatus ?? null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: ECOM_PRODUCT_STATUS_LIST,
            onChange: (status) =>
              onFilterChange({ ...filter, fkEcomProductStatus: status }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onAction={(action, item) => {
          const product = productData.find(
            (s) => s.pkEcomStoreProduct === item.key
          );
          if (product) {
            switch (action) {
              case VIEW_ACTIONS_ENUM.VIEW:
                navigate(`/merchant/products/${product.uuid}`);
                break;
              case VIEW_ACTIONS_ENUM.CHANGE_STATUS:
              case VIEW_ACTIONS_ENUM.ARCHIVE:
              case VIEW_ACTIONS_ENUM.DELETE:
              default:
                onOpenModal(action, [product]);
                break;
            }
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        onSelect={setSelectedRows}
        pageIndex={pageIndex}
        rows={productData.map((a) => {
          const pricingPrefix =
            a.fkEcomPricingTypes.length === 1 &&
            a.fkEcomPricingTypes[0]?.toString() ===
              ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT.toString()
              ? '$'
              : '';
          const pricingSuffix = a.fkEcomPricingTypes.some(
            (fk) =>
              fk.toString() ===
              ECOM_PRODUCT_PRICING_TYPE_ENUM.PERCENTAGE_ADJUSTMENT.toString()
          )
            ? '%'
            : '';
          const pricingAmountString = `${
            a.minPricingAmount === a.maxPricingAmount ? '' : 'From '
          }${
            pricingPrefix && !pricingSuffix ? pricingPrefix : ''
          }${currencyFormat(a.minPricingAmount / 100).replace(
            '$',
            ''
          )}${pricingSuffix}`;

          const minServiceFee =
            a.minWebstorePriceBeforeRounding - a.minStorePrice;
          const maxServiceFee =
            a.maxWebstorePriceBeforeRounding - a.maxStorePrice;

          const minStoreProfit =
            a.minWebstorePrice - a.minVendorPrice - minServiceFee;
          const maxStoreProfit =
            a.maxWebstorePrice - a.maxVendorPrice - maxServiceFee;

          return {
            key: a.pkEcomStoreProduct,
            actions: VIEW_ACTIONS,
            columns: [
              {
                key: 1,
                uuid: a.uuid,
                label: a.ecomVendorProduct.name,
                onClick: () =>
                  navigate(`/merchant/products/bulk-edit?ids=${a.uuid}`),
                weight: 500,
                subLabel: a.ecomVendorProduct.description,
                src: a.ecomVendorProduct.previewImage ?? null,
                showImage: true
              },
              {
                key: 2,
                ...getEcomStoreProductStatus(a)
              },
              {
                key: 3,
                label:
                  a.minVendorPrice === a.maxVendorPrice
                    ? currencyFormat(a.minVendorPrice / 100)
                    : `${currencyFormat(
                        a.minVendorPrice / 100
                      )} - ${currencyFormat(a.maxVendorPrice / 100)}`
              },
              {
                key: 4,
                label: pricingAmountString
              },
              {
                key: 5,
                label:
                  a.minRoundingAdjustment === a.maxRoundingAdjustment
                    ? currencyFormat(a.minRoundingAdjustment / 100)
                    : `${currencyFormat(
                        a.minRoundingAdjustment / 100
                      )} - ${currencyFormat(a.maxRoundingAdjustment / 100)}`
              },
              {
                key: 6,
                label:
                  a.minStorePrice === a.maxStorePrice
                    ? currencyFormat(a.minStorePrice / 100)
                    : `${currencyFormat(
                        a.minStorePrice / 100
                      )} - ${currencyFormat(a.maxStorePrice / 100)}`
              },
              {
                key: 7,
                label:
                  minServiceFee === maxServiceFee
                    ? currencyFormat(minServiceFee / 100)
                    : `${currencyFormat(
                        minServiceFee / 100
                      )} - ${currencyFormat(maxServiceFee / 100)}`
              },
              {
                key: 8,
                label:
                  a.minWebstorePrice === a.maxWebstorePrice
                    ? currencyFormat(a.minWebstorePrice / 100)
                    : `${currencyFormat(
                        a.minWebstorePrice / 100
                      )} - ${currencyFormat(a.maxWebstorePrice / 100)}`
              },
              {
                key: 9,
                label:
                  minStoreProfit === maxStoreProfit
                    ? currencyFormat(minStoreProfit / 100)
                    : `${currencyFormat(
                        minStoreProfit / 100
                      )} - ${currencyFormat(maxStoreProfit / 100)}`
              },
              {
                key: 10,
                label: dayjs(formatUtcDate(a.createdAt)).format('MMMM D, YYYY')
              }
            ]
          };
        })}
        selectActionSection={
          <AppMenu
            control={
              <ActionIcon color="#ced4da" size="lg" variant="outline">
                <Dots color="#000" />
              </ActionIcon>
            }
            styles={{ dropdown: { padding: 0 } }}
          >
            <AppStack style={{ padding: 2, gap: 1 }}>
              <Menu.Item
                leftSection={<Pencil size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (
                    selectedData.length === pageSize ||
                    (pageIndex === totalPages &&
                      selectedData.length === productData.length)
                  ) {
                    navigate(`/merchant/products/bulk-edit`);
                  }
                  else {
                    navigate(
                      `/merchant/products/bulk-edit?ids=${selectedData
                        .map((f) => f.uuid)
                        .join(',')}`
                    );
                  }
                }}
                visibleFrom="xsm"
              >
                Bulk edit
              </Menu.Item>
              <Menu.Item
                leftSection={<Eye size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onOpenModal(VIEW_ACTIONS_ENUM.CHANGE_STATUS, [
                    ...selectedData
                  ]);
                }}
              >
                Change status
              </Menu.Item>
            </AppStack>
            <Divider />
            <AppStack style={{ padding: 2, gap: 1 }}>
              <Menu.Item
                leftSection={<Archive size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onOpenModal(VIEW_ACTIONS_ENUM.ARCHIVE, [...selectedData]);
                }}
              >
                Archive products
              </Menu.Item>
              <Menu.Item
                leftSection={<Trash size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onOpenModal(VIEW_ACTIONS_ENUM.DELETE, [...selectedData]);
                }}
                style={{ color: 'red' }}
              >
                Delete products
              </Menu.Item>
            </AppStack>
          </AppMenu>
        }
        selectedRows={selectedRows}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Store Products"
        totalCount={totalCount}
        totalPages={totalPages}
      />

      <ChangeProductStatusModal
        archiveProducts={modalState.action === VIEW_ACTIONS_ENUM.ARCHIVE}
        fkEcomStore={authState.pkEcomStore}
        isOpen={
          modalState.isOpen &&
          (modalState.action === VIEW_ACTIONS_ENUM.CHANGE_STATUS ||
            modalState.action === VIEW_ACTIONS_ENUM.ARCHIVE)
        }
        onClose={onCloseModal}
        onStatusChangeSuccess={onRefresh}
        products={modalState.item ?? []}
      />

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText={`Yes, delete ${
          modalState.item
            ? singularPluralFormat(
                modalState.item.length,
                'product',
                'products'
              )
            : ''
        }`}
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to delete{' '}
              <b>
                {modalState.item?.length === 1
                  ? modalState.item[0]?.ecomVendorProduct.name
                  : `${modalState.item?.length} products`}
              </b>
              ?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteStoreProducts(
            authState.pkEcomStore,
            modalState.item.map((f) => f.pkEcomStoreProduct),
            () => {
              onRefresh();
              triggerNotification('Products deleted.', 'success');
              onCloseModal();
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${
          modalState.item?.length === 1
            ? modalState.item[0]?.ecomVendorProduct.name
            : `${modalState.item?.length} products`
        }?
        `}
      />
    </HeaderView>
  );
};

StoreProductTable.propTypes = {};

export default StoreProductTable;
