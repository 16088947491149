import { Link } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsMenuBlock from './WsMenuBlock';

const HEADING_SIZE_STYLE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  HUGE: 'huge'
};

const configNameValues = {
  heading: 'heading',
  headingSize: 'headingSize',
  menu: 'menu'
};

const configDefaultValues = {
  [configNameValues.heading]: 'Links',
  [configNameValues.headingSize]: HEADING_SIZE_STYLE_ENUM.MEDIUM,
  [configNameValues.menu]: ''
};

const configOptions = {
  [configNameValues.heading]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Heading',
    isTextArea: true
  },
  [configNameValues.headingSize]: {
    label: 'Heading size',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      {
        value: HEADING_SIZE_STYLE_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.HUGE,
        label: 'Huge'
      }
    ]
  },
  [configNameValues.menu]: {
    label: 'Menu',
    type: 'entity',
    entityType: 'ecomStoreMenu'
  }
};

const config = {
  title: 'Menu',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.MENU,
  icon: Link,
  Component: WsMenuBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
