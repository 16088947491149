/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';
import { configNameValues } from './wsTextConfig';
import AppStack from '../../../../common/AppStack';
import AppFlexbox from '../../../../common/AppFlexbox';
import { HEADING_SIZE_STYLE_ENUM } from '../../sections/featuredCollection/wsFeaturedCollectionConfig';
import { generateColorVariants } from '../../../../../helpers/styles';

const WsTextBlock = ({ config, colorScheme }) =>
  (config.text || config.heading) && (
    <AppFlexbox>
      <AppStack>
        {config.heading && (
          <AppText
            style={{
              fontWeight: 500,
              fontSize:
                config.headingSize === HEADING_SIZE_STYLE_ENUM.HUGE
                  ? 22
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE
                  ? 20
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.LARGE
                  ? 18
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.MEDIUM
                  ? 16
                  : 14,
              wordBreak: 'break-word',
              overflowWrap: 'break-word'
            }}
          >
            {config.heading}
          </AppText>
        )}
        <AppText
          style={{
            fontSize: config[configNameValues.fontSize] * 1,
            fontStyle: config[configNameValues.fontStyle],
            fontWeight: config[configNameValues.fontWeight],
            textDecoration: config[configNameValues.textDecoration],
            textTransform: config[configNameValues.textTransform],
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            ...(config.heading &&
              colorScheme && {color: generateColorVariants(colorScheme.textColor, 100).lighter})
          }}
        >
          {config.text}
        </AppText>
      </AppStack>
    </AppFlexbox>
  );

WsTextBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object
};

export default WsTextBlock;
