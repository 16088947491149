import React, { useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { BuildingStore, Check, TruckDelivery } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import HeaderNavigationMenu from './HeaderNavigationMenu';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import {
  ADMIN_NAVIGATION_LINKS,
  BASE_NAVIGATION_LINKS,
  MERCHANT_NAVIGATION_LINKS,
  VENDOR_NAVIGATION_LINKS
} from './navigationLinks';

const HeaderAuthNavigationMenu = ({ lightMode }) => {
  const navigate = useNavigate();
  const { state: authState, logout } = useContext(AuthContext);
  const loading = !authState.tokenAttempted || authState.loading;
  const adminLinks = BASE_NAVIGATION_LINKS.admin.filter((a) =>
    a.hasAccess(authState)
  );
  const selectedStore = authState.ecomStores.find(
    (v) => v.pkEcomStore.toString() === authState.pkEcomStore.toString()
  );
  const selectedVendor = authState.ecomVendors.find(
    (v) => v.pkEcomVendor.toString() === authState.pkEcomVendor.toString()
  );

  return (
    <Routes>
      <Route
        element={
          <HeaderNavigationMenu
            adminLinks={VENDOR_NAVIGATION_LINKS.main}
            controlTitle="My Vendor"
            lightMode={lightMode}
            loading={loading}
            logoutTitle="Exit Dashboard"
            onLogoutAction={() => navigate('/')}
            profileInfo={{
              to: '/vendor/settings',
              image: selectedVendor?.logoImageUrl,
              label: selectedVendor?.name,
              active: true,
              icon: Check,
              secondAction: authState.userData?.isAdmin
                ? {
                    to: '/vendor/switch',
                    label: 'Switch vendor',
                    icon: TruckDelivery
                  }
                : null
            }}
          />
        }
        path="/vendor/*"
      />
      <Route
        element={
          <HeaderNavigationMenu
            adminLinks={MERCHANT_NAVIGATION_LINKS.main}
            controlTitle="My Store"
            lightMode={lightMode}
            loading={loading}
            logoutTitle="Exit Dashboard"
            onLogoutAction={() => navigate('/')}
            profileInfo={{
              to: '/merchant/settings',
              image: selectedStore?.logoImageUrl,
              label: selectedStore?.name,
              active: true,
              icon: Check,
              secondAction: {
                to: '/merchant/switch',
                label: 'Switch store',
                icon: BuildingStore
              }
            }}
          />
        }
        path="/merchant/*"
      />
      <Route
        element={
          <HeaderNavigationMenu
            adminLinks={ADMIN_NAVIGATION_LINKS.main}
            controlTitle="Admin Dashboard"
            hideSupport
            lightMode={lightMode}
            loading={loading}
            logoutTitle="Exit Dashboard"
            onLogoutAction={() => navigate('/')}
            profileInfo={{
              to: '/admin',
              image: authState.userData?.user.avatar,
              label: 'Admin',
              active: true,
              icon: Check
            }}
          />
        }
        path="/admin/*"
      />
      <Route
        element={
          <HeaderNavigationMenu
            adminLinks={adminLinks}
            alwaysShowProfileLinks
            lightMode={lightMode}
            loading={loading}
            onLogoutAction={() => {
              logout();
              navigate('/');
            }}
            profileInfo={{
              to: '/settings',
              image: authState.userData?.user.avatar,
              label: authState.userData?.user.name,
              subLabel: authState.userData?.user.email
            }}
            profileLinks={BASE_NAVIGATION_LINKS.profile}
          />
        }
        path="*"
      />
    </Routes>
  );
};

HeaderAuthNavigationMenu.propTypes = { lightMode: PropTypes.bool };

export default HeaderAuthNavigationMenu;
