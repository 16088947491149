import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dots, Download, Pencil, Plus, Trash, Users } from 'tabler-icons-react';
import { ActionIcon, Button, Divider, Menu } from '@mantine/core';
import dayjs from 'dayjs';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import TableView from '../../common/TableView';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import { useModalState, usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import AppMenu from '../../common/AppMenu';
import StoreFundraisingRosterPlayerModal from './StoreFundraisingRosterPlayerModal';
import { formatUtcDate } from '../../../helpers/format';
import ConfirmModal from '../../common/ConfirmModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const TABLE_COLUMNS = [
  {
    label: 'Display name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Name',
    value: 'team_name',
    sortable: true
  },
  {
    label: 'Order count',
    value: 'orders',
    sortable: true
  },
  {
    label: 'Created',
    value: 'created',
    sortable: true
  }
];

const VIEW_ACTIONS = [
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.EDIT,
    section: 1,
    icon: <Pencil size={18} />
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.DELETE,
    icon: <Trash color="red" size={18} />,
    section: 2,
    color: 'red'
  }
];

const StoreFundraisingRosterPlayerTable = () => {
  const navigate = useNavigate();
  const hasFetched = useRef(null);
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchPaginatedStoreRosterPlayers,
    fetchStoreRosterPlayers,
    deleteStoreRosterPlayers
  } = useContext(StoreContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: playerData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      team: ''
    },
    (newFilter) => {
      fetchPaginatedStoreRosterPlayers(
        authState.pkEcomStore,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedEcomStoreRosterPlayers
  );
  const loading = !hasFetched.current || dataLoading;
  const noPlayersCreated = !loading && totalNoFilterCount === 0;
  const selectedPlayers = playerData.filter((f) =>
    selectedRows.includes(f.pkEcomStoreRosterPlayer)
  );

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchPaginatedStoreRosterPlayers(
        authState.pkEcomStore,
        {},
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      fetchStoreRosterPlayers(authState.pkEcomStore);
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Fundraising roster',
          to: '/merchant/fundraising'
        }
      ]}
      rightSection={
        !loading && (
          <Button
            color="dark"
            leftSection={<Plus size={18} />}
            onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.CREATE)}
            radius="md"
            size="compact-md"
            style={{ fontSize: 14 }}
          >
            Add player
          </Button>
        )
      }
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabledContent={{
          title: 'Add roster players',
          description:
            'Setup roster players to track fundraising orders and sales.  Start by adding a player or importing a list of players.',
          icon: <Users color="#000" size={125} />,
          primaryButton: {
            label: 'Add player',
            icon: <Plus size={18} />,
            onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.CREATE)
          },
          secondaryButton: {
            label: 'Import',
            icon: <Download size={18} />,
            onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.IMPORT)
          }
        }}
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by player',
            value: filter.search,
            onChange: (value) => onFilterChange({ ...filter, search: value })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={loading}
        noSort
        onAction={(action, item) => {
          const player = playerData.find(
            (s) => s.pkEcomStoreRosterPlayer === item.key
          );
          if (player) {
            switch (action) {
              case VIEW_ACTIONS_ENUM.CREATE:
              case VIEW_ACTIONS_ENUM.EDIT:
              case VIEW_ACTIONS_ENUM.DELETE:
              default:
                onOpenModal(action, [player]);
                break;
            }
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        onSelect={setSelectedRows}
        pageIndex={pageIndex}
        rows={playerData.map((a) => ({
          key: a.pkEcomStoreRosterPlayer,
          actions: VIEW_ACTIONS,
          columns: [
            {
              key: 1,
              label: a.name,
              weight: 500,
              subLabel: a.teamUser?.teamName || ''
            },
            {
              key: 2,
              label: a.teamUser?.name || '',
              weight: 500
            },
            {
              key: 3,
              label: '',
              weight: 500
            },
            {
              key: 4,
              label: dayjs(formatUtcDate(a.createdAt)).format('MMMM D, YYYY')
            }
          ]
        }))}
        selectActionSection={
          <AppMenu
            control={
              <ActionIcon color="#ced4da" size="lg" variant="outline">
                <Dots color="#000" />
              </ActionIcon>
            }
            styles={{ dropdown: { padding: 0 } }}
          >
            <AppStack style={{ padding: 2, gap: 1 }} visibleFrom="xsm">
              <Menu.Item
                leftSection={<Pencil size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate(
                    `/merchant/fundraising/bulk-edit?ids=${selectedPlayers
                      .map((f) => f.pkEcomStoreRosterPlayer)
                      .join(',')}`
                  );
                }}
              >
                Bulk edit
              </Menu.Item>
            </AppStack>
            <Divider visibleFrom="xsm" />
            <AppStack style={{ padding: 2, gap: 1 }}>
              <Menu.Item
                leftSection={<Trash size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onOpenModal(VIEW_ACTIONS_ENUM.DELETE, [...selectedPlayers]);
                }}
                style={{ color: 'red' }}
              >
                Delete players
              </Menu.Item>
            </AppStack>
          </AppMenu>
        }
        selectedRows={selectedPlayers.map((p) => p.pkEcomStoreRosterPlayer)}
        showDisabledContent={noPlayersCreated}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Orders"
        totalCount={totalCount}
        totalPages={totalPages}
      />

      <StoreFundraisingRosterPlayerModal
        ecomStoreRosterPlayer={
          modalState.action === VIEW_ACTIONS_ENUM.EDIT
            ? modalState.item[0]
            : null
        }
        isOpen={
          modalState.isOpen &&
          (modalState.action === VIEW_ACTIONS_ENUM.CREATE ||
            modalState.action === VIEW_ACTIONS_ENUM.EDIT)
        }
        onClose={onCloseModal}
        onSuccessCallback={() => {
          onRefresh();
          fetchStoreRosterPlayers(authState.pkEcomStore);
        }}
      />

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete players"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to delete{' '}
              <b>
                {modalState.item?.length === 1
                  ? modalState.item[0]?.name
                  : `${modalState.item?.length} players`}
              </b>
              ?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteStoreRosterPlayers(
            authState.pkEcomStore,
            modalState.item.map((i) => i.pkEcomStoreRosterPlayer),
            () => {
              onRefresh();
              triggerNotification(
                modalState.item?.length === 1
                  ? 'Player deleted'
                  : 'Players deleted',
                'success'
              );
              fetchStoreRosterPlayers(authState.pkEcomStore);
              onCloseModal();
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${
          modalState.item?.length === 1
            ? modalState.item[0]?.name
            : `${modalState.item?.length} players`
        }?
        `}
      />
    </HeaderView>
  );
};

export default StoreFundraisingRosterPlayerTable;
