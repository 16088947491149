import React from 'react';
import { NavLink } from '@mantine/core';
import { ChevronRight } from 'tabler-icons-react';
import { useHover } from '@mantine/hooks';

const CustomNavLink = ({ ...props }) => {
  const { hovered, ref: hoverRef } = useHover();

  return (
    <NavLink
      ref={hoverRef}
      rightSection={hovered ? <ChevronRight color="#000" size={18} /> : null}
      style={{
        borderRadius: 8,
        textShadow: hovered ? '0 0 0.6px #000' : 'none',
        outline: hovered ? 'solid 1px #dee2e6' : 'none'
      }}
      {...props}
    />
  );
};

export default CustomNavLink;
