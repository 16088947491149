import { AlignLeft } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsIconWithTextBlock from './WsIconWithTextBlock';
import { WS_TEXT_ICON_OPTIONS } from './wsTextIconOptions';

const ICON_TEXT_LAYOUT_ENUM = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
};

const configNameValues = {
  layout: 'layout',
  icon1Ref: 'icon1Ref',
  icon1Image: 'icon1Image',
  icon1Text: 'icon1Text',
  icon2Ref: 'icon2Ref',
  icon2Image: 'icon2Image',
  icon2Text: 'icon2Text',
  icon3Ref: 'icon3Ref',
  icon3Image: 'icon3Image',
  icon3Text: 'icon3Text'
};

const configDefaultValues = {
  layout: ICON_TEXT_LAYOUT_ENUM.HORIZONTAL,
  icon1Ref: 'Heart',
  icon1Image: '',
  icon1Text: 'First Icon',
  icon2Ref: 'Truck',
  icon2Image: '',
  icon2Text: 'Second Icon',
  icon3Ref: 'Check',
  icon3Image: '',
  icon3Text: 'Third Icon'
};

const configOptions = {
  [configNameValues.layout]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Layout',
    options: [
      {
        value: ICON_TEXT_LAYOUT_ENUM.HORIZONTAL,
        label: 'Horizontal'
      },
      {
        value: ICON_TEXT_LAYOUT_ENUM.VERTICAL,
        label: 'Vertical'
      }
    ]
  },
  [configNameValues.icon1Image]: {
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia,
    label: 'Image',
    category: 'First icon'
  },
  [configNameValues.icon1Ref]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: WS_TEXT_ICON_OPTIONS,
    label: 'Icon',
    category: 'First icon',
    isVisible: (config) => !config.icon1Image
  },
  [configNameValues.icon1Text]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    isTextArea: true,
    label: 'Heading',
    category: 'First icon'
  },
  [configNameValues.icon2Image]: {
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia,
    label: 'Image',
    category: 'Second icon'
  },
  [configNameValues.icon2Ref]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: WS_TEXT_ICON_OPTIONS,
    label: 'Icon',
    category: 'Second icon',
    isVisible: (config) => !config.icon1Image
  },
  [configNameValues.icon2Text]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    isTextArea: true,
    label: 'Heading',
    category: 'Second icon'
  },
  [configNameValues.icon3Image]: {
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia,
    label: 'Image',
    category: 'Third icon'
  },
  [configNameValues.icon3Ref]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: WS_TEXT_ICON_OPTIONS,
    label: 'Icon',
    category: 'Third icon',
    isVisible: (config) => !config.icon1Image
  },
  [configNameValues.icon3Text]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    isTextArea: true,
    label: 'Heading',
    category: 'Third icon'
  }
};

const config = {
  title: 'Icon with text',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ICON_WITH_TEXT,
  icon: AlignLeft,
  Component: WsIconWithTextBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
