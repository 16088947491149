import React from 'react';
import { Button, Divider, Image, Skeleton } from '@mantine/core';
import { Logout, Pencil } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import sportsHeadzLogo from '../../../images/sportsheadz.png';
import BulkEditColumnSelectMenu from './BulkEditColumnSelectMenu';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const BulkEditHeaderNavbar = ({
  dataFields,
  onSelectFields,
  title,
  selectedCount,
  hasUnsavedChanges,
  saving,
  loading,
  onReset,
  onExitEdit,
  onOpenSelectedModal
}) => {
  const { isTabletOrSmaller } = useMediaQueryIndex();

  return (
    <AppCard
      style={{
        position: '-webkit-sticky',
        position: 'sticky',
        top: 0,
        height: 60,
        padding: 0,
        borderBottom: 'solid 1px #dee2e6'
      }}
    >
      <AppFlexbox style={{ gap: 0, flex: 1, alignItems: 'center' }}>
        <AppFlexbox
          style={{ height: '100%', gap: 0, flex: 1, alignItems: 'center' }}
        >
          <AppFlexbox
            onClick={onExitEdit}
            p={{ base: 10, sm: '10px 20px' }}
            style={{ alignItems: 'center', gap: 5, cursor: 'pointer' }}
          >
            <Logout size={20} style={{ transform: 'rotate(180deg)' }} />
            <AppText>Back</AppText>
          </AppFlexbox>
          <Divider orientation="vertical" />

          <AppFlexbox
            p={{ base: 10, sm: '10px 20px' }}
            style={{
              alignItems: 'center',
              gap: 20
            }}
          >
            {loading ? (
              <Skeleton height={18} width={160} />
            ) : (
              <AppText
                style={{ fontWeight: 500, fontSize: 16 }}
                visibleFrom="sm"
              >
                {selectedCount ? `${selectedCount} selected` : title}
              </AppText>
            )}
            {selectedCount > 0 && (
              <Button
                color="blue"
                onClick={onOpenSelectedModal}
                rightSection={<Pencil color="#FFF" size={18} />}
                size="compact-sm"
                variant="filled"
              >
                {isTabletOrSmaller
                  ? `Edit (${selectedCount})`
                  : 'Change values'}
              </Button>
            )}
          </AppFlexbox>
        </AppFlexbox>
        {!selectedCount && (
          <AppStack hiddenFrom="sm" style={{ height: 45 }}>
            <Image height="100%" src={sportsHeadzLogo} width="auto" />
          </AppStack>
        )}

        <AppStack style={{ height: 45 }} visibleFrom="sm">
          <Image height="100%" src={sportsHeadzLogo} width="auto" />
        </AppStack>
        <AppFlexbox style={{ flex: 1, gap: 0, justifyContent: 'end' }}>
          <BulkEditColumnSelectMenu
            dataFields={dataFields}
            isTableLoading={loading}
            onSelectFields={onSelectFields}
          />
          {hasUnsavedChanges && (
            <>
              <Divider orientation="vertical" visibleFrom="sm" />
              <AppFlexbox
                style={{ alignItems: 'center', padding: '10px 20px', gap: 5 }}
                visibleFrom="sm"
              >
                <Button
                  color="dark"
                  disabled={!hasUnsavedChanges || loading}
                  onClick={onReset}
                  type="button"
                  variant="outline"
                >
                  Reset
                </Button>
              </AppFlexbox>
            </>
          )}
          <Divider orientation="vertical" />

          <AppFlexbox
            p={{ base: 10, sm: '10px 20px' }}
            style={{ alignItems: 'center', gap: 5 }}
          >
            <Button
              color="dark"
              disabled={!hasUnsavedChanges || loading}
              loading={saving}
              size={isTabletOrSmaller ? 'compact-md' : null}
              type="submit"
              variant="filled"
            >
              Save
            </Button>
          </AppFlexbox>
        </AppFlexbox>
      </AppFlexbox>
    </AppCard>
  );
};

BulkEditHeaderNavbar.propTypes = {
  dataFields: PropTypes.array,
  hasUnsavedChanges: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onExitEdit: PropTypes.func,
  onOpenSelectedModal: PropTypes.func,
  onReset: PropTypes.func,
  onSelectFields: PropTypes.func,
  saving: PropTypes.bool,
  selectedCount: PropTypes.number,
  title: PropTypes.string
};

export default BulkEditHeaderNavbar;
