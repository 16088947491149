import { Message } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsColumnContentBlock from './WsColumnContentBlock';

const configNameValues = {
  image: 'image',
  heading: 'heading',
  description: 'description',
  linkLabel: 'linkLabel',
  link: 'link',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  image: '',
  heading: 'Column',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur accumsan semper odio quis lacinia. Pellentesque sagittis nibh id quam ultricies, eget sagittis lacus congue. Ut ligula augue, vehicula et porttitor vel, malesuada nec lorem.',
  linkLabel: '',
  link: '',
  colorScheme: '1'
};

const configOptions = {
  [configNameValues.image]: {
    label: 'Image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  },
  [configNameValues.heading]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Heading',
    controlType: 'text'
  },
  [configNameValues.description]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Description',
    controlType: 'text'
  },
  [configNameValues.linkLabel]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Link Label',
    controlType: 'text'
  },
  [configNameValues.link]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Link',
    controlType: 'text'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  title: 'Column',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.COLUMN_CONTENT,
  icon: Message,
  Component: WsColumnContentBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
