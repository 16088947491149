import React, { useContext } from 'react';
import { ActionIcon, Divider, Image, Indicator } from '@mantine/core';
import { Logout, ShoppingCart } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import sportsHeadzLogo from '../../../images/sportsheadz.png';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import HeaderAuthNavigationMenu from '../navigation/HeaderAuthNavigationMenu';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';

const VendorCatalogHeader = () => {
  const { toggleVendorCatalogDrawer } = useContext(HelperContext);
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const { cart: catalogCart } = useVendorCatalogCheckout();

  return (
    <AppCard
      style={{
        position: '-webkit-sticky',
        position: 'sticky',
        top: 0,
        height: 60,
        padding: 0,
        borderBottom: 'solid 1px #dee2e6'
      }}
    >
      <AppFlexbox
        style={{ gap: 0, flex: 1, alignItems: 'center', paddingRight: 10 }}
      >
        <AppFlexbox
          style={{ height: '100%', gap: 0, flex: 1, alignItems: 'center' }}
        >
          <AppFlexbox
            component={Link}
            style={{
              alignItems: 'center',
              gap: 5,
              cursor: 'pointer',
              textDecoration: 'none',
              color: '#000',
              padding: isTabletOrSmaller ? 10 : '10px 20px'
            }}
            to="/merchant/products"
          >
            <Logout size={20} style={{ transform: 'rotate(180deg)' }} />
            <AppText>Exit</AppText>
          </AppFlexbox>
          <Divider orientation="vertical" />

          <AppStack
            style={{
              gap: 0,
              padding: isTabletOrSmaller ? 10 : '10px 20px'
            }}
          >
            <AppText
              style={{ fontWeight: 500, fontSize: 16, lineHeight: '24px' }}
            >
              Vendor Catalog
            </AppText>
            <AppText
              style={{
                fontSize: 14,
                color: '#666',
                lineHeight: '16px',
                whiteSpace: 'nowrap'
              }}
              visibleFrom="sm"
            >
              View and add products to your store
            </AppText>
          </AppStack>
        </AppFlexbox>
        <AppFlexbox style={{ gap: 20, alignItems: 'center' }}>
          {catalogCart.ecomVendorProducts.length > 0 && (
            <AppFlexbox hiddenFrom="sm">
              <Indicator
                inline
                label={catalogCart.ecomVendorProducts.length}
                size={16}
              >
                <ActionIcon
                  color="dark"
                  onClick={() => toggleVendorCatalogDrawer(true)}
                  radius="lg"
                  size="lg"
                  variant="subtle"
                >
                  <ShoppingCart />
                </ActionIcon>
              </Indicator>
            </AppFlexbox>
          )}
          <AppStack style={{ height: 45 }} visibleFrom="xxs">
            <Image height="100%" src={sportsHeadzLogo} width="auto" />
          </AppStack>
        </AppFlexbox>
        <AppFlexbox
          flex={{ base: 'unset', xsm: 1 }}
          style={{
            justifyContent: 'flex-end',
            placeItems: 'center',
            gap: 20
          }}
          visibleFrom="sm"
        >
          {catalogCart.ecomVendorProducts.length > 0 && (
            <Indicator
              inline
              label={catalogCart.ecomVendorProducts.length}
              size={18}
            >
              <ActionIcon
                color="dark"
                onClick={() => toggleVendorCatalogDrawer(true)}
                radius="lg"
                size="lg"
                variant="subtle"
              >
                <ShoppingCart />
              </ActionIcon>
            </Indicator>
          )}
          <HeaderAuthNavigationMenu lightMode />
        </AppFlexbox>
      </AppFlexbox>
    </AppCard>
  );
};

VendorCatalogHeader.propTypes = {};

export default VendorCatalogHeader;
