import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from '@mantine/core';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import ResponsiveWebstoreModal from './ResponsiveWebstoreModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { useCartController } from '../../../helpers/webstoreCheckoutHelper';
import { triggerNotification } from '../../../helpers/notification';
import sportsheadzLogo from '../../../images/sportsheadz_Icons_sh_transparent.png';
import AppFlexbox from '../../common/AppFlexbox';

const ContinueAsGuestModal = ({
  mediaQueries,
  editModeEnabled,
  baseStoreUrl,
  onNavigate,
  ecomStoreTheme
}) => {
  const { state: helperState, toggleWebstoreContinueAsGuestModal } = useContext(
    HelperContext
  );
  const { addStoreProducts } = useCartController();
  const [loading, setLoading] = useState(false);

  const onAddProductToCart = () => {
    setLoading(true);
    addStoreProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      {
        ecomStoreCartProducts:
          helperState.webstore.continueAsGuestModal.ecomStoreCartProducts
      },
      () => {
        toggleWebstoreContinueAsGuestModal(false);
        setLoading(false);
      },
      (e) => {
        triggerNotification(e);
        setLoading(false);
      }
    );
  };

  return (
    <ResponsiveWebstoreModal
      alignModal="center"
      editModeEnabled={editModeEnabled}
      isOpen={helperState.webstore.continueAsGuestModal.isOpen}
      mediaQueries={mediaQueries}
      noHeader
      onClose={() => toggleWebstoreContinueAsGuestModal(false)}
      radius={0}
      size={600}
      trapFocus={false}
      zIndex={201}
    >
      <AppStack style={{ gap: 25 }}>
        <AppText style={{ fontSize: 24, fontWeight: 500 }}>
          You're almost set!
        </AppText>
        <AppFlexbox style={{ alignItems: 'center' }}>
          <AppFlexbox style={{ height: 80, maxHeight: 80 }}>
            <Image fit="contain" h="100%" src={sportsheadzLogo} />
          </AppFlexbox>
          <AppText
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: '#333'
            }}
          >
            Sign in to proceed with your account or continue as a guest
          </AppText>
        </AppFlexbox>

        <AppStack style={{ gap: 20 }}>
          <Button
            color="dark"
            disabled={loading}
            onClick={() => {
              toggleWebstoreContinueAsGuestModal(false);
              onNavigate(`${baseStoreUrl}/account/login`);
            }}
            radius={0}
            size="lg"
            variant="filled"
          >
            Continue to sign in
          </Button>
          <Button
            color="dark"
            loading={loading}
            onClick={onAddProductToCart}
            radius={0}
            size="lg"
            variant="outline"
          >
            Continue as guest
          </Button>
        </AppStack>
      </AppStack>
    </ResponsiveWebstoreModal>
  );
};

ContinueAsGuestModal.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func
};

export default ContinueAsGuestModal;
