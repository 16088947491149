import React from 'react';
import PropTypes from 'prop-types';
import { ECOM_WEBSTORE_SECTIONS } from './wsSectionConfig';

const WsSection = ({
  fkEcomStoreThemeSectionType,
  ecomStoreThemeSectionBlocks,
  config,
  pathname,
  ecomStoreThemeSection,
  ...rest
}) => {
  const themeSection = ECOM_WEBSTORE_SECTIONS[fkEcomStoreThemeSectionType];
  const WsSectionComponent = themeSection?.Component;
  const blocks =
    ecomStoreThemeSectionBlocks
      .filter((b) => !b.hidden)
      .sort((a, b) => a.sort - b.sort) ?? [];

  return WsSectionComponent ? (
    <WsSectionComponent
      blocks={blocks}
      config={config}
      pathname={pathname}
      title={
        themeSection?.getTitle
          ? themeSection.getTitle(ecomStoreThemeSection, pathname) ||
            themeSection.title
          : themeSection.title
      }
      {...rest}
    />
  ) : null;
};

WsSection.propTypes = {
  config: PropTypes.object,
  ecomStoreThemeSection: PropTypes.object,
  ecomStoreThemeSectionBlocks: PropTypes.array,
  fkEcomStoreThemeSectionType: PropTypes.number,
  pathname: PropTypes.string
};

export default WsSection;
