import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput } from '@mantine/core';
import AppMenu from '../../common/AppMenu';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { triggerNotification } from '../../../helpers/notification';
import { SUPPORTED_MEDIA_TYPES } from '../../../config/constants';

const MediaUploadUrlUploadModal = ({ control, onUpload }) => {
  const [formState, setFormState] = useState({
    showMenu: false,
    url: '',
    loading: false
  });

  useEffect(() => {
    if (formState.showMenu) {
      setFormState({
        ...formState,
        loading: false
      });
    }
  }, [formState.showMenu]);

  const onUploadUrl = async () => {
    const response = await fetch(formState.url);

    if (!response.ok) {
      triggerNotification(
        'The image could not be uploaded. Please check the URL and try again.'
      );
      setFormState({
        ...formState,
        loading: false
      });
    }
    else {
      const imageBlob = await response.blob();
      if (imageBlob.size > 5 * 1024 ** 2) {
        triggerNotification(
          'The image is too large. Please upload an image that is less than 5MB.'
        );
        setFormState({
          ...formState,
          loading: false
        });
      }
      else if (!SUPPORTED_MEDIA_TYPES.includes(imageBlob.type)) {
        triggerNotification(
          'The image is not a supported file type. Please upload a JPEG, PNG, SVG, WEBP, or HEIC image.'
        );
        setFormState({
          ...formState,
          loading: false
        });
      }
      else {
        let filename = '';
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition && contentDisposition.includes('filename=')) {
          const matches = contentDisposition.match(/filename="?([^"]+)"?/);
          if (matches && matches[1]) {
            // eslint-disable-next-line prefer-destructuring
            filename = matches[1];
          }
        }

        if (!filename) {
          filename = formState.url.split('/').pop();
        }

        onUpload([
          {
            key: new Date().getTime().toString(),
            alt: filename,
            contentType: imageBlob.type,
            file: imageBlob,
            src: formState.url,
            preview: URL.createObjectURL(imageBlob)
          }
        ]);
        setFormState({
          ...formState,
          showMenu: false,
          url: '',
          loading: false
        });
      }
    }
  };

  return (
    <AppMenu
      control={control}
      onChange={(showMenu) => {
        setFormState({
          ...formState,
          showMenu
        });
      }}
      opened={formState.showMenu}
      width={350}
    >
      <AppStack
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        style={{ padding: 5, gap: 8 }}
      >
        <AppText style={{ fontSize: 16, fontWeight: 650, marginBottom: 5 }}>
          Upload media from a URL
        </AppText>

        <TextInput
          label="Image Url"
          onChange={(e) =>
            setFormState({
              ...formState,
              url: e.currentTarget.value
            })
          }
          placeholder="https://"
          value={formState.url}
        />

        <AppFlexbox style={{ justifyContent: 'end' }}>
          <Button
            color="dark"
            disabled={!formState.url}
            loading={formState.loading}
            onClick={() => {
              setFormState({
                ...formState,
                loading: true
              });
              onUploadUrl();
            }}
            size="compact-sm"
            variant="filled"
          >
            Upload
          </Button>
        </AppFlexbox>
      </AppStack>
    </AppMenu>
  );
};

MediaUploadUrlUploadModal.propTypes = {
  control: PropTypes.node,
  onUpload: PropTypes.func
};

export default MediaUploadUrlUploadModal;
