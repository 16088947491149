import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Context as AuthState } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import SetupGuideSidebar from '../../common/SetupGuideSidebar';
import { setupGuideConfig } from './storeSetupGuideConfig';

const StoreSetupGuideSidebar = () => {
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthState);
  const { state, fetchStoreSetupSummary } = useContext(StoreContext);
  const loading =
    !authState.pkEcomStore ||
    state.ecomStoreSetupSummary.loading ||
    state.ecomStoreSetupSummary.value?.ecomStore.pkEcomStore.toString() !==
      authState.pkEcomStore?.toString();

  useEffect(() => {
    if (
      authState.pkEcomStore &&
      !pathname.startsWith('/merchant/products/discover')
    ) {
      fetchStoreSetupSummary(authState.pkEcomStore);
    }
  }, [authState.pkEcomStore]);

  return (
    <SetupGuideSidebar
      id={`skippedEcomStoreSetupStepsString-${authState.pkEcomStore}`}
      loading={loading}
      setupGuideConfig={setupGuideConfig}
      state={state}
    />
  );
};

export default StoreSetupGuideSidebar;
