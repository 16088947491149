// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.zjiF3rUPebYidHf3mpcj {
    &[data-inactive] {
        opacity: 0.3;
        cursor: default;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/mantineStyles.module.css"],"names":[],"mappings":";AACA;IACI;QACI,YAAY;QACZ,eAAe;IACnB;AACJ","sourcesContent":["\r\n.control {\r\n    &[data-inactive] {\r\n        opacity: 0.3;\r\n        cursor: default;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var control = `zjiF3rUPebYidHf3mpcj`;
export default ___CSS_LOADER_EXPORT___;
