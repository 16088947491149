import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM,
  ECOM_ENTITY_TYPES
} from '../../../../../config/constants';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';
import WsMultiRow from './WsMultiRow';

const IMAGE_HEIGHT_ENUM = {
  ADAPT_TO_IMAGE: 'adapt-to-image',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  CUSTOM: 'custom'
};

const SIZE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

const ALIGNMENT_ENUM = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
};

const BUTTON_SIZE_ENUM = {
  EXTRA_SMALL: 'xs',
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  EXTRA_LARGE: 'xl'
};

const buttonSizeOptions = [
  {
    value: BUTTON_SIZE_ENUM.EXTRA_SMALL,
    label: 'Extra small'
  },
  {
    value: BUTTON_SIZE_ENUM.SMALL,
    label: 'Small'
  },
  {
    value: BUTTON_SIZE_ENUM.MEDIUM,
    label: 'Medium'
  },
  {
    value: BUTTON_SIZE_ENUM.LARGE,
    label: 'Large'
  },
  {
    value: BUTTON_SIZE_ENUM.EXTRA_LARGE,
    label: 'Extra large'
  }
];

const alignmentOptions = [
  {
    value: ALIGNMENT_ENUM.START,
    label: 'Start'
  },
  {
    value: ALIGNMENT_ENUM.CENTER,
    label: 'Center'
  },
  {
    value: ALIGNMENT_ENUM.END,
    label: 'End'
  }
];

const IMAGE_PLACEMENT_ENUM = {
  LEFT: 'left',
  RIGHT: 'right',
  ALTERNATE_LEFT: 'alternate-left',
  ALTERNATE_RIGHT: 'alternate-right'
};

const configNameValues = {
  heading: 'heading',
  headingSize: 'headingSize',
  imageHeight: 'imageHeight',
  imageWidth: 'imageWidth',
  contentHeadingSize: 'contentHeadingSize',
  buttonSize: 'buttonSize',
  useOutlineButtonStyle: 'useOutlineButtonStyle',
  useCompactButtonStyle: 'useCompactButtonStyle',
  contentPosition: 'contentPosition',
  contentAlignment: 'contentAlignment',
  imagePlacement: 'imagePlacement',
  colorScheme: 'colorScheme',
  containerColorScheme: 'containerColorScheme'
};

const configDefaultValues = {
  [configNameValues.heading]: '',
  [configNameValues.headingSize]: '24',
  [configNameValues.imageHeight]: IMAGE_HEIGHT_ENUM.ADAPT_TO_IMAGE,
  [configNameValues.imageWidth]: SIZE_ENUM.MEDIUM,
  [configNameValues.contentHeadingSize]: SIZE_ENUM.MEDIUM,
  [configNameValues.buttonSize]: BUTTON_SIZE_ENUM.MEDIUM,
  [configNameValues.useCompactButtonStyle]: 'false',
  [configNameValues.useOutlineButtonStyle]: 'false',
  [configNameValues.contentPosition]: ALIGNMENT_ENUM.CENTER,
  [configNameValues.contentAlignment]: ALIGNMENT_ENUM.START,
  [configNameValues.imagePlacement]: IMAGE_PLACEMENT_ENUM.ALTERNATE_LEFT,
  [configNameValues.colorScheme]: '1',
  [configNameValues.containerColorScheme]: '1'
};

const configOptions = {
  [configNameValues.heading]: {
    label: 'Heading',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.headingSize]: {
    label: 'Heading Size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 100,
    unit: 'px'
  },
  [configNameValues.imageHeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Image height',
    options: [
      {
        value: IMAGE_HEIGHT_ENUM.ADAPT_TO_IMAGE,
        label: 'Adapt to image'
      },
      { value: IMAGE_HEIGHT_ENUM.SMALL, label: 'Small' },
      { value: IMAGE_HEIGHT_ENUM.MEDIUM, label: 'Medium' },
      { value: IMAGE_HEIGHT_ENUM.LARGE, label: 'Large' },
      { value: IMAGE_HEIGHT_ENUM.CUSTOM, label: 'Custom' }
    ]
  },
  [configNameValues.imageWidth]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Image width',
    options: [
      { value: SIZE_ENUM.SMALL, label: 'Small' },
      { value: SIZE_ENUM.MEDIUM, label: 'Medium' },
      { value: SIZE_ENUM.LARGE, label: 'Large' }
    ]
  },
  [configNameValues.contentHeadingSize]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Heading size',
    options: [
      { value: SIZE_ENUM.SMALL, label: 'Small' },
      { value: SIZE_ENUM.MEDIUM, label: 'Medium' },
      { value: SIZE_ENUM.LARGE, label: 'Large' }
    ]
  },
  [configNameValues.buttonSize]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Button size',
    options: buttonSizeOptions
  },
  [configNameValues.useCompactButtonStyle]: {
    label: 'Use compact button style',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.useOutlineButtonStyle]: {
    label: 'Use outlined button style',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.contentPosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Content position',
    options: [
      { value: ALIGNMENT_ENUM.START, label: 'Top' },
      { value: ALIGNMENT_ENUM.CENTER, label: 'Middle' },
      { value: ALIGNMENT_ENUM.END, label: 'Bottom' }
    ]
  },
  [configNameValues.contentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Content alignment',
    options: alignmentOptions
  },
  [configNameValues.contentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Content alignment',
    options: alignmentOptions
  },
  [configNameValues.imagePlacement]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Image placement',
    options: [
      { value: IMAGE_PLACEMENT_ENUM.LEFT, label: 'Left' },
      { value: IMAGE_PLACEMENT_ENUM.RIGHT, label: 'Right' },
      { value: IMAGE_PLACEMENT_ENUM.ALTERNATE_LEFT, label: 'Alternate left' },
      { value: IMAGE_PLACEMENT_ENUM.ALTERNATE_RIGHT, label: 'Alternate right' }
    ]
  },
  [configNameValues.containerColorScheme]: {
    label: 'Container color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.MULTIROW,
  title: 'Multirow',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsMultiRow,
  configOptions,
  maxItemCount: null,
  cannotCreate: true,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ROW_CONTENT
      ],
      defaultCount: 4,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
