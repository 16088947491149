/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Burger,
  Button,
  CloseButton,
  Divider,
  Drawer as MantineDrawer
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'tabler-icons-react';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppImage from '../../../../common/AppImage';
import AppText from '../../../../common/AppText';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import AppStack from '../../../../common/AppStack';
import { determineEcomStoreMenuItemUrl } from '../../../../../helpers/webstoreHelper';

const WsHeaderDrawer = ({
  onNavigate,
  baseStoreUrl,
  ecomStoreMenu,
  menuColorScheme,
  headerColorScheme,
  ecomStoreTheme,
  logoImageUrl,
  mediaQueries,
  pathname,
  editModeEnabled,
  builderHasAuthenticated,
  onAuthenticateEditMode
}) => {
  const { state: authState, logout } = useContext(AuthContext);
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    selectedMenuItem: null
  });
  const { isLargeMobileOrSmaller } = mediaQueries;
  const loading = !authState.tokenAttempted || authState.loading;

  const selectedMenuItem = ecomStoreMenu?.ecomStoreMenuItems.find(
    (item) => item.pkEcomStoreMenuItem === drawerState.selectedMenuItem
  );

  const mainLinks =
    ecomStoreMenu?.ecomStoreMenuItems.filter(
      (item) => item.fkEcomStoreMenuItem === drawerState.selectedMenuItem
    ) ?? [];

  return (
    <>
      <Burger
        color={headerColorScheme.textColor}
        onClick={() =>
          setDrawerState({
            ...drawerState,
            isOpen: !drawerState.isOpen,
            selectedMenuItem: null
          })
        }
        opened={drawerState.isOpen}
        title={drawerState.isOpen ? 'Close navigation' : 'Open navigation'}
      />

      <MantineDrawer
        onClose={() =>
          setDrawerState({
            ...drawerState,
            isOpen: false
          })
        }
        opened={drawerState.isOpen}
        portalProps={{ target: '#ws-app-container' }}
        position="left"
        size={isLargeMobileOrSmaller ? '100%' : 350}
        styles={{
          body: {
            padding: 0,
            height: '100%',
            backgroundColor: menuColorScheme.backgroundColor
          },
          overlay: { ...(editModeEnabled ? { position: 'absolute' } : {}) },
          inner: { ...(editModeEnabled ? { position: 'absolute' } : {}) }
        }}
        withCloseButton={false}
      >
        <AppFlexbox
          style={{ flex: 1, gap: 0, flexDirection: 'column', height: '100%' }}
        >
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              textDecoration: 'none',
              color: menuColorScheme.textColor,
              gap: 5,
              padding: 8,
              height: 60
            }}
          >
            <AppFlexbox
              style={{
                alignItems: 'center',
                gap: 8
              }}
            >
              {logoImageUrl && (
                <AppImage
                  alt="Main Logo"
                  fit="contain"
                  height={30}
                  src={logoImageUrl}
                  style={{ minWidth: 'unset', maxWidth: 125 }}
                  width="100%"
                />
              )}

              <AppText style={{ fontSize: 16, fontWeight: 650 }}>
                {ecomStoreTheme.ecomStore.name}
              </AppText>
            </AppFlexbox>

            <CloseButton
              onClick={() =>
                setDrawerState({
                  ...drawerState,
                  isOpen: false
                })
              }
              style={{ color: menuColorScheme.textColor }}
            />
          </AppFlexbox>
          <AppFlexbox
            style={{
              flex: 1,
              flexDirection: 'column',
              gap: 0,
              overflow: 'auto',
              height: '100%'
            }}
          >
            <Divider />
            <AppFlexbox
              style={{
                padding: '8px 0px',
                flexDirection: 'column',
                gap: 2,
                flex: 1
              }}
            >
              {selectedMenuItem && (
                <Button
                  color="dark"
                  justify="start"
                  onClick={(e) => {
                    setDrawerState({
                      ...drawerState,
                      selectedMenuItem:
                        selectedMenuItem.fkEcomStoreMenuItem ?? null
                    });
                  }}
                  radius={0}
                  size="md"
                  style={{ color: menuColorScheme.textColor }}
                  styles={{ label: { width: '100%' } }}
                  variant="subtle"
                >
                  <AppFlexbox
                    style={{
                      width: '100%',
                      flex: 1,
                      alignItems: 'center',
                      gap: 8
                    }}
                  >
                    <ArrowLeft size={16} />
                    <AppText style={{ fontSize: 14, fontWieght: 500 }}>
                      {selectedMenuItem.title}
                    </AppText>
                  </AppFlexbox>
                </Button>
              )}

              {mainLinks.map((link) => {
                const sublinks = ecomStoreMenu?.ecomStoreMenuItems.filter(
                  (f) => f.fkEcomStoreMenuItem === link.pkEcomStoreMenuItem
                );
                const linkInfo = determineEcomStoreMenuItemUrl({
                  ...link,
                  ecomStoreTheme
                });
                const isSelected = linkInfo.isSelected(pathname);

                return (
                  <Button
                    key={link.pkEcomStoreMenuItem}
                    color="dark"
                    component={Link}
                    justify="start"
                    onClick={(e) => {
                      if (sublinks.length > 0) {
                        e.preventDefault();
                        setDrawerState({
                          ...drawerState,
                          selectedMenuItem: link.pkEcomStoreMenuItem
                        });
                      }
                      else {
                        setDrawerState({
                          ...drawerState,
                          isOpen: false
                        });
                        onNavigate(e);
                      }
                    }}
                    radius={0}
                    size="lg"
                    style={{ color: menuColorScheme.textColor }}
                    styles={{ label: { width: '100%' } }}
                    target={linkInfo.target}
                    to={linkInfo.url || `${baseStoreUrl ?? ''}${linkInfo.path}`}
                    variant={isSelected ? 'light' : 'subtle'}
                  >
                    <AppFlexbox
                      style={{
                        width: '100%',
                        flex: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <AppText style={{ fontSize: 16, fontWieght: 500 }}>
                        {link.title}
                      </AppText>
                      {sublinks.length > 0 && <ArrowRight size={18} />}
                    </AppFlexbox>
                  </Button>
                );
              })}
            </AppFlexbox>
            <Divider />

            {!loading && (
              <AppStack style={{ overflow: 'hidden' }}>
                {(
                  editModeEnabled
                    ? builderHasAuthenticated
                    : authState.isAuthenticated
                ) ? (
                  <Button
                    color="#FFF"
                    onClick={() => {
                      if (editModeEnabled) {
                        setDrawerState({
                          ...drawerState,
                          isOpen: false
                        });
                        onAuthenticateEditMode(false);
                      }
                      else {
                        setDrawerState({
                          ...drawerState,
                          isOpen: false
                        });
                        logout();
                      }
                    }}
                    radius={0}
                    size="lg"
                    variant="subtle"
                  >
                    Sign out
                  </Button>
                ) : (
                  <Button
                    color="#FFF"
                    component={Link}
                    onClick={(e) => {
                      setDrawerState({
                        ...drawerState,
                        isOpen: false
                      });
                      onNavigate(e);
                    }}
                    radius={0}
                    size="lg"
                    to={`${baseStoreUrl}/account/login`}
                    variant="subtle"
                  >
                    Sign In
                  </Button>
                )}
              </AppStack>
            )}
          </AppFlexbox>
        </AppFlexbox>
      </MantineDrawer>
    </>
  );
};

WsHeaderDrawer.propTypes = {
  baseStoreUrl: PropTypes.string,
  builderHasAuthenticated: PropTypes.bool,
  config: PropTypes.object,
  ecomStoreMenu: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  headerColorScheme: PropTypes.object,
  logoImageUrl: PropTypes.string,
  mediaQueries: PropTypes.object,
  menuColorScheme: PropTypes.object,
  onAuthenticateEditMode: PropTypes.func,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string
};

export default WsHeaderDrawer;
