import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import WebstoreBuilderSidebarSection from './WebstoreBuilderSidebarSection';
import WebstoreBuilderSidebarAddSectionMenu from './WebstoreBuilderSidebarAddSectionMenu';
import { ECOM_WEBSTORE_SECTION_BOUND_TYPE } from '../../../config/constants';
import { ECOM_WEBSTORE_SECTIONS } from './sections/wsSectionConfig';
import SortableList from '../../common/SortableList';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { ECOM_WEBSTORE_PAGE_CONFIG } from './pages/wsPageConfig';

const WebstoreBuilderSidebar = ({
  loading,
  ecomStoreTheme,
  onSelectSection,
  onSelectSectionBlock,
  onAddSection,
  onRemoveSection,
  onAddSectionBlock,
  onRemoveSectionBlock,
  onToggleOpenSection,
  onToggleHide,
  editState,
  onChangeSectionOrder,
  onChangeSectionBlockOrder,
  controlState,
  mobileEditMode
}) => {
  const { isLaptopOrSmaller } = useMediaQueryIndex();
  const pageSectionsWithConfig =
    editState?.ecomStoreThemeSections.map((s) => ({
      ...s,
      typeConfig:
        ECOM_WEBSTORE_SECTIONS[s.fkEcomStoreThemeSectionType.toString()]
    })) ?? [];
  const headerSections = pageSectionsWithConfig.filter(
    (s) =>
      s.typeConfig.allowedBounds.includes(
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.HEADER
      ) &&
      s.fkEcomStoreThemeSectionLocationType ===
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.HEADER
  );
  const templateSections = pageSectionsWithConfig.filter(
    (s) =>
      s.typeConfig.allowedBounds.includes(
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      ) &&
      s.fkEcomStoreThemeSectionLocationType ===
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY &&
      s.fkEcomStorePageType === controlState.selectedPageType
  );
  const footerSections = pageSectionsWithConfig.filter(
    (s) =>
      s.typeConfig.allowedBounds.includes(
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.FOOTER
      ) &&
      s.fkEcomStoreThemeSectionLocationType ===
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.FOOTER
  );
  const selectedPage = ECOM_WEBSTORE_PAGE_CONFIG[controlState.selectedPageType];

  return (
    !loading &&
    ecomStoreTheme && (
      <AppStack
        style={{
          flex: 1,
          gap: 0,
          overflow: 'hidden'
        }}
      >
        {!isLaptopOrSmaller && (
          <>
            <AppFlexbox style={{ padding: 10 }}>
              <AppText
                style={{ fontSize: 18, fontWeight: 500, paddingLeft: 22 }}
              >
                {selectedPage?.title ?? 'Home Page'}
              </AppText>
            </AppFlexbox>
            <Divider />
          </>
        )}

        <AppStack
          style={{ flex: 1, gap: 0, overflow: 'auto', position: 'relative' }}
        >
          <AppStack style={{ padding: 10, gap: 1 }}>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: isLaptopOrSmaller ? 5 : 0,
                position: 'sticky',
                top: 0,
                backgroundColor: '#FFF',
                zIndex: 1,
                height: 48
              }}
            >
              <AppText
                style={
                  isLaptopOrSmaller
                    ? {
                        fontSize: 16,
                        fontWeight: 500
                      }
                    : {
                        fontSize: 14,
                        fontWeight: 500,
                        paddingLeft: 22
                      }
                }
              >
                Header
              </AppText>
              {!mobileEditMode && isLaptopOrSmaller && (
                <WebstoreBuilderSidebarAddSectionMenu
                  onAdd={onAddSection}
                  sectionBoundsType={ECOM_WEBSTORE_SECTION_BOUND_TYPE.HEADER}
                />
              )}
            </AppFlexbox>
            <SortableList
              items={headerSections}
              listProps={{ style: { gap: isLaptopOrSmaller ? 2 : 0 } }}
              onChange={onChangeSectionOrder}
              renderItem={(s, sortableProps) => (
                <WebstoreBuilderSidebarSection
                  key={s.key}
                  mobileEditMode={mobileEditMode}
                  onAddSectionBlock={onAddSectionBlock}
                  onChangeSectionBlockOrder={onChangeSectionBlockOrder}
                  onRemove={
                    headerSections.length > 1 ? () => onRemoveSection(s) : null
                  }
                  onRemoveSectionBlock={onRemoveSectionBlock}
                  onSelect={() => onSelectSection(s.key)}
                  onSelectSectionBlock={(block) =>
                    onSelectSectionBlock(block.key, s.key)
                  }
                  onToggleHide={onToggleHide}
                  onToggleOpen={() => onToggleOpenSection(s.key)}
                  open={controlState.openSections.includes(s.key)}
                  section={s}
                  selected={controlState.selectedSection === s.key}
                  selectedSectionBlock={controlState.selectedSectionBlock}
                  sortableProps={sortableProps}
                  typeConfig={s.typeConfig}
                />
              )}
            />
            {!isLaptopOrSmaller && (
              <WebstoreBuilderSidebarAddSectionMenu
                onAdd={onAddSection}
                sectionBoundsType={ECOM_WEBSTORE_SECTION_BOUND_TYPE.HEADER}
              />
            )}
          </AppStack>

          <Divider />
          <AppStack style={{ padding: 10, gap: 1 }}>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: isLaptopOrSmaller ? 5 : 0,
                position: 'sticky',
                top: 0,
                backgroundColor: '#FFF',
                zIndex: 1,
                height: 48
              }}
            >
              <AppText
                style={
                  isLaptopOrSmaller
                    ? {
                        fontSize: 16,
                        fontWeight: 500
                      }
                    : {
                        fontSize: 14,
                        fontWeight: 500,
                        paddingLeft: 22
                      }
                }
              >
                Body
              </AppText>
              {!mobileEditMode && isLaptopOrSmaller && (
                <WebstoreBuilderSidebarAddSectionMenu
                  onAdd={onAddSection}
                  sectionBoundsType={ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY}
                />
              )}
            </AppFlexbox>
            <SortableList
              items={templateSections}
              listProps={{ style: { gap: isLaptopOrSmaller ? 2 : 0 } }}
              onChange={onChangeSectionOrder}
              renderItem={(s, sortableProps) => (
                <WebstoreBuilderSidebarSection
                  key={s.key}
                  mobileEditMode={mobileEditMode}
                  onAddSectionBlock={onAddSectionBlock}
                  onChangeSectionBlockOrder={onChangeSectionBlockOrder}
                  onRemove={
                    templateSections.length > 1
                      ? () => onRemoveSection(s)
                      : null
                  }
                  onRemoveSectionBlock={onRemoveSectionBlock}
                  onSelect={() => onSelectSection(s.key)}
                  onSelectSectionBlock={(block) =>
                    onSelectSectionBlock(block.key, s.key)
                  }
                  onToggleHide={onToggleHide}
                  onToggleOpen={() => onToggleOpenSection(s.key)}
                  open={controlState.openSections.includes(s.key)}
                  section={s}
                  selected={controlState.selectedSection === s.key}
                  selectedSectionBlock={controlState.selectedSectionBlock}
                  sortableProps={sortableProps}
                  typeConfig={s.typeConfig}
                />
              )}
            />

            {!mobileEditMode && !isLaptopOrSmaller && (
              <WebstoreBuilderSidebarAddSectionMenu
                onAdd={onAddSection}
                sectionBoundsType={ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY}
              />
            )}
          </AppStack>

          <Divider />
          <AppStack style={{ padding: 10, gap: 1 }}>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: isLaptopOrSmaller ? 5 : 0,
                position: 'sticky',
                top: 0,
                backgroundColor: '#FFF',
                zIndex: 1,
                height: 48
              }}
            >
              <AppText
                style={
                  isLaptopOrSmaller
                    ? {
                        fontSize: 16,
                        fontWeight: 500
                      }
                    : {
                        fontSize: 14,
                        fontWeight: 500,
                        paddingLeft: 22
                      }
                }
              >
                Footer
              </AppText>
              {!mobileEditMode && isLaptopOrSmaller && (
                <WebstoreBuilderSidebarAddSectionMenu
                  onAdd={onAddSection}
                  sectionBoundsType={ECOM_WEBSTORE_SECTION_BOUND_TYPE.FOOTER}
                />
              )}
            </AppFlexbox>

            <SortableList
              items={footerSections}
              listProps={{ style: { gap: isLaptopOrSmaller ? 2 : 0 } }}
              onChange={onChangeSectionOrder}
              renderItem={(s, sortableProps) => (
                <WebstoreBuilderSidebarSection
                  key={s.key}
                  mobileEditMode={mobileEditMode}
                  onAddSectionBlock={onAddSectionBlock}
                  onChangeSectionBlockOrder={onChangeSectionBlockOrder}
                  onRemove={
                    footerSections.length > 1 ? () => onRemoveSection(s) : null
                  }
                  onRemoveSectionBlock={onRemoveSectionBlock}
                  onSelect={() => onSelectSection(s.key)}
                  onSelectSectionBlock={(block) =>
                    onSelectSectionBlock(block.key, s.key)
                  }
                  onToggleHide={onToggleHide}
                  onToggleOpen={() => onToggleOpenSection(s.key)}
                  open={controlState.openSections.includes(s.key)}
                  section={s}
                  selected={controlState.selectedSection === s.key}
                  selectedSectionBlock={controlState.selectedSectionBlock}
                  sortableProps={sortableProps}
                  typeConfig={s.typeConfig}
                />
              )}
            />
            {!isLaptopOrSmaller && (
              <WebstoreBuilderSidebarAddSectionMenu
                onAdd={onAddSection}
                sectionBoundsType={ECOM_WEBSTORE_SECTION_BOUND_TYPE.FOOTER}
              />
            )}
          </AppStack>
        </AppStack>
      </AppStack>
    )
  );
};

WebstoreBuilderSidebar.propTypes = {
  controlState: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editState: PropTypes.object,
  loading: PropTypes.bool,
  mobileEditMode: PropTypes.bool,
  onAddSection: PropTypes.func,
  onAddSectionBlock: PropTypes.func,
  onChangeSectionBlockOrder: PropTypes.func,
  onChangeSectionOrder: PropTypes.func,
  onRemoveSection: PropTypes.func,
  onRemoveSectionBlock: PropTypes.func,
  onSelectSection: PropTypes.func,
  onSelectSectionBlock: PropTypes.func,
  onToggleHide: PropTypes.func,
  onToggleOpenSection: PropTypes.func
};

export default WebstoreBuilderSidebar;
