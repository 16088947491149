import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, Dots, PhotoPlus, Plus } from 'tabler-icons-react';
import {
  ActionIcon,
  Anchor,
  Button,
  Divider,
  Image,
  Menu,
  Select,
  Table,
  Tooltip
} from '@mantine/core';
import { Link } from 'react-router-dom';
import EditProductCard from './EditProductCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import EditProductVariantOption from './EditProductVariantOption';
import AppText from '../../common/AppText';
import AppCheckbox from '../../common/AppCheckbox';
import { currencyFormat } from '../../../helpers/format';
import SortableList from '../../common/SortableList';
import * as classes from '../../../styles/nestedStyles.module.css';
import AppMenu from '../../common/AppMenu';
import ConfirmModal from '../../common/ConfirmModal';
import { useModalState } from '../../../helpers/hooks';
import MediaDropzone from '../media/MediaDropzone';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import CustomNumericFormat from '../../common/CustomNumericFormat';
import CustomNumberInput from '../../common/CustomNumberInput';

const ManageProductVariants = ({
  ecomVendorProduct,
  ecomVendorProductVariantCopy,
  ecomVendorProductVariants,
  onUpdateVendorProductVariantImage,
  onUpdateVendorProductVariants,
  disabled,
  hasUnsavedChanges,
  submitError
}) => {
  const isBulkEditFocused = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [editState, setEditState] = useState({
    activeOptionKeys: [],
    selectedOptionKey: null,
    expandedOptionValues: [],
    selectedOptionValues: []
  });
  const variantOptions = ecomVendorProductVariants
    .reduce((r, c) => {
      c.ecomVendorProductVariantOptions.forEach((d) => {
        const existing = r.find((s) => s.key === d.key);
        if (!existing) {
          r.push({
            key: d.key,
            name: d.name,
            nameSort: d.nameSort,
            initialized: d.initialized,
            values: [
              {
                key: d.valueKey,
                value: d.value,
                valueSort: d.valueSort
              }
            ]
          });
        }
        else if (!existing.values.some((v) => v.key === d.valueKey)) {
          if (!existing.initialized) {
            existing.initialized = d.initialized;
          }
          existing.values.push({
            key: d.valueKey,
            value: d.value,
            valueSort: d.valueSort
          });
        }
      });
      return r;
    }, [])
    .sort((a, b) => a.nameSort - b.nameSort);
  const selectedVariantOption = variantOptions.find(
    (o) => o.key === editState.selectedOptionKey
  );
  const variantOptionsWithValues = variantOptions.filter((v) => v.initialized);
  const allVariantsExpanded = selectedVariantOption?.values.every((v) =>
    editState.expandedOptionValues.includes(v.key)
  );
  const allVariantsOptionsSelected = ecomVendorProductVariants.every((v) =>
    editState.selectedOptionValues.includes(v.key)
  );

  const selectedVariants = ecomVendorProductVariants.filter((v) =>
    editState.selectedOptionValues.includes(v.key)
  );

  const selectedVariantImage = ecomVendorProduct?.ecomVendorProductMedia.find(
    (m) => m.preview === modalState.item?.find((f) => !!f.src)?.src
  );

  useEffect(() => {
    if (variantOptions.length > 0 && !selectedVariantOption) {
      setEditState({
        ...editState,
        selectedOptionKey: variantOptions[0].key,
        expandedOptionValues: [],
        selectedOptionValues: []
      });
    }
  }, [variantOptions]);

  const generateCombinations = (options, prefix = [], depth = 0) => {
    if (depth === options.length) {
      return [prefix];
    }

    const currentOption = options[depth];
    const combinations = [];

    for (const optionValue of currentOption.values) {
      combinations.push(
        ...generateCombinations(
          options,
          [
            ...prefix,
            {
              key: currentOption.key,
              name: currentOption.name,
              nameSort: currentOption.nameSort,
              value: optionValue.value,
              valueSort: optionValue.valueSort,
              valueKey: optionValue.key,
              initialized: true
            }
          ],
          depth + 1
        )
      );
    }

    return combinations;
  };

  const onAddVariantOptionValue = (initialOption = null) => {
    const key = new Date().getTime().toString();
    setEditState({
      ...editState,
      activeOptionKeys: [...editState.activeOptionKeys, key],
      selectedOptionKey: editState.selectedOptionKey ?? key
    });

    const newVariantOption = {
      key: initialOption?.key ?? key,
      name: initialOption?.name ?? '',
      value: initialOption?.value ?? '',
      valueKey: initialOption?.valueKey ?? key,
      valueSort: initialOption?.valueSort ?? 1,
      nameSort: initialOption?.nameSort ?? variantOptions.length + 1,
      initialized: !!initialOption?.value
    };
    const updatedVariants = [
      ...ecomVendorProductVariants
        .filter((f) => f.ecomVendorProductVariantOptions.length > 0)
        .map((v) => ({
          ...v,
          hasUnsavedChanges: true,
          ecomVendorProductVariantOptions: v.ecomVendorProductVariantOptions.some(
            (s) => s.key === newVariantOption.key
          )
            ? v.ecomVendorProductVariantOptions
            : [...v.ecomVendorProductVariantOptions, newVariantOption]
        }))
    ];
    const otherVariantOptions = variantOptions.filter(
      (o) => o.key !== newVariantOption.key
    );
    if (otherVariantOptions.length === 0) {
      onUpdateVendorProductVariants([
        ...updatedVariants,
        {
          ...ecomVendorProductVariantCopy,
          key,
          hasUnsavedChanges: true,
          ecomVendorProductVariantOptions: [newVariantOption]
        }
      ]);
    }
    else {
      const newVariants = [];
      if (initialOption) {
        const variantOptionCombinations = generateCombinations(
          otherVariantOptions
        );
        variantOptionCombinations.forEach((optionCombination, index) => {
          newVariants.push({
            ...ecomVendorProductVariantCopy,
            key: (new Date().getTime() + index).toString(),
            hasUnsavedChanges: true,
            ecomVendorProductVariantOptions: [
              ...optionCombination,
              newVariantOption
            ]
          });
        });
      }

      onUpdateVendorProductVariants([...updatedVariants, ...newVariants]);
    }
  };

  const onUpdateVariantOption = (optionKey, data) => {
    onUpdateVendorProductVariants([
      ...ecomVendorProductVariants.map((v) => ({
        ...v,
        hasUnsavedChanges: true,
        ecomVendorProductVariantOptions: [
          ...v.ecomVendorProductVariantOptions.map((d) => {
            if (d.key === optionKey) {
              return {
                ...d,
                ...data
              };
            }
            return d;
          })
        ]
      }))
    ]);
  };

  const onChangeVariantOptionOrder = (newOrderedList) => {
    onUpdateVendorProductVariants([
      ...ecomVendorProductVariants.map((v) => ({
        ...v,
        hasUnsavedChanges: true,
        ecomVendorProductVariantOptions: [
          ...v.ecomVendorProductVariantOptions.map((d) => ({
            ...d,
            nameSort: newOrderedList.findIndex((o) => o.key === d.key) + 1
          }))
        ]
      }))
    ]);
  };

  const onUpdateVariantOptionValue = (valueKey, value) => {
    const updatedVariants = [
      ...ecomVendorProductVariants.map((v) => ({
        ...v,
        hasUnsavedChanges: true,
        ecomVendorProductVariantOptions: [
          ...v.ecomVendorProductVariantOptions.map((d) => {
            if (d.valueKey === valueKey) {
              return {
                ...d,
                value,
                initialized: true
              };
            }
            return d;
          })
        ]
      }))
    ];
    onUpdateVendorProductVariants(updatedVariants);
  };

  const onChangeVariantOptionValueOrder = (newOrderedList) => {
    onUpdateVendorProductVariants([
      ...ecomVendorProductVariants.map((v) => ({
        ...v,
        hasUnsavedChanges: true,
        ecomVendorProductVariantOptions: [
          ...v.ecomVendorProductVariantOptions.map((d) => {
            const newIndex = newOrderedList.findIndex(
              (o) => o.key === d.valueKey
            );

            return {
              ...d,
              valueSort: newIndex !== -1 ? newIndex + 1 : d.valueSort
            };
          })
        ]
      }))
    ]);
  };

  const onRemove = (optionKey, valueKey = null) => {
    if (!valueKey) {
      setEditState({
        ...editState,
        activeOptionKeys: editState.activeOptionKeys.filter(
          (k) => k !== optionKey
        )
      });
    }

    const hasAnotherInputAvailable = ecomVendorProductVariants.some((v) =>
      v.ecomVendorProductVariantOptions.some(
        (d) => d.key === optionKey && d.valueKey !== valueKey
      )
    );
    if (!valueKey || hasAnotherInputAvailable) {
      onUpdateVendorProductVariants(
        [
          ...(valueKey
            ? ecomVendorProductVariants.filter((v) =>
                v.ecomVendorProductVariantOptions.every(
                  (d) => d.valueKey !== valueKey
                )
              )
            : ecomVendorProductVariants.map((v) => ({
                ...v,
                hasUnsavedChanges: true,
                ecomVendorProductVariantOptions: v.ecomVendorProductVariantOptions.filter(
                  (d) => d.key !== optionKey
                )
              })))
        ].reduce((r, c) => {
          if (c.ecomVendorProductVariantOptions.length > 0) {
            const existingVariantWithMatchingOptions = r.find((s) =>
              s.ecomVendorProductVariantOptions.every((d) =>
                c.ecomVendorProductVariantOptions.some(
                  (e) => e.key === d.key && e.valueKey === d.valueKey
                )
              )
            );
            if (!existingVariantWithMatchingOptions) {
              r.push(c);
            }
          }
          return r;
        }, [])
      );
    }
  };

  const onBulkRemoveOptionValues = () => {
    onUpdateVendorProductVariants(
      [
        ...ecomVendorProductVariants
          .filter((v) => !editState.selectedOptionValues.includes(v.key))
          .map((v) => ({
            ...v,
            ecomVendorProductVariantOptions: v.ecomVendorProductVariantOptions.filter(
              (d) => !editState.selectedOptionValues.includes(d.key)
            )
          }))
      ].reduce((r, c) => {
        if (c.ecomVendorProductVariantOptions.length > 0) {
          const existingVariantWithMatchingOptions = r.find((s) =>
            s.ecomVendorProductVariantOptions.every((d) =>
              c.ecomVendorProductVariantOptions.some(
                (e) => e.key === d.key && e.valueKey === d.valueKey
              )
            )
          );
          if (!existingVariantWithMatchingOptions) {
            r.push(c);
          }
        }
        return r;
      }, [])
    );

    setEditState({
      ...editState,
      selectedOptionValues: []
    });
  };

  const onNavigateVariant = (e) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      setShowSaveAlert(true);
    }
  };

  return (
    <EditProductCard
      contentProps={{ style: { padding: 0 } }}
      title="Variants"
      titleAction={
        variantOptionsWithValues.length > 0 && (
          <Anchor
            c="dodgerblue"
            component={ecomVendorProduct ? Link : null}
            onClick={onNavigateVariant}
            style={{ fontSize: 14 }}
            to={
              ecomVendorProduct
                ? `/vendor/products/${ecomVendorProduct.uuid}/variants/new`
                : null
            }
          >
            Add variant
          </Anchor>
        )
      }
    >
      {!variantOptions.length ? (
        <AppFlexbox
          style={{ alignItems: 'center', gap: 5, padding: 16, paddingTop: 8 }}
        >
          <Plus color="dodgerblue" size={18} />
          <Anchor
            onClick={() => {
              if (!disabled) {
                onAddVariantOptionValue();
              }
            }}
            style={{ color: 'dodgerblue', fontSize: 14 }}
          >
            Add options such as color, size, or material
          </Anchor>
        </AppFlexbox>
      ) : (
        <AppStack style={{ gap: 0 }}>
          <AppCard
            radius="md"
            shadow="none"
            style={{ margin: 16, marginTop: 0, padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <SortableList
                items={variantOptions.map((p) => ({ ...p, sort: p.nameSort }))}
                onChange={onChangeVariantOptionOrder}
                renderItem={(item, sortableProps) => (
                  <React.Fragment key={item.key}>
                    <EditProductVariantOption
                      disabled={disabled}
                      ecomVendorProductVariantOption={item}
                      existingOptions={variantOptions}
                      onAddVariantOptionValue={onAddVariantOptionValue}
                      onChangeOptionName={(newName) =>
                        onUpdateVariantOption(item.key, { name: newName })
                      }
                      onChangeOptionValue={onUpdateVariantOptionValue}
                      onChangeOptionValueSort={onChangeVariantOptionValueOrder}
                      onRemove={() => onRemove(item.key)}
                      onRemoveOptionValue={(valueKey) =>
                        onRemove(item.key, valueKey)
                      }
                      onToggleEdit={(showEdit) => {
                        setEditState({
                          ...editState,
                          activeOptionKeys: showEdit
                            ? [...editState.activeOptionKeys, item.key]
                            : editState.activeOptionKeys.filter(
                                (k) => k !== item.key
                              )
                        });
                      }}
                      option={item}
                      showEdit={editState.activeOptionKeys.includes(item.key)}
                      sortableProps={sortableProps}
                      submitError={submitError}
                    />
                    <Divider />
                  </React.Fragment>
                )}
              />
              {variantOptions.length < 3 && (
                <AppFlexbox
                  style={{ alignItems: 'center', gap: 5, padding: '16px 8px' }}
                >
                  <Plus color="dodgerblue" size={18} />
                  <Anchor
                    onClick={() => {
                      if (!disabled) {
                        onAddVariantOptionValue();
                      }
                    }}
                    style={{ color: 'dodgerblue', fontSize: 14 }}
                  >
                    Add another option
                  </Anchor>
                </AppFlexbox>
              )}
            </AppStack>
          </AppCard>
          <AppStack style={{ gap: 0 }}>
            {variantOptionsWithValues.length > 0 && (
              <AppFlexbox style={{ gap: 5, padding: 8, alignItems: 'center' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Group by
                </AppText>
                <Select
                  data={variantOptionsWithValues.map((v) => ({
                    label: v.name,
                    value: v.key
                  }))}
                  onChange={(value) =>
                    setEditState({
                      ...editState,
                      selectedOptionKey: value
                    })
                  }
                  style={{ maxWidth: 200, flex: 1 }}
                  value={selectedVariantOption?.key}
                />
              </AppFlexbox>
            )}

            {variantOptionsWithValues.length > 0 && (
              <Table
                highlightOnHover
                horizontalSpacing={0}
                style={{ borderTop: '1px solid #dee2e6' }}
                verticalSpacing={0}
              >
                <Table.Thead
                  style={{ backgroundColor: 'rgba(56, 56, 56, 0.1)' }}
                >
                  <Table.Tr>
                    <Table.Th
                      colSpan={
                        editState.selectedOptionValues.length > 0 ? 3 : 1
                      }
                    >
                      <AppFlexbox
                        style={{
                          alignItems: 'center',
                          padding: 8,
                          minHeight: 42
                        }}
                      >
                        <AppCheckbox
                          checked={allVariantsOptionsSelected}
                          disabled={disabled}
                          onChange={() => {
                            setEditState({
                              ...editState,
                              selectedOptionValues: allVariantsOptionsSelected
                                ? []
                                : ecomVendorProductVariants.map((v) => v.key)
                            });
                          }}
                        />
                        {editState.selectedOptionValues.length > 0 ? (
                          <AppFlexbox
                            style={{
                              flex: 1,
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: 8
                            }}
                          >
                            <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                              {editState.selectedOptionValues.length} selected
                            </AppText>

                            <AppFlexbox
                              style={{ gap: 8, alignItems: 'center' }}
                            >
                              <Button
                                color="dark"
                                component={Link}
                                disabled={disabled}
                                onClick={onNavigateVariant}
                                radius="md"
                                size="compact-sm"
                                to={`/vendor/products/bulk-edit?ids=${
                                  ecomVendorProduct?.uuid
                                }&variants=${selectedVariants
                                  .map((m) => m.uuid)
                                  .join(',')}`}
                                variant="filled"
                                visibleFrom="xsm"
                              >
                                Bulk edit
                              </Button>
                              <AppMenu
                                control={
                                  <ActionIcon
                                    color="dark"
                                    disabled={disabled}
                                    radius="md"
                                    size="compact-md"
                                    style={{ border: 'solid 1px #000' }}
                                    variant="white"
                                  >
                                    <Dots style={{ color: '#000' }} />
                                  </ActionIcon>
                                }
                              >
                                <Menu.Item
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onBulkRemoveOptionValues();
                                  }}
                                  style={{ color: 'red' }}
                                >
                                  Delete variants
                                </Menu.Item>
                              </AppMenu>
                            </AppFlexbox>
                          </AppFlexbox>
                        ) : (
                          <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                            <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                              Variant
                            </AppText>
                            {variantOptions.length > 1 && (
                              <AppText style={{ fontSize: 13, color: '#666' }}>
                                ·{' '}
                                <Anchor
                                  c="#666"
                                  onClick={() => {
                                    setEditState({
                                      ...editState,
                                      expandedOptionValues: allVariantsExpanded
                                        ? []
                                        : selectedVariantOption?.values.map(
                                            (v) => v.key
                                          )
                                    });
                                  }}
                                  style={{ fontSize: 13 }}
                                >
                                  {allVariantsExpanded
                                    ? 'Collapse all'
                                    : 'Expand all'}
                                </Anchor>
                              </AppText>
                            )}
                          </AppFlexbox>
                        )}
                      </AppFlexbox>
                    </Table.Th>
                    {editState.selectedOptionValues.length === 0 && (
                      <Table.Th style={{ width: 200 }}>
                        <AppFlexbox
                          style={{ alignItems: 'center', padding: 8 }}
                        >
                          <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                            Price
                          </AppText>
                        </AppFlexbox>
                      </Table.Th>
                    )}
                    {editState.selectedOptionValues.length === 0 && (
                      <Table.Th style={{ width: 100 }} visibleFrom="sm">
                        <AppFlexbox
                          style={{ alignItems: 'center', padding: 8 }}
                        >
                          <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                            Available
                          </AppText>
                        </AppFlexbox>
                      </Table.Th>
                    )}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {selectedVariantOption?.values
                    .sort((a, b) => a.valueSort - b.valueSort)
                    .map((value) => {
                      const isExpanded = editState.expandedOptionValues.includes(
                        value.key
                      );
                      const optionVariants = ecomVendorProductVariants.filter(
                        (v) =>
                          v.ecomVendorProductVariantOptions.some(
                            (d) => d.valueKey === value.key
                          )
                      );
                      const variantPrices = optionVariants
                        .map((v) => v.price)
                        .filter((f) => !!f || f === 0);

                      const minPrice = Math.min(...variantPrices);
                      const maxPrice = Math.max(...variantPrices);
                      const allVariantsSelected = optionVariants.every((v) =>
                        editState.selectedOptionValues.includes(v.key)
                      );

                      const expandableVariantOptions = optionVariants.filter(
                        (v) => v.ecomVendorProductVariantOptions.length > 1
                      );
                      const imageSrc = optionVariants.find((v) => !!v.src)?.src;

                      return (
                        <React.Fragment key={value.key}>
                          <Table.Tr
                            onClick={() => {
                              setEditState({
                                ...editState,
                                expandedOptionValues: isExpanded
                                  ? editState.expandedOptionValues.filter(
                                      (k) => k !== value.key
                                    )
                                  : [
                                      ...editState.expandedOptionValues,
                                      value.key
                                    ]
                              });
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <Table.Td style={{}}>
                              <AppFlexbox
                                style={{
                                  alignItems: 'center',
                                  padding: '16px 8px'
                                }}
                              >
                                <AppCheckbox
                                  checked={allVariantsSelected}
                                  disabled={disabled}
                                  onChange={() => {
                                    setEditState({
                                      ...editState,
                                      selectedOptionValues: allVariantsSelected
                                        ? editState.selectedOptionValues.filter(
                                            (s) =>
                                              !optionVariants.some(
                                                (v) => v.key === s
                                              )
                                          )
                                        : [
                                            ...editState.selectedOptionValues,
                                            ...optionVariants.map((v) => v.key)
                                          ]
                                    });
                                  }}
                                />
                                <AppStack
                                  className={classes['hover-button']}
                                  onClick={(e) => {
                                    if (!disabled) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onOpenModal(
                                        VIEW_ACTIONS_ENUM.UPLOAD,
                                        optionVariants
                                      );
                                    }
                                  }}
                                  style={{
                                    width: 50,
                                    height: 50,
                                    border: imageSrc
                                      ? 'solid 1px lightgrey'
                                      : 'dashed 1px dodgerblue',
                                    borderRadius: 8,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {!disabled && (
                                    <AppStack
                                      className={classes['hover-show']}
                                      style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        left: 0,
                                        top: 0
                                      }}
                                    />
                                  )}

                                  {imageSrc ? (
                                    <Image
                                      fit="contain"
                                      h="100%"
                                      src={imageSrc}
                                      w="100%"
                                    />
                                  ) : (
                                    <PhotoPlus color="dodgerblue" size={24} />
                                  )}
                                </AppStack>
                                <AppStack
                                  className={classes['hover-text-action']}
                                  component={
                                    ecomVendorProduct &&
                                    expandableVariantOptions.length === 0
                                      ? Link
                                      : null
                                  }
                                  onClick={
                                    expandableVariantOptions.length === 0
                                      ? onNavigateVariant
                                      : null
                                  }
                                  style={{ flex: 1, gap: 0, color: '#000' }}
                                  to={
                                    ecomVendorProduct &&
                                    expandableVariantOptions.length === 0
                                      ? `/vendor/products/${ecomVendorProduct.uuid}/variants/${optionVariants[0]?.uuid}`
                                      : null
                                  }
                                >
                                  <AppText
                                    style={{ fontSize: 13, fontWeight: 500 }}
                                  >
                                    {value.value}
                                  </AppText>
                                  {expandableVariantOptions.length > 0 && (
                                    <AppFlexbox
                                      style={{ gap: 5, alignItems: 'center' }}
                                    >
                                      <AppText
                                        style={{
                                          fontSize: 12,
                                          color: '#666',
                                          whiteSpace: 'nowrap'
                                        }}
                                      >
                                        {expandableVariantOptions.length}{' '}
                                        Variants
                                      </AppText>
                                      <ChevronDown color="#666" size={16} />
                                    </AppFlexbox>
                                  )}
                                </AppStack>
                              </AppFlexbox>
                            </Table.Td>
                            <Table.Td style={{ width: 200 }}>
                              <AppFlexbox
                                style={{
                                  alignItems: 'center',
                                  padding: '16px 8px'
                                }}
                              >
                                <CustomNumericFormat
                                  disabled={disabled}
                                  onBlur={() => {
                                    isBulkEditFocused.current = false;
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.stopPropagation();
                                  }}
                                  onFocus={() => {
                                    isBulkEditFocused.current = true;
                                  }}
                                  onValueChange={(values) => {
                                    if (
                                      isBulkEditFocused.current &&
                                      values.floatValue * 100 !== minPrice
                                    ) {
                                      onUpdateVendorProductVariants([
                                        ...ecomVendorProductVariants.map((v) =>
                                          optionVariants.some(
                                            (o) => o.key === v.key
                                          )
                                            ? {
                                                ...v,
                                                hasUnsavedChanges: true,
                                                price: Math.round(
                                                  values.floatValue * 100
                                                )
                                              }
                                            : v
                                        )
                                      ]);
                                    }
                                  }}
                                  placeholder={
                                    !variantPrices.length
                                      ? '$0.00'
                                      : minPrice === maxPrice
                                      ? currencyFormat(minPrice / 100)
                                      : `${currencyFormat(
                                          minPrice / 100
                                        )} - ${currencyFormat(maxPrice / 100)}`
                                  }
                                  style={{ flex: 1 }}
                                  value={
                                    minPrice === maxPrice ? minPrice / 100 : ''
                                  }
                                />
                              </AppFlexbox>
                            </Table.Td>
                            <Table.Td style={{ width: 100 }} visibleFrom="sm">
                              <AppFlexbox
                                style={{
                                  alignItems: 'center',
                                  padding: '16px 8px'
                                }}
                              >
                                <Tooltip
                                  disabled={
                                    expandableVariantOptions.length === 0 ||
                                    (optionVariants.length === 1 &&
                                      optionVariants[0]?.trackQuantity)
                                  }
                                  label={
                                    optionVariants.length > 1 ? (
                                      <AppStack style={{ gap: 0 }}>
                                        <AppText style={{ fontSize: 14 }}>
                                          For a single variant option only.
                                          Please update the variant directly.
                                        </AppText>
                                      </AppStack>
                                    ) : (
                                      <AppStack style={{ gap: 0 }}>
                                        <AppText style={{ fontSize: 14 }}>
                                          Inventory is not tracked
                                        </AppText>
                                      </AppStack>
                                    )
                                  }
                                  multiline
                                  position="bottom"
                                  w={200}
                                  withArrow
                                >
                                  <CustomNumberInput
                                    decimalScale={0}
                                    disabled={
                                      disabled ||
                                      optionVariants.length > 1 ||
                                      !optionVariants[0].trackQuantity
                                    }
                                    fixedDecimalScale
                                    hideControls={
                                      disabled ||
                                      expandableVariantOptions.length > 0
                                    }
                                    min={0}
                                    onChange={(quantity) => {
                                      onUpdateVendorProductVariants([
                                        ...ecomVendorProductVariants.map((v) =>
                                          optionVariants.some(
                                            (o) => o.key === v.key
                                          )
                                            ? {
                                                ...v,
                                                hasUnsavedChanges: true,
                                                quantity
                                              }
                                            : v
                                        )
                                      ]);
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.stopPropagation();
                                    }}
                                    style={{ flex: 1 }}
                                    value={
                                      optionVariants.length > 1
                                        ? 0
                                        : optionVariants[0]?.quantity ?? 0
                                    }
                                  />
                                </Tooltip>
                              </AppFlexbox>
                            </Table.Td>
                          </Table.Tr>
                          {isExpanded &&
                            expandableVariantOptions.map((v) => {
                              const variantSelected = editState.selectedOptionValues.includes(
                                v.key
                              );

                              return (
                                <Table.Tr key={v.key}>
                                  <Table.Td style={{}}>
                                    <AppFlexbox
                                      style={{
                                        alignItems: 'center',
                                        padding: 0,
                                        paddingLeft: 40
                                      }}
                                    >
                                      <AppCheckbox
                                        checked={variantSelected}
                                        disabled={disabled}
                                        onChange={() => {
                                          setEditState({
                                            ...editState,
                                            selectedOptionValues: variantSelected
                                              ? editState.selectedOptionValues.filter(
                                                  (s) => s !== v.key
                                                )
                                              : [
                                                  ...editState.selectedOptionValues,
                                                  v.key
                                                ]
                                          });
                                        }}
                                      />
                                      <AppStack
                                        className={classes['hover-button']}
                                        onClick={(e) => {
                                          if (!disabled) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onOpenModal(
                                              VIEW_ACTIONS_ENUM.UPLOAD,
                                              [v]
                                            );
                                          }
                                        }}
                                        style={{
                                          width: 30,
                                          height: 30,
                                          border: v.src
                                            ? 'solid 1px lightgrey'
                                            : 'dashed 1px dodgerblue',
                                          borderRadius: 8,
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          overflow: 'hidden',
                                          position: 'relative',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        {!disabled && (
                                          <AppStack
                                            className={classes['hover-show']}
                                            style={{
                                              backgroundColor:
                                                'rgba(0, 0, 0, 0.5)',
                                              position: 'absolute',
                                              width: '100%',
                                              height: '100%',
                                              left: 0,
                                              top: 0
                                            }}
                                          />
                                        )}
                                        {v.src ? (
                                          <Image
                                            fit="contain"
                                            h="100%"
                                            src={v.src}
                                            w="100%"
                                          />
                                        ) : (
                                          <PhotoPlus
                                            color="dodgerblue"
                                            size={18}
                                          />
                                        )}
                                      </AppStack>
                                      <AppStack
                                        className={classes['hover-text-action']}
                                        component={
                                          ecomVendorProduct ? Link : null
                                        }
                                        onClick={onNavigateVariant}
                                        style={{
                                          justifyContent: 'center',
                                          flex: 1,
                                          height: 50,
                                          color: '#000'
                                        }}
                                        to={
                                          ecomVendorProduct
                                            ? `/vendor/products/${ecomVendorProduct.uuid}/variants/${v.uuid}`
                                            : null
                                        }
                                      >
                                        <AppText
                                          style={{
                                            fontSize: 13,
                                            fontWeight: 500
                                          }}
                                        >
                                          {v.ecomVendorProductVariantOptions
                                            .filter(
                                              (op) =>
                                                op.valueKey !== value.key &&
                                                op.value &&
                                                op.name
                                            )
                                            .sort(
                                              (a, b) => a.nameSort - b.nameSort
                                            )
                                            .map((m) => m.value)
                                            .join(' / ')}
                                        </AppText>
                                      </AppStack>
                                    </AppFlexbox>
                                  </Table.Td>
                                  <Table.Td style={{ width: 200 }}>
                                    <AppFlexbox
                                      style={{
                                        alignItems: 'center',
                                        padding: 8
                                      }}
                                    >
                                      <CustomNumericFormat
                                        disabled={disabled}
                                        onValueChange={(values) => {
                                          if (
                                            values.floatValue * 100 !==
                                            v.price
                                          ) {
                                            onUpdateVendorProductVariants([
                                              ...ecomVendorProductVariants.map(
                                                (m) =>
                                                  m.key === v.key
                                                    ? {
                                                        ...m,
                                                        hasUnsavedChanges: true,
                                                        price: Math.round(
                                                          values.floatValue *
                                                            100
                                                        )
                                                      }
                                                    : m
                                              )
                                            ]);
                                          }
                                        }}
                                        style={{ flex: 1 }}
                                        value={v.price ? v.price / 100 : ''}
                                      />
                                    </AppFlexbox>
                                  </Table.Td>
                                  <Table.Td
                                    style={{ width: 100 }}
                                    visibleFrom="sm"
                                  >
                                    <AppFlexbox
                                      style={{
                                        alignItems: 'center',
                                        padding: 8
                                      }}
                                    >
                                      <Tooltip
                                        disabled={v.trackQuantity}
                                        label={
                                          <AppStack style={{ gap: 0 }}>
                                            <AppText style={{ fontSize: 14 }}>
                                              Inventory is not tracked
                                            </AppText>
                                          </AppStack>
                                        }
                                        position="bottom"
                                        withArrow
                                      >
                                        <CustomNumberInput
                                          decimalScale={0}
                                          disabled={
                                            disabled || !v.trackQuantity
                                          }
                                          fixedDecimalScale
                                          hideControls={disabled}
                                          min={0}
                                          onChange={(quantity) => {
                                            onUpdateVendorProductVariants([
                                              ...ecomVendorProductVariants.map(
                                                (m) => ({
                                                  ...m,
                                                  hasUnsavedChanges: true,
                                                  quantity:
                                                    m.key === v.key
                                                      ? quantity
                                                      : m.quantity
                                                })
                                              )
                                            ]);
                                          }}
                                          style={{ flex: 1 }}
                                          value={v.quantity ?? 0}
                                        />
                                      </Tooltip>
                                    </AppFlexbox>
                                  </Table.Td>
                                </Table.Tr>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                </Table.Tbody>
              </Table>
            )}
          </AppStack>
        </AppStack>
      )}

      <AppStack style={{ gap: 0, display: 'none' }}>
        <MediaDropzone
          enableMediaSelect
          files={selectedVariantImage ? [selectedVariantImage] : []}
          fkEcomVendor={authState.pkEcomVendor}
          isOpen={
            modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.UPLOAD
          }
          maxSelectCount={1}
          newFiles={ecomVendorProduct.ecomVendorProductMedia.filter(
            (m) => !m.deleted
          )}
          onClose={onCloseModal}
          onUpload={(uploadedMedia) =>
            onUpdateVendorProductVariantImage(uploadedMedia[0], modalState.item)
          }
          skipMediaFetch
        />
      </AppStack>

      <ConfirmModal
        cancelActionText="Close"
        formSectionProps={{ isSubmitHidden: true }}
        isOpen={showSaveAlert}
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText>
              You have unsaved changes. Please save or discard them before
              editing variants.
            </AppText>
          </AppStack>
        }
        onCancel={() => setShowSaveAlert(false)}
        onConfirm={() => {
          setShowSaveAlert(false);
        }}
        title="Unsaved changes"
      />
    </EditProductCard>
  );
};

ManageProductVariants.propTypes = {
  disabled: PropTypes.bool,
  ecomVendorProduct: PropTypes.object,
  ecomVendorProductVariantCopy: PropTypes.object,
  ecomVendorProductVariants: PropTypes.array,
  hasUnsavedChanges: PropTypes.bool,
  onUpdateVendorProductVariantImage: PropTypes.func,
  onUpdateVendorProductVariants: PropTypes.func,
  submitError: PropTypes.any
};

export default ManageProductVariants;
