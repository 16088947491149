import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Anchor,
  Badge,
  Button,
  Checkbox,
  ScrollArea,
  Table
} from '@mantine/core';
import { AlertCircle, ArrowLeft, ArrowRight } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import FileUpload from '../../common/FileUpload';
import { downloadPapaCsv, parsePapaCsv } from '../../../helpers/csvHelper';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notification';
import { singularPluralFormat } from '../../../helpers/format';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';

const ImportCsvModal = ({
  isOpen,
  onClose,
  importConfig,
  descriptor,
  onImportCsvData,
  onImport,
  overrideRequired
}) => {
  const [formState, setFormState] = useState({
    file: null,
    overrideExisting: true,
    data: [],
    headers: [],
    previewIndex: 0,
    showPreview: false,
    loading: false,
    importing: false,
    importComplete: false
  });
  const dataWithIdentifiers = formState.data.filter(
    (d) => !!d[importConfig.identifier.value]
  );

  const importData = importConfig.getImportData(
    formState.data,
    overrideRequired
  );
  const importItems = overrideRequired
    ? importData.existingItems
    : formState.overrideExisting
    ? importData.items
    : importData.newItems;
  const importSubitems = overrideRequired
    ? importData.existingSubitems
    : formState.overrideExisting
    ? importData.subitems
    : importData.newSubitems;

  const previewData =
    importItems[formState.previewIndex] ?? null
      ? importConfig.getPreviewData(
          importItems[formState.previewIndex],
          formState.headers
        )
      : null;

  useEffect(() => {
    if (isOpen) {
      setFormState({
        file: null,
        overrideExisting: true,
        data: [],
        headers: [],
        previewIndex: 0,
        showPreview: false,
        loading: false,
        importing: false,
        importComplete: false
      });
    }
  }, [isOpen]);

  const onDownloadCsvTemplate = () => {
    downloadPapaCsv(
      `SportsHeadz_import_${descriptor}_template`,
      importConfig.fieldSections
        .map((s) => s.fields)
        .flat()
        .reduce((r, c) => [...r, ...c.exportColumns.map((m) => m.label)], []),
      importConfig.sampleData
    );
  };

  const onErrorCallback = (error) => {
    triggerNotification(error);
    setFormState({ ...formState, loading: false, importing: false });
  };

  const onUploadCsv = (file) => {
    setFormState({ ...formState, loading: true });
    parsePapaCsv(
      file,
      importConfig.fieldSections
        .map((s) => s.fields)
        .flat()
        .reduce((r, c) => [...r, ...c.exportColumns], []),
      async (csvData, { foundHeaders }) => {
        const parsedData = await importConfig.parseCsvData(csvData);
        onImportCsvData(
          parsedData,
          (data) => {
            setFormState({
              ...formState,
              data,
              headers: foundHeaders,
              file,
              loading: false
            });
          },
          onErrorCallback
        );
      },
      onErrorCallback,
      { skipRequiredCheck: true }
    );
  };

  return (
    <ResponsiveModal
      {...(formState.importing
        ? {
            title: formState.importComplete
              ? `Import complete`
              : `Importing ${descriptor}`,
            formSectionProps: {
              onSubmit: onClose,
              submitTitle: `Close`,
              isCancelHidden: true,
              padding: 0
            }
          }
        : formState.showPreview
        ? {
            title: 'Preview import',
            formSectionProps: {
              onCancel: () => {
                setFormState({ ...formState, showPreview: false });
              },
              onSubmit: () => {
                setFormState({ ...formState, importing: true });
                onImport(
                  {
                    data: importItems,
                    overrideExisting:
                      overrideRequired || formState.overrideExisting
                  },
                  () => {
                    triggerNotification(
                      `${descriptor[0].toUpperCase()}${descriptor.slice(
                        1,
                        descriptor.length
                      )} imported!`,
                      'success'
                    );
                    setFormState((c) => ({
                      ...c,
                      importComplete: c.importing
                    }));
                  },
                  onErrorCallback
                );
              },
              cancelTitle: 'Back',
              submitTitle: `Import ${descriptor}`,
              padding: 0
            }
          }
        : {
            title: `Import ${descriptor}`,
            formSectionProps: {
              onCancel: onClose,
              onSubmit: () =>
                setFormState({
                  ...formState,
                  showPreview: true,
                  previewIndex: 0
                }),
              submitTitle: `Preview ${descriptor}`,
              isSubmitDisabled: importItems.length === 0,
              leftSection: (
                <Anchor
                  c="dodgerblue"
                  onClick={onDownloadCsvTemplate}
                  style={{ fontSize: 14 }}
                >
                  Download CSV template
                </Anchor>
              )
            }
          })}
      isOpen={isOpen}
      onClose={onClose}
    >
      {formState.importing ? (
        <AppStack style={{ padding: 16, gap: 0 }}>
          {formState.importComplete ? (
            <Alert
              color="#067D62"
              icon={<AlertCircle size={22} />}
              title={`${descriptor[0].toUpperCase()}${descriptor.slice(
                1,
                descriptor.length
              )} imported!`}
              variant="outline"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  You have successfully imported your {descriptor}.
                </AppText>
              </AppStack>
            </Alert>
          ) : (
            <Alert
              color="dodgerblue"
              icon={<AlertCircle size={22} />}
              title={`Importing ${descriptor}`}
              variant="outline"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Importing your {descriptor}.
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  You can close this dialog and check back later. You will be
                  notified when the import is complete.
                </AppText>
              </AppStack>
            </Alert>
          )}
        </AppStack>
      ) : formState.showPreview ? (
        <AppStack style={{ gap: 0 }}>
          <AppStack style={{ padding: 16, gap: 10 }}>
            <Alert color="blue" variant="outline">
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }}>
                  You are about to import{' '}
                  <b>
                    {singularPluralFormat(
                      importItems.length,
                      importData.itemsLabel,
                      importData.itemsLabelPlural
                    )}
                  </b>
                  {importSubitems.length > 0 ? (
                    <>
                      {' '}
                      with a total of{' '}
                      <b>
                        {singularPluralFormat(
                          importSubitems.length,
                          importData.subitemsLabel,
                          importData.subitemsLabelPlural
                        )}
                      </b>
                    </>
                  ) : (
                    ''
                  )}
                  .
                </AppText>

                {!overrideRequired && !formState.overrideExisting ? (
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    {singularPluralFormat(
                      importItems.length,
                      `new ${importData.itemsLabel}`,
                      `new ${importData.itemsLabelPlural}`
                    )}{' '}
                    and{' '}
                    {singularPluralFormat(
                      importSubitems.length,
                      `new ${importData.subitemsLabel}`,
                      `new ${importData.subitemsLabelPlural}`
                    )}{' '}
                    will be created.
                  </AppText>
                ) : (
                  <>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {singularPluralFormat(
                        importData.existingItems.length,
                        importData.itemsLabel,
                        importData.itemsLabelPlural
                      )}{' '}
                      will be updated and{' '}
                      {singularPluralFormat(
                        importItems.length - importData.existingItems.length,
                        importData.itemsLabel,
                        importData.itemsLabelPlural
                      )}{' '}
                      will be created.
                    </AppText>

                    {importSubitems.length > 0 && (
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {singularPluralFormat(
                          importData.existingSubitems.length,
                          importData.subitemsLabel,
                          importData.subitemsLabelPlural
                        )}{' '}
                        will be updated and{' '}
                        {singularPluralFormat(
                          importItems.length - importData.existingItems.length,
                          importData.subitemsLabel,
                          importData.subitemsLabelPlural
                        )}{' '}
                        will be created.
                      </AppText>
                    )}
                  </>
                )}
              </AppStack>
            </Alert>
          </AppStack>

          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              fontStyle: 'italic',
              color: '#666',
              textAlign: 'center',
              marginBottom: 16
            }}
          >
            Review the data below to ensure it is correct before importing.
          </AppText>

          {previewData && (
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0px 16px'
                }}
              >
                <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>
                    {importData.itemsLabel[0].toUpperCase()}
                    {importData.itemsLabel.slice(
                      1,
                      importData.itemsLabel.length
                    )}{' '}
                    ({formState.previewIndex + 1}/{importItems.length})
                  </AppText>
                  {((!overrideRequired && !formState.overrideExisting) ||
                    previewData.isNewEntry) && (
                    <Badge color="blue" size="sm" variant="filled">
                      NEW
                    </Badge>
                  )}
                </AppFlexbox>

                <Button.Group>
                  <Button
                    color="dark"
                    disabled={importItems.length === 1}
                    onClick={() => {
                      const prevIndex = formState.previewIndex - 1;
                      setFormState({
                        ...formState,
                        previewIndex:
                          prevIndex < 0 ? importItems.length - 1 : prevIndex
                      });
                    }}
                    size="compact-sm"
                    variant="outline"
                  >
                    <ArrowLeft size={18} />
                  </Button>
                  <Button
                    color="dark"
                    disabled={importItems.length === 1}
                    onClick={() => {
                      const nextIndex = formState.previewIndex + 1;
                      setFormState({
                        ...formState,
                        previewIndex:
                          nextIndex >= importItems.length ? 0 : nextIndex
                      });
                    }}
                    size="compact-sm"
                    variant="outline"
                  >
                    <ArrowRight size={18} />
                  </Button>
                </Button.Group>
              </AppFlexbox>

              <AppStack style={{ gap: 0 }}>
                {previewData.columns.map((c) => (
                  <AppCard
                    key={c.dataValue}
                    radius={0}
                    shadow="none"
                    style={{
                      borderTop: 'solid 1px  #dee2e6',
                      backgroundColor: 'rgba(56, 56, 56, 0.1)'
                    }}
                  >
                    <AppFlexbox>
                      <AppFlexbox style={{ width: 150 }}>
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {c.label}
                        </AppText>
                      </AppFlexbox>
                      <AppFlexbox style={{ flex: 1 }}>
                        <AppText style={{ fontSize: 14 }}>{c.value}</AppText>
                      </AppFlexbox>
                    </AppFlexbox>
                  </AppCard>
                ))}
                {previewData.subData && (
                  <ScrollArea.Autosize
                    maw="100%"
                    miw="100%"
                    mx="auto"
                    scrollbarSize={5}
                  >
                    <Table
                      horizontalSpacing={16}
                      style={{ borderTop: 'solid 1px  #dee2e6' }}
                      verticalSpacing={8}
                    >
                      <Table.Thead>
                        <Table.Tr>
                          {previewData.subData[0]?.columns.map((c) => (
                            <Table.Th key={c.dataValue}>
                              <AppText
                                style={{
                                  whiteSpace: 'nowrap',
                                  fontSize: 14,
                                  fontWeight: 650
                                }}
                              >
                                {c.label}
                              </AppText>
                            </Table.Th>
                          ))}
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {previewData.subData.map((d, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Table.Tr key={i}>
                            {d.columns.map((c) => (
                              <Table.Td key={c.dataValue}>
                                <AppText
                                  style={{
                                    whiteSpace: 'nowrap',
                                    fontSize: 14
                                  }}
                                >
                                  {c.value}
                                </AppText>
                              </Table.Td>
                            ))}
                          </Table.Tr>
                        ))}
                      </Table.Tbody>
                    </Table>
                  </ScrollArea.Autosize>
                )}
              </AppStack>
            </AppStack>
          )}
        </AppStack>
      ) : (
        <AppStack style={{ gap: 20 }}>
          <FileUpload
            file={formState.file}
            height={200}
            loading={formState.loading}
            onUpload={(files) => {
              onUploadCsv(files[0]);
            }}
          />
          {formState.file && (
            <AppStack>
              {formState.data.length === 0 ? (
                <Alert
                  color="#c40000"
                  icon={<AlertCircle size={22} />}
                  title={`No ${importData.itemsLabelPlural} found.`}
                  variant="outline"
                >
                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Unable to parse the CSV file. Please ensure the data is
                      correct and try again.
                    </AppText>
                  </AppStack>
                </Alert>
              ) : dataWithIdentifiers.length > 0 ? (
                <>
                  {importData.existingItems.length === 0 ? (
                    <Alert
                      color={overrideRequired ? '#c40000' : '#eca70a'}
                      icon={<AlertCircle size={22} />}
                      title={`No ${importData.itemsLabelPlural} found.`}
                      variant="outline"
                    >
                      <AppStack style={{ gap: 5 }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          No {importData.itemsLabelPlural} found by{' '}
                          {importConfig.identifier.name}.
                        </AppText>
                        {overrideRequired ? (
                          <AppText style={{ fontSize: 14 }}>
                            Please ensure the {importConfig.identifier.name}{' '}
                            column is correct and try again.
                          </AppText>
                        ) : (
                          <AppText style={{ fontSize: 14 }}>
                            Are you sure you want to continue importing?{' '}
                            <b>
                              You will create{' '}
                              {singularPluralFormat(
                                dataWithIdentifiers.length -
                                  importData.existingItems.length,
                                `new ${importData.itemsLabel}`,
                                `new ${importData.itemsLabelPlural}`
                              )}
                              .
                            </b>
                          </AppText>
                        )}
                      </AppStack>
                    </Alert>
                  ) : importData.existingItems.length !==
                    dataWithIdentifiers.length ? (
                    <Alert
                      color="dodgerblue"
                      icon={<AlertCircle size={22} />}
                      title={`${singularPluralFormat(
                        dataWithIdentifiers.length -
                          importData.existingItems.length,
                        `${importData.itemsLabel}`,
                        `${importData.itemsLabelPlural}`
                      )} not found.`}
                      variant="outline"
                    >
                      <AppStack style={{ gap: 5 }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {singularPluralFormat(
                            dataWithIdentifiers.length,
                            importConfig.identifier.name,
                            importConfig.identifier.namePlural
                          )}{' '}
                          provided but only{' '}
                          {singularPluralFormat(
                            importData.existingItems.length,
                            importData.itemsLabel,
                            importData.itemsLabelPlural
                          )}{' '}
                          were found.
                        </AppText>
                        <AppText style={{ fontSize: 14 }}>
                          Are you sure you want to continue importing?{' '}
                          <b>
                            {overrideRequired ? (
                              'You will only update the found entries'
                            ) : (
                              <>
                                You will create{' '}
                                {singularPluralFormat(
                                  dataWithIdentifiers.length -
                                    importData.existingItems.length,
                                  `new ${importData.itemsLabel}`,
                                  `new ${importData.itemsLabelPlural}`
                                )}
                              </>
                            )}
                            .
                          </b>
                        </AppText>
                      </AppStack>
                    </Alert>
                  ) : (
                    <Alert
                      color="#067D62"
                      icon={<AlertCircle size={22} />}
                      title={`All ${importConfig.identifier.namePlural} found.`}
                      variant="outline"
                    >
                      <AppStack style={{ gap: 5 }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {singularPluralFormat(
                            dataWithIdentifiers.length,
                            importConfig.identifier.name,
                            importConfig.identifier.namePlural
                          )}{' '}
                          provided and{' '}
                          {singularPluralFormat(
                            importData.existingItems.length,
                            `existing ${importData.itemsLabel}`,
                            `existing ${importData.itemsLabelPlural}`
                          )}{' '}
                          found.
                        </AppText>
                      </AppStack>
                    </Alert>
                  )}

                  {importData.existingItems.length > 0 && (
                    <Checkbox
                      checked={formState.overrideExisting || overrideRequired}
                      description={`${importData.itemsLabelPlural[0].toUpperCase()}${importData.itemsLabelPlural.slice(
                        1,
                        importData.itemsLabelPlural.length
                      )} with a matching ${
                        importConfig.identifier.name
                      } will be overridden with any included columns. You can export your ${descriptor} to easly get a list of your ${
                        importData.itemsLabel
                      } ${importConfig.identifier.namePlural}.`}
                      disabled={overrideRequired}
                      label={`Override existing ${importData.itemsLabel} values`}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          overrideExisting: !formState.overrideExisting
                        })
                      }
                      styles={{
                        input: { cursor: 'pointer' },
                        label: { cursor: 'pointer', fontWeight: 500 },
                        description: { fontSize: 14 }
                      }}
                    />
                  )}
                </>
              ) : (
                <Alert
                  color={overrideRequired ? '#c40000' : '#eca70a'}
                  icon={<AlertCircle size={22} />}
                  title={`No ${importConfig.identifier.namePlural} found.`}
                  variant="outline"
                >
                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Did you know you can export your{' '}
                      {importData.itemsLabelPlural} to get a list of your{' '}
                      {importData.itemsLabel}{' '}
                      {importConfig.identifier.namePlural}? Then you can change
                      the columns and re-import the CSV to update.
                    </AppText>
                    {overrideRequired ? (
                      <AppText style={{ fontSize: 14 }}>
                        Please ensure the {importConfig.identifier.name} column
                        is correct and try again.
                      </AppText>
                    ) : (
                      <AppText style={{ fontSize: 14 }}>
                        Are you sure you want to continue importing?{' '}
                        <b>
                          You will create{' '}
                          {singularPluralFormat(
                            importData.items.length,
                            `new ${importData.itemsLabel}`,
                            `new ${importData.itemsLabelPlural}`
                          )}
                          .
                        </b>
                      </AppText>
                    )}
                  </AppStack>
                </Alert>
              )}
            </AppStack>
          )}
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

ImportCsvModal.propTypes = {
  descriptor: PropTypes.string,
  importConfig: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onImport: PropTypes.func,
  onImportCsvData: PropTypes.func,
  overrideRequired: PropTypes.bool
};

export default ImportCsvModal;
