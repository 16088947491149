import React from 'react';
import { Anchor, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const StoreOverviewInfoCard = ({
  loading,
  ecomStore,
  showExternalConnections
}) => {
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();

  return !loading && ecomStore ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>Store</AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox>
                <Anchor
                  component={Link}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  to={`/store/${ecomStore.urlHandle}`}
                >
                  {ecomStore.name}
                </Anchor>
              </AppFlexbox>
            </AppStack>
          </AppStack>

          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Contact information
              </AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 13 }}>{ecomStore.name}</AppText>
              {ecomStore.domain && (
                <AppFlexbox>
                  <Anchor
                    href={ecomStore.domain}
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                    target="_blank"
                  >
                    {ecomStore.domain}
                  </Anchor>
                </AppFlexbox>
              )}

              <AppFlexbox>
                <Anchor
                  href={`mailto:${ecomStore.supportEmail}`}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  target="_blank"
                >
                  {ecomStore.supportEmail}
                </Anchor>
              </AppFlexbox>
            </AppStack>
          </AppStack>

          {showExternalConnections && (
            <>
              <AppStack style={{ flex: 1, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Team
                  </AppText>
                </AppFlexbox>
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 13 }}>
                    {ecomStore.team?.name ?? 'N/A'}
                  </AppText>
                </AppStack>
              </AppStack>
              <AppStack style={{ flex: 1, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Association
                  </AppText>
                </AppFlexbox>
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 13 }}>
                    {ecomStore.team?.association?.name ??
                      ecomStore.association?.name ??
                      'N/A'}
                  </AppText>
                </AppStack>
              </AppStack>
            </>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton height={14} width="30%" />
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <Skeleton height={14} width="40%" />
            </AppStack>
          </AppStack>

          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton height={14} width="45%" />
            </AppFlexbox>
            <AppStack style={{ gap: 5 }}>
              <Skeleton height={14} width="40%" />
              <Skeleton height={14} width="40%" />
              <Skeleton height={14} width="40%" />
            </AppStack>
          </AppStack>

          {showExternalConnections && (
            <>
              <AppStack style={{ flex: 1, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width="35%" />
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={14} width="45%" />
                </AppStack>
              </AppStack>
              <AppStack style={{ flex: 1, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width="40%" />
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={14} width="60%" />
                </AppStack>
              </AppStack>
            </>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

StoreOverviewInfoCard.propTypes = {
  ecomStore: PropTypes.object,
  loading: PropTypes.bool,
  showExternalConnections: PropTypes.bool
};

export default StoreOverviewInfoCard;
