import React, { useContext, useState } from 'react';
import {
  Anchor,
  Button,
  Loader,
  NumberFormatter,
  Skeleton
} from '@mantine/core';
import PropTypes from 'prop-types';
import { useHover } from '@mantine/hooks';
import { Minus, Plus, Trash } from 'tabler-icons-react';
import { Link, useLocation } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import ProductImageDisplay from '../products/ProductImageDisplay';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { sterilizeUrlHandle } from '../../../helpers/format';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const VendorCatalogDisplayCard = ({
  ecomVendorProduct,
  fromLocation,
  fromResource,
  vendorPreview,
  baseUrl
}) => {
  const { search } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const { setVendorCatalogRemoveStoreProductModal } = useContext(HelperContext);
  const { state } = useContext(StoreContext);
  const { hovered, ref: hoverRef } = useHover();
  const [loading, setLoading] = useState(false);
  const {
    cart: catalogCart,
    addProducts,
    removeProducts
  } = useVendorCatalogCheckout(authState.pkEcomStore);
  const { isTabletOrSmaller } = useMediaQueryIndex();

  const productAlreadyInStore =
    ecomVendorProduct &&
    state.ecomStoreProducts.value.some(
      (p) => p.ecomVendorProduct.uuid === ecomVendorProduct.uuid
    );

  const isSelected =
    ecomVendorProduct &&
    catalogCart.ecomVendorProducts.some(
      (p) => p.uuid === ecomVendorProduct.uuid
    );

  const getUrlParams = () => {
    const searchParams = new URLSearchParams();
    if (search) {
      searchParams.set('query', encodeURIComponent(search));
    }
    if (fromLocation) {
      searchParams.set('from', fromLocation);
    }
    if (fromResource) {
      searchParams.set('fromResource', fromResource);
    }
    const params = searchParams.toString();
    return params ? `?${params}` : '';
  };

  return ecomVendorProduct ? (
    <AppCard
      ref={hoverRef}
      component={Link}
      radius="md"
      shadow="xs"
      style={{
        flex: 1,
        padding: 0,
        height: '100%',
        cursor: 'pointer',
        position: 'relative'
      }}
      to={`${baseUrl}/products/${sterilizeUrlHandle(
        ecomVendorProduct.vendorUrlHandle
      )}/${ecomVendorProduct.urlHandle}${getUrlParams()}`}
      withBorder
    >
      {(loading || isSelected) && (
        <AppStack
          style={{
            top: 0,
            left: 0,
            borderRadius: 8,
            width: '100%',
            height: '100%',
            position: 'absolute',
            border: isSelected ? 'solid 3px dodgerblue' : 'unset',
            backgroundColor: loading ? 'rgba(0, 0, 0, 0.6)' : 'unset',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            pointerEvents: 'none'
          }}
        >
          {loading && <Loader color="#FFF" size={40} />}
        </AppStack>
      )}

      <AppStack
        style={{
          flex: 1,
          borderRadius: 8
        }}
      >
        <AppStack style={{ flex: 1, padding: 16 }}>
          <ProductImageDisplay
            previewImages={ecomVendorProduct.previewImages}
            productAlreadyInStore={productAlreadyInStore}
          />
          <AppStack style={{ gap: 5, flex: 1 }}>
            <AppStack style={{ gap: 5, flex: 1, minHeight: 80 }}>
              <AppText style={{ fontWeight: 700 }}>
                {ecomVendorProduct.name}
              </AppText>
              <Anchor
                c="dodgerblue"
                component={Link}
                style={{ fontSize: 13 }}
                to={`${baseUrl}/vendors/${
                  ecomVendorProduct.vendorUrlHandle
                }${getUrlParams()}`}
              >
                {ecomVendorProduct.vendorName}
              </Anchor>
              {ecomVendorProduct.fkEcomCategory && (
                <AppText style={{ color: '#666', fontSize: 14 }}>
                  {ecomVendorProduct.fkEcomCategory
                    ? PRODUCT_CATEGORY_LIST.find(
                        (c) => c.value === ecomVendorProduct.fkEcomCategory
                      )?.name
                    : ''}
                </AppText>
              )}

              {ecomVendorProduct.productType && (
                <AppText style={{ color: '#666', fontSize: 14 }}>
                  {ecomVendorProduct.productType}
                </AppText>
              )}
            </AppStack>
            <AppText style={{ fontWeight: 700 }}>
              {ecomVendorProduct.minPrice === ecomVendorProduct.maxPrice ? (
                <NumberFormatter
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  suffix=" CAD"
                  thousandSeparator
                  value={ecomVendorProduct.maxPrice / 100}
                />
              ) : (
                <>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    suffix=" CAD"
                    thousandSeparator
                    value={ecomVendorProduct.minPrice / 100}
                  />{' '}
                  -{' '}
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    suffix=" CAD"
                    thousandSeparator
                    value={ecomVendorProduct.maxPrice / 100}
                  />
                </>
              )}
            </AppText>
          </AppStack>
        </AppStack>
        {(isTabletOrSmaller || !loading) && !vendorPreview && (
          <AppStack
            style={{
              gap: 16,
              padding: 16,
              width: '100%',
              backgroundColor: '#FFF',
              ...(!isTabletOrSmaller
                ? {
                    transition: 'bottom 0.3s ease, opacity 0.3s ease',
                    bottom: hovered ? 0 : -40,
                    opacity: hovered ? 1 : 0,
                    position: 'absolute'
                  }
                : {})
            }}
          >
            {productAlreadyInStore ? (
              <Button
                color="red"
                disabled={loading}
                leftSection={<Trash size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setVendorCatalogRemoveStoreProductModal({
                    isOpen: true,
                    ecomVendorProducts: [ecomVendorProduct]
                  });
                }}
                size="md"
                style={{ width: '100%', fontSize: 14 }}
                type="button"
                variant="filled"
              >
                Remove from store
              </Button>
            ) : isSelected ? (
              <Button
                color="red"
                disabled={loading}
                leftSection={<Minus size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  removeProducts([ecomVendorProduct.uuid]);
                }}
                size="md"
                style={{ width: '100%', fontSize: 14 }}
                type="button"
                variant="outline"
              >
                Unselect product
              </Button>
            ) : (
              <Button
                color="blue"
                disabled={loading}
                leftSection={<Plus size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setLoading(true);
                  addProducts(
                    [ecomVendorProduct.uuid],
                    () => {
                      setLoading(false);
                    },
                    () => {
                      setLoading(false);
                    }
                  );
                }}
                size="md"
                style={{ width: '100%', fontSize: 14 }}
                type="button"
                variant="filled"
              >
                Select product
              </Button>
            )}
          </AppStack>
        )}
      </AppStack>
    </AppCard>
  ) : (
    <AppCard
      radius="md"
      shadow="xs"
      style={{
        flex: 1,
        padding: 0,
        height: '100%',
        minHeight: 410,
        outline: isSelected ? 'solid 3px dodgerblue' : 'unset'
      }}
      withBorder
    >
      <Skeleton height="100%" width="100%" />
    </AppCard>
  );
};

VendorCatalogDisplayCard.propTypes = {
  baseUrl: PropTypes.string,
  ecomVendorProduct: PropTypes.object,
  fromLocation: PropTypes.string,
  fromResource: PropTypes.string,
  onRemoveProductFromStore: PropTypes.func,
  onSelect: PropTypes.func,
  vendorPreview: PropTypes.bool
};

export default VendorCatalogDisplayCard;
