/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@mantine/carousel';
import { Button, Grid } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import AppText from '../../../../common/AppText';
import {
  HEADING_SIZE_STYLE_ENUM,
  IMAGE_DISPLAY_ENUM,
  VIEW_ALL_STYLE_ENUM
} from './wsCollectionListConfig';
import HoverableAnchor from '../../../../common/HoverableAnchor';
import AppFlexbox from '../../../../common/AppFlexbox';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsBlock from '../../blocks/WsBlock';

const WsCollectionList = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  onNavigate,
  baseStoreUrl,
  ecomStoreTheme,
  ...rest
}) => {
  const { state } = useContext(StoreUserContext);
  const { isTabletOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const maxDisplayed = config.maximumProductsDisplayed * 1 ?? 20;
  const pageWidth =
    themeConfig.pageWidth && themeConfig.pageWidth * 1 >= 1000
      ? themeConfig.pageWidth * 1
      : 1000;

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={{
        ...config,
        fullWidth: 'true'
      }}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack
        style={{
          flex: 1,
          justifyContent: 'space-between',
          margin: 'auto',
          width: '100%',
          alignItems: 'center',
          gap: 16
        }}
      >
        <AppStack
          style={{
            width: '100%',
            maxWidth: config.fullWidth === 'true' ? 'unset' : pageWidth,
            gap: 5
          }}
        >
          {config.headingText && (
            <AppText
              style={{
                fontWeight: 600,
                fontSize:
                  config.headingSize === HEADING_SIZE_STYLE_ENUM.HUGE
                    ? 50
                    : config.headingSize === HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE
                    ? 44
                    : config.headingSize === HEADING_SIZE_STYLE_ENUM.LARGE
                    ? 36
                    : config.headingSize === HEADING_SIZE_STYLE_ENUM.MEDIUM
                    ? 28
                    : 22
              }}
            >
              {config.headingText}
            </AppText>
          )}
          {config.descriptionText && (
            <AppText style={{ fontSize: 16 }}>{config.descriptionText}</AppText>
          )}
        </AppStack>

        <AppStack
          style={{
            flex: 1,
            justifyContent: 'space-between',
            margin: 'auto',
            width: '100%',
            maxWidth: config.productsFullWidth === 'true' ? 'unset' : pageWidth,
            gap: 0
          }}
        >
          {(isTabletOrSmaller && config.mobileCarousel === 'true') ||
          (!isTabletOrSmaller && config.desktopCarousel === 'true') ? (
            <Carousel
              align={isTabletOrSmaller ? 'center' : 'start'}
              controlSize={40}
              draggable
              loop={false}
              slideGap={16}
              slideSize={`${
                (isTabletOrSmaller ? 85 : 100) /
                (isTabletOrSmaller
                  ? config.mobileColumnCount * 1 ?? 1
                  : config.desktopColumnCount * 1 ?? 6)
              }%`}
              slidesToScroll={
                isTabletOrSmaller
                  ? config.mobileColumnCount * 1 ?? 1
                  : config.desktopColumnCount * 1 ?? 6
              }
              withControls={!isTabletOrSmaller}
              withIndicators={false}
            >
              {blocks.slice(0, maxDisplayed).map((b) => (
                <Carousel.Slide key={b.key}>
                  <WsBlock
                    baseStoreUrl={baseStoreUrl}
                    colorScheme={colorScheme}
                    ecomStoreTheme={ecomStoreTheme}
                    mediaQueries={mediaQueries}
                    onNavigate={onNavigate}
                    portraitRatio={
                      config.imageRatio === IMAGE_DISPLAY_ENUM.PORTRAIT
                    }
                    themeConfig={themeConfig}
                    {...b}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          ) : (
            <AppStack style={{}}>
              <Grid
                columns={
                  isTabletOrSmaller
                    ? config.mobileColumnCount * 1 ?? 1
                    : config.desktopColumnCount * 1 ?? 6
                }
              >
                {blocks.slice(0, maxDisplayed).map((b) => (
                  <Grid.Col key={b.key} span={1}>
                    <WsBlock
                      baseStoreUrl={baseStoreUrl}
                      colorScheme={colorScheme}
                      ecomStoreTheme={ecomStoreTheme}
                      mediaQueries={mediaQueries}
                      onNavigate={onNavigate}
                      portraitRatio={
                        config.imageRatio === IMAGE_DISPLAY_ENUM.portraitRatio
                      }
                      themeConfig={themeConfig}
                      {...b}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </AppStack>
          )}

          {!state.ecomStoreCollections.loading &&
            config.enableViewAll === 'true' &&
            blocks.length > maxDisplayed && (
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20
                }}
              >
                {config.viewAllButtonStyle === VIEW_ALL_STYLE_ENUM.LINK ? (
                  <HoverableAnchor
                    component={Link}
                    onClick={onNavigate}
                    style={{ color: colorScheme.textColor }}
                    to={`${baseStoreUrl}/collections/`}
                  >
                    View all
                  </HoverableAnchor>
                ) : config.viewAllButtonStyle ===
                  VIEW_ALL_STYLE_ENUM.OUTLINE_BUTTON ? (
                  <Button
                    color={colorScheme.outlineButtonColor}
                    component={Link}
                    onClick={onNavigate}
                    radius={0}
                    size="md"
                    to={`${baseStoreUrl}/collections/`}
                    variant="outline"
                  >
                    View all
                  </Button>
                ) : (
                  <Button
                    color={colorScheme.solidButtonColor}
                    component={Link}
                    onClick={onNavigate}
                    radius={0}
                    size="md"
                    style={{ color: colorScheme.solidButtonLabel }}
                    to={`${baseStoreUrl}/collections/`}
                    variant="filled"
                  >
                    View all
                  </Button>
                )}
              </AppFlexbox>
            )}
        </AppStack>
      </AppStack>
    </WsSectionContainer>
  );
};

WsCollectionList.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsCollectionList;
