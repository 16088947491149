/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@mantine/core';

const WsAccordionBlock = ({ config, value }) => (
  <Accordion.Item value={value.toString()}>
    <Accordion.Control>{config.heading}</Accordion.Control>
    <Accordion.Panel>{config.content}</Accordion.Panel>
  </Accordion.Item>
);

WsAccordionBlock.propTypes = {
  config: PropTypes.object,
  value: PropTypes.number
};

export default WsAccordionBlock;
