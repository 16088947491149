/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Button, TextInput } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import WsAccountAuthSection from './WsAccountAuthSection';
import { PASSWORD_CONSTRAINTS } from '../../../../../config/constants';

const WsAccountRegister = ({
  baseStoreUrl,
  blocks,
  config,
  themeConfig,
  ecomStoreTheme,
  colorSchemes,
  mediaQueries,
  sort,
  onAuthNavigation,
  pathname,
  ...rest
}) => {
  const { state, createAccount } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: state.email ?? '',
    password: '',
    confirmPassword: '',
    loading: false,
    error: ''
  });
  const { isLargeMobileOrSmaller } = mediaQueries;

  useEffect(() => {
    setFormState({
      firstName: '',
      lastName: '',
      email: state.email ?? '',
      password: '',
      confirmPassword: '',
      loading: false,
      error: ''
    });
  }, []);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const onSubmit = () => {
    if (formState.password !== formState.confirmPassword) {
      setFormState({
        ...formState,
        error: 'Passwords must match.'
      });
    }
    else if (formState.confirmPassword.length < PASSWORD_CONSTRAINTS.length) {
      setFormState({
        ...formState,
        error: `Password must be at least ${PASSWORD_CONSTRAINTS.length} characters long`
      });
    }
    else {
      setFormState({
        ...formState,
        loading: true,
        error: ''
      });
      createAccount(
        {
          firstName: formState.firstName,
          lastName: formState.lastName,
          email: formState.email,
          password: formState.password
        },
        () => {},
        (error) => {
          setFormState({
            ...formState,
            loading: false,
            error
          });
        }
      );
    }
  };

  return (
    <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
      <WsAccountAuthSection
        baseStoreUrl={baseStoreUrl}
        ecomStoreTheme={ecomStoreTheme}
        error={formState.error}
        mediaQueries={mediaQueries}
        onNavigate={onAuthNavigation}
        onSubmit={onSubmit}
        title="Create account"
      >
        <AppStack
          style={{
            gap: 10,
            padding: isLargeMobileOrSmaller
              ? '10px 20px 20px 20px'
              : '10px 40px 40px 40px'
          }}
        >
          <TextInput
            disabled={formState.loading}
            label="Email"
            name="email"
            onChange={handleInputChange}
            required
            type="email"
            value={formState.email}
          />
          <AppFlexbox style={{ gap: 10, flex: 1 }}>
            <TextInput
              disabled={formState.loading}
              label="First name"
              name="firstName"
              onChange={handleInputChange}
              required
              style={{ flex: 1 }}
              value={formState.firstName}
            />
            <TextInput
              disabled={formState.loading}
              label="Last name"
              name="lastName"
              onChange={handleInputChange}
              required
              style={{ flex: 1 }}
              value={formState.lastName}
            />
          </AppFlexbox>

          <AppStack style={{ gap: 5 }}>
            <TextInput
              autoComplete="on"
              disabled={formState.loading}
              label="Password"
              name="password"
              onChange={handleInputChange}
              placeholder="At least 8 characters"
              required
              type="password"
              value={formState.password}
            />
            <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
              <InfoCircle color="dodgerblue" size={16} />
              <AppText
                style={{ fontSize: 11, color: '#2b2b2b', lineHeight: '12px' }}
              >
                Passwords must consist of at least 8 characters.
              </AppText>
            </AppFlexbox>
          </AppStack>

          <TextInput
            autoComplete="on"
            disabled={formState.loading}
            label="Confirm password"
            name="confirmPassword"
            onChange={handleInputChange}
            required
            type="password"
            value={formState.confirmPassword}
          />
          <Button
            color="dark"
            loading={formState.loading}
            radius={10}
            size="sm"
            style={{ fontSize: 13, margin: '10px 0px' }}
            type="submit"
          >
            Continue
          </Button>

          <AppText
            style={{
              fontSize: 13,
              color: '#666',
              textAlign: 'center'
            }}
          >
            By creating an account, you agree to SportsHeadz{' '}
            <Anchor
              href="https://www.sportsheadz.com/privacy-policy"
              style={{ fontSize: 13, color: 'dodgerblue' }}
              target="_blank"
            >
              Privacy Policy
            </Anchor>
            .
          </AppText>

          <AppText style={{ fontSize: 14, marginTop: 10 }}>
            Already have a SportsHeadz account?{' '}
            <Anchor
              onClick={() => onAuthNavigation('/account/login')}
              style={{ fontSize: 14, color: 'dodgerblue' }}
            >
              Sign in
            </Anchor>
          </AppText>
        </AppStack>
      </WsAccountAuthSection>
    </AppFlexbox>
  );
};

WsAccountRegister.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onAuthNavigation: PropTypes.func,
  pathname: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsAccountRegister;
