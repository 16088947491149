import { Tag } from 'tabler-icons-react';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import WsPriceBlock from './WsPriceBlock';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  title: 'Price',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.PRICE,
  icon: Tag,
  Component: WsPriceBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
