/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  ActionIcon,
  Anchor,
  Badge,
  Checkbox,
  Divider,
  Image,
  Loader,
  Menu,
  NumberFormatter,
  ScrollArea,
  Select,
  TextInput
} from '@mantine/core';
import {
  AdjustmentsHorizontal,
  Check,
  ChevronDown,
  Search,
  X
} from 'tabler-icons-react';
import { singularPluralFormat } from '../../../../../helpers/format';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import AppFlexbox from '../../../../common/AppFlexbox';
import { DESKTOP_FILTER_LAYOUT_ENUM } from './wsProductGridConfig';
import AppText from '../../../../common/AppText';
import AppMenu from '../../../../common/AppMenu';
import { useWebstoreSearchFilter } from '../../../../../helpers/webstoreHelper';
import AppStack from '../../../../common/AppStack';
import { generateColorVariants } from '../../../../../helpers/styles';
import WsProductFilterDrawer from './WsProductFilterDrawer';

const PRODUCT_SORT_OPTIONS = [
  {
    label: 'Featured',
    value: 'featured'
  },
  {
    label: 'Best selling',
    value: 'best_selling'
  },
  {
    label: 'Alphabetically, A-Z',
    value: 'name'
  },
  {
    label: 'Alphabetically, Z-A',
    value: 'name_desc'
  },
  {
    label: 'Price, low to high',
    value: 'price'
  },
  {
    label: 'Price, high to low',
    value: 'price_desc'
  },
  {
    label: 'Date, old to new',
    value: 'created'
  },
  {
    label: 'Date, new to old',
    value: 'created_desc'
  }
];

const WsProductGridFilter = ({
  config,
  colorSchemes,
  mediaQueries,
  pathname,
  onNavigate,
  baseStoreUrl,
  editModeEnabled,
  search,
  verticalLayout,
  ecomStoreTheme,
  loading
}) => {
  const [debouncedFilter, setDebouncedFilter] = useState(null);
  const [filterState, setFilterState] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const { filter, onFilterChange, resetFilter } = useWebstoreSearchFilter({
    editModeEnabled,
    onNavigate,
    pathname: `${baseStoreUrl}${pathname}`,
    search
  });
  const { state, fetchEcomStoreProductFilterOptions } = useContext(
    StoreUserContext
  );
  const { isLaptopOrSmaller } = mediaQueries;
  const filterEnabled = config.filterEnabled === 'true';
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const sortEnabled = config.sortEnabled === 'true';
  const textColorVariants = generateColorVariants(colorScheme.textColor, 100);

  const selectedVendorOptions = state.ecomStoreProductFilterOptions.value?.ecomVendors.find(
    (v) => v.pkEcomVendor.toString() === filter.vendor
  );

  useEffect(() => {
    if (debouncedFilter !== null) {
      onFilterChange(debouncedFilter);
    }
  }, [debouncedFilter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilter(filterState);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterState]);

  useEffect(() => {
    if (
      state.ecomStoreProductFilterOptions.filter?.pkEcomStore !==
      ecomStoreTheme.ecomStore.pkEcomStore
    ) {
      fetchEcomStoreProductFilterOptions(ecomStoreTheme.ecomStore.pkEcomStore);
    }
  }, []);

  return !isLaptopOrSmaller
    ? (sortEnabled || filterEnabled) &&
        (verticalLayout ? (
          <AppStack
            style={{
              flex: 1,
              width: '100%',
              maxWidth: 300,
              gap: 0
            }}
          >
            <AppFlexbox
              style={{
                paddingBottom: 8,
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, color: colorScheme.textColor }}>
                Filter:
              </AppText>

              {(filter.includeInventory ||
                filter.includeNoInventory ||
                filter.minPrice ||
                filter.maxPrice) && (
                <Anchor
                  onClick={resetFilter}
                  style={{ fontSize: 14, marginLeft: 'auto' }}
                  underline="always"
                >
                  Remove all
                </Anchor>
              )}
            </AppFlexbox>
            {(filter.includeInventory ||
              filter.includeNoInventory ||
              filter.minPrice ||
              filter.maxPrice) && (
              <AppStack style={{ gap: 8, paddingBottom: 8 }}>
                {filter.includeInventory && (
                  <Badge
                    rightSection={
                      <ActionIcon
                        onClick={() => {
                          onFilterChange({ includeInventory: null });
                        }}
                        size={16}
                        variant="subtle"
                      >
                        <X size={16} />
                      </ActionIcon>
                    }
                    variant="outline"
                  >
                    Availability: In stock
                  </Badge>
                )}

                {filter.includeNoInventory && (
                  <Badge
                    rightSection={
                      <ActionIcon
                        onClick={() => {
                          onFilterChange({ includeNoInventory: null });
                        }}
                        size={16}
                        variant="subtle"
                      >
                        <X size={16} />
                      </ActionIcon>
                    }
                    variant="outline"
                  >
                    Availability: Out of stock
                  </Badge>
                )}

                {(filter.minPrice || filter.maxPrice) && (
                  <Badge
                    rightSection={
                      <ActionIcon
                        onClick={() => {
                          onFilterChange({
                            minPrice: null,
                            maxPrice: null
                          });
                        }}
                        size={16}
                        variant="subtle"
                      >
                        <X size={16} />
                      </ActionIcon>
                    }
                    variant="outline"
                  >
                    {!filter.maxPrice ? (
                      <>
                        From:{' '}
                        <NumberFormatter
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={filter.minPrice}
                        />
                      </>
                    ) : (
                      <>
                        <NumberFormatter
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={filter.minPrice ?? 0}
                        />{' '}
                        -{' '}
                        <NumberFormatter
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={filter.maxPrice}
                        />
                      </>
                    )}
                  </Badge>
                )}
              </AppStack>
            )}

            <Divider />

            {filterEnabled && (
              <>
                <TextInput
                  label="Search"
                  onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      search: !e.currentTarget.value
                        ? null
                        : e.currentTarget.value
                    });
                  }}
                  placeholder="Search by name..."
                  radius={0}
                  rightSection={
                    filterState?.search || filter.search ? (
                      <X
                        onClick={() => {
                          setFilterState({
                            ...filterState,
                            search: ''
                          });
                        }}
                        size={16}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <Search size={16} />
                    )
                  }
                  style={{ padding: '8px 0px', color: colorScheme.textColor }}
                  styles={{ label: { fontWeight: 'normal' } }}
                  value={filterState?.search ?? filter.search ?? ''}
                />

                <AppMenu
                  control={
                    <Select
                      label="Vendor"
                      placeholder="Filter by vendor"
                      rightSection={
                        selectedVendorOptions ? (
                          <X
                            onClick={() => {
                              onFilterChange({ vendor: null });
                            }}
                            size={16}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <ChevronDown size={16} />
                        )
                      }
                      rightSectionPointerEvents={
                        selectedVendorOptions ? 'all' : 'none'
                      }
                      searchValue={selectedVendorOptions?.name}
                    />
                  }
                  position="bottom-start"
                  styles={{ dropdown: { padding: 0 } }}
                  width={300}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppFlexbox
                      style={{
                        gap: 8,
                        padding: '8px 12px',
                        alignItems: 'center'
                      }}
                    >
                      <AppText style={{ fontSize: 14, color: '#999' }}>
                        Vendor
                      </AppText>
                    </AppFlexbox>
                    <Menu.Divider style={{ margin: 0 }} />
                    <ScrollArea.Autosize mah={350} scrollbarSize={5}>
                      <AppStack style={{ gap: 3, padding: 3 }}>
                        {state.ecomStoreProductFilterOptions.value?.ecomVendors.map(
                          (v) => (
                            <Menu.Item
                              key={v.pkEcomVendor}
                              onClick={() => {
                                onFilterChange({ vendor: v.pkEcomVendor });
                              }}
                            >
                              <AppFlexbox
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <AppFlexbox style={{ alignItems: 'center' }}>
                                  <AppStack style={{ width: 25, height: 25 }}>
                                    <Image
                                      alt={v.name}
                                      h="100%"
                                      src={v.image}
                                      w="100%"
                                    />
                                  </AppStack>
                                  <AppText style={{ fontSize: 14 }}>
                                    {v.name}
                                  </AppText>
                                </AppFlexbox>
                                {v.pkEcomVendor.toString() ===
                                  filter.vendor && (
                                  <Check color="#666" size={18} />
                                )}
                              </AppFlexbox>
                            </Menu.Item>
                          )
                        )}
                      </AppStack>
                    </ScrollArea.Autosize>
                  </AppStack>
                </AppMenu>

                <Accordion
                  defaultValue={['availability']}
                  multiple
                  styles={{
                    item: { padding: 0 },
                    control: { padding: 0 },
                    content: { padding: 0 },
                    chevron: { color: colorScheme.textColor }
                  }}
                >
                  <Accordion.Item value="availability">
                    <Accordion.Control>
                      <AppText
                        style={{ fontSize: 14, color: colorScheme.textColor }}
                      >
                        Availability
                      </AppText>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <AppStack style={{ gap: 0, paddingBottom: 8 }}>
                        <AppFlexbox
                          onClick={() =>
                            onFilterChange({
                              includeInventory: !filter.includeInventory
                            })
                          }
                          style={{ padding: '12px 0px', cursor: 'pointer' }}
                        >
                          <Checkbox
                            checked={filter.includeInventory}
                            label="In stock"
                            onChange={() => {}}
                            radius={0}
                            style={{ pointerEvents: 'none' }}
                            styles={{
                              label: { cursor: 'pointer' },
                              input: { cursor: 'pointer' }
                            }}
                          />
                        </AppFlexbox>
                        <AppFlexbox
                          onClick={() =>
                            onFilterChange({
                              includeNoInventory: !filter.includeNoInventory
                            })
                          }
                          style={{ padding: '12px 0px', cursor: 'pointer' }}
                        >
                          <Checkbox
                            checked={filter.includeNoInventory}
                            label="Out of stock"
                            onChange={() => {}}
                            radius={0}
                            style={{ pointerEvents: 'none' }}
                            styles={{
                              label: { cursor: 'pointer' },
                              input: { cursor: 'pointer' }
                            }}
                          />
                        </AppFlexbox>
                      </AppStack>
                    </Accordion.Panel>
                  </Accordion.Item>
                  {/* <Accordion.Item value="price">
            <Accordion.Control>
              <AppText style={{ fontSize: 14, color: colorScheme.textColor }}>Pricing</AppText>
            </Accordion.Control>
            <Accordion.Panel>
              <AppFlexbox style={{ gap: 8, paddingBottom: 8 }}>
                <CustomNumericFormat
                  label="Minimum"
                  onValueChange={(values) => {
                    setFilterState({
                      ...filterState,
                      minPrice: values.floatValue > 0 ? values.floatValue : null
                    });
                  }}
                  placeholder="From"
                  radius={0}
                  style={{ flex: 1 }}
                  value={filterState?.minPrice ?? ''}
                />
                <CustomNumericFormat
                  label="Maximum"
                  onValueChange={(values) => {
                    setFilterState({
                      ...filterState,
                      maxPrice:
                        values.floatValue < 1000 ? values.floatValue : null
                    });
                  }}
                  placeholder="To"
                  radius={0}
                  style={{ flex: 1 }}
                  value={filterState?.maxPrice ?? ''}
                />
              </AppFlexbox>
            </Accordion.Panel>
          </Accordion.Item> */}
                </Accordion>
              </>
            )}
          </AppStack>
        ) : (
          <AppStack style={{ gap: 16 }}>
            <AppFlexbox
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {config.filterLayout !== DESKTOP_FILTER_LAYOUT_ENUM.VERTICAL && (
                <AppFlexbox style={{ alignItems: 'center' }}>
                  {filterEnabled && (
                    <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                      <AppText
                        style={{ fontSize: 16, color: colorScheme.textColor }}
                      >
                        Filter:
                      </AppText>
                      <AppMenu
                        control={
                          <Select
                            rightSection={<ChevronDown size={16} />}
                            searchValue="Availability"
                            size="xs"
                            variant="filled"
                          />
                        }
                        position="bottom-start"
                        styles={{ dropdown: { padding: 0 } }}
                        width={300}
                      >
                        <AppStack style={{ gap: 0 }}>
                          <AppFlexbox
                            style={{
                              gap: 8,
                              padding: '8px 12px',
                              alignItems: 'center'
                            }}
                          >
                            <AppText style={{ fontSize: 14, color: '#999' }}>
                              Availability
                            </AppText>
                          </AppFlexbox>
                          <Menu.Divider />
                          <AppStack style={{ gap: 0 }}>
                            <AppFlexbox
                              onClick={() =>
                                onFilterChange({
                                  includeInventory: !filter.includeInventory
                                })
                              }
                              style={{
                                padding: '12px 12px',
                                cursor: 'pointer'
                              }}
                            >
                              <Checkbox
                                checked={filter.includeInventory}
                                label="In stock"
                                onChange={() => {}}
                                radius={0}
                                style={{ pointerEvents: 'none' }}
                                styles={{
                                  label: { cursor: 'pointer' },
                                  input: { cursor: 'pointer' }
                                }}
                              />
                            </AppFlexbox>
                            <AppFlexbox
                              onClick={() =>
                                onFilterChange({
                                  includeNoInventory: !filter.includeNoInventory
                                })
                              }
                              style={{
                                padding: '12px 12px',
                                cursor: 'pointer'
                              }}
                            >
                              <Checkbox
                                checked={filter.includeNoInventory}
                                label="Out of stock"
                                onChange={() => {}}
                                radius={0}
                                style={{ pointerEvents: 'none' }}
                                styles={{
                                  label: { cursor: 'pointer' },
                                  input: { cursor: 'pointer' }
                                }}
                              />
                            </AppFlexbox>
                          </AppStack>
                        </AppStack>
                      </AppMenu>
                      {/* <AppMenu
                  control={
                    <Select
                      rightSection={<ChevronDown size={16} />}
                      searchValue={
                        filter.minPrice || filter.maxPrice
                          ? `${
                              filter.minPrice
                                ? `From ${currencyFormat(filter.minPrice)} `
                                : ''
                            }${
                              filter.maxPrice
                                ? `To ${currencyFormat(filter.maxPrice)}`
                                : ''
                            }`
                          : 'Price'
                      }
                      size="xs"
                      variant="filled"
                    />
                  }
                  position="bottom-start"
                  styles={{ dropdown: { padding: 0 } }}
                  width={400}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppFlexbox
                      style={{
                        gap: 8,
                        padding: '8px 12px',
                        alignItems: 'center'
                      }}
                    >
                      <AppText style={{ fontSize: 14, color: '#999' }}>
                        Price range
                      </AppText>
                      {filter.minPrice || filter.maxPrice ? (
                        <Anchor
                          onClick={() => {
                            onFilterChange({
                              minPrice: null,
                              maxPrice: null
                            });
                          }}
                          style={{ marginLeft: 'auto', fontSize: 14 }}
                          underline="always"
                        >
                          Reset
                        </Anchor>
                      ) : null}
                    </AppFlexbox>
                    <Menu.Divider />
                    <AppFlexbox style={{ gap: 8, padding: '8px 12px' }}>
                      <CustomNumericFormat
                        label="Minimum"
                        onValueChange={(values) => {
                          setFilterState({
                            ...filterState,
                            minPrice:
                              values.floatValue > 0 ? values.floatValue : null
                          });
                        }}
                        placeholder="From"
                        radius={0}
                        style={{ flex: 1 }}
                        value={
                          filterState?.minPrice ?? filterState?.filter ?? ''
                        }
                      />
                      <CustomNumericFormat
                        label="Maximum"
                        onValueChange={(values) => {
                          setFilterState({
                            ...filterState,
                            maxPrice:
                              values.floatValue < 1000
                                ? values.floatValue
                                : null
                          });
                        }}
                        placeholder="To"
                        radius={0}
                        style={{ flex: 1 }}
                        value={filterState?.maxPrice ?? filter?.maxPrice ?? ''}
                      />
                    </AppFlexbox>
                  </AppStack>
                </AppMenu> */}
                      <AppMenu
                        control={
                          <Select
                            rightSection={
                              selectedVendorOptions ? (
                                <X
                                  onClick={() => {
                                    onFilterChange({ vendor: null });
                                  }}
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <ChevronDown size={16} />
                              )
                            }
                            rightSectionPointerEvents={
                              selectedVendorOptions ? 'all' : 'none'
                            }
                            searchValue={
                              selectedVendorOptions?.name ?? 'Vendor'
                            }
                            size="xs"
                            variant="filled"
                          />
                        }
                        position="bottom-start"
                        styles={{ dropdown: { padding: 0 } }}
                        width={300}
                      >
                        <AppStack style={{ gap: 0 }}>
                          <AppFlexbox
                            style={{
                              gap: 8,
                              padding: '8px 12px',
                              alignItems: 'center'
                            }}
                          >
                            <AppText style={{ fontSize: 14, color: '#999' }}>
                              Vendor
                            </AppText>
                          </AppFlexbox>
                          <Menu.Divider style={{ margin: 0 }} />
                          <ScrollArea.Autosize mah={350} scrollbarSize={5}>
                            <AppStack style={{ gap: 3, padding: 3 }}>
                              {state.ecomStoreProductFilterOptions.value?.ecomVendors.map(
                                (v) => (
                                  <Menu.Item
                                    key={v.pkEcomVendor}
                                    onClick={() => {
                                      onFilterChange({
                                        vendor: v.pkEcomVendor
                                      });
                                    }}
                                  >
                                    <AppFlexbox
                                      style={{
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                      }}
                                    >
                                      <AppFlexbox
                                        style={{ alignItems: 'center' }}
                                      >
                                        <AppStack
                                          style={{ width: 25, height: 25 }}
                                        >
                                          <Image
                                            alt={v.name}
                                            h="100%"
                                            src={v.image}
                                            w="100%"
                                          />
                                        </AppStack>
                                        <AppText style={{ fontSize: 14 }}>
                                          {v.name}
                                        </AppText>
                                      </AppFlexbox>
                                      {v.pkEcomVendor.toString() ===
                                        filter.vendor && (
                                        <Check color="#666" size={18} />
                                      )}
                                    </AppFlexbox>
                                  </Menu.Item>
                                )
                              )}
                            </AppStack>
                          </ScrollArea.Autosize>
                        </AppStack>
                      </AppMenu>
                      {filterEnabled && (
                        <TextInput
                          onChange={(e) => {
                            setFilterState({
                              ...filterState,
                              search: e.currentTarget.value
                            });
                          }}
                          placeholder="Search by name..."
                          rightSection={
                            filterState?.search || filter.search ? (
                              <X
                                onClick={() => {
                                  setFilterState({
                                    ...filterState,
                                    search: ''
                                  });
                                }}
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <Search size={16} />
                            )
                          }
                          size="xs"
                          value={filterState?.search ?? filter.search ?? ''}
                          variant="filled"
                        />
                      )}
                    </AppFlexbox>
                  )}
                </AppFlexbox>
              )}

              <AppFlexbox style={{ alignItems: 'center', marginLeft: 'auto' }}>
                {sortEnabled && (
                  <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                    <AppText
                      style={{ fontSize: 16, color: colorScheme.textColor }}
                    >
                      Sort by:
                    </AppText>
                    <AppMenu
                      control={
                        <Select
                          rightSection={<ChevronDown size={16} />}
                          searchValue={
                            PRODUCT_SORT_OPTIONS.find(
                              (o) => o.value === filter.sort
                            )?.label ?? PRODUCT_SORT_OPTIONS[0].label
                          }
                          size="xs"
                          variant="filled"
                        />
                      }
                      position="bottom-start"
                      width="target"
                    >
                      {PRODUCT_SORT_OPTIONS.map((option) => (
                        <Menu.Item
                          key={option.value}
                          onClick={() => onFilterChange({ sort: option.value })}
                          value={option.value}
                        >
                          {option.label}
                        </Menu.Item>
                      ))}
                    </AppMenu>
                  </AppFlexbox>
                )}
                <AppFlexbox style={{ minWidth: 85, justifyContent: 'end' }}>
                  {!loading ? (
                    <AppText
                      style={{ fontSize: 16, color: textColorVariants.lighter }}
                    >
                      {singularPluralFormat(
                        state.paginatedEcomStoreProducts.totalCount,
                        'product',
                        'products'
                      )}
                    </AppText>
                  ) : (
                    <Loader
                      color={colorScheme.textColor}
                      size={18}
                      style={{ margin: 'auto' }}
                      type="dots"
                    />
                  )}
                </AppFlexbox>
              </AppFlexbox>
            </AppFlexbox>

            {!config.filterLayout === DESKTOP_FILTER_LAYOUT_ENUM.VERTICAL &&
              (filter.includeInventory ||
                filter.includeNoInventory ||
                filter.minPrice ||
                filter.maxPrice) && (
                <AppFlexbox style={{ gap: 8, alignItems: 'end' }}>
                  {filter.includeInventory && (
                    <Badge
                      rightSection={
                        <ActionIcon
                          onClick={() => {
                            onFilterChange({ includeInventory: null });
                          }}
                          size={16}
                          variant="subtle"
                        >
                          <X size={16} />
                        </ActionIcon>
                      }
                      variant="outline"
                    >
                      Availability: In stock
                    </Badge>
                  )}

                  {filter.includeNoInventory && (
                    <Badge
                      rightSection={
                        <ActionIcon
                          onClick={() => {
                            onFilterChange({ includeNoInventory: null });
                          }}
                          size={16}
                          variant="subtle"
                        >
                          <X size={16} />
                        </ActionIcon>
                      }
                      variant="outline"
                    >
                      Availability: Out of stock
                    </Badge>
                  )}

                  {(filter.minPrice || filter.maxPrice) && (
                    <Badge
                      rightSection={
                        <ActionIcon
                          onClick={() => {
                            onFilterChange({
                              minPrice: null,
                              maxPrice: null
                            });
                          }}
                          size={16}
                          variant="subtle"
                        >
                          <X size={16} />
                        </ActionIcon>
                      }
                      variant="outline"
                    >
                      {!filter.maxPrice ? (
                        <>
                          From:{' '}
                          <NumberFormatter
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={filter.minPrice}
                          />
                        </>
                      ) : (
                        <>
                          <NumberFormatter
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={filter.minPrice ?? 0}
                          />{' '}
                          -{' '}
                          <NumberFormatter
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={filter.maxPrice}
                          />
                        </>
                      )}
                    </Badge>
                  )}

                  <Anchor
                    onClick={resetFilter}
                    style={{ fontSize: 14 }}
                    underline="always"
                  >
                    Remove all
                  </Anchor>
                </AppFlexbox>
              )}
          </AppStack>
        ))
    : (filterEnabled || sortEnabled) && (
        <AppFlexbox
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <AppFlexbox
            onClick={() => setShowDrawer(true)}
            style={{ gap: 5, alignItems: 'center' }}
          >
            <AdjustmentsHorizontal color={colorScheme.textColor} size={18} />
            <Anchor style={{ color: colorScheme.textColor }}>
              Sort and filter
            </Anchor>
          </AppFlexbox>
          <AppFlexbox style={{ minWidth: 50, justifyContent: 'end' }}>
            {!loading ? (
              <AppText
                style={{ fontSize: 16, color: textColorVariants.lighter }}
              >
                {singularPluralFormat(
                  state.paginatedEcomStoreProducts.totalCount,
                  'product',
                  'products'
                )}
              </AppText>
            ) : (
              <Loader
                color={colorScheme.textColor}
                size={18}
                style={{ margin: 'auto' }}
                type="dots"
              />
            )}
          </AppFlexbox>

          <WsProductFilterDrawer
            colorScheme={colorScheme}
            editModeEnabled={editModeEnabled}
            filter={filter}
            filterEnabled={filterEnabled}
            filterState={filterState}
            isOpen={showDrawer}
            mediaQueries={mediaQueries}
            onClose={() => setShowDrawer(false)}
            onFilterChange={onFilterChange}
            setFilterState={setFilterState}
            sortEnabled={sortEnabled}
            sortOptions={PRODUCT_SORT_OPTIONS}
          />
        </AppFlexbox>
      );
};

WsProductGridFilter.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string,
  verticalLayout: PropTypes.bool
};

export default WsProductGridFilter;
