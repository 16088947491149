import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsProductGrid from './WsProductGrid';

const QUICK_ADD_STYLE_ENUM = {
  NONE: 'NONE',
  STANDARD: 'STANDARD',
  BULK: 'BULK'
};

const DESKTOP_FILTER_LAYOUT_ENUM = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  DRAWER: 'DRAWER'
};

const IMAGE_DISPLAY_ENUM = {
  PORTRAIT: 'PORTRAIT',
  SQUARE: 'SQUARE'
};

const configNameValues = {
  productsPerPage: 'productsPerPage',
  desktopColumnCount: 'desktopColumnCount',
  colorScheme: 'colorScheme',
  imageRatio: 'imageRatio',
  showSecondImageOnHover: 'showSecondImageOnHover',
  showVendorName: 'showVendorName',
  quickAddStyle: 'quickAddStyle',
  filterEnabled: 'filterEnabled',
  filterLayout: 'filterLayout',
  sortEnabled: 'sortEnabled',
  mobileColumnCount: 'mobileColumnCount'
};

const configDefaultValues = {
  [configNameValues.productsPerPage]: '20',
  [configNameValues.desktopColumnCount]: '4',
  [configNameValues.colorScheme]: '1',
  [configNameValues.imageRatio]: IMAGE_DISPLAY_ENUM.SQUARE,
  [configNameValues.showSecondImageOnHover]: 'true',
  [configNameValues.showVendorName]: 'true',
  [configNameValues.quickAddStyle]: QUICK_ADD_STYLE_ENUM.STANDARD,
  [configNameValues.mobileColumnCount]: '2',
  [configNameValues.filterEnabled]: 'true',
  [configNameValues.filterLayout]: DESKTOP_FILTER_LAYOUT_ENUM.HORIZONTAL,
  [configNameValues.sortEnabled]: 'true',
  [configNameValues.mobileColumnCount]: '2'
};

const configOptions = {
  [configNameValues.productsPerPage]: {
    label: 'Products per page',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 8,
    max: 36,
    unit: ''
  },
  [configNameValues.desktopColumnCount]: {
    label: 'Desktop column count',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 1,
    max: 6,
    unit: ''
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },

  [configNameValues.imageRatio]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Image ratio',
    category: 'Product display',
    options: [
      {
        value: IMAGE_DISPLAY_ENUM.PORTRAIT,
        label: 'Portrait'
      },
      {
        value: IMAGE_DISPLAY_ENUM.SQUARE,
        label: 'Square'
      }
    ]
  },
  [configNameValues.showSecondImageOnHover]: {
    label: 'Show second image on hover',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Product display'
  },
  [configNameValues.showVendorName]: {
    label: 'Show vendor name',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Product display'
  },
  [configNameValues.quickAddStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Quick add',
    category: 'Product display',
    options: [
      {
        value: QUICK_ADD_STYLE_ENUM.NONE,
        label: 'None'
      },
      {
        value: QUICK_ADD_STYLE_ENUM.STANDARD,
        label: 'Standard'
      }
    ]
  },
  [configNameValues.filterEnabled]: {
    label: 'Enable filtering',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Filtering and sorting'
  },
  [configNameValues.filterLayout]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop filter layout',
    category: 'Filtering and sorting',
    isVisible: (config) => config.filterEnabled === 'true',
    options: [
      {
        value: DESKTOP_FILTER_LAYOUT_ENUM.HORIZONTAL,
        label: 'Horizontal'
      },
      {
        value: DESKTOP_FILTER_LAYOUT_ENUM.VERTICAL,
        label: 'Vertical'
      }
      // {
      //   value: DESKTOP_FILTER_LAYOUT_ENUM.DRAWER,
      //   label: 'Drawer'
      // }
    ]
  },
  [configNameValues.sortEnabled]: {
    label: 'Enable sorting',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Filtering and sorting'
  },
  [configNameValues.mobileColumnCount]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Mobile column count',
    category: 'Mobile layout',
    options: [
      {
        value: '1',
        label: '1 column'
      },
      {
        value: '2',
        label: '2 columns'
      }
    ]
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.PRODUCT_GRID,
  title: 'Product grid',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsProductGrid,
  configOptions,
  isPermanent: true,
  cannotCreate: true,
  maxItemCount: null,
  blocks: [],
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  IMAGE_DISPLAY_ENUM,
  DESKTOP_FILTER_LAYOUT_ENUM,
  QUICK_ADD_STYLE_ENUM
};
