import React, { useContext } from 'react';
import HeaderView from '../../common/HeaderView';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import { storeAnalyticsAndReportsConfig } from '../analytics/storeAnalyticsAndReportsConfig';
import AnalyticsOverviewView from '../analytics/AnalyticsOverviewView';

const StoreAnalyticsView = () => {
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreAnalytics } = useContext(StoreContext);

  const onFetchAnalyticsOverview = (requestData) => {
    fetchStoreAnalytics(authState.pkEcomStore, requestData, null, (error) =>
      triggerNotification(error)
    );
  };

  return (
    <HeaderView noHeader>
      <AnalyticsOverviewView
        analyticConfigs={storeAnalyticsAndReportsConfig.analytics}
        dataState={state.ecomStoreAnalytics.value}
        entityId={authState.pkEcomStore}
        loading={state.ecomStoreAnalytics.loading}
        onFetchOverview={onFetchAnalyticsOverview}
        urlBase="/merchant/reports"
      />
    </HeaderView>
  );
};

export default StoreAnalyticsView;
