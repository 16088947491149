import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import WebstoreBuilderView from './WebstoreBuilderView';

const WebstoreThemeView = () => {
  const { ecomStoreThemeUuid } = useParams();
  const { fetchStoreTheme } = useContext(StoreContext);

  useEffect(() => {
    if (ecomStoreThemeUuid) {
      fetchStoreTheme(ecomStoreThemeUuid);
    }
  }, [ecomStoreThemeUuid]);

  return (
    <Routes>
      <Route element={<WebstoreBuilderView />} path="editor/*" />
      <Route
        element={
          <Navigate
            replace
            to={`/merchant/webstore/themes/${ecomStoreThemeUuid}/editor`}
          />
        }
        path="*"
      />
    </Routes>
  );
};

export default WebstoreThemeView;
