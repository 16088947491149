import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Anchor, Button, TextInput } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import AuthCardView from './AuthCardView';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import { PASSWORD_CONSTRAINTS } from '../../../config/constants';
import AppCard from '../../common/AppCard';

const ResetPasswordView = ({ onNavigate }) => {
  const { state, resetPassword, signIn } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    code: '',
    newPassword: '',
    confirmPassword: '',
    error: ''
  });

  useEffect(() => {
    if (state.email) {
      setFormState({
        code: '',
        newPassword: '',
        confirmPassword: '',
        error: ''
      });
    }
    else {
      onNavigate('/auth/forgot-password');
    }
  }, []);

  return (
    <AuthCardView
      error={formState.error}
      onSubmit={() => {
        if (formState.newPassword !== formState.confirmPassword) {
          setFormState({
            ...formState,
            error: 'Passwords must match.'
          });
        }
        else if (
          formState.confirmPassword.length < PASSWORD_CONSTRAINTS.length
        ) {
          setFormState({
            ...formState,
            error: `Password must be at least ${PASSWORD_CONSTRAINTS.length} characters long`
          });
        }
        else {
          setFormState({
            ...formState,
            loading: true
          });
          resetPassword(
            {
              email: state.email,
              code: formState.code,
              newPassword: formState.newPassword
            },
            () => {
              triggerNotification(
                'Password was successfully reset!',
                'success'
              );
              signIn(
                { email: state.email, password: formState.newPassword },
                null,
                () => onNavigate('/auth/signin')
              );
            },
            (error) =>
              setFormState({
                ...formState,
                loading: false,
                error
              })
          );
        }
      }}
      title="Create new password"
    >
      <AppStack
        p={{ base: 20, xsm: 40 }}
        style={{ gap: 10, flex: 1, paddingTop: 10 }}
      >
        <AppStack style={{ gap: 0 }}>
          <AppText style={{ fontSize: 14 }}>
            We've sent a One Time Password (OTP) to the following email;
          </AppText>
        </AppStack>
        <AppCard
          radius={8}
          shadow="none"
          style={{ padding: 16, backgroundColor: 'rgba(245, 245, 245, 1)' }}
          withBorder
        >
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 14 }}>{state.email}</AppText>
            <Anchor
              onClick={() => onNavigate('/auth/forgot-password')}
              style={{ fontSize: 14, color: 'dodgerblue' }}
            >
              Change
            </Anchor>
          </AppFlexbox>
        </AppCard>

        <TextInput
          disabled={formState.loading}
          label="Enter OTP"
          onChange={(e) =>
            setFormState({
              ...formState,
              code: e.target.value
            })
          }
          required
          value={formState.code}
        />

        <AppStack style={{ gap: 5 }}>
          <TextInput
            disabled={formState.loading}
            label="New Password"
            onChange={(e) =>
              setFormState({
                ...formState,
                newPassword: e.target.value
              })
            }
            required
            type="password"
            value={formState.newPassword}
          />
          <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
            <InfoCircle color="dodgerblue" size={16} />
            <AppText
              style={{ fontSize: 11, color: '#2b2b2b', lineHeight: '12px' }}
            >
              Passwords must consist of at least 8 characters.
            </AppText>
          </AppFlexbox>
        </AppStack>

        <TextInput
          disabled={formState.loading}
          label="Confirm Password"
          onChange={(e) =>
            setFormState({
              ...formState,
              confirmPassword: e.target.value
            })
          }
          required
          type="password"
          value={formState.confirmPassword}
        />
        <Button
          color="dark"
          loading={formState.loading}
          radius={10}
          size="sm"
          style={{ fontSize: 13, margin: '5px 0px' }}
          type="submit"
        >
          Save changes and sign in
        </Button>
        <AppText style={{ fontSize: 14, textAlign: 'center', color: '#666' }}>
          We'll ask for this password whenever you sign in.
        </AppText>
        <AppText style={{ fontSize: 14, marginTop: 10 }}>
          Remember your password?{' '}
          <Anchor
            onClick={() => onNavigate('/auth/signin')}
            style={{ fontSize: 14, color: 'dodgerblue' }}
          >
            Sign in
          </Anchor>
        </AppText>
      </AppStack>
    </AuthCardView>
  );
};

ResetPasswordView.propTypes = { onNavigate: PropTypes.func };

export default ResetPasswordView;
