/* eslint-disable import/no-cycle */
import { config as wsHeaderConfig } from './header/wsHeaderConfig';
import { config as wsAnnouncementBarConfig } from './announcementBar/wsAnnouncementBarConfig';
import { config as wsSlideshowConfig } from './slideshow/wsSlideshowConfig';
import { config as wsFeaturedProductConfig } from './featuredProduct/wsFeaturedProductConfig';
import { config as wsImageBannerConfig } from './imageBanner/wsImageBannerConfig';
import { config as wsLayeredBannerConfig } from './layeredBanner/wsLayeredBannerConfig';
import { config as wsCollectionListConfig } from './collectionList/wsCollectionListConfig';
import { config as wsCollageConfig } from './collage/wsCollageConfig';
import { config as wsFeaturedCollectionConfig } from './featuredCollection/wsFeaturedCollectionConfig';
import { config as wsMultiColumnConfig } from './multiColumn/wsMultiColumnConfig';
import { config as wsRichTextConfig } from './richText/wsRichTextConfig';
import { config as wsMultiRowConfig } from './multiRow/wsMultiRowConfig';
import { config as wsEmailSignupConfig } from './emailSignup/wsEmailSignupConfig';
import { config as wsCollapsibleContentConfig } from './collapsibleContent/wsCollapsibleContentConfig';
import { config as wsContactFormConfig } from './contactForm/wsContactFormConfig';
import { config as wsVideoConfig } from './video/wsVideoConfig';
import { config as wsBlogPostsConfig } from './blogPosts/wsBlogPostsConfig';
import { config as wsPageConfig } from './page/wsPageConfig';
import { config as wsFooterConfig } from './footer/wsFooterConfig';
import { config as wsAccountContentConfig } from './accountContent/wsAccountContentConfig';
import { config as wsImageWithTextConfig } from './imageWithText/wsImageWithTextConfig';
import { config as wsProductInformationConfig } from './productInformation/wsProductInformationConfig';
import { config as wsCheckoutContentConfig } from './checkoutContent/wsCheckoutContentConfig';
import { config as wsBannerConfig } from './resourceBanner/wsResourceBannerConfig';
import { config as wsProductGridConfig } from './productGrid/wsProductGridConfig';
import { config as wsCollectionGridConfig } from './collectionGrid/wsCollectionGridConfig';
import {
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../config/constants';

const globalNameValues = {
  fullWidth: 'fullWidth',
  separatorLine: 'separatorLine',
  bottomMargin: 'bottomMargin',
  topPadding: 'topPadding',
  bottomPadding: 'bottomPadding',
  sidePadding: 'sidePadding'
};

const globalDefaultValues = {
  [globalNameValues.fullWidth]: 'false',
  [globalNameValues.separatorLine]: 'false',
  [globalNameValues.bottomMargin]: '0',
  [globalNameValues.topPadding]: '8',
  [globalNameValues.bottomPadding]: '8',
  [globalNameValues.sidePadding]: '0'
};

const globalConfigOptions = {
  [globalNameValues.fullWidth]: {
    label: 'Stretch container full width',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Container layout'
  },
  [globalNameValues.separatorLine]: {
    label: 'Show separator line',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Container layout'
  },
  [globalNameValues.bottomMargin]: {
    label: 'Bottom margin',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    category: 'Container layout',
    max: 100,
    unit: 'px'
  },
  [globalNameValues.topPadding]: {
    label: 'Top padding',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    category: 'Container layout',
    min: 0,
    max: 100,
    unit: 'px'
  },
  [globalNameValues.bottomPadding]: {
    label: 'Bottom Padding',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    category: 'Container layout',
    min: 0,
    max: 100,
    unit: 'px'
  },
  [globalNameValues.sidePadding]: {
    label: 'Side padding',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    category: 'Container layout',
    min: 0,
    max: 100,
    unit: 'px'
  }
};

const ECOM_WEBSTORE_SECTIONS = {
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.HEADER]: wsHeaderConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.ANNOUNCEMENT_BAR]: wsAnnouncementBarConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.FEATURED_COLLECTION]: wsFeaturedCollectionConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.FEATURED_PRODUCT]: wsFeaturedProductConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLECTION_LIST]: wsCollectionListConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.RICH_TEXT]: wsRichTextConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.IMAGE_WITH_TEXT]: wsImageWithTextConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.IMAGE_BANNER]: wsImageBannerConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.LAYERED_BANNER]: wsLayeredBannerConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.SLIDESHOW]: wsSlideshowConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLAGE]: wsCollageConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.MULTICOLUMN]: wsMultiColumnConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.MULTIROW]: wsMultiRowConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLAPSIBLE_CONTENT]: wsCollapsibleContentConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.EMAIL_SIGNUP]: wsEmailSignupConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.CONTACT_FORM]: wsContactFormConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.VIDEO]: wsVideoConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.BLOG_POSTS]: wsBlogPostsConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.PAGE]: wsPageConfig, // hidden
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.FOOTER]: wsFooterConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.ACCOUNT_CONTENT]: wsAccountContentConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.PRODUCT_INFORMATION]: wsProductInformationConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.CHECKOUT_CONTENT]: wsCheckoutContentConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.RESOURCE_BANNER]: wsBannerConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.PRODUCT_GRID]: wsProductGridConfig,
  [ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLECTION_GRID]: wsCollectionGridConfig
};

const ECOM_WEBSTORE_SECTIONS_LIST = Object.keys(ECOM_WEBSTORE_SECTIONS).reduce(
  (r, c) => [...r, ECOM_WEBSTORE_SECTIONS[c]],
  []
);

export {
  globalNameValues,
  globalDefaultValues,
  globalConfigOptions,
  ECOM_WEBSTORE_SECTIONS,
  ECOM_WEBSTORE_SECTIONS_LIST
};
