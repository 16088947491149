import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ECOM_WEBSTORE_PAGE_CONFIG,
  ECOM_WEBSTORE_PAGE_CONFIG_LIST
} from '../components/content/webstore/pages/wsPageConfig';
import { Context as StoreUserContext } from '../providers/StoreUserContextProvider';
import { Context as HelperContext } from '../providers/HelperContextProvider';
import {
  ECOM_PRODUCT_STATUS_ENUM,
  ECOM_WEBSTORE_PAGE_TYPE_ENUM
} from '../config/constants';
import { triggerNotification } from './notification';
import { ECOM_WEBSTORE_SECTIONS } from '../components/content/webstore/sections/wsSectionConfig';
import { IMAGE_HEIGHT_ENUM } from '../components/content/webstore/sections/imageBanner/wsImageBannerConfig';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../components/content/webstore/blocks/sectionBlockConfig';

const getProductUrlHandleFromPathname = (pathname) => {
  const splitPath = pathname.split('/');
  return splitPath.length >= 3 ? splitPath[2] : null;
};

const determineEcomStoreMenuItemUrl = ({
  fkEcomStorePageType,
  ecomResourceId,
  customUrl,
  ecomStoreTheme
}) => {
  if (fkEcomStorePageType) {
    let page = ECOM_WEBSTORE_PAGE_CONFIG[fkEcomStorePageType];
    if (!page) {
      page = ECOM_WEBSTORE_PAGE_CONFIG[ECOM_WEBSTORE_PAGE_TYPE_ENUM.NOT_FOUND];
    }

    const { path, isSelected, getResourceValue } = page;
    const resourceValue = ecomResourceId
      ? getResourceValue
        ? getResourceValue(ecomResourceId, ecomStoreTheme)
        : ecomResourceId
      : '';

    const currentPath = path.replace(
      '/{id}',
      resourceValue ? `/${resourceValue}` : ''
    );

    return {
      url: '',
      path: currentPath,
      target: null,
      isSelected: isSelected(currentPath)
        ? isSelected
        : ECOM_WEBSTORE_PAGE_CONFIG_LIST.find((f) => f.isSelected(currentPath))
            ?.isSelected || isSelected
    };
  }
  return {
    url: customUrl?.replace(/^(https?:\/\/)?(www\.)?/, 'https://') ?? '',
    path: '',
    target: '_blank',
    isSelected: () => false
  };
};

const getDefaultBannerHeight = (
  config,
  adaptImageHeight,
  adaptImageWidth,
  isTabletOrSmaller,
  viewPortAdjustment,
  pageSize = null,
  pageAdjustment = null
) => {
  if (
    config.imageHeight === IMAGE_HEIGHT_ENUM.ADAPT_TO_IMAGE &&
    adaptImageHeight &&
    adaptImageWidth
  ) {
    const windowWidth = viewPortAdjustment
      ? `calc(100vw - ${viewPortAdjustment})`
      : `100vw`;
    return `calc((${adaptImageHeight} / ${adaptImageWidth}) * ${
      pageSize ? `min(${windowWidth}, ${pageSize}px)` : windowWidth
    }${pageAdjustment ? ` - ${pageAdjustment}` : ''})`;
  }

  const sizeMap = {
    [IMAGE_HEIGHT_ENUM.EXTRA_SMALL]: isTabletOrSmaller ? 200 : 300,
    [IMAGE_HEIGHT_ENUM.SMALL]: isTabletOrSmaller ? 300 : 450,
    [IMAGE_HEIGHT_ENUM.MEDIUM]: isTabletOrSmaller ? 450 : 600,
    [IMAGE_HEIGHT_ENUM.LARGE]: isTabletOrSmaller ? 550 : 750,
    [IMAGE_HEIGHT_ENUM.CUSTOM]: `${config.customImageHeight}px`
  };

  return sizeMap[config.imageHeight] || sizeMap[IMAGE_HEIGHT_ENUM.EXTRA_SMALL];
};

const sortVariantsByOptions = (ecomVendorProductVariants) =>
  ecomVendorProductVariants.sort((a, b) => {
    const aOptionsSorted = a.ecomVendorProductVariantOptions.sort(
      (a1, b1) => a1.nameSort - b1.nameSort
    );
    const bOptionsSorted = b.ecomVendorProductVariantOptions.sort(
      (a1, b1) => a1.nameSort - b1.nameSort
    );

    return (
      (aOptionsSorted[0]?.valueSort ?? 0) -
        (bOptionsSorted[0]?.valueSort ?? 0) ||
      (aOptionsSorted[1]?.valueSort ?? 0) -
        (bOptionsSorted[1]?.valueSort ?? 0) ||
      (aOptionsSorted[2]?.valueSort ?? 0) - (bOptionsSorted[2]?.valueSort ?? 0)
    );
  });

const getVariantOptions = (ecomStoreProduct) => {
  const variantOptions =
    ecomStoreProduct?.ecomStoreProductVariants
      .reduce((r, c) => {
        c.ecomVendorProductVariant.ecomVendorProductVariantOptions.forEach(
          (op) => {
            const existingOption = r.find((f) => f.name === op.name);
            if (existingOption) {
              if (!existingOption.values.find((f) => f.value === op.value)) {
                existingOption.values.push({
                  value: op.value,
                  sort: op.valueSort
                });
              }
            }
            else {
              r.push({
                sort: op.nameSort,
                name: op.name,
                values: [{ value: op.value, sort: op.valueSort }]
              });
            }
          }
        );
        return r;
      }, [])
      .sort((a, b) => a.sort - b.sort) ?? [];

  return variantOptions;
};

const isVariantUnavailable = (latestVariant) =>
  latestVariant.deleted ||
  latestVariant.ecomVendorProductVariant.deleted ||
  latestVariant.ecomStoreProduct.deleted ||
  latestVariant.ecomStoreProduct.ecomVendorProduct.deleted ||
  latestVariant.ecomStoreProduct.fkEcomProductStatus.toString() !==
    ECOM_PRODUCT_STATUS_ENUM.ACTIVE.toString() ||
  latestVariant.ecomStoreProduct.ecomVendorProduct.fkEcomProductStatus.toString() !==
    ECOM_PRODUCT_STATUS_ENUM.ACTIVE.toString() ||
  (latestVariant.ecomVendorProductVariant.quantity &&
    latestVariant.ecomVendorProductVariant.quantity <= 0);

const useWebstoreUserHelper = () => {
  const {
    state: helperState,
    toggleWebstoreAddProductToCartModal
  } = useContext(HelperContext);
  const {
    state,
    fetchAllProductsPreview,
    fetchEcomStoreProduct,
    fetchEcomStoreProductBulk,
    fetchEcomStoreCollectionsHistory,
    fetchEcomStoreCollections
  } = useContext(StoreUserContext);

  const viewProductOverviewModal = (
    pkEcomStore,
    { urlHandle },
    onCallback = null
  ) => {
    const ecomStoreProduct = state.ecomStoreProductHistory.value.find(
      (f) => f.urlHandle === urlHandle
    );
    if (ecomStoreProduct) {
      toggleWebstoreAddProductToCartModal({
        isOpen: true,
        ecomStoreProduct
      });
      onCallback();
    }
    else {
      fetchEcomStoreProduct(
        pkEcomStore,
        { urlHandle },
        (data) => {
          if (onCallback) {
            onCallback();
          }
          toggleWebstoreAddProductToCartModal({
            isOpen: true,
            ecomStoreProduct: data
          });
        },
        (e) => {
          triggerNotification(
            'There was a problem loading the product. Please try again later.'
          );
          if (onCallback) {
            onCallback();
          }
        }
      );
    }
  };

  const fetchWebstorePageResources = (
    ecomStoreTheme,
    bodySections,
    pathname
  ) => {
    // Map sections and blocks with their respective configurations
    const sectionsAndBlocksWithConfig = bodySections.map((section) => ({
      section,
      config: ECOM_WEBSTORE_SECTIONS[section.fkEcomStoreThemeSectionType],
      blocks: section.ecomStoreThemeSectionBlocks.map((block) => ({
        block,
        config:
          ECOM_WEBSTORE_SECTION_BLOCKS[block.fkEcomStoreThemeSectionBlockType]
      }))
    }));

    // Get fetch configs from blocks
    const blocksWithFetchConfigs = sectionsAndBlocksWithConfig
      .flatMap((section) => section.blocks) // Use flatMap to flatten and map in one step
      .filter((block) => block.config?.onFetchDataConfig)
      .map((block) =>
        block.config.onFetchDataConfig(block.block.config, pathname)
      );

    // Get fetch configs from sections
    const sectionFetchConfigs = sectionsAndBlocksWithConfig
      .filter((section) => section.config?.onFetchDataConfig)
      .map((section) =>
        section.config.onFetchDataConfig(section.section.config, pathname)
      );

    // Combine and reduce fetch configs by type
    const fetchTypesWithResources = [
      ...sectionFetchConfigs,
      ...blocksWithFetchConfigs
    ].reduce((result, current) => {
      const existing = result.find((fetch) => fetch.type === current.type);
      if (existing) {
        existing.resources.push(current);
      }
      else {
        result.push({ type: current.type, resources: [current] });
      }
      return result;
    }, []);

    fetchTypesWithResources.forEach((f) => {
      switch (f.type) {
        case 'ecomStoreCollection': {
          const collectionIdsThatNeedFetching = f.resources
            .map((r) => r.entityId)
            .filter((id) => !!id)
            .filter(
              (id) =>
                !state.ecomStoreCollectionHistory.value.find(
                  (c) => c.pkEcomStoreCollection.toString() === id?.toString()
                )
            );
          if (collectionIdsThatNeedFetching.length > 0) {
            fetchEcomStoreCollectionsHistory(collectionIdsThatNeedFetching);
          }
          break;
        }
        case 'ecomStoreCollections': {
          const collectionIdsThatNeedFetching = f.resources
            .map((r) => r.entityId)
            .filter((id) => !!id)
            .filter(
              (id) =>
                !state.ecomStoreCollections.value.find(
                  (c) => c.pkEcomStoreCollection.toString() === id?.toString()
                )
            );
          if (
            collectionIdsThatNeedFetching.length > 0 ||
            (ecomStoreTheme.ecomStore.ecomStoreCollections.length > 0 &&
              state.ecomStoreCollections.value.length === 0)
          ) {
            fetchEcomStoreCollections(ecomStoreTheme.ecomStore.pkEcomStore, {
              pkEcomStoreCollections: collectionIdsThatNeedFetching
            });
          }
          break;
        }
        case 'ecomStoreProducts':
          if (
            !state.paginatedEcomStoreCollectionProducts.initialized &&
            !state.paginatedEcomStoreCollectionProducts.loading
          ) {
            fetchAllProductsPreview(ecomStoreTheme.fkEcomStore);
          }
          break;
        case 'ecomStoreProduct': {
          const productsThatNeedFetching = f.resources.reduce(
            (acc, r) => {
              if (
                !state.ecomStoreProductHistory.value.some(
                  (p) =>
                    p.uuid === r.entityId || p.urlHandle === r.filter?.urlHandle
                )
              ) {
                if (r.entityId) {
                  acc.uuids.push(r.entityId);
                }
                else if (r.filter?.urlHandle) {
                  acc.urlHandles.push(r.filter?.urlHandle);
                }
              }
              return acc;
            },
            {
              uuids: [],
              urlHandles: []
            }
          );
          if (
            productsThatNeedFetching.uuids.length > 0 ||
            productsThatNeedFetching.urlHandles.length > 0
          ) {
            fetchEcomStoreProductBulk(ecomStoreTheme.ecomStore.pkEcomStore, {
              uuids: productsThatNeedFetching.uuids,
              urlHandles: productsThatNeedFetching.urlHandles
            });
          }
          break;
        }
        default:
          break;
      }
    });
  };

  return {
    fetchWebstorePageResources,
    viewProductOverviewModal
  };
};

const useWebstoreSearchFilter = ({
  editModeEnabled,
  onNavigate,
  search,
  pathname
}) => {
  const navigate = useNavigate();
  const filterSearchParams = new URLSearchParams(search);
  const searchParam = filterSearchParams.get('search');
  const sortParam = filterSearchParams.get('sort');
  const minPriceParam = filterSearchParams.get('minPrice');
  const maxPriceParam = filterSearchParams.get('maxPrice');
  const vendorParam = filterSearchParams.get('vendor');
  const includeInventory = filterSearchParams.get('includeInventory');
  const includeNoInventory = filterSearchParams.get('includeNoInventory');
  const pageIndexParam = filterSearchParams.get('page');

  const filter = {
    search: searchParam ?? '',
    sort: sortParam ?? null,
    minPrice: minPriceParam ?? null,
    maxPrice: maxPriceParam ?? null,
    vendor: vendorParam ?? null,
    includeInventory: includeInventory?.toLocaleLowerCase() === 'true',
    includeNoInventory: includeNoInventory?.toLocaleLowerCase() === 'true'
  };

  const hasSearched =
    !!filter.search ||
    !!filter.sort ||
    !!filter.minPrice ||
    !!filter.maxPrice ||
    !!filter.vendor ||
    !!includeInventory ||
    !!includeNoInventory;

  const resetFilter = () => {
    if (editModeEnabled) {
      onNavigate(pathname);
    }
    else {
      navigate(pathname, { replace: true });
    }
  };

  const objectToParams = (obj) => {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        params.set(key, obj[key]);
      }
    });
    return params.toString();
  };

  const onFilterChange = (params) => {
    const newParams = objectToParams({ ...filter, ...params });
    if (editModeEnabled) {
      onNavigate(`${pathname}?${newParams}`);
    }
    else {
      navigate(`${pathname}?${newParams}`);
    }
  };

  return {
    filter,
    hasSearched,
    resetFilter,
    onFilterChange,
    pageIndex: pageIndexParam ? parseInt(pageIndexParam, 10) : 1
  };
};

export {
  determineEcomStoreMenuItemUrl,
  getDefaultBannerHeight,
  getProductUrlHandleFromPathname,
  getVariantOptions,
  isVariantUnavailable,
  sortVariantsByOptions,
  useWebstoreUserHelper,
  useWebstoreSearchFilter
};
