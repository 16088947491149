import React from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';

const VendorOrderFulfillmentHelpModal = ({ isOpen, onClose }) => {
  const t = 1;

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: 'Close',
        onCancel: onClose,
        isSubmitHidden: true
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Fulfilling Orders"
    >
      <AppStack style={{ gap: 40 }} />
    </ResponsiveModal>
  );
};

VendorOrderFulfillmentHelpModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default VendorOrderFulfillmentHelpModal;
