import {
  Apple,
  BabyBottle,
  Beer,
  Bolt,
  Bottle,
  Bowl,
  Box,
  Bucket,
  Carrot,
  Check,
  Cheese,
  Cherry,
  Clipboard,
  Clock,
  Coffee,
  Cookie,
  Cup,
  Egg,
  Eye,
  Fish,
  Flame,
  Flask,
  Fridge,
  Heart,
  Ironing1,
  Leaf,
  Lemon2,
  Lock,
  Lollipop,
  MapPin,
  Meat,
  MessageCircle,
  Milk,
  Milkshake,
  Mug,
  Mushroom,
  Package,
  PaperBag,
  Paw,
  Pepper,
  Perfume,
  Pizza,
  Plane,
  Plant,
  QuestionMark,
  Recycle,
  Ruler,
  Salad,
  Salt,
  Sausage,
  Shirt,
  Shoe,
  Snowflake,
  Tag,
  Truck,
  TruckReturn
} from 'tabler-icons-react';

const WS_TEXT_ICONS = [
  // FOOD & BEVERAGES
  {
    value: 'Cherry',
    group: 'Food & Beverages',
    component: Cherry
  },
  {
    value: 'Lemon',
    group: 'Food & Beverages',
    component: Lemon2
  },
  {
    value: 'Carrot',
    group: 'Food & Beverages',
    component: Carrot
  },
  {
    value: 'Mushroom',
    group: 'Food & Beverages',
    component: Mushroom
  },
  {
    value: 'Salad',
    group: 'Food & Beverages',
    component: Salad
  },
  {
    value: 'Egg',
    group: 'Food & Beverages',
    component: Egg
  },
  {
    value: 'Fish',
    group: 'Food & Beverages',
    component: Fish
  },
  {
    value: 'Lollipop',
    group: 'Food & Beverages',
    component: Lollipop
  },
  {
    value: 'Cookie',
    group: 'Food & Beverages',
    component: Cookie
  },
  {
    value: 'Cheese',
    group: 'Food & Beverages',
    component: Cheese
  },
  {
    value: 'Milk',
    group: 'Food & Beverages',
    component: Milk
  },
  {
    value: 'Milkshake',
    group: 'Food & Beverages',
    component: Milkshake
  },
  {
    value: 'Beer',
    group: 'Food & Beverages',
    component: Beer
  },
  {
    value: 'Coffee',
    group: 'Food & Beverages',
    component: Coffee
  },
  {
    value: 'Meat',
    group: 'Food & Beverages',
    component: Meat
  },
  {
    value: 'Sausage',
    group: 'Food & Beverages',
    component: Sausage
  },
  {
    value: 'Salt',
    group: 'Food & Beverages',
    component: Salt
  },
  {
    value: 'Pizza',
    group: 'Food & Beverages',
    component: Pizza
  },
  {
    value: 'Pepper',
    group: 'Food & Beverages',
    component: Pepper
  },

  // CONTAINERS
  {
    value: 'Bottle',
    group: 'Containers',
    component: Bottle
  },
  {
    value: 'Baby Bottle',
    group: 'Containers',
    component: BabyBottle
  },
  {
    value: 'Cup',
    group: 'Containers',
    component: Cup
  },
  {
    value: 'Mug',
    group: 'Containers',
    component: Mug
  },
  {
    value: 'Flask',
    group: 'Containers',
    component: Flask
  },
  {
    value: 'Bucket',
    group: 'Containers',
    component: Bucket
  },
  {
    value: 'Box',
    group: 'Containers',
    component: Box
  },
  {
    value: 'Package',
    group: 'Containers',
    component: Package
  },
  {
    value: 'Fridge',
    group: 'Containers',
    component: Fridge
  },
  {
    value: 'Paper bag',
    group: 'Containers',
    component: PaperBag
  },
  {
    value: 'Bowl',
    group: 'Containers',
    component: Bowl
  },

  // CLOTHING & FOOTWEAR
  {
    value: 'Shirt',
    group: 'Clothing & Footwear',
    component: Shirt
  },
  {
    value: 'Shoe',
    group: 'Clothing & Footwear',
    component: Shoe
  },

  // Objects
  {
    value: 'Clipboard',
    group: 'Objects',
    component: Clipboard
  },
  {
    value: 'Lock',
    group: 'Objects',
    component: Lock
  },
  {
    value: 'Iron',
    group: 'Objects',
    component: Ironing1
  },
  {
    value: 'Perfume',
    group: 'Objects',
    component: Perfume
  },
  {
    value: 'Clock',
    group: 'Objects',
    component: Clock
  },
  {
    value: 'Ruler',
    group: 'Objects',
    component: Ruler
  },

  // TRANSPORT
  {
    value: 'Truck',
    group: 'Transport',
    component: Truck
  },
  {
    value: 'Plane',
    group: 'Transport',
    component: Plane
  },
  {
    value: 'Truck return',
    group: 'Transport',
    component: TruckReturn
  },
  {
    value: 'Truck delivery',
    group: 'Transport',
    component: TruckReturn
  },

  // MISCELLANEOUS
  {
    value: 'Chat bubble',
    group: 'Miscellaneous',
    component: MessageCircle
  },
  {
    value: 'Check',
    group: 'Miscellaneous',
    component: Check
  },
  {
    value: 'Eye',
    group: 'Miscellaneous',
    component: Eye
  },
  {
    value: 'Flame',
    group: 'Miscellaneous',
    component: Flame
  },
  {
    value: 'Heart',
    group: 'Miscellaneous',
    component: Heart
  },
  {
    value: 'Leaf',
    group: 'Miscellaneous',
    component: Leaf
  },
  {
    value: 'Bolt',
    group: 'Miscellaneous',
    component: Bolt
  },
  {
    value: 'MapPin',
    group: 'Miscellaneous',
    component: MapPin
  },
  {
    value: 'Paw',
    group: 'Miscellaneous',
    component: Paw
  },
  {
    value: 'Plant',
    group: 'Miscellaneous',
    component: Plant
  },
  {
    value: 'Tag',
    group: 'Miscellaneous',
    component: Tag
  },
  {
    value: 'QuestionMark',
    group: 'Miscellaneous',
    component: QuestionMark
  },
  {
    value: 'Recycle',
    group: 'Miscellaneous',
    component: Recycle
  },
  {
    value: 'Snowflake',
    group: 'Miscellaneous',
    component: Snowflake
  }
]
  .sort(
    (a, b) => a.group.localeCompare(b.group) || a.value.localeCompare(b.value)
  )
  .map((op) => ({
    ...op,
    label: op.value
  }));

const WS_TEXT_ICON_OPTIONS = WS_TEXT_ICONS.reduce(
  (acc, op) => {
    const existingGroup = acc.find((group) => group.group === op.group);
    if (!existingGroup) {
      acc.push({
        group: op.group,
        items: [op.value]
      });
    }
    else {
      existingGroup.items.push(op.value);
    }
    return acc;
  },
  [
    {
      label: 'None',
      value: 'none'
    }
  ]
);

export { WS_TEXT_ICONS, WS_TEXT_ICON_OPTIONS };
