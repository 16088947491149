const providerContextReducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: null,
          loading: true,
          ...action.payload
        }
      };
    case 'success':
      // don't update state if the payload params does not match the requested filters
      if (action.payload?.filter && state[action.stateName].filter) {
        for (const key in action.payload?.filter) {
          if (
            action.payload?.filter[key] !== state[action.stateName].filter[key]
          ) {
            return state;
          }
        }
      }

      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          loading: false,
          ...action.payload
        }
      };
    case 'error':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: action.payload,
          loading: false
        }
      };
    case 'replace':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          ...action.payload,
          value: [
            ...state[action.stateName].value.filter(action.payload.filter),
            ...(Array.isArray(action.payload.value)
              ? action.payload.value
              : [action.payload.value])
          ],
          loading: false
        }
      };
    case 'append':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value,
            ...(Array.isArray(action.payload.value)
              ? action.payload.value
              : [action.payload.value])
          ],
          loading: false
        }
      };
    case 'paginated-append':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          ...action.payload,
          data: [
            ...state[action.stateName].data,
            ...(Array.isArray(action.payload.data)
              ? action.payload.data
              : [action.payload.data])
          ],
          loading: false
        }
      };
    case 'paginated-replace-index': {
      const dataCopy = [...state[action.stateName].data];
      const index = dataCopy.findIndex(action.payload.filter);
      if (index >= 0) {
        dataCopy[index] = action.payload.value;
      }

      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          data: dataCopy,
          loading: false
        }
      };
    }
    case 'remove':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: state[action.stateName].value.filter(action.payload),
          loading: false
        }
      };
    case 'set':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export { providerContextReducer };
