import React, { useContext } from 'react';
import HeaderView from '../../common/HeaderView';
import { AVAILABLE_ECOM_REPORTS } from '../../../config/availableReports';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';

const VendorReportTable = () => {
  const { state: authState } = useContext(AuthContext);
  const vendorReports = AVAILABLE_ECOM_REPORTS.filter(
    (r) => r.isVendorReport || r.isGlobal
  );

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Reports',
          to: '/vendor/reports'
        }
      ]}
    >
    </HeaderView>
  );
};

export default VendorReportTable;
