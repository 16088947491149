import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@mantine/core';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppCard from './AppCard';

const FormSection = ({
  onSubmit,
  onCancel,
  cancelTitle,
  cancelColor,
  cancelVariant,
  submitTitle,
  submitColor,
  submitVariant,
  children,
  isLoading,
  isSubmitHidden,
  isSubmitDisabled,
  isCancelHidden,
  hideDivider,
  padding,
  error,
  leftSection,
  submitLoading,
  footerProps,
  ...rest
}) => (
  <AppStack
    component="form"
    onSubmit={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onSubmit(e);
    }}
    {...rest}
    style={{ gap: 0, flex: 1, ...rest.style }}
  >
    <AppStack
      style={{
        flex: 1,
        gap: 0,
        overflow: 'auto',
        padding: padding ?? 16
      }}
    >
      {children}
    </AppStack>

    {(!isSubmitHidden || !isCancelHidden || !leftSection) && (
      <AppCard
        radius={0}
        withBorder
        {...footerProps}
        style={{ position: 'sticky', bottom: 0, ...footerProps?.style }}
      >
        <AppStack hiddenFrom="xsm">
          {leftSection}
          {!isSubmitHidden && (
            <Button
              color={submitColor ?? 'dark'}
              disabled={isSubmitDisabled}
              loading={isLoading || submitLoading}
              radius="md"
              size="md"
              style={{ fontSize: 14 }}
              type="submit"
              variant={submitVariant ?? 'filled'}
            >
              {submitTitle ?? 'Submit'}
            </Button>
          )}
          {!isCancelHidden && (
            <Button
              color={cancelColor ?? 'dark'}
              disabled={isLoading}
              onClick={onCancel}
              radius="md"
              size="md"
              style={{ fontSize: 14 }}
              type="button"
              variant={cancelVariant ?? 'outline'}
            >
              {cancelTitle ?? 'Cancel'}
            </Button>
          )}
        </AppStack>

        <AppFlexbox style={{ flex: 1, alignItems: 'center' }} visibleFrom="xsm">
          {leftSection}
          <AppFlexbox style={{ flex: 1, justifyContent: 'end' }}>
            {!isCancelHidden && (
              <Button
                color={cancelColor ?? 'dark'}
                disabled={isLoading}
                onClick={onCancel}
                radius="md"
                size="compact-md"
                style={{ fontSize: 14 }}
                type="button"
                variant={cancelVariant ?? 'outline'}
              >
                {cancelTitle ?? 'Cancel'}
              </Button>
            )}
            {!isSubmitHidden && (
              <Button
                color={submitColor ?? 'dark'}
                disabled={isSubmitDisabled}
                loading={isLoading || submitLoading}
                radius="md"
                size="compact-md"
                style={{ fontSize: 14 }}
                type="submit"
                variant={submitVariant ?? 'filled'}
              >
                {submitTitle ?? 'Submit'}
              </Button>
            )}
          </AppFlexbox>
        </AppFlexbox>
      </AppCard>
    )}
  </AppStack>
);

FormSection.propTypes = {
  cancelColor: PropTypes.string,
  cancelTitle: PropTypes.string,
  cancelVariant: PropTypes.string,
  children: PropTypes.any,
  error: PropTypes.string,
  footerProps: PropTypes.object,
  hideDivider: PropTypes.bool,
  isCancelHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  isSubmitHidden: PropTypes.bool,
  leftSection: PropTypes.any,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  padding: PropTypes.number,
  submitColor: PropTypes.string,
  submitLoading: PropTypes.bool,
  submitTitle: PropTypes.string,
  submitVariant: PropTypes.string
};

export default FormSection;
