import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Button, Menu } from '@mantine/core';
import { Copy, Dots, Pencil, Plus, Stack, Trash } from 'tabler-icons-react';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import TableView from '../../common/TableView';
import { triggerNotification } from '../../../helpers/notification';
import HeaderView from '../../common/HeaderView';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppMenu from '../../common/AppMenu';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import { useModalState } from '../../../helpers/hooks';
import ConfirmModal from '../../common/ConfirmModal';

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Products',
    value: 'products',
    sortable: true
  }
];

const VIEW_ACTIONS = [
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.EDIT,
    section: 1,
    icon: <Pencil size={18} />
  },
  {
    label: 'Duplicate',
    value: VIEW_ACTIONS_ENUM.DUPLICATE,
    section: 1,
    icon: <Copy size={18} />
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.DELETE,
    icon: <Trash color="red" size={18} />,
    section: 2,
    color: 'red'
  }
];

const VendorCollectionsTable = () => {
  const fetchedPk = useRef(null);
  const navigate = useNavigate();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchVendorCollections, deleteVendorCollections } = useContext(
    VendorContext
  );
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [filterState, setFilterState] = useState({
    search: '',
    selectedPks: []
  });
  const loading =
    !fetchedPk.current ||
    fetchedPk.current !== authState.pkEcomVendor ||
    state.ecomVendorCollections.loading;

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchVendorCollections(
        { fkEcomVendor: authState.pkEcomVendor },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      fetchedPk.current = authState.pkEcomVendor;
    }
  }, [authState.pkEcomVendor]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Collections',
          to: '/vendor/collections'
        }
      ]}
      rightSection={
        !loading && (
          <Button
            color="dark"
            leftSection={<Plus size={18} />}
            onClick={() => navigate('/vendor/collections/new')}
            radius="md"
            size="compact-md"
            style={{ fontSize: 14 }}
          >
            Create collection
          </Button>
        )
      }
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={!loading && state.ecomVendorCollections.value.length === 0}
        disabledContent={{
          title: 'Create your first collection',
          description:
            'Collections are groups of products that you can use to organize your store. Add your first collection to get started. You can always add more collections later.',
          icon: <Stack color="#000" size={125} />,
          primaryButton: {
            label: 'Create collection',
            icon: <Plus size={18} />,
            link: '/vendor/collections/new'
          }
        }}
        emptyMessage="No collections found."
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by name',
            value: filterState.search,
            onChange: (value) =>
              setFilterState({ ...filterState, search: value })
          }
        ]}
        isLoading={loading}
        onAction={(action, item) => {
          switch (action) {
            case VIEW_ACTIONS_ENUM.EDIT:
              navigate(`/vendor/collections/${item.key}`);
              break;
            case VIEW_ACTIONS_ENUM.DUPLICATE:
              navigate(
                `/vendor/collections/${item.key}/duplicate?from=collections`
              );
              break;
            case VIEW_ACTIONS_ENUM.DELETE:
              onOpenModal(action, [
                state.ecomVendorCollections.value.find(
                  (s) => s.pkEcomVendorCollection === item.key
                )
              ]);
              break;
            default:
              onOpenModal(
                action,
                state.ecomVendorCollections.value.find(
                  (s) => s.pkEcomVendorCollection === item.key
                )
              );
              break;
          }
        }}
        onRefresh={() =>
          fetchVendorCollections(
            { fkEcomVendor: authState.pkEcomVendor },
            null,
            (error) => {
              triggerNotification(error);
            }
          )
        }
        onSelect={(selectedPks) =>
          setFilterState({
            ...filterState,
            selectedPks
          })
        }
        rows={state.ecomVendorCollections.value
          .filter(
            (e) =>
              !filterState.search ||
              e.name.toLowerCase().includes(filterState.search.toLowerCase()) ||
              (e.description &&
                e.description
                  .toLowerCase()
                  .includes(filterState.search.toLowerCase()))
          )
          .map((a) => ({
            key: a.pkEcomVendorCollection,
            actions: VIEW_ACTIONS,
            columns: [
              {
                key: 1,
                uuid: a.uuid,
                label: a.name,
                onClick: () =>
                  navigate(`/vendor/collections/${a.pkEcomVendorCollection}`),
                weight: 500,
                subLabel: a.description,
                src: a.previewImage ?? null,
                showImage: true
              },
              {
                key: 2,
                label: a.ecomVendorCollectionProductCount,
                sortValueType: 'number'
              }
            ]
          }))}
        selectActionSection={
          <AppMenu
            control={
              <ActionIcon color="#ced4da" size="lg" variant="outline">
                <Dots color="#000" />
              </ActionIcon>
            }
            styles={{ dropdown: { padding: 0 } }}
          >
            <AppStack style={{ padding: 2, gap: 1 }}>
              <Menu.Item
                leftSection={<Trash size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onOpenModal(VIEW_ACTIONS_ENUM.DELETE, [
                    ...state.ecomVendorCollections.value.filter((s) =>
                      filterState.selectedPks.includes(s.pkEcomVendorCollection)
                    )
                  ]);
                }}
                style={{ color: 'red' }}
              >
                Delete collections
              </Menu.Item>
            </AppStack>
          </AppMenu>
        }
        selectedRows={filterState.selectedPks}
        tableTitle="Collections"
      />

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete collections"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to delete{' '}
              <b>
                {modalState.item?.length === 1
                  ? modalState.item[0]?.name
                  : `${modalState.item?.length} collections`}
              </b>
              ?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteVendorCollections(
            authState.pkEcomVendor,
            modalState.item.map((i) => i.pkEcomVendorCollection),
            () => {
              triggerNotification(
                modalState.item.length === 1
                  ? 'Collection deleted.'
                  : 'Collections deleted.',
                'success'
              );
              onCloseModal();
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${
          modalState.item?.length === 1
            ? modalState.item[0]?.name
            : `${modalState.item?.length} collections`
        }?
        `}
      />
    </HeaderView>
  );
};

VendorCollectionsTable.propTypes = {};

export default VendorCollectionsTable;
