import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, Skeleton } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import * as classes from '../../../styles/animations.module.css';

const VendorCatalogVendorDisplayCard = ({ ecomVendor, baseUrl }) =>
  ecomVendor ? (
    <AppCard
      className={classes['grow-container']}
      component={Link}
      radius="md"
      shadow="xs"
      style={{
        flex: 1,
        height: '100%',
        cursor: 'pointer',
        padding: 16,
        alignItems: 'center',
        justifyContent: 'start'
      }}
      to={`${baseUrl}/vendors/${ecomVendor.urlHandle}`}
      withBorder
    >
      <AppStack style={{ flex: 1, gap: 16 }}>
        <AppStack
          style={{ height: 150, width: '100%', justifyContent: 'center' }}
        >
          <Image
            fit="contain"
            h="100%"
            src={ecomVendor.logoImageUrl}
            w="100%"
          />
        </AppStack>
        <AppStack style={{ flex: 1, gap: 0, justifyContent: 'space-between' }}>
          <AppText
            style={{ textAlign: 'center', fontSize: 20, fontWeight: 700 }}
          >
            {ecomVendor.name}
          </AppText>
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard
      className={classes['grow-container']}
      radius="md"
      shadow="xs"
      style={{
        flex: 1,
        height: '100%',
        minHeight: 235,
        cursor: 'pointer',
        padding: 0,
        alignItems: 'center',
        justifyContent: 'start'
      }}
      withBorder
    >
      <Skeleton height="100%" width="100%" />
    </AppCard>
  );

VendorCatalogVendorDisplayCard.propTypes = {
  baseUrl: PropTypes.string,
  ecomVendor: PropTypes.object
};

export default VendorCatalogVendorDisplayCard;
