import React, { useContext, useRef, useState } from 'react';
import { Anchor, Button, Loader } from '@mantine/core';
import PropTypes from 'prop-types';

import { Dropzone } from '@mantine/dropzone';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import SortableGrid from '../../common/SortableGrid';
// eslint-disable-next-line import/no-cycle
import SelectMediaModal from './SelectMediaModal';
import MediaUploadUrlUploadModal from './MediaUploadUrlUploadMenu';
import { SUPPORTED_MEDIA_TYPES } from '../../../config/constants';
import { uploadFileContentRecursively } from '../../../helpers/awsHelper';
import { triggerNotification } from '../../../helpers/notification';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';

const MediaDropzone = ({
  height,
  files,
  newFiles,
  onChangeOrder,
  maxFileSize,
  onUpload,
  onError,
  onSelectFile,
  selectedMediaKeys,
  enableMediaSelect,
  disabled,
  fkEcomStore,
  fkEcomVendor,
  uploadOnDrop,
  showSortGrid,
  maxSelectCount,
  isOpen,
  onClose,
  skipMediaFetch,
  ...rest
}) => {
  const { createStoreMedia } = useContext(StoreContext);
  const { createVendorMedia } = useContext(VendorContext);

  const [uploadState, setUploadState] = useState({
    loading: false,
    media: []
  });
  const openRef = useRef(null);
  const [showMediaSelector, setShowMediaSelector] = useState(false);

  const filesNotDeleted = files?.filter((f) => !f.deleted) ?? [];

  const onSuccessCallback = (data) => {
    triggerNotification('Media uploaded successfully', 'success');
    setUploadState((c) => ({
      ...c,
      loading: false
    }));
    onUpload([
      ...data.map((m) => ({
        ...m,
        preview: m.src
      })),
      ...files
    ]);
  };

  const onErrorCallback = () => {
    triggerNotification('Error uploading file');
    setUploadState({
      ...uploadState,
      loading: false
    });
  };

  const onSaveMedia = (mediaFiles) => {
    setUploadState({
      ...uploadState,
      loading: true
    });
    uploadFileContentRecursively(
      mediaFiles,
      fkEcomVendor
        ? `vendor-media-${fkEcomVendor}`
        : `store-media-${fkEcomStore}`,
      (media) => {
        if (fkEcomVendor) {
          createVendorMedia(
            fkEcomVendor,
            media,
            onSuccessCallback,
            onErrorCallback
          );
        }
        else {
          createStoreMedia(
            fkEcomStore,
            media,
            onSuccessCallback,
            onErrorCallback
          );
        }
      }
    );
  };

  const onUploadFiles = (mediaFiles) => {
    if (uploadOnDrop) {
      onSaveMedia(mediaFiles);
    }
    else {
      onUpload([...mediaFiles, ...files]);
    }
  };

  return (
    <Dropzone
      accept={SUPPORTED_MEDIA_TYPES}
      activateOnClick={false}
      disabled={disabled || showMediaSelector || uploadState.loading || isOpen}
      maxSize={5 * 1024 ** 2}
      onDrop={(uploadedFiles) => {
        onUploadFiles([
          ...uploadedFiles.map((file, index) => ({
            key: new Date().getTime() + index,
            file,
            alt: file.name,
            contentType: file.type,
            preview: URL.createObjectURL(file),
            fkEcomVendorMedia: null,
            src: ''
          }))
        ]);
      }}
      onReject={(e) => {
        if (onError) {
          onError(e);
        }
        else {
          triggerNotification(
            'File upload failed. Please ensure the file is under 5MB and in a valid image format.'
          );
        }
      }}
      openRef={openRef}
      {...rest}
    >
      <AppStack
        {...rest}
        style={{
          pointerEvents: 'all',
          position: 'relative',
          boxSizing: 'border-box',
          borderRadius: 10,
          flex: 1,
          minHeight: height ?? 150,
          overflow: 'hidden',
          ...rest.sx
        }}
      >
        <Dropzone.Accept>
          <AppStack
            style={{
              position: 'absolute',
              border: 'solid 2px #067D62',
              backgroundColor: 'rgba(6, 125, 98, 0.1)',
              borderRadius: 10,
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 9,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AppText
              style={{ textAlign: 'center', fontSize: 14, fontWeight: 500 }}
            >
              Drop file to upload
            </AppText>
          </AppStack>
        </Dropzone.Accept>

        <Dropzone.Idle>
          {showSortGrid && filesNotDeleted.length ? (
            <AppFlexbox style={{ alignItems: 'center', textAlign: 'center' }}>
              <SortableGrid
                items={filesNotDeleted.map((f) => ({
                  key: f.key,
                  sort: f.sort,
                  preview: f.preview
                }))}
                onSelectItem={onSelectFile}
                onSetItems={onChangeOrder}
                selectedItemKeys={selectedMediaKeys}
              >
                <AppStack
                  onClick={() => openRef.current?.()}
                  shadow="none"
                  style={{
                    border: 'dashed 2px #ced4da',
                    height: 135,
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,
                    borderRadius: 10,
                    cursor: 'pointer'
                  }}
                >
                  {uploadState.loading ? (
                    <Loader color="dark" size={36} />
                  ) : (
                    <>
                      <Button color="dark" size="compact-sm" variant="light">
                        Upload new
                      </Button>
                      {enableMediaSelect ? (
                        <Anchor
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!disabled) {
                              setShowMediaSelector(true);
                            }
                          }}
                          style={{
                            fontSize: 14,
                            color: '#666',
                            fontWeight: 500
                          }}
                        >
                          Select existing
                        </Anchor>
                      ) : (
                        <MediaUploadUrlUploadModal
                          control={
                            <Anchor
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              style={{
                                fontSize: 14,
                                color: '#666',
                                fontWeight: 500
                              }}
                            >
                              Add from URL
                            </Anchor>
                          }
                          onUpload={onUploadFiles}
                        />
                      )}
                    </>
                  )}
                </AppStack>
              </SortableGrid>
            </AppFlexbox>
          ) : (
            <AppStack
              onClick={() => openRef.current?.()}
              style={{
                position: 'absolute',
                border: 'dashed 2px #ced4da',
                borderRadius: 10,
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 9,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
            >
              {uploadState.loading ? (
                <AppStack
                  style={{
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Loader color="dark" size={36} />
                </AppStack>
              ) : (
                <>
                  <AppFlexbox
                    style={{ alignItems: 'center', textAlign: 'center' }}
                  >
                    <Button color="dark" size="compact-sm" variant="light">
                      Upload new
                    </Button>
                    {enableMediaSelect ? (
                      <Anchor
                        disabled={disabled}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!disabled) {
                            setShowMediaSelector(true);
                          }
                        }}
                        style={{ fontSize: 14, color: '#666', fontWeight: 500 }}
                      >
                        Select existing
                      </Anchor>
                    ) : (
                      <MediaUploadUrlUploadModal
                        control={
                          <Anchor
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{
                              fontSize: 14,
                              color: '#666',
                              fontWeight: 500
                            }}
                          >
                            Add from URL
                          </Anchor>
                        }
                        onUpload={onUploadFiles}
                      />
                    )}
                  </AppFlexbox>
                  <AppText
                    style={{ fontSize: 14, color: '#666' }}
                    visibleFrom="md"
                  >
                    Drag and drop images here
                  </AppText>
                </>
              )}
            </AppStack>
          )}
        </Dropzone.Idle>

        <Dropzone.Reject>
          <AppStack
            style={{
              position: 'absolute',
              border: 'solid 2px #c40000',
              backgroundColor: 'rgba(196, 0, 0, 0.1)',
              borderRadius: 10,
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 9,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AppText
              style={{ textAlign: 'center', fontSize: 14, fontWeight: 500 }}
            >
              File type must be jpg, png, svg, webp, heic
            </AppText>
          </AppStack>
        </Dropzone.Reject>
      </AppStack>

      {enableMediaSelect && (
        <SelectMediaModal
          fkEcomStore={fkEcomStore}
          fkEcomVendor={fkEcomVendor}
          isOpen={showMediaSelector || isOpen}
          maxSelectCount={maxSelectCount}
          newMedia={newFiles}
          onClose={() => {
            setShowMediaSelector(false);
            if (onClose) {
              onClose();
            }
          }}
          onConfirm={(media) => {
            setShowMediaSelector(false);
            onUpload(media);
            if (onClose) {
              onClose();
            }
          }}
          selectedMedia={files}
          skipMediaFetch={skipMediaFetch}
          uploadOnDrop={uploadOnDrop}
        />
      )}
    </Dropzone>
  );
};

MediaDropzone.propTypes = {
  disabled: PropTypes.bool,
  enableMediaSelect: PropTypes.bool,
  files: PropTypes.array,
  fkEcomStore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fkEcomVendor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  maxFileSize: PropTypes.number,
  maxSelectCount: PropTypes.number,
  newFiles: PropTypes.array,
  onChangeOrder: PropTypes.func,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  onSelectFile: PropTypes.func,
  onUpload: PropTypes.func,
  selectedMediaKeys: PropTypes.array,
  showSortGrid: PropTypes.bool,
  skipMediaFetch: PropTypes.bool,
  uploadOnDrop: PropTypes.bool
};

export default MediaDropzone;
