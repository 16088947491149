import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logout, Photo, Users } from 'tabler-icons-react';
import { Image } from '@mantine/core';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import BulkEditView from '../bulkEdit/BulkEditView';
import { BULK_EDIT_FIELD_TYPE_ENUM } from '../../../config/constants';
import AppFlexbox from '../../common/AppFlexbox';

const DATA_FIELDS = {
  name: 'name',
  teamUserName: 'teamUserName'
};

const FIELDS = {
  name: 0,
  teamUserName: 1
};

const EDIT_FIELDS = [
  {
    value: FIELDS.name,
    label: 'Display name',
    dataField: DATA_FIELDS.name,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    displayed: true,
    staticDisplay: true,
    firstPosition: true,
    noBulkEdit: true,
    leftSectionWidth: 70,
    getLeftSection: (altValue) => (
      <AppFlexbox
        style={{
          width: 25,
          height: 25,
          borderRadius: 5,
          boxSizing: 'border-box',
          border: 'solid 1px #dee2e6 ',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {altValue ? (
          <Image src={altValue} style={{ width: '100%', height: '100%' }} />
        ) : (
          <Photo color="#dee2e6" size={14} />
        )}
      </AppFlexbox>
    )
  },
  {
    value: FIELDS.teamUserName,
    label: 'Team player name',
    dataField: DATA_FIELDS.teamUserName,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    displayed: true,

    noBulkEdit: true
  }
];

const mapToForm = (ecomStoreRosterPlayer) => ({
  key: ecomStoreRosterPlayer.pkEcomStoreRosterPlayer.toString(),
  pkEcomStoreRosterPlayer: ecomStoreRosterPlayer.pkEcomStoreRosterPlayer.toString(),
  columns: [
    {
      key: DATA_FIELDS.name,
      value: ecomStoreRosterPlayer.name,
      altValue: ecomStoreRosterPlayer.teamUser?.avatar || ''
    },
    {
      key: DATA_FIELDS.teamUserName,
      value: ecomStoreRosterPlayer.teamUser?.name || '',
      disabled: true
    }
  ]
});

const BulkEditStoreRosterPlayersView = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchStoreRosterPlayers,
    updateStoreRosterPlayers
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({ loading: false });
  const playerIds = new URLSearchParams(search).get('ids')?.split(',') ?? [];
  const selectedPlayers = state.ecomStoreRosterPlayers.value.filter(
    (p) =>
      p.fkEcomStore.toString() === authState.pkEcomStore.toString() &&
      (playerIds.length === 0 ||
        playerIds.includes(p.pkEcomStoreRosterPlayer.toString()))
  );

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchStoreRosterPlayers(
        authState.pkEcomStore,
        { pkEcomStoreRosterPlayers: playerIds },
        null,
        (error) => triggerNotification(error)
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  const onSaveChanges = (data, onSuccess, onErrorCallback) => {
    setFormState({
      ...formState,
      loading: true
    });
    updateStoreRosterPlayers(
      authState.pkEcomStore,
      {
        ecomStoreRosterPlayers: data
          .filter((d) => d.hasUnsavedChanges)
          .map((d) => ({
            pkEcomStoreRosterPlayer: d.pkEcomStoreRosterPlayer,
            name: d.name
          }))
      },
      () => {
        onSuccess();
        triggerNotification('Players updated.', 'success');
        setFormState({
          ...formState,
          loading: false
        });
      },
      (error) => {
        onErrorCallback();
        triggerNotification(error);
        setFormState({
          ...formState,
          loading: false
        });
      }
    );
  };

  return (
    <BulkEditView
      data={selectedPlayers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((p) => mapToForm(p))}
      emptyContent={{
        title: 'No players found',
        description:
          "Oops looks like you haven't selected any players to edit. Try selecting players from your roster and try again.",
        icon: <Users color="#000" size={125} />,
        primaryButton: {
          label: 'Back to players',
          icon: <Logout size={18} style={{ transform: 'rotate(180deg)' }} />,
          link: '/merchant/fundraising'
        }
      }}
      fields={EDIT_FIELDS}
      loading={!hasFetched.current || state.ecomStoreRosterPlayers.loading}
      onCancel={() => {
        navigate('/merchant/fundraising');
      }}
      onSave={onSaveChanges}
      saving={formState.loading}
      title={`Editing ${selectedPlayers.length} players`}
    />
  );
};

BulkEditStoreRosterPlayersView.propTypes = {};

export default BulkEditStoreRosterPlayersView;
