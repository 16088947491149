import React from 'react';
import { AlertCircle, X } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import { ActionIcon, Anchor } from '@mantine/core';
import PropTypes from 'prop-types';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import AppStack from './AppStack';

const AppAlertBanner = ({
  message,
  linkText,
  linkTo,
  onClose,
  backgroundColor,
  textColor
}) => (
  <AppFlexbox
    style={{
      top: 60,
      left: 0,
      position: 'fixed',
      backgroundColor: backgroundColor || '#c40000',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: textColor || '#FFF',
      zIndex: 101,
      padding: '5px 12px'
    }}
  >
    <AppFlexbox style={{ flex: 1 }} visibleFrom="md" />
    <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
      <AppStack>
        <AlertCircle color="#FFF" size={20} style={{ fontWeight: 500 }} />
      </AppStack>
      <AppText style={{ fontWeight: 500 }}>
        {message}{' '}
        <Anchor
          component={Link}
          style={{ color: '#FFF' }}
          to={linkTo}
          underline="always"
        >
          {linkText}
        </Anchor>
      </AppText>
    </AppFlexbox>
    <AppFlexbox
      flex={{ base: 'unset', md: 1 }}
      style={{ justifyContent: 'end' }}
    >
      <ActionIcon color="#FFF" onClick={onClose} size="sm" variant="subtle">
        <X size={14} />
      </ActionIcon>
    </AppFlexbox>
  </AppFlexbox>
);

AppAlertBanner.propTypes = {
  linkText: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string
};

export default AppAlertBanner;
