import {
  ECOM_WEBSTORE_SECTIONS,
  globalDefaultValues as globalDefaultSectionConfig
} from '../components/content/webstore/sections/wsSectionConfig';
import {
  config as wsAppConfig,
  configDefaultValues as defaultWsAppValues
} from '../components/content/webstore/wsAppSettingConfig';
import { ECOM_WEBSTORE_PAGE_CONFIG } from '../components/content/webstore/pages/wsPageConfig';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../components/content/webstore/blocks/sectionBlockConfig';

const defaultColorScheme = {
  key: new Date().getTime(),
  sort: 1,
  backgroundColor: '#FFFFFF',
  backgroundGradient: null,
  textColor: '#000000',
  solidButtonColor: '#000000',
  solidButtonLabel: '#FFFFFF',
  outlineButtonColor: '#000000',
  shadowColor: '#000000'
};

const createEcomStoreThemeSection = (
  existingSections,
  fkEcomStoreThemeSectionType,
  fkEcomStoreThemeSectionLocationType,
  fkEcomStorePageType = null
) => {
  const existingSectionBoundsCount = existingSections.filter(
    (s) =>
      s.fkEcomStoreThemeSectionLocationType ===
        fkEcomStoreThemeSectionLocationType &&
      s.fkEcomStorePageType === fkEcomStorePageType
  ).length;

  const sectionConfig = ECOM_WEBSTORE_SECTIONS[fkEcomStoreThemeSectionType];
  const defaultItemConfigs = sectionConfig.blocks.filter(
    (f) => f.defaultCount !== null && f.defaultCount > 0
  );
  let sortIndex = 1;

  const defaultBlocks = defaultItemConfigs.reduce((r, c) => {
    for (let i = 0; i < c.defaultCount; i += 1) {
      r.push({
        key: new Date().getTime() + sortIndex + i,
        sort: sortIndex,
        fkEcomStoreThemeSectionBlockType: c.type,
        config: { ...c.defaultConfig }
      });
      sortIndex += 1;
    }
    return r;
  }, []);

  const sectionKey = new Date().getTime();
  return {
    key: sectionKey,
    sort: existingSectionBoundsCount + 1,
    fkEcomStoreThemeSectionLocationType,
    fkEcomStoreThemeSectionType,
    ecomStoreThemeSectionBlocks: defaultBlocks,
    fkEcomStorePageType,
    fkEcomStoreThemeTemplate: null,
    hidden: false,
    config: { ...globalDefaultSectionConfig, ...sectionConfig.defaultConfig }
  };
};

const createEcomStoreThemeSectionBlock = (
  section,
  fkEcomStoreThemeSectionBlockType
) => {
  const existingSectionBlockCount = section.ecomStoreThemeSectionBlocks.length;
  const existingItemCount = section.ecomStoreThemeSectionBlocks.filter(
    (f) =>
      f.fkEcomStoreThemeSectionBlockType === fkEcomStoreThemeSectionBlockType
  ).length;

  const sectionConfig =
    ECOM_WEBSTORE_SECTIONS[section.fkEcomStoreThemeSectionType];
  const blockConfig = sectionConfig.blocks.find(
    (c) => c.type === fkEcomStoreThemeSectionBlockType
  );
  if (
    (sectionConfig.maxItemCount == null ||
      existingSectionBlockCount < sectionConfig.maxItemCount) &&
    (blockConfig == null ||
      blockConfig.maxCount == null ||
      existingItemCount < blockConfig.maxCount)
  ) {
    const blockKey = new Date().getTime();
    const sectionBlockConfig = sectionConfig.blocks.find(
      (c) => c.type === fkEcomStoreThemeSectionBlockType
    );

    return {
      key: blockKey,
      sort: existingSectionBlockCount + 1,
      fkEcomStoreThemeSectionBlockType,
      config: {
        ...ECOM_WEBSTORE_SECTION_BLOCKS[fkEcomStoreThemeSectionBlockType]
          .defaultConfig,
        ...sectionBlockConfig?.defaultConfig
      }
    };
  }
  return null;
};

const addSection = (
  ecomStoreThemeSections,
  requiredSection,
  fkEcomStorePageType,
  keyIndex
) => {
  if (
    !ecomStoreThemeSections.find(
      (s) =>
        s.fkEcomStoreThemeSectionType === requiredSection.type &&
        s.fkEcomStorePageType === fkEcomStorePageType
    )
  ) {
    const ecomStoreThemeSection = createEcomStoreThemeSection(
      ecomStoreThemeSections,
      requiredSection.config.type,
      requiredSection.location,
      fkEcomStorePageType
    );
    ecomStoreThemeSection.key = keyIndex;
    ecomStoreThemeSections.push(ecomStoreThemeSection);
  }
};

const getWebstoreThemeConfig = (ecomStoreTheme) => {
  const ecomStoreThemeSections = [...ecomStoreTheme.ecomStoreThemeSections];
  let keyIndex = new Date().getTime();
  wsAppConfig.requiredSections.forEach((requiredSection) => {
    if (
      !ecomStoreThemeSections.some(
        (s) => s.fkEcomStoreThemeSectionType === requiredSection.config.type
      )
    ) {
      addSection(ecomStoreThemeSections, requiredSection, null, keyIndex);
    }
    keyIndex += 1;
  });

  Object.keys(ECOM_WEBSTORE_PAGE_CONFIG).forEach((key) => {
    const pageConfig = ECOM_WEBSTORE_PAGE_CONFIG[key];
    const fkEcomStorePageType = Number(key);

    // no page configured yet. Setup default sections
    if (
      !ecomStoreThemeSections.some(
        (s) => s.fkEcomStorePageType === fkEcomStorePageType
      )
    ) {
      pageConfig.defaultSections.forEach((defaultSection) => {
        addSection(
          ecomStoreThemeSections,
          defaultSection,
          fkEcomStorePageType,
          keyIndex
        );
        keyIndex += 1;
      });
    }

    // ensure all required sections are present on the page
    pageConfig.requiredSections.forEach((requiredSection) => {
      if (
        !ecomStoreThemeSections.some(
          (s) =>
            s.fkEcomStorePageType === fkEcomStorePageType &&
            s.fkEcomStoreThemeSectionType === requiredSection.config.type
        )
      ) {
        addSection(
          ecomStoreThemeSections,
          requiredSection,
          fkEcomStorePageType,
          keyIndex
        );
      }

      keyIndex += 1;
    });
  });

  return {
    logoImageUrl: ecomStoreTheme.logoImageUrl,
    ecomStoreThemeConfig: ecomStoreTheme.ecomStoreThemeProperties.reduce(
      (r, c) => ({
        ...r,
        [c.name]: c.value
      }),
      { ...defaultWsAppValues }
    ),
    ecomStoreThemeColorSchemes:
      ecomStoreTheme.ecomStoreThemeColorSchemes.length > 0
        ? ecomStoreTheme.ecomStoreThemeColorSchemes.map((c) => ({
            ...c,
            key: c.pkEcomStoreThemeColorScheme
          }))
        : [defaultColorScheme],
    ecomStoreThemeSections: ecomStoreThemeSections
      .filter((s) => !!ECOM_WEBSTORE_SECTIONS[s.fkEcomStoreThemeSectionType])
      .map((s) => ({
        ...s,
        key: s.key ?? s.pkEcomStoreThemeSection,
        config:
          s.config ??
          s.ecomStoreThemeSectionProperties.reduce(
            (r, c) => ({
              ...r,
              [c.name]: c.value
            }),
            {
              ...globalDefaultSectionConfig,
              ...ECOM_WEBSTORE_SECTIONS[s.fkEcomStoreThemeSectionType]
                .defaultConfig
            }
          ),
        ecomStoreThemeSectionBlocks: s.ecomStoreThemeSectionBlocks
          .filter(
            (b) =>
              !!ECOM_WEBSTORE_SECTION_BLOCKS[b.fkEcomStoreThemeSectionBlockType]
          )
          .map((i) => {
            const sectionBlockConfig = ECOM_WEBSTORE_SECTIONS[
              s.fkEcomStoreThemeSectionType
            ]?.blocks.find(
              (c) => c.type === i.fkEcomStoreThemeSectionBlockType
            );

            return {
              ...i,
              key: i.key ?? i.pkEcomStoreThemeSectionBlock,
              config:
                i.config ??
                i.ecomStoreThemeSectionBlockProperties.reduce(
                  (r, c) => ({
                    ...r,
                    [c.name]: c.value
                  }),
                  {
                    ...ECOM_WEBSTORE_SECTION_BLOCKS[
                      i.fkEcomStoreThemeSectionBlockType
                    ].defaultConfig,
                    ...sectionBlockConfig?.defaultConfig
                  }
                )
            };
          })
      }))
  };
};

export {
  getWebstoreThemeConfig,
  createEcomStoreThemeSection,
  createEcomStoreThemeSectionBlock
};
