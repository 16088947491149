/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import WsBlock from '../../blocks/WsBlock';
import AppText from '../../../../common/AppText';

const WsMultiRow = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  ecomStoreTheme,
  baseStoreUrl,
  onNavigate,
  ...rest
}) => {
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack style={{ flex: 1, gap: 5 }}>
        <AppText
          style={{ fontWeight: 700, fontSize: config.headingSize * 1 ?? 24 }}
        >
          {config.heading}
        </AppText>
        <AppStack style={{ flex: 1, gap: 5 }}>
          {blocks.map((b, i) => (
            <WsBlock
              key={b.key}
              baseStoreUrl={baseStoreUrl}
              colorScheme={colorScheme}
              ecomStoreTheme={ecomStoreTheme}
              leftSidedImage={i % 2 === 0}
              onNavigate={onNavigate}
              themeConfig={themeConfig}
              {...b}
            />
          ))}
        </AppStack>
      </AppStack>
    </WsSectionContainer>
  );
};

WsMultiRow.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsMultiRow;
