/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Badge, NumberFormatter } from '@mantine/core';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppText from '../../../../common/AppText';

const WsPriceBlock = ({ config, selectedVariant }) =>
  selectedVariant && (
    <AppFlexbox style={{ alignItems: 'end', gap: 10 }}>
      {selectedVariant.compareAtPrice > 0 && (
        <AppText
          style={{
            fontSize: 18,
            fontWeight: 450,
            color: '#999',
            textDecoration: 'line-through'
          }}
        >
          <NumberFormatter
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
            suffix=" CAD"
            thousandSeparator
            value={selectedVariant.compareAtPrice / 100}
          />
        </AppText>
      )}
      <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
        <AppText style={{ fontSize: 22, fontWeight: 450 }}>
          <NumberFormatter
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
            suffix=" CAD"
            thousandSeparator
            value={selectedVariant.price / 100}
          />
        </AppText>
        {selectedVariant.compareAtPrice > 0 && (
          <Badge color="dark" size="lg" variant="filled">
            Sale
          </Badge>
        )}
      </AppFlexbox>
    </AppFlexbox>
  );

WsPriceBlock.propTypes = {
  config: PropTypes.object,
  selectedVariant: PropTypes.object
};

export default WsPriceBlock;
