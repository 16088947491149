import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
// eslint-disable-next-line import/no-cycle
import MediaDropzone from './MediaDropzone';
import AppFlexbox from '../../common/AppFlexbox';
import MediaDisplayCard from './MediaDisplayCard';
import AppText from '../../common/AppText';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppStack from '../../common/AppStack';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const SelectMediaModal = ({
  isOpen,
  onClose,
  onConfirm,
  maxSelectCount,
  selectedMedia,
  newMedia,
  fkEcomVendor,
  fkEcomStore,
  uploadOnDrop,
  skipMediaFetch
}) => {
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = useMediaQueryIndex();
  const { state: vendorState, fetchVendorMedia } = useContext(VendorContext);
  const { state: storeState, fetchStoreMedia } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    search: '',
    newMedia: [],
    selectedMedia: []
  });

  const availableMedia = fkEcomVendor
    ? [
        ...vendorState.ecomVendorMedia.value
          .filter((f) => f.fkEcomVendor.toString() === fkEcomVendor?.toString())
          .map((m) => ({
            ...m,
            key: m.pkEcomVendorMedia,
            preview: m.src
          }))
      ]
    : [
        ...storeState.ecomStoreMedia.value
          .filter((f) => f.fkEcomStore.toString() === fkEcomStore?.toString())
          .map((m) => ({
            ...m,
            key: m.pkEcomStoreMedia,
            preview: m.src
          }))
      ];

  const filteredMedia = [
    ...formState.newMedia.filter(
      (f) =>
        availableMedia.every((s) => s.preview !== f.preview) &&
        selectedMedia.every((s) => s.preview !== f.preview)
    ),
    ...selectedMedia.filter((f) =>
      availableMedia.every((s) => s.preview !== f.preview)
    ),
    ...availableMedia
  ].filter((m) =>
    (m.alt ?? '').toLowerCase().includes(formState.search.toLowerCase())
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        search: '',
        newMedia: newMedia ?? [],
        selectedMedia
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (fkEcomStore && !skipMediaFetch && !storeState.ecomStoreMedia.loading) {
      fetchStoreMedia({ fkEcomStore });
    }
  }, [fkEcomStore]);

  useEffect(() => {
    if (fkEcomVendor && !skipMediaFetch && !vendorState.loading) {
      fetchVendorMedia({ fkEcomVendor });
    }
  }, [fkEcomVendor]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: false,
        onCancel: onClose,
        onSubmit: () => {
          onConfirm([...formState.selectedMedia]);
        },
        submitTitle: 'Done'
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={800}
      title="Select Media"
    >
      <AppStack style={{ gap: 10, minHeight: '60vh' }}>
        <AppFlexbox style={{ justifyContent: 'space-between' }}>
          <TextInput
            onChange={(e) =>
              setFormState({
                ...formState,
                search: e.currentTarget.value
              })
            }
            placeholder="Search files by name"
            style={{ flex: 1, maxWidth: isTabletOrSmaller ? 'unset' : 300 }}
            value={formState.search}
          />
        </AppFlexbox>

        <MediaDropzone
          files={[]}
          fkEcomStore={fkEcomStore}
          fkEcomVendor={fkEcomVendor}
          onUpload={(uploadedMedia) => {
            setFormState({
              ...formState,
              newMedia: [
                ...formState.newMedia,
                ...(!uploadOnDrop
                  ? uploadedMedia.filter((m) =>
                      availableMedia.every((s) => s.preview !== m.preview)
                    )
                  : [])
              ],
              selectedMedia: [
                ...uploadedMedia.filter((m) =>
                  availableMedia.every((s) => s.preview !== m.preview)
                ),
                ...formState.selectedMedia
              ].slice(0, maxSelectCount ?? formState.selectedMedia.length + 1)
            });
          }}
          uploadOnDrop={uploadOnDrop}
        />
        {filteredMedia.length === 0 ? (
          <AppStack
            style={{
              flex: 1,
              border: 'solid 1px #dee2e6',
              borderRadius: 10,
              alignItems: 'center',
              justifyContent: 'center',
              padding: '60px 20px'
            }}
          >
            <AppText style={{ fontSize: 14, color: '#666' }}>
              No media {availableMedia.length === 0 ? 'available' : 'found'}
            </AppText>
            {availableMedia.length > 0 && formState.search && (
              <Button
                color="dark"
                onClick={() => {
                  setFormState({
                    ...formState,
                    search: ''
                  });
                }}
                type="button"
                variant="light"
              >
                Clear Search
              </Button>
            )}
          </AppStack>
        ) : (
          <AppFlexbox
            style={{
              gridTemplateColumns: `repeat(${
                isLargeMobileOrSmaller ? 2 : isTabletOrSmaller ? 3 : 4
              }, 1fr)`,
              gridGap: 10,
              display: 'grid',
              width: '100%'
            }}
          >
            {filteredMedia.map((item) => (
              <MediaDisplayCard
                key={item.key ?? item.preview}
                alt={item.alt}
                alwaysShowCheckbox
                isSelected={formState.selectedMedia.some(
                  (s) => s.preview === item.preview
                )}
                onSelect={() => {
                  const existingItem = formState.selectedMedia.find(
                    (s) => s.preview === item.preview
                  );
                  if (existingItem) {
                    setFormState({
                      ...formState,
                      selectedMedia: [
                        ...formState.selectedMedia.filter(
                          (s) => s.preview !== item.preview
                        )
                      ]
                    });
                  }
                  else {
                    setFormState({
                      ...formState,
                      selectedMedia: [item, ...formState.selectedMedia].slice(
                        0,
                        maxSelectCount ?? formState.selectedMedia.length + 1
                      )
                    });
                  }
                }}
                preview={item.preview}
                style={{ height: 'unset', minHeight: 250, maxHeight: 225 }}
              />
            ))}
          </AppFlexbox>
        )}
      </AppStack>
    </ResponsiveModal>
  );
};

SelectMediaModal.propTypes = {
  fkEcomStore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fkEcomVendor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  maxSelectCount: PropTypes.number,
  newMedia: PropTypes.array,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  selectedMedia: PropTypes.array,
  skipMediaFetch: PropTypes.bool,
  uploadOnDrop: PropTypes.bool
};

export default SelectMediaModal;
