import React from 'react';
import { Alert, Badge, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AlertTriangle } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import { formatUtcDate } from '../../../helpers/format';
import * as classes from '../../../styles/nestedStyles.module.css';

const AdminVendorPaymentProviderInfoCard = ({ ecomVendor }) => {
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();

  return ecomVendor ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Payment provider
              </AppText>
            </AppFlexbox>

            {(!ecomVendor.ecomVendorPaymentProvider ||
              !ecomVendor.ecomVendorPaymentProvider.completed) && (
              <Alert
                color="#c40000"
                icon={<AlertTriangle style={{ height: 24, width: 24 }} />}
                radius={10}
                styles={{
                  body: { gap: 5 },
                  icon: { height: 24, width: 24 },
                  title: { fontSize: 15, fontWeight: 500 }
                }}
                title="Incomplete"
                variant="outline"
              >
                <AppStack>
                  <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                    {!ecomVendor.ecomVendorPaymentProvider
                      ? 'No payment provider connected'
                      : 'Onboarding has been started but is not finished'}
                  </AppText>
                </AppStack>
              </Alert>
            )}

            {ecomVendor.ecomVendorPaymentProvider && (
              <AppCard
                className={classes['hover-action-card']}
                component="a"
                href={`https://dashboard.stripe.com/connect/accounts/${ecomVendor.ecomVendorPaymentProvider.accountId}`}
                radius={8}
                shadow="none"
                style={{
                  padding: 8,
                  textDecoration: 'none',
                  color: '#000'
                }}
                target="_blank"
                withBorder
              >
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Stripe account
                    </AppText>
                    {!ecomVendor.ecomVendorPaymentProvider.completed ? (
                      <Badge color="yellow">Incomplete</Badge>
                    ) : (
                      <Badge color="green">Connected</Badge>
                    )}
                  </AppFlexbox>

                  <AppText style={{ fontSize: 14 }}>
                    {ecomVendor.ecomVendorPaymentProvider.accountId}
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#999' }}>
                    Added{' '}
                    {dayjs(
                      formatUtcDate(
                        ecomVendor.ecomVendorPaymentProvider.createdAt
                      )
                    ).format('MMM D, YYYY')}
                  </AppText>
                </AppStack>
              </AppCard>
            )}
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton height={22} width="40%" />
            </AppFlexbox>

            <AppCard radius={8} shadow="none" style={{ padding: 8 }} withBorder>
              <Skeleton height={65} width="100%" />
            </AppCard>
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

AdminVendorPaymentProviderInfoCard.propTypes = { ecomVendor: PropTypes.object };

export default AdminVendorPaymentProviderInfoCard;
