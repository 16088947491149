import {
  ECOM_PRODUCT_STATUS_ENUM,
  ECOM_SHIPPING_CARRIER_ENUM
} from '../config/constants';

const calculateVendorOrderProductAddonCost = (product) =>
  product.ecomStoreCartProductInputAnswers.reduce((total, answer) => {
    const input = product.ecomVendorProductVariant.ecomVendorProduct.ecomVendorProductInputs.find(
      (i) => i.pkEcomVendorProductInput === answer.fkEcomVendorProductInput
    );

    const optionCost =
      input?.adjustedCost ??
      input?.ecomVendorProductInputOptions.find((v) => v.value === answer.value)
        ?.adjustedCost ??
      0;

    return total + optionCost;
  }, 0);

const generateTrackingLink = (carrier, trackingNumber, customTrackingUrl) => {
  if (carrier === ECOM_SHIPPING_CARRIER_ENUM.OTHER) {
    return customTrackingUrl;
  }

  if (!carrier || !trackingNumber) {
    return null;
  }

  const trackingUrls = {
    [ECOM_SHIPPING_CARRIER_ENUM.CANADA_POST]: `https://www.canadapost-postescanada.ca/track-reperage/en#/details/${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.UPS]: `https://www.ups.com/track?tracknum=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.FEDEX]: `https://www.fedex.com/en-us/tracking.html?tracknumbers=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.PUROLATOR]: `https://www.purolator.com/en/tools/tracking.page?track=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.DHL]: `https://www.dhl.com/global-en/home/tracking.html?trackingId=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.USPS]: `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`
  };

  return trackingUrls[carrier];
};

const translateOrderProductData = (p) => {
  const addonCost = calculateVendorOrderProductAddonCost(p);
  return {
    key: p.pkEcomStoreCartProduct,
    pkEcomStoreCartProduct: p.pkEcomStoreCartProduct,
    to: `/vendor/products/${p.ecomVendorProductVariant.ecomVendorProduct.uuid}/variants/${p.ecomVendorProductVariant.uuid}`,
    previewImage:
      p.ecomVendorProductVariant.previewImage ??
      p.ecomVendorProductVariant.ecomVendorProduct.previewImage,
    name: p.ecomVendorProductVariant.ecomVendorProduct.name,
    sku: p.ecomVendorProductVariant.sku,
    barcode: p.ecomVendorProductVariant.barcode,
    quantity: p.count,
    unitCost: p.ecomVendorProductVariant.price + addonCost,
    addOns: [
      ...p.ecomVendorProductVariant.ecomVendorProductVariantOptions
        .sort((a, b) => a.nameSort - b.nameSort)
        .map((op) => ({
          sort: op.nameSort,
          name: op.name,
          value: op.value
        })),
      ...p.ecomStoreCartProductInputAnswers
        .map((a) => {
          const input = p.ecomVendorProductVariant.ecomVendorProduct.ecomVendorProductInputs.find(
            (i) => i.pkEcomVendorProductInput === a.fkEcomVendorProductInput
          );
          return {
            sort: input?.sort,
            name: input?.label,
            value: a.value
          };
        })
        .sort((a, b) => a.sort - b.sort)
    ]
  };
};

const getEcomStoreProductStatus = (product) =>
  product.ecomVendorProduct?.deleted ||
  product.ecomVendorProduct.fkEcomProductStatus.toString() !==
    ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Not available',
        color: 'red'
      }
    : product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Live',
        color: 'green'
      }
    : product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.DRAFT
    ? {
        label: 'Draft',
        color: 'darkgrey'
      }
    : {
        label: 'Pending action',
        color: 'blue'
      };

const getEcomVendorProductStatus = (product) =>
  product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Active',
        color: 'green'
      }
    : product.fkEcomProductStatus.toString() ===
      ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
    ? {
        label: 'Archived',
        color: 'grey'
      }
    : {
        label: 'Draft',
        color: 'grey'
      };

export {
  calculateVendorOrderProductAddonCost,
  generateTrackingLink,
  translateOrderProductData,
  getEcomStoreProductStatus,
  getEcomVendorProductStatus
};
