import React, { useState } from 'react';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCenter,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import PropTypes from 'prop-types';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import AppStack from './AppStack';
import SortableListItem from './SortableListItem';

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: { active: { opacity: '0.4' } }
  })
};

const SortableList = ({ items, onChange, renderItem, listProps }) => {
  const [activeSort, setActiveSort] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 5 } })
  );

  const sortableItems = [
    ...items.map((f) => ({
      ...f,
      id: f.key
    }))
  ].sort((a, b) => a.sort - b.sort);

  const handleDragEnd = ({ active, over }) => {
    if (active && over && active.id !== over.id) {
      const oldIndex = sortableItems.findIndex((f) => f.key === active.id);
      const newIndex = sortableItems.findIndex((f) => f.key === over.id);

      onChange(arrayMove(sortableItems, oldIndex, newIndex));
    }
    setActiveSort(null);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragCancel={() => {
        setActiveSort(null);
      }}
      onDragEnd={handleDragEnd}
      onDragStart={({ active }) => {
        setActiveSort(active);
      }}
      sensors={sensors}
    >
      <SortableContext
        items={sortableItems}
        strategy={verticalListSortingStrategy}
      >
        <AppStack
          {...listProps}
          style={{
            gap: 0,
            border: activeSort ? 'dashed 2px dodgerblue' : 'none',
            ...listProps?.style
          }}
        >
          {sortableItems.map((item, index) => (
            <SortableListItem
              key={item.id}
              id={item.id}
              item={item}
              renderItem={(i, props) => renderItem(i, props, index)}
            />
          ))}
        </AppStack>
      </SortableContext>

      <DragOverlay dropAnimation={dropAnimationConfig}>
        {activeSort ? (
          <SortableListItem
            key={activeSort.id}
            id={activeSort.id}
            isOverlay
            item={sortableItems.find((f) => f.id === activeSort.id)}
            renderItem={(i, props) => renderItem(i, props, 0)}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

SortableList.propTypes = {
  items: PropTypes.array,
  listProps: PropTypes.object,
  onChange: PropTypes.func,
  renderItem: PropTypes.func
};

export default SortableList;
