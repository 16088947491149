import React from 'react';
import { PropTypes } from 'prop-types';
import { Check } from 'tabler-icons-react';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import AppImage from './AppImage';

const CustomSelectItem = ({
  image,
  color,
  label,
  description,
  noWrap,
  forceImage,
  checked,
  ...rest
}) => (
  <AppFlexbox style={{ gap: 10, alignItems: 'center' }} {...rest}>
    {checked && (
      <AppStack>
        <Check color="#999" size={16} style={{ strokeWidth: 3 }} />
      </AppStack>
    )}

    {(forceImage || image) && <AppImage height={30} src={image} width={30} />}

    <AppFlexbox style={{ gap: 10, alignItems: 'center', minWidth: 0 }}>
      {color && (
        <AppText
          style={{
            width: 15,
            height: 15,
            borderRadius: 15,
            backgroundColor: color,
            border: color === 'white' ? 'solid 1px black' : 'none'
          }}
        />
      )}
      <AppStack
        style={{
          gap: 0,
          minWidth: 0,
          justifyContent: 'center',
          overflow: 'hidden'
        }}
      >
        <AppText size="sm" style={{ whiteSpace: noWrap ? 'nowrap' : 'normal' }}>
          {label}
        </AppText>
        {description && <AppText size="xs">{description}</AppText>}
      </AppStack>
    </AppFlexbox>
  </AppFlexbox>
);

CustomSelectItem.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  description: PropTypes.string,
  forceImage: PropTypes.bool,
  image: PropTypes.string,
  label: PropTypes.string,
  noWrap: PropTypes.bool
};

export default CustomSelectItem;
