/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';

const WsBlogPosts = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  ...rest
}) => {
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack />
    </WsSectionContainer>
  );
};

WsBlogPosts.propTypes = {
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  mediaQueries: PropTypes.object,
  themeConfig: PropTypes.object
};

export default WsBlogPosts;
