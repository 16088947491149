import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Avatar,
  Checkbox,
  Image,
  Tabs,
  TextInput
} from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import CustomNavLink from '../../common/CustomNavLink';
import { getInitialsFromName } from '../../../helpers/format';
import SettingSelector from '../../common/SettingSelector';

const SelectStoreTeamModal = ({
  isOpen,
  onClose,
  onConfirm,
  onRemove,
  team,
  association,
  isAssociationSelect
}) => {
  const hasFetched = useRef(false);
  const { state: authState, fetchAssociatedTeamsAndAssociations } = useContext(
    AuthContext
  );
  const { state } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    selectedTeam: null,
    overrideColors: false,
    search: '',
    activeTab: 'teams'
  });
  const loading = !hasFetched.current || authState.userAssociations.loading;

  const teams = authState.userAssociations.value
    ? [...authState.userAssociations.value.teams]
        .reduce(
          (r, c) => (r.find((t) => t.pkTeam === c.pkTeam) ? r : [...r, c]),
          []
        )
        .filter(
          (t) =>
            !formState.search ||
            t.name.toLowerCase().includes(formState.search.toLowerCase())
        )
    : [];
  const associationTeams = authState.userAssociations.value
    ? [
        ...authState.userAssociations.value.regAssociations
          .map((a) => a.association.teams)
          .flat()
      ]
        .reduce(
          (r, c) => (r.find((t) => t.pkTeam === c.pkTeam) ? r : [...r, c]),
          []
        )
        .filter(
          (t) =>
            !formState.search ||
            t.name.toLowerCase().includes(formState.search.toLowerCase())
        )
    : [];

  const regAssociations = authState.userAssociations.value
    ? [
        ...authState.userAssociations.value.regAssociations,
        ...authState.userAssociations.value.teams
          .map((t) => t.association.regAssociations)
          .flat()
      ].reduce(
        (r, c) =>
          r.find((a) => a.pkRegAssociation === c.pkRegAssociation)
            ? r
            : [...r, c],
        []
      )
    : [];

  const associations = authState.userAssociations.value
    ? [
        ...authState.userAssociations.value.regAssociations.map(
          (t) => t.association
        ),
        ...authState.userAssociations.value.teams.map((t) => t.association)
      ]
        .reduce(
          (r, c) =>
            r.find((a) => a.pkAssociation === c.pkAssociation) ? r : [...r, c],
          []
        )
        .filter(
          (t) =>
            !formState.search ||
            t.name.toLowerCase().includes(formState.search.toLowerCase())
        )
    : [];

  const showTeamTabs =
    authState.userAssociations.value &&
    !!authState.userAssociations.value?.teams.length &&
    !!authState.userAssociations.value?.regAssociations.some(
      (a) => !!a.association.teams.length
    );

  const teamRegAssociation = regAssociations.find(
    (a) =>
      a.association.pkAssociation ===
      (
        formState.selectedAssociation ??
        formState.selectedTeam?.association ??
        null
      )?.pkAssociation
  );

  useEffect(() => {
    if (isOpen) {
      if (!loading) {
        setFormState({
          selectedTeam: null,
          selectedAssociation: null,
          overrideColors: false,
          search: '',
          activeTab: teams.length ? 'teams' : 'association'
        });
      }

      if (!hasFetched.current) {
        fetchAssociatedTeamsAndAssociations();
        hasFetched.current = true;
      }
    }
  }, [isOpen, loading]);

  const onSelectTeam = (selectedTeam) => {
    setFormState({ ...formState, selectedTeam });
  };

  const onSelectAssociation = (selectedAssociation) => {
    setFormState({ ...formState, selectedAssociation });
  };

  return (
    <ResponsiveModal
      formSectionProps={
        formState.selectedAssociation
          ? {
              cancelTitle: 'Back',
              onCancel: () =>
                setFormState({
                  ...formState,
                  selectedAssociation: null,
                  overrideColors: false
                }),
              onSubmit: () => {
                onConfirm(
                  formState.selectedAssociation,
                  formState.overrideColors ? teamRegAssociation : null
                );
              },
              submitTitle: 'Confirm association'
            }
          : formState.selectedTeam
          ? {
              cancelTitle: 'Back',
              onCancel: () =>
                setFormState({
                  ...formState,
                  selectedTeam: null,
                  overrideColors: false
                }),
              onSubmit: () => {
                onConfirm(
                  formState.selectedTeam,
                  formState.overrideColors ? teamRegAssociation : null
                );
              },
              submitTitle: 'Confirm team'
            }
          : {
              onCancel: onClose,
              isSubmitHidden: true,
              leftSection:
                isAssociationSelect && association ? (
                  <Anchor
                    onClick={onRemove}
                    style={{ color: '#c40000', fontSize: 14 }}
                  >
                    Remove association
                  </Anchor>
                ) : (
                  team && (
                    <Anchor
                      onClick={onRemove}
                      style={{ color: '#c40000', fontSize: 14 }}
                    >
                      Remove team
                    </Anchor>
                  )
                )
            }
      }
      isLoading={loading}
      isOpen={isOpen}
      onClose={onClose}
      title={
        isAssociationSelect ? 'Select store association' : 'Select store team'
      }
    >
      {formState.selectedTeam || formState.selectedAssociation ? (
        <AppStack>
          {formState.selectedTeam && (
            <AppStack style={{ gap: 5 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>Team</AppText>
              <SettingSelector
                leftSection={
                  <Image
                    fit="contain"
                    h="100%"
                    src={formState.selectedTeam.image}
                    w="100%"
                  />
                }
                rightSection={<></>}
                title={formState.selectedTeam.name}
                withBorder
              />
            </AppStack>
          )}

          <AppStack style={{ gap: 5 }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Association
            </AppText>
            <SettingSelector
              description={
                (
                  formState.selectedAssociation ??
                  formState.selectedTeam.association
                ).domain
              }
              leftSection={
                <Image
                  fit="contain"
                  h="100%"
                  src={
                    (
                      formState.selectedAssociation ??
                      formState.selectedTeam.association
                    ).image
                  }
                  w="100%"
                />
              }
              rightSection={<></>}
              title={
                (
                  formState.selectedAssociation ??
                  formState.selectedTeam.association
                ).name
              }
              withBorder
            />
          </AppStack>

          <Checkbox
            checked={formState.overrideColors}
            disabled={!teamRegAssociation}
            error={
              !teamRegAssociation
                ? 'No default branding available for this association.'
                : null
            }
            label="Override branding with association colors"
            onChange={() =>
              setFormState({
                ...formState,
                overrideColors: !formState.overrideColors
              })
            }
            style={{ fontWeight: 500 }}
          />
        </AppStack>
      ) : isAssociationSelect ? (
        <AppStack style={{ gap: 10 }}>
          <AppStack style={{ gap: 0 }}>
            <TextInput
              onChange={(e) =>
                setFormState({
                  ...formState,
                  search: e.currentTarget.value
                })
              }
              placeholder="Search associations by name"
              size="sm"
              style={{ marginTop: 10 }}
              value={formState.search}
            />
          </AppStack>
          <AppStack
            style={{
              flex: 1,
              border: 'solid 1px lightgrey',
              padding: 5,
              borderRadius: 5,
              height: '100%',
              overflow: 'hidden',
              gap: 3
            }}
          >
            {associations.length === 0 ? (
              <AppStack
                style={{
                  padding: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  gap: 5
                }}
              >
                <AlertCircle color="#000" size={30} />
                <AppText style={{ fontSize: 16, color: '#000' }}>
                  No associations found
                </AppText>
              </AppStack>
            ) : (
              associations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((a) => (
                  <CustomNavLink
                    key={a.pkAssociation}
                    description={a.domain}
                    label={a.name}
                    leftSection={
                      <Avatar color="dark" radius="xl" size="md" src={a?.image}>
                        <AppText style={{ fontWeight: 500 }}>
                          {getInitialsFromName(a.name)}
                        </AppText>
                      </Avatar>
                    }
                    onClick={() => onSelectAssociation(a)}
                  />
                ))
            )}
          </AppStack>
        </AppStack>
      ) : (
        <AppStack style={{ gap: 10 }}>
          <AppStack style={{ gap: 0 }}>
            {showTeamTabs && (
              <Tabs
                onChange={(activeTab) =>
                  setFormState({ ...formState, activeTab })
                }
                value={formState.activeTab}
              >
                <Tabs.List>
                  <Tabs.Tab color="dark" value="teams">
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight:
                          formState.activeTab === 'teams' ? 500 : 'normal'
                      }}
                    >
                      Your teams
                    </AppText>
                  </Tabs.Tab>
                  <Tabs.Tab color="dark" value="association">
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight:
                          formState.activeTab === 'association' ? 500 : 'normal'
                      }}
                    >
                      Association teams
                    </AppText>
                  </Tabs.Tab>
                </Tabs.List>
              </Tabs>
            )}

            <TextInput
              onChange={(e) =>
                setFormState({
                  ...formState,
                  search: e.currentTarget.value
                })
              }
              placeholder="Search teams by name"
              size="sm"
              style={{ marginTop: 10 }}
              value={formState.search}
            />
          </AppStack>
          <AppStack
            style={{
              flex: 1,
              border: 'solid 1px lightgrey',
              padding: 5,
              borderRadius: 5,
              height: '100%',
              overflow: 'hidden',
              gap: 3
            }}
          >
            {(!showTeamTabs || formState.activeTab === 'teams') &&
              (teams.length === 0 && associationTeams.length === 0 ? (
                <AppStack
                  style={{
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    gap: 5
                  }}
                >
                  <AlertCircle color="#000" size={30} />
                  <AppText style={{ fontSize: 16, color: '#000' }}>
                    No teams found
                  </AppText>
                </AppStack>
              ) : (
                teams
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((t) => (
                    <CustomNavLink
                      key={t.pkTeam}
                      description={t.association.name}
                      label={t.name}
                      leftSection={
                        <Avatar
                          color="dark"
                          radius="xl"
                          size="md"
                          src={t.image ?? t.association?.image}
                        >
                          <AppText style={{ fontWeight: 500 }}>
                            {getInitialsFromName(t.name)}
                          </AppText>
                        </Avatar>
                      }
                      onClick={() => onSelectTeam(t)}
                    />
                  ))
              ))}

            {(!showTeamTabs || formState.activeTab === 'association') &&
              (associationTeams.length === 0 && showTeamTabs ? (
                <AppStack
                  style={{
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  <AlertCircle color="#000" size={30} />
                  <AppText style={{ fontSize: 16, color: '#000' }}>
                    No teams found
                  </AppText>
                </AppStack>
              ) : (
                associationTeams
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((t) => (
                    <CustomNavLink
                      key={t.pkTeam}
                      description={t.association.name}
                      label={t.name}
                      leftSection={
                        <Avatar
                          color="dark"
                          radius="xl"
                          size="md"
                          src={t.image ?? t.association?.image}
                        >
                          <AppText style={{ fontWeight: 500 }}>
                            {getInitialsFromName(t.name)}
                          </AppText>
                        </Avatar>
                      }
                      onClick={() => onSelectTeam(t)}
                    />
                  ))
              ))}
          </AppStack>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

SelectStoreTeamModal.propTypes = {
  association: PropTypes.object,
  isAssociationSelect: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onRemove: PropTypes.func,
  team: PropTypes.object
};

export default SelectStoreTeamModal;
