import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { triggerNotification } from '../../../helpers/notification';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import StoreCollectionsTable from './StoreCollectionsTable';
import StoreCollectionEditView from './StoreCollectionEditView';

const StoreCollectionsView = () => {
  const { state: authState } = useContext(AuthContext);
  const { fetchStoreProductOptions } = useContext(StoreContext);

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchStoreProductOptions(authState.pkEcomStore, {}, null, (error) => {
        triggerNotification(error);
      });
    }
  }, [authState.pkEcomStore]);

  return (
    <Routes>
      <Route element={<StoreCollectionEditView />} path="/new" />
      <Route
        element={<StoreCollectionEditView />}
        path="/:pkEcomStoreCollection"
      />
      <Route
        element={<StoreCollectionEditView duplicate />}
        path="/:pkEcomStoreCollection/duplicate"
      />
      <Route element={<StoreCollectionsTable />} path="/" />
      <Route
        element={<Navigate replace to="/merchant/collections" />}
        path="*"
      />
    </Routes>
  );
};

export default StoreCollectionsView;
