/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@mantine/core';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import AppStack from './AppStack';
import FileUpload from './FileUpload';
import { fileToBase64String } from '../../helpers/awsHelper';
import { triggerNotification } from '../../helpers/notification';
import MediaDropzone from '../content/media/MediaDropzone';

const CustomerFileInput = ({
  label,
  description,
  onChange,
  required,
  value,
  disabled,
  pkEcomStore
}) => {
  const [imagePreview, setImagePreview] = useState(null);

  return (
    <AppStack style={{ gap: 5 }}>
      <TextInput
        description={description}
        label={label}
        styles={{ input: { display: 'none' } }}
        withAsterisk={required}
      />

      {!value && pkEcomStore ? (
        <MediaDropzone
          disabled={disabled}
          enableMediaSelect
          files={[]}
          fkEcomStore={pkEcomStore}
          height={100}
          maxSelectCount={1}
          onUpload={(media) => {
            onChange(media[0]?.src);
          }}
          uploadOnDrop
        />
      ) : (
        <FileUpload
          accept={IMAGE_MIME_TYPE}
          disabled={disabled}
          height={100}
          hideChangeButton={!!pkEcomStore}
          imagePreview={imagePreview || value}
          onError={(e) => {
            triggerNotification(e);
          }}
          onRemove={() => {
            onChange(null);
            setImagePreview(null);
          }}
          onUpload={(files) => {
            onChange(files[0], 'file');
            fileToBase64String(files[0], setImagePreview);
          }}
          value={value}
          variant="image"
        />
      )}
    </AppStack>
  );
};

CustomerFileInput.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  isStoreAdminViewing: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  pkEcomStore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  value: PropTypes.string
};

export default CustomerFileInput;
