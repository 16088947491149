/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Anchor,
  Image,
  Loader,
  NumberFormatter,
  NumberInput
} from '@mantine/core';
import { AlertCircle, X } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import { isVariantUnavailable } from '../../../helpers/webstoreHelper';
import ProductLineItemAddonsDisplay from '../products/ProductLineItemAddonsDisplay';

const CartDrawerLineItem = ({
  baseStoreUrl,
  onNavigate,
  lineItem,
  disabled,
  onRemove,
  onUpdate,
  onCustomize,
  latestVersionEcomStoreCartProduct,
  onRemoveUnavailableProducts,
  ecomStoreCartProduct
}) => {
  const [debouncedQuantity, setDebouncedQuantity] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [removing, setRemoving] = useState(false);

  const isProductUnavailable =
    latestVersionEcomStoreCartProduct &&
    isVariantUnavailable(latestVersionEcomStoreCartProduct);

  const quantityLeft = ecomStoreCartProduct
    ? ecomStoreCartProduct.ecomStoreProductVariant.ecomVendorProductVariant
        .quantity
    : null;
  const outOfStock = quantityLeft != null && quantityLeft <= 0;
  const tooManyInCart =
    quantityLeft != null && quantityLeft - lineItem.quantity < 0;

  useEffect(() => {
    if (lineItem) {
      setQuantity(lineItem.quantity);
    }
  }, [lineItem]);

  useEffect(() => {
    if (debouncedQuantity !== null) {
      if (debouncedQuantity !== lineItem.quantity) {
        onUpdate([
          {
            pkEcomStoreCartProduct: lineItem.entityId,
            uuid:
              ecomStoreCartProduct.ecomStoreProductVariant
                .ecomVendorProductVariant.uuid,
            count: debouncedQuantity
          }
        ]);
      }
    }
  }, [debouncedQuantity]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuantity(quantity);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [quantity]);

  return (
    <AppStack>
      {latestVersionEcomStoreCartProduct ? (
        <AppFlexbox
          style={{
            padding: 10,
            backgroundColor: isProductUnavailable ? '#c40000' : '#eca70a',
            color: '#FFF',
            alignItems: 'center',
            gap: 8
          }}
        >
          <AlertCircle size={18} />{' '}
          {isProductUnavailable ? (
            <AppText style={{ fontSize: 14 }}>Product unavailable</AppText>
          ) : (
            <AppText style={{ fontSize: 14 }}>Product changed</AppText>
          )}
          {!disabled && (
            <Anchor
              onClick={onRemoveUnavailableProducts}
              style={{ marginLeft: 'auto', color: '#FFF', fontSize: 14 }}
              underline="always"
            >
              Update cart
            </Anchor>
          )}
        </AppFlexbox>
      ) : (
        quantityLeft != null &&
        (outOfStock ? (
          <AppFlexbox
            style={{
              padding: 10,
              alignItems: 'center',
              backgroundColor: '#c40000',
              color: '#FFF',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14 }}>Out of stock</AppText>
            {!disabled && (
              <Anchor
                onClick={onRemoveUnavailableProducts}
                style={{ marginLeft: 'auto', fontSize: 14, color: '#FFF' }}
                underline="always"
              >
                Update cart
              </Anchor>
            )}
          </AppFlexbox>
        ) : tooManyInCart ? (
          <AppFlexbox
            style={{
              padding: 10,
              alignItems: 'center',
              backgroundColor: '#c40000',
              color: '#FFF',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14 }}>
              Only {quantityLeft} left in stock
            </AppText>
            {!disabled && (
              <Anchor
                onClick={onRemoveUnavailableProducts}
                style={{ marginLeft: 'auto', fontSize: 14, color: '#FFF' }}
                underline="always"
              >
                Update cart
              </Anchor>
            )}
          </AppFlexbox>
        ) : (
          quantityLeft <= 25 && (
            <AppFlexbox
              style={{
                padding: 10,
                alignItems: 'center',
                backgroundColor: '#eca70a',
                color: '#FFF',
                gap: 8
              }}
            >
              <AppText style={{ fontSize: 14 }}>
                Low stock: {quantityLeft} left
              </AppText>
            </AppFlexbox>
          )
        ))
      )}

      <AppFlexbox>
        <AppStack
          component={Link}
          onClick={onNavigate}
          style={{ width: 80, height: 80 }}
          to={`${baseStoreUrl}/products/${lineItem?.urlHandle}`}
        >
          <Image
            fit="contain"
            src={lineItem.previewImage}
            style={{ height: '100%', width: '100%' }}
          />
        </AppStack>
        <AppStack style={{ flex: 1, gap: 0 }}>
          <AppFlexbox
            style={{ alignItems: 'start', justifyContent: 'space-between' }}
          >
            <AppStack style={{ flex: 1, gap: 0 }}>
              <AppText
                component={Link}
                onClick={onNavigate}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: '#000',
                  textDecoration: 'none'
                }}
                to={`${baseStoreUrl}/products/${lineItem?.urlHandle}`}
              >
                {lineItem.productName}
              </AppText>
              <ProductLineItemAddonsDisplay addOns={lineItem.addOns} />
            </AppStack>
            {removing ? (
              <Loader color="dark" size="18" />
            ) : (
              <ActionIcon
                color="#c40000"
                disabled={disabled}
                onClick={() => {
                  setRemoving(true);
                  onRemove(
                    [{ pkEcomStoreCartProduct: lineItem.entityId }],
                    () => {
                      setRemoving(false);
                    },
                    () => {
                      setRemoving(false);
                    }
                  );
                }}
                size="sm"
                style={{ marginTop: 3 }}
                variant="subtle"
              >
                <X />
              </ActionIcon>
            )}
          </AppFlexbox>
          <AppFlexbox
            style={{
              flex: 1,
              alignItems: 'end',
              justifyContent: 'space-between',
              marginTop: 5
            }}
          >
            <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
              <NumberInput
                allowNegative={false}
                disabled={
                  outOfStock ||
                  !!latestVersionEcomStoreCartProduct ||
                  disabled ||
                  removing
                }
                min={1}
                onChange={(v) => {
                  let newQuantity =
                    quantityLeft !== null && v > quantityLeft
                      ? quantityLeft
                      : v;
                  if (newQuantity <= 0) {
                    newQuantity = 1;
                  }
                  setQuantity(newQuantity);
                }}
                size="xs"
                style={{ flex: 1, maxWidth: 80 }}
                value={quantity}
              />
              {!latestVersionEcomStoreCartProduct && lineItem.isCustomizable && (
                <Anchor
                  onClick={onCustomize}
                  style={{ fontSize: 14, color: 'dodgerblue' }}
                  underline="always"
                >
                  Customize
                </Anchor>
              )}
            </AppFlexbox>

            <AppText style={{ fontSize: 18, fontWeight: 500 }}>
              <NumberFormatter
                decimalScale={2}
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                value={(lineItem.totalInCents * lineItem.quantity) / 100}
              />
            </AppText>
          </AppFlexbox>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

CartDrawerLineItem.propTypes = {
  baseStoreUrl: PropTypes.string,
  disabled: PropTypes.bool,
  ecomStoreCartProduct: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  latestVersionEcomStoreCartProduct: PropTypes.object,
  lineItem: PropTypes.object,
  onCustomize: PropTypes.func,
  onNavigate: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveUnavailableProducts: PropTypes.func,
  onUpdate: PropTypes.func
};

export default CartDrawerLineItem;
