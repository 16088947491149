import { BoxMultiple } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  ECOM_ENTITY_TYPES,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';
import WsLayeredBanner from './WsLayeredBanner';

const IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM = {
  TOP_LEFT: 'start-start',
  TOP_CENTER: 'start-center',
  TOP_RIGHT: 'start-end',
  CENTER_LEFT: 'center-start',
  CENTER: 'center-center',
  CENTER_RIGHT: 'center-end',
  BOTTOM_LEFT: 'end-start',
  BOTTOM_CENTER: 'end-center',
  BOTTOM_RIGHT: 'end-end'
};

const LAYERED_OFFSET_UNIT_ENUM = {
  PIXEL: 'px',
  PERCENTAGE: '%'
};

const IMAGE_HEIGHT_ENUM = {
  ADAPT_TO_IMAGE: 'adapt-to-first-image',
  EXTRA_SMALL: 'extra_small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  CUSTOM: 'custom'
};

const IMAGE_POSITION_ENUM = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom'
};

const CONTENT_PADDING_ENUM = {
  NONE: 'none',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large'
};

const configNameValues = {
  image: 'image',
  imageOverlayOpacity: 'imageOverlayOpacity',
  imageOverlayColor: 'imageOverlayColor',
  imageHeight: 'imageHeight',
  imagePosition: 'imagePosition',
  customImageHeight: 'customImageHeight',
  adaptImageHeight: 'adaptImageHeight',
  adaptImageWidth: 'adaptImageWidth',
  restrictBackgroundImage: 'restrictBackgroundImage',
  restrictBackgroundColor: 'restrictBackgroundColor',
  restrictOverlay: 'restrictOverlay',
  imagesBehindOverlay: 'imagesBehindOverlay',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  [configNameValues.image]: '',
  [configNameValues.colorScheme]: '1',
  [configNameValues.imageOverlayOpacity]: '0',
  [configNameValues.imageOverlayColor]: '',
  [configNameValues.imageHeight]: IMAGE_HEIGHT_ENUM.SMALL,
  [configNameValues.imagePosition]: IMAGE_POSITION_ENUM.CENTER,
  [configNameValues.customImageHeight]: '450',
  [configNameValues.adaptImageHeight]: '',
  [configNameValues.adaptImageWidth]: '',
  [configNameValues.restrictBackgroundImage]: 'false',
  [configNameValues.restrictBackgroundColor]: 'false',
  [configNameValues.restrictOverlay]: 'false',
  [configNameValues.imagesBehindOverlay]: 'true',
  fullWidth: 'true',
  topPadding: '0',
  bottomPadding: '0'
};

const configOptions = {
  [configNameValues.image]: {
    label: 'Background image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  },
  [configNameValues.imagesBehindOverlay]: {
    label: 'Place images behind overlay',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.imageOverlayOpacity]: {
    label: 'Background overlay opacity',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 0,
    max: 100,
    unit: '%'
  },
  [configNameValues.imageOverlayColor]: {
    label: 'Background overlay color',
    type: INPUT_CONTROL_TYPE_ENUM.COLOR
  },
  [configNameValues.imageHeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Background height',
    description:
      "The height of the image container. If you're using multiple images, the height will be the same for all images.",
    options: [
      {
        value: IMAGE_HEIGHT_ENUM.ADAPT_TO_IMAGE,
        label: 'Adapt to first image'
      },
      { value: IMAGE_HEIGHT_ENUM.EXTRA_SMALL, label: 'Extra small (300px)' },
      { value: IMAGE_HEIGHT_ENUM.SMALL, label: 'Small (450px)' },
      { value: IMAGE_HEIGHT_ENUM.MEDIUM, label: 'Medium (600px)' },
      { value: IMAGE_HEIGHT_ENUM.LARGE, label: 'Large (750px)' },
      { value: IMAGE_HEIGHT_ENUM.CUSTOM, label: 'Custom' }
    ]
  },
  [configNameValues.customImageHeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    label: 'Custom height',
    min: 200,
    max: 1200,
    unit: 'px',
    isVisible: (config) => config.imageHeight === IMAGE_HEIGHT_ENUM.CUSTOM
  },
  [configNameValues.imagePosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Image position',
    options: [
      { value: IMAGE_POSITION_ENUM.TOP, label: 'Top' },
      { value: IMAGE_POSITION_ENUM.CENTER, label: 'Center' },
      { value: IMAGE_POSITION_ENUM.BOTTOM, label: 'Bottom' }
    ]
  },
  [configNameValues.restrictBackgroundImage]: {
    label: 'Restrict background image to page size',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.restrictOverlay]: {
    label: 'Restrict overlay to page size',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.restrictBackgroundColor]: {
    label: 'Restrict background color to page size',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const defaultBlockValues = {
  layeredRestrictPageWidth: 'false',
  layeredContentPosition: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER
};

const defaultBlockPositionValues = {
  layeredSize: '75',
  layeredOffsetUnit: LAYERED_OFFSET_UNIT_ENUM.PERCENTAGE,
  layeredYOffset: '0',
  layeredXOffset: '0'
};

const defaultBlockConfigOptions = {
  layeredRestrictPageWidth: {
    label: 'Restrict to page width',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Layered position'
  },
  layeredContentPosition: {
    label: 'Position',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    category: 'Layered position',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_LEFT,
        label: 'Top left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_CENTER,
        label: 'Top center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_RIGHT,
        label: 'Top right'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER_LEFT,
        label: 'Middle left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER,
        label: 'Middle center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER_RIGHT,
        label: 'Middle right'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_LEFT,
        label: 'Bottom left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_CENTER,
        label: 'Bottom center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_RIGHT,
        label: 'Bottom right'
      }
    ]
  }
};

const blockPositionConfigOptions = {
  layeredSize: {
    label: 'Size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    category: 'Layered position',
    max: 100,
    min: 0,
    unit: '%'
  },
  layeredOffsetUnit: {
    label: 'Offset unit',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    category: 'Layered position',
    options: [
      {
        value: LAYERED_OFFSET_UNIT_ENUM.PERCENTAGE,
        label: 'Percentage'
      },
      {
        value: LAYERED_OFFSET_UNIT_ENUM.PIXEL,
        label: 'Pixel'
      }
    ]
  },
  layeredYOffset: {
    label: 'Vertical offset',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    category: 'Layered position',
    max: 100,
    min: -100,
    unit: '%',
    getValueProps: (config) =>
      config.layeredOffsetUnit === LAYERED_OFFSET_UNIT_ENUM.PIXEL
        ? {
            max: 1000,
            min: -1000,
            unit: 'px'
          }
        : null
  },
  layeredXOffset: {
    label: 'Horizontal offset',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    category: 'Layered position',
    max: 100,
    min: -100,
    unit: '%',
    getValueProps: (config) =>
      config.layeredOffsetUnit === LAYERED_OFFSET_UNIT_ENUM.PIXEL
        ? {
            max: 1000,
            min: -1000,
            unit: 'px'
          }
        : null
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.LAYERED_BANNER,
  title: 'Layered banner',
  description:
    'A background image with layered content. Only the first image will be displayed on mobile.',
  icon: BoxMultiple,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsLayeredBanner,
  configOptions,
  maxItemCount: null,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE
      ],
      configOptions: {
        ...defaultBlockConfigOptions,
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE
        ].configOptions,
        ...blockPositionConfigOptions
      },
      defaultConfig: {
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE
        ].configDefaultValues,
        ...defaultBlockValues,
        ...defaultBlockPositionValues
      },
      defaultCount: 1,
      maxCount: null,
      getTitle: (configValues, index) => {
        const contentPosition = configValues.layeredContentPosition.replace(
          '-',
          ' '
        );
        if (index === -1) {
          return `Image (${contentPosition})`;
        }

        if (index === 0) {
          return `${index + 1}. Mobile image (${contentPosition})`;
        }

        return `${index + 1}. Desktop image (${contentPosition})`;
      }
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CONTENT_CARD
      ],
      configOptions: {
        ...defaultBlockConfigOptions,
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CONTENT_CARD
        ].configOptions
      },
      defaultConfig: {
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CONTENT_CARD
        ].configDefaultValues,
        ...defaultBlockValues
      },
      defaultCount: 0,
      maxCount: 1
    }
  ],
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  IMAGE_HEIGHT_ENUM,
  CONTENT_PADDING_ENUM
};
