import React from 'react';
import PropTypes from 'prop-types';
import ContactInformationForm from './ContactInformationForm';
import ChangePasswordForm from './ChangePasswordForm';
import ChangeAvatarForm from './ChangeAvatarForm';
import ResponsiveWebstoreModal from '../webstore/ResponsiveWebstoreModal';

const SETTING_VIEWS = {
  contactInfo: 'contact_info',
  avatar: 'avatar',
  changePassword: 'change_password'
};

const SettingsModal = ({
  isOpen,
  onClose,
  setting,
  mediaQueries,
  editModeEnabled
}) => {
  const { isMobileOrSmaller, isTabletOrSmaller } = mediaQueries;

  return (
    <ResponsiveWebstoreModal
      alignModal={isTabletOrSmaller ? 'end' : 'center'}
      bodyProps={{ style: { padding: 0 } }}
      editModeEnabled={editModeEnabled}
      isOpen={isOpen}
      mediaQueries={mediaQueries}
      onClose={onClose}
      size={550}
      title={setting?.label}
    >
      {setting?.value === SETTING_VIEWS.contactInfo ? (
        <ContactInformationForm
          isMobileOrSmaller={isMobileOrSmaller}
          onClose={onClose}
        />
      ) : setting?.value === SETTING_VIEWS.avatar ? (
        <ChangeAvatarForm
          isMobileOrSmaller={isMobileOrSmaller}
          onClose={onClose}
        />
      ) : (
        setting?.value === SETTING_VIEWS.changePassword && (
          <ChangePasswordForm
            isMobileOrSmaller={isMobileOrSmaller}
            onClose={onClose}
          />
        )
      )}
    </ResponsiveWebstoreModal>
  );
};

SettingsModal.propTypes = {
  editModeEnabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onClose: PropTypes.func,
  setting: PropTypes.object
};

export default SettingsModal;
