import { Heading } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsHeadingBlock from './WsHeadingBlock';

const HEADING_SIZE_STYLE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  HUGE: 'huge'
};

const configNameValues = {
  text: 'text',
  textSize: 'textSize'
};

const configDefaultValues = {
  [configNameValues.text]: 'Show your support today!',
  [configNameValues.textSize]: HEADING_SIZE_STYLE_ENUM.HUGE
};

const configOptions = {
  [configNameValues.text]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Heading',
    isTextArea: true
  },
  [configNameValues.textSize]: {
    label: 'Heading size',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      {
        value: HEADING_SIZE_STYLE_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.HUGE,
        label: 'Huge'
      }
    ]
  }
};

const config = {
  title: 'Heading',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.HEADING,
  icon: Heading,
  Component: WsHeadingBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
