import React, { useContext, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AlertCircle,
  ArrowLeft,
  GripVertical,
  Plus,
  Trash
} from 'tabler-icons-react';
import {
  ActionIcon,
  Anchor,
  Button,
  Image,
  Loader,
  Skeleton,
  TextInput,
  Textarea
} from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppCard from '../../common/AppCard';
import { triggerNotification } from '../../../helpers/notification';
import ConfirmModal from '../../common/ConfirmModal';
import {
  useGlobalFormState,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import StoreCollectionProductAssignModal from './StoreCollectionProductAssignModal';
import SortableList from '../../common/SortableList';
import { ECOM_PRODUCT_STATUS_ENUM, VIEW_ACTIONS_ENUM } from '../../../config/constants';
import { getEcomStoreProductStatus } from '../../../helpers/vendorHelper';

const MENU_ACTIONS = { ADD_PRODUCT: 'ADD_PRODUCT', DELETE: 'delete' };

const StoreCollectionEditView = ({ duplicate }) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = useMediaQueryIndex();
  const { pkEcomStoreCollection } = useParams();
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    createStoreCollections,
    updateStoreCollection,
    deleteStoreCollections,
    fetchStoreCollection,
    fetchStoreSetupSummary
  } = useContext(StoreContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const {
    hasInitialized,
    hasUnsavedChanges,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(
    {
      name: '',
      description: '',
      ecomStoreCollectionProducts: [],
      ecomVendorCollectionProducts: []
    },
    { confirmDiscard: true, containerWidth: 950 }
  );
  const ecomStoreCollection = pkEcomStoreCollection
    ? state.ecomStoreCollection.value
    : null;
  const hasAlreadyFetched =
    hasFetched.current ||
    ecomStoreCollection?.pkEcomStoreCollection.toString() ===
      pkEcomStoreCollection;
  const loading = pkEcomStoreCollection
    ? !hasInitialized || !hasAlreadyFetched || state.ecomStoreCollection.loading
    : !hasInitialized;

  useEffect(() => {
    if (
      pkEcomStoreCollection &&
      ecomStoreCollection?.pkEcomStoreCollection.toString() !==
        pkEcomStoreCollection
    ) {
      fetchStoreCollection(
        pkEcomStoreCollection,
        () => {},
        (error) => triggerNotification(error)
      );
      hasFetched.current = true;
    }
  }, [pkEcomStoreCollection]);

  useEffect(() => {
    if (
      !state.ecomStoreCollection.loading &&
      (!pkEcomStoreCollection || ecomStoreCollection)
    ) {
      resetFormState({
        name: ecomStoreCollection?.name ?? '',
        description: ecomStoreCollection?.description ?? '',
        ecomStoreCollectionProducts:
          ecomStoreCollection?.ecomStoreCollectionProducts.map((p) => ({
            key: p.pkEcomStoreCollectionProduct,
            fkEcomStoreProduct: p.fkEcomStoreProduct,
            sort: p.sort
          })) ?? []
      });
    }
  }, [ecomStoreCollection, state.ecomStoreCollection.loading, duplicate]);

  const onRemoveProduct = (pkEcomStoreProduct) => {
    setFormState({
      ...formState,
      ecomStoreCollectionProducts: formState.ecomStoreCollectionProducts
        .filter((p) => p.fkEcomStoreProduct !== pkEcomStoreProduct)
        .sort((a, b) => a.sort - b.sort)
        .map((p, i) => ({ ...p, sort: i + 1 })),
      hasUnsavedChanges: true
    });
  };

  const onAddProducts = (pkEcomStoreProducts) => {
    const newPkEcomStoreProducts = pkEcomStoreProducts.filter(
      (p) =>
        !formState.ecomStoreCollectionProducts.find(
          (f) => f.fkEcomStoreProduct === p
        )
    );
    setFormState({
      ...formState,
      ecomStoreCollectionProducts: [
        ...formState.ecomStoreCollectionProducts.filter((p) =>
          pkEcomStoreProducts.includes(p.fkEcomStoreProduct)
        ),
        ...newPkEcomStoreProducts.map((p, i) => ({
          fkEcomStoreProduct: p,
          sort: formState.ecomStoreCollectionProducts.length + i + 1,
          key: new Date().getTime() + i
        }))
      ]
        .sort((a, b) => a.sort - b.sort)
        .map((p, i) => ({ ...p, sort: i + 1 })),
      hasUnsavedChanges: true
    });
  };

  const onSaveCollection = () => {
    submitFormState((formData, onErrorCallback) => {
      if (ecomStoreCollection && !duplicate) {
        updateStoreCollection(
          ecomStoreCollection.pkEcomStoreCollection,
          formState,
          () => {
            triggerNotification('Collection updated!', 'success');
          },
          (error) => {
            triggerNotification(error);
            setFormState({ ...formState, loading: false });
          }
        );
      }
      else {
        createStoreCollections(
          authState.pkEcomStore,
          { ecomStoreCollections: [formState], selectFirstItem: true },
          (data) => {
            navigate(`/merchant/collections/${data[0].pkEcomStoreCollection}`);
            triggerNotification('Collection created!', 'success');
            if (
              state.ecomStoreSetupSummary.value?.ecomStore.sandboxEnabled &&
              !state.ecomStoreSetupSummary.value?.hasCollections
            ) {
              fetchStoreSetupSummary(authState.pkEcomStore, {skipLoading: true});
            }
          },
          onErrorCallback
        );
      }
    });
  };

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'collections':
        return '/merchant/collections';
      default: {
        if (duplicate) {
          return `/merchant/collections/${pkEcomStoreCollection}`;
        }
        return '/merchant/collections';
      }
    }
  };

  return !loading && (!pkEcomStoreCollection || hasInitialized) ? (
    <>
      <AppStack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSaveCollection();
        }}
        p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
        style={{
          flex: 1,
          margin: 'auto',
          width: '100%',
          maxWidth: 950,
          paddingTop: 16,
          gap: 16
        }}
      >
        <AppFlexbox
          p={{ base: '0px 8px', sm: 0 }}
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <ActionableIcon
              color="dark"
              component={Link}
              radius="md"
              to={getBackPath()}
              variant="subtle"
            >
              <ArrowLeft />
            </ActionableIcon>
            <AppText style={{ fontSize: 18, fontWeight: 700 }}>
              {ecomStoreCollection?.name ?? 'Create collection'}
            </AppText>
          </AppFlexbox>

          {!!ecomStoreCollection && !duplicate && (
            <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
              <Button
                color="dark"
                component={Link}
                radius="md"
                size="xs"
                to={`/merchant/collections/${ecomStoreCollection.pkEcomStoreCollection}/duplicate`}
                type="button"
                variant="light"
              >
                Duplicate
              </Button>
            </AppFlexbox>
          )}
        </AppFlexbox>

        {duplicate ? (
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 8 }}
            withBorder
          >
            <AppFlexbox style={{ gap: 8 }}>
              <AppStack>
                <AlertCircle color="#eca70a" style={{ size: 18 }} />
              </AppStack>
              <AppText style={{ fontSize: 14 }}>
                Duplicate this collection by changing the <b>Title</b> and
                clicking save.
              </AppText>
            </AppFlexbox>
          </AppCard>
        ) : (
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 8 }}
            withBorder
          >
            <AppFlexbox style={{ gap: 8 }}>
              <AppStack>
                <AlertCircle color="dodgerblue" style={{ size: 18 }} />
              </AppStack>
              <AppText style={{ fontSize: 14 }}>
                Use collections to group products together, making it easier for
                customers to browse similar products!
              </AppText>
            </AppFlexbox>
          </AppCard>
        )}

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack>
            <TextInput
              disabled={isSubmitting}
              label="Title"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.currentTarget.value,
                  hasUnsavedChanges: true
                })
              }
              required
              value={formState.name}
            />

            <Textarea
              autosize
              disabled={isSubmitting}
              label="Description"
              minRows={4}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  description: e.currentTarget.value,
                  hasUnsavedChanges: true
                })
              }
              value={formState.description}
            />
          </AppStack>
        </AppCard>

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox
              style={{
                padding: 16,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                Products
              </AppText>

              <Button
                color="dark"
                disabled={isSubmitting}
                leftSection={<Plus />}
                onClick={() => onOpenModal(MENU_ACTIONS.ADD_PRODUCT)}
                size="compact-sm"
                type="button"
                variant="filled"
              >
                Add
              </Button>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              {state.ecomStoreProducts.loading ? (
                <AppStack
                  style={{
                    gap: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 10px',
                    flex: 1
                  }}
                >
                  <Loader color="dark" size={34} />
                </AppStack>
              ) : formState.ecomStoreCollectionProducts.length === 0 ? (
                <AppStack
                  style={{
                    padding: 16,
                    gap: 10,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AppText style={{ fontWeight: 500, color: '#666' }}>
                    No products added to this collection.
                  </AppText>
                  <Button
                    color="dark"
                    disabled={isSubmitting}
                    leftSection={<Plus />}
                    onClick={() => onOpenModal(MENU_ACTIONS.ADD_PRODUCT)}
                    size="compact-sm"
                    type="button"
                    variant="outline"
                  >
                    Add product
                  </Button>
                </AppStack>
              ) : (
                <SortableList
                  items={formState.ecomStoreCollectionProducts}
                  onChange={(newList) => {
                    setFormState({
                      ...formState,
                      ecomStoreCollectionProducts: newList.map((p, index) => ({
                        ...p,
                        sort: index + 1
                      })),
                      hasUnsavedChanges: true
                    });
                  }}
                  renderItem={(
                    item,
                    {
                      attributes,
                      listeners,
                      isDragging,
                      setNodeRef,
                      style,
                      isOverlay
                    }
                  ) => {
                    const product = state.ecomStoreProducts.value.find(
                      (p) => p.pkEcomStoreProduct === item?.fkEcomStoreProduct
                    );
const productStatusInfo = getEcomStoreProductStatus(product)

                    return product ? (
                      <AppCard
                        ref={setNodeRef}
                        radius={0}
                        shadow="none"
                        style={{
                          ...style,
                          padding: 0,
                          outline: isOverlay ? 'solid 1px #dee2e6' : 'none',
                          backgroundColor: isDragging
                            ? 'rgba(56, 56, 56, 0.1)'
                            : '#FFF',
                          opacity: isDragging ? 0.4 : 1,
                          cursor: isDragging ? 'grabbing' : 'default'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            padding: 10,
                            gap: 10,
                            borderTop: isOverlay ? 'none' : 'solid 1px #dee2e6',
                            flex: 1,
                            alignItems: 'center'
                          }}
                        >
                          <ActionIcon
                            color="dark"
                            disabled={isSubmitting}
                            variant="subtle"
                            {...attributes}
                            {...listeners}
                          >
                            <GripVertical size={20} />
                          </ActionIcon>

                          <AppFlexbox
                            style={{
                              flex: 1,
                              gap: 10,
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <AppFlexbox style={{ alignItems: 'center' }}>
                              <AppStack
                                style={{
                                  width: 40,
                                  height: 40,
                                  border: !product.ecomVendorProduct
                                    .previewImage
                                    ? 'solid 1px #dee2e6'
                                    : 'none'
                                }}
                              >
                                <Image
                                  fit="fill"
                                  h={40}
                                  src={product.ecomVendorProduct.previewImage}
                                  w={40}
                                />
                              </AppStack>
                              <AppStack style={{ fles: 1, gap: 0 }}>
                                <Anchor
                                  onClick={() =>
                                    navigate(
                                      `/merchant/products/${product.uuid}`
                                    )
                                  }
                                  style={{ fontSize: 14, fontWeight: 500,color: '#000' }}
                                >
                                  {product.ecomVendorProduct.name}
                                </Anchor>
                                <AppText
                                  style={{ fontSize: 12, color: '#666' }}
                                >
                                  {product.ecomVendorProduct.vendorName}
                                </AppText>
                                <AppText
                                  style={{ fontSize: 12,color:productStatusInfo.color }}
                                >
                                  {productStatusInfo.label}
                                </AppText>
                              </AppStack>
                            </AppFlexbox>

                            <ActionableIcon
                              color="red"
                              disabled={isSubmitting}
                              onClick={() =>
                                onRemoveProduct(item.fkEcomStoreProduct)
                              }
                              radius="md"
                              variant="subtle"
                            >
                              <Trash />
                            </ActionableIcon>
                          </AppFlexbox>
                        </AppFlexbox>
                      </AppCard>
                    ) : null;
                  }}
                />
              )}
            </AppStack>
          </AppStack>
        </AppCard>

        <AppFlexbox
          p={{ base: 16, sm: '16px 0px' }}
          style={{
            justifyContent: 'flex-end',
            flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
          }}
        >
          {!!ecomStoreCollection && !duplicate && (
            <Button
              color="red"
              disabled={isSubmitting}
              onClick={() => {
                onOpenModal(VIEW_ACTIONS_ENUM.DELETE, ecomStoreCollection);
              }}
              radius="md"
              size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
              type="button"
              variant="filled"
            >
              Delete collection
            </Button>
          )}
          <Button
            color="dark"
            disabled={!hasUnsavedChanges}
            id="save-button"
            loading={isSubmitting}
            radius="md"
            size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
            type="submit"
            variant="filled"
          >
            Save
          </Button>
        </AppFlexbox>
      </AppStack>

      {ConfirmDiscardModal}

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete collection"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText>
              Are you sure you want to delete <b>{modalState.item?.name}</b>?
              This action can’t be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteStoreCollections(
            authState.pkEcomStore,
            [modalState.item.pkEcomStoreCollection],
            () => {
              triggerNotification('Collection deleted!', 'success');
              navigate('/merchant/collections');
              if (state.ecomStoreSetupSummary.value?.ecomStore.sandboxEnabled) {
                fetchStoreSetupSummary(authState.pkEcomStore, {skipLoading: true});
              }
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${modalState.item?.name}?`}
      />
      <StoreCollectionProductAssignModal
        isOpen={
          modalState.isOpen && modalState.action === MENU_ACTIONS.ADD_PRODUCT
        }
        onClose={onCloseModal}
        onConfirm={(pkEcomStoreProducts) => {
          onAddProducts(pkEcomStoreProducts);
          onCloseModal();
        }}
        pkEcomStoreProducts={formState.ecomStoreCollectionProducts.map(
          (p) => p.fkEcomStoreProduct
        )}
      />
    </>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <AppFlexbox style={{ gap: 5 }}>
          <Skeleton height={30} width={200} />
        </AppFlexbox>

        {!!ecomStoreCollection && !duplicate && (
          <AppFlexbox style={{ gap: 5 }}>
            <Skeleton height={30} width={125} />
          </AppFlexbox>
        )}
      </AppFlexbox>

      <AppStack style={{ gap: 16 }}>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
      </AppStack>
    </AppStack>
  );
};

StoreCollectionEditView.propTypes = {
  duplicate: PropTypes.bool,
  loading: PropTypes.bool
};

export default StoreCollectionEditView;
