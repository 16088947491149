/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import { configNameValues } from './wsButtonGroupConfig';
import AppFlexbox from '../../../../common/AppFlexbox';
import { determineEcomStoreMenuItemUrl } from '../../../../../helpers/webstoreHelper';

const WsButtonGroupBlock = ({
  config,
  colorScheme,
  baseStoreUrl,
  onNavigate,
  ecomStoreTheme,
  ...rest
}) => {
  const button1Style =
    config[configNameValues.button1UseOutlineStyle] === 'true'
      ? {
          textColor: colorScheme.textColor,
          buttonColor: colorScheme.outlineButtonColor,
          buttonTextColor: colorScheme.outlineButtonColor,
          variant: 'outline'
        }
      : {
          textColor: colorScheme.textColor,
          buttonColor: colorScheme.solidButtonColor,
          buttonTextColor: colorScheme.solidButtonLabel,
          variant: 'filled'
        };
  const button2Style =
    config[configNameValues.button2UseOutlineStyle] === 'true'
      ? {
          textColor: colorScheme.textColor,
          buttonColor: colorScheme.outlineButtonColor,
          buttonTextColor: colorScheme.outlineButtonColor,
          variant: 'outline'
        }
      : {
          textColor: colorScheme.textColor,
          buttonColor: colorScheme.solidButtonColor,
          buttonTextColor: colorScheme.solidButtonLabel,
          variant: 'filled'
        };
  const button1Size = config[configNameValues.button1Size] ?? 'md';
  const button1UseCompactStyle =
    config[configNameValues.button1UseCompactStyle] === 'true';

  const button2Size = config[configNameValues.button2Size] ?? 'md';
  const button2UseCompactStyle =
    config[configNameValues.button2UseCompactStyle] === 'true';

  const button1HasLink =
    config?.button1FkEcomStorePageType || config?.button1CustomUrl;
  const button1Link = determineEcomStoreMenuItemUrl({
    fkEcomStorePageType: config?.button1FkEcomStorePageType,
    ecomResourceId: config?.button1EcomResourceId,
    customUrl: config?.button1CustomUrl,
    ecomStoreTheme
  });

  const button2HasLink =
    config?.button2FkEcomStorePageType || config?.button2CustomUrl;
  const button2Link = determineEcomStoreMenuItemUrl({
    fkEcomStorePageType: config?.button2FkEcomStorePageType,
    ecomResourceId: config?.button2EcomResourceId,
    customUrl: config?.button2CustomUrl,
    ecomStoreTheme
  });

  return (
    <AppFlexbox
      style={{
        alignItems: config.alignItems,
        justifyContent: config.justifyContent
      }}
    >
      {config[configNameValues.button1Text] && (
        <Button
          color={button1Style.buttonColor}
          component={button1HasLink ? Link : ''}
          onClick={onNavigate}
          radius={0}
          size={button1UseCompactStyle ? `compact-${button1Size}` : button1Size}
          style={{ color: button1Style.buttonTextColor }}
          target={button1Link?.target}
          to={button1Link?.url || `${baseStoreUrl}${button1Link?.path}`}
          type="button"
          variant={button1Style.variant}
        >
          {config[configNameValues.button1Text]}
        </Button>
      )}

      {config[configNameValues.button2Text] && (
        <Button
          color={button2Style.buttonColor}
          component={button2HasLink ? Link : ''}
          onClick={onNavigate}
          radius={0}
          size={button2UseCompactStyle ? `compact-${button2Size}` : button2Size}
          style={{ color: button2Style.buttonTextColor }}
          target={button2Link?.target}
          to={button2Link?.url || `${baseStoreUrl}${button2Link?.path}`}
          type="button"
          variant={button2Style.variant}
        >
          {config[configNameValues.button2Text]}
        </Button>
      )}
    </AppFlexbox>
  );
};

WsButtonGroupBlock.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  onNavigate: PropTypes.func
};

export default WsButtonGroupBlock;
