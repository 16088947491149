import {
  BallBaseball,
  Barbell,
  Bottle,
  Clipboard,
  Coin,
  Shirt,
  Speakerphone,
  StereoGlasses
} from 'tabler-icons-react';

const PRODUCT_CATEGORY_TYPE_ENUM = {
  ACCESSORIES: 1,
  APPAREL: 2,
  CUSTOMIZATION_SERVICES: 3,
  EVENT_SUPPLIES: 4,
  FAN_GEAR: 5,
  FITNESS_TRAINING: 6,
  NUTRITION_HEALTH: 7,
  SPORTS_EQUIPMENT: 8,
  TEAM_SUPPLIES: 9
};

const PRODUCT_CATEGORY_ENUM = {
  Accessories: {
    'Bags & Backpacks': {},
    'Protective Gear': {},
    'Support & Recovery': {}
  },
  Apparel: {
    'Team Uniforms': {},
    'Training Wear': {},
    Outerwear: {},
    Footwear: {}
  },
  'Customization Services': {
    'Uniform Customization': {},
    'Equipment Customization': {}
  },
  'Coaching & Team Supplies': {
    'Coaching Equipment': {},
    'Team Supplies': {}
  },
  'Event Management & Supplies': {
    'Event Supplies': {},
    'Management Tools': {}
  },
  'Fan Gear': {
    Merchandise: {},
    Clothing: {}
  },
  'Fitness & Training': {
    'Fitness Equipment': {},
    'Training Aids': {}
  },
  'Nutrition & Health': {
    Supplements: {},
    Hydration: {}
  },
  'Sports Equipment': {
    'Team Sports': {
      Soccer: {},
      Basketball: {},
      'Baseball & Softball': {},
      Football: {},
      Hockey: {}
    },
    'Individual Sports': {
      Tennis: {},
      Golf: {},
      Running: {}
    }
  }
};

const PRODUCT_CATEGORY_LIST = [
  {
    name: 'Sports Equipment',
    value: PRODUCT_CATEGORY_TYPE_ENUM.SPORTS_EQUIPMENT,
    description:
      'Find all the gear you need for both team and individual sports, from balls and nets to training equipment.',
    gradientColor: 'linear-gradient(135deg, #00b09b 0%, #96c93d 100%)',
    Icon: BallBaseball,
    subcategories: {
      'Team Sports': {
        name: 'Team Sports',
        value: 1.1,
        description:
          'Get equipped for your favorite team sports, including soccer, basketball, baseball, football, and hockey.',
        gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
        subcategories: {
          Soccer: {
            name: 'Soccer',
            value: 1.11,
            description:
              'Explore our wide range of soccer equipment and accessories, including balls, goals, and training gear.',
            gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
          },
          Basketball: {
            name: 'Basketball',
            value: 1.12,
            description:
              'Discover high-quality basketballs, hoops, and training equipment to elevate your game.',
            gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
          },
          'Baseball & Softball': {
            name: 'Baseball & Softball',
            value: 1.13,
            description:
              'Find top-notch bats, gloves, balls, and training equipment for baseball and softball enthusiasts.',
            gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
          },
          Football: {
            name: 'Football',
            value: 1.14,
            description:
              'Browse our selection of footballs, helmets, pads, and training equipment for all your football needs.',
            gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
          },
          Hockey: {
            name: 'Hockey',
            value: 1.15,
            description:
              'Get the best in hockey sticks, pucks, and protective gear for players of all levels.',
            gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
          }
        }
      },
      'Individual Sports': {
        name: 'Individual Sports',
        value: 1.2,
        description:
          'Equip yourself for individual sports like tennis, golf, and running with our top-quality gear.',
        gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
        subcategories: {
          Tennis: {
            name: 'Tennis',
            value: 1.21,
            description:
              'Shop for premium tennis rackets, balls, and nets to enhance your tennis experience.',
            gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
          },
          Golf: {
            name: 'Golf',
            value: 1.22,
            description:
              'Explore our collection of golf clubs, balls, and bags to improve your game on the course.',
            gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
          },
          Running: {
            name: 'Running',
            value: 1.23,
            description:
              'Find the perfect running shoes and track & field equipment to support your running goals.',
            gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
          }
        }
      }
    }
  },
  {
    name: 'Apparel',
    value: PRODUCT_CATEGORY_TYPE_ENUM.APPAREL,
    description:
      'Discover a wide range of sports apparel, including team uniforms, training wear, and outerwear.',
    gradientColor: 'linear-gradient(135deg, #8e2de2 0%, #4a00e0 100%)',
    Icon: Shirt,
    subcategories: {
      'Team Uniforms': {
        name: 'Team Uniforms',
        value: 2.1,
        description:
          'Customize your team uniforms with our high-quality jerseys, shorts, and socks.',
        gradientColor: 'linear-gradient(135deg, #e52d27 0%, #b31217 100%)'
      },
      'Training Wear': {
        name: 'Training Wear',
        value: 2.2,
        description:
          'Stay comfortable and stylish with our range of training t-shirts, sweatpants, and hoodies.',
        gradientColor: 'linear-gradient(135deg, #e52d27 0%, #b31217 100%)'
      },
      Outerwear: {
        name: 'Outerwear',
        value: 2.3,
        description:
          'Keep warm and dry with our selection of jackets and warm-ups designed for athletes.',
        gradientColor: 'linear-gradient(135deg, #e52d27 0%, #b31217 100%)'
      },
      Footwear: {
        name: 'Footwear',
        value: 2.4,
        description:
          'Choose from a variety of cleats, running shoes, and court shoes to suit your sport.',
        gradientColor: 'linear-gradient(135deg, #e52d27 0%, #b31217 100%)'
      }
    }
  },
  {
    name: 'Accessories',
    value: PRODUCT_CATEGORY_TYPE_ENUM.ACCESSORIES,
    description:
      'Enhance your sports experience with our range of accessories, from bags to protective gear.',
    gradientColor: 'linear-gradient(135deg, #f9d423 0%, #ff4e50 100%)',
    Icon: StereoGlasses,
    subcategories: {
      'Bags & Backpacks': {
        name: 'Bags & Backpacks',
        value: 3.1,
        description:
          'Carry your gear in style with our sports bags, equipment bags, and backpacks.',
        gradientColor: 'linear-gradient(135deg, #f9d423 0%, #ff4e50 100%)'
      },
      'Protective Gear': {
        name: 'Protective Gear',
        value: 3.2,
        description:
          'Stay safe on the field with our range of mouthguards, shin guards, and helmets.',
        gradientColor: 'linear-gradient(135deg, #f9d423 0%, #ff4e50 100%)'
      },
      'Support & Recovery': {
        name: 'Support & Recovery',
        value: 3.3,
        description:
          'Aid your recovery and prevent injuries with our braces, supports, and recovery tools.',
        gradientColor: 'linear-gradient(135deg, #f9d423 0%, #ff4e50 100%)'
      }
    }
  },
  {
    name: 'Fitness & Training',
    value: PRODUCT_CATEGORY_TYPE_ENUM.FITNESS_TRAINING,
    description:
      'Equip your home gym and enhance your workouts with our fitness equipment and training aids.',
    gradientColor: 'linear-gradient(135deg, #ff6a00 0%, #ee0979 100%)',
    Icon: Barbell,
    subcategories: {
      'Fitness Equipment': {
        name: 'Fitness Equipment',
        value: 4.1,
        description:
          'Equip your home gym with our weights, dumbbells, resistance bands, and exercise mats.',
        gradientColor: 'linear-gradient(135deg, #5b247a 0%, #1bcedf 100%)'
      },
      'Training Aids': {
        name: 'Training Aids',
        value: 4.2,
        description:
          'Improve your performance with agility ladders, cones, and other training aids.',
        gradientColor: 'linear-gradient(135deg, #5b247a 0%, #1bcedf 100%)'
      }
    }
  },
  {
    name: 'Team Supplies',
    value: PRODUCT_CATEGORY_TYPE_ENUM.TEAM_SUPPLIES,
    description:
      'Find essential supplies for coaches and teams, including equipment, water bottles, and seating.',
    gradientColor: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
    Icon: Clipboard,
    subcategories: {
      'Coaching Equipment': {
        name: 'Coaching Equipment',
        value: 5.1,
        description:
          'Find essential coaching tools like whistles, coaching boards, and stopwatches.',
        gradientColor: 'linear-gradient(135deg, #4da0b0 0%, #d39d38 100%)'
      },
      'Team Supplies': {
        name: 'Team Supplies',
        value: 5.2,
        description:
          'Equip your team with water bottles, first aid kits, benches, and other essential supplies.',
        gradientColor: 'linear-gradient(135deg, #4da0b0 0%, #d39d38 100%)'
      }
    }
  },
  {
    name: 'Fan Gear',
    value: PRODUCT_CATEGORY_TYPE_ENUM.FAN_GEAR,
    description:
      'Show your team spirit with our collection of fan merchandise and clothing.',
    gradientColor: 'linear-gradient(135deg, #ed6ea0 0%, #ec8c69 100%)',
    Icon: Speakerphone,
    subcategories: {
      Merchandise: {
        name: 'Merchandise',
        value: 6.1,
        description:
          'Shop for fan merchandise including flags, banners, and collectibles.',
        gradientColor: 'linear-gradient(135deg, #ed6ea0 0%, #ec8c69 100%)'
      },
      Clothing: {
        name: 'Clothing',
        value: 6.2,
        description:
          'Support your team with our selection of fan clothing and apparel.',
        gradientColor: 'linear-gradient(135deg, #ed6ea0 0%, #ec8c69 100%)'
      }
    }
  },
  {
    name: 'Customization Services',
    value: PRODUCT_CATEGORY_TYPE_ENUM.CUSTOMIZATION_SERVICES,
    description:
      'Personalize your team uniforms and equipment with our customization services.',
    gradientColor: 'linear-gradient(135deg, #ff512f 0%, #dd2476 100%)',
    Icon: Shirt,
    subcategories: {
      'Uniform Customization': {
        name: 'Uniform Customization',
        value: 7.1,
        description:
          'Customize your team jerseys, shorts, and apparel with names, numbers, and logos.',
        gradientColor: 'linear-gradient(135deg, #ff512f 0%, #dd2476 100%)'
      },
      'Equipment Customization': {
        name: 'Equipment Customization',
        value: 7.2,
        description:
          'Personalize your sports equipment like bats, helmets, and bags with custom graphics.',
        gradientColor: 'linear-gradient(135deg, #ff512f 0%, #dd2476 100%)'
      }
    }
  },
  {
    name: 'Nutrition & Health',
    value: PRODUCT_CATEGORY_TYPE_ENUM.NUTRITION_HEALTH,
    description:
      'Fuel your performance with our range of sports nutrition supplements and hydration solutions.',
    gradientColor: 'linear-gradient(135deg, #f12711 0%, #f5af19 100%)',
    Icon: Bottle,
    subcategories: {
      Supplements: {
        name: 'Supplements',
        value: 8.1,
        description:
          'Enhance your training and recovery with our selection of sports supplements.',
        gradientColor: 'linear-gradient(135deg, #00d2ff 0%, #3a7bd5 100%)'
      },
      Hydration: {
        name: 'Hydration',
        value: 8.2,
        description:
          'Stay hydrated during workouts and games with our sports drinks and hydration packs.',
        gradientColor: 'linear-gradient(135deg, #00d2ff 0%, #3a7bd5 100%)'
      }
    }
  },
  {
    name: 'Event Management',
    value: PRODUCT_CATEGORY_TYPE_ENUM.EVENT_SUPPLIES,
    description:
      'Manage your sports events and teams efficiently with our management tools and supplies.',
    gradientColor: 'linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)',
    Icon: Clipboard,
    subcategories: {
      'Event Supplies': {
        name: 'Event Supplies',
        value: 9.1,
        description:
          'Organize successful sports events with our event planning supplies and equipment.',
        gradientColor: 'linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)'
      },
      'Team Management Tools': {
        name: 'Team Management Tools',
        value: 9.2,
        description:
          'Streamline team management with our scheduling software and communication tools.',
        gradientColor: 'linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)'
      }
    }
  }
];

const SPECIAL_PRODUCT_CATEGORIES = [
  {
    name: 'Fundraising',
    value: 'fundraising',
    description:
      'Raise funds for your team or organization with our easy-to-use fundraising solutions.',
    gradientColor: 'linear-gradient(135deg, #f9d423 0%, #ff4e50 100%)',
    Icon: Coin,
    searchProps: { fundraisingRecommended: true }
  }
];

export {
  PRODUCT_CATEGORY_TYPE_ENUM,
  PRODUCT_CATEGORY_ENUM,
  PRODUCT_CATEGORY_LIST,
  SPECIAL_PRODUCT_CATEGORIES
};
