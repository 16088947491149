/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Divider, Loader, NavLink } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Receipt, Settings } from 'tabler-icons-react';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppCard from '../../../../common/AppCard';
import WsAccountDetails from './WsAccountDetails';
import WsAccountOrders from './WsAccountOrders';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import AppText from '../../../../common/AppText';

const WsAccountInfoView = ({
  loading,
  pathname,
  mediaQueries,
  baseStoreUrl,
  onNavigate,
  onAuthNavigation,
  editModeEnabled,
  onAuthenticateEditMode,
  builderHasAuthenticated,
  search,
  ...rest
}) => {
  const { state: authState, logout } = useContext(AuthContext);
  const { isTabletOrSmaller } = mediaQueries;
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const accountContent = [
    {
      title: 'Account details',
      icon: Settings,
      path: '/account/details',
      Component: WsAccountDetails,
      requiresAuth: true
    },
    {
      title: 'Order history',
      icon: Receipt,
      path: '/account/orders',
      Component: WsAccountOrders,
      requiresAuth: true
    }
  ];
  const currentContent = accountContent.find((content) =>
    pathname.startsWith(content.path)
  );

  return !loading ? (
    <AppFlexbox
      style={{
        flex: 1,
        justifyContent: 'center',
        margin: isTabletOrSmaller ? 0 : '20px 0px 40px 0px',
        flexDirection: isTabletOrSmaller ? 'column' : 'row'
      }}
    >
      {(!isTabletOrSmaller || showMobileNavigation) && (
        <AppStack style={{ flex: 1, maxHeight: 550 }}>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ flex: 1, padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0, flex: 1 }}>
              {(builderHasAuthenticated || authState.isAuthenticated) && (
                <>
                  <AppFlexbox style={{ padding: 16, alignItems: 'center' }}>
                    <Avatar
                      size="md"
                      src={authState.userData.user.avatar}
                      variant="filled"
                    />
                    <AppStack style={{ gap: 0 }}>
                      <AppText
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          lineHeight: '22px'
                        }}
                      >
                        {authState.userData.user.name}
                      </AppText>
                      <AppText
                        style={{
                          fontSize: 14,
                          color: '#666',
                          lineHeight: '18px'
                        }}
                      >
                        {authState.userData.user.email}
                      </AppText>
                    </AppStack>
                  </AppFlexbox>
                  <Divider />
                </>
              )}

              <AppStack style={{ gap: 0, flex: 1 }}>
                {accountContent.map((c) => {
                  const selected =
                    !isTabletOrSmaller && pathname.startsWith(c.path);
                  return (
                    <AppFlexbox key={c.path}>
                      <NavLink
                        active={selected}
                        color="dark"
                        component={Link}
                        label={c.title}
                        leftSection={<c.icon size={18} />}
                        onClick={(e) => {
                          setShowMobileNavigation(false);
                          onNavigate(e);
                        }}
                        style={{
                          fontWeight: selected ? 500 : 'normal',
                          fontSize: 14,
                          padding: '12px 16px'
                        }}
                        to={
                          !authState.isAuthenticated
                            ? `${baseStoreUrl}/account/login?redirect=${c.path}`
                            : `${baseStoreUrl}${c.path}`
                        }
                        variant="light"
                      />
                    </AppFlexbox>
                  );
                })}
              </AppStack>
              {!isTabletOrSmaller && (
                <AppStack style={{ gap: 0 }}>
                  <Button
                    color="dark"
                    onClick={(e) => {
                      if (builderHasAuthenticated) {
                        onAuthenticateEditMode(false);
                      }
                      else if (authState.isAuthenticated) {
                        logout(() => {
                          onAuthNavigation('/account/login');
                        });
                      }
                      else {
                        onAuthNavigation(
                          `/account/login?redirect=${pathname}${search}`
                        );
                      }
                    }}
                    radius={0}
                    size="md"
                    style={{ width: '100%', borderTop: '1px solid #dee2e6' }}
                    variant="subtle"
                  >
                    {builderHasAuthenticated || authState.isAuthenticated
                      ? 'Sign out'
                      : 'Sign in'}
                  </Button>
                </AppStack>
              )}
            </AppStack>
          </AppCard>
        </AppStack>
      )}

      {(!isTabletOrSmaller || !showMobileNavigation) && (
        <AppStack style={{ flex: 2, position: 'relative' }}>
          {currentContent && (
            <currentContent.Component
              baseStoreUrl={baseStoreUrl}
              builderHasAuthenticated={builderHasAuthenticated}
              editModeEnabled={editModeEnabled}
              mediaQueries={mediaQueries}
              onNavigate={onNavigate}
              onShowMobileNavigation={() => setShowMobileNavigation(true)}
              pathname={pathname}
              search={search}
              {...rest}
            />
          )}
        </AppStack>
      )}
    </AppFlexbox>
  ) : (
    <Loader color="dark" size={80} style={{ margin: 'auto' }} type="dots" />
  );
};

WsAccountInfoView.propTypes = {
  baseStoreUrl: PropTypes.string,
  builderHasAuthenticated: PropTypes.bool,
  editModeEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onAuthNavigation: PropTypes.func,
  onAuthenticateEditMode: PropTypes.func,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string,
  view: PropTypes.string
};

export default WsAccountInfoView;
