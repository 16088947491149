import React, { useContext, useEffect, useRef } from 'react';
import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Image,
  Skeleton,
  TextInput,
  Textarea
} from '@mantine/core';
import {
  AlertCircle,
  BucketDroplet,
  Check,
  ChevronRight,
  CurrencyDollar,
  EyeCheck,
  EyeOff,
  Mail,
  MailOff,
  Pencil,
  PhotoPlus,
  Trash,
  World
} from 'tabler-icons-react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import {
  useGlobalFormState,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import { triggerNotification } from '../../../helpers/notification';
import OnboardVendorPaymentProviderModal from '../vendors/OnboardVendorPaymentProviderModal';
import MediaDropzone from '../media/MediaDropzone';
import LaunchStoreModal from './LaunchStoreModal';
import SettingCard from '../../common/SettingCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import SettingSelector from '../../common/SettingSelector';
import CustomColorInput from '../../common/CustomColorInput';
import CustomInputModal from '../../common/CustomInputModal';
import ConfirmModal from '../../common/ConfirmModal';
import AppCard from '../../common/AppCard';
import {
  CURRENCY_TYPE_ENUM,
  CURRENCY_TYPE_LIST
} from '../../../config/constants';
import ChangeCurrencyModal from '../../common/ChangeCurrencyModal';
import SelectStoreTeamModal from './SelectStoreTeamModal';
import CreateStoreWindowModal from './CreateStoreWindowModal';
import {
  currencyFormat,
  formatUtcDate,
  sterilizeUrlHandle
} from '../../../helpers/format';

const MODAL_ACTION_ENUM = {
  UPLOAD_LOGO: 'UPLOAD_LOGO',
  CONNECT_PAYMENT_PROVIDER: 'CONNECT_PAYMENT_PROVIDER',
  SANDBOX_OFF: 'SANDBOX_OFF',
  SANDBOX_ON: 'SANDBOX_ON',
  CONFIRM_CANCEL: 'CONFIRM_CANCEL',
  CHANGE_CURRENCY: 'CHANGE_CURRENCY',
  CHANGE_TEAM: 'CHANGE_TEAM',
  CHANGE_ASSOCIATION: 'CHANGE_ASSOCIATION',
  STORE_WINDOW: 'STORE_WINDOW',
  CLAIM_ESCROW_PAYMENTS: 'CLAIM_ESCROW_PAYMENTS'
};

const StoreSettingsView = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const fetchedPkEcomStore = useRef(false);
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = useMediaQueryIndex();
  const { state: authState, fetchEcommerceAdminData } = useContext(AuthContext);
  const modalAction = new URLSearchParams(search).get('modal');
  const { state, fetchStore, updateStore, fetchStoreSetupSummary } = useContext(
    StoreContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    hasInitialized,
    hasUnsavedChanges,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(
    {
      name: '',
      domain: '',
      supportEmail: '',
      description: '',
      logoImageUrl: '',
      primaryColor: '',
      primaryContrastColor: '#FFF',
      secondaryColor: '',
      secondaryAltColor: '',
      secondaryContrastColor: '#FFF',
      onlineStorePassword: '',
      onlineStorePasswordDescription: '',
      treasurerAlertEmail: '',
      urlHandle: '',
      sandboxEnabled: false,
      fkEcomCurrency: CURRENCY_TYPE_ENUM.CAD,
      ecomStoreWindow: null,
      team: null,
      hasUnsavedChanges: false,
      loading: false
    },
    { confirmDiscard: true, containerWidth: 1100 }
  );
  const loading = !fetchedPkEcomStore.current || state.ecomStore.loading;
  const ecomStore =
    fetchedPkEcomStore.current === state.ecomStore.value?.pkEcomStore.toString()
      ? state.ecomStore.value
      : null;

  const selectedCurrency =
    CURRENCY_TYPE_LIST.find(
      (currency) => currency.value === formState.fkCurrencyType
    ) || CURRENCY_TYPE_LIST[0];

  useEffect(() => {
    if (
      authState.pkEcomStore &&
      fetchedPkEcomStore.current !== authState.pkEcomStore
    ) {
      fetchStore(authState.pkEcomStore, null, (e) => triggerNotification(e));
      fetchedPkEcomStore.current = authState.pkEcomStore;
    }
  }, [authState.pkEcomStore]);

  useEffect(() => {
    if (
      ecomStore &&
      fetchedPkEcomStore.current === authState.pkEcomStore &&
      !loading
    ) {
      resetFormState({
        name: ecomStore.name,
        domain: ecomStore.domain,
        supportEmail: ecomStore.supportEmail,
        description: ecomStore.description,
        logoImageUrl: ecomStore.logoImageUrl,
        primaryColor: ecomStore.primaryColor,
        primaryContrastColor: ecomStore.primaryContrastColor ?? '#FFF',
        secondaryColor: ecomStore.secondaryColor,
        secondaryAltColor: ecomStore.secondaryAltColor,
        secondaryContrastColor: ecomStore.secondaryContrastColor ?? '#FFF',
        onlineStorePassword: ecomStore.onlineStorePassword,
        onlineStorePasswordDescription:
          ecomStore.onlineStorePasswordDescription,
        treasurerAlertEmail: ecomStore.treasurerAlertEmail,
        sandboxEnabled: ecomStore.sandboxEnabled,
        urlHandle: ecomStore.urlHandle,
        fkCurrencyType: ecomStore.fkCurrencyType,
        fkTeam: ecomStore.fkTeam,
        fkAssociation: ecomStore.fkAssociation,
        team: ecomStore.team,
        association: ecomStore.association,
        ecomStoreWindow: ecomStore.ecomStoreWindow
          ? {
              closeDate: formatUtcDate(ecomStore.ecomStoreWindow.closeDate),
              createdAt: formatUtcDate(ecomStore.ecomStoreWindow.createdAt)
            }
          : null,
        hasUnsavedChanges: false,
        loading: false
      });
    }
  }, [ecomStore, loading]);

  useEffect(() => {
    if (!loading && modalAction) {
      switch (modalAction) {
        case 'logo':
          onOpenModal(MODAL_ACTION_ENUM.UPLOAD_LOGO);
          break;
        case 'payment-provider':
          onOpenModal(MODAL_ACTION_ENUM.CONNECT_PAYMENT_PROVIDER);
          break;
        case 'claim-escrow-payments':
          onOpenModal(MODAL_ACTION_ENUM.CLAIM_ESCROW_PAYMENTS);
          break;
        case 'sales-window':
          onOpenModal(MODAL_ACTION_ENUM.STORE_WINDOW);
          break;
        case 'team':
          onOpenModal(MODAL_ACTION_ENUM.CHANGE_TEAM);
          break;
        case 'sandbox':
          onOpenModal(
            ecomStore && !ecomStore.sandboxEnabled
              ? MODAL_ACTION_ENUM.SANDBOX_ON
              : MODAL_ACTION_ENUM.SANDBOX_OFF
          );
          break;
        default:
          break;
      }
    }
  }, [modalAction, loading]);

  const onSaveStoreSettings = () => {
    submitFormState((formData, onErrorCallback) =>
      updateStore(
        ecomStore.pkEcomStore,
        formData,
        (data) => {
          triggerNotification('Store updated!', 'success');
          fetchEcommerceAdminData();
          if (
            data.sandboxEnabled ||
            state.ecomStoreSetupSummary.value?.ecomStore.sandboxEnabled
          ) {
            fetchStoreSetupSummary(authState.pkEcomStore, {skipLoading: true});
          }
        },
        onErrorCallback
      )
    );
  };

  return (
    <AppStack style={{ flex: 1, alignItems: 'center' }}>
      {!loading && hasInitialized ? (
        <AppStack
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSaveStoreSettings();
          }}
          p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
          style={{
            flex: 1,
            margin: 'auto',
            width: '100%',
            maxWidth: 1100,
            paddingTop: 16,
            gap: 16
          }}
        >
          <AppFlexbox
            p={{ base: '0px 8px', sm: 0 }}
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <AppText style={{ fontSize: 22, fontWeight: 700 }}>
              Store settings
            </AppText>
          </AppFlexbox>
          <AppStack style={{ gap: isLargeMobileOrSmaller ? 20 : 50 }}>
            <SettingCard
              description="Manage core settings for your store, including the store name, support email, and other essential configurations."
              title="General"
            >
              <TextInput
                disabled={isSubmitting}
                label="Store name"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    name: e.currentTarget.value,
                    hasUnsavedChanges: true
                  })
                }
                required
                value={formState?.name ?? ''}
              />
              <AppFlexbox style={{ flex: 1 }}>
                <TextInput
                  disabled={isSubmitting}
                  label="Domain"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      domain: e.currentTarget.value,
                      hasUnsavedChanges: true
                    })
                  }
                  style={{ flex: 1 }}
                  value={formState.domain ?? ''}
                />

                <TextInput
                  disabled={isSubmitting}
                  label="Support email"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      supportEmail: e.currentTarget.value,
                      hasUnsavedChanges: true
                    })
                  }
                  required
                  style={{ flex: 1 }}
                  type="email"
                  value={formState.supportEmail ?? ''}
                />
              </AppFlexbox>

              <Textarea
                autosize
                disabled={isSubmitting}
                label="Description"
                minRows={8}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    description: e.currentTarget.value
                  })
                }
                value={formState.description ?? ''}
              />
            </SettingCard>

            <Divider />

            <SettingCard
              description="Define the default branding that will be applied to emails and other areas of the platform. Customize your store's visual identity with colors, logos, and additional branding elements to ensure a cohesive and professional appearance across all communications and interactions."
              title="Branding"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Default logo</AppText>
                <AppStack style={{ fontSize: 14, color: '#666' }}>
                  Used to pupulate emails and other areas of the store.
                </AppStack>
                {formState.logoImageUrl ? (
                  <AppStack style={{ gap: 0 }}>
                    <AppStack
                      style={{
                        width: '100%',
                        height: 150,
                        backgroundColor: 'rgba(240, 240, 240, 1)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8
                      }}
                    >
                      <AppStack
                        style={{
                          height: '100%',
                          width: 150,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Image
                          fit="contain"
                          h="100%"
                          src={formState.logoImageUrl}
                          w="100%"
                        />
                      </AppStack>
                    </AppStack>
                    <Button.Group style={{ flex: 1 }}>
                      <Button
                        disabled={isSubmitting}
                        leftSection={<Pencil size={18} />}
                        onClick={() =>
                          onOpenModal(MODAL_ACTION_ENUM.UPLOAD_LOGO)
                        }
                        size="compact-md"
                        style={{
                          flex: 1,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0
                        }}
                        variant="default"
                      >
                        Change
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        leftSection={<Trash size={18} />}
                        onClick={() => {
                          setFormState({
                            ...formState,
                            logoImageUrl: '',
                            hasUnsavedChanges: true
                          });
                        }}
                        size="compact-md"
                        style={{
                          flex: 1,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0
                        }}
                        variant="default"
                      >
                        Remove
                      </Button>
                    </Button.Group>
                  </AppStack>
                ) : (
                  <SettingSelector
                    description="jpg, png, svg, webp, or heic. Max file size 5MB."
                    disabled={isSubmitting}
                    icon={<PhotoPlus color="#666" size={24} />}
                    onClick={() => onOpenModal(MODAL_ACTION_ENUM.UPLOAD_LOGO)}
                    title="Select your default logo"
                  />
                )}
                <AppStack style={{ display: 'none' }}>
                  <MediaDropzone
                    disabled={
                      modalState.isOpen &&
                      modalState.action === MODAL_ACTION_ENUM.UPLOAD_LOGO
                    }
                    enableMediaSelect
                    files={
                      formState.logoImageUrl
                        ? [
                            {
                              src: formState.logoImageUrl,
                              preview: formState.logoImageUrl
                            }
                          ]
                        : []
                    }
                    fkEcomStore={authState.pkEcomStore}
                    height={275}
                    isOpen={
                      modalState.isOpen &&
                      modalState.action === MODAL_ACTION_ENUM.UPLOAD_LOGO
                    }
                    maxSelectCount={1}
                    onClose={onCloseModal}
                    onUpload={(media) => {
                      setFormState({
                        ...formState,
                        logoImageUrl: media[0]?.src ?? '',
                        hasUnsavedChanges: true
                      });
                    }}
                    uploadOnDrop
                  />
                </AppStack>
              </AppStack>

              <Divider />

              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Colors</AppText>
                <AppStack style={{ fontSize: 14, color: '#666' }}>
                  Used to set the default color schemes for your store
                  templates.
                </AppStack>
              </AppStack>

              <AppFlexbox style={{ gap: 10 }}>
                <AppStack style={{ flex: 1, gap: 16 }}>
                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Primary
                    </AppText>
                    <CustomColorInput
                      control={
                        <AppStack>
                          <SettingSelector
                            description={formState.primaryColor}
                            disabled={isSubmitting}
                            icon={<BucketDroplet color="#666" size={24} />}
                            leftSection={
                              formState.primaryColor ? (
                                <AppStack
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: formState.primaryColor
                                  }}
                                />
                              ) : null
                            }
                            onClick={() => {}}
                            onRemove={
                              formState.primaryColor
                                ? () => {
                                    setFormState({
                                      ...formState,
                                      primaryColor: '',
                                      hasUnsavedChanges: true
                                    });
                                  }
                                : null
                            }
                            title={
                              formState.primaryColor
                                ? 'Primary color'
                                : 'Select a primary color'
                            }
                          />
                        </AppStack>
                      }
                      label="Primary color"
                      onChange={(color) =>
                        setFormState({
                          ...formState,
                          primaryColor: color,
                          hasUnsavedChanges: true
                        })
                      }
                      value={formState.primaryColor ?? ''}
                    />

                    {formState.primaryColor && (
                      <CustomColorInput
                        control={
                          <AppStack>
                            <SettingSelector
                              description={formState.primaryContrastColor}
                              disabled={isSubmitting}
                              leftSection={
                                formState.primaryContrastColor ? (
                                  <AppStack
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      backgroundColor:
                                        formState.primaryContrastColor
                                    }}
                                  />
                                ) : null
                              }
                              onClick={() => {}}
                              rightSection={<></>}
                              title={
                                formState.primaryContrastColor
                                  ? 'Contrasting color'
                                  : 'Select a contrasting color'
                              }
                            />
                          </AppStack>
                        }
                        label="Contrasting color"
                        onChange={(color) =>
                          setFormState({
                            ...formState,
                            primaryContrastColor: color,
                            hasUnsavedChanges: true
                          })
                        }
                        value={formState.primaryContrastColor ?? ''}
                      />
                    )}
                  </AppStack>

                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Secondary
                    </AppText>
                    <CustomColorInput
                      control={
                        <AppStack>
                          <SettingSelector
                            description={formState.secondaryColor}
                            disabled={isSubmitting}
                            icon={<BucketDroplet color="#666" size={24} />}
                            leftSection={
                              formState.secondaryColor ? (
                                <AppStack
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: formState.secondaryColor
                                  }}
                                />
                              ) : null
                            }
                            onClick={() => {}}
                            onRemove={
                              formState.secondaryColor
                                ? () => {
                                    setFormState({
                                      ...formState,
                                      secondaryColor: '',
                                      hasUnsavedChanges: true
                                    });
                                  }
                                : null
                            }
                            title={
                              formState.secondaryColor
                                ? 'Secondary color 1'
                                : 'Select a secondary color'
                            }
                          />
                        </AppStack>
                      }
                      label="Secondary color 1"
                      onChange={(color) =>
                        setFormState({
                          ...formState,
                          secondaryColor: color,
                          hasUnsavedChanges: true
                        })
                      }
                      value={formState.secondaryColor ?? ''}
                    />

                    {formState.secondaryColor && (
                      <>
                        <CustomColorInput
                          control={
                            <AppStack>
                              <SettingSelector
                                description={formState.secondaryAltColor}
                                disabled={isSubmitting}
                                icon={<BucketDroplet color="#666" size={24} />}
                                leftSection={
                                  formState.secondaryAltColor ? (
                                    <AppStack
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor:
                                          formState.secondaryAltColor
                                      }}
                                    />
                                  ) : null
                                }
                                onClick={() => {}}
                                onRemove={
                                  formState.secondaryAltColor
                                    ? () => {
                                        setFormState({
                                          ...formState,
                                          secondaryAltColor: '',
                                          hasUnsavedChanges: true
                                        });
                                      }
                                    : null
                                }
                                title={
                                  formState.secondaryAltColor
                                    ? 'Secondary color 2'
                                    : 'Select another secondary color'
                                }
                              />
                            </AppStack>
                          }
                          label="Secondary color 2"
                          onChange={(color) =>
                            setFormState({
                              ...formState,
                              secondaryAltColor: color,
                              hasUnsavedChanges: true
                            })
                          }
                          value={formState.secondaryAltColor ?? ''}
                        />

                        <CustomColorInput
                          control={
                            <AppStack>
                              <SettingSelector
                                description={formState.secondaryContrastColor}
                                disabled={isSubmitting}
                                leftSection={
                                  formState.secondaryContrastColor ? (
                                    <AppStack
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor:
                                          formState.secondaryContrastColor
                                      }}
                                    />
                                  ) : null
                                }
                                onClick={() => {}}
                                rightSection={<></>}
                                title={
                                  formState.primaryContrastColor
                                    ? 'Contrasting color'
                                    : 'Select a contrasting color'
                                }
                              />
                            </AppStack>
                          }
                          label="Contrasting color"
                          onChange={(color) =>
                            setFormState({
                              ...formState,
                              secondaryContrastColor: color,
                              hasUnsavedChanges: true
                            })
                          }
                          value={formState.secondaryContrastColor ?? ''}
                        />
                      </>
                    )}
                  </AppStack>
                </AppStack>
              </AppFlexbox>
            </SettingCard>

            <Divider />

            <SettingCard
              description="Manage the visibility of your store by enabling sandbox mode. This feature allows you to hide the store from public view, making it accessible only to authorized members while updates or changes are being made."
              title="Visibility"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Sandbox mode</AppText>
                <AppStack style={{ fontSize: 14, color: '#666' }}>
                  Enable sandbox mode to hide your stroe from public view while
                  making changes.
                </AppStack>
              </AppStack>

              <SettingSelector
                description={
                  formState.sandboxEnabled
                    ? 'Store is invisible'
                    : 'Store is live'
                }
                descriptionColor={
                  formState.sandboxEnabled ? '#c40000' : '#067D62'
                }
                disabled={isSubmitting}
                leftSection={
                  formState.sandboxEnabled ? (
                    <EyeOff color="#c40000" size={32} />
                  ) : (
                    <EyeCheck color="#067D62" size={32} />
                  )
                }
                onClick={() => {
                  if (formState.sandboxEnabled) {
                    onOpenModal(MODAL_ACTION_ENUM.SANDBOX_OFF);
                  }
                  else {
                    onOpenModal(MODAL_ACTION_ENUM.SANDBOX_ON);
                  }
                }}
                rightSection={
                  <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {formState.sandboxEnabled
                        ? 'Click to go live'
                        : 'Click to hide'}
                    </AppText>
                    <ChevronRight size={18} />
                  </AppFlexbox>
                }
                title={
                  formState.sandboxEnabled
                    ? 'Sandbox Enabled'
                    : 'Sandbox Disabled'
                }
                withBorder
              />

              <Divider />

              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Store URL</AppText>
                <AppStack style={{ fontSize: 14, color: '#666' }}>
                  Your store URL is the link to your store. You can share this
                  link with your customers to access your store.
                </AppStack>
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText
                    style={{
                      fontSize: 14,
                      color: '#666',
                      whiteSpace: 'nowrap',
                      overflow: 'unset'
                    }}
                  >
                    Current url:
                  </AppText>
                  <Anchor
                    component={Link}
                    style={{
                      fontSize: 14,
                      color: 'dodgerblue',
                      fontWeight: 500,
                      wordBreak: 'break-word'
                    }}
                    target="_blank"
                    to={`https://store.sportsheadz.com/store/${formState.urlHandle}`}
                    underline="always"
                  >
                    https://store.sportsheadz.com/store/
                    {formState.urlHandle}
                  </Anchor>
                </AppFlexbox>
              </AppStack>

              <CustomInputModal
                control={
                  <SettingSelector
                    description={`/${formState.urlHandle}`}
                    disabled={isSubmitting}
                    leftSection={<World color="dodgerblue" size={32} />}
                    onClick={() => {}}
                    rightSection={
                      <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          Click to change
                        </AppText>
                        <ChevronRight size={18} />
                      </AppFlexbox>
                    }
                    title="URL handle"
                    withBorder
                  />
                }
                inputProps={{
                  label: 'URL handle',
                  placeholder: 'Enter a URL handle for your store'
                }}
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    urlHandle: value
                      ? sterilizeUrlHandle(value)
                      : sterilizeUrlHandle(ecomStore.name),
                    hasUnsavedChanges: true
                  })
                }
                onClear={() =>
                  setFormState({
                    ...formState,
                    urlHandle: '',
                    hasUnsavedChanges: true
                  })
                }
                parser={(value) => sterilizeUrlHandle(value)}
                title="Store URL handle"
                value={formState.urlHandle}
              />

              {/* <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>
                  Password protection
                </AppText>
                <AppStack style={{ fontSize: 14, color: '#666' }}>
                  Restrict access to visitors with the password
                </AppStack>
              </AppStack>

              <CustomInputModal
                control={
                  <SettingSelector
                    description={
                      formState.onlineStorePassword
                        ? 'Protected'
                        : 'Open to public'
                    }
                    descriptionColor={
                      formState.onlineStorePassword ? '#c40000' : '#067D62'
                    }
                    disabled={isSubmitting}
                    leftSection={
                      formState.onlineStorePassword ? (
                        <Lock color="#c40000" size={32} />
                      ) : (
                        <LockOpen color="#067D62" size={32} />
                      )
                    }
                    onClick={() => {}}
                    rightSection={
                      <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {formState.onlineStorePassword
                            ? 'Click to change'
                            : 'Click to setup'}
                        </AppText>
                        <ChevronRight size={18} />
                      </AppFlexbox>
                    }
                    title={
                      formState.onlineStorePassword
                        ? formState.onlineStorePassword
                        : 'Setup a store password.'
                    }
                    withBorder
                  />
                }
                inputProps={{
                  label: 'Password',
                  placeholder: 'Enter a password to hide your store'
                }}
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    onlineStorePassword: value,
                    hasUnsavedChanges: true
                  })
                }
                onClear={
                  formState.onlineStorePassword
                    ? () =>
                        setFormState({
                          ...formState,
                          onlineStorePassword: '',
                          hasUnsavedChanges: true
                        })
                    : null
                }
                title="Store password"
                value={formState.onlineStorePassword}
              />
              {formState.onlineStorePassword && (
                <Textarea
                  autosize
                  disabled={isSubmitting}
                  label="Message to your visitors"
                  minRows={4}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      onlineStorePasswordDescription: e.currentTarget.value,
                      hasUnsavedChanges: true
                    })
                  }
                  value={formState.onlineStorePasswordDescription}
                />
              )} */}
            </SettingCard>

            <Divider />

            <SettingCard
              description="Set the purchase period for your store by selecting closing date. Once the window closes, all sales are final, and vendor payments cannot be recovered. You can update the closing date anytime before the window closes."
              title="Sales window"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Active window</AppText>
                <AppStack style={{ fontSize: 14, color: '#666' }}>
                  Set the closing date for your store. Once the window closes,
                  all sales are final orders will be processed. Refunding vendor
                  product amounts will be unavailable after this point.
                </AppStack>
              </AppStack>

              <SettingSelector
                description={
                  formState.ecomStoreWindow
                    ? formState.ecomStoreWindow.createdAt &&
                      `Started ${dayjs(
                        formatUtcDate(formState.ecomStoreWindow.createdAt)
                      ).format('MMM DD YYYY hh:mm A')}`
                    : 'Action Required'
                }
                descriptionColor={
                  formState.ecomStoreWindow ? '#666' : '#c40000'
                }
                disabled={isSubmitting}
                leftSection={
                  formState.ecomStoreWindow ? (
                    <Check color="#067D62" size={32} />
                  ) : (
                    <AlertCircle color="#c40000" size={32} />
                  )
                }
                onClick={() => {
                  onOpenModal(MODAL_ACTION_ENUM.STORE_WINDOW);
                }}
                rightSection={
                  <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {formState.ecomStoreWindow
                        ? 'Click to edit'
                        : 'Click to setup'}
                    </AppText>
                    <ChevronRight size={18} />
                  </AppFlexbox>
                }
                title={
                  formState.ecomStoreWindow
                    ? `Closes on ${dayjs(
                        formatUtcDate(formState.ecomStoreWindow.closeDate)
                      ).format('MMM DD YYYY hh:mm A')}`
                    : 'Not setup'
                }
                withBorder
              />
            </SettingCard>

            <Divider />

            <SettingCard
              description="Onboard or update your payment provider with Stripe. You can also change the currency for transactions and enable email notifications for every purchase, which will be sent to the designated treasurer alert email."
              title="Payments"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Payment provider</AppText>
                <AppStack style={{ fontSize: 14, color: '#666' }}>
                  Onboard with Stripe and start accepting payments.
                </AppStack>
              </AppStack>

              <SettingSelector
                description={
                  !ecomStore.ecomStorePaymentProvider
                    ? 'Not connected'
                    : ecomStore.ecomStorePaymentProvider?.completed
                    ? 'Connected'
                    : 'Action required'
                }
                descriptionColor={
                  ecomStore.ecomStorePaymentProvider?.completed
                    ? '#067D62'
                    : '#c40000'
                }
                disabled={isSubmitting}
                leftSection={
                  ecomStore.ecomStorePaymentProvider?.completed ? (
                    <Check color="#067D62" size={32} />
                  ) : (
                    <AlertCircle
                      color={
                        ecomStore.ecomStorePaymentProvider
                          ? '#eca70a'
                          : '#c40000'
                      }
                      size={32}
                    />
                  )
                }
                onClick={() => {
                  onOpenModal(MODAL_ACTION_ENUM.CONNECT_PAYMENT_PROVIDER);
                }}
                rightSection={
                  <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {ecomStore.ecomStorePaymentProvider
                        ? 'Click to edit'
                        : 'Click to setup'}
                    </AppText>
                    <ChevronRight size={18} />
                  </AppFlexbox>
                }
                title={
                  ecomStore.ecomStorePaymentProvider
                    ? `Account ID: ${ecomStore.ecomStorePaymentProvider.accountId}`
                    : 'Click here to onboard with Stripe.'
                }
                withBorder
              />
              {state.ecomStoreSetupSummary.value && (
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Escrow payments
                  </AppText>
                  <SettingSelector
                    description={
                      state.ecomStoreSetupSummary.value.escrowBalance > 0
                        ? 'Action required'
                        : ''
                    }
                    descriptionColor={
                      state.ecomStoreSetupSummary.value.escrowBalance === 0
                        ? '#067D62'
                        : '#eca70a'
                    }
                    disabled={isSubmitting}
                    leftSection={
                      state.ecomStoreSetupSummary.value.escrowBalance === 0 ? (
                        <Check color="#067D62" size={32} />
                      ) : (
                        <AlertCircle color="#eca70a" size={32} />
                      )
                    }
                    onClick={() => {
                      navigate('/merchant/escrow-payments');
                    }}
                    rightSection={
                      state.ecomStoreSetupSummary.value.escrowBalance > 0 && (
                        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            Click to view
                          </AppText>
                          <ChevronRight size={18} />
                        </AppFlexbox>
                      )
                    }
                    title={`You have ${currencyFormat(
                      state.ecomStoreSetupSummary.value.escrowBalance / 100
                    )} in escrow`}
                    withBorder
                  />
                </AppStack>
              )}

              <Divider />

              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Currency</AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Set the currency for transactions in your store. This will be
                  used to display prices and process payments. You will only be
                  able to add products in the selected currency. It is suggested
                  to create two different stores if you want to sell in multiple
                  currencies.
                </AppText>
              </AppStack>

              <SettingSelector
                disabled={isSubmitting}
                leftSection={<CurrencyDollar size={32} />}
                onClick={() => onOpenModal(MODAL_ACTION_ENUM.CHANGE_CURRENCY)}
                rightSection={
                  <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Click to change
                    </AppText>
                    <ChevronRight size={18} />
                  </AppFlexbox>
                }
                title={selectedCurrency.fullLabel}
                withBorder
              />

              <Divider />

              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Treasurer Email</AppText>
                <AppStack style={{ fontSize: 14, color: '#666' }}>
                  Receive email alerts when online purchases are made.
                </AppStack>
              </AppStack>

              <CustomInputModal
                control={
                  <SettingSelector
                    description={formState.treasurerAlertEmail || 'Not setup'}
                    descriptionColor={
                      !formState.treasurerAlertEmail ? '#c40000' : null
                    }
                    disabled={isSubmitting}
                    leftSection={
                      formState.treasurerAlertEmail ? (
                        <Mail color="#067D62" size={32} />
                      ) : (
                        <MailOff color="#c40000" size={32} />
                      )
                    }
                    onClick={() => {}}
                    rightSection={
                      <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {formState.treasurerAlertEmail
                            ? 'Click to change'
                            : 'Click to setup'}
                        </AppText>
                        <ChevronRight size={18} />
                      </AppFlexbox>
                    }
                    title={
                      formState.treasurerAlertEmail
                        ? 'Current email'
                        : 'Setup a treasurer email'
                    }
                    withBorder
                  />
                }
                inputProps={{
                  label: 'Email address',
                  placeholder: 'email@domain.com',
                  type: 'email'
                }}
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    treasurerAlertEmail: value,
                    hasUnsavedChanges: true
                  })
                }
                onClear={
                  formState.treasurerAlertEmail
                    ? () =>
                        setFormState({
                          ...formState,
                          treasurerAlertEmail: '',
                          hasUnsavedChanges: true
                        })
                    : null
                }
                title="Treasurer Email"
                value={formState.treasurerAlertEmail}
              />
            </SettingCard>

            <Divider />

            <SettingCard
              description="Manage your connections with SportsHeads, including your team and association, to promote them across our other platforms."
              title="Connections"
            >
              <AppStack style={{ gap: 10 }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>Team</AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Connect your store to your team to promote it across our
                    other platforms. This will also allow you to access
                    team-specific features. You can only connect to one team at
                    a time. If you want to connect to multiple teams, you will
                    need to create a separate store for each team.
                  </AppText>
                </AppStack>

                <SettingSelector
                  description={!formState.team ? 'No team connected' : null}
                  descriptionColor={!formState.team ? '#c40000' : null}
                  disabled={isSubmitting}
                  leftSection={
                    formState.team ? (
                      <Image
                        fit="contain"
                        h="100%"
                        src={formState.team.image}
                        w="100%"
                      />
                    ) : null
                  }
                  onClick={() => onOpenModal(MODAL_ACTION_ENUM.CHANGE_TEAM)}
                  rightSection={
                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {formState.team ? 'Click to change' : 'Click to setup'}
                      </AppText>
                      <ChevronRight size={18} />
                    </AppFlexbox>
                  }
                  title={formState.team?.name ?? 'Click here to add a team'}
                  withBorder
                />
              </AppStack>

              <Divider />

              <AppStack style={{ gap: 10 }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>Association</AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Connect your store to your association to promote it across
                    our other platforms.
                  </AppText>
                </AppStack>

                <SettingSelector
                  description={
                    !formState.association
                      ? 'No association connected'
                      : formState.association.domain
                  }
                  descriptionColor={!formState.association ? '#c40000' : null}
                  disabled={isSubmitting}
                  leftSection={
                    formState.association ? (
                      <Image
                        fit="contain"
                        h="100%"
                        src={formState.association.image}
                        w="100%"
                      />
                    ) : null
                  }
                  onClick={() =>
                    onOpenModal(MODAL_ACTION_ENUM.CHANGE_ASSOCIATION)
                  }
                  rightSection={
                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {formState.association
                          ? 'Click to change'
                          : 'Click to setup'}
                      </AppText>
                      <ChevronRight size={18} />
                    </AppFlexbox>
                  }
                  title={
                    formState.association?.name ??
                    'Click here to add an association'
                  }
                  withBorder
                />
              </AppStack>
            </SettingCard>
          </AppStack>

          <AppFlexbox
            p={{ base: 16, sm: '16px 0px' }}
            style={{
              justifyContent: 'flex-end',
              flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
            }}
          >
            <Button
              color="dark"
              disabled={!hasUnsavedChanges}
              id="save-button"
              loading={isSubmitting}
              radius="md"
              size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
              type="submit"
              variant="filled"
            >
              Save Changes
            </Button>
          </AppFlexbox>

          {ConfirmDiscardModal}

          <OnboardVendorPaymentProviderModal
            ecomStore={ecomStore}
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.CONNECT_PAYMENT_PROVIDER
            }
            onClaimEscrowPayments={() =>
              onOpenModal(MODAL_ACTION_ENUM.CLAIM_ESCROW_PAYMENTS)
            }
            onClose={onCloseModal}
          />

          <LaunchStoreModal
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.SANDBOX_OFF
            }
            onClose={onCloseModal}
            onConfirm={() => {
              onCloseModal();
              setFormState({
                ...formState,
                sandboxEnabled: false,
                hasUnsavedChanges: true
              });
            }}
          />

          <ConfirmModal
            confirmActionColor="red"
            confirmActionText="Yes, hide store"
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.SANDBOX_ON
            }
            message=""
            onCancel={onCloseModal}
            onConfirm={() => {
              onCloseModal();
              setFormState({
                ...formState,
                sandboxEnabled: true,
                hasUnsavedChanges: true
              });
            }}
            title="Hide store?"
          >
            <AppStack>
              <AppText style={{ fontSize: 14 }}>
                Are you certain you want to <b>hide</b> this <b>store</b>? Doing
                so will make the store <b>private</b> and <b>unavailable</b> for
                users to view or purchase your products.
              </AppText>

              <AppCard
                radius="md"
                shadow="none"
                style={{ padding: 10 }}
                withBorder
              >
                <Checkbox
                  label="Any products that have already been purchased will still need to be fulfilled."
                  required
                />
              </AppCard>
            </AppStack>
          </ConfirmModal>

          <ChangeCurrencyModal
            fkCurrencyType={formState.fkCurrencyType}
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.CHANGE_CURRENCY
            }
            onClose={onCloseModal}
            onConfirm={(fkCurrencyType) => {
              setFormState({
                ...formState,
                fkCurrencyType
              });
              onCloseModal();
            }}
            sandboxEnabled={formState.sandboxEnabled}
          />

          <CreateStoreWindowModal
            ecomStoreWindow={formState.ecomStoreWindow}
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.STORE_WINDOW
            }
            onClose={onCloseModal}
            onConfirm={({ closeDate }) => {
              setFormState({
                ...ecomStore,
                ecomStoreWindow: {
                  ...formState.ecomStoreWindow,
                  closeDate
                }
              });
              onCloseModal();
            }}
          />

          <SelectStoreTeamModal
            association={formState.association}
            isAssociationSelect={
              modalState.action === MODAL_ACTION_ENUM.CHANGE_ASSOCIATION
            }
            isOpen={
              modalState.isOpen &&
              (modalState.action === MODAL_ACTION_ENUM.CHANGE_TEAM ||
                modalState.action === MODAL_ACTION_ENUM.CHANGE_ASSOCIATION)
            }
            onClose={onCloseModal}
            onConfirm={(item, regAssociation) => {
              if (modalState.action === MODAL_ACTION_ENUM.CHANGE_ASSOCIATION) {
                setFormState({
                  ...formState,
                  fkTeam:
                    formState.team?.association.pkAssociation ===
                    item.pkAssociation
                      ? formState.fkTeam
                      : null,
                  fkAssociation: item.pkAssociation,
                  team:
                    formState.team?.association.pkAssociation ===
                    item.pkAssociation
                      ? formState.team
                      : null,
                  association: item,
                  ...(regAssociation
                    ? {
                        primaryColor: regAssociation?.primaryColor ?? '',
                        secondaryColor: regAssociation?.secondaryColor ?? ''
                      }
                    : {})
                });
              }
              else {
                setFormState({
                  ...formState,
                  fkTeam: item.pkTeam,
                  fkAssociation: item.association.pkAssociation,
                  team: item,
                  association: item.association,
                  ...(regAssociation
                    ? {
                        primaryColor: regAssociation?.primaryColor ?? '',
                        secondaryColor: regAssociation?.secondaryColor ?? ''
                      }
                    : {})
                });
              }

              onCloseModal();
            }}
            onRemove={() => {
              if (modalState.action === MODAL_ACTION_ENUM.CHANGE_ASSOCIATION) {
                setFormState({
                  ...formState,
                  fkTeam: null,
                  fkAssociation: -1,
                  team: null,
                  association: null
                });
                onCloseModal();
              }
              else {
                setFormState({
                  ...formState,
                  fkTeam: -1,
                  team: null
                });
                onCloseModal();
              }
            }}
            team={formState.team}
          />
        </AppStack>
      ) : (
        <AppStack
          p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
          style={{
            flex: 1,
            margin: 'auto',
            width: '100%',
            maxWidth: 1100,
            paddingTop: 16,
            gap: 16
          }}
        >
          <AppFlexbox
            p={{ base: '0px 8px', sm: 0 }}
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Skeleton height={34} width={160} />
          </AppFlexbox>
          <AppStack style={{ flex: 1, gap: isLargeMobileOrSmaller ? 20 : 50 }}>
            <SettingCard loading loadingHeight={370} />
            <Divider />
            <SettingCard loading loadingHeight={675} />
            <Divider />
            <SettingCard loading loadingHeight={135} />
            <Divider />
            <SettingCard loading loadingHeight={75} />
          </AppStack>
        </AppStack>
      )}
    </AppStack>
  );
};

export default StoreSettingsView;
