import { MIME_TYPES } from '@mantine/dropzone';

const PRODUCTION = true;
const TESTING = false;
const LOCAL_API = false;
const CONSOLE_DEBUG = false;

const COGNITO_SETTING_DEV = {
  Auth: {
    identityPoolId: 'us-east-1:5111a894-2c2c-41b1-a29a-f717eaa9aa31',
    region: 'us-east-1',
    userPoolId: 'us-east-1_Z2PFfqRF1',
    userPoolWebClientId: '624bs7emni9leiuspgcrrs9j8s',
    mandatorySignIn: true
  },
  Storage: {
    bucket: 'hockeyheadz-storage',
    region: 'us-east-1'
  }
};

const COGNITO_SETTING_PROD = {
  Auth: {
    identityPoolId: 'us-east-1:93ca516d-e2c4-4ad6-b44c-4b05604c8bd7',
    region: 'us-east-1',
    userPoolId: 'us-east-1_FAoqNjX2j',
    userPoolWebClientId: '4fdno1j1g5jukptomkdifu3gao',
    mandatorySignIn: true
  },
  Storage: {
    bucket: 'hockeyheadz-storage',
    region: 'us-east-1'
  }
};

const S3_PUBLIC_URL = 'https://hockeyheadz-storage.s3.amazonaws.com/public/';

const PASSWORD_CONSTRAINTS = {
  length: 8,
  regexList: {
    capRegex: /\w*[A-Z]/,
    numRegex: /[0-9]/,
    symRegex: /[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/
  }
};

const PAYMENT_PROVIDERS = {
  stripe: {
    value: 1,
    label: 'Stripe'
  }
};

const CURRENCY_TYPE_ENUM = {
  CAD: 1,
  USD: 2
};

const CURRENCY_TYPE_LIST = [
  {
    label: 'Canadian Dollar',
    fullLabel: 'Canadian Dollar (CAD $)',
    abbr: 'CAD',
    symbol: '$',
    value: CURRENCY_TYPE_ENUM.CAD
  },
  {
    label: 'US Dollar',
    fullLabel: 'US Dollar (USD $)',
    abbr: 'USD',
    symbol: '$',
    value: CURRENCY_TYPE_ENUM.USD
  }
];

const INPUT_CONTROL_TYPE_ENUM = {
  TEXT: 1,
  TEXT_INPUT: 2,
  DROP_DOWN: 3,
  DATE: 4,
  RADIO_GROUP: 5,
  CHECKBOX: 6,
  FILE_DOWNLOAD: 7,
  SIGNATURE: 8,
  FILE_UPLOAD: 9,
  SLIDER: 10,
  BUTTON_GROUP: 11,
  LINK: 12,
  COLOR: 13
};

const INPUT_CONTROL_TYPE_OPTIONS = [
  {
    label: 'Text input',
    value: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  {
    label: 'Dropdown',
    value: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN
  },
  {
    label: 'Date',
    value: INPUT_CONTROL_TYPE_ENUM.DATE
  },
  {
    label: 'Checkbox',
    value: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  {
    label: 'File upload',
    value: INPUT_CONTROL_TYPE_ENUM.FILE_UPLOAD
  }
];

const BULK_EDIT_FIELD_TYPE_ENUM = {
  TEXT: 1,
  TEXT_PRICE: 2,
  SELECT: 3,
  SELECT_MULTIPLE: 4,
  NUMBER: 5,
  PRICE: 6,
  TEXT_INPUT: 7,
  DATE_TIME: 8,
  CHECKBOX: 9
};

const VIEW_ACTIONS_ENUM = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
  RESTORE: 'RESTORE',
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT',
  CHANGE_STATUS: 'CHANGE_STATUS',
  ARCHIVE: 'ARCHIVE',
  UPLOAD: 'UPLOAD',
  ERROR: 'ERROR',
  DISCARD: 'DISCARD',
  REFUND: 'REFUND'
};

const ECOM_PRODUCT_PRICING_TYPE_ENUM = {
  STATIC: '1',
  PERCENTAGE_ADJUSTMENT: '2',
  FLAT_ADJUSTMENT: '3'
};

const ECOM_PRODUCT_PRICING_TYPE_LIST = [
  {
    value: ECOM_PRODUCT_PRICING_TYPE_ENUM.PERCENTAGE_ADJUSTMENT,
    label: 'Percentage Adjustment'
  },
  {
    value: ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT,
    label: 'Flat Adjustment'
  }
];

const ECOM_PRODUCT_STATUS_ENUM = {
  ACTIVE: '1',
  DRAFT: '2',
  ARCHIVED: '3',
  PENDING_ACTION: '4'
};

const ECOM_PRODUCT_STATUS_LIST = [
  {
    value: ECOM_PRODUCT_STATUS_ENUM.ACTIVE,
    label: 'Active'
  },
  {
    value: ECOM_PRODUCT_STATUS_ENUM.DRAFT,
    label: 'Draft'
  },
  {
    value: ECOM_PRODUCT_STATUS_ENUM.ARCHIVED,
    label: 'Archived'
  },
  {
    value: ECOM_PRODUCT_STATUS_ENUM.PENDING_ACTION,
    label: 'Pending Action'
  }
];

const ECOM_CART_PRODUCT_STATUS_ENUM = {
  PENDING: '1',
  PENDING_PAYMENT: '5',
  ACCEPTED: '2'
};

const WEIGHT_UNIT_ENUM = {
  G: '1',
  OZ: '2',
  KG: '3',
  LB: '4'
};

const WEIGHT_UNIT_LIST = [
  {
    value: WEIGHT_UNIT_ENUM.LB,
    label: 'lb'
  },
  {
    value: WEIGHT_UNIT_ENUM.OZ,
    label: 'oz'
  },
  {
    value: WEIGHT_UNIT_ENUM.KG,
    label: 'kg'
  },
  {
    value: WEIGHT_UNIT_ENUM.G,
    label: 'g'
  }
];

const SUPPORTED_MEDIA_TYPES = [
  MIME_TYPES.jpeg,
  MIME_TYPES.png,
  MIME_TYPES.svg,
  MIME_TYPES.webp,
  MIME_TYPES.heic
];

const ECOM_WEBSTORE_SECTION_BOUND_TYPE = {
  HEADER: 1,
  BODY: 2,
  FOOTER: 3
};

const ECOM_WEBSTORE_SECTION_TYPE_ENUM = {
  HEADER: 1,
  ANNOUNCEMENT_BAR: 2,
  FEATURED_COLLECTION: 3,
  FEATURED_PRODUCT: 4,
  COLLECTION_LIST: 5,
  RICH_TEXT: 6,
  IMAGE_WITH_TEXT: 7,
  IMAGE_BANNER: 8,
  SLIDESHOW: 9,
  COLLAGE: 10,
  MULTICOLUMN: 11,
  MULTIROW: 12,
  COLLAPSIBLE_CONTENT: 13,
  EMAIL_SIGNUP: 14,
  CONTACT_FORM: 15,
  VIDEO: 16,
  BLOG_POSTS: 17,
  PAGE: 18,
  FOOTER: 19,
  ACCOUNT_CONTENT: 20,
  PRODUCT_INFORMATION: 21,
  CHECKOUT_CONTENT: 22,
  PRODUCT_GRID: 23,
  RESOURCE_BANNER: 24,
  COLLECTION_GRID: 25,
  LAYERED_BANNER: 26
};

const ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM = {
  TEXT_LINK: 1,
  TEXT_OVER_IMAGE: 2,
  HEADING: 3,
  SUBHEADING: 4,
  TITLE: 5,
  TEXT: 6,
  CAPTION: 7,
  BUTTON_GROUP: 8,
  EMAIL_FORM: 9,
  PRICE: 10,
  VARIANT_PICKER: 11,
  QUANTITY_SELECTOR: 12,
  BUY_BUTTONS: 13,
  SKU: 14,
  RATING: 15,
  ICON_WITH_TEXT: 16,
  IMAGE: 17,
  COLLECTION: 18,
  PRODUCT: 19,
  VIDEO: 20,
  COLUMN_CONTENT: 21,
  ROW_CONTENT: 22,
  ACCORDION: 23,
  MENU: 24,
  BRAND_INFORMATION: 25,
  DESCRIPTION: 26,
  INVENTORY_STATUS: 27,
  CONTENT_CARD: 28,
  VENDOR_TITLE: 29
};

const ECOM_WEBSTORE_PAGE_TYPE_ENUM = {
  HOME: 1,
  PRODUCT: 2,
  COLLECTION: 3,
  COLLECTION_LIST: 4,
  CART: 5,
  CHECKOUT: 6,
  PAGE: 7,
  BLOG: 8,
  BLOG_POST: 9,
  SEARCH: 10,
  PASSWORD: 11,
  NOT_FOUND: 12,
  CUSTOMER_LOGIN: 13,
  CUSTOMER_REGISTER: 14,
  CUSTOMER_RESET_PASSWORD: 15,
  CUSTOMER_ACCOUNT: 16,
  CUSTOMER_ORDERS: 17,
  CUSTOMER_ORDER_DETAILS: 18,
  CUSTOMER_ADDRESSES: 19
};

const ECOM_ENTITY_TYPES = {
  ecomStoreThemeColorScheme: 'ecomStoreThemeColorScheme',
  ecomStoreMedia: 'ecomStoreMedia',
  ecomStoreProduct: 'ecomStoreProduct',
  ecomStoreCollection: 'ecomStoreCollection',
  ecomStorePage: 'ecomStorePage',
  ecomStoreMenu: 'ecomStoreMenu'
};

const PAYMENT_PROVIDER_LIST = [
  {
    label: 'Stripe',
    value: 1
  },
  {
    label: 'SportsPay',
    value: 2
  }
];

const CATALOG_SORT_OPTIONS = [
  {
    value: 'name',
    label: 'Relevance'
  },
  {
    value: 'top_selling_desc',
    label: 'Top Selling',
    path: 'top-sellers',
    pathTitle: 'Top Sellers'
  },
  {
    value: 'created_at_desc',
    label: 'Newest',
    path: 'new',
    pathTitle: 'Newest'
  },
  {
    value: 'price',
    label: 'Price'
  },
  {
    value: 'trending_desc',
    label: 'Popularity',
    path: 'trending',
    pathTitle: 'Trending'
  }
];

const PAYMENT_TYPE_ENUM = {
  CHEQUE: '1',
  CASH: '2',
  E_TRANSFER: '3',
  JUMPSTART: '4',
  REFUND: '5',
  INVOICE: '6',
  STRIPE: '7',
  AFTERPAY: '8',
  FORGIVENESS: '9',
  SPORTSPAY: '10',
  SPORTSPAY_INSTALLMENTS: '11'
};

const ECOM_SHIPPING_CARRIER_ENUM = {
  CANADA_POST: 'Canada Post',
  UPS: 'UPS',
  FEDEX: 'FedEx',
  PUROLATOR: 'Purolator',
  DHL: 'DHL',
  USPS: 'USPS',
  OTHER: 'Other'
};

const ECOM_SHIPPING_CARRIER_LIST = [
  {
    label: 'Canada Post',
    value: ECOM_SHIPPING_CARRIER_ENUM.CANADA_POST
  },
  {
    label: 'UPS',
    value: ECOM_SHIPPING_CARRIER_ENUM.UPS
  },
  {
    label: 'FedEx',
    value: ECOM_SHIPPING_CARRIER_ENUM.FEDEX
  },
  {
    label: 'Purolator',
    value: ECOM_SHIPPING_CARRIER_ENUM.PUROLATOR
  },
  {
    label: 'DHL',
    value: ECOM_SHIPPING_CARRIER_ENUM.DHL
  },
  {
    label: 'USPS',
    value: ECOM_SHIPPING_CARRIER_ENUM.USPS
  },
  {
    label: 'Other',
    value: ECOM_SHIPPING_CARRIER_ENUM.OTHER
  }
];

const WEB_SAFE_FONT_OPTIONS = [
  { label: 'Arial', value: 'Arial, sans-serif' },
  { label: 'Bookman Old Style', value: 'Bookman Old Style, serif' },
  { label: 'Century Gothic', value: 'Century Gothic, sans-serif' },
  { label: 'Comic Sans MS', value: 'Comic Sans MS, cursive' },
  { label: 'Courier New', value: 'Courier New, monospace' },
  { label: 'Frank Ruhl Libre', value: 'Frank Ruhl Libre, serif' },
  { label: 'Georgia', value: 'Georgia, serif' },
  { label: 'Garamond', value: 'Garamond, serif' },
  { label: 'Helvetica', value: 'Helvetica, sans-serif' },
  { label: 'Impact', value: 'Impact, sans-serif' },
  { label: 'Lucida Console', value: 'Lucida Console, monospace' },
  { label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode, sans-serif' },
  { label: 'MS Sans Serif', value: 'MS Sans Serif, sans-serif' },
  { label: 'MS Serif', value: 'MS Serif, serif' },
  { label: 'Palatino Linotype', value: 'Palatino Linotype, serif' },
  { label: 'Segoe UI', value: 'Segoe UI, sans-serif' },
  { label: 'Tahoma', value: 'Tahoma, sans-serif' },
  { label: 'Times New Roman', value: 'Times New Roman, serif' },
  { label: 'Trebuchet MS', value: 'Trebuchet MS, sans-serif' },
  { label: 'Verdana', value: 'Verdana, sans-serif' }
];

export {
  PRODUCTION,
  TESTING,
  LOCAL_API,
  CONSOLE_DEBUG,
  COGNITO_SETTING_DEV,
  COGNITO_SETTING_PROD,
  S3_PUBLIC_URL,
  PASSWORD_CONSTRAINTS,
  PAYMENT_PROVIDERS,
  CURRENCY_TYPE_ENUM,
  CURRENCY_TYPE_LIST,
  INPUT_CONTROL_TYPE_ENUM,
  INPUT_CONTROL_TYPE_OPTIONS,
  BULK_EDIT_FIELD_TYPE_ENUM,
  VIEW_ACTIONS_ENUM,
  ECOM_PRODUCT_PRICING_TYPE_ENUM,
  ECOM_PRODUCT_PRICING_TYPE_LIST,
  ECOM_PRODUCT_STATUS_ENUM,
  ECOM_PRODUCT_STATUS_LIST,
  ECOM_CART_PRODUCT_STATUS_ENUM,
  WEIGHT_UNIT_ENUM,
  WEIGHT_UNIT_LIST,
  SUPPORTED_MEDIA_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_PAGE_TYPE_ENUM,
  PAYMENT_PROVIDER_LIST,
  CATALOG_SORT_OPTIONS,
  PAYMENT_TYPE_ENUM,
  ECOM_SHIPPING_CARRIER_ENUM,
  ECOM_SHIPPING_CARRIER_LIST,
  WEB_SAFE_FONT_OPTIONS
};
