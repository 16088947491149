import React, { useContext, useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { AlertCircle, ArrowLeft, Receipt } from 'tabler-icons-react';
import {
  Anchor,
  Badge,
  Button,
  Divider,
  Image,
  Indicator,
  NumberFormatter,
  Skeleton,
  Timeline
} from '@mantine/core';
import dayjs from 'dayjs';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import AppCard from '../../common/AppCard';
import { triggerNotification } from '../../../helpers/notification';
import ResponsiveModal from '../../common/ResponsiveModal';
import {
  currencyFormat,
  formatUtcDate,
  singularPluralFormat
} from '../../../helpers/format';
import { PAYMENT_TYPE_ENUM } from '../../../config/constants';
import OrderStatsCard from '../orders/OrderStatsCard';
import ProductLineItemAddonsDisplay from '../products/ProductLineItemAddonsDisplay';

const CustomerDetailsView = () => {
  const hasFetched = useRef(false);
  const { pkUser } = useParams();
  const { search } = useLocation();
  const searchUrl = new URLSearchParams(search);
  const isGuest = searchUrl.get('guest') === 'true';
  const fromLocation = searchUrl.get('from');
  const fromLocationId = searchUrl.get('fromId');
  const {
    isLargeMobileOrSmaller,
    isTabletOrSmaller,
    isDesktopOrSmaller
  } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreCustomer } = useContext(StoreContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const loading = !hasFetched.current || state.ecomStoreCustomer.loading;
  const storeCustomer =
    state.ecomStoreCustomer.value &&
    pkUser ===
      (
        state.ecomStoreCustomer.value.customer.pkUser ||
        state.ecomStoreCustomer.value.customer.pkEcomStoreCartGuest
      ).toString()
      ? state.ecomStoreCustomer.value
      : null;
  const defaultAddress =
    storeCustomer?.addresses.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )[0] ?? null;

  const backParams =
    storeCustomer && storeCustomer.customer.pkUser
      ? `?from=customers&fromId=${storeCustomer.customer.pkUser}&guest=${isGuest}`
      : '';

  const orderTimelineContent = storeCustomer
    ? storeCustomer.orderSummaries
        .filter((f) => f.ecomStoreCartPayments.length > 0)
        .sort((a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate))
        .map((order) => {
          const payment = order.ecomStoreCartPayments.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )[0];

          return {
            title: 'New order placed',
            date: order.purchaseDate,
            content: (
              <AppStack style={{ gap: 8 }}>
                <AppText style={{ fontSize: 14 }}>
                  Customer placed order{' '}
                  <Anchor
                    component={Link}
                    style={{ color: 'dodgerblue' }}
                    to={`/merchant/orders/${order.pkEcomStoreCart}${backParams}`}
                  >
                    #{order.pkEcomStoreCart}
                  </Anchor>{' '}
                  containing{' '}
                  {singularPluralFormat(order.itemCount, 'item', 'items')} with
                  a total cost of {currencyFormat(payment.amount / 100)}.
                </AppText>
                <AppText style={{ fontSize: 13, color: '#666' }}>
                  {dayjs(formatUtcDate(order.purchaseDate)).format(
                    'MMMM D, YYYY'
                  )}{' '}
                  at {dayjs(formatUtcDate(order.purchaseDate)).format('h:mm A')}
                </AppText>
              </AppStack>
            )
          };
        })
    : [];

  const refundTimelineContent = storeCustomer
    ? storeCustomer.orderSummaries
        .reduce(
          (acc, order) => [
            ...acc,
            ...order.ecomStoreCartPayments
              .filter(
                (f) =>
                  f.fkRegFormSubmissionPaymentType.toString() ===
                  PAYMENT_TYPE_ENUM.REFUND.toString()
              )
              .map((payment) => ({ ...payment, order }))
          ],
          []
        )
        .map((payment) => ({
          title: 'Refund issued',
          date: payment.createdAt,
          content: (
            <AppStack style={{ gap: 8 }}>
              <AppText style={{ fontSize: 14 }}>
                Refund of {currencyFormat(payment.amount / 100)} issued for
                order{' '}
                <Anchor
                  component={Link}
                  style={{ color: 'dodgerblue' }}
                  to={`/merchant/orders/${payment.order.pkEcomStoreCart}${backParams}`}
                >
                  #{payment.order.pkEcomStoreCart}
                </Anchor>
                .
              </AppText>
              <AppText style={{ fontSize: 13, color: '#666' }}>
                {dayjs(formatUtcDate(payment.createdAt)).format('MMMM D, YYYY')}{' '}
                at {dayjs(formatUtcDate(payment.createdAt)).format('h:mm A')}
              </AppText>
            </AppStack>
          )
        }))
    : [];

  const timelineContent = [
    ...orderTimelineContent,
    ...refundTimelineContent
  ].sort((a, b) => new Date(b.date) - new Date(a.date));

  useEffect(() => {
    if (authState.pkEcomStore && pkUser) {
      fetchStoreCustomer(
        authState.pkEcomStore,
        { pkUser, isGuest },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore, pkUser]);

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'orders': {
        if (fromLocationId) {
          return `/merchant/orders/${fromLocationId}`;
        }
        return '/merchant/orders';
      }
      default: {
        return '/merchant/customers';
      }
    }
  };

  return !loading && storeCustomer ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={getBackPath()}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 0 }}>
          <AppText
            style={{
              flex: 1,
              fontSize: 24,
              fontWeight: 700,
              wordBreak: 'break-word'
            }}
          >
            {storeCustomer.customer.name || storeCustomer.customer.email}
          </AppText>
          <AppText
            style={{
              fontSize: 12,
              lineHeight: '16px',
              color: '#666'
            }}
          >
            Customer since{' '}
            {dayjs(formatUtcDate(storeCustomer.firstPurchaseDate)).format(
              'MMMM D, YYYY'
            )}
          </AppText>
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderStatsCard
            data={[
              {
                label: 'Amount spent',
                value: storeCustomer.totalSpent / 100
              },
              {
                label: 'Average order value',
                value: storeCustomer.totalSpent / storeCustomer.orderCount / 100
              },
              {
                label: 'Amount refunded',
                value: storeCustomer.totalRefunded / 100
              },
              {
                label: 'Orders',
                value: storeCustomer.orderCount,
                type: 'string'
              }
            ]}
          />

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            {!storeCustomer.lastOrderPlaced ? (
              <AppFlexbox
                style={{
                  flex: 1,
                  padding: 16,
                  justifyContent: 'space-between'
                }}
              >
                <AppStack style={{ gap: 8 }}>
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    Latest order
                  </AppText>

                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    No orders were found for this customer.
                  </AppText>
                </AppStack>
                <Receipt color="#000" size={60} />
              </AppFlexbox>
            ) : (
              <AppStack style={{ gap: 0 }}>
                <AppStack style={{ gap: 8, padding: 16 }}>
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    Latest order
                  </AppText>
                  <AppStack style={{ gap: 0 }}>
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                        <Anchor
                          component={Link}
                          style={{
                            color: 'dodgerblue',
                            fontSize: 16,
                            fontWeight: 500
                          }}
                          to={`/merchant/orders/${storeCustomer.lastOrderPlaced.pkEcomStoreCart}${backParams}`}
                        >
                          #{storeCustomer.lastOrderPlaced.pkEcomStoreCart}
                        </Anchor>
                        <Badge
                          color="orange"
                          leftSection={<AlertCircle size={16} />}
                          size="md"
                          variant="filled"
                        >
                          Unfulfilled
                        </Badge>
                      </AppFlexbox>

                      <NumberFormatter
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        style={{ fontSize: 20, fontWeight: 500 }}
                        thousandSeparator
                        value={
                          storeCustomer.lastOrderPlaced.cartBalance
                            .amountPaidInCents / 100
                        }
                      />
                    </AppFlexbox>
                    <AppText style={{ color: '#666', fontSize: 13 }}>
                      {dayjs(
                        formatUtcDate(
                          storeCustomer.lastOrderPlaced.cartBalance.purchaseDate
                        )
                      ).format('MMMM D, YYYY')}{' '}
                      at{' '}
                      {dayjs(
                        formatUtcDate(
                          storeCustomer.lastOrderPlaced.cartBalance.purchaseDate
                        )
                      ).format('h:mm A')}
                    </AppText>
                  </AppStack>

                  <AppStack style={{ gap: 16, marginTop: 8 }}>
                    {storeCustomer.lastOrderPlaced.cartBalance.lineItems.map(
                      (item, index) => (
                        <React.Fragment key={item.entityId}>
                          {index > 0 && <Divider />}
                          <AppFlexbox
                            style={{
                              gap: 16,
                              alignItems: 'start',
                              justifyContent: 'space-between'
                            }}
                          >
                            <AppFlexbox style={{ gap: 16 }}>
                              <Indicator
                                color="dark"
                                inline
                                label={item.quantity}
                                radius="lg"
                                size="lg"
                              >
                                <AppStack
                                  style={{
                                    width: 60,
                                    height: 60
                                  }}
                                >
                                  <Image
                                    fit="contain"
                                    h="100%"
                                    src={item.previewImage}
                                    w="100%"
                                  />
                                </AppStack>
                              </Indicator>

                              <AppStack style={{ flex: 1 }}>
                                <AppText
                                  style={{ fontSize: 16, fontWeight: 500 }}
                                >
                                  {item.productName}
                                </AppText>
                                <ProductLineItemAddonsDisplay
                                  addOns={item.addOns}
                                />
                              </AppStack>
                            </AppFlexbox>

                            <NumberFormatter
                              decimalScale={2}
                              fixedDecimalScale
                              prefix="$"
                              style={{ fontSize: 16, fontWeight: 500 }}
                              thousandSeparator
                              value={item.totalInCents / 100}
                            />
                          </AppFlexbox>
                        </React.Fragment>
                      )
                    )}
                  </AppStack>
                </AppStack>
                <Divider />
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'end',
                    padding: 16,
                    gap: 16
                  }}
                >
                  {storeCustomer.orderCount > 1 && (
                    <Anchor
                      component={Link}
                      style={{ color: 'dodgerblue', fontSize: 14 }}
                      to={`/merchant/orders?customer=${storeCustomer.customer.email}`}
                    >
                      View all orders
                    </Anchor>
                  )}

                  <Button
                    color="dark"
                    component={Link}
                    size="sm"
                    to={`/merchant/orders/${storeCustomer.lastOrderPlaced.pkEcomStoreCart}${backParams}`}
                    variant="outline"
                  >
                    See details
                  </Button>
                </AppFlexbox>
              </AppStack>
            )}
          </AppCard>

          <AppStack style={{}}>
            <AppFlexbox style={{ padding: '0px 16px' }}>
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Timeline
              </AppText>
            </AppFlexbox>

            <AppStack style={{ padding: '0px 16px' }}>
              <Timeline
                active={timelineContent.length}
                bulletSize={16}
                color="dark"
                lineWidth={2}
              >
                {timelineContent.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Timeline.Item key={index} title={item.title}>
                    {item.content}
                  </Timeline.Item>
                ))}
              </Timeline>
            </AppStack>
          </AppStack>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack
              style={{
                gap: 0,
                flexDirection:
                  !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
              }}
            >
              <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    Contact information
                  </AppText>
                </AppFlexbox>
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 13 }}>
                    {storeCustomer.customer.name}
                  </AppText>
                  <Anchor
                    href={`mailto:${storeCustomer.customer.email}`}
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                    target="_blank"
                  >
                    {storeCustomer.customer.email}
                  </Anchor>
                </AppStack>
              </AppStack>

              <Divider
                orientation={
                  !isTabletOrSmaller && isDesktopOrSmaller
                    ? 'vertical'
                    : 'horizontal'
                }
              />
              <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    Default address
                  </AppText>
                  {storeCustomer.addresses.length > 1 && (
                    <Anchor
                      onClick={() => onOpenModal('addresses')}
                      style={{ color: 'dodgerblue', fontSize: 14 }}
                    >
                      View all
                    </Anchor>
                  )}
                </AppFlexbox>
                {defaultAddress && (
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 13 }}>
                      {defaultAddress.fullName}
                    </AppText>
                    <AppText style={{ fontSize: 13 }}>
                      {defaultAddress.address1}
                    </AppText>
                    {defaultAddress.address2 && (
                      <AppText style={{ fontSize: 13 }}>
                        {defaultAddress.address2}
                      </AppText>
                    )}
                    <AppText style={{ fontSize: 13 }}>
                      {defaultAddress.city} {defaultAddress.state}{' '}
                      {defaultAddress.postalCode}
                    </AppText>
                    <AppText style={{ fontSize: 13 }}>
                      {defaultAddress.country}
                    </AppText>
                  </AppStack>
                )}
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>

      <ResponsiveModal
        formSectionProps={{
          cancelTitle: 'Close',
          onCancel: onCloseModal,
          isSubmitHidden: true
        }}
        isOpen={modalState.isOpen && modalState.action === 'addresses'}
        onClose={onCloseModal}
        title="Customer addresses"
      >
        <AppStack style={{}}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            {storeCustomer.addresses.map((a, index) => (
              <AppStack
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{
                  padding: '8px 16px',
                  gap: 0,
                  borderTop: index !== 0 ? '1px solid #f0f0f0' : 'none'
                }}
              >
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {a.fullName}
                </AppText>
                <AppText style={{ fontSize: 14 }}>{a.address1}</AppText>
                {a.address2 && (
                  <AppText style={{ fontSize: 14 }}>{a.address1}</AppText>
                )}
                <AppText style={{ fontSize: 14 }}>
                  {a.city} {a.state} {a.postalCode}
                </AppText>
                <AppText style={{ fontSize: 14 }}>{a.country}</AppText>
                <AppText style={{ fontSize: 12, color: '#999' }}>
                  Last used{' '}
                  {dayjs(formatUtcDate(a.createdAt)).format('MMMM D, YYYY')}
                </AppText>
              </AppStack>
            ))}
          </AppCard>
        </AppStack>
      </ResponsiveModal>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={getBackPath()}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderStatsCard loading />

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 16, padding: 16 }}>
                <Skeleton height={22} width={150} />
                <AppStack style={{ gap: 8 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                      <Skeleton height={22} width={50} />
                      <Skeleton height={22} radius={100} width={120} />
                    </AppFlexbox>

                    <Skeleton height={22} width={75} />
                  </AppFlexbox>
                  <Skeleton height={14} width={150} />
                </AppStack>

                <AppStack style={{ gap: 8, marginTop: 8 }}>
                  <AppFlexbox
                    style={{
                      gap: 16,
                      alignItems: 'start',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppFlexbox style={{ gap: 16 }}>
                      <Skeleton height={60} width={60} />
                      <AppStack style={{ flex: 1, gap: 5 }}>
                        <Skeleton height={22} width={150} />
                        <Skeleton height={12} width={80} />
                      </AppStack>
                    </AppFlexbox>

                    <Skeleton height={22} width={80} />
                  </AppFlexbox>
                </AppStack>
              </AppStack>
              <Divider />
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'end',
                  padding: 16,
                  gap: 16
                }}
              >
                <Skeleton height={34} width={108} />
              </AppFlexbox>
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{
            flex: 1,
            maxWidth: isDesktopOrSmaller ? 'unset' : 350
          }}
        >
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack
              style={{
                gap: 0,
                flexDirection:
                  !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
              }}
            >
              <AppStack
                style={{
                  flex: 1,
                  padding: 16,
                  gap: 12
                }}
              >
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={20} width={90} />
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={16} width={120} />
                  <Skeleton height={16} width={140} />
                </AppStack>
              </AppStack>

              <Divider
                orientation={
                  !isTabletOrSmaller && isDesktopOrSmaller
                    ? 'vertical'
                    : 'horizontal'
                }
              />
              <AppStack style={{ flex: 1, padding: 16, gap: 12 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={20} width={120} />
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={16} width={130} />
                  <Skeleton height={16} width={150} />
                  <Skeleton height={16} width={80} />
                </AppStack>
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

export default CustomerDetailsView;
