import React, { useContext, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AlertCircle,
  ArrowLeft,
  GripVertical,
  Plus,
  Trash
} from 'tabler-icons-react';
import {
  ActionIcon,
  Anchor,
  Button,
  Divider,
  Image,
  Loader,
  Skeleton,
  TextInput,
  Textarea
} from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import AppCard from '../../common/AppCard';
import { triggerNotification } from '../../../helpers/notification';
import ConfirmModal from '../../common/ConfirmModal';
import {
  useGlobalFormState,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import VendorCollectionProductAssignModal from './VendorCollectionProductAssignModal';
import SortableList from '../../common/SortableList';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import { getEcomVendorProductStatus } from '../../../helpers/vendorHelper';

const VendorCollectionEditView = ({ duplicate }) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = useMediaQueryIndex();
  const { pkEcomVendorCollection } = useParams();
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    createVendorCollections,
    updateVendorCollection,
    deleteVendorCollections,
    fetchVendorCollection,
    fetchVendorSetupSummary
  } = useContext(VendorContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const {
    hasInitialized,
    hasUnsavedChanges,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(
    {
      name: '',
      description: '',
      ecomVendorCollectionProducts: []
    },
    { confirmDiscard: true, containerWidth: 950 }
  );
  const ecomVendorCollection = pkEcomVendorCollection
    ? state.ecomVendorCollection.value
    : null;
  const hasAlreadyFetched =
    hasFetched.current ||
    ecomVendorCollection?.pkEcomVendorCollection.toString() ===
      pkEcomVendorCollection;
  const loading = pkEcomVendorCollection
    ? !hasInitialized ||
      !hasAlreadyFetched ||
      state.ecomVendorCollection.loading
    : !hasInitialized;

  useEffect(() => {
    if (
      pkEcomVendorCollection &&
      ecomVendorCollection?.pkEcomVendorCollection.toString() !==
        pkEcomVendorCollection
    ) {
      fetchVendorCollection(
        pkEcomVendorCollection,
        () => {},
        (error) => triggerNotification(error)
      );
      hasFetched.current = true;
    }
  }, [pkEcomVendorCollection]);

  useEffect(() => {
    if (
      !state.ecomVendorCollection.loading &&
      (!pkEcomVendorCollection || ecomVendorCollection)
    ) {
      resetFormState({
        name: ecomVendorCollection?.name ?? '',
        description: ecomVendorCollection?.description ?? '',
        ecomVendorCollectionProducts:
          ecomVendorCollection?.ecomVendorCollectionProducts.map((p) => ({
            ...p,
            key: p.ecomVendorProduct.uuid,
            uuid: p.ecomVendorProduct.uuid,
            sort: p.sort
          })) ?? []
      });
    }
  }, [ecomVendorCollection, state.ecomVendorCollection.loading, duplicate]);

  const onRemoveProduct = (uuid) => {
    setFormState({
      ...formState,
      ecomVendorCollectionProducts: formState.ecomVendorCollectionProducts
        .filter((p) => p.uuid !== uuid)
        .sort((a, b) => a.sort - b.sort)
        .map((p, i) => ({ ...p, sort: i + 1 }))
    });
  };

  const onAddProducts = (uuids) => {
    const newEcomVendorProductUuids = uuids.filter(
      (p) => !formState.ecomVendorCollectionProducts.find((f) => f.uuid === p)
    );
    setFormState({
      ...formState,
      ecomVendorCollectionProducts: [
        ...formState.ecomVendorCollectionProducts.filter((p) =>
          uuids.includes(p.uuid)
        ),
        ...newEcomVendorProductUuids.map((p, i) => ({
          uuid: p,
          sort: formState.ecomVendorCollectionProducts.length + i + 1,
          key: new Date().getTime() + i
        }))
      ]
        .sort((a, b) => a.sort - b.sort)
        .map((p, i) => ({ ...p, sort: i + 1 }))
    });
  };

  const onSaveCollection = () => {
    submitFormState((formData, onErrorCallback) => {
      if (ecomVendorCollection && !duplicate) {
        updateVendorCollection(
          ecomVendorCollection.pkEcomVendorCollection,
          formState,
          () => {
            triggerNotification('Collection updated!', 'success');
          },
          onErrorCallback
        );
      }
      else {
        createVendorCollections(
          authState.pkEcomVendor,
          { ecomVendorCollections: [formState], selectFirstItem: true },
          (data) => {
            navigate(`/vendor/collections/${data[0].pkEcomVendorCollection}`);
            triggerNotification('Collection created!', 'success');
            if (
              state.ecomVendorSetupSummary.value?.ecomVendor.sandboxEnabled &&
              !state.ecomVendorSetupSummary.value?.hasCollections
            ) {
              fetchVendorSetupSummary(authState.pkEcomVendor, {skipLoading: true});
            }
          },
          onErrorCallback
        );
      }
    });
  };

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'collections':
        return '/vendor/collections';
      default: {
        if (duplicate) {
          return `/vendor/collections/${pkEcomVendorCollection}`;
        }
        return '/vendor/collections';
      }
    }
  };

  return !loading && (!pkEcomVendorCollection || hasInitialized) ? (
    <>
      <AppStack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSaveCollection();
        }}
        p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
        style={{
          flex: 1,
          margin: 'auto',
          width: '100%',
          maxWidth: 950,
          paddingTop: 16,
          gap: 16
        }}
      >
        <AppFlexbox
          p={{ base: '0px 8px', sm: 0 }}
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <ActionableIcon
              color="dark"
              component={Link}
              radius="md"
              to={getBackPath()}
              variant="subtle"
            >
              <ArrowLeft />
            </ActionableIcon>
            <AppText style={{ fontSize: 18, fontWeight: 700 }}>
              {ecomVendorCollection?.name ?? 'Create collection'}
            </AppText>
          </AppFlexbox>

          {!!ecomVendorCollection && !duplicate && (
            <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
              <Button
                color="dark"
                component={Link}
                disabled={isSubmitting}
                radius="md"
                size="xs"
                to={`/vendor/collections/${ecomVendorCollection.pkEcomVendorCollection}/duplicate`}
                type="button"
                variant="light"
              >
                Duplicate
              </Button>
            </AppFlexbox>
          )}
        </AppFlexbox>

        {duplicate ? (
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 8 }}
            withBorder
          >
            <AppFlexbox style={{ gap: 8 }}>
              <AppStack>
                <AlertCircle color="#eca70a" style={{ size: 18 }} />
              </AppStack>
              <AppText style={{ fontSize: 14 }}>
                Duplicate this collection by changing the <b>Title</b> and
                clicking save.
              </AppText>
            </AppFlexbox>
          </AppCard>
        ) : (
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 8 }}
            withBorder
          >
            <AppFlexbox style={{ gap: 8 }}>
              <AppStack>
                <AlertCircle color="dodgerblue" style={{ size: 18 }} />
              </AppStack>
              <AppText style={{ fontSize: 14 }}>
                Use collections to group products together, making it easier for
                merchants to browse similar products!
              </AppText>
            </AppFlexbox>
          </AppCard>
        )}

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack>
            <TextInput
              disabled={isSubmitting}
              label="Title"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.currentTarget.value
                })
              }
              required
              value={formState.name}
            />

            <Textarea
              autosize
              disabled={isSubmitting}
              label="Description"
              minRows={4}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  description: e.currentTarget.value
                })
              }
              value={formState.description}
            />
          </AppStack>
        </AppCard>

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox
              style={{
                padding: 16,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                Products
              </AppText>

              <Button
                color="dark"
                disabled={isSubmitting}
                leftSection={<Plus />}
                onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.CREATE)}
                size="compact-sm"
                type="button"
                variant="filled"
              >
                Add
              </Button>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              {state.ecomVendorProducts.loading ? (
                <>
                  <Divider />
                  <AppStack
                    style={{
                      gap: 5,
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '20px 10px',
                      flex: 1
                    }}
                  >
                    <Loader color="dark" size={34} />
                  </AppStack>
                </>
              ) : formState.ecomVendorCollectionProducts.length === 0 ? (
                <>
                  <Divider />
                  <AppStack
                    style={{
                      padding: 16,
                      gap: 10,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <AppText style={{ fontWeight: 500, color: '#666' }}>
                      No products added to this collection.
                    </AppText>
                    <Button
                      color="dark"
                      disabled={isSubmitting}
                      leftSection={<Plus />}
                      onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.CREATE)}
                      size="compact-sm"
                      type="button"
                      variant="outline"
                    >
                      Add product
                    </Button>
                  </AppStack>
                </>
              ) : (
                <SortableList
                  items={formState.ecomVendorCollectionProducts}
                  onChange={(newList) => {
                    setFormState({
                      ...formState,
                      ecomVendorCollectionProducts: newList.map((p, index) => ({
                        ...p,
                        sort: index + 1
                      }))
                    });
                  }}
                  renderItem={(
                    item,
                    {
                      attributes,
                      listeners,
                      isDragging,
                      setNodeRef,
                      style,
                      isOverlay
                    }
                  ) => {
                    const product = state.ecomVendorProducts.value.find(
                      (p) => p.uuid === item?.uuid
                    );
                    const productStatusInfo = getEcomVendorProductStatus(product);

                    return product ? (
                      <AppCard
                        ref={setNodeRef}
                        radius={0}
                        shadow="none"
                        style={{
                          ...style,
                          padding: 0,
                          outline: isOverlay ? 'solid 1px #dee2e6' : 'none',
                          backgroundColor: isDragging
                            ? 'rgba(56, 56, 56, 0.1)'
                            : '#FFF',
                          opacity: isDragging ? 0.4 : 1,
                          cursor: isDragging ? 'grabbing' : 'default'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            padding: 10,
                            gap: 10,
                            borderTop: isOverlay ? 'none' : 'solid 1px #dee2e6',
                            flex: 1,
                            alignItems: 'center'
                          }}
                        >
                          <ActionIcon
                            color="dark"
                            disabled={isSubmitting}
                            variant="subtle"
                            {...attributes}
                            {...listeners}
                          >
                            <GripVertical size={20} />
                          </ActionIcon>

                          <AppFlexbox
                            style={{
                              flex: 1,
                              gap: 10,
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <AppFlexbox style={{ alignItems: 'center' }}>
                              <AppStack
                                style={{
                                  width: 40,
                                  height: 40,
                                  border: 'solid 1px #dee2e6'
                                }}
                              >
                                <Image
                                  h={40}
                                  src={product.previewImage}
                                  w={40}
                                />
                              </AppStack>
                              <AppStack style={{gap: 0}}>
                              <Anchor
                                onClick={() =>
                                  navigate(`/vendor/products/${product.uuid}`)
                                }
                                style={{ fontSize: 14, fontWeight: 500, color: '#000' }}
                              >
                                {product.name}
                              </Anchor>
                              <AppText style={{ fontSize: 12, color: productStatusInfo.color }}> 
                                {
                                  productStatusInfo.label
                                }
                                </AppText>
                              </AppStack>
                            </AppFlexbox>

                            <ActionIcon
                              color="red"
                              disabled={isSubmitting}
                              onClick={() => onRemoveProduct(item?.uuid)}
                              radius="md"
                              variant="subtle"
                            >
                              <Trash />
                            </ActionIcon>
                          </AppFlexbox>
                        </AppFlexbox>
                      </AppCard>
                    ) : null;
                  }}
                />
              )}
            </AppStack>
          </AppStack>
        </AppCard>

        <AppFlexbox
          p={{ base: 16, sm: '16px 0px' }}
          style={{
            justifyContent: 'flex-end',
            flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
          }}
        >
          {!!ecomVendorCollection && !duplicate && (
            <Button
              color="red"
              disabled={isSubmitting}
              onClick={() => {
                onOpenModal(VIEW_ACTIONS_ENUM.DELETE, ecomVendorCollection);
              }}
              radius="md"
              size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
              type="button"
              variant="filled"
            >
              Delete collection
            </Button>
          )}
          <Button
            color="dark"
            disabled={!hasUnsavedChanges}
            id="save-button"
            loading={isSubmitting}
            radius="md"
            size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
            type="submit"
            variant="filled"
          >
            Save
          </Button>
        </AppFlexbox>
      </AppStack>
      {ConfirmDiscardModal}

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete collection"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText>
              Are you sure you want to delete <b>{modalState.item?.name}</b>?
              This action can’t be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteVendorCollections(
            authState.pkEcomVendor,
            [modalState.item.pkEcomVendorCollection],
            () => {
              triggerNotification('Collection deleted!', 'success');
              navigate('/vendor/collections');
              if (
                state.ecomVendorSetupSummary.value?.ecomVendor.sandboxEnabled
              ) {
                fetchVendorSetupSummary(authState.pkEcomVendor, {skipLoading: true});
              }
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${modalState.item?.name}?`}
      />

      <VendorCollectionProductAssignModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.CREATE
        }
        onClose={onCloseModal}
        onConfirm={(pkEcomVendorProducts) => {
          onAddProducts(pkEcomVendorProducts);
          onCloseModal();
        }}
        uuids={formState.ecomVendorCollectionProducts.map((p) => p.uuid)}
      />
    </>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <AppFlexbox style={{ gap: 5 }}>
          <Skeleton height={30} width={200} />
        </AppFlexbox>

        {!!ecomVendorCollection && !duplicate && (
          <AppFlexbox style={{ gap: 5 }}>
            <Skeleton height={30} width={125} />
          </AppFlexbox>
        )}
      </AppFlexbox>

      <AppStack style={{ gap: 16 }}>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
      </AppStack>
    </AppStack>
  );
};

VendorCollectionEditView.propTypes = { duplicate: PropTypes.bool };

export default VendorCollectionEditView;
