import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Anchor, Button, TextInput } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import AuthCardView from './AuthCardView';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';

const ForgotPasswordView = ({ onNavigate }) => {
  const { state, sendForgotPasswordConfirmation } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    email: state.email ?? '',
    error: null,
    loading: false
  });

  useEffect(() => {
    setFormState({
      email: state.email ?? '',
      error: null,
      loading: false
    });
  }, []);

  return (
    <AuthCardView
      error={formState.error}
      onSubmit={() => {
        setFormState({
          ...formState,
          loading: true
        });
        sendForgotPasswordConfirmation(
          formState.email,
          () => {
            triggerNotification('Reset code sent!', 'success');
            onNavigate('/auth/reset-password');
          },
          (error) =>
            setFormState({
              ...formState,
              loading: false,
              error
            })
        );
      }}
      title="Password assistance"
    >
      <AppStack
        p={{ base: 20, xsm: 40 }}
        style={{ gap: 10, flex: 1, paddingTop: 10 }}
      >
        <AppStack style={{ gap: 0 }}>
          <AppText style={{ fontSize: 14 }}>
            Please enter the email associated with your SportsHeadz account to
            begin resetting your password.
          </AppText>
        </AppStack>

        <TextInput
          disabled={formState.loading}
          label="Email"
          onChange={(e) =>
            setFormState({
              ...formState,
              email: e.target.value
            })
          }
          required
          value={formState.email}
        />
        <Button
          color="dark"
          loading={formState.loading}
          radius={10}
          size="sm"
          style={{ fontSize: 13, margin: '5px 0px' }}
          type="submit"
        >
          Continue
        </Button>

        <AppText style={{ fontSize: 14 }}>
          Remember your password?{' '}
          <Anchor
            onClick={() => onNavigate('/auth/signin')}
            style={{ fontSize: 14, color: 'dodgerblue' }}
          >
            Sign in
          </Anchor>
        </AppText>
      </AppStack>
    </AuthCardView>
  );
};

ForgotPasswordView.propTypes = { onNavigate: PropTypes.func };

export default ForgotPasswordView;
