import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import StoreFundraisingRosterPlayerTable from './StoreFundraisingRosterPlayerTable';
import BulkEditStoreRosterPlayersView from './BulkEditStoreRosterPlayersView';

const StoreFundraisingView = () => (
  <Routes>
    <Route element={<BulkEditStoreRosterPlayersView />} path="/bulk-edit/*" />
    <Route element={<StoreFundraisingRosterPlayerTable />} path="/" />
    <Route element={<Navigate replace to="/merchant/fundraising" />} path="*" />
  </Routes>
);

export default StoreFundraisingView;
