/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';

const WsTitleBlock = ({ entity, config, displayFunctions, ...rest }) => (
  <AppText style={{ fontSize: 28, fontWeight: 500 }}>{entity.title}</AppText>
);

WsTitleBlock.propTypes = {
  config: PropTypes.object,
  displayFunctions: PropTypes.object,
  entity: PropTypes.object
};

export default WsTitleBlock;
