import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Anchor, Button, Divider, TextInput } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AuthCardView from './AuthCardView';
import AppCard from '../../common/AppCard';

const SigninView = ({ onNavigate }) => {
  const { state, checkForExistingUser, signIn, reset } = useContext(
    AuthContext
  );
  const [formState, setFormState] = useState({
    email: state.email ?? '',
    password: state.password ?? '',
    showPasswordInput: !!state.email,
    loading: false,
    error: ''
  });

  useEffect(() => {
    if (!state.email || state.isWaitingConfirmSignUp) {
      reset();
    }

    setFormState({
      email: state.email ?? '',
      password: state.password ?? '',
      showPasswordInput: false,
      loading: false,
      error: ''
    });
  }, []);

  const onSignin = () => {
    setFormState({
      ...formState,
      loading: true
    });
    if (!formState.showPasswordInput) {
      checkForExistingUser(
        formState.email,
        () => {
          setFormState({
            ...formState,
            password: '',
            error: '',
            showPasswordInput: true
          });
        },
        () => {
          setFormState({
            ...formState,
            error: 'We cannot find an account with that e-mail address'
          });
        }
      );
    }
    else {
      signIn(
        { email: formState.email, password: formState.password },
        () => {},
        (error) => {
          setFormState({
            ...formState,
            loading: false,
            error
          });
        }
      );
    }
  };

  return (
    <AuthCardView error={formState.error} onSubmit={onSignin} title="Sign in">
      <AppStack
        p={{ base: 20, xsm: 40 }}
        style={{ gap: 10, flex: 1, paddingTop: 10 }}
      >
        {formState.showPasswordInput ? (
          <>
            <AppCard
              radius={8}
              shadow="none"
              style={{ padding: 16, backgroundColor: 'rgba(245, 245, 245, 1)' }}
              withBorder
            >
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontSize: 13 }}>{formState.email}</AppText>
                <Anchor
                  onClick={() =>
                    setFormState({
                      ...formState,
                      email: '',
                      password: '',
                      error: '',
                      loading: false,
                      showPasswordInput: false
                    })
                  }
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                >
                  Change
                </Anchor>
              </AppFlexbox>
            </AppCard>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                    Password
                  </AppText>
                  <AppText
                    style={{
                      fontSize: 13,
                      fontWeight: 500,
                      color: '#f03e3e'
                    }}
                  >
                    *
                  </AppText>
                </AppFlexbox>
                <Anchor
                  onClick={() => onNavigate('/auth/forgot-password')}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                >
                  Forgot password?
                </Anchor>
              </AppFlexbox>

              <TextInput
                disabled={formState.loading}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    password: e.currentTarget.value
                  })
                }
                required
                type="password"
                value={formState.password}
              />
            </AppStack>
            <Button
              color="dark"
              loading={formState.loading}
              radius={10}
              size="sm"
              style={{ fontSize: 13, margin: '5px 0px' }}
              type="submit"
            >
              Sign in
            </Button>
          </>
        ) : (
          <>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ gap: 5 }}>
                <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                  Email address
                </AppText>
                <AppText
                  style={{ fontSize: 13, fontWeight: 500, color: '#f03e3e' }}
                >
                  *
                </AppText>
              </AppFlexbox>

              <TextInput
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    email: e.currentTarget.value
                  })
                }
                required
                type="email"
                value={formState.email}
              />
            </AppStack>
            <Button
              color="dark"
              loading={formState.loading}
              radius={10}
              size="sm"
              style={{ fontSize: 13, margin: '5px 0px' }}
              type="submit"
            >
              Continue
            </Button>
            <AppText style={{ fontSize: 13 }}>
              By continuing, you agree to SportsHeadz{' '}
              <Anchor
                href="https://www.sportsheadz.com/privacy-policy"
                style={{ fontSize: 13, color: 'dodgerblue' }}
                target="_blank"
              >
                Privacy Policy
              </Anchor>
              .
            </AppText>

            <AppText style={{ fontSize: 13 }}>
              <Anchor
                onClick={() => onNavigate('/auth/forgot-password')}
                style={{ fontSize: 13, color: 'dodgerblue' }}
              >
                Forgot your password?
              </Anchor>
            </AppText>

            <Divider
              label="New to SportsHeadz?"
              labelPosition="center"
              style={{ marginTop: 5 }}
            />
            <Button
              color="dark"
              onClick={() => onNavigate('/auth/register')}
              radius={10}
              size="compact-md"
              style={{ fontSize: 13 }}
              type="button"
              variant="outline"
            >
              Create your SportsHeadz account
            </Button>
          </>
        )}
      </AppStack>
    </AuthCardView>
  );
};

SigninView.propTypes = { onNavigate: PropTypes.func };

export default SigninView;
