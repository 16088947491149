import { Photo } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM
} from '../../../../../config/constants';
import WsImageBlock from './WsImageBlock';

const configNameValues = { image: 'image' };

const configDefaultValues = { [configNameValues.image]: '' };

const configOptions = {
  [configNameValues.image]: {
    label: 'Image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  }
};

const config = {
  title: 'Image',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE,
  icon: Photo,
  Component: WsImageBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
