import React from 'react';
import PropTypes from 'prop-types';
import { ActionIcon } from '@mantine/core';
import { ChevronDown, ChevronRight } from 'tabler-icons-react';
import { useLocation } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import WebstoreBuilderSidebarAddItemMenu from './WebstoreBuilderSidebarAddItemMenu';
import SortableList from '../../common/SortableList';
import WebstoreBuilderSidebarButtonSortable from './WebstoreBuilderSidebarButtonSortable';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const WebstoreBuilderSidebarSection = ({
  section,
  typeConfig,
  open,
  onToggleOpen,
  selected,
  onSelect,
  onSelectSectionBlock,
  selectedSectionBlock,
  onAddSectionBlock,
  onRemove,
  onRemoveSectionBlock,
  onToggleHide,
  onChangeSectionBlockOrder,
  mobileEditMode,
  sortableProps
}) => {
  const { search } = useLocation();
  const locationParams = new URLSearchParams(search);
  const previewPathname = locationParams.get('previewPath');
  const { isLaptopOrSmaller } = useMediaQueryIndex();
  const sectionBlocks = section?.ecomStoreThemeSectionBlocks?.filter(
    (i) =>
      !!typeConfig?.blocks.find(
        (c) => c.type === i.fkEcomStoreThemeSectionBlockType
      )
  );

  return (
    <AppStack
      ref={sortableProps?.setNodeRef}
      style={{
        backgroundColor: '#FFF',
        gap: isLaptopOrSmaller ? 2 : 0,
        cursor: sortableProps?.isOverlay ? 'grabbing' : 'pointer',
        ...sortableProps?.style,
        height: '100%'
      }}
    >
      <WebstoreBuilderSidebarButtonSortable
        isItemHidden={section.hidden}
        isLaptopOrSmaller={isLaptopOrSmaller}
        label={
          typeConfig.getTitle
            ? typeConfig.getTitle(typeConfig, previewPathname) ||
              typeConfig.title
            : typeConfig.title
        }
        LeftIcon={typeConfig.icon}
        leftSection={
          isLaptopOrSmaller ? null : typeConfig.blocks.length === 0 ? (
            <AppStack style={{ width: 22 }} />
          ) : open ? (
            <ActionIcon
              color="dark"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onToggleOpen(section);
              }}
              size="sm"
              style={{
                height: '100%',
                borderRadius: 8,
                maxWidth: 22,
                border: 'none',
                color: '#666'
              }}
              variant="subtle"
            >
              <ChevronDown size={18} />
            </ActionIcon>
          ) : (
            <ActionIcon
              color="dark"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onToggleOpen(section);
              }}
              size="sm"
              style={{
                height: '100%',
                borderRadius: 8,
                width: 20,
                border: 'none',
                color: '#666'
              }}
              variant="subtle"
            >
              <ChevronRight size={18} />
            </ActionIcon>
          )
        }
        mobileEditMode={mobileEditMode}
        onClick={() => onSelect(section)}
        onRemove={typeConfig.isPermanent ? null : onRemove}
        onToggleHide={() => onToggleHide(section.key)}
        selected={isLaptopOrSmaller || (!selectedSectionBlock && selected)}
        sortableProps={sortableProps}
      />
      {!sortableProps?.isDragging &&
        !sortableProps?.isOverlay &&
        (open || isLaptopOrSmaller) &&
        typeConfig.blocks.length > 0 && (
          <AppStack style={{ paddingLeft: 40, gap: 1 }}>
            <SortableList
              items={sectionBlocks}
              listProps={{ style: { gap: isLaptopOrSmaller ? 2 : 0 } }}
              onChange={(newBlockOrder) =>
                onChangeSectionBlockOrder(section.key, newBlockOrder)
              }
              renderItem={(i, blockSortableProps, index) => {
                const itemConfig = typeConfig.blocks.find(
                  (c) => c.type === i.fkEcomStoreThemeSectionBlockType
                );

                return (
                  <AppStack
                    ref={blockSortableProps?.setNodeRef}
                    style={{
                      backgroundColor: '#FFF',
                      gap: 0,
                      cursor: blockSortableProps?.isOverlay
                        ? 'grabbing'
                        : 'pointer',
                      ...blockSortableProps?.style,
                      height: '100%'
                    }}
                  >
                    <WebstoreBuilderSidebarButtonSortable
                      isItemHidden={i.hidden}
                      isLaptopOrSmaller={isLaptopOrSmaller}
                      label={
                        itemConfig.getTitle
                          ? itemConfig.getTitle(
                              i.config,
                              blockSortableProps?.isOverlay ||
                                blockSortableProps?.isDragging
                                ? -1
                                : index
                            ) || itemConfig.title
                          : itemConfig.title
                      }
                      LeftIcon={itemConfig.icon}
                      mobileEditMode={mobileEditMode}
                      onClick={() => onSelectSectionBlock(i)}
                      onRemove={() => onRemoveSectionBlock(section, i)}
                      onToggleHide={() => onToggleHide(section.key, i.key)}
                      selected={selected && selectedSectionBlock === i.key}
                      sortableProps={blockSortableProps}
                    />
                  </AppStack>
                );
              }}
            />
            {!mobileEditMode &&
              typeConfig.blocks &&
              typeConfig.blocks.length > 0 && (
                <WebstoreBuilderSidebarAddItemMenu
                  blocks={typeConfig.blocks}
                  isLaptopOrSmaller={isLaptopOrSmaller}
                  maxItemCount={typeConfig.maxItemCount}
                  onAdd={(block) => onAddSectionBlock(section, block.type)}
                  sectionBlocks={sectionBlocks}
                />
              )}
          </AppStack>
        )}
    </AppStack>
  );
};

WebstoreBuilderSidebarSection.propTypes = {
  mobileEditMode: PropTypes.bool,
  onAddSectionBlock: PropTypes.func,
  onChangeSectionBlockOrder: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveSectionBlock: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectSectionBlock: PropTypes.func,
  onToggleHide: PropTypes.func,
  onToggleOpen: PropTypes.func,
  open: PropTypes.bool,
  section: PropTypes.object,
  selected: PropTypes.bool,
  selectedSectionBlock: PropTypes.number,
  sortableProps: PropTypes.object,
  typeConfig: PropTypes.object
};

export default WebstoreBuilderSidebarSection;
