import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import VendorInventoryTable from './VendorInventoryTable';

const VendorInventoryView = () => (
  <Routes>
    <Route element={<></>} path="/bulk-edit" />
    <Route element={<VendorInventoryTable />} path="/" />
    <Route element={<Navigate replace to="/vendor/products" />} path="*" />
  </Routes>
);

export default VendorInventoryView;
