import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Anchor,
  Avatar,
  Button,
  ColorInput,
  Divider,
  Image,
  Loader,
  Menu,
  ScrollArea,
  Tabs,
  TextInput
} from '@mantine/core';
import {
  AlertCircle,
  AlertTriangle,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  CircleCheck,
  CurrencyDollar
} from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { triggerNotification } from '../../../helpers/notification';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import CustomNavLink from '../../common/CustomNavLink';
import { getInitialsFromName } from '../../../helpers/format';
import AppCard from '../../common/AppCard';
import * as classes from '../../../styles/nestedStyles.module.css';
import { uploadFileContentRecursively } from '../../../helpers/awsHelper';
import {
  CURRENCY_TYPE_ENUM,
  CURRENCY_TYPE_LIST
} from '../../../config/constants';
import SettingSelector from '../../common/SettingSelector';
import AppMenu from '../../common/AppMenu';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const CreateStoreForm = ({ onCancel }) => {
  const navigate = useNavigate();
  const imageInputRef = useRef(null);
  const {
    state: authState,
    selectEcomStore,
    fetchEcommerceAdminData
  } = useContext(AuthContext);
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();
  const { createStore } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    fkCurrencyType: CURRENCY_TYPE_ENUM.CAD,
    teamData: {
      name: '',
      domain: '',
      logoImageUrl: '',
      primaryColor: '',
      secondaryColor: '',
      supportEmail: '',
      fkTeam: null,
      fkAssociation: null,
      file: null,
      filePreview: null
    },
    showTeamSelect: true,
    activeTab: 'teams',
    teamSearch: '',
    selectedRegAssociation: null,
    loading: false,
    error: null
  });
  const teams = authState.userAssociations.value
    ? [...authState.userAssociations.value.teams]
        .reduce(
          (r, c) => (r.find((t) => t.pkTeam === c.pkTeam) ? r : [...r, c]),
          []
        )
        .filter(
          (t) =>
            !formState.teamSearch ||
            t.name.toLowerCase().includes(formState.teamSearch.toLowerCase())
        )
    : [];
  const associationTeams = authState.userAssociations.value
    ? [
        ...authState.userAssociations.value.regAssociations
          .map((a) => a.association.teams)
          .flat()
      ]
        .reduce(
          (r, c) => (r.find((t) => t.pkTeam === c.pkTeam) ? r : [...r, c]),
          []
        )
        .filter(
          (t) =>
            !formState.teamSearch ||
            t.name.toLowerCase().includes(formState.teamSearch.toLowerCase())
        )
    : [];

  const regAssociations = authState.userAssociations.value
    ? [
        ...authState.userAssociations.value.regAssociations,
        ...authState.userAssociations.value.teams
          .map((t) => t.association.regAssociations)
          .flat()
      ].reduce(
        (r, c) =>
          r.find((a) => a.pkRegAssociation === c.pkRegAssociation)
            ? r
            : [...r, c],
        []
      )
    : [];

  const showTeamTabs =
    authState.userAssociations.value &&
    !!authState.userAssociations.value?.teams.length &&
    !!authState.userAssociations.value?.regAssociations.some(
      (a) => !!a.association.teams.length
    );

  const selectedCurrency =
    CURRENCY_TYPE_LIST.find(
      (currency) => currency.value === formState.fkCurrencyType
    ) || CURRENCY_TYPE_LIST[0];

  useEffect(() => {
    if (!authState.userAssociations.loading) {
      setFormState({
        fkCurrencyType: CURRENCY_TYPE_ENUM.CAD,
        teamData: {
          name: '',
          domain: '',
          logoImageUrl: '',
          primaryColor: '',
          secondaryColor: '',
          supportEmail: '',
          fkTeam: null,
          fkAssociation: null,
          file: null,
          filePreview: null
        },
        showTeamSelect: !!teams.length || !!associationTeams.length,
        activeTab: teams.length ? 'teams' : 'association',
        teamSearch: '',
        selectedRegAssociation: null,
        loading: false,
        error: null
      });
    }
  }, [authState.userAssociations.loading]);

  const onSelectTeam = (team = null) => {
    const regAssociation = regAssociations.find(
      (a) => a.association.pkAssociation === team?.association.pkAssociation
    );

    setFormState({
      ...formState,
      teamData: {
        ...formState.teamData,
        name: team?.name ?? '',
        domain: team?.association?.domain ?? '',
        logoImageUrl:
          team?.image ??
          team?.association?.image ??
          regAssociation?.logoImageUrl ??
          '',
        primaryColor: regAssociation?.primaryColor ?? '',
        secondaryColor: regAssociation?.secondaryColor ?? '',
        supportEmail: regAssociation?.supportEmail ?? '',
        fkTeam: team?.pkTeam ?? null,
        fkAssociation: team?.association.pkAssociation ?? null,
        file: null,
        filePreview: null
      },
      showTeamSelect: false,
      selectedRegAssociation: regAssociation
    });
  };

  const onCreateStore = () => {
    setFormState({
      ...formState,
      loading: true
    });
    uploadFileContentRecursively(
      formState.teamData.file
        ? [{ src: '', file: formState.teamData.file }]
        : [],
      `store-media-user-${authState.userData.user.pkUser}`,
      (media) => {
        createStore(
          {
            ...formState.teamData,
            logoImageUrl: media[0]?.src ?? formState.teamData.logoImageUrl,
            fkCurrencyType: formState.fkCurrencyType
          },
          (data) => {
            fetchEcommerceAdminData(
              () => {
                selectEcomStore(data.pkEcomStore);
                triggerNotification('Store created!', 'success');
                navigate('/merchant/');
              },
              () => {
                window.location.reload();
              }
            );
          },
          (error) => {
            setFormState({
              ...formState,
              loading: false,
              error
            });
          }
        );
      },
      () => {
        triggerNotification('Error saving image. Please try again.');
        setFormState({
          ...formState,
          loading: false
        });
      }
    );
  };

  return authState.userAssociations.loading || formState.loading ? (
    <AppStack
      style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
    >
      <Loader color="dark" size={50} />
    </AppStack>
  ) : (
    <AppStack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onCreateStore();
      }}
      style={{
        flex: 1,
        gap: 10,
        height: '100%',
        overflow: 'hidden'
      }}
    >
      {formState.error && (
        <AppStack style={{ padding: 20 }}>
          <Alert
            color="#c40000"
            icon={<AlertTriangle style={{ height: 28, width: 28 }} />}
            radius={10}
            styles={{
              body: { gap: 5 },
              icon: { height: 28, width: 28 },
              title: { fontSize: 17, fontWeight: 500 }
            }}
            title="There was a problem"
            variant="outline"
          >
            <AppText style={{ fontSize: 13 }}>{formState.error}</AppText>
          </Alert>
        </AppStack>
      )}

      <AppFlexbox
        style={{
          padding: '0px 20px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <AppText style={{ fontWeight: 500, fontSize: 22 }}>
          Create store
        </AppText>
      </AppFlexbox>
      {formState.showTeamSelect ? (
        <AppStack
          style={{
            flex: 1,
            padding: 20,
            gap: 10,
            overflow: 'hidden',
            height: '100%'
          }}
        >
          <AppText
            style={{
              fontWeight: 500,
              fontSize: 14,
              textAlign: 'center',
              color: '#666',
              whiteSpace: 'pre-wrap',
              overflow: 'unset'
            }}
          >
            Choose one of your teams below to get started or create a new store
            from scratch.
          </AppText>
          <AppStack style={{ gap: 0 }}>
            {showTeamTabs && (
              <Tabs
                onChange={(activeTab) =>
                  setFormState({ ...formState, activeTab })
                }
                value={formState.activeTab}
              >
                <Tabs.List>
                  <Tabs.Tab color="dark" value="teams">
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight:
                          formState.activeTab === 'teams' ? 500 : 'normal'
                      }}
                    >
                      Your teams
                    </AppText>
                  </Tabs.Tab>
                  <Tabs.Tab color="dark" value="association">
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight:
                          formState.activeTab === 'association' ? 500 : 'normal'
                      }}
                    >
                      Association teams
                    </AppText>
                  </Tabs.Tab>
                </Tabs.List>
              </Tabs>
            )}

            <TextInput
              onChange={(e) =>
                setFormState({
                  ...formState,
                  teamSearch: e.currentTarget.value
                })
              }
              placeholder="Search teams by name"
              size="sm"
              style={{ marginTop: 5 }}
              value={formState.teamSearch}
            />
          </AppStack>
          <AppStack
            style={{
              flex: 1,
              border: 'solid 1px lightgrey',
              padding: 5,
              borderRadius: 5,
              height: '100%',
              overflow: 'hidden'
            }}
          >
            {(!showTeamTabs || formState.activeTab === 'teams') &&
              (teams.length === 0 && associationTeams.length === 0 ? (
                <AppStack
                  style={{
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    gap: 5
                  }}
                >
                  <AlertCircle color="#000" size={30} />
                  <AppText style={{ fontSize: 16, color: '#000' }}>
                    No teams found
                  </AppText>
                </AppStack>
              ) : (
                <ScrollArea.Autosize
                  style={{ width: '100%', height: '100%', maxHeight: '100%' }}
                >
                  {teams
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((t) => (
                      <CustomNavLink
                        key={t.pkTeam}
                        description={t.association.name}
                        label={t.name}
                        leftSection={
                          <Avatar
                            color="dark"
                            radius="xl"
                            size="md"
                            src={t.image ?? t.association?.image}
                          >
                            <AppText style={{ fontWeight: 500 }}>
                              {getInitialsFromName(t.name)}
                            </AppText>
                          </Avatar>
                        }
                        onClick={() => onSelectTeam(t)}
                      />
                    ))}
                </ScrollArea.Autosize>
              ))}

            {(!showTeamTabs || formState.activeTab === 'association') &&
              (associationTeams.length === 0 && showTeamTabs ? (
                <AppStack
                  style={{
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  <AlertCircle color="#000" size={30} />
                  <AppText style={{ fontSize: 16, color: '#000' }}>
                    No teams found
                  </AppText>
                </AppStack>
              ) : (
                <ScrollArea.Autosize
                  style={{ width: '100%', height: '100%', maxHeight: '100%' }}
                >
                  {associationTeams
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((t) => (
                      <CustomNavLink
                        key={t.pkTeam}
                        description={t.association.name}
                        label={t.name}
                        leftSection={
                          <Avatar
                            color="dark"
                            radius="xl"
                            size="md"
                            src={t.image ?? t.association?.image}
                          >
                            <AppText style={{ fontWeight: 500 }}>
                              {getInitialsFromName(t.name)}
                            </AppText>
                          </Avatar>
                        }
                        onClick={() => onSelectTeam(t)}
                      />
                    ))}
                </ScrollArea.Autosize>
              ))}
          </AppStack>

          <Divider
            label="OR"
            labelPosition="center"
            style={{ marginTop: 20 }}
          />
          <AppStack>
            <Button
              color="dark"
              onClick={() => onSelectTeam()}
              radius={10}
              size="compact-md"
              style={{ fontSize: 13 }}
              type="button"
              variant="outline"
            >
              Create store from scratch
            </Button>
          </AppStack>
        </AppStack>
      ) : (
        <AppStack style={{ gap: 16, padding: 20, paddingTop: 10, flex: 1 }}>
          <TextInput
            disabled={formState.loading}
            label="Store name"
            onChange={(e) =>
              setFormState({
                ...formState,
                teamData: {
                  ...formState.teamData,
                  name: e.currentTarget.value
                }
              })
            }
            placeholder="My store"
            required
            value={formState.teamData.name}
          />

          <AppStack style={{ gap: 5 }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Currency
            </AppText>

            <AppMenu
              control={
                <AppFlexbox>
                  <SettingSelector
                    disabled={formState.loading}
                    leftSection={
                      <CurrencyDollar size={isLargeMobileOrSmaller ? 26 : 32} />
                    }
                    leftSectionWidth={35}
                    onClick={() => {}}
                    rightSection={
                      <AppFlexbox
                        style={{ gap: 5, alignItems: 'center', color: '#999' }}
                      >
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {isLargeMobileOrSmaller
                            ? 'Change'
                            : 'Click to change'}
                        </AppText>
                        <ChevronRight size={18} />
                      </AppFlexbox>
                    }
                    style={{ gap: 8 }}
                    title={selectedCurrency.fullLabel}
                    withBorder
                  />
                </AppFlexbox>
              }
              position="bottom-start"
              styles={{
                dropdown: {
                  padding: 0,
                  zIndex: 1000
                },
                item: { padding: 2 }
              }}
              width="target"
            >
              <AppStack style={{ padding: 2, gap: 1 }}>
                {CURRENCY_TYPE_LIST.map((c) => (
                  <Menu.Item>
                    <SettingSelector
                      disabled={formState.loading}
                      leftSection={
                        c.value === selectedCurrency.value ? (
                          <CircleCheck color="green" size={32} />
                        ) : (
                          <></>
                        )
                      }
                      leftSectionWidth={35}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          fkCurrencyType: c.value
                        });
                      }}
                      rightSection={<></>}
                      style={{ gap: 8 }}
                      title={c.fullLabel}
                      withBorder
                    />
                  </Menu.Item>
                ))}
              </AppStack>
            </AppMenu>
          </AppStack>

          {formState.selectedRegAssociation && (
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Default settings
                </AppText>
                {(formState.teamData.primaryColor !==
                  formState.selectedRegAssociation.primaryColor ||
                  formState.teamData.secondaryColor !==
                    formState.selectedRegAssociation.secondaryColor ||
                  !!formState.teamData.file) && (
                  <Anchor
                    onClick={() =>
                      setFormState({
                        ...formState,
                        teamData: {
                          ...formState.teamData,
                          primaryColor:
                            formState.selectedRegAssociation.primaryColor,
                          secondaryColor:
                            formState.selectedRegAssociation.secondaryColor,
                          file: null,
                          filePreview: null
                        }
                      })
                    }
                    style={{ color: 'dodgerblue', fontSize: 14 }}
                  >
                    Reset
                  </Anchor>
                )}
              </AppFlexbox>
              <AppCard
                radius="md"
                shadow="none"
                style={{ padding: 8 }}
                withBorder
              >
                <AppStack style={{ gap: 8 }}>
                  <AppFlexbox style={{ flex: 1, alignItems: 'center' }}>
                    <AppCard
                      className={classes['hover-button']}
                      onClick={() => {
                        if (!formState.loading) {
                          imageInputRef.current.click();
                        }
                      }}
                      radius="md"
                      style={{
                        width: 125,
                        height: '100%',
                        padding: 0,
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      withBorder
                    >
                      <AppStack
                        className={classes['hover-show']}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          padding: 10,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(240, 240, 240, 0.9)',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        <AppText
                          style={{
                            color: 'dodgerblue',
                            fontWeight: 500,
                            fontSize: 14
                          }}
                        >
                          Click to change
                        </AppText>
                      </AppStack>
                      <Image
                        fit="contain"
                        h="100%"
                        src={
                          formState.teamData.filePreview ??
                          formState.teamData.logoImageUrl
                        }
                        w="100%"
                      />
                      <input
                        ref={imageInputRef}
                        accept="image/png, image/jpeg"
                        hidden
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            setFormState({
                              ...formState,
                              teamData: {
                                ...formState.teamData,
                                file: e.target.files[0],
                                filePreview: URL.createObjectURL(
                                  e.target.files[0]
                                )
                              }
                            });
                          }
                        }}
                        type="file"
                      />
                    </AppCard>
                    <AppStack style={{ gap: 5 }}>
                      <ColorInput
                        disabled={formState.loading}
                        label="Primary color"
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            teamData: {
                              ...formState.teamData,
                              primaryColor: value
                            }
                          })
                        }
                        popoverProps={{ zIndex: 1000 }}
                        style={{ flex: 1 }}
                        value={formState.teamData.primaryColor}
                      />

                      <ColorInput
                        disabled={formState.loading}
                        label="Secondary color"
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            teamData: {
                              ...formState.teamData,
                              secondaryColor: value
                            }
                          })
                        }
                        popoverProps={{ zIndex: 1000 }}
                        style={{ flex: 1 }}
                        value={formState.teamData.secondaryColor}
                      />
                    </AppStack>
                  </AppFlexbox>
                  <TextInput
                    label="Support email"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        teamData: {
                          ...formState.teamData,
                          supportEmail: e.currentTarget.value
                        }
                      })
                    }
                    type="email"
                    value={formState.teamData.supportEmail}
                  />
                </AppStack>
              </AppCard>
              <AppText
                style={{ fontSize: 13, color: '#666', textAlign: 'center' }}
              >
                These values were copied directly from{' '}
                {formState.selectedRegAssociation.association.name}. You can
                change them here or in the store settings later.
              </AppText>
            </AppStack>
          )}
        </AppStack>
      )}

      {!formState.loading && (
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 20,
            paddingTop: 0
          }}
        >
          <Button
            color="dark"
            disabled={formState.loading}
            leftSection={<ChevronLeft size={18} />}
            onClick={() => {
              if (
                formState.showTeamSelect ||
                (!teams.length && !associationTeams.length)
              ) {
                onCancel();
              }
              else {
                setFormState({
                  ...formState,
                  showTeamSelect: true
                });
              }
            }}
            size="sm"
            type="button"
            variant="subtle"
          >
            {formState.showTeamSelect ||
            (!teams.length && !associationTeams.length)
              ? 'Cancel'
              : 'Back'}
          </Button>
          {!formState.showTeamSelect && (
            <Button
              color="dark"
              disabled={!formState.teamData.name}
              loading={formState.loading}
              rightSection={<ArrowRight size={18} />}
              size="sm"
              type="submit"
              variant="filled"
            >
              Create
            </Button>
          )}
        </AppFlexbox>
      )}
    </AppStack>
  );
};

CreateStoreForm.propTypes = { onCancel: PropTypes.func.isRequired };

export default CreateStoreForm;
