import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { CONSOLE_DEBUG, LOCAL_API, PRODUCTION } from '../config/constants';

const sportsheadzApi = axios.create({
  baseURL: LOCAL_API
    ? 'http://localhost:61249'
    : PRODUCTION
    ? 'https://api.sportsheadz.com/prod'
    : 'https://api.sportsheadz.com/stage'
});

sportsheadzApi.interceptors.request.use(
  async (config) => {
    const token = Auth.user
      ? (await Auth.currentSession()).getIdToken().getJwtToken()
      : null;
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

sportsheadzApi.interceptors.response.use(
  (response) => {
    if (CONSOLE_DEBUG) {
      console.log(response, response.data);
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default sportsheadzApi;
