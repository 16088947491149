import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Select, TextInput } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import {
  CANADA_PROVINCE_LIST_OPTIONS,
  USA_STATE_LIST_OPTIONS
} from '../../../config/locationConstants';

const CustomerAddressModal = ({
  isOpen,
  onClose,
  address,
  isBilling,
  addressCopy
}) => {
  const [formState, setFormState] = useState({
    fullName: '',
    address1: '',
    address2: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    sameAsCopy: false,
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        fullName: address?.fullName || '',
        address1: address?.address1 || '',
        address2: address?.address2 || '',
        city: address?.city || '',
        state: address?.state || '',
        postalCode: address?.postalCode || '',
        country: address?.country || '',
        sameAsCopy: false,
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        submitTitle: 'Update address',
        cancelTitle: 'Close',
        onCancel: onClose,
        onSubmit: () => {},
        isLoading: formState.loading
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={isBilling ? 'Billing address' : 'Shipping address'}
    >
      <AppStack style={{}}>
        <Checkbox
          checked={formState.sameAsCopy}
          disabled={formState.loading}
          label={
            isBilling ? 'Same as shipping address' : 'Same as billing address'
          }
          onChange={() =>
            setFormState({
              ...formState,
              sameAsCopy: !formState.sameAsCopy
            })
          }
        />

        <TextInput
          disabled={formState.sameAsCopy || formState.loading}
          label="Full name"
          onChange={(e) =>
            setFormState({
              ...formState,
              fullName: e.target.value
            })
          }
          required
          value={formState.fullName}
        />

        <Select
          clearable
          data={[
            { label: 'Canada', value: 'CA' },
            { label: 'United States', value: 'US' }
          ]}
          disabled={formState.sameAsCopy || formState.loading}
          label="Country"
          onChange={(v) =>
            setFormState({
              ...formState,
              country: v
            })
          }
          required
          searchable
          value={formState.country}
        />

        <TextInput
          disabled={formState.sameAsCopy || formState.loading}
          label="Address 1"
          onChange={(e) =>
            setFormState({
              ...formState,
              address1: e.target.value
            })
          }
          required
          value={formState.address1}
        />
        <TextInput
          disabled={formState.sameAsCopy || formState.loading}
          label="Address 2"
          onChange={(e) =>
            setFormState({
              ...formState,
              address2: e.target.value
            })
          }
          value={formState.address2}
        />
        <AppFlexbox style={{ flex: 1 }}>
          <TextInput
            disabled={formState.sameAsCopy || formState.loading}
            label="City"
            onChange={(e) =>
              setFormState({
                ...formState,
                city: e.target.value
              })
            }
            required
            style={{ flex: 1 }}
            value={formState.city}
          />
          <Select
            data={
              formState.country === 'CA'
                ? CANADA_PROVINCE_LIST_OPTIONS.map((p) => ({
                    label: p.label,
                    value: p.abbr
                  }))
                : USA_STATE_LIST_OPTIONS.map((s) => ({
                    label: s.label,
                    value: s.abbr
                  }))
            }
            disabled={formState.sameAsCopy || formState.loading}
            label={formState.country === 'CA' ? 'Province' : 'State'}
            onChange={(v) =>
              setFormState({
                ...formState,
                state: v
              })
            }
            required
            style={{ flex: 1 }}
            value={formState.state}
          />
        </AppFlexbox>
        <TextInput
          disabled={formState.sameAsCopy || formState.loading}
          label="Postal code"
          onChange={(e) =>
            setFormState({
              ...formState,
              postalCode: e.target.value
            })
          }
          required
          value={formState.postalCode}
        />
      </AppStack>
    </ResponsiveModal>
  );
};

CustomerAddressModal.propTypes = {
  address: PropTypes.object,
  addressCopy: PropTypes.object,
  isBilling: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default CustomerAddressModal;
