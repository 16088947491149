/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import AppStack from '../../../../common/AppStack';
import AppCard from '../../../../common/AppCard';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppText from '../../../../common/AppText';

const WsBuyButtonsBlock = ({
  ecomStoreProduct,
  selectedVariant,
  submitting,
  config,
  optionErrors
}) => {
  const isUnavailable =
    ecomStoreProduct?.deleted ||
    ecomStoreProduct?.ecomVendorProduct.deleted ||
    selectedVariant?.deleted ||
    selectedVariant?.unavailable ||
    selectedVariant?.ecomVendorProductVariant.deleted;

  const quantityLeft = selectedVariant
    ? selectedVariant.ecomVendorProductVariant.quantity
    : null;
  const isOutOfStock = quantityLeft !== null && quantityLeft <= 0;

  return (
    <AppStack>
      {optionErrors && optionErrors.length > 0 && (
        <AppCard
          radius={0}
          shadow="xs"
          style={{ padding: 0, marginTop: 10 }}
          withBorder
        >
          <AppFlexbox
            style={{
              padding: 8,
              gap: 8,
              backgroundColor: '#c40000',
              color: '#fff'
            }}
          >
            <AppStack>
              <AlertCircle size={18} />
            </AppStack>
            <AppText style={{ fontSize: 14 }}>{optionErrors[0]}</AppText>
          </AppFlexbox>
        </AppCard>
      )}

      <Button
        color="dark"
        disabled={!selectedVariant || isUnavailable || isOutOfStock}
        loading={submitting}
        radius={0}
        size="lg"
        style={{ width: '100%', maxWidth: 400, maxHeight: 46, marginTop: 4 }}
        type="submit"
        variant="filled"
      >
        {isOutOfStock
          ? 'Out of stock'
          : isUnavailable
          ? 'Unavailable'
          : 'Add to Cart'}
      </Button>
    </AppStack>
  );
};

WsBuyButtonsBlock.propTypes = {
  config: PropTypes.object,
  ecomStoreProduct: PropTypes.object,
  optionErrors: PropTypes.array,
  selectedVariant: PropTypes.object,
  submitting: PropTypes.bool
};

export default WsBuyButtonsBlock;
