import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider, NumberFormatter, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import AppCard from '../../../../common/AppCard';
import AppStack from '../../../../common/AppStack';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppText from '../../../../common/AppText';
import { formatUtcDate } from '../../../../../helpers/format';
import WsAccountOrderProductListItem from './WsAccountOrderProductListItem';

const OrderListItem = ({
  ecomStoreCart,
  mediaQueries,
  baseStoreUrl,
  onNavigate
}) => {
  const { isTabletOrSmaller, getResponsiveStyle: rs } = mediaQueries;
  const hasBeenRefunded = false;

  return ecomStoreCart ? (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: 0 }}
      withBorder
    >
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          style={{
            backgroundColor: '#000',
            color: '#FFF',
            opacity: 0.8,
            padding: '15px 30px',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 10,
            flexDirection: rs([
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row'
            ])
          }}
        >
          <AppFlexbox
            style={{
              gap: rs([10, 10, 10, 40]),
              flex: rs([1, 1, 1, 'unset']),
              alignSelf: rs(['stretch', 'stretch', 'stretch', 'unset']),
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14 }}>ORDER PLACED</AppText>
              <AppText style={{ fontSize: 16 }} weight={700}>
                {ecomStoreCart.cartBalance.purchaseDate
                  ? dayjs(
                      formatUtcDate(ecomStoreCart.cartBalance.purchaseDate)
                    ).format('MMM D, YYYY')
                  : 'N/A'}
              </AppText>
            </AppStack>
            <AppStack
              style={{ gap: 0, alignItems: rs(['end', 'end', 'end', 'start']) }}
            >
              <AppText style={{ fontSize: 14 }}>TOTAL</AppText>
              <AppFlexbox style={{ gap: 10 }}>
                <AppText style={{ fontSize: 16 }} weight={700}>
                  <NumberFormatter
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={ecomStoreCart.cartBalance.totalInCents / 100}
                  />
                </AppText>
                <AppText style={{ fontSize: 16 }} weight={700}>
                  {ecomStoreCart.cartBalance.currency?.toUpperCase()}
                </AppText>
              </AppFlexbox>
            </AppStack>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14 }}>STATUS</AppText>
              <AppText style={{ fontSize: 16 }} weight={700}>
                {hasBeenRefunded ? 'REFUNDED' : 'RESERVED'}
              </AppText>
            </AppStack>
          </AppFlexbox>

          <AppStack
            style={{
              gap: 0,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: rs(['row', 'row', 'row', 'column'])
            }}
          >
            <AppText style={{ fontSize: 14 }} weight={500}>
              ORDER # {ecomStoreCart.pkEcomStoreCart}
            </AppText>
            <AppText
              component={Link}
              onClick={onNavigate}
              style={{
                fontSize: 16,
                textDecoration: 'none',
                color: 'dodgerblue',
                textAlign: 'end'
              }}
              to={`${baseStoreUrl}/account/orders/${ecomStoreCart.pkEcomStoreCart}`}
              weight={700}
            >
              View order details
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppStack style={{ padding: '15px 30px', gap: 20 }}>
          {ecomStoreCart.cartBalance.lineItems.map((lineItem, index) => (
            <React.Fragment key={lineItem.entityId}>
              {index > 0 && <Divider />}
              <WsAccountOrderProductListItem
                baseStoreUrl={baseStoreUrl}
                lineItem={lineItem}
                mediaQueries={mediaQueries}
                onNavigate={onNavigate}
              />
            </React.Fragment>
          ))}

          {ecomStoreCart.cartBalance.lineItems.length > 5 && (
            <AppText
              component={Link}
              onClick={onNavigate}
              style={{
                fontSize: 20,
                textDecoration: 'none',
                color: 'dodgerblue'
              }}
              to={`/orders/${ecomStoreCart.pkEcomStoreCart}`}
              weight={500}
            >
              See All {ecomStoreCart.cartBalance.lineItems.length} items
            </AppText>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: 0 }}
      withBorder
    >
      <AppStack style={{ flex: 1, gap: 0 }}>
        <AppFlexbox
          style={{
            flex: 1,
            backgroundColor: '#000',
            color: '#FFF',
            opacity: 0.8,
            padding: '15px 30px',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 10,
            flexDirection: rs([
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row'
            ])
          }}
        >
          <AppFlexbox
            style={{
              gap: rs([10, 10, 10, 40]),
              flex: 1,
              alignSelf: rs(['stretch', 'stretch', 'stretch', 'unset']),
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <AppStack style={{ flex: 1, gap: 10 }}>
              <Skeleton height={16} width="85%" />
              <Skeleton height={16} width="85%" />
            </AppStack>

            <AppStack
              style={{
                flex: 1,
                gap: 10,
                alignItems: rs(['end', 'end', 'end', 'start'])
              }}
            >
              <Skeleton height={16} width={80} />

              <Skeleton height={16} width={80} />
            </AppStack>
          </AppFlexbox>

          <AppStack
            style={{
              flex: 1,
              gap: 10,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: rs(['row', 'row', 'row', 'column'])
            }}
          >
            <Skeleton height={18} width="45%" />
            <Skeleton height={18} width="45%" />
          </AppStack>
        </AppFlexbox>

        <AppStack style={{ padding: '15px 30px', gap: 20 }}>
          <AppFlexbox
            style={{
              flexWrap: 'nowrap',
              flexDirection: rs(['column', 'column', 'column', 'row'])
            }}
          >
            <AppFlexbox
              style={{ flexWrap: 'nowrap', flex: 2, alignSelf: 'stretch' }}
            >
              <Skeleton
                height={isTabletOrSmaller ? 70 : 100}
                width={isTabletOrSmaller ? 70 : 100}
              />
              <AppStack
                style={{
                  flex: 1,
                  gap: 10,
                  textDecoration: 'none',
                  color: '#000'
                }}
              >
                <Skeleton height={12} width="50%" />
                <Skeleton height={12} width="40%" />
              </AppStack>
            </AppFlexbox>

            <AppStack
              style={{
                flex: 1,
                alignSelf: 'start',
                placeItems: 'end',
                marginLeft: 'auto',
                gap: rs([0, 0, 0, 30]),
                flexDirection: rs([
                  'row-reverse',
                  'row-reverse',
                  'row-reverse',
                  'column'
                ]),
                justifyContent: rs([
                  'space-between',
                  'space-between',
                  'space-between',
                  'inital'
                ]),
                width: rs(['100%', '100%', '100%', 'unset'])
              }}
            >
              <AppStack style={{ gap: 0, alignItems: 'end' }}>
                <Skeleton height={20} width={100} />
              </AppStack>
            </AppStack>
          </AppFlexbox>
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

OrderListItem.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomStoreCart: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func
};

export default OrderListItem;
