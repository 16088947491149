import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  ActionIcon,
  Alert,
  Badge,
  Button,
  Divider,
  Skeleton
} from '@mantine/core';
import { Check, CircleCheck, X } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppCard from './AppCard';
import { Context as HelperContext } from '../../providers/HelperContextProvider';
import AppFlexbox from './AppFlexbox';
import AppStack from './AppStack';
import AppText from './AppText';
import { getFromStorage, saveToStorage } from '../../helpers/storage';
import { useMediaQueryIndex } from '../../helpers/hooks';

const SetupGuideSidebar = ({ loading, state, setupGuideConfig, id }) => {
  const navigate = useNavigate();
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { pathname, search } = useLocation();
  const activeSidebarStep = getFromStorage('activeSidebarStep');
  const skippedStepsString = getFromStorage(id);
  const { state: helperState, toggleSetupGuideSidebar } = useContext(
    HelperContext
  );
  const [activeStep, setActiveStep] = useState(null);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = setupGuideConfig.sections.flatMap((s) => s.steps);
  const completedSteps = steps.filter(
    (s) => s.isCompleted(state) || skippedSteps.includes(s.value)
  );
  const isGuideCompleted =
    steps.length === completedSteps.length ||
    setupGuideConfig.isCompleted(state);

  useEffect(() => {
    setActiveStep(
      activeSidebarStep ?? helperState.setupGuide.activeSidebarStep
    );
  }, [helperState.setupGuide.activeSidebarStep]);

  const onStepButtonClick = (e, action, url, step) => {
    if (action === 'skip') {
      e.preventDefault();
      e.stopPropagation();
      saveToStorage(id, [...skippedSteps, step.value].join(','));
    }
    else if (action === 'link' && pathname + search === url) {
      e.preventDefault();
      e.stopPropagation();
      navigate(pathname);
      setTimeout(() => {
        navigate(pathname + search);
      }, [0]);
    }
  };

  useEffect(() => {
    setSkippedSteps(skippedStepsString?.split(',') ?? []);
  }, [skippedStepsString]);

  return (
    helperState.setupGuide.showSidebar && (
      <AppCard
        radius="md"
        shadow="xl"
        style={{
          zIndex: 100,
          width: 350,
          marginRight: 5,
          padding: 0,
          height: 'calc(100vh - 70px)',
          maxHeight: 'calc(100vh - 70px)',
          position: isDesktopOrSmaller ? 'fixed' : 'sticky',
          top: 65,
          right: 0,
          overflow: 'hidden'
        }}
        visibleFrom="xsm"
        withBorder
      >
        {!loading ? (
          <AppStack style={{ gap: 0, flex: 1 }}>
            <AppStack style={{ gap: 0, padding: 16 }}>
              <AppFlexbox
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontWeight: 700 }}>Setup guide</AppText>
                <ActionIcon
                  color="dark"
                  onClick={() => toggleSetupGuideSidebar(false)}
                  radius="md"
                  variant="subtle"
                >
                  <X color="grey" size={18} />
                </ActionIcon>
              </AppFlexbox>
              <Badge
                color={isGuideCompleted ? 'green' : 'dark'}
                style={{ marginTop: 5, border: 'solid 1px #dee2e6' }}
                variant={isGuideCompleted ? 'filled' : 'light'}
              >
                {isGuideCompleted ? steps.length : completedSteps.length} /{' '}
                {steps.length} steps completed
              </Badge>
            </AppStack>
            <Divider />
            <AppStack style={{ gap: 0, padding: 8, overflow: 'auto' }}>
              <Accordion
                onChange={(value) => toggleSetupGuideSidebar(true, value)}
                styles={{
                  control: { padding: 8 },
                  label: { padding: 0 },
                  content: { padding: 0, borderRadius: 30 },
                  chevron: { display: 'none' }
                }}
                value={activeStep}
                variant="filled"
              >
                {steps.map((step) => {
                  const isStepCompleted =
                    step.isCompleted(state) ||
                    skippedSteps.includes(step.value);
                  const isStepDisbled =
                    step.disableAfterCompletion && isStepCompleted;

                  return (
                    <Accordion.Item
                      key={step.value}
                      style={{
                        backgroundColor:
                          step.value === activeStep
                            ? 'rgba(245, 245, 245, 1)'
                            : 'unset'
                      }}
                      value={step.value}
                      variant="filled"
                    >
                      <Accordion.Control>
                        <AppFlexbox
                          style={{
                            gap: 10,
                            alignItems: 'center'
                          }}
                        >
                          {isGuideCompleted || isStepCompleted ? (
                            <AppStack
                              style={{
                                height: 20,
                                width: 20
                              }}
                            >
                              <CircleCheck
                                color="#067D62"
                                style={{ width: '100%', height: '100%' }}
                              />
                            </AppStack>
                          ) : (
                            <AppStack
                              style={{
                                height: 20,
                                width: 20,
                                borderRadius: 100,
                                border: 'dashed 2px #999'
                              }}
                            />
                          )}

                          <AppText
                            style={{
                              fontWeight: 500,
                              color: '#333',
                              fontSize: 14
                            }}
                          >
                            {step.title}
                          </AppText>
                        </AppFlexbox>
                      </Accordion.Control>
                      <Accordion.Panel>
                        <AppFlexbox
                          style={{ padding: 8, paddingTop: 0, gap: 10 }}
                        >
                          <AppStack
                            style={{
                              height: 20,
                              width: 20
                            }}
                          />
                          <AppStack style={{ gap: 0, flex: 1 }}>
                            <AppText style={{ fontSize: 14, color: '#666' }}>
                              {step.description}
                            </AppText>
                            {step.subDescription && (
                              <AppText style={{ fontSize: 14, color: '#666' }}>
                                {step.subDescription}
                              </AppText>
                            )}
                            <AppFlexbox style={{ marginTop: 10 }}>
                              {step.primaryButton &&
                                (step.primaryButton?.action !== 'skip' ||
                                  !isStepCompleted) && (
                                  <Button
                                    color="dark"
                                    component={isStepDisbled ? null : Link}
                                    disabled={isStepDisbled}
                                    leftSection={
                                      step.primaryButton.icon && (
                                        <step.primaryButton.icon size={18} />
                                      )
                                    }
                                    onClick={(e) => {
                                      if (!isStepDisbled) {
                                        toggleSetupGuideSidebar(
                                          true,
                                          step.value
                                        );
                                        onStepButtonClick(
                                          e,
                                          step.primaryButton?.action,
                                          step.primaryButton.url,
                                          step
                                        );
                                      }
                                    }}
                                    radius="md"
                                    size="compact-sm"
                                    to={step.primaryButton.url}
                                  >
                                    {step.primaryButton.title}
                                  </Button>
                                )}
                              {step.secondaryButton &&
                                (step.secondaryButton?.action !== 'skip' ||
                                  !isStepCompleted) && (
                                  <Button
                                    color="dark"
                                    component={isStepDisbled ? null : Link}
                                    disabled={isStepDisbled}
                                    leftSection={
                                      step.secondaryButton.icon && (
                                        <step.secondaryButton.icon size={18} />
                                      )
                                    }
                                    onClick={(e) => {
                                      if (!isStepDisbled) {
                                        toggleSetupGuideSidebar(
                                          true,
                                          step.value
                                        );
                                        onStepButtonClick(
                                          e,
                                          step.secondaryButton?.action,
                                          step.secondaryButton.url,
                                          step
                                        );
                                      }
                                    }}
                                    radius="md"
                                    size="compact-sm"
                                    to={step.secondaryButton.url}
                                    variant="outline"
                                  >
                                    {step.secondaryButton.title}
                                  </Button>
                                )}
                            </AppFlexbox>
                          </AppStack>
                        </AppFlexbox>
                      </Accordion.Panel>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </AppStack>

            {isGuideCompleted && (
              <AppStack style={{ padding: 8 }}>
                <Alert
                  color="#067D62"
                  icon={<CircleCheck size={18} />}
                  title="Setup guide completed!"
                  variant="outline"
                >
                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14 }}>
                      You have completed all the steps in the setup guide. You
                      can now close this sidebar.
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      If you need to revisit this guide at any time, you can do
                      so by visiting the home page.
                    </AppText>
                    <Button
                      color="#067D62"
                      leftSection={<Check size={18} />}
                      onClick={() => toggleSetupGuideSidebar(false)}
                      size="compact-md"
                      style={{ marginTop: 5 }}
                      variant="outline"
                    >
                      Close guide
                    </Button>
                  </AppStack>
                </Alert>
              </AppStack>
            )}
          </AppStack>
        ) : (
          <AppStack style={{ gap: 0, flex: 1 }}>
            <AppStack style={{ gap: 5, padding: 16 }}>
              <AppFlexbox
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Skeleton height={20} width={100} />
                <ActionIcon
                  color="dark"
                  onClick={() => toggleSetupGuideSidebar(false)}
                  radius="md"
                  variant="subtle"
                >
                  <X color="grey" size={18} />
                </ActionIcon>
              </AppFlexbox>
              <Skeleton height={18} radius={100} width={150} />
            </AppStack>
            <Divider />
            <AppStack style={{ gap: 0, padding: 8 }}>
              <Accordion
                onChange={() => {}}
                styles={{
                  control: { padding: 8 },
                  label: { padding: 0 },
                  content: { padding: 0, borderRadius: 30 },
                  chevron: { display: 'none' }
                }}
                value={activeStep}
                variant="filled"
              >
                {steps.map((step) => (
                  <Accordion.Item
                    key={step.value}
                    style={{
                      backgroundColor:
                        step.value === activeStep
                          ? 'rgba(245, 245, 245, 1)'
                          : 'unset'
                    }}
                    value={step.value}
                    variant="filled"
                  >
                    <Accordion.Control>
                      <AppFlexbox
                        style={{
                          gap: 10,
                          alignItems: 'center'
                        }}
                      >
                        <Skeleton height={20} radius={100} width={20} />
                        <Skeleton height={20} width={step.title.length * 7} />
                      </AppFlexbox>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <AppFlexbox
                        style={{
                          padding: 8,
                          paddingTop: 0,
                          gap: 10,
                          marginTop: 10
                        }}
                      >
                        <AppStack
                          style={{
                            height: 20,
                            width: 20
                          }}
                        />
                        <AppStack style={{ gap: 5, flex: 1 }}>
                          <Skeleton height={12} width="100%" />
                          <Skeleton height={12} width="50%" />
                          <AppFlexbox style={{ marginTop: 10 }}>
                            {step.primaryButton && (
                              <Skeleton
                                height={26}
                                width={step.primaryButton.title.length * 7 + 42}
                              />
                            )}
                            {step.secondaryButton && (
                              <Skeleton
                                height={26}
                                width={
                                  step.secondaryButton.title.length * 7 + 42
                                }
                              />
                            )}
                          </AppFlexbox>
                        </AppStack>
                      </AppFlexbox>
                    </Accordion.Panel>
                  </Accordion.Item>
                ))}
              </Accordion>
            </AppStack>
          </AppStack>
        )}
      </AppCard>
    )
  );
};

SetupGuideSidebar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  setupGuideConfig: PropTypes.object,
  state: PropTypes.object
};

export default SetupGuideSidebar;
