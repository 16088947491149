import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Menu, ScrollArea } from '@mantine/core';
import { ChevronDown, Menu2, Plus, Tag } from 'tabler-icons-react';
import { ECOM_ENTITY_TYPES } from '../../../config/constants';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppMenu from '../../common/AppMenu';
import WebstoreColorSchemeDisplay from './WebstoreColorSchemeDisplay';
import AppCard from '../../common/AppCard';
import MediaDropzone from '../media/MediaDropzone';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';

const WebstoreBuilderConfigResourceSelect = ({
  label,
  value,
  defaultValue,
  onChange,
  entityType,
  fkEcomStore,
  entities,
  allCollectionDisabled,
  onAddColorScheme
}) => {
  const { state } = useContext(StoreContext);
  const currentValue = value === undefined ? defaultValue : value;
  const valueSelected =
    currentValue ||
    (entityType === ECOM_ENTITY_TYPES.ecomStoreCollection &&
      !allCollectionDisabled);

  const selectedCollection = state.ecomStoreResourceOptions.value?.ecomStoreCollections.find(
    (c) => c.pkEcomStoreCollection.toString() === currentValue
  )?.name;

  return (
    <AppStack style={{ gap: 5 }}>
      <AppText style={{ fontSize: 14 }}>{label}</AppText>
      <AppCard
        style={{ backgroundColor: 'rgba(200, 200, 200, 0.1)' }}
        withBorder
      >
        <AppStack>
          {valueSelected &&
            (entityType === ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme ? (
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <WebstoreColorSchemeDisplay
                  colorScheme={
                    entities.ecomStoreThemeColorSchemes
                      .sort((a, b) => a.sort - b.sort)
                      .find(
                        (c, index) => (index + 1).toString() === currentValue
                      ) ?? entities.ecomStoreThemeColorSchemes[0]
                  }
                  smallDisplay
                />
                <AppText style={{ fontSize: 16 }}>
                  Scheme {currentValue}
                </AppText>
              </AppFlexbox>
            ) : entityType === ECOM_ENTITY_TYPES.ecomStoreMedia ? (
              <AppStack style={{ gap: 10 }}>
                <AppStack
                  style={{
                    width: '100%',
                    height: 120,
                    justifyContent: 'center'
                  }}
                >
                  <Image fit="contain" h={120} src={currentValue} />
                </AppStack>
                <Button
                  color="dark"
                  onClick={() => onChange('')}
                  size="compact-md"
                  style={{
                    fontSize: 14,
                    outline: 'solid 1px #666'
                  }}
                  variant="white"
                >
                  Remove
                </Button>
              </AppStack>
            ) : entityType === ECOM_ENTITY_TYPES.ecomStoreCollection &&
              (selectedCollection || !allCollectionDisabled) ? (
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <AppCard style={{ padding: 5 }}>
                  <Tag color="#000" size={18} />
                </AppCard>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {selectedCollection ?? 'All'}
                </AppText>
              </AppFlexbox>
            ) : entityType === ECOM_ENTITY_TYPES.ecomStoreProduct ? (
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <AppCard style={{ padding: 5 }}>
                  <Tag color="#000" size={18} />
                </AppCard>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {
                    state.ecomStoreResourceOptions.value?.ecomStoreProducts.find(
                      (c) => c.uuid === currentValue
                    )?.name
                  }
                </AppText>
              </AppFlexbox>
            ) : entityType === ECOM_ENTITY_TYPES.ecomStoreMenu ? (
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <AppCard style={{ padding: 5 }}>
                  <Menu2 color="#000" size={18} />
                </AppCard>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {
                    state.ecomStoreResourceOptions.value?.ecomStoreMenus.find(
                      (c) => c.pkEcomStoreMenu.toString() === currentValue
                    )?.name
                  }
                </AppText>
              </AppFlexbox>
            ) : (
              <></>
            ))}

          {entityType === ECOM_ENTITY_TYPES.ecomStoreMedia ? (
            !currentValue && (
              <AppStack style={{ width: '100%', height: 120 }}>
                <MediaDropzone
                  enableMediaSelect
                  files={[]}
                  fkEcomStore={fkEcomStore}
                  height={120}
                  maxSelectCount={1}
                  onUpload={(media) => onChange(media[0]?.src ?? '')}
                  skipMediaFetch
                  uploadOnDrop
                />
              </AppStack>
            )
          ) : (
            <AppMenu
              control={
                <Button
                  color="dark"
                  onClick={() => {}}
                  rightSection={<ChevronDown />}
                  size="compact-md"
                  style={{ fontSize: 14, outline: 'solid 1px #666' }}
                  variant="white"
                >
                  {entityType === ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
                    ? 'Change color scheme'
                    : `${
                        valueSelected ? 'Change' : 'Select'
                      } ${label.toLowerCase()}`}
                </Button>
              }
              position="bottom-center"
              width={250}
            >
              <ScrollArea.Autosize mah={400} scrollbarSize={5} type="scroll">
                {entityType === ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme ? (
                  <>
                    <Menu.Item
                      onClick={() => {
                        onAddColorScheme();
                      }}
                    >
                      <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                        <AppCard
                          radius={10}
                          shadow="none"
                          style={{
                            padding: 0,
                            minWidth: 55,
                            height: 40,
                            maxHeight: 40,
                            border: 'dashed 1px dodgerblue'
                          }}
                        >
                          <AppStack
                            style={{
                              gap: 0,
                              flex: 1,
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Plus color="dodgerblue" size={18} />
                          </AppStack>
                        </AppCard>
                        <AppText style={{ fontSize: 14, color: 'dodgerblue' }}>
                          Create new scheme
                        </AppText>
                      </AppFlexbox>
                    </Menu.Item>

                    {entities.ecomStoreThemeColorSchemes
                      .sort((a, b) => a.sort - b.sort)
                      .map((scheme, index) => (
                        <Menu.Item
                          key={scheme.key}
                          onClick={() => {
                            onChange((index + 1).toString());
                          }}
                        >
                          <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                            <WebstoreColorSchemeDisplay
                              colorScheme={scheme}
                              smallDisplay
                            />
                            <AppText style={{ fontSize: 14 }}>
                              Scheme {index + 1}
                            </AppText>
                          </AppFlexbox>
                        </Menu.Item>
                      ))}
                  </>
                ) : entityType === ECOM_ENTITY_TYPES.ecomStoreCollection ? (
                  <>
                    {!allCollectionDisabled && (
                      <Menu.Item
                        leftSection={<Tag color="#000" size={18} />}
                        onClick={() => {
                          onChange('');
                        }}
                      >
                        <AppText style={{ fontSize: 14 }}>All</AppText>
                      </Menu.Item>
                    )}

                    {state.ecomStoreResourceOptions.value?.ecomStoreCollections
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((collection) => (
                        <Menu.Item
                          key={collection.pkEcomStoreCollection}
                          leftSection={<Tag color="#000" size={18} />}
                          onClick={() => {
                            onChange(
                              collection.pkEcomStoreCollection.toString()
                            );
                          }}
                        >
                          <AppText style={{ fontSize: 14 }}>
                            {collection.name}
                          </AppText>
                        </Menu.Item>
                      ))}
                  </>
                ) : entityType === ECOM_ENTITY_TYPES.ecomStoreProduct ? (
                  state.ecomStoreResourceOptions.value?.ecomStoreProducts
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((product) => (
                      <Menu.Item
                        key={product.uuid}
                        onClick={() => {
                          onChange(product.uuid);
                        }}
                      >
                        <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                          <AppStack
                            style={{
                              width: 25,
                              height: 25,
                              alignItems: 'center'
                            }}
                          >
                            <Image
                              fit="contain"
                              h="100%"
                              src={product.previewImage}
                              w="100%"
                            />
                          </AppStack>
                          <AppText style={{ fontSize: 14 }}>
                            {product.name}
                          </AppText>
                        </AppFlexbox>
                      </Menu.Item>
                    ))
                ) : entityType === ECOM_ENTITY_TYPES.ecomStoreMenu ? (
                  state.ecomStoreResourceOptions.value?.ecomStoreMenus
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((menu) => (
                      <Menu.Item
                        key={menu.pkEcomStoreMenu}
                        leftSection={<Tag color="#000" size={18} />}
                        onClick={() => {
                          onChange(menu.pkEcomStoreMenu.toString());
                        }}
                      >
                        <AppText style={{ fontSize: 14 }}>{menu.name}</AppText>
                      </Menu.Item>
                    ))
                ) : (
                  <></>
                )}
              </ScrollArea.Autosize>
            </AppMenu>
          )}
        </AppStack>
      </AppCard>
    </AppStack>
  );
};

WebstoreBuilderConfigResourceSelect.propTypes = {
  allCollectionDisabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entities: PropTypes.object,
  entityType: PropTypes.string,
  fkEcomStore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onAddColorScheme: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default WebstoreBuilderConfigResourceSelect;
