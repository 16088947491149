import React, { useState } from 'react';
import { ChevronRight, CirclePlus } from 'tabler-icons-react';
import { TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppMenu from '../../common/AppMenu';
import WebstoreBuilderSidebarButton from './WebstoreBuilderSidebarButton';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import ResponsiveModal from '../../common/ResponsiveModal';

const WebstoreBuilderSidebarAddItemMenu = ({
  onAdd,
  blocks,
  sectionBlocks,
  maxItemCount,
  isLaptopOrSmaller
}) => {
  const [formState, setFormState] = useState({ search: '', menuOpen: false });
  const maxLimitReached =
    maxItemCount !== null && sectionBlocks.length > maxItemCount;
  const availableBlocks = maxLimitReached
    ? []
    : blocks.filter(
        (s) =>
          (s.maxCount === null ||
            sectionBlocks.filter(
              (i) => i.fkEcomStoreThemeSectionBlockType === s.type
            ).length < s.maxCount) &&
          (!formState.search ||
            s.title.toLowerCase().includes(formState.search.toLowerCase()))
      );

  return (
    <>
      <AppMenu
        control={
          <AppFlexbox style={{ flex: 1 }}>
            <WebstoreBuilderSidebarButton
              color="dodgerblue"
              disabled={
                maxItemCount !== null && sectionBlocks.length >= maxItemCount
              }
              fontSize={isLaptopOrSmaller ? 16 : null}
              height={isLaptopOrSmaller ? 45 : null}
              itemCount={sectionBlocks.length}
              label={blocks.length === 1 ? blocks[0].title : 'Add item'}
              leftSection={
                <AppStack
                  style={{
                    width: 22,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CirclePlus size={18} />
                </AppStack>
              }
              onClick={() => {
                if (blocks.length === 1) {
                  onAdd(blocks[0]);
                }
                else {
                  setFormState({
                    ...formState,
                    menuOpen: true,
                    search: ''
                  });
                }
              }}
              selected={formState.menuOpen}
              style={isLaptopOrSmaller ? { padding: '0px 10px' } : null}
            />
          </AppFlexbox>
        }
        onClose={() => setFormState({ ...formState, menuOpen: false })}
        opened={!isLaptopOrSmaller && formState.menuOpen}
        position="right-start"
        radius={10}
        width={300}
      >
        <AppStack style={{ gap: 5, flex: 1, height: 500, padding: 2 }}>
          <TextInput
            onChange={(e) =>
              setFormState({
                ...formState,
                search: e.currentTarget.value
              })
            }
            placeholder="Search blocks"
            radius={10}
            size="sm"
            value={formState.search}
          />
          <AppStack style={{ gap: 1, flex: 1, overflow: 'auto' }}>
            {availableBlocks.length === 0 ? (
              <AppStack
                style={{
                  gap: 10,
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: '40px 20px'
                }}
              >
                {maxLimitReached ||
                (availableBlocks.length === 0 && blocks.length > 0) ? (
                  <AppText
                    style={{ fontSize: 14, fontWeight: 500, color: '#666' }}
                  >
                    No more blocks can be added to this section.
                  </AppText>
                ) : (
                  <>
                    <AppText
                      style={{ fontSize: 14, fontWeight: 500, color: '#666' }}
                    >
                      No blocks found.
                    </AppText>
                    {formState.search && (
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        Try searching for something else.
                      </AppText>
                    )}
                  </>
                )}
              </AppStack>
            ) : (
              availableBlocks.map((i) => (
                <WebstoreBuilderSidebarButton
                  key={i.type}
                  fontSize={isLaptopOrSmaller ? 16 : null}
                  height={isLaptopOrSmaller ? 45 : null}
                  label={i.title}
                  leftSection={
                    <AppStack
                      style={{
                        paddingLeft: 10,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <i.icon size={18} />
                    </AppStack>
                  }
                  onClick={() => {
                    onAdd(i);
                    setFormState({ ...formState, menuOpen: false });
                  }}
                />
              ))
            )}
          </AppStack>
        </AppStack>
      </AppMenu>
      {isLaptopOrSmaller && (
        <ResponsiveModal
          isDrawer={isLaptopOrSmaller}
          isOpen={formState.menuOpen}
          onClose={() => setFormState({ ...formState, menuOpen: false })}
          styles={{ content: { height: 'calc(100% - 60px)' } }}
          title="Add block"
        >
          <AppStack
            style={{
              gap: 5,
              flex: 1,
              minHeight: '100%',
              padding: 2
            }}
          >
            <TextInput
              onChange={(e) =>
                setFormState({
                  ...formState,
                  search: e.currentTarget.value
                })
              }
              placeholder="Search blocks"
              radius={10}
              size="sm"
              value={formState.search}
            />
            <AppStack style={{ gap: 1, flex: 1, overflow: 'auto' }}>
              {availableBlocks.length === 0 ? (
                <AppStack
                  style={{
                    gap: 10,
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '40px 20px'
                  }}
                >
                  {maxLimitReached ||
                  (availableBlocks.length === 0 && blocks.length > 0) ? (
                    <AppText
                      style={{ fontSize: 14, fontWeight: 500, color: '#666' }}
                    >
                      No more blocks can be added to this section.
                    </AppText>
                  ) : (
                    <>
                      <AppText
                        style={{ fontSize: 14, fontWeight: 500, color: '#666' }}
                      >
                        No blocks found.
                      </AppText>
                      {formState.search && (
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          Try searching for something else.
                        </AppText>
                      )}
                    </>
                  )}
                </AppStack>
              ) : (
                availableBlocks.map((i) => (
                  <WebstoreBuilderSidebarButton
                    key={i.type}
                    fontSize={16}
                    height={45}
                    label={i.title}
                    leftSection={
                      <AppStack
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <i.icon size={18} />
                      </AppStack>
                    }
                    onClick={() => {
                      onAdd(i);
                      setFormState({ ...formState, menuOpen: false });
                    }}
                    rightSection={<ChevronRight color="#666" size={18} />}
                    style={{ padding: '0px 10px' }}
                  />
                ))
              )}
            </AppStack>
          </AppStack>
        </ResponsiveModal>
      )}
    </>
  );
};

WebstoreBuilderSidebarAddItemMenu.propTypes = {
  blocks: PropTypes.array,
  isLaptopOrSmaller: PropTypes.bool,
  maxItemCount: PropTypes.number,
  onAdd: PropTypes.func,
  sectionBlocks: PropTypes.array
};

export default WebstoreBuilderSidebarAddItemMenu;
