import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import CustomSelect from '../../common/CustomSelect';

const StoreSelectInput = ({
  label,
  value,
  onChange,
  autoPopulate,
  placeholder,
  data,
  disabled,
  ...rest
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state, selectEcomStore } = useContext(AuthContext);
  const pkEcomStoreParam = searchParams.get('store') || state.pkEcomStore;
  const ecomStoreOptions =
    data ??
    state.ecomStores
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((a) => ({
        forceImage: true,
        image: a.logoImageUrl,
        value: a.pkEcomStore.toString(),
        label: a.name
      }));

  const selectValue = onChange ? value : state.pkEcomStore?.toString();

  useEffect(() => {
    const pkEcomStore =
      (
        state.ecomStores.find(
          (a) => a.pkEcomStore.toString() === pkEcomStoreParam.toString()
        )?.value ?? ecomStoreOptions[0]?.value
      )?.toString() ?? '';
    if (!selectValue && autoPopulate && pkEcomStore) {
      if (onChange) {
        onChange(pkEcomStore);
      }
      else {
        selectEcomStore(pkEcomStore);
      }
    }
  }, [pkEcomStoreParam, state.loading]);

  return (
    <CustomSelect
      data={ecomStoreOptions}
      disabled={disabled || state.loading}
      label={label}
      onChange={(v) => {
        if (onChange) {
          onChange(v);
        }
        else {
          const ecomStore = state.ecomStores.find(
            (a) => a.pkEcomStore.toString() === v
          );
          selectEcomStore(ecomStore?.pkEcomStore.toString() ?? '');
        }
      }}
      placeholder={placeholder ?? 'Filter by store'}
      value={selectValue}
      {...rest}
    />
  );
};

StoreSelectInput.propTypes = {
  autoPopulate: PropTypes.bool,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default StoreSelectInput;
