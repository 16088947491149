import React, { useContext, useEffect, useRef, useState } from 'react';
import { ActionIcon, Image, Menu, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight, ExternalLink, X } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import { ECOM_WEBSTORE_PAGE_CONFIG_LIST } from './pages/wsPageConfig';
import AppText from '../../common/AppText';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppMenu from '../../common/AppMenu';

const WebstoreLinkInput = ({
  label,
  customUrl,
  fkEcomStorePageType,
  ecomResourceId,
  required,
  onChange,
  fontWeight
}) => {
  const menuInputRef = useRef(null);
  const { state } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    isOpen: false,
    urlInputValue: '',
    selectedPage: null,
    hasSearched: false,
    errors: {}
  });
  const filteredPageOptions = ECOM_WEBSTORE_PAGE_CONFIG_LIST.filter(
    (c) =>
      c.canAssignMenuItem &&
      (!formState.hasSearched ||
        c.title.toLowerCase().includes(formState.urlInputValue.toLowerCase()))
  );

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    onReset();
  }, [customUrl, fkEcomStorePageType, ecomResourceId]);

  useEffect(() => {
    if (
      !formState.isOpen &&
      !formState.selectedPage &&
      !formState.fkEcomStorePageType &&
      formState.urlInputValue &&
      formState.urlInputValue !== customUrl
    ) {
      // eslint-disable-next-line no-use-before-define
      if (isValidUrl(formState.urlInputValue)) {
        // eslint-disable-next-line no-use-before-define
        onResourceChange(null, null, formState.urlInputValue);
      }
      else {
        // eslint-disable-next-line no-use-before-define
        onResourceChange(null, null, null, {customUrl: 'Link must be a URL address'});
      }
    }
    else {
      // eslint-disable-next-line no-use-before-define
      onReset();
    }
  }, [formState.isOpen]);

  const onReset = () => {
    const selectedMenuPage = filteredPageOptions.find(
      (p) => p.type.toString() === fkEcomStorePageType?.toString()
    );
    const selectedResource =
      ecomResourceId && selectedMenuPage.getResourceOptions
        ? selectedMenuPage
            .getResourceOptions(state.ecomStoreResourceOptions.value)
            .find((v) => v.value.toString() === ecomResourceId?.toString())
        : null;

    setFormState((c) => ({
      ...c,
      urlInputValue: selectedResource
        ? selectedResource.label
        : selectedMenuPage
        ? selectedMenuPage?.getResourceOptions
          ? `All ${selectedMenuPage.title.toLowerCase()}`
          : selectedMenuPage.title
        : customUrl ?? '',
      selectedPage: selectedMenuPage,
      hasSearched: false
    }));
  };

  const isValidUrl = (str) => {
    const fullUrlPattern = /^(https?:\/\/)?([\da-z.-]+\.[a-z]{2,6})([/\w .-]*)*\/?$/i;
    const urlHandlePattern = /^\/[a-zA-Z0-9\-/]*$/;
    return fullUrlPattern.test(str) || urlHandlePattern.test(str);
  };

  const onResourceChange = (
    pageType = null,
    resourceId = null,
    url = null,
    errors = {}
  ) => {
    if (
      pageType?.toString() !== fkEcomStorePageType?.toString() ||
      resourceId?.toString() !== ecomResourceId?.toString() ||
      url !== customUrl
    ) {
      onChange({
        fkEcomStorePageType: pageType,
        ecomResourceId: resourceId,
        customUrl: url
      });
    }
    setFormState({
      ...formState,
      fkEcomStorePageType: pageType,
      isOpen: false,
      errors
    });
  };

  return (
    <AppStack style={{ gap: 5 }}>
      {label && (
        <AppText style={{ fontSize: 14, fontWeight: fontWeight ?? 500 }}>
          {label}
        </AppText>
      )}
      <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
        <AppMenu
          closeOnItemClick={false}
          control={
            <TextInput
              ref={menuInputRef}
              leftSection={
                formState.selectedPage ? (
                  <formState.selectedPage.icon size={18} />
                ) : (
                  customUrl && <ExternalLink size={18} />
                )
              }
              onChange={(e) =>
                setFormState({
                  ...formState,
                  hasSearched: true,
                  fkEcomStorePageType: null,
                  urlInputValue: e.currentTarget.value,
                  selectedPage: null
                })
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFormState({
                  ...formState,
                  isOpen: true
                });
              }}
              placeholder="Search or paste a link"
              required={required}
              style={{ flex: 1 }}
              value={formState.urlInputValue ?? ''}
            />
          }
          keepMounted
          onClose={() => {
            if (menuInputRef.current !== document.activeElement) {
              setFormState({
                ...formState,
                isOpen: false
              });
            }
          }}
          opened={formState.isOpen}
          position="bottom-start"
          radius={10}
          width="target"
        >
          <AppStack style={{ flex: 1, gap: 0 }}>
            {formState.selectedPage &&
            formState.selectedPage.getResourceOptions ? (
              <>
                <Menu.Item
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setFormState({
                      ...formState,
                      urlInputValue: '',
                      selectedPage: null
                    });
                  }}
                >
                  <AppFlexbox
                    style={{
                      gap: 10,
                      alignItems: 'center'
                    }}
                  >
                    <ChevronLeft color="#666" size={18} />
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {formState.selectedPage.title}
                    </AppText>
                  </AppFlexbox>
                </Menu.Item>
                <Menu.Item
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onResourceChange(formState.selectedPage.type, null);
                  }}
                >
                  All {formState.selectedPage.title.toLowerCase()}
                </Menu.Item>

                {formState.selectedPage.getResourceOptions &&
                  formState.selectedPage
                    .getResourceOptions(state.ecomStoreResourceOptions.value)
                    .map((r) => (
                      <Menu.Item
                        key={r.value}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onResourceChange(
                            formState.selectedPage.type,
                            r.value
                          );
                        }}
                      >
                        <AppFlexbox style={{ gap: 10 }}>
                          {r.showImage && (
                            <AppStack style={{ width: 25, height: 25 }}>
                              <Image
                                alt={r.label}
                                fit="contain"
                                h="100%"
                                src={r.image}
                                w="100%"
                              />
                            </AppStack>
                          )}

                          {r.label}
                        </AppFlexbox>
                      </Menu.Item>
                    ))}
              </>
            ) : (
              <>
                {isValidUrl(formState.urlInputValue) ? (
                  <Menu.Item
                    leftSection={<ExternalLink size={18} />}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFormState({
                        ...formState,
                        isOpen: false
                      });
                    }}
                  >
                    {formState.urlInputValue}
                  </Menu.Item>
                ) : (
                  filteredPageOptions.length === 0 && (
                    <Menu.Item disabled>No results</Menu.Item>
                  )
                )}
                {filteredPageOptions.map((c) => (
                  <Menu.Item
                    key={c.type}
                    leftSection={c.icon && <c.icon size={18} />}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (c.canCreateTemplate || c.canAssignResource) {
                        setFormState({
                          ...formState,
                          urlInputValue: '',
                          selectedPage: c
                        });
                      }
                      else {
                        onResourceChange(c.type, null, null);
                      }
                    }}
                    rightSection={
                      c.canCreateTemplate ? (
                        <ChevronRight color="#666" size={18} />
                      ) : null
                    }
                  >
                    {c.title}
                  </Menu.Item>
                ))}
              </>
            )}
          </AppStack>
        </AppMenu>
        <ActionIcon
          color="dark"
          disabled={!formState.selectedPage && !customUrl}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onResourceChange(null, null, null);
          }}
          variant="outline"
        >
          <X size={18} />
        </ActionIcon>
      </AppFlexbox>
      {formState.errors.customUrl && (
        <AppText style={{ color: 'red', fontSize: 14 }}>
          {formState.errors.customUrl}
        </AppText>
      )}
    </AppStack>
  );
};

WebstoreLinkInput.propTypes = {
  customUrl: PropTypes.string,
  ecomResourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fkEcomStorePageType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

export default WebstoreLinkInput;
