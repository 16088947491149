import { Hash } from 'tabler-icons-react';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import WsQuantitySelectorBlock from './WsQuantitySelectorBlock';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  title: 'Quantity selector',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.QUANTITY_SELECTOR,
  icon: Hash,
  Component: WsQuantitySelectorBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
