import React from 'react';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';
import AppFlexbox from './AppFlexbox';
import SortableMedia from '../content/media/SortableMedia';

const SortableGrid = ({
  items,
  onSetItems,
  onSelectItem,
  selectedItemKeys,
  children
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 5 } })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      onSetItems({ active, over });
    }
  };

  const sortableItems = [
    ...items.map((f) => ({
      ...f,
      id: Number(f.sort)
    }))
  ];

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={sortableItems} strategy={rectSortingStrategy}>
        <AppFlexbox
          style={{
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: 10,
            display: 'grid',
            width: '100%'
          }}
        >
          {sortableItems?.map((item, index) => (
            <SortableMedia
              key={item.id}
              isMain={index === 0}
              isSelected={!!selectedItemKeys?.find((k) => k === item.key)}
              onSelect={() => onSelectItem(item)}
              {...item}
            />
          ))}
          {children}
        </AppFlexbox>
      </SortableContext>
    </DndContext>
  );
};

SortableGrid.propTypes = {
  children: PropTypes.any,
  items: PropTypes.array,
  onSelectItem: PropTypes.func,
  onSetItems: PropTypes.func,
  selectedItemKeys: PropTypes.array
};

export default SortableGrid;
