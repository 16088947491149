import { BuildingWarehouse } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsInventoryStatus from './WsInventoryStatus';

const TEXT_STYLE_ENUM = {
  NORMAL: 'normal',
  UPPERCASE: 'uppercase'
};

const configNameValues = {
  textStyle: 'textStyle',
  useInventoryThreshold: 'useInventoryThreshold',
  visibleWhenNotTracked: 'visibleWhenNotTracked',
  inventoryThreshold: 'inventoryThreshold',
  showInventoryCount: 'showInventoryCount'
};

const configDefaultValues = {
  textStyle: TEXT_STYLE_ENUM.UPPERCASE,
  useInventoryThreshold: 'true',
  visibleWhenNotTracked: 'true',
  inventoryThreshold: '25',
  showInventoryCount: 'true'
};

const configOptions = {
  [configNameValues.visibleWhenNotTracked]: {
    label: 'Keep the status visible when inventory tracking is disabled',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.textStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Text style',
    options: [
      {
        value: TEXT_STYLE_ENUM.NORMAL,
        label: 'Normal'
      },
      {
        value: TEXT_STYLE_ENUM.UPPERCASE,
        label: 'Uppercase'
      }
    ]
  },
  [configNameValues.showInventoryCount]: {
    label: 'Show inventory count',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.useInventoryThreshold]: {
    label: 'Use inventory threshold',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.inventoryThreshold]: {
    label: 'Low inventory threshold',
    description:
      'When inventory is below this threshold, the status will change',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 1,
    max: 100,
    isVisible: (config) => config.useInventoryThreshold === 'true'
  }
};

const config = {
  title: 'Inventory status',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.INVENTORY_STATUS,
  icon: BuildingWarehouse,
  Component: WsInventoryStatus,
  configOptions,
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  TEXT_STYLE_ENUM
};
