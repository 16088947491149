import React, { useContext, useEffect, useRef } from 'react';
import { Receipt } from 'tabler-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import TableView from '../../common/TableView';
import { usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';

const TABLE_COLUMNS = [
  {
    label: 'Order ID',
    value: 'order',
    sortable: true
  },
  {
    label: 'Date',
    value: 'date',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Customer',
    value: 'customer',
    sortable: true
  },
  {
    label: 'Merchant',
    value: 'store',
    sortable: true
  },
  {
    label: 'Products ordered',
    value: 'products',
    sortable: true
  },
  {
    label: 'Total',
    value: 'total',
    sortable: true
  }
];

const VendorOrderTable = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchPaginatedVendorOrders } = useContext(VendorContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: orderData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      sort: 'order_desc'
    },
    (newFilter) => {
      fetchPaginatedVendorOrders(
        authState.pkEcomVendor,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedEcomVendorOrders
  );
  const loading = !hasFetched.current || dataLoading;

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchPaginatedVendorOrders(authState.pkEcomVendor, {}, null, (error) => {
        triggerNotification(error);
      });
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Orders',
          to: '/vendor/orders'
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={!loading && totalNoFilterCount === 0}
        disabledContent={{
          title: 'No orders found.',
          description:
            'Orders will appear here once customers begin to place orders.',
          icon: <Receipt color="#000" size={125} />
        }}
        emptyMessage="No orders found."
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (search) =>
              onFilterChange({
                ...filter,
                search
              })
          },
          {
            key: 2,
            label: 'Status',
            type: 'select',
            value: filter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'unfulfilled', label: 'Unfulfilled' },
              { value: 'fulfilled', label: 'Fulfilled' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, status: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onAction={(action, item) => {
          if (action === 'view') {
            navigate(`/vendor/orders/${item.key}`);
          }
          else if (action === 'fulfill') {
            navigate(`/vendor/orders/${item.key}/fulfill`);
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={orderData.map((a) => {
          const orderTotal = a.ecomStoreCartProducts.reduce(
            (acc, item) =>
              acc + (item.productCost + item.addonCost) * item.count,
            0
          );

          const isFullyFulfilled = a.ecomStoreCartProducts.every(
            (p) =>
              p.shippingCount >= p.count &&
              p.trackingNotifiedCount >= p.trackingCount
          );

          return {
            key: a.pkEcomStoreCart,
            actions: [
              {
                label: 'View',
                value: 'view'
              },
              ...(!isFullyFulfilled
                ? [
                    {
                      label: 'Fulfill',
                      value: 'fulfill'
                    }
                  ]
                : [])
            ],
            columns: [
              {
                key: 1,
                label: `#${a.pkEcomStoreCart}`,
                weight: 500,
                component: Link,
                to: `/vendor/orders/${a.pkEcomStoreCart}`,
                onClick: () => navigate(`/vendor/orders/${a.pkEcomStoreCart}`)
              },
              {
                key: 2,
                label: `${dayjs(formatUtcDate(a.purchaseDate)).format(
                  'MMM D, YYYY'
                )} ${dayjs(formatUtcDate(a.purchaseDate)).format('h:mm A')}`
              },
              {
                key: 3,
                label: isFullyFulfilled ? 'Fulfilled' : 'Unfulfilled',
                color: isFullyFulfilled ? 'green' : 'orange'
              },
              {
                key: 4,
                label: a.customer.name,
                subLabel: a.customer.email,
                component: Link,
                to: `/vendor/customers/${a.customer.pkUser}`,
                onClick: () =>
                  navigate(`/vendor/customers/${a.customer.pkUser}`)
              },
              {
                key: 5,
                label: a.ecomStore.name,
                component: Link,
                to: `/vendor/merchants/${a.ecomStore.pkEcomStore}`,
                onClick: () =>
                  navigate(`/vendor/merchants/${a.ecomStore.pkEcomStore}`)
              },
              {
                key: 6,
                label: a.ecomStoreCartProducts.reduce((r, c) => r + c.count, 0)
              },
              {
                key: 7,
                label: currencyFormat(orderTotal / 100)
              }
            ]
          };
        })}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Orders"
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </HeaderView>
  );
};

export default VendorOrderTable;
