import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreUserContext } from '../../../providers/StoreUserContextProvider';
import LoadableView from '../../common/LoadableView';
import WsApp from './WsApp';
import { ECOM_WEBSTORE_PAGE_CONFIG_LIST } from './pages/wsPageConfig';
import { getWebstoreThemeConfig } from '../../../helpers/webstoreBuilderHelper';

const WsStoreView = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { urlHandle } = useParams();
  const hasFetched = useRef(false);
  const locationParams = new URLSearchParams(search);
  const pkEcomStoreTheme = locationParams.get('previewTheme');
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchEcomStoreTheme,
    fetchEcomStoreThemePreview,
    clearEcomStoreProductHistory
  } = useContext(StoreUserContext);
  const [webstoreThemeConfig, setWebstoreThemeConfig] = useState(null);
  const ecomStoreTheme =
    state.ecomStoreTheme.value?.ecomStore.urlHandle === urlHandle
      ? state.ecomStoreTheme.value
      : null;
  const thirdSlashIndex = pathname.indexOf('/', pathname.indexOf('/', 1) + 1);
  const storeUrl =
    thirdSlashIndex !== -1 ? pathname.substring(thirdSlashIndex) : '';
  const selectedPage = ECOM_WEBSTORE_PAGE_CONFIG_LIST.find((pageConfig) =>
    pageConfig.isSelected(storeUrl)
  );
  const isLoading =
    !hasFetched.current || state.ecomStoreTheme.loading || !ecomStoreTheme;

  const isAuthLoading = !authState.tokenAttempted || authState.loading;

  useEffect(() => {
    if (urlHandle && !hasFetched.current) {
      if (!pkEcomStoreTheme) {
        fetchEcomStoreTheme(urlHandle, null, (...args) => {
          if (args.includes(404)) {
            navigate('/store/404');
          }
        });
        clearEcomStoreProductHistory();
        hasFetched.current = true;
      }
      else if (!isAuthLoading) {
        fetchEcomStoreThemePreview(pkEcomStoreTheme, null, () =>
          navigate('/store/404')
        );
        hasFetched.current = true;
      }
    }
  }, [urlHandle, pkEcomStoreTheme, isAuthLoading]);

  useEffect(() => {
    if (ecomStoreTheme && !selectedPage) {
      navigate(`/store/404`);
    }
  }, [selectedPage, ecomStoreTheme]);

  useEffect(() => {
    if (ecomStoreTheme) {
      setWebstoreThemeConfig(getWebstoreThemeConfig(ecomStoreTheme));
    }
  }, [ecomStoreTheme]);

  return (
    <LoadableView isLoading={isLoading || (pkEcomStoreTheme && isAuthLoading)}>
      {urlHandle ? (
        <WsApp
          ecomStoreMenus={ecomStoreTheme?.ecomStore.ecomStoreMenus ?? []}
          ecomStoreTheme={ecomStoreTheme}
          ecomStoreThemeColorSchemes={
            webstoreThemeConfig?.ecomStoreThemeColorSchemes ?? []
          }
          ecomStoreThemeConfig={webstoreThemeConfig?.ecomStoreThemeConfig ?? {}}
          ecomStoreThemeSections={
            webstoreThemeConfig?.ecomStoreThemeSections ?? []
          }
          logoImageUrl={ecomStoreTheme?.logoImageUrl}
          selectedPageType={selectedPage?.type}
        />
      ) : (
        <Routes>
          <Route element={<Navigate replace to="404" />} path="*" />
        </Routes>
      )}
    </LoadableView>
  );
};

export default WsStoreView;
