import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import StoreCustomersTableView from './StoreCustomersTableView';
import CustomerDetailsView from '../customers/CustomerDetailsView';

const StoreCustomersView = () => (
  <Routes>
    <Route element={<StoreCustomersTableView />} path="/" />
    <Route element={<CustomerDetailsView />} path="/:pkUser" />
    <Route element={<Navigate replace to="/merchant/customers" />} path="*" />
  </Routes>
);

export default StoreCustomersView;
