/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Divider,
  Image,
  NavLink,
  ScrollArea,
  TextInput
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { Photo } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { ECOM_PRODUCT_STATUS_ENUM } from '../../../config/constants';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { sortVariantsByOptions } from '../../../helpers/webstoreHelper';

const ProductVariantsDisplayCard = ({
  ecomVendorProduct,
  ecomVendorProductVariants,
  ecomVendorProductVariant
}) => {
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const [formState, setFormState] = useState({ search: '' });

  const variantsSorted = sortVariantsByOptions(
    [...ecomVendorProductVariants].filter(
      (v) =>
        !!v.uuid &&
        (!formState.search ||
          v.ecomVendorProductVariantOptions
            .sort((a, b) => a.nameSort - b.nameSort)
            .map((s) => s.value)
            .join(' / ')
            .toLowerCase()
            .includes(formState.search.toLowerCase()))
    )
  );

  return (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: 0, maxHeight: 600 }}
      withBorder
    >
      <AppStack style={{ gap: 0, overflow: 'hidden' }}>
        <AppFlexbox style={{ flex: 1, padding: 16 }}>
          <AppStack
            style={{
              width: 80,
              height: 80,
              border: 'solid 1px lightgrey',
              borderRadius: 8,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {ecomVendorProduct.ecomVendorProductMedia[0]?.src ? (
              <Image
                fit="contain"
                h="100%"
                src={ecomVendorProduct.ecomVendorProductMedia[0]?.src ?? ''}
                w="100%"
              />
            ) : (
              <Photo color="gray" size={40} />
            )}
          </AppStack>
          <AppStack style={{ gap: 0 }}>
            <AppText style={{ fontSize: 16, fontWeight: 500 }}>
              {ecomVendorProduct.name}
            </AppText>
            <AppText style={{ fontSize: 14, color: '#666' }}>
              {ecomVendorProduct.ecomVendorProductVariants.length} Variants
            </AppText>
            {ecomVendorProduct && (
              <AppStack style={{ marginTop: 5 }}>
                {ecomVendorProduct.fkEcomProductStatus.toString() ===
                ECOM_PRODUCT_STATUS_ENUM.ACTIVE ? (
                  <Badge color="green" radius="sm">
                    Active
                  </Badge>
                ) : ecomVendorProduct.fkEcomProductStatus.toString() ===
                  ECOM_PRODUCT_STATUS_ENUM.ARCHIVED ? (
                  <Badge color="dark" radius="sm">
                    Archived
                  </Badge>
                ) : (
                  <Badge color="gray" radius="sm">
                    Draft
                  </Badge>
                )}
              </AppStack>
            )}
          </AppStack>
        </AppFlexbox>

        {ecomVendorProductVariants.length > 10 && (
          <>
            <Divider />
            <TextInput
              onChange={(e) =>
                setFormState({
                  ...formState,
                  search: e.currentTarget.value
                })
              }
              placeholder="Search variants..."
              radius={0}
              size="sm"
              styles={{ input: { border: 'none' } }}
              value={formState.search}
            />
          </>
        )}
        <Divider />
        <ScrollArea.Autosize style={{ maxHeight: '100%' }}>
          <AppStack style={{ gap: 0 }}>
            {variantsSorted.map((v) => {
              const selected = ecomVendorProductVariant?.key === v.key;
              return (
                <AppFlexbox key={v.pkEcomVendorProductVariant}>
                  <NavLink
                    active={selected}
                    color="dark"
                    component={Link}
                    label={v.ecomVendorProductVariantOptions
                      .sort((a, b) => a.nameSort - b.nameSort)
                      .map((s) => s.value)
                      .join(' / ')}
                    leftSection={
                      <AppStack
                        style={{
                          width: 20,
                          height: 20,
                          border: 'solid 1px lightgrey',
                          borderRadius: 4,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {v.src ? (
                          <Image fit="contain" h="100%" src={v.src} w="100%" />
                        ) : (
                          <Photo color="gray" size={12} />
                        )}
                      </AppStack>
                    }
                    style={{
                      fontWeight: selected ? 500 : 'normal',
                      fontSize: 14
                    }}
                    to={`/vendor/products/${ecomVendorProduct.uuid}/variants/${v.uuid}`}
                    variant="light"
                  />
                </AppFlexbox>
              );
            })}
          </AppStack>
        </ScrollArea.Autosize>
      </AppStack>
    </AppCard>
  );
};

ProductVariantsDisplayCard.propTypes = {
  ecomVendorProduct: PropTypes.object.isRequired,
  ecomVendorProductVariants: PropTypes.array.isRequired,
  ecomVendorProductVariant: PropTypes.object
};

export default ProductVariantsDisplayCard;
