import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Alert, Checkbox } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import { triggerNotification } from '../../../helpers/notification';

const StoreEscrowPaymentsClaimModal = ({ isOpen, onClose }) => {
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    claimStoreEscrowPayments,
    fetchStoreEscrowSummary
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({ loading: false });

  const storeEscrowSummary = state.ecomStoreEscrowSummary.value;
  const ecomStorePaymentProvider =
    storeEscrowSummary?.ecomStore.ecomStorePaymentProvider ?? null;

  const claimableBalance = storeEscrowSummary
    ? (storeEscrowSummary.amountPaid -
        storeEscrowSummary.amountRefunded -
        storeEscrowSummary.totalServiceFees -
        storeEscrowSummary.totalApplicationFees -
        storeEscrowSummary.totalAmountClaimed) /
      100
    : 0;

  const escrowClaimDisabled =
    !ecomStorePaymentProvider ||
    !ecomStorePaymentProvider.completed ||
    claimableBalance <= 0;

  useEffect(() => {
    if (isOpen) {
      setFormState({ loading: false });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: claimableBalance <= 0 ? 'Close' : 'Cancel',
        isSubmitHidden: escrowClaimDisabled,
        onCancel: onClose,
        isLoading: formState.loading,
        onSubmit: () => {
          setFormState({ ...formState, loading: true });
          claimStoreEscrowPayments(
            authState.pkEcomStore,
            () => {
              fetchStoreEscrowSummary(authState.pkEcomStore);
              triggerNotification(
                "You've successfully claimed your store income.",
                'success'
              );
              onClose();
            },
            () => {
              triggerNotification(
                'There was an error claiming your store income. Please try again later.',
                'error'
              );
              setFormState({ ...formState, loading: false });
            }
          );
        },
        submitTitle: `Claim ${currencyFormat(claimableBalance)}`
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Claim your store income"
    >
      {claimableBalance <= 0 ? (
        <AppStack style={{ gap: 16 }}>
          <Alert
            color="#067D62"
            icon={<AlertCircle size={22} />}
            title="You have no income to claim at this time."
            variant="outline"
          />
        </AppStack>
      ) : (
        <AppStack style={{ gap: 16 }}>
          <Alert
            color="blue"
            icon={<AlertCircle />}
            title="Attention"
            variant="outline"
          >
            <AppStack style={{ gap: 8 }}>
              <AppText style={{ fontSize: 14 }}>
                You are about to claim your store income of{' '}
                <b>{currencyFormat(claimableBalance)}</b>. This will transfer
                the funds to your Stripe account.
              </AppText>

              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Please note that this action is irreversible. You will not be
                able to claim this income again.
              </AppText>
            </AppStack>
          </Alert>

          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: '#666',
              textAlign: 'center'
            }}
          >
            Ensure the connect account is correct before proceeding.
          </AppText>

          {ecomStorePaymentProvider && (
            <AppCard radius={8} shadow="lg" style={{ padding: 8 }} withBorder>
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Stripe account
                  </AppText>
                </AppFlexbox>

                <AppText style={{ fontSize: 14 }}>
                  {ecomStorePaymentProvider.accountId}
                </AppText>
                <AppText style={{ fontSize: 14, color: '#999' }}>
                  Added{' '}
                  {dayjs(
                    formatUtcDate(ecomStorePaymentProvider.createdAt)
                  ).format('MMM D, YYYY')}
                </AppText>
              </AppStack>
            </AppCard>
          )}

          <Checkbox
            disabled={formState.loading}
            label="I confirm this is the correct Stripe connect account and wish to use it to claim my store income."
            required
            style={{ marginTop: 10 }}
            styles={{
              label: {
                fontWeight: 500,
                cursor: 'pointer'
              },
              input: { cursor: 'pointer' }
            }}
          />
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

StoreEscrowPaymentsClaimModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default StoreEscrowPaymentsClaimModal;
