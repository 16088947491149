import { Article } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsContentCardBlock from './WsContentCardBlock';
import {
  configOptions as wsHeadingConfigOptions,
  configDefaultValues as wsHeadingDefaultConfigValues
} from '../heading/wsHeadingConfig';
import {
  configOptions as wsTextConfigOptions,
  configDefaultValues as wsTextDefaultConfigValues
} from '../text/wsTextConfig';
import {
  configOptions as wsButtonGroupConfigOptions,
  configDefaultValues as wsHButtonGroupDefaultConfigValues
} from '../buttonGroup/wsButtonGroupConfig';

const CONTAINER_PADDING_ENUM = {
  NONE: 'none',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large'
};

const IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end'
};

const configNameValues = {
  containerPadding: 'containerPadding',
  containerMargin: 'containerMargin',
  showContainerOnDesktop: 'showContainerOnDesktop',
  showMobileContainer: 'showMobileContainer',
  colorScheme: 'colorScheme',
  desktopContentAlignment: 'desktopContentAlignment',
  mobileContentAlignment: 'mobileContentAlignment',
  desktopContentPosition: 'desktopContentPosition'
};

const addPrefixToKeys = (obj, prefix) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      `${prefix}${key[0].toUpperCase()}${key.slice(1, key.length)}`,
      value
    ])
  );

const configDefaultValues = {
  ...addPrefixToKeys(wsHeadingDefaultConfigValues, 'cardHeading'),
  ...addPrefixToKeys(wsTextDefaultConfigValues, 'cardText'),
  ...wsHButtonGroupDefaultConfigValues,
  [configNameValues.containerPadding]: CONTAINER_PADDING_ENUM.LARGE,
  [configNameValues.containerMargin]: CONTAINER_PADDING_ENUM.LARGE,
  [configNameValues.showContainerOnDesktop]: 'false',
  [configNameValues.showMobileContainer]: 'true',
  [configNameValues.colorScheme]: '3',
  [configNameValues.desktopContentAlignment]:
    IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
  [configNameValues.mobileContentAlignment]:
    IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER
};

const transformBlockConfig = (blockConfig, category, keyPrefix) =>
  Object.entries(
    Object.entries(blockConfig).map(([key, value]) => ({
      [`${keyPrefix}${key[0].toUpperCase()}${key.slice(1, key.length)}`]: {
        ...value,
        category: value.category || category
      }
    }))
  ).reduce((acc, [_, value]) => {
    const innerKey = Object.keys(value)[0];
    acc[innerKey] = value[innerKey];
    return acc;
  }, {});

const configOptions = {
  [configNameValues.containerPadding]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Container padding',
    category: 'Container content',
    options: [
      {
        value: CONTAINER_PADDING_ENUM.NONE,
        label: 'None'
      },
      {
        value: CONTAINER_PADDING_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: CONTAINER_PADDING_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: CONTAINER_PADDING_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: CONTAINER_PADDING_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      }
    ]
  },
  [configNameValues.containerMargin]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Container margin',
    category: 'Container content',
    options: [
      {
        value: CONTAINER_PADDING_ENUM.NONE,
        label: 'None'
      },
      {
        value: CONTAINER_PADDING_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: CONTAINER_PADDING_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: CONTAINER_PADDING_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: CONTAINER_PADDING_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      }
    ]
  },
  [configNameValues.desktopContentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop content alignment',
    category: 'Container content',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
        label: 'Center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  },
  [configNameValues.showContainerOnDesktop]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Show container on desktop',
    category: 'Container content'
  },
  [configNameValues.mobileContentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Mobile content alignment',
    category: 'Mobile layout',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
        label: 'Center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  },
  [configNameValues.showMobileContainer]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Show container on mobile',
    category: 'Mobile layout'
  },
  ...transformBlockConfig(wsHeadingConfigOptions, 'Heading', 'cardHeading'),
  ...transformBlockConfig(wsTextConfigOptions, 'Text', 'cardText'),
  ...wsButtonGroupConfigOptions
};

const config = {
  title: 'Content Card',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CONTENT_CARD,
  icon: Article,
  Component: WsContentCardBlock,
  configOptions,
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
