/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';

const WsDescriptionBlock = ({ entity, config }) => (
  <AppText style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>
    {entity.description}
  </AppText>
);

WsDescriptionBlock.propTypes = {
  config: PropTypes.object,
  entity: PropTypes.object
};

export default WsDescriptionBlock;
