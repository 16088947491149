import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Checkbox, Divider } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import AppText from '../../common/AppText';
import PaymentPriceItem from '../../common/PaymentPriceItem';
import CustomNumericFormat from '../../common/CustomNumericFormat';
import { currencyFormat } from '../../../helpers/format';

const OrderRefundModal = ({ pkEcomStoreCart, isOpen, onClose, onSuccess }) => {
  const hasFetched = useRef(false);
  const {
    state,
    fetchStoreCartTransactionDetails,
    refundStoreCartTransaction
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    refundAmount: 0,
    retainServiceFee: true,
    showConfirm: false,
    loading: false
  });

  const transactionDetails =
    state.ecomStoreCartTransactionDetails.value?.pkEcomStoreCart ===
    pkEcomStoreCart
      ? state.ecomStoreCartTransactionDetails.value
      : null;
  const currency =
    transactionDetails?.cartBalance.currency?.toUpperCase() ?? 'CAD';

  const maxRefundWithServiceFeeInCents = transactionDetails
    ? transactionDetails.paymentProviderTransaction.amountCapturedInCents -
      transactionDetails.paymentProviderTransaction.amountRefundedInCents
    : 0;
  const maxRefundAmount = transactionDetails
    ? (maxRefundWithServiceFeeInCents -
        (formState.retainServiceFee
          ? transactionDetails.paymentProviderTransaction.serviceFeeInCents
          : 0)) /
      100
    : 0;

  const loading = !transactionDetails;

  useEffect(() => {
    if (isOpen && pkEcomStoreCart) {
      if (
        !transactionDetails ||
        !transactionDetails.paymentProviderTransaction
      ) {
        fetchStoreCartTransactionDetails(pkEcomStoreCart, {}, null, (e) => {
          triggerNotification(e);
        });
        hasFetched.current = true;
      }
      setFormState({
        ...formState,
        showConfirm: false,
        retainServiceFee: true,
        loading: false
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (transactionDetails) {
      setFormState({
        ...formState,
        refundAmount: maxRefundAmount
      });
    }
  }, [transactionDetails, maxRefundAmount]);

  const onRefundOrder = () => {
    setFormState({
      ...formState,
      loading: true
    });

    refundStoreCartTransaction(
      pkEcomStoreCart,
      { refundAmount: Math.round(formState.refundAmount * 100) },
      () => {
        triggerNotification('Order refunded!', 'success');
        onSuccess();
        setFormState({
          ...formState,
          loading: false
        });
      },
      (error) => {
        triggerNotification(error);
        setFormState({
          ...formState,
          loading: false
        });
      }
    );
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: formState.showConfirm ? 'Back' : 'Close',
        onCancel: () => {
          if (formState.showConfirm) {
            setFormState({
              ...formState,
              showConfirm: false
            });
          }
          else {
            onClose();
          }
        },
        onSubmit: () => {
          if (
            formState.refundAmount <= 0 ||
            formState.refundAmount > maxRefundAmount
          ) {
            triggerNotification('Invalid refund amount');
          }
          else if (!formState.showConfirm) {
            setFormState({
              ...formState,
              showConfirm: true
            });
          }
          else {
            onRefundOrder();
          }
        },
        submitTitle: !loading
          ? formState.showConfirm
            ? `Yes, refund ${currencyFormat(
                formState.refundAmount
              )} ${currency}`
            : `Refund ${currencyFormat(formState.refundAmount)} ${currency}`
          : 'Refund',
        isLoading: formState.loading,
        isSubmitDisabled:
          formState.refundAmount <= 0 ||
          formState.refundAmount > maxRefundAmount,
        submitColor: 'red'
      }}
      isLoading={loading}
      isOpen={isOpen}
      onClose={onClose}
      size={450}
      title={
        formState.showConfirm
          ? `Refund ${currencyFormat(formState.refundAmount)} ${currency}?`
          : 'Refund order'
      }
    >
      {transactionDetails && formState.showConfirm ? (
        <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
          <Alert
            color="#C40000"
            icon={<AlertCircle />}
            title="Confirm refund amount"
            variant="outline"
          >
            <AppStack style={{ gap: 10 }}>
              <AppText style={{ fontSize: 14 }}>
                Are you sure you want to refund{' '}
                {currencyFormat(formState.refundAmount)} {currency}?
              </AppText>

              {formState.refundAmount >
                (maxRefundWithServiceFeeInCents -
                  transactionDetails.paymentProviderTransaction
                    .serviceFeeInCents) /
                  100 && (
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  You have chosen to refund the stripe service fee. This will
                  result in a loss of{' '}
                  {currencyFormat(
                    (transactionDetails.paymentProviderTransaction
                      .amountCapturedInCents -
                      transactionDetails.paymentProviderTransaction
                        .serviceFeeInCents -
                      transactionDetails.paymentProviderTransaction
                        .amountRefundedInCents) /
                      100 -
                      formState.refundAmount
                  )}{' '}
                  {currency} for this order.
                </AppText>
              )}

              <AppText style={{ fontSize: 14, fontWeight: 500, color: '#666' }}>
                This action cannot be undone.
              </AppText>
            </AppStack>
          </Alert>
        </AppStack>
      ) : (
        transactionDetails && (
          <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
            <AppText
              style={{ color: 'dodgerblue', fontSize: 18, fontWeight: 500 }}
            >
              Overview
            </AppText>
            <Divider size={2} />

            <PaymentPriceItem
              color="#37b24d"
              currency={currency}
              label="Total paid"
              value={
                transactionDetails.paymentProviderTransaction
                  .amountCapturedInCents / 100
              }
            />

            {formState.retainServiceFee && (
              <PaymentPriceItem
                color="#C40000"
                currency={currency}
                label="Service Fee"
                value={
                  (transactionDetails.paymentProviderTransaction
                    .serviceFeeInCents /
                    100) *
                  -1
                }
              />
            )}

            <PaymentPriceItem
              color={
                transactionDetails.paymentProviderTransaction
                  .amountRefundedInCents
                  ? '#C40000'
                  : null
              }
              currency={currency}
              label="Amount Refunded"
              value={
                transactionDetails.paymentProviderTransaction
                  .amountRefundedInCents / 100
              }
            />

            <PaymentPriceItem
              currency={currency}
              isDividerHidden
              label="Refundable Balance"
              value={maxRefundAmount}
              weight={700}
            />

            <AppStack style={{ marginTop: 20 }}>
              <Alert
                color="#C40000"
                icon={<AlertCircle />}
                title="Important"
                variant="outline"
              >
                <AppStack style={{ gap: 10 }}>
                  <AppText style={{ fontSize: 14 }}>
                    Refundable balance is the amount that can be refunded to the
                    customer. This amount is calculated by subtracting the total
                    amount refunded from the total amount paid.
                  </AppText>

                  <AppText style={{ fontSize: 14 }}>
                    Please note that the refund will be processed through the
                    payment provider and may take a few days to reflect on the
                    customer's account.
                  </AppText>

                  <AppText
                    style={{ fontSize: 14, fontWeight: 500, color: '#666' }}
                  >
                    Refunding an order will automatically cancel the order and
                    notify the customer.
                  </AppText>
                </AppStack>
              </Alert>

              <Checkbox
                checked={formState.retainServiceFee}
                description={`Choose to retain the Stripe service fee of ${currencyFormat(
                  transactionDetails.paymentProviderTransaction
                    .serviceFeeInCents / 100
                )} for this order. Otherwise you may refund this order at a loss.`}
                label="Retain payment provider service fees"
                onChange={() =>
                  setFormState({
                    ...formState,
                    retainServiceFee: !formState.retainServiceFee
                  })
                }
                style={{ marginTop: 10 }}
                styles={{
                  input: { cursor: 'pointer' },
                  label: { fontWeight: 500, cursor: 'pointer' }
                }}
              />

              <CustomNumericFormat
                allowNegative={false}
                error={
                  formState.refundAmount > maxRefundAmount
                    ? `Refund cannot be more than ${currencyFormat(
                        maxRefundAmount
                      )} CAD.`
                    : null
                }
                label="Refund Amount"
                onValueChange={(values) => {
                  setFormState({
                    ...formState,
                    refundAmount: values.floatValue
                  });
                }}
                required
                style={{ flex: 1 }}
                value={formState.refundAmount}
              />
            </AppStack>
          </AppStack>
        )
      )}
    </ResponsiveModal>
  );
};

OrderRefundModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  pkEcomStoreCart: PropTypes.number
};

export default OrderRefundModal;
