import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import AppCard from '../../common/AppCard';
import WsApp from './WsApp';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { VIEW_SCREEN_RATIO_ENUM } from './wsAppSettingConfig';

const WebstoreBuilderViewAppContainer = ({
  ecomStoreTheme,
  editState,
  viewState,
  controlState,
  onSelectSection,
  onUnselectSection,
  builderAuthState,
  onAuthenticateEditMode,
  onChangeSectionConfigValues
}) => {
  const containerRef = useRef(null);
  const {
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isLargeDesktopOrSmaller
  } = useMediaQueryIndex();

  return (
    <AppCard
      ref={containerRef}
      radius={isLaptopOrSmaller ? 0 : 10}
      shadow="xl"
      style={{
        padding: 0,
        flex: 1,
        width: '100%',
        maxHeight: isLaptopOrSmaller
          ? 'calc(100vh - 120px)'
          : 'calc(100vh - 80px)',
        maxWidth:
          !isTabletOrSmaller &&
          viewState.screenRatio === VIEW_SCREEN_RATIO_ENUM.mobile
            ? 375
            : 'unset',
        position: 'relative',
        overflow: 'auto'
      }}
    >
      <WsApp
        builderHasAuthenticated={builderAuthState.isAuthenticated}
        containerRef={containerRef}
        ecomStoreMenus={ecomStoreTheme.ecomStore.ecomStoreMenus}
        ecomStoreTheme={ecomStoreTheme}
        ecomStoreThemeColorSchemes={editState.ecomStoreThemeColorSchemes}
        ecomStoreThemeConfig={editState.ecomStoreThemeConfig}
        ecomStoreThemeSections={editState.ecomStoreThemeSections}
        editModeEnabled
        logoImageUrl={editState.logoImageUrl}
        onAuthenticateEditMode={onAuthenticateEditMode}
        onChangeSectionConfigValues={onChangeSectionConfigValues}
        onSelectSection={viewState.inspectEnabled ? onSelectSection : null}
        onUnselectSection={viewState.inspectEnabled ? onUnselectSection : null}
        selectedPageType={controlState.selectedPageType}
        selectedSection={
          viewState.inspectEnabled ? controlState.selectedSection : null
        }
        selectedSectionBlock={
          viewState.inspectEnabled ? controlState.selectedSectionBlock : null
        }
        viewPortAdjustment={
          isTabletOrSmaller
            ? null
            : viewState.screenRatio === VIEW_SCREEN_RATIO_ENUM.fullscreen
            ? '20px'
            : viewState.screenRatio === VIEW_SCREEN_RATIO_ENUM.mobile
            ? '(100vw - 375px)'
            : isLargeDesktopOrSmaller
            ? '370px'
            : '670px'
        }
      />
    </AppCard>
  );
};

WebstoreBuilderViewAppContainer.propTypes = {
  builderAuthState: PropTypes.object,
  controlState: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editState: PropTypes.object,
  onAuthenticateEditMode: PropTypes.func,
  onChangeSectionConfigValues: PropTypes.func,
  onSelectSection: PropTypes.func,
  onUnselectSection: PropTypes.func,
  viewState: PropTypes.object
};

export default WebstoreBuilderViewAppContainer;
