import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Checkbox,
  Image,
  Menu,
  ScrollArea,
  Select,
  TextInput,
  Tooltip
} from '@mantine/core';
import { Check, ChevronDown, Minus, Search, X } from 'tabler-icons-react';
import AppText from '../../../../common/AppText';
import AppStack from '../../../../common/AppStack';
import ResponsiveWebstoreDrawer from '../../ResponsiveWebstoreDrawer';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppMenu from '../../../../common/AppMenu';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';

const WsProductFilterDrawer = ({
  colorScheme,
  filter,
  filterState,
  setFilterState,
  onFilterChange,
  isOpen,
  onClose,
  mediaQueries,
  editModeEnabled,
  sortOptions,
  sortEnabled,
  filterEnabled
}) => {
  const { state } = useContext(StoreUserContext);
  const selectedVendorOptions = state.ecomStoreProductFilterOptions.value?.ecomVendors.find(
    (v) => v.pkEcomVendor.toString() === filter.vendor
  );

  return (
    <ResponsiveWebstoreDrawer
      closeIcon={
        <Tooltip label="Close" position="bottom" withArrow>
          <AppStack>
            <Minus color={colorScheme.textColor} size={18} />
          </AppStack>
        </Tooltip>
      }
      editModeEnabled={editModeEnabled}
      keepMounted
      mediaQueries={mediaQueries}
      onClose={onClose}
      opened={isOpen}
      size={325}
      styles={{
        content: {
          background: colorScheme.backgroundColor,
          color: colorScheme.textColor
        },
        header: {
          background: colorScheme.backgroundColor,
          color: colorScheme.textColor
        }
      }}
      title={
        <AppText style={{ fontSize: 16, fontWeight: 500 }}>
          Filter products
        </AppText>
      }
    >
      <AppStack style={{ gap: 16, padding: 8 }}>
        {filterEnabled && (
          <>
            <TextInput
              label="Search"
              onChange={(e) => {
                setFilterState({
                  ...filterState,
                  search: !e.currentTarget.value ? null : e.currentTarget.value
                });
              }}
              placeholder="Search by name..."
              radius={0}
              rightSection={
                filterState?.search || filter.search ? (
                  <X
                    onClick={() => {
                      setFilterState({
                        ...filterState,
                        search: ''
                      });
                    }}
                    size={16}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <Search size={16} />
                )
              }
              style={{ padding: '8px 0px', color: colorScheme.textColor }}
              styles={{ label: { fontWeight: 'normal' } }}
              value={filterState?.search ?? filter.search ?? ''}
            />
            <AppMenu
              control={
                <Select
                  label="Vendor"
                  placeholder="Filter by vendor"
                  rightSection={
                    selectedVendorOptions ? (
                      <X
                        onClick={() => {
                          onFilterChange({ vendor: null });
                        }}
                        size={16}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <ChevronDown size={16} />
                    )
                  }
                  rightSectionPointerEvents={
                    selectedVendorOptions ? 'all' : 'none'
                  }
                  searchValue={selectedVendorOptions?.name}
                  styles={{ label: { fontWeight: 'normal' } }}
                />
              }
              position="bottom-start"
              styles={{ dropdown: { padding: 0 } }}
              width={300}
            >
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox
                  style={{
                    gap: 8,
                    padding: '8px 12px',
                    alignItems: 'center'
                  }}
                >
                  <AppText style={{ fontSize: 14, color: '#999' }}>
                    Vendor
                  </AppText>
                </AppFlexbox>
                <Menu.Divider style={{ margin: 0 }} />
                <ScrollArea.Autosize mah={350} scrollbarSize={5}>
                  <AppStack style={{ gap: 3, padding: 3 }}>
                    {state.ecomStoreProductFilterOptions.value?.ecomVendors.map(
                      (v) => (
                        <Menu.Item
                          key={v.pkEcomVendor}
                          onClick={() => {
                            onFilterChange({ vendor: v.pkEcomVendor });
                          }}
                        >
                          <AppFlexbox
                            style={{
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <AppFlexbox style={{ alignItems: 'center' }}>
                              <AppStack style={{ width: 25, height: 25 }}>
                                <Image
                                  alt={v.name}
                                  h="100%"
                                  src={v.image}
                                  w="100%"
                                />
                              </AppStack>
                              <AppText style={{ fontSize: 14 }}>
                                {v.name}
                              </AppText>
                            </AppFlexbox>
                            {v.pkEcomVendor.toString() === filter.vendor && (
                              <Check color="#666" size={18} />
                            )}
                          </AppFlexbox>
                        </Menu.Item>
                      )
                    )}
                  </AppStack>
                </ScrollArea.Autosize>
              </AppStack>
            </AppMenu>
          </>
        )}

        {sortEnabled && (
          <AppStack style={{ gap: 5 }}>
            <AppText style={{ fontSize: 14, color: colorScheme.textColor }}>
              Sort by:
            </AppText>
            <AppMenu
              control={
                <Select
                  rightSection={<ChevronDown size={16} />}
                  searchValue={
                    sortOptions.find((o) => o.value === filter.sort)?.label ??
                    sortOptions[0].label
                  }
                  size="xs"
                  variant="filled"
                />
              }
              position="bottom-start"
              width="target"
            >
              {sortOptions.map((option) => (
                <Menu.Item
                  key={option.value}
                  onClick={() => onFilterChange({ sort: option.value })}
                  value={option.value}
                >
                  {option.label}
                </Menu.Item>
              ))}
            </AppMenu>
          </AppStack>
        )}

        {filterEnabled && (
          <Accordion
            defaultValue={['availability']}
            multiple
            styles={{
              item: { padding: 0 },
              control: { padding: 0 },
              content: { padding: 0 },
              chevron: { color: colorScheme.textColor }
            }}
          >
            <Accordion.Item value="availability">
              <Accordion.Control>
                <AppText style={{ fontSize: 14, color: colorScheme.textColor }}>
                  Availability
                </AppText>
              </Accordion.Control>
              <Accordion.Panel>
                <AppStack style={{ gap: 0, paddingBottom: 8 }}>
                  <AppFlexbox
                    onClick={() =>
                      onFilterChange({includeInventory: !filter.includeInventory})
                    }
                    style={{ padding: '12px 0px', cursor: 'pointer' }}
                  >
                    <Checkbox
                      checked={filter.includeInventory}
                      label="In stock"
                      onChange={() => {}}
                      radius={0}
                      style={{ pointerEvents: 'none' }}
                      styles={{
                        label: { cursor: 'pointer' },
                        input: { cursor: 'pointer' }
                      }}
                    />
                  </AppFlexbox>
                  <AppFlexbox
                    onClick={() =>
                      onFilterChange({
                        includeNoInventory: !filter.includeNoInventory
                      })
                    }
                    style={{ padding: '12px 0px', cursor: 'pointer' }}
                  >
                    <Checkbox
                      checked={filter.includeNoInventory}
                      label="Out of stock"
                      onChange={() => {}}
                      radius={0}
                      style={{ pointerEvents: 'none' }}
                      styles={{
                        label: { cursor: 'pointer' },
                        input: { cursor: 'pointer' }
                      }}
                    />
                  </AppFlexbox>
                </AppStack>
              </Accordion.Panel>
            </Accordion.Item>
            {/* <Accordion.Item value="price">
        <Accordion.Control>
          <AppText style={{ fontSize: 14, color: colorScheme.textColor }}>Pricing</AppText>
        </Accordion.Control>
        <Accordion.Panel>
          <AppFlexbox style={{ gap: 8, paddingBottom: 8 }}>
            <CustomNumericFormat
              label="Minimum"
              onValueChange={(values) => {
                setFilterState({
                  ...filterState,
                  minPrice: values.floatValue > 0 ? values.floatValue : null
                });
              }}
              placeholder="From"
              radius={0}
              style={{ flex: 1 }}
              value={filterState?.minPrice ?? ''}
            />
            <CustomNumericFormat
              label="Maximum"
              onValueChange={(values) => {
                setFilterState({
                  ...filterState,
                  maxPrice:
                    values.floatValue < 1000 ? values.floatValue : null
                });
              }}
              placeholder="To"
              radius={0}
              style={{ flex: 1 }}
              value={filterState?.maxPrice ?? ''}
            />
          </AppFlexbox>
        </Accordion.Panel>
      </Accordion.Item> */}
          </Accordion>
        )}
      </AppStack>
    </ResponsiveWebstoreDrawer>
  );
};

WsProductFilterDrawer.propTypes = {
  colorScheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  filter: PropTypes.object,
  filterEnabled: PropTypes.bool,
  filterState: PropTypes.object,
  isOpen: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onClose: PropTypes.func,
  onFilterChange: PropTypes.func,
  setFilterState: PropTypes.func,
  sortEnabled: PropTypes.bool,
  sortOptions: PropTypes.array
};

export default WsProductFilterDrawer;
