import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Menu } from '@mantine/core';
import { Calendar } from 'tabler-icons-react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AnalyticsDateSelectMenu from './AnalyticsDateSelectMenu';
import AppText from '../../common/AppText';
import {
  DEFAULT_COMPARE_DATE_OPTIONS,
  DEFAULT_DATE_OPTIONS
} from './analyticsConfig';
import AppStack from '../../common/AppStack';
import AppMenu from '../../common/AppMenu';

const REPORT_PERIOD_OPTIONS = [
  {
    value: 'hour',
    label: 'Hourly'
  },
  {
    value: 'day',
    label: 'Daily'
  },
  {
    value: 'week',
    label: 'Weekly',
    isVisible: (daysBetween) => daysBetween >= 6
  },
  {
    value: 'month',
    label: 'Monthly',
    isVisible: (daysBetween) => daysBetween >= 27
  },
  {
    value: 'quarter',
    label: 'Quarterly',
    isVisible: (daysBetween) => daysBetween >= 85
  },
  {
    value: 'year',
    label: 'Yearly',
    isVisible: (daysBetween) => daysBetween >= 364
  }
];

const getDatePeriodNumber = (str) => {
  const match = str.match(/^-?\d+/);
  return match ? Number(match[0]) : 0;
};

const periodHandlers = {
  d: (date, num) => date.setDate(date.getDate() + num),
  w: (date, num) => date.setDate(date.getDate() + num * 7),
  m: (date, num) => date.setMonth(date.getMonth() + num),
  y: (date, num) => date.setFullYear(date.getFullYear() + num),
  q: (date, num) => date.setMonth(date.getMonth() + num * 3)
};

const getDateParamValue = (param) => {
  const lowerParam = param.toLowerCase();
  const period = lowerParam.slice(-1); // Get the last character
  const num = getDatePeriodNumber(param);

  const baseDate = new Date();
  if (periodHandlers[period]) {
    periodHandlers[period](baseDate, num);
    return baseDate;
  }
  return dayjs(param);
};

const getDefaultPeriodInterval = (startDate, endDate) => {
  const daysBetween = dayjs(endDate).diff(dayjs(startDate), 'day');

  if (daysBetween <= 2) {
    return 'hour';
  }
  if (daysBetween <= 363) {
    return 'day';
  }
  return 'month';
};

const AnalyticsFilter = ({
  entityId,
  onFilterChange,
  updateParamsOnFilterChange,
  includeCompareDateRange,
  includePeriod
}) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const startParam = searchParams.get('start');
  const endParam = searchParams.get('end');
  const periodParam = searchParams.get('period');

  // Find initial default date period
  const defaultDatePeriodInitial =
    startParam && endParam
      ? DEFAULT_DATE_OPTIONS.find(
          (f) => f.start === startParam && f.end === endParam
        )
      : DEFAULT_DATE_OPTIONS.find((f) => f.isDefault);

  // Set start and end dates based on the initial default or parameters
  const {
    startDate: startDateInitial,
    endDate: endDateInitial
  } = defaultDatePeriodInitial
    ? defaultDatePeriodInitial.getDateRange()
    : {
        startDate: getDateParamValue(startParam),
        endDate: getDateParamValue(endParam)
      };

  // Find initial compare date period and set compare start and end dates
  const defaultCompareDatePeriodInitial = DEFAULT_COMPARE_DATE_OPTIONS.find(
    (f) => f.isDefault
  );
  const {
    startDate: compareStartDateInitial,
    endDate: compareEndDateInitial
  } = defaultDatePeriodInitial
    ? defaultDatePeriodInitial.getCompareDateRange()
    : defaultCompareDatePeriodInitial.getDateRange(
        defaultDatePeriodInitial,
        startDateInitial,
        endDateInitial
      );

  // Initialize filter state
  const [filterState, setFilterState] = useState({
    datePeriod: defaultDatePeriodInitial,
    compareDatePeriod: defaultCompareDatePeriodInitial,
    startDate: startDateInitial,
    endDate: endDateInitial,
    compareStartDate: compareStartDateInitial,
    compareEndDate: compareEndDateInitial,
    periodInterval:
      periodParam?.toLowerCase() ??
      getDefaultPeriodInterval(startDateInitial, endDateInitial)
  });
  const daysBetween = dayjs(filterState.endDate).diff(
    dayjs(filterState.startDate),
    'day'
  );
  const periodIntervalOptions = REPORT_PERIOD_OPTIONS.filter((op) =>
    op.isVisible ? op.isVisible(daysBetween) : true
  );

  const getDateOnly = (date) => {
    if (!date) {
      return null;
    }
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  };

  const areDatesEqual = (date1, date2) => {
    const normalizeDate = (date) =>
      new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
    return normalizeDate(new Date(date1)) === normalizeDate(new Date(date2));
  };

  useEffect(() => {
    if (entityId) {
      if (updateParamsOnFilterChange && hasFetched.current) {
        const newSearchParams = new URLSearchParams(search);
        newSearchParams.set(
          'start',
          filterState.datePeriod
            ? filterState.datePeriod.start
            : dayjs(filterState.startDate).format('YYYY-MM-DD')
        );
        newSearchParams.set(
          'end',
          filterState.datePeriod
            ? filterState.datePeriod.end
            : dayjs(filterState.endDate).format('YYYY-MM-DD')
        );
        newSearchParams.set('period', filterState.periodInterval);
        navigate(`${pathname}?${newSearchParams.toString()}`, {replace: true});
      }

      onFilterChange(filterState);
      hasFetched.current = true;
    }
  }, [
    entityId,
    filterState.startDate,
    filterState.endDate,
    filterState.compareStartDate,
    filterState.compareEndDate,
    filterState.periodInterval
  ]);

  return (
    <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
      <AnalyticsDateSelectMenu
        control={
          <Button
            color="dark"
            radius={8}
            size="compact-sm"
            style={{ border: 'solid 1px #B1B1B1' }}
            variant="white"
          >
            <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
              <Calendar size={18} />
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                {filterState.datePeriod?.label ??
                  (areDatesEqual(filterState.startDate, filterState.endDate)
                    ? dayjs(filterState.startDate).format('MMM D, YYYY')
                    : `${dayjs(filterState.startDate).format(
                        'MMM D, YYYY'
                      )} - ${dayjs(filterState.endDate).format(
                        'MMM D, YYYY'
                      )}`)}
              </AppText>
            </AppFlexbox>
          </Button>
        }
        datePeriod={filterState.datePeriod}
        endDate={filterState.endDate}
        onChange={({ datePeriod, startDate, endDate }) => {
          const {
            startDate: compareStartDate,
            endDate: compareEndDate
          } = filterState.compareDatePeriod?.getDateRange(
            datePeriod,
            startDate,
            endDate
          ) ?? {
            startDate: filterState.compareStartDate,
            endDate: filterState.compareEndDate
          };

          const newStart = getDateOnly(startDate);
          const newEnd = getDateOnly(endDate);
          setFilterState({
            ...filterState,
            datePeriod,
            startDate: newStart,
            endDate: newEnd,
            compareStartDate: getDateOnly(compareStartDate),
            compareEndDate: getDateOnly(compareEndDate),
            periodInterval: getDefaultPeriodInterval(newStart, newEnd)
          });
        }}
        startDate={filterState.startDate}
      />
      {includeCompareDateRange && (
        <AnalyticsDateSelectMenu
          compareDatePeriod={filterState.compareDatePeriod}
          compareEndDate={filterState.compareEndDate}
          compareStartDate={filterState.compareStartDate}
          control={
            <Button
              color="dark"
              radius={8}
              size="compact-sm"
              style={{ border: 'solid 1px #B1B1B1' }}
              variant="white"
            >
              <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Compared to:{' '}
                  {filterState.compareDatePeriod?.value === 'noComparison'
                    ? 'No comparison'
                    : areDatesEqual(
                        filterState.compareStartDate,
                        filterState.compareEndDate
                      )
                    ? dayjs(filterState.compareStartDate).format('MMM D, YYYY')
                    : `${dayjs(filterState.compareStartDate).format(
                        'MMM D, YYYY'
                      )} - ${dayjs(filterState.compareEndDate).format(
                        'MMM D, YYYY'
                      )}`}
                </AppText>
              </AppFlexbox>
            </Button>
          }
          datePeriod={filterState.datePeriod}
          endDate={filterState.endDate}
          isCompareDateRange
          onChange={({ datePeriod, startDate, endDate }) => {
            setFilterState({
              ...filterState,
              compareDatePeriod: datePeriod,
              compareStartDate: getDateOnly(startDate),
              compareEndDate: getDateOnly(endDate)
            });
          }}
          startDate={filterState.startDate}
        />
      )}

      {includePeriod && (
        <AppMenu
          closeOnClickOutside
          closeOnItemClick
          control={
            <Button
              color="dark"
              radius={8}
              size="compact-sm"
              style={{ border: 'solid 1px #B1B1B1' }}
              variant="white"
            >
              <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {REPORT_PERIOD_OPTIONS.find(
                    (op) => op.value === filterState.periodInterval
                  )?.label ?? 'Period'}{' '}
                </AppText>
              </AppFlexbox>
            </Button>
          }
          position="bottom-start"
          radius="md"
          shadow="xl"
          styles={{ dropdown: { padding: 0 } }}
          width="unset"
        >
          <AppStack style={{ gap: 0, flex: 1 }}>
            {periodIntervalOptions.map((op) => (
              <Menu.Item
                key={op.value}
                onClick={(e) => {
                  e.preventDefault();
                  setFilterState({
                    ...filterState,
                    periodInterval: op.value
                  });
                }}
              >
                <AppFlexbox style={{ alignItems: 'center', gap: 12 }}>
                  <Checkbox
                    checked={filterState.periodInterval === op.value}
                    onChange={() => {}}
                  />
                  <AppText style={{ fontSize: 14 }}>{op.label}</AppText>
                </AppFlexbox>
              </Menu.Item>
            ))}
          </AppStack>
        </AppMenu>
      )}
    </AppFlexbox>
  );
};

AnalyticsFilter.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  includeCompareDateRange: PropTypes.bool,
  includePeriod: PropTypes.bool,
  onFilterChange: PropTypes.func,
  updateParamsOnFilterChange: PropTypes.bool
};

export default AnalyticsFilter;
