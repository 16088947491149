import React from 'react';
import { Timeline } from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { formatUtcDate } from '../../helpers/format';
import AppStack from './AppStack';
import AppText from './AppText';

const HistoryTimeLine = ({ data }) => (
  <Timeline active={data.length} bulletSize={16} color="dark" lineWidth={2}>
    {data.map((item, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Timeline.Item key={i} title={item.title}>
        <AppStack style={{ gap: 8 }}>
          {item.message}
          <AppText style={{ fontSize: 13, color: '#666' }}>
            {dayjs(formatUtcDate(item.date)).format('MMMM D, YYYY')} at{' '}
            {dayjs(formatUtcDate(item.date)).format('h:mm A')}
          </AppText>
        </AppStack>
      </Timeline.Item>
    ))}
  </Timeline>
);

HistoryTimeLine.propTypes = { data: PropTypes.array };

export default HistoryTimeLine;
