/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsSectionContainer from '../WsSectionContainer';
import WsProductInformationDisplay from '../productInformation/WsProductInformationDisplay';

const WsFeaturedProduct = ({
  config,
  colorSchemes,
  themeConfig,
  baseStoreUrl,
  onNavigate,
  ecomStoreTheme,
  mediaQueries,
  pathname,
  blocks,
  editModeEnabled,
  ...rest
}) => {
  const { state } = useContext(StoreUserContext);
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const ecomStoreProduct = state.ecomStoreProductHistory.value.find(
    (c) => c.uuid === config.ecomStoreProduct
  );
  const productNotFound = state.ecomStoreProductHistory.uuidsNotFound.includes(
    config.ecomStoreProduct
  );

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      {config.ecomStoreProduct && (
        <WsProductInformationDisplay
          baseStoreUrl={baseStoreUrl}
          blocks={blocks}
          colorSchemes={colorSchemes}
          config={config}
          ecomStoreProduct={ecomStoreProduct}
          ecomStoreTheme={ecomStoreTheme}
          editModeEnabled={editModeEnabled}
          isPreview
          mediaQueries={mediaQueries}
          onNavigate={onNavigate}
          pathname={pathname}
          productNotFound={productNotFound}
          themeConfig={themeConfig}
          {...rest}
        />
      )}
    </WsSectionContainer>
  );
};

WsFeaturedProduct.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  themeConfig: PropTypes.object
};

export default WsFeaturedProduct;
