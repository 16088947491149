import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Dots } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Loader,
  Menu,
  NumberFormatter,
  Select,
  Skeleton,
  Textarea,
  TextInput
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AdminVendorInfoCard from './AdminVendorInfoCard';
import AdminVendorPaymentProviderInfoCard from './AdminVendorPaymentProviderInfoCard';
import ActionableIcon from '../../common/ActionableIcon';
import AppCard from '../../common/AppCard';
import AppMenu from '../../common/AppMenu';
import { triggerNotification } from '../../../helpers/notification';
import AdminVendorPayoutListItem from './AdminVendorPayoutListItem';
import { usePrompt } from '../../../helpers/prompt';
import ConfirmModal from '../../common/ConfirmModal';
import AdminVendorPayoutSummary from './AdminVendorPayoutSummary';
import { formatUtcDate } from '../../../helpers/format';

const PAYOUT_REASONS = ['Payment for goods', 'Other'];

const GROUP_BY_ENUM = {
  PRODUCT: 'product',
  NONE: 'none'
};

const AdminVendorPayoutView = ({ loading, isCreate }) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { pkEcomVendor, pkEcomVendorPayout } = useParams();
  const { isLargeMobileOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const {
    state,
    fetchEcomVendor,
    fetchEcomVendorPayout,
    createEcomVendorPayout
  } = useContext(AdminContext);
  const [filterState, setFilterState] = useState({
    search: '',
    startDate: null,
    endDate: null,
    groupProductsBy: GROUP_BY_ENUM.PRODUCT,
    changeRequest: null
  });
  const [formState, setFormState] = useState({
    chequeId: '',
    reason: PAYOUT_REASONS[0],
    description: '',
    pkEcomStoreCartProducts: [],
    loading: false,
    showConfirm: false,
    hasUnsavedChanges: false,
    hasProductChanges: false
  });
  const ecomVendorDetails = state.ecomVendor.value;
  const ecomVendorPayout = state.ecomVendorPayout.value;

  const viewLoading =
    !hasFetched.current ||
    loading ||
    state.ecomVendorPayout.loading ||
    !ecomVendorPayout ||
    !ecomVendorDetails;

  const selectedProducts = ecomVendorPayout
    ? ecomVendorPayout.ecomStoreCartProducts.filter((f) =>
        formState.pkEcomStoreCartProducts.includes(f.pkEcomStoreCartProduct)
      )
    : [];
  const payoutAmount =
    selectedProducts.reduce((r, c) => r + c.amountPaid * c.count, 0) / 100;
  const totalProductCount = selectedProducts.reduce((r, c) => r + c.count, 0);
  const controlsDisabled = loading || formState.loading;
  const { ecomVendorPayout: payoutDetails } = ecomVendorPayout ?? {};

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    formState.hasUnsavedChanges && !formState.loading
  );

  useEffect(() => {
    if (ecomVendorDetails) {
      fetchEcomVendorPayout(
        ecomVendorDetails.ecomVendor.pkEcomVendor,
        {
          pkEcomVendorPayout: isCreate ? null : pkEcomVendorPayout,
          startDate: filterState.startDate,
          endDate: filterState.endDate
        },
        null,
        (e) => {
          triggerNotification(e);
        }
      );
      hasFetched.current = true;
    }
  }, [ecomVendorDetails, filterState.startDate, filterState.endDate]);

  useEffect(() => {
    if (ecomVendorPayout) {
      setFormState({
        ...formState,
        chequeId: payoutDetails?.chequeId || '',
        reason: payoutDetails
          ? PAYOUT_REASONS.find((r) => r === payoutDetails?.description) ||
            'Other'
          : PAYOUT_REASONS[0],
        description: payoutDetails?.description || '',
        pkEcomStoreCartProducts: ecomVendorPayout.ecomStoreCartProducts.map(
          (p) => p.pkEcomStoreCartProduct
        ),
        loading: false,
        showConfirm: false,
        hasUnsavedChanges: false,
        hasProductChanges: false
      });
    }
  }, [ecomVendorPayout]);

  const createSubData = (p) => {
    const allProductsByVariant = ecomVendorPayout.ecomStoreCartProducts.filter(
      (f) => f.variantUuid === p.variantUuid
    );
    return {
      key: p.variantUuid,
      variantUuid: p.variantUuid,
      label: p.productName,
      subLabel: p.variantOptions
        .sort((a, b) => a.nameSort - b.nameSort)
        .map((v) => v.value)
        .join(' / '),
      amountPaid: p.amountPaid * p.count,
      count: p.count,
      selected: allProductsByVariant.every((v) =>
        formState.pkEcomStoreCartProducts.includes(v.pkEcomStoreCartProduct)
      )
    };
  };

  const addOrUpdateProductDisplayData = (acc, p) => {
    const existing = acc.find((a) => a.productUuid === p.productUuid);
    if (existing) {
      existing.count += p.count;

      const existingVariant = existing.subData.find(
        (v) => v.key === p.variantUuid
      );
      if (existingVariant) {
        existingVariant.count += p.count;
        existingVariant.amountPaid += p.amountPaid * p.count;
      }
      else {
        existing.subData.push(createSubData(p));
      }
    }
    else {
      acc.push({
        productUuid: p.productUuid,
        variantUuid: p.variantUuid,
        label: p.productName,
        subData: [createSubData(p)]
      });
    }
    return acc;
  };

  const onSubmitPayout = () => {
    if (isCreate) {
      setFormState({
        ...formState,
        loading: true
      });
      createEcomVendorPayout(
        ecomVendorDetails.ecomVendor.pkEcomVendor,
        {
          chequeId: formState.chequeId,
          description:
            formState.reason === 'Other'
              ? formState.description
              : formState.reason,
          pkEcomStoreCartProducts: formState.pkEcomStoreCartProducts
        },
        () => {
          triggerNotification('Payout submitted successfully.', 'success');
          fetchEcomVendor(pkEcomVendor);
          navigate(`/admin/vendors/${pkEcomVendor}`);
        },
        (e) => {
          triggerNotification(e);
          setFormState({
            ...formState,
            loading: false
          });
        }
      );
    }
  };

  return !loading && ecomVendorDetails ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/admin/vendors/${ecomVendorDetails.ecomVendor.pkEcomVendor}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          {viewLoading ? (
            <AppStack style={{ gap: 5 }}>
              <Skeleton height={35} width="20%" />
              <Skeleton height={14} width="40%" />
            </AppStack>
          ) : (
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppText
                  style={{
                    flex: 1,
                    fontSize: 24,
                    fontWeight: 700,
                    wordBreak: 'break-word'
                  }}
                >
                  {isCreate ? 'Create a new Payout' : 'Payout details'}
                </AppText>
              </AppFlexbox>

              <AppText
                style={{
                  fontSize: 12,
                  lineHeight: '16px',
                  color: '#666'
                }}
              >
                Created{' '}
                {dayjs(formatUtcDate(payoutDetails?.createdAt)).format(
                  'MMMM D, YYYY'
                )}{' '}
                at{' '}
                {dayjs(formatUtcDate(payoutDetails?.createdAt)).format(
                  'h:mm A'
                )}{' '}
                for {ecomVendorDetails.ecomVendor.name}
              </AppText>
            </AppStack>
          )}
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox
        component={isCreate ? 'form' : null}
        onSubmit={
          isCreate
            ? (e) => {
                e.preventDefault();
                setFormState({
                  ...formState,
                  showConfirm: true
                });
              }
            : null
        }
        style={{
          flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row',
          width: '100%'
        }}
      >
        <AppStack style={{ flex: 1, width: '100%' }}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Payout
                </AppText>

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>Products Selected</AppText>
                  {viewLoading ? (
                    <Skeleton height={14} width={50} />
                  ) : (
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {totalProductCount}
                    </AppText>
                  )}
                </AppFlexbox>

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>
                    {isCreate ? 'Total amount to be paid' : 'Total amount paid'}
                  </AppText>
                  {viewLoading ? (
                    <Skeleton height={14} width={50} />
                  ) : (
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontSize: 14, fontWeight: 500 }}
                      thousandSeparator
                      value={payoutAmount}
                    />
                  )}
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppFlexbox
                  style={{
                    gap: 8,
                    flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
                  }}
                >
                  <TextInput
                    disabled={controlsDisabled || !isCreate}
                    label="Cheque ID (optional)"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        chequeId: e.currentTarget.value,
                        hasUnsavedChanges: true
                      })
                    }
                    placeholder={isCreate ? 'Enter cheque ID' : 'N/A'}
                    style={{ flex: 1 }}
                    value={formState.chequeId}
                  />

                  <Select
                    clearable
                    data={PAYOUT_REASONS.map((p) => ({ value: p, label: p }))}
                    disabled={controlsDisabled || !isCreate}
                    label="Reason"
                    onChange={(v) =>
                      setFormState({
                        ...formState,
                        reason: v,
                        hasUnsavedChanges: true
                      })
                    }
                    required
                    searchable
                    style={{ flex: 1 }}
                    value={formState.reason}
                  />
                </AppFlexbox>

                {formState.reason === 'Other' && (
                  <Textarea
                    autosize
                    description="Enter custom reason or description for this payout"
                    disabled={controlsDisabled || !isCreate}
                    label="Custom reason"
                    min={3}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        description: e.currentTarget.value,
                        hasUnsavedChanges: true
                      })
                    }
                    required
                    value={formState.description}
                  />
                )}
              </AppStack>
              {isCreate && (
                <>
                  <Divider />
                  <AppStack style={{ padding: '8px 16px' }}>
                    <AppText style={{ fontSize: 14, color: '#999' }}>
                      You will receive a confirmation email as soon as your
                      payout has been successfully submitted.
                    </AppText>
                  </AppStack>
                </>
              )}
            </AppStack>
          </AppCard>

          <AppCard
            hiddenFrom="lg"
            radius="md"
            shadow="xs"
            style={{ padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppStack style={{ fontSize: 16, fontWeight: 500 }}>
                  Summary
                </AppStack>

                <AdminVendorPayoutSummary
                  chequeId={formState.chequeId}
                  fontSize={14}
                  loading={viewLoading}
                  payoutAmount={payoutAmount}
                  reason={formState.reason}
                  totalProductCount={totalProductCount}
                />
              </AppStack>
              {isCreate && (
                <>
                  <Divider />
                  <AppStack style={{ gap: 0, padding: 16 }}>
                    <Button
                      color="dark"
                      disabled={controlsDisabled || payoutAmount <= 0}
                      type="submit"
                      variant="filled"
                    >
                      Submit Payout
                    </Button>
                  </AppStack>
                </>
              )}
            </AppStack>
          </AppCard>

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 16, padding: 16 }}>
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      Products{' '}
                      {ecomVendorPayout &&
                        `(${ecomVendorPayout.ecomStoreCartProducts.reduce(
                          (r, c) => r + c.count,
                          0
                        )})`}
                    </AppText>

                    <AppMenu
                      control={
                        <Button color="dark" size="compact-sm" variant="subtle">
                          <Dots size={22} />
                        </Button>
                      }
                    >
                      <Menu.Item onClick={() => {}}>Download CSV</Menu.Item>
                    </AppMenu>
                  </AppFlexbox>
                </AppStack>

                {isCreate && (
                  <>
                    <AppFlexbox style={{ justifyContent: 'center' }}>
                      <AppText
                        style={{
                          fontSize: 14,
                          color: '#666',
                          textAlign: 'center',
                          fontStyle: 'italic'
                        }}
                      >
                        Please select the products you'd like to include in this
                        payout.
                      </AppText>
                    </AppFlexbox>
                    <AppFlexbox
                      style={{
                        flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
                      }}
                    >
                      <DateInput
                        clearable
                        disabled={controlsDisabled || !isCreate}
                        label="Start date"
                        maxDate={filterState.endDate || new Date()}
                        onChange={(v) => {
                          if (formState.hasProductChanges) {
                            setFilterState({
                              ...filterState,
                              changeRequest: { startDate: v }
                            });
                          }
                          else {
                            setFilterState({
                              ...filterState,
                              startDate: v
                            });
                          }
                        }}
                        placeholder="Select a start date"
                        style={{ flex: 1 }}
                        value={filterState.startDate ?? null}
                      />

                      <DateInput
                        clearable
                        disabled={controlsDisabled || !isCreate}
                        label="End date"
                        maxDate={new Date()}
                        minDate={filterState.startDate}
                        onChange={(v) => {
                          if (formState.hasProductChanges) {
                            setFilterState({
                              ...filterState,
                              changeRequest: { endDate: v }
                            });
                          }
                          else {
                            setFilterState({
                              ...filterState,
                              endDate: v
                            });
                          }
                        }}
                        placeholder="Select an end date"
                        style={{ flex: 1 }}
                        value={filterState.endDate ?? null}
                      />
                    </AppFlexbox>
                  </>
                )}

                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppFlexbox style={{ flex: 1, gap: 8, alignItems: 'center' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Group by
                    </AppText>
                    <Select
                      data={[
                        {
                          value: GROUP_BY_ENUM.PRODUCT,
                          label: 'Product'
                        },
                        {
                          value: GROUP_BY_ENUM.NONE,
                          label: 'None'
                        }
                      ]}
                      disabled={controlsDisabled}
                      onChange={(v) =>
                        setFilterState({
                          ...filterState,
                          groupProductsBy: v
                        })
                      }
                      style={{ flex: 1 }}
                      value={filterState.groupProductsBy}
                    />
                  </AppFlexbox>
                  <AppFlexbox style={{ flex: 1 }} visibleFrom="xsm" />
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 0, padding: 0 }}>
                {viewLoading ? (
                  <AppStack style={{ padding: 40, alignItems: 'center' }}>
                    <Loader color="dark" size={40} />
                  </AppStack>
                ) : ecomVendorPayout.ecomStoreCartProducts.length === 0 ? (
                  <AppFlexbox
                    style={{
                      padding: 20,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      No products found.
                    </AppText>
                  </AppFlexbox>
                ) : filterState.groupProductsBy === GROUP_BY_ENUM.PRODUCT ? (
                  ecomVendorPayout.ecomStoreCartProducts
                    .reduce(addOrUpdateProductDisplayData, [])
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((p, index) => (
                      <React.Fragment key={p.productUuid}>
                        {index > 0 && <Divider />}
                        <AdminVendorPayoutListItem
                          selected={p.subData.every((v) => v.selected)}
                          {...p}
                          disabled={controlsDisabled}
                          onSelect={
                            isCreate
                              ? () => {
                                  const allProductsByProduct = ecomVendorPayout.ecomStoreCartProducts.filter(
                                    (f) => f.productUuid === p.productUuid
                                  );
                                  setFormState({
                                    ...formState,
                                    pkEcomStoreCartProducts: [
                                      ...formState.pkEcomStoreCartProducts.filter(
                                        (pk) =>
                                          !allProductsByProduct.some(
                                            (v) =>
                                              v.pkEcomStoreCartProduct === pk
                                          )
                                      ),
                                      ...(p.subData.every((v) => v.selected)
                                        ? []
                                        : allProductsByProduct.map(
                                            (v) => v.pkEcomStoreCartProduct
                                          ))
                                    ],
                                    hasProductChanges: true
                                  });
                                }
                              : null
                          }
                          onSubSelect={
                            isCreate
                              ? (subData) => {
                                  const allProductsByVariant = ecomVendorPayout.ecomStoreCartProducts.filter(
                                    (f) => f.variantUuid === subData.variantUuid
                                  );
                                  setFormState({
                                    ...formState,
                                    pkEcomStoreCartProducts: [
                                      ...formState.pkEcomStoreCartProducts.filter(
                                        (pk) =>
                                          !allProductsByVariant.some(
                                            (v) =>
                                              v.pkEcomStoreCartProduct === pk
                                          )
                                      ),
                                      ...(subData.selected
                                        ? []
                                        : allProductsByVariant.map(
                                            (v) => v.pkEcomStoreCartProduct
                                          ))
                                    ],
                                    hasProductChanges: true
                                  });
                                }
                              : null
                          }
                        />
                      </React.Fragment>
                    ))
                ) : (
                  ecomVendorPayout.ecomStoreCartProducts
                    .sort((a, b) => a.productName.localeCompare(b.productName))
                    .map((p, index) => (
                      <React.Fragment key={p.pkEcomStoreCartProduct}>
                        {index > 0 && <Divider />}
                        <AdminVendorPayoutListItem
                          amountPaid={p.amountPaid * p.count}
                          count={p.count}
                          disabled={controlsDisabled}
                          label={p.productName}
                          onSelect={
                            isCreate
                              ? () => {
                                  setFormState({
                                    ...formState,
                                    pkEcomStoreCartProducts: formState.pkEcomStoreCartProducts.includes(
                                      p.pkEcomStoreCartProduct
                                    )
                                      ? formState.pkEcomStoreCartProducts.filter(
                                          (pk) =>
                                            pk !== p.pkEcomStoreCartProduct
                                        )
                                      : [
                                          ...formState.pkEcomStoreCartProducts,
                                          p.pkEcomStoreCartProduct
                                        ],
                                    hasProductChanges: true
                                  });
                                }
                              : null
                          }
                          selected={formState.pkEcomStoreCartProducts.includes(
                            p.pkEcomStoreCartProduct
                          )}
                          subLabel={p.variantOptions
                            .sort((a, b) => a.nameSort - b.nameSort)
                            .map((v) => v.value)
                            .join(' / ')}
                        />
                      </React.Fragment>
                    ))
                )}
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <AdminVendorInfoCard ecomVendor={ecomVendorDetails.ecomVendor} />
          <AdminVendorPaymentProviderInfoCard
            ecomVendor={ecomVendorDetails.ecomVendor}
          />

          <AppCard
            radius="md"
            shadow="xs"
            style={{ padding: 0 }}
            visibleFrom="lg"
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppStack style={{ fontSize: 16, fontWeight: 500 }}>
                  Summary
                </AppStack>

                <AdminVendorPayoutSummary
                  chequeId={formState.chequeId}
                  fontSize={14}
                  loading={viewLoading}
                  payoutAmount={payoutAmount}
                  reason={formState.reason}
                  totalProductCount={totalProductCount}
                />
              </AppStack>
              {isCreate && (
                <>
                  <Divider />
                  <AppStack style={{ gap: 0, padding: 16 }}>
                    <Button
                      color="dark"
                      disabled={controlsDisabled || payoutAmount <= 0}
                      type="submit"
                      variant="filled"
                    >
                      Submit Payout
                    </Button>
                  </AppStack>
                </>
              )}
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>

      <ConfirmModal
        confirmActionColor="blue"
        confirmActionText="Yes, change period"
        isOpen={!!filterState.changeRequest}
        message={
          <AppStack>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to change the Payout period? This will reset
              the selected products.
            </AppText>
          </AppStack>
        }
        onCancel={() =>
          setFilterState({
            ...filterState,
            changeRequest: null
          })
        }
        onConfirm={() => {
          setFilterState({
            ...filterState,
            ...filterState.changeRequest,
            changeRequest: null
          });
        }}
        title="Change Payout period?"
      />

      <ConfirmModal
        confirmActionColor="dark"
        confirmActionText="Yes, submit Payout"
        isLoading={formState.loading}
        isOpen={formState.showConfirm}
        message={
          <AppStack style={{ gap: 16 }}>
            <AppText
              style={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}
            >
              Are you sure you want to submit this payout?
            </AppText>
            <Divider />
            <AdminVendorPayoutSummary
              chequeId={formState.chequeId}
              payoutAmount={payoutAmount}
              reason={formState.reason}
              totalProductCount={totalProductCount}
            />
          </AppStack>
        }
        modalProps={{ size: 450 }}
        onCancel={() =>
          setFormState({
            ...formState,
            showConfirm: false
          })
        }
        onConfirm={onSubmitPayout}
        title="Submit Payout?"
      />
    </AppStack>
  ) : (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/admin/vendors/${pkEcomVendor}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={36} width={200} />
            <Skeleton height={14} width={150} />
          </AppStack>
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox
        style={{
          flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row',
          width: '100%'
        }}
      >
        <AppStack style={{ flex: 1, width: '100%' }}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <Skeleton height={22} width={75} />

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={18} width={100} />
                  <Skeleton height={18} width={50} />
                </AppFlexbox>

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={18} width={100} />
                  <Skeleton height={18} width={50} />
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppFlexbox
                  style={{
                    gap: 8,
                    flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
                  }}
                >
                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <Skeleton height={18} width="50%" />
                    <Skeleton height={35} width="100%" />
                  </AppStack>

                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <Skeleton height={18} width="50%" />
                    <Skeleton height={35} width="100%" />
                  </AppStack>
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ padding: '8px 16px' }}>
                <Skeleton height={18} width="80%" />
              </AppStack>
            </AppStack>
          </AppCard>

          <AppCard
            hiddenFrom="lg"
            radius="md"
            shadow="xs"
            style={{ padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <Skeleton height={16} width={100} />

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 0, padding: 16 }}>
                <Skeleton height={35} width="100%" />
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <AdminVendorInfoCard />
          <AdminVendorPaymentProviderInfoCard />

          <AppCard
            radius="md"
            shadow="xs"
            style={{ padding: 0 }}
            visibleFrom="lg"
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <Skeleton height={16} width={100} />

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 0, padding: 16 }}>
                <Skeleton height={35} width="100%" />
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

AdminVendorPayoutView.propTypes = {
  isCreate: PropTypes.bool,
  loading: PropTypes.bool
};

export default AdminVendorPayoutView;
