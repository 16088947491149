import React from 'react';
import { TextInput } from '@mantine/core';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const CustomNumericFormat = React.forwardRef(({ onFocus, ...props }, ref) => (
  <NumericFormat
    ref={ref}
    allowNegative={false}
    customInput={TextInput}
    decimalScale={2}
    fixedDecimalScale
    onFocus={(e) => {
      e.target.select();
      if (onFocus) {
        onFocus(e);
      }
    }}
    placeholder="$0.00"
    prefix="$"
    style={{ flex: 1 }}
    thousandSeparator
    {...props}
  />
));

CustomNumericFormat.propTypes = { onFocus: PropTypes.func };

export default CustomNumericFormat;
