/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Anchor, List, Loader } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import AppFlexbox from '../../../../common/AppFlexbox';
import { currencyFormat } from '../../../../../helpers/format';
import { isVariantUnavailable } from '../../../../../helpers/webstoreHelper';

const WsUnavailableProductsAlert = ({
  ecomStoreCartProducts,
  latestVersionEcomStoreCartProducts,
  mediaQueries,
  baseStoreUrl,
  onNavigate,
  submitting,
  onRemoveUnavailableProducts,
  isDrawerView
}) => {
  const { isTabletOrSmaller } = mediaQueries;

  useEffect(() => {}, []);

  return (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="lg"
      style={{ flex: 1, padding: 0, width: '100%' }}
      withBorder
    >
      <Alert
        color="#eca70a"
        style={{ width: '100%' }}
        styles={{ root: { padding: isDrawerView ? 8 : 16 } }}
        variant="light"
      >
        <AppFlexbox style={{ gap: isDrawerView ? 8 : 16, width: '100%' }}>
          <AppStack>
            <AlertCircle color="#eca70a" size={isDrawerView ? 24 : 32} />
          </AppStack>
          <AppStack style={{ gap: 5, width: '100%' }}>
            <AppText
              style={{ fontSize: isDrawerView ? 16 : 20, fontWeight: 700 }}
            >
              Important{' '}
              {latestVersionEcomStoreCartProducts.length === 1
                ? 'message'
                : 'messages'}
            </AppText>

            <AppStack
              style={{ gap: 5, flex: 1, width: '100%', paddingRight: 5 }}
            >
              <List style={{ width: '100%' }}>
                {latestVersionEcomStoreCartProducts.map((latestVariant) => {
                  const cartProduct = ecomStoreCartProducts.find(
                    (c) =>
                      c.ecomStoreProductVariant.ecomVendorProductVariant
                        .uuid === latestVariant.ecomVendorProductVariant.uuid
                  );

                  const isStillUnavailable = isVariantUnavailable(
                    latestVariant
                  );

                  const url = `${baseStoreUrl}/products/${latestVariant.ecomStoreProduct.urlHandle}`;

                  return (
                    <List.Item
                      key={latestVariant.ecomVendorProductVariant.uuid}
                    >
                      {isStillUnavailable ? (
                        <AppText
                          style={{
                            fontSize: 14,
                            width: '100%',
                            whiteSpace: 'break-spaces'
                          }}
                        >
                          Please note that{' '}
                          <Anchor
                            component={Link}
                            onClick={onNavigate}
                            style={{ fontSize: 14 }}
                            to={url}
                            underline="hover"
                          >
                            {
                              cartProduct.ecomStoreProductVariant
                                .ecomStoreProduct.ecomVendorProduct.name
                            }
                          </Anchor>{' '}
                          is <b>unavailable</b> for purchase. Update your cart
                          to remove this product.
                        </AppText>
                      ) : latestVariant.price !==
                        cartProduct.ecomStoreProductVariant.price ? (
                        <AppText style={{ fontSize: 14 }}>
                          The price of{' '}
                          <Anchor
                            component={Link}
                            onClick={onNavigate}
                            style={{ fontSize: 14 }}
                            to={url}
                            underline="hover"
                          >
                            {
                              cartProduct.ecomStoreProductVariant
                                .ecomStoreProduct.ecomVendorProduct.name
                            }
                          </Anchor>{' '}
                          has{' '}
                          <b>
                            {latestVariant.price >
                            cartProduct.ecomStoreProductVariant.price
                              ? 'increased'
                              : 'decreased'}
                          </b>{' '}
                          from{' '}
                          <b>
                            {currencyFormat(
                              cartProduct.ecomStoreProductVariant.price / 100
                            )}
                          </b>{' '}
                          to <b>{currencyFormat(latestVariant.price / 100)}</b>.
                          Update you cart to apply the new price.
                        </AppText>
                      ) : (
                        <AppText style={{ fontSize: 14 }}>
                          Please note that the details for{' '}
                          <Anchor
                            component={Link}
                            onClick={onNavigate}
                            style={{ fontSize: 14 }}
                            to={url}
                            underline="hover"
                          >
                            {
                              cartProduct.ecomStoreProductVariant
                                .ecomStoreProduct.ecomVendorProduct.name
                            }
                          </Anchor>{' '}
                          have changed. Update your cart to review the changes.
                        </AppText>
                      )}
                    </List.Item>
                  );
                })}
              </List>
            </AppStack>

            <AppFlexbox style={{ marginTop: 5, alignItems: 'center', gap: 8 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Please review the details before proceeding to{' '}
                <Anchor
                  onClick={() => {
                    if (!submitting) {
                      onRemoveUnavailableProducts();
                    }
                  }}
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: submitting ? '#999' : 'dodgerblue'
                  }}
                  underline="always"
                >
                  update your cart by clicking here.
                </Anchor>{' '}
              </AppText>
              {submitting && <Loader size={14} />}
            </AppFlexbox>
          </AppStack>
        </AppFlexbox>
      </Alert>
    </AppCard>
  );
};

WsUnavailableProductsAlert.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomStoreCartProducts: PropTypes.array,
  isDrawerView: PropTypes.bool,
  latestVersionEcomStoreCartProducts: PropTypes.array,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  onRemoveUnavailableProducts: PropTypes.func,
  submitting: PropTypes.bool
};

export default WsUnavailableProductsAlert;
