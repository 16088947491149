import dayjs from 'dayjs';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import {
  ECOM_REPORT_ENUM,
  REPORT_CATEGORY_ENUM,
  REPORT_HEADER_CUSTOMER_ENUM,
  REPORT_HEADER_FUNDRAISER_ENUM,
  REPORT_HEADER_ORDER_ENUM,
  REPORT_HEADER_PRODUCT_ENUM,
  REPORT_HEADER_SHIPPING_ENUM,
  REPORT_TYPE_ENUM
} from './reportsConfig';

const formatDate = (date, period) =>
  dayjs(formatUtcDate(date)).format(
    `MMM D, ${period === 'hourly' ? 'hh:mm A' : 'YYYY'}`
  );

const mapChartData = (
  analytics,
  dataLabel,
  compareDataLabel,
  dataKey,
  valueKey,
  divisor = 1
) => {
  const chartData = analytics.data.map((d) => ({
    [dataKey]: formatDate(d.date, analytics.period),
    [dataLabel]: d[valueKey] / divisor
  }));

  if (analytics.compareData) {
    analytics.compareData.forEach((d, i) => {
      const chartDataItem = chartData[i];
      if (chartDataItem) {
        chartDataItem[compareDataLabel] = d[valueKey] / divisor;
      }
      else {
        chartData.push({
          [dataKey]: formatDate(d.date, analytics.period),
          [compareDataLabel]: d[valueKey] / divisor
        });
      }
    });
  }

  return chartData;
};

const processProductCounts = (
  analytics,
  dataLabel,
  compareDataLabel,
  dataKey,
  nameKey
) => {
  let chartData = analytics.data
    .map((d) => d[dataKey])
    .flat()
    .reduce((r, c) => {
      const existingProduct = r.find((d) => d.vendorUuid === c.vendorUuid);
      if (existingProduct) {
        existingProduct[dataLabel] += c.count;
      }
      else {
        r.push({
          vendorUuid: c.vendorUuid,
          name: c[nameKey],
          [dataLabel]: c.count
        });
      }
      return r;
    }, []);

  if (analytics.compareData) {
    chartData = analytics.compareData
      .map((d) => d[dataKey])
      .flat()
      .reduce((r, c) => {
        const existingProduct = r.find((d) => d.vendorUuid === c.vendorUuid);
        if (existingProduct) {
          existingProduct[compareDataLabel] =
            (existingProduct[compareDataLabel] ?? 0) + c.count;
        }
        else {
          r.push({
            vendorUuid: c.vendorUuid,
            name: c[nameKey],
            [compareDataLabel]: c.count
          });
        }
        return r;
      }, chartData);
  }

  return chartData
    .sort(
      (a, b) =>
        b[dataLabel] - a[dataLabel] ||
        b[compareDataLabel] - a[compareDataLabel] ||
        a.name.localeCompare(b.name)
    )
    .slice(0, 5)
    .map((m) => ({
      ...m,
      [dataLabel]: m[dataLabel] || 0,
      [compareDataLabel]: m[compareDataLabel] || 0
    }));
};

const storeAnalytics = [
  {
    title: 'Total sales',
    reportUuid: ECOM_REPORT_ENUM.SALES_OVER_TIME,
    type: 'line',
    dataKey: 'date',
    valueFormatter: currencyFormat,
    getData: (analytics, dataLabel, compareDataLabel) =>
      mapChartData(
        analytics,
        dataLabel,
        compareDataLabel,
        'date',
        'amountPaid',
        100
      )
  },
  {
    title: 'Total orders',
    reportUuid: ECOM_REPORT_ENUM.ORDERS_OVER_TIME,
    type: 'line',
    dataKey: 'date',
    getData: (analytics, dataLabel, compareDataLabel) =>
      mapChartData(analytics, dataLabel, compareDataLabel, 'date', 'orderCount')
  },
  {
    title: 'Average order value',
    reportUuid: ECOM_REPORT_ENUM.AVERAGE_ORDER_VALUE,
    type: 'line',
    dataKey: 'date',
    valueFormatter: currencyFormat,
    getOverviewData: (chartData, dataLabel, compareDataLabel) => ({
      total: currencyFormat(
        chartData.reduce((r, c) => r + c[dataLabel], 0) /
          chartData.filter((c) => c[dataLabel] > 0).length
      ),
      compareTotal: currencyFormat(
        chartData.reduce((r, c) => r + c[compareDataLabel], 0) /
          chartData.filter((c) => c[compareDataLabel] > 0).length
      )
    }),
    getData: (analytics, dataLabel, compareDataLabel) =>
      mapChartData(
        analytics,
        dataLabel,
        compareDataLabel,
        'date',
        'averageOrderTotal',
        100
      )
  },
  {
    title: 'Amount refunded',
    reportUuid: ECOM_REPORT_ENUM.REFUNDS,
    type: 'line',
    dataKey: 'date',
    valueFormatter: currencyFormat,
    getData: (analytics, dataLabel, compareDataLabel) =>
      mapChartData(
        analytics,
        dataLabel,
        compareDataLabel,
        'date',
        'amountRefunded',
        100
      )
  },
  {
    title: 'Gross profit',
    reportUuid: ECOM_REPORT_ENUM.GROSS_SALES,
    type: 'line',
    dataKey: 'date',
    valueFormatter: currencyFormat,
    getData: (analytics, dataLabel, compareDataLabel) =>
      mapChartData(
        analytics,
        dataLabel,
        compareDataLabel,
        'date',
        'totalNetProfit',
        100
      )
  },
  {
    title: 'Top selling products',
    reportUuid: ECOM_REPORT_ENUM.SALES_BY_PRODUCT,
    type: 'bar',
    dataKey: 'name',
    getOverviewData: (chartData, dataLabel, compareDataLabel) => ({
      total: chartData.reduce((r, c) => r + c[dataLabel], 0),
      compareTotal: chartData.reduce((r, c) => r + c[compareDataLabel], 0)
    }),
    getData: (analytics, dataLabel, compareDataLabel) =>
      processProductCounts(
        analytics,
        dataLabel,
        compareDataLabel,
        'soldProducts',
        'productName'
      )
  }
];

const storeReports = [
  // SALES
  {
    title: 'Sales over time',
    uuid: ECOM_REPORT_ENUM.SALES_OVER_TIME,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.GROSS_PROFIT
    ],
    groupBy: 'period',
    compareEnabled: true,
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    }
  },
  {
    title: 'Average order value over time',
    uuid: ECOM_REPORT_ENUM.AVERAGE_ORDER_VALUE,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: 'period',
    compareEnabled: true,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE
    ],
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE
    }
  },
  {
    title: 'Sales by customer',
    uuid: ECOM_REPORT_ENUM.SALES_BY_CUSTOMER,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    ],
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    }
  },
  {
    title: 'Sales by location',
    uuid: ECOM_REPORT_ENUM.SALES_BY_LOCATION,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    ],
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    }
  },
  {
    title: 'Sales by fundraiser',
    uuid: ECOM_REPORT_ENUM.SALES_BY_FUNDRAISER,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
    compareEnabled: false,
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    }
  },
  {
    title: 'Sales by product',
    uuid: ECOM_REPORT_ENUM.SALES_BY_PRODUCT,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
      REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    ]
  },
  {
    title: 'Sales by variant',
    uuid: ECOM_REPORT_ENUM.SALES_BY_VARIANT,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_PRODUCT_ENUM.VARIANT_NAME,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
      REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    ]
  },
  {
    title: 'Sales by SKU',
    uuid: ECOM_REPORT_ENUM.SALES_BY_SKU,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_PRODUCT_ENUM.VARIANT_SKU,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
      REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    ]
  },

  // CUSTOMERS
  {
    title: 'Customers by spending',
    uuid: ECOM_REPORT_ENUM.TOP_CUSTOMERS,
    fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
    groupBy: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
      REPORT_HEADER_CUSTOMER_ENUM.IS_RETURNING,
      REPORT_HEADER_CUSTOMER_ENUM.FIRST_ORDER_DATE,
      REPORT_HEADER_CUSTOMER_ENUM.LAST_ORDER_DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED
    ]
  },
  // {
  //   title: 'First time customers',
  //   uuid: ECOM_REPORT_ENUM.FIRST_TIME_CUSTOMERS,
  //   fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
  //   groupBy: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
  //     REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED
  //   ]
  // },
  // {
  //   title: 'Returning customers',
  //   uuid: ECOM_REPORT_ENUM.RETURNING_CUSTOMERS,
  //   fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
  //   groupBy: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
  //     REPORT_HEADER_CUSTOMER_ENUM.FIRST_ORDER_DATE,
  //     REPORT_HEADER_CUSTOMER_ENUM.LAST_ORDER_DATE,
  //     REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED
  //   ]
  // },
  {
    title: 'Customers over time',
    uuid: ECOM_REPORT_ENUM.CUSTOMERS_OVER_TIME,
    fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
    groupBy: 'period',
    compareEnabled: false,
    defaultHeaders: [REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT]
  },
  {
    title: 'Customers by location',
    uuid: ECOM_REPORT_ENUM.CUSTOMERS_BY_LOCATION,
    fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
    groupBy: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
    compareEnabled: false,
    defaultHeaders: [REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT]
  },
  // {
  //   title: 'First vs returning customer sales',
  //   uuid: ECOM_REPORT_ENUM.FIRST_VS_RETURNING_CUSTOMERS,
  //   fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
  //   groupBy: REPORT_HEADER_SHIPPING_ENUM.IS_RETURNING,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT,
  //     REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
  //     REPORT_HEADER_ORDER_ENUM.GROSS_SALES
  //   ]
  // },

  // ORDERS
  {
    title: 'Orders by product',
    uuid: ECOM_REPORT_ENUM.PRODUCT_ORDERS,
    fkReportType: REPORT_TYPE_ENUM.ORDERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.ORDERS,
    groupBy: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE,
      REPORT_HEADER_ORDER_ENUM.UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.REFUNDED_UNITS,
      REPORT_HEADER_ORDER_ENUM.REFUND_RATE
    ]
  },
  {
    title: 'Orders by fundraiser',
    uuid: ECOM_REPORT_ENUM.FUNDRAISER_ORDERS,
    fkReportType: REPORT_TYPE_ENUM.ORDERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.ORDERS,
    groupBy: REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.REFUNDED_UNITS,
      REPORT_HEADER_ORDER_ENUM.REFUND_RATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_VALUE,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE
    ]
  },
  {
    title: 'Orders over time',
    uuid: ECOM_REPORT_ENUM.ORDERS_OVER_TIME,
    fkReportType: REPORT_TYPE_ENUM.ORDERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.ORDERS,
    groupBy: 'period',
    compareEnabled: true,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
      REPORT_HEADER_ORDER_ENUM.REFUNDED_UNITS
    ],
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.ORDER_COUNT
    }
  },
  {
    title: 'Order fulfillment over time',
    uuid: ECOM_REPORT_ENUM.FULFILMENT_STATUS,
    fkReportType: REPORT_TYPE_ENUM.ORDERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.ORDERS,
    groupBy: 'period',
    compareEnabled: true,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.FULFILLED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.SHIPPED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.DELIVERED_ORDERS
    ]
  },

  // FINANCES
  {
    title: 'Finance summary',
    uuid: ECOM_REPORT_ENUM.FINANCE_SUMMARY,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: 'period',
    compareEnabled: true,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.GROSS_PROFIT,
      REPORT_HEADER_ORDER_ENUM.COST
    ]
  },
  {
    title: 'Total sales',
    uuid: ECOM_REPORT_ENUM.TOTAL_SALES,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    ]
  },
  {
    title: 'Refunds',
    uuid: ECOM_REPORT_ENUM.REFUNDS,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.REFUNDED_UNITS
    ]
  },
  {
    title: 'Payments',
    uuid: ECOM_REPORT_ENUM.PAYMENTS,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.TRANSACTION_COUNT,
      REPORT_HEADER_ORDER_ENUM.PAYMENT_COUNT,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_COUNTRY,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY
    ]
  },
  {
    title: 'Net sales',
    uuid: ECOM_REPORT_ENUM.NET_SALES,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE
    ]
  },
  {
    title: 'Gross sales',
    uuid: ECOM_REPORT_ENUM.GROSS_SALES,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.COST,
      REPORT_HEADER_ORDER_ENUM.GROSS_PROFIT,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE
    ]
  },
  {
    title: 'Gross payment by month',
    uuid: ECOM_REPORT_ENUM.GROSS_PAYMENT_BY_MONTH,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: 'period',
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.TRANSACTION_COUNT,
      REPORT_HEADER_ORDER_ENUM.PAYMENT_COUNT,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES
    ]
  },
  {
    title: 'Cost of goods',
    uuid: ECOM_REPORT_ENUM.COST_OF_GOODS,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE,
      REPORT_HEADER_ORDER_ENUM.COST
    ]
  }
];

const storeAnalyticsAndReportsConfig = {
  analytics: storeAnalytics,
  reports: storeReports
};

export { storeAnalyticsAndReportsConfig };
