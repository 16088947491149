import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  Anchor,
  Button,
  Divider,
  Grid,
  Image,
  NumberFormatter,
  Pagination,
  Select,
  Skeleton,
  Table,
  Tabs
} from '@mantine/core';
import { ArrowLeft, Minus, Plus, Trash } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import ProductImageShowcase from '../products/ProductImageShowcase';
import ProductInputDisplay from '../products/ProductInputDisplay';
import {
  CANADA_PROVINCE_LIST_OPTIONS,
  LOCATION_LIST_OPTIONS
} from '../../../config/locationConstants';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import { INPUT_CONTROL_TYPE_ENUM } from '../../../config/constants';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const PRODUCT_TABS_ENUM = {
  DESCRIPTION: 'DESCRIPTION',
  DETAILS: 'DETAILS',
  VARIANTS: 'VARIANTS',
  SHIPPING: 'SHIPPING'
};

const PRODUCT_TABS_LIST = [
  { label: 'Product description', value: PRODUCT_TABS_ENUM.DESCRIPTION },
  { label: 'Product details', value: PRODUCT_TABS_ENUM.DETAILS },
  { label: 'Variants', value: PRODUCT_TABS_ENUM.VARIANTS },
  { label: 'Shipping Information', value: PRODUCT_TABS_ENUM.SHIPPING }
];

const MAX_PAGE_LENGTH = 12;

const VendorCatalogProductView = ({ vendorPreview, baseUrl }) => {
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const fromResource = new URLSearchParams(search).get('fromResource');
  const fromQuery = new URLSearchParams(search).get('query');
  const { ecomVendor, urlHandle } = useParams();
  const { setVendorCatalogRemoveStoreProductModal } = useContext(HelperContext);
  const { state, fetchStoreCatalogProduct } = useContext(StoreContext);
  const { state: authState } = useContext(AuthContext);
  const {
    cart: catalogCart,
    addProducts,
    removeProducts
  } = useVendorCatalogCheckout(authState.pkEcomStore);
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const [loading, setLoading] = useState(false);
  const [productFilterState, setProductFilterState] = useState({
    variantOptionsSelected: {},
    selectedImageIndex: 0,
    variantPageIndex: 1
  });
  const [activeTab, setActiveTab] = React.useState(
    PRODUCT_TABS_ENUM.DESCRIPTION
  );
  const vendor = state.catalogVendors.value.find(
    (v) => v.urlHandle === ecomVendor
  );
  const vendorProduct =
    state.catalogProduct.value?.urlHandle === urlHandle
      ? state.catalogProduct.value
      : null;
  const vendorProvinces = vendorProduct?.ecomVendor.ecomVendorProvinces
    .map((p) =>
      LOCATION_LIST_OPTIONS.find((f) => f.value.toString() === p.toString())
    )
    .filter((p) => p)
    .sort((a, b) => a.label.localeCompare(b.label));
  const variantOptions =
    vendorProduct?.ecomVendorProductVariants.reduce((r, c) => {
      c.ecomVendorProductVariantOptions.forEach((op) => {
        const existingOption = r.find((f) => f.name === op.name);
        if (existingOption) {
          if (!existingOption.values.includes(op.value)) {
            existingOption.values.push(op.value);
          }
        }
        else {
          r.push({ name: op.name, values: [op.value] });
        }
      });
      return r;
    }, []) ?? [];

  const selectedVariant = vendorProduct?.ecomVendorProductVariants.find((v) =>
    Object.entries(
      productFilterState.variantOptionsSelected
    ).every(([key, value]) =>
      v.ecomVendorProductVariantOptions.find(
        (f) => f.name === key && f.value === value
      )
    )
  );
  const variantPrice = selectedVariant?.price ?? 0;
  const markupPrice = variantPrice * 1.3;
  const isSelected =
    vendorProduct &&
    catalogCart.ecomVendorProducts.some((p) => p.uuid === vendorProduct.uuid);

  const productAlreadyInStore =
    vendorProduct &&
    state.ecomStoreProducts.value.some(
      (p) => p.ecomVendorProduct.uuid === vendorProduct.uuid
    );

  useEffect(() => {
    if (vendorProduct && !productFilterState.variantOption1) {
      setProductFilterState({
        ...productFilterState,
        variantPageIndex: 1,
        variantOptionsSelected: variantOptions.reduce(
          (r, c) => ({ ...r, [c.name]: c.values[0] }),
          {}
        )
      });
    }
  }, [vendorProduct]);

  useEffect(() => {
    if (vendor && urlHandle) {
      fetchStoreCatalogProduct(
        {
          pkEcomStore: vendorPreview ? null : authState.pkEcomStore,
          pkEcomVendor: vendorPreview ? authState.pkEcomVendor : null
        },
        {
          fkEcomVendor: vendor.pkEcomVendor,
          urlHandle
        }
      );
    }
  }, [vendor, urlHandle]);

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'vendor':
        return `${baseUrl}/vendors/${ecomVendor}${
          fromQuery ? `?query=${encodeURIComponent(fromQuery ?? '')}` : ''
        }`;
      case 'products':
        return `${baseUrl}/products/${fromResource}${
          fromQuery
            ? `?${new URLSearchParams(
                decodeURIComponent(fromQuery)
              ).toString()}`
            : ''
        }`;
      case 'categories':
        return `${baseUrl}/categories/${fromResource}${
          fromQuery
            ? `?${new URLSearchParams(
                decodeURIComponent(fromQuery)
              ).toString()}`
            : ''
        }`;
      default: {
        return `${baseUrl}${
          fromQuery
            ? `?${new URLSearchParams(
                decodeURIComponent(fromQuery)
              ).toString()}`
            : ''
        }`;
      }
    }
  };

  return vendorProduct ? (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        alignSelf: 'center',
        gap: 0
      }}
    >
      <AppFlexbox style={{ margin: 16 }}>
        <Button
          color="dark"
          component={Link}
          leftSection={<ArrowLeft size={18} />}
          to={getBackPath()}
        >
          Back to search
        </Button>
      </AppFlexbox>
      <AppCard radius="md" shadow="xl" style={{ minHeight: 600 }} withBorder>
        <AppFlexbox
          style={{
            flex: 1,
            flexDirection: isTabletOrSmaller ? 'column' : 'row'
          }}
        >
          <ProductImageShowcase
            images={vendorProduct.ecomVendorProductMedia}
            maxWidth={isTabletOrSmaller ? 'unset' : 600}
            productAlreadyInStore={productAlreadyInStore}
          />
          <AppStack style={{ flex: 1 }}>
            <AppStack style={{ gap: 0, alignItems: 'start' }}>
              <AppText style={{ fontSize: 28, fontWeight: 700 }}>
                {vendorProduct.name}
              </AppText>
              {vendorProduct.minimumQuantity && (
                <AppText style={{ fontSize: 14, color: '#eca70a' }}>
                  Minimum buy count of {vendorProduct.minimumQuantity}
                </AppText>
              )}

              <AppText style={{ fontSize: 16 }}>
                by{' '}
                <Anchor
                  c="dodgerblue"
                  component={Link}
                  style={{ fontSize: 16, fontWeight: 500 }}
                  to={`${baseUrl}/vendors/${
                    vendorProduct.ecomVendor.urlHandle
                  }?from=vendor-product&fromResource=${urlHandle}${
                    fromQuery ? `&query=${encodeURIComponent(fromQuery)}` : ''
                  }`}
                >
                  {vendorProduct.ecomVendor.name}
                </Anchor>
              </AppText>
              {productAlreadyInStore ? (
                <Button
                  color="red"
                  disabled={vendorPreview}
                  leftSection={<Trash size={18} />}
                  onClick={() => {
                    setVendorCatalogRemoveStoreProductModal({
                      isOpen: true,
                      ecomVendorProducts: [vendorProduct]
                    });
                  }}
                  style={{ marginTop: 10 }}
                  variant="filled"
                >
                  Remove from store
                </Button>
              ) : isSelected ? (
                <Button
                  color="red"
                  disabled={vendorPreview}
                  leftSection={<Minus size={18} />}
                  onClick={() => {
                    removeProducts([vendorProduct.uuid]);
                  }}
                  style={{ marginTop: 10 }}
                  variant="outline"
                >
                  Unselect product
                </Button>
              ) : (
                <Button
                  color="blue"
                  disabled={vendorPreview}
                  leftSection={<Plus size={18} />}
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    addProducts(
                      [vendorProduct.uuid],
                      () => setLoading(false),
                      () => setLoading(false)
                    );
                  }}
                  style={{ marginTop: 10 }}
                  variant="filled"
                >
                  Add product to your store
                </Button>
              )}
            </AppStack>
            <Divider />

            <AppFlexbox>
              <AppStack style={{ flex: 1, gap: 0 }}>
                <AppText style={{ fontSize: 16 }}>Price</AppText>
                <AppText style={{ fontSize: 24 }}>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontWeight: 700 }}
                    suffix=" CAD"
                    thousandSeparator
                    value={variantPrice / 100}
                  />
                </AppText>
              </AppStack>
              <AppStack style={{ flex: 1, gap: 0 }}>
                <AppText style={{ fontSize: 16 }}>Profit</AppText>
                <AppText style={{ fontSize: 24 }}>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontWeight: 700 }}
                    suffix=" CAD"
                    thousandSeparator
                    value={(markupPrice - variantPrice) / 100}
                  />
                </AppText>
              </AppStack>
              <AppStack style={{ flex: 1, gap: 0 }}>
                <AppText style={{ fontSize: 16 }}>Retail price</AppText>
                <AppText style={{ fontSize: 24 }}>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontWeight: 700 }}
                    suffix=" CAD"
                    thousandSeparator
                    value={markupPrice / 100}
                  />
                </AppText>
                <AppText style={{ fontSize: 14, color: '#999' }}>
                  at 30% markup
                </AppText>
              </AppStack>
            </AppFlexbox>
            <Divider />

            <AppStack style={{}}>
              {(vendorProduct.ecomVendorProductInputs.length > 0 ||
                variantOptions.length > 0) && (
                <>
                  <AppStack>
                    <Grid>
                      {variantOptions.map((op) => (
                        <Grid.Col
                          key={op.name}
                          span={{
                            base: 12,
                            xsm: 6
                          }}
                          style={{ alignSelf: 'end' }}
                        >
                          <Select
                            data={op.values.map((v) => ({
                              label: v,
                              value: v
                            }))}
                            label={op.name}
                            onChange={(value) => {
                              if (value) {
                                setProductFilterState({
                                  ...productFilterState,
                                  variantOptionsSelected: {
                                    ...productFilterState.variantOptionsSelected,
                                    [op.name]: value
                                  }
                                });
                              }
                            }}
                            value={
                              productFilterState.variantOptionsSelected[op.name]
                            }
                          />
                        </Grid.Col>
                      ))}

                      {vendorProduct.ecomVendorProductInputs
                        .sort((a, b) => a.sort - b.sort)
                        .map((p) => (
                          <Grid.Col
                            key={p.pkEcomVendorProductInput}
                            span={{
                              base: 12,
                              xsm:
                                p.fkRegFormControlType ===
                                  INPUT_CONTROL_TYPE_ENUM.CHECKBOX ||
                                p.fkRegFormControlType ===
                                  INPUT_CONTROL_TYPE_ENUM.FILE_UPLOAD
                                  ? 12
                                  : 6
                            }}
                            style={{
                              alignSelf: 'end',
                              marginTop:
                                p.fkRegFormControlType ===
                                INPUT_CONTROL_TYPE_ENUM.CHECKBOX
                                  ? 5
                                  : 0
                            }}
                          >
                            <ProductInputDisplay
                              key={p.pkEcomVendorProductInput}
                              ecomVendorProductInput={p}
                              onChange={() => {}}
                              value=""
                            />
                          </Grid.Col>
                        ))}
                    </Grid>
                  </AppStack>

                  <Divider />
                </>
              )}

              <AppFlexbox style={{ gap: 5, flex: 1 }}>
                <AppText style={{ flex: 1, maxWidth: 150, color: '#666' }}>
                  Variant barcode:
                </AppText>
                <AppText style={{ flex: 1, fontWeight: 500 }}>
                  {selectedVariant?.barcode ? selectedVariant.barcode : 'N/A'}
                </AppText>
              </AppFlexbox>

              <AppFlexbox style={{ gap: 5, flex: 1 }}>
                <AppText style={{ flex: 1, maxWidth: 150, color: '#666' }}>
                  Variant SKU:
                </AppText>
                <AppText style={{ flex: 1, fontWeight: 500 }}>
                  {selectedVariant?.sku ? selectedVariant.sku : 'N/A'}
                </AppText>
              </AppFlexbox>

              <AppFlexbox style={{ gap: 5, flex: 1 }}>
                <AppText style={{ flex: 1, maxWidth: 150, color: '#666' }}>
                  Variant weight:
                </AppText>
                <AppText style={{ flex: 1, fontWeight: 500 }}>
                  {selectedVariant?.weight ? selectedVariant.weight : 'N/A'}
                </AppText>
              </AppFlexbox>
            </AppStack>

            <Divider />

            <AppStack>
              {CANADA_PROVINCE_LIST_OPTIONS.every((p) =>
                vendorProvinces.some((vp) => vp.value === p.value)
              ) ? (
                <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                  Ships all across Canada
                </AppText>
              ) : (
                <Table
                  horizontalSpacing={16}
                  style={{ borderBottom: 'solid 1px  #dee2e6' }}
                  verticalSpacing={8}
                >
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th style={{ width: '100%', padding: '8px 0px' }}>
                        <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                          Ships to
                        </AppText>
                      </Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {vendorProvinces.map((p) => (
                      <Table.Tr key={p.value}>
                        <Table.Td style={{ padding: '8px 0px' }}>
                          <AppText style={{ fontSize: 16 }}>{p.label}</AppText>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              )}
            </AppStack>
          </AppStack>
        </AppFlexbox>
      </AppCard>

      <AppCard radius="md" shadow="xl" withBorder>
        <Tabs defaultValue="gallery" onChange={setActiveTab} value={activeTab}>
          <Tabs.List>
            {PRODUCT_TABS_LIST.map((tab) => (
              <Tabs.Tab key={tab.value} color="dark" value={tab.value}>
                <AppText
                  style={{
                    fontSize: 16,
                    color: activeTab === tab.value ? '#000' : '#666',
                    fontWeight: activeTab === tab.value ? '500' : 'normal'
                  }}
                >
                  {tab.label}
                </AppText>
              </Tabs.Tab>
            ))}
          </Tabs.List>
          <AppStack style={{ padding: 16, paddingBottom: 0 }}>
            <Tabs.Panel value={PRODUCT_TABS_ENUM.DESCRIPTION}>
              {vendorProduct.description ? (
                <AppText>{vendorProduct.description}</AppText>
              ) : (
                <AppText style={{ fontWeight: 500 }}>
                  No description available for this product.
                </AppText>
              )}
            </Tabs.Panel>

            <Tabs.Panel value={PRODUCT_TABS_ENUM.DETAILS}>
              <AppFlexbox style={{ gap: 0, flex: 1 }}>
                <AppText style={{ flex: 1, maxWidth: 150, color: '#666' }}>
                  Product category:
                </AppText>
                <AppText style={{ flex: 1, fontWeight: 500 }}>
                  {vendorProduct.category ?? 'N/A'}
                </AppText>
              </AppFlexbox>

              <AppFlexbox style={{ gap: 0, flex: 1 }}>
                <AppText style={{ flex: 1, maxWidth: 150, color: '#666' }}>
                  Sub category:
                </AppText>
                <AppText style={{ flex: 1, fontWeight: 500 }}>
                  {vendorProduct.type ?? 'N/A'}
                </AppText>
              </AppFlexbox>

              <AppFlexbox style={{ gap: 0, flex: 1 }}>
                <AppText style={{ flex: 1, maxWidth: 150, color: '#666' }}>
                  Vendor:
                </AppText>
                <AppText style={{ flex: 1, fontWeight: 500 }}>
                  {vendorProduct.ecomVendor.name}
                </AppText>
              </AppFlexbox>

              <AppFlexbox style={{ gap: 0, flex: 1 }}>
                <AppText style={{ flex: 1, maxWidth: 150, color: '#666' }}>
                  Tags:
                </AppText>
                <AppText style={{ flex: 1, fontWeight: 500 }}>N/A</AppText>
              </AppFlexbox>
            </Tabs.Panel>

            <Tabs.Panel
              style={{ overflow: 'hidden', maxWidth: '100%' }}
              value={PRODUCT_TABS_ENUM.VARIANTS}
            >
              <AppStack style={{ overflow: 'auto', maxWidth: '100%' }}>
                <Table
                  horizontalSpacing={16}
                  style={{ borderBottom: 'solid 1px  #dee2e6' }}
                  verticalSpacing={8}
                >
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th style={{ padding: '8px 0px', minWidth: 200 }} />
                      <Table.Th style={{ padding: '8px 0px', minWidth: 200 }}>
                        <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                          Barcode
                        </AppText>
                      </Table.Th>
                      <Table.Th style={{ padding: '8px 0px', minWidth: 200 }}>
                        <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                          SKU
                        </AppText>
                      </Table.Th>
                      <Table.Th style={{ padding: '8px 0px', minWidth: 200 }}>
                        <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                          Price
                        </AppText>
                      </Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {vendorProduct.ecomVendorProductVariants
                      .sort(
                        (a, b) =>
                          (a.ecomVendorProductVariantOptions.find(
                            (f) => f.name === variantOptions[0]?.name
                          )?.valueSort ?? 0) -
                          (b.ecomVendorProductVariantOptions.find(
                            (f) => f.name === variantOptions[0]?.name
                          )?.valueSort ?? 0)
                      )
                      .slice(
                        -MAX_PAGE_LENGTH +
                          productFilterState.variantPageIndex * MAX_PAGE_LENGTH,
                        productFilterState.variantPageIndex * MAX_PAGE_LENGTH
                      )
                      .map((v) => (
                        <Table.Tr key={v.pkEcomVendorProductVariant}>
                          <Table.Td
                            style={{ padding: '8px 0px', minWidth: 200 }}
                          >
                            <AppStack style={{ width: 50, height: 50 }}>
                              <Image
                                fit="contain"
                                h="100%"
                                src={
                                  vendorProduct.ecomVendorProductMedia.find(
                                    (m) =>
                                      m.pkEcomVendorProductMedia ===
                                      v.fkEcomVendorProductMedia
                                  )?.src
                                }
                                w="100%"
                              />
                              <AppText style={{ fontSize: 16 }}>
                                {variantOptions
                                  .map(
                                    (op) =>
                                      v.ecomVendorProductVariantOptions.find(
                                        (f) => f.name === op.name
                                      )?.value
                                  )
                                  .join(' ')}
                              </AppText>
                            </AppStack>
                          </Table.Td>
                          <Table.Td
                            style={{ padding: '8px 0px', minWidth: 200 }}
                          >
                            <AppText style={{ fontSize: 16 }}>
                              {v.barcode || 'N/A'}
                            </AppText>
                          </Table.Td>
                          <Table.Td
                            style={{ padding: '8px 0px', minWidth: 200 }}
                          >
                            <AppText style={{ fontSize: 16 }}>
                              {v.sku || 'N/A'}
                            </AppText>
                          </Table.Td>
                          <Table.Td
                            style={{ padding: '8px 0px', minWidth: 200 }}
                          >
                            <AppText style={{ fontSize: 16 }}>
                              <NumberFormatter
                                decimalScale={2}
                                fixedDecimalScale
                                prefix="$"
                                style={{ fontWeight: 700 }}
                                suffix=" CAD"
                                thousandSeparator
                                value={v.price / 100}
                              />
                            </AppText>
                          </Table.Td>
                        </Table.Tr>
                      ))}
                  </Table.Tbody>
                </Table>
              </AppStack>
              <AppFlexbox
                style={{ gap: 5, marginTop: 10, justifyContent: 'center' }}
              >
                <Pagination
                  color="dark"
                  onChange={(index) => {
                    setProductFilterState({
                      ...productFilterState,
                      variantPageIndex: index
                    });
                  }}
                  total={Math.ceil(
                    vendorProduct.ecomVendorProductVariants.length /
                      MAX_PAGE_LENGTH
                  )}
                  value={productFilterState.variantPageIndex}
                />
              </AppFlexbox>
            </Tabs.Panel>

            <Tabs.Panel value={PRODUCT_TABS_ENUM.SHIPPING}>
              <AppStack>
                <Table
                  horizontalSpacing={16}
                  style={{ borderBottom: 'solid 1px  #dee2e6' }}
                  verticalSpacing={8}
                >
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th style={{ width: '100%', padding: '8px 0px' }}>
                        <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                          Ships to
                        </AppText>
                      </Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {vendorProvinces.map((p) => (
                      <Table.Tr key={p.value}>
                        <Table.Td style={{ padding: '8px 0px' }}>
                          <AppText style={{ fontSize: 16 }}>{p.label}</AppText>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              </AppStack>
            </Tabs.Panel>
          </AppStack>
        </Tabs>
      </AppCard>
    </AppStack>
  ) : (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        alignSelf: 'center',
        gap: 0
      }}
    >
      <AppFlexbox style={{ margin: 16 }}>
        <Skeleton height={34} width={150} />
      </AppFlexbox>

      <AppCard radius="md" shadow="xl" style={{ minHeight: 600 }} withBorder>
        <AppFlexbox
          style={{
            flex: 1,
            flexDirection: isTabletOrSmaller ? 'column' : 'row'
          }}
        >
          <AppStack
            style={{
              flex: 1,
              width: '100%',
              height: '100%',
              minHeight: 350,
              maxHeight: isTabletOrSmaller ? 350 : 600,
              position: 'relative'
            }}
          >
            <Skeleton height="100%" width="100%" />
          </AppStack>

          <AppStack style={{ flex: 1 }}>
            <AppStack style={{ gap: 5, alignItems: 'start' }}>
              <Skeleton height={40} width="60%" />
              <Skeleton height={20} width={150} />
              <Skeleton height={34} width={180} />
            </AppStack>
            <Divider />

            <AppFlexbox>
              <AppStack style={{ flex: 1, gap: 5 }}>
                <Skeleton height={20} width="50%" />
                <Skeleton height={32} width="80%" />
              </AppStack>
              <AppStack style={{ flex: 1, gap: 5 }}>
                <Skeleton height={20} width="50%" />
                <Skeleton height={32} width="80%" />
              </AppStack>
              <AppStack style={{ flex: 1, gap: 5 }}>
                <Skeleton height={20} width="50%" />
                <Skeleton height={32} width="80%" />
                <Skeleton height={12} width="35%" />
              </AppStack>
            </AppFlexbox>
            <Divider />

            <AppStack style={{}}>
              {variantOptions.map((op) => (
                <Select
                  key={op.name}
                  data={op.values.map((v) => ({
                    label: v,
                    value: v
                  }))}
                  label={op.name}
                  onChange={(value) =>
                    setProductFilterState({
                      ...productFilterState,
                      variantOptionsSelected: {
                        ...productFilterState.variantOptionsSelected,
                        [op.name]: value
                      }
                    })
                  }
                  value={productFilterState.variantOptionsSelected[op.name]}
                />
              ))}

              <Skeleton height={20} width="25%" />

              <Skeleton height={20} width="25%" />

              <Skeleton height={20} width="25%" />
            </AppStack>

            <Divider />

            <AppStack>
              <Table
                horizontalSpacing={16}
                style={{ borderBottom: 'solid 1px  #dee2e6' }}
                verticalSpacing={8}
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th style={{ width: '100%', padding: '8px 0px' }}>
                      <Skeleton height={20} width="25%" />
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td style={{ padding: '8px 0px' }}>
                      <Skeleton height={20} width="25%" />
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td style={{ padding: '8px 0px' }}>
                      <Skeleton height={20} width="25%" />
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </AppStack>
          </AppStack>
        </AppFlexbox>
      </AppCard>

      <AppCard radius="md" shadow="xl" withBorder>
        <Tabs>
          <Tabs.List>
            <Tabs.Tab color="dark" value="1">
              <Skeleton height={20} width={140} />
            </Tabs.Tab>
            <Tabs.Tab color="dark" value="2">
              <Skeleton height={20} width={140} />
            </Tabs.Tab>
            <Tabs.Tab color="dark" value="3">
              <Skeleton height={20} width={140} />
            </Tabs.Tab>
            <Tabs.Tab color="dark" value="1">
              <Skeleton height={20} width={140} />
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
        <AppStack style={{ marginTop: 10, gap: 10 }}>
          <Skeleton height={10} width="100%" />
          <Skeleton height={10} width="100%" />
          <Skeleton height={10} width="50%" />
        </AppStack>
      </AppCard>
    </AppStack>
  );
};

VendorCatalogProductView.propTypes = {
  baseUrl: PropTypes.string,
  vendorPreview: PropTypes.bool
};

export default VendorCatalogProductView;
