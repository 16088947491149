import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, TextInput } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const WebstoreBuilderCreatePageTemplateModal = ({ isOpen, onClose }) => {
  const { state: authState } = useContext(AuthContext);
  const { createStoreProducts } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    name: '',
    fkEcomStorePageTemplate: '',
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        name: '',
        fkEcomStorePageTemplate: '',
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        onCancel: onClose,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          onClose();
        },
        isSubmitDisabled: !formState.name,
        submitTitle: 'Create template'
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={600}
      title="Create a page template"
      zIndex={1000}
    >
      <AppStack style={{ gap: 20 }}>
        <AppText style={{ fontSize: 18 }}>
          Create a page template to customize how your content looks accross all
          products assigned.
        </AppText>
        <TextInput
          disabled={formState.loading}
          label="Name"
          onChange={(e) =>
            setFormState({
              ...formState,
              name: e.currentTarget.value
            })
          }
          required
          value={formState.name}
        />
        <Select
          data={[
            {
              value: '',
              label: 'Default template'
            }
          ]}
          disabled={formState.loading}
          label="Based on"
          onChange={(value) =>
            setFormState({
              ...formState,
              fkEcomStorePageTemplate: value
            })
          }
          value={formState.fkEcomStorePageTemplate ?? ''}
        />
      </AppStack>
    </ResponsiveModal>
  );
};

WebstoreBuilderCreatePageTemplateModal.propTypes = {
  ecomVendorProduct: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default WebstoreBuilderCreatePageTemplateModal;
