/* eslint-disable import/no-cycle */
import { User } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM
} from '../../../../../config/constants';
import WsAccountContent from './WsAccountContent';

const configNameValues = { colorScheme: 'colorScheme' };

const configDefaultValues = { [configNameValues.colorScheme]: '1' };

const configOptions = {
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.ACCOUNT_CONTENT,
  title: 'Account content',
  icon: User,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsAccountContent,
  configOptions,
  isPermanent: true,
  cannotCreate: true,
  maxItemCount: null,
  blocks: [],
  defaultConfig: configDefaultValues,
  getTitle: (section, pathname) => {
    const titles = {
      '/account/login': 'Account login',
      '/account/register': 'Account signup',
      '/account/forgot-password': 'Forgot password',
      '/account/reset-password': 'Password reset',
      '/account/confirm': 'Account confirmation',
      '/account/details': 'Account profile',
      '/account/orders': 'Account orders',
      '/account/orders/': 'Order details'
    };

    for (const key in titles) {
      if (pathname.startsWith(key)) {
        return titles[key];
      }
    }

    return 'Account content';
  }
};

export { config, configOptions, configNameValues, configDefaultValues };
