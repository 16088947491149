/* eslint-disable import/no-cycle */
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import WsSectionContainer from '../WsSectionContainer';
import WsAccountLogin from './WsAccountLogin';
import WsAccountRegister from './WsAccountRegister';
import WsAccountForgotPassword from './WsAccountForgotPassword';
import WsAccountResetPassword from './WsAccountResetPassword';
import WsAccountVerifyEmail from './WsAccountVerifyEmail';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import WsAccountInfoView from './WsAccountInfoView';

const accountContent = [
  {
    path: '/account/login',
    Component: WsAccountLogin,
    isSignin: true
  },
  {
    path: '/account/register',
    Component: WsAccountRegister,
    isSignin: true
  },
  {
    path: '/account/forgot-password',
    Component: WsAccountForgotPassword,
    isSignin: true
  },
  {
    path: '/account/reset-password',
    Component: WsAccountResetPassword,
    isSignin: true
  },
  {
    path: '/account/confirm-account',
    Component: WsAccountVerifyEmail,
    isSignin: true
  },
  {
    path: '/account/orders/',
    Component: WsAccountInfoView
  },
  {
    path: '/account',
    Component: WsAccountInfoView,
    requiresAuth: true
  }
];

const WsAccountContent = ({
  baseStoreUrl,
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  sort,
  pathname,
  search,
  editModeEnabled,
  builderHasAuthenticated,
  onAuthenticateEditMode,
  ecomStoreTheme,
  ...rest
}) => {
  const { pathname: windowPathname } = useLocation();
  const onNavigateStore = useNavigate();
  const { state: authState, clearErrors } = useContext(AuthContext);
  const { isTabletOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const redirectUrl = new URLSearchParams(search).get('redirect');
  const currentContent = accountContent.find((content) =>
    pathname.startsWith(content.path)
  );
  const canViewPage =
    currentContent &&
    (!currentContent.requiresAuth ||
      (editModeEnabled ? builderHasAuthenticated : authState.isAuthenticated));

  useEffect(() => {
    if (authState.tokenAttempted && currentContent) {
      const isAuthenticated = editModeEnabled
        ? builderHasAuthenticated
        : authState.isAuthenticated;

      if (currentContent.requiresAuth && !isAuthenticated) {
        // eslint-disable-next-line no-use-before-define
        onAuthNavigation(`/account/login?redirect=${pathname}`);
      }
      else if (currentContent.isSignin && isAuthenticated) {
        // eslint-disable-next-line no-use-before-define
        onAuthNavigation(redirectUrl ?? '/account/details');
      }
    }
  }, [
    authState.tokenAttempted,
    authState.isAuthenticated,
    builderHasAuthenticated,
    currentContent
  ]);

  useEffect(() => {
    if (authState.isWaitingConfirmSignUp) {
      // eslint-disable-next-line no-use-before-define
      onAuthNavigation(
        redirectUrl
          ? `/account/confirm-account?redirect=${redirectUrl}`
          : '/account/confirm-account'
      );
    }
  }, [authState.isWaitingConfirmSignUp]);

  useEffect(() => {
    if (pathname.startsWith('/account') && authState.tokenAttempted) {
      if (currentContent) {
        if (
          currentContent.requiresAuth &&
          (editModeEnabled
            ? !builderHasAuthenticated
            : !authState.isAuthenticated)
        ) {
          // eslint-disable-next-line no-use-before-define
          onAuthNavigation(`/account/login?redirect=${pathname}`);
        }
      }
      else {
        // eslint-disable-next-line no-use-before-define
        onAuthNavigation(`/account/details`);
      }
    }
  }, [pathname]);

  const onAuthNavigation = (path) => {
    clearErrors();
    if (editModeEnabled) {
      const searchParams = new URLSearchParams();
      searchParams.set('previewPath', path);
      onNavigateStore(`${windowPathname}?${searchParams.toString()}`);
    }
    else {
      onNavigateStore(`${baseStoreUrl}${path}`);
    }
  };
  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      ecomStoreTheme={ecomStoreTheme}
      mediaQueries={mediaQueries}
      noPadding={isTabletOrSmaller}
      themeConfig={themeConfig}
      {...rest}
    >
      {canViewPage && (
        <currentContent.Component
          baseStoreUrl={baseStoreUrl}
          blocks={blocks}
          colorSchemes={colorSchemes}
          config={config}
          ecomStoreTheme={ecomStoreTheme}
          editModeEnabled={editModeEnabled}
          loading={!authState.tokenAttempted}
          mediaQueries={mediaQueries}
          onAuthenticateEditMode={onAuthenticateEditMode}
          pathname={pathname}
          search={search}
          sort={sort}
          themeConfig={themeConfig}
          {...rest}
          onAuthNavigation={onAuthNavigation}
        />
      )}
    </WsSectionContainer>
  );
};

WsAccountContent.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  builderHasAuthenticated: PropTypes.bool,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onAuthenticateEditMode: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsAccountContent;
