/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Select } from '@mantine/core';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppFlexbox from '../../../../common/AppFlexbox';
import ProductInputDisplay from '../../../products/ProductInputDisplay';
import { INPUT_CONTROL_TYPE_ENUM } from '../../../../../config/constants';
import { VARIANT_STYLE_ENUM } from './wsVariantPickerConfig';

const WsVariantPickerBlock = ({
  selectedOptions,
  ecomStoreCartProductInputAnswers,
  ecomStoreProduct,
  variantOptions,
  onVariantChange,
  onInputChange,
  selectedVariant,
  submitting,
  mediaQueries,
  config,
  baseStoreUrl,
  onNavigate
}) => {
  const { isTabletOrSmaller } = mediaQueries;
  const firstSelectedOption = selectedOptions.find((op) => op.nameSort === 1);
  const secondSelectedOption = selectedOptions.find((op) => op.nameSort === 2);

  const secondVariantOptions =
    ecomStoreProduct?.ecomStoreProductVariants.filter((v) =>
      v.ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
        (s) =>
          s.nameSort === firstSelectedOption?.nameSort &&
          s.name === firstSelectedOption?.name &&
          s.value === firstSelectedOption?.value
      )
    ) ?? [];
  const thirdVariantOptions =
    ecomStoreProduct?.ecomStoreProductVariants.filter((v) =>
      v.ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
        (s) =>
          s.nameSort === secondSelectedOption?.nameSort &&
          s.name === secondSelectedOption?.name &&
          s.value === secondSelectedOption?.value
      )
    ) ?? [];

  return (
    (variantOptions.length > 0 ||
      ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs.length >
        0) && (
      <Grid gutter={8} style={{ paddingBottom: 0 }}>
        {variantOptions.map((op) => {
          const availableVariantValues = op.values.map((v) => ({
            ...v,
            disabled:
              op.sort === 2
                ? !secondVariantOptions.some((s) =>
                    s.ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
                      (so) =>
                        so.nameSort === op.sort &&
                        so.name === op.name &&
                        so.value === v.value
                    )
                  )
                : op.sort === 3 &&
                  !thirdVariantOptions.some((s) =>
                    s.ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
                      (so) =>
                        so.nameSort === op.sort &&
                        so.name === op.name &&
                        so.value === v.value
                    )
                  )
          }));

          return (
            <Grid.Col
              key={op.name}
              span={
                !isTabletOrSmaller &&
                (config.variantStyle === VARIANT_STYLE_ENUM.DROPDOWN ||
                  op.values.length > 10)
                  ? 6
                  : 12
              }
              style={{ alignSelf: 'end' }}
            >
              {config.variantStyle === VARIANT_STYLE_ENUM.BUTTON_GROUP &&
              op.values.length <= (isTabletOrSmaller ? 3 : 5) ? (
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 14 }}>{op.name}</AppText>
                  <Button.Group style={{ width: '100%' }}>
                    {availableVariantValues
                      .sort((a, b) => a.sort - b.sort)
                      .map((v) => (
                        <Button
                          key={v.value}
                          color="dark"
                          disabled={submitting || v.disabled}
                          onClick={() =>
                            onVariantChange([
                              ...selectedOptions.filter(
                                (f) => f.name !== op.name
                              ),
                              {
                                nameSort: op.sort,
                                name: op.name,
                                value: v.value
                              }
                            ])
                          }
                          size="compact-md"
                          style={{
                            width: '100%',
                            overflow: 'visible',
                            whiteSpace: 'nowrap',
                            textOverflow: 'unset',
                            flexGrow: 1,
                            fontSize: 14,
                            padding: '0px 4px'
                          }}
                          variant={
                            v.value ===
                            selectedOptions.find((f) => f.name === op.name)
                              ?.value
                              ? 'filled'
                              : 'outline'
                          }
                        >
                          {v.value}
                        </Button>
                      ))}
                  </Button.Group>
                </AppStack>
              ) : config.variantStyle === VARIANT_STYLE_ENUM.PILLS &&
                op.values.length <= 10 ? (
                <AppStack style={{ gap: 5 }}>
                  <AppText
                    style={{
                      fontSize: 14,
                      fontWeight: 500
                    }}
                  >
                    {op.name}
                  </AppText>
                  <AppFlexbox
                    style={{ gap: 8, flexWrap: 'wrap' }}
                    variant="outline"
                  >
                    {availableVariantValues
                      .sort((a, b) => a.sort - b.sort)
                      .map((v) => {
                        const isSelected = selectedOptions.some(
                          (f) => f.name === op.name && f.value === v.value
                        );

                        return (
                          <Button
                            key={v.value}
                            color={isSelected ? 'dark' : 'gray'}
                            disabled={submitting || v.disabled}
                            onClick={() =>
                              onVariantChange([
                                ...selectedOptions.filter(
                                  (f) => f.name !== op.name
                                ),
                                {
                                  nameSort: op.sort,
                                  name: op.name,
                                  value: v.value
                                }
                              ])
                            }
                            radius={100}
                            size="compact-md"
                            style={{ fontSize: 12, minWidth: 25 }}
                            variant={isSelected ? 'filled' : 'outline'}
                          >
                            {v.value}
                          </Button>
                        );
                      })}
                  </AppFlexbox>
                </AppStack>
              ) : (
                <Select
                  data={availableVariantValues
                    .sort((a, b) => a.sort - b.sort)
                    .map((v) => ({
                      label: v.value,
                      value: v.value,
                      disabled: v.disabled
                    }))}
                  disabled={submitting}
                  label={op.name}
                  onChange={(value) => {
                    if (value) {
                      onVariantChange([
                        ...selectedOptions.filter((f) => f.name !== op.name),
                        { nameSort: op.sort, name: op.name, value }
                      ]);
                    }
                  }}
                  radius={0}
                  value={selectedOptions.find((f) => f.name === op.name)?.value}
                />
              )}
            </Grid.Col>
          );
        })}

        {ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs
          .sort(
            (a, b) =>
              a.sort - b.sort &&
              (a.ecomVendorProductVariants.length === 0 ||
                a.ecomVendorProductVariants.some(
                  (pk) =>
                    pk ===
                    selectedVariant?.ecomVendorProductVariant
                      .pkEcomVendorProductVariant
                ))
          )
          .map((p) => {
            const inputAnswer = ecomStoreCartProductInputAnswers.find(
              (a) => a.fkEcomVendorProductInput === p.pkEcomVendorProductInput
            );

            return (
              <Grid.Col
                key={p.pkEcomVendorProductInput}
                span={
                  isTabletOrSmaller ||
                  p.fkRegFormControlType === INPUT_CONTROL_TYPE_ENUM.CHECKBOX ||
                  p.fkRegFormControlType === INPUT_CONTROL_TYPE_ENUM.FILE_UPLOAD
                    ? 12
                    : 6
                }
                style={{
                  alignSelf: 'end',
                  marginTop:
                    p.fkRegFormControlType === INPUT_CONTROL_TYPE_ENUM.CHECKBOX
                      ? 5
                      : 0
                }}
              >
                <ProductInputDisplay
                  key={p.pkEcomVendorProductInput}
                  baseStoreUrl={baseStoreUrl}
                  disabled={inputAnswer?.isAutopopulated || submitting}
                  ecomVendorProductInput={p}
                  onChange={(value, type) =>
                    onInputChange([
                      ...ecomStoreCartProductInputAnswers.filter(
                        (a) =>
                          a.fkEcomVendorProductInput !==
                          p.pkEcomVendorProductInput
                      ),
                      {
                        fkEcomVendorProductInput: p.pkEcomVendorProductInput,
                        fkRegFormControlType: p.fkRegFormControlType,
                        file: type === 'file' ? value : null,
                        value: type === 'file' ? null : value
                      }
                    ])
                  }
                  onNavigate={onNavigate}
                  radius={0}
                  value={inputAnswer?.value}
                />
              </Grid.Col>
            );
          })}
      </Grid>
    )
  );
};

WsVariantPickerBlock.propTypes = {
  baseStoreUrl: PropTypes.string,
  config: PropTypes.object,
  ecomStoreCartProductInputAnswers: PropTypes.array,
  ecomStoreProduct: PropTypes.object,
  mediaQueries: PropTypes.object,
  onInputChange: PropTypes.func,
  onNavigate: PropTypes.func,
  onVariantChange: PropTypes.func,
  selectedOptions: PropTypes.array,
  selectedVariant: PropTypes.object,
  submitting: PropTypes.bool,
  variantOptions: PropTypes.array
};

export default WsVariantPickerBlock;
