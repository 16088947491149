import React from 'react';
import { Anchor, Button, Image } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from './AppStack';
import AppCard from './AppCard';
import AppText from './AppText';
import shopOpenLogo from '../../images/sportsheadz-open-shop.png';
import { useMediaQueryIndex } from '../../helpers/hooks';

const HomeInfoCard = ({ ...rest }) => {
  const { getResponsiveStyle: rs } = useMediaQueryIndex();

  return (
    <AppCard
      radius="lg"
      shadow="md"
      withBorder
      {...rest}
      style={{
        position: 'absolute',
        top: -40,
        left: 0,
        padding: rs(['20px 20px 40px 20px', 40]),
        overflow: 'visible',
        border: 'solid 3px #ced4da',
        ...rest.style
      }}
    >
      <AppStack
        style={{
          gap: 0,
          flex: 1,
          position: 'relative',
          textAlign: 'center',
          overflow: 'visible'
        }}
      >
        <AppStack
          style={{
            position: 'absolute',
            height: rs([190, 270, 270, 260, 270]),
            width: '100%',
            top: rs([-75, -95])
          }}
        >
          <Image fit="contain" height="100%" src={shopOpenLogo} width="100%" />
        </AppStack>
        <AppStack
          style={{
            paddingTop: rs([120, 175, 175, '40%', 175]),
            gap: 0
          }}
        >
          <AppText
            style={{
              fontSize: rs([22, 26, 26, 26, 32]),
              color: '#333',
              fontWeight: 700,
              whiteSpace: 'nowrap'
            }}
          >
            READY TO START SELLING?
          </AppText>
          <Anchor
            c="#333"
            component={Link}
            style={{
              fontSize: rs([18, 20, 20, 20, 22]),
              whiteSpace: rs(['wrap', 'nowrap'])
            }}
            to="/merchant/switch"
            underline="always"
          >
            Click to open your <b>SportsHeadz Shop</b>
          </Anchor>
          <AppText
            style={{
              fontSize: rs([18, 20, 20, 20, 22]),
              color: '#333'
            }}
          >
            Start earning additional revenue on your current site by offering
            the latest merch to your visitors!
          </AppText>
        </AppStack>
      </AppStack>
      <Button
        color="#000"
        component={Link}
        radius="xl"
        size="compact-xl"
        style={{
          padding: '5px 40px',
          fontSize: 20,
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, 50%)'
        }}
        to="/merchant/switch"
        variant="filled"
      >
        Open Your Shop Today!
      </Button>
    </AppCard>
  );
};

export default HomeInfoCard;
