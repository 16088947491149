import React from 'react';
import PropTypes from 'prop-types';
import WsSectionContainer from '../WsSectionContainer';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import WsBlock from '../../blocks/WsBlock';
import { HEADING_SIZE_STYLE_ENUM, MOBILE_LAYOUT_ENUM } from './wsCollageConfig';

const getHeadingFontSize = (headingSize) => {
  switch (headingSize) {
    case HEADING_SIZE_STYLE_ENUM.HUGE:
      return 50;
    case HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE:
      return 44;
    case HEADING_SIZE_STYLE_ENUM.LARGE:
      return 36;
    case HEADING_SIZE_STYLE_ENUM.MEDIUM:
      return 28;
    default:
      return 22;
  }
};

const getGridStyles = (isTabletOrSmaller, config, index, blocksLength) => {
  const isLeft = config.desktopLayout === 'LEFT';
  if (
    isTabletOrSmaller &&
    (blocksLength <= 2 || config.mobileLayout === MOBILE_LAYOUT_ENUM.COLUMN)
  ) {
    return {
      gridRow: 'span 2',
      gridColumn: 'span 2'
    };
  }

  if (index === 0) {
    return {
      gridRow: isTabletOrSmaller && !isLeft ? '3 / span 2' : '1 / span 2',
      gridColumn: isTabletOrSmaller || isLeft ? '1 / span 2' : '2 / span 2'
    };
  }

  if (blocksLength === 2) {
    return {
      gridRow: '1 / span 2',
      gridColumn: `${isLeft ? 3 : 1} / span 1`
    };
  }

  return {};
};

const WsCollage = ({
  blocks,
  config,
  colorSchemes,
  themeConfig,
  ecomStoreTheme,
  baseStoreUrl,
  onNavigate,
  mediaQueries,
  ...rest
}) => {
  const { isTabletOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack style={{ width: '100%' }}>
        {config.heading && (
          <AppText
            style={{
              fontWeight: 600,
              fontSize: getHeadingFontSize(config.headingSize)
            }}
          >
            {config.heading}
          </AppText>
        )}

        {blocks.length === 1 && !isTabletOrSmaller ? (
          <AppStack
            style={{
              width: '100%',
              maxWidth: '60%',
              alignSelf: 'center'
            }}
          >
            <WsBlock
              baseStoreUrl={baseStoreUrl}
              colorScheme={colorScheme}
              ecomStoreTheme={ecomStoreTheme}
              fit="cover"
              onNavigate={onNavigate}
              {...blocks[0]}
            />
          </AppStack>
        ) : (
          blocks.length > 0 && (
            <AppFlexbox
              style={{
                gridTemplateColumns: isTabletOrSmaller
                  ? 'repeat(2, minmax(0, 1fr))'
                  : 'repeat(3, minmax(0, 1fr))',
                gridColumnGap: 8,
                gridRowGap: 8,
                display: 'grid',
                width: '100%'
              }}
            >
              {blocks.map((b, index) => (
                <AppStack
                  key={b.key}
                  style={{
                    ...getGridStyles(
                      isTabletOrSmaller,
                      config,
                      index,
                      blocks.length
                    ),
                    width: '100%',
                    maxWidth: '100%'
                  }}
                >
                  <WsBlock
                    baseStoreUrl={baseStoreUrl}
                    colorScheme={colorScheme}
                    ecomStoreTheme={ecomStoreTheme}
                    fit="cover"
                    onNavigate={onNavigate}
                    {...b}
                  />
                </AppStack>
              ))}
            </AppFlexbox>
          )
        )}
      </AppStack>
    </WsSectionContainer>
  );
};

WsCollage.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreCollections: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsCollage;
