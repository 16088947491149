import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ChevronRight,
  Coin,
  Lock
} from 'tabler-icons-react';
import {
  ActionIcon,
  Alert,
  Anchor,
  Badge,
  Button,
  Divider,
  NumberFormatter,
  Skeleton
} from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import StoreEscrowPaymentsClaimModal from './StoreEscrowPaymentsClaimModal';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import * as classes from '../../../styles/nestedStyles.module.css';
import { PAYMENT_TYPE_ENUM } from '../../../config/constants';

const StoreEscrowPaymentsView = () => {
  const hasFetched = useRef(false);
  const { search } = useLocation();
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreEscrowSummary } = useContext(StoreContext);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const modalAction = new URLSearchParams(search).get('modal');

  const storeEscrowSummary =
    authState.pkEcomStore?.toString() ===
    state.ecomStoreEscrowSummary.value?.ecomStore.pkEcomStore.toString()
      ? state.ecomStoreEscrowSummary.value
      : null;
  const ecomStorePaymentProvider =
    storeEscrowSummary?.ecomStore.ecomStorePaymentProvider ?? null;

  const isLoading =
    !hasFetched.current ||
    state.ecomStoreEscrowSummary.loading ||
    !storeEscrowSummary;

  const claimableBalance = storeEscrowSummary
    ? (storeEscrowSummary.amountPaid -
        storeEscrowSummary.amountRefunded -
        storeEscrowSummary.totalServiceFees -
        storeEscrowSummary.totalApplicationFees -
        storeEscrowSummary.totalAmountClaimed) /
      100
    : 0;

  const escrowClaimDisabled =
    !ecomStorePaymentProvider ||
    !ecomStorePaymentProvider.completed ||
    claimableBalance <= 0;

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchStoreEscrowSummary(authState.pkEcomStore);
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  useEffect(() => {
    if (modalAction) {
      switch (modalAction) {
        case 'claim':
          if (!escrowClaimDisabled) {
            setShowClaimModal(true);
          }
          break;

        default:
          break;
      }
    }
  }, [modalAction, storeEscrowSummary]);

  return !isLoading ? (
    <AppStack
      p={{ base: '8px 0px', sm: '40px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <ActionIcon
            color="dark"
            component={Link}
            radius="md"
            to="/merchant/settings"
            variant="subtle"
          >
            <ArrowLeft />
          </ActionIcon>
          <AppText
            style={{
              flex: 1,
              fontSize: 18,
              fontWeight: 700,
              wordBreak: 'break-word'
            }}
          >
            Escrow Payments
          </AppText>
        </AppFlexbox>

        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Button
            color="blue"
            disabled={escrowClaimDisabled}
            onClick={() => setShowClaimModal(true)}
            size="compact-sm"
            variant="filled"
          >
            Claim balance
          </Button>
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox
        style={{ flexDirection: isTabletOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppStack style={{ gap: 16 }}>
            <AppCard
              radius={isTabletOrSmaller ? 0 : 'md'}
              shadow="xs"
              style={{ flex: 1, padding: 0 }}
              withBorder
            >
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Balance
                </AppText>

                <AppCard
                  radius="md"
                  shadow="none"
                  style={{ padding: 0 }}
                  withBorder
                >
                  <AppFlexbox style={{ gap: 0 }}>
                    <AppStack
                      style={{
                        padding: 16,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Coin color="#999" size={65} />
                    </AppStack>
                    <Divider orientation="vertical" />
                    <AppStack style={{ padding: 16 }}>
                      <AppText style={{ fontSize: 13, color: '#666' }}>
                        When operating without a payment provider, SportsHeadz
                        manages your store and processes payments on your
                        behalf. You can claim your balance anytime by setting up
                        a payment provider and transferring the funds to your
                        account.
                      </AppText>
                    </AppStack>
                  </AppFlexbox>
                </AppCard>
              </AppStack>
              <Divider />
              <AppStack style={{ padding: 16, gap: 8 }}>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>Total</AppText>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={
                      (storeEscrowSummary.amountPaid -
                        storeEscrowSummary.amountRefunded -
                        storeEscrowSummary.totalServiceFees -
                        storeEscrowSummary.totalApplicationFees) /
                      100
                    }
                  />
                </AppFlexbox>

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>Claimed</AppText>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={storeEscrowSummary.totalAmountClaimed / 100}
                  />
                </AppFlexbox>
                <Divider />

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  {claimableBalance > 0 ? (
                    <>
                      <Anchor
                        onClick={() => setShowClaimModal(true)}
                        style={{ color: 'dodgerblue', fontSize: 14 }}
                      >
                        Balance
                      </Anchor>
                      <Anchor
                        onClick={() => setShowClaimModal(true)}
                        style={{ color: 'dodgerblue', fontSize: 14 }}
                      >
                        <NumberFormatter
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="$"
                          style={{ fontSize: 14 }}
                          thousandSeparator
                          value={claimableBalance}
                        />
                      </Anchor>
                    </>
                  ) : (
                    <>
                      <AppText style={{ fontSize: 14 }}>Balance</AppText>
                      <NumberFormatter
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        style={{ fontSize: 14 }}
                        thousandSeparator
                        value={claimableBalance}
                      />
                    </>
                  )}
                </AppFlexbox>
              </AppStack>
            </AppCard>
            <AppFlexbox style={{ justifyContent: 'end', alignItems: 'center' }}>
              <Button
                color="blue"
                disabled={escrowClaimDisabled}
                onClick={() => setShowClaimModal(true)}
                size="compact-sm"
                variant="filled"
              >
                Claim balance
              </Button>
            </AppFlexbox>
          </AppStack>

          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ flex: 1, padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ padding: 16 }}>
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Orders
                </AppText>
              </AppFlexbox>
              <AppStack style={{ gap: 0 }}>
                {storeEscrowSummary.latestOrders.length === 0 ? (
                  <AppFlexbox
                    style={{
                      padding: 16,
                      gap: 5,
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center'
                    }}
                  >
                    <AlertCircle color="#666" size={22} />
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#666'
                      }}
                    >
                      No orders found
                    </AppText>
                  </AppFlexbox>
                ) : (
                  storeEscrowSummary.latestOrders.map((order) => (
                    <AppFlexbox
                      key={order.pkEcomStoreCart}
                      className={classes['hover-action-card']}
                      component={Link}
                      style={{
                        padding: '8px 16px',
                        cursor: 'pointer',
                        borderTop: 'solid 1px #dee2e6',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        textDecoration: 'none',
                        color: '#000'
                      }}
                      to={`/merchant/orders/${order.pkEcomStoreCart}`}
                    >
                      <AppStack style={{ gap: 0 }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          Order #{order.pkEcomStoreCart}
                        </AppText>
                        <AppText style={{ fontSize: 14 }}>
                          {singularPluralFormat(
                            order.ecomStoreCartProducts.length,
                            'item',
                            'items'
                          )}
                        </AppText>
                        <AppText style={{ fontSize: 14, color: '#999' }}>
                          {dayjs(
                            formatUtcDate(
                              order.ecomStoreCartPayments.sort(
                                (a, b) =>
                                  new Date(a.createdAt) - new Date(b.createdAt)
                              )?.createdAt
                            )
                          ).format('MMM D, YYYY')}
                        </AppText>
                      </AppStack>
                      <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                        <NumberFormatter
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="$"
                          style={{ fontSize: 14 }}
                          thousandSeparator
                          value={
                            order.ecomStoreCartPayments
                              .filter(
                                (p) =>
                                  p.fkRegFormSubmissionPaymentType.toString() !==
                                  PAYMENT_TYPE_ENUM.REFUND
                              )
                              .reduce((r, current) => r + current.amount, 0) /
                            100
                          }
                        />
                        <ChevronRight size={20} />
                      </AppFlexbox>
                    </AppFlexbox>
                  ))
                )}

                {storeEscrowSummary.latestOrders.length <
                  storeEscrowSummary.orderCount && (
                  <Button
                    color="dark"
                    component={Link}
                    radius={0}
                    size="sm"
                    to="/merchant/orders?type=escrow"
                    variant="light"
                  >
                    View all {storeEscrowSummary.orderCount} orders
                  </Button>
                )}
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isTabletOrSmaller ? 'unset' : 350 }}
        >
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 8 }}>
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Payment Provider
              </AppText>
              {(!ecomStorePaymentProvider ||
                !ecomStorePaymentProvider.completed) && (
                <Alert
                  color="#c40000"
                  icon={<AlertTriangle style={{ height: 24, width: 24 }} />}
                  radius={10}
                  styles={{
                    body: { gap: 5 },
                    icon: { height: 24, width: 24 },
                    title: { fontSize: 15, fontWeight: 500 }
                  }}
                  title="Action required"
                  variant="outline"
                >
                  <AppStack>
                    <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                      {!ecomStorePaymentProvider
                        ? 'You must onboard a payment provider to claim your balance.'
                        : 'You must complete the onboarding process before you claim your balance.'}
                    </AppText>
                    <AppFlexbox>
                      <Button
                        color="blue"
                        component={Link}
                        size="compact-sm"
                        to="/merchant/settings?modal=payment-provider"
                        variant="filled"
                      >
                        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                          <Lock size={18} />
                          <AppText style={{ fontSize: 14 }}>
                            {!ecomStorePaymentProvider ? 'Onboard' : 'Update'}{' '}
                            payment provider
                          </AppText>
                        </AppFlexbox>
                      </Button>
                    </AppFlexbox>
                  </AppStack>
                </Alert>
              )}

              {ecomStorePaymentProvider && (
                <AppCard
                  radius={8}
                  shadow="none"
                  style={{ padding: 8 }}
                  withBorder
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Stripe account
                      </AppText>
                      {!ecomStorePaymentProvider.completed ? (
                        <Badge color="yellow">Incomplete</Badge>
                      ) : (
                        <Badge color="green">Connected</Badge>
                      )}
                    </AppFlexbox>

                    <AppText style={{ fontSize: 14 }}>
                      {ecomStorePaymentProvider.accountId}
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#999' }}>
                      Added{' '}
                      {dayjs(
                        formatUtcDate(ecomStorePaymentProvider.createdAt)
                      ).format('MMM D, YYYY')}
                    </AppText>
                  </AppStack>
                </AppCard>
              )}
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>

      <StoreEscrowPaymentsClaimModal
        isOpen={showClaimModal}
        onClose={() => setShowClaimModal(false)}
      />
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '40px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <ActionIcon
            color="dark"
            component={Link}
            radius="md"
            to="/merchant/settings"
            variant="subtle"
          >
            <ArrowLeft />
          </ActionIcon>
          <AppText
            style={{
              flex: 1,
              fontSize: 18,
              fontWeight: 700,
              wordBreak: 'break-word'
            }}
          >
            Escrow Payments
          </AppText>
        </AppFlexbox>

        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Button color="blue" disabled size="compact-sm" variant="filled">
            Claim balance
          </Button>
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox
        style={{ flexDirection: isTabletOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppStack style={{ gap: 8 }}>
            <AppCard
              radius={isTabletOrSmaller ? 0 : 'md'}
              shadow="xs"
              style={{ flex: 1, padding: 0 }}
              withBorder
            >
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Balance
                </AppText>
                <Skeleton height="90" radius={8} width="100%" />
              </AppStack>
              <Divider />
              <AppStack style={{ padding: 16, gap: 8 }}>
                {Array.from(Array(2)).map((_, index) => (
                  <AppFlexbox
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={{
                      gap: 8,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Skeleton height={16} width={90} />
                    <Skeleton height={16} width={35} />
                  </AppFlexbox>
                ))}

                <Divider />

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={16} width={110} />
                  <Skeleton height={16} width={35} />
                </AppFlexbox>
              </AppStack>
            </AppCard>
            <AppFlexbox style={{ justifyContent: 'end', alignItems: 'center' }}>
              <Skeleton height={28} width={105} />
            </AppFlexbox>
          </AppStack>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isTabletOrSmaller ? 'unset' : 350 }}
        >
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 8 }}>
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Payment Provider
              </AppText>

              <AppCard
                radius={8}
                shadow="none"
                style={{ padding: 8 }}
                withBorder
              >
                <AppStack style={{ gap: 5 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Skeleton height={14} width={90} />
                    <Skeleton height={20} radius={100} width={90} />
                  </AppFlexbox>

                  <Skeleton height={14} width={140} />

                  <Skeleton height={14} width={140} />
                </AppStack>
              </AppCard>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

export default StoreEscrowPaymentsView;
