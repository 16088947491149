/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Anchor } from '@mantine/core';
import {
  BrandFacebook,
  BrandInstagram,
  BrandLinkedin,
  BrandTwitter,
  BrandYoutube
} from 'tabler-icons-react';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import { HEADING_SIZE_STYLE_ENUM } from '../../sections/featuredCollection/wsFeaturedCollectionConfig';
import { generateColorVariants } from '../../../../../helpers/styles';

const WsBrandInformationBlock = ({ config, colorScheme, ecomStoreTheme }) => (
  <AppFlexbox style={{ overflow: 'hidden', maxWidth: '100%' }}>
    <AppStack style={{ overflow: 'hidden' }}>
      <AppText
        style={{
          fontWeight: 500,
          fontSize:
            config.headingSize === HEADING_SIZE_STYLE_ENUM.HUGE
              ? 22
              : config.headingSize === HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE
              ? 20
              : config.headingSize === HEADING_SIZE_STYLE_ENUM.LARGE
              ? 18
              : config.headingSize === HEADING_SIZE_STYLE_ENUM.MEDIUM
              ? 16
              : 14,
          wordBreak: 'break-word',
          overflowWrap: 'break-word'
        }}
      >
        {ecomStoreTheme.ecomStore.name}
      </AppText>

      {(config.text || ecomStoreTheme.ecomStore.description) && (
        <AppText
          style={{
            fontSize: config.fontSize * 1,
            fontStyle: config.fontStyle,
            fontWeight: config.fontWeight,
            textDecoration: config.textDecoration,
            textTransform: config.textTransform,
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            ...(config.heading &&
              colorScheme && {
                color: generateColorVariants(colorScheme.textColor, 100).lighter
              })
          }}
        >
          {config.text || ecomStoreTheme.ecomStore.description}
        </AppText>
      )}

      <AppFlexbox style={{ width: '100%', overflow: 'hidden' }}>
        <Anchor
          href={
            'https://local.store.sportsheadz.com:8000/store/test-association/products' ??
            ecomStoreTheme.ecomStore.domain
          }
          style={{
            fontSize: 14,
            color: colorScheme.textColor,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          target="_blank"
        >
          {ecomStoreTheme.ecomStore.domain}
        </Anchor>
      </AppFlexbox>

      {config.showSocials === 'true' &&
        (config.facebookUrl ||
          config.twitterUrl ||
          config.instagramUrl ||
          config.linkedinUrl ||
          config.youtubeUrl) && (
          <AppFlexbox style={{ gap: 8 }}>
            {config.facebookUrl && (
              <ActionIcon
                component="a"
                href={config.facebookUrl}
                radius="100"
                size="md"
                style={{
                  backgroundColor: colorScheme.solidButtonColor,
                  color: colorScheme.solidButtonLabel
                }}
                target="_blank"
              >
                <BrandFacebook />
              </ActionIcon>
            )}

            {config.twitterUrl && (
              <ActionIcon
                component="a"
                href={config.twitterUrl}
                radius="100"
                size="md"
                style={{
                  backgroundColor: colorScheme.solidButtonColor,
                  color: colorScheme.solidButtonLabel
                }}
                target="_blank"
              >
                <BrandTwitter />
              </ActionIcon>
            )}

            {config.instagramUrl && (
              <ActionIcon
                component="a"
                href={config.instagramUrl}
                radius="100"
                size="md"
                style={{
                  backgroundColor: colorScheme.solidButtonColor,
                  color: colorScheme.solidButtonLabel
                }}
                target="_blank"
              >
                <BrandInstagram />
              </ActionIcon>
            )}

            {config.linkedinUrl && (
              <ActionIcon
                component="a"
                href={config.linkedinUrl}
                radius="100"
                size="md"
                style={{
                  backgroundColor: colorScheme.solidButtonColor,
                  color: colorScheme.solidButtonLabel
                }}
                target="_blank"
              >
                <BrandLinkedin />
              </ActionIcon>
            )}

            {config.youtubeUrl && (
              <ActionIcon
                component="a"
                href={config.youtubeUrl}
                radius="100"
                size="md"
                style={{
                  backgroundColor: colorScheme.solidButtonColor,
                  color: colorScheme.solidButtonLabel
                }}
                target="_blank"
              >
                <BrandYoutube />
              </ActionIcon>
            )}
          </AppFlexbox>
        )}
    </AppStack>
  </AppFlexbox>
);

WsBrandInformationBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object
};

export default WsBrandInformationBlock;
