/* eslint-disable import/no-cycle */
import { Mail } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM
} from '../../../../../config/constants';
import WsEmailSignup from './WsEmailSignup';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';

const configNameValues = { colorScheme: 'colorScheme' };

const configDefaultValues = { [configNameValues.colorScheme]: '1' };

const configOptions = {
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.EMAIL_SIGNUP,
  title: 'Email signup',
  icon: Mail,
  allowedBounds: [
    ECOM_WEBSTORE_SECTION_BOUND_TYPE.FOOTER,
    ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
  ],
  Component: WsEmailSignup,
  configOptions,
  maxItemCount: null,
  cannotCreate: true,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.HEADING
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.SUBHEADING
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.EMAIL_FORM
      ],
      defaultCount: 1,
      maxCount: 1
    }
  ],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
