import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';

const ReportFilterModal = ({ isOpen, onClose }) => {
  const [formState, setFormState] = useState({});

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: () => {},
        submitTitle: 'Apply',
        padding: 0,
        footerProps: { style: { zIndex: 2000 } }
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Filter report"
    >
      <AppStack />
    </ResponsiveModal>
  );
};

ReportFilterModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default ReportFilterModal;
