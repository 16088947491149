import React, { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { createRoot } from 'react-dom/client';
import ResponsiveModal from '../components/common/ResponsiveModal';
import { theme } from '../styles/theme';
import AppText from '../components/common/AppText';
import ConfirmModal from '../components/common/ConfirmModal';

const useConfirmExit = (
  confirmExit,
  when = true,
  config = { urlCompareString: null }
) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const { push, location } = navigator;
    const currentPathname = location.pathname;

    navigator.push = (...args) => {
      let newPathname;
      const newUrl = args[0];

      if (typeof newUrl === 'string') {
        newPathname = new URL(newUrl, window.location.origin).pathname;
      }
      else if (newUrl && typeof newUrl === 'object' && newUrl.pathname) {
        newPathname = newUrl.pathname;
      }

      if (
        config.urlCompareString
          ? !newPathname.startsWith(config.urlCompareString)
          : newPathname !== currentPathname
      ) {
        const confirm = () => push(...args);
        confirmExit(confirm);
      }
      else {
        push(...args);
      }
    };

    // eslint-disable-next-line consistent-return
    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
};

export const usePrompt = (
  message,
  when,
  config = { urlCompareString: null }
) => {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => message;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(
    (confirm) => {
      const element = document.createElement('div');
      element.setAttribute('id', 'prompt-dialog-container');
      element.setAttribute('aria-hidden', 'true');
      document.body.appendChild(element);
      const root = createRoot(element);

      const closePrompt = (state) => {
        if (element) {
          root.unmount();
        }
        if (!state) {
          document.body.removeChild(element);
        }
        else {
          confirm();
        }
      };

      root.render(
        <MantineProvider theme={theme}>
          <ConfirmModal
            cancelActionText="Stay on page"
            confirmActionColor="red"
            confirmActionText="Leave without saving"
            isOpen
            message={<AppText style={{ fontSize: 16 }}>{message}</AppText>}
            onCancel={() => {
              closePrompt(false);
              if (config.onCancel) {
                config.onCancel();
              }
            }}
            onConfirm={() => {
              closePrompt(true);
            }}
            title="Leave without saving?"
          />
        </MantineProvider>
      );
    },
    [message]
  );

  useConfirmExit(confirmExit, when, config);
};
