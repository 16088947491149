import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Anchor, Button, Grid } from '@mantine/core';
import PropTypes from 'prop-types';
import { Carousel } from '@mantine/carousel';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import {
  PRODUCT_CATEGORY_LIST,
  SPECIAL_PRODUCT_CATEGORIES
} from '../../../config/productCategories';
import * as classes from '../../../styles/animations.module.css';
import VendorCatalogDisplayCard from './VendorCatalogDisplayCard';
import VendorCatalogVendorDisplayCard from './VendorCatalogVendorDisplayCard';
import VendorCatalogSearchFilter from './VendorCatalogSearchFilter';
import { useProductSearchFilter } from '../../../helpers/catalogHelper';
import VendorCatalogProductSearchList from './VendorCatalogProductSearchList';
import VendorCatalogCarousel from './VendorCatalogCarousel';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const STORE_CATEGORIES = [
  SPECIAL_PRODUCT_CATEGORIES[0],
  PRODUCT_CATEGORY_LIST[1],
  PRODUCT_CATEGORY_LIST[0],
  PRODUCT_CATEGORY_LIST[4],
  PRODUCT_CATEGORY_LIST[7]
];

const VendorCatalogExplorer = ({ loading, vendorPreview, baseUrl }) => {
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { state } = useContext(StoreContext);
  const { hasSearched, resetFilter } = useProductSearchFilter();
  const {
    isMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isLargeDesktopOrSmaller
  } = useMediaQueryIndex();
  const explorerLoading =
    loading || state.catalogSummary.loading || !state.catalogSummary.value;

  const isProductSelected = false;

  const productGridColumns = isMobileOrSmaller
    ? 1
    : isTabletOrSmaller
    ? 2
    : isLaptopOrSmaller
    ? 3
    : isDesktopOrSmaller
    ? 4
    : 5;

  const vendorGridColumns = isMobileOrSmaller
    ? 1
    : isTabletOrSmaller
    ? 2
    : isTabletOrSmaller
    ? 3
    : isDesktopOrSmaller
    ? 4
    : isLargeDesktopOrSmaller
    ? 5
    : 6;

  return (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        padding: 16,
        marginBottom: 70,
        gap: 20,
        alignSelf: 'center'
      }}
    >
      <AppStack style={{ gap: 5 }}>
        <AppFlexbox
          style={{ alignItems: 'end', justifyContent: 'space-between' }}
        >
          <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
            <AppText style={{ fontSize: 28, fontWeight: 700 }}>
              Find products
            </AppText>
          </AppFlexbox>

          {hasSearched && (
            <Anchor
              c="dodgerblue"
              onClick={resetFilter}
              style={{ fontSize: 16 }}
            >
              Clear search
            </Anchor>
          )}
        </AppFlexbox>

        <VendorCatalogSearchFilter />
      </AppStack>
      {!hasSearched ? (
        <>
          <AppStack style={{ gap: 5 }}>
            <AppFlexbox
              style={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                Categories
              </AppText>
              <Anchor
                c="dodgerblue"
                component={Link}
                to={`${baseUrl}/categories`}
              >
                View all
              </Anchor>
            </AppFlexbox>

            <AppFlexbox style={{ gap: 8, minHeight: 120 }}>
              {STORE_CATEGORIES.slice(0, productGridColumns).map((category) => (
                <AppCard
                  key={category.value}
                  className={classes['grow-container']}
                  component={Link}
                  radius="md"
                  shadow="xl"
                  style={{
                    flex: 1,
                    cursor: 'pointer',
                    background: category.gradientColor,
                    borderRadius: 10,
                    padding: 16,
                    color: '#FFF',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    alignItems: 'center',
                    justifyContent: 'start',
                    textAlign: 'center'
                  }}
                  to={`${baseUrl}/categories/${category.name
                    .toLowerCase()
                    .replace(' ', '-')}`}
                >
                  <AppStack style={{}}>
                    <category.Icon size={55} />
                  </AppStack>
                  <AppText style={{ fontSize: 28, fontWeight: 700 }}>
                    {category.name}
                  </AppText>
                  <AppText
                    style={{
                      fontSize: 13,
                      fontWeight: 500,
                      textAlign: 'center'
                    }}
                  >
                    {category.description}
                  </AppText>
                </AppCard>
              ))}
            </AppFlexbox>
          </AppStack>
          <AppStack style={{ gap: 5 }}>
            <AppFlexbox
              style={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                Top sellers
              </AppText>
              <Anchor
                c="dodgerblue"
                component={Link}
                to={`${baseUrl}/products/top-sellers`}
              >
                View all
              </Anchor>
            </AppFlexbox>

            {explorerLoading ? (
              <VendorCatalogCarousel
                items={Array.from(Array(10)).map((x, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Carousel.Slide key={i}>
                    <VendorCatalogDisplayCard />
                  </Carousel.Slide>
                ))}
                loading
              />
            ) : (
              <VendorCatalogCarousel
                items={state.catalogSummary.value.bestSellingProducts.map(
                  (p) => (
                    <Carousel.Slide key={p.pkEcomVendorProduct}>
                      <VendorCatalogDisplayCard
                        baseUrl={baseUrl}
                        ecomVendorProduct={p}
                        selected={isProductSelected}
                        vendorPreview={vendorPreview}
                      />
                    </Carousel.Slide>
                  )
                )}
              />
            )}
          </AppStack>

          <AppStack style={{ gap: 5 }}>
            <AppFlexbox
              style={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                Vendors
              </AppText>
              <Anchor c="dodgerblue" component={Link} to={`${baseUrl}/vendors`}>
                View all
              </Anchor>
            </AppFlexbox>

            {isTabletOrSmaller ? (
              explorerLoading ? (
                <VendorCatalogCarousel
                  items={Array.from(Array(10)).map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Carousel.Slide key={i}>
                      <VendorCatalogVendorDisplayCard />
                    </Carousel.Slide>
                  ))}
                  loading
                />
              ) : (
                <VendorCatalogCarousel
                  items={state.catalogVendors.value.map((vendor) => (
                    <Carousel.Slide key={vendor.pkEcomVendor}>
                      <VendorCatalogVendorDisplayCard
                        baseUrl={baseUrl}
                        ecomVendor={vendor}
                      />
                    </Carousel.Slide>
                  ))}
                />
              )
            ) : (
              <Grid columns={vendorGridColumns} gutter={10}>
                {explorerLoading
                  ? Array.from(Array(vendorGridColumns)).map((x, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid.Col key={i} span={1}>
                        <VendorCatalogVendorDisplayCard />
                      </Grid.Col>
                    ))
                  : state.catalogVendors.value
                      .slice(0, vendorGridColumns)
                      .map((vendor) => (
                        <Grid.Col key={vendor.pkEcomVendor} span={1}>
                          <VendorCatalogVendorDisplayCard
                            baseUrl={baseUrl}
                            ecomVendor={vendor}
                          />
                        </Grid.Col>
                      ))}
              </Grid>
            )}
          </AppStack>

          <AppStack style={{ gap: 5 }}>
            <AppFlexbox
              style={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                Trending
              </AppText>
              <Anchor
                c="dodgerblue"
                component={Link}
                to={`${baseUrl}/products/trending`}
              >
                View all
              </Anchor>
            </AppFlexbox>
            {explorerLoading ? (
              <VendorCatalogCarousel
                items={Array.from(Array(10)).map((x, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Carousel.Slide key={i}>
                    <VendorCatalogDisplayCard loading />
                  </Carousel.Slide>
                ))}
                loading
              />
            ) : (
              <VendorCatalogCarousel
                items={state.catalogSummary.value.trendingProducts.map((p) => (
                  <Carousel.Slide key={p.pkEcomVendorProduct}>
                    <VendorCatalogDisplayCard
                      baseUrl={baseUrl}
                      ecomVendorProduct={p}
                      selected={isProductSelected}
                      vendorPreview={vendorPreview}
                    />
                  </Carousel.Slide>
                ))}
              />
            )}
          </AppStack>

          <AppStack style={{ gap: 5 }}>
            <AppFlexbox
              style={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                Recently added
              </AppText>
              <Anchor
                c="dodgerblue"
                component={Link}
                to={`${baseUrl}/products/new`}
              >
                View all
              </Anchor>
            </AppFlexbox>
            <Grid columns={productGridColumns} style={{ gridGap: 10 }}>
              {explorerLoading
                ? Array.from(Array(10)).map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid.Col key={i} span={1}>
                      <VendorCatalogDisplayCard loading />
                    </Grid.Col>
                  ))
                : state.catalogSummary.value.newProducts.map((p) => (
                    <Grid.Col key={p.pkEcomVendorProduct} span={1}>
                      <VendorCatalogDisplayCard
                        baseUrl={baseUrl}
                        ecomVendorProduct={p}
                        selected={isProductSelected}
                        vendorPreview={vendorPreview}
                      />
                    </Grid.Col>
                  ))}
            </Grid>
            <AppStack
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20
              }}
            >
              <Button
                color="dark"
                component={Link}
                to={`${baseUrl}/products`}
                variant="filled"
              >
                View all products
              </Button>
            </AppStack>
          </AppStack>
        </>
      ) : (
        <VendorCatalogProductSearchList
          baseUrl={baseUrl}
          loading={explorerLoading}
          vendorPreview={vendorPreview}
        />
      )}
    </AppStack>
  );
};

VendorCatalogExplorer.propTypes = {
  baseUrl: PropTypes.string,
  loading: PropTypes.bool,
  vendorPreview: PropTypes.bool
};

export default VendorCatalogExplorer;
