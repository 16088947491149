import { Message } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsTextLinkBlock from './WsTextLinkBlock';

const configNameValues = {
  text: 'text',
  fkEcomStorePageType: 'fkEcomStorePageType',
  ecomResourceId: 'ecomResourceId',
  customUrl: 'customUrl'
};

const configDefaultValues = {
  [configNameValues.text]: 'Announcement message',
  [configNameValues.fkEcomStorePageType]: '',
  [configNameValues.ecomResourceId]: '',
  [configNameValues.customUrl]: ''
};

const configOptions = {
  [configNameValues.text]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Text',
    isTextArea: true
  },
  [configNameValues.fkEcomStorePageType]: {
    type: INPUT_CONTROL_TYPE_ENUM.LINK,
    label: 'Link',
    getOnChangeValues: ({ fkEcomStorePageType, ecomResourceId, customUrl }) => [
      {
        key: configNameValues.fkEcomStorePageType,
        value: fkEcomStorePageType?.toString() ?? ''
      },
      {
        key: configNameValues.ecomResourceId,
        value: ecomResourceId?.toString() ?? ''
      },
      {
        key: configNameValues.customUrl,
        value: customUrl ?? ''
      }
    ],
    getValueProps: (config) => ({
      [configNameValues.fkEcomStorePageType]:
        config[configNameValues.fkEcomStorePageType],
      [configNameValues.ecomResourceId]:
        config[configNameValues.ecomResourceId],
      [configNameValues.customUrl]: config[configNameValues.customUrl]
    })
  }
};

const config = {
  title: 'Text link',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT_LINK,
  icon: Message,
  Component: WsTextLinkBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configNameValues, configDefaultValues };
