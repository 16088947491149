import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import MediaDisplayCard from './MediaDisplayCard';

const SortableMedia = ({ id, isMain, ...rest }) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <MediaDisplayCard
      ref={setNodeRef}
      isMain={isMain}
      style={style}
      {...rest}
      {...attributes}
      {...listeners}
    />
  );
};

SortableMedia.propTypes = {
  file: PropTypes.object,
  id: PropTypes.number,
  isMain: PropTypes.bool,
  showPlaceholder: PropTypes.bool
};

export default SortableMedia;
