import React, { useState } from 'react';
import { Image } from '@mantine/core';
import PropTypes from 'prop-types';
import { BuildingStore } from 'tabler-icons-react';
import { Carousel } from '@mantine/carousel';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const ProductImageShowcase = ({ images, productAlreadyInStore, maxWidth }) => {
  const [imageState, setImageState] = useState({ index: 0 });
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const imagesSorted = images.sort((a, b) => a.order - b.order);

  const slidesToScroll = isTabletOrSmaller ? 4 : 5;

  return (
    <AppFlexbox style={{ flex: 1, maxWidth }}>
      {images.length > 0 && (
        <AppStack
          style={{
            gap: 8,
            paddingTop: images.length > slidesToScroll ? 36 : 0,
            paddingBottom: images.length > slidesToScroll ? 36 : 0
          }}
        >
          <Carousel
            align="start"
            controlSize={35}
            draggable={images.length > slidesToScroll}
            orientation="vertical"
            slideGap={10}
            slideSize={isTabletOrSmaller ? '25%' : '20%'}
            slidesToScroll={slidesToScroll}
            styles={{
              controls: {
                top: -50,
                bottom: -50
              },
              root: {
                height: '100%',
                maxHeight: isTabletOrSmaller ? 280 : 350
              },
              viewport: { height: '100%' }
            }}
            withControls={images.length > slidesToScroll}
          >
            {imagesSorted.map((image, index) => (
              <Carousel.Slide
                key={image.pkEcomVendorProductMedia}
                style={{ padding: '5px 2px' }}
              >
                <AppFlexbox
                  onClick={() => setImageState({ index })}
                  onMouseEnter={() => setImageState({ index })}
                  style={{
                    height: 60,
                    width: 60,
                    overflow: 'hidden',
                    outline:
                      index === imageState.index
                        ? 'solid 2px dodgerblue'
                        : null,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 10
                  }}
                >
                  <Image fit="contain" h="100%" src={image.src} w="100%" />
                </AppFlexbox>
              </Carousel.Slide>
            ))}
          </Carousel>
        </AppStack>
      )}

      <AppFlexbox
        style={{
          justifyContent: 'center',
          alignSelf: 'start',
          width: '100%',
          position: 'relative'
        }}
      >
        <AppStack style={{ width: '100%' }}>
          <Image
            fit="contain"
            h="100%"
            src={imagesSorted[imageState.index]?.src}
            w="100%"
          />
        </AppStack>

        {productAlreadyInStore && (
          <>
            <AppStack
              style={{
                position: 'absolute',
                top: 0,
                backgroundColor: '#000',
                width: '100%',
                height: '100%',
                opacity: 0.7
              }}
            />
            <AppStack
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                gap: 10
              }}
            >
              <BuildingStore color="#FFF" size={80} />
              <AppText style={{ fontSize: 24, color: '#FFF', fontWeight: 500 }}>
                Added to store
              </AppText>
            </AppStack>
          </>
        )}
      </AppFlexbox>
    </AppFlexbox>
  );
};

ProductImageShowcase.propTypes = {
  images: PropTypes.array,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  productAlreadyInStore: PropTypes.bool
};

export default ProductImageShowcase;
