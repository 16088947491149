/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@mantine/core';
import AppStack from '../../../../common/AppStack';
import placeholderImage from '../../../../../images/sportsheadz-logo.png';
import AppFlexbox from '../../../../common/AppFlexbox';

const WsImageBlock = ({ config, ecomStoreTheme, fit }) => (
  <AppFlexbox
    style={{
      height: '100%',
      width: '100%',
      justifyContent: config.imageAlignment || 'center'
    }}
  >
    <AppStack
      style={{
        height: '100%',
        width: '100%',
        maxWidth: config.imageWidth ? config.imageWidth * 1 : 'unset',
        flex: 1,
        justifyContent: 'center'
      }}
    >
      <Image
        fit={fit ?? 'contain'}
        h="100%"
        loading="lazy"
        src={config.image || ecomStoreTheme?.logoImageUrl || placeholderImage}
        style={{ flex: 1 }}
        w="100%"
      />
    </AppStack>
  </AppFlexbox>
);

WsImageBlock.propTypes = {
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  fit: PropTypes.string
};

export default WsImageBlock;
