/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsProductCard from '../../sections/productGrid/WsProductCard';

const WsProductBlock = ({ config, colorScheme, onNavigate, baseStoreUrl }) => {
  const { state } = useContext(StoreUserContext);
  const ecomStoreProduct = state.ecomStoreProductHistory.value.find(
    (c) => c.uuid === config.ecomStoreProduct
  );
  const productNotFound = state.ecomStoreProductHistory.uuidsNotFound.includes(
    config.ecomStoreProduct
  );

  return (
    <WsProductCard
      baseStoreUrl={baseStoreUrl}
      colorScheme={colorScheme}
      ecomStoreProduct={ecomStoreProduct}
      noProduct={!config.ecomStoreProduct || productNotFound}
      onNavigate={onNavigate}
      showSecondImageOnHover={config.showSecondImageOnHover === 'true'}
      showVendorName={config.showVendorName === 'true'}
    />
  );
};

WsProductBlock.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  onNavigate: PropTypes.func
};

export default WsProductBlock;
