import React from 'react';
import PropTypes from 'prop-types';
import { useHover } from '@mantine/hooks';
import AppFlexbox from './AppFlexbox';
import * as classes from '../../styles/animations.module.css';

const AppMarquee = ({ scrollSpeed, reverse, pauseOnHover, sections }) => {
  const { hovered, ref: hoverRef } = useHover();

  return (
    <AppFlexbox
      ref={hoverRef}
      className={classes['marquee-container']}
      style={{ flex: 1, width: '100%', height: 25 }}
    >
      <AppFlexbox
        className={classes['marquee-content']}
        style={{
          animationDuration: `${scrollSpeed ?? 10}s`,
          animationDirection: reverse ? 'reverse' : 'normal',
          animationPlayState: hovered && pauseOnHover ? 'paused' : 'running'
        }}
      >
        {sections.map((s, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={i}>{s}</React.Fragment>
        ))}
      </AppFlexbox>

      <AppFlexbox
        aria-hidden="true"
        className={classes['marquee-content']}
        style={{
          animationDuration: `${scrollSpeed ?? 10}s`,
          animationDirection: reverse ? 'reverse' : 'normal',
          animationPlayState: hovered && pauseOnHover ? 'paused' : 'running'
        }}
      >
        {sections.map((s, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={i}>{s}</React.Fragment>
        ))}
      </AppFlexbox>
    </AppFlexbox>
  );
};

AppMarquee.propTypes = {
  pauseOnHover: PropTypes.bool,
  reverse: PropTypes.bool,
  scrollSpeed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sections: PropTypes.array
};

export default AppMarquee;
