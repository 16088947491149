import React from 'react';
import PropTypes from 'prop-types';
import { Anchor } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import AppText from './AppText';
import AppFlexbox from './AppFlexbox';

const AppLink = ({
  label,
  color,
  weight,
  to,
  isActive,
  component,
  icon,
  ...rest
}) => {
  const { hovered, ref: hoverRef } = useHover();

  return (
    <Anchor
      ref={hoverRef}
      component={component}
      to={to}
      {...rest}
      style={{ textDecoration: 'none', ...rest.style }}
    >
      <AppFlexbox
        style={{
          color,
          flex: 1,
          gap: 5,
          alignItems: 'center',
          fontWeight: weight || 'normal',
          textDecoration: hovered || isActive ? 'underline' : 'none',
          boxSizing: 'border-box'
        }}
      >
        <AppText style={{ fontSize: 14 }}>{label}</AppText>
        {icon}
      </AppFlexbox>
    </Anchor>
  );
};

AppLink.propTypes = {
  color: PropTypes.string,
  component: PropTypes.any,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  to: PropTypes.string,
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AppLink;
