import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import VendorOrderTable from './VendorOrderTable';
import VendorOrdersBulkTable from './VendorOrdersBulkTable';
import VendorOrderDetailsView from '../orders/VendorOrderDetailsView';
import VendorOrderFulfillmentView from '../orders/VendorOrderFulfillmentView';
import { triggerNotification } from '../../../helpers/notification';

const VendorOrderView = () => {
  const hasFetched = useRef(false);
  const { pkEcomStoreCart } = useParams();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchVendorOrder } = useContext(VendorContext);

  const loading = !hasFetched.current || state.ecomVendorOrder.loading;
  const ecomVendorOrder =
    pkEcomStoreCart === state.ecomVendorOrder.value?.pkEcomStoreCart.toString()
      ? state.ecomVendorOrder.value
      : null;

  useEffect(() => {
    if (authState.pkEcomVendor && pkEcomStoreCart) {
      fetchVendorOrder(
        authState.pkEcomVendor,
        { pkEcomStoreCart },
        null,
        (e) => {
          triggerNotification(e);
        }
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor, pkEcomStoreCart]);

  return (
    <Routes>
      <Route
        element={
          <VendorOrderFulfillmentView
            ecomVendorOrder={ecomVendorOrder}
            loading={loading}
          />
        }
        path="/fulfill"
      />
      <Route
        element={
          <VendorOrderDetailsView
            ecomVendorOrder={ecomVendorOrder}
            loading={loading}
          />
        }
        path="/"
      />
      <Route
        element={<Navigate replace to={`/vendor/orders/${pkEcomStoreCart}`} />}
        path="*"
      />
    </Routes>
  );
};

const VendorOrdersView = () => {
  const { state } = useContext(AuthContext);

  return (
    <Routes>
      <Route element={<VendorOrdersBulkTable />} path="/bulk" />
      <Route element={<></>} path="/bulk/:pkEcomStoreCart/*" />
      <Route element={<VendorOrderView />} path="/:pkEcomStoreCart/*" />
      <Route element={<VendorOrderTable />} path="/" />
      <Route element={<Navigate replace to="/vendor/orders" />} path="*" />
    </Routes>
  );
};

export default VendorOrdersView;
