import React from 'react';
import { Divider, NumberFormatter, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const OrderStatsCard = ({ loading, loadingCount, data }) => {
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();

  return !loading ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 16 }} withBorder>
      <AppFlexbox
        style={{ flexDirection: isLargeMobileOrSmaller ? 'column' : 'row' }}
      >
        {data.map((d, index) => (
          <React.Fragment key={d.label}>
            {index > 0 && (
              <Divider
                orientation={isLargeMobileOrSmaller ? 'horizontal' : 'vertical'}
              />
            )}
            <AppStack
              style={{ gap: 0, flex: 1, justifyContent: 'space-between' }}
            >
              <AppText style={{ fontSize: 14, color: '#666' }}>
                {d.label}
              </AppText>
              {d.type === 'string' ? (
                <AppText style={{ fontSize: 24, fontWeight: 500 }}>
                  {d.value}
                </AppText>
              ) : (
                <NumberFormatter
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  style={{ fontSize: 24, fontWeight: 500 }}
                  thousandSeparator
                  value={d.value}
                />
              )}
            </AppStack>
          </React.Fragment>
        ))}
      </AppFlexbox>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 16 }} withBorder>
      <AppFlexbox
        style={{ flexDirection: isLargeMobileOrSmaller ? 'column' : 'row' }}
      >
        {Array.from(Array(loadingCount ?? 4)).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {index > 0 && (
              <Divider
                orientation={isLargeMobileOrSmaller ? 'horizontal' : 'vertical'}
              />
            )}
            <AppStack style={{ gap: 12, flex: 1 }}>
              <Skeleton height={14} width="50%" />
              <Skeleton height={32} width="70%" />
            </AppStack>
          </React.Fragment>
        ))}
      </AppFlexbox>
    </AppCard>
  );
};

OrderStatsCard.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  loadingCount: PropTypes.number
};

export default OrderStatsCard;
