import React from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Anchor, Button, Skeleton } from '@mantine/core';
import { AlertTriangle, ArrowLeft } from 'tabler-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppImage from '../../common/AppImage';
import sportsHeadzLogo from '../../../images/sportsheadz-logo.png';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const AuthCardView = ({
  title,
  error,
  onSubmit,
  children,
  noBackButtton,
  logoImage,
  noLogo,
  titleAction,
  viewLoading,
  cardProps
}) => {
  const navigate = useNavigate();
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();

  return (
    <>
      {!noBackButtton && (
        <Button
          color="white"
          leftSection={<ArrowLeft size={18} />}
          onClick={() => navigate('/')}
          variant="outline"
          visibleFrom="xsm"
        >
          Back to home
        </Button>
      )}

      <AppCard
        radius={isLargeMobileOrSmaller ? 0 : 'lg'}
        shadow="xl"
        withBorder
        {...cardProps}
        flex={{ base: 1, xsm: 'unset' }}
        mah={{ base: '100vh', xsm: 'unset' }}
        maw={{ base: '100%', xsm: 475 }}
        mih={{ base: '100vh', xsm: 'unset' }}
        style={{
          height: '100%',
          width: '100vw',
          padding: 0,
          overflow: 'auto',
          ...cardProps?.style
        }}
      >
        {!noLogo && (
          <AppStack
            mt={{ base: 40, xsm: 0 }}
            p={{ base: 20, xsm: 40 }}
            style={{ paddingBottom: 20 }}
          >
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppFlexbox
                component={Link}
                style={{
                  alignItems: 'center',
                  textDecoration: 'none',
                  gap: 5,
                  maxWidth: 240
                }}
                to="/"
              >
                <AppStack style={{ minWidth: 40, minHeight: 40 }}>
                  <AppImage
                    alt="SportsHeadz Main Logo"
                    fit="contain"
                    height={40}
                    src={logoImage ?? sportsHeadzLogo}
                    style={{ minWidth: 'unset', maxWidth: 125 }}
                    width="100%"
                  />
                </AppStack>

                <AppText
                  style={{
                    fontSize: isLargeMobileOrSmaller ? 22 : 18,
                    color: '#000',
                    fontWeight: 700
                  }}
                >
                  <em>SportsHeadz</em>
                </AppText>
              </AppFlexbox>
            </AppFlexbox>
          </AppStack>
        )}

        {error && (
          <AppStack
            p={{ base: 20, xsm: 40 }}
            style={{ paddingTop: 0, paddingBottom: 20 }}
          >
            <Alert
              color="#c40000"
              icon={<AlertTriangle style={{ height: 28, width: 28 }} />}
              radius={10}
              styles={{
                body: { gap: 5 },
                icon: { height: 28, width: 28 },
                title: { fontSize: 17, fontWeight: 500 }
              }}
              title="There was a problem"
              variant="outline"
            >
              <AppText style={{ fontSize: 13 }}>{error}</AppText>
            </Alert>
          </AppStack>
        )}

        {title && (
          <AppStack p={{ base: '0px 20px', xsm: '0px 40px' }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontWeight: 500, fontSize: 26 }}>
                {title}
              </AppText>

              {titleAction}
            </AppFlexbox>
          </AppStack>
        )}

        {viewLoading ? (
          <AppStack p={{ base: 20, xsm: 40 }} style={{ gap: 10, flex: 1 }}>
            <AppFlexbox style={{ padding: 8, gap: 10, alignItems: 'center' }}>
              <Skeleton height={38} radius={100} width={38} />
              <Skeleton height={12} width={100} />
            </AppFlexbox>
            <AppFlexbox style={{ padding: 8, gap: 10, alignItems: 'center' }}>
              <Skeleton height={38} radius={100} width={38} />
              <Skeleton height={12} width={100} />
            </AppFlexbox>
          </AppStack>
        ) : (
          <AppStack
            component="form"
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onSubmit();
            }}
            style={{ flex: 1 }}
          >
            {children}
          </AppStack>
        )}
        <AppFlexbox
          p={{ base: 20, xsm: 40 }}
          style={{ gap: 20, paddingTop: 0 }}
        >
          <Anchor
            href="https://www.sportsheadz.com/privacy-policy"
            style={{ fontSize: 12, color: '#666' }}
            target="_blank"
          >
            Privacy
          </Anchor>
          <Anchor
            href="https://support.sportsheadz.com/"
            style={{ fontSize: 12, color: '#666' }}
            target="_blank"
          >
            Help
          </Anchor>
        </AppFlexbox>
      </AppCard>
    </>
  );
};

AuthCardView.propTypes = {
  cardProps: PropTypes.object,
  children: PropTypes.node,
  error: PropTypes.string,
  logoImage: PropTypes.string,
  noBackButtton: PropTypes.bool,
  noLogo: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  titleAction: PropTypes.node,
  viewLoading: PropTypes.bool
};

export default AuthCardView;
