import React from 'react';
import { useHover } from '@mantine/hooks';
import { Image } from '@mantine/core';
import PropTypes from 'prop-types';
import { BuildingStore } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const ProductImageDisplay = ({
  previewImages,
  onlyShowFirstPhoto,
  productAlreadyInStore
}) => {
  const { hovered, ref: hoverRef } = useHover();
  const showSecondImage =
    !productAlreadyInStore &&
    !onlyShowFirstPhoto &&
    hovered &&
    previewImages.length > 1;

  const images = previewImages ?? [];
  const secondImage = images[1];

  return (
    <AppStack
      ref={hoverRef}
      style={{
        gap: 10,
        flex: 1,
        padding: 0,
        height: '100%',
        cursor: 'pointer'
      }}
    >
      <AppStack
        style={{
          border: !images[0] ? 'solid 1px lightgrey' : 'none',
          position: 'relative',
          width: '100%',
          height: '100%',
          backgroundColor: '#f2f2f2',
          overflow: 'hidden',
          aspectRatio: '1 / 1'
        }}
      >
        <Image
          fit="cover"
          h="100%"
          loading="lazy"
          src={images[0]}
          style={{ opacity: 0 }}
          w="100%"
        />
        <Image
          fit="cover"
          h="100%"
          loading="lazy"
          src={images[0]}
          style={{
            opacity: showSecondImage ? 0 : 1,
            transition: 'opacity 0.4s ease-in-out',
            position: 'absolute'
          }}
          w="100%"
        />
        <Image
          fit="cover"
          h="100%"
          loading="lazy"
          src={secondImage}
          style={{
            opacity: showSecondImage ? 1 : 0,
            transition: 'opacity 0.4s ease-in-out',
            position: 'absolute'
          }}
          w="100%"
        />
        {productAlreadyInStore && (
          <>
            <AppStack
              style={{
                position: 'absolute',
                top: 0,
                backgroundColor: '#000',
                width: '100%',
                height: '100%',
                opacity: 0.7
              }}
            />
            <AppStack
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                gap: 10
              }}
            >
              <BuildingStore color="#FFF" size={50} />
              <AppText style={{ fontSize: 16, color: '#FFF', fontWeight: 500 }}>
                Added to store
              </AppText>
            </AppStack>
          </>
        )}
      </AppStack>
    </AppStack>
  );
};

ProductImageDisplay.propTypes = {
  onlyShowFirstPhoto: PropTypes.bool,
  previewImages: PropTypes.array,
  productAlreadyInStore: PropTypes.bool
};

export default ProductImageDisplay;
