import {
  Home,
  Lock,
  Receipt,
  Search,
  ShoppingBag,
  ShoppingCart,
  Tag,
  Tags,
  Template,
  User
} from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_PAGE_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM
} from '../../../../config/constants';
import { ECOM_WEBSTORE_SECTIONS } from '../sections/wsSectionConfig';
import { sterilizeUrlHandle } from '../../../../helpers/format';

const ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM = {
  MAIN: 1,
  USER: 2,
  // PAGE: 3,
  NAVIGATION: 4
};

const ECOM_WEBSTORE_PAGE_SUB_CATEGORIES = {
  CUSTOMER_ACCOUNTS: {
    title: 'Customer accounts',
    icon: User,
    value: 1
  }
};

const ECOM_WEBSTORE_PAGE_CONFIG = {
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.HOME]: {
    title: 'Home page',
    sort: 1,
    icon: Home,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.HOME,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.MAIN,
    path: '/',
    canAssignMenuItem: true,
    isSelected: (pathname) =>
      pathname === '' || pathname === '/' || pathname.startsWith('/home'),
    requiredSections: [],
    defaultSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.FEATURED_COLLECTION
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ]
  },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.PRODUCT]: {
    title: 'Products',
    sort: 2,
    icon: Tag,
    canCreateTemplate: false,
    canAssignResource: true,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.PRODUCT,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.MAIN,
    path: '/products/{id}',
    canAssignMenuItem: true,
    isSelected: (pathname) => pathname.startsWith('/products/'),
    getResourceOptions: (data) =>
      data.ecomStoreProducts.map((d) => ({
        label: d.name,
        value: d.uuid,
        image: d.previewImage,
        showImage: true
      })),
    getResourceValue: (resourceId, ecomStoreTheme) =>
      ecomStoreTheme.ecomStore.ecomStoreProducts.find(
        (d) => d.uuid === resourceId
      )?.urlHandle ?? '',
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.PRODUCT_INFORMATION
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.COLLECTION]: {
    title: 'Collections',
    sort: 4,
    icon: Tags,
    canCreateTemplate: false,
    canAssignResource: true,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.COLLECTION,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.MAIN,
    path: '/collections/{id}',
    canAssignMenuItem: true,
    isSelected: (pathname) =>
      pathname.startsWith('/collections/') || pathname === '/products',
    getResourceOptions: (data) =>
      data.ecomStoreCollections.map((d) => ({
        label: d.name,
        value: d.pkEcomStoreCollection.toString()
      })),
    getResourceValue: (resourceId, ecomStoreTheme) =>
      sterilizeUrlHandle(
        ecomStoreTheme.ecomStore.ecomStoreCollections.find(
          (d) => d.pkEcomStoreCollection.toString() === resourceId
        )?.name ?? ''
      ),
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.RESOURCE_BANNER
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      },
      {
        config:
          ECOM_WEBSTORE_SECTIONS[ECOM_WEBSTORE_SECTION_TYPE_ENUM.PRODUCT_GRID],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.COLLECTION_LIST]: {
    title: 'Collections list',
    sort: 5,
    icon: Tags,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.COLLECTION_LIST,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.MAIN,
    path: '/collections',
    isSelected: (pathname) => pathname === '/collections',
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLECTION_GRID
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.CART]: {
    title: 'Cart',
    sort: 6,
    icon: ShoppingBag,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.CART,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.USER,
    path: '/checkout/cart',
    isSelected: (pathname) => pathname === '/checkout/cart',
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.CHECKOUT_CONTENT
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  // [ECOM_WEBSTORE_PAGE_TYPE_ENUM.CHECKOUT]: {
  //   title: 'Checkout',
  //   sort: 7,
  //   icon: ShoppingCart,
  //   type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.CHECKOUT,
  //   categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.USER,
  //   path: '/checkout',
  //   isSelected: (pathname) => pathname === '/checkout',
  //   requiredSections: [
  //     {
  //       config:
  //         ECOM_WEBSTORE_SECTIONS[
  //           ECOM_WEBSTORE_SECTION_TYPE_ENUM.ACCOUNT_CONTENT
  //         ],
  //       location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
  //     }
  //   ],
  //   defaultSections: []
  // },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_LOGIN]: {
    title: 'Login',
    sort: 8,
    icon: User,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_LOGIN,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.USER,
    subCategory: ECOM_WEBSTORE_PAGE_SUB_CATEGORIES.CUSTOMER_ACCOUNTS,
    path: '/account/login',
    isSelected: (pathname) => pathname.startsWith('/account/login'),
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.ACCOUNT_CONTENT
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_REGISTER]: {
    title: 'Register',
    sort: 9,
    icon: User,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_REGISTER,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.USER,
    subCategory: ECOM_WEBSTORE_PAGE_SUB_CATEGORIES.CUSTOMER_ACCOUNTS,
    path: '/account/register',
    isSelected: (pathname) => pathname.startsWith('/account/register'),
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.ACCOUNT_CONTENT
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_ACCOUNT]: {
    title: 'Account details',
    sort: 9,
    icon: User,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_ACCOUNT,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.USER,
    subCategory: ECOM_WEBSTORE_PAGE_SUB_CATEGORIES.CUSTOMER_ACCOUNTS,
    path: '/account/details',
    isSelected: (pathname) => pathname.startsWith('/account/details'),
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.ACCOUNT_CONTENT
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_ORDERS]: {
    title: 'Order history',
    sort: 10,
    icon: Receipt,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_ORDERS,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.USER,
    subCategory: ECOM_WEBSTORE_PAGE_SUB_CATEGORIES.CUSTOMER_ACCOUNTS,
    path: '/account/orders',
    isSelected: (pathname) =>
      pathname.startsWith('/account/orders') &&
      !pathname.startsWith('/account/orders/details'),
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.ACCOUNT_CONTENT
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_ORDER_DETAILS]: {
    title: 'Order details',
    sort: 11,
    icon: Receipt,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.CUSTOMER_ORDER_DETAILS,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.USER,
    subCategory: ECOM_WEBSTORE_PAGE_SUB_CATEGORIES.CUSTOMER_ACCOUNTS,
    path: '/account/orders/details',
    isSelected: (pathname) => pathname.startsWith('/account/orders/details'),
    requiredSections: [
      {
        config:
          ECOM_WEBSTORE_SECTIONS[
            ECOM_WEBSTORE_SECTION_TYPE_ENUM.ACCOUNT_CONTENT
          ],
        location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
      }
    ],
    defaultSections: []
  },
  // [ECOM_WEBSTORE_PAGE_TYPE_ENUM.PAGE]: {
  //   title: 'Pages',
  //   sort: 12,
  //   icon: File,
  //   canCreateTemplate: true,
  //   type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.PAGE,
  //   categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.PAGE,
  //   path: '/pages/{id}',
  //   canAssignMenuItem: true,
  //   isSelected: (pathname) => pathname.startsWith('/pages/'),
  //   requiredSections: [],
  //   defaultSections: []
  // },
  // [ECOM_WEBSTORE_PAGE_TYPE_ENUM.BLOG]: {
  //   title: 'Blog',
  //   sort: 13,
  //   icon: File,
  //   canCreateTemplate: true,
  //   type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.BLOG,
  //   categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.PAGE,
  //   path: '/blogs/{id}',
  //   canAssignMenuItem: true,
  //   isSelected: (pathname) => pathname.startsWith('/blog/'),
  //   requiredSections: [],
  //   defaultSections: []
  // },
  // [ECOM_WEBSTORE_PAGE_TYPE_ENUM.BLOG_POST]: {
  //   title: 'Blog post',
  //   sort: 14,
  //   icon: File,
  //   canCreateTemplate: true,
  //   type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.BLOG_POST,
  //   categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.PAGE,
  //   path: '/blogs/{id}',
  //   canAssignMenuItem: true,
  //   isSelected: (pathname) => pathname.startsWith('/blog/'),
  //   requiredSections: [],
  //   defaultSections: []
  // },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.SEARCH]: {
    title: 'Search',
    sort: 15,
    icon: Search,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.SEARCH,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.NAVIGATION,
    path: '/search',
    canAssignMenuItem: true,
    isSelected: (pathname) => pathname === '/search',
    requiredSections: [],
    defaultSections: []
  },
  // [ECOM_WEBSTORE_PAGE_TYPE_ENUM.PASSWORD]: {
  //   title: 'Password',
  //   sort: 16,
  //   icon: Lock,
  //   type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.PASSWORD,
  //   categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.NAVIGATION,
  //   path: '/password',
  //   isSelected: (pathname) => pathname === '/password',
  //   requiredSections: [],
  //   defaultSections: []
  // },
  [ECOM_WEBSTORE_PAGE_TYPE_ENUM.NOT_FOUND]: {
    title: '404 page (Not found)',
    sort: 17,
    icon: Template,
    type: ECOM_WEBSTORE_PAGE_TYPE_ENUM.NOT_FOUND,
    categoryType: ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM.NAVIGATION,
    path: '/404',
    isSelected: (pathname) => pathname === '/404',
    requiredSections: [],
    defaultSections: []
  }
};

const ECOM_WEBSTORE_PAGE_CONFIG_LIST = Object.keys(ECOM_WEBSTORE_PAGE_CONFIG)
  .reduce((r, c) => [...r, ECOM_WEBSTORE_PAGE_CONFIG[c]], [])
  .sort((a, b) => a.sort - b.sort);

export {
  ECOM_WEBSTORE_PAGE_CATEGORY_TYPE_ENUM,
  ECOM_WEBSTORE_PAGE_SUB_CATEGORIES,
  ECOM_WEBSTORE_PAGE_CONFIG,
  ECOM_WEBSTORE_PAGE_CONFIG_LIST
};
