import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import WebstoreBuilderHeader from './WebstoreBuilderHeader';
import AppCard from '../../common/AppCard';
import WebstoreBuilderConfigSidebar from './WebstoreBuilderConfigSidebar';
import { ECOM_WEBSTORE_SECTIONS } from './sections/wsSectionConfig';
import WsApp from './WsApp';
import {
  ECOM_WEBSTORE_PAGE_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE
} from '../../../config/constants';
import { ECOM_WEBSTORE_PAGE_CONFIG_LIST } from './pages/wsPageConfig';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import {
  createEcomStoreThemeSection,
  createEcomStoreThemeSectionBlock,
  getWebstoreThemeConfig
} from '../../../helpers/webstoreBuilderHelper';
import { usePrompt } from '../../../helpers/prompt';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import {
  SIDEBAR_VIEW_ENUM,
  VIEW_SCREEN_RATIO_ENUM,
  WEBSTORE_SETTING_TYPE_ENUM
} from './wsAppSettingConfig';
import WebstoreBuilderSectionManager from './WebstoreBuilderSectionManager';
import WebstoreBuilderViewAppContainer from './WebstoreBuilderViewAppContainer';

const WebstoreBuilderView = () => {
  const hasInitialized = useRef(false);
  const fetchedPkEcomStore = useRef(null);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { isLaptopOrSmaller } = useMediaQueryIndex();
  const {
    state,
    updateStoreTheme,
    fetchStoreResourceOptions,
    fetchStoreMedia
  } = useContext(StoreContext);
  const [builderAuthState, setBuilderAuthState] = useState({
    isAuthenticated: false
  });
  const [formState, setFormState] = useState({
    editState: {
      logoImageUrl: '',
      ecomStoreThemeConfig: {},
      ecomStoreThemeColorSchemes: [],
      ecomStoreThemeSections: [],
      hasUnsavedChanges: false,
      loading: false
    },
    controlState: {
      selectedPageType: ECOM_WEBSTORE_PAGE_TYPE_ENUM.HOME,
      selectedSection: null,
      selectedSectionBlock: null,
      selectedSetting: null,
      selectedSettingItem: null,
      openSections: []
    },
    viewState: {
      isAuthenticated: false,
      inspectEnabled: true,
      sidebarView: SIDEBAR_VIEW_ENUM.template,
      screenRatio: 'desktop'
    }
  });
  const currentViewPath = searchParams.get('previewPath');
  const { value: ecomStoreTheme } = state.ecomStoreTheme;
  const isLoading =
    !hasInitialized.current || state.ecomStoreTheme.loading || !ecomStoreTheme;

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    formState.editState.hasUnsavedChanges
  );

  useEffect(() => {
    if (ecomStoreTheme) {
      const ecomStoreThemeConfig = getWebstoreThemeConfig(ecomStoreTheme);
      setFormState({
        ...formState,
        editState: {
          logoImageUrl: ecomStoreThemeConfig.logoImageUrl,
          ecomStoreThemeConfig: ecomStoreThemeConfig.ecomStoreThemeConfig,
          ecomStoreThemeColorSchemes:
            ecomStoreThemeConfig.ecomStoreThemeColorSchemes,
          ecomStoreThemeSections: ecomStoreThemeConfig.ecomStoreThemeSections,
          hasUnsavedChanges: false,
          loading: false
        }
      });

      if (fetchedPkEcomStore.current !== ecomStoreTheme.fkEcomStore) {
        fetchStoreResourceOptions(ecomStoreTheme.fkEcomStore);
        fetchStoreMedia({ fkEcomStore: ecomStoreTheme.fkEcomStore });
      }
      fetchedPkEcomStore.current = ecomStoreTheme.fkEcomStore;
      hasInitialized.current = true;
    }
  }, [ecomStoreTheme]);

  useEffect(() => {
    if (currentViewPath != null) {
      const selectedPageType =
        ECOM_WEBSTORE_PAGE_CONFIG_LIST.find((pageConfig) =>
          pageConfig.isSelected(currentViewPath.split('?')[0])
        )?.type ?? ECOM_WEBSTORE_PAGE_TYPE_ENUM.NOT_FOUND;

      setFormState((c) => ({
        ...c,
        controlState: {
          ...c.controlState,
          selectedPageType,
          selectedSection: null,
          selectedSectionBlock: null
        }
      }));
    }
  }, [currentViewPath]);

  const onAuthenticateEditMode = (isAuthenticated) => {
    setBuilderAuthState({
      ...builderAuthState,
      isAuthenticated
    });
  };

  const onSelectSetting = (key) => {
    setFormState({
      ...formState,
      controlState: {
        ...formState.controlState,
        selectedSetting:
          formState.controlState.selectedSetting === key ? null : key,
        selectedSettingItem: null
      }
    });
  };

  const onSelectSettingItem = (blockKey, settingKey) => {
    setFormState({
      ...formState,
      controlState: {
        ...formState.controlState,
        selectedSetting: settingKey,
        selectedSettingItem: blockKey
      }
    });
  };

  const onChangeSetting = (key, value) => {
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        ecomStoreThemeConfig: {
          ...formState.editState.ecomStoreThemeConfig,
          [key]: value
        },
        hasUnsavedChanges: true
      }
    });
  };

  const onChangeColorScheme = (colorScheme) => {
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        ecomStoreThemeColorSchemes: formState.editState.ecomStoreThemeColorSchemes.map(
          (c) =>
            c.key === colorScheme.key
              ? {
                  ...c,
                  ...colorScheme
                }
              : c
        ),
        hasUnsavedChanges: true
      }
    });
  };

  const onChangeLogoImage = (media) => {
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        logoImageUrl: media?.src ?? '',
        hasUnsavedChanges: true
      }
    });
  };

  const onAddColorScheme = (colorSchemeCopy) => {
    const colorSchemeKey = new Date().getTime();
    const copyScheme =
      colorSchemeCopy || formState.editState.ecomStoreThemeColorSchemes[0];
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        ecomStoreThemeColorSchemes: [
          ...formState.editState.ecomStoreThemeColorSchemes,
          {
            ...copyScheme,
            pkEcomStoreThemeColorScheme: null,
            key: colorSchemeKey,
            sort: formState.editState.ecomStoreThemeColorSchemes.length + 1
          }
        ],
        hasUnsavedChanges: true
      },
      controlState: {
        ...formState.controlState,
        selectedSetting: WEBSTORE_SETTING_TYPE_ENUM.COLORS,
        selectedSettingItem: colorSchemeKey
      },
      viewState: {
        ...formState.viewState,
        sidebarView: SIDEBAR_VIEW_ENUM.settings
      }
    });
  };

  const onDeleteColorScheme = (colorScheme) => {
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        ecomStoreThemeColorSchemes:
          formState.editState.ecomStoreThemeColorSchemes.length > 1
            ? [
                ...formState.editState.ecomStoreThemeColorSchemes.filter(
                  (c) => c.key !== colorScheme.key
                )
              ].map((c, index) => ({ ...c, sort: index + 1 }))
            : formState.editState.ecomStoreThemeColorSchemes,
        hasUnsavedChanges: true
      },
      controlState: {
        ...formState.controlState,
        selectedSettingItem: null
      }
    });
  };

  const onSelectSection = (key) => {
    setFormState({
      ...formState,
      controlState: {
        ...formState.controlState,
        selectedSection: key,
        selectedSectionBlock: null,
        openSections: !formState.controlState.openSections.includes(key)
          ? [...formState.controlState.openSections, key]
          : [...formState.controlState.openSections]
      }
    });
  };

  const onSelectSectionBlock = (blockKey, sectionKey) => {
    setFormState({
      ...formState,
      controlState: {
        ...formState.controlState,
        selectedSection: sectionKey,
        selectedSectionBlock: blockKey,
        openSections: !formState.controlState.openSections.includes(sectionKey)
          ? [...formState.controlState.openSections, sectionKey]
          : [...formState.controlState.openSections]
      }
    });
  };

  const onUnselectSection = () => {
    setFormState({
      ...formState,
      controlState: {
        ...formState.controlState,
        selectedSection: null,
        selectedSectionBlock: null
      }
    });
  };

  const onToggleOpenSection = (key) => {
    setFormState({
      ...formState,
      controlState: {
        ...formState.controlState,
        openSections: !formState.controlState.openSections.includes(key)
          ? [...formState.controlState.openSections, key]
          : formState.controlState.openSections.filter(
              (section) => section !== key
            )
      }
    });
  };

  const onAddSection = (
    fkEcomStoreThemeSectionType,
    fkEcomStoreThemeSectionLocationType,
    sectionToCopy
  ) => {
    const newSection = createEcomStoreThemeSection(
      formState.editState.ecomStoreThemeSections,
      fkEcomStoreThemeSectionType,
      fkEcomStoreThemeSectionLocationType,
      fkEcomStoreThemeSectionLocationType ===
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY
        ? formState.controlState.selectedPageType
        : null
    );

    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        ecomStoreThemeSections: [
          ...formState.editState.ecomStoreThemeSections.map((s) => ({
            ...s,
            sort:
              sectionToCopy &&
              s.fkEcomStoreThemeSectionLocationType ===
                sectionToCopy.fkEcomStoreThemeSectionLocationType &&
              s.fkEcomStorePageType === sectionToCopy.fkEcomStorePageType &&
              s.sort > sectionToCopy.sort
                ? s.sort + 1
                : s.sort
          })),
          {
            ...newSection,
            ...(sectionToCopy && {
              sort: sectionToCopy.sort + 1,
              hidden: sectionToCopy.hidden,
              config: { ...newSection.config, ...sectionToCopy.config },
              ecomStoreThemeSectionBlocks: sectionToCopy.ecomStoreThemeSectionBlocks.map(
                (b, i) => ({
                  key: new Date().getTime() + i,
                  hidden: b.hidden,
                  fkEcomStoreThemeSectionBlockType:
                    b.fkEcomStoreThemeSectionBlockType,
                  config: { ...b.config }
                })
              )
            })
          }
        ],
        hasUnsavedChanges: true
      },
      controlState: {
        ...formState.controlState,
        selectedSection: newSection.key,
        selectedSectionBlock: null,
        openSections: [...formState.controlState.openSections, newSection.key]
      }
    });
  };

  const onAddSectionBlock = (section, blockType, blockToCopy = null) => {
    const newBlock = createEcomStoreThemeSectionBlock(section, blockType);
    if (newBlock) {
      setFormState({
        ...formState,
        editState: {
          ...formState.editState,
          ecomStoreThemeSections: formState.editState.ecomStoreThemeSections.map(
            (s) =>
              s.key === section.key
                ? {
                    ...s,
                    ecomStoreThemeSectionBlocks: [
                      ...s.ecomStoreThemeSectionBlocks.map((b) => ({
                        ...b,
                        sort:
                          blockToCopy && b.sort > blockToCopy.sort
                            ? b.sort + 1
                            : b.sort
                      })),
                      {
                        ...newBlock,
                        ...(blockToCopy && {
                          sort: blockToCopy.sort + 1,
                          hidden: blockToCopy.hidden,
                          config: { ...newBlock.config, ...blockToCopy.config }
                        })
                      }
                    ]
                  }
                : s
          ),
          hasUnsavedChanges: true
        },
        controlState: {
          ...formState.controlState,
          selectedSection: section.key,
          selectedSectionBlock: newBlock.key
        }
      });
    }
  };

  const onRemoveSection = (section) => {
    const isSectionPermanent =
      ECOM_WEBSTORE_SECTIONS[section.fkEcomStoreThemeSectionType].isPermanent;
    const existingSectionBoundsCount = formState.editState.ecomStoreThemeSections.filter(
      (s) =>
        s.fkEcomStoreThemeSectionLocationType ===
        section.fkEcomStoreThemeSectionLocationType
    ).length;

    if (!isSectionPermanent && existingSectionBoundsCount > 1) {
      const pageLocationReordered = formState.editState.ecomStoreThemeSections
        .filter(
          (s) =>
            s.key !== section.key &&
            s.fkEcomStoreThemeSectionLocationType ===
              section.fkEcomStoreThemeSectionLocationType &&
            s.fkEcomStorePageType === section.fkEcomStorePageType
        )
        .sort((a, b) => a.sort - b.sort)
        .map((s, index) => ({ ...s, sort: index + 1 }));

      setFormState({
        ...formState,
        editState: {
          ...formState.editState,
          ecomStoreThemeSections: [
            ...formState.editState.ecomStoreThemeSections.filter(
              (s) =>
                !(
                  s.fkEcomStoreThemeSectionLocationType ===
                    section.fkEcomStoreThemeSectionLocationType &&
                  s.fkEcomStorePageType === section.fkEcomStorePageType
                )
            ),
            ...pageLocationReordered
          ],
          hasUnsavedChanges: true
        },
        controlState: {
          ...formState.controlState,
          selectedSection: null,
          selectedSectionBlock: null
        }
      });
    }
  };

  const onRemoveSectionBlock = (section, block) => {
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        ecomStoreThemeSections: formState.editState.ecomStoreThemeSections.map(
          (s) =>
            s.key === section.key
              ? {
                  ...s,
                  ecomStoreThemeSectionBlocks: s.ecomStoreThemeSectionBlocks.filter(
                    (i) => i.key !== block.key
                  )
                }
              : s
        ),
        hasUnsavedChanges: true
      },
      controlState: {
        ...formState.controlState,
        selectedSection: null,
        selectedSectionBlock: null
      }
    });
  };

  const onToggleHide = (sectionKey, blockKey = null) => {
    if (!blockKey) {
      setFormState({
        ...formState,
        editState: {
          ...formState.editState,
          ecomStoreThemeSections: formState.editState.ecomStoreThemeSections.map(
            (s) =>
              s.key === sectionKey
                ? {
                    ...s,
                    hidden: !s.hidden
                  }
                : s
          ),
          hasUnsavedChanges: true
        }
      });
    }
    else {
      setFormState({
        ...formState,
        editState: {
          ...formState.editState,
          ecomStoreThemeSections: formState.editState.ecomStoreThemeSections.map(
            (s) =>
              s.key === sectionKey
                ? {
                    ...s,
                    ecomStoreThemeSectionBlocks: s.ecomStoreThemeSectionBlocks.map(
                      (i) =>
                        i.key === blockKey
                          ? {
                              ...i,
                              hidden: !i.hidden
                            }
                          : i
                    )
                  }
                : s
          ),
          hasUnsavedChanges: true
        }
      });
    }
  };

  const onChangeSectionOrder = (sections) => {
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        hasUnsavedChanges: true,
        ecomStoreThemeSections: [
          ...formState.editState.ecomStoreThemeSections.filter(
            (s) => !sections.find((newSection) => newSection.key === s.key)
          ),
          ...sections.map((s, i) => ({ ...s, sort: i + 1 }))
        ]
      }
    });
  };

  const onChangeSectionBlockOrder = (sectionKey, blocks) => {
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        hasUnsavedChanges: true,
        ecomStoreThemeSections: formState.editState.ecomStoreThemeSections.map(
          (s) =>
            s.key === sectionKey
              ? {
                  ...s,
                  ecomStoreThemeSectionBlocks: [
                    ...blocks.map((b, i) => ({ ...b, sort: i + 1 }))
                  ]
                }
              : s
        )
      }
    });
  };

  const onChangeSectionConfigValues = (sectionKey, values) => {
    setFormState((c) => ({
      ...c,
      editState: {
        ...c.editState,
        ecomStoreThemeSections: [
          ...c.editState.ecomStoreThemeSections.map((s) =>
            s.key === sectionKey
              ? {
                  ...s,
                  config: {
                    ...s.config,
                    ...values.reduce((acc, { name, value }) => {
                      acc[name] = value;
                      return acc;
                    }, {})
                  }
                }
              : s
          )
        ],
        hasUnsavedChanges: true
      }
    }));
  };

  const onSaveTheme = () => {
    setFormState({
      ...formState,
      editState: {
        ...formState.editState,
        loading: true
      }
    });
    updateStoreTheme(
      ecomStoreTheme.pkEcomStoreTheme,
      {
        logoImageUrl: formState.editState.logoImageUrl,
        ecomStoreThemeColorSchemes:
          formState.editState.ecomStoreThemeColorSchemes,
        ecomStoreThemeSections: formState.editState.ecomStoreThemeSections.map(
          (s) => ({
            ...s,
            ecomStoreThemeSectionProperties: Object.keys(s.config).map(
              (key) => ({
                name: key,
                value: s.config[key]
              })
            ),
            ecomStoreThemeSectionBlocks: s.ecomStoreThemeSectionBlocks.map(
              (b) => ({
                ...b,
                ecomStoreThemeSectionBlockProperties: Object.keys(b.config).map(
                  (key) => ({
                    name: key,
                    value: b.config[key]
                  })
                )
              })
            )
          })
        ),
        ecomStoreThemeProperties: Object.keys(
          formState.editState.ecomStoreThemeConfig
        ).map((key) => ({
          name: key,
          value: formState.editState.ecomStoreThemeConfig[key]
        }))
      },
      () => {
        triggerNotification('Changes saved!', 'success');
      },
      (error) => {
        triggerNotification(error);
        setFormState({
          ...formState,
          editState: {
            ...formState.editState,
            loading: false
          }
        });
      }
    );
  };

  return !isLoading ? (
    <AppStack style={{ flex: 1, gap: 0 }}>
      <WebstoreBuilderHeader
        controlState={formState.controlState}
        ecomStoreTheme={ecomStoreTheme}
        editState={formState.editState}
        loading={isLoading}
        onChangeViewState={(viewState) =>
          setFormState({ ...formState, viewState })
        }
        onSaveTheme={onSaveTheme}
        onSelectPage={(pageType) =>
          setFormState({
            ...formState,
            controlState: {
              ...formState.controlState,
              selectedPageType: pageType
            }
          })
        }
        viewState={formState.viewState}
      />

      <AppFlexbox
        style={{
          flex: 1,
          gap: 0,
          position: 'relative',
          paddingBottom: isLaptopOrSmaller ? 60 : 0,
          overflow: 'hidden'
        }}
      >
        <WebstoreBuilderSectionManager
          controlState={formState.controlState}
          ecomStoreTheme={ecomStoreTheme}
          editState={formState.editState}
          loading={isLoading}
          onAddColorScheme={onAddColorScheme}
          onAddSection={onAddSection}
          onAddSectionBlock={onAddSectionBlock}
          onChangeColorScheme={onChangeColorScheme}
          onChangeLogoImage={onChangeLogoImage}
          onChangeSectionBlockOrder={onChangeSectionBlockOrder}
          onChangeSectionOrder={onChangeSectionOrder}
          onChangeSetting={onChangeSetting}
          onCopySection={(section) =>
            onAddSection(
              section.fkEcomStoreThemeSectionType,
              section.fkEcomStoreThemeSectionLocationType,
              section
            )
          }
          onCopySectionBlock={(section, block) =>
            onAddSectionBlock(
              section,
              block.fkEcomStoreThemeSectionBlockType,
              block
            )
          }
          onDeleteColorScheme={onDeleteColorScheme}
          onRemoveSection={onRemoveSection}
          onRemoveSectionBlock={onRemoveSectionBlock}
          onSelectSection={onSelectSection}
          onSelectSectionBlock={onSelectSectionBlock}
          onSelectSetting={onSelectSetting}
          onSelectSettingItem={onSelectSettingItem}
          onToggleHide={onToggleHide}
          onToggleOpenSection={onToggleOpenSection}
          onUnselectSection={onUnselectSection}
          onUpdateEditState={(editState) =>
            setFormState({
              ...formState,
              editState: {
                ...formState.editState,
                ...editState,
                hasUnsavedChanges: true
              }
            })
          }
          onViewStateChange={(viewState) =>
            setFormState({
              ...formState,
              viewState: {
                ...formState.viewState,
                ...viewState
              }
            })
          }
          viewState={formState.viewState}
        />
        <AppStack
          style={{
            flex: 1,
            padding: isLaptopOrSmaller ? 0 : 10,
            alignItems: 'center'
          }}
        >
          <WebstoreBuilderViewAppContainer
            builderAuthState={builderAuthState}
            controlState={formState.controlState}
            ecomStoreTheme={ecomStoreTheme}
            editState={formState.editState}
            onAuthenticateEditMode={onAuthenticateEditMode}
            onChangeSectionConfigValues={onChangeSectionConfigValues}
            onSelectSection={onSelectSection}
            onUnselectSection={onUnselectSection}
            viewState={formState.viewState}
          />
        </AppStack>
        <AppFlexbox visibleFrom="xlg">
          {formState.viewState.screenRatio !==
            VIEW_SCREEN_RATIO_ENUM.fullscreen && (
            <AppCard
              shadow="xs"
              style={{
                height: 'calc(100vh - 60px)',
                padding: 0,
                minWidth: 300,
                maxWidth: 300,
                position: '-webkit-sticky',
                position: 'sticky',
                flex: 1,
                gap: 0,
                backgroundColor: '#FFF',
                borderRadius: 0
              }}
              visibleFrom="md"
            >
              <WebstoreBuilderConfigSidebar
                controlState={formState.controlState}
                ecomStoreTheme={ecomStoreTheme}
                editState={formState.editState}
                onAddColorScheme={onAddColorScheme}
                loading={isLoading}
                onCopySection={(section) =>
                  onAddSection(
                    section.fkEcomStoreThemeSectionType,
                    section.fkEcomStoreThemeSectionLocationType,
                    section
                  )
                }
                onCopySectionBlock={(section, block) =>
                  onAddSectionBlock(
                    section,
                    block.fkEcomStoreThemeSectionBlockType,
                    block
                  )
                }
                onRemoveSection={onRemoveSection}
                onRemoveSectionBlock={onRemoveSectionBlock}
                onUpdateEditState={(editState) =>
                  setFormState({
                    ...formState,
                    editState: {
                      ...formState.editState,
                      ...editState,
                      hasUnsavedChanges: true
                    }
                  })
                }
                viewState={formState.viewState}
              />
            </AppCard>
          )}
        </AppFlexbox>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack style={{ flex: 1, gap: 0 }}>
      <WebstoreBuilderHeader
        controlState={formState.controlState}
        editState={formState.editState}
        loading
        viewState={formState.viewState}
      />

      <AppFlexbox
        style={{
          flex: 1,
          gap: 0,
          position: 'relative',
          paddingBottom: isLaptopOrSmaller ? 60 : 0,
          overflow: 'hidden'
        }}
      >
        <WebstoreBuilderSectionManager
          controlState={formState.controlState}
          editState={formState.editState}
          loading
          viewState={formState.viewState}
        />
        <AppStack
          style={{
            flex: 1,
            overflow: 'auto',
            padding: isLaptopOrSmaller ? 0 : 10,
            alignItems: 'center'
          }}
        >
          <AppCard
            radius={isLaptopOrSmaller ? 0 : 10}
            shadow="xl"
            style={{
              padding: 0,
              flex: 1,
              width: '100%',
              maxHeight: isLaptopOrSmaller
                ? 'calc(100vh - 120px)'
                : 'calc(100vh - 80px)',
              maxWidth:
                formState.viewState.screenRatio ===
                VIEW_SCREEN_RATIO_ENUM.mobile
                  ? 375
                  : 'unset',
              position: 'relative',
              overflow: 'auto'
            }}
          >
            <WsApp
              controlState={formState.controlState}
              editState={formState.editState}
              loading
              viewState={formState.viewState}
            />
          </AppCard>
        </AppStack>
        <AppFlexbox visibleFrom="xlg">
          {formState.viewState.screenRatio !==
            VIEW_SCREEN_RATIO_ENUM.fullscreen && (
            <AppCard
              shadow="xs"
              style={{
                height: 'calc(100vh - 60px)',
                padding: 0,
                minWidth: 300,
                maxWidth: 300,
                position: '-webkit-sticky',
                position: 'sticky',
                flex: 1,
                gap: 0,
                backgroundColor: '#FFF',
                borderRadius: 0
              }}
              visibleFrom="md"
            >
              <WebstoreBuilderConfigSidebar
              onAddColorScheme={onAddColorScheme}
                controlState={formState.controlState}
                editState={formState.editState}
                loading
                viewState={formState.viewState}
              />
            </AppCard>
          )}
        </AppFlexbox>
      </AppFlexbox>
    </AppStack>
  );
};

export default WebstoreBuilderView;
