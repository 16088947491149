import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Button, Divider, Grid, Skeleton } from '@mantine/core';
import {
  ChevronLeft,
  Copy,
  Dots,
  Eye,
  EyeOff,
  Settings,
  Template,
  Trash
} from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import WebstoreBuilderSidebarSettings from './WebstoreBuilderSidebarSettings';
import WebstoreBuilderSidebar from './WebstoreBuilderSidebar';
import { VIEW_SCREEN_RATIO_ENUM } from './wsAppSettingConfig';
import WebstoreBuilderConfigSidebar from './WebstoreBuilderConfigSidebar';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { ECOM_WEBSTORE_SECTIONS } from './sections/wsSectionConfig';

const SIDEBAR_VIEW_ENUM = {
  template: 'template',
  settings: 'settings'
};

const WebstoreBuilderSectionManager = ({
  loading,
  ecomStoreTheme,
  onSelectSection,
  onSelectSectionBlock,
  onUnselectSection,
  onAddSection,
  onRemoveSection,
  onAddSectionBlock,
  onRemoveSectionBlock,
  onToggleOpenSection,
  onToggleHide,
  editState,
  onChangeSectionOrder,
  onChangeSectionBlockOrder,
  controlState,
  onAddColorScheme,
  onDeleteColorScheme,
  onChangeColorScheme,
  onChangeLogoImage,
  onChangeSetting,
  onSelectSetting,
  onSelectSettingItem,
  onViewStateChange,
  onUpdateEditState,
  onCopySection,
  onCopySectionBlock,
  viewState
}) => {
  const { isLargeDesktopOrSmaller } = useMediaQueryIndex();
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    isOpenAllTheWay: false,
    showEdit: false,
    showAdditionalActions: false
  });

  const selectedSection = editState.ecomStoreThemeSections?.find(
    (s) => s.key === controlState.selectedSection
  );
  const selectedSectionBlock = selectedSection?.ecomStoreThemeSectionBlocks?.find(
    (i) => i.key === controlState.selectedSectionBlock
  );
  const selectedSectionConfig =
    ECOM_WEBSTORE_SECTIONS[selectedSection?.fkEcomStoreThemeSectionType];
  const selectedSectionBlockConfig = selectedSectionConfig?.blocks?.find(
    (i) => i.type === selectedSectionBlock?.fkEcomStoreThemeSectionBlockType
  );

  useEffect(() => {
    if (viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings) {
      setDrawerState({
        isOpen: true,
        isOpenAllTheWay: true,
        showEdit: false,
        showAdditionalActions: false
      });
    }
  }, [viewState.sidebarView]);

  return !loading ? (
    <>
      {viewState.screenRatio !== VIEW_SCREEN_RATIO_ENUM.fullscreen && (
        <AppCard
          shadow="xs"
          style={{
            height: 'calc(100vh - 60px)',
            width: '100%',
            padding: 0,
            minWidth: 350,
            maxWidth: 350,
            position: '-webkit-sticky',
            position: 'sticky',
            flex: 1,
            gap: 0,
            backgroundColor: '#FFF',
            borderRadius: 0
          }}
          visibleFrom="md"
        >
          <AppFlexbox style={{ flex: 1, gap: 0, overflow: 'hidden' }}>
            <AppStack
              style={{ padding: 5, gap: 5, borderRight: 'solid 1px #dee2e6' }}
            >
              <ActionIcon
                color={
                  viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings
                    ? 'dark'
                    : 'blue'
                }
                onClick={() => {
                  onViewStateChange({
                    viewState,
                    sidebarView: SIDEBAR_VIEW_ENUM.template
                  });
                }}
                radius="md"
                size="xl"
                variant={
                  viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings
                    ? 'subtle'
                    : 'light'
                }
              >
                <Template size={18} />
              </ActionIcon>
              <ActionIcon
                color={
                  viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings
                    ? 'blue'
                    : 'dark'
                }
                onClick={() => {
                  onViewStateChange({
                    viewState,
                    sidebarView: SIDEBAR_VIEW_ENUM.settings
                  });
                }}
                radius="md"
                size="xl"
                variant={
                  viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings
                    ? 'light'
                    : 'subtle'
                }
              >
                <Settings size={18} />
              </ActionIcon>
            </AppStack>

            {isLargeDesktopOrSmaller && !!controlState.selectedSection ? (
              <WebstoreBuilderConfigSidebar
                controlState={controlState}
                ecomStoreTheme={ecomStoreTheme}
                editState={editState}
                onAddColorScheme={onAddColorScheme}
                onCopySection={onCopySection}
                onCopySectionBlock={onCopySectionBlock}
                onRemoveSection={onRemoveSection}
                onRemoveSectionBlock={onRemoveSectionBlock}
                onUnselectSection={onUnselectSection}
                onUpdateEditState={onUpdateEditState}
                viewState={viewState}
              />
            ) : viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings ? (
              <WebstoreBuilderSidebarSettings
                controlState={controlState}
                ecomStoreTheme={ecomStoreTheme}
                editState={editState}
                onAddColorScheme={onAddColorScheme}
                onChangeColorScheme={onChangeColorScheme}
                onChangeLogoImage={onChangeLogoImage}
                onChangeSetting={onChangeSetting}
                onDeleteColorScheme={onDeleteColorScheme}
                onSelectSetting={onSelectSetting}
                onSelectSettingItem={onSelectSettingItem}
              />
            ) : (
              <WebstoreBuilderSidebar
                controlState={controlState}
                ecomStoreTheme={ecomStoreTheme}
                editState={editState}
                onAddSection={onAddSection}
                onAddSectionBlock={onAddSectionBlock}
                onChangeSectionBlockOrder={onChangeSectionBlockOrder}
                onChangeSectionOrder={onChangeSectionOrder}
                onRemoveSection={onRemoveSection}
                onRemoveSectionBlock={onRemoveSectionBlock}
                onSelectSection={onSelectSection}
                onSelectSectionBlock={onSelectSectionBlock}
                onToggleHide={onToggleHide}
                onToggleOpenSection={onToggleOpenSection}
              />
            )}
          </AppFlexbox>
        </AppCard>
      )}

      <AppStack
        hiddenFrom="md"
        style={{
          width: '100%',
          position: 'absolute',
          top: drawerState.isOpen
            ? drawerState.isOpenAllTheWay
              ? 0
              : 'calc(40vh - 60px)'
            : 'calc(100vh - 120px)',
          left: 0,
          zIndex: 99
        }}
      >
        <AppCard
          shadow="xl"
          style={{
            height: '100%',
            width: '100%',
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 0,
            padding: 0
          }}
          withBorder
        >
          <AppStack
            onClick={() => {
              setDrawerState({
                ...drawerState,
                isOpen: !drawerState.isOpen,
                isOpenAllTheWay: true
              });
            }}
            style={{ gap: 0, height: 60, cursor: 'pointer' }}
          >
            <AppFlexbox
              style={{ flex: 1, justifyContent: 'center', paddingTop: 8 }}
            >
              <AppFlexbox
                style={{
                  width: 40,
                  height: 5,
                  borderRadius: 100,
                  backgroundColor: '#C4C4C4'
                }}
              />
            </AppFlexbox>
            <AppFlexbox
              style={{
                height: '100%',
                alignItems: 'center',
                padding: '0px 5px'
              }}
            >
              <AppFlexbox style={{ flex: 1, maxWidth: 100, height: '100%' }}>
                {viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings ? (
                  <Button
                    color="dark"
                    justify="start"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onViewStateChange({
                        ...viewState,
                        sidebarView: SIDEBAR_VIEW_ENUM.template
                      });
                      if (!drawerState.isOpen) {
                        setDrawerState({
                          ...drawerState,
                          isOpen: true,
                          isOpenAllTheWay: true,
                          showEdit: false,
                          showAdditionalActions: false
                        });
                      }
                    }}
                    radius="md"
                    style={{
                      height: '100%',
                      width: '100%',
                      padding: '0px 5px'
                    }}
                    variant="subtle"
                  >
                    <AppFlexbox
                      style={{
                        gap: 5,
                        alignItems: 'center',
                        justify: 'center'
                      }}
                    >
                      <ChevronLeft size={16} />
                      <AppText style={{ fontSize: 14 }}>Back</AppText>
                    </AppFlexbox>
                  </Button>
                ) : controlState.selectedSection ? (
                  <Button
                    color="dark"
                    justify="start"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      if (drawerState.showAdditionalActions) {
                        setDrawerState({
                          ...drawerState,
                          isOpen: true,
                          isOpenAllTheWay: true,
                          showEdit: false,
                          showAdditionalActions: false
                        });
                      }
                      else {
                        onUnselectSection();
                        if (!drawerState.isOpen) {
                          setDrawerState({
                            ...drawerState,
                            isOpen: true,
                            isOpenAllTheWay: true,
                            showEdit: false,
                            showAdditionalActions: false
                          });
                        }
                      }
                    }}
                    radius="md"
                    style={{
                      height: '100%',
                      width: '100%',
                      padding: '0px 5px'
                    }}
                    variant="subtle"
                  >
                    <AppFlexbox
                      style={{
                        gap: 5,
                        alignItems: 'center',
                        justify: 'center'
                      }}
                    >
                      <ChevronLeft size={16} />
                      <AppText style={{ fontSize: 14 }}>
                        {drawerState.showAdditionalActions
                          ? 'Back'
                          : 'Sections'}
                      </AppText>
                    </AppFlexbox>
                  </Button>
                ) : (
                  !drawerState.showEdit && (
                    <Button
                      color="dark"
                      justify="start"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDrawerState({
                          ...drawerState,
                          isOpen: true,
                          isOpenAllTheWay: true,
                          showEdit: true,
                          showAdditionalActions: false
                        });
                      }}
                      radius="md"
                      style={{
                        height: '100%',
                        width: '100%',
                        padding: '0px 10px'
                      }}
                      variant="subtle"
                    >
                      <AppText style={{ fontSize: 14 }}>Edit</AppText>
                    </Button>
                  )
                )}
              </AppFlexbox>
              <AppFlexbox
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                {selectedSectionConfig ? (
                  <AppStack
                    style={{ gap: 0, alignItems: 'center', width: '100%' }}
                  >
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        gap: 8,
                        width: '100%'
                      }}
                    >
                      <AppText
                        style={{
                          fontWeight: 500,
                          lineHeight: '22px',
                          color: (
                            selectedSectionBlock
                              ? selectedSectionBlock.hidden
                              : selectedSection.hidden
                          )
                            ? '#999'
                            : '#000',
                          whiteSpace: 'nowrap',
                          width: '100%',
                          textAlign: 'center'
                        }}
                      >
                        {selectedSectionBlockConfig?.title ||
                          selectedSectionConfig.title}
                      </AppText>
                      {(selectedSectionBlock
                        ? selectedSectionBlock.hidden
                        : selectedSection.hidden) && <EyeOff size={16} />}
                    </AppFlexbox>

                    {selectedSectionBlockConfig && (
                      <AppFlexbox
                        style={{ alignItems: 'center', gap: 8, width: '100%' }}
                      >
                        <AppText
                          style={{
                            fontSize: 13,
                            color: selectedSection.hidden ? '#999' : '#666',
                            fontWeight: 500,
                            lineHeight: '16px',
                            whiteSpace: 'nowrap',
                            width: '100%'
                          }}
                        >
                          {selectedSectionConfig.title}
                        </AppText>
                        {selectedSection.hidden && <EyeOff size={16} />}
                      </AppFlexbox>
                    )}
                  </AppStack>
                ) : (
                  <AppText
                    style={{
                      fontWeight: 500,
                      whiteSpace: 'nowrap',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    {viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings
                      ? 'Theme settings'
                      : drawerState.showEdit
                      ? 'Edit sections'
                      : 'Sections'}
                  </AppText>
                )}
              </AppFlexbox>
              <AppFlexbox
                style={{
                  flex: 1,
                  maxWidth: 100,
                  height: '100%',
                  justifyContent: 'end'
                }}
              >
                {selectedSectionConfig &&
                !drawerState.showAdditionalActions &&
                !selectedSectionConfig.isPermanent ? (
                  <Button
                    color="dark"
                    justify="end"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDrawerState({
                        ...drawerState,
                        isOpen: true,
                        isOpenAllTheWay: true,
                        showAdditionalActions: true
                      });
                    }}
                    radius="md"
                    style={{
                      height: '100%',
                      padding: '0px 10px'
                    }}
                    styles={{ inner: { justifyContent: 'end' } }}
                    variant="subtle"
                  >
                    <Dots size={18} />
                  </Button>
                ) : (
                  !controlState.selectedSection &&
                  viewState.sidebarView !== SIDEBAR_VIEW_ENUM.settings &&
                  drawerState.showEdit && (
                    <Button
                      color="dark"
                      justify="end"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDrawerState({
                          ...drawerState,
                          isOpen: true,
                          isOpenAllTheWay: true,
                          showEdit: false,
                          showAdditionalActions: false
                        });
                      }}
                      radius="md"
                      style={{
                        height: '100%',
                        width: '100%',
                        padding: '0px 10px'
                      }}
                      styles={{ inner: { justifyContent: 'end' } }}
                      variant="subtle"
                    >
                      <AppText style={{ fontSize: 14 }}>Done</AppText>
                    </Button>
                  )
                )}
              </AppFlexbox>
            </AppFlexbox>
          </AppStack>
          <AppStack
            style={{
              height: drawerState.isOpenAllTheWay
                ? 'calc(100vh - 120px)'
                : 'calc(60vh - 0px)'
            }}
          >
            {viewState.sidebarView === SIDEBAR_VIEW_ENUM.settings ? (
              <WebstoreBuilderSidebarSettings
                controlState={controlState}
                ecomStoreTheme={ecomStoreTheme}
                editState={editState}
                onAddColorScheme={onAddColorScheme}
                onChangeColorScheme={onChangeColorScheme}
                onChangeLogoImage={onChangeLogoImage}
                onChangeSetting={onChangeSetting}
                onSelectSetting={onSelectSetting}
                onSelectSettingItem={onSelectSettingItem}
              />
            ) : controlState.selectedSection ? (
              drawerState.showAdditionalActions ? (
                <Grid style={{ padding: 20 }}>
                  <Grid.Col span={{ base: 12, xsm: 4 }}>
                    <Button
                      color="dark"
                      disabled={
                        selectedSectionBlock
                          ? (selectedSectionConfig.maxItemCount !== null &&
                              selectedSection.ecomStoreThemeSectionBlocks
                                .length >=
                                selectedSectionConfig.maxItemCount) ||
                            (selectedSectionBlockConfig.maxCount !== null &&
                              selectedSection.ecomStoreThemeSectionBlocks.filter(
                                (i) =>
                                  i.fkEcomStoreThemeSectionBlockType ===
                                  selectedSectionBlockConfig.type
                              ).length >= selectedSectionBlockConfig.maxCount)
                          : false
                      }
                      onClick={() => {
                        if (selectedSectionBlock) {
                          onCopySectionBlock(
                            selectedSection,
                            selectedSectionBlock
                          );
                        }
                        else {
                          onCopySection(selectedSection);
                        }
                        setDrawerState({
                          ...drawerState,
                          showAdditionalActions: false
                        });
                      }}
                      radius="lg"
                      size="lg"
                      style={{ flex: 1, height: 125, width: '100%' }}
                      variant="light"
                    >
                      <AppStack style={{ alignItems: 'center', gap: 5 }}>
                        <Copy size={22} />
                        <AppText style={{ fontSize: 18 }}>Copy</AppText>
                      </AppStack>
                    </Button>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, xsm: 4 }}>
                    <Button
                      color="dark"
                      onClick={() => {
                        onToggleHide(
                          selectedSection.key,
                          selectedSectionBlock?.key
                        );
                      }}
                      radius="lg"
                      size="lg"
                      style={{ flex: 1, height: 125, width: '100%' }}
                      variant="light"
                    >
                      <AppStack style={{ alignItems: 'center', gap: 5 }}>
                        {(
                          selectedSectionBlock
                            ? selectedSectionBlock.hidden
                            : selectedSection
                        ) ? (
                          <Eye size={22} />
                        ) : (
                          <EyeOff size={22} />
                        )}
                        <AppText style={{ fontSize: 18 }}>
                          {(
                            selectedSectionBlock
                              ? selectedSectionBlock.hidden
                              : selectedSection
                          )
                            ? 'Show'
                            : 'Hide'}
                        </AppText>
                      </AppStack>
                    </Button>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, xsm: 4 }}>
                    <Button
                      color="dark"
                      disabled={
                        selectedSectionConfig.isPermanent ||
                        editState.ecomStoreThemeSections.filter(
                          (s) =>
                            s.fkEcomStoreThemeSectionLocationType ===
                            selectedSection.fkEcomStoreThemeSectionLocationType
                        ).length <= 1
                      }
                      onClick={() => {
                        if (selectedSectionBlock) {
                          onRemoveSectionBlock(
                            selectedSection,
                            selectedSectionBlock
                          );
                        }
                        else {
                          onRemoveSection(selectedSection);
                        }
                        setDrawerState({
                          ...drawerState,
                          showAdditionalActions: false
                        });
                      }}
                      radius="lg"
                      size="lg"
                      style={{ flex: 1, height: 125, width: '100%' }}
                      variant="light"
                    >
                      <AppStack style={{ alignItems: 'center', gap: 5 }}>
                        <Trash color="#c40000" size={22} />
                        <AppText style={{ fontSize: 18, color: '#c40000' }}>
                          Delete
                        </AppText>
                      </AppStack>
                    </Button>
                  </Grid.Col>
                </Grid>
              ) : (
                <WebstoreBuilderConfigSidebar
                  controlState={controlState}
                  ecomStoreTheme={ecomStoreTheme}
                  editState={editState}
                  noHeader
                  onAddColorScheme={onAddColorScheme}
                  onCopySection={onCopySection}
                  onCopySectionBlock={onCopySectionBlock}
                  onRemoveSection={onRemoveSection}
                  onRemoveSectionBlock={onRemoveSectionBlock}
                  onUnselectSection={onUnselectSection}
                  onUpdateEditState={onUpdateEditState}
                  viewState={viewState}
                />
              )
            ) : (
              <WebstoreBuilderSidebar
                controlState={controlState}
                ecomStoreTheme={ecomStoreTheme}
                editState={editState}
                mobileEditMode={drawerState.showEdit}
                onAddSection={onAddSection}
                onAddSectionBlock={onAddSectionBlock}
                onChangeSectionBlockOrder={onChangeSectionBlockOrder}
                onChangeSectionOrder={onChangeSectionOrder}
                onRemoveSection={onRemoveSection}
                onRemoveSectionBlock={onRemoveSectionBlock}
                onSelectSection={onSelectSection}
                onSelectSectionBlock={onSelectSectionBlock}
                onToggleHide={onToggleHide}
                onToggleOpenSection={onToggleOpenSection}
              />
            )}
          </AppStack>
        </AppCard>
      </AppStack>
    </>
  ) : (
    <>
      {viewState.screenRatio !== VIEW_SCREEN_RATIO_ENUM.fullscreen && (
        <AppCard
          shadow="xs"
          style={{
            height: 'calc(100vh - 60px)',
            width: '100%',
            padding: 0,
            minWidth: 350,
            maxWidth: 350,
            position: '-webkit-sticky',
            position: 'sticky',
            flex: 1,
            gap: 0,
            backgroundColor: '#FFF',
            borderRadius: 0
          }}
          visibleFrom="md"
        >
          <AppFlexbox style={{ flex: 1, gap: 0, overflow: 'hidden' }}>
            <AppStack
              style={{ padding: 5, gap: 5, borderRight: 'solid 1px #dee2e6' }}
            >
              <Skeleton height={42} radius="md" width={42} />
              <Skeleton height={42} radius="md" width={42} />
            </AppStack>

            <AppStack
              style={{
                flex: 1,
                gap: 0,
                overflow: 'hidden'
              }}
            >
              <AppFlexbox style={{ padding: 10, paddingLeft: 32 }}>
                <Skeleton height={28} width={95} />
              </AppFlexbox>

              <Divider />
              <AppStack
                style={{
                  gap: 0,
                  overflow: 'auto',
                  position: 'relative'
                }}
              >
                <AppStack style={{ padding: 10, gap: 3 }}>
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#FFF',
                      zIndex: 1,
                      height: 48,
                      paddingLeft: 32
                    }}
                  >
                    <Skeleton height={21} width={75} />
                  </AppFlexbox>
                  <Skeleton height={28} width="100%" />
                  <Skeleton height={28} width="100%" />
                </AppStack>
              </AppStack>

              <Divider />
              <AppStack
                style={{
                  gap: 0,
                  overflow: 'auto',
                  position: 'relative'
                }}
              >
                <AppStack style={{ padding: 10, gap: 3 }}>
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#FFF',
                      zIndex: 1,
                      height: 48,
                      paddingLeft: 32
                    }}
                  >
                    <Skeleton height={21} width={75} />
                  </AppFlexbox>
                  <Skeleton height={28} width="100%" />
                  <Skeleton height={28} width="100%" />
                </AppStack>
              </AppStack>

              <Divider />
              <AppStack
                style={{
                  gap: 0,
                  overflow: 'auto',
                  position: 'relative'
                }}
              >
                <AppStack style={{ padding: 10, gap: 3 }}>
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#FFF',
                      zIndex: 1,
                      height: 48,
                      paddingLeft: 32
                    }}
                  >
                    <Skeleton height={21} width={75} />
                  </AppFlexbox>
                  <Skeleton height={28} width="100%" />
                  <Skeleton height={28} width="100%" />
                </AppStack>
              </AppStack>
            </AppStack>
          </AppFlexbox>
        </AppCard>
      )}
      <AppStack
        hiddenFrom="md"
        style={{
          width: '100%',
          position: 'absolute',
          top: drawerState.isOpen
            ? drawerState.isOpenAllTheWay
              ? 0
              : 'calc(40vh - 60px)'
            : 'calc(100vh - 120px)',
          left: 0,
          zIndex: 99
        }}
      >
        <AppCard
          shadow="xl"
          style={{
            height: '100%',
            width: '100%',
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 0,
            padding: 0
          }}
          withBorder
        >
          <AppStack style={{ gap: 0, height: 60, cursor: 'pointer' }}>
            <AppFlexbox
              style={{ flex: 1, justifyContent: 'center', paddingTop: 8 }}
            >
              <AppFlexbox
                style={{
                  width: 40,
                  height: 5,
                  borderRadius: 100,
                  backgroundColor: '#C4C4C4'
                }}
              />
            </AppFlexbox>
            <AppFlexbox
              style={{
                height: '100%',
                alignItems: 'center',
                padding: '0px 5px'
              }}
            >
              <AppFlexbox style={{ flex: 1, maxWidth: 100, height: '100%' }}>
                <AppFlexbox
                  style={{
                    gap: 5,
                    height: '100%',
                    width: '100%',
                    padding: '0px 5px',
                    alignItems: 'center',
                    justify: 'center'
                  }}
                >
                  <Skeleton height={18} width={35} />
                </AppFlexbox>
              </AppFlexbox>
              <AppFlexbox
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Skeleton height={18} width={75} />
              </AppFlexbox>
              <AppFlexbox
                style={{
                  flex: 1,
                  maxWidth: 100,
                  height: '100%',
                  justifyContent: 'end'
                }}
              />
            </AppFlexbox>
          </AppStack>
        </AppCard>
      </AppStack>
    </>
  );
};

WebstoreBuilderSectionManager.propTypes = {
  controlState: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editState: PropTypes.object,
  loading: PropTypes.bool,
  onAddColorScheme: PropTypes.func,
  onAddSection: PropTypes.func,
  onAddSectionBlock: PropTypes.func,
  onChangeColorScheme: PropTypes.func,
  onChangeLogoImage: PropTypes.func,
  onChangeSectionBlockOrder: PropTypes.func,
  onChangeSectionOrder: PropTypes.func,
  onChangeSetting: PropTypes.func,
  onCopySection: PropTypes.func,
  onCopySectionBlock: PropTypes.func,
  onDeleteColorScheme: PropTypes.func,
  onRemoveSection: PropTypes.func,
  onRemoveSectionBlock: PropTypes.func,
  onSelectSection: PropTypes.func,
  onSelectSectionBlock: PropTypes.func,
  onSelectSetting: PropTypes.func,
  onSelectSettingItem: PropTypes.func,
  onToggleHide: PropTypes.func,
  onToggleOpenSection: PropTypes.func,
  onUnselectSection: PropTypes.func,
  onUpdateEditState: PropTypes.func,
  onViewStateChange: PropTypes.func,
  viewState: PropTypes.object
};

export default WebstoreBuilderSectionManager;
