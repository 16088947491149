import { Mail } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsContactForm from './WsContactForm';

const configNameValues = {
  heading: 'heading',
  headingSize: '',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  [configNameValues.heading]: 'Contact us',
  [configNameValues.headingSize]: '26',
  [configNameValues.colorScheme]: '1'
};

const configOptions = {
  [configNameValues.headingText]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Heading'
  },
  [configNameValues.headingSize]: {
    label: 'Heading size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 100,
    unit: 'px'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.CONTACT_FORM,
  title: 'Contact form',
  icon: Mail,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsContactForm,
  configOptions,
  maxItemCount: null,
  cannotCreate: true,
  blocks: [],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
