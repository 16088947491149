import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AlertCircle, GripVertical, Trash } from 'tabler-icons-react';
import { ActionIcon, Badge, Button, Select, TextInput } from '@mantine/core';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import SortableList from '../../common/SortableList';
import ActionableIcon from '../../common/ActionableIcon';

const DEFAULT_OPTION_NAMES = ['Size', 'Color', 'Material', 'Style'];

const DEFAULT_OPTION_VALUES = {
  Size: ['Small', 'Medium', 'Large'],
  Color: ['Red', 'Green', 'Blue'],
  Material: ['Cotton', 'Polyester', 'Wool'],
  Style: ['Casual', 'Formal', 'Sport']
};

const EditProductVariantOption = ({
  option,
  onChangeOptionName,
  onChangeOptionValue,
  onChangeOptionValueSort,
  onRemoveOptionValue,
  onAddVariantOptionValue,
  onToggleEdit,
  onRemove,
  showEdit,
  disabled,
  sortableProps,
  existingOptions,
  submitError
}) => {
  const key = new Date().getTime().toString();
  const lastInputRef = useRef(null);
  const [inputState, setInputState] = useState({
    currentOptionCount: 0,
    current: '',
    last: '',
    newOptionValue: '',
    errors: {}
  });

  useEffect(() => {
    if (inputState.currentOptionCount !== option.values.length) {
      setInputState({
        ...inputState,
        currentOptionCount: option.values.length
      });
      if (option.values.length > 1 && lastInputRef.current) {
        lastInputRef.current.focus();
      }
    }
  }, [option.values]);

  useEffect(() => {
    if (submitError) {
      const duplicateOptionValue = option.values.find(
        (op) =>
          option.values.filter(
            (f) => f.value?.toLowerCase() === op.value?.toLowerCase()
          ).length > 1
      );

      setInputState({
        ...inputState,
        errors: {
          ...inputState.errors,
          optionName: !option.name ? 'Option name is required.' : null,
          optionNameExisting:
            existingOptions.filter(
              (f) => f.name?.toLowerCase() === option.name?.toLowerCase()
            ).length > 1
              ? `Option '${option.name}' already exists.`
              : null,
          optionValues: option.values.some((s) => !s.value)
            ? 'Option value is required.'
            : null,
          optionValuesExisting: duplicateOptionValue
            ? `Option value '${duplicateOptionValue.value}' already exists.`
            : null
        }
      });
    }
  }, [submitError]);

  return (
    <AppFlexbox
      ref={sortableProps?.setNodeRef}
      style={{
        ...sortableProps?.style,
        flex: 1,
        padding: 0,
        outline: sortableProps?.isOverlay ? 'solid 1px #dee2e6' : 'none',
        backgroundColor: sortableProps?.isDragging
          ? 'rgba(56, 56, 56, 0.1)'
          : '#FFF',
        opacity: sortableProps?.isDragging ? 0.4 : 1,
        cursor: sortableProps?.isDragging ? 'grabbing' : 'default'
      }}
    >
      {showEdit ? (
        <AppStack style={{ flex: 1, gap: 10, padding: '16px 8px' }}>
          <AppStack style={{ gap: 2 }}>
            <AppFlexbox style={{ gap: 0 }}>
              <AppFlexbox style={{ width: 30 }} />
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Option Name
              </AppText>
            </AppFlexbox>
            <AppFlexbox style={{ alignItems: 'center', gap: 0 }}>
              <AppFlexbox style={{ width: 30 }}>
                <ActionIcon
                  color="dark"
                  size="sm"
                  style={{
                    height: '100%',
                    borderRadius: 8,
                    width: 20,
                    border: 'none',
                    cursor: sortableProps?.isOverlay ? 'grabbing' : 'pointer'
                  }}
                  variant="subtle"
                  {...sortableProps?.attributes}
                  {...sortableProps?.listeners}
                >
                  <GripVertical size={18} />
                </ActionIcon>
              </AppFlexbox>
              <Select
                data={DEFAULT_OPTION_NAMES}
                disabled={disabled}
                onBlur={() => {
                  setInputState({
                    ...inputState,
                    errors: {
                      ...inputState.errors,
                      optionName: !option.name
                        ? 'Option name is required.'
                        : null,
                      optionNameExisting:
                        existingOptions.filter(
                          (f) =>
                            f.name?.toLowerCase() === option.name?.toLowerCase()
                        ).length > 1
                          ? `Option '${option.name}' already exists.`
                          : null
                    }
                  });
                  onChangeOptionName(option.name);
                }}
                onChange={(v) => {
                  setInputState({
                    ...inputState,
                    errors: {
                      ...inputState.errors,
                      optionNameExisting:
                        existingOptions.filter(
                          (f) => f.name?.toLowerCase() === v?.toLowerCase()
                        ).length > 1
                          ? `Option '${option.name}' already exists.`
                          : null
                    }
                  });
                  onChangeOptionName(v);
                }}
                onSearchChange={(v) => {
                  setInputState({
                    ...inputState,
                    errors: {
                      ...inputState.errors,
                      optionNameExisting:
                        existingOptions.filter(
                          (f) => f.name?.toLowerCase() === v?.toLowerCase()
                        ).length > 1
                          ? `Option '${option.name}' already exists.`
                          : null
                    }
                  });
                  onChangeOptionName(v);
                }}
                placeholder={DEFAULT_OPTION_NAMES[0]}
                searchable
                searchValue={option.name}
                style={{ flex: 1 }}
                value=""
              />
            </AppFlexbox>
            {(inputState.errors.optionName ||
              inputState.errors.optionNameExisting) && (
              <AppFlexbox style={{ gap: 0 }}>
                <AppFlexbox style={{ width: 30 }} />
                <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                  <AlertCircle color="red" size={15} />
                  <AppText style={{ fontSize: 13, color: 'red' }}>
                    {inputState.errors.optionNameExisting ??
                      inputState.errors.optionName}
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
            )}
          </AppStack>

          <AppStack style={{ gap: 2, marginLeft: 15 }}>
            <AppFlexbox style={{ gap: 0 }}>
              <AppFlexbox style={{ width: 30 }} />
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Option Values
              </AppText>
            </AppFlexbox>
            <SortableList
              items={option.values.map((v) => ({ ...v, sort: v.valueSort }))}
              listProps={{ style: { gap: 2 } }}
              onChange={onChangeOptionValueSort}
              renderItem={(item, sortableItemProps, index) => (
                <AppFlexbox
                  key={item.key}
                  ref={sortableItemProps?.setNodeRef}
                  style={{
                    ...sortableItemProps?.style,
                    flex: 1,
                    alignItems: 'center',
                    gap: 0,
                    padding: 0,
                    outline: sortableItemProps?.isOverlay
                      ? 'solid 1px #dee2e6'
                      : 'none',
                    backgroundColor: sortableItemProps?.isDragging
                      ? 'rgba(56, 56, 56, 0.1)'
                      : '#FFF',
                    opacity: sortableItemProps?.isDragging ? 0.4 : 1,
                    cursor: sortableItemProps?.isDragging
                      ? 'grabbing'
                      : 'default'
                  }}
                >
                  <AppFlexbox style={{ width: 30, cursor: 'pointer' }}>
                    {option.values.length > 1 && (
                      <ActionIcon
                        color="dark"
                        size="sm"
                        style={{
                          height: '100%',
                          borderRadius: 8,
                          width: 20,
                          border: 'none',
                          cursor: sortableItemProps?.isOverlay
                            ? 'grabbing'
                            : 'pointer'
                        }}
                        variant="subtle"
                        {...sortableItemProps?.attributes}
                        {...sortableItemProps?.listeners}
                      >
                        <GripVertical size={18} />
                      </ActionIcon>
                    )}
                  </AppFlexbox>

                  <TextInput
                    ref={
                      index === option.values.length - 1 ? lastInputRef : null
                    }
                    disabled={disabled}
                    onBlur={() => {
                      setInputState({
                        ...inputState,
                        current: '',
                        last: '',
                        errors: {
                          ...inputState.errors,
                          optionValues:
                            !inputState.last &&
                            !option.values.some((s) => s.value)
                              ? 'Option value is required.'
                              : null,
                          optionValuesExisting: null
                        }
                      });
                      const existingOptionValue =
                        option.values.filter(
                          (f) =>
                            f.value?.toLowerCase() === item.value?.toLowerCase()
                        ).length > 1;
                      if (
                        (inputState.last && !item.value) ||
                        existingOptionValue
                      ) {
                        onChangeOptionValue(item.key, inputState.last);
                      }
                    }}
                    onChange={(e) => {
                      onChangeOptionValue(item.key, e.currentTarget.value);
                      setInputState({
                        ...inputState,
                        current: e.currentTarget.value,
                        last: item.value,
                        errors: {
                          ...inputState.errors,
                          optionValues: null,
                          optionValuesExisting: option.values.some(
                            (f) =>
                              f.value?.toLowerCase() ===
                              e.currentTarget.value?.toLowerCase()
                          )
                            ? `Option value '${e.currentTarget.value}' already exists.`
                            : null
                        }
                      });
                    }}
                    placeholder={
                      !option.name
                        ? DEFAULT_OPTION_VALUES[DEFAULT_OPTION_NAMES[0]][0]
                        : DEFAULT_OPTION_VALUES[option.name]
                        ? DEFAULT_OPTION_VALUES[option.name][0]
                        : 'Add a value'
                    }
                    rightSection={
                      option.values.length > 1 && (
                        <ActionableIcon
                          color="dark"
                          disabled={disabled}
                          onClick={() => {
                            onRemoveOptionValue(item.key);
                          }}
                          variant="subtle"
                        >
                          <Trash size={18} />
                        </ActionableIcon>
                      )
                    }
                    style={{ flex: 1 }}
                    value={item.value ?? ''}
                  />
                </AppFlexbox>
              )}
            />

            {(option.values[option.values.length - 1].value ||
              inputState.last) && (
              <AppFlexbox style={{ alignItems: 'center', gap: 0 }}>
                <AppFlexbox style={{ width: 30, cursor: 'pointer' }} />
                <TextInput
                  disabled={disabled}
                  onBlur={() => {}}
                  onChange={(e) => {
                    onAddVariantOptionValue({
                      ...option,
                      valueKey: key,
                      value: e.currentTarget.value,
                      valueSort: option.values.length + 1
                    });
                  }}
                  placeholder="Add another value"
                  style={{ flex: 1 }}
                  value=""
                />
              </AppFlexbox>
            )}

            {(inputState.errors.optionValues ||
              inputState.errors.optionValuesExisting) && (
              <AppFlexbox style={{ gap: 0 }}>
                <AppFlexbox style={{ width: 30 }} />
                <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                  <AlertCircle color="red" size={15} />
                  <AppText style={{ fontSize: 13, color: 'red' }}>
                    {inputState.errors.optionValuesExisting ??
                      inputState.errors.optionValues}
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
            )}
          </AppStack>
          <AppFlexbox style={{ gap: 0, flex: 1 }}>
            <AppFlexbox style={{ width: 30 }} />
            <AppFlexbox
              style={{
                gap: 10,
                marginLeft: 15,
                justifyContent: 'space-between',
                flex: 1
              }}
            >
              <Button
                color="red"
                disabled={disabled}
                onClick={onRemove}
                size="compact-sm"
                type="button"
                variant="outline"
              >
                Delete
              </Button>

              <Button
                color="dark"
                disabled={disabled}
                onClick={() => {
                  const existingName =
                    existingOptions.filter(
                      (f) =>
                        f.name?.toLowerCase() === option.name?.toLowerCase()
                    ).length > 1;
                  if (
                    !option.name ||
                    !option.values.some((s) => !!s.value) ||
                    existingName
                  ) {
                    setInputState({
                      ...inputState,
                      errors: {
                        optionName: !option.name
                          ? 'Option name is required.'
                          : null,
                        optionValues: !option.values.some((s) => s.value)
                          ? 'Option value is required.'
                          : null,
                        optionNameExisting: existingName
                          ? `Option '${option.name}' already exists.`
                          : null
                      }
                    });
                  }
                  else {
                    onToggleEdit(false);
                  }
                }}
                size="compact-sm"
                type="button"
              >
                Done
              </Button>
            </AppFlexbox>
          </AppFlexbox>
        </AppStack>
      ) : (
        <AppStack
          style={{
            flex: 1,
            gap: 10,
            padding: '16px 8px'
          }}
        >
          <AppFlexbox
            style={{
              gap: 10,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <AppFlexbox style={{ flex: 1, alignItems: 'center', gap: 0 }}>
              <AppFlexbox style={{ width: 30, cursor: 'pointer' }}>
                <ActionIcon
                  color="dark"
                  size="sm"
                  style={{
                    height: '100%',
                    borderRadius: 8,
                    width: 20,
                    border: 'none',
                    cursor: sortableProps?.isOverlay ? 'grabbing' : 'pointer'
                  }}
                  variant="subtle"
                  {...sortableProps?.attributes}
                  {...sortableProps?.listeners}
                >
                  <GripVertical size={18} />
                </ActionIcon>
              </AppFlexbox>
              <AppStack style={{ flex: 1, gap: 5 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {option.name}
                </AppText>
                <AppFlexbox style={{ gap: 5, flexWrap: 'wrap' }}>
                  {option.values
                    .sort((a, b) => a.valueSort - b.valueSort)
                    .map((optionValue) => (
                      <Badge
                        key={optionValue.key}
                        color="dark"
                        size="sm"
                        variant="light"
                      >
                        {optionValue.value}
                      </Badge>
                    ))}
                </AppFlexbox>
              </AppStack>
            </AppFlexbox>

            <Button
              color="dark"
              disabled={disabled}
              onClick={() => {
                onToggleEdit(true);
              }}
              size="compact-sm"
              type="button"
              variant="outline"
            >
              Edit
            </Button>
          </AppFlexbox>
        </AppStack>
      )}
    </AppFlexbox>
  );
};

EditProductVariantOption.propTypes = {
  disabled: PropTypes.bool,
  existingOptions: PropTypes.array,
  onAddVariantOptionValue: PropTypes.func,
  onChangeOptionName: PropTypes.func,
  onChangeOptionValue: PropTypes.func,
  onChangeOptionValueSort: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveOptionValue: PropTypes.func,
  onToggleEdit: PropTypes.func,
  option: PropTypes.object,
  showEdit: PropTypes.bool,
  sortableProps: PropTypes.object,
  submitError: PropTypes.any
};

export default EditProductVariantOption;
