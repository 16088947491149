import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsResourceBanner from './WsResourceBanner';

const configNameValues = {
  descriptionText: 'descriptionText',
  showCollectionDescription: 'showCollectionDescription',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  [configNameValues.descriptionText]: '',
  [configNameValues.showCollectionDescription]: 'true',
  [configNameValues.colorScheme]: '1'
};

const configOptions = {
  [configNameValues.showCollectionDescription]: {
    label: 'Show defined collection description',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.descriptionText]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    isTextArea: true,
    label: 'Description',
    isVisible: (config) => config.showCollectionDescription !== 'true'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.RESOURCE_BANNER,
  title: 'Collection banner',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsResourceBanner,
  configOptions,
  isPermanent: true,
  cannotCreate: true,
  maxItemCount: null,
  blocks: [],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
