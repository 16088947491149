import React, { useContext, useEffect, useRef } from 'react';
import { Alert } from '@mantine/core';
import { AlertCircle, Receipt } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import TableView from '../../common/TableView';
import {
  PAYMENT_TYPE_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import { useModalState, usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import ConfirmModal from '../../common/ConfirmModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notification';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import OrderTransactionModal from '../orders/OrderTransactionModal';
import OrderRefundModal from '../orders/OrderRefundModal';

const VIEW_ACTIONS = [
  {
    label: 'View order',
    value: VIEW_ACTIONS_ENUM.VIEW,
    section: 1
  },
  {
    label: 'View transaction',
    value: 'transaction',
    section: 1
  },
  {
    label: 'Refund',
    value: VIEW_ACTIONS_ENUM.REFUND,
    section: 2,
    paidOnly: true,
    color: 'red'
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Order',
    value: 'order',
    sortable: true
  },
  {
    label: 'Date',
    value: 'date',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Customer',
    value: 'customer',
    sortable: true
  },
  {
    label: 'Products ordered',
    value: 'products',
    sortable: true
  },
  {
    label: 'Total cost',
    value: 'total',
    sortable: true
  }
];

const StoreOrderTable = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchPaginatedStoreCartHistory } = useContext(StoreContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: orderData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      status: '',
      sort: 'order_desc'
    },
    (newFilter) => {
      fetchPaginatedStoreCartHistory(
        authState.pkEcomStore,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedEcomStoreCartOrderHistory
  );
  const loading = !hasFetched.current || dataLoading;

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchPaginatedStoreCartHistory(
        authState.pkEcomStore,
        { search: new URLSearchParams(search).get('customer') || '' },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Orders',
          to: '/merchant/orders'
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={!loading && totalNoFilterCount === 0}
        disabledContent={{
          title: 'No orders found.',
          description:
            'Orders will appear here once customers begin to place orders.',
          icon: <Receipt color="#000" size={125} />
        }}
        emptyMessage="No orders found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (v) =>
              onFilterChange({
                ...filter,
                search: v
              })
          },
          {
            key: 2,
            label: 'Status',
            type: 'select',
            value: filter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'unfulfilled', label: 'Unfulfilled' },
              { value: 'fulfilled', label: 'Fulfilled' },
              { value: 'refunded', label: 'Refunded' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, status: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onAction={(action, item) => {
          if (action === VIEW_ACTIONS_ENUM.VIEW) {
            navigate(`/merchant/orders/${item.key}`);
          }
          else {
            onOpenModal(action, item.key);
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={orderData.map((c) => {
          const isFullyFulfilled = c.ecomStoreCartProducts.every(
            (p) =>
              p.shippingCount >= p.count &&
              p.trackingNotifiedCount >= p.trackingCount
          );

          const totals = c.ecomStoreCartPayments.reduce(
            (r, current) => {
              if (
                current.fkRegFormSubmissionPaymentType.toString() ===
                PAYMENT_TYPE_ENUM.REFUND
              ) {
                return {
                  refunded: r.refunded + current.amount,
                  paid: r.paid
                };
              }
              return {
                refunded: r.refunded,
                paid: r.paid + current.amount
              };
            },
            {
              refunded: 0,
              paid: 0
            }
          );
          const completelyRefunded = totals.refunded >= totals.paid;

          return {
            key: c.pkEcomStoreCart,
            actions: VIEW_ACTIONS.filter(
              (f) => !completelyRefunded || !f.paidOnly
            ),
            columns: [
              {
                key: 1,
                label: `#${c.pkEcomStoreCart}`,
                weight: 500,
                onClick: () =>
                  navigate(`/merchant/orders/${c.pkEcomStoreCart}`),
                component: Link,
                to: `/merchant/orders/${c.pkEcomStoreCart}`
              },
              {
                key: 2,
                label: dayjs(
                  formatUtcDate(
                    c.ecomStoreCartPayments.sort(
                      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                    )?.createdAt
                  )
                ).format('MMMM D, YYYY h:mm A')
              },
              {
                key: 3,
                color:
                  totals.refunded > 0
                    ? '#c40000'
                    : isFullyFulfilled
                    ? 'green'
                    : 'orange',
                label:
                  totals.refunded > 0
                    ? 'Refunded'
                    : isFullyFulfilled
                    ? 'Fulfilled'
                    : 'Unfulfilled'
              },
              {
                key: 4,
                label: c.customer.name,
                weight: 500,
                subLabel: c.customer.email,
                onClick: () =>
                  navigate(`/merchant/orders/${c.pkEcomStoreCart}`),
                component: Link,
                to: `/merchant/orders/${c.pkEcomStoreCart}`
              },
              {
                key: 5,
                label: c.ecomStoreCartProducts.length
              },
              {
                key: 6,
                label: currencyFormat(totals.paid / 100)
              }
            ]
          };
        })}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Orders"
        totalCount={totalCount}
        totalPages={totalPages}
      />

      <ConfirmModal
        confirmActionColor="dark"
        confirmActionText="Yes, close window"
        confirmationValue="close"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EXPORT
        }
        message={
          <Alert
            color="#c40000"
            icon={<AlertCircle size={18} />}
            title="Important notice!"
            variant="outline"
          >
            <AppStack style={{ gap: 10 }}>
              <AppText style={{ fontSize: 14 }}>
                Are you sure you want to close the store window? This will
                prevent customers from placing orders until you open the a new
                store window.
              </AppText>

              <AppText style={{ fontSize: 14 }}>
                Order will begin to be process and delivered to customers. You
                cannot recover vendor payments for refunds after this action.
              </AppText>

              <AppText style={{ fontSize: 14 }}>
                Ensure you have met the minimum order quantity before closing
                this window. You are responsible for refunds and service fees
                for orders that do not meet the minimum order quantity.
              </AppText>

              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                This action cannot be undone.
              </AppText>
            </AppStack>
          </Alert>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onCloseModal();
        }}
        title="Close store window?"
      />

      <OrderTransactionModal
        isOpen={modalState.isOpen && modalState.action === 'transaction'}
        onClose={onCloseModal}
        onShowRefund={() =>
          onOpenModal(VIEW_ACTIONS_ENUM.REFUND, modalState.item)
        }
        pkEcomStoreCart={
          modalState.action === VIEW_ACTIONS_ENUM.VIEW ? modalState.item : null
        }
      />

      <OrderRefundModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.REFUND
        }
        onClose={onCloseModal}
        onSuccess={() => {
          onRefresh();
          onCloseModal();
        }}
        pkEcomStoreCart={
          modalState.action === VIEW_ACTIONS_ENUM.REFUND
            ? modalState.item
            : null
        }
      />
    </HeaderView>
  );
};

export default StoreOrderTable;
