import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@mantine/core';
import dayjs from 'dayjs';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { currencyFormat } from '../../../helpers/format';

const isRenderSameAsBefore = (prevProps, nextProps) =>
  prevProps.showFirstColumnBorder === nextProps.showFirstColumnBorder &&
  prevProps.value === nextProps.value &&
  prevProps.compareValue === nextProps.compareValue;

const ReportTableColumn = React.memo(
  ({
    header,
    value,
    compareValue,
    index,
    showFirstColumnBorder,
    isSummary
  }) => {
    const isNumber =
      header.dataType === 'currency' ||
      header.dataType === 'number' ||
      header.dataType === 'percentage';

    const formatColumnValue = (v) => {
      switch (header.dataType) {
        case 'currency':
          return currencyFormat(v / 100);
        case 'percentage':
          return `${Number(value)?.toFixed(2)}%`;
        case 'number':
          return v;
        case 'date':
          if (v && !v.includes('-')) {
            return dayjs(v).format('MMM DD, YYYY');
          }
          return v;
        default:
          return v;
      }
    };

    const calculatePercentageDifference = (num1, num2) => {
      if (!num2) {
        return '-';
      }
      const change = ((num1 - num2) / Math.abs(num2)) * 100;
      return `${change.toFixed(2)}%`;
    };

    return (
      <Table.Td
        style={{
          padding: 0,
          minWidth: 175,
          ...(index === 0 && {
            position: 'sticky',
            left: 0,
            backgroundColor: isSummary ? '#F1F1F1' : '#FFF'
          })
        }}
      >
        <AppFlexbox
          style={{
            padding: '8px 10px',
            justifyContent: isNumber ? 'end' : 'start',
            ...(index === 0 && {
              borderRight: showFirstColumnBorder ? 'solid 1px #dee2e6' : 'none'
            })
          }}
        >
          <AppStack
            style={{
              gap: 0,
              whiteSpace: 'nowrap',
              textAlign: isNumber ? 'end' : 'start'
            }}
          >
            <AppText
              style={{
                fontSize: 14,
                fontWeight: isSummary ? 500 : 'normal'
              }}
            >
              {formatColumnValue(value)}
            </AppText>
            {(compareValue || compareValue === 0) && (
              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#666'
                }}
              >
                {formatColumnValue(compareValue)}
              </AppText>
            )}

            {(compareValue || compareValue === 0) && isSummary && (
              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#666'
                }}
              >
                {index === 0
                  ? '% Difference'
                  : calculatePercentageDifference(value, compareValue)}
              </AppText>
            )}
          </AppStack>
        </AppFlexbox>
      </Table.Td>
    );
  },
  isRenderSameAsBefore
);

ReportTableColumn.propTypes = {
  compareValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  header: PropTypes.object,
  index: PropTypes.number,
  isSummary: PropTypes.bool,
  showFirstColumnBorder: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ReportTableColumn;
