/* eslint-disable no-use-before-define */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Select, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { INPUT_CONTROL_TYPE_ENUM } from '../../../config/constants';
import { currencyFormat } from '../../../helpers/format';
import CustomerFileInput from '../../common/CustomerFileInput';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';

const ProductInputDisplay = ({
  ecomVendorProductInput,
  value,
  onChange,
  pkEcomStore,
  baseStoreUrl,
  onNavigate,
  ...rest
}) => {
  const { state: authState } = useContext(AuthContext);
  const label =
    ecomVendorProductInput.adjustedCost &&
    ecomVendorProductInput.adjustedCost > 0
      ? `${ecomVendorProductInput.label} +${currencyFormat(
          ecomVendorProductInput.adjustedCost / 100
        )}`
      : ecomVendorProductInput.label;

  return ecomVendorProductInput.fkRegFormControlType.toString() ===
    INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT.toString() ? (
    <TextInput
      description={ecomVendorProductInput.instructions}
      label={label}
      onChange={onChange ? (e) => onChange(e.currentTarget.value) : null}
      required={!pkEcomStore && ecomVendorProductInput.isRequired}
      value={value ?? ''}
      {...rest}
    />
  ) : ecomVendorProductInput.fkRegFormControlType.toString() ===
    INPUT_CONTROL_TYPE_ENUM.DROP_DOWN.toString() ? (
    <Select
      clearable
      data={ecomVendorProductInput.ecomVendorProductInputOptions
        .sort((a, b) => a.sort - b.sort)
        .map((o) => ({
          value: o.value,
          label:
            o.adjustedCost && o.adjustedCost > 0
              ? `${o.value} +${currencyFormat(o.adjustedCost / 100)}`
              : o.value
        }))}
      description={ecomVendorProductInput.instructions}
      label={ecomVendorProductInput.label}
      onChange={onChange ? (v) => onChange(v) : null}
      required={!pkEcomStore && ecomVendorProductInput.isRequired}
      searchable
      value={value ?? null}
      {...rest}
    />
  ) : ecomVendorProductInput.fkRegFormControlType.toString() ===
    INPUT_CONTROL_TYPE_ENUM.DATE.toString() ? (
    <DateInput
      description={ecomVendorProductInput.instructions}
      label={label}
      onChange={onChange ? (v) => onChange(v) : null}
      required={!pkEcomStore && ecomVendorProductInput.isRequired}
      value={value ?? null}
      {...rest}
    />
  ) : ecomVendorProductInput.fkRegFormControlType.toString() ===
    INPUT_CONTROL_TYPE_ENUM.CHECKBOX.toString() ? (
    <Checkbox
      checked={value?.toLowerCase() === 'yes'}
      description={ecomVendorProductInput.instructions}
      label={label}
      onChange={
        onChange
          ? () => onChange(value?.toLowerCase() !== 'yes' ? 'yes' : 'no')
          : null
      }
      required={!pkEcomStore && ecomVendorProductInput.isRequired}
      style={{ padding: '8px 0px' }}
      styles={{
        label: { fontWeight: 500, cursor: 'pointer' },
        input: { cursor: 'pointer' }
      }}
      {...rest}
    />
  ) : ecomVendorProductInput.fkRegFormControlType.toString() ===
    INPUT_CONTROL_TYPE_ENUM.FILE_UPLOAD.toString() ? (
    <CustomerFileInput
      description={ecomVendorProductInput.instructions}
      label={label}
      onChange={(val, type) => {
        if (!authState.isAuthenticated) {
          triggerNotification(
            'You must be logged in to upload files.',
            'error'
          );
          onNavigate(`${baseStoreUrl}/account/login`);
        }
        else {
          onChange(val, type);
        }
      }}
      pkEcomStore={pkEcomStore}
      required={!pkEcomStore && ecomVendorProductInput.isRequired}
      value={value}
      {...rest}
    />
  ) : null;
};

ProductInputDisplay.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomVendorProductInput: PropTypes.object,
  isStoreAdminViewing: PropTypes.bool,
  onChange: PropTypes.func,
  onNavigate: PropTypes.func,
  pkEcomStore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.any
};

export default ProductInputDisplay;
