import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM,
  ECOM_ENTITY_TYPES
} from '../../../../../config/constants';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';
import WsCollage from './WsCollage';

const DESKTOP_LAYOUT_ENUM = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

const HEADING_SIZE_STYLE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  HUGE: 'huge'
};

const MOBILE_LAYOUT_ENUM = {
  COLLAGE: 'COLLAGE',
  COLUMN: 'COLUMN'
};

const configNameValues = {
  heading: 'heading',
  headingSize: 'headingSize',
  desktopLayout: 'desktopLayout',
  mobileLayout: 'mobileLayout',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  [configNameValues.heading]: 'Collage',
  [configNameValues.headingSize]: HEADING_SIZE_STYLE_ENUM.LARGE,
  [configNameValues.desktopLayout]: DESKTOP_LAYOUT_ENUM.LEFT,
  [configNameValues.mobileLayout]: MOBILE_LAYOUT_ENUM.COLLAGE,
  [configNameValues.colorScheme]: '1'
};

const configOptions = {
  [configNameValues.heading]: {
    label: 'Heading',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.headingSize]: {
    label: 'Heading size',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      {
        value: HEADING_SIZE_STYLE_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.HUGE,
        label: 'Huge'
      }
    ]
  },
  [configNameValues.desktopLayout]: {
    label: 'Desktop layout',
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    options: [
      {
        value: DESKTOP_LAYOUT_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: DESKTOP_LAYOUT_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  },
  [configNameValues.mobileLayout]: {
    label: 'Mobile layout',
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    options: [
      {
        value: MOBILE_LAYOUT_ENUM.COLLAGE,
        label: 'Collage'
      },
      {
        value: MOBILE_LAYOUT_ENUM.COLUMN,
        label: 'Column'
      }
    ]
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLAGE,
  title: 'Collage',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsCollage,
  configOptions,
  maxItemCount: 3,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE
      ],
      defaultCount: 1,
      maxCount: null
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.PRODUCT
      ],
      defaultCount: 1,
      maxCount: null
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.COLLECTION
      ],
      defaultCount: 1,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  HEADING_SIZE_STYLE_ENUM,
  MOBILE_LAYOUT_ENUM
};
