import { LetterT } from 'tabler-icons-react';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import WsTitleBlock from './WsTitleBlock';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  title: 'Title',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TITLE,
  icon: LetterT,
  Component: WsTitleBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text,
  displayFunctions: {getTitleValue: (ecomStoreProduct) => ecomStoreProduct.ecomVendorProduct.name}
};

export { config, configOptions, configNameValues, configDefaultValues };
