/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mantine/core';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import AppText from '../../../../common/AppText';
import AppFlexbox from '../../../../common/AppFlexbox';
import WsBlock from '../../blocks/WsBlock';

const WsMultiColumn = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  ecomStoreTheme,
  baseStoreUrl,
  onNavigate,
  ...rest
}) => {
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];

  const button1Style =
    config.buttonIsOutlineStyle === 'true'
      ? {
          textColor: colorScheme.textColor,
          buttonColor: colorScheme.outlineButtonColor,
          buttonTextColor: colorScheme.outlineButtonColor,
          variant: 'outline'
        }
      : {
          textColor: colorScheme.textColor,
          buttonColor: colorScheme.solidButtonColor,
          buttonTextColor: colorScheme.solidButtonLabel,
          variant: 'filled'
        };

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack style={{ flex: 1, gap: 10 }}>
        <AppText
          style={{ fontWeight: 700, fontSize: config.headingSize * 1 ?? 24 }}
        >
          {config.heading}
        </AppText>
        <AppStack style={{ gap: 20 }}>
          {blocks.length > 0 && (
            <Grid columns={3}>
              {blocks.map((b) => (
                <Grid.Col key={b.key} span={1}>
                  <WsBlock
                    baseStoreUrl={baseStoreUrl}
                    colorScheme={colorScheme}
                    ecomStoreTheme={ecomStoreTheme}
                    onNavigate={onNavigate}
                    {...b}
                  />
                </Grid.Col>
              ))}
            </Grid>
          )}
          <AppFlexbox
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            {config.buttonLabel && (
              <Button
                color={button1Style.buttonColor}
                component={config.buttonLink ? 'a' : 'button'}
                href={config.buttonLink}
                radius="md"
                size={
                  config.buttonUseCompactStyle === 'true'
                    ? `compact-${config.buttonSize}`
                    : config.buttonSize
                }
                style={{ color: button1Style.buttonTextColor }}
                type="button"
                variant={button1Style.variant}
              >
                {config.buttonLabel}
              </Button>
            )}
          </AppFlexbox>
        </AppStack>
      </AppStack>
    </WsSectionContainer>
  );
};

WsMultiColumn.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsMultiColumn;
