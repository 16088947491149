import { CreditCard } from 'tabler-icons-react';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import WsBuyButtonsBlock from './WsBuyButtonsBlock';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  title: 'Buy buttons',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUY_BUTTONS,
  icon: CreditCard,
  Component: WsBuyButtonsBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
