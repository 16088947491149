import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mantine/core';

const AppCard = React.forwardRef(({ children, ...rest }, ref) => (
  <Card ref={ref} {...rest} style={{ padding: 16, ...rest.style }}>
    {children}
  </Card>
));

AppCard.propTypes = { children: PropTypes.node, innerRef: PropTypes.any };

export default AppCard;
