/* eslint-disable import/no-cycle */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Grid, Image, ThemeIcon } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import classNames from 'classnames';
import { ZoomIn } from 'tabler-icons-react';
import AppStack from '../../../../common/AppStack';
import {
  MEDIA_LAYOUT_ENUM,
  MEDIA_SIZE_ENUM
} from './wsProductInformationConfig';
import AppFlexbox from '../../../../common/AppFlexbox';
import * as mantineClasses from '../../../../../styles/mantineStyles.module.css';
import * as classes from '../../../../../styles/nestedStyles.module.css';
import ResponsiveWebstoreModal from '../../ResponsiveWebstoreModal';

const WsProductMediaShowcase = ({
  switchToVariantImage,
  desktopMediaLayout,
  mediaSize,
  ecomVendorProductMedia,
  colorScheme,
  thumbnailColumns,
  mediaQueries,
  editModeEnabled,
  selectedVariant,
  loading,
  isPreview
}) => {
  const mediaSorted =
    ecomVendorProductMedia?.sort((a, b) => a.sort - b.sort) ?? [];
  const imageRefs = useRef(mediaSorted.map(() => React.createRef()));
  const [selectState, setSelectState] = useState({
    index: 0,
    showZoomModal: false
  });
  const isSmall = mediaSize === MEDIA_SIZE_ENUM.SMALL;
  const { isTabletOrSmaller } = mediaQueries;

  useEffect(() => {
    if (selectedVariant && switchToVariantImage) {
      const variantImageIndex = mediaSorted.findIndex(
        (m) =>
          m.pkEcomVendorProductMedia ===
            selectedVariant?.ecomVendorProductVariant
              ?.fkEcomVendorProductMedia ||
          m.src === selectedVariant?.ecomVendorProductVariant.previewImage
      );

      setSelectState({
        index: variantImageIndex !== -1 ? variantImageIndex : 0,
        showZoomModal: false
      });
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (selectState.showZoomModal) {
      const imageRef = imageRefs.current[selectState.index];
      if (imageRef && imageRef.current) {
        imageRef.current.scrollIntoView({ block: 'start' });
      }
    }
  }, [selectState.showZoomModal]);

  return !loading ? (
    <>
      {desktopMediaLayout === MEDIA_LAYOUT_ENUM.STACKED ||
      desktopMediaLayout === MEDIA_LAYOUT_ENUM.TWO_COLUMNS ? (
        <Grid>
          {mediaSorted
            .slice(0, isPreview ? 1 : mediaSorted.length)
            .map((media, index) => (
              <Grid.Col
                key={media.pkEcomVendorProductMedia}
                span={
                  index === 0 &&
                  desktopMediaLayout === MEDIA_LAYOUT_ENUM.STACKED
                    ? 12
                    : 6
                }
              >
                <AppStack
                  className={classes['hover-image']}
                  onClick={() =>
                    setSelectState({
                      ...selectState,
                      index,
                      showZoomModal: true
                    })
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    cursor: 'pointer'
                  }}
                >
                  <Image
                    fit="contain"
                    h="100%"
                    src={media.src}
                    style={{ minHeight: '100%' }}
                    w="100%"
                  />

                  <AppStack
                    className={classes['hover-image-show']}
                    style={{
                      position: 'absolute',
                      top: 12,
                      left: 12,
                      cursorEvents: 'none'
                    }}
                  >
                    <ThemeIcon
                      color="#FFF"
                      radius={100}
                      size={28}
                      style={{ border: 'solid 1px #BBB' }}
                      variant="filled"
                    >
                      <ZoomIn color="#BBB" size={18} />
                    </ThemeIcon>
                  </AppStack>
                  {index === 0 &&
                    desktopMediaLayout === MEDIA_LAYOUT_ENUM.STACKED && (
                      <Badge
                        color="green"
                        size="md"
                        style={{ position: 'absolute', right: 8, top: 8 }}
                        variant="filled"
                      >
                        Free Shipping
                      </Badge>
                    )}
                </AppStack>
              </Grid.Col>
            ))}
        </Grid>
      ) : (
        <AppStack style={{ width: '100%' }}>
          <AppStack
            className={classes['hover-image']}
            onClick={() =>
              setSelectState({ ...selectState, showZoomModal: true })
            }
            style={{ width: '100%', position: 'relative', cursor: 'pointer' }}
          >
            <Image
              fit="contain"
              src={mediaSorted[selectState.index]?.src}
              w="100%"
            />
            <AppStack
              className={classes['hover-image-show']}
              style={{
                position: 'absolute',
                top: 12,
                left: 12,
                cursorEvents: 'none'
              }}
            >
              <ThemeIcon
                color="#FFF"
                radius={100}
                size={28}
                style={{ border: 'solid 1px #000' }}
                variant="filled"
              >
                <ZoomIn color="#BBB" size={18} />
              </ThemeIcon>
            </AppStack>

            <Badge
              color="green"
              size="md"
              style={{ position: 'absolute', right: 8, top: 8 }}
              variant="filled"
            >
              Free Shipping
            </Badge>
          </AppStack>

          {!isPreview &&
            (desktopMediaLayout === MEDIA_LAYOUT_ENUM.CAROUSEL_THUMBNAILS ? (
              <AppFlexbox style={{ padding: '0px 80px' }}>
                <Carousel
                  align="start"
                  classNames={mantineClasses}
                  controlSize={35}
                  draggable={mediaSorted.length > 1}
                  slideGap="sm"
                  slideSize={isSmall ? '25%' : '20%'}
                  slidesToScroll={isSmall ? 3 : 4}
                  styles={{
                    controls: {
                      left: -55,
                      right: -55
                    }
                  }}
                  withControls={mediaSorted.length > 1}
                >
                  {mediaSorted.map((i, index) => (
                    <Carousel.Slide key={i.pkEcomVendorProductMedia}>
                      <AppStack
                        className={classNames(
                          classes['hover-clickable-image'],
                          classes['hover-button']
                        )}
                        onClick={() => setSelectState({ index })}
                        style={{
                          flex: 1,
                          width: '100%',
                          height: '100%',
                          overflow: 'hidden',
                          cursor: 'pointer',
                          position: 'relative'
                        }}
                      >
                        {selectState.index === index && (
                          <AppStack
                            style={{
                              position: 'absolute',
                              top: 0,
                              width: '100%',
                              height: '100%',
                              boxSizing: 'border-box',
                              border: `2px solid ${colorScheme.outlineButtonColor}`,
                              cursorEvents: 'none'
                            }}
                          />
                        )}
                        <Image
                          fit="contain"
                          h="100%"
                          src={i.src}
                          style={{ minHeight: '100%' }}
                          w="100%"
                        />
                      </AppStack>
                    </Carousel.Slide>
                  ))}
                </Carousel>
              </AppFlexbox>
            ) : (
              <Grid columns={thumbnailColumns}>
                {mediaSorted.map((media, index) => (
                  <Grid.Col key={media.pkEcomVendorProductMedia} span={1}>
                    <AppStack
                      className={classes['hover-clickable-image']}
                      onClick={() => setSelectState({ index })}
                      style={{
                        flex: 1,
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        position: 'relative'
                      }}
                    >
                      {selectState.index === index && (
                        <AppStack
                          style={{
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                            height: '100%',
                            boxSizing: 'border-box',
                            border: `2px solid ${colorScheme.outlineButtonColor}`,
                            cursorEvents: 'none'
                          }}
                        />
                      )}
                      <Image
                        fit="contain"
                        h="100%"
                        src={media.src}
                        style={{ minHeight: '100%' }}
                        w="100%"
                      />
                    </AppStack>
                  </Grid.Col>
                ))}
              </Grid>
            ))}
        </AppStack>
      )}
      <ResponsiveWebstoreModal
        closeOnClick
        editModeEnabled={editModeEnabled}
        fullScreen
        keepMounted
        mediaQueries={mediaQueries}
        noHeader
        onClose={() =>
          setSelectState({
            ...selectState,
            showZoomModal: false
          })
        }
        opened={selectState.showZoomModal}
        styles={{ content: { cursor: 'zoom-out' } }}
        transitionProps={{ duration: 0 }}
        trapFocus={false}
      >
        <AppStack
          style={{
            gap: 8,
            padding: isTabletOrSmaller ? '0px 10px' : '0px 60px'
          }}
        >
          {mediaSorted.map((media, index) => (
            <AppStack
              key={media.pkEcomVendorProductMedia}
              ref={imageRefs.current[index]}
              style={{ width: '100%', border: 'solid 1px #F1F1F1' }}
            >
              <Image
                fit="contain"
                h="100%"
                src={media.src}
                style={{ minHeight: '100%' }}
                w="100%"
              />
            </AppStack>
          ))}
        </AppStack>
      </ResponsiveWebstoreModal>
    </>
  ) : (
    <></>
  );
};

WsProductMediaShowcase.propTypes = {
  colorScheme: PropTypes.object,
  desktopMediaLayout: PropTypes.string,
  ecomVendorProductMedia: PropTypes.array,
  editModeEnabled: PropTypes.bool,
  isPreview: PropTypes.bool,
  loading: PropTypes.bool,
  mediaQueries: PropTypes.object,
  mediaSize: PropTypes.string,
  selectedVariant: PropTypes.object,
  switchToVariantImage: PropTypes.bool,
  thumbnailColumns: PropTypes.number
};

export default WsProductMediaShowcase;
