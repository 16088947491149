import { Message } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsAccordionBlock from './WsAccordionBlock';

const configNameValues = {
  heading: 'heading',
  icon: 'icon',
  content: 'content',
  ecomStorePage: 'ecomStorePage'
};

const configDefaultValues = {
  [configNameValues.heading]: 'Collapsible row',
  [configNameValues.icon]: '',
  [configNameValues.content]: '',
  [configNameValues.ecomStorePage]: ''
};

const configOptions = {
  [configNameValues.heading]: {
    label: 'Heading',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.content]: {
    label: 'Content',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.ecomStorePage]: {
    label: 'Content from page',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStorePage
  }
};

const config = {
  title: 'Accordion',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ACCORDION,
  icon: Message,
  Component: WsAccordionBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
