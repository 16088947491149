import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Checkbox, List } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppText from '../../common/AppText';
import { singularPluralFormat } from '../../../helpers/format';
import { triggerNotification } from '../../../helpers/notification';
import {
  ECOM_PRODUCT_PRICING_TYPE_ENUM,
  ECOM_PRODUCT_STATUS_ENUM
} from '../../../config/constants';
import CustomNumberInput from '../../common/CustomNumberInput';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import SetVendorProductInputsModal from './SetVendorProductInputsModal';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';

const ConfirmVendorProductsModal = ({
  isOpen,
  onClose,
  ecomVendor,
  ecomVendorCollection
}) => {
  const naviate = useNavigate();
  const { state: helperState } = useContext(HelperContext);
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    createStoreProducts,
    createStoreProductsFromVendor,
    fetchStoreSetupSummary,
    fetchStoreProductOptions
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    loading: false,
    hasConfirmedInputs: false,
    showConfirmMinimumQuantity: false,
    confirmMinimumQuantity: false,
    showInputModal: false,
    switchToPricing: false,
    setupPricing: false,
    percentagePricing: true,
    pricingAmount: '',
    roundingAdjustment: '',
    setProductToLive: false,
    selectedCount: 0
  });
  const {
    cart: catalogCart,
    removeProducts,
    clearCart
  } = useVendorCatalogCheckout();

  const selectedProducts = catalogCart.ecomVendorProducts;
  const variantCount = selectedProducts.reduce(
    (r, c) => r + c.ecomVendorProductVariantCount,
    0
  );
  const hasConfirmedInputs =
    helperState.vendorCatalog.inputProducts.length === 0 ||
    formState.hasConfirmedInputs;

  const unavailableProducts = selectedProducts.filter(
    (p) =>
      p.delete ||
      p.fkEcomProductStatus.toString() !==
        ECOM_PRODUCT_STATUS_ENUM.ACTIVE.toString()
  );
  const alreadyAddedProducts = selectedProducts.filter((p) =>
    state.ecomStoreProducts.value.some(
      (f) => f.ecomVendorProduct.uuid === p.uuid
    )
  );
  const showUnavailableProductsPrompt =
    alreadyAddedProducts.length > 0 || unavailableProducts.length > 0;

  useEffect(() => {
    if (isOpen) {
      setFormState({
        ...formState,
        selectedCount: selectedProducts.length,
        hasConfirmedInputs:
          helperState.vendorCatalog.inputProducts.length === 0,
        loading: false,
        showConfirmMinimumQuantity: false
      });
    }
  }, [isOpen]);

  const onSuccessCallback = (data) => {
    setFormState({
      ...formState,
      loading: false
    });
    onClose();
    triggerNotification(
      `${singularPluralFormat(
        data.length,
        'product',
        'products'
      )} added to your store!`,
      'success'
    );
    fetchStoreSetupSummary(authState.pkEcomStore);
    fetchStoreProductOptions(authState.pkEcomStore, { skipLoading: true });
    clearCart();
    if (formState.switchToPricing) {
      setTimeout(() => {
        naviate(
          `/merchant/products/bulk-edit?ids=${data
            .map((p) => p.uuid)
            .join(',')}`
        );
      }, 200);
    }
  };

  const onErrorCallback = (error) => {
    setFormState({
      ...formState,
      loading: false
    });
    fetchStoreProductOptions(
      authState.pkEcomStore,
      { skipLoading: true },
      (data) => {
        const newSelectedProducts = selectedProducts.filter(
          (p) => !data.some((d) => d.ecomVendorProduct.uuid === p.uuid)
        );
        if (newSelectedProducts.length === 0) {
          onClose();
        }
        if (selectedProducts.length > newSelectedProducts.length) {
          removeProducts([
            ...selectedProducts
              .filter(
                (p) => !newSelectedProducts.some((np) => np.uuid === p.uuid)
              )
              .map((p) => p.uuid)
          ]);
          triggerNotification(
            `Products already in your store were removed from the selection. Please try again.`
          );
        }
        else {
          triggerNotification(error);
        }
      },
      () => {
        setFormState({
          ...formState,
          loading: false
        });
        triggerNotification(error);
      }
    );
  };

  return showUnavailableProductsPrompt ? (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: () => {
          removeProducts([
            ...alreadyAddedProducts.map((p) => p.uuid),
            ...unavailableProducts.map((p) => p.uuid)
          ]);
          onClose();
        },
        submitColor: 'red',
        submitTitle: 'Remove unavailable',
        cancelTitle: 'Cancel',
        isLoading: formState.loading
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Selected products are unavailable"
    >
      <AppStack>
        <Alert
          color="#c40000"
          icon={<AlertCircle size={18} />}
          title="Products unavailable"
          variant="outline"
        >
          <AppStack style={{ gap: 12 }}>
            <AppText style={{ fontSize: 14 }}>
              The following products are already in your store or have been
              removed by the vendor. Please remove them from your selection
              before adding to your store.
            </AppText>

            <List withPadding>
              {alreadyAddedProducts.map((p) => (
                <List.Item key={p.pkEcomVendorProduct}>
                  <AppText style={{ fontSize: 14 }}>{p.name}</AppText>
                </List.Item>
              ))}
              {unavailableProducts.map((p) => (
                <List.Item key={p.pkEcomVendorProduct}>
                  <AppText style={{ fontSize: 14 }}>{p.name}</AppText>
                </List.Item>
              ))}
            </List>
          </AppStack>
        </Alert>
        <AppText
          style={{
            textAlign: 'center',
            fontSize: 14,
            color: '#666',
            fontWeight: 500
          }}
        >
          By clicking "Remove unavailable" you will remove the unavailable
          products from your selection. You can then add the remaining products
          to your store.
        </AppText>
      </AppStack>
    </ResponsiveModal>
  ) : (
    <>
      <ResponsiveModal
        formSectionProps={{
          onCancel: onClose,
          onSubmit: () => {
            if (
              !formState.showConfirmMinimumQuantity &&
              selectedProducts.some((p) => !!p.minimumQuantity)
            ) {
              setFormState({
                ...formState,
                showConfirmMinimumQuantity: true
              });
            }
            else {
              setFormState({
                ...formState,
                loading: true
              });
              if (ecomVendor || ecomVendorCollection) {
                createStoreProductsFromVendor(
                  authState.pkEcomStore,
                  {
                    fkEcomVendor: ecomVendor?.pkEcomVendor,
                    fkEcomVendorCollection:
                      ecomVendorCollection?.pkEcomVendorCollection,
                    ...(formState.setupPricing
                      ? {
                          pricingAmount: Math.round(
                            formState.pricingAmount * 100
                          ),
                          roundingAdjustment: formState.roundingAdjustment,
                          fkEcomPricingType: formState.percentagePricing
                            ? ECOM_PRODUCT_PRICING_TYPE_ENUM.PERCENTAGE_ADJUSTMENT
                            : ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT,
                          fkEcomProductStatus: formState.setProductToLive
                            ? ECOM_PRODUCT_STATUS_ENUM.ACTIVE
                            : ECOM_PRODUCT_STATUS_ENUM.DRAFT
                        }
                      : {})
                  },
                  onSuccessCallback,
                  onErrorCallback
                );
              }
              else {
                createStoreProducts(
                  authState.pkEcomStore,
                  {
                    storeProducts: selectedProducts.map((p) => ({
                      fkEcomVendorProduct: p.pkEcomVendorProduct,
                      ecomStoreProductInputAnswers: catalogCart.storageCart.ecomVendorProducts.find(
                        (c) => c.uuid === p.uuid
                      )?.ecomStoreProductInputAnswers,
                      ...(formState.setupPricing
                        ? {
                            pricingAmount: Math.round(
                              formState.pricingAmount * 100
                            ),
                            roundingAdjustment: formState.roundingAdjustment,
                            fkEcomPricingType: formState.percentagePricing
                              ? ECOM_PRODUCT_PRICING_TYPE_ENUM.PERCENTAGE_ADJUSTMENT
                              : ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT,
                            fkEcomProductStatus: formState.setProductToLive
                              ? ECOM_PRODUCT_STATUS_ENUM.ACTIVE
                              : ECOM_PRODUCT_STATUS_ENUM.DRAFT
                          }
                        : {})
                    }))
                  },
                  onSuccessCallback,
                  onErrorCallback
                );
              }
            }
          },
          submitColor: 'blue',
          submitTitle: 'Add to your store',
          cancelTitle: 'Cancel',
          isLoading: formState.loading
        }}
        isOpen={hasConfirmedInputs && isOpen}
        onClose={onClose}
        title={
          ecomVendorCollection
            ? `Add all ${ecomVendorCollection.name} products?`
            : ecomVendor
            ? `Add all ${ecomVendor.name} products?`
            : `Add ${singularPluralFormat(
                formState.selectedCount,
                'product',
                'products'
              )} to your store?`
        }
      >
        {formState.showConfirmMinimumQuantity ? (
          <AppStack style={{ gap: 20 }}>
            <Alert
              color="#eca70a"
              icon={<AlertCircle size={18} />}
              title="Confirm minimum order quantity"
              variant="outline"
            >
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }}>
                  Please note that the following products have a minimum order
                  quantity set by the vendor. You must reach the vendors minimum
                  order count in order for products to be fulfilled.
                </AppText>

                <List withPadding>
                  {selectedProducts
                    .filter((p) => p.minimumQuantity)
                    .map((p) => (
                      <List.Item key={p.pkEcomVendorProduct}>
                        <AppText style={{ fontSize: 14 }}>
                          {p.name} - {p.minimumQuantity}
                        </AppText>
                      </List.Item>
                    ))}
                </List>

                <AppText
                  style={{ fontSize: 14, fontWeight: 500, marginTop: 10 }}
                >
                  You are responsible for all refunds to customers as a result
                  to orders not being fulfilled.
                </AppText>

                <Checkbox
                  checked={formState.confirmMinimumQuantity}
                  disabled={formState.loading}
                  label="I understand and agree to the minimum order quantity."
                  onChange={() =>
                    setFormState({
                      ...formState,
                      confirmMinimumQuantity: !formState.confirmMinimumQuantity
                    })
                  }
                  required
                  style={{ marginTop: 5, fontWeight: 500 }}
                />
              </AppStack>
            </Alert>
          </AppStack>
        ) : (
          <AppStack style={{ gap: 20 }}>
            <Alert color="blue" variant="outline">
              <AppStack style={{ gap: 10 }}>
                {ecomVendorCollection ? (
                  <AppText style={{ fontSize: 14 }}>
                    Are you sure you want to add all the products from{' '}
                    <b>{ecomVendorCollection.name}</b> to your store?
                  </AppText>
                ) : ecomVendor ? (
                  <AppText style={{ fontSize: 14 }}>
                    Are you sure you want to add all the products from{' '}
                    <b>{ecomVendor.name}</b> to your store?
                  </AppText>
                ) : (
                  <AppText style={{ fontSize: 14 }}>
                    You are about to add{' '}
                    <b>
                      {singularPluralFormat(
                        formState.selectedCount,
                        'product',
                        'products'
                      )}
                    </b>{' '}
                    with a total of{' '}
                    <b>
                      {singularPluralFormat(
                        variantCount,
                        'variant',
                        'variants'
                      )}
                    </b>
                    .
                  </AppText>
                )}

                <AppText style={{ fontSize: 14 }}>
                  You will have to edit the products and set your pricing before
                  they are live in your store. You can also add more details
                  like images, descriptions, and more.
                </AppText>

                <Checkbox
                  checked={formState.switchToPricing}
                  disabled={formState.loading}
                  label="Switch to pricing after adding."
                  onChange={() =>
                    setFormState({
                      ...formState,
                      switchToPricing: !formState.switchToPricing
                    })
                  }
                  style={{ marginTop: 5, fontWeight: 500 }}
                />
              </AppStack>
            </Alert>

            <AppStack style={{ gap: 16 }}>
              <Checkbox
                checked={formState.setupPricing}
                disabled={formState.loading}
                label="Set base pricing for all products."
                onChange={() =>
                  setFormState({
                    ...formState,
                    setupPricing: !formState.setupPricing
                  })
                }
              />

              {formState.setupPricing && (
                <>
                  <CustomNumberInput
                    allowNegative={false}
                    decimalScale={2}
                    disabled={formState.loading}
                    fixedDecimalScale
                    label={`Margin (in ${
                      formState.percentagePricing ? '%' : '$'
                    })`}
                    leftSection={
                      !formState.percentagePricing && (
                        <AppText
                          style={{
                            fontWeight: 500,
                            color: '#666',
                            fontSize: 14
                          }}
                        >
                          $
                        </AppText>
                      )
                    }
                    onChange={(v) =>
                      setFormState({
                        ...formState,
                        pricingAmount: v
                      })
                    }
                    placeholder="30.00"
                    required
                    rightSection={
                      formState.percentagePricing ? (
                        <AppText
                          style={{
                            fontWeight: 500,
                            color: '#666',
                            fontSize: 14
                          }}
                        >
                          %
                        </AppText>
                      ) : (
                        <></>
                      )
                    }
                    value={formState.pricingAmount}
                  />

                  <Button.Group>
                    <Button
                      color={formState.percentagePricing ? 'blue' : 'dark'}
                      disabled={formState.loading}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          percentagePricing: true
                        });
                      }}
                      size="compact-md"
                      style={{ flex: 1 }}
                      variant={
                        formState.percentagePricing ? 'filled' : 'subtle'
                      }
                    >
                      %
                    </Button>
                    <Button
                      color={!formState.percentagePricing ? 'blue' : 'dark'}
                      disabled={formState.loading}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          percentagePricing: false
                        });
                      }}
                      size="compact-md"
                      style={{ flex: 1 }}
                      variant={
                        !formState.percentagePricing ? 'filled' : 'subtle'
                      }
                    >
                      $
                    </Button>
                  </Button.Group>

                  <CustomNumberInput
                    allowNegative={false}
                    clampBehavior="strict"
                    decimalScale={0}
                    disabled={formState.loading}
                    fixedDecimalScale
                    label="Rounding Adjustment (in cents)"
                    max={99}
                    min={0}
                    onChange={(v) => {
                      setFormState({
                        ...formState,
                        roundingAdjustment: v > 99 ? 99 : v
                      });
                    }}
                    placeholder=".95"
                    required
                    value={
                      formState.roundingAdjustment > 99
                        ? 99
                        : formState.roundingAdjustment
                    }
                  />

                  <AppText
                    style={{
                      fontSize: 14,
                      padding: '0px 40px',
                      color: '#666',
                      textAlign: 'center'
                    }}
                  >
                    All products being added will have the same pricing. You can
                    change the pricing for individual products later.
                  </AppText>

                  <Checkbox
                    checked={formState.setProductToLive}
                    description="With pricing set you can make the products live after adding. This will make them visible in your store right away. Otherwise they will be hidden until you change their status."
                    disabled={formState.loading}
                    label="Make products live after adding."
                    onChange={() =>
                      setFormState({
                        ...formState,
                        setProductToLive: !formState.setProductToLive
                      })
                    }
                    styles={{ description: { fontSize: 14 } }}
                  />
                </>
              )}
            </AppStack>
          </AppStack>
        )}
      </ResponsiveModal>

      <SetVendorProductInputsModal
        isOpen={isOpen && !hasConfirmedInputs}
        onClose={() => setFormState({ ...formState, hasConfirmedInputs: true })}
      />
    </>
  );
};

ConfirmVendorProductsModal.propTypes = {
  ecomVendor: PropTypes.object,
  ecomVendorCollection: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default ConfirmVendorProductsModal;
