import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';

const SortableListItem = ({ id, item, renderItem, isOverlay }) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return renderItem(item, {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    style,
    isOverlay
  });
};

SortableListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node,
  isOverlay: PropTypes.bool,
  sort: PropTypes.number
};

export default SortableListItem;
