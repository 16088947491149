/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { SLIDESHOW_SLIDE_HEIGHT_ENUM } from './wsSlideshowConfig';
import AppCarousel from '../../../../common/AppCarousel';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import WsBlock from '../../blocks/WsBlock';
import placeholderImage from '../../../../../images/sportsheadz-header-bg.png';

const WsSlideshow = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  isSelected,
  mediaQueries,
  ecomStoreTheme,
  baseStoreUrl,
  onNavigate,
  ...rest
}) => {
  const pauseOnHover = config.pauseOnHover === 'true';
  const autoRotate = config.autoRotate === 'true';
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const adaptToFirstImage =
    config.slideHeight === SLIDESHOW_SLIDE_HEIGHT_ENUM.ADAPT_TO_IMAGE;

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      isSelected={isSelected}
      mediaQueries={mediaQueries}
      noPadding
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack
        style={{
          gap: 0,
          height:
            config.slideHeight === SLIDESHOW_SLIDE_HEIGHT_ENUM.ADAPT_TO_IMAGE
              ? 'unset'
              : config.slideHeight === SLIDESHOW_SLIDE_HEIGHT_ENUM.SMALL
              ? 450
              : config.slideHeight === SLIDESHOW_SLIDE_HEIGHT_ENUM.MEDIUM
              ? 600
              : config.slideHeight === SLIDESHOW_SLIDE_HEIGHT_ENUM.CUSTOM
              ? `${config.customSlideHeight}px`
              : 750
        }}
      >
        <AppCarousel
          adaptToFirstImage={adaptToFirstImage}
          autoRotate={autoRotate}
          changeEvery={config.changeEvery}
          images={blocks.map((block, i) => ({
            key: i,
            src: block.config?.image ? block.config.image : placeholderImage,
            flipLeftToRight: block.config?.flipLeftToRight === 'true',
            flipUpsideDown: block.config?.flipUpsideDown === 'true',
            container: (
              <WsBlock
                key={block.key}
                baseStoreUrl={baseStoreUrl}
                colorScheme={
                  colorSchemes[block.config.colorScheme - 1] ?? colorSchemes[0]
                }
                ecomStoreTheme={ecomStoreTheme}
                hideImage
                objectPosition={config.objectPosition}
                onNavigate={onNavigate}
                {...block}
              />
            )
          }))}
          objectPosition={config.objectPosition}
          pauseOnHover={pauseOnHover}
          withControls={false}
        />
      </AppStack>
    </WsSectionContainer>
  );
};

WsSlideshow.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  isSelected: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsSlideshow;
