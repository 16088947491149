import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  ColorInput,
  NumberInput,
  Select,
  Slider,
  Textarea,
  TextInput
} from '@mantine/core';
import { INPUT_CONTROL_TYPE_ENUM } from '../../../config/constants';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import WebstoreBuilderConfigResourceSelect from './WebstoreBuilderConfigResourceSelect';
import WebstoreLinkInput from './WebstoreLinkInput';

const WebstoreBuilderConfigSidebarInput = ({
  label,
  type,
  options,
  value,
  min,
  max,
  unit,
  defaultValue,
  onChange,
  entityType,
  fkEcomStore,
  entities,
  isTextArea,
  searchable,
  clearable,
  placeholder,
  description,
  onAddColorScheme,
  ...rest
}) => {
  const currentValue = value === undefined ? defaultValue : value;

  return type === INPUT_CONTROL_TYPE_ENUM.DROP_DOWN ? (
    <Select
      clearable={clearable}
      data={options ?? []}
      description={description}
      label={label}
      onChange={(v) => {
        if (clearable || v) {
          onChange(v);
        }
      }}
      placeholder={placeholder}
      searchable={searchable}
      styles={{ label: { fontWeight: 'normal' } }}
      value={currentValue ?? null}
    />
  ) : type === INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP ? (
    <AppStack style={{ gap: 5 }}>
      <AppText style={{ fontSize: 14 }}>{label}</AppText>
      <Button.Group style={{ width: '100%' }}>
        {options.map((option) => (
          <Button
            key={option.value}
            color="dark"
            onClick={() => onChange(option.value)}
            size="compact-md"
            style={{
              width: '100%',
              overflow: 'visible',
              whiteSpace: 'nowrap',
              textOverflow: 'unset',
              flexGrow: 1,
              fontSize: 14,
              padding: '0px 4px'
            }}
            variant={option.value === currentValue ? 'filled' : 'outline'}
          >
            {option.label}
          </Button>
        ))}
      </Button.Group>
    </AppStack>
  ) : type === INPUT_CONTROL_TYPE_ENUM.CHECKBOX ? (
    <Checkbox
      checked={currentValue?.toString() === 'true'}
      description={description}
      label={label}
      onChange={() =>
        onChange(currentValue?.toString() === 'true' ? 'false' : 'true')
      }
      style={{ marginTop: 5 }}
      styles={{ label: { cursor: 'pointer' }, input: { cursor: 'pointer' } }}
    />
  ) : type === INPUT_CONTROL_TYPE_ENUM.SLIDER ? (
    <AppStack style={{ gap: 0 }}>
      <AppText style={{ fontSize: 14 }}>{label}</AppText>
      <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
        <Slider
          max={max}
          min={min}
          onChange={(v) => onChange(v?.toString())}
          style={{ flex: 1 }}
          value={currentValue}
        />
        <NumberInput
          clampBehavior="blur"
          max={max}
          min={min}
          onChange={(v) => onChange(v.toString())}
          placeholder={placeholder}
          rightSection={<AppText style={{ fontSize: 12 }}>{unit}</AppText>}
          size="sm"
          style={{ maxWidth: 80 }}
          value={currentValue * 1 ?? 0}
        />
      </AppFlexbox>
    </AppStack>
  ) : type === INPUT_CONTROL_TYPE_ENUM.COLOR ? (
    <ColorInput
      description={description}
      label={label}
      onChange={onChange}
      styles={{ label: { fontWeight: 'normal' } }}
      value={currentValue}
    />
  ) : type === INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT ? (
    isTextArea ? (
      <Textarea
        autosize
        description={description}
        label={label}
        maxLength={255}
        maxRows={8}
        minRows={1}
        onChange={(e) => onChange(e.currentTarget.value.slice(0, 255))}
        placeholder={placeholder}
        styles={{ label: { fontWeight: 'normal' } }}
        value={currentValue}
      />
    ) : (
      <TextInput
        description={description}
        label={label}
        maxLength={255}
        onChange={(e) => onChange(e.currentTarget.value.slice(0, 255))}
        placeholder={placeholder}
        styles={{ label: { fontWeight: 'normal' } }}
        value={currentValue}
      />
    )
  ) : type === 'entity' ? (
    <WebstoreBuilderConfigResourceSelect
      defaultValue={defaultValue}
      entities={entities}
      entityType={entityType}
      fkEcomStore={fkEcomStore}
      label={label}
      onChange={onChange}
      value={value}
      onAddColorScheme={onAddColorScheme}
      {...rest}
    />
  ) : type === INPUT_CONTROL_TYPE_ENUM.LINK ? (
    <WebstoreLinkInput
      fontWeight="normal"
      label={label}
      onChange={onChange}
      {...rest}
    />
  ) : (
    <></>
  );
};

WebstoreBuilderConfigSidebarInput.propTypes = {
  clearable: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entities: PropTypes.object,
  entityType: PropTypes.string,
  fkEcomStore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isTextArea: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAddColorScheme: PropTypes.func,
  description: PropTypes.string
};

export default WebstoreBuilderConfigSidebarInput;
