/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../../../common/AppStack';
import AppCard from '../../../../common/AppCard';
import WsHeadingBlock from '../heading/WsHeadingBlock';
import WsTextBlock from '../text/WsTextBlock';
import WsButtonGroupBlock from '../buttonGroup/WsButtonGroupBlock';

const paddingMap = {
  none: { tablet: 0, default: 0 },
  small: { tablet: 8, default: 12 },
  medium: { tablet: 12, default: 20 },
  large: { tablet: 16, default: 36 },
  extra_large: { tablet: 24, default: 45 }
};

const groupDataByPrefix = (data) => {
  const groupedData = {
    headingConfig: {},
    textConfig: {},
    buttonsConfig: {},
    config: {}
  };

  const handleNewKey = (prefix, key) =>
    `${key.replace(prefix, '')[0].toLowerCase()}${key
      .replace(prefix, '')
      .slice(1)}`;

  Object.entries(data).forEach(([key, value]) => {
    if (key.startsWith('cardHeading')) {
      groupedData.headingConfig[handleNewKey('cardHeading', key)] = value;
    }
    else if (key.startsWith('cardText')) {
      groupedData.textConfig[handleNewKey('cardText', key)] = value;
    }
    else {
      groupedData.config[key] = value;
    }
  });

  return groupedData;
};

const WsContentCardBlock = ({
  config,
  colorScheme,
  themeConfig,
  mediaQueries,
  baseStoreUrl,
  onNavigate,
  ecomStoreTheme
}) => {
  const { isTabletOrSmaller } = mediaQueries;
  const { headingConfig, textConfig, config: cardConfig } = groupDataByPrefix(
    config
  );
  const containerPadding = (paddingMap[cardConfig.containerPadding] ||
    paddingMap.large)[isTabletOrSmaller ? 'tablet' : 'default'];

  return (
    <AppCard
      radius={0}
      style={{
        maxWidth: 900,
        padding: containerPadding,
        backgroundColor: (
          isTabletOrSmaller
            ? cardConfig.showMobileContainer === 'true'
            : cardConfig.showContainerOnDesktop === 'true'
        )
          ? colorScheme.backgroundColor
          : 'unset',
        width: isTabletOrSmaller ? '100%' : 'unset',
        color: colorScheme.textColor
      }}
    >
      <AppStack
        style={{
          flex: 1,
          gap: 5,
          alignItems: cardConfig.desktopContentPosition,
          justifyContent: cardConfig.desktopContentPosition
        }}
      >
        <AppStack
          style={{
            alignItems: cardConfig.desktopContentAlignment,
            justifyContent: cardConfig.desktopContentAlignment,
            textAlign: cardConfig.desktopContentAlignment,
            gap: `${cardConfig.contentSpacing ?? 16}px`,
            maxWidth: cardConfig.maxContentWidth ?? 'unset'
          }}
        >
          {headingConfig.text && (
            <WsHeadingBlock
              baseStoreUrl={baseStoreUrl}
              config={headingConfig}
              ecomStoreTheme={ecomStoreTheme}
              onNavigate={onNavigate}
            />
          )}
          {textConfig.text && (
            <WsTextBlock
              baseStoreUrl={baseStoreUrl}
              config={textConfig}
              ecomStoreTheme={ecomStoreTheme}
              onNavigate={onNavigate}
            />
          )}
          {(cardConfig.button1Text || cardConfig.button2Text) && (
            <WsButtonGroupBlock
              baseStoreUrl={baseStoreUrl}
              colorScheme={colorScheme}
              config={cardConfig}
              ecomStoreTheme={ecomStoreTheme}
              onNavigate={onNavigate}
            />
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

WsContentCardBlock.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsContentCardBlock;
