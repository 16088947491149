/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from '@mantine/core';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import placeholderImage from '../../../../../images/sportsheadz-logo.png';
import AppText from '../../../../common/AppText';

const WsRowContentBlock = ({
  config,
  themeConfig,
  colorScheme,
  leftSidedImage
}) => (
  <AppFlexbox
    style={{
      flex: 1,
      gap: 0,
      flexDirection: leftSidedImage ? 'row-reverse' : 'row'
    }}
  >
    <AppStack style={{ flex: 1 }}>
      <AppStack
        style={{
          flex: 1,
          alignItems: 'start',
          justifyContent: 'center',
          paddingLeft: leftSidedImage ? 40 : 0,
          paddingRight: leftSidedImage ? 0 : 40,
          gap: 10
        }}
      >
        <AppStack style={{ gap: 0 }}>
          <AppText style={{}}>{config.caption}</AppText>
          <AppText style={{ fontWeight: 700, fontSize: 18 }}>
            {config.heading}
          </AppText>
        </AppStack>
        <AppText style={{ fontSize: 16 }}>{config.description}</AppText>
        {config.buttonLabel && <Button>{config.buttonLabel}</Button>}
      </AppStack>
    </AppStack>
    <AppStack style={{ flex: 1, border: 'solid 1px #dee2e6' }}>
      <Image
        fallbackSrc={placeholderImage}
        h="100%"
        src={config.image}
        w="100%"
      />
    </AppStack>
  </AppFlexbox>
);

WsRowContentBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  leftSidedImage: PropTypes.bool,
  themeConfig: PropTypes.object
};

export default WsRowContentBlock;
