import React from 'react';
import PropTypes from 'prop-types';
import { ActionIcon } from '@mantine/core';
import {
  ArrowsMove,
  ChevronRight,
  EyeOff,
  GripVertical
} from 'tabler-icons-react';
import WebstoreBuilderSidebarButton from './WebstoreBuilderSidebarButton';
import * as classes from '../../../styles/nestedStyles.module.css';

const WebstoreBuilderSidebarButtonSortable = ({
  leftSection,
  LeftIcon,
  sortableProps,
  isLaptopOrSmaller,
  mobileEditMode,
  isItemHidden,
  onClick,
  ...rest
}) => (
  <WebstoreBuilderSidebarButton
    controlsHidden={isLaptopOrSmaller}
    fontSize={isLaptopOrSmaller ? 16 : null}
    height={isLaptopOrSmaller ? 45 : null}
    isDragging={sortableProps?.isDragging}
    isItemHidden={isItemHidden}
    leftSection={
      <>
        {mobileEditMode && (
          <ActionIcon
            color="dark"
            size="sm"
            style={{
              height: '100%',
              borderRadius: 8,
              width: 45,
              border: 'none'
            }}
            variant="subtle"
          >
            <ArrowsMove size={22} />
          </ActionIcon>
        )}
        {leftSection}
        <ActionIcon
          className={sortableProps ? classes['hover-button'] : null}
          color="dark"
          onClick={(e) => {}}
          size="sm"
          style={{
            height: '100%',
            borderRadius: 8,
            width: 20,
            border: 'none',
            cursor: sortableProps?.isOverlay ? 'grabbing' : 'pointer'
          }}
          variant={isLaptopOrSmaller ? 'transparent' : 'subtle'}
        >
          {!isLaptopOrSmaller && sortableProps && (
            <GripVertical
              className={classes['hover-show']}
              size={18}
              style={sortableProps.isOverlay ? { display: 'flex' } : null}
            />
          )}

          <LeftIcon
            className={
              !isLaptopOrSmaller && sortableProps ? classes['hover-hide'] : null
            }
            size={isLaptopOrSmaller ? 22 : 18}
            style={
              !isLaptopOrSmaller && sortableProps.isOverlay
                ? { display: 'none' }
                : null
            }
          />
        </ActionIcon>
      </>
    }
    mobileEditMode={mobileEditMode}
    onClick={mobileEditMode ? null : onClick}
    rightSection={
      !mobileEditMode && isLaptopOrSmaller ? (
        <>
          {isItemHidden && <EyeOff size={18} style={{ marginRight: 5 }} />}
          <ChevronRight color="#666" size={18} />
        </>
      ) : null
    }
    sortableProps={sortableProps}
    style={
      !mobileEditMode
        ? {
            paddingLeft: isLaptopOrSmaller ? 10 : null,
            paddingRight: isLaptopOrSmaller ? 10 : null
          }
        : null
    }
    {...rest}
  />
);

WebstoreBuilderSidebarButtonSortable.propTypes = {
  LeftIcon: PropTypes.any,
  isItemHidden: PropTypes.bool,
  isLaptopOrSmaller: PropTypes.bool,
  leftSection: PropTypes.any,
  mobileEditMode: PropTypes.bool,
  onClick: PropTypes.func,
  sortableProps: PropTypes.object
};

export default WebstoreBuilderSidebarButtonSortable;
