/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import WsBlock from '../../blocks/WsBlock';
import AppFlexbox from '../../../../common/AppFlexbox';
import { getDefaultBannerHeight } from '../../../../../helpers/webstoreHelper';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';

const marginMap = {
  none: 0,
  small: 16,
  medium: 24,
  large: 40,
  extra_large: 60
};

const WsLayeredBanner = ({
  blocks,
  config,
  colorSchemes,
  themeConfig,
  mediaQueries,
  onNavigate,
  baseStoreUrl,
  onChangeSectionConfigValues,
  sectionKey,
  editModeEnabled,
  viewPortAdjustment,
  ecomStoreTheme,
  ...rest
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { isTabletOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const adaptImageHeight = config.adaptImageHeight * 1;
  const adaptImageWidth = config.adaptImageWidth * 1;
  const restrictBackgroundImage =
    config.restrictBackgroundImage === 'true' || config.fullWidth !== 'true';
  const restrictBackgroundColor =
    config.restrictBackgroundColor === 'true' || config.fullWidth !== 'true';
  const defaultBannerHeight = getDefaultBannerHeight(
    config,
    adaptImageHeight,
    adaptImageWidth,
    isTabletOrSmaller,
    viewPortAdjustment,
    restrictBackgroundImage ? themeConfig.pageWidth : null
  );
  const pageWidth =
    themeConfig.pageWidth && themeConfig.pageWidth * 1 >= 1000
      ? themeConfig.pageWidth * 1
      : 1000;
  const showImagesBehindOverlay = config.imagesBehindOverlay === 'true';

  const cardContentBlock = blocks.find(
    (b) =>
      b.fkEcomStoreThemeSectionBlockType ===
      ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CONTENT_CARD
  );
  const cardPosition = cardContentBlock?.config?.layeredContentPosition?.split(
    '-'
  ) ?? ['center', 'center'];
  const containerMargin =
    marginMap[cardContentBlock?.config.containerMargin] || marginMap.large;

  useEffect(() => {
    if (config.image) {
      const image = new Image();
      image.src = config.image;
      image.onload = () => {
        if (
          (adaptImageHeight !== image.naturalHeight ||
            adaptImageWidth !== image.naturalWidth) &&
          editModeEnabled &&
          onChangeSectionConfigValues
        ) {
          onChangeSectionConfigValues(sectionKey, [
            {
              name: 'adaptImageHeight',
              value: image.naturalHeight.toString()
            },
            {
              name: 'adaptImageWidth',
              value: image.naturalWidth.toString()
            }
          ]);
        }
        setImageLoaded(true);
      };
    }
  }, [config.image]);

  const renderImageOverlay = () => (
    <AppFlexbox
      style={{
        gap: 0,
        flex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0
      }}
    >
      <AppFlexbox
        style={{
          maxWidth: config.restrictOverlay === 'true' ? pageWidth : 'unset',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          opacity: config.imageOverlayOpacity / 100 ?? 0,
          backgroundColor: config.imageOverlayColor || '#000',
          top: 0,
          left: 0
        }}
      />
    </AppFlexbox>
  );

  return (
    <WsSectionContainer
      config={config}
      noPadding
      sectionKey={sectionKey}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack style={{ flex: 1, gap: 0 }}>
        <AppStack
          style={{
            flex: 1,
            minHeight: defaultBannerHeight
          }}
        >
          <AppStack
            style={{
              flex: 1,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <AppFlexbox
              style={{
                gap: 0,
                flex: 1,
                position: 'absolute',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0
              }}
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  maxWidth: restrictBackgroundColor ? pageWidth : 'unset',
                  width: '100%',
                  height: '100%',
                  flexShrink: 0,
                  color: colorScheme?.textColor ?? '#000',
                  background: colorScheme.backgroundGradient
                    ? `linear-gradient(165deg, ${colorScheme.backgroundColor} 0%, ${colorScheme.solidButtonColor} 100%)`
                    : 'unset',
                  backgroundColor: colorScheme.backgroundGradient
                    ? 'unset'
                    : colorScheme?.backgroundColor
                }}
              />
            </AppFlexbox>

            <AppFlexbox
              style={{
                gap: 0,
                flex: 1,
                position: 'absolute',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0
              }}
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  maxWidth: restrictBackgroundImage ? pageWidth : 'unset',
                  width: '100%',
                  height: '100%',
                  flexShrink: 0,
                  visibility: 'visible',
                  backgroundImage: `url(${config.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: config.imagePosition ?? 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              />
            </AppFlexbox>

            {!showImagesBehindOverlay && renderImageOverlay()}

            <AppStack
              style={{
                width: '100%',
                height: '100%',
                flex: 1,
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              {blocks
                .filter(
                  (b) =>
                    b.fkEcomStoreThemeSectionBlockType ===
                    ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE
                )
                .slice(0, isTabletOrSmaller ? 1 : blocks.length)
                .map((b) => {
                  const blockConfig = b.config || {};
                  const size =
                    blockConfig.layeredSize && blockConfig.layeredSize < 100
                      ? blockConfig.layeredSize
                      : 100;
                  const blockPosition = blockConfig.layeredContentPosition?.split(
                    '-'
                  ) ?? ['center', 'center'];
                  const restrictBlockContent =
                    blockConfig.layeredRestrictPageWidth === 'true';
                  const offsetUnit = blockConfig.layeredOffsetUnit ?? '%';

                  return (
                    <AppFlexbox
                      key={b.key}
                      style={{
                        top: isTabletOrSmaller
                          ? 0
                          : `${blockConfig.layeredYOffset ?? 0}${offsetUnit}`,
                        left: isTabletOrSmaller
                          ? 0
                          : `${blockConfig.layeredXOffset ?? 0}${offsetUnit}`,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <AppStack
                        key={b.key}
                        style={{
                          alignItems: isTabletOrSmaller
                            ? 'center'
                            : blockPosition[1],
                          justifyContent: isTabletOrSmaller
                            ? 'center'
                            : blockPosition[0],
                          width: '100%',
                          height: '100%',
                          maxWidth: restrictBlockContent ? pageWidth : 'unset'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            height: '100%',
                            maxHeight: `${size}%`
                          }}
                        >
                          <WsBlock
                            key={b.key}
                            baseStoreUrl={baseStoreUrl}
                            colorScheme={colorScheme}
                            ecomStoreTheme={ecomStoreTheme}
                            mediaQueries={mediaQueries}
                            onNavigate={onNavigate}
                            themeConfig={themeConfig}
                            {...b}
                          />
                        </AppFlexbox>
                      </AppStack>
                    </AppFlexbox>
                  );
                })}

              {showImagesBehindOverlay && renderImageOverlay()}

              {cardContentBlock &&
                !(
                  isTabletOrSmaller &&
                  cardContentBlock.config.showMobileContainer === 'true'
                ) && (
                  <AppStack
                    style={{
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: containerMargin
                    }}
                  >
                    <AppStack
                      style={{
                        flex: 1,
                        width: '100%',
                        height: '100%',
                        maxWidth:
                          cardContentBlock.config.layeredRestrictPageWidth ===
                          'true'
                            ? pageWidth
                            : 'unset',
                        alignItems: isTabletOrSmaller
                          ? 'center'
                          : cardPosition[1],
                        justifyContent: isTabletOrSmaller
                          ? 'center'
                          : cardPosition[0]
                      }}
                    >
                      <WsBlock
                        key={cardContentBlock.key}
                        baseStoreUrl={baseStoreUrl}
                        colorScheme={colorScheme}
                        ecomStoreTheme={ecomStoreTheme}
                        mediaQueries={mediaQueries}
                        onNavigate={onNavigate}
                        themeConfig={themeConfig}
                        {...cardContentBlock}
                      />
                    </AppStack>
                  </AppStack>
                )}
            </AppStack>
          </AppStack>
        </AppStack>
        {cardContentBlock &&
          isTabletOrSmaller &&
          cardContentBlock.config.showMobileContainer === 'true' && (
            <AppFlexbox
              style={{
                justifyContent: 'center',
                boxSizing: 'border-box'
              }}
            >
              <AppStack
                style={{
                  flex: 1,
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <WsBlock
                  key={cardContentBlock.key}
                  baseStoreUrl={baseStoreUrl}
                  colorScheme={colorScheme}
                  ecomStoreTheme={ecomStoreTheme}
                  mediaQueries={mediaQueries}
                  onNavigate={onNavigate}
                  themeConfig={themeConfig}
                  {...cardContentBlock}
                />
              </AppStack>
            </AppFlexbox>
          )}
      </AppStack>
    </WsSectionContainer>
  );
};

WsLayeredBanner.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreCollections: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onChangeSectionConfigValues: PropTypes.func,
  onNavigate: PropTypes.func,
  sectionKey: PropTypes.number,
  themeConfig: PropTypes.object,
  viewPortAdjustment: PropTypes.string
};

export default WsLayeredBanner;
