import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ActionIcon,
  Alert,
  Badge,
  Button,
  Checkbox,
  Divider,
  Grid,
  Loader,
  Menu,
  Skeleton
} from '@mantine/core';
import {
  ChevronDown,
  ChevronUp,
  CircleCheck,
  Dots,
  Eye,
  Template
} from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppMenu from '../../common/AppMenu';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import WebstoreThemeEditModal from './WebstoreThemeEditModal';
import { triggerNotification } from '../../../helpers/notification';
import ConfirmModal from '../../common/ConfirmModal';
import { formatUtcDate } from '../../../helpers/format';

const MENU_ACTION_ENUM = {
  PREVIEW: 'PREVIEW',
  RENAME: 'RENAME',
  DUPLICATE: 'DUPLICATE',
  REMOVE: 'REMOVE',
  PUBLISH: 'PUBLISH',
  EDIT_ACTIVE_THEME: 'EDIT_ACTIVE_THEME'
};

const THEME_ACTIONS = [
  { value: MENU_ACTION_ENUM.RENAME, label: 'Rename' },
  { value: MENU_ACTION_ENUM.DUPLICATE, label: 'Duplicate' }
];

const WebstoreThemesView = () => {
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchStoreThemes,
    createStoreTheme,
    deleteStoreTheme,
    publishStoreTheme
  } = useContext(StoreContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [themeState, setThemeState] = useState({
    newThemesLoading: [],
    completedThemeInstall: null,
    showAllThemes: false
  });
  const globalThemes = state.ecomStoreThemes.value.filter(
    (f) => f.isGlobalTemplate
  );
  const storeThemes = state.ecomStoreThemes.value.filter(
    (f) =>
      !f.isGlobalTemplate ||
      f.fkEcomStore.toString() === authState.pkEcomStore.toString()
  );

  const activeTheme = state.ecomStoreThemes.value.find(
    (t) =>
      t.active && t.fkEcomStore.toString() === authState.pkEcomStore.toString()
  );
  const libraryThemes = storeThemes.filter(
    (t) =>
      !t.active && t.fkEcomStore.toString() === authState.pkEcomStore.toString()
  );
  const selectedStore = authState.ecomStores.find(
    (s) => s.pkEcomStore.toString() === authState.pkEcomStore.toString()
  );
  const isViewLoading = !hasFetched.current || state.ecomStoreThemes.loading;

  useEffect(() => {
    // Fetch data
    if (authState.pkEcomStore) {
      fetchStoreThemes(authState.pkEcomStore, null, (error) =>
        triggerNotification(error)
      );
      hasFetched.current = true;
      setThemeState({
        newThemesLoading: [],
        completedThemeInstall: null,
        showAllThemes: false
      });
    }
  }, [authState.pkEcomStore]);

  const onDuplicateTheme = (theme, isCopy) => {
    const refKey = new Date().getTime();
    setThemeState({
      ...themeState,
      newThemesLoading: [
        ...themeState.newThemesLoading,
        {
          key: refKey,
          pkEcomStoreTheme: theme.pkEcomStoreTheme,
          name: theme.isGlobalTemplate ? theme.name : `Copy of ${theme.name}`,
          isCopy
        }
      ]
    });
    createStoreTheme(
      authState.pkEcomStore,
      {
        pkEcomStoreTheme: theme.pkEcomStoreTheme,
        name: theme.isGlobalTemplate ? null : `Copy of ${theme.name}`
      },
      (data) => {
        setThemeState((c) => ({
          ...c,
          newThemesLoading: c.newThemesLoading.filter((f) => f.key !== refKey),
          completedThemeInstall: data
        }));
      },
      (error) => {
        triggerNotification(error);
        setThemeState((c) => ({
          ...c,
          newThemesLoading: c.newThemesLoading.filter((f) => f.key !== refKey)
        }));
      }
    );
  };

  return !isViewLoading ? (
    <AppStack
      p={{ base: 0, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16,
        overflow: 'hidden'
      }}
    >
      <AppFlexbox
        p={{ base: '0px 16px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <AppText style={{ fontSize: 22, fontWeight: 500 }}>Themes</AppText>
        </AppFlexbox>

        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Button
            color="green"
            component={Link}
            leftSection={<Eye size={20} />}
            radius="md"
            size="xs"
            target="_blank"
            to={`/store/${selectedStore.urlHandle}`}
            variant="filled"
          >
            View your store
          </Button>
        </AppFlexbox>
      </AppFlexbox>
      {themeState.completedThemeInstall && (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ padding: 16, backgroundColor: '#067D62' }}>
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <CircleCheck color="#FFF" size={16} />
                <AppText style={{ fontWeight: 500, color: '#FFF' }}>
                  You successfully added {themeState.completedThemeInstall.name}
                </AppText>
              </AppFlexbox>
            </AppFlexbox>

            <Divider />
            <AppFlexbox style={{ padding: 16 }}>
              <Button
                color="dark"
                onClick={() =>
                  navigate(
                    `/merchant/webstore/themes/${themeState.completedThemeInstall.pkEcomStoreTheme}/editor`
                  )
                }
                radius="md"
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="outline"
              >
                Customize theme
              </Button>
            </AppFlexbox>
          </AppStack>
        </AppCard>
      )}
      <AppStack style={{ gap: 16 }}>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ padding: 16 }}>
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontWeight: 500 }}>Active Theme</AppText>
              </AppStack>
            </AppFlexbox>

            <Divider />
            {activeTheme ? (
              <AppFlexbox
                style={{
                  padding: 16,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <AppFlexbox
                  style={{
                    gap: 10,
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'hidden'
                  }}
                >
                  <AppStack
                    style={{
                      gap: 0,
                      width: 60,
                      height: 60,
                      border: 'solid 1px #dee2e6',
                      backgroundColor: 'rgba(56, 56, 56, 0.1)',
                      borderRadius: 8,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Template color="#000" size={30} />
                  </AppStack>
                  <AppStack
                    style={{ gap: 0, width: '100%', overflow: 'hidden' }}
                  >
                    <AppFlexbox
                      style={{
                        gap: 10,
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <AppFlexbox
                        style={{
                          gap: 10,
                          alignItems: 'center',
                          overflow: 'hidden',
                          maxWidth: '100%'
                        }}
                      >
                        <AppText
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {activeTheme.name}
                        </AppText>
                      </AppFlexbox>

                      <AppFlexbox style={{ minWidth: 120 }} visibleFrom="xsm">
                        <Badge color="green" radius="md" variant="light">
                          Current Theme
                        </Badge>
                      </AppFlexbox>
                    </AppFlexbox>

                    {activeTheme.lastUpdated ? (
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        Updated:{' '}
                        {dayjs(formatUtcDate(activeTheme.lastUpdated)).format(
                          'MMMM D, YYYY'
                        )}
                        .
                      </AppText>
                    ) : (
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        Added:{' '}
                        {dayjs(formatUtcDate(activeTheme.createdAt)).format(
                          'MMMM D, YYYY'
                        )}
                        .
                      </AppText>
                    )}
                  </AppStack>
                </AppFlexbox>

                <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                  <AppMenu
                    control={
                      <ActionIcon
                        color="lightgrey"
                        radius="md"
                        variant="outline"
                      >
                        <Dots style={{ color: '#000' }} />
                      </ActionIcon>
                    }
                    styles={{ dropdown: { padding: 0 } }}
                  >
                    <AppStack style={{ padding: 2, gap: 1 }}>
                      <Menu.Item
                        component={Link}
                        target="_blank"
                        to={`/store/${selectedStore.urlHandle}`}
                      >
                        Preview
                      </Menu.Item>
                    </AppStack>
                    <Divider />
                    <AppStack hiddenFrom="xsm" style={{ gap: 0 }}>
                      <AppStack style={{ padding: 2, gap: 1 }}>
                        <Menu.Item
                          onClick={(e) => {
                            e.preventDefault();
                            onOpenModal(
                              MENU_ACTION_ENUM.EDIT_ACTIVE_THEME,
                              activeTheme
                            );
                          }}
                        >
                          Customize
                        </Menu.Item>
                      </AppStack>
                      <Divider />
                    </AppStack>
                    <AppStack style={{ padding: 2, gap: 1 }}>
                      {THEME_ACTIONS.map((a) => (
                        <Menu.Item
                          key={a.value}
                          onClick={(e) => {
                            e.preventDefault();
                            switch (a.value) {
                              case MENU_ACTION_ENUM.RENAME:
                                onOpenModal(
                                  MENU_ACTION_ENUM.RENAME,
                                  activeTheme
                                );
                                break;
                              case MENU_ACTION_ENUM.DUPLICATE:
                                onDuplicateTheme(activeTheme, true);
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          {a.label}
                        </Menu.Item>
                      ))}
                    </AppStack>
                  </AppMenu>
                  <Button
                    color="dark"
                    onClick={() =>
                      onOpenModal(
                        MENU_ACTION_ENUM.EDIT_ACTIVE_THEME,
                        activeTheme
                      )
                    }
                    radius="md"
                    size="compact-md"
                    style={{ fontSize: 14 }}
                    variant="filled"
                    visibleFrom="xsm"
                  >
                    Customize
                  </Button>
                </AppFlexbox>
              </AppFlexbox>
            ) : (
              <AppStack
                style={{
                  padding: '40px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <AppText style={{ fontSize: 14, color: '#000' }}>
                  No active theme setup
                </AppText>
              </AppStack>
            )}
          </AppStack>
        </AppCard>

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ padding: 16 }}>
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontWeight: 500 }}>Theme library</AppText>
                <AppText style={{ fontSize: 14, color: 'grey' }}>
                  These themes are accessible only to you. To update your
                  store's appearance, publish your desired theme.
                </AppText>
              </AppStack>
            </AppFlexbox>
            <Divider />
            {themeState.newThemesLoading.map((t, index) => (
              <React.Fragment key={t.key}>
                <AppFlexbox
                  style={{
                    padding: 16,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                    <AppStack
                      style={{
                        gap: 0,
                        width: 60,
                        height: 60,
                        border: 'solid 1px #dee2e6',
                        backgroundColor: 'rgba(56, 56, 56, 0.1)',
                        borderRadius: 8,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Loader color="dark" size={20} />
                    </AppStack>
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                        {t.name}
                      </AppText>
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        {t.isCopy ? 'Copying your theme' : 'Installing theme'}
                      </AppText>
                    </AppStack>
                  </AppFlexbox>

                  <AppFlexbox style={{ gap: 10 }}>
                    <ActionIcon color="lightgrey" disabled variant="outline">
                      <Dots style={{ color: '#000' }} />
                    </ActionIcon>
                    <Button
                      color="dark"
                      disabled
                      onClick={() => {}}
                      radius="md"
                      size="compact-md"
                      style={{ fontSize: 14, border: 'solid 1px lightgrey' }}
                      variant="outline"
                      visibleFrom="xsm"
                    >
                      Publish
                    </Button>
                    <Button
                      color="dark"
                      disabled
                      onClick={() =>
                        navigate(
                          `/merchant/webstore/themes/${t.pkEcomStoreTheme}/editor`
                        )
                      }
                      radius="md"
                      size="compact-md"
                      style={{ fontSize: 14, border: 'solid 1px lightgrey' }}
                      variant="outline"
                      visibleFrom="xsm"
                    >
                      Customize
                    </Button>
                  </AppFlexbox>
                </AppFlexbox>

                {libraryThemes.length > 0 ||
                  (index < themeState.newThemesLoading.length - 1 && (
                    <Divider />
                  ))}
                <Divider />
              </React.Fragment>
            ))}
            {themeState.newThemesLoading.length === 0 &&
            libraryThemes.length === 0 ? (
              <AppStack
                style={{
                  padding: '40px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Get started by adding a theme from the list of available
                  themes below.
                </AppText>
              </AppStack>
            ) : (
              libraryThemes
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(
                  0,
                  themeState.showAllThemes
                    ? libraryThemes.length
                    : 5 - themeState.newThemesLoading.length
                )
                .map((t, index) => (
                  <React.Fragment key={t.pkEcomStoreTheme}>
                    {index > 0 && <Divider />}
                    <AppFlexbox
                      style={{
                        padding: 16,
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                        <AppStack
                          style={{
                            gap: 0,
                            width: 60,
                            height: 60,
                            border: 'solid 1px #dee2e6',
                            backgroundColor: 'rgba(56, 56, 56, 0.1)',
                            borderRadius: 8,
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Template color="#000" size={30} />
                        </AppStack>
                        <AppStack style={{ gap: 0 }}>
                          <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                            {t.name}
                          </AppText>
                          {t.lastUpdated ? (
                            <AppText style={{ fontSize: 14, color: '#666' }}>
                              Updated:{' '}
                              {dayjs(formatUtcDate(t.lastUpdated)).format(
                                'MMMM D, YYYY'
                              )}
                              .
                            </AppText>
                          ) : (
                            <AppText style={{ fontSize: 14, color: '#666' }}>
                              Added:{' '}
                              {dayjs(formatUtcDate(t.createdAt)).format(
                                'MMMM D, YYYY'
                              )}
                              .
                            </AppText>
                          )}
                        </AppStack>
                      </AppFlexbox>

                      <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                        <AppMenu
                          control={
                            <ActionIcon
                              color="lightgrey"
                              radius="md"
                              variant="outline"
                            >
                              <Dots style={{ color: '#000' }} />
                            </ActionIcon>
                          }
                          styles={{ dropdown: { padding: 0 } }}
                        >
                          <AppStack style={{ padding: 2, gap: 1 }}>
                            <Menu.Item
                              component={Link}
                              target="_blank"
                              to={`/store/${selectedStore.urlHandle}?previewTheme=${t.pkEcomStoreTheme}`}
                            >
                              Preview
                            </Menu.Item>
                          </AppStack>
                          <Divider />
                          <AppStack hiddenFrom="xsm" style={{ gap: 0 }}>
                            <AppStack style={{ padding: 2, gap: 1 }}>
                              <Menu.Item
                                onClick={(e) => {
                                  e.preventDefault();
                                  onOpenModal(MENU_ACTION_ENUM.PUBLISH, t);
                                }}
                              >
                                Publish
                              </Menu.Item>
                              <Menu.Item
                                component={Link}
                                to={`/merchant/webstore/themes/${t.pkEcomStoreTheme}/editor`}
                              >
                                Customize
                              </Menu.Item>
                            </AppStack>
                            <Divider />
                          </AppStack>
                          <AppStack style={{ padding: 2, gap: 1 }}>
                            {THEME_ACTIONS.map((a) => (
                              <Menu.Item
                                key={a.value}
                                onClick={(e) => {
                                  e.preventDefault();
                                  switch (a.value) {
                                    case MENU_ACTION_ENUM.RENAME:
                                      onOpenModal(MENU_ACTION_ENUM.RENAME, t);
                                      break;
                                    case MENU_ACTION_ENUM.DUPLICATE:
                                      onDuplicateTheme(t, true);
                                      break;
                                    default:
                                      break;
                                  }
                                }}
                              >
                                {a.label}
                              </Menu.Item>
                            ))}
                          </AppStack>
                          <Divider />
                          <AppStack style={{ padding: 2, gap: 1 }}>
                            <Menu.Item
                              onClick={(e) => {
                                e.preventDefault();
                                onOpenModal(MENU_ACTION_ENUM.REMOVE, t);
                              }}
                            >
                              Remove
                            </Menu.Item>
                          </AppStack>
                        </AppMenu>
                        <Button
                          color="dark"
                          onClick={() => {
                            onOpenModal(MENU_ACTION_ENUM.PUBLISH, t);
                          }}
                          radius="md"
                          size="compact-md"
                          style={{
                            fontSize: 14,
                            border: 'solid 1px lightgrey'
                          }}
                          variant="outline"
                          visibleFrom="xsm"
                        >
                          Publish
                        </Button>
                        <Button
                          color="dark"
                          onClick={() =>
                            navigate(
                              `/merchant/webstore/themes/${t.pkEcomStoreTheme}/editor`
                            )
                          }
                          radius="md"
                          size="compact-md"
                          style={{
                            fontSize: 14,
                            border: 'solid 1px lightgrey'
                          }}
                          variant="outline"
                          visibleFrom="xsm"
                        >
                          Customize
                        </Button>
                      </AppFlexbox>
                    </AppFlexbox>
                  </React.Fragment>
                ))
            )}
          </AppStack>
          {libraryThemes.length + themeState.newThemesLoading.length > 5 && (
            <Button
              color="dark"
              justify="center"
              onClick={() =>
                setThemeState({
                  ...themeState,
                  showAllThemes: !themeState.showAllThemes
                })
              }
              radius={0}
              rightSection={
                themeState.showAllThemes ? (
                  <ChevronUp color="dodgerblue" size={20} />
                ) : (
                  <ChevronDown color="dodgerblue" size={20} />
                )
              }
              size="lg"
              style={{ color: 'dodgerblue', fontSize: 14 }}
              variant="light"
            >
              {themeState.showAllThemes ? 'Show less' : 'Show all'}
            </Button>
          )}
        </AppCard>

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ padding: 16 }}>
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontWeight: 500 }}>Available Themes</AppText>
                <AppText style={{ fontSize: 14, color: 'grey' }}>
                  Built with core capabilities that enable seamless
                  customization—no programming skills needed.
                </AppText>
              </AppStack>
            </AppFlexbox>
            <AppStack style={{ padding: 16, paddingTop: 0 }}>
              <Grid>
                {globalThemes
                  .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAT))
                  .map((t) => (
                    <Grid.Col
                      key={t.pkEcomStoreTheme}
                      span={{ base: 12, xsm: 6, lg: 4 }}
                    >
                      <AppCard
                        radius="md"
                        shadow="xs"
                        style={{ padding: 0 }}
                        withBorder
                      >
                        <AppStack style={{ gap: 0 }}>
                          <AppStack
                            style={{
                              height: 250,
                              maxHeight: 200,
                              borderBottom: 'solid 1px #dee2e6',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Template color="#000" size="40%" />
                          </AppStack>
                          <AppFlexbox
                            style={{
                              padding: 10,
                              justifyContent: 'space-between'
                            }}
                          >
                            <AppStack style={{ gap: 0 }}>
                              <AppText
                                style={{ fontSize: 16, fontWeight: 500 }}
                              >
                                {t.name}
                              </AppText>
                              <AppText style={{ fontSize: 14, color: '#666' }}>
                                By SportsHeadz
                              </AppText>
                            </AppStack>
                            <Button
                              color="dark"
                              loading={themeState.newThemesLoading.some(
                                (f) => f.pkEcomStoreTheme === t.pkEcomStoreTheme
                              )}
                              onClick={() => {
                                onDuplicateTheme(t, false);
                              }}
                              radius={8}
                              size="compact-md"
                              style={{
                                border: '1px solid #dee2e6',
                                fontSize: 14
                              }}
                              variant="white"
                            >
                              Add
                            </Button>
                          </AppFlexbox>
                        </AppStack>
                      </AppCard>
                    </Grid.Col>
                  ))}
              </Grid>
            </AppStack>
          </AppStack>
        </AppCard>
      </AppStack>
      <WebstoreThemeEditModal
        ecomStoreTheme={modalState.item}
        isOpen={
          modalState.isOpen && modalState.action === MENU_ACTION_ENUM.RENAME
        }
        onClose={onCloseModal}
      />
      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, remove theme"
        confirmationValue={modalState.item?.name}
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === MENU_ACTION_ENUM.REMOVE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText>This action can’t be undone.</AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteStoreTheme(
            modalState.item.pkEcomStoreTheme,
            () => {
              onCloseModal();
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Remove ${modalState.item?.name}?`}
      />
      <ConfirmModal
        confirmActionColor="dark"
        confirmActionText="Yes, publish theme"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === MENU_ACTION_ENUM.PUBLISH
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText>
              Are you sure you want to publish <b>{modalState.item?.name}</b>?
            </AppText>
            {activeTheme && (
              <AppText>
                This will replace the current theme <b>{activeTheme.name}</b>
              </AppText>
            )}
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          publishStoreTheme(
            modalState.item.pkEcomStoreTheme,
            () => {
              onCloseModal();
              fetchStoreThemes(authState.pkEcomStore);
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Publish ${modalState.item?.name}?`}
      />
      <ConfirmModal
        confirmActionColor="dark"
        confirmActionText="Yes, edit theme"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen &&
          modalState.action === MENU_ACTION_ENUM.EDIT_ACTIVE_THEME
        }
        message={
          <AppStack style={{ gap: 20 }}>
            <Alert color="yellow" title="Warning!" variant="outline">
              <AppStack style={{ gap: 10 }}>
                <AppText>
                  You are about to edit the live theme. Any changes made will be
                  visible to your customers.
                </AppText>
                <AppText>
                  <b>Note:</b> It’s recommended to duplicate the theme and make
                  your changes in the copy before publishing.
                </AppText>
              </AppStack>
            </Alert>
            <AppStack style={{ gap: 10, padding: '0px 5px' }}>
              <AppText>
                Are you sure you want to edit the live theme{' '}
                <b>{modalState.item?.name}</b>?
              </AppText>
              <Checkbox
                label="Yes, edit the live theme without duplicating"
                required
                styles={{ label: { fontSize: 16 } }}
              />
            </AppStack>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          navigate(
            `/merchant/webstore/themes/${activeTheme.pkEcomStoreTheme}/editor`
          );
        }}
        title="Edit live theme?"
      />
    </AppStack>
  ) : (
    <AppStack
      p={{ base: 0, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 16px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Skeleton height={30} width={78} />
        <Skeleton height={30} width={140} />
      </AppFlexbox>
      <AppStack style={{ flex: 1 }}>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ padding: 16 }}>
              <Skeleton height={24} width={100} />
            </AppFlexbox>

            <Divider />
            <AppFlexbox
              style={{ padding: 16, justifyContent: 'space-between' }}
            >
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Skeleton height={58} radius={8} width={58} />
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={20} width={160} />
                  <Skeleton height={18} width={120} />
                </AppStack>
              </AppFlexbox>

              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Skeleton height={24} width={24} />
                <Skeleton height={28} visibleFrom="xsm" width={85} />
              </AppFlexbox>
            </AppFlexbox>
          </AppStack>
        </AppCard>

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ padding: 16, gap: 5 }}>
              <Skeleton height={24} width={100} />
              <Skeleton height={16} width="60%" />
            </AppStack>

            <Divider />
            <AppFlexbox
              style={{
                padding: 16,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Skeleton height={58} radius={8} width={58} />
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={20} width={160} />
                  <Skeleton height={18} width={120} />
                </AppStack>
              </AppFlexbox>

              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Skeleton height={24} width={24} />
                <Skeleton height={24} visibleFrom="xsm" width={65} />
                <Skeleton height={24} visibleFrom="xsm" width={85} />
              </AppFlexbox>
            </AppFlexbox>
          </AppStack>
        </AppCard>

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ padding: 16, gap: 5 }}>
              <Skeleton height={24} width={100} />
              <Skeleton height={16} width="45%" />
            </AppStack>

            <AppFlexbox
              style={{
                padding: 16,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Grid style={{ width: '100%' }}>
                {Array.from(Array(2)).map((x, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid.Col key={i} span={{ base: 12, xsm: 6, lg: 4 }}>
                    <AppCard
                      radius="md"
                      shadow="xs"
                      style={{ padding: 0 }}
                      withBorder
                    >
                      <AppStack style={{ gap: 0 }}>
                        <Skeleton h={250} w="100%" />
                        <AppFlexbox
                          style={{
                            padding: 10,
                            justifyContent: 'space-between'
                          }}
                        >
                          <AppStack style={{ gap: 5 }}>
                            <Skeleton h={22} w="75" />
                            <Skeleton h={18} w="100" />
                          </AppStack>
                          <Skeleton h={28} w="45" />
                        </AppFlexbox>
                      </AppStack>
                    </AppCard>
                  </Grid.Col>
                ))}
              </Grid>
            </AppFlexbox>
          </AppStack>
        </AppCard>
      </AppStack>
    </AppStack>
  );
};

export default WebstoreThemesView;
