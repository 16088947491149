import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Image } from '@mantine/core';
import { ArrowLeft, ArrowRight } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import ProductInputDisplay from '../products/ProductInputDisplay';

const SetVendorProductInputsModal = ({ isOpen, onClose, skippable }) => {
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    toggleVendorCatalogInputModal,
    toggleVendorCatalogSkipInputModal
  } = useContext(HelperContext);
  const { cart: catalogCart, updateProducts } = useVendorCatalogCheckout();
  const [formState, setFormState] = useState({ ecomVendorProducts: [] });

  const selectedInputProductIndex = state.vendorCatalog.inputProducts.findIndex(
    (p) => p.uuid === state.vendorCatalog.selectedInputProductUuid
  );
  const selectedInputProduct =
    state.vendorCatalog.inputProducts[selectedInputProductIndex];

  const selectedProductAnswers = formState.ecomVendorProducts.find(
    (p) => p.uuid === selectedInputProduct?.uuid
  )?.ecomStoreProductInputAnswers;

  useEffect(() => {
    if (state.vendorCatalog.showInputModal || isOpen) {
      setFormState({
        ecomVendorProducts: state.vendorCatalog.inputProducts.map((p) => ({
          uuid: p.uuid,
          ecomStoreProductInputAnswers:
            catalogCart.storageCart.ecomVendorProducts.find(
              (c) => c.uuid === p.uuid
            )?.ecomStoreProductInputAnswers ?? []
        }))
      });
    }
  }, [state.vendorCatalog.showInputModal, isOpen]);

  const onCloseModal = () => {
    updateProducts(formState.ecomVendorProducts);
    if (onClose) {
      onClose();
    }
    else {
      toggleVendorCatalogInputModal(false);
    }
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onCloseModal,
        onSubmit: onCloseModal,
        submitColor: 'dark',
        submitTitle: 'Confirm',
        isCancelHidden: true,
        isLoading: formState.loading,
        leftSection: skippable && (
          <Checkbox
            checked={state.vendorCatalog.skipInputModalOnAdd}
            label="Skip until the end"
            onChange={() => {
              toggleVendorCatalogSkipInputModal(
                !state.vendorCatalog.skipInputModalOnAdd
              );
            }}
          />
        )
      }}
      isOpen={onClose ? isOpen : state.vendorCatalog.showInputModal}
      onClose={onCloseModal}
      title="Autopopulate product inputs"
    >
      <AppStack style={{ gap: 16 }}>
        <AppText style={{ fontSize: 14, fontWeight: 500, textAlign: 'center' }}>
          Please confirm the following optional inputs before adding to your
          store.
        </AppText>

        <AppText
          style={{
            textAlign: 'center',
            fontWeight: 500,
            fontSize: 14,
            color: '#666'
          }}
        >
          The following{' '}
          {state.vendorCatalog.inputProducts.length === 1
            ? 'product has'
            : 'products have'}{' '}
          optional inputs that you can choose to autopopulate. If selected,
          these values will ensure that values are correctly assigned for every
          customer checkout. If you prefer not to use this feature, simply leave
          the inputs blank.
        </AppText>

        <Divider />

        <AppStack style={{ gap: 5 }}>
          <AppFlexbox
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <AppText style={{ fontSize: 16, fontWeight: 500 }}>Product</AppText>
            {state.vendorCatalog.inputProducts.length > 1 && (
              <AppFlexbox style={{ gap: 8 }}>
                ({selectedInputProductIndex + 1}/
                {state.vendorCatalog.inputProducts.length})
                <Button.Group>
                  <Button
                    color="dark"
                    onClick={() => {
                      const prev =
                        state.vendorCatalog.inputProducts[
                          selectedInputProductIndex - 1
                        ];
                      if (prev) {
                        toggleVendorCatalogInputModal(
                          state.vendorCatalog.showInputModal,
                          prev.uuid
                        );
                      }
                      else {
                        toggleVendorCatalogInputModal(
                          state.vendorCatalog.showInputModal,
                          state.vendorCatalog.inputProducts[
                            state.vendorCatalog.inputProducts.length - 1
                          ].uuid
                        );
                      }
                    }}
                    size="compact-sm"
                    variant="outline"
                  >
                    <ArrowLeft size={18} />
                  </Button>
                  <Button
                    color="dark"
                    onClick={() => {
                      const next =
                        state.vendorCatalog.inputProducts[
                          selectedInputProductIndex + 1
                        ];
                      if (next) {
                        toggleVendorCatalogInputModal(
                          state.vendorCatalog.showInputModal,
                          next.uuid
                        );
                      }
                      else {
                        toggleVendorCatalogInputModal(
                          state.vendorCatalog.showInputModal,
                          state.vendorCatalog.inputProducts[0].uuid
                        );
                      }
                    }}
                    size="compact-sm"
                    variant="outline"
                  >
                    <ArrowRight size={18} />
                  </Button>
                </Button.Group>
              </AppFlexbox>
            )}
          </AppFlexbox>
          <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
            <AppFlexbox style={{ gap: 0, alignItems: 'center' }}>
              <AppStack style={{ height: 90, width: 90, padding: 8 }}>
                <Image
                  fit="contain"
                  src={selectedInputProduct?.previewImage}
                  style={{ height: '100%', width: '100%' }}
                />
              </AppStack>
              <Divider orientation="vertical" />
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppStack style={{ padding: 8, gap: 0 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 700 }}>
                    {selectedInputProduct?.name}
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    {selectedInputProduct?.ecomVendorProductVariantCount}{' '}
                    variants
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    by {selectedInputProduct?.vendorName}
                  </AppText>
                </AppStack>
              </AppFlexbox>
            </AppFlexbox>
          </AppCard>
        </AppStack>

        {selectedInputProduct?.ecomVendorProductInputs.map((i) => {
          const selectedAnswer = selectedProductAnswers?.find(
            (a) => a.fkEcomVendorProductInput === i.pkEcomVendorProductInput
          );

          return (
            <ProductInputDisplay
              key={i.pkEcomVendorProductInput}
              ecomVendorProductInput={i}
              onChange={(v) => {
                setFormState({
                  ...formState,
                  ecomVendorProducts: formState.ecomVendorProducts.map((p) => {
                    if (p.uuid === selectedInputProduct.uuid) {
                      return {
                        ...p,
                        ecomStoreProductInputAnswers: [
                          ...p.ecomStoreProductInputAnswers.filter(
                            (a) =>
                              a.fkEcomVendorProductInput !==
                              i.pkEcomVendorProductInput
                          ),
                          {
                            fkEcomVendorProductInput:
                              i.pkEcomVendorProductInput,
                            value: v
                          }
                        ]
                      };
                    }
                    return p;
                  })
                });
              }}
              pkEcomStore={authState.pkEcomStore}
              value={selectedAnswer ? selectedAnswer.value : ''}
            />
          );
        })}
      </AppStack>
    </ResponsiveModal>
  );
};

SetVendorProductInputsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  skippable: PropTypes.bool
};

export default SetVendorProductInputsModal;
