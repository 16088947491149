import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mantine/core';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import AppCard from './AppCard';

const PaginationList = ({
  isLoading,
  items,
  LoadingComponent,
  loadingComponentCount,
  itemsPerPage,
  emptyMessage,
  pageIndex,
  onPageChange,
  noBorder,
  cardProps,
  listProps,
  bottomAction,
  title,
  titleAction,
  ...rest
}) => {
  const MAX_PAGE_LENGTH = itemsPerPage ?? 10;
  const [pageIndexState, setPageIndexState] = useState(1);
  const index = pageIndex ?? pageIndexState;
  const showPagination = !isLoading && items.length > MAX_PAGE_LENGTH;

  useEffect(() => {
    setPageIndexState(1);
  }, [items]);

  return (
    <AppStack
      {...rest}
      style={{
        flex: 1,
        gap: 10,
        alignSelf: 'stretch',
        ...rest.style
      }}
    >
      <AppStack style={{ gap: 5, flex: 1 }}>
        {(title || titleAction) && (
          <AppFlexbox style={{ justifyContent: 'space-between' }}>
            {title && (
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                {title}
              </AppText>
            )}
            {titleAction}
          </AppFlexbox>
        )}
        <AppCard
          shadow="none"
          {...cardProps}
          style={{
            flex: items.length > 0 ? 'unset' : 1,
            gap: 0,
            padding: 0,
            ...cardProps?.style
          }}
        >
          <AppStack
            {...listProps}
            style={{
              gap: 0,
              border: noBorder ? null : 'solid 1px #ced4da',
              borderBottom: 'none',
              flex: 1,
              ...listProps?.style
            }}
          >
            {isLoading ? (
              <>
                {[...Array(loadingComponentCount ?? 4)].map((_, i) => (
                  <AppStack
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    style={{borderBottom: noBorder ? null : 'solid 1px #ced4da'}}
                  >
                    <LoadingComponent />
                  </AppStack>
                ))}
              </>
            ) : items.length === 0 ? (
              <AppFlexbox
                style={{
                  gap: 20,
                  flex: 1,
                  padding: '50px 10px',
                  borderBottom: noBorder ? null : 'solid 1px #ced4da'
                }}
              >
                <AppText
                  style={{ margin: 'auto', fontSize: 14, color: '#666' }}
                >
                  {emptyMessage ?? 'No Items available'}
                </AppText>
              </AppFlexbox>
            ) : (
              items
                .slice(
                  -MAX_PAGE_LENGTH + index * MAX_PAGE_LENGTH,
                  index * MAX_PAGE_LENGTH
                )
                .map((r) => (
                  <AppStack
                    key={r.key}
                    style={{borderBottom: noBorder ? null : 'solid 1px #ced4da'}}
                  >
                    {r}
                  </AppStack>
                ))
            )}
          </AppStack>
        </AppCard>
      </AppStack>
      {(bottomAction || showPagination) && (
        <AppFlexbox
          style={{ justifyContent: bottomAction ? 'space-between' : 'center' }}
        >
          {showPagination && (
            <Pagination
              color="blue"
              onChange={(page) => {
                if (onPageChange) {
                  onPageChange(page);
                }
                else {
                  setPageIndexState(page);
                }
              }}
              total={Math.ceil(items.length / MAX_PAGE_LENGTH)}
              value={index}
            />
          )}

          {bottomAction}
        </AppFlexbox>
      )}
    </AppStack>
  );
};

PaginationList.propTypes = {
  LoadingComponent: PropTypes.any,
  bottomAction: PropTypes.node,
  cardProps: PropTypes.object,
  emptyMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  itemsPerPage: PropTypes.number,
  listProps: PropTypes.object,
  loadingComponentCount: PropTypes.number,
  noBorder: PropTypes.bool,
  onPageChange: PropTypes.func,
  pageIndex: PropTypes.number,
  title: PropTypes.string,
  titleAction: PropTypes.node
};

export default PaginationList;
