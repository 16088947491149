import React from 'react';
import {} from 'react-router-dom';
import AppStack from '../components/common/AppStack';

const NotFoundView = () => (
  <AppStack style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <h1>Store not found</h1>
  </AppStack>
);

export default NotFoundView;
