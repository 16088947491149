/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import placeholderImage from '../../../../../images/sportsheadz-clothing-folded-banner.png';
import AppCard from '../../../../common/AppCard';
import WsBlock from '../../blocks/WsBlock';
import AppFlexbox from '../../../../common/AppFlexbox';
import { getDefaultBannerHeight } from '../../../../../helpers/webstoreHelper';

const paddingMap = {
  none: { tablet: 0, default: 0 },
  small: { tablet: 8, default: 12 },
  medium: { tablet: 12, default: 20 },
  large: { tablet: 16, default: 36 },
  extra_large: { tablet: 24, default: 45 }
};

const marginMap = {
  none: 0,
  small: 16,
  medium: 24,
  large: 40,
  extra_large: 60
};

const WsImageBanner = ({
  blocks,
  config,
  colorSchemes,
  themeConfig,
  mediaQueries,
  onNavigate,
  baseStoreUrl,
  onChangeSectionConfigValues,
  sectionKey,
  editModeEnabled,
  viewPortAdjustment,
  ecomStoreTheme,
  ...rest
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { isTabletOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const adaptImageHeight = config.adaptImageHeight * 1;
  const adaptImageWidth = config.adaptImageWidth * 1;
  const containerPosition = config.desktopContentPosition?.split('-') ?? [
    'center',
    'center'
  ];
  const showContainerOnDesktop = config.showContainerOnDesktop === 'true';
  const showMobileContainer = config.showMobileContainer === 'true';
  const defaultBannerHeight = getDefaultBannerHeight(
    config,
    adaptImageHeight,
    adaptImageWidth,
    isTabletOrSmaller,
    viewPortAdjustment,
    config.fullWidth === 'false' ? themeConfig.pageWidth : null
  );
  const pageWidth =
    themeConfig.pageWidth && themeConfig.pageWidth * 1 >= 1000
      ? themeConfig.pageWidth * 1
      : 1000;

  const containerPadding = (paddingMap[config.containerPadding] ||
    paddingMap.large)[isTabletOrSmaller ? 'tablet' : 'default'];
  const containerMargin = marginMap[config.containerMargin] || marginMap.large;

  useEffect(() => {
    if (config.image) {
      const image = new Image();
      image.src = config.image;
      image.onload = () => {
        if (
          (adaptImageHeight !== image.naturalHeight ||
            adaptImageWidth !== image.naturalWidth) &&
          editModeEnabled &&
          onChangeSectionConfigValues
        ) {
          onChangeSectionConfigValues(sectionKey, [
            {
              name: 'adaptImageHeight',
              value: image.naturalHeight.toString()
            },
            {
              name: 'adaptImageWidth',
              value: image.naturalWidth.toString()
            }
          ]);
        }
        setImageLoaded(true);
      };
    }
  }, [config.image]);

  return (
    <WsSectionContainer
      config={config}
      noPadding
      sectionKey={sectionKey}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack style={{ flex: 1, gap: 0 }}>
        <AppStack
          style={{
            flex: 1,
            minHeight: defaultBannerHeight
          }}
        >
          <AppStack
            style={{
              flex: 1,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxSizing: 'border-box'
            }}
          >
            <AppFlexbox
              style={{
                gap: 0,
                flex: 1,
                position: 'absolute',
                width: '100%',
                height: '100%',
                flexDirection:
                  config.stackMobileImages === 'true' && isTabletOrSmaller
                    ? 'column'
                    : 'row',
                top: 0,
                left: 0
              }}
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  width: '100%',
                  height: '100%',
                  flexShrink: 0,
                  visibility: 'visible',
                  backgroundImage: `url(${config.image || placeholderImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: config.imagePosition ?? 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              />
              {config.secondImage && (
                <AppFlexbox
                  style={{
                    flex: 1,
                    width: '100%',
                    height: '100%',
                    flexShrink: 0,
                    backgroundImage: `url(${config.secondImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: config.imagePosition ?? 'center',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
              )}
              {config.secondImage && config.thirdImage && (
                <AppFlexbox
                  style={{
                    flex: 1,
                    width: '100%',
                    height: '100%',
                    flexShrink: 0,
                    backgroundImage: `url(${config.thirdImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: config.imagePosition ?? 'center',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
              )}
            </AppFlexbox>
            <AppFlexbox
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                opacity: config.imageOverlayOpacity / 100 ?? 0,
                backgroundColor: '#000',
                pointerEvents: 'none',
                top: 0,
                left: 0
              }}
            />

            {blocks.length > 0 && !(isTabletOrSmaller && showMobileContainer) && (
              <AppStack
                style={{
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <AppStack
                  style={{
                    flex: 1,
                    width: '100%',
                    height: '100%',
                    maxWidth:
                      config.displayType === 'grid' ? pageWidth : 'unset',
                    alignItems: isTabletOrSmaller
                      ? 'center'
                      : containerPosition[1],
                    justifyContent: isTabletOrSmaller
                      ? 'center'
                      : containerPosition[0],
                    padding: containerMargin
                  }}
                >
                  <AppCard
                    radius={0}
                    style={{
                      maxWidth: 900,
                      padding: containerPadding,
                      backgroundColor: (
                        isTabletOrSmaller
                          ? showMobileContainer
                          : showContainerOnDesktop
                      )
                        ? colorScheme.backgroundColor
                        : 'unset',
                      color: colorScheme.textColor
                    }}
                  >
                    <AppStack
                      style={{
                        flex: 1,
                        gap: 5,
                        alignItems: config.desktopContentPosition,
                        justifyContent: config.desktopContentPosition
                      }}
                    >
                      <AppStack
                        style={{
                          alignItems: config.desktopContentAlignment,
                          justifyContent: config.desktopContentAlignment,
                          textAlign: config.desktopContentAlignment,
                          gap: `${config.contentSpacing ?? 16}px`,
                          maxWidth: config.maxContentWidth
                            ? config.maxContentWidth * 1
                            : 'unset'
                        }}
                      >
                        {blocks.map((b) => (
                          <WsBlock
                            key={b.key}
                            baseStoreUrl={baseStoreUrl}
                            colorScheme={colorScheme}
                            ecomStoreTheme={ecomStoreTheme}
                            onNavigate={onNavigate}
                            {...b}
                          />
                        ))}
                      </AppStack>
                    </AppStack>
                  </AppCard>
                </AppStack>
              </AppStack>
            )}
          </AppStack>
        </AppStack>
        {blocks.length > 0 && isTabletOrSmaller && showMobileContainer && (
          <AppFlexbox
            style={{
              justifyContent: 'center',
              boxSizing: 'border-box'
            }}
          >
            <AppStack
              style={{
                flex: 1,
                width: '100%',
                alignItems: containerPosition[1],
                justifyContent: containerPosition[0]
              }}
            >
              <AppCard
                radius={0}
                style={{
                  flex: 1,
                  width: '100%',
                  backgroundColor: colorScheme.backgroundColor,
                  color: colorScheme.textColor,
                  padding: containerPadding
                }}
              >
                <AppStack
                  style={{
                    flex: 1,
                    gap: 5,
                    alignItems: config.desktopContentPosition,
                    justifyContent: config.desktopContentPosition
                  }}
                >
                  <AppStack
                    style={{
                      alignItems: config.desktopContentAlignment,
                      justifyContent: config.desktopContentAlignment,
                      textAlign: config.desktopContentAlignment,
                      gap: `${config.contentSpacing ?? 16}px`,
                      maxWidth: config.maxContentWidth
                        ? config.maxContentWidth * 1
                        : 'unset'
                    }}
                  >
                    {blocks.map((b) => (
                      <WsBlock
                        key={b.key}
                        baseStoreUrl={baseStoreUrl}
                        colorScheme={colorScheme}
                        ecomStoreTheme={ecomStoreTheme}
                        onNavigate={onNavigate}
                        {...b}
                      />
                    ))}
                  </AppStack>
                </AppStack>
              </AppCard>
            </AppStack>
          </AppFlexbox>
        )}
      </AppStack>
    </WsSectionContainer>
  );
};

WsImageBanner.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreCollections: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onChangeSectionConfigValues: PropTypes.func,
  onNavigate: PropTypes.func,
  sectionKey: PropTypes.number,
  themeConfig: PropTypes.object,
  viewPortAdjustment: PropTypes.string
};

export default WsImageBanner;
