import { Message } from 'tabler-icons-react';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import WsRatingBlock from './WsRatingBlock';

const configNameValues = {};

const configDefaultValues = {};

const configOptions = {};

const config = {
  title: 'Product rating',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.RATING,
  icon: Message,
  Component: WsRatingBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text
};

export { config, configOptions, configNameValues, configDefaultValues };
