/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppText from '../../../../common/AppText';
import { determineEcomStoreMenuItemUrl } from '../../../../../helpers/webstoreHelper';

const WsTextLinkBlock = ({
  onNavigate,
  baseStoreUrl,
  colorScheme,
  config,
  ecomStoreTheme,
  ...rest
}) => {
  const hasLink = config?.fkEcomStorePageType || config?.customUrl;
  const link = determineEcomStoreMenuItemUrl({
    fkEcomStorePageType: config?.fkEcomStorePageType,
    ecomResourceId: config?.ecomResourceId,
    customUrl: config?.customUrl,
    ecomStoreTheme
  });

  return (
    <AppText
      component={hasLink ? Link : ''}
      onClick={onNavigate}
      style={{ color: colorScheme.textColor }}
      target={link.target}
      to={link.url || `${baseStoreUrl ?? ''}${link.path}`}
    >
      {config.text}
    </AppText>
  );
};

WsTextLinkBlock.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  onNavigate: PropTypes.func
};

export default WsTextLinkBlock;
