/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { X } from 'tabler-icons-react';
import classNames from 'classnames';
import AppFlexbox from '../../../common/AppFlexbox';
import AppCard from '../../../common/AppCard';
import AppStack from '../../../common/AppStack';
import AppText from '../../../common/AppText';
import * as classes from '../../../../styles/nestedStyles.module.css';

const WsSectionContainer = ({
  containerId,
  config,
  themeConfig,
  colorScheme,
  isSelected,
  isFirstComponent,
  onSelectSection,
  onUnselectSection,
  sectionKey,
  title,
  position,
  children,
  noPadding,
  extraClasses
}) => (
  <AppStack
    className={classNames(classes['hover-button'], ...(extraClasses ?? []))}
    id={containerId}
    onClick={onSelectSection ? () => onSelectSection(sectionKey) : null}
    style={{
      position: position ?? 'relative',
      zIndex: position ? 1 : 'unset',
      overflow: 'visible',
      top: 0,
      left: 0,
      transition: 'transform 0.3s'
    }}
  >
    <AppCard
      shadow="none"
      style={{
        overflow: 'visible',
        color: colorScheme?.textColor ?? '#000',
        borderRadius: 0,
        backgroundColor: colorScheme?.backgroundColor ?? 'unset',
        padding: !noPadding ? '0 10px' : 0,
        marginBottom: `${config.bottomMargin}px`,
        borderBottom:
          config.separatorLine === 'true' ? 'solid 1px #dee2e6' : 'none'
      }}
    >
      <AppFlexbox
        style={{
          flex: 1,
          maxWidth:
            config.fullWidth === 'true'
              ? 'unset'
              : themeConfig.pageWidth && themeConfig.pageWidth * 1 >= 1000
              ? themeConfig.pageWidth * 1
              : 1000,
          margin: 'auto',
          height: '100%',
          width: '100%',
          padding: `${config.topPadding}px ${config.sidePadding}px ${config.bottomPadding}px ${config.sidePadding}px`
        }}
      >
        {children}
      </AppFlexbox>
    </AppCard>
    {onSelectSection && (
      <AppStack
        className={classes['hover-show']}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: 'solid 2px dodgerblue',
          borderTopRightRadius: isFirstComponent ? 10 : 0,
          borderTopLeftRadius: isFirstComponent ? 10 : 0,
          pointerEvents: 'none',
          backgroundColor: 'rgba(30, 144, 255, 0.1)'
        }}
      />
    )}

    {isSelected && (
      <>
        <AppStack
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            border: 'solid 3px dodgerblue',
            borderTopRightRadius: isFirstComponent ? 10 : 0,
            borderTopLeftRadius: isFirstComponent ? 10 : 0,
            pointerEvents: 'none'
          }}
        />
        <AppStack
          onClick={
            onUnselectSection
              ? (e) => {
                  e.stopPropagation();
                  onUnselectSection();
                }
              : null
          }
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'dodgerblue',
            cursor: 'pointer',
            pointerEvents: 'all',
            borderBottomRightRadius: 10,
            overflow: 'hidden'
          }}
        >
          <Button
            color="blue"
            leftSection={<X color="#FFF" size={14} />}
            size="xs"
            style={{ padding: '0px 8px', height: 22 }}
          >
            <AppText style={{ fontSize: 12, color: '#FFF' }}>{title}</AppText>
          </Button>
        </AppStack>
      </>
    )}
  </AppStack>
);

WsSectionContainer.propTypes = {
  children: PropTypes.node,
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  containerId: PropTypes.string,
  extraClasses: PropTypes.array,
  isFirstComponent: PropTypes.bool,
  isSelected: PropTypes.bool,
  noPadding: PropTypes.bool,
  onSelectSection: PropTypes.func,
  onUnselectSection: PropTypes.func,
  position: PropTypes.string,
  sectionKey: PropTypes.number,
  themeConfig: PropTypes.object,
  title: PropTypes.string,
  withPadding: PropTypes.bool
};

export default WsSectionContainer;
