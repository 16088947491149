/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRight } from 'tabler-icons-react';
import { Image } from '@mantine/core';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppFlexbox from '../../../../common/AppFlexbox';

const WsColumnContentBlock = ({ config }) => (
  <AppStack
    style={{
      gap: 8,
      backgroundColor: 'rgba(56, 56, 56, 0.1)',
      padding: 8,
      borderRadius: 8,
      height: '100%'
    }}
  >
    {config.image && (
      <AppStack style={{ maxHeight: 300 }}>
        <Image fit="contain" h="100%" src={config.image} w="100%" />
      </AppStack>
    )}
    <AppText style={{ fontSize: 18, fontWeight: 500 }}>
      {config.heading}
    </AppText>
    <AppText style={{ fontSize: 16 }}>{config.description}</AppText>
    {config.linkLabel && (
      <AppStack style={{ alignItems: 'start' }}>
        <AppFlexbox style={{ alignItems: 'center', gap: 5, cursor: 'pointer' }}>
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>
            {config.linkLabel}
          </AppText>
          <ArrowRight size={18} />
        </AppFlexbox>
      </AppStack>
    )}
  </AppStack>
);

WsColumnContentBlock.propTypes = { config: PropTypes.object };

export default WsColumnContentBlock;
