import React from 'react';
import { ActionIcon, ColorInput, Divider, Menu } from '@mantine/core';
import PropTypes from 'prop-types';
import { ChevronLeft, Copy, DotsVertical, Trash } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import AppMenu from '../../common/AppMenu';
import ConfirmModal from '../../common/ConfirmModal';
import { useModalState } from '../../../helpers/hooks';

const WebstoreColorSchemeEdit = ({
  ecomStoreThemeColorSchemes,
  selectedColorKey,
  onChangeColorScheme,
  onDuplicateColorScheme,
  onDeleteColorScheme,
  onClose,
  editState
}) => {
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const selectedColorScheme = ecomStoreThemeColorSchemes.find(
    (c) => c.key === selectedColorKey
  );

  return (
    <>
      <AppStack style={{ padding: '10px 20px', gap: 10 }}>
        <AppFlexbox style={{ justifyContent: 'space-between' }}>
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <ActionIcon
              color="dark"
              onClick={onClose}
              raduis={8}
              size={30}
              variant="subtle"
            >
              <ChevronLeft size={30} />
            </ActionIcon>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 13, color: '#666' }}>Colors</AppText>
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Scheme {selectedColorScheme?.sort}
              </AppText>
            </AppStack>
          </AppFlexbox>
          <AppMenu
            control={
              <ActionIcon color="dark" onClick={() => {}} variant="subtle">
                <DotsVertical size={18} />
              </ActionIcon>
            }
            styles={{ dropdown: { padding: 0 } }}
          >
            <Menu.Item
              leftSection={<Copy size={18} />}
              onClick={() => {
                onDuplicateColorScheme(selectedColorScheme);
              }}
            >
              Duplicate
            </Menu.Item>
            <Menu.Item
              color="red"
              disabled={editState.ecomStoreThemeColorSchemes.length === 1}
              leftSection={<Trash size={18} />}
              onClick={() => {
                onOpenModal('delete', selectedColorScheme);
              }}
            >
              Delete
            </Menu.Item>
          </AppMenu>
        </AppFlexbox>
        {selectedColorScheme && (
          <AppFlexbox style={{ gap: 15 }}>
            <AppCard
              radius={10}
              shadow="none"
              style={{
                backgroundColor: selectedColorScheme.backgroundColor,
                color: selectedColorScheme.textColor,
                padding: 5,
                paddingBottom: 10,
                minWidth: 75,
                height: 60,
                maxHeight: 60
              }}
              withBorder
            >
              <AppStack
                style={{
                  gap: 0,
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AppText style={{ fontSize: 18 }}>Aa</AppText>
                <AppFlexbox style={{ gap: 5 }}>
                  <AppFlexbox
                    style={{
                      width: 25,
                      height: 10,
                      borderRadius: 100,
                      backgroundColor: selectedColorScheme.solidButtonColor
                    }}
                  />
                  <AppFlexbox
                    style={{
                      width: 25,
                      height: 10,
                      borderRadius: 100,
                      border: `solid 1px ${selectedColorScheme.outlineButtonColor}`
                    }}
                  />
                </AppFlexbox>
              </AppStack>
            </AppCard>
            <AppStack style={{ flex: 1, gap: 0 }}>
              <AppText style={{ color: '#666', fontSize: 14 }}>
                Editing this scheme will affect all sections using it.
              </AppText>
            </AppStack>
          </AppFlexbox>
        )}
      </AppStack>
      <Divider />
      {selectedColorScheme && (
        <AppStack
          style={{ flex: 1, gap: 20, overflow: 'auto', padding: '10px 20px' }}
        >
          <ColorInput
            label="Background"
            onChange={(v) =>
              onChangeColorScheme({
                ...selectedColorScheme,
                backgroundColor: v
              })
            }
            value={selectedColorScheme.backgroundColor}
          />
          <ColorInput
            label="Text"
            onChange={(v) =>
              onChangeColorScheme({
                ...selectedColorScheme,
                textColor: v
              })
            }
            value={selectedColorScheme.textColor}
          />
          <ColorInput
            label="Solid button background"
            onChange={(v) =>
              onChangeColorScheme({
                ...selectedColorScheme,
                solidButtonColor: v
              })
            }
            value={selectedColorScheme.solidButtonColor}
          />
          <ColorInput
            label="Solid button label"
            onChange={(v) =>
              onChangeColorScheme({
                ...selectedColorScheme,
                solidButtonLabel: v
              })
            }
            value={selectedColorScheme.solidButtonLabel}
          />
          <ColorInput
            label="Outline button"
            onChange={(v) =>
              onChangeColorScheme({
                ...selectedColorScheme,
                outlineButtonColor: v
              })
            }
            value={selectedColorScheme.outlineButtonColor}
          />
          <ColorInput
            label="Shadow"
            onChange={(v) =>
              onChangeColorScheme({
                ...selectedColorScheme,
                shadowColor: v
              })
            }
            value={selectedColorScheme.shadowColor}
          />
        </AppStack>
      )}

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete scheme"
        isOpen={modalState.isOpen}
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 14 }}>
              Are you sure you want to delete Scheme {modalState.item?.sort}?
              All color schemes after this one will be shifted down. This can
              cause unexpected behavior, you will have to reassign color schemes
              to sections.
            </AppText>

            <AppText style={{ fontSize: 14 }}>
              It's recommended to update the existing colors schemes and only
              delete the last index.
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onDeleteColorScheme(modalState.item);
          onCloseModal();
        }}
        title="Delete color scheme?"
      />
    </>
  );
};

WebstoreColorSchemeEdit.propTypes = {
  ecomStoreThemeColorSchemes: PropTypes.array,
  editState: PropTypes.object,
  onChangeColorScheme: PropTypes.func,
  onClose: PropTypes.func,
  onDeleteColorScheme: PropTypes.func,
  onDuplicateColorScheme: PropTypes.func,
  selectedColorKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default WebstoreColorSchemeEdit;
