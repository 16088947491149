import { Link, Message } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_PAGE_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsButtonGroupBlock from './WsButtonGroupBlock';

const ALIGNMENT_ENUM = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
};

const BUTTON_SIZE_ENUM = {
  EXTRA_SMALL: 'xs',
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  EXTRA_LARGE: 'xl'
};

const buttonSizeOptions = [
  {
    value: BUTTON_SIZE_ENUM.EXTRA_SMALL,
    label: 'Extra small'
  },
  {
    value: BUTTON_SIZE_ENUM.SMALL,
    label: 'Small'
  },
  {
    value: BUTTON_SIZE_ENUM.MEDIUM,
    label: 'Medium'
  },
  {
    value: BUTTON_SIZE_ENUM.LARGE,
    label: 'Large'
  },
  {
    value: BUTTON_SIZE_ENUM.EXTRA_LARGE,
    label: 'Extra large'
  }
];

const configNameValues = {
  button1Text: 'button1Text',
  button1Size: 'button1Size',
  button1FkEcomStorePageType: 'button1FkEcomStorePageType',
  button1EcomResourceId: 'button1EcomResourceId',
  button1CustomUrl: 'button1CustomUrl',
  button1UseCompactStyle: 'button1UseCompactStyle',
  button1UseOutlineStyle: 'button1UseOutlineStyle',

  button2Text: 'button2Text',
  button2Size: 'button2Size',
  button2FkEcomStorePageType: 'button2FkEcomStorePageType',
  button2EcomResourceId: 'button2EcomResourceId',
  button2CustomUrl: 'button2CustomUrl',
  button2UseCompactStyle: 'button2UseCompactStyle',
  button2UseOutlineStyle: 'button2UseOutlineStyle'
};

const configDefaultValues = {
  [configNameValues.button1Text]: 'Browse products',
  [configNameValues.button1FkEcomStorePageType]: ECOM_WEBSTORE_PAGE_TYPE_ENUM.PRODUCT.toString(),
  [configNameValues.button1EcomResourceId]: '',
  [configNameValues.button1CustomUrl]: '',
  [configNameValues.button1Size]: BUTTON_SIZE_ENUM.MEDIUM,
  [configNameValues.button1UseCompactStyle]: 'false',
  [configNameValues.button1UseOutlineStyle]: 'false',

  [configNameValues.button2Text]: '',
  [configNameValues.button2FkEcomStorePageType]: '',
  [configNameValues.button2EcomResourceId]: '',
  [configNameValues.button2CustomUrl]: '',
  [configNameValues.button2Size]: BUTTON_SIZE_ENUM.MEDIUM,
  [configNameValues.button2UseCompactStyle]: 'false',
  [configNameValues.button2UseOutlineStyle]: 'false'
};

const configOptions = {
  [configNameValues.button1Text]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    category: 'First button',
    label: 'First button label'
  },
  [configNameValues.button1FkEcomStorePageType]: {
    type: INPUT_CONTROL_TYPE_ENUM.LINK,
    category: 'First button',
    label: 'First button link',
    getOnChangeValues: ({ fkEcomStorePageType, ecomResourceId, customUrl }) => [
      {
        key: configNameValues.button1FkEcomStorePageType,
        value: fkEcomStorePageType?.toString() ?? ''
      },
      {
        key: configNameValues.button1EcomResourceId,
        value: ecomResourceId?.toString() ?? ''
      },
      {
        key: configNameValues.button1CustomUrl,
        value: customUrl ?? ''
      }
    ],
    getValueProps: (config) => ({
      fkEcomStorePageType: config[configNameValues.button1FkEcomStorePageType],
      ecomResourceId: config[configNameValues.button1EcomResourceId],
      customUrl: config[configNameValues.button1CustomUrl]
    }),
    isVisible: (config) => !!config.button1Text
  },
  [configNameValues.button1Size]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    category: 'First button',
    label: 'First button size',
    options: buttonSizeOptions,
    isVisible: (config) => !!config.button1Text
  },
  [configNameValues.button1UseCompactStyle]: {
    label: 'Use compact button style',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'First button',
    isVisible: (config) => !!config.button1Text
  },
  [configNameValues.button1UseOutlineStyle]: {
    label: 'Use outlined button style',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'First button',
    isVisible: (config) => !!config.button1Text
  },
  [configNameValues.button2Text]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    category: 'Second button',
    label: 'Second button label'
  },
  [configNameValues.button2FkEcomStorePageType]: {
    type: INPUT_CONTROL_TYPE_ENUM.LINK,
    category: 'Second button',
    label: 'Second button link',
    getOnChangeValues: ({ fkEcomStorePageType, ecomResourceId, customUrl }) => [
      {
        key: configNameValues.button2FkEcomStorePageType,
        value: fkEcomStorePageType?.toString() ?? ''
      },
      {
        key: configNameValues.button2EcomResourceId,
        value: ecomResourceId?.toString() ?? ''
      },
      {
        key: configNameValues.button2CustomUrl,
        value: customUrl ?? ''
      }
    ],
    getValueProps: (config) => ({
      fkEcomStorePageType: config[configNameValues.button2FkEcomStorePageType],
      ecomResourceId: config[configNameValues.button2EcomResourceId],
      customUrl: config[configNameValues.button2CustomUrl]
    }),
    isVisible: (config) => !!config.button2Text
  },
  [configNameValues.button2Size]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    category: 'Second button',
    label: 'Second button size',
    options: buttonSizeOptions,
    isVisible: (config) => !!config.button2Text
  },
  [configNameValues.button2UseCompactStyle]: {
    label: 'Use compact button style',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Second button',
    isVisible: (config) => !!config.button2Text
  },
  [configNameValues.button2UseOutlineStyle]: {
    label: 'Use outlined button style',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Second button',
    isVisible: (config) => !!config.button2Text
  }
};

const config = {
  title: 'Buttons',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUTTON_GROUP,
  icon: Link,
  Component: WsButtonGroupBlock,
  configOptions,
  defaultConfig: configDefaultValues
};

export { config, configOptions, configDefaultValues, configNameValues };
