import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { Image } from '@mantine/core';

const AppCarousel = ({
  slides,
  images,
  autoRotate,
  changeEvery,
  pauseOnHover,
  adaptToFirstImage,
  objectPosition,
  ...rest
}) => {
  const [updateStateTime, setUpdateStateTime] = useState(null);
  const autoplay = useRef(null);
  const [embla, setEmbla] = useState(null);
  const [slideHeight, setSlideHeight] = useState(0);
  const firstImageRef = useRef(null);

  useEffect(() => {
    if (embla) {
      autoplay.current = Autoplay({
        delay: changeEvery * 1000 ?? 3000,
        stopOnMouseEnter: pauseOnHover,
        stopOnInteraction: false,
        playOnInit: autoRotate
      });
      setUpdateStateTime(new Date().getTime());
    }
  }, [changeEvery, pauseOnHover, autoRotate, embla]);

  useEffect(() => {
    if (embla) {
      const handleResize = () => {
        if (firstImageRef.current) {
          // eslint-disable-next-line no-use-before-define
          adjustSlidesHeight(firstImageRef.current);
        }
      };

      if (firstImageRef.current) {
        if (firstImageRef.current.complete) {
          // eslint-disable-next-line no-use-before-define
          adjustSlidesHeight(firstImageRef.current);
        }
        else {
          firstImageRef.current.onload = () =>
            // eslint-disable-next-line no-use-before-define
            adjustSlidesHeight(firstImageRef.current);
        }
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    return () => {};
  }, [embla, firstImageRef.current]);

  const adjustSlidesHeight = (image) => {
    if (image) {
      const imageHeight = image.clientHeight;
      setSlideHeight(imageHeight);
      if (embla) {
        embla.reInit();
      }
    }
  };

  return (
    <Carousel
      draggable={slides?.length > 1 || images?.length > 1}
      getEmblaApi={setEmbla}
      loop
      plugins={autoplay.current && autoRotate ? [autoplay.current] : undefined}
      slideGap="sm"
      slideSize="100%"
      {...rest}
      style={{ ...rest.style }}
      styles={{
        ...rest.styles,
        root: { overflow: 'hidden', width: '100%', ...rest.styles?.root },
        viewport: {
          overflow: 'hidden',
          height: '100%',
          ...rest.styles?.viewport
        },
        container: {
          display: 'flex',
          height: '100%',
          width: '100%',
          ...rest.styles?.container
        },
        slide: {
          padding: 0,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          visibility: 'visible',
          height: adaptToFirstImage
            ? !firstImageRef.current
              ? 'auto'
              : slideHeight
            : '100%',
          flexShrink: 0,
          ...rest.styles?.slide
        },
        control: {
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'unset',
          color: '#000',
          ...rest.styles?.control
        },
        controls: {
          left: -15,
          right: -15,
          ...rest.styles?.controls
        }
      }}
    >
      {slides && slides.map((slide) => slide)}
      {images &&
        images.map((image, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Carousel.Slide key={image.key}>
            <Image
              ref={i === 0 ? firstImageRef : null}
              fit="cover"
              src={image.src}
              style={{
                transform: `scaleX(${image.flipLeftToRight ? -1 : 1}) scaleY(${
                  image.flipUpsideDown ? -1 : 1
                })`,
                display: 'block',
                maxWidth: '100%',
                postion: 'absolute',
                top: 0,
                left: 0,
                height: adaptToFirstImage && i === 0 ? 'unset' : '100%',
                minHeight: adaptToFirstImage && i === 0 ? 'unset' : '100%',
                width: '100%',
                objectFit: 'cover',
                objectPosition: objectPosition || 'center center',
                transition: 'opacity .4s cubic-bezier(.25,.46,.45,.94)',
                boxSizing: 'inherit',
                overflow: 'clip',
                overflowClipMargin: 'content-box'
              }}
            />
            {image.container}
          </Carousel.Slide>
        ))}
    </Carousel>
  );
};

AppCarousel.propTypes = {
  adaptToFirstImage: PropTypes.bool,
  autoRotate: PropTypes.bool,
  changeEvery: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstImageRef: PropTypes.object,
  images: PropTypes.array,
  objectPosition: PropTypes.string,
  pauseOnHover: PropTypes.bool,
  slides: PropTypes.array
};

export default AppCarousel;
