const DEFAULT_DATE_OPTIONS = [
  {
    label: 'Today',
    value: 'today',
    start: 'today',
    end: 'today',
    getDateRange: () => ({
      startDate: new Date(),
      endDate: new Date()
    }),
    getCompareDateRange: () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return { startDate: yesterday, endDate: yesterday };
    }
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    start: 'yesterday',
    end: 'yesterday',
    getDateRange: () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return { startDate: yesterday, endDate: yesterday };
    },
    getCompareDateRange: () => {
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      return { startDate: twoDaysAgo, endDate: twoDaysAgo };
    }
  },
  {
    label: 'Last 7 days',
    value: 'last7',
    start: '-7d',
    end: '-1d',
    isDefault: true,
    getDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 14);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 8);
      return { startDate, endDate };
    }
  },
  {
    label: 'Last 30 days',
    value: 'last30',
    start: '-30d',
    end: '-1d',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 60);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 31);
      return { startDate, endDate };
    }
  },
  {
    label: 'Last 90 days',
    value: 'last90',
    start: '-90d',
    end: '-1d',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 90);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 180);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 91);
      return { startDate, endDate };
    }
  },
  {
    label: 'Last 365 days',
    value: 'last365',
    start: '-365d',
    end: '-1d',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 365);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 730);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 366);
      return { startDate, endDate };
    }
  },
  {
    label: 'Last month',
    value: 'lastmonth',
    start: '-1m',
    end: '-1m',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setDate(1);

      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 2);
      startDate.setDate(1);

      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);

      return { startDate, endDate };
    }
  },
  {
    label: 'Last 12 months',
    value: 'last12Months',
    start: '-12m',
    end: '-1m',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 12);
      startDate.setDate(1);

      const endDate = new Date();
      endDate.setDate(0);

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 24);
      startDate.setDate(1);

      const endDate = new Date();
      endDate.setMonth(endDate.getMonth() - 12);
      endDate.setDate(0);

      return { startDate, endDate };
    }
  },
  {
    label: 'Last year',
    value: 'lastyear',
    start: '-1y',
    end: '-1y',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      startDate.setMonth(0);
      startDate.setDate(1);

      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(0);

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 2);
      startDate.setMonth(0);
      startDate.setDate(1);

      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(0);

      return { startDate, endDate };
    }
  },
  {
    label: 'Week to date',
    value: 'weektodate',
    start: '0w',
    until: 'today',
    getDateRange: () => {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - diffToMonday);
      const endDate = today;

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - diffToMonday - 7);
      const endDate = new Date(today);
      endDate.setDate(today.getDate() - 7);

      return { startDate, endDate };
    }
  },
  {
    label: 'Month to date',
    value: 'monthtodate',
    start: '0m',
    until: 'today',
    getDateRange: () => {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const endDate = today;

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const endDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      return { startDate, endDate };
    }
  },
  {
    label: 'Quarter to date',
    value: 'quartertodate',
    start: '0q',
    until: 'today',
    getDateRange: () => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentQuarterStartMonth = Math.floor(currentMonth / 3) * 3;
      const startDate = new Date(
        today.getFullYear(),
        currentQuarterStartMonth,
        1
      );
      const endDate = today;

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const lastQuarterStartMonth = currentMonth - (currentMonth % 3) - 3;

      const startDate = new Date(today.getFullYear(), lastQuarterStartMonth, 1);
      const endDate = new Date(
        today.getFullYear(),
        lastQuarterStartMonth + (today.getMonth() % 3),
        today.getDate()
      );

      return { startDate, endDate };
    }
  },
  {
    label: 'Year to date',
    value: 'yeartodate',
    start: '0y',
    until: 'today',
    getDateRange: () => {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), 0, 1);
      const endDate = today;

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const today = new Date();
      const startDate = new Date(today.getFullYear() - 1, 0, 1);
      const endDate = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );

      return { startDate, endDate };
    }
  }
];

const DEFAULT_COMPARE_DATE_OPTIONS = [
  {
    label: 'No comparison',
    value: 'noComparison',
    getDateRange: (datePeriod, startDate, endDate) => ({
      startDate: null,
      endDate: null
    })
  },
  {
    label: 'Previous period',
    value: 'previousPeriod',
    isDefault: true,
    getDateRange: (datePeriod, startDate, endDate) => {
      if (datePeriod) {
        return datePeriod.getCompareDateRange();
      }

      const dayDifference = (endDate - startDate) / (1000 * 60 * 60 * 24);

      const compareEndDate = new Date(startDate);
      compareEndDate.setDate(compareEndDate.getDate() - 1);

      const compareStartDate = new Date(compareEndDate);
      compareStartDate.setDate(compareEndDate.getDate() - dayDifference);
      return { startDate: compareStartDate, endDate: compareEndDate };
    }
  },
  {
    label: 'Previous year',
    value: 'previousYear',
    getDateRange: (datePeriod, startDate, endDate) => {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const previousYearStart = new Date(
        start.getFullYear() - 1,
        start.getMonth(),
        start.getDate()
      );
      const previousYearEnd = new Date(
        end.getFullYear() - 1,
        end.getMonth(),
        end.getDate()
      );

      return { startDate: previousYearStart, endDate: previousYearEnd };
    }
  }
];

export { DEFAULT_DATE_OPTIONS, DEFAULT_COMPARE_DATE_OPTIONS };
