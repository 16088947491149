import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider, NumberFormatter, Skeleton } from '@mantine/core';
import AppText from './AppText';
import AppFlexbox from './AppFlexbox';

const PaymentPriceItem = ({
  label,
  weight,
  value,
  valueLabel,
  currency,
  color,
  loading,
  isDividerHidden
}) => (
  <>
    <AppFlexbox
      style={{
        flexWrap: 'nowrap',
        gap: 10,
        marginTop: 15,
        alignItems: 'center'
      }}
    >
      <AppText style={{ fontSize: 16 }} weight={weight ?? 'normal'}>
        {label}
      </AppText>
      <Divider style={{ flex: 1 }} variant="dotted" />
      {loading ? (
        <Skeleton height={20} width={90} />
      ) : (
        <AppText
          style={{ fontSize: 16, color, fontWeight: weight ?? 'normal' }}
        >
          <NumberFormatter
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
            style={{ fontSize: 14 }}
            suffix={` ${currency}${valueLabel ? ` ${valueLabel}` : ''}`}
            thousandSeparator
            value={value}
          />
        </AppText>
      )}
    </AppFlexbox>
    {!isDividerHidden && <Divider />}
  </>
);

PaymentPriceItem.propTypes = {
  color: PropTypes.string,
  currency: PropTypes.string,
  isDividerHidden: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.number,
  valueLabel: PropTypes.string,
  weight: PropTypes.number
};

export default PaymentPriceItem;
