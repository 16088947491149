import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Anchor, Button, TextInput, ThemeIcon } from '@mantine/core';
import { Check } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import AuthCardView from './AuthCardView';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';

const VerifyEmailView = ({ onNavigate }) => {
  const { state, confirmAccount, resendVerificationCode, signIn } = useContext(
    AuthContext
  );
  const [formState, setFormState] = useState({
    confirmationCode: '',
    sendingCode: false,
    codeResent: false,
    loading: false,
    error: ''
  });

  useEffect(() => {
    if (state.email) {
      setFormState({
        confirmationCode: '',
        sendingCode: false,
        codeResent: false,
        loading: false,
        error: ''
      });
    }
    else {
      onNavigate('/auth/signin');
    }
  }, []);

  return (
    <AuthCardView
      error={formState.error}
      onSubmit={() => {
        setFormState({
          ...formState,
          loading: true
        });
        confirmAccount(
          { email: state.email, confirmationCode: formState.confirmationCode },
          () => {
            triggerNotification('Account confirmed!', 'success');
            if (state.password) {
              signIn(
                { email: state.email, password: state.password },
                null,
                () => onNavigate('/auth/signin')
              );
            }
            else {
              onNavigate('/auth/signin');
            }
          },
          (error) =>
            setFormState({
              ...formState,
              loading: false,
              error
            })
        );
      }}
      title="Verify email address"
    >
      <AppStack
        p={{ base: 20, xsm: 40 }}
        style={{ gap: 10, flex: 1, paddingTop: 10 }}
      >
        <AppStack style={{ gap: 0 }}>
          <AppText style={{ fontSize: 14 }}>
            To verify your email, we've sent a One Time Password (OTP) to the
            following email;
          </AppText>
        </AppStack>
        <AppCard
          radius={8}
          shadow="none"
          style={{ padding: 16, backgroundColor: 'rgba(245, 245, 245, 1)' }}
          withBorder
        >
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 14 }}>{state.email}</AppText>
            <Anchor
              onClick={() => onNavigate('/auth/register')}
              style={{ fontSize: 14, color: 'dodgerblue' }}
            >
              Change
            </Anchor>
          </AppFlexbox>
        </AppCard>

        <AppStack style={{ gap: formState.codeResent ? 10 : 20 }}>
          <TextInput
            disabled={formState.loading}
            label="Enter OTP"
            onChange={(e) =>
              setFormState({
                ...formState,
                confirmationCode: e.target.value
              })
            }
            required
            value={formState.confirmationCode}
          />
          {formState.codeResent && (
            <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
              <ThemeIcon color="#067D62" radius={100} size={15}>
                <Check />
              </ThemeIcon>
              <AppText style={{ fontSize: 13, color: '#067D62' }}>
                A new code has been sent to your email.
              </AppText>
            </AppFlexbox>
          )}
          <Button
            color="dark"
            loading={formState.loading}
            radius={10}
            size="sm"
            style={{ fontSize: 13, margin: '5px 0px' }}
            type="submit"
          >
            Verify
          </Button>
        </AppStack>

        <AppFlexbox style={{ justifyContent: 'center' }}>
          <Anchor
            onClick={() => {
              if (!formState.sendingCode) {
                setFormState({
                  ...formState,
                  sendingCode: true
                });
                resendVerificationCode(state.email, () => {
                  setFormState({
                    ...formState,
                    sendingCode: false,
                    codeResent: true
                  });
                });
              }
            }}
            style={{ fontSize: 14, color: '#067D62' }}
          >
            Resend OTP
          </Anchor>
        </AppFlexbox>
        {formState.codeResent && (
          <AppStack style={{ gap: 0 }}>
            <AppText style={{ fontSize: 13 }}>
              Note: If you didn't receive our verification email:
            </AppText>
            <ul style={{ margin: 0 }}>
              <li>
                <AppText style={{ fontSize: 13 }}>
                  Confirm that your email address was entered correctly above.
                </AppText>
              </li>
              <li>
                <AppText style={{ fontSize: 13 }}>
                  Check your spam or junk email folder.
                </AppText>
              </li>
            </ul>
          </AppStack>
        )}
      </AppStack>
    </AuthCardView>
  );
};

VerifyEmailView.propTypes = { onNavigate: PropTypes.func };

export default VerifyEmailView;
