/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsSectionContainer from '../WsSectionContainer';
import WsProductInformationDisplay from './WsProductInformationDisplay';
import { getProductUrlHandleFromPathname } from '../../../../../helpers/webstoreHelper';

const WsProductInformation = ({
  config,
  colorSchemes,
  themeConfig,
  baseStoreUrl,
  onNavigate,
  ecomStoreTheme,
  mediaQueries,
  pathname,
  blocks,
  editModeEnabled,
  ...rest
}) => {
  const { state } = useContext(StoreUserContext);
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const urlHandle = getProductUrlHandleFromPathname(pathname);
  const ecomStoreProduct = state.ecomStoreProductHistory.value.find(
    (c) => c.urlHandle === urlHandle
  );
  const productNotFound = state.ecomStoreProductHistory.urlsNotFound.includes(
    urlHandle
  );

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <WsProductInformationDisplay
        baseStoreUrl={baseStoreUrl}
        blocks={blocks}
        colorSchemes={colorSchemes}
        config={config}
        ecomStoreProduct={ecomStoreProduct}
        ecomStoreTheme={ecomStoreTheme}
        editModeEnabled={editModeEnabled}
        mediaQueries={mediaQueries}
        onNavigate={onNavigate}
        pathname={pathname}
        productNotFound={productNotFound}
        themeConfig={themeConfig}
        {...rest}
      />
    </WsSectionContainer>
  );
};

WsProductInformation.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  themeConfig: PropTypes.object
};

export default WsProductInformation;
