import React from 'react';
import { Plus, Trash } from 'tabler-icons-react';
import { ActionIcon, Anchor, Divider, Select, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { ECOM_SHIPPING_CARRIER_LIST } from '../../../config/constants';

const EditTrackingDetailsFormSection = ({ formState, setFormState }) => {
  const onChangeAdditionalShipping = (key, field, value) => {
    setFormState({
      ...formState,
      additionalShipping: formState.additionalShipping.map((as) => {
        if (as.key === key) {
          return {
            ...as,
            [field]: value
          };
        }
        return as;
      })
    });
  };

  return (
    <>
      <AppFlexbox
        style={{
          gap: 8,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <TextInput
          disabled={formState.loading}
          label="Tracking number"
          onChange={(e) =>
            setFormState({
              ...formState,
              trackingNumber: e.target.value
            })
          }
          style={{ flex: 1 }}
          value={formState.trackingNumber}
        />
        <Select
          clearable
          data={ECOM_SHIPPING_CARRIER_LIST}
          disabled={formState.loading}
          label="Shipping carrier"
          onChange={(v) =>
            setFormState({
              ...formState,
              shippingCarrier: v
            })
          }
          required={!!formState.trackingNumber}
          searchable
          style={{ flex: 1 }}
          value={formState.shippingCarrier}
        />
      </AppFlexbox>

      {formState.shippingCarrier === 'Other' && (
        <TextInput
          disabled={formState.loading}
          label="Tracking URL"
          onChange={(e) =>
            setFormState({
              ...formState,
              trackingUrl: e.target.value
            })
          }
          style={{ flex: 1 }}
          value={formState.trackingUrl}
        />
      )}

      {formState.additionalShipping.map((as, index) => (
        <AppStack key={as.key} style={{ gap: 16, marginTop: 8 }}>
          <Divider />

          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Tracking #{index + 2}
            </AppText>

            <ActionIcon
              color="red"
              disabled={formState.loading}
              onClick={() => {
                setFormState({
                  ...formState,
                  additionalShipping: formState.additionalShipping.filter(
                    (s) => s.key !== as.key
                  )
                });
              }}
              variant="subtle"
            >
              <Trash size={18} />
            </ActionIcon>
          </AppFlexbox>

          <AppStack key={as.key} style={{ gap: 8 }}>
            <AppFlexbox
              style={{
                gap: 8,
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <TextInput
                disabled={formState.loading}
                label="Tracking number"
                onChange={(e) =>
                  onChangeAdditionalShipping(
                    as.key,
                    'trackingNumber',
                    e.currentTarget.value
                  )
                }
                style={{ flex: 1 }}
                value={as.trackingNumber}
              />

              <Select
                clearable
                data={ECOM_SHIPPING_CARRIER_LIST}
                disabled={formState.loading}
                label="Shipping carrier"
                onChange={(v) =>
                  onChangeAdditionalShipping(as.key, 'shippingCarrier', v)
                }
                required={!!as.trackingNumber}
                searchable
                style={{ flex: 1 }}
                value={as.shippingCarrier}
              />
            </AppFlexbox>

            {as.shippingCarrier === 'Other' && (
              <TextInput
                disabled={formState.loading}
                label="Tracking URL"
                onChange={(e) =>
                  onChangeAdditionalShipping(
                    as.key,
                    'trackingUrl',
                    e.currentTarget.value
                  )
                }
                style={{ flex: 1 }}
                value={as.trackingUrl}
              />
            )}
          </AppStack>
        </AppStack>
      ))}
      <AppFlexbox>
        <Anchor
          disabled={formState.loading}
          onClick={() => {
            setFormState({
              ...formState,
              additionalShipping: [
                ...formState.additionalShipping,
                {
                  key: new Date().getTime(),
                  trackingNumber: '',
                  trackingUrl: '',
                  shippingCarrier: formState.shippingCarrier
                }
              ]
            });
          }}
          style={{ color: 'dodgerblue', fontSize: 14 }}
        >
          <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
            <Plus color="dodgerblue" size={15} />
            <AppText style={{ fontSize: 14 }}>
              Add another tracking number
            </AppText>
          </AppFlexbox>
        </Anchor>
      </AppFlexbox>
    </>
  );
};

EditTrackingDetailsFormSection.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func
};

export default EditTrackingDetailsFormSection;
