/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';

const WsEmailFormBlock = ({ config }) => <AppText />;

WsEmailFormBlock.propTypes = { config: PropTypes.object };

export default WsEmailFormBlock;
