import React, { useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { Context as AuthContext } from '../providers/AuthContextProvider';
import AppStack from '../components/common/AppStack';
import SigninView from '../components/content/auth/SigninView';
import RegisterView from '../components/content/auth/RegisterView';
import VerifyEmailView from '../components/content/auth/VerifyEmailView';
import ForgotPasswordView from '../components/content/auth/ForgotPasswordView';
import ResetPasswordView from '../components/content/auth/ResetPasswordView';
import AnimatedBackgroundView from '../components/common/AnimatedBackgroundView';
import { useMediaQueryIndex } from '../helpers/hooks';

const AuthView = () => {
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();
  const navigate = useNavigate();
  const { state, clearErrors } = useContext(AuthContext);
  const { search } = useLocation();
  const redirectUrl = new URLSearchParams(search).get('redirect');

  useEffect(() => {
    if (state.isAuthenticated) {
      clearErrors();
      navigate(redirectUrl ?? '/merchant/switch');
    }
  }, [state.isAuthenticated]);

  useEffect(() => {
    if (state.isWaitingConfirmSignUp) {
      clearErrors();
      navigate(
        redirectUrl
          ? `/auth/confirm-account?redirect=${redirectUrl}`
          : '/auth/confirm-account'
      );
    }
  }, [state.isWaitingConfirmSignUp]);

  const onNavigate = (view) => {
    clearErrors();
    navigate(redirectUrl ? `${view}?redirect=${redirectUrl}` : view);
  };

  return (
    <AnimatedBackgroundView>
      <AppStack
        style={{
          flex: 1,
          alignItems: 'start',
          justifyContent: 'center',
          paddingBottom: isLargeMobileOrSmaller ? 0 : 60,
          paddingTop: isLargeMobileOrSmaller ? 0 : 10
        }}
      >
        <Routes>
          <Route
            element={<SigninView onNavigate={onNavigate} />}
            path="signin"
          />
          <Route
            element={<RegisterView onNavigate={onNavigate} />}
            path="register"
          />
          <Route
            element={<VerifyEmailView onNavigate={onNavigate} />}
            path="confirm-account"
          />
          <Route
            element={<ForgotPasswordView onNavigate={onNavigate} />}
            path="forgot-password"
          />
          <Route
            element={<ResetPasswordView onNavigate={onNavigate} />}
            path="reset-password"
          />
          <Route
            element={
              <Navigate
                replace
                to={
                  redirectUrl
                    ? `/auth/signin?redirect=${redirectUrl}`
                    : '/auth/signin'
                }
              />
            }
            path="*"
          />
        </Routes>
      </AppStack>
    </AnimatedBackgroundView>
  );
};

export default AuthView;
