/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';
import { HEADING_SIZE_STYLE_ENUM } from '../../sections/featuredCollection/wsFeaturedCollectionConfig';

const WsHeadingBlock = ({ config }) => (
  <AppText
    style={{
      fontWeight: 900,
      fontSize:
        config.textSize === HEADING_SIZE_STYLE_ENUM.HUGE
          ? 50
          : config.textSize === HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE
          ? 44
          : config.textSize === HEADING_SIZE_STYLE_ENUM.LARGE
          ? 36
          : config.textSize === HEADING_SIZE_STYLE_ENUM.MEDIUM
          ? 28
          : 22,
      wordBreak: 'break-word',
      overflowWrap: 'break-word'
    }}
  >
    {config.text}
  </AppText>
);

WsHeadingBlock.propTypes = { config: PropTypes.object };

export default WsHeadingBlock;
