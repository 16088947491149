const REPORT_HEADER_ORDER_ENUM = {
  ADDITIONAL_FEES: 'additional_fees',
  ADJUSTMENT: 'adjustment',
  ORDER_VALUE: 'order_value',
  AVERAGE_ORDER_VALUE: 'average_order_value',
  AMOUNT_PAID: 'amount_paid',
  AMOUNT_REFUNDED: 'amount_refunded',
  FULFILMENT_STATUS: 'fulfilment_status',
  GROSS_MARGIN: 'gross_margin',
  GROSS_SALES: 'gross_sales',
  GROSS_PROFIT: 'gross_profit',
  NET_SALES: 'net_sales',
  ORDER_ID: 'order_id',
  ORDER_COUNT: 'order_count',
  COST: 'cost',
  TOTAL_SALES: 'total_sales',
  SHIPPED_ORDERS: 'shipped_orders',
  DELIVERED_ORDERS: 'delivered_orders',
  FULFILLED_ORDERS: 'fulfilled_orders',
  UNITS_ORDERED: 'units_ordered',
  AVERAGE_UNITS_ORDERED: 'average_units_ordered',
  REFUNDED_UNITS: 'refunded_units',
  REFUND_RATE: 'refund_rate',
  DATE: 'date',
  TRANSACTION_COUNT: 'transaction_count',
  PAYMENT_COUNT: 'payment_count'
};

const REPORT_HEADER_CUSTOMER_ENUM = {
  CUSTOMER_EMAIL: 'customer_email',
  CUSTOMER_NAME: 'customer_name',
  CUSTOMER_COUNT: 'customer_count',
  IS_RETURNING: 'is_returning',
  FIRST_ORDER_DATE: 'first_order_date',
  LAST_ORDER_DATE: 'last_order_date'
};

const REPORT_HEADER_PRODUCT_ENUM = {
  NET_QUANTITY: 'net_quantity',
  ORDERED_QUANTITY: 'ordered_quantity',
  AVERAGE_UNITS_ORDERED: 'average_units_ordered',
  PRODUCT_ID: 'product_id',
  PRODUCT_TITLE: 'product_title',
  PRODUCT_NAME: 'product_name',
  PRODUCT_VENDOR: 'product_vendor',
  VARIANT_ID: 'variant_id',
  VARIANT_NAME: 'variant_name',
  VARIANT_SKU: 'variant_sku'
};

const REPORT_HEADER_SHIPPING_ENUM = {
  SHIPPING_ADDRESS: 'shipping_address',
  SHIPPING_CITY: 'shipping_city',
  SHIPPING_COUNTRY: 'shipping_country',
  SHIPPING_POSTAL_CODE: 'shipping_postal_code',
  SHIPPING_PROVINCE: 'shipping_province'
};

const REPORT_HEADER_FUNDRAISER_ENUM = {
  FUNDRAISER_NAME: 'fundraiser_name',
  SALES_WTIH_FUNDRAISER: 'sales_with_fundraiser'
};

const REPORT_HEADER_LIST = [
  // ORDER
  {
    value: REPORT_HEADER_ORDER_ENUM.ADDITIONAL_FEES,
    label: 'Additional fees',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ADJUSTMENT,
    label: 'Adjustment',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
    label: 'Average order value',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
    label: 'Amount paid',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
    label: 'Amount refunded',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.FULFILMENT_STATUS,
    label: 'Fulfilment status',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.GROSS_MARGIN,
    label: 'Gross margin',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
    label: 'Gross sales',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.GROSS_PROFIT,
    label: 'Gross profit',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.NET_SALES,
    label: 'Net sales',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    label: 'Order ID',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
    label: 'Order count',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.COST,
    label: 'Cost',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
    label: 'Total sales',
    category: 'Order',
    chartable: true
  },

  // CUSTOMER
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
    label: 'Customer email',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
    label: 'Customer name',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT,
    label: 'Customer count',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.IS_RETURNING,
    label: 'Is returning',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.FIRST_ORDER_DATE,
    label: 'First order date',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.LAST_ORDER_DATE,
    label: 'Last order date',
    category: 'Customer'
  },

  // PRODUCT
  {
    value: REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
    label: 'Net quantity',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.ORDERED_QUANTITY,
    label: 'Ordered quantity',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.AVERAGE_UNITS_ORDERED,
    label: 'Average units ordered',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_ID,
    label: 'Product ID',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
    label: 'Product title',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
    label: 'Product name',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
    label: 'Product vendor',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.VARIANT_ID,
    label: 'Variant ID',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.VARIANT_NAME,
    label: 'Variant name',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.VARIANT_SKU,
    label: 'Variant SKU',
    category: 'Product'
  },

  // SHIPPING
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_ADDRESS,
    label: 'Shipping address',
    category: 'Shipping'
  },
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
    label: 'Shipping city',
    category: 'Shipping'
  },
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_COUNTRY,
    label: 'Shipping country',
    category: 'Shipping'
  },
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_POSTAL_CODE,
    label: 'Shipping postal code',
    category: 'Shipping'
  },
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_PROVINCE,
    label: 'Shipping province',
    category: 'Shipping'
  },

  // FUNDRAISER
  {
    value: REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
    label: 'Fundraiser name',
    category: 'Fundraiser'
  },
  {
    value: REPORT_HEADER_FUNDRAISER_ENUM.SALES_WTIH_FUNDRAISER,
    label: 'Sales with fundraiser',
    category: 'Fundraiser'
  }
];

const REPORT_CATEGORY_ENUM = {
  SALES: '1',
  CUSTOMERS: '2',
  ORDERS: '3',
  FINANCES: '4'
};

const REPORT_CATEGORY_LIST = [
  {
    label: 'Sales',
    value: REPORT_CATEGORY_ENUM.SALES
  },
  {
    label: 'Customers',
    value: REPORT_CATEGORY_ENUM.CUSTOMERS
  },
  {
    label: 'Orders',
    value: REPORT_CATEGORY_ENUM.ORDERS
  },
  {
    label: 'Finances',
    value: REPORT_CATEGORY_ENUM.FINANCES
  }
];

const REPORT_TYPE_ENUM = {
  SALES: '1',
  CUSTOMERS: '2',
  ORDERS: '3'
};

const REPORT_TYPE_CONFIG = [
  {
    value: REPORT_TYPE_ENUM.SALES,
    label: 'Sales',
    chartable: true,
    headers: [
      // ORDER
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ADDITIONAL_FEES,
      REPORT_HEADER_ORDER_ENUM.ADJUSTMENT,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.FULFILMENT_STATUS,
      REPORT_HEADER_ORDER_ENUM.GROSS_MARGIN,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.GROSS_PROFIT,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.COST,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.TRANSACTION_COUNT,
      REPORT_HEADER_ORDER_ENUM.PAYMENT_COUNT,

      // CUSTOMER
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT,

      // PRODUCT
      REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
      REPORT_HEADER_PRODUCT_ENUM.ORDERED_QUANTITY,
      REPORT_HEADER_PRODUCT_ENUM.AVERAGE_UNITS_ORDERED,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
      REPORT_HEADER_PRODUCT_ENUM.VARIANT_ID,
      REPORT_HEADER_PRODUCT_ENUM.VARIANT_NAME,
      REPORT_HEADER_PRODUCT_ENUM.VARIANT_SKU,

      // SHIPPING
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_ADDRESS,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_COUNTRY,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_POSTAL_CODE,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_PROVINCE,

      // FUNDRAISER
      REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
      REPORT_HEADER_FUNDRAISER_ENUM.SALES_WTIH_FUNDRAISER
    ]
  },
  {
    value: REPORT_TYPE_ENUM.CUSTOMERS,
    label: 'Customers',
    headers: [
      // CUSTOMER
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT,
      REPORT_HEADER_CUSTOMER_ENUM.IS_RETURNING,

      // ORDER
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.REFUND_RATE,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.SHIPPED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.DELIVERED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.FULFILLED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.REFUNDED_UNITS,

      // SHIPPING
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_ADDRESS,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_COUNTRY,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_POSTAL_CODE,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_PROVINCE,

      // FUNDRAISER
      REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
      REPORT_HEADER_FUNDRAISER_ENUM.SALES_WTIH_FUNDRAISER
    ]
  },
  {
    value: REPORT_TYPE_ENUM.ORDERS,
    label: 'Orders',
    headers: [
      // ORDER
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.SHIPPED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.DELIVERED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.FULFILLED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.REFUNDED_UNITS,
      REPORT_HEADER_ORDER_ENUM.ORDER_VALUE,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,

      // PRODUCT
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE,

      // FUNDRAISER
      REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME
    ]
  }
];

const ECOM_REPORT_ENUM = {
  // SALES
  SALES_OVER_TIME: 'sales_over_time',
  AVERAGE_ORDER_VALUE: 'average_order_value',
  SALES_BY_CUSTOMER: 'sales_by_customer',
  SALES_BY_PRODUCT: 'sales_by_product',
  SALES_BY_LOCATION: 'sales_by_location',
  SALES_BY_VARIANT: 'sales_by_product_variant',
  SALES_BY_SKU: 'sales_by_sku',
  SALES_BY_FUNDRAISER: 'sales_by_fundraiser',

  // CUSTOMERS
  TOP_CUSTOMERS: 'top_customers',
  RETURNING_CUSTOMERS: 'returning_customers',
  FIRST_TIME_CUSTOMERS: 'first_time_customers',
  CUSTOMERS_BY_LOCATION: 'customers_by_location',
  CUSTOMERS_OVER_TIME: 'customers_over_time',
  FIRST_VS_RETURNING_CUSTOMERS: 'first_vs_returning_customers',

  // ORDERS
  PRODUCT_ORDERS: 'product_orders_and_returns',
  FUNDRAISER_ORDERS: 'orders_by_fundraiser',
  ORDERS_OVER_TIME: 'orders_over_time',
  ORDER_VALUE: 'order_value',
  FULFILMENT_STATUS: 'order_fulfilment_over_time',

  // INVENTORY
  SOLD_INVENTORY_PERCENTAGE: 'sold_inventory_percentage',
  INVENTORY_VALUE: 'inventory_value',
  INVENTORY_SNAPSHOT: 'inventory_snapshot',
  INVENTORY_OVER_TIME: 'inventory_over_time',
  AVERAGE_INVENTORY_SOLD: 'average_inventory_sold',

  // FINANCES
  FINANCE_SUMMARY: 'finance_summary',
  TOTAL_SALES: 'total_sales',
  REFUNDS: 'refunds',
  PAYMENTS: 'payments',
  NET_SALES: 'net_sales',
  NET_PROFIT: 'net_profit',
  GROSS_PAYMENT_BY_MONTH: 'gross_payment_by_month',
  GROSS_SALES: 'gross_sales',
  COST_OF_GOODS: 'cost_of_goods'
};

export {
  REPORT_HEADER_ORDER_ENUM,
  REPORT_HEADER_CUSTOMER_ENUM,
  REPORT_HEADER_PRODUCT_ENUM,
  REPORT_HEADER_SHIPPING_ENUM,
  REPORT_HEADER_FUNDRAISER_ENUM,
  REPORT_HEADER_LIST,
  REPORT_CATEGORY_ENUM,
  REPORT_CATEGORY_LIST,
  REPORT_TYPE_ENUM,
  REPORT_TYPE_CONFIG,
  ECOM_REPORT_ENUM
};
