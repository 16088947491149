import React from 'react';
import { MERCHANT_NAVIGATION_LINKS } from './navigationLinks';
import Sidebar from './Sidebar';
import StoreSelectInput from '../stores/StoreSelectInput';

const StoreSidebar = () => (
  <Sidebar
    navigation={MERCHANT_NAVIGATION_LINKS}
    selectInput={<StoreSelectInput placeholder="Select a store" />}
    subTitle="Merchant"
    title="SportsHeadz"
  />
);

export default StoreSidebar;
