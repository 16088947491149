import React, { useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image } from '@mantine/core';
import dayjs from 'dayjs';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import TableView from '../../common/TableView';
import { usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import AppStack from '../../common/AppStack';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';

const TABLE_COLUMNS = [
  {
    label: 'Store',
    value: 'name',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Team',
    value: 'team',
    sortable: true
  },
  {
    label: 'Association',
    value: 'association',
    sortable: true
  },
  {
    label: 'Gross sales',
    value: 'gross_sales',
    sortable: true
  },
  {
    label: 'Escrow Balance',
    value: 'escrow_balance',
    sortable: true
  },
  {
    label: 'Payment provider',
    value: 'payment_provider',
    sortable: true
  },
  {
    label: 'Window ends',
    value: 'window_date',
    sortable: true
  },
  {
    label: 'Created At',
    value: 'created',
    sortable: true
  }
];

const AdminStoresTableView = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state, fetchPaginatedEcomStores } = useContext(AdminContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      teamSearch: ''
    },
    (newFilter) => {
      fetchPaginatedEcomStores(newFilter, null, (error) => {
        triggerNotification(error);
      });
    },
    state.paginatedEcomStores
  );
  const loading = !hasFetched.current || dataLoading;

  useEffect(() => {
    fetchPaginatedEcomStores({}, null, (error) => {
      triggerNotification(error);
    });
    hasFetched.current = true;
  }, []);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Stores',
          to: '/admin/stores'
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No stores found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by name',
            value: filter.search || '',
            onChange: (search) =>
              onFilterChange({
                ...filter,
                search
              })
          },
          {
            key: 2,
            label: 'Team',
            placeholder: 'Filter by team/association',
            value: filter.teamSearch || '',
            onChange: (teamSearch) =>
              onFilterChange({
                ...filter,
                teamSearch
              })
          },
          {
            key: 3,
            label: 'Status',
            type: 'select',
            value: filter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'deleted', label: 'Deleted' },
              { value: 'sandbox', label: 'Sandbox' },
              { value: 'live', label: 'Live' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, status: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={data.map((s) => ({
          key: s.ecomStore.pkEcomStore,
          columns: [
            {
              key: 1,
              label: s.ecomStore.name,
              subLabel: s.ecomStore.domain,
              weight: 500,
              onClick: () =>
                navigate(`/admin/stores/${s.ecomStore.pkEcomStore}`),
              component: Link,
              to: `/admin/stores/${s.ecomStore.pkEcomStore}`,
              onSubLabelClick: () => window.open(s.ecomStore.domain, '_blank'),
              icon: (
                <AppStack
                  style={{
                    width: 40,
                    height: 40,
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: s.ecomStore.logoImageUrl
                      ? 'none'
                      : 'solid 1px lightgrey'
                  }}
                >
                  {s.ecomStore.logoImageUrl && (
                    <Image
                      h="100%"
                      src={s.ecomStore.logoImageUrl || ''}
                      w="100%"
                    />
                  )}
                </AppStack>
              )
            },
            {
              key: 2,
              label: s.ecomStore.deleted
                ? 'Deleted'
                : s.ecomStore.sandboxEnabled
                ? 'Sandbox'
                : 'Live',
              color: s.ecomStore.deleted
                ? '#c40000'
                : s.ecomStore.sandboxEnabled
                ? '#eca70a'
                : '#067D62'
            },
            {
              key: 3,
              label: s.ecomStore.team?.name || 'N/A'
            },
            {
              key: 4,
              label:
                s.ecomStore.team?.association?.name ||
                s.ecomStore.association?.name ||
                'N/A'
            },
            {
              key: 5,
              label: currencyFormat(s.grossSales / 100)
            },
            {
              key: 6,
              label: currencyFormat(s.escrowBalance / 100)
            },
            {
              key: 7,
              label: s.ecomStore.ecomStorePaymentProvider?.accountId || 'N/A',
              onClick: s.ecomStore.ecomStorePaymentProvider
                ? () =>
                    window.open(
                      `https://dashboard.stripe.com/connect/accounts/${s.ecomStore.ecomStorePaymentProvider.accountId}`,
                      '_blank'
                    )
                : undefined
            },
            {
              key: 8,
              label: s.ecomStore.ecomStoreWindow
                ? dayjs(
                    formatUtcDate(s.ecomStore.ecomStoreWindow.closeDate)
                  ).format('MMM D, YYYY')
                : 'N/A'
            },
            {
              key: 9,
              label: dayjs(formatUtcDate(s.ecomStore.createdAt)).format(
                'MMM D, YYYY'
              )
            }
          ]
        }))}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Stores"
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </HeaderView>
  );
};

export default AdminStoresTableView;
