import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { ActionIcon, Button } from '@mantine/core';
import { ChevronDown, GripVertical } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import * as styles from '../../styles/sortableTree.module.css';
import AppFlexbox from './AppFlexbox';

const TreeItem = forwardRef(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      onEdit,
      style,
      value,
      wrapperRef,
      childrenElements,
      disabled,
      ...props
    },
    ref
  ) => {
    const childDepthCount = childrenElements.some((c) => c.children.length)
      ? 2
      : childrenElements.length
      ? 1
      : 0;

    return (
      <li
        ref={wrapperRef}
        className={classNames(
          styles.Wrapper,
          clone && styles.clone,
          ghost && styles.ghost,
          indicator &&
            (depth + childDepthCount > 2
              ? styles.indicatorDisabled
              : styles.indicator),
          disableSelection && styles.disableSelection,
          disableInteraction && styles.disableInteraction
        )}
        style={{ '--spacing': `${indentationWidth * depth}px` }}
        {...props}
      >
        <div ref={ref} className={styles.TreeItem} style={style}>
          <AppFlexbox
            style={{ alignItems: 'center', padding: 10, flex: 1, gap: 5 }}
          >
            <ActionIcon
              color="dark"
              data-cypress="draggable-handle"
              style={{ cursor: 'grab' }}
              variant="subtle"
              {...handleProps}
              disabled={disabled}
            >
              <GripVertical size={18} />
            </ActionIcon>
            {!clone && onCollapse && (
              <ActionIcon
                className={classNames(
                  styles.Collapse,
                  collapsed && styles.collapsed
                )}
                color="dark"
                disabled={disabled}
                onClick={onCollapse}
                variant="subtle"
              >
                <ChevronDown size={18} />
              </ActionIcon>
            )}
            <span className={styles.Text}>{value}</span>
            {!clone && (
              <AppFlexbox style={{ gap: 16, alignItems: 'center' }}>
                <Button.Group>
                  {onEdit && (
                    <Button
                      color="dark"
                      disabled={disabled}
                      onClick={onEdit}
                      size="compact-sm"
                      variant="outline"
                    >
                      Edit
                    </Button>
                  )}
                  {onRemove && (
                    <Button
                      color="dark"
                      disabled={disabled}
                      onClick={onRemove}
                      size="compact-sm"
                      variant="outline"
                    >
                      Delete
                    </Button>
                  )}
                </Button.Group>
              </AppFlexbox>
            )}
          </AppFlexbox>
          {clone && childCount && childCount > 1 ? (
            <span className={styles.Count}>{childCount}</span>
          ) : null}
        </div>
      </li>
    );
  }
);

TreeItem.propTypes = {
  childCount: PropTypes.number,
  childrenElements: PropTypes.array,
  clone: PropTypes.bool,
  collapsed: PropTypes.bool,
  depth: PropTypes.number,
  disableInteraction: PropTypes.bool,
  disableSelection: PropTypes.bool,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  handleProps: PropTypes.object,
  indentationWidth: PropTypes.number,
  indicator: PropTypes.bool,
  onCollapse: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.node,
  wrapperRef: PropTypes.func
};

export default TreeItem;
