import React, { useEffect, useState } from 'react';
import { TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ResponsiveDrawer from '../../common/ResponsiveDrawer';
import WebstoreLinkInput from './WebstoreLinkInput';

const WebstoreMenuItemDrawer = ({
  isOpen,
  onClose,
  ecomStoreMenuItem,
  onConfirm
}) => {
  const [formState, setFormState] = useState({
    title: '',
    customUrl: '',
    ecomResourceId: null,
    fkEcomStorePageType: null,
    hasUnsavedChanges: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        title: ecomStoreMenuItem?.title ?? '',
        customUrl: ecomStoreMenuItem?.customUrl ?? '',
        ecomResourceId: ecomStoreMenuItem?.ecomResourceId ?? '',
        fkEcomStorePageType: ecomStoreMenuItem?.fkEcomStorePageType ?? '',
        hasUnsavedChanges: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveDrawer
      closeOnClickOutside={!formState.hasUnsavedChanges}
      formSectionProps={{
        submitDisabled: !formState.hasUnsavedChanges,
        submitTitle: ecomStoreMenuItem ? 'Save changes' : 'Add menu',
        onSubmit: () => {
          if (
            formState.title &&
            (formState.fkEcomStorePageType || formState.customUrl)
          ) {
            onConfirm(formState);
          }
        },
        isSubmitDisabled:
          !formState.hasUnsavedChanges ||
          !formState.title ||
          !(formState.fkEcomStorePageType || formState.customUrl)
      }}
      onClose={onClose}
      opened={isOpen}
      title={ecomStoreMenuItem ? 'Edit menu item' : 'Add menu item'}
    >
      <AppStack style={{ flex: 1, padding: 16 }}>
        <TextInput
          label="Label"
          onChange={(e) => {
            setFormState({
              ...formState,
              title: e.currentTarget.value,
              hasUnsavedChanges: true
            });
          }}
          required
          style={{ flex: 1 }}
          value={formState.title ?? ''}
        />
        <WebstoreLinkInput
          customUrl={formState.customUrl}
          ecomResourceId={formState.ecomResourceId}
          fkEcomStorePageType={formState.fkEcomStorePageType}
          onChange={({ fkEcomStorePageType, ecomResourceId, customUrl }) =>
            setFormState({
              ...formState,
              fkEcomStorePageType,
              ecomResourceId,
              customUrl,
              hasUnsavedChanges: true
            })
          }
          required
        />
      </AppStack>
    </ResponsiveDrawer>
  );
};

WebstoreMenuItemDrawer.propTypes = {
  ecomStoreMenuItem: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default WebstoreMenuItemDrawer;
