/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Button, TextInput } from '@mantine/core';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import WsAccountAuthSection from './WsAccountAuthSection';
import { triggerNotification } from '../../../../../helpers/notification';

const WsAccountForgotPassword = ({
  baseStoreUrl,
  blocks,
  config,
  themeConfig,
  ecomStoreTheme,
  colorSchemes,
  mediaQueries,
  sort,
  onAuthNavigation,
  pathname,
  ...rest
}) => {
  const { state, sendForgotPasswordConfirmation } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    email: state.email ?? '',
    error: null,
    loading: false
  });
  const { isLargeMobileOrSmaller } = mediaQueries;

  useEffect(() => {
    setFormState({
      email: state.email ?? '',
      error: null,
      loading: false
    });
  }, []);

  const onSubmit = () => {
    setFormState({
      ...formState,
      loading: true
    });
    sendForgotPasswordConfirmation(
      formState.email,
      () => {
        triggerNotification('Reset code sent!', 'success');
        onAuthNavigation('/account/reset-password');
      },
      (error) =>
        setFormState({
          ...formState,
          loading: false,
          error
        })
    );
  };

  return (
    <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
      <WsAccountAuthSection
        baseStoreUrl={baseStoreUrl}
        ecomStoreTheme={ecomStoreTheme}
        error={formState.error}
        mediaQueries={mediaQueries}
        onNavigate={onAuthNavigation}
        onSubmit={onSubmit}
        title="Password assistance"
      >
        <AppStack
          style={{
            gap: 10,
            flex: 1,
            padding: isLargeMobileOrSmaller
              ? '10px 20px 20px 20px'
              : '10px 40px 40px 40px'
          }}
        >
          <AppStack style={{ gap: 0 }}>
            <AppText style={{ fontSize: 14 }}>
              Please enter the email associated with your SportsHeadz account to
              begin resetting your password.
            </AppText>
          </AppStack>

          <TextInput
            disabled={formState.loading}
            label="Email"
            onChange={(e) =>
              setFormState({
                ...formState,
                email: e.target.value
              })
            }
            required
            value={formState.email}
          />
          <Button
            color="dark"
            loading={formState.loading}
            radius={10}
            size="sm"
            style={{ fontSize: 13, margin: '5px 0px' }}
            type="submit"
          >
            Continue
          </Button>

          <AppText style={{ fontSize: 14 }}>
            Remember your password?{' '}
            <Anchor
              onClick={() => onAuthNavigation('/account/login')}
              style={{ fontSize: 14, color: 'dodgerblue' }}
            >
              Sign in
            </Anchor>
          </AppText>
        </AppStack>
      </WsAccountAuthSection>
    </AppFlexbox>
  );
};

WsAccountForgotPassword.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onAuthNavigation: PropTypes.func,
  pathname: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsAccountForgotPassword;
