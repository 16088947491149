import React, { useEffect, useRef, useState } from 'react';
import {
  ActionIcon,
  Alert,
  Checkbox,
  Divider,
  NumberFormatter,
  Select,
  Textarea,
  TextInput,
  Tooltip
} from '@mantine/core';
import PropTypes from 'prop-types';
import { AlertTriangle, GripVertical, Tag, Trash } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import ResponsiveDrawer from '../../common/ResponsiveDrawer';
import {
  INPUT_CONTROL_TYPE_ENUM,
  INPUT_CONTROL_TYPE_OPTIONS
} from '../../../config/constants';
import AppText from '../../common/AppText';
import SortableList from '../../common/SortableList';
import ResponsiveModal from '../../common/ResponsiveModal';
import CustomNumericFormat from '../../common/CustomNumericFormat';

const EditProductInputDrawer = ({
  isOpen,
  onClose,
  ecomVendorProductInput,
  ecomVendorProductVariants,
  onConfirm
}) => {
  const lastInputRef = useRef(null);
  const [formState, setFormState] = useState({
    label: '',
    instructions: '',
    adjustedCost: null,
    isRequired: true,
    fkRegFormControlType: null,
    ecomVendorProductInputOptions: [],
    ecomVendorProductVariantInputs: [],
    additionalCostModalState: {
      isOpen: false,
      option: null,
      adjustedCost: null
    },
    hasChanges: false,
    errors: {},
    submitError: null
  });

  const isDropdownInput =
    formState.fkRegFormControlType ===
    INPUT_CONTROL_TYPE_ENUM.DROP_DOWN.toString();

  useEffect(() => {
    if (isOpen) {
      setFormState({
        label: ecomVendorProductInput?.label ?? '',
        isRequired: ecomVendorProductInput?.isRequired ?? true,
        adjustedCost: ecomVendorProductInput?.adjustedCost ?? null,
        instructions: ecomVendorProductInput?.instructions ?? '',
        fkRegFormControlType:
          ecomVendorProductInput?.fkRegFormControlType?.toString() ?? null,
        ecomVendorProductInputOptions:
          ecomVendorProductInput?.ecomVendorProductInputOptions.sort(
            (a, b) => a.sort - b.sort
          ) ?? [],
        ecomVendorProductVariantInputs: ecomVendorProductInput
          ? ecomVendorProductVariants
              .filter((v) =>
                v.ecomVendorProductVariantInputs.some(
                  (i) =>
                    i.fkEcomVendorProductInput ===
                      ecomVendorProductInput.pkEcomVendorProductInput ||
                    i.ecomvendorProductInputReferenceKey ===
                      ecomVendorProductInput.key
                )
              )
              .map((v) => ({ key: v.key }))
          : [],
        additionalCostModalState: {
          isOpen: false,
          option: null,
          adjustedCost: null
        },
        hasChanges: false,
        errors: {},
        submitError: null
      });
    }
    else {
      setFormState({
        label: '',
        isRequired: true,
        fkRegFormControlType: null,
        ecomVendorProductInputOptions: [],
        ecomVendorProductVariantInputs: [],
        additionalCostModalState: {
          isOpen: false,
          option: null
        },
        hasChanges: false,
        errors: {},
        submitError: null
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (lastInputRef.current) {
      lastInputRef.current.focus();
    }
  }, [formState.ecomVendorProductInputOptions.length]);

  const checkForOptionErrors = () => {
    if (isDropdownInput) {
      const duplicateOptions = formState.ecomVendorProductInputOptions.filter(
        (option) =>
          formState.ecomVendorProductInputOptions.find(
            (op) =>
              op.value?.toLowerCase() === option.value?.toLowerCase() &&
              op.key !== option.key
          )
      );
      setFormState({
        ...formState,
        errors: duplicateOptions.reduce((acc, option) => {
          acc[option.key] = 'Duplicate option';
          return acc;
        }, {})
      });
    }
  };

  return (
    <ResponsiveDrawer
      closeOnClickOutside={!formState.hasChanges}
      formSectionProps={{
        submitTitle: ecomVendorProductInput ? 'Save changes' : 'Add input',
        submitDisabled: !formState.hasChanges,
        onSubmit: () => {
          const duplicateOptions = formState.ecomVendorProductInputOptions.filter(
            (option) =>
              formState.ecomVendorProductInputOptions.find(
                (op) =>
                  op.value?.toLowerCase() === option.value?.toLowerCase() &&
                  op.key !== option.key
              )
          );
          if (isDropdownInput && duplicateOptions.length > 0) {
            setFormState({
              ...formState,
              submitError: 'Duplicate options are not allowed'
            });
          }
          else {
            onConfirm({
              ...ecomVendorProductInput,
              ...formState,
              key: ecomVendorProductInput?.key ?? new Date().getTime(),
              label: formState.label,
              isRequired: formState.isRequired,
              fkRegFormControlType: formState.fkRegFormControlType,
              ecomVendorProductVariantInputs:
                formState.ecomVendorProductVariantInputs,
              ecomVendorProductInputOptions: isDropdownInput
                ? formState.ecomVendorProductInputOptions
                : []
            });
          }
        }
      }}
      onClose={onClose}
      opened={isOpen}
      title={ecomVendorProductInput ? 'Edit custom input' : 'Add custom input'}
      transitionProps={{ duration: 0 }}
    >
      <AppStack style={{ flex: 1, gap: 0, height: '100%' }}>
        <AppStack style={{ padding: 16 }}>
          {formState.submitError && (
            <Alert
              color="#c40000"
              icon={<AlertTriangle style={{ height: 22, width: 22 }} />}
              radius={10}
              styles={{
                body: { gap: 5 },
                icon: { height: 28, width: 28 },
                title: { fontSize: 14, fontWeight: 500 }
              }}
              title="Please fix the following errors"
              variant="outline"
            >
              <AppText style={{ fontSize: 13 }}>
                {formState.submitError}
              </AppText>
            </Alert>
          )}
          <TextInput
            label="Input label"
            onChange={(e) =>
              setFormState({
                ...formState,
                label: e.currentTarget.value,
                hasChanges: true
              })
            }
            required
            value={formState.label}
          />
          <Textarea
            description="Add instructions for customers such as recommended image resolution."
            label="Instructions"
            onChange={(e) =>
              setFormState({
                ...formState,
                instructions: e.currentTarget.value
              })
            }
            value={formState.instructions}
          />
          <Select
            clearable
            data={INPUT_CONTROL_TYPE_OPTIONS.map((op) => ({
              label: op.label,
              value: op.value.toString()
            }))}
            label="Input control type"
            onChange={(value) => {
              setFormState({
                ...formState,
                fkRegFormControlType: value,
                hasChanges: true
              });
            }}
            required
            searchable
            value={formState.fkRegFormControlType}
          />
          <Checkbox
            checked={formState.isRequired}
            description="Make this input required to ensure customers have to fill it out before purchasing"
            label="This input is required"
            onChange={() =>
              setFormState({
                ...formState,
                isRequired: !formState.isRequired,
                hasChanges: true
              })
            }
            style={{ marginTop: 5 }}
            styles={{ label: { fontWeight: 500 } }}
          />
        </AppStack>

        {!isDropdownInput ? (
          <>
            <Divider />
            <AppStack style={{ gap: 10 }}>
              <AppStack style={{ gap: 5, padding: 16, paddingBottom: 0 }}>
                <AppFlexbox>
                  <AppText style={{ fontWeight: 500 }}>
                    Price adjustment
                  </AppText>
                </AppFlexbox>
                <AppText style={{ fontSize: 12, color: '#868e96' }}>
                  Add an additional cost to the product price when this input is
                  selected. This cost will be added to the product price when
                  the customer selects this option.
                </AppText>
              </AppStack>

              <AppStack style={{ gap: 3, padding: 16, paddingTop: 0 }}>
                <CustomNumericFormat
                  allowNegative={false}
                  label="Amount"
                  onValueChange={(values) => {
                    setFormState({
                      ...formState,
                      adjustedCost:
                        values.floatValue != null
                          ? Math.round(values.floatValue * 100)
                          : null
                    });
                  }}
                  style={{ flex: 1 }}
                  value={
                    formState.adjustedCost ? formState.adjustedCost / 100 : null
                  }
                />
              </AppStack>
            </AppStack>
          </>
        ) : (
          <>
            <Divider />
            <AppStack style={{ gap: 10 }}>
              <AppStack style={{ gap: 5, padding: 16, paddingBottom: 0 }}>
                <AppFlexbox>
                  <AppText style={{ fontWeight: 500 }}>Options</AppText>{' '}
                </AppFlexbox>
                <AppText style={{ fontSize: 12, color: '#868e96' }}>
                  Add options for the dropdown input control. These will be the
                  options the customer must choose from when answering this
                  input.
                </AppText>
              </AppStack>

              <AppStack style={{ gap: 3, padding: 16, paddingTop: 0 }}>
                <SortableList
                  items={formState.ecomVendorProductInputOptions}
                  listProps={{ style: { gap: 3 } }}
                  onChange={(items) =>
                    setFormState({
                      ...formState,
                      ecomVendorProductInputOptions: items.map((op, i) => ({
                        ...op,
                        sort: i + 1
                      })),
                      hasChanges: true
                    })
                  }
                  renderItem={(item, sortableProps, index) => (
                    <AppFlexbox
                      key={item.key}
                      ref={sortableProps?.setNodeRef}
                      style={{
                        ...sortableProps?.style,
                        flex: 1,
                        gap: 8,
                        outline: sortableProps?.isOverlay
                          ? 'solid 1px #dee2e6'
                          : 'none',
                        backgroundColor: sortableProps?.isDragging
                          ? 'rgba(56, 56, 56, 0.1)'
                          : '#FFF',
                        opacity: sortableProps?.isDragging ? 0.4 : 1,
                        cursor: sortableProps?.isDragging
                          ? 'grabbing'
                          : 'default'
                      }}
                    >
                      {formState.ecomVendorProductInputOptions.length > 1 && (
                        <ActionIcon
                          color="dark"
                          size={32}
                          style={{
                            marginTop: 2,
                            cursor: sortableProps?.isOverlay
                              ? 'grabbing'
                              : 'pointer'
                          }}
                          variant="subtle"
                        >
                          <GripVertical
                            {...sortableProps?.attributes}
                            {...sortableProps?.listeners}
                          />
                        </ActionIcon>
                      )}

                      <TextInput
                        ref={
                          index ===
                          formState.ecomVendorProductInputOptions.length - 1
                            ? lastInputRef
                            : null
                        }
                        error={formState.errors[item.key]}
                        onBlur={checkForOptionErrors}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            ecomVendorProductInputOptions: formState.ecomVendorProductInputOptions.map(
                              (op) =>
                                op.key === item.key
                                  ? { ...op, value: e.currentTarget.value }
                                  : op
                            ),
                            hasChanges: true
                          })
                        }
                        placeholder="Enter an option"
                        required
                        rightSection={
                          item.adjustedCost ? (
                            <AppFlexbox
                              style={{
                                gap: 0,
                                padding: '0px 5px',
                                overflow: 'hidden',
                                color: item.adjustedCost < 0 ? 'red' : 'green'
                              }}
                            >
                              <NumberFormatter
                                decimalScale={2}
                                fixedDecimalScale
                                prefix={`${item.adjustedCost > 0 ? '+' : ''} $`}
                                style={{ fontSize: 12, whiteSpace: 'nowrap' }}
                                suffix=" "
                                thousandSeparator
                                value={item.adjustedCost / 100}
                              />
                            </AppFlexbox>
                          ) : null
                        }
                        rightSectionWidth={70}
                        style={{ flex: 1 }}
                        value={item.value}
                      />
                      <Tooltip label="Add additional cost" withArrow>
                        <ActionIcon
                          color="dark"
                          onClick={() => {
                            setFormState({
                              ...formState,
                              additionalCostModalState: {
                                isOpen: true,
                                option: item,
                                adjustedCost: item.adjustedCost
                              }
                            });
                          }}
                          size={32}
                          style={{ marginTop: 2 }}
                          variant="subtle"
                        >
                          <Tag />
                        </ActionIcon>
                      </Tooltip>
                      {formState.ecomVendorProductInputOptions.length > 1 && (
                        <Tooltip label="Remove option" withArrow>
                          <ActionIcon
                            color="red"
                            onClick={() => {
                              setFormState({
                                ...formState,
                                ecomVendorProductInputOptions: formState.ecomVendorProductInputOptions
                                  .filter((op) => op.key !== item.key)
                                  .map((op, i) => ({ ...op, sort: i + 1 })),
                                hasChanges: true
                              });
                            }}
                            size={32}
                            style={{ marginTop: 2 }}
                            variant="subtle"
                          >
                            <Trash />
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </AppFlexbox>
                  )}
                />

                {(formState.ecomVendorProductInputOptions.length === 0 ||
                  (formState.ecomVendorProductInputOptions.length >= 1 &&
                    formState.ecomVendorProductInputOptions[
                      formState.ecomVendorProductInputOptions.length - 1
                    ].value)) && (
                  <AppFlexbox
                    style={{
                      flex: 1,
                      gap: 8,
                      alignItems: 'center'
                    }}
                  >
                    {formState.ecomVendorProductInputOptions.length > 1 && (
                      <AppStack style={{ width: 32 }} />
                    )}

                    <TextInput
                      onChange={(e) => {
                        setFormState({
                          ...formState,
                          ecomVendorProductInputOptions: [
                            ...formState.ecomVendorProductInputOptions,
                            {
                              key: new Date().getTime(),
                              value: e.currentTarget.value,
                              sort:
                                formState.ecomVendorProductInputOptions.length +
                                1,
                              adjustedCost: null
                            }
                          ],
                          hasChanges: true
                        });
                      }}
                      placeholder={
                        formState.ecomVendorProductInputOptions.length === 0
                          ? 'Enter your first option'
                          : 'Add another option'
                      }
                      style={{ flex: 1 }}
                      value=""
                    />
                    {formState.ecomVendorProductInputOptions.length > 0 && (
                      <AppStack style={{ width: 32 }} />
                    )}
                    {formState.ecomVendorProductInputOptions.length > 1 && (
                      <AppStack style={{ width: 32 }} />
                    )}
                  </AppFlexbox>
                )}
              </AppStack>
            </AppStack>
          </>
        )}

        {ecomVendorProductVariants.length > 1 && (
          <>
            <Divider />
            <AppStack>
              <AppStack style={{ gap: 5, padding: 16 }}>
                <AppFlexbox>
                  <AppText style={{ fontWeight: 500 }}>Variants</AppText>
                </AppFlexbox>
                <AppText style={{ fontSize: 12, color: '#868e96' }}>
                  Assign this input to one or more product variants. This input
                  will only be displayed to customers when they select one of
                  the variants you choose. If you don't select any variants, the
                  input will be displayed for all variants.
                </AppText>
              </AppStack>
            </AppStack>

            <AppStack style={{ padding: '0px 16px' }}>
              <AppStack
                style={{
                  gap: 0,
                  border: 'solid 1px #dee2e6',
                  borderBottom: 'none'
                }}
              >
                {ecomVendorProductVariants
                  .sort((a, b) => {
                    const aOptionsSorted = a.ecomVendorProductVariantOptions.sort(
                      (a1, b1) => a1.nameSort - b1.nameSort
                    );
                    const bOptionsSorted = b.ecomVendorProductVariantOptions.sort(
                      (a1, b1) => a1.nameSort - b1.nameSort
                    );

                    return (
                      (aOptionsSorted[0]?.valueSort ?? 0) -
                        (bOptionsSorted[0]?.valueSort ?? 0) ||
                      (aOptionsSorted[1]?.valueSort ?? 0) -
                        (bOptionsSorted[1]?.valueSort ?? 0) ||
                      (aOptionsSorted[2]?.valueSort ?? 0) -
                        (bOptionsSorted[2]?.valueSort ?? 0)
                    );
                  })
                  .map((variant) => (
                    <AppFlexbox
                      key={variant.key}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          ecomVendorProductVariantInputs: formState.ecomVendorProductVariantInputs.some(
                            (v) => v.key === variant.key
                          )
                            ? [
                                ...formState.ecomVendorProductVariantInputs.filter(
                                  (v) => v.key !== variant.key
                                )
                              ]
                            : [
                                ...formState.ecomVendorProductVariantInputs,
                                { key: variant.key }
                              ],
                          hasChanges: true
                        });
                      }}
                      style={{
                        padding: 10,
                        gap: 10,
                        alignItems: 'center',
                        cursor: 'pointer',
                        borderBottom: 'solid 1px #dee2e6'
                      }}
                    >
                      <Checkbox
                        checked={formState.ecomVendorProductVariantInputs.some(
                          (v) => v.key === variant.key
                        )}
                        label={variant.label}
                        onChange={() => {}}
                      />
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {variant.ecomVendorProductVariantOptions
                          .sort((a, b) => a.nameSort - b.nameSort)
                          .map((s) => s.value)
                          .join(' / ')}
                      </AppText>
                    </AppFlexbox>
                  ))}
              </AppStack>
            </AppStack>
          </>
        )}
      </AppStack>

      <ResponsiveModal
        formSectionProps={{
          onSubmit: () => {
            setFormState({
              ...formState,
              additionalCostModalState: {
                ...formState.additionalCostModalState,
                isOpen: false
              },
              ecomVendorProductInputOptions: formState.ecomVendorProductInputOptions.map(
                (op) =>
                  op.key === formState.additionalCostModalState.option.key
                    ? {
                        ...op,
                        adjustedCost:
                          formState.additionalCostModalState.adjustedCost ??
                          null
                      }
                    : op
              ),
              hasChanges: true
            });
          },
          onCancel: () =>
            setFormState({
              ...formState,
              additionalCostModalState: {
                ...formState.additionalCostModalState,
                isOpen: false
              }
            }),
          submitTitle: 'Confirm'
        }}
        isOpen={formState.additionalCostModalState.isOpen}
        onClose={() =>
          setFormState({
            ...formState,
            additionalCostModalState: {
              ...formState.additionalCostModalState,
              isOpen: false
            }
          })
        }
        size={500}
        title="Add additional cost"
      >
        <AppStack style={{ gap: 20 }}>
          <AppText style={{ fontSize: 14, color: '#666', textAlign: 'center' }}>
            Add an additional cost{' '}
            {formState.additionalCostModalState.option?.value ? (
              <b>{formState.additionalCostModalState.option.value}</b>
            ) : (
              'to this option'
            )}
            . This cost will be added to the product price when the customer
            selects this option.
          </AppText>

          <CustomNumericFormat
            allowNegative={false}
            label="Price adjustment amount"
            onValueChange={(values) => {
              setFormState({
                ...formState,
                additionalCostModalState: {
                  ...formState.additionalCostModalState,
                  adjustedCost:
                    values.floatValue != null
                      ? Math.round(values.floatValue * 100)
                      : null
                }
              });
            }}
            style={{ flex: 1 }}
            value={
              formState.additionalCostModalState.adjustedCost
                ? formState.additionalCostModalState.adjustedCost / 100
                : null
            }
          />
        </AppStack>
      </ResponsiveModal>
    </ResponsiveDrawer>
  );
};

EditProductInputDrawer.propTypes = {
  ecomVendorProductInput: PropTypes.object,
  ecomVendorProductVariants: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  productPrice: PropTypes.number
};

export default EditProductInputDrawer;
