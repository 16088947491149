import React from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const EditProductCard = ({ title, titleAction, contentProps, children }) => {
  const { isTabletOrSmaller } = useMediaQueryIndex();

  return (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: 0 }}
      withBorder
    >
      <AppStack style={{ gap: 8 }}>
        {(title || titleAction) && (
          <AppFlexbox
            style={{
              padding: '16px 16px 0px 16px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 16, fontWeight: 700 }}>{title}</AppText>
            {titleAction}
          </AppFlexbox>
        )}

        <AppStack
          {...contentProps}
          style={{
            padding: '0px 16px 16px 16px',
            ...contentProps?.style
          }}
        >
          {children}
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

EditProductCard.propTypes = {
  children: PropTypes.node,
  contentProps: PropTypes.object,
  title: PropTypes.string,
  titleAction: PropTypes.any
};

export default EditProductCard;
