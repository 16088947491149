import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, TextInput } from '@mantine/core';
import AppStack from './AppStack';
import ResponsiveModal from './ResponsiveModal';
import AppText from './AppText';

const ConfirmModal = ({
  isLoading,
  isOpen,
  onConfirm,
  onCancel,
  message,
  title,
  confirmActionText,
  confirmActionColor,
  cancelActionText,
  children,
  warnings,
  warningListTitle,
  submitDisabled,
  confirmationValue,
  modalProps,
  formSectionProps
}) => {
  const [formState, setFormState] = useState({ confirmation: '' });
  const confirmationRequired = confirmationValue;
  const confirmationText = confirmationValue || 'delete';

  useEffect(() => {
    if (isOpen) {
      setFormState({ confirmation: '' });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel,
        onSubmit: onConfirm,
        submitColor: confirmActionColor,
        submitTitle: confirmActionText || 'Confirm',
        isSubmitDisabled:
          (confirmationRequired &&
            formState.confirmation !== confirmationText) ||
          submitDisabled,
        cancelTitle: cancelActionText || 'Cancel',
        isLoading,
        ...formSectionProps
      }}
      onClose={onCancel}
      opened={isOpen}
      size={600}
      title={title}
      {...modalProps}
    >
      <AppStack style={{ gap: 25 }}>
        {message && (
          <AppStack>
            {typeof message === 'string' ? (
              <AppText>{message}</AppText>
            ) : (
              message
            )}
          </AppStack>
        )}

        {warnings && warnings.length > 0 && (
          <AppStack style={{ gap: 5 }}>
            <AppText color="grey">{warningListTitle}</AppText>
            <List withPadding>
              {warnings.map((w) => (
                <List.Item key={w}>
                  <AppText color="grey">{w}</AppText>
                </List.Item>
              ))}
            </List>
          </AppStack>
        )}

        {children}

        {confirmationRequired && (
          <AppStack style={{ gap: 5 }}>
            <AppText color="grey">
              Type '{confirmationText}' to confirm.
            </AppText>
            <TextInput
              disabled={isLoading}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  confirmation: e.target.value
                })
              }
              placeholder={confirmationText}
              value={formState.confirmation}
            />
          </AppStack>
        )}
      </AppStack>
    </ResponsiveModal>
  );
};

ConfirmModal.propTypes = {
  cancelActionText: PropTypes.string,
  children: PropTypes.any,
  confirmActionColor: PropTypes.string,
  confirmActionText: PropTypes.string,
  confirmationValue: PropTypes.string,
  formSectionProps: PropTypes.object,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  message: PropTypes.any,
  modalProps: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  submitDisabled: PropTypes.bool,
  title: PropTypes.string,
  warningListTitle: PropTypes.string,
  warnings: PropTypes.array
};

export default ConfirmModal;
