/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Button } from '@mantine/core';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import SettingsModal from '../../../userSettings/SettingsModal';
import { USER_SETTINGS } from '../../../userSettings/userSettings';
import AppCard from '../../../../common/AppCard';

const WsAccountDetails = ({
  baseStoreUrl,
  blocks,
  config,
  themeConfig,
  ecomStoreTheme,
  colorSchemes,
  mediaQueries,
  sort,
  onNavigate,
  pathname,
  editModeEnabled,
  onShowMobileNavigation,
  ...rest
}) => {
  const { isTabletOrSmaller } = mediaQueries;
  const { state: authState } = useContext(AuthContext);
  const [settingModalState, setSettingModalState] = useState({
    isOpen: false,
    setting: null
  });

  return (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: 0 }}
      withBorder
    >
      <AppStack style={{ flex: 1, gap: 0 }}>
        <AppFlexbox style={{ padding: 16, gap: 8 }}>
          {isTabletOrSmaller && (
            <ActionIcon
              color="dark"
              onClick={onShowMobileNavigation}
              variant="subtle"
            >
              <ChevronLeft size={20} />
            </ActionIcon>
          )}
          <AppText style={{ fontSize: 18, fontWeight: 700 }}>
            Account details
          </AppText>
        </AppFlexbox>
        <AppStack style={{ flex: 1, gap: 0 }}>
          {USER_SETTINGS.map((setting) => (
            <React.Fragment key={setting.value}>
              <Button
                color="dark"
                justify="start"
                onClick={() => setSettingModalState({ isOpen: true, setting })}
                radius={0}
                size="md"
                style={{
                  width: '100%',
                  padding: '12px 16px',
                  height: 'unset',
                  borderTop: '1px solid #dee2e6'
                }}
                styles={{ label: { width: '100%' } }}
                variant="subtle"
              >
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <AppFlexbox style={{ gap: 16, alignItems: 'center' }}>
                    <setting.icon color="#666" size={30} />
                    <AppStack style={{ gap: 0, textAlign: 'start' }}>
                      <AppText weight={500}>{setting.label}</AppText>
                      {setting.description &&
                      typeof setting.description === 'string' ? (
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          {setting.description}
                        </AppText>
                      ) : (
                        setting.description
                      )}
                      {setting.getDescription &&
                        setting.getDescription(authState.userData.user)}
                    </AppStack>
                  </AppFlexbox>

                  <ChevronRight color="#666" size={20} />
                </AppFlexbox>
              </Button>
            </React.Fragment>
          ))}
        </AppStack>
        <SettingsModal
          editModeEnabled={editModeEnabled}
          isOpen={settingModalState.isOpen}
          mediaQueries={mediaQueries}
          onClose={() =>
            setSettingModalState({
              ...settingModalState,
              isOpen: false
            })
          }
          setting={settingModalState.setting}
        />
      </AppStack>
    </AppCard>
  );
};

WsAccountDetails.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  onShowMobileNavigation: PropTypes.func,
  pathname: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsAccountDetails;
