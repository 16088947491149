/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import { generateColorVariants } from '../../../../../helpers/styles';

const WsSkuBlock = ({ config, selectedVariant, colorScheme }) =>
  selectedVariant &&
  selectedVariant.ecomVendorProductVariant.sku && (
    <AppFlexbox>
      <AppStack>
        <AppText
          style={{
            fontSize: config.fontSize * 1,
            fontStyle: config.fontStyle,
            fontWeight: config.fontWeight,
            textDecoration: config.textDecoration,
            textTransform: config.textTransform,
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            ...(colorScheme && {
              color: generateColorVariants(colorScheme.textColor, 100).lighter
            })
          }}
        >
          {selectedVariant.ecomVendorProductVariant.sku}
        </AppText>
      </AppStack>
    </AppFlexbox>
  );

WsSkuBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  selectedVariant: PropTypes.object
};

export default WsSkuBlock;
