/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import AppText from '../../../../common/AppText';
import { sterilizeUrlHandle } from '../../../../../helpers/format';

const WsResourceBanner = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  pathname,
  ecomStoreTheme,
  ...rest
}) => {
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const collectionUrlHandle = pathname.split('/')[2];
  const ecomStoreCollection = ecomStoreTheme.ecomStore.ecomStoreCollections.find(
    (c) => sterilizeUrlHandle(c.name) === collectionUrlHandle
  );
  const isAllProducts = pathname.startsWith('/products');

  const title = isAllProducts ? 'All products' : ecomStoreCollection?.name;
  const description =
    config.showCollectionDescription === 'true'
      ? ecomStoreCollection
        ? ecomStoreCollection.description
        : ''
      : config.descriptionText;

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      withPadding
      {...rest}
    >
      <AppStack>
        {title && (
          <AppText style={{ fontSize: 34, fontWeight: 500 }}>{title}</AppText>
        )}

        {description && (
          <AppText style={{ fontSize: 16 }}>{description}</AppText>
        )}
      </AppStack>
    </WsSectionContainer>
  );
};

WsResourceBanner.propTypes = {
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  pathname: PropTypes.string,
  themeConfig: PropTypes.object
};

export default WsResourceBanner;
