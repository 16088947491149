import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mantine/core';

const AppCheckbox = React.forwardRef(({ children, ...rest }, ref) => (
  <Checkbox
    ref={ref}
    {...rest}
    styles={{
      ...rest?.styles,
      input: { cursor: 'pointer', ...rest?.styles?.input },
      label: { cursor: 'pointer', ...rest?.styles?.label }
    }}
  />
));

AppCheckbox.propTypes = { children: PropTypes.node };

export default AppCheckbox;
