import React from 'react';
import { Loader, Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import { useMediaQueryIndex } from '../../helpers/hooks';
import AppFlexbox from './AppFlexbox';
import AppStack from './AppStack';
import FormSection from './FormSection';
import AppText from './AppText';

const ResponsiveModal = ({
  isOpen,
  onClose,
  title,
  size,
  isLoading,
  children,
  noCloseButton,
  onSubmit,
  formSectionProps,
  bodyProps,
  isDrawer,
  ...rest
}) => {
  const { isTabletOrSmaller } = useMediaQueryIndex();

  return (
    <Modal.Root
      centered
      onClose={onClose}
      opened={isOpen}
      withinPortal
      {...rest}
      styles={{
        ...rest.styles,
        header: {
          padding: 16,
          minHeight: 60,
          backgroundColor: '#f3f3f3',
          borderBottom: 'solid 1px #dee2e6',
          ...rest.styles?.header
        },
        title: { flex: 1, padding: 0, ...rest.styles?.title },
        inner: {
          padding: 0,
          height: '100%',
          top: isDrawer || isTabletOrSmaller ? 'unset' : 0,
          alignItems: isDrawer || isTabletOrSmaller ? 'end' : 'center',
          ...rest.styles?.inner
        },
        body: {
          maxHeight: 'calc(100% - 60px)',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: 0,
          ...rest.styles?.body
        },
        root: { flex: 1, ...rest.styles?.root },
        content: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          maxWidth: size ?? 600,
          minHeight: 'unset',
          borderRadius: 20,
          ...(isDrawer || isTabletOrSmaller
            ? {
                maxHeight: '100%',
                maxWidth: '100%',
                borderRadius: 0
              }
            : {}),
          ...rest.styles?.content
        }
      }}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          {typeof title === 'string' ? (
            <AppText style={{ fontSize: 16, fontWeight: 650 }}>{title}</AppText>
          ) : (
            title
          )}

          {!noCloseButton && <Modal.CloseButton />}
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <AppFlexbox style={{ padding: 40, margin: 'auto' }}>
              <Loader color="dark" size="xl" />
            </AppFlexbox>
          ) : !formSectionProps ? (
            <AppStack
              {...bodyProps}
              style={{
                gap: 16,
                padding: 16,
                flex: 1,
                height: '100%',
                overflow: 'auto',
                ...bodyProps?.style
              }}
            >
              {children}
            </AppStack>
          ) : (
            <FormSection {...formSectionProps}>{children}</FormSection>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

ResponsiveModal.propTypes = {
  bodyProps: PropTypes.object,
  children: PropTypes.any,
  formSectionProps: PropTypes.object,
  isDrawer: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  sidePadding: PropTypes.number,
  size: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default ResponsiveModal;
