import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Grid, Pagination, TextInput } from '@mantine/core';
import { ArrowLeft, ClearAll, ShoppingCart } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import * as classes from '../../../styles/animations.module.css';
import AppFlexbox from '../../common/AppFlexbox';
import TableViewDisabledContent from '../../common/TableViewDisabledContent';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const MAX_PAGE_LENGTH = 30;

const VendorCatalogCategoriesView = ({ baseUrl }) => {
  const {
    isMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isDesktopOrSmaller
  } = useMediaQueryIndex();
  const [filterState, setFilterState] = useState({
    search: '',
    page: 1
  });
  const filteredCategories = PRODUCT_CATEGORY_LIST.filter(
    (c) =>
      !filterState.search ||
      c.name
        .toLowerCase()
        .replace(' ', '')
        .includes(filterState.search.toLowerCase().replace(' ', ''))
  );
  const showPagination = filteredCategories.length > MAX_PAGE_LENGTH;

  return (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        padding: 16,
        marginBottom: 70,
        gap: 20,
        alignSelf: 'center'
      }}
    >
      <AppFlexbox
        style={{
          justifyContent: 'space-between',
          gap: 10,
          flexDirection: isMobileOrSmaller ? 'column' : 'row',
          alignItems: isMobileOrSmaller ? 'stretch' : 'center'
        }}
      >
        <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
          <ActionIcon
            color="dark"
            component={Link}
            to={baseUrl}
            variant="subtle"
          >
            <ArrowLeft size={24} />
          </ActionIcon>
          <AppText style={{ fontSize: 28, fontWeight: 700 }}>
            Categories
          </AppText>
        </AppFlexbox>
        <TextInput
          onChange={(e) => {
            setFilterState({
              ...filterState,
              search: e.currentTarget.value,
              page: 1
            });
          }}
          placeholder="Search categories..."
          style={{ flex: 1, maxWidth: isMobileOrSmaller ? 'unset' : 300 }}
          value={filterState.search}
        />
      </AppFlexbox>
      {filteredCategories.length === 0 ? (
        <TableViewDisabledContent
          disabledContent={{
            title: 'No categories found',
            description:
              "We're sorry, but no categories were found. Please try a different search term.",
            icon: <ShoppingCart color="#000" size={125} />,
            primaryButton: {
              label: 'Clear search',
              icon: <ClearAll size={18} />,
              onClick: () =>
                setFilterState({
                  ...filterState,
                  search: '',
                  page: 1
                })
            }
          }}
        />
      ) : (
        <AppStack style={{ gap: 20 }}>
          <Grid
            columns={
              isMobileOrSmaller
                ? 1
                : isTabletOrSmaller
                ? 2
                : isLaptopOrSmaller
                ? 3
                : isDesktopOrSmaller
                ? 4
                : 5
            }
          >
            {filteredCategories
              .slice(
                -MAX_PAGE_LENGTH + filterState.page * MAX_PAGE_LENGTH,
                filterState.page * MAX_PAGE_LENGTH
              )
              .map((category) => (
                <Grid.Col key={category.name} span={1}>
                  <AppCard
                    className={classes['grow-container']}
                    component={Link}
                    radius="md"
                    shadow="xl"
                    style={{
                      flex: 1,
                      height: '100%',
                      cursor: 'pointer',
                      background: category.gradientColor,
                      borderRadius: 10,
                      padding: 16,
                      color: '#FFF',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                      alignItems: 'center',
                      justifyContent: 'start'
                    }}
                    to={`${baseUrl}/categories/${category.name
                      .toLowerCase()
                      .replace(' ', '-')}`}
                  >
                    <AppStack style={{}}>
                      <category.Icon size={55} />
                    </AppStack>
                    <AppText style={{ fontSize: 22, fontWeight: 700 }}>
                      {category.name}
                    </AppText>
                    <AppText
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                        textAlign: 'center'
                      }}
                    >
                      {category.description}
                    </AppText>
                  </AppCard>
                </Grid.Col>
              ))}
          </Grid>
          {showPagination && (
            <AppFlexbox style={{ justifyContent: 'center' }}>
              <Pagination
                color="blue"
                onChange={(page) => {
                  setFilterState({
                    ...filterState,
                    page
                  });
                }}
                total={Math.ceil(filteredCategories.length / MAX_PAGE_LENGTH)}
                value={filterState.page}
              />
            </AppFlexbox>
          )}
        </AppStack>
      )}
    </AppStack>
  );
};

VendorCatalogCategoriesView.propTypes = { baseUrl: PropTypes.string };

export default VendorCatalogCategoriesView;
