import React, { useRef } from 'react';
import {
  ActionIcon,
  Checkbox,
  Divider,
  Image,
  Menu,
  Table
} from '@mantine/core';
import { Dots } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import * as classes from '../../styles/nestedStyles.module.css';
import AppMenu from './AppMenu';

const TableRow = ({
  row,
  isTabletOrSmaller,
  onSelect,
  onAction,
  rowsSelected
}) => {
  const renderTableRowMenuActions = (r) => {
    const sections = [...new Set(r.actions.map((a) => a.section))];
    return (
      r.actions.length > 0 && (
        <AppMenu
          control={
            <ActionIcon color="lightgrey" radius="xl" variant="subtle">
              <Dots color="#000" />
            </ActionIcon>
          }
          styles={{ dropdown: { padding: 0 } }}
        >
          {sections.length > 1 ? (
            sections.map((s, i) => {
              const items = r.actions.filter((a) => a.section === s);
              return (
                <React.Fragment key={items[0].value}>
                  {i > 0 && <Divider />}
                  <AppStack style={{ padding: 2, gap: 1 }}>
                    {items.map((a) => (
                      <Menu.Item
                        key={a.value}
                        leftSection={a.icon}
                        onClick={(e) => {
                          e.preventDefault();
                          onAction(a.value, r);
                        }}
                        style={{ color: a.color ?? '#000' }}
                      >
                        {a.label}
                      </Menu.Item>
                    ))}
                  </AppStack>
                </React.Fragment>
              );
            })
          ) : (
            <AppStack style={{ padding: 2, gap: 1 }}>
              {r.actions.map((a) => (
                <Menu.Item
                  key={a.value}
                  leftSection={a.icon}
                  onClick={(e) => {
                    e.preventDefault();
                    onAction(a.value, r);
                  }}
                  style={{ color: a.color ?? '#000' }}
                >
                  {a.label}
                </Menu.Item>
              ))}
            </AppStack>
          )}
        </AppMenu>
      )
    );
  };

  const renderTableColumnInfo = (
    rowKey,
    c,
    columnIndex,
    isMobile = false,
    hideIcon = false
  ) => (
    <AppFlexbox style={{ alignItems: 'center', gap: 10, minHeight: 36 }}>
      {onSelect && columnIndex === 0 ? (
        <Checkbox
          checked={rowsSelected.includes(rowKey)}
          onChange={() => {}}
          styles={{ input: { cursor: 'pointer' } }}
        />
      ) : (
        onSelect && isMobile && <AppStack style={{ width: 20 }} />
      )}
      {!hideIcon &&
        (c.src || c.showImage ? (
          <AppStack style={{ width: 40, height: 40 }}>
            <Image
              fit="contain"
              h="100%"
              src={c.src}
              style={{ flex: 1 }}
              w="100%"
            />
          </AppStack>
        ) : (
          !!c.icon && <AppStack>{c.icon}</AppStack>
        ))}
      <AppStack
        component={c.component}
        style={{
          gap: 0,
          alignItems: 'start',
          textDecoration: 'none',
          color: '#000'
        }}
        to={c.to}
      >
        {c.customComponent ? (
          c.customComponent
        ) : (
          <>
            <AppText
              className={
                c.onClick || c.to ? classes['hover-text-action'] : null
              }
              color={c.color || '#000'}
              onClick={c.onClick}
              style={{
                fontSize: 14,
                lineHeight: '18px',
                ...(isMobile
                  ? {
                      display: '-webkit-box',
                      WebkitLineClamp: '2',
                      lineClamp: '2',
                      WebkitBoxOrient: 'vertical'
                    }
                  : { maxWidth: 400 })
              }}
              weight={c.weight}
            >
              {c.label}
            </AppText>
            {c.subLabel && (
              <AppText
                className={
                  c.onSubLabelClick ? classes['hover-text-action'] : null
                }
                color={c.subLabelColor || 'grey'}
                onClick={c.onSubLabelClick}
                style={{
                  fontSize: 14,
                  lineHeight: '18px',
                  ...(isMobile
                    ? {
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        lineClamp: '2',
                        WebkitBoxOrient: 'vertical'
                      }
                    : { maxWidth: 400 })
                }}
              >
                {c.subLabel}
              </AppText>
            )}
          </>
        )}
      </AppStack>
    </AppFlexbox>
  );

  return isTabletOrSmaller ? (
    <Table.Tr>
      <Table.Td style={{ padding: '12px 10px' }}>
        <AppFlexbox style={{ flex: 1, alignItems: 'center' }}>
          <AppFlexbox
            onClick={
              onSelect
                ? (e) => {
                    e.stopPropagation();
                    onSelect(row.key);
                  }
                : null
            }
            style={{
              flex: 1,
              alignItems: 'start',
              gap: 10,
              ...(onSelect ? { cursor: 'pointer' } : {})
            }}
          >
            <AppStack
              style={{
                flex: 1,
                gap: 0,
                textWrap: 'wrap',
                alignSelf: 'center'
              }}
            >
              {renderTableColumnInfo(row.key, row.columns[0], 0, true)}
              {row.columns
                .filter((c) => c.showOnMobile)
                .map((c) => (
                  <React.Fragment key={c.key}>
                    {renderTableColumnInfo(row.key, c, null, true, true)}
                  </React.Fragment>
                ))}
            </AppStack>
          </AppFlexbox>

          {onAction && row.actions && renderTableRowMenuActions(row)}
        </AppFlexbox>
      </Table.Td>
    </Table.Tr>
  ) : (
    <Table.Tr>
      <>
        {row.columns.map((c, i) => (
          <Table.Td
            key={c.key}
            onClick={
              onSelect
                ? (e) => {
                    e.stopPropagation();
                    onSelect(row.key);
                  }
                : null
            }
            style={onSelect ? { cursor: 'pointer' } : {}}
          >
            {renderTableColumnInfo(row.key, c, i)}
          </Table.Td>
        ))}
        {onAction && (
          <Table.Td style={{ textAlign: 'end', paddingRight: 40 }}>
            {row.actions && renderTableRowMenuActions(row)}
          </Table.Td>
        )}
      </>
    </Table.Tr>
  );
};

TableRow.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  isTabletOrSmaller: PropTypes.bool,
  onAction: PropTypes.func,
  onSelect: PropTypes.func,
  rowsSelected: PropTypes.array
};

export default TableRow;
