import React, { useState } from 'react';
import { ChevronRight, CirclePlus } from 'tabler-icons-react';
import { Button, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppMenu from '../../common/AppMenu';
import WebstoreBuilderSidebarButton from './WebstoreBuilderSidebarButton';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { ECOM_WEBSTORE_SECTIONS_LIST } from './sections/wsSectionConfig';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import ResponsiveModal from '../../common/ResponsiveModal';

const WebstoreBuilderSidebarAddSectionMenu = ({ onAdd, sectionBoundsType }) => {
  const { isLaptopOrSmaller } = useMediaQueryIndex();
  const [formState, setFormState] = useState({ search: '', menuOpen: false });
  const availableSections = ECOM_WEBSTORE_SECTIONS_LIST.filter(
    (s) =>
      s.allowedBounds.includes(sectionBoundsType) &&
      !s.isPermanent &&
      !s.cannotCreate &&
      (!formState.search ||
        s.title.toLowerCase().includes(formState.search.toLowerCase()))
  );

  return (
    <>
      <AppMenu
        control={
          isLaptopOrSmaller ? (
            <Button
              color="dark"
              onClick={() =>
                setFormState({
                  ...formState,
                  menuOpen: true,
                  search: ''
                })
              }
              size="lg"
              style={{
                fontSize: 14,
                color: 'dodgerblue',
                padding: '18px 0px',
                height: 48
              }}
              variant="subtle"
            >
              Add section
            </Button>
          ) : (
            <AppFlexbox style={{ flex: 1 }}>
              <WebstoreBuilderSidebarButton
                color="dodgerblue"
                label="Add Section"
                leftSection={
                  <>
                    <AppStack style={{ width: 22 }} />
                    <AppStack
                      style={{
                        width: 22,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <CirclePlus size={18} />
                    </AppStack>
                  </>
                }
                onClick={() =>
                  setFormState({
                    ...formState,
                    menuOpen: true,
                    search: ''
                  })
                }
                selected={formState.menuOpen}
              />
            </AppFlexbox>
          )
        }
        onClose={() => setFormState({ ...formState, menuOpen: false })}
        opened={!isLaptopOrSmaller && formState.menuOpen}
        position="right-start"
        radius={10}
        width={300}
      >
        <AppStack style={{ gap: 5, flex: 1, height: 500, padding: 2 }}>
          <TextInput
            onChange={(e) =>
              setFormState({
                ...formState,
                search: e.currentTarget.value
              })
            }
            placeholder="Search sections"
            radius={10}
            size="sm"
            value={formState.search}
          />
          <AppStack style={{ gap: 1, flex: 1, overflow: 'auto' }}>
            {availableSections.length === 0 ? (
              <AppStack
                style={{
                  gap: 10,
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: '40px 20px'
                }}
              >
                <AppText
                  style={{ fontSize: 14, fontWeight: 500, color: '#666' }}
                >
                  No sections found.
                </AppText>
                {formState.search && (
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Try searching for something else.
                  </AppText>
                )}
              </AppStack>
            ) : (
              availableSections.map((s) => (
                <WebstoreBuilderSidebarButton
                  key={s.type}
                  label={s.title}
                  leftSection={
                    <AppStack
                      style={{
                        paddingLeft: 10,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <s.icon size={18} />
                    </AppStack>
                  }
                  onClick={() => {
                    onAdd(s.type, sectionBoundsType);
                    setFormState({ ...formState, menuOpen: false });
                  }}
                />
              ))
            )}
          </AppStack>
        </AppStack>
      </AppMenu>
      {isLaptopOrSmaller && (
        <ResponsiveModal
          isDrawer={isLaptopOrSmaller}
          isOpen={formState.menuOpen}
          onClose={() => setFormState({ ...formState, menuOpen: false })}
          styles={{ content: { height: 'calc(100% - 60px)' } }}
          title="Add Section"
        >
          <AppStack
            style={{
              gap: 5,
              flex: 1,
              minHeight: '100%',
              padding: 2
            }}
          >
            <TextInput
              onChange={(e) =>
                setFormState({
                  ...formState,
                  search: e.currentTarget.value
                })
              }
              placeholder="Search sections"
              radius={10}
              size="sm"
              value={formState.search}
            />
            <AppStack style={{ gap: 1, flex: 1, overflow: 'auto' }}>
              {availableSections.length === 0 ? (
                <AppStack
                  style={{
                    gap: 10,
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '40px 20px'
                  }}
                >
                  <AppText
                    style={{ fontSize: 14, fontWeight: 500, color: '#666' }}
                  >
                    No sections found.
                  </AppText>
                  {formState.search && (
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      Try searching for something else.
                    </AppText>
                  )}
                </AppStack>
              ) : (
                availableSections.map((s) => (
                  <WebstoreBuilderSidebarButton
                    key={s.type}
                    fontSize={16}
                    height={45}
                    label={s.title}
                    leftSection={
                      <AppStack
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <s.icon size={18} />
                      </AppStack>
                    }
                    onClick={() => {
                      onAdd(s.type, sectionBoundsType);
                      setFormState({ ...formState, menuOpen: false });
                    }}
                    rightSection={<ChevronRight color="#666" size={18} />}
                    style={{ padding: '0px 10px' }}
                  />
                ))
              )}
            </AppStack>
          </AppStack>
        </ResponsiveModal>
      )}
    </>
  );
};

WebstoreBuilderSidebarAddSectionMenu.propTypes = {
  control: PropTypes.node,
  onAdd: PropTypes.func,
  sectionBoundsType: PropTypes.number
};

export default WebstoreBuilderSidebarAddSectionMenu;
