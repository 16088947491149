import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@mantine/core';

const AppStack = React.forwardRef(({ children, ...rest }, ref) => (
  <Flex
    ref={ref}
    {...rest}
    style={{
      gap: 16,
      flexWrap: 'nowrap',
      flexDirection: 'column',
      ...rest.style
    }}
  >
    {children}
  </Flex>
));

AppStack.propTypes = { children: PropTypes.node };

export default AppStack;
