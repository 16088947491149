import React, { useContext, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import PropTypes from 'prop-types';
import { ArrowLeft } from 'tabler-icons-react';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import VendorCatalogSearchFilter from './VendorCatalogSearchFilter';
import { useProductSearchFilter } from '../../../helpers/catalogHelper';
import VendorCatalogProductSearchList from './VendorCatalogProductSearchList';
import ActionableIcon from '../../common/ActionableIcon';
import { CATALOG_SORT_OPTIONS } from '../../../config/constants';

const VendorCatalogProductSortExplorer = ({
  loading,
  vendorPreview,
  baseUrl
}) => {
  const navigate = useNavigate();
  const { sort } = useParams();
  const { state } = useContext(StoreContext);
  const { hasSearched, resetFilter } = useProductSearchFilter();
  const explorerLoading =
    loading || state.catalogSummary.loading || !state.catalogSummary.value;

  const sortOption = CATALOG_SORT_OPTIONS.find((o) => o.path === sort);

  useEffect(() => {
    if (sort && !sortOption) {
      navigate(`${baseUrl}/products`);
    }
  }, [sort]);

  return (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        margin: 16,
        marginBottom: 70,
        gap: 20,
        alignSelf: 'center'
      }}
    >
      <AppStack style={{ gap: 5 }}>
        <AppFlexbox
          style={{ alignItems: 'end', justifyContent: 'space-between' }}
        >
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <ActionableIcon
              color="dark"
              component={Link}
              radius="md"
              to={baseUrl}
              variant="subtle"
            >
              <ArrowLeft />
            </ActionableIcon>
            <AppText style={{ fontSize: 28, fontWeight: 700 }}>
              {sortOption?.pathTitle ?? 'All Products'}
            </AppText>
          </AppFlexbox>

          {hasSearched && (
            <Anchor
              c="dodgerblue"
              onClick={resetFilter}
              style={{ fontSize: 16 }}
            >
              Clear search
            </Anchor>
          )}
        </AppFlexbox>

        <VendorCatalogSearchFilter sort={sortOption?.value} />
      </AppStack>
      <VendorCatalogProductSearchList
        baseUrl={baseUrl}
        fromLocation="products"
        fromResource={sort}
        loading={explorerLoading}
        sort={sortOption?.value}
        vendorPreview={vendorPreview}
      />
    </AppStack>
  );
};

VendorCatalogProductSortExplorer.propTypes = {
  baseUrl: PropTypes.string,
  loading: PropTypes.bool,
  vendorPreview: PropTypes.bool
};

export default VendorCatalogProductSortExplorer;
