import React, { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AlertCircle, ArrowLeft, Plus } from 'tabler-icons-react';
import { Button, Skeleton, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppCard from '../../common/AppCard';
import WebstoreMenuItemDrawer from './WebstoreMenuItemDrawer';
import { triggerNotification } from '../../../helpers/notification';
import SortableTree from '../../common/SortableTree';
import ConfirmModal from '../../common/ConfirmModal';
import {
  useGlobalFormState,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';

const WebstoreMenuEditView = ({ isViewLoading, duplicate }) => {
  const navigate = useNavigate();
  const { pkEcomStoreMenu } = useParams();
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = useMediaQueryIndex();
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    createStoreMenu,
    updateStoreMenu,
    deleteStoreMenu
  } = useContext(StoreContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const {
    hasInitialized,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    hasUnsavedChanges,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(
    {
      title: '',
      ecomStoreMenuItems: []
    },
    {
      confirmDiscard: true,
      containerWidth: 950
    }
  );
  const ecomStoreMenu = state.ecomStoreMenus.value.find(
    (m) =>
      m.pkEcomStoreMenu.toString() === pkEcomStoreMenu &&
      m.fkEcomStore.toString() === authState.pkEcomStore.toString()
  );
  const selectedItem =
    modalState.action === VIEW_ACTIONS_ENUM.EDIT
      ? formState.ecomStoreMenuItems.find((i) => i.key === modalState.item)
      : null;

  useEffect(() => {
    if (!pkEcomStoreMenu || ecomStoreMenu) {
      resetFormState({
        title: ecomStoreMenu?.title ?? '',
        ecomStoreMenuItems:
          ecomStoreMenu?.ecomStoreMenuItems.map((i) => ({
            ...i,
            key: i.pkEcomStoreMenuItem
          })) ?? []
      });
    }
  }, [ecomStoreMenu, duplicate]);

  const onTreeSortChange = (items) => {
    let maxTreeDepthExceeded = false;
    const menuItemsCopy = [...formState.ecomStoreMenuItems];
    items.forEach((item, index) => {
      const menuItem = menuItemsCopy.find((i) => i.key === item.id);
      menuItem.fkEcomStoreMenuItem = null;
      menuItem.sort = index + 1;
      menuItem.collapsed = item.collapsed;
      menuItem.deleted = item.deleted;
      item.children.forEach((child, childIndex) => {
        const childMenuItem = menuItemsCopy.find((i) => i.key === child.id);
        childMenuItem.fkEcomStoreMenuItem = menuItem.key;
        childMenuItem.sort = childIndex + 1;
        childMenuItem.collapsed = child.collapsed;
        childMenuItem.deleted = menuItem.deleted || child.deleted;
        child.children.forEach((subChild, subChildIndex) => {
          const subChildMenuItem = menuItemsCopy.find(
            (i) => i.key === subChild.id
          );
          subChildMenuItem.fkEcomStoreMenuItem = childMenuItem.key;
          subChildMenuItem.sort = subChildIndex + 1;
          subChildMenuItem.collapsed = subChild.collapsed;
          subChildMenuItem.deleted = childMenuItem.deleted || subChild.deleted;
          if (subChild.children.length > 0) {
            maxTreeDepthExceeded = true;
          }
        });
      });
    });

    if (!maxTreeDepthExceeded) {
      setFormState({
        ...formState,
        ecomStoreMenuItems: menuItemsCopy
      });
    }
  };

  const onSaveChanges = () => {
    submitFormState((formData, onErrorCallback) => {
      const ecomStoreMenuData = {
        title: formState.title,
        ecomStoreMenuItems: formData.ecomStoreMenuItems
          .filter((i) => !i.deleted)
          .map((i) => ({
            referenceKey: i.key,
            fkReferenceKey: i.fkEcomStoreMenuItem,
            title: i.title,
            customUrl: i.customUrl || null,
            sort: i.sort,
            ecomResourceId: i.ecomResourceId || null,
            fkEcomStorePageType: i.fkEcomStorePageType || null
          }))
      };

      if (!duplicate && ecomStoreMenu) {
        updateStoreMenu(
          ecomStoreMenu.pkEcomStoreMenu,
          ecomStoreMenuData,
          () => {
            triggerNotification('Menu updated!', 'success');
          },
          onErrorCallback
        );
      }
      else {
        createStoreMenu(
          authState.pkEcomStore,
          ecomStoreMenuData,
          (data) => {
            triggerNotification('Menu created!', 'success');
            navigate(`/merchant/webstore/menus/${data.pkEcomStoreMenu}`);
          },
          onErrorCallback
        );
      }
    });
  };

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      default: {
        if (duplicate) {
          return `/merchant/webstore/menus/${pkEcomStoreMenu}`;
        }
        return '/merchant/webstore/menus';
      }
    }
  };

  return !isViewLoading && (!pkEcomStoreMenu || hasInitialized) ? (
    <>
      <AppStack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSaveChanges();
        }}
        p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
        style={{
          flex: 1,
          margin: 'auto',
          width: '100%',
          maxWidth: 950,
          paddingTop: 16,
          gap: 16
        }}
      >
        <AppFlexbox
          p={{ base: '0px 8px', sm: 0 }}
          style={{
            gap: 5,
            alignItems: isLargeMobileOrSmaller ? 'stretch' : 'center',
            flexDirection: isLargeMobileOrSmaller ? 'column' : 'row',
            justifyContent: 'space-between'
          }}
        >
          <AppFlexbox
            style={{
              gap: 5,
              alignItems: 'center'
            }}
          >
            <ActionableIcon
              color="dark"
              component={Link}
              radius="md"
              to={getBackPath()}
              variant="subtle"
            >
              <ArrowLeft />
            </ActionableIcon>
            <AppText style={{ fontSize: 18, fontWeight: 700 }}>
              {ecomStoreMenu?.title ?? 'Add menu'}
            </AppText>
          </AppFlexbox>
          {ecomStoreMenu && !duplicate && (
            <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
              <Button
                color="dark"
                component={Link}
                disabled={isSubmitting}
                radius="md"
                size="xs"
                style={{ flex: isLargeMobileOrSmaller ? 1 : 'unset' }}
                to={`/merchant/webstore/menus/${pkEcomStoreMenu}/duplicate`}
                variant="light"
              >
                Duplicate
              </Button>
            </AppFlexbox>
          )}
        </AppFlexbox>

        {duplicate && (
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 8 }}
            withBorder
          >
            <AppFlexbox style={{ gap: 8 }}>
              <AppStack>
                <AlertCircle color="#eca70a" size={18} />
              </AppStack>
              <AppText style={{ fontSize: 14 }}>
                Duplicate this menu by changing the <b>Title</b> and clicking
                save.
              </AppText>
            </AppFlexbox>
          </AppCard>
        )}

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <TextInput
            disabled={isSubmitting}
            label="Title"
            onChange={(e) =>
              setFormState({
                ...formState,
                title: e.currentTarget.value
              })
            }
            required
            value={formState.title}
          />
        </AppCard>

        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox
              style={{
                padding: '16px 16px 0px 16px',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                Menu Items
              </AppText>
            </AppFlexbox>
            {formState.ecomStoreMenuItems.length === 0 ? (
              <AppCard
                radius="lg"
                shadow="xs"
                style={{
                  padding: '40px 20px',
                  margin: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
                withBorder
              >
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  This menu doesn't have any items.
                </AppText>
              </AppCard>
            ) : (
              <AppStack style={{ paddingTop: 16, gap: 0 }}>
                <SortableTree
                  collapsible
                  disabled={isSubmitting}
                  indicator
                  items={formState.ecomStoreMenuItems
                    .filter((i) => !i.fkEcomStoreMenuItem && !i.deleted)
                    .sort((a, b) => a.sort - b.sort)
                    .map((i) => ({
                      id: i.key,
                      title: i.title,
                      removeable: true,
                      collapsed: i.collapsed,
                      children: [
                        ...formState.ecomStoreMenuItems
                          .filter(
                            (j) => j.fkEcomStoreMenuItem === i.key && !j.deleted
                          )
                          .sort((a, b) => a.sort - b.sort)
                          .map((j) => ({
                            id: j.key,
                            title: j.title,
                            removeable: true,
                            collapsed: j.collapsed,
                            children: [
                              ...formState.ecomStoreMenuItems
                                .filter(
                                  (k) =>
                                    k.fkEcomStoreMenuItem === j.key &&
                                    !k.deleted
                                )
                                .sort((a, b) => a.sort - b.sort)
                                .map((k) => ({
                                  id: k.key,
                                  title: k.title,
                                  removeable: true,
                                  collapsed: k.collapsed,
                                  children: []
                                }))
                            ]
                          }))
                      ]
                    }))}
                  onEditItem={(itemKey) =>
                    onOpenModal(VIEW_ACTIONS_ENUM.EDIT, itemKey)
                  }
                  setItems={onTreeSortChange}
                />
              </AppStack>
            )}
            <Button
              color="dark"
              disabled={isSubmitting}
              justify="start"
              leftSection={<Plus color="dodgerblue" size={18} />}
              onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EDIT, null)}
              radius={0}
              size="lg"
              style={{ color: 'dodgerblue', fontSize: 14 }}
              variant="light"
            >
              Add menu item
            </Button>
          </AppStack>
        </AppCard>

        <AppFlexbox
          p={{ base: 16, sm: '16px 0px' }}
          style={{
            justifyContent: 'flex-end',
            flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
          }}
        >
          {ecomStoreMenu && !duplicate && (
            <Button
              color="red"
              disabled={isSubmitting}
              onClick={() => {
                onOpenModal(VIEW_ACTIONS_ENUM.DELETE);
              }}
              radius="md"
              size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
              type="button"
              variant="filled"
            >
              Delete menu
            </Button>
          )}

          <Button
            color="dark"
            disabled={!hasUnsavedChanges}
            id="save-button"
            loading={isSubmitting}
            radius="md"
            size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
            type="submit"
            variant="filled"
          >
            Save
          </Button>
        </AppFlexbox>
      </AppStack>
      <WebstoreMenuItemDrawer
        ecomStoreMenuItem={selectedItem}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EDIT
        }
        onClose={onCloseModal}
        onConfirm={(menuItem) => {
          if (modalState.item) {
            setFormState({
              ...formState,
              ecomStoreMenuItems: formState.ecomStoreMenuItems.map((i) =>
                i.key === modalState.item ? { ...i, ...menuItem } : i
              )
            });
          }
          else {
            setFormState({
              ...formState,
              ecomStoreMenuItems: [
                ...formState.ecomStoreMenuItems,
                {
                  ...menuItem,
                  sort:
                    formState.ecomStoreMenuItems.filter(
                      (i) =>
                        i.fkEcomStoreMenuItem === menuItem.fkEcomStoreMenuItem
                    ).length + 1,
                  key: new Date().getTime()
                }
              ]
            });
          }
          onCloseModal();
        }}
      />
      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete menu"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to delete the menu{' '}
              <b>{ecomStoreMenu?.title}</b>?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteStoreMenu(
            ecomStoreMenu.pkEcomStoreMenu,
            () => {
              navigate('/merchant/webstore/menus');
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${ecomStoreMenu?.title}?`}
      />
      {ConfirmDiscardModal}
    </>
  ) : (
    <AppStack
      p={{ base: 0, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 16px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Skeleton height={30} width={120} />
        <Skeleton height={28} width={90} />
      </AppFlexbox>

      <AppCard
        radius={isTabletOrSmaller ? 0 : 'md'}
        shadow="xs"
        style={{ padding: 16 }}
        withBorder
      >
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={18} width={38} />
          <Skeleton height={36} width="100%" />
        </AppStack>
      </AppCard>

      <AppCard
        radius={isTabletOrSmaller ? 0 : 'md'}
        shadow="xs"
        style={{ padding: 0 }}
        withBorder
      >
        <AppStack style={{ gap: 0 }}>
          <AppFlexbox
            style={{
              padding: '16px 16px 0px 16px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={25} width={90} />
          </AppFlexbox>
          <AppStack style={{ paddingTop: 16, gap: 0 }}>
            {Array.from(Array(3)).map((x, i) => (
              <AppFlexbox
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                style={{
                  padding: 20,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderTop: '1px solid #dee2e6'
                }}
              >
                <AppFlexbox style={{ gap: 10, flex: 1, paddingLeft: 10 }}>
                  <Skeleton height={18} width={18} />
                  <Skeleton height={18} width={125} />
                </AppFlexbox>

                <Skeleton height={26} width={100} />
              </AppFlexbox>
            ))}
          </AppStack>
          <Button
            color="dark"
            justify="start"
            radius={0}
            size="lg"
            variant="light"
          >
            <Skeleton height={18} width={140} />
          </Button>
        </AppStack>
      </AppCard>
    </AppStack>
  );
};

WebstoreMenuEditView.propTypes = {
  duplicate: PropTypes.bool,
  isViewLoading: PropTypes.bool
};

export default WebstoreMenuEditView;
