/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Anchor } from '@mantine/core';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import { HEADING_SIZE_STYLE_ENUM } from '../../sections/featuredCollection/wsFeaturedCollectionConfig';
import { ECOM_WEBSTORE_PAGE_TYPE_ENUM } from '../../../../../config/constants';
import { generateColorVariants } from '../../../../../helpers/styles';
import AppFlexbox from '../../../../common/AppFlexbox';

const defaultHeaderMenu = {
  pkEcomStoreMenu: 0,
  title: 'Default',
  ecomStoreMenuItems: [
    {
      pkEcomStoreMenuItem: 1,
      title: 'Products',
      customUrl: '',
      sort: 1,
      ecomResourceId: null,
      fkEcomStoreMenuItem: null,
      fkEcomStorePageType: ECOM_WEBSTORE_PAGE_TYPE_ENUM.PRODUCT
    }
  ]
};

const WsMenuBlock = ({ config, colorScheme, ecomStoreMenus }) => {
  const selectedMenu =
    ecomStoreMenus.find(
      (menu) =>
        menu.pkEcomStoreMenu.toString() === config.ecomStoreMenu?.toString()
    ) ??
    ecomStoreMenus.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    )[0] ??
    defaultHeaderMenu;
  const textColorVariants = generateColorVariants(colorScheme.textColor, 100);

  return (
    <AppFlexbox>
      <AppStack>
        {config.heading && (
          <AppText
            style={{
              fontWeight: 500,
              fontSize:
                config.headingSize === HEADING_SIZE_STYLE_ENUM.HUGE
                  ? 22
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE
                  ? 20
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.LARGE
                  ? 18
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.MEDIUM
                  ? 16
                  : 14,
              wordBreak: 'break-word',
              overflowWrap: 'break-word'
            }}
          >
            {config.heading}
          </AppText>
        )}
        <AppStack style={{ gap: 8 }}>
          {selectedMenu &&
            selectedMenu.ecomStoreMenuItems.map((i) => (
              <Anchor
                key={i.pkEcomStoreMenuItem}
                style={{ fontSize: 14, color: textColorVariants.lighter }}
              >
                {i.title}
              </Anchor>
            ))}
        </AppStack>
      </AppStack>
    </AppFlexbox>
  );
};

WsMenuBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  ecomStoreMenus: PropTypes.array
};

export default WsMenuBlock;
