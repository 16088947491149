import React from 'react';
import { PropTypes } from 'prop-types';
import { Badge, Image, NumberFormatter } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Ticket } from 'tabler-icons-react';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import ProductLineItemAddonsDisplay from '../../../products/ProductLineItemAddonsDisplay';

const WsAccountOrderProductListItem = ({
  lineItem,
  mediaQueries,
  baseStoreUrl,
  onNavigate
}) => {
  const { isTabletOrSmaller, getResponsiveStyle: rs } = mediaQueries;
  const currency = lineItem.currency?.toUpperCase();
  const imageSrc = lineItem.previewImage;
  const url = `${baseStoreUrl}/products/${lineItem.urlHandle}`;

  return (
    <AppFlexbox
      key={lineItem.entityId}
      style={{
        flex: 1,
        flexWrap: 'nowrap',
        flexDirection: rs(['column', 'column', 'column', 'row']),
        gap: 0
      }}
    >
      <AppFlexbox style={{ flexWrap: 'nowrap', alignSelf: 'stretch', flex: 2 }}>
        <AppStack
          component={Link}
          onClick={onNavigate}
          style={{
            height: isTabletOrSmaller ? 70 : 100,
            width: isTabletOrSmaller ? 70 : 100
          }}
          to={url}
        >
          <Image fit="contain" h="100%" src={imageSrc} w="100%" />
        </AppStack>
        <AppStack
          component={url ? Link : null}
          onClick={onNavigate}
          style={{
            flex: 1,
            gap: 0,
            textDecoration: 'none',
            color: '#000'
          }}
          to={url}
        >
          <AppText size="lg" weight={700}>
            {lineItem.productName}
          </AppText>

          <ProductLineItemAddonsDisplay addOns={lineItem.addOns} />

          <AppText style={{ fontSize: 12, color: '#666' }}>
            Qty: {lineItem.quantity}
          </AppText>
        </AppStack>
      </AppFlexbox>

      <AppStack
        style={{
          flex: 1,
          alignSelf: 'start',
          placeItems: 'end',
          marginLeft: 'auto',
          gap: rs([0, 20]),
          flexDirection: rs([
            'row-reverse',
            'row-reverse',
            'row-reverse',
            'column'
          ]),
          justifyContent: rs([
            'space-between',
            'space-between',
            'space-between',
            'inital'
          ]),
          width: rs(['100%', '100%', '100%', 'unset'])
        }}
      >
        <AppStack style={{ gap: 0, alignItems: 'end' }}>
          <AppFlexbox style={{ gap: 10, flexWrap: 'nowrap' }}>
            <AppText
              size="lg"
              style={{
                textDecoration:
                  lineItem.financialAssistance || lineItem.coupons.length > 0
                    ? 'line-through'
                    : 'normal'
              }}
              weight={700}
            >
              <NumberFormatter
                decimalScale={2}
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                value={lineItem.subtotalInCents / 100}
              />{' '}
              {currency}
            </AppText>
          </AppFlexbox>
          {lineItem.coupons.length > 0 && (
            <AppStack style={{ gap: 5 }}>
              {lineItem.coupons.map((c) => (
                <AppFlexbox
                  key={`${c.couponName}-${c.unitAmountInCents}`}
                  style={{ gap: 5, flexWrap: 'nowrap' }}
                >
                  <Badge
                    color="gray"
                    leftSection={<Ticket size={16} style={{ paddingTop: 5 }} />}
                    variant="outline"
                  >
                    {c.couponName}
                  </Badge>
                  <AppText size="md" weight={500}>
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={
                        c.unitAmountInCents
                          ? (c.unitAmountInCents / 100) * -1
                          : ((lineItem.subtotalInCents *
                              (c.percentageAmount / 100)) /
                              100) *
                            -1
                      }
                    />{' '}
                    {currency}
                  </AppText>
                </AppFlexbox>
              ))}
            </AppStack>
          )}

          {lineItem.taxInCents > 0 && (
            <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
              <AppText size="md" weight={500}>
                +{' '}
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={lineItem.taxInCents / 100}
                />{' '}
                @{' '}
                <NumberFormatter
                  displayType="text"
                  fixedDecimalScale
                  suffix="%"
                  thousandSeparator
                  value={lineItem.taxRate}
                />{' '}
                TAX
              </AppText>
            </AppFlexbox>
          )}

          {lineItem.customerPaysServiceFees && lineItem.serviceFeeInCents > 0 && (
            <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
              <AppText size="md" weight={500}>
                +{' '}
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={lineItem.serviceFeeInCents / 100}
                />{' '}
                Service fee
              </AppText>
            </AppFlexbox>
          )}

          {lineItem.financialAssistance && (
            <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
              <AppText size="md" weight={500}>
                Jumpstart{' '}
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={0.0}
                />{' '}
                {currency}
              </AppText>
            </AppFlexbox>
          )}
        </AppStack>
      </AppStack>
    </AppFlexbox>
  );
};

WsAccountOrderProductListItem.propTypes = {
  baseStoreUrl: PropTypes.string.isRequired,
  lineItem: PropTypes.object.isRequired,
  mediaQueries: PropTypes.object.isRequired,
  onNavigate: PropTypes.func
};

export default WsAccountOrderProductListItem;
