import React, { useContext, useState } from 'react';
import {
  Burger,
  Button,
  CloseButton,
  Divider,
  Drawer as MantineDrawer
} from '@mantine/core';
import PropTypes from 'prop-types';
import { Help } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import DrawerLinkSection from './DrawerLinkSection';
import SUPPORT_LINKS from './supportLinks';
import AppFlexbox from '../../common/AppFlexbox';
import AppImage from '../../common/AppImage';
import AppText from '../../common/AppText';
import sportsHeadzLogo from '../../../images/sportsheadz-logo.png';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppStack from '../../common/AppStack';

const Drawer = ({
  publicLinks,
  profileLinks,
  adminLinks,
  extraSections,
  lightMode,
  loading
}) => {
  const { state: authState } = useContext(AuthContext);
  const [openState, setOpenState] = useState(false);

  return (
    <>
      {loading ? (
        <Burger color="#FFF" style={{ opacity: 0.2 }} />
      ) : (
        <Burger
          color="#FFF"
          onClick={() => setOpenState(!openState)}
          opened={openState}
          title={openState ? 'Close navigation' : 'Open navigation'}
        />
      )}

      <MantineDrawer
        onClose={() => setOpenState(!openState)}
        opened={openState}
        position="left"
        size={240}
        styles={{
          body: {
            padding: 0,
            height: '100%',
            backgroundColor: lightMode ? ' rgba(56, 56, 56, 0.1)' : '#000'
          }
        }}
        withCloseButton={false}
      >
        <AppFlexbox
          style={{ flex: 1, gap: 0, flexDirection: 'column', height: '100%' }}
        >
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              textDecoration: 'none',
              backgroundColor: lightMode ? ' rgba(56, 56, 56, 0.1)' : '#000',
              color: lightMode ? '#000' : '#FFF',
              gap: 5,
              padding: 8,
              height: 60
            }}
          >
            <AppFlexbox
              style={{
                alignItems: 'center',
                gap: 8
              }}
            >
              <AppImage
                alt="SportsHeadz Main Logo"
                fit="contain"
                height={30}
                src={sportsHeadzLogo}
                style={{ minWidth: 'unset', maxWidth: 125 }}
                width="100%"
              />
              <AppText style={{ fontSize: 16, fontWeight: 650 }}>
                SportsHeadz
              </AppText>
            </AppFlexbox>

            <CloseButton
              onClick={() => setOpenState(false)}
              style={{ color: lightMode ? '#000' : '#FFF' }}
            />
          </AppFlexbox>
          <AppFlexbox
            style={{
              flex: 1,
              flexDirection: 'column',
              gap: 0,
              overflow: 'auto'
            }}
          >
            <Divider />
            {publicLinks && publicLinks.length > 0 && (
              <>
                <AppFlexbox
                  style={{
                    padding: 8,
                    paddingRight: 0,
                    flexDirection: 'column',
                    gap: 0
                  }}
                >
                  <DrawerLinkSection
                    lightMode={lightMode}
                    links={publicLinks}
                    onClick={() => setOpenState(false)}
                  />
                </AppFlexbox>
                <Divider />
              </>
            )}

            {profileLinks && profileLinks.length > 0 && (
              <>
                <AppFlexbox
                  style={{
                    padding: 8,
                    paddingRight: 0,
                    flexDirection: 'column',
                    gap: 0
                  }}
                >
                  <DrawerLinkSection
                    lightMode={lightMode}
                    links={profileLinks}
                    onClick={() => setOpenState(false)}
                  />
                </AppFlexbox>
                <Divider />
              </>
            )}

            {adminLinks && adminLinks.length > 0 && (
              <>
                <AppFlexbox
                  style={{
                    padding: 8,
                    paddingRight: 0,
                    flexDirection: 'column',
                    gap: 0,
                    flex: 1
                  }}
                >
                  <DrawerLinkSection
                    lightMode={lightMode}
                    links={adminLinks}
                    onClick={() => setOpenState(false)}
                  />

                  {extraSections &&
                    extraSections.length > 0 &&
                    extraSections.map((extraSection) => (
                      <AppStack
                        key={extraSection.title}
                        style={{ marginTop: 40, gap: 8 }}
                      >
                        <AppText style={{ fontSize: 16, fontWeight: 750 }}>
                          {extraSection.title}
                        </AppText>
                        <AppStack
                          style={{
                            flex: 1,
                            gap: 0
                          }}
                        >
                          <DrawerLinkSection
                            hideTitleLink
                            lightMode={lightMode}
                            links={extraSection.links}
                            onClick={() => setOpenState(false)}
                          />
                        </AppStack>
                      </AppStack>
                    ))}
                </AppFlexbox>
                <Divider />
                <AppFlexbox
                  style={{
                    padding: 8,
                    paddingRight: 0,
                    flexDirection: 'column',
                    gap: 5
                  }}
                >
                  <DrawerLinkSection
                    lightMode={lightMode}
                    links={[
                      {
                        to: SUPPORT_LINKS.index.href,
                        title: SUPPORT_LINKS.index.label,
                        icon: Help,
                        target: '_blank',
                        isSelected: () => false
                      }
                    ]}
                    onClick={() => setOpenState(false)}
                  />
                </AppFlexbox>
              </>
            )}

            {!loading && !authState.isAuthenticated && (
              <AppStack style={{ gap: 20, padding: '20px 10px' }}>
                <Button
                  color="#FFF"
                  component={Link}
                  onClick={() => setOpenState(false)}
                  radius="xl"
                  size="lg"
                  to="/auth/signin"
                  variant="outline"
                >
                  Sign In
                </Button>
                <Button
                  color="#FFF"
                  component={Link}
                  onClick={() => setOpenState(false)}
                  radius="xl"
                  size="lg"
                  style={{ color: '#000' }}
                  to="/auth/register"
                  variant="filled"
                >
                  Register
                </Button>
              </AppStack>
            )}
          </AppFlexbox>
        </AppFlexbox>
      </MantineDrawer>
    </>
  );
};

Drawer.propTypes = {
  adminLinks: PropTypes.array,
  extraSections: PropTypes.array,
  lightMode: PropTypes.bool,
  loading: PropTypes.bool,
  profileInfo: PropTypes.object,
  profileLinks: PropTypes.array,
  publicLinks: PropTypes.array,
  textColor: PropTypes.string
};

export default Drawer;
