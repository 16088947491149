/* eslint-disable import/no-cycle */
import { LayoutNavbar } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsHeader from './WsHeader';

const DESKTOP_LOGO_POSITION_ENUM = {
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  MIDDLE_LEFT: 'middle-left',
  MIDDLE_CENTER: 'middle-center'
};

const DESKTOP_MENU_TYPE_ENUM = {
  MEGA_MENU: 'mega_menu',
  DRAWER: 'drawer'
};

const HEADER_STICKY_TYPE_ENUM = {
  NONE: 'none',
  ON_SCROLL_UP: 'on-scroll-up',
  ALWAYS: 'always'
};

const HEADER_MOBILE_LOGO_POSITION_ENUM = {
  CENTER: 'center',
  LEFT: 'left'
};

const configNameValues = {
  desktopLogoPosition: 'desktopLogoPosition',
  flipLogoOnDesktop: 'flipLogoOnDesktop',
  ecomStoreMenu: 'ecomStoreMenu',
  desktopMenuType: 'desktopMenuType',
  stickyHeader: 'stickyHeader',
  colorScheme: 'colorScheme',
  menuColorScheme: 'menuColorScheme',
  mobileLogoPosition: 'mobileLogoPosition'
};

const configDefaultValues = {
  [configNameValues.desktopLogoPosition]:
    DESKTOP_LOGO_POSITION_ENUM.MIDDLE_LEFT,
  [configNameValues.flipLogoOnDesktop]: 'false',
  [configNameValues.ecomStoreMenu]: null,
  [configNameValues.desktopMenuType]: DESKTOP_MENU_TYPE_ENUM.MEGA_MENU,
  [configNameValues.stickyHeader]: HEADER_STICKY_TYPE_ENUM.ALWAYS,
  [configNameValues.colorScheme]: '1',
  [configNameValues.menuColorScheme]: '1',
  [configNameValues.mobileLogoPosition]: HEADER_MOBILE_LOGO_POSITION_ENUM.CENTER
};

const configOptions = {
  [configNameValues.desktopLogoPosition]: {
    label: 'Logo position',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      { value: DESKTOP_LOGO_POSITION_ENUM.TOP_LEFT, label: 'Top Left' },
      { value: DESKTOP_LOGO_POSITION_ENUM.TOP_CENTER, label: 'Top Center' },
      { value: DESKTOP_LOGO_POSITION_ENUM.MIDDLE_LEFT, label: 'Middle left' },
      {
        value: DESKTOP_LOGO_POSITION_ENUM.MIDDLE_CENTER,
        label: 'Middle Center'
      }
    ]
  },
  [configNameValues.flipLogoOnDesktop]: {
    label: 'Flip logo and menu',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.ecomStoreMenu]: {
    label: 'Menu',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMenu
  },
  [configNameValues.desktopMenuType]: {
    label: 'Desktop menu type',
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    options: [
      { value: DESKTOP_MENU_TYPE_ENUM.MEGA_MENU, label: 'Mega menu' },
      { value: DESKTOP_MENU_TYPE_ENUM.DRAWER, label: 'Drawer' }
    ]
  },
  [configNameValues.stickyHeader]: {
    label: 'Sticky header',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      { value: HEADER_STICKY_TYPE_ENUM.NONE, label: 'None' },
      // { value: HEADER_STICKY_TYPE_ENUM.ON_SCROLL_UP, label: 'On scroll up' },
      { value: HEADER_STICKY_TYPE_ENUM.ALWAYS, label: 'Always' }
    ]
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.menuColorScheme]: {
    label: 'Menu color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.mobileLogoPosition]: {
    label: 'Mobile logo position',
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    options: [
      { value: HEADER_MOBILE_LOGO_POSITION_ENUM.CENTER, label: 'Center' },
      { value: HEADER_MOBILE_LOGO_POSITION_ENUM.LEFT, label: 'Left' }
    ]
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.HEADER,
  title: 'Header',
  icon: LayoutNavbar,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.HEADER],
  configOptions,
  Component: WsHeader,
  isPermanent: true,
  maxItemCount: null,
  blocks: [],
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  DESKTOP_LOGO_POSITION_ENUM,
  DESKTOP_MENU_TYPE_ENUM,
  HEADER_STICKY_TYPE_ENUM,
  HEADER_MOBILE_LOGO_POSITION_ENUM
};
