import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM
} from '../../../../../config/constants';
import WsCollectionBlock from './WsCollectionBlock';

const configNameValues = { ecomStoreCollection: 'ecomStoreCollection' };

const configDefaultValues = { ecomStoreCollection: '' };

const configOptions = {
  [configNameValues.ecomStoreCollection]: {
    label: 'Collection',
    type: 'entity',
    allCollectionDisabled: true,
    entityType: ECOM_ENTITY_TYPES.ecomStoreCollection
  }
};

const config = {
  title: 'Collection',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.COLLECTION,
  icon: BoxMargin,
  Component: WsCollectionBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text,
  onFetchDataConfig: (blockConfigValues) => ({
    type: 'ecomStoreCollections',
    entityId: blockConfigValues.ecomStoreCollection,
    filter: {}
  })
};

export { config, configOptions, configNameValues, configDefaultValues };
