import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import VendorCollectionsTable from './VendorCollectionsTable';
import VendorCollectionEditView from './VendorCollectionEditView';
import { triggerNotification } from '../../../helpers/notification';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';

const VendorCollectionsView = () => {
  const fetchedPk = useRef(null);
  const { state: authState } = useContext(AuthContext);
  const { fetchVendorProductOptions } = useContext(VendorContext);

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchVendorProductOptions(authState.pkEcomVendor, {}, null, (error) => {
        triggerNotification(error);
      });
      fetchedPk.current = authState.pkEcomVendor;
    }
  }, [authState.pkEcomVendor]);

  return (
    <Routes>
      <Route element={<VendorCollectionEditView />} path="/new" />
      <Route
        element={<VendorCollectionEditView />}
        path="/:pkEcomVendorCollection"
      />
      <Route
        element={<VendorCollectionEditView duplicate />}
        path="/:pkEcomVendorCollection/duplicate"
      />
      <Route element={<VendorCollectionsTable />} path="/" />
      <Route element={<Navigate replace to="/vendor/collections" />} path="*" />
    </Routes>
  );
};

export default VendorCollectionsView;
