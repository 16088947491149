/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../../../common/AppStack';
import WsBlock from '../../blocks/WsBlock';
import WsSectionContainer from '../WsSectionContainer';

const WsRichText = ({
  blocks,
  config,
  colorSchemes,
  themeConfig,
  mediaQueries,
  ecomStoreTheme,
  baseStoreUrl,
  onNavigate,
  ...rest
}) => {
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const limitContentWidth = config.limitContentWidth === 'true';
  const contentWidthLimit = limitContentWidth
    ? config.maxContentWidth * 1 ?? 'unset'
    : 'unset';

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack
        style={{
          flex: 1,
          alignItems: config.desktopContentPosition,
          justifyContent: config.desktopContentPosition
        }}
      >
        <AppStack
          style={{
            alignItems: config.desktopContentAlignment,
            justifyContent: config.desktopContentAlignment,
            textAlign: config.desktopContentAlignment,
            gap: `${config.contentSpacing ?? 16}px`,
            maxWidth: contentWidthLimit,
            ...(config.fontFamily ? { fontFamily: config.fontFamily } : {})
          }}
        >
          {blocks.map((b) => (
            <WsBlock
              key={b.key}
              baseStoreUrl={baseStoreUrl}
              colorScheme={colorScheme}
              ecomStoreTheme={ecomStoreTheme}
              onNavigate={onNavigate}
              {...b}
            />
          ))}
        </AppStack>
      </AppStack>
    </WsSectionContainer>
  );
};

WsRichText.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreCollections: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsRichText;
