import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import {
  fileToBase64String,
  uploadFileToStorage
} from '../../../helpers/awsHelper';
import { triggerNotification } from '../../../helpers/notification';

const ChangeAvatarForm = ({ onClose }) => {
  const avatarInputRef = useRef(null);
  const { state, updateAvatar } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    file: null,
    base64String: null,
    avatar: '',
    isFileLoading: false,
    isLoading: false
  });
  const { user } = state.userData;

  useEffect(() => {
    if (user) {
      setFormState({
        file: null,
        avatar: user.avatar,
        isFileLoading: false,
        isLoading: false
      });
    }
  }, [user]);

  return (
    <FormSection
      isLoading={formState.isLoading}
      isSubmitDisabled={formState.isFileLoading || !formState.file}
      onCancel={onClose}
      onSubmit={() => {
        setFormState({
          ...formState,
          isLoading: true
        });

        uploadFileToStorage(
          `user-${user.pkUser}-avatar`,
          formState.file,
          (url) =>
            updateAvatar(
              { avatar: url },
              () => {
                triggerNotification(
                  'Profile picture updated!',
                  'Success',
                  'green'
                );
                onClose();
              },
              (e) => {
                triggerNotification(e);
                setFormState({
                  ...formState,
                  isLoading: false
                });
              }
            ),
          () => {
            triggerNotification();
            setFormState({ ...formState, isFileLoading: false });
          }
        );
      }}
      submitTitle="Save Changes"
    >
      <AppStack style={{ gap: 20 }}>
        <AppFlexbox style={{ justifyContent: 'center' }}>
          <Avatar size={180} src={formState.base64String || formState.avatar} />
        </AppFlexbox>
        <AppFlexbox style={{ justifyContent: 'center' }}>
          <Button
            color="dark"
            disabled={formState.isLoading}
            justify="start"
            leftSection={<Plus />}
            loading={formState.isFileLoading}
            onClick={() => avatarInputRef.current.click()}
            variant="outline"
          >
            Upload new photo
          </Button>
        </AppFlexbox>

        <input
          ref={avatarInputRef}
          accept="image/png, image/jpeg"
          data-testid="fileInput"
          hidden
          onChange={(e) => {
            const file = e.target.files[0];
            const fileSize = file.size / 1024 / 1024;
            if (fileSize > 50) {
              triggerNotification('File exceeds maximum size of 50MB');
            }
            else {
              setFormState({ ...formState, isFileLoading: true });
              fileToBase64String(file, (base64String) => {
                setFormState({ ...formState, file, base64String });
              });
            }
          }}
          type="file"
        />
      </AppStack>
    </FormSection>
  );
};

ChangeAvatarForm.propTypes = { onClose: PropTypes.func };

export default ChangeAvatarForm;
