import React, { useEffect, useRef, useState } from 'react';
import { Anchor, Button, Divider, Image, Transition } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../components/common/AppStack';
import bannerImage from '../images/sportsheadz-clothing-banner.png';
import sellWithUs from '../images/sportsheadz-sell-with-us.png';
import halfDotsLeft from '../images/sportsheadz-half-dots-left.png';
import halfDotsRight from '../images/sportsheadz-half-dots-right.png';
import sellWithUsHanging from '../images/sportsheadz-sell-with-us-hanging.png';
import krispyKremeLogo from '../images/krispy-kreme-logo.png';
import sportsheadzShopLogo from '../images/sportsheadz-shop.png';
import AppFlexbox from '../components/common/AppFlexbox';
import { useMediaQueryIndex, useOnScreen } from '../helpers/hooks';
import AppText from '../components/common/AppText';
import Footer from '../components/content/navigation/Footer';
import halfoneBgImage from '../images/sportsheadz-halftone-bg.png';
import * as classes from '../styles/nestedStyles.module.css';
import HomeInfoCard from '../components/common/HomeInfoCard';

const VENDOR_PARTNERS = [
  {
    label: 'Krispy Kreme',
    image: krispyKremeLogo,
    src: 'https://krispykreme.ca/'
  }
];

const HomeView = () => {
  const vendorPartnersListRef = useRef(null);
  const [showPartnersList, setShowPartnersList] = useState(false);
  const { getResponsiveStyle: rs } = useMediaQueryIndex();
  const isPartnersListShowing = useOnScreen(vendorPartnersListRef);

  useEffect(() => {
    if (isPartnersListShowing) {
      setShowPartnersList(true);
    }
  }, [isPartnersListShowing]);

  return (
    <AppStack
      style={{
        flex: 1,
        gap: 0
      }}
    >
      <AppStack style={{ flex: 1, gap: 0 }}>
        <AppFlexbox
          style={{
            width: '100%',
            minHeight: rs([120, 190, 230, 380]),
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: '#EFB514'
          }}
        >
          <AppStack
            style={{
              position: 'absolute',
              top: -10,
              left: 0,
              width: '100%',
              minHeight: 450,
              opacity: 0.498,
              backgroundImage: `url(${bannerImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: rs([
                '100% 140px',
                '100% 205px',
                '100% 255px',
                '100% 420px'
              ])
            }}
          />
          <AppStack
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AppFlexbox
              style={{
                height: '100%',
                width: '100%',
                maxWidth: 1200
              }}
            >
              <AppStack
                style={{
                  flex: 1,
                  height: '100%',
                  width: '100%',
                  position: 'relative'
                }}
              >
                <AppStack
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '100%'
                  }}
                >
                  <Image height="100%" src={sportsheadzShopLogo} width="100%" />
                </AppStack>
              </AppStack>
              <AppStack
                style={{
                  flex: 1,
                  height: '100%',
                  width: '100%',
                  position: 'relative'
                }}
              >
                <AppStack
                  style={{
                    position: 'absolute',
                    height: rs([150, 240, 275, 385]),
                    top: rs([-50, -95, -95, -95]),
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                >
                  <Image height="100%" src={sellWithUsHanging} width="100%" />
                </AppStack>
              </AppStack>
            </AppFlexbox>
          </AppStack>
        </AppFlexbox>
        <AppStack
          style={{
            padding: 20,
            gap: 30,
            backgroundColor: '#000',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <AppFlexbox
            style={{
              flex: 1,
              width: '100%',
              maxWidth: 1200,
              gap: rs([20, 20, 40]),
              justifyContent: rs(['center', 'center', 'center', 'start']),
              alignItems: 'center',
              zIndex: 1
            }}
          >
            <Anchor
              c="#FFF"
              component={Link}
              style={{ fontSize: rs([18, 18, 20]), fontWeight: 650 }}
              to="/merchant/switch"
              underline="always"
            >
              Create Your Shop
            </Anchor>

            <Anchor
              c="#FFF"
              href="https://www.sportsheadz.com/contact"
              style={{ fontSize: rs([18, 18, 20]), fontWeight: 650 }}
              target="_blank"
              underline="always"
            >
              Become a Vendor
            </Anchor>

            <Anchor
              c="#FFF"
              href="https://www.sportsheadz.com/contact"
              style={{ fontSize: 20, fontWeight: 650 }}
              target="_blank"
              underline="always"
              visibleFrom="xsm"
            >
              Support
            </Anchor>
          </AppFlexbox>
          <AppStack
            style={{
              position: 'absolute',
              top: rs([0, 0, 0, 0]),
              left: rs(['-35%', '-80%', '-80%', '-80%', '-40%', '-25%']),
              width: rs(['50%', '50%', '50%', '50%'])
            }}
            visibleFrom="lg"
          >
            <Image height="100%" src={halfDotsLeft} width="100%" />
          </AppStack>

          <AppStack
            hiddenFrom="lg"
            style={{
              position: 'absolute',
              top: rs(['-75%', '-85%', '-85%', '-85%']),
              left: rs(['-35%', '-30%', '-35%', '-35%']),
              width: rs(['50%', '50%', '50%', '50%'])
            }}
            visibleFrom="xsm"
          >
            <Image
              height="100%"
              src={halfDotsRight}
              style={{ transform: 'rotate(180deg)' }}
              width="100%"
            />
          </AppStack>

          <AppStack
            style={{
              position: 'absolute',
              top: rs(['-75%', '-85%', '-85%', '-85%']),
              left: rs(['85%', '80%', '80%', '60%', '40%']),
              width: rs(['50%', '50%', '50%', '50%'])
            }}
            visibleFrom="xsm"
          >
            <Image height="100%" src={halfDotsRight} width="100%" />
          </AppStack>
        </AppStack>

        <AppStack
          style={{
            padding: rs([
              '0px 20px 20px 20px',
              '0px 20px 20px 20px',
              '0px 40px 40px 40px'
            ]),
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <AppStack
            style={{
              marginBottom: 20,
              alignItems: 'start',
              width: '100%',
              maxWidth: 1200,
              position: 'relative',
              flex: 1,
              gap: rs([30, 30, 50])
            }}
          >
            <AppFlexbox style={{ flex: 1, gap: 60 }}>
              <AppStack
                style={{
                  flex: 1,
                  gap: 0,
                  paddingTop: rs([10, 10, 20])
                }}
              >
                <AppText
                  style={{
                    fontSize: rs([32, 34, 44, 44, 54]),
                    color: '#333',
                    fontWeight: 500,
                    whiteSpace: 'nowrap'
                  }}
                >
                  <b>SportsHeadz</b> Shop
                </AppText>
                <AppText
                  style={{
                    fontSize: rs([18, 18, 22, 22, 24]),
                    color: '#333',
                    fontWeight: 500,
                    wordBreak: 'keep-all'
                  }}
                >
                  Is your one-stop shop for your fundraising and
                  e&#8209;commerce needs. We are innovating on one of the most
                  antiquated aspects of youth sports; fund-raising and product
                  sales, by creating an online end&#8209;to&#8209;end solution
                  that matches local and globally vetted vendors with teams and
                  associations looking to create fundraising opportunities.
                </AppText>
              </AppStack>
              <Divider
                orientation="vertical"
                size={3}
                style={{ marginBottom: 30 }}
                visibleFrom="md"
              />
              <AppStack
                style={{
                  flex: 1,
                  position: 'relative',
                  height: '100%'
                }}
                visibleFrom="md"
              >
                <HomeInfoCard />
              </AppStack>
            </AppFlexbox>
            <Divider hiddenFrom="md" size={3} style={{ width: '100%' }} />
            <AppFlexbox style={{ flex: 1 }}>
              <AppStack
                style={{
                  flex: 1,
                  gap: rs([30, 30, 40]),
                  alignItems: 'start'
                }}
              >
                <AppText
                  style={{
                    fontSize: rs([18, 18, 22, 22, 24]),
                    color: '#333'
                  }}
                >
                  Our proprietary central vendor database powers the entire
                  platform. Major national suppliers are already on board with
                  products ready for your digital storefront on day one. Know a
                  trusted local supplier? They can add their inventory with just
                  a few clicks.
                </AppText>

                <AppText
                  style={{
                    fontSize: rs([18, 18, 22, 22, 24]),
                    color: '#333'
                  }}
                >
                  Sell apparel, equipment, snacks and more. The sky's the limit.
                  All funds land directly in your account.
                </AppText>

                <Button
                  color="#000"
                  component="a"
                  href="https://www.sportsheadz.com/contact"
                  radius="xl"
                  size="compact-xl"
                  style={{ padding: '5px 40px', fontSize: 18 }}
                  target="_blank"
                  variant="filled"
                >
                  Learn More
                </Button>
              </AppStack>
            </AppFlexbox>
          </AppStack>

          <AppStack hiddenFrom="md" style={{ marginTop: 80 }}>
            <HomeInfoCard style={{ position: 'relative' }} />
          </AppStack>
        </AppStack>

        <AppFlexbox
          style={{
            justifyContent: 'center',
            backgroundColor: '#000',
            padding: 5,
            gap: 0
          }}
        >
          <AppFlexbox style={{ flex: 1 }} visibleFrom="sm" />
          <AppFlexbox style={{ padding: '10px 40px' }}>
            <AppText
              style={{
                fontSize: rs([36, 36, 42]),
                color: '#FFF',
                fontWeight: 500,
                wordBreak: 'break-word',
                textAlign: 'center'
              }}
            >
              OUR VENDOR PARTNERS
            </AppText>
          </AppFlexbox>
          <AppFlexbox
            style={{
              flex: 1,
              position: 'relative',
              overflow: 'visible'
            }}
            visibleFrom="sm"
          >
            <AppStack
              style={{ position: 'absolute', left: 0, top: -32.5, height: 150 }}
            >
              <Image height="100%" src={sellWithUs} width="100%" />
            </AppStack>
          </AppFlexbox>
        </AppFlexbox>
        <AppStack
          style={{
            backgroundImage: `url(${halfoneBgImage})`,
            backgroundRepeat: 'repeat',
            padding: '50px 0px',
            gap: 30
          }}
        >
          <AppFlexbox
            ref={vendorPartnersListRef}
            style={{
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'start',
              alignSelf: 'stretch'
            }}
          >
            <AppFlexbox
              style={{
                flex: 1,
                maxWidth: '90%',
                alignSelf: 'center',
                justifyContent: 'center',
                gap: 20,
                flexWrap: 'wrap',
                position: 'relative'
              }}
            >
              {VENDOR_PARTNERS.map((p, index) => {
                const size = rs([100, 150, 200]);
                const margin = rs([25, 35, 50]);

                return (
                  <Transition
                    // eslint-disable-next-line react/no-array-index-key
                    key={p.label}
                    duration={500}
                    mounted={showPartnersList}
                    timingFunction="ease"
                    transition="fade"
                  >
                    {(styles) => (
                      <AppFlexbox
                        component="a"
                        href={p.src}
                        style={{
                          ...styles,
                          flex: 1,
                          transitionDelay: `${index * 0.1}s`,
                          flexDirection: 'column',
                          justifyContent: 'center',
                          minWidth: size,
                          minHeight: size,
                          maxWidth: size,
                          maxHeight: size,
                          marginLeft: margin,
                          marginRight: margin,
                          borderRadius: 200,
                          overflow: 'hidden'
                        }}
                        target="_blank"
                      >
                        <Image
                          className={classes['hover-clickable-image']}
                          fit="contain"
                          height="100%"
                          src={p.image}
                          width="100%"
                        />
                      </AppFlexbox>
                    )}
                  </Transition>
                );
              })}
            </AppFlexbox>
          </AppFlexbox>
          {showPartnersList && (
            <AppFlexbox style={{ justifyContent: 'center' }}>
              <Button
                color="#000"
                component="a"
                href="https://www.sportsheadz.com/contact"
                radius="xl"
                size="compact-xl"
                style={{ padding: '5px 40px', fontSize: 18 }}
                target="_blank"
                variant="filled"
              >
                Join the List!
              </Button>
            </AppFlexbox>
          )}
        </AppStack>
      </AppStack>

      <Footer />
    </AppStack>
  );
};

export default HomeView;
