import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Anchor, Skeleton } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const OrderMerchantInfoCard = ({ ecomStore, loading }) =>
  !loading && ecomStore ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
        <AppStack style={{ flex: 1, gap: 8 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 16, fontWeight: 500 }}>
              Merchant
            </AppText>
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox>
              <Anchor
                component={Link}
                style={{ fontSize: 13, color: 'dodgerblue' }}
                to={`/vendor/merchants/${ecomStore.pkEcomStore}/`}
              >
                {ecomStore.name}
              </Anchor>
            </AppFlexbox>
          </AppStack>
        </AppStack>

        <AppStack style={{ flex: 1, gap: 8 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Contact information
            </AppText>
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <AppText style={{ fontSize: 13 }}>{ecomStore.name}</AppText>
            {ecomStore.domain && (
              <AppFlexbox>
                <Anchor
                  href={ecomStore.domain}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  target="_blank"
                >
                  {ecomStore.domain}
                </Anchor>
              </AppFlexbox>
            )}
            {ecomStore.supportEmail && (
              <AppFlexbox>
                <Anchor
                  href={`mailto:${ecomStore.supportEmail}`}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  target="_blank"
                >
                  {ecomStore.supportEmail}
                </Anchor>
              </AppFlexbox>
            )}
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
        <AppStack style={{ flex: 1, gap: 12 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={22} width="35%" />
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <Skeleton height={18} width="40%" />
          </AppStack>
        </AppStack>

        <AppStack style={{ flex: 1, gap: 12 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={22} width="45%" />
          </AppFlexbox>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={18} width="40%" />
            <Skeleton height={18} width="35%" />
            <Skeleton height={18} width="50%" />
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  );

OrderMerchantInfoCard.propTypes = {
  ecomStore: PropTypes.object,
  loading: PropTypes.bool
};

export default OrderMerchantInfoCard;
