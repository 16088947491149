import {
  BrandStripe,
  Clock,
  CreditCard,
  Download,
  Eye,
  EyeCheck,
  Photo,
  Plus,
  Search,
  Shirt,
  Stack,
  TruckDelivery
} from 'tabler-icons-react';
import { formatUtcDate } from '../../../helpers/format';

const setupGuideConfig = {
  title: 'Setup your store',
  intro: 'Here is a guide to help you get started with your merchant account.',
  description:
    'Follow the steps below to to help you setup your store and start selling products.',
  isCompleted: (state) =>
    state.ecomStoreSetupSummary.value &&
    !state.ecomStoreSetupSummary.value?.ecomStore.sandboxEnabled,
  sections: [
    {
      value: 'products',
      title: 'Product setup',
      steps: [
        {
          value: 'import-product',
          title: 'Find your first product',
          description:
            'Discover products from our marketplace to add to your store.',
          icon: Shirt,
          primaryButton: {
            title: 'Find product',
            action: 'link',
            url: '/merchant/products/discover',
            icon: Search
          },
          secondaryButton: {
            title: 'Import',
            action: 'link',
            url: '/merchant/products?modal=import',
            icon: Download
          },
          required: true,
          isCompleted: (state) =>
            state.ecomStoreSetupSummary.value?.hasProducts ||
            state.ecomStoreProducts.value.filter(
              (p) =>
                p.fkEcomStore ===
                state.ecomStoreSetupSummary.value?.ecomStore.pkEcomStore
            ).length > 0
        },
        {
          value: 'create-collection',
          title: 'Create your first collection',
          description:
            'Organize your products into collections to display on your store.',
          subDescription: 'You can skip this step and complete it later.',
          icon: Stack,
          primaryButton: {
            title: 'Create collection',
            icon: Plus,
            action: 'link',
            url: '/merchant/collections/new'
          },
          secondaryButton: {
            title: 'Skip step',
            action: 'skip'
          },
          required: false,
          isCompleted: (state) =>
            state.ecomStoreSetupSummary.value?.hasCollections ||
            state.ecomStoreCollections.value.length > 0
        }
      ]
    },
    {
      value: 'settings',
      title: 'Store settings',
      steps: [
        {
          value: 'add-logo',
          title: 'Add your logo',
          description:
            'Update your store information to help vendors identify you. Start by adding a logo.',
          icon: Photo,
          primaryButton: {
            title: 'Add logo',
            action: 'link',
            url: '/merchant/settings?modal=logo',
            icon: Photo
          },
          required: true,
          isCompleted: (state) =>
            state.ecomStoreSetupSummary.value &&
            !!state.ecomStoreSetupSummary.value?.ecomStore.logoImageUrl
        },
        {
          value: 'setup-payment-provider',
          title: 'Setup payment provider',
          description:
            'Onboard with stripe to start receiving payments from your customers.',
          icon: CreditCard,
          primaryButton: {
            title: 'Onboard with Stripe',
            icon: BrandStripe,
            action: 'link',
            url: '/merchant/settings?modal=payment-provider'
          },
          secondaryButton: {
            title: 'Skip step',
            action: 'skip'
          },
          required: false,
          isCompleted: (state) =>
            state.ecomStoreSetupSummary.value &&
            !!state.ecomStoreSetupSummary.value?.ecomStore.ecomStoreWindow
        }
      ]
    },
    {
      value: 'launch',
      title: 'Launch your store',
      steps: [
        {
          value: 'setup-store-window',
          title: 'Setup a sales window',
          description:
            'Set the purchase period for your store by creating a sales window. Once the window closes, all sales are final, and vendor payments cannot be recovered. You can update the closing date anytime before the window closes.',
          icon: TruckDelivery,
          primaryButton: {
            title: 'Setup window',
            icon: Clock,
            action: 'link',
            url: '/merchant/settings?modal=sales-window'
          },
          required: true,
          isCompleted: (state) =>
            state.ecomStoreSetupSummary.value &&
            state.ecomStoreSetupSummary.value.ecomStore.ecomStoreWindow &&
            new Date(
              formatUtcDate(
                state.ecomStoreSetupSummary.value.ecomStore.ecomStoreWindow
                  .closeDate
              )
            ) > new Date()
        },
        {
          value: 'turn-off-sandbox',
          title: 'Turn off sandbox mode',
          description:
            'Turn off sandbox mode to make your store live and start receiving orders.',
          icon: EyeCheck,
          primaryButton: {
            title: 'Launch store',
            icon: Eye,
            action: 'link',
            url: '/merchant/settings?modal=sandbox'
          },
          required: false,
          disableAfterCompletion: true,
          isCompleted: (state) =>
            state.ecomStoreSetupSummary.value &&
            !state.ecomStoreSetupSummary.value?.ecomStore.sandboxEnabled
        }
      ]
    }
  ]
};

export { setupGuideConfig };
