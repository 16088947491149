import React, { useContext, useEffect, useRef, useState } from 'react';
import { Anchor, Grid, Pagination } from '@mantine/core';
import { ArrowLeft, ClearAll, ShoppingCart } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import VendorCatalogDisplayCard from './VendorCatalogDisplayCard';
import TableViewDisabledContent from '../../common/TableViewDisabledContent';
import { useProductSearchFilter } from '../../../helpers/catalogHelper';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import AppFlexbox from '../../common/AppFlexbox';
import { LOCATION_LIST_OPTIONS } from '../../../config/locationConstants';
import { CATALOG_SORT_OPTIONS } from '../../../config/constants';
import { singularPluralFormat } from '../../../helpers/format';
import ActionableIcon from '../../common/ActionableIcon';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const VendorCatalogProductSearchList = ({
  title,
  titleBackLink,
  fkEcomCategory,
  fkEcomVendor,
  fkEcomVendorCollection,
  sort,
  loading,
  fromLocation,
  fromResource,
  vendorPreview,
  baseUrl,
  fundraisingRecommended
}) => {
  const {
    isMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isDesktopOrSmaller
  } = useMediaQueryIndex();
  const fetchedCollection = useRef(null);
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreCatalogProducts } = useContext(StoreContext);
  const {
    filter,
    hasSearched,
    resetFilter,
    onFilterChange,
    pageIndex: filterPageIndex
  } = useProductSearchFilter();
  const [filterState, setFilterState] = useState({});
  const {
    pageIndex,
    totalPages,
    totalCount,
    data: catalogProducts
  } = state.catalogProducts;

  const selectedCategory = PRODUCT_CATEGORY_LIST.find(
    (c) => c.name === filter.category
  );
  const selectedVendor = state.catalogVendors.value.find(
    (c) => c.name === filter.vendor
  );
  const productSearchLoading =
    !hasFetched.current ||
    loading ||
    state.catalogProducts.loading ||
    fkEcomVendorCollection !== fetchedCollection.current;
  const fkProvince = LOCATION_LIST_OPTIONS.find(
    (op) => op.label === filter.location
  )?.value;

  useEffect(() => {
    if (
      (hasSearched ||
        fkEcomCategory ||
        fkEcomVendor ||
        fundraisingRecommended ||
        sort ||
        fkEcomVendorCollection) &&
      !loading
    ) {
      if (
        !hasFetched.current ||
        fetchedCollection.current !== fkEcomVendorCollection ||
        Object.keys(filter).some((key) => filter[key] !== filterState[key])
      ) {
        setFilterState(filter);
        // eslint-disable-next-line no-use-before-define
        fetchCatalogProducts();
        hasFetched.current = true;
        fetchedCollection.current = fkEcomVendorCollection;
      }
    }
  }, [filter, fkEcomVendorCollection, loading]);

  const fetchCatalogProducts = (nextIndex = null) => {
    fetchStoreCatalogProducts(
      {
        pkEcomStore: vendorPreview ? null : authState.pkEcomStore,
        pkEcomVendor: vendorPreview ? authState.pkEcomVendor : null
      },
      {
        search: filter.search,
        fkEcomCategory: fkEcomCategory ?? selectedCategory?.value,
        fkEcomVendor: fkEcomVendor ?? selectedVendor?.pkEcomVendor,
        fundraisingRecommended,
        fkEcomVendorCollection,
        fkProvince,
        minPrice: filter.minPrice ? Math.round(filter.minPrice * 100) : null,
        maxPrice: filter.maxPrice ? Math.round(filter.maxPrice * 100) : null,
        sort:
          sort ??
          CATALOG_SORT_OPTIONS.find((op) => op.label === filter.sort)?.value,
        page: nextIndex ?? filterPageIndex
      }
    );
  };

  const isProductSelected = false;

  return (
    <AppStack style={{ gap: 5 }}>
      {title && (
        <AppFlexbox
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            {titleBackLink && (
              <ActionableIcon
                color="dark"
                component={Link}
                radius="md"
                to={titleBackLink}
                variant="subtle"
              >
                <ArrowLeft />
              </ActionableIcon>
            )}

            <AppText style={{ fontSize: 24, fontWeight: 700 }}>{title}</AppText>
          </AppFlexbox>

          {hasSearched && (
            <Anchor c="dodgerblue" onClick={resetFilter}>
              Clear search
            </Anchor>
          )}
        </AppFlexbox>
      )}
      {productSearchLoading ? (
        <Grid
          columns={
            isMobileOrSmaller
              ? 1
              : isTabletOrSmaller
              ? 2
              : isLaptopOrSmaller
              ? 3
              : isDesktopOrSmaller
              ? 4
              : 5
          }
        >
          {Array.from(Array(15)).map((x, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid.Col key={i} span={1}>
              <VendorCatalogDisplayCard />
            </Grid.Col>
          ))}
        </Grid>
      ) : !catalogProducts || catalogProducts.length === 0 ? (
        <TableViewDisabledContent
          disabledContent={{
            title: 'No products found',
            description:
              "We're sorry, but no products were found. Please try a different search term or browsing another category. New products are added regularly, so check back soon!",
            icon: <ShoppingCart color="#000" size={125} />,
            primaryButton: hasSearched
              ? {
                  label: 'Clear search',
                  icon: <ClearAll size={18} />,
                  onClick: resetFilter
                }
              : null
          }}
        />
      ) : (
        <>
          <Grid
            columns={
              isMobileOrSmaller
                ? 1
                : isTabletOrSmaller
                ? 2
                : isLaptopOrSmaller
                ? 3
                : isDesktopOrSmaller
                ? 4
                : 5
            }
          >
            {catalogProducts?.map((p) => (
              <Grid.Col key={p.pkEcomVendorProduct} span={1}>
                <VendorCatalogDisplayCard
                  baseUrl={baseUrl}
                  ecomVendorProduct={p}
                  fromLocation={fromLocation}
                  fromResource={fromResource}
                  selected={isProductSelected}
                  vendorPreview={vendorPreview}
                />
              </Grid.Col>
            ))}
          </Grid>

          <AppStack style={{ marginTop: 20, gap: 10, alignItems: 'center' }}>
            {totalPages > 1 && (
              <Pagination
                color="dark"
                onChange={(index) => {
                  onFilterChange({ page: index });
                  fetchCatalogProducts(index);
                }}
                total={totalPages}
                value={pageIndex}
              />
            )}
            <AppText style={{ fontSize: 18, fontWeight: 700, color: '#999' }}>
              {singularPluralFormat(totalCount, 'product', 'products')} found
            </AppText>
          </AppStack>
        </>
      )}
    </AppStack>
  );
};

VendorCatalogProductSearchList.propTypes = {
  baseUrl: PropTypes.string,
  fkEcomCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fkEcomVendor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fkEcomVendorCollection: PropTypes.number,
  fromLocation: PropTypes.string,
  fromResource: PropTypes.string,
  fundraisingRecommended: PropTypes.bool,
  loading: PropTypes.bool,
  sort: PropTypes.string,
  title: PropTypes.string,
  titleBackLink: PropTypes.string,
  vendorPreview: PropTypes.bool
};

export default VendorCatalogProductSearchList;
