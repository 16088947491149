import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '@mantine/core';
import WsSection from './sections/WsSection';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import { ECOM_WEBSTORE_SECTION_BOUND_TYPE } from '../../../config/constants';
import { useMediaQueryIndex, useOnScrollUp } from '../../../helpers/hooks';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { useCartController } from '../../../helpers/webstoreCheckoutHelper';
import AddWebstoreProductToCartModal from './AddWebstoreProductToCartModal';
import CartDrawer from './CartDrawer';
import CustomizeCartProductModal from './CustomizeCartProductModal';
import { useWebstoreUserHelper } from '../../../helpers/webstoreHelper';
import ContinueAsGuestModal from './ContinueAsGuestModal';

const WsApp = ({
  loading,
  viewPortAdjustment,
  editModeEnabled,
  selectedPageType,
  selectedSection,
  selectedSectionBlock,
  ecomStoreMenus,
  ecomStoreTheme,
  ecomStoreThemeConfig,
  ecomStoreThemeColorSchemes,
  ecomStoreThemeSections,
  onSelectSection,
  onUnselectSection,
  logoImageUrl,
  containerRef,
  builderHasAuthenticated,
  onAuthenticateEditMode,
  onChangeSectionConfigValues
}) => {
  const hasFetchedCart = useRef(false);
  const scrollContainerRef = useRef(null);
  const { state: authState } = useContext(AuthContext);
  const { fetchStoreCart } = useCartController();
  const { hasScrolledUp } = useOnScrollUp({ containerRef });
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { fetchWebstorePageResources } = useWebstoreUserHelper();
  const locationParams = new URLSearchParams(search);
  const previewPathname = locationParams.get('previewPath');
  const mediaQueries = useMediaQueryIndex({ viewPortAdjustment });
  const baseStoreUrl = `/store/${ecomStoreTheme?.ecomStore?.urlHandle}`;
  const headerSections = ecomStoreThemeSections?.filter(
    (s) =>
      s.fkEcomStoreThemeSectionLocationType ===
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.HEADER && !s.hidden
  );
  const bodySections = ecomStoreThemeSections?.filter(
    (s) =>
      s.fkEcomStoreThemeSectionLocationType ===
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY &&
      s.fkEcomStorePageType === selectedPageType &&
      !s.hidden
  );
  const footerSections = ecomStoreThemeSections?.filter(
    (s) =>
      s.fkEcomStoreThemeSectionLocationType ===
        ECOM_WEBSTORE_SECTION_BOUND_TYPE.FOOTER && !s.hidden
  );
  const colorSchemesSorted = ecomStoreThemeColorSchemes?.sort(
    (a, b) => a.sort - b.sort
  );
  const currentSearch =
    previewPathname !== null
      ? `?${previewPathname.split('?')[1] ?? ''}`
      : search;
  const currentPathname = (previewPathname !== null
    ? previewPathname.split('?')[0] ?? '/'
    : pathname
  ).replace(baseStoreUrl, '');

  useEffect(() => {
    if (
      ecomStoreTheme &&
      authState.tokenAttempted &&
      !currentPathname.startsWith(`/checkout`) &&
      !hasFetchedCart.current
    ) {
      fetchStoreCart(ecomStoreTheme.ecomStore.pkEcomStore);
      hasFetchedCart.current = true;
    }
  }, [ecomStoreTheme, authState.isAuthenticated, authState.tokenAttempted]);

  useEffect(() => {
    if (scrollContainerRef?.current) {
      if (editModeEnabled) {
        scrollContainerRef.current.scrollTo(0, 0);
      }
      else {
        window.scrollTo(0, 0);
      }
    }
  }, [currentPathname, scrollContainerRef]);

  useEffect(() => {
    if (ecomStoreThemeSections) {
      fetchWebstorePageResources(ecomStoreTheme, bodySections, currentPathname);
    }
  }, [selectedPageType, ecomStoreThemeSections, currentPathname]);

  const onNavigate = (event, url = null) => {
    if (typeof event === 'string') {
      if (editModeEnabled) {
        const searchParams = new URLSearchParams();
        searchParams.set('previewPath', event.replace(baseStoreUrl, ''));
        navigate(`${pathname}?${searchParams.toString()}`);
      }
      else {
        navigate(event);
      }
    }
    else if (
      editModeEnabled &&
      event.currentTarget.getAttribute('target') !== '_blank'
    ) {
      event.preventDefault();

      const searchParams = new URLSearchParams();
      searchParams.set(
        'previewPath',
        (url || event.currentTarget.getAttribute('href')).replace(
          baseStoreUrl,
          ''
        )
      );
      navigate(`${pathname}?${searchParams.toString()}`);
    }
  };

  return !loading ? (
    <AppFlexbox
      id="ws-app-container"
      style={{
        flex: 1,
        gap: 0,
        flexDirection: 'column',
        alignSelf: 'center',
        width: '100%',
        height: '100%',
        overflow: editModeEnabled ? 'hidden' : 'unset',
        backgroundColor: colorSchemesSorted[0]?.backgroundColor ?? '#FFF'
      }}
    >
      <AppStack
        ref={scrollContainerRef}
        style={{
          flex: 1,
          gap: 0,
          minHeight: 'unset',
          height: '100%',
          width: '100%',
          overflow: editModeEnabled ? 'auto' : 'unset',
          position: 'relative'
        }}
      >
        {headerSections.length > 0 &&
          headerSections
            .sort((a, b) => a.sort - b.sort)
            .map((s) => (
              <WsSection
                key={s.key}
                baseStoreUrl={baseStoreUrl}
                builderHasAuthenticated={builderHasAuthenticated}
                colorSchemes={colorSchemesSorted}
                ecomStoreMenus={ecomStoreMenus}
                ecomStoreTheme={ecomStoreTheme}
                ecomStoreThemeSection={s}
                editModeEnabled={editModeEnabled}
                isSelected={selectedSection === s.key}
                logoImageUrl={logoImageUrl}
                mediaQueries={mediaQueries}
                onAuthenticateEditMode={onAuthenticateEditMode}
                onChangeSectionConfigValues={onChangeSectionConfigValues}
                onNavigate={onNavigate}
                onSelectSection={onSelectSection}
                onUnselectSection={onUnselectSection}
                pathname={currentPathname}
                scrollUpVisible={hasScrolledUp}
                search={currentSearch}
                sectionKey={s.key}
                selectedSectionBlock={selectedSectionBlock}
                themeConfig={ecomStoreThemeConfig}
                viewPortAdjustment={viewPortAdjustment}
                {...s}
              />
            ))}

        <AppStack
          style={{
            flex: 1,
            gap: ecomStoreThemeConfig.spaceBetweenBodySections
              ? ecomStoreThemeConfig.spaceBetweenBodySections * 1
              : 0
          }}
        >
          {bodySections.length > 0 &&
            bodySections
              .sort((a, b) => a.sort - b.sort)
              .map((s) => (
                <WsSection
                  key={s.key}
                  baseStoreUrl={baseStoreUrl}
                  builderHasAuthenticated={builderHasAuthenticated}
                  colorSchemes={colorSchemesSorted}
                  ecomStoreMenus={ecomStoreMenus}
                  ecomStoreTheme={ecomStoreTheme}
                  editModeEnabled={editModeEnabled}
                  isSelected={selectedSection === s.key}
                  logoImageUrl={logoImageUrl}
                  mediaQueries={mediaQueries}
                  onAuthenticateEditMode={onAuthenticateEditMode}
                  onChangeSectionConfigValues={onChangeSectionConfigValues}
                  onNavigate={onNavigate}
                  onSelectSection={onSelectSection}
                  onUnselectSection={onUnselectSection}
                  pathname={currentPathname}
                  scrollUpVisible={hasScrolledUp}
                  search={currentSearch}
                  sectionKey={s.key}
                  selectedSectionBlock={selectedSectionBlock}
                  themeConfig={ecomStoreThemeConfig}
                  viewPortAdjustment={viewPortAdjustment}
                  {...s}
                />
              ))}
        </AppStack>

        {footerSections.length > 0 &&
          footerSections
            .sort((a, b) => a.sort - b.sort)
            .map((s) => (
              <WsSection
                key={s.key}
                baseStoreUrl={baseStoreUrl}
                builderHasAuthenticated={builderHasAuthenticated}
                colorSchemes={colorSchemesSorted}
                ecomStoreMenus={ecomStoreMenus}
                ecomStoreTheme={ecomStoreTheme}
                editModeEnabled={editModeEnabled}
                isSelected={selectedSection === s.key}
                logoImageUrl={logoImageUrl}
                mediaQueries={mediaQueries}
                onAuthenticateEditMode={onAuthenticateEditMode}
                onChangeSectionConfigValues={onChangeSectionConfigValues}
                onNavigate={onNavigate}
                onSelectSection={onSelectSection}
                onUnselectSection={onUnselectSection}
                pathname={currentPathname}
                scrollUpVisible={hasScrolledUp}
                search={currentSearch}
                sectionKey={s.key}
                selectedSectionBlock={selectedSectionBlock}
                themeConfig={ecomStoreThemeConfig}
                viewPortAdjustment={viewPortAdjustment}
                {...s}
              />
            ))}
      </AppStack>
      <CartDrawer
        baseStoreUrl={baseStoreUrl}
        ecomStoreTheme={ecomStoreTheme}
        editModeEnabled={editModeEnabled}
        logoImageUrl={logoImageUrl}
        mediaQueries={mediaQueries}
        onNavigate={onNavigate}
        pathname={currentPathname}
      />
      <AddWebstoreProductToCartModal
        baseStoreUrl={baseStoreUrl}
        ecomStoreTheme={ecomStoreTheme}
        editModeEnabled={editModeEnabled}
        logoImageUrl={logoImageUrl}
        mediaQueries={mediaQueries}
        onNavigate={onNavigate}
        pathname={currentPathname}
      />
      <CustomizeCartProductModal
        baseStoreUrl={baseStoreUrl}
        ecomStoreTheme={ecomStoreTheme}
        editModeEnabled={editModeEnabled}
        logoImageUrl={logoImageUrl}
        mediaQueries={mediaQueries}
        onNavigate={onNavigate}
        pathname={currentPathname}
      />
      <ContinueAsGuestModal
        baseStoreUrl={baseStoreUrl}
        ecomStoreTheme={ecomStoreTheme}
        editModeEnabled={editModeEnabled}
        logoImageUrl={logoImageUrl}
        mediaQueries={mediaQueries}
        onNavigate={onNavigate}
        pathname={currentPathname}
      />
    </AppFlexbox>
  ) : (
    <AppStack
      style={{
        flex: 1,
        gap: 0,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <Loader color="dark" size={60} type="dots" />
    </AppStack>
  );
};

WsApp.propTypes = {
  builderHasAuthenticated: PropTypes.bool,
  containerRef: PropTypes.object,
  ecomStoreMenus: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  ecomStoreThemeColorSchemes: PropTypes.array,
  ecomStoreThemeConfig: PropTypes.object,
  ecomStoreThemeSections: PropTypes.array,
  editModeEnabled: PropTypes.bool,
  hasScrolledUp: PropTypes.bool,
  loading: PropTypes.bool,
  logoImageUrl: PropTypes.string,
  onAuthenticateEditMode: PropTypes.func,
  onChangeSectionConfigValues: PropTypes.func,
  onSelectSection: PropTypes.func,
  onUnselectSection: PropTypes.func,
  selectedPageType: PropTypes.number,
  selectedSection: PropTypes.number,
  selectedSectionBlock: PropTypes.number,
  viewPortAdjustment: PropTypes.string
};

export default WsApp;
