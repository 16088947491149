import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import StoreOrderTable from './StoreOrderTable';
import StoreOrderDetailsView from '../orders/StoreOrderDetailsView';

const StoreOrdersView = () => (
  <Routes>
    <Route element={<StoreOrderDetailsView />} path="/:pkEcomStoreCart/*" />
    <Route element={<StoreOrderTable />} path="/" />
    <Route element={<Navigate replace to="/merchant/orders" />} path="*" />
  </Routes>
);

export default StoreOrdersView;
