import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import * as classes from '../../../styles/nestedStyles.module.css';
import AppStack from '../../common/AppStack';

const DrawerLinkSection = ({ lightMode, links, onClick, hideTitleLink }) => {
  const { pathname } = useLocation();
  const [openSections, setOpenSections] = useState([]);

  useEffect(() => {
    setOpenSections([]);
  }, [links]);

  return (
    <>
      {links.map((link) => {
        const isSelected = link.isSelected(pathname);
        const selectedSectionLinkIndex = link.links?.findIndex((f) =>
          f.isSelected(pathname)
        );
        const sectionOpen = openSections.includes(link.to);
        const isInnerSelected = selectedSectionLinkIndex >= 0;
        const hasSublinks = !!link.links && link.links.length > 0;

        return (
          <React.Fragment key={link.to}>
            <AppFlexbox
              key={link.to}
              className={
                lightMode
                  ? classes['hover-button-light-mode']
                  : classes['hover-button-dark-mode']
              }
              component={Link}
              onClick={(e) => {
                if (hasSublinks) {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenSections(
                    sectionOpen
                      ? openSections.filter((s) => s !== link.to)
                      : [...openSections, link.to]
                  );
                }
                else {
                  onClick();
                }
              }}
              style={{
                padding: '5px 8px',
                borderBottomLeftRadius: 8,
                borderTopLeftRadius: 8,
                color: lightMode ? '#000' : '#FFF',
                textDecoration: 'none',
                placeItems: 'center',
                gap: 8,
                backgroundImage:
                  !hasSublinks && isSelected
                    ? 'linear-gradient(270deg, rgba(240, 180, 0,0.2) 0%, rgba(255,255,255,1) 100%)'
                    : 'none',
                borderRight:
                  !hasSublinks && isSelected
                    ? 'solid 3px rgba(240, 180, 0)'
                    : isSelected || isInnerSelected
                    ? 'solid 3px #999'
                    : 'none',
                backgroundColor: !hasSublinks && isSelected ? '#FFF' : 'unset'
              }}
              target={link.target}
              to={link.to}
            >
              <AppStack style={{ width: 20 }}>
                <link.icon
                  size={20}
                  style={{ fontWeight: !hasSublinks && isSelected ? 700 : 500 }}
                />
              </AppStack>
              <AppText
                style={{ whiteSpace: 'normal', fontSize: 16 }}
                weight={!hasSublinks && isSelected ? 700 : 500}
              >
                {link.title}
              </AppText>
            </AppFlexbox>

            {hasSublinks &&
              (sectionOpen || isSelected || selectedSectionLinkIndex >= 0) && (
                <>
                  {!hideTitleLink && (
                    <AppFlexbox
                      className={
                        lightMode
                          ? classes['hover-button-light-mode']
                          : classes['hover-button-dark-mode']
                      }
                      component={Link}
                      onClick={onClick}
                      style={{
                        padding: '5px 8px',
                        borderBottomLeftRadius: 8,
                        borderTopLeftRadius: 8,
                        color: '#666',
                        textDecoration: 'none',
                        placeItems: 'center',
                        gap: 8,
                        backgroundImage: isSelected
                          ? 'linear-gradient(270deg, rgba(240, 180, 0,0.2) 0%, rgba(255,255,255,1) 100%)'
                          : 'none',
                        borderRight: isSelected
                          ? 'solid 3px rgba(240, 180, 0)'
                          : isInnerSelected
                          ? 'solid 3px #999'
                          : 'none',
                        backgroundColor: isSelected ? '#FFF' : 'unset'
                      }}
                      target={link.target}
                      to={link.to}
                    >
                      <AppStack style={{ width: 20 }} />
                      <AppText
                        style={{ whiteSpace: 'normal', fontSize: 16 }}
                        weight={isSelected ? 700 : 500}
                      >
                        {link.title}
                      </AppText>
                    </AppFlexbox>
                  )}

                  {link.links.map((sublink, index) => {
                    const isSublinkSelected =
                      index === selectedSectionLinkIndex;

                    return (
                      <AppFlexbox
                        key={sublink.to}
                        className={
                          lightMode
                            ? classes['hover-button-light-mode']
                            : classes['hover-button-dark-mode']
                        }
                        component={Link}
                        onClick={onClick}
                        style={{
                          padding: '5px 8px',
                          borderBottomLeftRadius: 8,
                          borderTopLeftRadius: 8,
                          color: '#666',
                          textDecoration: 'none',
                          placeItems: 'center',
                          gap: 8,
                          backgroundImage: isSublinkSelected
                            ? 'linear-gradient(270deg, rgba(240, 180, 0,0.3) 0%, rgba(255,255,255,1) 70%)'
                            : 'none',
                          borderRight: isSublinkSelected
                            ? 'solid 3px rgba(240, 180, 0)'
                            : index < selectedSectionLinkIndex
                            ? 'solid 3px #999'
                            : 'none',
                          backgroundColor: isSublinkSelected ? '#FFF' : 'unset'
                        }}
                        target={sublink.target}
                        to={sublink.to}
                      >
                        <AppStack style={{ width: 20 }} />
                        <AppText
                          style={{ whiteSpace: 'normal', fontSize: 16 }}
                          weight={isSublinkSelected ? 700 : 500}
                        >
                          {sublink.title}
                        </AppText>
                      </AppFlexbox>
                    );
                  })}
                </>
              )}
          </React.Fragment>
        );
      })}
    </>
  );
};

DrawerLinkSection.propTypes = {
  lightMode: PropTypes.bool,
  links: PropTypes.array,
  onClick: PropTypes.func,
  textColor: PropTypes.string
};

export default DrawerLinkSection;
