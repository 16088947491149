import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Image, Indicator, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import ProductLineItemAddonsDisplay from '../products/ProductLineItemAddonsDisplay';

const VendorOrderProductPreview = ({
  product,
  loading,
  quantityIndicatorAmount
}) =>
  !loading && product ? (
    <AppFlexbox style={{ gap: 8 }}>
      <Indicator
        disabled={!quantityIndicatorAmount}
        label={quantityIndicatorAmount}
        radius={100}
        size={15}
      >
        <AppStack
          component={product.to ? Link : null}
          style={{
            width: 50,
            height: 50,
            overflow: 'hidden',
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #e1e1e1'
          }}
          to={product.to ? product.to : null}
        >
          <Image fit="contain" h="100%" src={product.previewImage} w="100%" />
        </AppStack>
      </Indicator>
      <AppFlexbox
        component={product.to ? Link : null}
        style={{ color: '#000', textDecoration: 'none' }}
        to={product.to ? product.to : null}
      >
        <AppStack style={{ gap: 0 }}>
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            {product.name}
          </AppText>

          <ProductLineItemAddonsDisplay addOns={product.addOns} />

          {(product.sku || product.barcode) && (
            <>
              <Divider style={{ margin: '4px 0px' }} />
              {product.sku && (
                <AppText style={{ fontSize: 12, color: '#666' }}>
                  SKU: {product.sku}
                </AppText>
              )}
              {product.barcode && (
                <AppText style={{ fontSize: 12, color: '#666' }}>
                  BARCODE: {product.barcode}
                </AppText>
              )}
            </>
          )}
        </AppStack>
      </AppFlexbox>
    </AppFlexbox>
  ) : (
    <AppFlexbox style={{ width: '100%', flex: 1, gap: 8 }}>
      <AppStack
        style={{
          width: 50,
          height: 50,
          overflow: 'hidden',
          borderRadius: 8,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Skeleton height="100%" width="100%" />
      </AppStack>
      <AppStack style={{ width: '100%', gap: 5 }}>
        <Skeleton height={18} width="40%" />

        <Skeleton height={16} width="30%" />
        <Skeleton height={16} width="30%" />
      </AppStack>
    </AppFlexbox>
  );

VendorOrderProductPreview.propTypes = {
  loading: PropTypes.bool,
  product: PropTypes.object,
  quantityIndicatorAmount: PropTypes.number,
  withNavigation: PropTypes.bool
};

export default VendorOrderProductPreview;
