import React, { useRef } from 'react';
import { Button, Divider, Image, Loader } from '@mantine/core';
import PropTypes from 'prop-types';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { Edit, File, Trash } from 'tabler-icons-react';
import AppStack from './AppStack';
import AppText from './AppText';
import AppFlexbox from './AppFlexbox';
import { triggerNotification } from '../../helpers/notification';
import AppCard from './AppCard';

const FileUpload = ({
  onUpload,
  onError,
  disabled,
  height,
  loading,
  file,
  accept,
  variant,
  imagePreview,
  onRemove,
  hideChangeButton,
  ...rest
}) => {
  const openRef = useRef(null);
  const acceptValues = accept || [MIME_TYPES.csv];

  const allowedTypes = Object.keys(MIME_TYPES)
    .filter((key) => acceptValues.includes(MIME_TYPES[key]))
    .map((key) => key.toUpperCase());

  const formatTypes = (types) => {
    if (types.length === 0) return '';
    if (types.length === 1) return types[0];
    if (types.length === 2) return types.join(' or ');

    return `${types.slice(0, -1).join(', ')}, or ${types[types.length - 1]}`;
  };

  return (
    <Dropzone
      accept={acceptValues}
      activateOnClick={false}
      disabled={disabled}
      maxSize={5 * 1024 ** 2}
      onDrop={onUpload}
      onReject={
        onError ||
        ((e) =>
          triggerNotification(
            e[0]?.errors[0]?.message ?? 'Error uploading file'
          ))
      }
      openRef={openRef}
      {...rest}
    >
      {imagePreview ? (
        <AppStack style={{ gap: 0 }}>
          <AppStack
            style={{
              padding: 20,
              border: 'solid 1px lightgrey',
              borderBottom: disabled ? 'solid 1px lightgrey' : 'none',
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4
            }}
          >
            <AppStack style={{ height: 100 }}>
              <Image fit="contain" h="100%" src={imagePreview} w="100%" />
            </AppStack>
          </AppStack>
          <AppFlexbox style={{ gap: 0, flex: 1 }}>
            <Button.Group style={{ flex: 1 }}>
              {!hideChangeButton && (
                <Button
                  disabled={disabled}
                  leftSection={<Edit size={18} />}
                  onClick={() => openRef.current?.()}
                  size="compact-md"
                  style={{
                    flex: 1,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0
                  }}
                  variant="default"
                >
                  Change
                </Button>
              )}

              <Button
                disabled={disabled}
                leftSection={<Trash size={18} />}
                onClick={onRemove}
                size="compact-md"
                style={{
                  flex: 1,
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0
                }}
                variant="default"
              >
                Remove
              </Button>
            </Button.Group>
          </AppFlexbox>
        </AppStack>
      ) : file ? (
        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppFlexbox style={{ flex: 1, gap: 0, alignItems: 'center' }}>
            <AppStack style={{ padding: 10 }}>
              <File size={36} />
            </AppStack>
            <Divider orientation="vertical" />
            <AppFlexbox
              style={{
                flex: 1,
                padding: 10,
                justifyContent: 'space-between',
                alignItems: 'center',
                overflow: 'hidden'
              }}
            >
              <AppText
                style={{ fontSize: 14, fontWeight: 500, textWrap: 'nowrap' }}
              >
                {file.name}
              </AppText>
              <AppFlexbox>
                <Button
                  color="dark"
                  loading={loading}
                  onClick={() => openRef.current?.()}
                  size="compact-sm"
                  variant="outline"
                >
                  Change
                </Button>
              </AppFlexbox>
            </AppFlexbox>
          </AppFlexbox>
        </AppCard>
      ) : (
        <AppStack
          {...rest}
          style={{
            pointerEvents: 'all',
            position: 'relative',
            boxSizing: 'border-box',
            borderRadius: 10,
            flex: 1,
            minHeight: height ?? 150,
            overflow: 'hidden',
            ...rest.sx
          }}
        >
          <Dropzone.Accept>
            <AppStack
              style={{
                position: 'absolute',
                border: 'solid 2px #067D62',
                backgroundColor: 'rgba(6, 125, 98, 0.1)',
                borderRadius: 10,
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 9,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AppText
                style={{ textAlign: 'center', fontSize: 14, fontWeight: 500 }}
              >
                Drop file to upload
              </AppText>
            </AppStack>
          </Dropzone.Accept>

          <Dropzone.Idle>
            <AppStack
              onClick={() => openRef.current?.()}
              style={{
                position: 'absolute',
                border: 'dashed 2px #ced4da',
                borderRadius: 10,
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 9,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                gap: 12,
                padding: 8
              }}
            >
              {loading ? (
                <AppStack
                  style={{
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Loader color="dark" size={36} />
                </AppStack>
              ) : (
                <>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      textAlign: 'center'
                    }}
                  >
                    <Button color="dark" size="compact-sm" variant="outline">
                      Upload file
                    </Button>
                  </AppFlexbox>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    or drag and drop a file to upload
                  </AppText>
                </>
              )}
            </AppStack>
          </Dropzone.Idle>

          <Dropzone.Reject>
            <AppStack
              style={{
                position: 'absolute',
                border: 'solid 2px #c40000',
                backgroundColor: 'rgba(196, 0, 0, 0.1)',
                borderRadius: 10,
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 9,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AppText
                style={{ textAlign: 'center', fontSize: 14, fontWeight: 500 }}
              >
                File type must be {formatTypes(allowedTypes)}.
              </AppText>
            </AppStack>
          </Dropzone.Reject>
        </AppStack>
      )}
    </Dropzone>
  );
};

FileUpload.propTypes = {
  accept: PropTypes.array,
  disabled: PropTypes.bool,
  file: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideChangeButton: PropTypes.bool,
  imagePreview: PropTypes.string,
  loading: PropTypes.bool,
  onError: PropTypes.func,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  variant: PropTypes.string
};

export default FileUpload;
