import React, { useContext, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AlertTriangle, ArrowLeft } from 'tabler-icons-react';
import {
  ActionIcon,
  Alert,
  Badge,
  Divider,
  NumberFormatter,
  Skeleton
} from '@mantine/core';
import dayjs from 'dayjs';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import { formatUtcDate } from '../../../helpers/format';
import * as classes from '../../../styles/nestedStyles.module.css';
import OrderStatsCard from '../orders/OrderStatsCard';
import StoreOverviewInfoCard from '../stores/StoreOverviewInfoCard';

const AdminStoreDetailsView = () => {
  const fetchedPkEcomStore = useRef(null);
  const { pkEcomStore } = useParams();
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const { state, fetchEcomStore } = useContext(AdminContext);
  const ecomStoreDetails = state.ecomStore.value;
  const loading =
    !ecomStoreDetails ||
    state.ecomStore.loading ||
    ecomStoreDetails.ecomStore.pkEcomStore.toString() !== pkEcomStore;

  useEffect(() => {
    if (pkEcomStore && pkEcomStore !== fetchedPkEcomStore.current) {
      fetchedPkEcomStore.current = pkEcomStore;
      fetchEcomStore(pkEcomStore);
    }
  }, [pkEcomStore]);

  return !loading && ecomStoreDetails ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to="/admin/stores"
            variant="subtle"
          >
            <ArrowLeft />
          </ActionIcon>

          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText
                style={{
                  flex: 1,
                  fontSize: 24,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                {ecomStoreDetails.ecomStore.name}
              </AppText>
            </AppFlexbox>
            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Created{' '}
              {dayjs(
                formatUtcDate(ecomStoreDetails.ecomStore.createdAt)
              ).format('MMMM D, YYYY')}{' '}
              at{' '}
              {dayjs(
                formatUtcDate(ecomStoreDetails.ecomStore.createdAt)
              ).format('h:mm A')}
            </AppText>
          </AppStack>
        </AppFlexbox>
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }} />
      </AppFlexbox>
      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderStatsCard
            data={[
              {
                label: 'Amount paid',
                value: ecomStoreDetails.transactionsOverview.amountPaid / 100
              },
              {
                label: 'Average order value',
                value:
                  ecomStoreDetails.transactionsOverview.orderCount === 0
                    ? 0
                    : ecomStoreDetails.transactionsOverview.amountPaid /
                      ecomStoreDetails.transactionsOverview.orderCount /
                      100
              },
              {
                label: 'Amount refunded',
                value:
                  ecomStoreDetails.transactionsOverview.amountRefunded / 100
              },
              {
                label: 'Orders',
                value: ecomStoreDetails.transactionsOverview.orderCount,
                type: 'string'
              }
            ]}
          />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <StoreOverviewInfoCard
            ecomStore={ecomStoreDetails.ecomStore}
            showExternalConnections
          />

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack
              style={{
                gap: 0,
                flexDirection:
                  !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
              }}
            >
              <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
                <AppStack style={{ flex: 1, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      Payment provider
                    </AppText>
                  </AppFlexbox>

                  {(!ecomStoreDetails.ecomStore.ecomStorePaymentProvider ||
                    !ecomStoreDetails.ecomStore.ecomStorePaymentProvider
                      .completed) && (
                    <Alert
                      color="#c40000"
                      icon={<AlertTriangle style={{ height: 24, width: 24 }} />}
                      radius={10}
                      styles={{
                        body: { gap: 5 },
                        icon: { height: 24, width: 24 },
                        title: { fontSize: 15, fontWeight: 500 }
                      }}
                      title="Action required"
                      variant="outline"
                    >
                      <AppStack>
                        <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                          Payment provider onboarding is incomplete
                        </AppText>
                      </AppStack>
                    </Alert>
                  )}

                  {ecomStoreDetails.ecomStore.ecomStorePaymentProvider && (
                    <AppCard
                      className={classes['hover-action-card']}
                      component="a"
                      href={`https://dashboard.stripe.com/connect/accounts/${ecomStoreDetails.ecomStore.ecomStorePaymentProvider.accountId}`}
                      radius={8}
                      shadow="none"
                      style={{
                        padding: 8,
                        textDecoration: 'none',
                        color: '#000'
                      }}
                      target="_blank"
                      withBorder
                    >
                      <AppStack style={{ gap: 0 }}>
                        <AppFlexbox
                          style={{
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            Stripe account
                          </AppText>
                          {!ecomStoreDetails.ecomStore.ecomStorePaymentProvider
                            .completed ? (
                            <Badge color="yellow">Incomplete</Badge>
                          ) : (
                            <Badge color="green">Connected</Badge>
                          )}
                        </AppFlexbox>

                        <AppText style={{ fontSize: 14 }}>
                          {
                            ecomStoreDetails.ecomStore.ecomStorePaymentProvider
                              .accountId
                          }
                        </AppText>
                        <AppText style={{ fontSize: 14, color: '#999' }}>
                          Added{' '}
                          {dayjs(
                            formatUtcDate(
                              ecomStoreDetails.ecomStore
                                .ecomStorePaymentProvider.createdAt
                            )
                          ).format('MMM D, YYYY')}
                        </AppText>
                      </AppStack>
                    </AppCard>
                  )}
                </AppStack>
              </AppStack>

              <Divider
                orientation={
                  !isTabletOrSmaller && isDesktopOrSmaller
                    ? 'vertical'
                    : 'horizontal'
                }
              />

              <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText
                    style={{
                      fontSize: 14,
                      fontWeight: 500
                    }}
                  >
                    Escrow balance
                  </AppText>
                </AppFlexbox>
                <AppStack style={{ gap: 8 }}>
                  <AppFlexbox
                    style={{
                      gap: 8,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 14 }}>Total</AppText>
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontSize: 14 }}
                      thousandSeparator
                      value={
                        (ecomStoreDetails.escrowBalance.amountPaid -
                          ecomStoreDetails.escrowBalance.amountRefunded -
                          ecomStoreDetails.escrowBalance.totalServiceFees -
                          ecomStoreDetails.escrowBalance.totalApplicationFees) /
                        100
                      }
                    />
                  </AppFlexbox>

                  <AppFlexbox
                    style={{
                      gap: 8,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 14 }}>Claimed</AppText>
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontSize: 14 }}
                      thousandSeparator
                      value={
                        ecomStoreDetails.escrowBalance.totalAmountClaimed / 100
                      }
                    />
                  </AppFlexbox>
                  <Divider />

                  <AppFlexbox
                    style={{
                      gap: 8,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 14 }}>Balance</AppText>
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontSize: 14 }}
                      thousandSeparator
                      value={
                        (ecomStoreDetails.escrowBalance.amountPaid -
                          ecomStoreDetails.escrowBalance.amountRefunded -
                          ecomStoreDetails.escrowBalance.totalServiceFees -
                          ecomStoreDetails.escrowBalance.totalApplicationFees -
                          ecomStoreDetails.escrowBalance.totalAmountClaimed) /
                        100
                      }
                    />
                  </AppFlexbox>
                </AppStack>
              </AppStack>
            </AppStack>
          </AppCard>

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack
              style={{
                gap: 0,
                flexDirection:
                  !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
              }}
            >
              <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
                <AppStack style={{ flex: 1, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      Purchasing Window
                    </AppText>
                  </AppFlexbox>

                  <AppCard
                    radius={8}
                    shadow="none"
                    style={{ padding: 8 }}
                    withBorder
                  >
                    {!ecomStoreDetails.ecomStore.ecomStoreWindow ? (
                      <></>
                    ) : (
                      <AppStack style={{ gap: 0 }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          Ends{' '}
                          {dayjs(
                            formatUtcDate(
                              ecomStoreDetails.ecomStore.ecomStoreWindow
                                ?.closeDate
                            )
                          ).format('MMM D, YYYY')}
                        </AppText>
                        <AppText style={{ fontSize: 14, color: '#999' }}>
                          Started{' '}
                          {dayjs(
                            formatUtcDate(
                              ecomStoreDetails.ecomStore.ecomStoreWindow
                                ?.createdAt
                            )
                          ).format('MMM D, YYYY')}
                        </AppText>
                      </AppStack>
                    )}
                  </AppCard>
                </AppStack>
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <ActionIcon
            color="dark"
            component={Link}
            radius="md"
            to="/admin/stores"
            variant="subtle"
          >
            <ArrowLeft />
          </ActionIcon>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={36} width={200} />
            <Skeleton height={14} width={150} />
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ gap: 5, alignItems: 'center' }} />
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderStatsCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <StoreOverviewInfoCard loading showExternalConnections />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

export default AdminStoreDetailsView;
