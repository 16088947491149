import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import VendorCatalogExplorer from './VendorCatalogExplorer';
import VendorCatalogCategoryView from './VendorCatalogCategoryView';
import VendorCatalogCategoriesView from './VendorCatalogCategoriesView';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import VendorCatalogVendorsView from './VendorCatalogVendorsView';
import VendorCatalogVendorView from './VendorCatalogVendorView';
import VendorCatalogProductView from './VendorCatalogProductView';
import SelectedVendorProductsDialog from './SelectedVendorProductsDialog';
import VendorCatalogProductSortExplorer from './VendorCatalogProductSortExplorer';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import VendorCatalogRemoveStoreProductsModal from './VendorCatalogRemoveStoreProductsModal';

const VendorCatalogView = ({ vendorPreview }) => {
  const hasFetched = useRef(false);
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchStoreCatalogVendors,
    fetchStoreCatalogProductsSummary,
    fetchStoreProductOptions,
    fetchStoreSetupSummary
  } = useContext(StoreContext);
  const { toggleVendorCatalogDrawer } = useContext(HelperContext);
  const { cart, fetchCart: fetchCatalogCart } = useVendorCatalogCheckout();
  const loading =
    !hasFetched.current ||
    state.catalogVendors.loading ||
    state.ecomStoreProducts.loading ||
    state.catalogCartProducts.loading;
  const baseUrl = vendorPreview
    ? '/vendor/catalog'
    : '/merchant/products/discover';

  useEffect(() => {
    if (authState.pkEcomStore || authState.pkEcomVendor) {
      if (!state.catalogVendors.loading && !state.catalogVendors.value.length) {
        fetchStoreCatalogVendors({
          pkEcomStore: vendorPreview ? null : authState.pkEcomStore,
          pkEcomVendor: vendorPreview ? authState.pkEcomVendor : null
        });
        fetchStoreCatalogProductsSummary({
          pkEcomStore: vendorPreview ? null : authState.pkEcomStore,
          pkEcomVendor: vendorPreview ? authState.pkEcomVendor : null
        });
      }

      if (!hasFetched.current) {
        fetchStoreProductOptions(authState.pkEcomStore, {});
        if (!vendorPreview) {
          fetchStoreSetupSummary(authState.pkEcomStore, { skipLoading: true });
        }
      }

      if (
        !vendorPreview &&
        cart.storageCart &&
        cart.storageCart.ecomVendorProducts.length > 0
      ) {
        fetchCatalogCart();
        toggleVendorCatalogDrawer(true);
      }
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore, authState.pkEcomVendor]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route
          element={<VendorCatalogCategoriesView baseUrl={baseUrl} />}
          path="/categories"
        />
        <Route
          element={
            <VendorCatalogCategoryView
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/categories/:categoryName"
        />
        <Route
          element={
            <VendorCatalogProductSortExplorer
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/products"
        />
        <Route
          element={
            <VendorCatalogProductSortExplorer
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/products/:sort"
        />
        <Route
          element={
            <VendorCatalogProductView
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/products/:ecomVendor/:urlHandle"
        />
        <Route
          element={
            <VendorCatalogVendorsView baseUrl={baseUrl} loading={loading} />
          }
          path="/vendors"
        />
        <Route
          element={
            <VendorCatalogVendorView
              baseUrl={baseUrl}
              vendorPreview={vendorPreview}
            />
          }
          path="/vendors/:ecomVendor"
        />
        <Route
          element={
            <VendorCatalogVendorView
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/vendors/:ecomVendor/collections/:collectionName"
        />
        <Route
          element={
            <VendorCatalogExplorer
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/"
        />
        <Route element={<Navigate replace to={baseUrl} />} path="*" />
      </Routes>
      <SelectedVendorProductsDialog />
      <VendorCatalogRemoveStoreProductsModal />
    </>
  );
};

VendorCatalogView.propTypes = { vendorPreview: PropTypes.bool };

export default VendorCatalogView;
