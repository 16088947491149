import React from 'react';
import PropTypes from 'prop-types';
import { ScrollArea } from '@mantine/core';
import AppStack from './AppStack';
import * as classes from '../../styles/animations.module.css';

const AnimatedBackgroundView = ({ children }) => (
  <AppStack
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      flex: 1,
      zIndex: 999
    }}
  >
    <AppStack
      className={classes['pulse-container']}
      style={{
        height: '100%',
        width: '100%',
        flex: 1,
        backgroundColor: '#1A1B1E',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <AppStack className={classes['pulse-circle1']} />
      <AppStack className={classes['pulse-circle2']} />

      <ScrollArea.Autosize
        style={{
          width: '100%',
          maxHeight: '100%'
        }}
      >
        <AppStack
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {children}
        </AppStack>
      </ScrollArea.Autosize>
    </AppStack>
  </AppStack>
);

AnimatedBackgroundView.propTypes = { children: PropTypes.any };

export default AnimatedBackgroundView;
