import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mantine/core';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import AppStack from './AppStack';
import AppCard from './AppCard';
import { useMediaQueryIndex } from '../../helpers/hooks';

const SettingCard = ({
  title,
  action,
  description,
  padding,
  children,
  loading,
  loadingHeight
}) => {
  const { isTabletOrSmaller } = useMediaQueryIndex();

  return !loading ? (
    <AppFlexbox style={{ gap: 10 }}>
      <AppStack style={{ flex: 1, gap: 10, maxWidth: 325 }} visibleFrom="md">
        <AppFlexbox
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <AppText style={{ fontSize: 17, fontWeight: 500 }}>{title}</AppText>

          {action}
        </AppFlexbox>
        <AppText style={{ fontSize: 14, color: '#666' }}>{description}</AppText>
      </AppStack>
      <AppStack style={{ flex: 1 }}>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="sm"
          style={{ padding: padding ?? 16 }}
          withBorder
        >
          <AppStack>{children}</AppStack>
        </AppCard>
      </AppStack>
    </AppFlexbox>
  ) : (
    <AppFlexbox style={{ gap: 10 }}>
      <AppStack style={{ flex: 1, gap: 10, maxWidth: 325 }} visibleFrom="md">
        <Skeleton height={24} width={200} />
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={12} width="100%" />
          <Skeleton height={12} width="100%" />
          <Skeleton height={12} width="100%" />
          <Skeleton height={12} width="100%" />
          <Skeleton height={12} width="65%" />
        </AppStack>
      </AppStack>
      <AppStack style={{ flex: 1 }}>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="sm"
          style={{ padding: padding ?? 16 }}
          withBorder
        >
          <AppStack>
            <Skeleton height={loadingHeight ?? 100} width="100%" />
          </AppStack>
        </AppCard>
      </AppStack>
    </AppFlexbox>
  );
};

SettingCard.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node,
  description: PropTypes.string,
  loading: PropTypes.bool,
  loadingHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string
};

export default SettingCard;
