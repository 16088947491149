/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useHover } from '@mantine/hooks';
import { ArrowRight, Shirt } from 'tabler-icons-react';
import { Image, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppFlexbox from '../../../../common/AppFlexbox';
import { sterilizeUrlHandle } from '../../../../../helpers/format';

const WsCollectionCard = ({
  ecomStoreCollection,
  portraitRatio,
  noCollection,
  onNavigate,
  baseStoreUrl,
  colorScheme
}) => {
  const { hovered, ref: hoverRef } = useHover();

  return (
    <AppStack
      ref={hoverRef}
      component={ecomStoreCollection ? Link : null}
      onClick={ecomStoreCollection ? onNavigate : null}
      style={{
        gap: 0,
        flex: 1,
        padding: 0,
        height: '100%',
        cursor: noCollection ? 'not-allowed' : 'pointer',
        textDecoration: 'none',
        color: colorScheme?.textColor ?? '#000'
      }}
      to={`${baseStoreUrl}/collections/${sterilizeUrlHandle(
        ecomStoreCollection?.name
      )}`}
    >
      <AppStack
        style={{
          border: !ecomStoreCollection?.previewImage
            ? 'solid 1px lightgrey'
            : 'none',
          position: 'relative',
          width: '100%',
          backgroundColor: '#f2f2f2',
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
          aspectRatio: portraitRatio ? '3 / 4' : '1 / 1'
        }}
      >
        {noCollection ? (
          <Shirt color="#444" size="65%" />
        ) : !ecomStoreCollection ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <>
            <Image
              fit="cover"
              h="100%"
              src={ecomStoreCollection.previewImage}
              style={{ opacity: 0 }}
              w="100%"
            />
            <Image
              fit="cover"
              h="100%"
              src={ecomStoreCollection.previewImage}
              style={{ position: 'absolute' }}
              w="100%"
            />
          </>
        )}
      </AppStack>
      <AppStack style={{ gap: 0 }}>
        {noCollection || ecomStoreCollection ? (
          <AppFlexbox
            style={{ padding: '10px 0px', gap: 5, alignItems: 'center' }}
          >
            <AppText
              style={{
                fontSize: 15,
                fontWeight: 500,
                textDecoration: hovered ? 'underline' : 'unset'
              }}
            >
              {noCollection ? 'Collection name' : ecomStoreCollection.name}
            </AppText>
            <AppStack>
              <ArrowRight size={20} />
            </AppStack>
          </AppFlexbox>
        ) : (
          <Skeleton height={22} style={{ margin: '10px 0px' }} width="40%" />
        )}
      </AppStack>
    </AppStack>
  );
};

WsCollectionCard.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorScheme: PropTypes.object,
  ecomStoreCollection: PropTypes.object,
  noCollection: PropTypes.bool,
  onNavigate: PropTypes.func,
  portraitRatio: PropTypes.bool
};

export default WsCollectionCard;
