/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';

const WsSubheadingBlock = ({ config }) => <AppText />;

WsSubheadingBlock.propTypes = { config: PropTypes.object };

export default WsSubheadingBlock;
