import React, { useContext } from 'react';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import SetupGuideView from '../../common/SetupGuideView';
import { setupGuideConfig } from './storeSetupGuideConfig';

const StoreDashboardOverview = () => {
  const { state: authState } = useContext(AuthContext);
  const { state } = useContext(StoreContext);
  const loading =
    !authState.pkEcomStore ||
    state.ecomStoreSetupSummary.loading ||
    state.ecomStoreSetupSummary.value?.ecomStore.pkEcomStore.toString() !==
      authState.pkEcomStore?.toString();

  return (
    <SetupGuideView
      id={`skippedEcomStoreSetupStepsString-${authState.pkEcomStore}`}
      loading={loading}
      setupGuideConfig={setupGuideConfig}
      state={state}
    />
  );
};

export default StoreDashboardOverview;
