import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Accordion } from '@mantine/core';
import SidebarNavButton from './SidebarNavButton';

const SidebarNavSections = ({ sections, state }) => {
  const { pathname } = useLocation();

  return sections.map((section) => {
    const isSelected = section.isSelected(pathname);
    const selectedSectionLinkIndex = section.links?.findIndex((link) =>
      link.isSelected(pathname)
    );

    return (
      <Accordion
        key={section.to}
        onChange={() => {}}
        style={{ border: 'none', padding: 0 }}
        styles={{
          label: { padding: 0 },
          chevron: { display: 'none' },
          control: { padding: 0 },
          content: { padding: 0 },
          item: { border: 'none' }
        }}
        value={isSelected || selectedSectionLinkIndex >= 0 ? section.to : null}
      >
        <Accordion.Item value={section.to}>
          <Accordion.Control>
            <SidebarNavButton
              key={section.to}
              color="#000"
              IconComponent={section.icon}
              innerSelected={selectedSectionLinkIndex >= 0}
              isSelected={isSelected}
              label={section.title}
              link={section.to}
              rightSection={
                section.getRightSection ? section.getRightSection(state) : null
              }
            />
          </Accordion.Control>
          {section.links && (
            <Accordion.Panel>
              {section.links.map((link, index) => (
                <Accordion
                  key={link.to}
                  onChange={() => {}}
                  style={{ border: 'none', padding: 0 }}
                  styles={{
                    label: { padding: 0 },
                    chevron: { display: 'none' },
                    control: { padding: 0 },
                    content: { padding: 0 },
                    item: { border: 'none' }
                  }}
                  value={null}
                >
                  <Accordion.Item value={section.to}>
                    <Accordion.Control>
                      <SidebarNavButton
                        IconComponent={link.icon}
                        innerSelected={index < selectedSectionLinkIndex}
                        isSelected={link.isSelected(pathname)}
                        label={link.title}
                        link={link.to}
                        rightSection={
                          link.getRightSection
                            ? link.getRightSection(state)
                            : null
                        }
                      />
                    </Accordion.Control>
                  </Accordion.Item>
                </Accordion>
              ))}
            </Accordion.Panel>
          )}
        </Accordion.Item>
      </Accordion>
    );
  });
};

SidebarNavSections.propTypes = { sections: PropTypes.array };

export default SidebarNavSections;
