import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Button, ScrollArea, Skeleton } from '@mantine/core';
import { ArrowLeft, BuildingStore, Plus } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppImage from '../../common/AppImage';
import sportsHeadzLogo from '../../../images/sportsheadz-logo.png';
import CustomNavLink from '../../common/CustomNavLink';
import { getInitialsFromName } from '../../../helpers/format';
import AppText from '../../common/AppText';
import AnimatedBackgroundView from '../../common/AnimatedBackgroundView';
import CreateStoreForm from './CreateStoreForm';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const StoreSwitchView = () => {
  const navigate = useNavigate();
  const { isMobileOrSmaller, isLargeMobileOrSmaller } = useMediaQueryIndex();
  const {
    state: authState,
    selectEcomStore,
    fetchAssociatedTeamsAndAssociations
  } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    showNewStoreForm: false,
    loading: false
  });

  useEffect(() => {
    fetchAssociatedTeamsAndAssociations();
  }, []);

  return (
    <AnimatedBackgroundView>
      <AppStack
        style={{
          flex: 1,
          alignItems: 'start',
          justifyContent: 'center',
          paddingBottom: isLargeMobileOrSmaller ? 0 : 60,
          paddingTop: isLargeMobileOrSmaller ? 0 : 10
        }}
      >
        <Button
          color="white"
          component={Link}
          leftSection={<ArrowLeft size={18} />}
          to={authState.pkEcomStore ? '/merchant/' : '/'}
          variant="outline"
          visibleFrom="xsm"
        >
          Back to store
        </Button>

        <AppCard
          flex={{ base: 1, xsm: 'unset' }}
          mah={{ base: '100vh', xsm: 900 }}
          maw={{ base: '100%', xsm: 475 }}
          mih={{ base: '100vh', xsm: 750 }}
          radius={isLargeMobileOrSmaller ? 0 : 'lg'}
          shadow="xl"
          style={{
            height: '100%',
            width: '100vw',
            padding: 0,
            overflow: 'auto'
          }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox
              style={{
                padding: 10,
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppFlexbox
                component={Link}
                style={{
                  alignItems: 'center',
                  textDecoration: 'none',
                  paddingLeft: 10,
                  gap: 5,
                  maxWidth: 240
                }}
                to="/"
              >
                <AppImage
                  alt="SportsHeadz Main Logo"
                  fit="contain"
                  height={80}
                  src={sportsHeadzLogo}
                  style={{ minWidth: 'unset', maxWidth: 125 }}
                  width="100%"
                />
              </AppFlexbox>
              <Avatar
                color="dark"
                radius="xl"
                size={50}
                src={authState.userData.user.avatar}
                style={{ backgroundColor: '#eca70a', marginRight: 10 }}
                styles={{ root: { border: 'solid 1px lightgrey' } }}
              >
                <AppText>
                  {getInitialsFromName(authState.userData.user.name)}
                </AppText>
              </Avatar>
            </AppFlexbox>
          </AppStack>

          {formState.showNewStoreForm ? (
            <CreateStoreForm
              onCancel={() =>
                setFormState({
                  ...formState,
                  showNewStoreForm: false
                })
              }
            />
          ) : (
            <>
              <AppFlexbox
                style={{
                  padding: '0px 20px',
                  justifyContent: 'space-between',
                  alignItems: isMobileOrSmaller ? 'stretch' : 'center',
                  flexDirection: isMobileOrSmaller ? 'column' : 'row'
                }}
              >
                <AppText style={{ fontWeight: 500, fontSize: 22 }}>
                  Hello, {authState.userData.user.name.split(' ')[0]}
                </AppText>
                {authState.ecomStores.length > 0 && (
                  <Button
                    color="dark"
                    leftSection={<Plus size={18} />}
                    onClick={() =>
                      setFormState({
                        ...formState,
                        showNewStoreForm: true
                      })
                    }
                    radius="sm"
                    size="compact-md"
                    variant="filled"
                  >
                    New store
                  </Button>
                )}
              </AppFlexbox>
              <AppStack
                style={{
                  padding: 20,
                  gap: 10,
                  height: '100%',
                  overflow: 'hidden'
                }}
              >
                {authState.loading ? (
                  <>
                    <AppFlexbox
                      style={{ padding: 8, gap: 10, alignItems: 'center' }}
                    >
                      <Skeleton height={38} radius={100} width={38} />
                      <Skeleton height={12} width={100} />
                    </AppFlexbox>
                    <AppFlexbox
                      style={{ padding: 8, gap: 10, alignItems: 'center' }}
                    >
                      <Skeleton height={38} radius={100} width={38} />
                      <Skeleton height={12} width={100} />
                    </AppFlexbox>
                  </>
                ) : authState.ecomStores.length === 0 ? (
                  <AppStack
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 20,
                      gap: 10
                    }}
                  >
                    <BuildingStore color="#000" size={100} />
                    <AppText style={{ fontWeight: 500 }}>
                      Get started by creating your first store
                    </AppText>
                    <Button
                      color="dark"
                      leftSection={<Plus size={18} />}
                      onClick={() =>
                        setFormState({
                          ...formState,
                          showNewStoreForm: true
                        })
                      }
                      radius="sm"
                      size="compact-md"
                      style={{ fontSize: 14 }}
                      type="button"
                      variant="filled"
                    >
                      Create store
                    </Button>
                  </AppStack>
                ) : (
                  <ScrollArea.Autosize
                    style={{ width: '100%', height: '100%', maxHeight: '100%' }}
                  >
                    {authState.ecomStores
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((s) => (
                        <CustomNavLink
                          key={s.pkEcomStore}
                          label={s.name}
                          leftSection={
                            <Avatar
                              color="dark"
                              radius="xl"
                              size="md"
                              src={s.logoImageUrl}
                              style={{ backgroundColor: '#eca70a' }}
                              styles={{root: { border: 'solid 1px lightgrey' }}}
                            >
                              <AppText>{getInitialsFromName(s.name)}</AppText>
                            </Avatar>
                          }
                          onClick={() => {
                            selectEcomStore(s.pkEcomStore);
                            navigate('/merchant/');
                          }}
                        />
                      ))}
                  </ScrollArea.Autosize>
                )}
              </AppStack>
            </>
          )}
        </AppCard>
      </AppStack>
    </AnimatedBackgroundView>
  );
};

export default StoreSwitchView;
