import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logout, Photo, Shirt } from 'tabler-icons-react';
import { Image } from '@mantine/core';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import BulkEditView from '../bulkEdit/BulkEditView';
import {
  BULK_EDIT_FIELD_TYPE_ENUM,
  ECOM_PRODUCT_STATUS_ENUM,
  WEIGHT_UNIT_LIST
} from '../../../config/constants';
import AppFlexbox from '../../common/AppFlexbox';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import { formatUtcDate } from '../../../helpers/format';
import {
  CANADA_PROVINCE_LIST,
  SHIPPING_REGION_LIST
} from '../../../config/locationConstants';
import { sortVariantsByOptions } from '../../../helpers/webstoreHelper';

const PRODUCT_DATA_FIELDS = {
  title: 'title',
  urlHandle: 'urlHandle',
  description: 'description',
  fkEcomProductStatus: 'fkEcomProductStatus',
  scheduleDate: 'scheduleDate',
  fkEcomCategory: 'fkEcomCategory',
  productType: 'productType',
  ecomVendorCollections: 'ecomVendorCollections'
};

const VARIANT__DATA_FIELDS = {
  price: 'price',
  compareAtPrice: 'compareAtPrice',
  costPerItem: 'costPerItem',
  trackQuantity: 'trackQuantity',
  continueSellingWhenOutOfStock: 'continueSellingWhenOutOfStock',
  quantity: 'quantity',
  sku: 'sku',
  barcode: 'barcode',
  requiresShipping: 'requiresShipping',
  weight: 'weight',
  weightUnit: 'weightUnit',
  originCountry: 'originCountry',
  originProvince: 'originProvince',
  harmonizedSystemCode: 'harmonizedSystemCode'
};

const PRODUCT_FIELDS = {
  title: 0,
  urlHandle: 1,
  description: 2,
  fkEcomProductStatus: 3,
  scheduleDate: 4,
  fkEcomCategory: 5,
  productType: 6,
  ecomVendorCollections: 7
};

const VARIANT_FIELDS = {
  price: 8,
  compareAtPrice: 9,
  costPerItem: 10,
  trackQuantity: 11,
  continueSellingWhenOutOfStock: 12,
  quantity: 13,
  sku: 14,
  barcode: 15,
  requiresShipping: 16,
  weight: 17,
  weightUnit: 18,
  originCountry: 19,
  originProvince: 20,
  harmonizedSystemCode: 21
};

const EDIT_VARIANT_FIELDS = [
  {
    value: 'price',
    label: 'Price',
    menuLabel: 'Base Price',
    dataField: VARIANT__DATA_FIELDS.price,
    type: BULK_EDIT_FIELD_TYPE_ENUM.PRICE,
    displayed: true,
    required: true,
    category: 'Variant',
    getErrorMessage: (rowColumns, subrowColumns = null) =>
      subrowColumns != null &&
      rowColumns[PRODUCT_FIELDS.fkEcomProductStatus].value ===
        ECOM_PRODUCT_STATUS_ENUM.ACTIVE &&
      subrowColumns[VARIANT_FIELDS.price].value === 0
        ? 'Price required for active products. Please enter a value or change the status to draft.'
        : null
  },
  {
    value: 'compareAtPrice',
    label: 'Compare-at price',
    dataField: VARIANT__DATA_FIELDS.compareAtPrice,
    type: BULK_EDIT_FIELD_TYPE_ENUM.PRICE,
    category: 'Variant'
  },
  {
    value: 'costPerItem',
    label: 'Cost per item',
    dataField: VARIANT__DATA_FIELDS.costPerItem,
    type: BULK_EDIT_FIELD_TYPE_ENUM.PRICE,
    category: 'Variant'
  },
  {
    value: 'trackQuantity',
    label: 'Track quantity',
    dataField: VARIANT__DATA_FIELDS.trackQuantity,
    type: BULK_EDIT_FIELD_TYPE_ENUM.CHECKBOX,
    category: 'Variant'
  },
  {
    value: 'continueSellingWhenOutOfStock',
    label: 'Continue selling when out of stock',
    dataField: VARIANT__DATA_FIELDS.continueSellingWhenOutOfStock,
    type: BULK_EDIT_FIELD_TYPE_ENUM.CHECKBOX,
    category: 'Variant',
    getDisabledMessage: (rowColumns, subrowColumns = null) =>
      subrowColumns != null &&
      subrowColumns[VARIANT_FIELDS.trackQuantity].value === false
        ? "Change 'Track quantity' to 'Yes' to set a value."
        : null
  },
  {
    value: 'quantity',
    label: 'Available quantity',
    dataField: VARIANT__DATA_FIELDS.quantity,
    type: BULK_EDIT_FIELD_TYPE_ENUM.NUMBER,
    min: 0,
    decimalScale: 0,
    displayed: true,
    category: 'Variant',
    getDisabledMessage: (rowColumns, subrowColumns = null) =>
      subrowColumns != null &&
      subrowColumns[VARIANT_FIELDS.trackQuantity].value === false
        ? "Change 'Track quantity' to 'Yes' to set a value."
        : null
  },
  {
    value: 'sku',
    label: 'SKU',
    displayed: true,
    dataField: VARIANT__DATA_FIELDS.sku,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    category: 'Variant'
  },
  {
    value: 'barcode',
    label: 'Barcode',
    dataField: VARIANT__DATA_FIELDS.barcode,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    category: 'Variant'
  },
  {
    value: 'requiresShipping',
    label: 'Physical product',
    dataField: VARIANT__DATA_FIELDS.requiresShipping,
    type: BULK_EDIT_FIELD_TYPE_ENUM.CHECKBOX,
    groupedField: 'weight',
    category: 'Variant'
  },
  {
    value: 'weight',
    label: 'Weight',
    menuLabel: 'Weight and shipping',
    dataField: VARIANT__DATA_FIELDS.weight,
    type: BULK_EDIT_FIELD_TYPE_ENUM.NUMBER,
    category: 'Variant',
    getDisabledMessage: (rowColumns, subrowColumns = null) =>
      subrowColumns != null &&
      subrowColumns[VARIANT_FIELDS.requiresShipping].value === false
        ? "Change 'Physical product' to 'Yes' to set a weight."
        : null
  },
  {
    value: 'weightUnit',
    label: 'Weight unit',
    dataField: VARIANT__DATA_FIELDS.weightUnit,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT,
    options: WEIGHT_UNIT_LIST,
    groupedField: 'weight',
    category: 'Variant',
    getDisabledMessage: (rowColumns, subrowColumns = null) =>
      subrowColumns != null &&
      subrowColumns[VARIANT_FIELDS.requiresShipping].value === false
        ? "Change 'Physical product' to 'Yes' to set a weight unit."
        : null
  },
  {
    value: 'originCountry',
    label: 'Country/Region of origin',
    dataField: VARIANT__DATA_FIELDS.originCountry,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT,
    options: SHIPPING_REGION_LIST.map((p) => ({
      value: p,
      label: p
    })),
    clearable: true,
    category: 'Variant'
  },
  {
    value: 'originProvince',
    label: 'Province of origin',
    dataField: VARIANT__DATA_FIELDS.originProvince,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT,
    options: CANADA_PROVINCE_LIST.map((p) => ({
      value: p,
      label: p
    })),
    clearable: true,
    groupedField: 'originCountry',
    category: 'Variant',
    getDisabledMessage: (
      rowColumns,
      subrowColumns = null,
      requiredValue = false
    ) =>
      subrowColumns != null &&
      (!requiredValue || subrowColumns[VARIANT_FIELDS.originCountry].value) &&
      subrowColumns[VARIANT_FIELDS.originCountry].value !== 'Canada'
        ? "Change 'Country/Region of origin' to 'Canada' to set a province."
        : null
  },
  {
    value: 'harmonizedSystemCode',
    label: 'Harmonized System Code',
    dataField: VARIANT__DATA_FIELDS.harmonizedSystemCode,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    category: 'Variant'
  }
];

const EDIT_PRODUCT_FIELDS = [
  {
    value: 'title',
    label: 'Title',
    dataField: PRODUCT_DATA_FIELDS.title,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    displayed: true,
    staticDisplay: true,
    category: 'Product',
    firstPosition: true,
    leftSectionWidth: 70,
    noBulkEdit: true,
    getLeftSection: (altValue) => (
      <AppFlexbox
        style={{
          width: 25,
          height: 25,
          borderRadius: 5,
          boxSizing: 'border-box',
          border: 'solid 1px #dee2e6 ',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {altValue ? (
          <Image src={altValue} style={{ width: '100%', height: '100%' }} />
        ) : (
          <Photo color="#dee2e6" size={14} />
        )}
      </AppFlexbox>
    )
  },
  {
    value: 'urlHandle',
    label: 'URL handle',
    noBulkEdit: true,
    dataField: PRODUCT_DATA_FIELDS.urlHandle,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    productOnly: true,
    category: 'Product'
  },
  {
    value: 'description',
    label: 'Description',
    dataField: PRODUCT_DATA_FIELDS.description,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    productOnly: true,
    category: 'Product'
  },
  {
    value: 'status',
    label: 'Status',
    dataField: PRODUCT_DATA_FIELDS.fkEcomProductStatus,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT,
    options: [
      { value: ECOM_PRODUCT_STATUS_ENUM.ACTIVE, label: 'Active' },
      { value: ECOM_PRODUCT_STATUS_ENUM.DRAFT, label: 'Draft' },
      { value: ECOM_PRODUCT_STATUS_ENUM.ARCHIVED, label: 'Archived' }
    ],
    productOnly: true,
    required: true,
    displayed: true,
    category: 'Product'
  },
  {
    value: 'scheduleDate',
    label: 'Schedule date',
    dataField: PRODUCT_DATA_FIELDS.scheduleDate,
    type: BULK_EDIT_FIELD_TYPE_ENUM.DATE_TIME,
    productOnly: true,
    category: 'Product'
  },
  {
    value: 'category',
    label: 'Category',
    dataField: PRODUCT_DATA_FIELDS.fkEcomCategory,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT,
    getOptions: (optionsState) => optionsState.ecomCategories,
    productOnly: true,
    displayed: true,
    clearable: true,
    category: 'Product',
    defaultValue: null
  },
  {
    value: 'productType',
    label: 'Sub category',
    dataField: PRODUCT_DATA_FIELDS.productType,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    productOnly: true,
    category: 'Product'
  },
  {
    value: 'collections',
    label: 'Collections',
    dataField: PRODUCT_DATA_FIELDS.ecomVendorCollections,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT_MULTIPLE,
    productOnly: true,
    category: 'Product',
    defaultValue: [],
    getOptions: (optionsState) => optionsState.ecomVendorCollections
  },
  ...EDIT_VARIANT_FIELDS
];

const mapVariantToForm = (variant, product = null) => ({
  key: variant.uuid.toString(),
  pkEcomVendorProductVariant: variant.pkEcomVendorProductVariant.toString(),
  uuid: variant.uuid,
  columns: [
    {
      key: PRODUCT_FIELDS.title,
      value: `${
        product ? `${product} - ` : ''
      }${variant.ecomVendorProductVariantOptions
        .sort((a, b) => a.nameSort - b.nameSort)
        .map((s) => s.value)
        .join(' / ')}`,
      altValue: variant.previewImage,
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.urlHandle,
      value: '',
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.description,
      value: '',
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.fkEcomProductStatus,
      value: '',
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.scheduleDate,
      value: '',
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.fkEcomCategory,
      value: '',
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.productType,
      value: '',
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.ecomVendorCollections,
      value: [],
      disabled: true
    },
    {
      key: VARIANT_FIELDS.price,
      value: variant.price / 100,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.compareAtPrice,
      value: variant.compareAtPrice / 100,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.costPerItem,
      value: variant.costPerItem / 100,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.trackQuantity,
      value: variant.trackQuantity,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.continueSellingWhenOutOfStock,
      value: variant.continueSellingWhenOutOfStock,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.quantity,
      value: variant.quantity ?? 0,
      min: 0,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.sku,
      value: variant.sku,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.barcode,
      value: variant.barcode,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.requiresShipping,
      value: variant.requiresShipping,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.weight,
      value: variant.weight,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.weightUnit,
      value: variant.weightUnit,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.originCountry,
      value: variant.originCountry,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.originProvince,
      value: variant.originProvince,
      disabled: false
    },
    {
      key: VARIANT_FIELDS.harmonizedSystemCode,
      value: variant.harmonizedSystemCode,
      disabled: false
    }
  ]
});

const mapProductToForm = (ecomVendorProduct, variantUuids = []) => ({
  key: ecomVendorProduct.pkEcomVendorProduct.toString(),
  pkEcomVendorProduct: ecomVendorProduct.pkEcomVendorProduct.toString(),
  uuid: ecomVendorProduct.uuid,
  columns: [
    {
      key: PRODUCT_FIELDS.title,
      value: ecomVendorProduct.name,
      altValue: ecomVendorProduct.previewImage
    },
    {
      key: PRODUCT_FIELDS.urlHandle,
      value: ecomVendorProduct.urlHandle
    },
    {
      key: PRODUCT_FIELDS.description,
      value: ecomVendorProduct.description
    },
    {
      key: PRODUCT_FIELDS.fkEcomProductStatus,
      value: ecomVendorProduct.fkEcomProductStatus.toString()
    },
    {
      key: PRODUCT_FIELDS.scheduleDate,
      value: ecomVendorProduct.scheduleDate
        ? new Date(formatUtcDate(ecomVendorProduct.scheduleDate))
        : null
    },
    {
      key: PRODUCT_FIELDS.fkEcomCategory,
      value: ecomVendorProduct.fkEcomCategory?.toString() ?? ''
    },
    {
      key: PRODUCT_FIELDS.productType,
      value: ecomVendorProduct.productType
    },
    {
      key: PRODUCT_FIELDS.ecomVendorCollections,
      value:
        ecomVendorProduct.ecomVendorCollections.map((c) =>
          c.pkEcomVendorCollection.toString()
        ) ?? []
    },
    ...EDIT_VARIANT_FIELDS.map((f) => ({
      key: f.value,
      value: '',
      disabled: true
    }))
  ],
  subrows: [
    ...sortVariantsByOptions(ecomVendorProduct.ecomVendorProductVariants)
      .filter((v) => !variantUuids.length || variantUuids.includes(v.uuid))
      .map((v) =>
        mapVariantToForm(v, variantUuids.length ? ecomVendorProduct.name : null)
      )
  ]
});

const BulkEditVendorProductsView = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchVendorProductExportData,
    updateVendorProducts
  } = useContext(VendorContext);
  const [formState, setFormState] = useState({ loading: false });
  const productUuids = new URLSearchParams(search).get('ids')?.split(',') ?? [];
  const variantUuids =
    new URLSearchParams(search).get('variants')?.split(',') ?? [];
  const fromLocation = new URLSearchParams(search).get('from');

  const selectedProducts = state.ecomVendorProductsExport.value.filter(
    (p) =>
      p.fkEcomVendor.toString() === authState.pkEcomVendor.toString() &&
      (productUuids.length === 0 || productUuids.includes(p.uuid))
  );
  const selectedVariants =
    variantUuids && variantUuids.length > 0
      ? selectedProducts
          .map((s) => s.ecomVendorProductVariants)
          .flat()
          .filter((v) => variantUuids.includes(v.uuid))
      : [];

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchVendorProductExportData(
        { fkEcomVendor: authState.pkEcomVendor, uuids: productUuids },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor]);

  const onSaveChanges = (data, onSuccess, onErrorCallback) => {
    setFormState({
      ...formState,
      loading: true
    });
    updateVendorProducts(
      authState.pkEcomVendor,
      {
        ecomVendorProducts: data
          .filter(
            (d) =>
              d.hasUnsavedChanges || d.subData.some((s) => s.hasUnsavedChanges)
          )
          .map((product) => ({
            uuid: product.uuid,
            ...(product.hasUnsavedChanges
              ? {
                  productType: product.productType,
                  fkEcomProductStatus: product.fkEcomProductStatus,
                  fkEcomCategory: product.fkEcomCategory
                    ? product.fkEcomCategory
                    : 0,
                  ecomVendorCollections: product.ecomVendorCollections,
                  ecomVendorProduct: {
                    name: product.name,
                    urlHandle: product.urlHandle,
                    description: product.description,
                    scheduleDate: product.scheduleDate
                  }
                }
              : {}),
            ecomVendorProductVariants: product.subData
              .filter((s) => s.hasUnsavedChanges)
              .map((variant) => ({
                ...variant,
                price: Math.round(variant.price * 100),
                compareAtPrice: Math.round(variant.compareAtPrice * 100),
                costPerItem: Math.round(variant.costPerItem * 100)
              }))
          }))
      },
      () => {
        onSuccess();
        triggerNotification('Changes saved!', 'success');
        setFormState({
          ...formState,
          loading: false
        });
      },
      (error) => {
        onErrorCallback();
        triggerNotification(error);
        setFormState({
          ...formState,
          loading: false
        });
      }
    );
  };

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'inventory':
        return '/vendor/inventory';
      case 'products':
      default: {
        return variantUuids.length > 0
          ? `/vendor/products/${productUuids[0]}`
          : '/vendor/products';
      }
    }
  };

  return (
    <BulkEditView
      data={selectedProducts
        .sort(
          (a, b) => a.name.localeCompare(b.name) || a.uuid.localeCompare(b.uuid)
        )
        .map((p) => mapProductToForm(p, variantUuids))}
      emptyContent={{
        title: 'No products found',
        description:
          "Oops looks like you haven't selected any products to edit. Try selecting products from your list of products and try again.",
        icon: <Shirt color="#000" size={125} />,
        primaryButton: {
          label: 'Back to products',
          icon: <Logout size={18} style={{ transform: 'rotate(180deg)' }} />,
          link: '/vendor/products'
        }
      }}
      fields={EDIT_PRODUCT_FIELDS.map((f) => ({
        ...f,
        displayed: f.productOnly && !!variantUuids.length ? false : f.displayed,
        hidden: f.productOnly && variantUuids.length > 0
      }))}
      loading={!hasFetched.current || state.ecomVendorProductsExport.loading}
      onCancel={() => navigate(getBackPath())}
      onlySubrows={variantUuids.length > 0}
      onSave={onSaveChanges}
      optionsState={{
        ecomVendorCollections: state.ecomVendorCollections.value
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c) => ({
            value: c.pkEcomVendorCollection.toString(),
            label: c.name
          })),
        ecomCategories: PRODUCT_CATEGORY_LIST.sort((a, b) =>
          a.name.localeCompare(b.name)
        ).map((c) => ({
          value: c.value.toString(),
          label: c.name
        }))
      }}
      saving={formState.loading}
      title={`Editing ${
        selectedVariants.length
          ? selectedVariants.length
          : selectedProducts.length
      } ${selectedVariants.length ? 'variants' : 'products'}`}
    />
  );
};

BulkEditVendorProductsView.propTypes = {};

export default BulkEditVendorProductsView;
