import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Divider, TextInput } from '@mantine/core';
import { Coin } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import { currencyFormat } from '../../../helpers/format';

const OnboardVendorPaymentProviderModal = ({
  ecomStore,
  ecomVendor,
  isOpen,
  onClose,
  onClaimEscrowPayments
}) => {
  const { onboardVendorPaymentProvider } = useContext(VendorContext);
  const { state, onboardStorePaymentProvider } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    accountId: '',
    loading: false
  });
  const existingAccountId = ecomVendor
    ? ecomVendor?.ecomVendorPaymentProvider?.accountId
    : ecomStore?.ecomStorePaymentProvider?.accountId;
  const isCompleted = ecomVendor
    ? ecomVendor?.ecomVendorPaymentProvider?.completed
    : ecomStore?.ecomStorePaymentProvider?.completed;

  const { escrowBalance } = state.ecomStoreSetupSummary.value || {};

  useEffect(() => {
    if (isOpen && (ecomVendor || ecomStore)) {
      setFormState({
        accountId: existingAccountId || '',
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        onCancel: onClose,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          if (ecomVendor) {
            onboardVendorPaymentProvider(
              {
                pkEcomVendor: ecomVendor.pkEcomVendor,
                accountId: formState.accountId
              },
              (session) => {
                window.location.href = session.sessionUrl;
              },
              (e) => {
                triggerNotification(e);
                setFormState({ ...formState, isLoading: false });
              }
            );
          }
          else if (ecomStore) {
            onboardStorePaymentProvider(
              {
                pkEcomStore: ecomStore.pkEcomStore,
                accountId: formState.accountId
              },
              (session) => {
                window.location.href = session.sessionUrl;
              },
              (e) => {
                triggerNotification(e);
                setFormState({ ...formState, isLoading: false });
              }
            );
          }
        },
        submitTitle: existingAccountId ? 'Update Stripe' : 'Onboard Stripe'
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Onboard Payment Provider"
    >
      <AppStack style={{ gap: 20 }}>
        <Alert color="blue" title="Onboard with Stripe!" variant="outline">
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 14 }}>
              You are about to onboard <b>Stripe</b> as a payment provider for{' '}
              <b>{ecomVendor?.name ?? ecomStore?.name}</b>.
            </AppText>
            <AppText style={{ fontSize: 14 }}>
              If you already have a <b>Stripe</b> account you can enter the ID
              for it below. If you do not have a <b>Stripe</b> account you can
              leave this field blank and we will create one for you.
            </AppText>
          </AppStack>
        </Alert>

        <TextInput
          disabled={formState.loading || !!existingAccountId}
          label="Existing Stripe account ID"
          onChange={(e) =>
            setFormState({
              ...formState,
              accountId: e.target.value
            })
          }
          value={formState.accountId}
        />

        {onClaimEscrowPayments && escrowBalance > 0 && (
          <AppCard
            style={{
              padding: 0,
              borderRadius: 8
            }}
            withBorder
          >
            <AppFlexbox
              style={{
                gap: 0,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AppStack style={{ padding: 16 }}>
                <Coin color="#067D62" size={60} />
              </AppStack>
              <Divider orientation="vertical" />
              <AppStack
                style={{
                  flex: 1,
                  padding: 16,
                  gap: 0
                }}
              >
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Claim your {currencyFormat(escrowBalance / 100)} escrow
                  balance.
                </AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Your store continues to generate revenue without onboarding a
                  payment provider. You can claim your escrow balance at any
                  time by onboarding a payment provider and transfering the
                  funds to your account.
                </AppText>
                <AppFlexbox style={{ marginTop: 10 }}>
                  {isCompleted ? (
                    <Button
                      color="blue"
                      onClick={onClaimEscrowPayments}
                      size="compact-md"
                      style={{ fontSize: 14 }}
                      variant="filled"
                    >
                      Claim {currencyFormat(escrowBalance / 100)}
                    </Button>
                  ) : (
                    existingAccountId && (
                      <AppText style={{ fontSize: 14, color: '#c40000' }}>
                        You must complete the onboarding process to claim your
                        escrow balance.
                      </AppText>
                    )
                  )}
                </AppFlexbox>
              </AppStack>
            </AppFlexbox>
          </AppCard>
        )}

        <AppText style={{ fontSize: 14, color: '#666', textAlign: 'center' }}>
          You will be redirected to <b>Stripe</b> to complete the onboarding
          process.
        </AppText>
      </AppStack>
    </ResponsiveModal>
  );
};

OnboardVendorPaymentProviderModal.propTypes = {
  ecomStore: PropTypes.object,
  ecomVendor: PropTypes.object,
  isOpen: PropTypes.bool,
  onClaimEscrowPayments: PropTypes.func,
  onClose: PropTypes.func
};

export default OnboardVendorPaymentProviderModal;
