import React, { useContext, useEffect, useState } from 'react';
import {
  Anchor,
  Divider,
  NumberFormatter,
  Select,
  Table,
  TextInput,
  Tooltip
} from '@mantine/core';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { InfoCircle, Plus } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import EditProductCard from './EditProductCard';
import AppCheckbox from '../../common/AppCheckbox';
import {
  CURRENCY_TYPE_LIST,
  WEIGHT_UNIT_LIST
} from '../../../config/constants';
import {
  CANADA_PROVINCE_LIST,
  SHIPPING_REGION_LIST
} from '../../../config/locationConstants';
import CustomNumericFormat from '../../common/CustomNumericFormat';
import CustomNumberInput from '../../common/CustomNumberInput';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';

const EditProductVariant = ({
  disabled,
  ecomVendorProductVariant,
  onChange
}) => {
  const { state: authState } = useContext(AuthContext);
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();
  const [errors, setErrors] = useState({});
  const isOrginCanada = ecomVendorProductVariant.originCountry === 'Canada';
  const showProfit =
    ecomVendorProductVariant.price != null &&
    ecomVendorProductVariant.costPerItem != null;

  const currencyInfo = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      authState.ecomVendors
        .find(
          (s) =>
            s.pkEcomVendor.toString() === authState.pkEcomVendor?.toString()
        )
        ?.fkCurrencyType.toString()
  );

  useEffect(() => {
    if (ecomVendorProductVariant.price) {
      setErrors({});
    }
  }, [ecomVendorProductVariant]);

  return (
    <AppFlexbox style={{ flex: 1 }}>
      <AppStack style={{ flex: 2 }}>
        <EditProductCard title="Pricing">
          <AppFlexbox>
            <CustomNumericFormat
              disabled={disabled}
              error={errors?.price}
              label="Price"
              onBlur={() => {
                setErrors({
                  ...errors,
                  price:
                    ecomVendorProductVariant.price !== 0 &&
                    !ecomVendorProductVariant.price
                      ? 'Price is required'
                      : null
                });
              }}
              onValueChange={(values) => {
                if (
                  values.floatValue * 100 !==
                  ecomVendorProductVariant.price
                ) {
                  onChange({
                    ...ecomVendorProductVariant,
                    price:
                      values.floatValue != null
                        ? Math.round(values.floatValue * 100)
                        : 0
                  });
                  setErrors({});
                }
              }}
              required={!!ecomVendorProductVariant.uuid}
              rightSection={
                <Tooltip
                  label={
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14 }}>
                        The price you sell your product to stores for.
                      </AppText>
                      {ecomVendorProductVariant.compareAtPrice != null &&
                        ecomVendorProductVariant.compareAtPrice >
                          ecomVendorProductVariant.price && (
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            (Markdown.{' '}
                            <s>
                              {' '}
                              <NumberFormatter
                                decimalScale={2}
                                fixedDecimalScale
                                prefix="$"
                                style={{ fontSize: 14, whiteSpace: 'nowrap' }}
                                suffix={` ${currencyInfo.abbr}`}
                                thousandSeparator
                                value={
                                  ecomVendorProductVariant.compareAtPrice / 100
                                }
                              />
                            </s>
                            )
                          </AppText>
                        )}
                    </AppStack>
                  }
                  multiline
                  position="bottom"
                  w={200}
                >
                  <AppStack>
                    <InfoCircle color="darkgrey" size={18} />
                  </AppStack>
                </Tooltip>
              }
              style={{ flex: 1 }}
              value={
                ecomVendorProductVariant.price != null
                  ? ecomVendorProductVariant.price / 100
                  : ''
              }
            />
            <CustomNumericFormat
              disabled={disabled}
              error={errors?.compareAtPrice}
              label="Compare-at price"
              onBlur={() => {
                setErrors({
                  ...errors,
                  compareAtPrice:
                    ecomVendorProductVariant.price !== null &&
                    ecomVendorProductVariant.compareAtPrice !== null &&
                    ecomVendorProductVariant.compareAtPrice <=
                      ecomVendorProductVariant.price
                      ? 'Compare-at price must be greater than price'
                      : null
                });
              }}
              onValueChange={(values) => {
                if (
                  values.floatValue * 100 !==
                  ecomVendorProductVariant.compareAtPrice
                ) {
                  onChange({
                    ...ecomVendorProductVariant,
                    compareAtPrice:
                      values.floatValue != null
                        ? Math.round(values.floatValue * 100)
                        : null
                  });
                }
              }}
              rightSection={
                <Tooltip
                  label={
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14 }}>
                        Enter a value greater than your price to display a
                        markdown.
                      </AppText>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        (e.g. <s>$14.99</s>)
                      </AppText>
                    </AppStack>
                  }
                  multiline
                  position="bottom"
                  w={200}
                >
                  <AppStack>
                    <InfoCircle color="darkgrey" size={18} />
                  </AppStack>
                </Tooltip>
              }
              style={{ flex: 1 }}
              value={
                ecomVendorProductVariant.compareAtPrice != null
                  ? ecomVendorProductVariant.compareAtPrice / 100
                  : ''
              }
            />
            <AppStack style={{ flex: 1 }} visibleFrom="xsm" />
          </AppFlexbox>

          <AppFlexbox
            style={{ flexDirection: isLargeMobileOrSmaller ? 'column' : 'row' }}
          >
            <CustomNumericFormat
              disabled={disabled}
              label="Cost per item"
              onValueChange={(values) => {
                if (
                  values.floatValue * 100 !==
                  ecomVendorProductVariant.costPerItem
                ) {
                  onChange({
                    ...ecomVendorProductVariant,
                    costPerItem:
                      values.floatValue != null
                        ? Math.round(values.floatValue * 100)
                        : null
                  });
                }
              }}
              rightSection={
                <Tooltip
                  label={
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14 }}>
                        The cost to supply or create your product.
                      </AppText>
                    </AppStack>
                  }
                  multiline
                  position="bottom"
                  w={200}
                >
                  <AppStack>
                    <InfoCircle color="darkgrey" size={18} />
                  </AppStack>
                </Tooltip>
              }
              style={{ flex: 1 }}
              value={
                ecomVendorProductVariant.costPerItem != null
                  ? ecomVendorProductVariant.costPerItem / 100
                  : ''
              }
            />
            <CustomNumericFormat
              disabled
              label="Profit"
              onValueChange={(values) => {}}
              placeholder="--"
              prefix="$"
              rightSection={
                <Tooltip
                  label={
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14 }}>
                        Price - Cost per item
                      </AppText>
                      {showProfit && (
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {ecomVendorProductVariant.price / 100} -{' '}
                          {ecomVendorProductVariant.costPerItem / 100}
                        </AppText>
                      )}
                    </AppStack>
                  }
                  multiline
                  position="bottom"
                  w={200}
                >
                  <AppStack>
                    <InfoCircle color="darkgrey" size={18} />
                  </AppStack>
                </Tooltip>
              }
              style={{ flex: 1 }}
              styles={{ input: { opacity: 0.8 } }}
              value={
                showProfit
                  ? (ecomVendorProductVariant.price -
                      ecomVendorProductVariant.costPerItem) /
                    100
                  : ''
              }
            />
            <NumericFormat
              customInput={TextInput}
              decimalScale={1}
              disabled
              fixedDecimalScale
              label="Margin"
              onValueChange={(values) => {}}
              placeholder="--"
              rightSection={
                <Tooltip
                  label={
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14 }}>
                        (Price - Cost per item) / Price
                      </AppText>
                      {showProfit && (
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          ({ecomVendorProductVariant.price / 100} -{' '}
                          {ecomVendorProductVariant.costPerItem / 100}) /{' '}
                          {ecomVendorProductVariant.price / 100}
                        </AppText>
                      )}
                    </AppStack>
                  }
                  multiline
                  position="bottom"
                  w={200}
                >
                  <AppStack>
                    <InfoCircle color="darkgrey" size={18} />
                  </AppStack>
                </Tooltip>
              }
              style={{ flex: 1 }}
              styles={{ input: { opacity: 0.8 } }}
              suffix="%"
              thousandSeparator
              value={
                showProfit
                  ? ((ecomVendorProductVariant.price -
                      ecomVendorProductVariant.costPerItem) /
                      ecomVendorProductVariant.price) *
                    100
                  : ''
              }
            />
          </AppFlexbox>
        </EditProductCard>
        <EditProductCard
          contentProps={{ style: { padding: 0 } }}
          title="Inventory"
        >
          <AppStack style={{ gap: 8 }}>
            <AppStack style={{ padding: 16, paddingTop: 0 }}>
              <AppFlexbox
                style={{
                  flex: 1,
                  flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
                }}
              >
                <TextInput
                  disabled={disabled}
                  label="SKU (Stock Keeping Unit)"
                  onChange={(e) => {
                    onChange({
                      ...ecomVendorProductVariant,
                      sku: e.currentTarget.value
                    });
                  }}
                  style={{ flex: 1 }}
                  value={ecomVendorProductVariant.sku ?? ''}
                />
                <TextInput
                  disabled={disabled}
                  label="Barcode (ISBN, UPC, GTIN, etc.)"
                  onChange={(e) => {
                    onChange({
                      ...ecomVendorProductVariant,
                      barcode: e.currentTarget.value
                    });
                  }}
                  style={{ flex: 1 }}
                  value={ecomVendorProductVariant.barcode ?? ''}
                />
              </AppFlexbox>
              <AppCheckbox
                checked={ecomVendorProductVariant.trackQuantity}
                disabled={disabled}
                label="Track quantity"
                onChange={() =>
                  onChange({
                    ...ecomVendorProductVariant,
                    trackQuantity: !ecomVendorProductVariant.trackQuantity
                  })
                }
              />

              {ecomVendorProductVariant.trackQuantity && (
                <AppCheckbox
                  checked={
                    ecomVendorProductVariant.continueSellingWhenOutOfStock
                  }
                  disabled={disabled}
                  label="Continue selling when out of stock"
                  onChange={() =>
                    onChange({
                      ...ecomVendorProductVariant,
                      continueSellingWhenOutOfStock: !ecomVendorProductVariant.continueSellingWhenOutOfStock
                    })
                  }
                />
              )}
            </AppStack>

            <Table horizontalSpacing={0} verticalSpacing={0}>
              <Table.Thead style={{ backgroundColor: 'rgba(56, 56, 56, 0.1)' }}>
                <Table.Tr>
                  <Table.Th style={{ width: '100%' }}>
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        padding: '8px 16px',
                        minHeight: 42
                      }}
                    >
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Location
                      </AppText>
                    </AppFlexbox>
                  </Table.Th>

                  <Table.Th style={{ minWidth: 100, width: 100 }}>
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        padding: '8px 16px',
                        justifyContent: 'end',
                        minHeight: 42
                      }}
                    >
                      <Tooltip
                        label={
                          <AppText style={{ fontSize: 14 }}>
                            The number of units available to sell.
                          </AppText>
                        }
                        multiline
                        position="bottom"
                        w={200}
                      >
                        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            Available
                          </AppText>
                          <InfoCircle color="#666" size={16} />
                        </AppFlexbox>
                      </Tooltip>
                    </AppFlexbox>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td style={{}}>
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        padding: 16
                      }}
                    >
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Online Store
                      </AppText>
                    </AppFlexbox>
                  </Table.Td>
                  <Table.Td style={{}}>
                    {ecomVendorProductVariant.trackQuantity ? (
                      <AppFlexbox
                        style={{
                          alignItems: 'center',
                          padding: '16px 8px'
                        }}
                      >
                        <CustomNumberInput
                          decimalScale={0}
                          disabled={disabled}
                          fixedDecimalScale
                          hideControls={disabled}
                          min={0}
                          onChange={(value) => {
                            onChange({
                              ...ecomVendorProductVariant,
                              quantity: value
                            });
                          }}
                          style={{ flex: 1 }}
                          value={ecomVendorProductVariant.quantity ?? 0}
                        />
                      </AppFlexbox>
                    ) : (
                      <AppFlexbox
                        style={{
                          alignItems: 'center',
                          justifyContent: 'end',
                          padding: 16
                        }}
                      >
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#666',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          Not Tracked
                        </AppText>
                      </AppFlexbox>
                    )}
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </AppStack>
        </EditProductCard>

        <EditProductCard
          contentProps={{ style: { padding: 0 } }}
          title="Shipping"
        >
          <AppStack style={{ padding: '0px 16px', paddingTop: 8 }}>
            <AppCheckbox
              checked={ecomVendorProductVariant.requiresShipping}
              disabled={disabled}
              label="This is a physical product that requires shipping"
              onChange={() =>
                onChange({
                  ...ecomVendorProductVariant,
                  requiresShipping: !ecomVendorProductVariant.requiresShipping
                })
              }
            />
          </AppStack>

          {ecomVendorProductVariant.requiresShipping ? (
            <>
              <AppStack style={{ padding: '0px 16px' }}>
                <AppFlexbox style={{ alignItems: 'end', gap: 5 }}>
                  <NumericFormat
                    allowNegative={false}
                    customInput={TextInput}
                    disabled={disabled}
                    label="Weight"
                    onFocus={(e) => e.target.select()}
                    onValueChange={(values) => {
                      onChange({
                        ...ecomVendorProductVariant,
                        weight: values.floatValue.toString().split('.')[1]
                          ? values.floatValue
                          : values.floatValue.toFixed(1)
                      });
                    }}
                    placeholder="0.0"
                    style={{ flex: 1, maxWidth: 100 }}
                    thousandSeparator
                    value={
                      ecomVendorProductVariant.weight
                        ? ecomVendorProductVariant.weight
                        : ''
                    }
                  />
                  <Select
                    data={WEIGHT_UNIT_LIST}
                    disabled={disabled}
                    onChange={(v) => {
                      onChange({
                        ...ecomVendorProductVariant,
                        weightUnit: v
                      });
                    }}
                    style={{ maxWidth: 80 }}
                    value={ecomVendorProductVariant.weightUnit ?? ''}
                  />
                </AppFlexbox>
              </AppStack>
              <Divider />
              {ecomVendorProductVariant.showCustomsInfo ||
              ecomVendorProductVariant.harmonizedSystemCode ||
              ecomVendorProductVariant.originCountry ? (
                <AppStack style={{ padding: 16, paddingTop: 0 }}>
                  <Select
                    clearable
                    data={SHIPPING_REGION_LIST.map((p) => ({
                      value: p,
                      label: p
                    }))}
                    disabled={disabled}
                    label="Country/Region of origin"
                    onChange={(v) => {
                      if (v !== ecomVendorProductVariant.originCountry) {
                        onChange({
                          ...ecomVendorProductVariant,
                          originCountry: v,
                          originProvince: null
                        });
                      }
                    }}
                    placeholder="Select a country"
                    searchable
                    value={ecomVendorProductVariant.originCountry ?? ''}
                  />
                  {isOrginCanada && (
                    <Select
                      clearable
                      data={CANADA_PROVINCE_LIST.map((p) => ({
                        value: p,
                        label: p
                      }))}
                      disabled={disabled}
                      label="Province of origin"
                      onChange={(v) => {
                        if (v !== ecomVendorProductVariant.originProvince) {
                          onChange({
                            ...ecomVendorProductVariant,
                            originProvince: v
                          });
                        }
                      }}
                      placeholder="Select a Province"
                      searchable
                      value={ecomVendorProductVariant.originProvince ?? ''}
                    />
                  )}
                  <TextInput
                    disabled={disabled}
                    label="Harmonized System Code (HS Code)"
                    onChange={(e) => {
                      onChange({
                        ...ecomVendorProductVariant,
                        harmonizedSystemCode: e.currentTarget.value
                      });
                    }}
                    placeholder="1234.56.78"
                    style={{ flex: 1 }}
                    value={ecomVendorProductVariant.harmonizedSystemCode ?? ''}
                  />
                </AppStack>
              ) : (
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    gap: 5,
                    padding: 16,
                    paddingTop: 0
                  }}
                >
                  <Plus color="dodgerblue" size={18} />
                  <Anchor
                    onClick={() => {
                      if (!disabled) {
                        onChange({
                          ...ecomVendorProductVariant,
                          showCustomsInfo: true
                        });
                      }
                    }}
                    style={{ color: 'dodgerblue', fontSize: 14 }}
                  >
                    Add customs information
                  </Anchor>
                </AppFlexbox>
              )}
            </>
          ) : (
            <>
              <Divider />
              <AppStack style={{ padding: 16, paddingTop: 0 }}>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Shipping details will not be collected at checkout.
                </AppText>
              </AppStack>
            </>
          )}
        </EditProductCard>
      </AppStack>
    </AppFlexbox>
  );
};

EditProductVariant.propTypes = {
  ecomVendorProductVariant: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default EditProductVariant;
