import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';
import { Button, TextInput, Textarea } from '@mantine/core';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppCard from '../../common/AppCard';
import { usePrompt } from '../../../helpers/prompt';
import { triggerNotification } from '../../../helpers/notification';
import ConfirmModal from '../../common/ConfirmModal';
import { useModalState } from '../../../helpers/hooks';

const MENU_ACTIONS = { DELETE: 'delete' };

const WebstorePageEditView = () => {
  const hasInitialized = useRef(false);
  const navigate = useNavigate();
  const { pkEcomStorePage } = useParams();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    createStorePage,
    updateStorePage,
    deleteStorePage
  } = useContext(StoreContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [formState, setFormState] = useState({
    title: '',
    content: '',
    loading: false,
    hasUnsavedChanges: false
  });
  const ecomStorePage = state.ecomStorePages.value.find(
    (m) =>
      m.pkEcomStorePage.toString() === pkEcomStorePage &&
      m.fkEcomStore.toString() === authState.pkEcomStore.toString()
  );

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    formState.hasUnsavedChanges && !formState.loading
  );

  useEffect(() => {
    setFormState({
      title: ecomStorePage?.title ?? '',
      content: ecomStorePage?.content ?? '',
      loading: false,
      hasUnsavedChanges: false
    });
    hasInitialized.current = true;
  }, [ecomStorePage]);

  return (
    hasInitialized.current && (
      <>
        <AppStack
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setFormState({ ...formState, loading: true });

            if (ecomStorePage) {
              updateStorePage(
                authState.pkEcomStore,
                formState,
                () => {
                  triggerNotification('Page updated!', 'success');
                },
                (error) => {
                  triggerNotification(error);
                  setFormState({ ...formState, loading: false });
                }
              );
            }
            else {
              createStorePage(
                authState.pkEcomStore,
                formState,
                (data) => {
                  navigate(`/merchant/webstore/pages/${data.pkEcomStorePage}`);
                  triggerNotification('Page updated!', 'success');
                },
                (error) => {
                  triggerNotification(error);
                  setFormState({ ...formState, loading: false });
                }
              );
            }
          }}
        >
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <ActionableIcon
              color="dark"
              onClick={() => navigate('/merchant/webstore/pages')}
              radius="md"
              variant="subtle"
            >
              <ArrowLeft />
            </ActionableIcon>
            <AppText style={{ fontSize: 18, fontWeight: 700 }}>
              {ecomStorePage?.title ?? 'Add page'}
            </AppText>
          </AppFlexbox>

          <AppCard radius="md" shadow="xs" style={{ padding: 16 }} withBorder>
            <AppStack>
              <TextInput
                disabled={formState.loading}
                label="Title"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    title: e.currentTarget.value,
                    hasUnsavedChanges: true
                  })
                }
                required
                value={formState.title}
              />

              <Textarea
                autosize
                label="Content"
                minRows={8}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    content: e.currentTarget.value,
                    hasUnsavedChanges: true
                  })
                }
                value={formState.content}
              />
            </AppStack>
          </AppCard>

          <AppFlexbox
            style={{ justifyContent: 'flex-end', marginTop: 10, gap: 10 }}
          >
            <Button
              color="dark"
              disabled={formState.loading}
              onClick={() => navigate('/merchant/webstore/pages')}
              radius="md"
              size="compact-sm"
              style={{ border: '1px solid #000' }}
              type="button"
              variant="white"
            >
              Cancel
            </Button>
            {ecomStorePage && (
              <Button
                color="red"
                disabled={formState.loading}
                onClick={() => {
                  onOpenModal(MENU_ACTIONS.DELETE, ecomStorePage);
                }}
                radius="md"
                size="compact-sm"
                type="button"
                variant="filled"
              >
                Delete page
              </Button>
            )}
            <Button
              color="blue"
              disabled={!formState.hasUnsavedChanges}
              id="product-save-button"
              loading={formState.loading}
              radius="md"
              size="compact-sm"
              type="submit"
              variant="filled"
            >
              Save
            </Button>
          </AppFlexbox>
        </AppStack>
        <ConfirmModal
          confirmActionColor="red"
          confirmActionText="Yes, delete page"
          isLoading={modalState.loading}
          isOpen={
            modalState.isOpen && modalState.action === MENU_ACTIONS.DELETE
          }
          message={
            <AppStack style={{ gap: 10 }}>
              <AppText>
                Are you sure you want to delete the page{' '}
                <b>{modalState.item?.title}</b>? This action can’t be undone.
              </AppText>
            </AppStack>
          }
          onCancel={onCloseModal}
          onConfirm={() => {
            onChangeModalLoading(true);
            deleteStorePage(
              modalState.item.pkEcomStorePage,
              () => {
                navigate('/merchant/webstore/pages');
              },
              (error) => {
                triggerNotification(error);
                onChangeModalLoading(false);
              }
            );
          }}
          title={`Delete ${modalState.item?.title}?`}
        />
      </>
    )
  );
};

WebstorePageEditView.propTypes = {};

export default WebstorePageEditView;
