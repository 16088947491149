import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Alert, Button, Loader } from '@mantine/core';
import { Link } from 'react-router-dom';
import { AlertCircle } from 'tabler-icons-react';
import AppText from '../../common/AppText';
import ConfirmModal from '../../common/ConfirmModal';
import { setupGuideConfig } from './vendorSetupGuideConfig';
import { Context as VendorContent } from '../../../providers/VendorContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';

const LaunchVendorModal = ({ onConfirm, isOpen, onClose }) => {
  const { state } = useContext(VendorContent);
  const { toggleSetupGuideSidebar } = useContext(HelperContext);
  const [activeStep, setActiveStep] = useState(undefined);
  const steps = setupGuideConfig.sections
    .flatMap((s) => s.steps)
    .filter((s) => s.required && !s.isCompleted(state));
  const isLoading = state.ecomVendorSetupSummary.loading;

  useEffect(() => {
    if (!isLoading && isOpen) {
      setActiveStep(steps[0]?.value);
    }
  }, [isOpen, isLoading]);

  return (
    <ConfirmModal
      confirmActionColor="blue"
      confirmActionText="Yes, Launch vendor"
      isOpen={isOpen}
      message=""
      onCancel={onClose}
      onConfirm={onConfirm}
      submitDisabled={steps.length > 0}
      title="Launch vendor?"
    >
      {isLoading ? (
        <AppStack
          style={{
            padding: 40,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loader color="dark" size={40} />
        </AppStack>
      ) : steps.length === 0 ? (
        <AppText style={{ fontSize: 14 }}>
          Are you sure you want to <b>launch</b> this vendor? This will make it{' '}
          <b>public for merchants</b> to view and add products to their store.
        </AppText>
      ) : (
        <AppStack>
          <Alert color="red" icon={<AlertCircle size={20} />} variant="outline">
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              You must complete the starting guide before you are able to launch
              this vendor.
            </AppText>
          </Alert>

          <AppStack style={{ gap: 5 }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Setup your vendor
            </AppText>
          </AppStack>
          <Accordion
            onChange={setActiveStep}
            styles={{
              control: { padding: 8 },
              label: { padding: 0 },
              content: { padding: 0, borderRadius: 30 },
              chevron: { display: 'none' }
            }}
            value={activeStep}
            variant="filled"
          >
            {steps.map((step) => (
              <Accordion.Item
                key={step.value}
                style={{
                  backgroundColor:
                    step.value === activeStep
                      ? 'rgba(245, 245, 245, 1)'
                      : 'unset'
                }}
                value={step.value}
                variant="filled"
              >
                <Accordion.Control>
                  <AppFlexbox
                    style={{
                      gap: 10,
                      alignItems: 'center'
                    }}
                  >
                    <AppStack
                      style={{
                        height: 20,
                        width: 20,
                        borderRadius: 100,
                        border: 'dashed 2px #999'
                      }}
                    />

                    <AppText
                      style={{
                        fontWeight: 500,
                        color: '#333',
                        fontSize: 14
                      }}
                    >
                      {step.title}
                    </AppText>
                  </AppFlexbox>
                </Accordion.Control>
                <Accordion.Panel>
                  <AppFlexbox style={{ padding: 8, paddingTop: 0, gap: 10 }}>
                    <AppStack
                      style={{
                        height: 20,
                        width: 20
                      }}
                    />
                    <AppStack style={{ gap: 0, flex: 1 }}>
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        {step.description}
                      </AppText>
                      {step.subDescription && (
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          {step.subDescription}
                        </AppText>
                      )}
                      <AppFlexbox style={{ marginTop: 10 }}>
                        {step.primaryButton && (
                          <Button
                            color="dark"
                            component={Link}
                            leftSection={
                              step.primaryButton.icon && (
                                <step.primaryButton.icon size={18} />
                              )
                            }
                            onClick={() => {
                              toggleSetupGuideSidebar(true, step.value);
                              onClose();
                            }}
                            radius="md"
                            size="compact-sm"
                            to={step.primaryButton.url}
                          >
                            {step.primaryButton.title}
                          </Button>
                        )}
                        {step.secondaryButton && (
                          <Button
                            color="dark"
                            component={Link}
                            leftSection={
                              step.secondaryButton.icon && (
                                <step.primaryButton.icon size={18} />
                              )
                            }
                            onClick={() => {
                              toggleSetupGuideSidebar(true, step.value);
                              onClose();
                            }}
                            radius="md"
                            size="compact-sm"
                            to={step.secondaryButton.url}
                            variant="outline"
                          >
                            {step.secondaryButton.title}
                          </Button>
                        )}
                      </AppFlexbox>
                    </AppStack>
                  </AppFlexbox>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </AppStack>
      )}
    </ConfirmModal>
  );
};

LaunchVendorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default LaunchVendorModal;
