import React from 'react';
import PropTypes from 'prop-types';
import { ColorPicker, TextInput } from '@mantine/core';
import AppStack from './AppStack';
import AppMenu from './AppMenu';

const CustomColorInput = ({ control, label, onChange, value }) => (
  <AppMenu control={control} position="bottom-start" width="unset">
    <AppStack style={{ padding: 8, gap: 8 }}>
      <ColorPicker onChange={onChange} value={value} />

      <TextInput
        error={
          !value || value.length === 4 || value.length === 7
            ? null
            : 'Please enter a valid hex code'
        }
        label={label}
        onChange={(e) =>
          onChange(
            `#${e.currentTarget.value
              .replace(/[^0-9A-Fa-f]/g, '')
              .substring(0, 6)}`
          )
        }
        value={value?.replace('#', '') ?? ''}
      />
    </AppStack>
  </AppMenu>
);

CustomColorInput.propTypes = {
  control: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default CustomColorInput;
