/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import { generateColorVariants } from '../../../../../helpers/styles';

const WsVendorTitleBlock = ({ config, ecomStoreProduct, colorScheme }) =>
  ecomStoreProduct && (
    <AppFlexbox>
      <AppStack>
        <AppText
          style={{
            fontSize: config.fontSize * 1,
            fontStyle: config.fontStyle,
            fontWeight: config.fontWeight,
            textTransform: config.textTransform,
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            ...(colorScheme && {color: generateColorVariants(colorScheme.textColor, 100).lighter})
          }}
        >
          {ecomStoreProduct.ecomVendorProduct.vendorName}
        </AppText>
      </AppStack>
    </AppFlexbox>
  );

WsVendorTitleBlock.propTypes = {
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  ecomStoreProduct: PropTypes.object
};

export default WsVendorTitleBlock;
