/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  MeasuringStrategy,
  defaultDropAnimation
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import PropTypes from 'prop-types';
import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  removeChildrenOf,
  removeItem,
  setProperty
} from '../../helpers/sortableTreeHelper';
import SortableTreeItem from './SortableTreeItem';
import ConfirmModal from './ConfirmModal';
import { useModalState } from '../../helpers/hooks';
import AppStack from './AppStack';
import AppText from './AppText';
import { singularPluralFormat } from '../../helpers/format';

const measuring = { droppable: { strategy: MeasuringStrategy.Always } };

const dropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5
};

const adjustTranslate = ({ transform }) => ({
  ...transform,
  y: transform.y - 25
});

const SortableTree = ({
  collapsible,
  indicator,
  indentationWidth = 30,
  items,
  setItems,
  onEditItem,
  disabled
}) => {
  const [activeId, setActiveId] = useState(null);
  const [overId, setOverId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(null);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, items]);
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;
  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft
  });
  const sensors = useSensors(
    useSensor(PointerSensor)
    // useSensor(KeyboardSensor, {
    //   coordinateGetter,
    // })
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
    flattenedItems
  ]);
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;
  const deleteItemChildCount = modalState.item
    ? getChildCount(items, modalState.item.id)
    : 0;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft
    };
  }, [flattenedItems, offsetLeft]);

  const announcements = {
    onDragStart(id) {
      return `Picked up ${id}.`;
    },
    onDragMove(id, overId) {
      return getMovementAnnouncement('onDragMove', id, overId);
    },
    onDragOver(id, overId) {
      return getMovementAnnouncement('onDragOver', id, overId);
    },
    onDragEnd(id, overId) {
      return getMovementAnnouncement('onDragEnd', id, overId);
    },
    onDragCancel(id) {
      return `Moving was cancelled. ${id} was dropped in its original position.`;
    }
  };

  return (
    <>
      <DndContext
        announcements={announcements}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <SortableContext
          items={sortedIds}
          strategy={verticalListSortingStrategy}
        >
          {flattenedItems.map(
            ({ id, title, children, collapsed, removeable, depth }) => (
              <SortableTreeItem
                key={id}
                childrenElements={children}
                collapsed={Boolean(collapsed && children.length)}
                depth={id === activeId && projected ? projected.depth : depth}
                disabled={disabled}
                id={id}
                indentationWidth={indentationWidth}
                indicator={indicator}
                onCollapse={
                  collapsible && children.length
                    ? () => handleCollapse(id)
                    : undefined
                }
                onEdit={onEditItem ? () => onEditItem(id) : undefined}
                onRemove={removeable ? () => handleRemove(id) : undefined}
                value={title}
              />
            )
          )}
          {createPortal(
            <DragOverlay
              dropAnimation={dropAnimation}
              modifiers={indicator ? [adjustTranslate] : undefined}
            >
              {activeId && activeItem ? (
                <SortableTreeItem
                  childCount={getChildCount(items, activeId) + 1}
                  childrenElements={activeItem.children}
                  clone
                  depth={activeItem.depth}
                  id={activeId}
                  indentationWidth={indentationWidth}
                  value={activeItem?.title}
                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </SortableContext>
      </DndContext>
      <ConfirmModal
        confirmActionColor="red"
        confirmActionText={`Yes, remove ${
          deleteItemChildCount === 0 ? 'item' : 'items'
        }`}
        isLoading={modalState.loading}
        isOpen={modalState.isOpen}
        message={
          modalState.item && (
            <AppStack style={{ gap: 10 }}>
              <AppText>
                This will remove the <b>{modalState.item.title}</b> menu item
                {deleteItemChildCount > 0
                  ? ` and ${singularPluralFormat(
                      deleteItemChildCount,
                      'menu item',
                      'menu items'
                    )} under it`
                  : ''}
                .
              </AppText>
            </AppStack>
          )
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          setItems(
            setProperty(items, modalState.item.id, 'deleted', (value) => !value)
          );
          onCloseModal();
        }}
        title={`Remove ${
          deleteItemChildCount === 0
            ? modalState.item?.title
            : `${deleteItemChildCount + 1} menu items`
        }?`}
      />
    </>
  );

  function handleDragStart({ active: { id: activeId } }) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ delta }) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      setItems(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function handleRemove(removeId) {
    onOpenModal(
      'remove',
      flattenedItems.find(({ id }) => id === removeId)
    );
  }

  function handleCollapse(id) {
    setItems(setProperty(items, id, 'collapsed', (value) => !value));
  }

  function getMovementAnnouncement(eventName, activeId, overId) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        }
        setCurrentPosition({
          parentId: projected.parentId,
          overId
        });
      }

      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      }
      else if (projected.depth > previousItem.depth) {
        announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
      }
      else {
        let previousSibling = previousItem;
        while (previousSibling && projected.depth < previousSibling.depth) {
          const { parentId } = previousSibling;
          previousSibling = sortedItems.find(({ id }) => id === parentId);
        }

        if (previousSibling) {
          announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
        }
      }
      return announcement;
    }
  }
};

SortableTree.propTypes = {
  collapsible: PropTypes.bool,
  disabled: PropTypes.bool,
  indentationWidth: PropTypes.number,
  indicator: PropTypes.bool,
  items: PropTypes.array,
  onEditItem: PropTypes.func,
  onHandleCollapse: PropTypes.func,
  onRemoveItem: PropTypes.func,
  setItems: PropTypes.func
};

export default SortableTree;
