import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  Image,
  Loader,
  Select,
  TextInput
} from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import { getEcomStoreProductStatus } from '../../../helpers/vendorHelper';

const StoreCollectionProductAssignModal = ({
  isOpen,
  onClose,
  onConfirm,
  pkEcomStoreProducts
}) => {
  const navigate = useNavigate();
  const { state } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    pkEcomStoreProducts,
    productSearch: '',
    category: '',
    fkEcomVendor: ''
  });

  const sortingOptions = state.ecomStoreProducts.value.reduce(
    (r, c) => {
      if (
        c.ecomVendorProduct.fkEcomCategory &&
        !r.categories.find(
          (e) => e.value === c.ecomVendorProduct.fkEcomCategory.toString()
        )
      ) {
        const category = PRODUCT_CATEGORY_LIST.find(
          (e) =>
            e.value.toString() === c.ecomVendorProduct.fkEcomCategory.toString()
        );
        if (category) {
          r.categories.push({
            label: category.name,
            value: c.ecomVendorProduct.fkEcomCategory.toString()
          });
        }
      }

      if (
        c.ecomVendorProduct.fkEcomVendor &&
        !r.vendors.find(
          (e) => e.value === c.ecomVendorProduct.fkEcomVendor.toString()
        )
      ) {
        r.vendors.push({
          label: c.ecomVendorProduct.vendorName,
          value: c.ecomVendorProduct.fkEcomVendor.toString()
        });
      }

      return r;
    },
    {
      categories: [
        {
          label: 'Fundraising',
          value: 'fundraising'
        }
      ],
      vendors: []
    }
  );
  const filteredProducts = state.ecomStoreProducts.value.filter(
    (e) =>
      (!formState.productSearch ||
        e.ecomVendorProduct.name
          .toLowerCase()
          .includes(formState.productSearch.toLowerCase()) ||
        e.ecomVendorProduct.description
          .toLowerCase()
          .includes(formState.productSearch.toLowerCase())) &&
      (!formState.fkEcomVendor ||
        formState.fkEcomVendor ===
          e.ecomVendorProduct.fkEcomVendor.toString()) &&
      (!formState.category ||
        (formState.category === 'fundraiser'
          ? e.ecomVendorProduct.fundraisingRecommended
          : e.ecomVendorProduct.fkEcomCategory?.toString() ===
            formState.category))
  );

  const isAllSelected = filteredProducts.every((p) =>
    formState.pkEcomStoreProducts.includes(p.pkEcomStoreProduct)
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        pkEcomStoreProducts: pkEcomStoreProducts ?? [],
        productSearch: '',
        category: ''
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: () => {
          onConfirm(formState.pkEcomStoreProducts);
        },
        padding: 0,
        submitTitle: 'Confirm'
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={600}
      title="Edit products in collection"
    >
      <AppStack
        style={{
          gap: 0,
          minHeight: 400,
          overflow: 'auto',
          flex: 1
        }}
      >
        <AppStack style={{ padding: 5, gap: 5 }}>
          <TextInput
            onChange={(e) =>
              setFormState({
                ...formState,
                productSearch: e.currentTarget.value
              })
            }
            placeholder="Filter by title..."
            style={{ flex: 1 }}
            value={formState.productSearch}
          />
          <Select
            clearable
            data={sortingOptions.vendors.sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
            onChange={(v) =>
              setFormState({
                ...formState,
                fkEcomVendor: v
              })
            }
            placeholder="Filter by vendor..."
            searchable
            style={{ flex: 1 }}
            value={formState.fkEcomVendor}
          />
          <Select
            clearable
            data={sortingOptions.categories.sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
            onChange={(v) =>
              setFormState({
                ...formState,
                category: v
              })
            }
            placeholder="Filter by category..."
            searchable
            style={{ flex: 1 }}
            value={formState.category}
          />
        </AppStack>
        <Divider />
        <AppFlexbox
          style={{
            gap: 5,
            padding: 8,
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#f3f3f3'
          }}
        >
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>Products</AppText>

          <Checkbox
            checked={isAllSelected}
            label="Select all"
            onChange={() => {
              setFormState({
                ...formState,
                pkEcomStoreProducts: isAllSelected
                  ? formState.pkEcomStoreProducts.filter(
                      (e) =>
                        !filteredProducts
                          .map((p) => p.pkEcomStoreProduct)
                          .includes(e)
                    )
                  : [
                      ...formState.pkEcomStoreProducts,
                      ...filteredProducts
                        .filter(
                          (p) =>
                            !formState.pkEcomStoreProducts.includes(
                              p.pkEcomStoreProduct
                            )
                        )
                        .map((p) => p.pkEcomStoreProduct)
                    ]
              });
            }}
            styles={{
              label: { fontWeight: 500, cursor: 'pointer' },
              input: { cursor: 'pointer' }
            }}
          />
        </AppFlexbox>
        <Divider />
        <AppStack
          style={{
            gap: 0,
            flex: 1
          }}
        >
          {state.ecomStoreProducts.loading ? (
            <AppStack
              style={{
                gap: 5,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 10px',
                flex: 1
              }}
            >
              <Loader color="dark" size={34} />
            </AppStack>
          ) : filteredProducts.length === 0 ? (
            <AppStack
              style={{
                gap: 5,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 10px'
              }}
            >
              <AppStack style={{ padding: 16, fontWeight: 500, color: '#666' }}>
                No products found.
              </AppStack>
              <Button
                color="dark"
                leftSection={<Plus size={18} />}
                onClick={() => {
                  navigate('/merchant/products/discover');
                }}
                type="button"
                variant="outline"
              >
                Add a new product
              </Button>
            </AppStack>
          ) : (
            filteredProducts
              .sort((a, b) =>
                a.ecomVendorProduct.name.localeCompare(b.ecomVendorProduct.name)
              )
              .map((p) => {
                const productStatusInfo = getEcomStoreProductStatus(p);

                return (
                  <AppFlexbox
                    key={p.pkEcomStoreProduct}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        pkEcomStoreProducts: formState.pkEcomStoreProducts.includes(
                          p.pkEcomStoreProduct
                        )
                          ? formState.pkEcomStoreProducts.filter(
                              (e) => e !== p.pkEcomStoreProduct
                            )
                          : [
                              ...formState.pkEcomStoreProducts,
                              p.pkEcomStoreProduct
                            ]
                      });
                    }}
                    style={{
                      padding: '10px 16px',
                      gap: 16,
                      alignItems: 'center',
                      borderBottom: 'solid 1px #dee2e6',
                      cursor: 'pointer'
                    }}
                  >
                    <Checkbox
                      checked={formState.pkEcomStoreProducts.includes(
                        p.pkEcomStoreProduct
                      )}
                      onChange={() => {}}
                    />
                    <AppStack
                      style={{
                        width: 40,
                        height: 40,
                        border: 'solid 1px #dee2e6'
                      }}
                    >
                      <Image
                        h={40}
                        src={p.ecomVendorProduct.previewImage}
                        w={40}
                      />
                    </AppStack>
                    <AppStack style={{ fles: 1, gap: 0 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {p.ecomVendorProduct.name}
                      </AppText>
                      <AppText style={{ fontSize: 12, color: '#666' }}>
                        {p.ecomVendorProduct.vendorName}
                      </AppText>
                      <AppText
                        style={{ fontSize: 12, color: productStatusInfo.color }}
                      >
                        {productStatusInfo.label}
                      </AppText>
                    </AppStack>
                  </AppFlexbox>
                );
              })
          )}
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

StoreCollectionProductAssignModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  pkEcomStoreProducts: PropTypes.array
};

export default StoreCollectionProductAssignModal;
