import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@mantine/carousel';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import * as classes from '../../../styles/animations.module.css';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const VendorCatalogCarousel = ({ items, loading }) => {
  const {
    isMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isDesktopOrSmaller
  } = useMediaQueryIndex();
  const [embla, setEmbla] = useState(null);
  const [scrollState, setScrollState] = useState({
    currentSlide: 1,
    hasScrolled: false
  });
  const slidesToScroll = isMobileOrSmaller
    ? 1
    : isTabletOrSmaller
    ? 2
    : isLaptopOrSmaller
    ? 3
    : isDesktopOrSmaller
    ? 4
    : 5;

  useEffect(() => {
    if (scrollState.hasScrolled) {
      embla.scrollTo(2, true);
      // eslint-disable-next-line no-use-before-define
      onScrollNext(true);
    }
  }, [scrollState.hasScrolled]);

  const onScrollNext = (firstScroll = false) => {
    let nextScroll =
      (firstScroll ? 2 : scrollState.currentSlide) + slidesToScroll;
    if (nextScroll > items.length) {
      nextScroll = 2;
    }
    embla.scrollTo(nextScroll, false, -1);

    setScrollState({
      ...scrollState,
      currentSlide: nextScroll
    });
  };

  const onScrollPrev = () => {
    let nextScroll = scrollState.currentSlide - slidesToScroll;
    if (nextScroll < 0) {
      nextScroll = items.length - (items.length % slidesToScroll) + 2;
    }
    embla.scrollTo(nextScroll, false, 1);

    setScrollState({
      ...scrollState,
      currentSlide: nextScroll
    });
  };

  return (
    <AppStack style={{ position: 'relative' }}>
      {!isDesktopOrSmaller && !loading && scrollState.hasScrolled && (
        <AppStack
          className={classes['grow-button-container']}
          onClick={onScrollPrev}
          style={{
            position: 'absolute',
            top: 1,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            width: 'calc(3.75% - 10px)',
            height: 'calc(100% - 2px)',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8
          }}
        >
          <ChevronLeft
            className={classes['grow-button']}
            color="#FFF"
            size={60}
            style={{ cursor: 'pointer' }}
          />
        </AppStack>
      )}

      {!isDesktopOrSmaller && !loading && (
        <AppStack
          className={classes['grow-button-container']}
          onClick={() => {
            if (!scrollState.hasScrolled) {
              setScrollState({
                ...scrollState,
                hasScrolled: !scrollState.hasScrolled
              });
            }
            else {
              onScrollNext();
            }
          }}
          style={{
            position: 'absolute',
            top: 1,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            width: scrollState.hasScrolled
              ? 'calc(3.75% - 10px)'
              : 'calc(7.5% - 10px)',
            height: 'calc(100% - 2px)',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            transition: 'width 0.5s ease',
            cursor: 'pointer'
          }}
        >
          <ChevronRight
            className={classes['grow-button']}
            color="#FFF"
            size={60}
            style={{ cursor: 'pointer' }}
          />
        </AppStack>
      )}

      <Carousel
        align={
          (!isDesktopOrSmaller && scrollState.hasScrolled) || isTabletOrSmaller
            ? 'center'
            : 'start'
        }
        controlSize={40}
        draggable={isDesktopOrSmaller}
        getEmblaApi={setEmbla}
        loop={!isDesktopOrSmaller}
        slideGap={10}
        slideSize={
          isMobileOrSmaller
            ? '90%'
            : isTabletOrSmaller
            ? '45%'
            : isLaptopOrSmaller
            ? 'calc(100% / 3)'
            : isDesktopOrSmaller
            ? '25%'
            : '18.5%'
        }
        slidesToScroll={
          isMobileOrSmaller
            ? 1
            : isTabletOrSmaller
            ? 2
            : isLaptopOrSmaller
            ? 3
            : isDesktopOrSmaller
            ? 4
            : 1
        }
        withControls={!isTabletOrSmaller && isDesktopOrSmaller}
        withIndicators={false}
      >
        {items.map((item) => item)}
      </Carousel>
    </AppStack>
  );
};

VendorCatalogCarousel.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool
};

export default VendorCatalogCarousel;
