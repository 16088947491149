import React from 'react';
import { LineChart } from '@mantine/charts';
import PropTypes from 'prop-types';

const AnalyticsLineChart = ({
  mainDataLabel,
  compareDataLabel,
  chartData,
  dataKey,
  valueFormatter
}) => {
  const getSpacedDates = (dates, maxCount) => {
    const totalDates = dates.length;

    if (totalDates <= maxCount) {
      return dates;
    }

    const spacedDates = [dates[0]];
    const step = (totalDates - 1) / (maxCount - 1);
    for (let i = 1; i < maxCount - 1; i += 1) {
      const index = Math.round(i * step);
      spacedDates.push(dates[index]);
    }

    spacedDates.push(dates[totalDates - 1]);

    return spacedDates;
  };

  return chartData ? (
    <LineChart
      connectNulls
      curveType="linear"
      data={chartData}
      dataKey={dataKey}
      h={300}
      legendProps={{
        iconType: 'plainline',
        verticalAlign: 'bottom',
        align: 'center',
        height: 50
      }}
      series={
        compareDataLabel
          ? [
              {
                name: mainDataLabel,
                color: 'blue.6'
              },
              {
                name: compareDataLabel,
                color: 'rgba(18, 184, 134, 0.8)',
                strokeDasharray: '5 5'
              }
            ]
          : [{ name: mainDataLabel, color: 'blue.6' }]
      }
      valueFormatter={valueFormatter}
      withDots={false}
      withLegend
      xAxisProps={{
        ticks: getSpacedDates(
          chartData.map((d) => d.date),
          12
        )
      }}
    />
  ) : (
    <LineChart
      connectNulls
      curveType="linear"
      h={300}
      legendProps={{
        iconType: 'plainline',
        verticalAlign: 'bottom',
        align: 'center',
        height: 50
      }}
      series={[]}
      withDots={false}
      withLegend
    />
  );
};

AnalyticsLineChart.propTypes = {
  chartData: PropTypes.array,
  compareDataLabel: PropTypes.string,
  dataKey: PropTypes.string,
  mainDataLabel: PropTypes.string,
  valueFormatter: PropTypes.func
};

export default AnalyticsLineChart;
