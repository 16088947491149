import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Divider } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import {
  CANADA_PROVINCE_LIST_OPTIONS,
  USA_STATE_LIST_OPTIONS
} from '../../../config/locationConstants';

const AssignVendorProvinceModal = ({
  onConfirm,
  provinces,
  isOpen,
  onClose
}) => {
  const [formState, setFormState] = useState({ provinces: [] });
  const ecomProvinceOptions = CANADA_PROVINCE_LIST_OPTIONS.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const ecomStateOptions = USA_STATE_LIST_OPTIONS.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const allProvincesSelected = CANADA_PROVINCE_LIST_OPTIONS.every((p) =>
    formState.provinces.some((x) => x === p.value)
  );
  const allStatesSelected = USA_STATE_LIST_OPTIONS.every((p) =>
    formState.provinces.some((x) => x === p.value)
  );
  const allLocationsSelected = allProvincesSelected;

  useEffect(() => {
    if (isOpen) {
      setFormState({ provinces: provinces ?? [] });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.saving,
        isSubmitDisabled: formState.provinces.length === 0,
        onCancel: onClose,
        onSubmit: () => onConfirm(formState.provinces),
        submitTitle: 'Assign',
        padding: 0
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Assign locations"
    >
      <AppStack>
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 16,
            paddingBottom: 0
          }}
        >
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            Select the locations you want to sell your products to.
          </AppText>
          <Checkbox
            checked={allLocationsSelected}
            label={allLocationsSelected ? 'Unselect All' : 'Select All'}
            onChange={() => {
              if (allLocationsSelected) {
                setFormState({ provinces: [] });
              }
              else {
                setFormState({
                  ...formState,
                  provinces: [
                    ...CANADA_PROVINCE_LIST_OPTIONS.map((p) => p.value),
                    ...USA_STATE_LIST_OPTIONS.map((p) => p.value)
                  ]
                });
              }
            }}
            styles={{
              label: {
                fontWeight: 500,
                cursor: 'pointer',
                whiteSpace: 'nowrap'
              },
              input: { cursor: 'pointer' }
            }}
          />
        </AppFlexbox>

        <Divider />

        <AppStack style={{ padding: 16, paddingTop: 0, gap: 16 }}>
          <AppFlexbox
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Provinces
            </AppText>
            {/* <Checkbox
              checked={allProvincesSelected}
              label={allProvincesSelected ? 'Unselect All' : 'Select All'}
              onChange={() => {
                if (allProvincesSelected) {
                  setFormState({
                    ...formState,
                    provinces: [
                      ...formState.provinces.filter((p) =>
                        USA_STATE_LIST_OPTIONS.some((x) => x.value === p)
                      )
                    ]
                  });
                }
                else {
                  setFormState({
                    ...formState,
                    provinces: [
                      ...formState.provinces.filter((p) =>
                        USA_STATE_LIST_OPTIONS.some((x) => x.value === p)
                      ),
                      ...CANADA_PROVINCE_LIST_OPTIONS.map((p) => p.value)
                    ]
                  });
                }
              }}
              styles={{
                label: {
                  fontWeight: 500,
                  cursor: 'pointer',
                  whiteSpace: 'nowrap'
                },
                input: { cursor: 'pointer' }
              }}
            /> */}
          </AppFlexbox>

          <AppFlexbox style={{ gap: 40 }}>
            <AppStack style={{ flex: 1 }}>
              {ecomProvinceOptions
                .slice(0, Math.ceil(CANADA_PROVINCE_LIST_OPTIONS.length / 2))
                .map((p) => (
                  <Checkbox
                    key={p.value}
                    checked={formState.provinces.includes(p.value)}
                    label={p.label}
                    onChange={() => {
                      if (formState.provinces.includes(p.value)) {
                        setFormState({
                          ...formState,
                          provinces: formState.provinces.filter(
                            (x) => x !== p.value
                          )
                        });
                      }
                      else {
                        setFormState({
                          ...formState,
                          provinces: [...formState.provinces, p.value]
                        });
                      }
                    }}
                    styles={{
                      label: { fontWeight: 500, cursor: 'pointer' },
                      input: { cursor: 'pointer' }
                    }}
                  />
                ))}
            </AppStack>
            <AppStack style={{ flex: 1 }}>
              {ecomProvinceOptions
                .slice(
                  Math.ceil(CANADA_PROVINCE_LIST_OPTIONS.length / 2),
                  CANADA_PROVINCE_LIST_OPTIONS.length
                )
                .map((p) => (
                  <Checkbox
                    key={p.value}
                    checked={formState.provinces.includes(p.value)}
                    label={p.label}
                    onChange={() => {
                      if (formState.provinces.includes(p.value)) {
                        setFormState({
                          ...formState,
                          provinces: formState.provinces.filter(
                            (x) => x !== p.value
                          )
                        });
                      }
                      else {
                        setFormState({
                          ...formState,
                          provinces: [...formState.provinces, p.value]
                        });
                      }
                    }}
                    styles={{
                      label: { fontWeight: 500, cursor: 'pointer' },
                      input: { cursor: 'pointer' }
                    }}
                  />
                ))}
            </AppStack>
          </AppFlexbox>
        </AppStack>

        {/* <AppStack style={{ padding: 16, paddingTop: 0, gap: 16 }}>
          <AppFlexbox
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>States</AppText>
            <Checkbox
              checked={allStatesSelected}
              label={allStatesSelected ? 'Unselect All' : 'Select All'}
              onChange={() => {
                if (allStatesSelected) {
                  setFormState({
                    ...formState,
                    provinces: [
                      ...formState.provinces.filter((p) =>
                        CANADA_PROVINCE_LIST_OPTIONS.some((x) => x.value === p)
                      )
                    ]
                  });
                }
                else {
                  setFormState({
                    ...formState,
                    provinces: [
                      ...formState.provinces.filter((p) =>
                        CANADA_PROVINCE_LIST_OPTIONS.some((x) => x.value === p)
                      ),
                      ...USA_STATE_LIST_OPTIONS.map((p) => p.value)
                    ]
                  });
                }
              }}
              styles={{
                label: {
                  fontWeight: 500,
                  cursor: 'pointer',
                  whiteSpace: 'nowrap'
                },
                input: { cursor: 'pointer' }
              }}
            />
          </AppFlexbox>

          <AppFlexbox style={{ gap: 40 }}>
            <AppStack style={{ flex: 1 }}>
              {ecomStateOptions
                .slice(0, Math.ceil(USA_STATE_LIST_OPTIONS.length / 2))
                .map((p) => (
                  <Checkbox
                    key={p.value}
                    checked={formState.provinces.includes(p.value)}
                    label={p.label}
                    onChange={() => {
                      if (formState.provinces.includes(p.value)) {
                        setFormState({
                          ...formState,
                          provinces: formState.provinces.filter(
                            (x) => x !== p.value
                          )
                        });
                      }
                      else {
                        setFormState({
                          ...formState,
                          provinces: [...formState.provinces, p.value]
                        });
                      }
                    }}
                    styles={{
                      label: { fontWeight: 500, cursor: 'pointer' },
                      input: { cursor: 'pointer' }
                    }}
                  />
                ))}
            </AppStack>
            <AppStack style={{ flex: 1 }}>
              {ecomStateOptions
                .slice(
                  Math.ceil(USA_STATE_LIST_OPTIONS.length / 2),
                  USA_STATE_LIST_OPTIONS.length
                )
                .map((p) => (
                  <Checkbox
                    key={p.value}
                    checked={formState.provinces.includes(p.value)}
                    label={p.label}
                    onChange={() => {
                      if (formState.provinces.includes(p.value)) {
                        setFormState({
                          ...formState,
                          provinces: formState.provinces.filter(
                            (x) => x !== p.value
                          )
                        });
                      }
                      else {
                        setFormState({
                          ...formState,
                          provinces: [...formState.provinces, p.value]
                        });
                      }
                    }}
                    styles={{
                      label: { fontWeight: 500, cursor: 'pointer' },
                      input: { cursor: 'pointer' }
                    }}
                  />
                ))}
            </AppStack>
          </AppFlexbox>
        </AppStack> */}
      </AppStack>
    </ResponsiveModal>
  );
};

AssignVendorProvinceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  provinces: PropTypes.array
};

export default AssignVendorProvinceModal;
