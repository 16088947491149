/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@mantine/core';
import { ArrowRight } from 'tabler-icons-react';
import AppStack from '../../../../common/AppStack';
import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../../config/constants';
import AppText from '../../../../common/AppText';
import WsSectionContainer from '../WsSectionContainer';

const WsEmailSignup = ({
  blocks,
  config,
  colorSchemes,
  themeConfig,
  mediaQueries,
  ...rest
}) => {
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack
        style={{
          flex: 1,
          margin: 'auto',
          alignItems: 'center',
          gap: 30
        }}
      >
        {blocks
          .sort((a, b) => a.sort - b.sort)
          .map((block) =>
            block.fkEcomStoreThemeSectionBlockType ===
              ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.HEADING ||
            block.fkEcomStoreThemeSectionBlockType ===
              ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.SUBHEADING
              ? block.config.text && (
                  <AppText
                    key={block.key}
                    style={{ fontSize: block.config.textSize * 1 ?? '20' }}
                  >
                    {block.config.text}
                  </AppText>
                )
              : block.fkEcomStoreThemeSectionBlockType ===
                  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.EMAIL_FORM && (
                  <TextInput
                    key={block.key}
                    placeholder="Email"
                    radius="xl"
                    rightSection={<ArrowRight color={colorScheme.textColor} />}
                    size="lg"
                    style={{ color: colorScheme.textColor, minWidth: 250 }}
                    styles={{
                      input: { backgroundColor: 'unset' },
                      wrapper: { '--input-bd': colorScheme.textColor }
                    }}
                  />
                )
          )}
      </AppStack>
    </WsSectionContainer>
  );
};

WsEmailSignup.propTypes = {
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  mediaQueries: PropTypes.object,
  themeConfig: PropTypes.object
};

export default WsEmailSignup;
