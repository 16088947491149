import { ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM } from '../../../../config/constants';
import { config as wsButtonGroupConfig } from './buttonGroup/wsButtonGroupConfig';
import { config as wsHeadingConfig } from './heading/wsHeadingConfig';
import { config as wsTextOverImageConfig } from './textOverImage/wsTextOverImageConfig';
import { config as wsSubheadingConfig } from './subheading/wsSubheadingConfig';
import { config as wsTextConfig } from './text/wsTextConfig';
import { config as wsCaptionConfig } from './caption/wsCaptionConfig';
import { config as wsTextLinkConfig } from './textLink/wsTextLinkConfig';
import { config as wsEmailFormConfig } from './emailForm/wsEmailFormConfig';
import { config as wsTitleConfig } from './title/wsTitleConfig';
import { config as wsVariantPickerConfig } from './variantPicker/wsVariantPickerConfig';
import { config as wsAccordionConfig } from './accordion/wsAccordionConfig';
import { config as wsMenuConfig } from './menu/wsMenuConfig';
import { config as wsBrandInformationConfig } from './brandInformation/wsBrandInformationConfig';
import { config as wsColumnContentConfig } from './columnContent/wsColumnContentConfig';
import { config as wsRowContentConfig } from './rowContent/wsRowContentConfig';
import { config as wsVideoConfig } from './video/wsVideoConfig';
import { config as wsProductConfig } from './product/wsProductConfig';
import { config as wsCollectionConfig } from './collection/wsCollectionConfig';
import { config as wsImageConfig } from './image/wsImageConfig';
import { config as wsBuyButtonsConfig } from './buyButtons/wsBuyButtonsConfig';
import { config as wsSkuConfig } from './sku/wsSkuConfig';
import { config as wsPriceConfig } from './price/wsPriceConfig';
import { config as wsRatingConfig } from './rating/wsRatingConfig';
import { config as wsIconWithTextConfig } from './iconWithText/wsIconWithTextConfig';
import { config as wsQuantitySelectorConfig } from './quantitySelector/wsQuantitySelectorConfig';
import { config as wsDescriptionConfig } from './description/wsDescriptionConfig';
import { config as wsInventoryStatusConfig } from './inventoryStatus/wsInventoryStatusConfig';
import { config as wsContentCardConfig } from './contentCard/wsContentCardConfig';
import { config as WsVendorTitleConfig } from './vendorTitle/wsVendorTitleConfig';

const ECOM_WEBSTORE_SECTION_BLOCKS = {
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT_LINK]: wsTextLinkConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT_OVER_IMAGE]: wsTextOverImageConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.HEADING]: wsHeadingConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.SUBHEADING]: wsSubheadingConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TITLE]: wsTitleConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT]: wsTextConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CAPTION]: wsCaptionConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUTTON_GROUP]: wsButtonGroupConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.EMAIL_FORM]: wsEmailFormConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.PRICE]: wsPriceConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VARIANT_PICKER]: wsVariantPickerConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.QUANTITY_SELECTOR]: wsQuantitySelectorConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUY_BUTTONS]: wsBuyButtonsConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.SKU]: wsSkuConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.RATING]: wsRatingConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ICON_WITH_TEXT]: wsIconWithTextConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE]: wsImageConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.COLLECTION]: wsCollectionConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.PRODUCT]: wsProductConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VIDEO]: wsVideoConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.COLUMN_CONTENT]: wsColumnContentConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ROW_CONTENT]: wsRowContentConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ACCORDION]: wsAccordionConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.MENU]: wsMenuConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BRAND_INFORMATION]: wsBrandInformationConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.DESCRIPTION]: wsDescriptionConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.INVENTORY_STATUS]: wsInventoryStatusConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CONTENT_CARD]: wsContentCardConfig,
  [ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VENDOR_TITLE]: WsVendorTitleConfig
};

const ECOM_WEBSTORE_SECTION_BLOCK_LIST = Object.keys(
  ECOM_WEBSTORE_SECTION_BLOCKS
).reduce((r, c) => [...r, ECOM_WEBSTORE_SECTION_BLOCKS[c]], []);

export { ECOM_WEBSTORE_SECTION_BLOCKS, ECOM_WEBSTORE_SECTION_BLOCK_LIST };
