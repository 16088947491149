import { saveToStorage } from '../helpers/storage';
import createDataProvider from './createDataProvider';
import { providerContextReducer } from './providerContextReducer';

const initialState = {
  form: {
    data: {},
    initialized: false,
    hasUnsavedChanges: false,
    loading: false,
    triggerSubmit: false,
    triggerDiscard: false,
    containerWidth: null,
    errors: {}
  },
  itemStorage: { value: [], loading: false, error: null },
  setupGuide: { showSidebar: false, activeSidebarStep: null },
  vendorCatalog: {
    showDrawer: false,
    showInputModal: false,
    skipInputModalOnAdd: false,
    inputProducts: [],
    fetchingProducts: [],
    selectedInputProductUuid: null,
    removeProductModal: {
      isOpen: false,
      ecomVendorProducts: []
    }
  },
  webstore: {
    addProductToCartModal: {
      isOpen: false,
      ecomStoreProduct: null
    },
    checkoutSummaryDrawer: {
      isOpen: false,
      showProductCustomization: false,
      ecomStoreCartProduct: null
    },
    continueAsGuestModal: {
      isOpen: false,
      ecomStoreCartProducts: [],
      hasBeenShown: false
    }
  }
};

const setFormData = (dispatch) => async ({
  data,
  containerWidth,
  hasUnsavedChanges = true,
  triggerDiscard = false,
  loading = false
}) => {
  dispatch({
    type: 'success',
    stateName: 'form',
    payload: {
      data,
      hasUnsavedChanges,
      containerWidth,
      triggerDiscard,
      loading,
      initialized: true,
      triggerSubmit: false
    }
  });
};

const triggerFormDiscard = (dispatch) => async () => {
  dispatch({
    type: 'success',
    stateName: 'form',
    payload: { triggerDiscard: true }
  });
};

const triggerFormSubmit = (dispatch) => async () => {
  dispatch({
    type: 'success',
    stateName: 'form',
    payload: { triggerSubmit: true }
  });
};

const resetForm = (dispatch) => async () => {
  dispatch({
    type: 'success',
    stateName: 'form',
    payload: {
      data: {},
      hasUnsavedChanges: false,
      loading: false,
      triggerSubmit: false,
      triggerDiscard: false,
      initialized: false,
      errors: {}
    }
  });
};

const toggleSetupGuideSidebar = (dispatch) => async (
  open = false,
  activeSidebarStep = null
) => {
  saveToStorage('showSetupGuide', open ? 'true' : 'false');
  saveToStorage('activeSidebarStep', activeSidebarStep?.toString() ?? '');
  dispatch({
    type: 'success',
    stateName: 'setupGuide',
    payload: { showSidebar: open, activeSidebarStep }
  });
};

const toggleVendorCatalogDrawer = (dispatch) => async (showDrawer = false) => {
  dispatch({
    type: 'success',
    stateName: 'vendorCatalog',
    payload: { showDrawer }
  });
};

const toggleVendorCatalogInputModal = (dispatch) => async (
  showInputModal = false,
  selectedInputProductUuid = null
) => {
  dispatch({
    type: 'success',
    stateName: 'vendorCatalog',
    payload: selectedInputProductUuid
      ? { showInputModal, selectedInputProductUuid }
      : { showInputModal }
  });
};

const toggleVendorCatalogSkipInputModal = (dispatch) => async (
  skipInputModalOnAdd = false
) => {
  dispatch({
    type: 'success',
    stateName: 'vendorCatalog',
    payload: { skipInputModalOnAdd }
  });
};

const setVendorCatalogInputProducts = (dispatch) => async ({
  inputProducts = [],
  showInputModal = false,
  selectedInputProductUuid = null
} = {}) => {
  dispatch({
    type: 'success',
    stateName: 'vendorCatalog',
    payload: {
      inputProducts,
      showInputModal,
      selectedInputProductUuid
    }
  });
};

const setVendorCatalogRemoveStoreProductModal = (dispatch) => async ({
  isOpen,
  ecomVendorProducts
} = {}) => {
  dispatch({
    type: 'success',
    stateName: 'vendorCatalog',
    payload: {
      removeProductModal: {
        isOpen,
        ecomVendorProducts
      }
    }
  });
};

const appendItemStorage = (dispatch) => async (items) => {
  dispatch({
    type: 'append',
    stateName: 'itemStorage',
    payload: { value: items }
  });
};

const removeItemStorage = (dispatch) => async ({ filter } = {}) => {
  dispatch({
    type: 'remove',
    stateName: 'itemStorage',
    payload: filter
  });
};

const toggleWebstoreAddProductToCartModal = (dispatch) => async ({
  isOpen,
  ecomStoreProduct
}) => {
  dispatch({
    type: 'success',
    stateName: 'webstore',
    payload: { addProductToCartModal: { isOpen, ecomStoreProduct } }
  });
};

const toggleWebstoreCheckoutSummaryDrawer = (dispatch) => async ({
  isOpen,
  showProductCustomization = false,
  ecomStoreCartProduct = null
}) => {
  dispatch({
    type: 'success',
    stateName: 'webstore',
    payload: {
      checkoutSummaryDrawer: {
        isOpen,
        showProductCustomization,
        ecomStoreCartProduct
      }
    }
  });
};

const toggleWebstoreContinueAsGuestModal = (dispatch) => async ({
  isOpen,
  ecomStoreCartProducts = []
}) => {
  dispatch({
    type: 'success',
    stateName: 'webstore',
    payload: {
      continueAsGuestModal: {
        isOpen,
        ecomStoreCartProducts,
        hasBeenShown: true
      }
    }
  });
};

export const { Provider, Context } = createDataProvider(
  providerContextReducer,
  {
    setFormData,
    triggerFormDiscard,
    triggerFormSubmit,
    resetForm,
    toggleSetupGuideSidebar,
    toggleVendorCatalogDrawer,
    toggleVendorCatalogInputModal,
    toggleVendorCatalogSkipInputModal,
    toggleWebstoreAddProductToCartModal,
    toggleWebstoreCheckoutSummaryDrawer,
    toggleWebstoreContinueAsGuestModal,
    setVendorCatalogRemoveStoreProductModal,
    setVendorCatalogInputProducts,
    appendItemStorage,
    removeItemStorage
  },
  initialState
);
