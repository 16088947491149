import React, { useEffect, useRef, useContext } from 'react';
import AppStack from '../../common/AppStack';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const AdminOverviewView = () => {
  const hasFetched = useRef(null);
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const { state, fetchAdminOverview } = useContext(AdminContext);

  useEffect(() => {}, []);

  return (
    <AppStack style={{ flex: 1 }}>
      <AppStack
        p={{ base: 0, sm: '16px 16px 80px 16px' }}
        style={{
          flex: 1,
          padding: 10
        }}
      />
    </AppStack>
  );
};

export default AdminOverviewView;
