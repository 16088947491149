import React, { useContext } from 'react';
import { Anchor, Button, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const WebstoreMenuListView = ({ isViewLoading }) => {
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const { state } = useContext(StoreContext);

  return !isViewLoading ? (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16,
        overflow: 'hidden'
      }}
    >
      <AppFlexbox
        p={{ base: '0px 16px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <AppText style={{ fontSize: 22, fontWeight: 500 }}>
            Navigation
          </AppText>
        </AppFlexbox>
      </AppFlexbox>
      <AppStack
        style={{
          gap: 16,
          overflow: 'hidden',
          maxWidth: isTabletOrSmaller ? '100vw' : 'calc(100vw - 272px)'
        }}
      >
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0, width: '100%', flex: 1 }}
          withBorder
        >
          <AppStack style={{ gap: 0, overflow: 'hidden', flex: 1 }}>
            <AppFlexbox
              style={{
                gap: 5,
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 16
              }}
            >
              <AppText style={{ fontSize: 18, fontWeight: 500 }}>Menus</AppText>
              <Anchor
                component={Link}
                style={{ fontSize: 14 }}
                to="/merchant/webstore/menus/new"
              >
                Add Menu
              </Anchor>
            </AppFlexbox>
            {state.ecomStoreMenus.value.length === 0 ? (
              <AppStack
                style={{
                  padding: '40px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Add a menu to customize your webstore navigation
                </AppText>
                <Button
                  color="dark"
                  component={Link}
                  size="compact-md"
                  to="/merchant/webstore/menus/new"
                  variant="outline"
                >
                  Add menu
                </Button>
              </AppStack>
            ) : (
              <AppStack
                style={{
                  flex: 1,
                  gap: 0,
                  overflow: 'hidden'
                }}
              >
                <AppFlexbox
                  style={{
                    padding: '0px 16px 10px 16px',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppFlexbox style={{ gap: 10, flex: 1 }}>
                    <AppText style={{ color: '#666', fontSize: 14 }}>
                      Title
                    </AppText>
                  </AppFlexbox>

                  <AppFlexbox style={{ gap: 10, flex: 2 }}>
                    <AppText style={{ color: '#666', fontSize: 14 }}>
                      Menu items
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
                {state.ecomStoreMenus.value.map((t) => (
                  <AppFlexbox
                    key={t.pkEcomStoreMenu}
                    style={{
                      flex: 1,
                      padding: 16,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderTop: '1px solid #dee2e6',
                      overflow: 'hidden'
                    }}
                  >
                    <AppFlexbox style={{ gap: 10, flex: 1 }}>
                      <AppStack style={{ gap: 0 }}>
                        <Anchor
                          component={Link}
                          style={{ fontSize: 14 }}
                          to={`/merchant/webstore/menus/${t.pkEcomStoreMenu}`}
                        >
                          {t.title}
                        </Anchor>
                      </AppStack>
                    </AppFlexbox>

                    <AppFlexbox
                      style={{
                        gap: 10,
                        flex: 2,
                        width: '100%',
                        overflow: 'hidden'
                      }}
                    >
                      <AppText
                        style={{
                          fontSize: 14,
                          color: '#666',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}
                      >
                        {t.ecomStoreMenuItems
                          .sort((a, b) => a.sort - b.sort)
                          .map((i) => i.title)
                          .join(', ')}
                      </AppText>
                    </AppFlexbox>
                  </AppFlexbox>
                ))}
              </AppStack>
            )}
          </AppStack>
        </AppCard>
      </AppStack>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: 0, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 16px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Skeleton height={30} width={110} />
      </AppFlexbox>

      <AppStack style={{ flex: 1 }}>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox
              style={{
                gap: 5,
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 16
              }}
            >
              <Skeleton height={24} width={55} />
              <Skeleton height={18} width={65} />
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{
                  padding: '0px 16px 10px 16px',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppFlexbox style={{ gap: 10, flex: 1 }}>
                  <Skeleton height={18} width={26} />
                </AppFlexbox>

                <AppFlexbox style={{ gap: 10, flex: 1 }}>
                  <Skeleton height={18} width={75} />
                </AppFlexbox>
              </AppFlexbox>
              {Array.from(Array(2)).map((x, i) => (
                <AppFlexbox
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  style={{
                    padding: 16,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTop: '1px solid #dee2e6'
                  }}
                >
                  <AppFlexbox style={{ gap: 10, flex: 1 }}>
                    <AppStack style={{ gap: 0 }}>
                      <Skeleton height={18} width={80} />
                    </AppStack>
                  </AppFlexbox>

                  <AppFlexbox style={{ gap: 10, flex: 1, overflow: 'hidden' }}>
                    <Skeleton height={18} width={120} />
                  </AppFlexbox>
                </AppFlexbox>
              ))}
            </AppStack>
          </AppStack>
        </AppCard>
      </AppStack>
    </AppStack>
  );
};

WebstoreMenuListView.propTypes = { isViewLoading: PropTypes.bool };

export default WebstoreMenuListView;
