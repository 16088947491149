import React, { useContext, useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ArrowLeft, ChevronDown, Dots, Tooltip } from 'tabler-icons-react';
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Menu,
  Skeleton
} from '@mantine/core';
import dayjs from 'dayjs';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import {
  currencyFormat,
  formatUtcDate,
  singularPluralFormat
} from '../../../helpers/format';
import { triggerNotification } from '../../../helpers/notification';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import AppMenu from '../../common/AppMenu';
import OrderTransactionModal from './OrderTransactionModal';
import {
  ECOM_CART_PRODUCT_STATUS_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import HistoryTimeLine from '../../common/HistoryTimeLine';
import OrderProductsInfoCard from './OrderProductsInfoCard';
import OrderPaymentInfoCard from './OrderPaymentInfoCard';
import OrderTrackingInfoCard from './OrderTrackingInfoCard';

const StoreOrderDetailsView = () => {
  const hasFetched = useRef(false);
  const { pkEcomStoreCart } = useParams();
  const { search } = useLocation();
  const searchUrl = new URLSearchParams(search);
  const fromLocation = searchUrl.get('from');
  const fromLocationId = searchUrl.get('fromId');
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreCartHistory } = useContext(StoreContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const loading = !hasFetched.current || state.ecomStoreOrder.loading;
  const storeOrder =
    pkEcomStoreCart ===
    state.ecomStoreOrder.value?.ecomStoreCart.pkEcomStoreCart.toString()
      ? state.ecomStoreOrder.value
      : null;
  const isFulfilled = storeOrder?.ecomStoreCart.ecomStoreCartProducts.every(
    (p) =>
      p.fkRegFormSubmissionStatus.toString() ===
      ECOM_CART_PRODUCT_STATUS_ENUM.ACCEPTED
  );
  const ecomStoreCartShippingProducts =
    storeOrder?.ecomStoreCart.ecomStoreCartShipping.reduce(
      (r, c) => [...r, ...c.ecomStoreCartShippingProducts],
      []
    ) ?? [];

  const productData =
    storeOrder?.ecomStoreCart.cartBalance.lineItems.map((item) => {
      const product = storeOrder.ecomStoreCart.ecomStoreCartProducts.find(
        (c) => c.pkEcomStoreCartProduct === item.entityId
      );

      return {
        key: item.entityId,
        pkEcomStoreCartProduct: product.pkEcomStoreCartProduct,
        to: `/merchant/products/${item.uuid}`,
        previewImage: item.previewImage,
        name: item.productName,
        sku: product?.ecomStoreProductVariant.ecomVendorProductVariant.sku,
        barcode:
          product?.ecomStoreProductVariant.ecomVendorProductVariant.barcode,
        quantity: item.quantity,
        unitCost: item.totalInCents,
        addOns: item.addOns.map((a) => ({
          name: a.name,
          value: a.value,
          sort: a.sort
        }))
      };
    }) ?? [];

  const shippedProductCount = ecomStoreCartShippingProducts.reduce(
    (r, c) => r + c.count,
    0
  );
  const totalUnitCount =
    productData?.reduce((acc, p) => acc + p.quantity, 0) ?? 0;

  const allShippingNotified = storeOrder?.ecomStoreCart?.ecomStoreCartShipping.every(
    (s) => s.emailSent
  );

  const timelineData = storeOrder
    ? [
        ...storeOrder.ecomStoreCartEscrowPayments.map((p) => ({
          title: 'Escrow claimed',
          date: storeOrder.createdAt,
          message: (
            <AppText style={{ fontSize: 14 }}>
              <Anchor
                component={Link}
                style={{ color: 'dodgerblue', fontSize: 14 }}
                to="/merchant/escrow-payments"
              >
                Escrow payment
              </Anchor>{' '}
              of {currencyFormat(p.amount / 100)} was claimed.
            </AppText>
          )
        })),
        {
          title: 'Order placed',
          date: storeOrder.ecomStoreCart.cartBalance.purchaseDate,
          message: (
            <AppText style={{ fontSize: 14 }}>
              {storeOrder.ecomStoreCart.customer.name ? (
                <Anchor
                  component={Link}
                  style={{ color: 'dodgerblue', fontSize: 14 }}
                  to={`/merchant/customers/${storeOrder.ecomStoreCart.customer.pkUser}?from=orders&fromId=${storeOrder.ecomStoreCart.pkEcomStoreCart}`}
                >
                  {storeOrder.ecomStoreCart.customer.name}
                </Anchor>
              ) : (
                'A guest'
              )}{' '}
              placed an order containing{' '}
              {storeOrder.ecomStoreCart.cartBalance.lineItems.length} items with
              a total cost of{' '}
              {currencyFormat(
                storeOrder.ecomStoreCart.cartBalance.totalInCents / 100
              )}
              .
            </AppText>
          )
        }
      ].sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  useEffect(() => {
    if (authState.pkEcomStore && pkEcomStoreCart) {
      fetchStoreCartHistory(
        authState.pkEcomStore,
        { pkEcomStoreCart },
        null,
        (e) => {
          triggerNotification(e);
        }
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore, pkEcomStoreCart]);

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'customers': {
        if (fromLocationId) {
          return `/merchant/customers/${fromLocationId}`;
        }
        return '/merchant/customers';
      }
      default: {
        return '/merchant/orders';
      }
    }
  };

  return !loading && storeOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <OrderTransactionModal
        ecomStoreCart={storeOrder.ecomStoreCart}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.VIEW
        }
        onClose={onCloseModal}
      />

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={getBackPath()}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText
                style={{
                  flex: 1,
                  fontSize: 24,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                Order #{storeOrder.ecomStoreCart.pkEcomStoreCart}
              </AppText>
              {storeOrder?.ecomStoreCart.isEscrowPurchase && (
                <Badge color="dark" variant="light">
                  Escrow
                </Badge>
              )}
              {shippedProductCount < totalUnitCount ? (
                <Badge color="dark" variant="light">
                  Unfulfilled
                </Badge>
              ) : (
                <Badge color="green" variant="filled">
                  Fulfilled
                </Badge>
              )}
              {storeOrder.ecomStoreCart.ecomStoreCartShipping.length > 0 &&
                (allShippingNotified ? (
                  <Badge color="blue" variant="filled">
                    Customer Notified
                  </Badge>
                ) : (
                  <Tooltip label="Customer has not been notified about all shipments.">
                    <Badge color="red" variant="filled">
                      Action Required
                    </Badge>
                  </Tooltip>
                ))}
            </AppFlexbox>

            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Placed{' '}
              {dayjs(
                formatUtcDate(storeOrder.ecomStoreCart.cartBalance.purchaseDate)
              ).format('MMMM D, YYYY')}{' '}
              at{' '}
              {dayjs(
                formatUtcDate(storeOrder.ecomStoreCart.cartBalance.purchaseDate)
              ).format('h:mm A')}
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          <AppMenu
            control={
              <Button
                color="dark"
                rightSection={<ChevronDown size={18} />}
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            {/* <Menu.Item onClick={() => {}}>Archive order</Menu.Item> */}
            <Menu.Item
              onClick={() => {
                onOpenModal(VIEW_ACTIONS_ENUM.VIEW);
              }}
            >
              View transaction
            </Menu.Item>
            {/* <Menu.Item onClick={() => {}}>Cancel order</Menu.Item>
            <Menu.Item onClick={() => {}} style={{ color: 'red' }}>
              Refund order
            </Menu.Item> */}
          </AppMenu>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard
            isFulfilled={isFulfilled}
            productData={productData}
            shippedProductCount={ecomStoreCartShippingProducts.reduce(
              (r, c) => r + c.count,
              0
            )}
          />

          <OrderPaymentInfoCard
            actionMenu={
              <AppMenu
                control={
                  <ActionIcon color="dark" variant="subtle">
                    <Dots />
                  </ActionIcon>
                }
              >
                <Menu.Item
                  onClick={() => {
                    onOpenModal(VIEW_ACTIONS_ENUM.VIEW);
                  }}
                >
                  View transaction
                </Menu.Item>
                {/* <Menu.Item onClick={() => {}}>Cancel order</Menu.Item>
                <Menu.Item onClick={() => {}} style={{ color: 'red' }}>
                  Refund order
                </Menu.Item> */}
              </AppMenu>
            }
            payments={[
              {
                group: 1,
                label: 'Subtotal',
                color: '#666',
                description: singularPluralFormat(
                  storeOrder.ecomStoreCart.cartBalance.lineItems.reduce(
                    (acc, item) => acc + item.quantity,
                    0
                  ),
                  'item',
                  'items'
                ),
                value:
                  storeOrder.ecomStoreCart.cartBalance.subtotalInCents / 100
              },
              {
                group: 1,
                label: 'Platform fee',
                color: '#666',
                description: '-',
                value:
                  storeOrder.ecomStoreCart.cartBalance.tieredPricingFeeInCents /
                  100
              },
              {
                group: 2,
                label: 'Total',
                weight: 500,
                description: '',
                value: storeOrder.ecomStoreCart.cartBalance.totalInCents / 100
              }
            ]}
          />

          <OrderTrackingInfoCard
            ecomStoreCartShipping={
              storeOrder.ecomStoreCart.ecomStoreCartShipping
            }
            isStoreViewing
            loading={loading}
            onNotify={(shipping) => onOpenModal('notify', shipping)}
            onUpdateShipping={(shipping) =>
              onOpenModal('edit-shipping', shipping)
            }
            productData={productData}
          />

          <AppStack style={{}}>
            <AppFlexbox style={{ padding: '0px 16px' }}>
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Timeline
              </AppText>
            </AppFlexbox>

            <AppStack style={{ padding: '0px 16px' }}>
              <HistoryTimeLine data={timelineData} />
            </AppStack>
          </AppStack>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard
            addresses={storeOrder.ecomStoreCart.addresses}
            customer={storeOrder.ecomStoreCart.customer.name}
            customerEmail={storeOrder.ecomStoreCart.customer.email}
            customerUrl={
              storeOrder.ecomStoreCart.customer.pkUser
                ? `/merchant/customers/${storeOrder.ecomStoreCart.customer.pkUser}?from=orders&fromId=${storeOrder.ecomStoreCart.pkEcomStoreCart}`
                : null
            }
          />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={getBackPath()}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard loading />
          <OrderPaymentInfoCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

export default StoreOrderDetailsView;
