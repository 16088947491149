import React from 'react';
import PropTypes from 'prop-types';
import { useHover } from '@mantine/hooks';
import { ActionIcon } from '@mantine/core';
import { Eye, EyeOff, Trash } from 'tabler-icons-react';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const WebstoreBuilderSidebarButton = ({
  label,
  leftSection,
  rightSection,
  onClick,
  selected,
  onRemove,
  onToggleHide,
  disabled,
  itemCount,
  isItemHidden,
  color,
  radius,
  height,
  fontSize,
  fontWeight,
  controlsHidden,
  isDragging,
  mobileEditMode,
  sortableProps,
  ...rest
}) => {
  const { hovered, ref: hoverRef } = useHover();

  return (
    <AppFlexbox
      ref={hoverRef}
      onClick={onClick}
      {...rest}
      style={{
        minHeight: height ?? 32,
        maxHeight: height ?? 32,
        borderRadius: radius ?? 8,
        gap: 0,
        height: '100%',
        width: '100%',
        alignItems: 'center',
        color: disabled ? '#999' : color ?? '#000',
        ...(!disabled && (hovered || selected || isDragging)
          ? { backgroundColor: 'rgba(56, 56, 56, 0.1)' }
          : {}),
        fontWeight:
          !disabled && (hovered || selected) ? 500 : fontWeight ?? 'normal',
        justifyContent: 'space-between',
        overflow: 'hidden',
        cursor: sortableProps?.isOverlay
          ? 'grabbing'
          : disabled
          ? 'not-allowed'
          : onClick
          ? 'pointer'
          : 'default',
        ...rest.style
      }}
      {...sortableProps?.attributes}
      {...sortableProps?.listeners}
    >
      <AppFlexbox
        style={{
          flex: 1,
          gap: 0,
          display: isDragging ? 'none' : 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          height: '100%'
        }}
      >
        {leftSection}
        <AppText
          style={{
            fontSize: fontSize ?? 14,
            flex: 1,
            paddingLeft: leftSection ? 5 : 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          {label} {disabled ? `(${itemCount}/${itemCount})` : ''}
        </AppText>
        <AppFlexbox
          style={{
            marginLeft: 'auto',
            gap: 2,
            alignItems: 'center',
            height: '100%'
          }}
        >
          {rightSection}
          {(mobileEditMode ||
            (!disabled && !controlsHidden && (hovered || isItemHidden))) && (
            <>
              {(mobileEditMode || hovered) && onRemove && (
                <ActionIcon
                  color={mobileEditMode ? 'dark' : 'red'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRemove();
                  }}
                  size="sm"
                  style={{
                    height: '100%',
                    borderRadius: 8,
                    width: mobileEditMode ? 45 : 25,
                    border: 'none'
                  }}
                  variant="subtle"
                >
                  <Trash size={mobileEditMode ? 22 : 18} />
                </ActionIcon>
              )}
              {(onToggleHide || isItemHidden) && (
                <ActionIcon
                  color="dark"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onToggleHide();
                  }}
                  size="sm"
                  style={{
                    height: '100%',
                    borderRadius: 8,
                    width: mobileEditMode ? 45 : 25,
                    border: 'none'
                  }}
                  variant="subtle"
                >
                  {isItemHidden ? (
                    <EyeOff size={mobileEditMode ? 22 : 18} />
                  ) : (
                    <Eye size={mobileEditMode ? 22 : 18} />
                  )}
                </ActionIcon>
              )}
            </>
          )}
        </AppFlexbox>
      </AppFlexbox>
    </AppFlexbox>
  );
};

WebstoreBuilderSidebarButton.propTypes = {
  color: PropTypes.string,
  controlsHidden: PropTypes.bool,
  disabled: PropTypes.bool,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  height: PropTypes.number,
  isDragging: PropTypes.bool,
  isItemHidden: PropTypes.bool,
  itemCount: PropTypes.number,
  label: PropTypes.string,
  leftSection: PropTypes.any,
  mobileEditMode: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  onToggleHide: PropTypes.func,
  radius: PropTypes.number,
  rightSection: PropTypes.any,
  selected: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  sidePadding: PropTypes.number,
  sortableProps: PropTypes.object
};

export default WebstoreBuilderSidebarButton;
