import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon } from '@mantine/core';
import { ArrowLeft } from 'tabler-icons-react';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import AnalyticsFilter from './AnalyticsFilter';
import ReportTable from './ReportTable';
import AnalyticsChartBase from './AnalyticsChartBase';
import { REPORT_HEADER_LIST } from './reportsConfig';
import { getDateRangeLabel } from '../../../helpers/format';

const convertReportData = (dataKey, data, index, dataLabel, dataType) =>
  data.map((d) => {
    let value = d[index];
    switch (dataType) {
      case 'currency':
        value = Number(value) / 100;
        break;
      case 'number':
        value = Number(value);
        break;
      case 'percentage':
        value = `${Number(value)?.toFixed(2)}%`;
        break;
      default:
        value = value ?? 'N/A';
    }
    return {
      [dataKey]: d[0],
      [dataLabel]: value
    };
  });

const getReportResultChartData = (
  reportResult,
  dataKey,
  selectedHeaderField,
  mainDataLabel,
  compareDataLabel
) => {
  const selectedHeaderIndex = reportResult.report.headers.findIndex(
    (f) => f.field === selectedHeaderField
  );
  if (selectedHeaderIndex === -1) {
    return [];
  }

  const selectedHeader = reportResult.report.headers[selectedHeaderIndex];
  const mainData = reportResult?.report.data
    ? convertReportData(
        dataKey,
        reportResult.report.data,
        selectedHeaderIndex,
        mainDataLabel,
        selectedHeader.dataType
      )
    : null;

  const compareData = reportResult?.report.compareData
    ? convertReportData(
        dataKey,
        reportResult.report.compareData,
        selectedHeaderIndex,
        compareDataLabel,
        selectedHeader.dataType
      )
    : null;

  const data = mainData;
  if (compareData) {
    compareData.forEach((d, i) => {
      const dataItem = data[i];
      if (dataItem) {
        dataItem[compareDataLabel] = d[compareDataLabel];
      }
      else {
        data.push({
          [dataKey]: d[dataKey],
          [compareDataLabel]: d[compareDataLabel]
        });
      }
    });
  }

  return data.length === 1 ? [...data, ...data] : data;
};

const AnalyticsReportView = ({
  entityId,
  onFetchReport,
  reportConfigs,
  reportResult,
  loading,
  redirectPath
}) => {
  const hasFetched = useRef(false);
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const { uuid } = useParams();
  const [viewState, setViewState] = useState({
    headers: null,
    options: [],
    filter: {},
    selectedChartField: null
  });

  const chartableHeaders = reportResult
    ? REPORT_HEADER_LIST.filter(
        (h) =>
          h.chartable &&
          reportResult.report.headers.some((r) => r.field === h.value)
      )
    : [];
  const reportLoading = !hasFetched.current || loading;
  const reportConfig = reportConfigs.find((f) => f.uuid === uuid);

  const mainDataLabel = getDateRangeLabel(
    viewState.filter.startDate,
    viewState.filter.endDate
  );
  const compareDataLabel = getDateRangeLabel(
    viewState.filter.compareStartDate,
    viewState.filter.compareEndDate
  );
  const chartData =
    reportResult && viewState.selectedChartField
      ? getReportResultChartData(
          reportResult,
          reportConfig.chart?.dataKey,
          viewState.selectedChartField,
          mainDataLabel,
          compareDataLabel
        )
      : null;

  useEffect(() => {
    if (reportResult) {
      setViewState((c) => ({
        ...c,
        selectedChartField:
          chartableHeaders.find(
            (h) => h.value === reportConfig.chart?.defaultHeaderValue
          )?.value ?? chartableHeaders[0]?.value
      }));
    }
  }, [reportResult]);

  const onFetchReportData = (headers, filter) => {
    const groupByPeriod = reportConfig.groupBy === 'period';
    const compareEnabled = groupByPeriod && reportConfig.compareEnabled;
    onFetchReport(reportConfig.uuid, {
      headers,
      fkReportType: reportConfig.fkReportType,
      groupBy: reportConfig.groupBy,
      ...filter,
      periodInterval: groupByPeriod ? filter.periodInterval : null,
      compareStartDate: compareEnabled ? filter.compareStartDate : null,
      compareEndDate: compareEnabled ? filter.compareEndDate : null
    });
    hasFetched.current = true;
  };

  return reportConfig ? (
    <AppStack
      id="analytics-report-view"
      style={{
        flex: 1,
        gap: 16,
        padding: isTabletOrSmaller
          ? '10px 10px 40px 10px'
          : '10px 20px 40px 15px',
        backgroundColor: '#F0F0F0',
        width: '100%',
        height: '100%',
        overflow: 'auto'
      }}
    >
      <AppFlexbox
        style={{
          gap: 8,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <ActionIcon
            color="dark"
            component={Link}
            to={redirectPath}
            variant="subtle"
          >
            <ArrowLeft size={24} />
          </ActionIcon>
          <AppText style={{ fontSize: 20, fontWeight: 700 }}>
            {reportConfig.title ?? 'Analytics'}
          </AppText>
        </AppFlexbox>
      </AppFlexbox>

      <AnalyticsFilter
        entityId={entityId}
        includeCompareDateRange={reportConfig?.compareEnabled}
        includePeriod={reportConfig?.groupBy === 'period'}
        onFilterChange={(filter) => {
          setViewState((c) => ({ ...c, filter }));
          onFetchReportData(
            viewState.headers || reportConfig.defaultHeaders,
            filter
          );
        }}
        updateParamsOnFilterChange
      />

      {reportConfig?.chart && (
        <AppStack>
          <AnalyticsChartBase
            chart={reportConfig.chart}
            chartableHeaders={chartableHeaders}
            chartData={chartData}
            compareDataLabel={compareDataLabel}
            loading={reportLoading}
            loadingVariant="overlay"
            mainDataLabel={mainDataLabel}
            onChangeChartHeader={(header) =>
              setViewState((c) => ({ ...c, selectedChartField: header.value }))
            }
            selectedChartField={viewState.selectedChartField}
          />
        </AppStack>
      )}

      <AppStack>
        <AppCard
          radius="md"
          shadow="lg"
          style={{ padding: 0, position: 'unset', overflow: 'visible' }}
          withBorder
        >
          <ReportTable
            loading={reportLoading}
            onHeaderFieldChange={(headers) => {
              setViewState((c) => ({
                ...c,
                headers
              }));
              onFetchReportData(headers, viewState.filter);
            }}
            report={reportResult?.report}
            reportConfig={reportConfig}
            showSummary
            uuid={reportResult?.uuid}
          />
        </AppCard>
      </AppStack>
    </AppStack>
  ) : (
    <></>
  );
};

AnalyticsReportView.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  onFetchReport: PropTypes.func,
  redirectPath: PropTypes.string,
  reportConfigs: PropTypes.array,
  reportResult: PropTypes.object
};

export default AnalyticsReportView;
