import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useHover } from '@mantine/hooks';
import { Image } from '@mantine/core';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppCheckbox from '../../common/AppCheckbox';
import AppText from '../../common/AppText';

const MediaDisplayCard = forwardRef(
  (
    {
      isMain,
      alt,
      preview,
      isSelected,
      onSelect,
      height,
      alwaysShowCheckbox,
      ...props
    },
    ref
  ) => {
    const { hovered, ref: hoverRef } = useHover();
    const onOverActionAvailable = onSelect && (hovered || isSelected);

    return (
      <AppCard
        ref={ref}
        shadow="sm"
        {...props}
        style={{
          padding: 0,
          height: height ?? 135,
          borderRadius: 10,
          ...(isMain
            ? {
                gridRowStart: 'span 2',
                gridColumnStart: 'span 2',
                gridRow: '1 / span 2',
                gridColumn: '1 / span 2',
                height: 280,
                borderRadius: 10
              }
            : {}),
          ...props?.style
        }}
        withBorder
      >
        <AppStack
          ref={hoverRef}
          onClick={() => {
            if (onSelect) {
              onSelect(preview);
            }
          }}
          style={{
            flex: 1,
            height: '100%',
            position: 'relative',
            pointerEvents: 'all',
            gap: 8,
            padding: alt ? 8 : 0,
            justifyContent: 'space-between',
            overflow: 'hidden'
          }}
        >
          {(onOverActionAvailable || alwaysShowCheckbox) && (
            <AppStack
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: onOverActionAvailable
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'unset',
                borderRadius: 10,
                padding: 8,
                cursor: 'pointer'
              }}
            >
              <AppStack style={{ flex: 1, justifyContent: 'space-between' }}>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  {(alwaysShowCheckbox || isSelected || hovered) && (
                    <AppCheckbox
                      checked={isSelected ?? false}
                      onChange={() => {}}
                    />
                  )}
                </AppFlexbox>
              </AppStack>
            </AppStack>
          )}
          <AppStack
            style={{
              justifyContent: 'center',
              height: '100%',
              maxHeight: 280,
              overflow: 'hidden'
            }}
          >
            <Image fit="contain" h="100%" src={preview} w="100%" />
          </AppStack>

          {alt && (
            <AppText
              style={{
                fontSize: 14,
                color: '#666',
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              {alt}
            </AppText>
          )}
        </AppStack>
      </AppCard>
    );
  }
);

MediaDisplayCard.propTypes = {
  alt: PropTypes.string,
  alwaysShowCheckbox: PropTypes.bool,
  height: PropTypes.number,
  isMain: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  preview: PropTypes.string
};

export default MediaDisplayCard;
