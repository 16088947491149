import { Video } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';

const configNameValues = {
  heading: 'heading',
  headingSize: '24',
  playOnLoop: 'playOnLoop',
  video: 'video',
  externalVideo: 'externalVideo',
  coverImage: 'coverImage',
  videoAltText: 'videoAltText',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  [configNameValues.heading]: 'Video',
  [configNameValues.headingSize]: '24',
  [configNameValues.playOnLoop]: 'false',
  [configNameValues.video]: '',
  [configNameValues.externalVideo]: '',
  [configNameValues.coverImage]: '',
  [configNameValues.videoAltText]: '',
  [configNameValues.colorScheme]: '1'
};

const configOptions = {
  [configNameValues.heading]: {
    label: 'Heading',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.headingSize]: {
    label: 'Heading Size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 100,
    unit: 'px'
  },
  [configNameValues.playOnLoop]: {
    label: 'Play on loop',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.video]: {
    label: 'Video',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  },
  [configNameValues.externalVideo]: {
    label: 'External video url',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.videoAltText]: {
    label: 'Video alt text',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.coverImage]: {
    label: 'Cover image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.VIDEO,
  title: 'Video',
  icon: Video,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: null,
  configOptions,
  maxItemCount: null,
  cannotCreate: true,
  blocks: [],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
