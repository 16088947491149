import React, { useContext, useEffect } from 'react';
import { Context as AuthState } from '../../../providers/AuthContextProvider';
import { Context as VendorContent } from '../../../providers/VendorContextProvider';
import { setupGuideConfig } from './vendorSetupGuideConfig';
import SetupGuideSidebar from '../../common/SetupGuideSidebar';

const VendorSetupGuideSidebar = () => {
  const { state: authState } = useContext(AuthState);
  const { state, fetchVendorSetupSummary } = useContext(VendorContent);
  const loading =
    !authState.pkEcomVendor ||
    state.ecomVendorSetupSummary.loading ||
    state.ecomVendorSetupSummary.value?.ecomVendor.pkEcomVendor.toString() !==
      authState.pkEcomVendor?.toString();

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchVendorSetupSummary(authState.pkEcomVendor);
    }
  }, [authState.pkEcomVendor]);

  return (
    <SetupGuideSidebar
      id={`skippedEcomVendorSetupStepsString-${authState.pkEcomVendor}`}
      loading={loading}
      setupGuideConfig={setupGuideConfig}
      state={state}
    />
  );
};

export default VendorSetupGuideSidebar;
