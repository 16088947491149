import React from 'react';
import PropTypes from 'prop-types';
import { BarChart } from '@mantine/charts';

const AnalyticsBarChart = ({
  mainDataLabel,
  compareDataLabel,
  chartData,
  dataKey,
  valueFormatter
}) => (
  <BarChart
    data={chartData}
    dataKey={dataKey}
    h={300}
    legendProps={{
      iconType: 'plainline',
      verticalAlign: 'bottom',
      align: 'center',
      height: 50
    }}
    orientation="vertical"
    series={
      compareDataLabel
        ? [
            {
              name: mainDataLabel,
              color: 'blue.6'
            },
            {
              name: compareDataLabel,
              color: 'rgba(18, 184, 134, 0.8)',
              strokeDasharray: '5 5'
            }
          ]
        : [{ name: mainDataLabel, color: 'blue.6' }]
    }
    valueFormatter={valueFormatter}
    withLegend
  />
);

AnalyticsBarChart.propTypes = {
  chartData: PropTypes.array,
  compareDataLabel: PropTypes.string,
  dataKey: PropTypes.string,
  mainDataLabel: PropTypes.string,
  valueFormatter: PropTypes.func
};

export default AnalyticsBarChart;
