import React, { useContext, useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import WebstoreMenuEditView from './WebstoreMenuEditView';
import WebstoreMenuListView from './WebstoreMenuListView';

const WebstoreMenusView = () => {
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreMenus, fetchStoreResourceOptions } = useContext(
    StoreContext
  );
  const isViewLoading = !hasFetched.current || state.ecomStoreMenus.loading;

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchStoreMenus(authState.pkEcomStore);
      fetchStoreResourceOptions(authState.pkEcomStore);
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  return (
    <Routes>
      <Route
        element={<WebstoreMenuEditView isViewLoading={isViewLoading} />}
        path="/new"
      />
      <Route
        element={
          <WebstoreMenuEditView duplicate isViewLoading={isViewLoading} />
        }
        path="/:pkEcomStoreMenu/duplicate"
      />
      <Route
        element={<WebstoreMenuEditView isViewLoading={isViewLoading} />}
        path="/:pkEcomStoreMenu"
      />
      <Route
        element={<WebstoreMenuListView isViewLoading={isViewLoading} />}
        path="*"
      />
    </Routes>
  );
};

WebstoreMenusView.propTypes = {};

export default WebstoreMenusView;
