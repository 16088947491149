import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsVendorTitleBlock from './WsVendorTitleBlock';

const FONT_STYLE_ENUM = {
  NORMAL: 'normal',
  ITALIC: 'italic'
};

const FONT_WEIGHT_ENUM = {
  NORMAL: 'normal',
  BOLD: 'bold',
  BOLDER: 'bolder',
  LIGHTER: 'lighter',
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900'
};

const TEXT_TRANSFORM_ENUM = {
  NONE: 'none',
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase',
  CAPITALIZE: 'capitalize'
};

const configNameValues = {
  fontSize: 'fontSize',
  fontStyle: 'fontStyle',
  fontWeight: 'fontWeight',
  textDecoration: 'textDecoration',
  textTransform: 'textTransform'
};

const configDefaultValues = {
  [configNameValues.fontSize]: '14',
  [configNameValues.fontWeight]: '500',
  [configNameValues.fontStyle]: FONT_STYLE_ENUM.NORMAL,
  [configNameValues.textTransform]: TEXT_TRANSFORM_ENUM.CAPITALIZE
};

const configOptions = {
  [configNameValues.fontSize]: {
    label: 'Text size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 24,
    unit: 'px'
  },
  [configNameValues.fontStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Text Style',
    options: [
      {
        value: FONT_STYLE_ENUM.NORMAL,
        label: 'Normal'
      },
      {
        value: FONT_STYLE_ENUM.ITALIC,
        label: 'Italic'
      }
    ]
  },
  [configNameValues.fontWeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Font weight',
    options: [
      {
        value: FONT_WEIGHT_ENUM.NORMAL,
        label: 'Normal'
      },
      {
        value: FONT_WEIGHT_ENUM.BOLD,
        label: 'Bold'
      },
      {
        value: FONT_WEIGHT_ENUM.BOLDER,
        label: 'Bolder'
      },
      {
        value: FONT_WEIGHT_ENUM.LIGHTER,
        label: 'Lighter'
      },
      {
        value: FONT_WEIGHT_ENUM[100],

        label: '100'
      },
      {
        value: FONT_WEIGHT_ENUM[200],
        label: '200'
      },
      {
        value: FONT_WEIGHT_ENUM[300],
        label: '300'
      },
      {
        value: FONT_WEIGHT_ENUM[400],
        label: '400 (Normal)'
      },
      {
        value: FONT_WEIGHT_ENUM[500],
        label: '500'
      },
      {
        value: FONT_WEIGHT_ENUM[600],
        label: '600'
      },
      {
        value: FONT_WEIGHT_ENUM[700],
        label: '700 (Bold)'
      },
      {
        value: FONT_WEIGHT_ENUM[800],
        label: '800'
      },
      {
        value: FONT_WEIGHT_ENUM[900],
        label: '900'
      }
    ]
  },
  [configNameValues.textTransform]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Text transform',
    options: [
      {
        value: TEXT_TRANSFORM_ENUM.NONE,
        label: 'None'
      },
      {
        value: TEXT_TRANSFORM_ENUM.UPPERCASE,
        label: 'Uppercase'
      },
      {
        value: TEXT_TRANSFORM_ENUM.LOWERCASE,
        label: 'Lowercase'
      },
      {
        value: TEXT_TRANSFORM_ENUM.CAPITALIZE,
        label: 'Capitalize'
      }
    ]
  }
};

const config = {
  title: 'Vendor name',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VENDOR_TITLE,
  icon: BoxMargin,
  Component: WsVendorTitleBlock,
  configOptions,
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
