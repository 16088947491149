import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateInput, DatePicker } from '@mantine/dates';
import { CalendarEvent, Clock } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Select } from '@mantine/core';
import ResponsiveModal from './ResponsiveModal';
import AppStack from './AppStack';
import AppText from './AppText';
import AppFlexbox from './AppFlexbox';
import { useMediaQueryIndex } from '../../helpers/hooks';

function generateTimeList() {
  const times = [];
  const totalMinutesInDay = 24 * 60; // 1440 minutes in a day
  const minutesPerHalfHour = 30;

  for (
    let minutes = 0;
    minutes < totalMinutesInDay;
    minutes += minutesPerHalfHour
  ) {
    const hours = Math.floor(minutes / 60);
    const label = new Date(0, 0, 0, hours, minutes % 60).toLocaleTimeString(
      'en-US',
      {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      }
    );
    times.push({
      value: minutes.toString(),
      label: minutes === 0 ? '12:00 AM (Start of day)' : label
    });
  }

  return times;
}

const SchedulerModal = ({
  isOpen,
  onClose,
  initialDate,
  onConfirm,
  minDate
}) => {
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();
  const dateInputRef = useRef();
  const timeInputRef = useRef();
  const [formState, setFormState] = useState({
    leftChartDate: null,
    rightChartDate: null,
    dateInputSelected: false,
    date: null,
    customTimeOption: null,
    time: null,
    errors: {}
  });
  const timeOptions = generateTimeList();
  const dateNow = initialDate ? dayjs(initialDate) : dayjs();
  const startOfMonth = dayjs(new Date(dateNow.year(), dateNow.month(), 1));

  useEffect(() => {
    if (isOpen) {
      const timeData = initialDate
        ? // eslint-disable-next-line no-use-before-define
          getTimeChange(dateNow.get('hour') * 60 + dateNow.get('minute'))
        : {};
      setFormState({
        leftChartDate: startOfMonth,
        rightChartDate: startOfMonth.add(1, 'month'),
        dateInputSelected: false,
        date: dateNow,
        ...timeData,
        errors: {}
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (dateInputRef.current && formState.dateInputSelected) {
      dateInputRef.current.focus();
    }
  }, [formState.dateInputSelected, dateInputRef.current]);

  const onSelectTimeOption = (time, onBlur = false) => {
    if (time || onBlur) {
      // eslint-disable-next-line no-use-before-define
      const timeData = getTimeChange(time);

      setFormState({
        ...formState,
        ...timeData,
        errors: {}
      });
    }
  };

  const getTimeChange = (time) => {
    const foundTimeOption = timeOptions.find(
      (timeOption) => timeOption.value === time.toString()
    );

    const timeNumber = parseInt(time, 10);
    const hours = Math.floor(timeNumber / 60);
    let hoursString = hours;
    if (hours === 0) {
      hoursString = 12;
    }
    else if (hours > 12) {
      hoursString = hours - 12;
    }
    const customTimeOption = foundTimeOption
      ? null
      : {
          value: time.toString(),
          label: `${hoursString}:${(timeNumber - hours * 60).toLocaleString(
            'en-US',
            {
              minimumIntegerDigits: 2,
              useGrouping: false
            }
          )} ${timeNumber / 60 > 12 ? 'PM' : 'AM'}`
        };

    return {
      timeSearch: foundTimeOption
        ? foundTimeOption.label
        : customTimeOption.label,
      customTimeOption,
      time: time.toString()
    };
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: false,
        onCancel: onClose,
        onSubmit: () => {
          if (!formState.date || !formState.time) {
            setFormState({
              ...formState,
              errors: {
                date: !formState.date ? 'Please select a date' : null,
                time: !formState.time ? 'Please select a time' : null
              }
            });
          }
          else {
            const dateTime = dayjs(
              new Date(
                formState.date.get('y'),
                formState.date.get('M'),
                formState.date.get('D')
              )
            ).add(parseInt(formState.time, 10), 'minutes');
            onConfirm(dateTime);
          }
        },
        submitTitle: 'Schedule product'
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Schedule store publishing"
    >
      <AppStack style={{ gap: 20 }}>
        <AppText style={{ fontSize: 14, color: '#666' }}>
          Choose a date and time to schedule publishing the product to your
          store.
        </AppText>
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
          }}
        >
          {formState.dateInputSelected ? (
            <DateInput
              key={1}
              ref={dateInputRef}
              error={formState.errors?.date}
              leftSection={<CalendarEvent size={20} />}
              minDate={minDate}
              onBlur={() => {
                setFormState({
                  ...formState,
                  dateInputSelected: false
                });
              }}
              onChange={(e) => {
                const date = dayjs(e);
                const dateStartOfMonth = dayjs(
                  new Date(date.year(), date.month(), 1)
                );

                const updateCalendar =
                  date.isBefore(formState.leftChartDate) ||
                  date.isAfter(formState.rightChartDate);
                setFormState({
                  ...formState,
                  date: dayjs(e),
                  leftChartDate: updateCalendar
                    ? dateStartOfMonth
                    : formState.leftChartDate,
                  rightChartDate: updateCalendar
                    ? dateStartOfMonth.add(1, 'month')
                    : formState.rightChartDate,
                  errors: {}
                });
              }}
              popoverProps={{ styles: { dropdown: { display: 'none' } } }}
              style={{ flex: 1 }}
              value={formState.date}
              valueFormat="YYYY-MM-DD"
            />
          ) : (
            <DateInput
              key={2}
              error={formState.errors?.date}
              leftSection={<CalendarEvent size={20} />}
              minDate={minDate}
              onChange={(e) => {}}
              onClick={() => {
                setFormState({
                  ...formState,
                  dateInputSelected: true
                });
              }}
              popoverProps={{ styles: { dropdown: { display: 'none' } } }}
              style={{ flex: 1 }}
              value={formState.date}
              valueFormat="MMMM D, YYYY"
            />
          )}
          <Select
            clearable
            data={
              !formState.customTimeOption
                ? timeOptions
                : [...timeOptions, formState.customTimeOption].sort(
                    (a, b) => a.value - b.value
                  )
            }
            error={formState.errors?.time}
            leftSection={
              <Clock
                onClick={() => timeInputRef.current?.showPicker()}
                size={20}
              />
            }
            onBlur={() => {
              const isPM = formState.timeSearch?.toLowerCase().includes('p');
              const time = formState.timeSearch?.replace(/[^0-9:]/g, '');
              if (time) {
                const timeParts = time.split(':');
                let hours = parseInt(timeParts[0], 10);
                const minutes = parseInt(timeParts[1], 10);

                if (isPM && hours < 12) {
                  hours += 12;
                }
                else if (!isPM && hours === 12) {
                  hours = 0;
                }
                onSelectTimeOption(hours * 60 + minutes, true);
              }
            }}
            onChange={onSelectTimeOption}
            onSearchChange={(e) => {
              setFormState({
                ...formState,
                timeSearch: e
              });
            }}
            placeholder="Time"
            rightSection="EDT"
            searchable
            searchValue={formState.timeSearch}
            style={{ flex: 1 }}
          />
        </AppFlexbox>
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
          }}
        >
          <AppStack style={{ alignItems: 'center', flex: 1 }}>
            <DatePicker
              date={formState.leftChartDate}
              hideOutsideDates
              minDate={minDate}
              onChange={(e) => {
                const date = dayjs(e);
                setFormState({
                  ...formState,
                  date,
                  errors: {}
                });
              }}
              onDateChange={(e) => {
                const date = dayjs(e);
                setFormState({
                  ...formState,
                  leftChartDate: date,
                  rightChartDate: date.add(1, 'month'),
                  errors: {}
                });
              }}
              style={{ flex: 1, justifyContent: 'center' }}
              value={
                formState.date?.isBefore(formState.rightChartDate)
                  ? formState.date
                  : null
              }
            />
          </AppStack>
          <AppStack style={{ alignItems: 'center', flex: 1 }}>
            <DatePicker
              date={formState.rightChartDate}
              hideOutsideDates
              minDate={minDate}
              onChange={(e) => {
                const date = dayjs(e);
                setFormState({
                  ...formState,
                  date,
                  errors: {}
                });
              }}
              onDateChange={(e) => {
                const dateChange = dayjs(e);
                setFormState({
                  ...formState,
                  leftChartDate: dateChange.subtract(1, 'month'),
                  rightChartDate: dateChange,
                  errors: {}
                });
              }}
              style={{ flex: 1, justifyContent: 'center' }}
              value={
                formState.date?.isAfter(
                  formState.rightChartDate.subtract(1, 'day')
                )
                  ? formState.date
                  : null
              }
            />
          </AppStack>
        </AppFlexbox>
      </AppStack>
    </ResponsiveModal>
  );
};

SchedulerModal.propTypes = {
  initialDate: PropTypes.object,
  isOpen: PropTypes.bool,
  minDate: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default SchedulerModal;
