/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import WsCollectionCard from '../../sections/collectionGrid/WsCollectionCard';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';

const WsCollectionBlock = ({
  config,
  baseStoreUrl,
  onNavigate,
  colorScheme,
  portraitRatio,
  ...rest
}) => {
  const { state } = useContext(StoreUserContext);
  const ecomStoreCollection = state.ecomStoreCollections.value.find(
    (c) =>
      c.pkEcomStoreCollection.toString() ===
      config.ecomStoreCollection?.toString()
  );
  const collectionNotFound = state.ecomStoreCollections.idsNotFound.includes(
    config.ecomStoreCollection?.toString()
  );

  return (
    <WsCollectionCard
      baseStoreUrl={baseStoreUrl}
      colorScheme={colorScheme}
      ecomStoreCollection={ecomStoreCollection}
      noCollection={!config.ecomStoreCollection || collectionNotFound}
      onNavigate={onNavigate}
      portraitRatio={portraitRatio}
      {...rest}
    />
  );
};

WsCollectionBlock.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorScheme: PropTypes.object,
  config: PropTypes.object,
  onNavigate: PropTypes.func,
  portraitRatio: PropTypes.bool
};

export default WsCollectionBlock;
