/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppText from '../../../../common/AppText';
import { configNameValues } from './wsCaptionConfig';

const WsCaptionBlock = ({ config }) => (
  <AppText
    style={{
      fontSize: config[configNameValues.fontSize] * 1,
      fontStyle: config[configNameValues.fontStyle],
      fontWeight: config[configNameValues.fontWeight],
      textDecoration: config[configNameValues.textDecoration],
      textTransform: config[configNameValues.textTransform],
      wordBreak: 'break-word',
      overflowWrap: 'break-word'
    }}
  >
    {config.text}
  </AppText>
);

WsCaptionBlock.propTypes = { config: PropTypes.object };

export default WsCaptionBlock;
