import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  Image,
  Loader,
  Select,
  TextInput
} from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import { getEcomVendorProductStatus } from '../../../helpers/vendorHelper';

const VendorCollectionProductAssignModal = ({
  isOpen,
  onClose,
  onConfirm,
  uuids
}) => {
  const navigate = useNavigate();
  const { state } = useContext(VendorContext);
  const [formState, setFormState] = useState({
    uuids,
    productSearch: '',
    category: ''
  });

  const categoryOptions = state.ecomVendorProducts.value
    .reduce(
      (r, c) => {
        if (
          c.fkEcomCategory &&
          !r.find((e) => e.value === c.fkEcomCategory.toString())
        ) {
          const category = PRODUCT_CATEGORY_LIST.find(
            (e) => e.value.toString() === c.fkEcomCategory.toString()
          );
          if (category) {
            r.push({
              label: category.name,
              value: c.fkEcomCategory.toString()
            });
          }
        }
        return r;
      },
      [
        {
          label: 'Fundraising',
          value: 'fundraising'
        }
      ]
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  const filteredProducts = state.ecomVendorProducts.value
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(
      (e) =>
        (!formState.productSearch ||
          e.name
            .toLowerCase()
            .includes(formState.productSearch.toLowerCase()) ||
          e.description
            .toLowerCase()
            .includes(formState.productSearch.toLowerCase())) &&
        (!formState.category ||
          (formState.category === 'fundraiser'
            ? e.fundraisingRecommended
            : e.fkEcomCategory?.toString() === formState.category))
    );

  const isAllSelected = filteredProducts.every((p) =>
    formState.uuids.includes(p.uuid)
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        uuids: uuids ?? [],
        productSearch: '',
        category: ''
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: () => {
          onConfirm(formState.uuids);
        },
        padding: 0,
        submitTitle: 'Confirm'
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={600}
      title="Edit products in collection"
    >
      <AppStack
        style={{
          gap: 0,
          minHeight: 400,
          overflow: 'auto',
          flex: 1
        }}
      >
        <AppStack style={{ padding: 5, gap: 5 }}>
          <TextInput
            onChange={(e) =>
              setFormState({
                ...formState,
                productSearch: e.currentTarget.value
              })
            }
            placeholder="Filter by title..."
            style={{ flex: 1 }}
            value={formState.productSearch}
          />
          <Select
            clearable
            data={categoryOptions}
            onChange={(v) =>
              setFormState({
                ...formState,
                category: v
              })
            }
            placeholder="Filter by category..."
            searchable
            style={{ flex: 1 }}
            value={formState.category}
          />
        </AppStack>
        <Divider />
        <AppFlexbox
          style={{
            gap: 5,
            padding: 8,
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#f3f3f3'
          }}
        >
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>Products</AppText>

          <Checkbox
            checked={isAllSelected}
            label="Select all"
            onChange={() => {
              setFormState({
                ...formState,
                uuids: isAllSelected
                  ? formState.uuids.filter(
                      (e) => !filteredProducts.map((p) => p.uuid).includes(e)
                    )
                  : [
                      ...formState.uuids,
                      ...filteredProducts
                        .filter((v) => !formState.uuids.includes(v.uuid))
                        .map((p) => p.uuid)
                    ]
              });
            }}
            styles={{
              label: { fontWeight: 500, cursor: 'pointer' },
              input: { cursor: 'pointer' }
            }}
          />
        </AppFlexbox>
        <Divider />
        <AppStack
          style={{
            gap: 0,
            flex: 1
          }}
        >
          {state.ecomVendorProducts.loading ? (
            <AppStack
              style={{
                gap: 5,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 10px',
                flex: 1
              }}
            >
              <Loader color="dark" size={34} />
            </AppStack>
          ) : filteredProducts.length === 0 ? (
            <AppStack
              style={{
                gap: 5,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 10px'
              }}
            >
              <AppStack style={{ padding: 16, fontWeight: 500, color: '#666' }}>
                No products found.
              </AppStack>
              <Button
                color="dark"
                leftSection={<Plus size={18} />}
                onClick={() => {
                  navigate('/vendor/products/new');
                }}
                type="button"
                variant="outline"
              >
                Add a new product
              </Button>
            </AppStack>
          ) : (
            filteredProducts
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((p) => {
                const productStatusInfo = getEcomVendorProductStatus(p);
                return (
                  <AppFlexbox
                    key={p.uuid}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        uuids: formState.uuids.includes(p.uuid)
                          ? formState.uuids.filter((e) => e !== p.uuid)
                          : [...formState.uuids, p.uuid]
                      });
                    }}
                    style={{
                      padding: '10px 16px',
                      gap: 16,
                      alignItems: 'center',
                      borderBottom: 'solid 1px #dee2e6',
                      cursor: 'pointer'
                    }}
                  >
                    <Checkbox
                      checked={formState.uuids.includes(p.uuid)}
                      onChange={() => {}}
                    />
                    <AppStack
                      style={{
                        width: 40,
                        height: 40,
                        border: 'solid 1px #dee2e6'
                      }}
                    >
                      <Image h={40} src={p.previewImage} w={40} />
                    </AppStack>
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {p.name}
                      </AppText>
                      <AppText
                        style={{ fontSize: 12, color: productStatusInfo.color }}
                      >
                        {productStatusInfo.label}
                      </AppText>
                    </AppStack>
                  </AppFlexbox>
                );
              })
          )}
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

VendorCollectionProductAssignModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  uuids: PropTypes.array
};

export default VendorCollectionProductAssignModal;
