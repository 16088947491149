import { Message } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsTextOverImageBlock from './WsTextOverImageBlock';

const textLinkConfigValues = {
  IMAGE: 'image',
  TEXT: 'text',
  LINK: 'link'
};

const IMAGE_WITH_TEXT_HEADING_SIZE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

const IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end'
};

const IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM = {
  TOP_LEFT: 'start-start',
  TOP_CENTER: 'start-center',
  TOP_RIGHT: 'start-end',
  CENTER_LEFT: 'center-start',
  CENTER: 'center-center',
  CENTER_RIGHT: 'center-end',
  BOTTOM_LEFT: 'end-start',
  BOTTOM_CENTER: 'end-center',
  BOTTOM_RIGHT: 'end-end'
};

const configNameValues = {
  image: 'image',
  flipLeftToRight: 'flipLeftToRight',
  flipUpsideDown: 'flipUpsideDown',
  heading: 'heading',
  headingSize: 'headingSize',
  subheading: 'subheading',
  subheadingSize: 'subheadingSize',
  buttonLabel: 'buttonLabel',
  buttonLink: 'buttonLink',
  useOutlinedButton: 'useOutlinedButton',
  desktopContentPosition: 'desktopContentPosition',
  desktopContentTopMargin: 'desktopContentTopMargin',
  desktopContentBottomMargin: 'desktopContentBottomMargin',
  desktopContentSideMargin: 'desktopContentSideMargin',
  showContainerOnDesktop: 'showContainerOnDesktop',
  fullWidthContainerPlacement: 'fullWidthContainerPlacement',
  desktopContentAlignment: 'desktopContentAlignment',
  imageOverlayOpacity: 'imageOverlayOpacity',
  colorScheme: 'colorScheme',
  mobileContentAlignment: 'mobileContentAlignment'
};

const configDefaultValues = {
  [configNameValues.image]: null,
  [configNameValues.flipLeftToRight]: 'false',
  [configNameValues.flipUpsideDown]: 'false',
  [configNameValues.heading]: 'HERE FOR THE HEAT',
  [configNameValues.headingSize]: '36',
  [configNameValues.subheading]: 'Summer styles to turn up your game',
  [configNameValues.subheadingSize]: '18',
  [configNameValues.buttonLabel]: 'Shop',
  [configNameValues.buttonLink]: '',
  [configNameValues.useOutlinedButton]: 'false',
  [configNameValues.desktopContentPosition]:
    IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_LEFT,
  [configNameValues.desktopContentTopMargin]: '0',
  [configNameValues.desktopContentBottomMargin]: '5',
  [configNameValues.desktopContentSideMargin]: '5',
  [configNameValues.showContainerOnDesktop]: 'false',
  [configNameValues.fullWidthContainerPlacement]: 'true',
  [configNameValues.desktopContentAlignment]:
    IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
  [configNameValues.imageOverlayOpacity]: '0',
  [configNameValues.colorScheme]: '2',
  [configNameValues.mobileContentAlignment]:
    IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER
};

const configOptions = {
  [configNameValues.image]: {
    label: 'Image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  },
  [configNameValues.flipLeftToRight]: {
    label: 'Flip left to right',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.flipUpsideDown]: {
    label: 'Flip upside down',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.heading]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Heading'
  },
  [configNameValues.headingSize]: {
    label: 'Heading Size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 100,
    unit: 'px'
  },
  [configNameValues.subheading]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Text'
  },
  [configNameValues.subheadingSize]: {
    label: 'Subheading Size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 10,
    max: 20,
    unit: 'px'
  },
  [configNameValues.buttonLabel]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Button label'
  },
  [configNameValues.buttonLink]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Button link'
  },
  [configNameValues.useOutlinedButton]: {
    label: 'Use outlined button style',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.desktopContentPosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Desktop content position',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_LEFT,
        label: 'Top left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_CENTER,
        label: 'Top center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_RIGHT,
        label: 'Top right'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER_LEFT,
        label: 'Middle left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER,
        label: 'Middle center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER_RIGHT,
        label: 'Middle right'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_LEFT,
        label: 'Bottom left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_CENTER,
        label: 'Bottom center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_RIGHT,
        label: 'Bottom right'
      }
    ]
  },
  [configNameValues.desktopContentTopMargin]: {
    label: 'Content top margin',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 0,
    max: 100,
    unit: '%'
  },
  [configNameValues.desktopContentBottomMargin]: {
    label: 'Content bottom margin',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 0,
    max: 100,
    unit: '%'
  },
  [configNameValues.desktopContentSideMargin]: {
    label: 'Content side margin',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 0,
    max: 100,
    unit: '%'
  },
  [configNameValues.showContainerOnDesktop]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Show container on desktop'
  },
  [configNameValues.fullWidthContainerPlacement]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Full width container placement'
  },
  [configNameValues.desktopContentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop content alignment',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
        label: 'Center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  },
  [configNameValues.imageOverlayOpacity]: {
    label: 'Image overlay opacity',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 0,
    max: 100,
    unit: '%'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.mobileContentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Mobile content alignment',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
        label: 'Center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  }
};

const config = {
  title: 'Text over image',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT_OVER_IMAGE,
  icon: Message,
  Component: WsTextOverImageBlock,
  configOptions,
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  IMAGE_WITH_TEXT_HEADING_SIZE_ENUM,
  textLinkConfigValues
};
