/* eslint-disable import/no-cycle */
import { Slideshow } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsSlideshow from './WsSlideshow';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';

const SLIDESHOW_LAYOUT_TYPE = {
  FULL_WIDTH: 'full-width',
  GRID: 'grid'
};

const SLIDESHOW_SLIDE_HEIGHT_ENUM = {
  ADAPT_TO_IMAGE: 'adapt-to-first-image',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  CUSTOM: 'custom'
};

const SLIDESHOW_PAGINATION_STYLE_ENUM = {
  DOTS: 'dots',
  COUNTER: 'counter',
  NUMBERS: 'numbers'
};

const SLIDESHOW_IMAGE_POSITION_ENUM = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom'
};

const configNameValues = {
  displayType: 'displayType',
  slideHeight: 'slideHeight',
  customSlideHeight: 'customSlideHeight',
  objectPosition: 'objectPosition',
  paginationStyle: 'paginationStyle',
  autoRotate: 'autoRotate',
  pauseOnHover: 'pauseOnHover',
  changeEvery: 'changeEvery',
  fullWidth: 'fullWidth'
};

const configDefaultValues = {
  [configNameValues.displayType]: SLIDESHOW_LAYOUT_TYPE.FULL_WIDTH,
  [configNameValues.slideHeight]: SLIDESHOW_SLIDE_HEIGHT_ENUM.ADAPT_TO_IMAGE,
  [configNameValues.customSlideHeight]: '450',
  [configNameValues.objectPosition]: SLIDESHOW_IMAGE_POSITION_ENUM.BOTTOM,
  [configNameValues.paginationStyle]: SLIDESHOW_PAGINATION_STYLE_ENUM.DOTS,
  [configNameValues.autoRotate]: 'true',
  [configNameValues.pauseOnHover]: 'true',
  [configNameValues.changeEvery]: '5',
  [configNameValues.fullWidth]: 'true'
};

const configOptions = {
  [configNameValues.displayType]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Layout',
    options: [
      { value: SLIDESHOW_LAYOUT_TYPE.FULL_WIDTH, label: 'Full width' },
      { value: SLIDESHOW_LAYOUT_TYPE.GRID, label: 'Grid' }
    ]
  },
  [configNameValues.slideHeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Image height',
    options: [
      {
        value: SLIDESHOW_SLIDE_HEIGHT_ENUM.ADAPT_TO_IMAGE,
        label: 'Adapt to first image'
      },
      { value: SLIDESHOW_SLIDE_HEIGHT_ENUM.SMALL, label: 'Small' },
      { value: SLIDESHOW_SLIDE_HEIGHT_ENUM.MEDIUM, label: 'Medium' },
      { value: SLIDESHOW_SLIDE_HEIGHT_ENUM.LARGE, label: 'Large' },
      { value: SLIDESHOW_SLIDE_HEIGHT_ENUM.CUSTOM, label: 'Custom' }
    ]
  },
  [configNameValues.customSlideHeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    label: 'Custom height',
    min: 200,
    max: 1200,
    unit: 'px',
    isVisible: (config) =>
      config.slideHeight === SLIDESHOW_SLIDE_HEIGHT_ENUM.CUSTOM
  },
  [configNameValues.objectPosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Image position',
    options: [
      { value: SLIDESHOW_IMAGE_POSITION_ENUM.TOP, label: 'Top' },
      { value: SLIDESHOW_IMAGE_POSITION_ENUM.CENTER, label: 'Center' },
      { value: SLIDESHOW_IMAGE_POSITION_ENUM.BOTTOM, label: 'Bottom' }
    ]
  },
  [configNameValues.paginationStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Pagination style',
    options: [
      { value: SLIDESHOW_PAGINATION_STYLE_ENUM.DOTS, label: 'Dots' },
      {
        value: SLIDESHOW_PAGINATION_STYLE_ENUM.COUNTER,
        label: 'Counter'
      },
      { value: SLIDESHOW_PAGINATION_STYLE_ENUM.NUMBERS, label: 'Numbers' }
    ]
  },
  [configNameValues.autoRotate]: {
    label: 'Auto-rotate slides',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.pauseOnHover]: {
    label: 'Pause on hover',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    isVisible: (config) => config.autoRotate === 'true'
  },
  [configNameValues.changeEvery]: {
    label: 'Change every',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 3,
    max: 10,
    unit: 's',
    isVisible: (config) => config.autoRotate === 'true'
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.SLIDESHOW,
  title: 'Slideshow',
  icon: Slideshow,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsSlideshow,
  configOptions,
  maxItemCount: 5,
  cannotCreate: true,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT_OVER_IMAGE
      ],
      title: 'Image slide',
      defaultCount: 2,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  SLIDESHOW_LAYOUT_TYPE,
  SLIDESHOW_SLIDE_HEIGHT_ENUM,
  SLIDESHOW_PAGINATION_STYLE_ENUM
};
