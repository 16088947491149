import React, { useContext, useEffect, useState } from 'react';
import { TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';

const WebstoreThemeEditModal = ({ isOpen, onClose, ecomStoreTheme }) => {
  const { updateStoreTheme } = useContext(StoreContext);
  const [formState, setFormState] = useState({ name: '', loading: false });

  useEffect(() => {
    // Fetch data
    if (isOpen && ecomStoreTheme) {
      setFormState({ name: ecomStoreTheme.name, loading: false });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        onCancel: onClose,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          updateStoreTheme(
            ecomStoreTheme.pkEcomStoreTheme,
            { name: formState.name, replaceListState: true },
            () => {
              triggerNotification('Theme renamed!', 'success');
              onClose();
            },
            (error) => {
              triggerNotification(error);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        },
        submitTitle: 'Save'
      }}
      isOpen={isOpen && !!ecomStoreTheme}
      onClose={onClose}
      opened={isOpen}
      title="Change theme name"
    >
      <TextInput
        disabled={formState.loading}
        label="Theme name"
        onChange={(e) =>
          setFormState({
            ...formState,
            name: e.target.value
          })
        }
        placeholder="Theme name"
        required
        value={formState.name}
      />
    </ResponsiveModal>
  );
};

WebstoreThemeEditModal.propTypes = {
  ecomStoreTheme: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default WebstoreThemeEditModal;
