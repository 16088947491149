import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM,
  ECOM_ENTITY_TYPES
} from '../../../../../config/constants';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';
import WsCollectionList from './WsCollectionList';

const HEADING_SIZE_STYLE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  HUGE: 'huge'
};

const VIEW_ALL_STYLE_ENUM = {
  LINK: 'LINK',
  OUTLINE_BUTTON: 'OUTLINE_BUTTON',
  SOLID_BUTTON: 'SOLID_BUTTON'
};

const IMAGE_DISPLAY_ENUM = {
  PORTRAIT: 'PORTRAIT',
  SQUARE: 'SQUARE'
};

const configNameValues = {
  headingText: 'headingText',
  headingSize: 'headingSize',
  descriptionText: 'descriptionText',
  maximumProductsDisplayed: 'maximumProductsDisplayed',
  productsFullWidth: 'productsFullWidth',
  desktopColumnCount: 'desktopColumnCount',
  enableViewAll: 'enableViewAll',
  colorScheme: 'colorScheme',
  imageRatio: 'imageRatio',
  desktopCarousel: 'desktopCarousel',
  viewAllButtonStyle: 'viewAllButtonStyle',
  mobileColumnCount: 'mobileColumnCount',
  mobileCarousel: 'mobileCarousel'
};

const configDefaultValues = {
  [configNameValues.headingText]: 'Collection list',
  [configNameValues.headingSize]: '24',
  [configNameValues.descriptionText]: '',
  [configNameValues.productsFullWidth]: 'false',
  [configNameValues.desktopColumnCount]: '3',
  [configNameValues.enableViewAll]: 'true',
  [configNameValues.colorScheme]: '1',
  [configNameValues.maximumProductsDisplayed]: '6',
  [configNameValues.imageRatio]: IMAGE_DISPLAY_ENUM.SQUARE,
  [configNameValues.desktopCarousel]: 'false',
  [configNameValues.enableViewAll]: 'true',
  [configNameValues.viewAllButtonStyle]: VIEW_ALL_STYLE_ENUM.OUTLINE_BUTTON,
  [configNameValues.mobileColumnCount]: '2',
  [configNameValues.mobileCarousel]: 'false'
};

const configOptions = {
  [configNameValues.headingText]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Heading'
  },
  [configNameValues.headingSize]: {
    label: 'Heading size',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      {
        value: HEADING_SIZE_STYLE_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.HUGE,
        label: 'Huge'
      }
    ]
  },
  [configNameValues.descriptionText]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    isTextArea: true,
    label: 'Description',
    isVisible: (config) => config.showCollectionDescription !== 'true'
  },
  [configNameValues.maximumProductsDisplayed]: {
    label: 'Maximum collections displayed',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 2,
    max: 20,
    unit: ''
  },
  [configNameValues.desktopColumnCount]: {
    label: 'Desktop column count',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 1,
    max: 6,
    unit: ''
  },
  [configNameValues.productsFullWidth]: {
    label: 'Stretch collections full width',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.enableViewAll]: {
    label:
      'Show a "view all" button when there are more collections than displayed',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.viewAllButtonStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: '"View all" button style',
    options: [
      {
        value: VIEW_ALL_STYLE_ENUM.LINK,
        label: 'Link'
      },
      {
        value: VIEW_ALL_STYLE_ENUM.OUTLINE_BUTTON,
        label: 'Outline button'
      },
      {
        value: VIEW_ALL_STYLE_ENUM.SOLID_BUTTON,
        label: 'Solid button'
      }
    ],
    isVisible: (config) => config.enableViewAll === 'true'
  },
  [configNameValues.desktopCarousel]: {
    label: 'Enable desktop carousel',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.imageRatio]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Image ratio',
    category: 'Product display',
    options: [
      {
        value: IMAGE_DISPLAY_ENUM.PORTRAIT,
        label: 'Portrait'
      },
      {
        value: IMAGE_DISPLAY_ENUM.SQUARE,
        label: 'Square'
      }
    ]
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.mobileColumnCount]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Mobile column count',
    category: 'Mobile layout',
    options: [
      {
        value: '1',
        label: '1 column'
      },
      {
        value: '2',
        label: '2 columns'
      }
    ]
  },
  [configNameValues.mobileCarousel]: {
    label: 'Enable mobile carousel',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Mobile layout'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.COLLECTION_LIST,
  title: 'Collection list',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsCollectionList,
  configOptions,
  maxItemCount: 21,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.COLLECTION
      ],
      defaultCount: 3,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  HEADING_SIZE_STYLE_ENUM,
  VIEW_ALL_STYLE_ENUM,
  IMAGE_DISPLAY_ENUM
};
