import Papa from 'papaparse';

const downloadPapaCsv = (fileName, columnValues, rows) => {
  const csv = Papa.unparse(
    {
      fields: columnValues,
      data: rows,
      dynamicTyping: false
    },
    { dynamicTyping: false }
  );
  const blob = new Blob([csv]);
  const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
  const link = document.createElement('a');
  link.setAttribute('href', csvURL);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};

const parsePapaCsv = (
  file,
  columns,
  onSuccessCallback,
  onErrorCallback,
  config = { skipRequiredCheck: false }
) => {
  Papa.parse(file, {
    dynamicTyping: false,
    complete: (results) => {
      const headers = columns.map((a) => ({
        ...a,
        columnIndex: results.data[0].findIndex(
          (h) =>
            h.replace(' ', '').toLowerCase() ===
            a.label.replace(' ', '').toLowerCase()
        )
      }));
      const foundHeaders = headers.filter((h) => h.columnIndex !== -1);
      const missingHeaders = headers.filter((h) => h.columnIndex === -1);
      const missingRequiredHeader = missingHeaders.find((h) => h.required);

      if (!config.skipRequiredCheck && missingRequiredHeader) {
        onErrorCallback(
          `The uploaded file is missing the required column: ${missingRequiredHeader.value}`
        );
      }
      else if (foundHeaders.length === 0) {
        onErrorCallback('The uploaded file is missing all required columns');
      }
      else {
        const resultData = results.data.filter((d) => d.some((c) => c));
        const data = [];
        for (let i = 1; i < resultData.length; i += 1) {
          const row = resultData[i];

          const rowData = {};
          for (let j = 0; j < foundHeaders.length; j += 1) {
            const header = foundHeaders[j];

            if (header.options) {
              const foundOption = header.options.find(
                (f) => f.toLowerCase() === row[header.columnIndex].toLowerCase()
              );
              rowData[header.dataValue] = foundOption ?? null;
            }
            else {
              rowData[header.dataValue] = row[header.columnIndex];
            }

            if (
              !config.skipRequiredCheck &&
              header.required &&
              !row[header.dataValue]
            ) {
              onErrorCallback(
                `The uploaded file is missing a value for the required column: ${header.label}`
              );
              return;
            }
          }
          data.push(rowData);
        }
        onSuccessCallback(data, { headers, foundHeaders });
      }
    }
  });
};

export { downloadPapaCsv, parsePapaCsv };
