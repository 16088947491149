/* eslint-disable import/no-cycle */
import { ShoppingCart } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM
} from '../../../../../config/constants';
import WsCheckoutContent from './WsCheckoutContent';

const configNameValues = { colorScheme: 'colorScheme' };

const configDefaultValues = { [configNameValues.colorScheme]: '1' };

const configOptions = {
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.CHECKOUT_CONTENT,
  title: 'Cart content',
  icon: ShoppingCart,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsCheckoutContent,
  configOptions,
  isPermanent: true,
  cannotCreate: true,
  maxItemCount: null,
  blocks: [],
  defaultConfig: configDefaultValues,
  getTitle: (section, pathname) => {
    const titles = { '/checkout/cart': 'Cart details' };

    for (const key in titles) {
      if (pathname.startsWith(key)) {
        return titles[key];
      }
    }

    return 'Cart content';
  }
};

export { config, configOptions, configNameValues, configDefaultValues };
