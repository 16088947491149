import React, { useContext, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import '@mantine/core/styles.css';
// eslint-disable-next-line import/no-unresolved
import '@mantine/notifications/styles.css';
// eslint-disable-next-line import/no-unresolved
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/carousel/styles.css';
import './styles/globalStyles.module.css';
import './styles/nestedStyles.module.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate
} from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import {
  COGNITO_SETTING_DEV,
  COGNITO_SETTING_PROD,
  PRODUCTION
} from './config/constants';
import AppShell from './components/common/AppShell';
import { theme } from './styles/theme';
import HomeView from './views/HomeView';
import {
  Provider as AuthContextProvider,
  Context as AuthContext
} from './providers/AuthContextProvider';
import { Provider as AdminContextProvider } from './providers/AdminContextProvider';
import { Provider as VendorContextProvider } from './providers/VendorContextProvider';
import { Provider as StoreContextProvider } from './providers/StoreContextProvider';
import { Provider as StoreUserContextProvider } from './providers/StoreUserContextProvider';
import { Provider as HelperContextProvider } from './providers/HelperContextProvider';
import AuthView from './views/AuthView';
import UserViews from './views/UserViews';
import Header from './components/content/navigation/Header';
import WsStoreView from './components/content/webstore/WsStoreView';
import NotFoundView from './views/NotFoundView';
import RedirectViews from './views/RedirectViews';
// import { Provider } from 'react-redux';
// import { store } from './store';

Auth.configure(PRODUCTION ? COGNITO_SETTING_PROD : COGNITO_SETTING_DEV);
Storage.configure(PRODUCTION ? COGNITO_SETTING_PROD : COGNITO_SETTING_DEV);

const hiddenNavRoutes = [/\/merchant\/webstore\/themes\/(.*)\/(.*)/];

const App = () => {
  const { pathname } = useLocation();
  const { state: authState, tokenSignIn } = useContext(AuthContext);
  const showHeader = !hiddenNavRoutes.some((r) => pathname.match(r));

  useEffect(() => {
    if (!authState.tokenAttempted) {
      tokenSignIn();
    }
  }, [authState.tokenAttempted]);

  return pathname.startsWith('/store') ? (
    <AppShell>
      <Routes>
        <Route element={<NotFoundView />} path="/store/404" />
        <Route element={<WsStoreView />} path="/store/:urlHandle/*" />
        <Route element={<Navigate replace to="/store/404" />} path="*" />
      </Routes>
    </AppShell>
  ) : (
    <Routes>
      <Route
        element={
          <AppShell>
            <RedirectViews />
          </AppShell>
        }
        path="/redirect/:action/:entityId/*"
      />
      <Route
        element={
          <AppShell>
            <RedirectViews />
          </AppShell>
        }
        path="/redirect/*"
      />
      <Route
        element={
          <AppShell header={showHeader && <Header />}>
            <Routes>
              <Route element={<AuthView />} path="/auth/*" />
              <Route element={<HomeView />} path="/" />
              <Route element={<UserViews />} path="*" />
            </Routes>
          </AppShell>
        }
        path="*"
      />
    </Routes>
  );
};

export default () => (
  // <Provider store={store}>
  <AuthContextProvider>
    <AdminContextProvider>
      <VendorContextProvider>
        <StoreContextProvider>
          <StoreUserContextProvider>
            <HelperContextProvider>
              <MantineProvider theme={theme}>
                <Notifications
                  position="top-right"
                  style={{ marginTop: 50, zIndex: 9999 }}
                />
                <Router>
                  <App />
                </Router>
              </MantineProvider>
            </HelperContextProvider>
          </StoreUserContextProvider>
        </StoreContextProvider>
      </VendorContextProvider>
    </AdminContextProvider>
  </AuthContextProvider>
  // </Provider>
);
