/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@mantine/core';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppImage from '../../../../common/AppImage';
import WsBlock from '../../blocks/WsBlock';
import AppText from '../../../../common/AppText';

const WsCollapsibleContent = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  ecomStoreTheme,
  baseStoreUrl,
  onNavigate,
  ...rest
}) => {
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack
        style={{
          flex: 1,
          gap: 5,
          backgroundColor:
            config.filledContainer === 'true' ? 'lightgrey' : 'unset'
        }}
      >
        <AppText style={{ fontSize: 26, fontWeight: 700 }}>
          {config.heading}
        </AppText>
        <AppFlexbox style={{ gap: 10 }}>
          {config.image && (
            <AppStack style={{ flex: 1 }}>
              <AppImage />
            </AppStack>
          )}

          <AppStack style={{ flex: 1 }}>
            <Accordion defaultValue="Apples" variant={config.layoutStyle}>
              {blocks.map((b) => (
                <WsBlock
                  key={b.key}
                  baseStoreUrl={baseStoreUrl}
                  colorScheme={
                    colorSchemes[b.config.colorScheme - 1] ?? colorSchemes[0]
                  }
                  ecomStoreTheme={ecomStoreTheme}
                  onNavigate={onNavigate}
                  value={b.key}
                  {...b}
                />
              ))}
            </Accordion>
          </AppStack>
        </AppFlexbox>
      </AppStack>
    </WsSectionContainer>
  );
};

WsCollapsibleContent.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsCollapsibleContent;
