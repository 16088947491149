import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsPage from './WsPage';

const configNameValues = {
  ecomStorePage: 'ecomStorePage',
  headingSize: 'headingSize',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  [configNameValues.ecomStorePage]: '',
  [configNameValues.headingSize]: '24',
  [configNameValues.colorScheme]: '1'
};

const configOptions = {
  [configNameValues.ecomStorePage]: {
    label: 'Page',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStorePage
  },
  [configNameValues.headingSize]: {
    label: 'Heading size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 100,
    unit: 'px'
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.PAGE,
  title: 'Page',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsPage,
  configOptions,
  maxItemCount: null,
  cannotCreate: true,
  blocks: [],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
