/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Menu } from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppLink from '../../../../common/AppLink';
import { determineEcomStoreMenuItemUrl } from '../../../../../helpers/webstoreHelper';
import AppStack from '../../../../common/AppStack';

const WsHeaderMenu = ({
  config,
  themeConfig,
  onNavigate,
  baseStoreUrl,
  ecomStoreMenu,
  headerColorScheme,
  menuColorScheme,
  pathname,
  ecomStoreTheme
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const mainLinks =
    ecomStoreMenu?.ecomStoreMenuItems.filter(
      (item) => item.fkEcomStoreMenuItem === null
    ) ?? [];
  const pageWidth =
    themeConfig.pageWidth && themeConfig.pageWidth * 1 >= 1000
      ? themeConfig.pageWidth * 1
      : 1000;

  return (
    <AppFlexbox
      style={{ minHeight: 34, alignItems: 'center', gap: 8, flexWrap: 'wrap' }}
    >
      {mainLinks
        .sort((a, b) => a.sort - b.sort)
        .map((item) => {
          const sublinks = ecomStoreMenu.ecomStoreMenuItems.filter(
            (i) => i.fkEcomStoreMenuItem === item.pkEcomStoreMenuItem
          );
          const urlInfo = determineEcomStoreMenuItemUrl({
            ...item,
            ecomStoreTheme
          });
          const isSelected = urlInfo.isSelected(pathname);
          const hasSubSublinks = sublinks.some(
            (i) =>
              ecomStoreMenu.ecomStoreMenuItems.filter(
                (j) => j.fkEcomStoreMenuItem === i.pkEcomStoreMenuItem
              ).length > 0
          );

          return (
            <AppFlexbox
              key={item.pkEcomStoreMenuItem}
              style={{ padding: 8, alignItems: 'center' }}
            >
              <Menu
                closeOnClickOutside
                disabled={sublinks.length === 0}
                onClose={() => setSelectedMenuItem(null)}
                onOpen={() => setSelectedMenuItem(item.pkEcomStoreMenuItem)}
                opened={selectedMenuItem === item.pkEcomStoreMenuItem}
                portalProps={{ target: '#webstore-menu-target' }}
              >
                <Menu.Target>
                  <AppFlexbox>
                    <AppLink
                      color={headerColorScheme.textColor}
                      component={Link}
                      icon={
                        sublinks.length > 0 ? <ChevronDown size={18} /> : null
                      }
                      isActive={isSelected}
                      label={item.title}
                      onClick={(e) => {
                        if (sublinks.length > 0) {
                          e.preventDefault();
                        }
                        else {
                          onNavigate(e);
                        }
                      }}
                      target={urlInfo.target}
                      to={urlInfo.url || `${baseStoreUrl ?? ''}${urlInfo.path}`}
                    />
                  </AppFlexbox>
                </Menu.Target>
                <Menu.Dropdown
                  style={{
                    position: 'absolute',
                    top: 'unset',
                    bottom: 1,
                    left: 0,
                    width: '100%',
                    border: 'none',
                    borderRadius: '0px',
                    padding: '3px 10px',
                    color: menuColorScheme?.textColor ?? '#000',
                    backgroundColor: menuColorScheme.backgroundColor,
                    transform: 'translateY(calc(100%))',
                    minHeight: 0,
                    overflow: 'hidden'
                  }}
                >
                  <AppFlexbox
                    style={{
                      flex: 1,
                      maxWidth:
                        config.fullWidth === 'true' ? 'unset' : pageWidth,
                      margin: 'auto',
                      height: '100%',
                      width: '100%',
                      padding: `${config.topPadding}px ${config.sidePadding}px ${config.bottomPadding}px ${config.sidePadding}px`,
                      position: 'relative'
                    }}
                  >
                    {hasSubSublinks ? (
                      <Grid style={{ flex: 1 }}>
                        {sublinks
                          .sort((a, b) => a.sort - b.sort)
                          .map((subitem) => {
                            const subUrlInfo = determineEcomStoreMenuItemUrl({
                              ...subitem,
                              ecomStoreTheme
                            });
                            const subIsSelected = subUrlInfo.isSelected(
                              pathname
                            );

                            const subSublinks = ecomStoreMenu.ecomStoreMenuItems.filter(
                              (i) =>
                                i.fkEcomStoreMenuItem ===
                                subitem.pkEcomStoreMenuItem
                            );

                            return (
                              <Grid.Col
                                key={subitem.pkEcomStoreMenuItem}
                                span={2}
                              >
                                <AppStack style={{ gap: 12, flex: 1 }}>
                                  <AppLink
                                    color={menuColorScheme.textColor}
                                    component={Link}
                                    isActive={subIsSelected}
                                    label={subitem.title}
                                    onClick={(e) => {
                                      setSelectedMenuItem(null);
                                      onNavigate(e);
                                    }}
                                    style={{ flex: 1 }}
                                    target={subUrlInfo.target}
                                    to={
                                      subUrlInfo.url ||
                                      `${baseStoreUrl ?? ''}${subUrlInfo.path}`
                                    }
                                    weight={700}
                                  />
                                  {subSublinks
                                    .sort((a, b) => a.sort - b.sort)
                                    .map((subsubitem) => {
                                      const subSubUrlInfo = determineEcomStoreMenuItemUrl(
                                        { ...subsubitem, ecomStoreTheme }
                                      );
                                      const subSubIsSelected = subSubUrlInfo.isSelected(
                                        pathname
                                      );
                                      return (
                                        <AppLink
                                          key={subsubitem.pkEcomStoreMenuItem}
                                          color={menuColorScheme.textColor}
                                          component={Link}
                                          isActive={subSubIsSelected}
                                          label={subsubitem.title}
                                          onClick={(e) => {
                                            setSelectedMenuItem(null);
                                            onNavigate(e);
                                          }}
                                          style={{ flex: 1 }}
                                          target={subSubUrlInfo.target}
                                          to={
                                            subSubUrlInfo.url ||
                                            `${baseStoreUrl ?? ''}${
                                              subSubUrlInfo.path
                                            }`
                                          }
                                        />
                                      );
                                    })}
                                </AppStack>
                              </Grid.Col>
                            );
                          })}
                      </Grid>
                    ) : (
                      <AppStack style={{ gap: 12, flex: 1 }}>
                        {sublinks
                          .sort((a, b) => a.sort - b.sort)
                          .map((subitem) => {
                            const subUrlInfo = determineEcomStoreMenuItemUrl({
                              ...subitem,
                              ecomStoreTheme
                            });
                            const subIsSelected = subUrlInfo.isSelected(
                              pathname
                            );
                            return (
                              <AppLink
                                key={subitem.pkEcomStoreMenuItem}
                                color={menuColorScheme.textColor}
                                component={Link}
                                isActive={subIsSelected}
                                label={subitem.title}
                                onClick={(e) => {
                                  setSelectedMenuItem(null);
                                  onNavigate(e);
                                }}
                                style={{ flex: 1 }}
                                target={subUrlInfo.target}
                                to={
                                  subUrlInfo.url ||
                                  `${baseStoreUrl ?? ''}${subUrlInfo.path}`
                                }
                              />
                            );
                          })}
                      </AppStack>
                    )}
                  </AppFlexbox>
                </Menu.Dropdown>
              </Menu>
            </AppFlexbox>
          );
        })}
    </AppFlexbox>
  );
};

WsHeaderMenu.propTypes = {
  baseStoreUrl: PropTypes.string,
  config: PropTypes.object,
  ecomStoreMenu: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  headerColorScheme: PropTypes.object,
  menuColorScheme: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  themeConfig: PropTypes.object
};

export default WsHeaderMenu;
