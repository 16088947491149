import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Checkbox } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { DateTimePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppFlexbox from '../../common/AppFlexbox';
import { formatUtcDate } from '../../../helpers/format';

const CreateStoreWindowModal = ({
  onConfirm,
  isOpen,
  onClose,
  ecomStoreWindow
}) => {
  const [formState, setFormState] = useState({
    closeDate: null,
    showConfirm: false,
    error: null
  });
  const today = new Date();
  const nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 1);
  nextDay.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (isOpen) {
      setFormState({
        closeDate: ecomStoreWindow
          ? new Date(formatUtcDate(ecomStoreWindow.closeDate))
          : null
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: 'Cancel',
        onCancel: onClose,
        onSubmit: () => {
          if (formState.showConfirm) {
            onConfirm(formState);
          }
          else if (!formState.closeDate) {
            setFormState({ error: 'Please select a date and time' });
          }
          else if (formState.closeDate < nextDay) {
            setFormState({
              error: 'Please select a date and time greater than today'
            });
          }
          else {
            setFormState({ ...formState, error: null, showConfirm: true });
          }
        },
        submitTitle: 'Confirm window'
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={500}
      title={ecomStoreWindow ? 'Edit store window' : 'Create store window'}
    >
      {formState.showConfirm ? (
        <AppStack style={{ gap: 10 }}>
          <Alert
            color="#c40000"
            icon={<AlertCircle size={20} />}
            variant="outline"
          >
            <AppStack style={{ gap: 10 }}>
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Once the window closes, all sales are final, and vendor payments
                cannot be recovered.
              </AppText>

              <AppText style={{ fontSize: 14 }}>
                Refunds must be handled before the closing date to recover
                vendor payments.
              </AppText>

              <Checkbox
                label={`I confirm that all sales are final and vendor payments are not recoverable after ${dayjs(
                  formatUtcDate(formState.closeDate)
                ).format('MMM DD YYYY hh:mm A')}.`}
                required
                style={{ marginTop: 10 }}
                styles={{ label: { fontWeight: 500 } }}
              />
            </AppStack>
          </Alert>

          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 13, color: '#666' }}>
              By confirming, you agree to the terms and conditions of the store
              window.{' '}
              <b>
                You must reach the vendors minimum order count in order for
                products to be fulfilled.
              </b>{' '}
              You are responsible for all refunds to customers as a result to
              orders not being fulfilled. Ensure this window stays open as
              intented to avoid any unexpected issues.
            </AppText>
          </AppStack>
        </AppStack>
      ) : (
        <AppStack style={{ gap: 10 }}>
          <Alert
            color="#eca70a"
            icon={<AlertCircle size={20} />}
            variant="outline"
          >
            <AppStack style={{ gap: 10 }}>
              <AppText style={{ fontSize: 14 }}>
                Select a date and time for your store window to close. Your
                store will be open for customers to place orders during this
                time.
              </AppText>

              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                You can update the closing date anytime before the window
                closes.
              </AppText>
            </AppStack>
          </Alert>

          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: '#666',
              textAlign: 'center'
            }}
          >
            All sales are final once the window closes, and vendor payments are
            not recoverable.
          </AppText>

          <AppStack style={{ gap: 10 }}>
            <DateTimePicker
              clearable
              label="Pick a date and time"
              minDate={nextDay}
              onChange={(v) =>
                setFormState({
                  ...formState,
                  closeDate: v
                })
              }
              placeholder="Pick date and time"
              required
              value={formState.closeDate}
              valueFormat="MMM DD YYYY hh:mm A"
            />

            {formState.error && (
              <Alert color="#c40000" style={{ marginTop: 10 }} variant="filled">
                <AppFlexbox style={{ gap: 8 }}>
                  <AlertCircle size={20} />
                  <AppText
                    style={{ fontSize: 14, color: '#FFF', fontWeight: 500 }}
                  >
                    {formState.error}
                  </AppText>
                </AppFlexbox>
              </Alert>
            )}
          </AppStack>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

CreateStoreWindowModal.propTypes = {
  ecomStoreWindow: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default CreateStoreWindowModal;
