import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import VendorReportTable from './VendorReportTable';

const VendorReportsView = () => (
  <Routes>
    <Route element={<VendorReportTable />} path="/" />
    <Route element={<Navigate replace to="/vendor/reports" />} path="*" />
  </Routes>
);

export default VendorReportsView;
