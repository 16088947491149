import React from 'react';
import { notifications } from '@mantine/notifications';
import { AlertCircle, CircleCheck } from 'tabler-icons-react';

const triggerNotification = (message, variant) => {
  let variantData = {};
  switch (variant?.toLowerCase()) {
    case 'success':
      variantData = {
        color: '#067D62',
        title: 'Success',
        icon: <CircleCheck />
      };
      break;
    case 'warning':
      variantData = {
        color: '#eca70a',
        title: 'Warning',
        icon: <AlertCircle />
      };
      break;
    default:
      variantData = {
        color: '#c40000',
        title: 'Error',
        icon: <AlertCircle />
      };
  }

  notifications.show({
    withCloseButton: true,
    title: variantData.title,
    message: message ?? 'Oops something went wrong!',
    color: variantData.color,
    icon: variantData.icon,
    withBorder: true,
    styles: {
      root: {
        backgroundColor: variantData.color,
        borderColor: variantData.color
      },
      title: { color: '#FFF' },
      description: { color: '#FFF' },
      closeButton: { color: '#FFF' }
    }
  });
};

export { triggerNotification };
