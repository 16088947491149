import { Photo } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  ECOM_ENTITY_TYPES,
  INPUT_CONTROL_TYPE_ENUM,
  ECOM_WEBSTORE_PAGE_TYPE_ENUM
} from '../../../../../config/constants';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';
import WsImageBanner from './WsImageBanner';

const DISPLAY_TYPE_ENUM = {
  FULL_WIDTH: 'full-width',
  GRID: 'grid'
};

const IMAGE_HEIGHT_ENUM = {
  ADAPT_TO_IMAGE: 'adapt-to-first-image',
  EXTRA_SMALL: 'extra_small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  CUSTOM: 'custom'
};

const IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end'
};

const IMAGE_POSITION_ENUM = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom'
};

const CONTAINER_PADDING_ENUM = {
  NONE: 'none',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large'
};

const IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM = {
  TOP_LEFT: 'start-start',
  TOP_CENTER: 'start-center',
  TOP_RIGHT: 'start-end',
  CENTER_LEFT: 'center-start',
  CENTER: 'center-center',
  CENTER_RIGHT: 'center-end',
  BOTTOM_LEFT: 'end-start',
  BOTTOM_CENTER: 'end-center',
  BOTTOM_RIGHT: 'end-end'
};

const configNameValues = {
  image: 'image',
  secondImage: 'secondImage',
  thirdImage: 'thirdImage',
  imageOverlayOpacity: 'imageOverlayOpacity',
  displayType: 'displayType',
  imageHeight: 'imageHeight',
  imagePosition: 'imagePosition',
  customImageHeight: 'customImageHeight',
  adaptImageHeight: 'adaptImageHeight',
  adaptImageWidth: 'adaptImageWidth',
  containerPadding: 'containerPadding',
  containerMargin: 'containerMargin',
  desktopContentPosition: 'desktopContentPosition',
  showContainerOnDesktop: 'showContainerOnDesktop',
  fullWidthContainerPlacement: 'fullWidthContainerPlacement',
  desktopContentAlignment: 'desktopContentAlignment',
  colorScheme: 'colorScheme',
  mobileContentAlignment: 'mobileContentAlignment',
  stackMobileImages: 'stackMobileImages',
  showMobileContainer: 'showMobileContainer',
  fullWidth: 'fullWidth'
};

const configDefaultValues = {
  [configNameValues.adaptImageHeight]: '',
  [configNameValues.adaptImageWidth]: '',
  [configNameValues.image]: '',
  [configNameValues.secondImage]: '',
  [configNameValues.thirdImage]: '',
  [configNameValues.imageOverlayOpacity]: '40',
  [configNameValues.displayType]: DISPLAY_TYPE_ENUM.FULL_WIDTH,
  [configNameValues.imageHeight]: IMAGE_HEIGHT_ENUM.SMALL,
  [configNameValues.imagePosition]: IMAGE_POSITION_ENUM.CENTER,
  [configNameValues.customImageHeight]: '450',
  [configNameValues.containerPadding]: CONTAINER_PADDING_ENUM.LARGE,
  [configNameValues.containerMargin]: CONTAINER_PADDING_ENUM.LARGE,
  [configNameValues.desktopContentPosition]:
    IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER,
  [configNameValues.showContainerOnDesktop]: 'false',
  [configNameValues.fullWidthContainerPlacement]: 'true',
  [configNameValues.desktopContentAlignment]:
    IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
  [configNameValues.colorScheme]: '3',
  [configNameValues.mobileContentAlignment]:
    IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
  [configNameValues.fullWidth]: 'true',
  [configNameValues.stackMobileImages]: 'true',
  [configNameValues.showMobileContainer]: 'true',
  topPadding: '0',
  bottomPadding: '0'
};

const configOptions = {
  [configNameValues.image]: {
    label: 'Image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia
  },
  [configNameValues.secondImage]: {
    label: 'Second image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia,
    isVisible: (config) => !!config.image
  },
  [configNameValues.thirdImage]: {
    label: 'Third image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia,
    isVisible: (config) => !!config.secondImage
  },
  [configNameValues.imageOverlayOpacity]: {
    label: 'Image overlay opacity',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 0,
    max: 100,
    unit: '%'
  },
  [configNameValues.imageHeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Image height',
    description:
      "The height of the image container. If you're using multiple images, the height will be the same for all images.",
    options: [
      {
        value: IMAGE_HEIGHT_ENUM.ADAPT_TO_IMAGE,
        label: 'Adapt to first image'
      },
      { value: IMAGE_HEIGHT_ENUM.EXTRA_SMALL, label: 'Extra small (300px)' },
      { value: IMAGE_HEIGHT_ENUM.SMALL, label: 'Small (450px)' },
      { value: IMAGE_HEIGHT_ENUM.MEDIUM, label: 'Medium (600px)' },
      { value: IMAGE_HEIGHT_ENUM.LARGE, label: 'Large (750px)' },
      { value: IMAGE_HEIGHT_ENUM.CUSTOM, label: 'Custom' }
    ]
  },
  [configNameValues.customImageHeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    label: 'Custom height',
    min: 200,
    max: 1200,
    unit: 'px',
    isVisible: (config) => config.imageHeight === IMAGE_HEIGHT_ENUM.CUSTOM
  },
  [configNameValues.imagePosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Image position',
    options: [
      { value: IMAGE_POSITION_ENUM.TOP, label: 'Top' },
      { value: IMAGE_POSITION_ENUM.CENTER, label: 'Center' },
      { value: IMAGE_POSITION_ENUM.BOTTOM, label: 'Bottom' }
    ]
  },
  [configNameValues.showContainerOnDesktop]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Show container on desktop',
    category: 'Container content'
  },
  [configNameValues.containerPadding]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Container padding',
    category: 'Container content',
    options: [
      {
        value: CONTAINER_PADDING_ENUM.NONE,
        label: 'None'
      },
      {
        value: CONTAINER_PADDING_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: CONTAINER_PADDING_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: CONTAINER_PADDING_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: CONTAINER_PADDING_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      }
    ]
  },
  [configNameValues.containerMargin]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Container margin',
    category: 'Container content',
    options: [
      {
        value: CONTAINER_PADDING_ENUM.NONE,
        label: 'None'
      },
      {
        value: CONTAINER_PADDING_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: CONTAINER_PADDING_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: CONTAINER_PADDING_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: CONTAINER_PADDING_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      }
    ]
  },
  [configNameValues.desktopContentPosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Desktop content position',
    category: 'Container content',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_LEFT,
        label: 'Top left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_CENTER,
        label: 'Top center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.TOP_RIGHT,
        label: 'Top right'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER_LEFT,
        label: 'Middle left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER,
        label: 'Middle center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.CENTER_RIGHT,
        label: 'Middle right'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_LEFT,
        label: 'Bottom left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_CENTER,
        label: 'Bottom center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_POSITION_ENUM.BOTTOM_RIGHT,
        label: 'Bottom right'
      }
    ]
  },
  [configNameValues.displayType]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Container desktop layout',
    category: 'Container content',
    options: [
      { value: DISPLAY_TYPE_ENUM.FULL_WIDTH, label: 'Full width' },
      { value: DISPLAY_TYPE_ENUM.GRID, label: 'Grid' }
    ]
  },
  [configNameValues.desktopContentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop content alignment',
    category: 'Container content',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
        label: 'Center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  },
  [configNameValues.mobileContentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Mobile content alignment',
    category: 'Mobile layout',
    options: [
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.CENTER,
        label: 'Center'
      },
      {
        value: IMAGE_WITH_TEXT_CONTAINER_ALIGNMENT_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    category: 'Container content',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.stackMobileImages]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Stack images on mobile',
    category: 'Mobile layout',
    isVisible: (config) => !!config.secondImage
  },
  [configNameValues.showMobileContainer]: {
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    label: 'Show container on mobile',
    category: 'Mobile layout'
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.IMAGE_BANNER,
  title: 'Image banner',
  icon: Photo,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsImageBanner,
  configOptions,
  maxItemCount: 4,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.HEADING
      ],
      defaultConfig: {
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.HEADING
        ].defaultConfig,
        text: 'Shop all our products'
      },
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CAPTION
      ],
      defaultCount: 0,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT
      ],
      defaultCount: 0,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUTTON_GROUP
      ],
      defaultConfig: {
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUTTON_GROUP
        ].defaultConfig,
        button1Text: 'View all',
        button1FkEcomStorePageType: ECOM_WEBSTORE_PAGE_TYPE_ENUM.PRODUCT.toString(),
        button1UseOutlineStyle: 'true',
        button1Size: 'lg'
      },
      defaultCount: 1,
      maxCount: 1
    }
  ],
  defaultConfig: configDefaultValues
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  IMAGE_HEIGHT_ENUM,
  CONTAINER_PADDING_ENUM
};
