import React, { useContext, useEffect, useState } from 'react';
import {
  ActionIcon,
  RangeSlider,
  Select,
  TextInput,
  Tooltip
} from '@mantine/core';
import PropTypes from 'prop-types';
import { Filter, Minus } from 'tabler-icons-react';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import AppMenu from '../../common/AppMenu';
import CustomSelectItem from '../../common/CustomSelectItem';
import AppImage from '../../common/AppImage';
import { useProductSearchFilter } from '../../../helpers/catalogHelper';
import { CANADA_PROVINCE_LIST } from '../../../config/locationConstants';
import { CATALOG_SORT_OPTIONS } from '../../../config/constants';
import CustomNumericFormat from '../../common/CustomNumericFormat';
import { currencyFormat } from '../../../helpers/format';
import ResponsiveDrawer from '../../common/ResponsiveDrawer';

const VendorCatalogSearchFilter = ({ fkEcomCategory, fkEcomVendor, sort }) => {
  const { state } = useContext(StoreContext);
  const [debouncedFilter, setDebouncedFilter] = useState(null);
  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const [filterState, setFilterState] = useState(null);
  const { filter, onFilterChange } = useProductSearchFilter();
  const categoryOptions = PRODUCT_CATEGORY_LIST.sort((a, b) =>
    a.name.localeCompare(b.name)
  ).map((c) => ({
    value: c.value.toString(),
    label: c.name
  }));
  const vendorOptions = state.catalogVendors.value.map((v) => ({
    forceImage: true,
    label: v.name,
    description: v.domain,
    image: v.logoImageUrl,
    value: v.pkEcomVendor.toString()
  }));

  const selectedVendor = vendorOptions.find(
    (v) => v.label === (filterState?.vendor ?? filter.vendor)
  );
  const selectedCategory = categoryOptions.find(
    (c) => c.label === (filterState?.category ?? filter.category)
  );
  const selectedSort = CATALOG_SORT_OPTIONS.find(
    (c) => c.label === (filterState?.sort ?? filter.sort)
  );

  useEffect(() => {
    if (debouncedFilter !== null) {
      onFilterChange(debouncedFilter);
    }
  }, [debouncedFilter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilter(filterState);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterState]);

  return (
    <>
      <AppCard radius="md" shadow="xs" style={{ padding: 8 }} withBorder>
        <AppStack style={{ gap: 5 }}>
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <TextInput
              onChange={(e) => {
                setFilterState({
                  ...filterState,
                  search: e.currentTarget.value
                });
              }}
              placeholder="Search for products"
              style={{ flex: 3 }}
              value={filterState?.search ?? filter.search ?? ''}
            />
            <Select
              clearable
              data={CATALOG_SORT_OPTIONS}
              disabled={!!sort}
              onChange={(v) =>
                setFilterState({
                  sort: v
                    ? CATALOG_SORT_OPTIONS.find((c) => c.value === v)?.label ??
                      ''
                    : ''
                })
              }
              placeholder="Sort by relevance"
              searchable
              style={{ flex: 1 }}
              value={sort ?? selectedSort?.value ?? null}
              visibleFrom="xsm"
            />
            <ActionIcon
              color="dark"
              hiddenFrom="xsm"
              onClick={() => setShowMobileDrawer(true)}
              variant="subtle"
            >
              <Filter />
            </ActionIcon>
          </AppFlexbox>

          <AppFlexbox style={{ gap: 5 }} visibleFrom="xsm">
            <AppFlexbox style={{ gap: 5, flex: 3 }}>
              <Select
                clearable
                data={CANADA_PROVINCE_LIST.map((c) => ({
                  label: c,
                  value: c
                }))}
                onChange={(v) => {
                  if (v !== filter.location) {
                    setFilterState({ ...filterState, location: v });
                  }
                }}
                placeholder="Available anywhere"
                searchable
                style={{ flex: 1 }}
                value={filterState?.location ?? filter.location ?? null}
              />
              <Select
                clearable
                data={categoryOptions}
                disabled={!!fkEcomCategory}
                onChange={(v) => {
                  const categoryOption = categoryOptions.find(
                    (f) => f.value === v
                  );
                  if (categoryOption?.label !== filter.category) {
                    setFilterState({
                      ...filterState,
                      category: categoryOption?.label ?? null
                    });
                  }
                }}
                placeholder="All categories"
                searchable
                style={{ flex: 1 }}
                value={
                  fkEcomCategory?.toString() ??
                  (filter.category && selectedCategory
                    ? selectedCategory.value
                    : null)
                }
              />
              <Select
                clearable
                data={vendorOptions}
                disabled={!!fkEcomVendor}
                leftSection={
                  selectedVendor ? (
                    <AppImage
                      height={30}
                      src={selectedVendor.image}
                      width={30}
                    />
                  ) : null
                }
                leftSectionWidth={40}
                onChange={(v) => {
                  const vendor = vendorOptions.find((a) => a.value === v);
                  if (vendor?.label !== filter.vendor) {
                    setFilterState({
                      ...filterState,
                      vendor: vendor?.label ?? null
                    });
                  }
                }}
                placeholder="All vendors"
                renderOption={({ option, checked }) => (
                  <CustomSelectItem checked={checked} {...option} />
                )}
                required
                searchable
                style={{ flex: 1 }}
                value={
                  fkEcomVendor?.toString() ??
                  (filter.vendor && selectedVendor
                    ? selectedVendor.value
                    : null)
                }
              />
            </AppFlexbox>
            <AppMenu
              control={
                <TextInput
                  onChange={() => {}}
                  placeholder="Price range"
                  style={{ flex: 1 }}
                  value={
                    filter.minPrice || filter.maxPrice
                      ? `${currencyFormat(
                          filter.minPrice ?? 0
                        )} - ${currencyFormat(filter.maxPrice ?? 0)}`
                      : ''
                  }
                />
              }
              position="bottom-start"
              styles={{ dropdown: { padding: 0 } }}
              width={300}
            >
              <AppStack style={{ flex: 1, padding: 8, gap: 5 }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Price range
                  </AppText>
                  <RangeSlider
                    max={1000}
                    min={0}
                    onChangeEnd={(priceRange) => {
                      const min = priceRange[0];
                      const max = priceRange[1];
                      setFilterState({
                        ...filterState,
                        minPrice: min > 0 ? min : null,
                        maxPrice: max < 1000 ? max : null
                      });
                    }}
                    value={[
                      filter.minPrice ? filter.minPrice * 1 : 0,
                      filter.maxPrice ? filter.maxPrice * 1 : 1000
                    ]}
                  />
                  <AppFlexbox style={{ gap: 5 }}>
                    <CustomNumericFormat
                      label="Minimum"
                      onValueChange={(values) => {
                        setFilterState({
                          ...filterState,
                          minPrice:
                            values.floatValue > 0 ? values.floatValue : null
                        });
                      }}
                      style={{ flex: 1 }}
                      value={filter.minPrice}
                    />
                    <CustomNumericFormat
                      label="Maximum"
                      onValueChange={(values) => {
                        setFilterState({
                          ...filterState,
                          maxPrice:
                            values.floatValue < 1000 ? values.floatValue : null
                        });
                      }}
                      style={{ flex: 1 }}
                      value={filter.maxPrice}
                    />
                  </AppFlexbox>
                </AppStack>
              </AppStack>
            </AppMenu>
          </AppFlexbox>
        </AppStack>
      </AppCard>

      <ResponsiveDrawer
        closeIcon={
          <Tooltip label="Minimize" position="bottom" withArrow>
            <AppStack>
              <Minus size={18} />
            </AppStack>
          </Tooltip>
        }
        keepMounted
        lockScroll={false}
        onClose={() => {
          setShowMobileDrawer(false);
        }}
        opened={showMobileDrawer}
        size={325}
        title={
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>
            Filter products
          </AppText>
        }
        trapFocus={false}
        withOverlay={false}
      >
        <AppStack style={{ gap: 16, padding: 8 }}>
          <Select
            clearable
            data={CANADA_PROVINCE_LIST.map((c) => ({
              label: c,
              value: c
            }))}
            label="Location"
            onChange={(v) => {
              if (v !== filter.location) {
                setFilterState({ ...filterState, location: v });
              }
            }}
            placeholder="Available anywhere"
            searchable
            style={{ flex: 1 }}
            value={filterState?.location ?? filter.location ?? ''}
          />
          <Select
            clearable
            data={categoryOptions}
            disabled={!!fkEcomCategory}
            label="Category"
            onChange={(v) => {
              const categoryOption = categoryOptions.find((f) => f.value === v);
              if (categoryOption?.label !== filter.category) {
                setFilterState({
                  ...filterState,
                  category: categoryOption?.label ?? ''
                });
              }
            }}
            placeholder="All categories"
            searchable
            style={{ flex: 1 }}
            value={
              fkEcomCategory?.toString() ??
              (filter.category && selectedCategory
                ? selectedCategory.value
                : null)
            }
          />
          <Select
            clearable
            data={vendorOptions}
            disabled={!!fkEcomVendor}
            label="Vendor"
            leftSection={
              selectedVendor ? (
                <AppImage height={30} src={selectedVendor.image} width={30} />
              ) : null
            }
            leftSectionWidth={40}
            onChange={(v) => {
              const vendor = vendorOptions.find((a) => a.value === v);
              if (vendor?.label !== filter.vendor) {
                setFilterState({
                  ...filterState,
                  vendor: vendor?.label ?? null
                });
              }
            }}
            placeholder="All vendors"
            renderOption={({ option, checked }) => (
              <CustomSelectItem checked={checked} {...option} />
            )}
            required
            searchable
            style={{ flex: 1 }}
            value={
              fkEcomVendor?.toString() ??
              (filter.vendor && selectedVendor ? selectedVendor.value : null)
            }
          />
          <AppFlexbox style={{ gap: 5 }}>
            <CustomNumericFormat
              label="Minimum price"
              onValueChange={(values) => {
                setFilterState({
                  ...filterState,
                  minPrice: values.floatValue > 0 ? values.floatValue : null
                });
              }}
              style={{ flex: 1 }}
              value={filterState?.minPrice ?? filter.minPrice}
            />
            <CustomNumericFormat
              label="Maximum price"
              onValueChange={(values) => {
                setFilterState({
                  ...filterState,
                  maxPrice: values.floatValue < 1000 ? values.floatValue : null
                });
              }}
              style={{ flex: 1 }}
              value={filterState?.maxPrice ?? filter.maxPrice}
            />
          </AppFlexbox>
          <Select
            clearable
            data={CATALOG_SORT_OPTIONS}
            disabled={!!sort}
            label="Sort by"
            onChange={(v) =>
              setFilterState({
                ...filterState,
                sort: v
                  ? CATALOG_SORT_OPTIONS.find((c) => c.value === v)?.label ??
                    null
                  : ''
              })
            }
            placeholder="Sort by relevance"
            searchable
            style={{ flex: 1 }}
            value={sort ?? selectedSort?.value ?? null}
          />
        </AppStack>
      </ResponsiveDrawer>
    </>
  );
};

VendorCatalogSearchFilter.propTypes = {
  fkEcomCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fkEcomVendor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sort: PropTypes.string
};

export default VendorCatalogSearchFilter;
