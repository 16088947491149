import React, { useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { Context as AuthContext } from '../providers/AuthContextProvider';
import LoadableView from '../components/common/LoadableView';
import AdminViews from './AdminViews';
import MerchantsView from './MerchantsView';
import VendorsView from './VendorsView';
import { useDashboardCookies } from '../helpers/hooks';

const UserViews = () => {
  useDashboardCookies();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { state: authState } = useContext(AuthContext);

  useEffect(() => {
    if (authState.tokenAttempted && !authState.isAuthenticated) {
      navigate(`/auth/signin?redirect=${pathname}${search}`);
    }
  }, [authState.tokenAttempted]);

  return (
    <LoadableView isLoading={!authState.isAuthenticated}>
      <Routes>
        <Route element={<MerchantsView />} path="/merchant/*" />
        <Route element={<VendorsView />} path="/vendor/*" />
        <Route element={<AdminViews />} path="/admin/*" />
        <Route element={<Navigate replace to="/" />} path="*" />
      </Routes>
    </LoadableView>
  );
};

export default UserViews;
