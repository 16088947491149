import React, { useContext } from 'react';
import { Minus, Shirt, Trash, Upload, X } from 'tabler-icons-react';
import {
  ActionIcon,
  Anchor,
  Avatar,
  Button,
  Divider,
  Image,
  Loader,
  NumberFormatter,
  Skeleton,
  Tooltip
} from '@mantine/core';
import AppStack from '../../common/AppStack';
import ResponsiveDrawer from '../../common/ResponsiveDrawer';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { singularPluralFormat } from '../../../helpers/format';
import AppCard from '../../common/AppCard';
import { useModalState } from '../../../helpers/hooks';
import ConfirmModal from '../../common/ConfirmModal';
import ConfirmVendorProductsModal from './ConfirmVendorProductsModal';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import SetVendorProductInputsModal from './SetVendorProductInputsModal';
import { ECOM_PRODUCT_STATUS_ENUM } from '../../../config/constants';

const MODAL_ACTION_ENUM = { CLEAR: 'clear', ADD: 'add' };

const SelectedVendorProductsDialog = () => {
  const { state } = useContext(StoreContext);
  const {
    state: helperState,
    toggleVendorCatalogDrawer,
    toggleVendorCatalogInputModal
  } = useContext(HelperContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    cart: catalogCart,
    removeProducts,
    clearCart
  } = useVendorCatalogCheckout();

  const totalMinPrice =
    catalogCart.ecomVendorProducts.reduce((acc, p) => acc + p.minPrice, 0) /
    100;
  const totalMaxPrice =
    catalogCart.ecomVendorProducts.reduce((acc, p) => acc + p.maxPrice, 0) /
    100;

  return (
    <>
      <ResponsiveDrawer
        closeIcon={
          <Tooltip label="Minimize" position="bottom" withArrow>
            <AppStack>
              <Minus size={18} />
            </AppStack>
          </Tooltip>
        }
        footerContent={
          catalogCart.loading ? (
            <AppStack style={{ padding: '16px 8px', gap: 16 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Skeleton height={18} width={150} />
                <Skeleton height={18} width={20} />
              </AppFlexbox>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Skeleton height={18} width={150} />
                <Skeleton height={18} width={80} />
              </AppFlexbox>
              <Skeleton height={34} width="100%" />
              <AppFlexbox style={{ justifyContent: 'center' }}>
                <Skeleton height={18} width={225} />
              </AppFlexbox>
            </AppStack>
          ) : (
            <AppStack style={{ padding: '16px 8px', gap: 16 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppText style={{ fontWeight: 500 }}>
                  Total selected products
                </AppText>
                {helperState.itemStorage.value.length > 0 ? (
                  <Skeleton height={18} width={20} />
                ) : (
                  <AppText style={{ fontWeight: 500 }}>
                    {catalogCart.ecomVendorProducts.length}
                  </AppText>
                )}
              </AppFlexbox>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppText style={{ fontWeight: 500 }}>
                  Total vendor pricing
                </AppText>
                {helperState.itemStorage.value.length > 0 ? (
                  <Skeleton height={18} width={80} />
                ) : (
                  <AppText style={{ fontWeight: 500, textAlign: 'end' }}>
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontWeight: 700 }}
                      suffix=" CAD"
                      thousandSeparator
                      value={totalMinPrice}
                    />
                    {totalMinPrice !== totalMaxPrice && (
                      <>
                        {' '}
                        -{' '}
                        <NumberFormatter
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="$"
                          style={{ fontWeight: 700 }}
                          suffix=" CAD"
                          thousandSeparator
                          value={totalMaxPrice}
                        />
                      </>
                    )}
                  </AppText>
                )}
              </AppFlexbox>
              <Button
                color="blue"
                leftSection={<Upload size={18} />}
                onClick={() => onOpenModal(MODAL_ACTION_ENUM.ADD)}
                variant="filled"
              >
                Add to your store
              </Button>
              <AppText
                style={{
                  fontSize: 12,
                  color: '#666',
                  textAlign: 'center'
                }}
              >
                You will be asked to set your pricing after adding
              </AppText>
            </AppStack>
          )
        }
        keepMounted
        lockScroll={false}
        onClose={() => {
          toggleVendorCatalogDrawer(false);
        }}
        opened={helperState.vendorCatalog.showDrawer}
        size={325}
        title={
          <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
            <AppText style={{ fontSize: 16, fontWeight: 500 }}>
              Add products
            </AppText>
            {catalogCart.loading || helperState.itemStorage.value.length > 0 ? (
              <Loader color="blue" size={26} />
            ) : (
              <Avatar color="blue" radius="xl" size="sm" variant="filled">
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  {catalogCart.ecomVendorProducts.length}
                </AppText>
              </Avatar>
            )}
          </AppFlexbox>
        }
        trapFocus={false}
        withOverlay={false}
      >
        <AppStack style={{ flex: 1, gap: 0 }}>
          <AppStack style={{ flex: 1, padding: '8px 16px', gap: 8 }}>
            {catalogCart.loading
              ? Array.from(
                  Array(catalogCart.storageCart?.ecomVendorProducts.length ?? 0)
                ).map((x, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={i}>
                    <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                      <Skeleton height={85} width={85} />
                      <AppStack style={{ flex: 1, gap: 5 }}>
                        <AppFlexbox
                          style={{
                            gap: 10,
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Skeleton height={16} width={80} />
                          <Skeleton height={20} width={20} />
                        </AppFlexbox>
                        <Skeleton height={16} width={65} />
                        <AppFlexbox
                          style={{
                            gap: 10,
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Skeleton height={16} width={80} />
                          <AppText style={{ fontWeight: 500, fontSize: 14 }}>
                            <Skeleton height={16} width={75} />
                          </AppText>
                        </AppFlexbox>
                      </AppStack>
                    </AppFlexbox>
                    <Divider />
                  </React.Fragment>
                ))
              : catalogCart.ecomVendorProducts
                  .sort(
                    (a, b) =>
                      catalogCart.storageCart.ecomVendorProducts.findIndex(
                        (sp) => sp.uuid === a.uuid
                      ) -
                      catalogCart.storageCart.ecomVendorProducts.findIndex(
                        (sp) => sp.uuid === b.uuid
                      )
                  )
                  .map((p) => {
                    const alreadyInStore = state.ecomStoreProducts.value.some(
                      (f) => f.ecomVendorProduct.uuid === p.uuid
                    );

                    return (
                      <AppStack key={p.pkEcomVendorProduct} style={{ gap: 0 }}>
                        {alreadyInStore ? (
                          <AppFlexbox
                            style={{
                              padding: '3px 5px',
                              backgroundColor: '#eca70a'
                            }}
                          >
                            <AppText
                              style={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: '#FFF'
                              }}
                            >
                              Already added
                            </AppText>
                          </AppFlexbox>
                        ) : (
                          (p.delete ||
                            p.fkEcomProductStatus.toString() !==
                              ECOM_PRODUCT_STATUS_ENUM.ACTIVE.toString()) && (
                            <AppFlexbox
                              style={{
                                padding: '3px 5px',
                                backgroundColor: '#c40000'
                              }}
                            >
                              <AppText
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: '#FFF'
                                }}
                              >
                                No longer available
                              </AppText>
                            </AppFlexbox>
                          )
                        )}
                        <AppFlexbox />
                        <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                          <AppStack style={{ width: 85, height: 85 }}>
                            <Image
                              fit="contain"
                              h="100%"
                              src={p.previewImage}
                              w="100%"
                            />
                          </AppStack>
                          <AppStack style={{ flex: 1, gap: 0 }}>
                            <AppFlexbox
                              style={{
                                gap: 10,
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <AppText
                                style={{ fontWeight: 500, fontSize: 14 }}
                              >
                                {p.name}
                              </AppText>
                              <ActionIcon
                                color="red"
                                onClick={() => {
                                  removeProducts([p.uuid]);
                                }}
                                variant="subtle"
                              >
                                <X />
                              </ActionIcon>
                            </AppFlexbox>
                            <AppStack style={{ flex: 1, gap: 0 }}>
                              <AppText style={{ fontSize: 14, color: '#666' }}>
                                {singularPluralFormat(
                                  p.ecomVendorProductVariantCount,
                                  'variant',
                                  'variants'
                                )}
                              </AppText>
                            </AppStack>
                            <AppFlexbox
                              style={{
                                gap: 10,
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <AppStack style={{ overflow: 'hidden' }}>
                                <AppText
                                  style={{ fontSize: 14, color: '#666' }}
                                >
                                  by {p.vendorName}
                                </AppText>
                              </AppStack>

                              <AppText
                                style={{ fontWeight: 500, fontSize: 14 }}
                              >
                                {p.minPrice === p.maxPrice ? (
                                  <NumberFormatter
                                    decimalScale={2}
                                    fixedDecimalScale
                                    prefix="$"
                                    style={{ fontWeight: 700 }}
                                    suffix=" CAD"
                                    thousandSeparator
                                    value={p.minPrice / 100}
                                  />
                                ) : (
                                  <>
                                    <NumberFormatter
                                      decimalScale={2}
                                      fixedDecimalScale
                                      prefix="$"
                                      style={{ fontWeight: 700 }}
                                      suffix=" CAD"
                                      thousandSeparator
                                      value={p.minPrice / 100}
                                    />{' '}
                                    -{' '}
                                    <NumberFormatter
                                      decimalScale={2}
                                      fixedDecimalScale
                                      prefix="$"
                                      style={{ fontWeight: 700 }}
                                      suffix=" CAD"
                                      thousandSeparator
                                      value={p.maxPrice / 100}
                                    />
                                  </>
                                )}
                              </AppText>
                            </AppFlexbox>
                            {p.minimumQuantity && (
                              <AppText
                                style={{ fontSize: 14, color: '#eca70a' }}
                              >
                                {p.minimumQuantity} Minimum
                              </AppText>
                            )}

                            {p.ecomVendorProductInputs.length > 0 && (
                              <Anchor
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleVendorCatalogInputModal(true, p.uuid);
                                }}
                                style={{ color: 'dodgerblue', fontSize: 14 }}
                              >
                                Configure
                              </Anchor>
                            )}
                          </AppStack>
                        </AppFlexbox>
                        <Divider style={{ marginTop: 8 }} />
                      </AppStack>
                    );
                  })}
            {helperState.itemStorage.value.map((p) => (
              <React.Fragment key={p}>
                <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                  <Skeleton height={85} width={85} />
                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <AppFlexbox
                      style={{
                        gap: 10,
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Skeleton height={16} width={80} />
                      <Skeleton height={20} width={20} />
                    </AppFlexbox>
                    <Skeleton height={16} width={65} />
                    <AppFlexbox
                      style={{
                        gap: 10,
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Skeleton height={16} width={80} />
                      <AppText style={{ fontWeight: 500, fontSize: 14 }}>
                        <Skeleton height={16} width={75} />
                      </AppText>
                    </AppFlexbox>
                  </AppStack>
                </AppFlexbox>
                <Divider />
              </React.Fragment>
            ))}
          </AppStack>
        </AppStack>
      </ResponsiveDrawer>
      {!helperState.vendorCatalog.showDrawer &&
        catalogCart.ecomVendorProducts.length > 0 && (
          <AppFlexbox
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              padding: 16,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 999
            }}
            visibleFrom="sm"
          >
            <AppCard
              radius="md"
              style={{
                border: 'solid 3px dodgerblue',
                padding: 16,
                flex: 1,
                maxWidth: 750
              }}
            >
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
                  <ActionIcon
                    color="red"
                    onClick={() => onOpenModal(MODAL_ACTION_ENUM.CLEAR)}
                    radius="xl"
                    size={36}
                  >
                    <Trash />
                  </ActionIcon>
                  <Shirt size={36} />
                  <AppText style={{ fontWeight: 500, fontSize: 18 }}>
                    {singularPluralFormat(
                      catalogCart.ecomVendorProducts.length,
                      'product',
                      'products'
                    )}{' '}
                    selected
                  </AppText>
                </AppFlexbox>

                <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                  <Button
                    color="dark"
                    onClick={() => {
                      toggleVendorCatalogDrawer(true);
                    }}
                    variant="outline"
                  >
                    Details
                  </Button>
                  <Button
                    color="blue"
                    leftSection={<Upload />}
                    onClick={() => onOpenModal(MODAL_ACTION_ENUM.ADD)}
                    variant="filled"
                  >
                    Add to your store
                  </Button>
                </AppFlexbox>
              </AppFlexbox>
            </AppCard>
          </AppFlexbox>
        )}

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, clear selected"
        isOpen={
          modalState.isOpen && modalState.action === MODAL_ACTION_ENUM.CLEAR
        }
        message="Are you sure you want to clear all selected products?"
        onCancel={onCloseModal}
        onConfirm={() => {
          onCloseModal();
          clearCart();
        }}
        title="Clear selected products?"
      />

      <ConfirmVendorProductsModal
        isOpen={
          modalState.isOpen && modalState.action === MODAL_ACTION_ENUM.ADD
        }
        onClose={onCloseModal}
      />

      <SetVendorProductInputsModal skippable />
    </>
  );
};

SelectedVendorProductsDialog.propTypes = {};

export default SelectedVendorProductsDialog;
