import React from 'react';
import { Button, Divider, Drawer, ScrollArea } from '@mantine/core';
import PropTypes from 'prop-types';
import AppText from './AppText';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';

const ResponsiveDrawer = ({
  title,
  children,
  onClose,
  closeIcon,
  withOverlay = true,
  formSectionProps,
  footerContent,
  ...rest
}) => (
  <Drawer.Root
    onClose={onClose}
    padding={0}
    position="right"
    size={375}
    {...rest}
    styles={{
      ...rest?.styles,
      header: {
        padding: '0px 16px',
        minHeight: 'unset',
        height: 50,
        alignItems: 'center',
        ...rest?.styles?.header
      },
      overlay: { backgroundColor: 'unset', ...rest?.styles?.overlay },
      content: {
        marginTop: 60,
        display: 'flex',
        flexDirection: 'column',
        ...rest?.styles?.content
      },
      body: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 60,
        height: '100%',
        overflow: 'hidden',
        ...rest?.styles?.body
      }
    }}
  >
    {withOverlay && <Drawer.Overlay onClick={onClose} />}
    <Drawer.Content>
      <Drawer.Header>
        <Drawer.Title>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {typeof title === 'string' ? (
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                {title}
              </AppText>
            ) : (
              title
            )}
          </AppFlexbox>
        </Drawer.Title>
        <Drawer.CloseButton icon={closeIcon} />
      </Drawer.Header>
      <Drawer.Body>
        <AppStack
          {...(formSectionProps
            ? {
                component: 'form',
                onSubmit: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  formSectionProps.onSubmit(e);
                }
              }
            : {})}
          style={{ flex: 1, gap: 0, height: '100%' }}
        >
          <Divider />
          <ScrollArea.Autosize
            h="100%"
            maw="100%"
            miw="100%"
            mx="auto"
            scrollbarSize={10}
          >
            <AppStack style={{ flex: 1, gap: 0, height: '100%' }}>
              {children}
            </AppStack>
          </ScrollArea.Autosize>
          <Divider />
          {footerContent}
          {formSectionProps && (
            <>
              <AppStack hiddenFrom="xsm" style={{ padding: 16 }}>
                {!formSectionProps.isSubmitHidden && (
                  <Button
                    color={formSectionProps.submitColor ?? 'dark'}
                    disabled={formSectionProps.isSubmitDisabled}
                    loading={
                      formSectionProps.isLoading ||
                      formSectionProps.submitLoading
                    }
                    radius="md"
                    size="md"
                    style={{ fontSize: 14 }}
                    type="submit"
                    variant={formSectionProps.submitVariant ?? 'filled'}
                  >
                    {formSectionProps.submitTitle ?? 'Confirm'}
                  </Button>
                )}
                {!formSectionProps.isCancelHidden && (
                  <Button
                    color={formSectionProps.cancelColor ?? 'dark'}
                    disabled={
                      formSectionProps.isLoading ||
                      formSectionProps.submitLoading ||
                      formSectionProps.isCancelDisabled
                    }
                    onClick={onClose}
                    radius="md"
                    size="md"
                    style={{ fontSize: 14 }}
                    type="button"
                    variant={formSectionProps.cancelVariant ?? 'outline'}
                  >
                    {formSectionProps.cancelTitle ?? 'Cancel'}
                  </Button>
                )}
              </AppStack>

              <AppFlexbox
                style={{
                  padding: 16,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                visibleFrom="xsm"
              >
                {!formSectionProps.isCancelHidden && (
                  <Button
                    color={formSectionProps.cancelColor ?? 'dark'}
                    disabled={
                      formSectionProps.isLoading ||
                      formSectionProps.submitLoading ||
                      formSectionProps.isCancelDisabled
                    }
                    onClick={onClose}
                    size="compact-md"
                    style={{ fontSize: 14 }}
                    type="button"
                    variant={formSectionProps.cancelVariant ?? 'outline'}
                  >
                    {formSectionProps.cancelTitle ?? 'Cancel'}
                  </Button>
                )}
                {!formSectionProps.isSubmitHidden && (
                  <Button
                    color={formSectionProps.submitColor ?? 'dark'}
                    disabled={formSectionProps.isSubmitDisabled}
                    loading={
                      formSectionProps.isLoading ||
                      formSectionProps.submitLoading
                    }
                    size="compact-md"
                    style={{ fontSize: 14 }}
                    type="submit"
                    variant={formSectionProps.submitVariant ?? 'filled'}
                  >
                    {formSectionProps.submitTitle ?? 'Confirm'}
                  </Button>
                )}
              </AppFlexbox>
            </>
          )}
        </AppStack>
      </Drawer.Body>
    </Drawer.Content>
  </Drawer.Root>
);

ResponsiveDrawer.propTypes = {
  children: PropTypes.node,
  closeIcon: PropTypes.node,
  footerContent: PropTypes.any,
  formSectionProps: PropTypes.object,
  onClose: PropTypes.func,
  submitTitle: PropTypes.string,
  title: PropTypes.any,
  withOverlay: PropTypes.bool
};

export default ResponsiveDrawer;
