/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Anchor } from '@mantine/core';
import { useHover } from '@mantine/hooks';

const HoverableAnchor = ({ children, ...rest }) => {
  const { hovered, ref: hoverRef } = useHover();

  return (
    <Anchor
      ref={hoverRef}
      {...rest}
      style={{
        textUnderlineOffset: 4,
        textDecorationThickness: hovered ? 2 : 1,
        ...rest.style
      }}
      underline="always"
    >
      {children}
    </Anchor>
  );
};

HoverableAnchor.propTypes = { children: PropTypes.any };

export default HoverableAnchor;
