import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../config/constants';
import { ECOM_WEBSTORE_SECTIONS } from './sections/wsSectionConfig';

const VIEW_SCREEN_RATIO_ENUM = {
  desktop: 'desktop',
  mobile: 'mobile',
  fullscreen: 'fullscreen'
};

const SIDEBAR_VIEW_ENUM = {
  template: 'template',
  settings: 'settings'
};

const WEBSTORE_SETTING_TYPE_ENUM = {
  LOGO: 'LOGO',
  COLORS: 'COLORS',
  TYPOGRAPHY: 'TYPOGRAPHY',
  LAYOUT: 'LAYOUT'
};

const configNameValues = {
  desktopLogoWidth: 'desktopLogoWidth',
  headingFontType: 'headingFontType',
  headingFontSizeScale: 'headingFontSizeScale',
  bodyFontType: 'bodyFontType',
  bodyFontSizeScale: 'bodyFontSizeScale',
  pageWidth: 'pageWidth',
  spaceBetweenBodySections: 'spaceBetweenBodySections'
};

const configDefaultValues = {
  [configNameValues.desktopLogoWidth]: '150',
  [configNameValues.headingFontType]: 'Arial',
  [configNameValues.headingFontSizeScale]: '100',
  [configNameValues.bodyFontType]: 'Arial',
  [configNameValues.bodyFontSizeScale]: '100',
  [configNameValues.pageWidth]: '1200',
  [configNameValues.spaceBetweenBodySections]: '0'
};

const configSections = {
  [WEBSTORE_SETTING_TYPE_ENUM.LOGO]: {
    value: WEBSTORE_SETTING_TYPE_ENUM.LOGO,
    title: 'Logo',
    configOptions: {
      [configNameValues.desktopLogoWidth]: {
        label: 'Desktop logo width',
        type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
        min: 50,
        max: 300,
        unit: 'px',
        isVisible: (configValues, themeConfig) => !!themeConfig.logoImageUrl
      }
    }
  },
  [WEBSTORE_SETTING_TYPE_ENUM.COLORS]: {
    value: WEBSTORE_SETTING_TYPE_ENUM.COLORS,
    title: 'Colors',
    configOptions: {}
  },
  // [WEBSTORE_SETTING_TYPE_ENUM.TYPOGRAPHY]: {
  //   value: WEBSTORE_SETTING_TYPE_ENUM.TYPOGRAPHY,
  //   title: 'Typography',
  //   configOptions: {
  //     [configNameValues.headingFontType]: {
  //       label: 'Heading font',
  //       type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
  //       options: [
  //         { value: 'Arial', label: 'Arial' },
  //         { value: 'Roboto', label: 'Roboto' },
  //         { value: 'Open Sans', label: 'Open Sans' }
  //       ]
  //     },
  //     [configNameValues.headingFontSizeScale]: {
  //       label: 'Font size scale',
  //       type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
  //       min: 100,
  //       max: 150,
  //       unit: '%'
  //     },
  //     [configNameValues.bodyFontType]: {
  //       label: 'Body font',
  //       type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
  //       options: [
  //         { value: 'Arial', label: 'Arial' },
  //         { value: 'Roboto', label: 'Roboto' },
  //         { value: 'Open Sans', label: 'Open Sans' }
  //       ]
  //     },
  //     [configNameValues.bodyFontSizeScale]: {
  //       label: 'Font size scale',
  //       type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
  //       min: 100,
  //       max: 150,
  //       unit: '%'
  //     }
  //   }
  // },
  [WEBSTORE_SETTING_TYPE_ENUM.LAYOUT]: {
    value: WEBSTORE_SETTING_TYPE_ENUM.LAYOUT,
    title: 'Layout',
    configOptions: {
      [configNameValues.pageWidth]: {
        label: 'Page width',
        type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
        min: 1000,
        max: 2600,
        unit: 'px'
      },
      [configNameValues.spaceBetweenBodySections]: {
        label: 'Space between body sections',
        type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
        min: 0,
        max: 100,
        unit: 'px'
      }
    }
  }
};

const config = {
  configSections,
  requiredSections: [
    {
      config: ECOM_WEBSTORE_SECTIONS[ECOM_WEBSTORE_SECTION_TYPE_ENUM.HEADER],
      location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.HEADER
    },
    {
      config: ECOM_WEBSTORE_SECTIONS[ECOM_WEBSTORE_SECTION_TYPE_ENUM.FOOTER],
      location: ECOM_WEBSTORE_SECTION_BOUND_TYPE.FOOTER
    }
  ],
  defaultConfig: configDefaultValues
};

export {
  config,
  configSections,
  configNameValues,
  configDefaultValues,
  VIEW_SCREEN_RATIO_ENUM,
  SIDEBAR_VIEW_ENUM,
  WEBSTORE_SETTING_TYPE_ENUM
};
