import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  ECOM_ENTITY_TYPES,
  INPUT_CONTROL_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM
} from '../../../../../config/constants';
import WsProductInformation from './WsProductInformation';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';
import { getProductUrlHandleFromPathname } from '../../../../../helpers/webstoreHelper';

const MEDIA_POSITION_ENUM = {
  LEFT: 'left',
  RIGHT: 'right'
};

const MEDIA_LAYOUT_ENUM = {
  STACKED: 'stacked',
  TWO_COLUMNS: 'twoColumns',
  THUMBNAILS: 'thumbnails',
  CAROUSEL_THUMBNAILS: 'carouselThumbnails'
};

const MEDIA_SIZE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

const configNameValues = {
  colorScheme: 'colorScheme',
  desktopMediaPosition: 'desktopMediaPosition',
  desktopMediaLayout: 'desktopMediaLayout',
  mediaSize: 'mediaSize',
  switchToVariantImage: 'switchToVariantImage'
};

const configDefaultValues = {
  [configNameValues.colorScheme]: '1',
  [configNameValues.desktopMediaPosition]: MEDIA_POSITION_ENUM.LEFT,
  [configNameValues.desktopMediaLayout]: MEDIA_LAYOUT_ENUM.THUMBNAILS,
  [configNameValues.mediaSize]: MEDIA_SIZE_ENUM.MEDIUM,
  [configNameValues.switchToVariantImage]: 'true'
};

const configOptions = {
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.desktopMediaPosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop content alignment',
    category: 'Media',
    options: [
      {
        value: MEDIA_POSITION_ENUM.LEFT,
        label: 'Left'
      },
      {
        value: MEDIA_POSITION_ENUM.RIGHT,
        label: 'Right'
      }
    ]
  },
  [configNameValues.desktopMediaLayout]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Desktop layout',
    category: 'Media',
    options: [
      {
        value: MEDIA_LAYOUT_ENUM.STACKED,
        label: 'Stacked'
      },
      {
        value: MEDIA_LAYOUT_ENUM.TWO_COLUMNS,
        label: '2 columns'
      },
      {
        value: MEDIA_LAYOUT_ENUM.THUMBNAILS,
        label: 'Thumbnails'
      },
      {
        value: MEDIA_LAYOUT_ENUM.CAROUSEL_THUMBNAILS,
        label: 'Carousel thumbnails'
      }
    ]
  },
  [configNameValues.mediaSize]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Media size',
    category: 'Media',
    options: [
      {
        value: MEDIA_SIZE_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: MEDIA_SIZE_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: MEDIA_SIZE_ENUM.LARGE,
        label: 'Large'
      }
    ]
  },
  [configNameValues.switchToVariantImage]: {
    label: 'Switch to variant image after selecting',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Media'
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.PRODUCT_INFORMATION,
  title: 'Product information',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsProductInformation,
  configOptions,
  isPermanent: true,
  cannotCreate: true,
  maxItemCount: null,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT
      ],
      defaultCount: 0,
      maxCount: null
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.INVENTORY_STATUS
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VENDOR_TITLE
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TITLE
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.PRICE
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.VARIANT_PICKER
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.QUANTITY_SELECTOR
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUY_BUTTONS
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.DESCRIPTION
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.SKU
      ],
      defaultCount: 0,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.ICON_WITH_TEXT
      ],
      defaultCount: 0,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues,
  onFetchDataConfig: (sectionConfigValues, pathname) => ({
    type: 'ecomStoreProduct',
    entityId: sectionConfigValues.ecomStoreCollection,
    filter: { urlHandle: getProductUrlHandleFromPathname(pathname) }
  })
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  MEDIA_LAYOUT_ENUM,
  MEDIA_POSITION_ENUM,
  MEDIA_SIZE_ENUM
};
