/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Button, Divider, TextInput } from '@mantine/core';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import WsAccountAuthSection from './WsAccountAuthSection';

const WsAccountLogin = ({
  blocks,
  config,
  themeConfig,
  ecomStoreTheme,
  colorSchemes,
  mediaQueries,
  sort,
  onAuthNavigation,
  pathname,
  baseStoreUrl,
  editModeEnabled,
  onAuthenticateEditMode,
  ...rest
}) => {
  const { state, checkForExistingUser, signIn } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    email: state.email ?? '',
    password: state.password ?? '',
    showPasswordInput: !!state.email,
    loading: false,
    error: ''
  });
  const { isLargeMobileOrSmaller } = mediaQueries;

  useEffect(() => {
    setFormState({
      email: state.email ?? '',
      password: state.password ?? '',
      showPasswordInput: false,
      loading: false,
      error: ''
    });
  }, []);

  const onSignin = () => {
    if (editModeEnabled) {
      if (!formState.showPasswordInput) {
        setFormState({
          ...formState,
          password: '',
          error: '',
          showPasswordInput: true
        });
      }
      else {
        onAuthenticateEditMode(true);
      }
    }
    else {
      setFormState({
        ...formState,
        loading: true
      });
      if (!formState.showPasswordInput) {
        checkForExistingUser(
          formState.email,
          () => {
            setFormState({
              ...formState,
              password: '',
              error: '',
              showPasswordInput: true
            });
          },
          () => {
            setFormState({
              ...formState,
              error: 'We cannot find an account with that e-mail address'
            });
          }
        );
      }
      else {
        signIn(
          { email: formState.email, password: formState.password },
          () => {},
          (error) => {
            setFormState({
              ...formState,
              loading: false,
              error
            });
          }
        );
      }
    }
  };

  return (
    <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
      <WsAccountAuthSection
        ecomStoreTheme={ecomStoreTheme}
        error={formState.error}
        mediaQueries={mediaQueries}
        onNavigate={onAuthNavigation}
        onSubmit={onSignin}
        title="Sign in"
      >
        <AppStack
          style={{
            gap: 10,
            flex: 1,
            padding: isLargeMobileOrSmaller
              ? '10px 20px 20px 20px'
              : '10px 40px 40px 40px'
          }}
        >
          {formState.showPasswordInput ? (
            <>
              <AppCard
                radius={8}
                shadow="none"
                style={{
                  padding: 16,
                  backgroundColor: 'rgba(245, 245, 245, 1)'
                }}
                withBorder
              >
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 13 }}>
                    {editModeEnabled
                      ? state.userData.user.email
                      : formState.email}
                  </AppText>
                  <Anchor
                    onClick={() =>
                      setFormState({
                        ...formState,
                        email: '',
                        password: '',
                        error: '',
                        loading: false,
                        showPasswordInput: false
                      })
                    }
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                  >
                    Change
                  </Anchor>
                </AppFlexbox>
              </AppCard>
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox style={{ justifyContent: 'space-between' }}>
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                      Password
                    </AppText>
                    <AppText
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                        color: '#f03e3e'
                      }}
                    >
                      *
                    </AppText>
                  </AppFlexbox>
                  <Anchor
                    onClick={() => onAuthNavigation('/account/forgot-password')}
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                  >
                    Forgot password?
                  </Anchor>
                </AppFlexbox>

                <TextInput
                  disabled={editModeEnabled || formState.loading}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      password: e.currentTarget.value
                    })
                  }
                  required
                  type="password"
                  value={editModeEnabled ? '********' : formState.password}
                />
              </AppStack>
              <Button
                color="dark"
                loading={formState.loading}
                radius={10}
                size="sm"
                style={{ fontSize: 13, margin: '5px 0px' }}
                type="submit"
              >
                Sign in
              </Button>
            </>
          ) : (
            <>
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 13, fontWeight: 500 }}>
                    Email address
                  </AppText>
                  <AppText
                    style={{ fontSize: 13, fontWeight: 500, color: '#f03e3e' }}
                  >
                    *
                  </AppText>
                </AppFlexbox>

                <TextInput
                  disabled={editModeEnabled}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      email: e.currentTarget.value
                    })
                  }
                  required
                  type="email"
                  value={
                    editModeEnabled
                      ? state.userData.user.email
                      : formState.email
                  }
                />
              </AppStack>
              <Button
                color="dark"
                loading={formState.loading}
                radius={10}
                size="sm"
                style={{ fontSize: 13, margin: '5px 0px' }}
                type="submit"
              >
                Continue
              </Button>
              <AppText style={{ fontSize: 13 }}>
                By continuing, you agree to SportsHeadz{' '}
                <Anchor
                  href="https://www.sportsheadz.com/privacy-policy"
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  target="_blank"
                >
                  Privacy Policy
                </Anchor>
                .
              </AppText>

              <AppText style={{ fontSize: 13 }}>
                <Anchor
                  onClick={() => onAuthNavigation('/account/forgot-password')}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                >
                  Forgot your password?
                </Anchor>
              </AppText>

              <Divider
                label="New to SportsHeadz?"
                labelPosition="center"
                style={{ marginTop: 5 }}
              />
              <Button
                color="dark"
                onClick={() => onAuthNavigation('/account/register')}
                radius={10}
                size="compact-md"
                style={{ fontSize: 13 }}
                type="button"
                variant="outline"
              >
                Create your SportsHeadz account
              </Button>
            </>
          )}
        </AppStack>
      </WsAccountAuthSection>
    </AppFlexbox>
  );
};

WsAccountLogin.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onAuthNavigation: PropTypes.func,
  onAuthenticateEditMode: PropTypes.func,
  pathname: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsAccountLogin;
