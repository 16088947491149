/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Pagination } from '@mantine/core';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import { sterilizeUrlHandle } from '../../../../../helpers/format';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsProductCard from './WsProductCard';
import AppFlexbox from '../../../../common/AppFlexbox';
import {
  DESKTOP_FILTER_LAYOUT_ENUM,
  IMAGE_DISPLAY_ENUM,
  QUICK_ADD_STYLE_ENUM
} from './wsProductGridConfig';
import AppText from '../../../../common/AppText';
import { useWebstoreSearchFilter } from '../../../../../helpers/webstoreHelper';
import WsProductGridFilter from './WsProductGridFilter';

const PRODUCT_SORT_OPTIONS = [
  {
    label: 'Featured',
    value: 'featured'
  },
  {
    label: 'Best selling',
    value: 'best_selling'
  },
  {
    label: 'Alphabetically, A-Z',
    value: 'name'
  },
  {
    label: 'Alphabetically, Z-A',
    value: 'name_desc'
  },
  {
    label: 'Price, low to high',
    value: 'price'
  },
  {
    label: 'Price, high to low',
    value: 'price_desc'
  },
  {
    label: 'Date, old to new',
    value: 'created'
  },
  {
    label: 'Date, new to old',
    value: 'created_desc'
  }
];

const WsProductGrid = ({
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  pathname,
  ecomStoreTheme,
  onNavigate,
  baseStoreUrl,
  editModeEnabled,
  search,
  ...rest
}) => {
  const fetchedPageSize = useRef(null);
  const [debouncedPageSize, setDebouncedPageSize] = useState(
    config.productsPerPage * 1 ?? 20
  );
  const { filter } = useWebstoreSearchFilter({
    editModeEnabled,
    onNavigate,
    pathname: `${baseStoreUrl}${pathname}`,
    search
  });
  const [pageIndexState, setPageIndexState] = useState(null);
  const [filterState, setFilterState] = useState({});
  const { state, fetchEcomStoreProducts } = useContext(StoreUserContext);
  const { isTabletOrSmaller, isLaptopOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const collectionUrlHandle = pathname.split('/')[2];
  const isAllProducts = pathname.startsWith('/products');
  const ecomStoreCollection = ecomStoreTheme.ecomStore.ecomStoreCollections.find(
    (c) => sterilizeUrlHandle(c.name) === collectionUrlHandle
  );
  const {
    pageIndex,
    totalPages,
    filter: dataFilter,
    data: productData,
    loading: dataLoading
  } = state.paginatedEcomStoreProducts;

  const showSecondImageOnHover = config.showSecondImageOnHover === 'true';
  const showVendorName = config.showVendorName === 'true';
  const squareRatio = config.imageRatio === IMAGE_DISPLAY_ENUM.SQUARE;
  const productsPerPage = config.productsPerPage * 1 ?? 20;
  const isQuickAddEnabled =
    config.quickAddStyle === QUICK_ADD_STYLE_ENUM.STANDARD;

  const alreadyLoaded =
    dataFilter &&
    dataFilter.fkEcomStoreCollection ===
      (ecomStoreCollection?.pkEcomStoreCollection || null);
  const isVerticalFilter =
    config.filterLayout === DESKTOP_FILTER_LAYOUT_ENUM.VERTICAL;
  const loading = !alreadyLoaded || dataLoading;
  const filterEnabled = config.filterEnabled === 'true';

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPageSize(productsPerPage);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [productsPerPage]);

  useEffect(() => {
    if (
      fetchedPageSize.current !== debouncedPageSize ||
      (!alreadyLoaded && (isAllProducts || ecomStoreCollection)) ||
      Object.keys(filter).some((key) => filter[key] !== filterState[key])
    ) {
      // eslint-disable-next-line no-use-before-define
      fetchStoreProducts();
      setFilterState(filter);
      fetchedPageSize.current = debouncedPageSize;
    }
  }, [ecomStoreCollection, filter, debouncedPageSize]);

  const fetchStoreProducts = (nextIndex = 1) => {
    setPageIndexState(nextIndex);
    fetchEcomStoreProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      {
        ...filter,
        fkEcomStoreCollection:
          ecomStoreCollection?.pkEcomStoreCollection ?? null,
        pageSize: productsPerPage,
        sort: filter.sort ?? PRODUCT_SORT_OPTIONS[0].value,
        page: nextIndex
      },
      () => {},
      () => {
        setPageIndexState(null);
      }
    );
    if (nextIndex > 1) {
      const scrollContainerRef = document.getElementById(
        'product-grid-container'
      );
      if (scrollContainerRef) {
        scrollContainerRef.scrollIntoView({ block: 'start' });
      }
    }
  };

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      containerId="product-grid-container"
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppStack style={{ width: '100%' }}>
        <WsProductGridFilter
          baseStoreUrl={baseStoreUrl}
          colorSchemes={colorSchemes}
          config={config}
          ecomStoreTheme={ecomStoreTheme}
          editModeEnabled={editModeEnabled}
          loading={loading}
          mediaQueries={mediaQueries}
          onNavigate={onNavigate}
          pathname={pathname}
          search={search}
          themeConfig={themeConfig}
          {...rest}
        />

        <AppFlexbox
          style={{ flex: 1, width: '100%', justifyContent: 'center' }}
        >
          {!isTabletOrSmaller && filterEnabled && isVerticalFilter && (
            <WsProductGridFilter
              baseStoreUrl={baseStoreUrl}
              colorSchemes={colorSchemes}
              config={config}
              ecomStoreTheme={ecomStoreTheme}
              editModeEnabled={editModeEnabled}
              mediaQueries={mediaQueries}
              onNavigate={onNavigate}
              pathname={pathname}
              search={search}
              themeConfig={themeConfig}
              verticalLayout
              {...rest}
            />
          )}

          <AppStack style={{ flex: 1 }}>
            {!loading ? (
              productData.length === 0 ? (
                <AppStack
                  style={{ flex: 1, alignItems: 'center', padding: 40 }}
                >
                  <AppText
                    style={{
                      color: colorScheme.textColor,
                      fontSize: 18,
                      fontWeight: '500'
                    }}
                  >
                    No products found
                  </AppText>
                </AppStack>
              ) : (
                <Grid
                  columns={
                    (
                      filterEnabled && isVerticalFilter
                        ? isLaptopOrSmaller
                        : isTabletOrSmaller
                    )
                      ? config.mobileColumnCount * 1 ?? 1
                      : config.desktopColumnCount * 1 ?? 6
                  }
                  style={{ flex: 1 }}
                >
                  {productData.slice(0, productsPerPage).map((p) => (
                    <Grid.Col key={p.pkEcomStoreProduct} span={1}>
                      <WsProductCard
                        baseStoreUrl={baseStoreUrl}
                        colorScheme={colorScheme}
                        ecomStoreProduct={p}
                        isQuickAddEnabled={isQuickAddEnabled}
                        onNavigate={onNavigate}
                        portraitRatio={!squareRatio}
                        showSecondImageOnHover={showSecondImageOnHover}
                        showVendorName={showVendorName}
                      />
                    </Grid.Col>
                  ))}
                </Grid>
              )
            ) : (
              <Grid
                columns={
                  (
                    filterEnabled && isVerticalFilter
                      ? isLaptopOrSmaller
                      : isTabletOrSmaller
                  )
                    ? config.mobileColumnCount * 1 ?? 1
                    : config.desktopColumnCount * 1 ?? 6
                }
                style={{ flex: 1 }}
              >
                {Array.from(Array(productsPerPage)).map((x, i) => (
                  <Grid.Col
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    span={1}
                  >
                    <WsProductCard
                      baseStoreUrl={baseStoreUrl}
                      colorScheme={colorScheme}
                      isQuickAddEnabled={isQuickAddEnabled}
                      onNavigate={onNavigate}
                      portraitRatio={!squareRatio}
                      showSecondImageOnHover={showSecondImageOnHover}
                      showVendorName={showVendorName}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            )}

            {totalPages > 1 && (
              <AppFlexbox style={{ justifyContent: 'center' }}>
                <AppStack>
                  <Pagination
                    color="dark"
                    onChange={fetchStoreProducts}
                    total={totalPages}
                    value={pageIndexState || pageIndex}
                  />
                </AppStack>
              </AppFlexbox>
            )}
          </AppStack>
        </AppFlexbox>
      </AppStack>
    </WsSectionContainer>
  );
};

WsProductGrid.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string,
  themeConfig: PropTypes.object
};

export default WsProductGrid;
