import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsProductBlock from './WsProductBlock';

const configNameValues = {
  ecomStoreProduct: 'ecomStoreProduct',
  showSecondImageOnHover: 'showSecondImageOnHover',
  showVendorName: 'showVendorName'
};

const configDefaultValues = {
  ecomStoreProduct: '',
  showSecondImageOnHover: 'true',
  showVendorName: 'true'
};

const configOptions = {
  [configNameValues.ecomStoreProduct]: {
    label: 'Product',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreProduct
  },
  [configNameValues.showSecondImageOnHover]: {
    label: 'Show second image on hover',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.showVendorName]: {
    label: 'Show vendor name',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  }
};

const config = {
  title: 'Product',
  type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.PRODUCT,
  icon: BoxMargin,
  Component: WsProductBlock,
  configOptions,
  defaultConfig: configDefaultValues,
  getTitle: (c) => c.text,
  onFetchDataConfig: (sectionConfigValues) => ({
    type: 'ecomStoreProduct',
    entityId: sectionConfigValues.ecomStoreProduct,
    filter: {}
  })
};

export { config, configOptions, configNameValues, configDefaultValues };
