import React from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Anchor, Image, Skeleton } from '@mantine/core';
import { AlertTriangle } from 'tabler-icons-react';
import AppCard from '../../../../common/AppCard';
import AppStack from '../../../../common/AppStack';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppText from '../../../../common/AppText';

const WsAccountAuthSection = ({
  title,
  error,
  onSubmit,
  children,
  noLogo,
  titleAction,
  viewLoading,
  cardProps,
  mediaQueries,
  ecomStoreTheme,
  onNavigate
}) => {
  const { isLargeMobileOrSmaller } = mediaQueries;
  const logoImage =
    ecomStoreTheme.logoImageUrl || ecomStoreTheme.ecomStore.logoImageUrl;

  return (
    <AppCard
      radius={isLargeMobileOrSmaller ? 0 : 'lg'}
      shadow={isLargeMobileOrSmaller ? 0 : 'xl'}
      withBorder={!isLargeMobileOrSmaller}
      {...cardProps}
      style={{
        width: '100%',
        padding: 0,
        overflow: 'auto',
        flex: isLargeMobileOrSmaller ? 1 : 'unset',
        maxWidth: isLargeMobileOrSmaller ? '100%' : 475,
        margin: isLargeMobileOrSmaller ? 0 : '20px 0px',
        ...cardProps?.style
      }}
    >
      {!noLogo && (
        <AppStack
          style={{
            padding: isLargeMobileOrSmaller ? 20 : '40px 40px 20px 40px'
          }}
        >
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppFlexbox
              onClick={() => onNavigate('/')}
              style={{
                alignItems: 'center',
                textDecoration: 'none',
                gap: 10,
                cursor: 'pointer'
              }}
            >
              {logoImage && (
                <AppStack style={{ minWidth: 40, minHeight: 40 }}>
                  <Image
                    alt="SportsHeadz Main Logo"
                    fit="contain"
                    height={40}
                    src={logoImage}
                    width="100%"
                  />
                </AppStack>
              )}
              <AppText
                style={{
                  fontSize: isLargeMobileOrSmaller ? 22 : 18,
                  color: '#000',
                  fontWeight: 700
                }}
              >
                <em>{ecomStoreTheme.ecomStore.name}</em>
              </AppText>
            </AppFlexbox>
          </AppFlexbox>
        </AppStack>
      )}

      {error && (
        <AppStack
          style={{
            padding: isLargeMobileOrSmaller
              ? '0px 20px 20px 20px'
              : '0px 40px 20px 40px'
          }}
        >
          <Alert
            color="#c40000"
            icon={<AlertTriangle style={{ height: 28, width: 28 }} />}
            radius={10}
            styles={{
              body: { gap: 5 },
              icon: { height: 28, width: 28 },
              title: { fontSize: 17, fontWeight: 500 }
            }}
            title="There was a problem"
            variant="outline"
          >
            <AppText style={{ fontSize: 13 }}>{error}</AppText>
          </Alert>
        </AppStack>
      )}

      {title && (
        <AppStack
          style={{ padding: isLargeMobileOrSmaller ? '0px 20px' : '0px 40px' }}
        >
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontWeight: 500, fontSize: 26 }}>{title}</AppText>

            {titleAction}
          </AppFlexbox>
        </AppStack>
      )}

      {viewLoading ? (
        <AppStack
          style={{
            padding: isLargeMobileOrSmaller ? 20 : 40,
            gap: 10,
            flex: 1
          }}
        >
          <AppFlexbox style={{ padding: 8, gap: 10, alignItems: 'center' }}>
            <Skeleton height={38} radius={100} width={38} />
            <Skeleton height={12} width={100} />
          </AppFlexbox>
          <AppFlexbox style={{ padding: 8, gap: 10, alignItems: 'center' }}>
            <Skeleton height={38} radius={100} width={38} />
            <Skeleton height={12} width={100} />
          </AppFlexbox>
        </AppStack>
      ) : (
        <AppStack
          component="form"
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSubmit();
          }}
          style={{ flex: 1 }}
        >
          {children}
        </AppStack>
      )}
      <AppFlexbox p={{ base: 20, xsm: 40 }} style={{ gap: 20, paddingTop: 0 }}>
        <Anchor
          href="https://www.sportsheadz.com/privacy-policy"
          style={{ fontSize: 12, color: '#666' }}
          target="_blank"
        >
          Privacy
        </Anchor>
      </AppFlexbox>
    </AppCard>
  );
};

WsAccountAuthSection.propTypes = {
  cardProps: PropTypes.object,
  children: PropTypes.node,
  ecomStoreTheme: PropTypes.object,
  error: PropTypes.string,
  logoImage: PropTypes.string,
  mediaQueries: PropTypes.object,
  noBackButtton: PropTypes.bool,
  noLogo: PropTypes.bool,
  onNavigate: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  titleAction: PropTypes.node,
  viewLoading: PropTypes.bool
};

export default WsAccountAuthSection;
