import React, { useContext, useState } from 'react';
import {
  Button,
  Avatar,
  useMantineTheme,
  Menu,
  ScrollArea,
  Skeleton
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, Help, Logout } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import * as classes from '../../../styles/nestedStyles.module.css';
import AppMenu from '../../common/AppMenu';
import SUPPORT_LINKS from './supportLinks';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppStack from '../../common/AppStack';

const HeaderNavigationMenu = ({
  profileInfo,
  profileLinks,
  adminLinks,
  control,
  controlTitle,
  lightMode,
  onLogoutAction,
  logoutTitle,
  alwaysShowProfileLinks,
  hideSupport
}) => {
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();
  const theme = useMantineTheme();
  const { state } = useContext(AuthContext);
  const [openState, setOpenState] = useState(false);
  const textColor = lightMode ? '#000' : '#FFF';
  const loading = !state.tokenAttempted || state.loading;

  const logoutAction = () => {
    onLogoutAction();
    setOpenState(false);
  };

  return (
    <AppFlexbox>
      {!state.tokenAttempted || state.isAuthenticated || state.loading ? (
        <AppMenu
          classNames={{
            item: lightMode
              ? classes['hover-button-light-mode']
              : classes['hover-button-dark-mode']
          }}
          control={
            control ? (
              <AppFlexbox
                onClick={() => setOpenState(true)}
                style={{ cursor: 'pointer' }}
              >
                {control}
              </AppFlexbox>
            ) : loading ? (
              isLargeMobileOrSmaller ? (
                <Skeleton
                  height={36}
                  radius={100}
                  style={{ opacity: lightMode ? 1 : 0.2 }}
                  width={36}
                />
              ) : (
                <Skeleton
                  height={36}
                  radius={10}
                  style={{ opacity: lightMode ? 1 : 0.2 }}
                  width={145}
                />
              )
            ) : (
              <Button
                color="dark"
                size="sm"
                style={{
                  color: textColor,
                  borderRadius: isLargeMobileOrSmaller ? 100 : 10,
                  border: lightMode ? 'solid 1px #dee2e6' : 'none',
                  ...(isLargeMobileOrSmaller
                    ? { padding: 4, height: 'unset' }
                    : {})
                }}
                variant={
                  lightMode
                    ? 'white'
                    : !openState && isLargeMobileOrSmaller
                    ? 'subtle'
                    : 'filled'
                }
              >
                {profileInfo && (
                  <Avatar
                    name={profileInfo.label}
                    radius="xl"
                    size={isLargeMobileOrSmaller ? 'md' : 'sm'}
                    src={profileInfo.image}
                    styles={{
                      root: { border: 'solid 1px #dee2e6' },
                      placeholder: { color: '#FFF' }
                    }}
                  />
                )}

                <AppText
                  style={{
                    marginLeft: isLargeMobileOrSmaller ? 5 : 10,
                    marginRight: 5,
                    fontSize: 16
                  }}
                  visibleFrom="xsm"
                  weight={500}
                >
                  {controlTitle ?? 'My Account'}
                </AppText>
                <AppStack visibleFrom="xsm">
                  {openState ? <ChevronUp /> : <ChevronDown />}
                </AppStack>
              </Button>
            )
          }
          onClose={() => setOpenState(false)}
          onOpen={() => setOpenState(true)}
          opened={openState}
          position="bottom-end"
          styles={{
            menu: {},
            dropdown: {
              padding: 3,
              backgroundColor: lightMode ? theme.white : theme.colors.dark[6],
              borderColor: lightMode ? theme.white : theme.colors.dark[6],
              maxHeight: 'calc(100vh - 60px)',
              overflowY: 'hidden'
            },
            item: {
              padding: '5px 10px',
              color: textColor
            }
          }}
          width={240}
        >
          <AppFlexbox
            style={{
              flex: 1,
              gap: 0,
              flexDirection: 'column',
              maxHeight: 'calc(100vh - 60px)'
            }}
          >
            {profileInfo && (
              <>
                <Menu.Item
                  component={profileInfo.to ? Link : null}
                  leftSection={
                    <Avatar
                      name={profileInfo.label}
                      radius="xl"
                      size="sm"
                      src={profileInfo.image}
                      styles={{
                        root: { border: 'solid 1px lightgrey' },
                        placeholder: { color: '#FFF' }
                      }}
                    />
                  }
                  onClick={() => setOpenState(false)}
                  rightSection={
                    profileInfo.icon ? <profileInfo.icon size={18} /> : null
                  }
                  style={{
                    backgroundColor: profileInfo.active
                      ? lightMode
                        ? '#FFF'
                        : '#1A1B1E'
                      : null,
                    fontWeight: 500
                  }}
                  to={profileInfo.to}
                >
                  {profileInfo.label}
                </Menu.Item>
                {profileInfo.secondAction && (
                  <Menu.Item
                    key={profileInfo.secondAction.label}
                    component={Link}
                    leftSection={<profileInfo.secondAction.icon size={18} />}
                    style={{ marginTop: 2 }}
                    to={profileInfo.secondAction.to}
                  >
                    {profileInfo.secondAction.label}
                  </Menu.Item>
                )}
                <Menu.Divider />
              </>
            )}

            <AppFlexbox
              style={{
                gap: 0,
                height: '100%',
                flex: 1,
                overflow: 'hidden'
              }}
            >
              <ScrollArea style={{ minWidth: 0, width: '100%' }} type="auto">
                {profileLinks && profileLinks.length > 0 && (
                  <>
                    {profileLinks.map((link) => (
                      <Menu.Item
                        key={link.title}
                        component={Link}
                        leftSection={<link.icon size={18} />}
                        to={link.to}
                      >
                        {link.title}
                      </Menu.Item>
                    ))}
                    <Menu.Divider />
                  </>
                )}

                {adminLinks && adminLinks.length > 0 && (
                  <AppStack
                    style={{ gap: 0 }}
                    visibleFrom={alwaysShowProfileLinks ? null : 'sm'}
                  >
                    {adminLinks.map((link) => (
                      <Menu.Item
                        key={link.title}
                        component={Link}
                        leftSection={<link.icon size={18} />}
                        to={
                          link.linkOverride ? link.linkOverride(state) : link.to
                        }
                      >
                        {link.title}
                      </Menu.Item>
                    ))}

                    {!hideSupport && (
                      <>
                        <Menu.Divider />
                        <Menu.Item
                          component="a"
                          href={SUPPORT_LINKS.index.href}
                          leftSection={<Help size={18} />}
                          target="_blank"
                        >
                          {SUPPORT_LINKS.index.label}
                        </Menu.Item>
                      </>
                    )}

                    <Menu.Divider />
                  </AppStack>
                )}

                <Menu.Item
                  leftSection={
                    <Logout size={18} style={{ transform: 'rotate(180deg)' }} />
                  }
                  onClick={logoutAction}
                >
                  {logoutTitle ?? 'Sign out'}
                </Menu.Item>
              </ScrollArea>
            </AppFlexbox>
          </AppFlexbox>
        </AppMenu>
      ) : (
        <Button
          color={lightMode ? 'gray' : 'dark'}
          component={Link}
          radius="xs"
          size="sm"
          style={{
            color: textColor,
            borderRadius: 10,
            border: 'none'
          }}
          to="/auth/signin"
          variant="filled"
        >
          Sign In
        </Button>
      )}
    </AppFlexbox>
  );
};

HeaderNavigationMenu.propTypes = {
  adminLinks: PropTypes.array,
  alwaysShowProfileLinks: PropTypes.bool,
  control: PropTypes.node,
  controlTitle: PropTypes.string,
  hideSupport: PropTypes.bool,
  lightMode: PropTypes.bool,
  logoutTitle: PropTypes.string,
  onLogoutAction: PropTypes.func,
  profileInfo: PropTypes.object,
  profileLinks: PropTypes.array,
  publicLinks: PropTypes.array
};

export default HeaderNavigationMenu;
