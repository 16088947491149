import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Context as StoreUserContext } from '../providers/StoreUserContextProvider';
import LoadableView from '../components/common/LoadableView';

const REDIRECT_URLS = [
  {
    path: '/redirect/customer-orders',
    action: 'store',
    param: 'pkEcomStore',
    redirectPath: '/account/orders'
  },
  {
    path: '/redirect/association',
    action: 'store',
    param: 'pkAssociation',
    redirectPath: ''
  },
  {
    path: '/redirect/team',
    action: 'store',
    param: 'pkTeam',
    redirectPath: ''
  }
];

const RedirectViews = () => {
  const navigate = useNavigate();
  const { entityId } = useParams();
  const { pathname, search } = useLocation();
  const { fetchEcomStoreSummary } = useContext(StoreUserContext);

  const searchParams = new URLSearchParams(search);
  const pkEcomStore = searchParams.get('store');
  const pkEcomStoreCart = searchParams.get('order');
  const guestEmail = searchParams.get('email');
  const guestUuid = searchParams.get('uuid');

  useEffect(() => {
    const redirectUrl = REDIRECT_URLS.find((f) => pathname.startsWith(f.path));
    if (redirectUrl) {
      switch (redirectUrl.action) {
        case 'store':
          // eslint-disable-next-line no-use-before-define
          onStoreRedirect(redirectUrl);
          break;
        default:
          break;
      }
    }
    else {
      navigate('/');
    }
  }, [pathname]);

  const onStoreRedirect = (redirectUrl) => {
    fetchEcomStoreSummary(
      { [redirectUrl.param]: entityId ?? pkEcomStore },
      (ecomStore) => {
        let url = `/store/${ecomStore.urlHandle}${redirectUrl.redirectPath}`;
        if (pkEcomStoreCart) {
          url += `/${pkEcomStoreCart}`;
          if (guestEmail && guestUuid) {
            url += `?email=${guestEmail}&uuid=${guestUuid}`;
          }
        }
        navigate(url);
      },
      () => {
        navigate('/');
      }
    );
  };

  return <LoadableView isLoading />;
};

export default RedirectViews;
