import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Anchor,
  Badge,
  Divider,
  Menu,
  Skeleton,
  Tooltip
} from '@mantine/core';
import dayjs from 'dayjs';
import { Dots, Pencil, Send } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import AppMenu from '../../common/AppMenu';
import VendorOrderProductPreview from './VendorOrderProductPreview';

const OrderTrackingInfoCard = ({
  productData,
  ecomStoreCartShipping,
  loading,
  onNotify,
  onUpdateShipping,
  isStoreViewing
}) =>
  !loading && ecomStoreCartShipping ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ padding: 16, gap: 8 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>
            Fulfillment
          </AppText>
        </AppFlexbox>

        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          {ecomStoreCartShipping.length === 0 ? (
            <AppStack
              style={{
                gap: 0,
                padding: 20,
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <AppText style={{ fontSize: 14, color: '#666' }}>
                No fulfillment information available.
              </AppText>
            </AppStack>
          ) : (
            <AppStack style={{ gap: 0 }}>
              {ecomStoreCartShipping.map((s, index) => {
                const productCount = s.ecomStoreCartShippingProducts.reduce(
                  (r, c) => r + c.count,
                  0
                );
                return (
                  <React.Fragment key={s.pkEcomStoreCartShipping}>
                    {index > 0 && <Divider />}
                    <AppStack style={{ flex: 1, gap: 5, padding: 8 }}>
                      <AppFlexbox
                        style={{
                          flex: 1,
                          gap: 8,
                          padding: 8,
                          alignItems: 'start',
                          justifyContent: 'space-between'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            width: '100%',
                            gap: 8,
                            alignItems: 'center'
                          }}
                        >
                          <AppStack
                            style={{
                              width: '100%',
                              overflow: 'hidden',
                              gap: 3
                            }}
                          >
                            <AppFlexbox style={{ gap: 8 }}>
                              <AppText
                                style={{
                                  fontSize: 14,
                                  fontWeight: 500
                                }}
                              >
                                Date:
                              </AppText>
                              <AppText style={{ fontSize: 14 }}>
                                {dayjs(formatUtcDate(s.createdAt)).format(
                                  'MMMM D, YYYY'
                                )}{' '}
                                at{' '}
                                {dayjs(formatUtcDate(s.createdAt)).format(
                                  'h:mm A'
                                )}
                              </AppText>
                            </AppFlexbox>
                            <AppFlexbox style={{ gap: 8 }}>
                              <AppText
                                style={{
                                  fontSize: 14,
                                  fontWeight: 500
                                }}
                              >
                                Carrier:
                              </AppText>
                              <AppText style={{ fontSize: 14 }}>
                                {s.shippingCarrier || 'N/A'}
                              </AppText>
                            </AppFlexbox>
                            <AppFlexbox style={{ gap: 8 }}>
                              <AppText
                                style={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  overflow: 'unset'
                                }}
                              >
                                Tracking:
                              </AppText>
                              <Anchor
                                c={
                                  s.trackingUrl || s.trackingNumber
                                    ? 'dodgerblue'
                                    : '#000'
                                }
                                href={s.trackingUrl}
                                style={{ fontSize: 14 }}
                                target="_blank"
                              >
                                {s.trackingNumber || s.trackingUrl || 'N/A'}
                              </Anchor>
                            </AppFlexbox>
                            <AppFlexbox style={{ gap: 8 }}>
                              <AppText
                                style={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  overflow: 'unset'
                                }}
                              >
                                Customer notified:
                              </AppText>
                              <AppText
                                style={{
                                  fontSize: 14,
                                  color: s.emailSent ? 'green' : 'red'
                                }}
                              >
                                {s.emailSent ? 'Yes' : 'No'}
                              </AppText>
                            </AppFlexbox>
                            {!s.emailSent && (
                              <Tooltip
                                label="The customer has not been notified"
                                withArrow
                              >
                                <Badge color="red" style={{ marginTop: 3 }}>
                                  Action required
                                </Badge>
                              </Tooltip>
                            )}
                          </AppStack>
                        </AppFlexbox>

                        {!isStoreViewing && (onNotify || onUpdateShipping) && (
                          <AppFlexbox style={{ gap: 8 }}>
                            <AppMenu
                              control={
                                <ActionIcon
                                  color="#ced4da"
                                  size="md"
                                  variant="outline"
                                >
                                  <Dots color="#000" />
                                </ActionIcon>
                              }
                            >
                              {onUpdateShipping && (
                                <Menu.Item
                                  leftSection={<Pencil size={18} />}
                                  onClick={() => onUpdateShipping(s)}
                                >
                                  Edit tracking
                                </Menu.Item>
                              )}

                              {onNotify && (
                                <Menu.Item
                                  leftSection={<Send size={18} />}
                                  onClick={onNotify}
                                >
                                  {s.emailSent
                                    ? 'Resent notification'
                                    : 'Notify customer'}
                                </Menu.Item>
                              )}
                            </AppMenu>
                          </AppFlexbox>
                        )}
                      </AppFlexbox>
                      <Divider
                        label={singularPluralFormat(
                          productCount,
                          'ITEM',
                          'ITEMS'
                        )}
                        labelPosition="center"
                      />
                      <AppStack style={{ gap: 5 }}>
                        {s.ecomStoreCartShippingProducts.map((p) => {
                          const product = productData.find(
                            (pd) =>
                              pd.pkEcomStoreCartProduct ===
                              p.fkEcomStoreCartProduct
                          );
                          return (
                            <VendorOrderProductPreview
                              key={p.pkEcomStoreCartShippingProduct}
                              product={product}
                              quantityIndicatorAmount={p.count}
                            />
                          );
                        })}
                      </AppStack>
                    </AppStack>
                  </React.Fragment>
                );
              })}
            </AppStack>
          )}
        </AppCard>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ padding: 16, gap: 8 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Skeleton height={22} width="20%" />
        </AppFlexbox>

        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox style={{ flex: 1, maxWidth: 150 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="15%" />
                </AppFlexbox>
                <Skeleton height={18} width="15%" />
              </AppFlexbox>
            </AppStack>

            <Divider />
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox style={{ flex: 1, maxWidth: 150 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="15%" />
                </AppFlexbox>
                <Skeleton height={18} width="15%" />
              </AppFlexbox>
            </AppStack>
          </AppStack>
        </AppCard>
      </AppStack>
    </AppCard>
  );

OrderTrackingInfoCard.propTypes = {
  ecomStoreCartShipping: PropTypes.array,
  isStoreViewing: PropTypes.bool,
  loading: PropTypes.bool,
  onNotify: PropTypes.func,
  onUpdateShipping: PropTypes.func,
  productData: PropTypes.array
};

export default OrderTrackingInfoCard;
