const COUNTRIES_LIST = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Republic of the',
  'Congo, Democratic Republic of the',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  "Ivory Coast (Côte d'Ivoire)",
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];

const ADDITIONAL_SHIPPING_REGION_LIST = [
  'Åland Islands',
  'American Samoa',
  'Anguilla',
  'Aruba',
  'Bermuda',
  'British Virgin Islands',
  'Cayman Islands',
  'Cook Islands',
  'Curacao',
  'Falkland Islands',
  'Faroe Islands',
  'French Guiana',
  'French Polynesia',
  'Gibraltar',
  'Greenland',
  'Guadeloupe',
  'Guam',
  'Guernsey',
  'Hong Kong',
  'Isle of Man',
  'Jersey',
  'Macau',
  'Martinique',
  'Montserrat',
  'New Caledonia',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Pitcairn Islands',
  'Puerto Rico',
  'Reunion',
  'Saint Barthelemy',
  'Saint Helena',
  'Saint Pierre and Miquelon',
  'Sint Maarten',
  'Tokelau',
  'Turks and Caicos Islands',
  'U.S. Virgin Islands',
  'Wallis and Futuna'
];

const SHIPPING_REGION_LIST = [
  ...COUNTRIES_LIST,
  ...ADDITIONAL_SHIPPING_REGION_LIST
].sort((a, b) => a.localeCompare(b));

const CANADA_PROVINCE_LIST = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
];

const USA_STATE_LIST = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

const CANADA_PROVINCE_ENUM = {
  ALBERTA: 1,
  BRITISH_COLUMBIA: 2,
  MANITOBA: 3,
  NEW_BRUNSWICK: 4,
  NEWFOUNDLAND_AND_LABRADOR: 5,
  NOVA_SCOTIA: 6,
  ONTARIO: 7,
  PRINCE_EDWARD_ISLAND: 8,
  QUEBEC: 9,
  SASKATCHEWAN: 10,
  NORTHWEST_TERRITORIES: 11,
  NUNAVUT: 12,
  YUKON: 13
};

const USA_STATE_ENUM = {
  ALABAMA: 14,
  ALASKA: 15,
  ARIZONA: 16,
  ARKANSAS: 17,
  CALIFORNIA: 18,
  COLORADO: 19,
  CONNECTICUT: 20,
  DELAWARE: 21,
  FLORIDA: 22,
  GEORGIA: 23,
  HAWAII: 24,
  IDAHO: 25,
  ILLINOIS: 26,
  INDIANA: 27,
  IOWA: 28,
  KANSAS: 29,
  KENTUCKY: 30,
  LOUISIANA: 31,
  MAINE: 32,
  MARYLAND: 33,
  MASSACHUSETTS: 34,
  MICHIGAN: 35,
  MINNESOTA: 36,
  MISSISSIPPI: 37,
  MISSOURI: 38,
  MONTANA: 39,
  NEBRASKA: 40,
  NEVADA: 41,
  NEW_HAMPSHIRE: 42,
  NEW_JERSEY: 43,
  NEW_MEXICO: 44,
  NEW_YORK: 45,
  NORTH_CAROLINA: 46,
  NORTH_DAKOTA: 47,
  OHIO: 48,
  OKLAHOMA: 49,
  OREGON: 50,
  PENNSYLVANIA: 51,
  RHODE_ISLAND: 52,
  SOUTH_CAROLINA: 53,
  SOUTH_DAKOTA: 54,
  TENNESSEE: 55,
  TEXAS: 56,
  UTAH: 57,
  VERMONT: 58,
  VIRGINIA: 59,
  WASHINGTON: 60,
  WEST_VIRGINIA: 61,
  WISCONSIN: 62,
  WYOMING: 63
};

const CANADA_PROVINCE_LIST_OPTIONS = [
  {
    label: 'Alberta',
    value: CANADA_PROVINCE_ENUM.ALBERTA,
    abbr: 'AB'
  },
  {
    label: 'British Columbia',
    value: CANADA_PROVINCE_ENUM.BRITISH_COLUMBIA,
    abbr: 'BC'
  },
  {
    label: 'Manitoba',
    value: CANADA_PROVINCE_ENUM.MANITOBA,
    abbr: 'MB'
  },
  {
    label: 'New Brunswick',
    value: CANADA_PROVINCE_ENUM.NEW_BRUNSWICK,
    abbr: 'NB'
  },
  {
    label: 'Newfoundland and Labrador',
    value: CANADA_PROVINCE_ENUM.NEWFOUNDLAND_AND_LABRADOR,
    abbr: 'NL'
  },
  {
    label: 'Nova Scotia',
    value: CANADA_PROVINCE_ENUM.NOVA_SCOTIA,
    abbr: 'NS'
  },
  {
    label: 'Ontario',
    value: CANADA_PROVINCE_ENUM.ONTARIO,
    abbr: 'ON'
  },
  {
    label: 'Prince Edward Island',
    value: CANADA_PROVINCE_ENUM.PRINCE_EDWARD_ISLAND,
    abbr: 'PE'
  },
  {
    label: 'Quebec',
    value: CANADA_PROVINCE_ENUM.QUEBEC,
    abbr: 'QC'
  },
  {
    label: 'Saskatchewan',
    value: CANADA_PROVINCE_ENUM.SASKATCHEWAN,
    abbr: 'SK'
  },
  {
    label: 'Northwest Territories',
    value: CANADA_PROVINCE_ENUM.NORTHWEST_TERRITORIES,
    abbr: 'NT'
  },
  {
    label: 'Nunavut',
    value: CANADA_PROVINCE_ENUM.NUNAVUT,
    abbr: 'NU'
  },
  {
    label: 'Yukon',
    value: CANADA_PROVINCE_ENUM.YUKON,
    abbr: 'YT'
  }
];

const USA_STATE_LIST_OPTIONS = [
  {
    label: 'Alabama',
    value: USA_STATE_ENUM.ALABAMA,
    abbr: 'AL'
  },
  {
    label: 'Alaska',
    value: USA_STATE_ENUM.ALASKA,
    abbr: 'AK'
  },
  {
    label: 'Arizona',
    value: USA_STATE_ENUM.ARIZONA,
    abbr: 'AZ'
  },
  {
    label: 'Arkansas',
    value: USA_STATE_ENUM.ARKANSAS,
    abbr: 'AR'
  },
  {
    label: 'California',
    value: USA_STATE_ENUM.CALIFORNIA,
    abbr: 'CA'
  },
  {
    label: 'Colorado',
    value: USA_STATE_ENUM.COLORADO,
    abbr: 'CO'
  },
  {
    label: 'Connecticut',
    value: USA_STATE_ENUM.CONNECTICUT,
    abbr: 'CT'
  },
  {
    label: 'Delaware',
    value: USA_STATE_ENUM.DELAWARE,
    abbr: 'DE'
  },
  {
    label: 'Florida',
    value: USA_STATE_ENUM.FLORIDA,
    abbr: 'FL'
  },
  {
    label: 'Georgia',
    value: USA_STATE_ENUM.GEORGIA,
    abbr: 'GA'
  },
  {
    label: 'Hawaii',
    value: USA_STATE_ENUM.HAWAII,
    abbr: 'HI'
  },
  {
    label: 'Idaho',
    value: USA_STATE_ENUM.IDAHO,
    abbr: 'ID'
  },
  {
    label: 'Illinois',
    value: USA_STATE_ENUM.ILLINOIS,
    abbr: 'IL'
  },
  {
    label: 'Indiana',
    value: USA_STATE_ENUM.INDIANA,
    abbr: 'IN'
  },
  {
    label: 'Iowa',
    value: USA_STATE_ENUM.IOWA,
    abbr: 'IA'
  },
  {
    label: 'Kansas',
    value: USA_STATE_ENUM.KANSAS,
    abbr: 'KS'
  },
  {
    label: 'Kentucky',
    value: USA_STATE_ENUM.KENTUCKY,
    abbr: 'KY'
  },
  {
    label: 'Louisiana',
    value: USA_STATE_ENUM.LOUISIANA,
    abbr: 'LA'
  },
  {
    label: 'Maine',
    value: USA_STATE_ENUM.MAINE,
    abbr: 'ME'
  },
  {
    label: 'Maryland',
    value: USA_STATE_ENUM.MARYLAND,
    abbr: 'MD'
  },
  {
    label: 'Massachusetts',
    value: USA_STATE_ENUM.MASSACHUSETTS,
    abbr: 'MA'
  },
  {
    label: 'Michigan',
    value: USA_STATE_ENUM.MICHIGAN,
    abbr: 'MI'
  },
  {
    label: 'Minnesota',
    value: USA_STATE_ENUM.MINNESOTA,
    abbr: 'MN'
  },
  {
    label: 'Mississippi',
    value: USA_STATE_ENUM.MISSISSIPPI,
    abbr: 'MS'
  },
  {
    label: 'Missouri',
    value: USA_STATE_ENUM.MISSOURI,
    abbr: 'MO'
  },
  {
    label: 'Montana',
    value: USA_STATE_ENUM.MONTANA,
    abbr: 'MT'
  },
  {
    label: 'Nebraska',
    value: USA_STATE_ENUM.NEBRASKA,
    abbr: 'NE'
  },
  {
    label: 'Nevada',
    value: USA_STATE_ENUM.NEVADA,
    abbr: 'NV'
  },
  {
    label: 'New_Hampshire',
    value: USA_STATE_ENUM.NEW_HAMPSHIRE,
    abbr: 'NH'
  },
  {
    label: 'New_Jersey',
    value: USA_STATE_ENUM.NEW_JERSEY,
    abbr: 'NJ'
  },
  {
    label: 'New_Mexico',
    value: USA_STATE_ENUM.NEW_MEXICO,
    abbr: 'NM'
  },
  {
    label: 'New_York',
    value: USA_STATE_ENUM.NEW_YORK,
    abbr: 'NY'
  },
  {
    label: 'North_Carolina',
    value: USA_STATE_ENUM.NORTH_CAROLINA,
    abbr: 'NC'
  },
  {
    label: 'North_Dakota',
    value: USA_STATE_ENUM.NORTH_DAKOTA,
    abbr: 'ND'
  },
  {
    label: 'Ohio',
    value: USA_STATE_ENUM.OHIO,
    abbr: 'OH'
  },
  {
    label: 'Oklahoma',
    value: USA_STATE_ENUM.OKLAHOMA,
    abbr: 'OK'
  },
  {
    label: 'Oregon',
    value: USA_STATE_ENUM.OREGON,
    abbr: 'OR'
  },
  {
    label: 'Pennsylvania',
    value: USA_STATE_ENUM.PENNSYLVANIA,
    abbr: 'PA'
  },
  {
    label: 'Rhode_Island',
    value: USA_STATE_ENUM.RHODE_ISLAND,
    abbr: 'RI'
  },
  {
    label: 'South_Carolina',
    value: USA_STATE_ENUM.SOUTH_CAROLINA,
    abbr: 'SC'
  },
  {
    label: 'South_Dakota',
    value: USA_STATE_ENUM.SOUTH_DAKOTA,
    abbr: 'SD'
  },
  {
    label: 'Tennessee',
    value: USA_STATE_ENUM.TENNESSEE,
    abbr: 'TN'
  },
  {
    label: 'Texas',
    value: USA_STATE_ENUM.TEXAS,
    abbr: 'TX'
  },
  {
    label: 'Utah',
    value: USA_STATE_ENUM.UTAH,
    abbr: 'UT'
  },
  {
    label: 'Vermont',
    value: USA_STATE_ENUM.VERMONT,
    abbr: 'VT'
  },
  {
    label: 'Virginia',
    value: USA_STATE_ENUM.VIRGINIA,
    abbr: 'VA'
  },
  {
    label: 'Washington',
    value: USA_STATE_ENUM.WASHINGTON,
    abbr: 'WA'
  },
  {
    label: 'West_Virginia',
    value: USA_STATE_ENUM.WEST_VIRGINIA,
    abbr: 'WV'
  },
  {
    label: 'Wisconsin',
    value: USA_STATE_ENUM.WISCONSIN,
    abbr: 'WI'
  },
  {
    label: 'Wyoming',
    value: USA_STATE_ENUM.WYOMING,
    abbr: 'WY'
  }
];

const LOCATION_LIST_OPTIONS = [
  ...CANADA_PROVINCE_LIST_OPTIONS,
  ...USA_STATE_LIST_OPTIONS
];

export {
  COUNTRIES_LIST,
  ADDITIONAL_SHIPPING_REGION_LIST,
  SHIPPING_REGION_LIST,
  CANADA_PROVINCE_LIST,
  CANADA_PROVINCE_LIST_OPTIONS,
  USA_STATE_LIST,
  USA_STATE_LIST_OPTIONS,
  LOCATION_LIST_OPTIONS
};
