/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Button, TextInput } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import WsAccountAuthSection from './WsAccountAuthSection';
import { PASSWORD_CONSTRAINTS } from '../../../../../config/constants';
import { triggerNotification } from '../../../../../helpers/notification';

const WsAccountResetPassword = ({
  baseStoreUrl,
  blocks,
  config,
  themeConfig,
  ecomStoreTheme,
  colorSchemes,
  mediaQueries,
  sort,
  onAuthNavigation,
  pathname,
  ...rest
}) => {
  const { state, resetPassword, signIn } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    code: '',
    newPassword: '',
    confirmPassword: '',
    error: ''
  });
  const { isLargeMobileOrSmaller } = mediaQueries;

  useEffect(() => {
    if (state.email) {
      setFormState({
        code: '',
        newPassword: '',
        confirmPassword: '',
        error: ''
      });
    }
    else {
      onAuthNavigation('/account/forgot-password');
    }
  }, []);

  const onSubmit = () => {
    if (formState.newPassword !== formState.confirmPassword) {
      setFormState({
        ...formState,
        error: 'Passwords must match.'
      });
    }
    else if (formState.confirmPassword.length < PASSWORD_CONSTRAINTS.length) {
      setFormState({
        ...formState,
        error: `Password must be at least ${PASSWORD_CONSTRAINTS.length} characters long`
      });
    }
    else {
      setFormState({
        ...formState,
        loading: true
      });
      resetPassword(
        {
          email: state.email,
          code: formState.code,
          newPassword: formState.newPassword
        },
        () => {
          triggerNotification('Password was successfully reset!', 'success');
          signIn(
            { email: state.email, password: formState.newPassword },
            null,
            () => onAuthNavigation('/account/login')
          );
        },
        (error) =>
          setFormState({
            ...formState,
            loading: false,
            error
          })
      );
    }
  };

  return (
    <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
      <WsAccountAuthSection
        baseStoreUrl={baseStoreUrl}
        ecomStoreTheme={ecomStoreTheme}
        error={formState.error}
        mediaQueries={mediaQueries}
        onNavigate={onAuthNavigation}
        onSubmit={onSubmit}
        title="Create new password"
      >
        <AppStack
          style={{
            gap: 10,
            flex: 1,
            padding: isLargeMobileOrSmaller
              ? '10px 20px 20px 20px'
              : '10px 40px 40px 40px'
          }}
        >
          <AppStack style={{ gap: 0 }}>
            <AppText style={{ fontSize: 14 }}>
              We've sent a One Time Password (OTP) to the following email;
            </AppText>
          </AppStack>
          <AppCard
            radius={8}
            shadow="none"
            style={{ padding: 16, backgroundColor: 'rgba(245, 245, 245, 1)' }}
            withBorder
          >
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 14 }}>{state.email}</AppText>
              <Anchor
                onClick={() => onAuthNavigation('/account/forgot-password')}
                style={{ fontSize: 14, color: 'dodgerblue' }}
              >
                Change
              </Anchor>
            </AppFlexbox>
          </AppCard>

          <TextInput
            disabled={formState.loading}
            label="Enter OTP"
            onChange={(e) =>
              setFormState({
                ...formState,
                code: e.target.value
              })
            }
            required
            value={formState.code}
          />

          <AppStack style={{ gap: 5 }}>
            <TextInput
              disabled={formState.loading}
              label="New Password"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  newPassword: e.target.value
                })
              }
              required
              type="password"
              value={formState.newPassword}
            />
            <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
              <InfoCircle color="dodgerblue" size={16} />
              <AppText
                style={{ fontSize: 11, color: '#2b2b2b', lineHeight: '12px' }}
              >
                Passwords must consist of at least 8 characters.
              </AppText>
            </AppFlexbox>
          </AppStack>

          <TextInput
            disabled={formState.loading}
            label="Confirm Password"
            onChange={(e) =>
              setFormState({
                ...formState,
                confirmPassword: e.target.value
              })
            }
            required
            type="password"
            value={formState.confirmPassword}
          />
          <Button
            color="dark"
            loading={formState.loading}
            radius={10}
            size="sm"
            style={{ fontSize: 13, margin: '5px 0px' }}
            type="submit"
          >
            Save changes and sign in
          </Button>
          <AppText style={{ fontSize: 14, textAlign: 'center', color: '#666' }}>
            We'll ask for this password whenever you sign in.
          </AppText>
          <AppText style={{ fontSize: 14, marginTop: 10 }}>
            Remember your password?{' '}
            <Anchor
              onClick={() => onAuthNavigation('/account/login')}
              style={{ fontSize: 14, color: 'dodgerblue' }}
            >
              Sign in
            </Anchor>
          </AppText>
        </AppStack>
      </WsAccountAuthSection>
    </AppFlexbox>
  );
};

WsAccountResetPassword.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onAuthNavigation: PropTypes.func,
  pathname: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsAccountResetPassword;
