import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';
import { Badge, Skeleton, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import {
  useBackPath,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import OrderMerchantInfoCard from './OrderMerchantInfoCard';
import { ECOM_CART_PRODUCT_STATUS_ENUM } from '../../../config/constants';
import {
  calculateVendorOrderProductAddonCost,
  translateOrderProductData
} from '../../../helpers/vendorHelper';
import VendorOrderShippingNotifyModal from './VendorOrderShippingNotifyModal';
import OrderProductsInfoCard from './OrderProductsInfoCard';
import OrderPaymentInfoCard from './OrderPaymentInfoCard';
import OrderTrackingInfoCard from './OrderTrackingInfoCard';
import EditShippingDetailsModal from './EditShippingDetailsModal';

const VendorOrderDetailsView = ({ ecomVendorOrder, loading }) => {
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { backPath } = useBackPath({ baseUrl: '/vendor' });
  const productData =
    ecomVendorOrder?.ecomStoreCartProducts.map((p) =>
      translateOrderProductData(p)
    ) ?? [];

  const isFulfilled = ecomVendorOrder?.ecomStoreCartProducts.every(
    (p) =>
      p.fkRegFormSubmissionStatus.toString() ===
      ECOM_CART_PRODUCT_STATUS_ENUM.ACCEPTED
  );
  const isFullyPaid = ecomVendorOrder?.ecomStoreCartProducts.every(
    (p) =>
      p.ecomVendorPayoutProducs
        .filter((f) => !f.isRefund)
        .reduce((r, c) => r + c.amount, 0) >=
      (p.ecomVendorProductVariant.price +
        calculateVendorOrderProductAddonCost(p)) *
        p.count
  );

  const ecomStoreCartShippingProducts =
    ecomVendorOrder?.ecomStoreCartShipping.reduce(
      (r, c) => [...r, ...c.ecomStoreCartShippingProducts],
      []
    ) ?? [];
  const haveAllProductsBeenShipped = ecomVendorOrder?.ecomStoreCartProducts.every(
    (p) =>
      p.count -
        ecomStoreCartShippingProducts
          .filter((f) => f.fkEcomStoreCartProduct === p.pkEcomStoreCartProduct)
          .reduce((r2, c2) => r2 + c2.count, 0) <=
      0
  );

  const productCost =
    ecomVendorOrder?.ecomStoreCartProducts.reduce(
      (r, c) =>
        r +
        (c.ecomVendorProductVariant.price +
          calculateVendorOrderProductAddonCost(c)) *
          c.count,
      0
    ) ?? 0;

  const shippedProductCount = ecomStoreCartShippingProducts.reduce(
    (r, c) => r + c.count,
    0
  );
  const totalUnitCount =
    productData?.reduce((acc, p) => acc + p.quantity, 0) ?? 0;

  const allShippingNotified = ecomVendorOrder?.ecomStoreCartShipping.every(
    (s) => s.emailSent
  );

  return !loading && ecomVendorOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <VendorOrderShippingNotifyModal
        ecomVendorOrder={ecomVendorOrder}
        isOpen={modalState.isOpen && modalState.action === 'notify'}
        onClose={onCloseModal}
        pkEcomStoreCartShipping={
          modalState.action === 'notify'
            ? modalState.item?.pkEcomStoreCartShipping
            : null
        }
      />

      <EditShippingDetailsModal
        ecomStoreCartShipping={
          modalState.action === 'edit-shipping' ? modalState.item : null
        }
        isOpen={modalState.isOpen && modalState.action === 'edit-shipping'}
        onClose={onCloseModal}
        pkEcomStoreCart={ecomVendorOrder.pkEcomStoreCart}
      />

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={backPath}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppFlexbox>
                <AppText
                  style={{
                    flex: 1,
                    fontSize: 24,
                    fontWeight: 700,
                    wordBreak: 'break-word'
                  }}
                >
                  Order #{ecomVendorOrder.pkEcomStoreCart}
                </AppText>
              </AppFlexbox>

              {!isFullyPaid ? (
                <Badge color="dark" variant="light">
                  Unpaid
                </Badge>
              ) : (
                <Badge color="blue" variant="filled">
                  Paid
                </Badge>
              )}

              {shippedProductCount < totalUnitCount ? (
                <Badge color="dark" variant="light">
                  Unfulfilled
                </Badge>
              ) : (
                <Badge color="green" variant="filled">
                  Fulfilled
                </Badge>
              )}
              {ecomVendorOrder.ecomStoreCartShipping.length > 0 &&
                (allShippingNotified ? (
                  <Badge color="blue" variant="filled">
                    Customer Notified
                  </Badge>
                ) : (
                  <Tooltip label="Customer has not been notified about all shipments.">
                    <Badge color="red" variant="filled">
                      Action Required
                    </Badge>
                  </Tooltip>
                ))}
            </AppFlexbox>
            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Placed{' '}
              {dayjs(formatUtcDate(ecomVendorOrder.purchaseDate)).format(
                'MMMM D, YYYY'
              )}{' '}
              at{' '}
              {dayjs(formatUtcDate(ecomVendorOrder.purchaseDate)).format(
                'h:mm A'
              )}{' '}
              from {ecomVendorOrder.ecomStore.name}
            </AppText>
          </AppStack>
        </AppFlexbox>

        {/* <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          <AppMenu
            control={
              <Button
                color="dark"
                rightSection={<ChevronDown size={18} />}
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            <Menu.Item onClick={() => {}}>Archive order</Menu.Item>
            <Menu.Item onClick={() => {}}>Cancel order</Menu.Item>
            <Menu.Item onClick={() => {}} style={{ color: 'red' }}>
              Refund order
            </Menu.Item>
          </AppMenu>
        </AppFlexbox> */}
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard
            fulfillLink={
              !haveAllProductsBeenShipped
                ? `/vendor/orders/${ecomVendorOrder.pkEcomStoreCart}/fulfill`
                : null
            }
            isFulfilled={isFulfilled}
            onNotify={
              shippedProductCount > 0 && !allShippingNotified
                ? () => onOpenModal('notify')
                : null
            }
            productData={productData}
            shippedProductCount={shippedProductCount}
          />

          <OrderPaymentInfoCard
            payments={[
              {
                group: 1,
                label: 'Subtotal',
                color: '#666',
                description: singularPluralFormat(
                  ecomVendorOrder.ecomStoreCartProducts.reduce(
                    (acc, item) => acc + item.count,
                    0
                  ),
                  'item',
                  'items'
                ),
                value: productCost / 100
              },
              {
                group: 2,
                label: 'Total',
                weight: 500,
                description: '',
                value: productCost / 100
              }
            ]}
          />

          <OrderTrackingInfoCard
            ecomStoreCartShipping={ecomVendorOrder.ecomStoreCartShipping}
            loading={loading}
            onNotify={(shipping) => onOpenModal('notify', shipping)}
            onUpdateShipping={(shipping) =>
              onOpenModal('edit-shipping', shipping)
            }
            productData={productData}
          />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard
            addresses={ecomVendorOrder.addresses}
            customerEmail={ecomVendorOrder.customer.email}
            customerName={ecomVendorOrder.customer.name}
            customerUrl={
              ecomVendorOrder.customer.pkUser
                ? `/vendor/customers/${ecomVendorOrder.customer.pkUser}?from=orders&fromId=${ecomVendorOrder.pkEcomStoreCart}`
                : null
            }
          />

          <OrderMerchantInfoCard ecomStore={ecomVendorOrder.ecomStore} />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={backPath}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard loading />

          <OrderPaymentInfoCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
          <OrderMerchantInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

VendorOrderDetailsView.propTypes = {
  ecomVendorOrder: PropTypes.object,
  loading: PropTypes.bool
};

export default VendorOrderDetailsView;
