import dayjs from 'dayjs';
import {
  ECOM_PRODUCT_STATUS_LIST,
  WEIGHT_UNIT_LIST
} from '../../../config/constants';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import { fetchMediaContent } from '../../../helpers/awsHelper';
import { currencyFormat } from '../../../helpers/format';

const PRODUCT_DATA_FIELDS = {
  name: 'name',
  urlHandle: 'urlHandle',
  description: 'description',
  fkEcomProductStatus: 'fkEcomProductStatus',
  scheduleDate: 'scheduleDate',
  scheduleEndDate: 'scheduleEndDate',
  fkEcomCategory: 'fkEcomCategory',
  productType: 'productType',
  ecomVendorCollections: 'ecomVendorCollections',
  minimumQuantity: 'minimumQuantity'
};

const VARIANT__DATA_FIELDS = {
  price: 'price',
  compareAtPrice: 'compareAtPrice',
  costPerItem: 'costPerItem',
  trackQuantity: 'trackQuantity',
  continueSellingWhenOutOfStock: 'continueSellingWhenOutOfStock',
  quantity: 'quantity',
  title: 'title',
  sku: 'sku',
  barcode: 'barcode',
  requiresShipping: 'requiresShipping',
  weight: 'weight',
  weightUnit: 'weightUnit',
  originCountry: 'originCountry',
  originProvince: 'originProvince',
  harmonizedSystemCode: 'harmonizedSystemCode',
  imageUrl: 'imageUrl',
  imagePosition: 'imagePosition'
};

const EXPORT_PRODUCT_FIELDS = [
  {
    label: 'URL Handle',
    sort: 1,
    inventoryField: true,
    selectDisabled: true,
    exportColumns: [
      {
        label: 'URL Handle',
        dataValue: PRODUCT_DATA_FIELDS.urlHandle
      }
    ],
    getExportValue: (product) => ({ urlHandle: product.urlHandle })
  },
  {
    label: 'Title',
    sort: 2,
    inventoryField: true,
    selectDisabled: true,
    required: true,
    exportColumns: [
      {
        label: 'Title',
        dataValue: PRODUCT_DATA_FIELDS.name,
        required: true
      }
    ],
    getExportValue: (product, variant, index) => ({name: index === 0 ? product.name : ''})
  },
  {
    label: 'Description',
    sort: 3,
    exportColumns: [
      {
        label: 'Description',
        dataValue: PRODUCT_DATA_FIELDS.description
      }
    ],
    getExportValue: (product, variant, index) => ({description: index === 0 ? product.description : ''})
  },
  {
    label: 'Category',
    sort: 4,
    exportColumns: [
      {
        label: 'Category',
        dataValue: PRODUCT_DATA_FIELDS.fkEcomCategory
      }
    ],
    getExportValue: (product, variant, index) => ({
      fkEcomCategory:
        index === 0 && product.fkEcomCategory
          ? PRODUCT_CATEGORY_LIST.find(
              (c) => c.value.toString() === product.fkEcomCategory.toString()
            )?.name ?? ''
          : ''
    })
  },
  {
    label: 'Sub category',
    sort: 5,
    exportColumns: [
      {
        label: 'Sub category',
        dataValue: PRODUCT_DATA_FIELDS.productType
      }
    ],
    getExportValue: (product, variant, index) => ({productType: index === 0 ? product.productType : ''})
  },
  {
    label: 'Status',
    sort: 6,
    exportColumns: [
      {
        label: 'Status',
        dataValue: PRODUCT_DATA_FIELDS.fkEcomProductStatus
      }
    ],
    getExportValue: (product, variant, index) => ({
      fkEcomProductStatus:
        index === 0 && product.fkEcomProductStatus
          ? ECOM_PRODUCT_STATUS_LIST.find(
              (s) =>
                s.value.toString() === product.fkEcomProductStatus.toString()
            )?.label.toUpperCase() ?? ''
          : ''
    })
  },
  {
    label: 'Schedule Date',
    sort: 7,
    exportColumns: [
      {
        label: 'Schedule Date',
        dataValue: PRODUCT_DATA_FIELDS.scheduleDate
      },
      {
        label: 'Schedule End Date',
        dataValue: PRODUCT_DATA_FIELDS.scheduleEndDate
      }
    ],
    getExportValue: (product, variant, index) => ({
      scheduleDate:
        index === 0 && product.scheduleDate
          ? dayjs(product.scheduleDate).format('MMMM D, YYYY h:mm A')
          : '',
      scheduleEndDate:
        index === 0 && product.scheduleEndDate
          ? dayjs(product.scheduleEndDate).format('MMMM D, YYYY h:mm A')
          : ''
    })
  },
  {
    label: 'Minimum Batch Order Quantity',
    sort: 8,
    exportColumns: [
      {
        label: 'Minimum Batch Order Quantity',
        dataValue: PRODUCT_DATA_FIELDS.minimumQuantity
      }
    ],
    getExportValue: (product, variant, index) => ({minimumQuantity: index === 0 ? product.minimumQuantity : ''})
  }
];

const EXPORT_VARIANT_FIELDS = [
  {
    label: 'Options',
    sort: 9,
    inventoryField: true,
    selectDisabled: true,
    exportColumns: [
      {
        label: 'Option1 Name',
        dataValue: 'option1Name'
      },
      {
        label: 'Option1 Value',
        dataValue: 'option1Value'
      },
      {
        label: 'Option2 Name',
        dataValue: 'option2Name'
      },
      {
        label: 'Option2 Value',
        dataValue: 'option2Value'
      },
      {
        label: 'Option3 Name',
        dataValue: 'option3Name'
      },
      {
        label: 'Option3 Value',
        dataValue: 'option3Value'
      }
    ],
    getExportValue: (product, variant, index) => {
      const variantOptions = variant.ecomVendorProductVariantOptions.sort(
        (a, b) => a.nameSort - b.nameSort
      );
      return {
        option1Name: index === 0 ? variantOptions[0]?.name ?? '' : '',
        option1Value: variantOptions[0]?.value ?? '',
        option2Name: index === 0 ? variantOptions[1]?.name ?? '' : '',
        option2Value: variantOptions[1]?.value ?? '',
        option3Name: index === 0 ? variantOptions[2]?.name ?? '' : '',
        option3Value: variantOptions[2]?.value ?? ''
      };
    }
  },
  {
    label: 'SKU',
    sort: 10,
    inventoryField: true,
    exportColumns: [
      {
        label: 'SKU',
        dataValue: VARIANT__DATA_FIELDS.sku
      }
    ],
    getExportValue: (product, variant) => ({ sku: variant.sku })
  },
  {
    label: 'Barcode',
    sort: 11,
    exportColumns: [
      {
        label: 'Barcode',
        dataValue: VARIANT__DATA_FIELDS.barcode
      }
    ],
    getExportValue: (product, variant) => ({ barcode: variant.barcode })
  },
  {
    label: 'Inventory Qty',
    sort: 12,
    inventoryField: true,
    exportColumns: [
      {
        label: 'Inventory Qty',
        dataValue: VARIANT__DATA_FIELDS.quantity
      }
    ],
    getExportValue: (product, variant) => ({ quantity: variant.quantity })
  },
  {
    label: 'Sell When Out Of Stock',
    sort: 13,
    inventoryField: true,
    exportColumns: [
      {
        label: 'Sell When Out Of Stock',
        dataValue: VARIANT__DATA_FIELDS.continueSellingWhenOutOfStock
      }
    ],
    getExportValue: (product, variant) => ({
      continueSellingWhenOutOfStock: variant.continueSellingWhenOutOfStock
        ? 'TRUE'
        : 'FALSE'
    })
  },
  {
    label: 'Price',
    sort: 14,
    exportColumns: [
      {
        label: 'Price',
        dataValue: VARIANT__DATA_FIELDS.price
      }
    ],
    getExportValue: (product, variant) => ({ price: variant.price / 100 })
  },
  {
    label: 'Compare At Price',
    sort: 15,
    exportColumns: [
      {
        label: 'Compare At Price',
        dataValue: VARIANT__DATA_FIELDS.compareAtPrice
      }
    ],
    getExportValue: (product, variant) => ({
      compareAtPrice: variant.compareAtPrice
        ? variant.compareAtPrice / 100
        : null
    })
  },
  {
    label: 'Cost Per Item',
    sort: 16,
    exportColumns: [
      {
        label: 'Cost Per Item',
        dataValue: VARIANT__DATA_FIELDS.costPerItem
      }
    ],
    getExportValue: (product, variant) => ({costPerItem: variant.costPerItem ? variant.costPerItem / 100 : null})
  },
  {
    label: 'Weight',
    sort: 17,
    exportColumns: [
      {
        label: 'Weight',
        dataValue: VARIANT__DATA_FIELDS.weight
      },
      {
        label: 'Weight Unit',
        dataValue: VARIANT__DATA_FIELDS.weightUnit
      }
    ],
    getExportValue: (product, variant) => ({
      weight: variant.weight,
      weightUnit: variant.weightUnit
        ? WEIGHT_UNIT_LIST.find(
            (f) => f.value === variant.weightUnit.toString()
          )?.label ?? ''
        : ''
    })
  },
  {
    label: 'Customs Information',
    sort: 18,
    exportColumns: [
      {
        label: 'Origin Country',
        dataValue: VARIANT__DATA_FIELDS.originCountry
      },
      {
        label: 'Origin Province',
        dataValue: VARIANT__DATA_FIELDS.originProvince
      },
      {
        label: 'Harmonized System Code',
        dataValue: VARIANT__DATA_FIELDS.harmonizedSystemCode
      }
    ],
    getExportValue: (product, variant) => ({
      originCountry: variant.originCountry,
      originProvince: variant.originProvince,
      harmonizedSystemCode: variant.harmonizedSystemCode
    })
  },
  {
    label: 'Image',
    sort: 19,
    exportColumns: [
      {
        label: 'Image URL',
        dataValue: VARIANT__DATA_FIELDS.imageUrl
      },
      {
        label: 'Image Position',
        dataValue: VARIANT__DATA_FIELDS.imagePosition
      }
    ],
    getExportValue: (product, variant) => {
      const productImage = variant.fkEcomVendorProductMedia
        ? product.ecomVendorProductMedia.find(
            (m) =>
              m.pkEcomVendorProductMedia === variant.fkEcomVendorProductMedia
          )
        : null;

      return {
        src: productImage?.src ?? '',
        imagePosition: productImage?.sort ?? ''
      };
    }
  }
];

const PRODUCT_FIELD_SECTION_ENUM = {
  PRODUCT: 'product',
  VARIANT: 'variant'
};

const vendorProductCsvConfig = {
  identifier: {
    name: 'URL handle',
    namePlural: 'URL handles',
    value: 'urlHandle'
  },
  fieldSections: [
    {
      name: 'Product',
      value: PRODUCT_FIELD_SECTION_ENUM.PRODUCT,
      fields: EXPORT_PRODUCT_FIELDS
    },
    {
      name: 'Variant',
      value: PRODUCT_FIELD_SECTION_ENUM.VARIANT,
      fields: EXPORT_VARIANT_FIELDS,
      canExclude: true
    }
  ],
  sampleData: [
    [
      'example-shirt',
      'Example Shirt',
      'This is an example shirt.',
      'Clothing',
      'Shirts',
      'DRAFT',
      '',
      '',
      '10',
      'Size',
      'Small',
      '',
      '',
      '',
      '',
      'example-shirt-small',
      '123456789',
      '',
      '',
      '19.99',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    [
      'example-shirt',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Medium',
      '',
      '',
      '',
      '',
      'example-shirt-medium',
      '123456789',
      '',
      '',
      '19.99',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    [
      'example-shirt',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Large',
      '',
      '',
      '',
      '',
      'example-shirt-large',
      '123456789',
      '',
      '',
      '19.99',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    [
      'example-hat',
      'Example Hat',
      'This is an example hat.',
      'Clothing',
      'Hats',
      'DRAFT',
      '',
      '',
      '',
      'Size',
      'Small',
      '',
      '',
      '',
      '',
      'example-hat',
      '123456789',
      '',
      '',
      '9.99',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ]
  ],
  getImportData: (data, overrideRequired = false) => {
    const variants = data.map((p) => p.ecomVendorProductVariants).flat();
    const existingItems = data.filter((d) => d.uuid);
    const existingSubitems = variants.filter((v) => v.uuid).flat();

    return {
      itemsLabel: 'product',
      itemsLabelPlural: 'products',
      items: data,
      existingItems,
      newItems: data.filter((d) => !d.uuid),
      subitemsLabel: 'variant',
      subitemsLabelPlural: 'variants',
      subitems: variants,
      existingSubitems,
      newSubitems: variants.filter((v) => !v.uuid)
    };
  },
  getPreviewData: (data, headers) => ({
    isNewEntry: !data.uuid,
    columns: [
      {
        label: 'Title',
        dataValue: PRODUCT_DATA_FIELDS.name,
        value: data.name
      },
      {
        label: 'URL Handle',
        dataValue: PRODUCT_DATA_FIELDS.urlHandle,
        value: data.urlHandle
      },
      {
        label: 'Description',
        dataValue: PRODUCT_DATA_FIELDS.description,
        value: data.description
      },
      {
        label: 'Category',
        dataValue: PRODUCT_DATA_FIELDS.fkEcomCategory,
        value: data.fkEcomCategory
          ? PRODUCT_CATEGORY_LIST.find(
              (f) => f.value === data.fkEcomCategory.toString()
            )?.name ?? ''
          : ''
      },
      {
        label: 'Sub category',
        dataValue: PRODUCT_DATA_FIELDS.productType,
        value: data.productType
      },
      {
        label: 'Status',
        dataValue: PRODUCT_DATA_FIELDS.fkEcomProductStatus,
        value: data.fkEcomProductStatus
          ? ECOM_PRODUCT_STATUS_LIST.find(
              (f) => f.value === data.fkEcomProductStatus.toString()
            )?.label.toUpperCase() ?? ''
          : ''
      },
      {
        label: 'Start date',
        dataValue: PRODUCT_DATA_FIELDS.scheduleDate,
        value: data.scheduleDate
          ? dayjs(data.scheduleDate).format('MMMM D, YYYY h:mm A')
          : null
      },
      {
        label: 'End date',
        dataValue: PRODUCT_DATA_FIELDS.scheduleEndDate,
        value: data.scheduleEndDate
          ? dayjs(data.scheduleEndDate).format('MMMM D, YYYY h:mm A')
          : null
      },
      {
        label: 'Minimum quantity',
        dataValue: PRODUCT_DATA_FIELDS.minimumQuantity,
        value: data.minimumQuantity
      }
    ].filter((f) => headers.some((h) => h.dataValue === f.dataValue)),
    subData: data.ecomVendorProductVariants.map((variant) => ({
      columns: [
        {
          label: 'Variant',
          dataValue: VARIANT__DATA_FIELDS.title,
          value: variant.title
        },
        {
          label: 'SKU',
          dataValue: VARIANT__DATA_FIELDS.sku,
          value: variant.sku
        },
        {
          label: 'Barcode',
          dataValue: VARIANT__DATA_FIELDS.barcode,
          value: variant.barcode
        },
        {
          label: 'Price',
          dataValue: VARIANT__DATA_FIELDS.price,
          value: variant.price ? currencyFormat(variant.price / 100) : 0
        },
        {
          label: 'Compare At Price',
          dataValue: VARIANT__DATA_FIELDS.compareAtPrice,
          value: variant.compareAtPrice
            ? currencyFormat(variant.compareAtPrice / 100)
            : 0
        },
        {
          label: 'Cost Per Item',
          dataValue: VARIANT__DATA_FIELDS.costPerItem,
          value: variant.costPerItem
            ? currencyFormat(variant.costPerItem / 100)
            : 0
        },
        {
          label: 'Inventory Qty',
          dataValue: VARIANT__DATA_FIELDS.quantity,
          value: variant.quantity
        },
        {
          label: 'Sell When Out Of Stock',
          dataValue: VARIANT__DATA_FIELDS.continueSellingWhenOutOfStock,
          value: variant.continueSellingWhenOutOfStock ? 'TRUE' : 'FALSE'
        },
        {
          label: 'Weight',
          dataValue: VARIANT__DATA_FIELDS.weight,
          value: variant.weight
        },
        {
          label: 'Weight Unit',
          dataValue: VARIANT__DATA_FIELDS.weightUnit,
          value: variant.weightUnit
            ? WEIGHT_UNIT_LIST.find(
                (f) => f.value === variant.weightUnit.toString()
              )?.label ?? ''
            : ''
        },
        {
          label: 'Origin Country',
          dataValue: VARIANT__DATA_FIELDS.originCountry,
          value: variant.originCountry
        },
        {
          label: 'Origin Province',
          dataValue: VARIANT__DATA_FIELDS.originProvince,
          value: variant.originProvince
        },
        {
          label: 'Harmonized System Code',
          dataValue: VARIANT__DATA_FIELDS.harmonizedSystemCode,
          value: variant.harmonizedSystemCode
        }
      ].filter((f) =>
        headers.some(
          (h) => h.dataValue === f.dataValue || f.dataValue === 'title'
        )
      )
    }))
  }),
  parseCsvData: async (csvData) => {
    const productData = csvData.reduce((r, c) => {
      const existingProduct = c.urlHandle
        ? r.find((p) => p.urlHandle === c.urlHandle?.trim())
        : null;
      if (!existingProduct) {
        const variantOptions = [
          {
            name:
              c.option1Name ||
              csvData.find(
                (d) => d.urlHandle === c.urlHandle && !!d.option1Name
              )?.option1Name,
            value: c.option1Value
          },
          {
            name:
              c.option2Name ||
              csvData.find(
                (d) => d.urlHandle === c.urlHandle && !!d.option2Name
              )?.option2Name,
            value: c.option2Value
          },
          {
            name:
              c.option3Name ||
              csvData.find(
                (d) => d.urlHandle === c.urlHandle && !!d.option3Name
              )?.option3Name,
            value: c.option3Value
          }
        ].filter((o) => o.name && o.value);

        r.push({
          foundExisting: c.foundExisting,
          ecomVendorProduct: c.ecomVendorProduct,
          urlHandle: c.urlHandle?.trim(),
          name: c.name,
          description: c.description,
          fkEcomCategory: c.fkEcomCategory
            ? PRODUCT_CATEGORY_LIST.find(
                (f) =>
                  f.name.replace(' ', '').toLowerCase() ===
                  c.fkEcomCategory.replace(' ', '').toLowerCase()
              )?.value ?? null
            : null,
          productType: c.productType,
          fkEcomProductStatus: c.fkEcomProductStatus
            ? ECOM_PRODUCT_STATUS_LIST.find(
                (f) =>
                  f.label.replace(' ', '').toLowerCase() ===
                  c.fkEcomProductStatus.replace(' ', '').toLowerCase()
              )?.value ?? null
            : null,
          scheduleDate: c.scheduleDate ? dayjs(c.scheduleDate) ?? null : null,
          scheduleEndDate: c.scheduleEndDate
            ? dayjs(c.scheduleEndDate) ?? null
            : null,
          minimumQuantity: c.minimumQuantity
            ? parseInt(c.minimumQuantity, 10)
            : null,
          ecomVendorProductMedia: c.imageUrl
            ? [
                {
                  src: c.src,
                  sort: c.imagePosition
                }
              ]
            : [],
          ecomVendorProductVariants: [
            {
              ecomVendorProductVariantOptions: variantOptions,
              title: variantOptions.map((o) => o.value).join(' / '),
              sku: c.sku,
              barcode: c.barcode,
              quantity: c.quantity ? parseInt(c.quantity, 10) : null,
              continueSellingWhenOutOfStock: c.continueSellingWhenOutOfStock
                ? c.continueSellingWhenOutOfStock.toLowerCase().trim() ===
                  'true'
                : false,
              price: c.price ? Math.round(c.price * 100) : 0,
              compareAtPrice: c.compareAtPrice
                ? Math.round(c.compareAtPrice * 100)
                : null,
              costPerItem: c.costPerItem ? Math.round(c.price * 100) : null,
              weight: c.weight ? parseFloat(c.weight) : null,
              weightUnit: c.weightUnit
                ? WEIGHT_UNIT_LIST.find(
                    (w) =>
                      w.label.toLowerCase() ===
                      c.weightUnit.replace(' ', '').toLowerCase()
                  )?.value ?? null
                : null,
              originCountry: c.originCountry,
              originProvince: c.originProvince,
              harmonizedSystemCode: c.harmonizedSystemCode,
              src: c.src
            }
          ]
        });
      }
      else {
        const variantOptions = [
          {
            name:
              existingProduct.ecomVendorProductVariants[0]
                .ecomVendorProductVariantOptions[0]?.name,
            value: c.option1Value
          },
          {
            name:
              existingProduct.ecomVendorProductVariants[0]
                .ecomVendorProductVariantOptions[1]?.name,
            value: c.option2Value
          },
          {
            name:
              existingProduct.ecomVendorProductVariants[0]
                .ecomVendorProductVariantOptions[2]?.name,
            value: c.option3Value
          }
        ].filter((o) => o.name && o.value);

        const existingVariant = existingProduct.ecomVendorProductVariants.find(
          (v) =>
            v.ecomVendorProductVariantOptions.every((o) =>
              variantOptions.some(
                (vo) => vo.name === o.name && vo.value === o.value
              )
            )
        );
        if (!existingVariant) {
          existingProduct.ecomVendorProductVariants.push({
            ecomVendorProductVariantOptions: variantOptions,
            title: variantOptions.map((o) => o.value).join(' / '),
            sku: c.sku,
            barcode: c.barcode,
            quantity: c.quantity ? parseInt(c.quantity, 10) : null,
            continueSellingWhenOutOfStock: c.continueSellingWhenOutOfStock
              ? c.continueSellingWhenOutOfStock.toLowerCase().trim() === 'true'
              : false,
            price: c.price ? Math.round(c.price * 100) : 0,
            compareAtPrice: c.compareAtPrice
              ? Math.round(c.compareAtPrice * 100)
              : null,
            costPerItem: c.costPerItem ? Math.round(c.price * 100) : null,
            weight: c.weight ? parseFloat(c.weight) : null,
            weightUnit: c.weightUnit
              ? WEIGHT_UNIT_LIST.find(
                  (w) =>
                    w.label.toLowerCase() ===
                    c.weightUnit.replace(' ', '').toLowerCase()
                )?.value ?? null
              : null,
            originCountry: c.originCountry,
            originProvince: c.originProvince,
            harmonizedSystemCode: c.harmonizedSystemCode,
            src: c.src
          });

          if (c.src) {
            const existingMedia = existingProduct.ecomVendorProductMedia.find(
              (m) => m.src === c.src
            );
            if (!existingMedia) {
              existingProduct.ecomVendorProductMedia.push({
                src: c.src,
                sort: c.imagePosition
              });
            }
          }
        }
      }
      return r;
    }, []);

    const urls = [
      ...new Set(
        productData.reduce((r, c) => {
          r.push(...c.ecomVendorProductMedia.map((m) => m.src));
          return r;
        }, [])
      )
    ];
    if (urls.length > 0) {
      const mediaResponse = await fetchMediaContent(urls);
      productData.forEach((product) => {
        product.ecomVendorProductMedia.forEach((media) => {
          const mediaInfo = mediaResponse.find((m) => m.url === media.src);
          // eslint-disable-next-line no-param-reassign
          media.contentType = mediaInfo?.contentType ?? '';
        });
      });
    }

    return productData;
  }
};

const vendorProductInventoryCsvConfig = {
  ...vendorProductCsvConfig,
  fieldSections: vendorProductCsvConfig.fieldSections.map((f) => ({
    ...f,
    fields: f.fields.filter((field) => field.inventoryField),
    canExclude: false
  })),
  sampleData: [
    [
      'example-shirt',
      'Example Shirt',
      'Size',
      'Small',
      '',
      '',
      '',
      '',
      'example-shirt-small',
      '1',
      'FALSE'
    ],
    [
      'example-shirt',
      'Example Shirt',
      'Size',
      'Medium',
      '',
      '',
      '',
      '',
      'example-shirt-medium',
      '2',
      'FALSE'
    ],
    [
      'example-shirt',
      'Example Shirt',
      'Size',
      'Large',
      '',
      '',
      '',
      '',
      'example-shirt-large',
      '3',
      'FALSE'
    ],
    [
      'example-hat',
      'Example Hat',
      'Size',
      'Small',
      '',
      '',
      '',
      '',
      'example-hat',
      '5',
      'TRUE'
    ]
  ],
  getPreviewData: (data, headers) => ({
    isNewEntry: !data.uuid,
    columns: [
      {
        label: 'Title',
        dataValue: PRODUCT_DATA_FIELDS.name,
        value: data.name
      },
      {
        label: 'URL Handle',
        dataValue: PRODUCT_DATA_FIELDS.urlHandle,
        value: data.urlHandle
      }
    ].filter((f) => headers.some((h) => h.dataValue === f.dataValue)),
    subData: data.ecomVendorProductVariants
      .filter((v) => v.uuid)
      .map((variant) => ({
        columns: [
          {
            label: 'Variant',
            dataValue: VARIANT__DATA_FIELDS.title,
            value: variant.title
          },
          {
            label: 'SKU',
            dataValue: VARIANT__DATA_FIELDS.sku,
            value: variant.sku
          },
          {
            label: 'Inventory Qty',
            dataValue: VARIANT__DATA_FIELDS.quantity,
            value: variant.quantity
          },
          {
            label: 'Sell When Out Of Stock',
            dataValue: VARIANT__DATA_FIELDS.continueSellingWhenOutOfStock,
            value: variant.continueSellingWhenOutOfStock ? 'TRUE' : 'FALSE'
          }
        ].filter((f) =>
          headers.some(
            (h) => h.dataValue === f.dataValue || f.dataValue === 'title'
          )
        )
      }))
  })
};

export {
  vendorProductCsvConfig,
  vendorProductInventoryCsvConfig,
  EXPORT_PRODUCT_FIELDS,
  EXPORT_VARIANT_FIELDS,
  PRODUCT_FIELD_SECTION_ENUM
};
