import React, { useContext, useEffect } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Button, Skeleton } from '@mantine/core';
import { AlertTriangle } from 'tabler-icons-react';
import sportsHeadzLogo from '../../../images/sportsheadz-logo.png';
import AppFlexbox from '../../common/AppFlexbox';
import AppImage from '../../common/AppImage';
import AppCard from '../../common/AppCard';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import {
  ADMIN_NAVIGATION_LINKS,
  BASE_NAVIGATION_LINKS,
  MERCHANT_NAVIGATION_LINKS,
  VENDOR_NAVIGATION_LINKS
} from './navigationLinks';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import Drawer from './Drawer';
import VendorCatalogHeader from '../vendorCatalog/VendorCatalogHeader';
import HeaderAuthNavigationMenu from './HeaderAuthNavigationMenu';

const Header = () => {
  const { state: authState } = useContext(AuthContext);
  const {
    state: helperState,
    triggerFormSubmit,
    triggerFormDiscard,
    resetForm
  } = useContext(HelperContext);
  const loading = !authState.tokenAttempted || authState.loading;
  const { pathname } = useLocation();
  const lightMode = false;
  const adminLinks = BASE_NAVIGATION_LINKS.admin.filter((a) =>
    a.hasAccess(authState)
  );

  const isVendorView = pathname.startsWith('/vendor');
  const isMerchantView = pathname.startsWith('/merchant');
  const isVendorCatalogView = pathname.startsWith(
    '/merchant/products/discover'
  );

  useEffect(() => {
    resetForm();
  }, [pathname]);

  return authState.isAuthenticated && isVendorCatalogView ? (
    <VendorCatalogHeader />
  ) : (
    <AppCard
      shadow="xs"
      style={{
        padding: 0,
        height: '100%',
        backgroundColor: lightMode ? '#FFF' : '#1D1D1D',
        borderColor: lightMode ? 'lightgrey' : '#000',
        borderRadius: 0,
        position: 'relative'
      }}
    >
      <AppFlexbox
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'space-between',
          placeItems: 'center',
          gap: 5
        }}
      >
        <AppFlexbox
          maw={{ base: 'unset', xsm: 240 }}
          pl={{ base: 10, xsm: 15 }}
          style={{
            flex: 1,
            gap: 10
          }}
          visibleFrom={helperState.form.hasUnsavedChanges ? 'sm' : null}
        >
          <AppFlexbox hiddenFrom="sm">
            <Routes>
              <Route
                element={
                  <Drawer
                    adminLinks={VENDOR_NAVIGATION_LINKS.main}
                    lightMode
                    loading={loading}
                  />
                }
                path="/vendor/*"
              />
              <Route
                element={
                  <Drawer
                    adminLinks={MERCHANT_NAVIGATION_LINKS.main}
                    extraSections={MERCHANT_NAVIGATION_LINKS.sections}
                    lightMode
                    loading={loading}
                  />
                }
                path="/merchant/*"
              />
              <Route
                element={
                  <Drawer
                    adminLinks={ADMIN_NAVIGATION_LINKS.main}
                    lightMode
                    loading={loading}
                  />
                }
                path="/admin/*"
              />
              <Route
                element={
                  <Drawer
                    {...(!loading && authState.isAuthenticated
                      ? {
                          adminLinks,
                          profileLinks: BASE_NAVIGATION_LINKS.profile
                        }
                      : {})}
                  />
                }
                path="*"
              />
            </Routes>
          </AppFlexbox>
          {loading ? (
            <AppFlexbox
              style={{
                alignItems: 'center',
                gap: 5
              }}
            >
              <Skeleton
                height={30}
                radius={100}
                style={{ opacity: 0.2 }}
                width={30}
              />
              <Skeleton
                h={{ base: 20, xsm: 24 }}
                style={{ opacity: 0.2 }}
                w={{ base: 95, xsm: 110 }}
              />
            </AppFlexbox>
          ) : (
            <AppFlexbox
              component={Link}
              style={{
                alignItems: 'center',
                textDecoration: 'none',
                color: lightMode ? '#000' : '#FFF',
                gap: 5
              }}
              to={isVendorView ? '/vendor' : isMerchantView ? '/merchant' : '/'}
            >
              <AppStack style={{ minWidth: 30 }}>
                <AppImage
                  alt="SportsHeadz Main Logo"
                  fit="contain"
                  height={30}
                  src={sportsHeadzLogo}
                  style={{ minWidth: 'unset', maxWidth: 125 }}
                  width="100%"
                />
              </AppStack>

              <AppText fz={{ base: 16, xsm: 18 }} style={{ fontWeight: 650 }}>
                SportsHeadz
              </AppText>
            </AppFlexbox>
          )}
        </AppFlexbox>

        {helperState.form.hasUnsavedChanges ? (
          <>
            <AppStack
              style={{
                flex: 1,
                margin: 'auto',
                width: '100%',
                maxWidth: helperState.form.containerWidth ?? 950,
                padding: 16,
                marginBottom: 70
              }}
            >
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppFlexbox
                  style={{ alignItems: 'center', gap: 5 }}
                  visibleFrom="xxs"
                >
                  <AppFlexbox
                    style={{
                      width: 28,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <AlertTriangle color="#dee2e6" />
                  </AppFlexbox>
                  <AppText
                    style={{
                      fontSize: 18,
                      fontWeight: 650,
                      color: '#dee2e6',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Unsaved changes
                  </AppText>
                </AppFlexbox>

                <AppFlexbox
                  style={{
                    width: '100%',
                    justifyContent: 'end',
                    gap: 10,
                    alignItems: 'center'
                  }}
                >
                  <Button
                    color="dark"
                    disabled={helperState.form.loading}
                    flex={{ base: 1, xxs: 'unset' }}
                    onClick={triggerFormDiscard}
                    radius="md"
                    size="compact-md"
                    style={{ fontSize: 14 }}
                    type="button"
                    variant="filled"
                  >
                    Discard
                  </Button>
                  <Button
                    color="dark"
                    flex={{ base: 1, xxs: 'unset' }}
                    loading={helperState.form.loading}
                    onClick={triggerFormSubmit}
                    radius="md"
                    size="compact-md"
                    style={{ fontSize: 14 }}
                    type="button"
                    variant="white"
                  >
                    Save
                  </Button>
                </AppFlexbox>
              </AppFlexbox>
            </AppStack>
            {helperState.setupGuide.showSidebar && (
              <AppStack style={{ width: 355 }} />
            )}
          </>
        ) : (
          <AppFlexbox
            flex={{ base: 'unset', xsm: 1 }}
            style={{
              justifyContent: 'flex-end',
              placeItems: 'center',
              gap: 10,
              paddingRight: 10
            }}
          >
            <HeaderAuthNavigationMenu />
          </AppFlexbox>
        )}
      </AppFlexbox>
    </AppCard>
  );
};

export default Header;
