/* eslint-disable import/no-cycle */
import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsFeaturedCollection from './WsFeaturedCollection';

const HEADING_SIZE_STYLE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  HUGE: 'huge'
};

const VIEW_ALL_STYLE_ENUM = {
  LINK: 'LINK',
  OUTLINE_BUTTON: 'OUTLINE_BUTTON',
  SOLID_BUTTON: 'SOLID_BUTTON'
};

const QUICK_ADD_STYLE_ENUM = {
  NONE: 'NONE',
  STANDARD: 'STANDARD',
  BULK: 'BULK'
};

const IMAGE_DISPLAY_ENUM = {
  PORTRAIT: 'PORTRAIT',
  SQUARE: 'SQUARE'
};

const configNameValues = {
  headingText: 'headingText',
  headingSize: 'headingSize',
  descriptionText: 'descriptionText',
  showCollectionDescription: 'showCollectionDescription',
  ecomStoreCollection: 'ecomStoreCollection',
  productsFullWidth: 'productsFullWidth',
  maximumProductsDisplayed: 'maximumProductsDisplayed',
  desktopColumnCount: 'desktopColumnCount',
  showSecondImageOnHover: 'showSecondImageOnHover',
  showVendorName: 'showVendorName',
  showVariantsSeparately: 'showVariantsSeparately',
  seperateVariantsSelector: 'seperateVariantsSelector',
  enableViewAll: 'enableViewAll',
  viewAllButtonStyle: 'viewAllButtonStyle',
  desktopCarousel: 'desktopCarousel',
  colorScheme: 'colorScheme',
  imageRatio: 'imageRatio',
  quickAddStyle: 'quickAddStyle',
  mobileColumnCount: 'mobileColumnCount',
  mobileCarousel: 'mobileCarousel'
};

const configDefaultValues = {
  [configNameValues.headingText]: 'Featured products',
  [configNameValues.headingSize]: HEADING_SIZE_STYLE_ENUM.LARGE,
  [configNameValues.descriptionText]: '',
  [configNameValues.showCollectionDescription]: 'false',
  [configNameValues.ecomStoreCollection]: '',
  [configNameValues.showSecondImageOnHover]: 'true',
  [configNameValues.showVendorName]: 'true',
  [configNameValues.showVariantsSeparately]: 'false',
  [configNameValues.seperateVariantsSelector]: '',
  [configNameValues.productsFullWidth]: 'false',
  [configNameValues.maximumProductsDisplayed]: '5',
  [configNameValues.desktopColumnCount]: '6',
  [configNameValues.enableViewAll]: 'true',
  [configNameValues.viewAllButtonStyle]: VIEW_ALL_STYLE_ENUM.OUTLINE_BUTTON,
  [configNameValues.desktopCarousel]: 'false',
  [configNameValues.colorScheme]: '1',
  [configNameValues.imageRatio]: IMAGE_DISPLAY_ENUM.SQUARE,
  [configNameValues.quickAddStyle]: QUICK_ADD_STYLE_ENUM.STANDARD,
  [configNameValues.mobileColumnCount]: '2',
  [configNameValues.mobileCarousel]: 'false'
};

const configOptions = {
  [configNameValues.headingText]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    label: 'Heading'
  },
  [configNameValues.headingSize]: {
    label: 'Heading size',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      {
        value: HEADING_SIZE_STYLE_ENUM.SMALL,
        label: 'Small'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.MEDIUM,
        label: 'Medium'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.LARGE,
        label: 'Large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE,
        label: 'Extra large'
      },
      {
        value: HEADING_SIZE_STYLE_ENUM.HUGE,
        label: 'Huge'
      }
    ]
  },
  [configNameValues.showCollectionDescription]: {
    label: 'Show defined collection description',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.descriptionText]: {
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
    isTextArea: true,
    label: 'Description',
    isVisible: (config) => config.showCollectionDescription !== 'true'
  },
  [configNameValues.ecomStoreCollection]: {
    label: 'Collection',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreCollection
  },
  [configNameValues.maximumProductsDisplayed]: {
    label: 'Maximum products displayed',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 2,
    max: 25,
    unit: ''
  },
  [configNameValues.desktopColumnCount]: {
    label: 'Desktop column count',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 1,
    max: 6,
    unit: ''
  },
  [configNameValues.productsFullWidth]: {
    label: 'Stretch products full width',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.enableViewAll]: {
    label:
      'Show a "view all" button when the collection has more products than displayed',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.viewAllButtonStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: '"View all" button style',
    options: [
      {
        value: VIEW_ALL_STYLE_ENUM.LINK,
        label: 'Link'
      },
      {
        value: VIEW_ALL_STYLE_ENUM.OUTLINE_BUTTON,
        label: 'Outline button'
      },
      {
        value: VIEW_ALL_STYLE_ENUM.SOLID_BUTTON,
        label: 'Solid button'
      }
    ],
    isVisible: (config) => config.enableViewAll === 'true'
  },
  [configNameValues.desktopCarousel]: {
    label: 'Enable desktop carousel',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.imageRatio]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Image ratio',
    category: 'Product display',
    options: [
      {
        value: IMAGE_DISPLAY_ENUM.PORTRAIT,
        label: 'Portrait'
      },
      {
        value: IMAGE_DISPLAY_ENUM.SQUARE,
        label: 'Square'
      }
    ]
  },
  [configNameValues.showSecondImageOnHover]: {
    label: 'Show second image on hover',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Product display'
  },
  [configNameValues.showVendorName]: {
    label: 'Show vendor name',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Product display'
  },
  [configNameValues.quickAddStyle]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Quick add',
    category: 'Product display',
    options: [
      {
        value: QUICK_ADD_STYLE_ENUM.NONE,
        label: 'None'
      },
      {
        value: QUICK_ADD_STYLE_ENUM.STANDARD,
        label: 'Standard'
      }
    ]
  },
  [configNameValues.mobileColumnCount]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Mobile column count',
    category: 'Mobile layout',
    options: [
      {
        value: '1',
        label: '1 column'
      },
      {
        value: '2',
        label: '2 columns'
      }
    ]
  },
  [configNameValues.mobileCarousel]: {
    label: 'Enable mobile carousel',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX,
    category: 'Mobile layout'
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.FEATURED_COLLECTION,
  title: 'Featured collection',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsFeaturedCollection,
  configOptions,
  maxItemCount: null,
  blocks: [],
  defaultConfig: configDefaultValues,
  onFetchDataConfig: (sectionConfigValues) => ({
    type: sectionConfigValues.ecomStoreCollection
      ? 'ecomStoreCollection'
      : 'ecomStoreProducts',
    entityId: sectionConfigValues.ecomStoreCollection,
    filter: {}
  })
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  HEADING_SIZE_STYLE_ENUM,
  VIEW_ALL_STYLE_ENUM,
  IMAGE_DISPLAY_ENUM,
  QUICK_ADD_STYLE_ENUM
};
