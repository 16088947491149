/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AlertCircle, ArrowLeft, Pencil, Trash } from 'tabler-icons-react';
import { Button, Image, Skeleton, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import {
  ECOM_PRODUCT_STATUS_ENUM,
  VIEW_ACTIONS_ENUM,
  WEIGHT_UNIT_ENUM
} from '../../../config/constants';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import AppCard from '../../common/AppCard';
import EditProductCard from './EditProductCard';
import ProductVariantsDisplayCard from './ProductVariantsDisplayCard';
import EditProductVariant from './EditProductVariant';
import ConfirmModal from '../../common/ConfirmModal';
import {
  useGlobalFormState,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import MediaDropzone from '../media/MediaDropzone';
import { uploadFileContentRecursively } from '../../../helpers/awsHelper';
import { sortVariantsByOptions } from '../../../helpers/webstoreHelper';

const initialVariantState = {
  key: new Date().getTime().toString(),
  price: 0,
  compareAtPrice: null,
  costPerItem: null,
  src: '',
  sku: '',
  barcode: '',
  trackQuantity: true,
  continueSellingWhenOutOfStock: false,
  requiresShipping: false,
  weight: 0,
  weightUnit: WEIGHT_UNIT_ENUM.KG,
  originCountry: '',
  originProvince: '',
  harmonizedSystemCode: '',
  ecomVendorProductVariantOptions: [],
  ecomVendorProductVariantMedia: []
};

const EditProductVariantsView = ({ duplicate, newVariant }) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const {
    isLargeMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller
  } = useMediaQueryIndex();
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const {
    ecomVendorProductUuid,
    ecomVendorProductVariantUuid,
    action
  } = useParams();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchVendorProduct, updateVendorProducts } = useContext(
    VendorContext
  );
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [submitError, setSubmitError] = useState(null);
  const {
    hasInitialized,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    discardFormChanges,
    hasUnsavedChanges,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(
    { ecomVendorProductVariants: [] },
    {
      confirmDiscard: true,
      containerWidth: 950
    }
  );
  const loading =
    !hasFetched.current ||
    state.ecomVendorProduct.loading ||
    state.ecomVendorProduct.value?.uuid !== ecomVendorProductUuid;
  const ecomVendorProduct = !loading ? state.ecomVendorProduct.value : null;
  const ecomVendorProductVariant =
    ecomVendorProductVariantUuid && duplicate
      ? formState.ecomVendorProductVariants?.find(
          (v) => v.key === initialVariantState.key && v.duplicate === duplicate
        ) ??
        formState.ecomVendorProductVariants?.find(
          (v) => v.uuid === ecomVendorProductVariantUuid
        ) ??
        null
      : ecomVendorProductVariantUuid
      ? formState.ecomVendorProductVariants?.find(
          (v) => v.uuid === ecomVendorProductVariantUuid
        ) ?? null
      : formState.ecomVendorProductVariants?.find(
          (v) => v.key === initialVariantState.key && v.duplicate === duplicate
        ) ?? null;
  const productVariantOptions =
    ecomVendorProduct?.ecomVendorProductVariants
      .reduce((r, c) => {
        c.ecomVendorProductVariantOptions.forEach((d) => {
          const existing = r.find((s) => s.name === d.name);
          if (!existing) {
            r.push({
              name: d.name,
              nameSort: d.nameSort
            });
          }
        });
        return r;
      }, [])
      .sort((a, b) => a.nameSort - b.nameSort) ?? [];

  const selectedEcomVendorProductMedia = formState.ecomVendorProductMedia?.find(
    (m) => m.preview === ecomVendorProductVariant?.src
  );

  useEffect(() => {
    if (ecomVendorProductUuid) {
      fetchVendorProduct(ecomVendorProductUuid, null, (error) => {
        triggerNotification('Product not found!');
      });
      hasFetched.current = true;
    }
  }, [ecomVendorProductUuid, action]);

  useEffect(() => {
    if (!state.ecomVendorProduct.loading && !!ecomVendorProduct) {
      const duplicateVariant = duplicate
        ? ecomVendorProduct.ecomVendorProductVariants.find(
            (v) => v.uuid === ecomVendorProductVariantUuid
          )
        : {};

      resetFormState({
        ecomVendorProductMedia: ecomVendorProduct.ecomVendorProductMedia.map(
          (m) => ({
            ...m,
            key: m.pkEcomVendorProductMedia.toString(),
            file: null,
            sort: m.sort,
            alt: m.alt,
            contentType: m.contentType,
            src: m.src,
            preview: m.src
          })
        ),
        ecomVendorProductVariants: [
          ...ecomVendorProduct.ecomVendorProductVariants.map((v) => ({
            ...v,
            key: v.pkEcomVendorProductVariant.toString(),
            src: ecomVendorProduct.ecomVendorProductMedia.find(
              (m) => m.pkEcomVendorProductMedia === v.fkEcomVendorProductMedia
            )?.src,
            fkEcomVendorProductMedia: null
          })),
          {
            ...initialVariantState,
            ecomVendorProductVariantOptions: productVariantOptions.map(
              (op) => ({
                name: op.name,
                nameSort: op.nameSort,
                value: '',
                valueSort:
                  ecomVendorProduct.ecomVendorProductVariants.filter((v) =>
                    v.ecomVendorProductVariantOptions.some(
                      (s) => s.name === op.name
                    )
                  ).length + 1
              })
            ),
            ...(duplicateVariant
              ? {
                  ...duplicateVariant,
                  src: ecomVendorProduct.ecomVendorProductMedia.find(
                    (m) =>
                      m.pkEcomVendorProductMedia ===
                      duplicateVariant.fkEcomVendorProductMedia
                  )?.src
                }
              : {}),
            duplicate,
            uuid: null
          }
        ]
      });
      setSubmitError(null);
    }
  }, [
    state.ecomVendorProduct.loading,
    ecomVendorProduct,
    ecomVendorProductVariantUuid,
    duplicate
  ]);

  useEffect(() => {
    if (ecomVendorProduct && !newVariant) {
      if (
        !ecomVendorProduct.ecomVendorProductVariants.find(
          (v) => v.uuid === ecomVendorProductVariantUuid
        )
      ) {
        const firstVariant = sortVariantsByOptions(
          ecomVendorProduct.ecomVendorProductVariants
        )[0];
        if (firstVariant) {
          navigate(
            `/vendor/products/${ecomVendorProductUuid}/variants/${
              firstVariant.uuid
            }${fromLocation ? `?from=${fromLocation}` : ''}`
          );
        }
        else {
          navigate(
            `/vendor/products/${ecomVendorProductUuid}/variants/new${
              fromLocation ? `?from=${fromLocation}` : ''
            }`
          );
        }
      }
    }
  }, [ecomVendorProduct, ecomVendorProductVariantUuid]);

  const onUpdateProductVariant = (data) => {
    setFormState({
      ...formState,
      ecomVendorProductVariants: [
        ...formState.ecomVendorProductVariants.map((v) =>
          v.key === ecomVendorProductVariant.key
            ? { ...v, ...data, hasUnsavedChanges: true }
            : v
        )
      ]
    });
  };

  const onSubmitError = (message) => {
    setSubmitError(message);
    if (message !== submitError) {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    }
  };

  const onSubmitProductForm = () => {
    const duplicateVariant = formState.ecomVendorProductVariants.find(
      (v) =>
        v.key !== ecomVendorProductVariant.key &&
        v.ecomVendorProductVariantOptions.every((o) =>
          ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
            (s) =>
              s.name.toLowerCase().trim() === o.name.toLowerCase().trim() &&
              s.value.toLowerCase().trim() === o.value.toLowerCase().trim()
          )
        )
    );

    if (duplicateVariant) {
      onSubmitError(
        <AppText style={{ fontSize: 14 }}>
          Variant with options{' '}
          <b>
            {ecomVendorProductVariant.ecomVendorProductVariantOptions
              .map((s) => s.value)
              .join('/')}
          </b>{' '}
          already exists.
        </AppText>
      );
    }
    else if (
      (!ecomVendorProductVariant.price ||
        ecomVendorProductVariant.price === 0) &&
      ecomVendorProduct.fkEcomProductStatus.toString() ===
        ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ) {
      onSubmitError(
        "Price required for active products. Please set a price greater than $0 or change the product status to 'Draft'."
      );
    }
    else if (
      ecomVendorProductVariant.compareAtPrice &&
      ecomVendorProductVariant.compareAtPrice <= ecomVendorProductVariant.price
    ) {
      onSubmitError('Compare at price must be greater than the price.');
    }
    else {
      submitFormState((formData, onErrorCallback) =>
        uploadFileContentRecursively(
          formState.ecomVendorProductMedia,
          `ecom-vendor-${
            authState.pkEcomVendor
          }-product-${new Date().getTime()}`,
          (media) => {
            updateVendorProducts(
              authState.pkEcomVendor,
              {
                selectFirstItem: true,
                ecomVendorProducts: [
                  {
                    uuid: ecomVendorProduct.uuid,
                    ecomVendorProductMedia: media.filter((f) => !f.deleted),
                    ecomVendorProductVariants: [
                      ...formState.ecomVendorProductVariants
                        .filter((v) => v.hasUnsavedChanges)
                        .map((v) => ({
                          ...v,
                          weight: v.weight ? v.weight : -1,
                          costPerItem: v.costPerItem ? v.costPerItem : -1,
                          compareAtPrice: v.compareAtPrice
                            ? v.compareAtPrice
                            : -1,
                          quantity: v.quantity ? v.quantity : -1,
                          src:
                            media.find((m) => m.preview === v.src && !m.deleted)
                              ?.src ?? ''
                        }))
                    ]
                  }
                ]
              },
              (data) => {
                const newVariantUuid = data[0].ecomVendorProductVariants.find(
                  (v) =>
                    !formState.ecomVendorProductVariants.some(
                      (s) => s.uuid === v.uuid
                    )
                )?.uuid;
                if (newVariantUuid) {
                  navigate(
                    `/vendor/products/${ecomVendorProductUuid}/variants/${newVariantUuid}${
                      fromLocation ? `?from=${fromLocation}` : ''
                    }`
                  );
                }

                triggerNotification(
                  ecomVendorProductVariantUuid
                    ? `Variant updated!`
                    : 'Variant created!',
                  'success'
                );
              },
              onErrorCallback
            );
          },
          onErrorCallback
        )
      );
    }
  };

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'inventory':
        return '/vendor/inventory';
      case 'products':
        return '/vendor/products';
      default: {
        if (duplicate) {
          return `/vendor/products/${ecomVendorProductUuid}/variants/${ecomVendorProductVariantUuid}`;
        }
        return `/vendor/products/${ecomVendorProductUuid}`;
      }
    }
  };

  return !loading && hasInitialized && ecomVendorProductVariant ? (
    <AppStack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmitProductForm(formState);
      }}
      p={{ base: '8px 0px', sm: '16px 16px 40px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            to={getBackPath()}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppText style={{ fontSize: 18, fontWeight: 700 }}>
            {duplicate
              ? 'Duplicate variant'
              : ecomVendorProductVariantUuid
              ? ecomVendorProductVariant.ecomVendorProductVariantOptions
                  .sort((a, b) => a.nameSort - b.nameSort)
                  .map((s) => s.value)
                  .join(' / ')
              : 'New variant'}
          </AppText>
        </AppFlexbox>

        {ecomVendorProductVariantUuid && ecomVendorProduct && !duplicate && (
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <Button
              color="dark"
              onClick={() =>
                navigate(
                  `/vendor/products/${ecomVendorProductUuid}/variants/${ecomVendorProductVariantUuid}/duplicate${
                    fromLocation ? `?from=${fromLocation}` : ''
                  }`
                )
              }
              radius="md"
              size="xs"
              variant="light"
            >
              Duplicate
            </Button>
          </AppFlexbox>
        )}
      </AppFlexbox>
      {submitError && (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppFlexbox
            style={{
              padding: 8,
              gap: 8,
              backgroundColor: '#c40000',
              color: '#fff'
            }}
          >
            <AppStack>
              <AlertCircle size={18} />
            </AppStack>

            <AppText style={{ fontSize: 14 }}>
              Please fix the error with this variant.
            </AppText>
          </AppFlexbox>
          <AppStack style={{ padding: 8 }}>
            {typeof submitError === 'string' ? (
              <AppText style={{ fontSize: 14 }}>{submitError}</AppText>
            ) : (
              submitError
            )}
          </AppStack>
        </AppCard>
      )}

      {(!ecomVendorProductVariantUuid || duplicate) && (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 8 }}
          withBorder
        >
          <AppFlexbox style={{ gap: 8 }}>
            <AppStack>
              <AlertCircle
                color={duplicate ? '#eca70a' : '#067D62'}
                style={{ size: 18 }}
              />
            </AppStack>

            <AppText style={{ fontSize: 14 }}>
              {duplicate
                ? 'Duplicate this variant by changing the options for '
                : 'Enter the options for '}
              <b>{productVariantOptions.map((s) => s.name).join(' / ')}</b>
              {!duplicate && ' to create a new variant.'}.
            </AppText>
          </AppFlexbox>
        </AppCard>
      )}

      <AppFlexbox
        style={{ flexDirection: isLaptopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <ProductVariantsDisplayCard
            ecomVendorProduct={ecomVendorProduct}
            ecomVendorProductVariant={ecomVendorProductVariant}
            ecomVendorProductVariants={formState.ecomVendorProductVariants}
          />
        </AppStack>
        <AppStack style={{ flex: 2 }}>
          <EditProductCard title="Options">
            <AppStack style={{ gap: 8 }}>
              {ecomVendorProductVariant.ecomVendorProductVariantOptions
                .sort((a, b) => a.nameSort - b.nameSort)
                .map((option) => (
                  <TextInput
                    key={option.name}
                    disabled={isSubmitting}
                    label={option.name}
                    onChange={(e) => {
                      onUpdateProductVariant({
                        ...ecomVendorProductVariant,
                        ecomVendorProductVariantOptions: ecomVendorProductVariant.ecomVendorProductVariantOptions.map(
                          (o) =>
                            o.name === option.name
                              ? { ...o, value: e.target.value }
                              : o
                        )
                      });
                    }}
                    required
                    style={{ flex: 1 }}
                    value={option.value ?? ''}
                  />
                ))}
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Image
                </AppText>

                {selectedEcomVendorProductMedia && (
                  <AppStack style={{ gap: 0 }}>
                    <AppStack
                      style={{
                        width: '100%',
                        height: 150,
                        backgroundColor: 'rgba(240, 240, 240, 1)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8
                      }}
                    >
                      <AppStack
                        style={{
                          height: '100%',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Image
                          fit="contain"
                          h="100%"
                          src={ecomVendorProductVariant.src}
                          w="100%"
                        />
                      </AppStack>
                    </AppStack>
                    <Button.Group style={{ flex: 1 }}>
                      <Button
                        disabled={isSubmitting}
                        leftSection={<Pencil size={18} />}
                        onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.UPLOAD)}
                        size="compact-md"
                        style={{
                          flex: 1,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0
                        }}
                        variant="default"
                      >
                        Change
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        leftSection={<Trash size={18} />}
                        onClick={() => {
                          setFormState({
                            ...formState,
                            ecomVendorProductMedia: [
                              ...formState.ecomVendorProductMedia.map((m) =>
                                m.preview === ecomVendorProductVariant.src &&
                                !m.pkEcomVendorProductMedia
                                  ? {
                                      ...m,
                                      deleted: true
                                    }
                                  : m
                              )
                            ],
                            ecomVendorProductVariants: [
                              ...formState.ecomVendorProductVariants.map((v) =>
                                v.key === ecomVendorProductVariant.key
                                  ? {
                                      ...v,
                                      src: null,
                                      hasUnsavedChanges: true
                                    }
                                  : v
                              )
                            ]
                          });
                        }}
                        size="compact-md"
                        style={{
                          flex: 1,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0
                        }}
                        variant="default"
                      >
                        Remove
                      </Button>
                    </Button.Group>
                  </AppStack>
                )}

                <AppStack
                  style={{display: selectedEcomVendorProductMedia ? 'none' : 'flex'}}
                >
                  <MediaDropzone
                    disabled={isSubmitting}
                    enableMediaSelect
                    files={
                      selectedEcomVendorProductMedia
                        ? [selectedEcomVendorProductMedia]
                        : []
                    }
                    fkEcomVendor={authState.pkEcomVendor}
                    isOpen={
                      modalState.isOpen &&
                      modalState.action === VIEW_ACTIONS_ENUM.UPLOAD
                    }
                    maxSelectCount={1}
                    onClose={onCloseModal}
                    onUpload={(uploadedMedia) => {
                      const media = uploadedMedia[0];
                      const existingMedia = formState.ecomVendorProductMedia.find(
                        (m) => m.preview === media?.preview
                      );
                      setFormState({
                        ...formState,
                        ecomVendorProductMedia:
                          existingMedia || !media
                            ? formState.ecomVendorProductMedia
                            : [
                                ...formState.ecomVendorProductMedia,
                                {
                                  ...media,
                                  deleted: false,
                                  sort:
                                    formState.ecomVendorProductMedia.length + 1
                                }
                              ],
                        ecomVendorProductVariants: [
                          ...formState.ecomVendorProductVariants.map((v) =>
                            v.key === ecomVendorProductVariant.key
                              ? {
                                  ...v,
                                  src: media?.preview ?? null,
                                  hasUnsavedChanges: true
                                }
                              : v
                          )
                        ]
                      });
                    }}
                  />
                </AppStack>
              </AppStack>
            </AppStack>
          </EditProductCard>

          <EditProductVariant
            disabled={isSubmitting}
            ecomVendorProductVariant={ecomVendorProductVariant}
            onChange={onUpdateProductVariant}
          />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        p={{ base: 16, sm: '16px 0px' }}
        style={{
          justifyContent: 'flex-end',
          flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
        }}
      >
        {ecomVendorProductVariant.uuid && (
          <Button
            color="red"
            disabled={isSubmitting}
            onClick={() =>
              onOpenModal(VIEW_ACTIONS_ENUM.DELETE, ecomVendorProductVariant)
            }
            radius="md"
            size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
            type="button"
            variant="filled"
          >
            Delete variant
          </Button>
        )}

        <Button
          color="dark"
          disabled={!hasUnsavedChanges}
          id="save-button"
          loading={isSubmitting}
          radius="md"
          size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
          type="submit"
          variant="filled"
        >
          Save
        </Button>
      </AppFlexbox>

      {ConfirmDiscardModal}

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete variant"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to delete the variant{' '}
              <b>
                {modalState.item?.ecomVendorProductVariantOptions
                  .sort((a, b) => a.nameSort - b.nameSort)
                  .map((s) => s.value)
                  .join('/')}
              </b>
              ?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          updateVendorProducts(
            authState.pkEcomVendor,
            {
              selectFirstItem: true,
              ecomVendorProducts: [
                {
                  uuid: ecomVendorProduct.uuid,
                  ecomVendorProductVariants: [
                    {
                      ...ecomVendorProductVariant,
                      deleted: true
                    }
                  ]
                }
              ]
            },
            (data) => {
              const newVariantUuid = data[0].ecomVendorProductVariants[0]?.uuid;
              navigate(
                `/vendor/products/${ecomVendorProductUuid}${
                  newVariantUuid ? `/variants/${newVariantUuid}` : ''
                }${fromLocation ? `?from=${fromLocation}` : ''}`
              );
              onCloseModal();
              triggerNotification(`Variant deleted!`, 'success');
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title="Delete variant?"
      />
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Skeleton height={30} width={150} />
        </AppFlexbox>

        {ecomVendorProductUuid && (
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <Skeleton height={30} width={80} />
          </AppFlexbox>
        )}
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isLaptopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
        </AppStack>
        <AppStack style={{ flex: 2 }}>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

EditProductVariantsView.propTypes = {
  duplicate: PropTypes.bool,
  newVariant: PropTypes.bool
};

export default EditProductVariantsView;
