import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Anchor, Divider, Skeleton, Tooltip } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import CustomerAddressModal from '../customers/CustomerAddressModal';
import { createGoogleMapsLink } from '../../../helpers/format';

const OrderCustomerInfoCard = ({
  addresses,
  customerName,
  customerEmail,
  customerUrl,
  fulfillmentPreview,
  loading
}) => {
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const shippingAddress = addresses?.find((f) => f.isShipping);
  const billingAddress = addresses?.find((f) => !f.isShipping);

  const isBillingSameAsShipping =
    !shippingAddress ||
    !billingAddress ||
    (shippingAddress.fullName === billingAddress.fullName &&
      shippingAddress.address1 === billingAddress.address1 &&
      shippingAddress.address2 === billingAddress.address2 &&
      shippingAddress.city === billingAddress.city &&
      shippingAddress.state === billingAddress.state &&
      shippingAddress.postalCode === billingAddress.postalCode &&
      shippingAddress.country === billingAddress.country);

  return !loading && customerEmail ? (
    <>
      <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
        <AppStack
          style={{
            gap: 0,
            flexDirection:
              !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
          }}
        >
          {!fulfillmentPreview && (
            <>
              <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
                <AppStack style={{ flex: 1, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      Customer
                    </AppText>
                  </AppFlexbox>
                  <AppStack style={{ gap: 0 }}>
                    <AppFlexbox>
                      {customerName ? (
                        <Anchor
                          component={customerUrl ? Link : null}
                          style={{ fontSize: 13, color: 'dodgerblue' }}
                          to={customerUrl}
                        >
                          {customerName || 'Guest'}
                        </Anchor>
                      ) : (
                        <Tooltip label="Guest checkout" withArrow>
                          <AppText style={{ fontSize: 13 }}>N/A</AppText>
                        </Tooltip>
                      )}
                    </AppFlexbox>
                  </AppStack>
                </AppStack>

                <AppStack style={{ flex: 1, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Contact information
                    </AppText>
                  </AppFlexbox>
                  <AppStack style={{ gap: 0 }}>
                    {customerName && (
                      <AppText style={{ fontSize: 13 }}>
                        {customerName || 'Guest'}
                      </AppText>
                    )}

                    <AppFlexbox>
                      <Anchor
                        href={`mailto:${customerEmail}`}
                        style={{ fontSize: 13, color: 'dodgerblue' }}
                        target="_blank"
                      >
                        {customerEmail}
                      </Anchor>
                    </AppFlexbox>
                  </AppStack>
                </AppStack>
              </AppStack>

              <Divider
                orientation={
                  !isTabletOrSmaller && isDesktopOrSmaller
                    ? 'vertical'
                    : 'horizontal'
                }
              />
            </>
          )}

          <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText
                style={{
                  fontSize: fulfillmentPreview ? 16 : 14,
                  fontWeight: 500
                }}
              >
                Shipping address
              </AppText>
              {/* {!fulfillmentPreview && (
                <ActionIcon
                  color="#666"
                  onClick={() =>
                    onOpenModal(VIEW_ACTIONS_ENUM.EDIT, shippingAddress)
                  }
                  size="sm"
                  variant="subtle"
                >
                  <Edit />
                </ActionIcon>
              )} */}
            </AppFlexbox>
            {!shippingAddress ? (
              <AppText style={{ fontSize: 13, color: '#666' }}>
                No shipping address
              </AppText>
            ) : (
              <>
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 13 }}>
                    {shippingAddress.fullName}
                  </AppText>
                  <AppText style={{ fontSize: 13 }}>
                    {shippingAddress.address1}
                  </AppText>
                  {shippingAddress.address2 && (
                    <AppText style={{ fontSize: 13 }}>
                      {shippingAddress.address2}
                    </AppText>
                  )}
                  <AppText style={{ fontSize: 13 }}>
                    {shippingAddress.city} {shippingAddress.state}{' '}
                    {shippingAddress.postalCode}
                  </AppText>
                  <AppText style={{ fontSize: 13 }}>
                    {shippingAddress.country}
                  </AppText>
                </AppStack>

                <AppFlexbox>
                  <Anchor
                    component="a"
                    href={createGoogleMapsLink(shippingAddress)}
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                    target="_blank"
                  >
                    View on map
                  </Anchor>
                </AppFlexbox>
              </>
            )}
          </AppStack>

          {!fulfillmentPreview && (
            <>
              <Divider
                orientation={
                  !isTabletOrSmaller && isDesktopOrSmaller
                    ? 'vertical'
                    : 'horizontal'
                }
              />

              <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Billing address
                  </AppText>
                  {/* <ActionIcon
                    color="#666"
                    onClick={() =>
                      onOpenModal(VIEW_ACTIONS_ENUM.EDIT, billingAddress)
                    }
                    size="sm"
                    variant="subtle"
                  >
                    <Edit />
                  </ActionIcon> */}
                </AppFlexbox>
                <AppStack style={{ gap: 0 }}>
                  {!billingAddress || isBillingSameAsShipping ? (
                    <AppText style={{ fontSize: 13, color: '#666' }}>
                      Same a shipping address
                    </AppText>
                  ) : (
                    <>
                      <AppText style={{ fontSize: 13 }}>
                        {billingAddress.fullName}
                      </AppText>
                      <AppText style={{ fontSize: 13 }}>
                        {billingAddress.address1}
                      </AppText>
                      {billingAddress.address2 && (
                        <AppText style={{ fontSize: 13 }}>
                          {billingAddress.address1}
                        </AppText>
                      )}
                      <AppText style={{ fontSize: 13 }}>
                        {billingAddress.city} {billingAddress.state}{' '}
                        {billingAddress.postalCode}
                      </AppText>
                      <AppText style={{ fontSize: 13 }}>
                        {billingAddress.country}
                      </AppText>
                    </>
                  )}
                </AppStack>
              </AppStack>
            </>
          )}
        </AppStack>
      </AppCard>

      <CustomerAddressModal
        address={modalState.item}
        copyAddress={
          modalState.item?.isShipping ? billingAddress : shippingAddress
        }
        isBilling={!modalState.item?.isShipping}
        isOpen={modalState.isOpen}
        onClose={onCloseModal}
      />
    </>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      {!fulfillmentPreview && (
        <>
          <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
            <AppStack style={{ flex: 1, gap: 12 }}>
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Skeleton height={22} width="30%" />
              </AppFlexbox>
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox>
                  <Skeleton height={18} width="35%" />
                </AppFlexbox>
              </AppStack>
            </AppStack>

            <AppStack style={{ flex: 1, gap: 8 }}>
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Skeleton height={22} width="40%" />
              </AppFlexbox>
              <AppStack style={{ gap: 5 }}>
                <Skeleton height={18} width="35%" />
                <Skeleton height={18} width="50%" />
              </AppStack>
            </AppStack>
          </AppStack>

          <Divider
            orientation={
              !isTabletOrSmaller && isDesktopOrSmaller
                ? 'vertical'
                : 'horizontal'
            }
          />
        </>
      )}

      <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
        <AppStack style={{ flex: 1, gap: 12 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={22} width="35%" />
          </AppFlexbox>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={18} width="30%" />
            <Skeleton height={18} width="40%" />
            <Skeleton height={18} width="40%" />
            <Skeleton height={18} width="15%" />
          </AppStack>
        </AppStack>
      </AppStack>

      <Divider
        orientation={
          !isTabletOrSmaller && isDesktopOrSmaller ? 'vertical' : 'horizontal'
        }
      />

      <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
        <AppStack style={{ flex: 1, gap: 12 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={22} width="35%" />
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <Skeleton height={18} width="30%" />
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

OrderCustomerInfoCard.propTypes = {
  addresses: PropTypes.array,
  customerEmail: PropTypes.string,
  customerName: PropTypes.string,
  customerUrl: PropTypes.string,
  fulfillmentPreview: PropTypes.bool,
  loading: PropTypes.bool
};

export default OrderCustomerInfoCard;
