/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { ColorSwatch } from '@mantine/core';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppText from '../../../../common/AppText';
import { TEXT_STYLE_ENUM } from './wsInventoryStatusConfig';

const WsInventoryStatus = ({ config, selectedVariant }) => {
  const uppercaseText = config.textStyle === TEXT_STYLE_ENUM.UPPERCASE;
  const showInventoryCount = config.showInventoryCount === 'true';
  const useThreshold = config.useInventoryThreshold === 'true';
  const inventoryThreshold = config.inventoryThreshold * 1;

  const quantityLeft = selectedVariant
    ? selectedVariant.ecomVendorProductVariant.quantity
    : null;

  return (
    selectedVariant &&
    (quantityLeft || config.visibleWhenNotTracked === 'true') && (
      <AppFlexbox style={{ flex: 1, gap: 8, alignItems: 'center' }}>
        <ColorSwatch
          color={
            quantityLeft != null &&
            useThreshold &&
            inventoryThreshold >= quantityLeft
              ? '#eca70a'
              : '#067D62'
          }
          size={18}
        />
        <AppText
          style={{
            fontSize: 16,
            textTransform: uppercaseText ? 'uppercase' : 'none'
          }}
        >
          {quantityLeft != null
            ? quantityLeft <= 0
              ? 'Out of stock'
              : useThreshold && inventoryThreshold >= quantityLeft
              ? showInventoryCount
                ? `Low stock: ${quantityLeft} left`
                : `Low stock`
              : showInventoryCount
              ? `${quantityLeft} In stock`
              : `In stock`
            : `In stock`}
        </AppText>
      </AppFlexbox>
    )
  );
};

WsInventoryStatus.propTypes = {
  config: PropTypes.object,
  selectedVariant: PropTypes.object
};

export default WsInventoryStatus;
