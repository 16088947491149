import { BoxMargin } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM,
  ECOM_ENTITY_TYPES
} from '../../../../../config/constants';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';
import WsMultiColumn from './WsMultiColumn';

const CONTENT_ALIGNMENT_ENUM = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end'
};

const BUTTON_SIZE_ENUM = {
  EXTRA_SMALL: 'xs',
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  EXTRA_LARGE: 'xl'
};

const buttonSizeOptions = [
  {
    value: BUTTON_SIZE_ENUM.EXTRA_SMALL,
    label: 'Extra small'
  },
  {
    value: BUTTON_SIZE_ENUM.SMALL,
    label: 'Small'
  },
  {
    value: BUTTON_SIZE_ENUM.MEDIUM,
    label: 'Medium'
  },
  {
    value: BUTTON_SIZE_ENUM.LARGE,
    label: 'Large'
  },
  {
    value: BUTTON_SIZE_ENUM.EXTRA_LARGE,
    label: 'Extra large'
  }
];

const configNameValues = {
  heading: 'heading',
  headingSize: 'headingSize',
  contentAlignment: 'contentAlignment',
  buttonLabel: 'buttonLabel',
  buttonLink: 'buttonLink',
  buttonSize: 'buttonSize',
  buttonUseCompactStyle: 'buttonUseCompactStyle',
  buttonIsOutlineStyle: 'buttonIsOutlineStyle',
  colorScheme: 'colorScheme'
};

const configDefaultValues = {
  [configNameValues.heading]: 'Multicolumn',
  [configNameValues.headingSize]: '24',
  [configNameValues.contentAlignment]: CONTENT_ALIGNMENT_ENUM.CENTER,
  [configNameValues.buttonLabel]: 'Button label',
  [configNameValues.buttonLink]: '',
  [configNameValues.buttonSize]: 'md',
  [configNameValues.buttonUseCompactStyle]: 'false',
  [configNameValues.buttonIsOutlineStyle]: 'false',
  [configNameValues.colorScheme]: '1'
};

const configOptions = {
  [configNameValues.heading]: {
    label: 'Heading',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.headingSize]: {
    label: 'Heading Size',
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    min: 12,
    max: 100,
    unit: 'px'
  },
  [configNameValues.contentAlignment]: {
    label: 'Content Alignment',
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    options: [
      {
        label: 'Left',
        value: CONTENT_ALIGNMENT_ENUM.LEFT
      },
      {
        label: 'Center',
        value: CONTENT_ALIGNMENT_ENUM.CENTER
      },
      {
        label: 'Right',
        value: CONTENT_ALIGNMENT_ENUM.RIGHT
      }
    ]
  },
  [configNameValues.buttonLabel]: {
    label: 'Button Label',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.buttonLink]: {
    label: 'Button Link',
    type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  [configNameValues.buttonSize]: {
    label: 'Button Size',
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: buttonSizeOptions
  },
  [configNameValues.buttonUseCompactStyle]: {
    label: 'Use compact button style',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.buttonIsOutlineStyle]: {
    label: 'Button is outline variant',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.MULTICOLUMN,
  title: 'Multicolumn',
  icon: BoxMargin,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsMultiColumn,
  configOptions,
  maxItemCount: null,
  cannotCreate: true,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.COLUMN_CONTENT
      ],
      defaultCount: 3,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
