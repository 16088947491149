import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@mantine/core';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';

const ChangePasswordForm = ({ onClose }) => {
  const { changePassword } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    errors: {},
    isLoading: false
  });

  useEffect(() => {
    setFormState({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errors: {},
      isLoading: false
    });
  }, []);

  const validateFormInputs = () => {
    const errors = {};
    if (formState.newPassword.length <= 7) {
      errors.newPassword = 'Password must be at least 8 characters long';
    }
    else if (
      formState.newPassword &&
      formState.confirmNewPassword &&
      formState.newPassword !== formState.confirmNewPassword
    ) {
      errors.newPassword = 'Passwords do not match';
      errors.confirmNewPassword = 'Passwords do not match';
    }
    setFormState({
      ...formState,
      errors
    });
    return Object.keys(errors).length === 0;
  };

  return (
    <FormSection
      isLoading={formState.isLoading}
      onCancel={onClose}
      onSubmit={() => {
        if (validateFormInputs()) {
          setFormState({
            ...formState,
            isLoading: true
          });
          changePassword(
            formState,
            () => {
              triggerNotification('Password changed!', 'Success', 'green');
              onClose();
            },
            (error) => {
              triggerNotification(error);
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }
      }}
      submitTitle="Change Password"
    >
      <AppStack>
        <TextInput
          disabled={formState.isLoading}
          error={formState.errors.oldPassword}
          label="Old Password"
          onChange={(e) =>
            setFormState({
              ...formState,
              oldPassword: e.currentTarget.value
            })
          }
          required
          style={{ flex: 1 }}
          type="password"
          value={formState.oldPassword}
        />
        <TextInput
          disabled={formState.isLoading}
          error={formState.errors.newPassword}
          label="New Password"
          onBlur={() => {
            if (formState.newPassword) {
              validateFormInputs();
            }
          }}
          onChange={(e) =>
            setFormState({
              ...formState,
              newPassword: e.currentTarget.value
            })
          }
          required
          style={{ flex: 1 }}
          type="password"
          value={formState.newPassword}
        />
        <TextInput
          disabled={formState.isLoading}
          error={formState.errors.confirmNewPassword}
          label="Confirm New Password"
          onBlur={() => {
            if (formState.confirmNewPassword) {
              validateFormInputs();
            }
          }}
          onChange={(e) =>
            setFormState({
              ...formState,
              confirmNewPassword: e.currentTarget.value
            })
          }
          required
          style={{ flex: 1 }}
          type="password"
          value={formState.confirmNewPassword}
        />
      </AppStack>
    </FormSection>
  );
};

ChangePasswordForm.propTypes = { onClose: PropTypes.func };

export default ChangePasswordForm;
