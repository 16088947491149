import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';

const HeaderView = ({
  breadcrumbs,
  leftSection,
  rightSection,
  actionButton,
  children,
  noHeader
}) => (
  <AppStack
    maw={{ base: 'calc(100vw)', sm: 'calc(100vw - 245px)' }}
    style={{
      flex: 1,
      gap: 0,
      height: '100%',
      maxHeight: 'calc(100vh - 60px)'
    }}
  >
    {!noHeader && (
      <AppStack
        style={{
          gap: 8,
          padding: 10,
          minHeight: 55
        }}
      >
        <AppFlexbox
          style={{
            gap: 0,
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <AppStack
            style={{
              minHeight: 32,
              justifyContent: 'center',
              alignItems: 'center',
              gap: 10
            }}
          >
            <AppFlexbox
              style={{ alignItems: 'center', gap: 10, fontWeight: 700 }}
            >
              {breadcrumbs &&
                breadcrumbs.map((b, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    {index > 0 && (
                      <AppText style={{ fontSize: 20, overflow: 'visible' }}>
                        /
                      </AppText>
                    )}

                    <AppText
                      component={Link}
                      style={{ fontSize: 20 }}
                      to={b.to}
                    >
                      {b.title}
                    </AppText>
                  </React.Fragment>
                ))}
              {leftSection}
            </AppFlexbox>
          </AppStack>

          <AppFlexbox
            style={{
              alignSelf: 'stretch',
              alignItems: 'center',
              flex: 1,
              justifyContent: 'end',
              gap: 8
            }}
          >
            {rightSection}
            {actionButton && (
              <AppStack visibleFrom="xsm">{actionButton}</AppStack>
            )}
          </AppFlexbox>
        </AppFlexbox>
        {actionButton && <AppStack hiddenFrom="xsm">{actionButton}</AppStack>}
      </AppStack>
    )}

    <AppStack
      style={{
        flex: 1,
        gap: 0,
        height: '100%',
        overflow: 'hidden'
      }}
    >
      {children}
    </AppStack>
  </AppStack>
);

HeaderView.propTypes = {
  actionButton: PropTypes.node,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
  leftSection: PropTypes.node,
  noHeader: PropTypes.bool,
  rightSection: PropTypes.node
};

export default HeaderView;
