import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Badge,
  Button,
  Grid,
  Image,
  NumberFormatter
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { AlertCircle } from 'tabler-icons-react';
import { Context as StoreUserContext } from '../../../providers/StoreUserContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import ResponsiveWebstoreModal from './ResponsiveWebstoreModal';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { INPUT_CONTROL_TYPE_ENUM } from '../../../config/constants';
import ProductInputDisplay from '../products/ProductInputDisplay';
import { useCartController } from '../../../helpers/webstoreCheckoutHelper';
import { triggerNotification } from '../../../helpers/notification';
import AppCard from '../../common/AppCard';

const CustomizeCartProductModal = ({
  baseStoreUrl,
  mediaQueries,
  editModeEnabled,
  onNavigate
}) => {
  const { updateStoreCartProducts } = useCartController();
  const {
    state: helperState,
    toggleWebstoreCheckoutSummaryDrawer
  } = useContext(HelperContext);
  const { state } = useContext(StoreUserContext);
  const [formState, setFormState] = useState({
    ecomStoreCartProductInputAnswers: [],
    optionErrors: [],
    loading: false
  });
  const { ecomStoreCartProduct } = helperState.webstore.checkoutSummaryDrawer;
  const ecomStoreProduct =
    ecomStoreCartProduct?.ecomStoreProductVariant.ecomStoreProduct;
  const selectedVariant = ecomStoreCartProduct?.ecomStoreProductVariant;
  const { isTabletOrSmaller } = mediaQueries;

  useEffect(() => {
    if (
      helperState.webstore.checkoutSummaryDrawer.showProductCustomization &&
      ecomStoreCartProduct
    ) {
      setFormState({
        ecomStoreCartProductInputAnswers: ecomStoreCartProduct.ecomStoreCartProductInputAnswers.map(
          (a) => ({
            ...a,
            isAutopopulated: ecomStoreProduct.ecomStoreProductInputAnswers.some(
              (is) =>
                is.fkRegFormControlType === a.fkRegFormControlType &&
                (is.fkEcomVendorProductInput === a.fkEcomVendorProductInput ||
                  is.label.toLowerCase() === a.label.toLowerCase())
            )
          })
        ),
        loading: false
      });
    }
  }, [helperState.webstore.checkoutSummaryDrawer.showProductCustomization]);

  const onClose = () => {
    toggleWebstoreCheckoutSummaryDrawer({
      ...helperState.webstore.checkoutSummaryDrawer,
      showProductCustomization: false
    });
  };

  const onUpdateCartProduct = () => {
    setFormState({ ...formState, optionErrors: [], loading: true });
    updateStoreCartProducts(
      ecomStoreProduct.fkEcomStore,
      {
        ecomStoreCartProducts: [
          {
            ecomStoreProduct,
            pkEcomStoreCartProduct: ecomStoreCartProduct.pkEcomStoreCartProduct,
            uuid:
              ecomStoreCartProduct.ecomStoreProductVariant
                .ecomVendorProductVariant.uuid,
            ecomStoreCartProductInputAnswers:
              formState.ecomStoreCartProductInputAnswers
          }
        ]
      },
      () => {
        setFormState({
          ...formState,
          optionErrors: [],
          loading: false
        });
        onClose();
      },
      (error, type) => {
        if (type === 'input') {
          setFormState({
            ...formState,
            optionErrors: [error],
            loading: false
          });
        }
        else {
          triggerNotification(error);
          setFormState({
            ...formState,
            optionErrors: [],
            loading: false
          });
        }
      }
    );
  };

  return (
    <ResponsiveWebstoreModal
      editModeEnabled={editModeEnabled}
      isOpen={
        helperState.webstore.checkoutSummaryDrawer.showProductCustomization
      }
      mediaQueries={mediaQueries}
      noHeader
      onClose={onClose}
      radius={0}
      size={1200}
      trapFocus={false}
      zIndex={201}
    >
      {ecomStoreProduct && (
        <AppFlexbox
          style={{
            position: 'relative',
            flexDirection: isTabletOrSmaller ? 'column' : 'row'
          }}
        >
          <AppStack
            style={{
              width: '100%',
              maxWidth: isTabletOrSmaller ? 'unset' : '45%',
              alignItems: 'center'
            }}
          >
            <AppStack
              style={{
                height: isTabletOrSmaller ? 250 : 'unset',
                width: '100%'
              }}
            >
              <Image
                fit="contain"
                h="100%"
                src={ecomStoreProduct.ecomVendorProduct.previewImage}
                w="100%"
              />
            </AppStack>
          </AppStack>
          <AppStack
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onUpdateCartProduct();
            }}
            style={{
              width: '100%',
              gap: 16,
              marginTop: isTabletOrSmaller ? 0 : 20,
              marginLeft: isTabletOrSmaller ? 0 : 40
            }}
          >
            <AppStack style={{ gap: 10 }}>
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 14, color: '#999' }}>
                  {ecomStoreProduct.ecomVendorProduct.vendorName}
                </AppText>

                <Anchor
                  component={Link}
                  onClick={(e) => {
                    onNavigate(e);
                    onClose();
                  }}
                  style={{ color: '#000', fontSize: 28, fontWeight: 500 }}
                  to={`${baseStoreUrl}/products/${ecomStoreProduct.urlHandle}`}
                >
                  {ecomStoreProduct.ecomVendorProduct.name}
                </Anchor>
              </AppStack>

              <AppFlexbox style={{ alignItems: 'end', gap: 10 }}>
                {selectedVariant.compareAtPrice > 0 && (
                  <AppText
                    style={{
                      fontSize: 18,
                      fontWeight: 450,
                      color: '#999',
                      textDecoration: 'line-through'
                    }}
                  >
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      suffix=" CAD"
                      thousandSeparator
                      value={selectedVariant.compareAtPrice / 100}
                    />
                  </AppText>
                )}
                <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
                  <AppText style={{ fontSize: 22, fontWeight: 450 }}>
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      suffix=" CAD"
                      thousandSeparator
                      value={selectedVariant.price / 100}
                    />
                  </AppText>
                  {selectedVariant.compareAtPrice > 0 && (
                    <Badge color="dark" size="lg" variant="filled">
                      Sale
                    </Badge>
                  )}
                </AppFlexbox>
              </AppFlexbox>
            </AppStack>

            <AppStack style={{ gap: 10 }}>
              <Grid gutter="xs">
                {ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs
                  .sort((a, b) => a.sort - b.sort)
                  .map((p) => {
                    const inputAnswer = formState.ecomStoreCartProductInputAnswers.find(
                      (a) =>
                        a.fkEcomVendorProductInput ===
                        p.pkEcomVendorProductInput
                    );

                    return (
                      <Grid.Col
                        key={p.pkEcomVendorProductInput}
                        span={{
                          base: 12,
                          sm:
                            p.fkRegFormControlType ===
                              INPUT_CONTROL_TYPE_ENUM.CHECKBOX ||
                            p.fkRegFormControlType ===
                              INPUT_CONTROL_TYPE_ENUM.FILE_UPLOAD
                              ? 12
                              : 6
                        }}
                        style={{
                          marginTop:
                            p.fkRegFormControlType ===
                            INPUT_CONTROL_TYPE_ENUM.CHECKBOX
                              ? 5
                              : 0
                        }}
                      >
                        <ProductInputDisplay
                          key={p.pkEcomVendorProductInput}
                          baseStoreUrl={baseStoreUrl}
                          disabled={
                            inputAnswer?.isAutopopulated || formState.loading
                          }
                          ecomVendorProductInput={p}
                          onChange={(value, type) =>
                            setFormState({
                              ...formState,
                              optionErrors: [],
                              ecomStoreCartProductInputAnswers: [
                                ...formState.ecomStoreCartProductInputAnswers.filter(
                                  (a) =>
                                    a.fkEcomVendorProductInput !==
                                    p.pkEcomVendorProductInput
                                ),
                                {
                                  fkEcomVendorProductInput:
                                    p.pkEcomVendorProductInput,
                                  fkRegFormControlType: p.fkRegFormControlType,
                                  file: type === 'file' ? value : null,
                                  value: type === 'file' ? null : value
                                }
                              ]
                            })
                          }
                          onNavigate={onNavigate}
                          radius={0}
                          value={inputAnswer?.value}
                        />
                      </Grid.Col>
                    );
                  })}
              </Grid>
            </AppStack>

            {formState.optionErrors && formState.optionErrors.length > 0 && (
              <AppCard
                radius={0}
                shadow="xs"
                style={{ padding: 0, marginTop: 10 }}
                withBorder
              >
                <AppFlexbox
                  style={{
                    padding: 8,
                    gap: 8,
                    backgroundColor: '#c40000',
                    color: '#fff'
                  }}
                >
                  <AppStack>
                    <AlertCircle size={18} />
                  </AppStack>
                  <AppText style={{ fontSize: 14 }}>
                    {formState.optionErrors[0]}
                  </AppText>
                </AppFlexbox>
              </AppCard>
            )}

            <AppFlexbox
              style={{
                flex: 1,
                gap: 10,
                justifyContent: 'end',
                alignItems: 'end'
              }}
            >
              {!isTabletOrSmaller && (
                <Button
                  color="dark"
                  disabled={formState.loading}
                  onClick={onClose}
                  size="md"
                  type="button"
                  variant="outline"
                >
                  Cancel
                </Button>
              )}

              <Button
                color="blue"
                loading={formState.loading}
                size="md"
                style={{ flex: isTabletOrSmaller ? 1 : 'unset' }}
                type="submit"
                variant="filled"
              >
                Update cart
              </Button>
            </AppFlexbox>
          </AppStack>
        </AppFlexbox>
      )}
    </ResponsiveWebstoreModal>
  );
};

CustomizeCartProductModal.propTypes = {
  baseStoreUrl: PropTypes.string,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func
};

export default CustomizeCartProductModal;
