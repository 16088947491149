import React, { useContext } from 'react';
import { VENDOR_NAVIGATION_LINKS } from './navigationLinks';
import Sidebar from './Sidebar';
import VendorSelectInput from '../vendors/VendorSelectInput';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';

const VendorSidebar = () => {
  const { state } = useContext(VendorContext);

  return (
    <Sidebar
      navigation={VENDOR_NAVIGATION_LINKS}
      selectInput={<VendorSelectInput placeholder="Select a vendor" />}
      state={state}
      subTitle="Vendor"
      title="SportsHeadz"
    />
  );
};

export default VendorSidebar;
