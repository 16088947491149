import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import { useMediaQueryIndex } from '../../helpers/hooks';

const TableViewDisabledContent = ({ disabledContent }) => {
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();

  return (
    disabledContent && (
      <AppStack
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          padding: isLargeMobileOrSmaller ? 20 : '60px 20px'
        }}
      >
        <AppFlexbox
          style={{
            maxWidth: 450,
            justifyContent: 'space-between',
            ...(isLargeMobileOrSmaller
              ? {
                  width: 'unset',
                  flexDirection: 'column-reverse',
                  alignItems: 'start'
                }
              : { width: '100%', flexDirection: 'row', alignItems: 'center' })
          }}
        >
          <AppStack style={{ gap: 0, flex: 1 }}>
            <AppText
              style={{ fontSize: 24, fontWeight: 700, whiteSpace: 'normal' }}
            >
              {disabledContent.title}
            </AppText>
            <AppText
              style={{ fontSize: 14, color: '#666', whiteSpace: 'normal' }}
            >
              {disabledContent.description}
            </AppText>
            <AppFlexbox style={{ marginTop: 16 }}>
              {disabledContent.primaryButton && (
                <Button
                  color="dark"
                  component={disabledContent.primaryButton.link ? Link : null}
                  leftSection={disabledContent.primaryButton.icon}
                  onClick={disabledContent.primaryButton.onClick}
                  radius="md"
                  size="compact-sm"
                  to={disabledContent.primaryButton.link}
                >
                  {disabledContent.primaryButton.label}
                </Button>
              )}

              {disabledContent.secondaryButton && (
                <Button
                  color="dark"
                  component={disabledContent.secondaryButton.link ? Link : null}
                  leftSection={disabledContent.secondaryButton.icon}
                  onClick={disabledContent.secondaryButton.onClick}
                  radius="md"
                  size="compact-sm"
                  to={disabledContent.secondaryButton.link}
                  variant="outline"
                >
                  {disabledContent.secondaryButton.label}
                </Button>
              )}
            </AppFlexbox>
          </AppStack>

          <AppStack style={{ gap: 0 }}>{disabledContent.icon}</AppStack>
        </AppFlexbox>
      </AppStack>
    )
  );
};

TableViewDisabledContent.propTypes = { disabledContent: PropTypes.object };

export default TableViewDisabledContent;
