import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Divider, NumberFormatter } from '@mantine/core';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const AdminVendorPayoutListItem = ({
  label,
  subLabel,
  count,
  amountPaid,
  subData,
  selected,
  onSelect,
  onSubSelect,
  disabled
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandable = subData && subData.length > 0;
  const totalCount = expandable
    ? subData?.reduce((r, c) => r + c.count, 0)
    : count;

  return (
    <AppStack style={{ gap: 0 }}>
      <AppFlexbox
        onClick={() => {
          if (expandable) {
            setIsExpanded(!isExpanded);
          }
          else if (onSelect) {
            onSelect(!selected);
          }
        }}
        style={{
          padding: 16,
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: expandable || onSelect ? 'pointer' : 'default',
          minHeight: 72
        }}
      >
        <AppFlexbox style={{ alignItems: 'center' }}>
          {onSelect && (
            <Checkbox
              checked={selected}
              disabled={disabled}
              onChange={() => {}}
              onClick={(e) => {
                e.stopPropagation();
                if (onSelect) {
                  onSelect(!selected);
                }
              }}
            />
          )}

          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8 }}>
              <AppText style={{ fontSize: 14 }}>
                <span style={{ fontWeight: 500 }}>{label}</span>{' '}
                {totalCount && (
                  <span style={{ color: '#666' }}>({totalCount})</span>
                )}
              </AppText>
            </AppFlexbox>
            <AppText style={{ fontSize: 12, color: '#666' }}>
              {subLabel}
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'end',
            textAlign: 'end',
            gap: 8
          }}
        >
          <NumberFormatter
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
            style={{ fontSize: 14, fontWeight: 500 }}
            thousandSeparator
            value={
              (expandable
                ? subData.reduce((r, c) => r + c.amountPaid, 0)
                : amountPaid * count) / 100
            }
          />

          {expandable &&
            (isExpanded ? (
              <ChevronUp color="#999" size={18} />
            ) : (
              <ChevronDown color="#999" size={18} />
            ))}
        </AppFlexbox>
      </AppFlexbox>

      {isExpanded && (
        <>
          <Divider />
          <AppStack style={{ backgroundColor: '#F1F1F1', gap: 0 }}>
            {subData
              .sort(
                (a, b) =>
                  a.label.localeCompare(b.label) ||
                  a.subLabel.localeCompare(b.subLabel)
              )
              .map((d, index) => (
                <React.Fragment key={d.key}>
                  {index > 0 && <Divider />}
                  <AppFlexbox
                    onClick={() => {
                      if (onSubSelect) {
                        onSubSelect(d);
                      }
                    }}
                    style={{
                      margin: '0px 8px',
                      padding: 16,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: onSubSelect ? 'pointer' : 'default',
                      minHeight: 72
                    }}
                  >
                    <AppFlexbox style={{ alignItems: 'center' }}>
                      {onSubSelect && (
                        <Checkbox
                          checked={selected}
                          disabled={disabled}
                          onChange={() => {}}
                        />
                      )}
                      <AppStack style={{ gap: 0 }}>
                        <AppFlexbox style={{ gap: 8 }}>
                          <AppText style={{ fontSize: 14 }}>
                            <span style={{ fontWeight: 500 }}>{d.label}</span>{' '}
                            {d.count && (
                              <span style={{ color: '#666' }}>({d.count})</span>
                            )}
                          </AppText>
                        </AppFlexbox>
                        <AppText style={{ fontSize: 12, color: '#666' }}>
                          {d.subLabel}
                        </AppText>
                      </AppStack>
                    </AppFlexbox>

                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        justifyContent: 'end',
                        textAlign: 'end',
                        gap: 8
                      }}
                    >
                      <NumberFormatter
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        style={{ fontSize: 14, color: '#666' }}
                        thousandSeparator
                        value={d.amountPaid / 100}
                      />
                    </AppFlexbox>
                  </AppFlexbox>
                </React.Fragment>
              ))}
          </AppStack>
        </>
      )}
    </AppStack>
  );
};

AdminVendorPayoutListItem.propTypes = {
  amountPaid: PropTypes.number,
  count: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  onSubSelect: PropTypes.func,
  rightSection: PropTypes.node,
  selected: PropTypes.bool,
  subData: PropTypes.array,
  subLabel: PropTypes.string
};

export default AdminVendorPayoutListItem;
