import React from 'react';
import { AddressBook, Lock, Photo } from 'tabler-icons-react';
import { PatternFormat } from 'react-number-format';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const USER_SETTINGS = [
  {
    label: 'Profile Picture',
    icon: Photo,
    value: 'avatar'
  },
  {
    label: 'Contact Information',
    icon: AddressBook,
    getDescription: (user) => (
      <AppFlexbox style={{ gap: 0, alignItems: 'center' }}>
        <AppText style={{ fontSize: 14, color: '#666' }}>{user.name}</AppText>
        {user.phone && (
          <AppText style={{ fontSize: 14, color: '#666' }}>
            ,{' '}
            <PatternFormat
              displayType="text"
              format="+1 (###) ###-####"
              mask="_"
              prefix=", "
              value={user.phone}
            />
          </AppText>
        )}
      </AppFlexbox>
    ),
    value: 'contact_info'
  },
  {
    label: 'Change Password',
    icon: Lock,
    value: 'change_password'
  }
];

export { USER_SETTINGS };
