// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, 
body, 
#ie4Fw5rm84FxcTecGr23 {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #F0F0F0;
}

.ZGkF9Aauuc7rQUvGAfic, 
.DOqvrmnAZBgKZ2HNF4gl {
    width: 100%;
}

.HeTbVS3teHRPE76sjHc5 { 
    background-color: 'inherit' 
}`, "",{"version":3,"sources":["webpack://./src/styles/globalStyles.module.css"],"names":[],"mappings":"AAAA;;;IAGI,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI;AACJ","sourcesContent":["html, \r\nbody, \r\n#root {\r\n    height: 100%;\r\n    width: 100%;\r\n    margin: 0px;\r\n    padding: 0px;\r\n    background-color: #F0F0F0;\r\n}\r\n\r\n.mantine-AppShell-body, \r\n.mantine-AppShell-main {\r\n    width: 100%;\r\n}\r\n\r\n.mantine-Avatar-placeholder { \r\n    background-color: 'inherit' \r\n}"],"sourceRoot":""}]);
// Exports
export var root = `ie4Fw5rm84FxcTecGr23`;
var _1 = `ZGkF9Aauuc7rQUvGAfic`;
export { _1 as "mantine-AppShell-body" };
var _2 = `DOqvrmnAZBgKZ2HNF4gl`;
export { _2 as "mantine-AppShell-main" };
var _3 = `HeTbVS3teHRPE76sjHc5`;
export { _3 as "mantine-Avatar-placeholder" };
export default ___CSS_LOADER_EXPORT___;
