/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ActionIcon, Image, Indicator } from '@mantine/core';
import { ShoppingCart, User } from 'tabler-icons-react';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppText from '../../../../common/AppText';
import {
  DESKTOP_LOGO_POSITION_ENUM,
  DESKTOP_MENU_TYPE_ENUM,
  HEADER_MOBILE_LOGO_POSITION_ENUM,
  HEADER_STICKY_TYPE_ENUM,
  configNameValues
} from './wsHeaderConfig';
import AppStack from '../../../../common/AppStack';
import WsSectionContainer from '../WsSectionContainer';
import WsHeaderDrawer from './WsHeaderDrawer';
import WsHeaderMenu from './WsHeaderMenu';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import { useCartController } from '../../../../../helpers/webstoreCheckoutHelper';
import { ECOM_WEBSTORE_PAGE_TYPE_ENUM } from '../../../../../config/constants';

const defaultHeaderMenu = {
  pkEcomStoreMenu: 0,
  title: 'Default',
  ecomStoreMenuItems: [
    {
      pkEcomStoreMenuItem: 0,
      title: 'Home',
      customUrl: '',
      sort: 1,
      ecomResourceId: null,
      fkEcomStoreMenuItem: null,
      fkEcomStorePageType: ECOM_WEBSTORE_PAGE_TYPE_ENUM.HOME
    },
    {
      pkEcomStoreMenuItem: -1,
      title: 'Products',
      customUrl: '',
      sort: 2,
      ecomResourceId: null,
      fkEcomStoreMenuItem: null,
      fkEcomStorePageType: ECOM_WEBSTORE_PAGE_TYPE_ENUM.PRODUCT
    }
  ]
};

const WsHeader = ({
  config,
  colorSchemes,
  themeConfig,
  onNavigate,
  baseStoreUrl,
  ecomStoreMenus,
  logoImageUrl,
  sort,
  selectedSectionBlock,
  mediaQueries,
  scrollUpVisible,
  ecomStoreTheme,
  pathname,
  editModeEnabled,
  builderHasAuthenticated,
  onAuthenticateEditMode,
  ...rest
}) => {
  const { state: authState } = useContext(AuthContext);
  const { ecomStoreCart } = useCartController();
  const { isTabletOrSmaller } = mediaQueries;
  const flipLogoOnDesktop = config.flipLogoOnDesktop === 'true';
  const isDrawerMenu =
    isTabletOrSmaller ||
    config.desktopMenuType === DESKTOP_MENU_TYPE_ENUM.DRAWER;
  const headerColorScheme =
    colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const menuColorScheme =
    colorSchemes[config.menuColorScheme - 1] ?? colorSchemes[0];
  const showHeaderOnScrollUp =
    config.stickyHeader === HEADER_STICKY_TYPE_ENUM.ON_SCROLL_UP;
  const showHeaderAlways =
    config.stickyHeader === HEADER_STICKY_TYPE_ENUM.ALWAYS;
  const productCount =
    ecomStoreCart?.checkoutCart.lineItems.reduce((r, c) => r + c.quantity, 0) ??
    0;
  const selectedMenu =
    ecomStoreMenus.find(
      (menu) =>
        menu.pkEcomStoreMenu.toString() ===
        config[configNameValues.ecomStoreMenu]?.toString()
    ) ??
    ecomStoreMenus.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    )[0] ??
    defaultHeaderMenu;

  const renderLogo = () => (
    <AppFlexbox
      component={Link}
      onClick={onNavigate}
      style={{
        alignItems: 'center',
        textDecoration: 'none',
        color: headerColorScheme.textColor,
        gap: 5,
        maxWidth: 240
      }}
      to={`${baseStoreUrl}/`}
    >
      {logoImageUrl ? (
        <AppStack
          style={{
            height: '100%',
            width: themeConfig.desktopLogoWidth * 1 ?? 50,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Image fit="contain" h="100%" src={logoImageUrl} w="100%" />
        </AppStack>
      ) : (
        <AppText style={{ fontSize: 18, fontWeight: 500 }}>
          {ecomStoreTheme.ecomStore.name}
        </AppText>
      )}
    </AppFlexbox>
  );

  const renderMenu = () =>
    !isDrawerMenu && (
      <WsHeaderMenu
        baseStoreUrl={baseStoreUrl}
        config={config}
        ecomStoreMenu={selectedMenu}
        ecomStoreTheme={ecomStoreTheme}
        headerColorScheme={headerColorScheme}
        menuColorScheme={menuColorScheme}
        onNavigate={onNavigate}
        pathname={pathname}
        themeConfig={themeConfig}
      />
    );

  const renderDrawer = () =>
    isDrawerMenu && (
      <WsHeaderDrawer
        baseStoreUrl={baseStoreUrl}
        builderHasAuthenticated={builderHasAuthenticated}
        config={config}
        ecomStoreMenu={selectedMenu}
        ecomStoreTheme={ecomStoreTheme}
        editModeEnabled={editModeEnabled}
        headerColorScheme={headerColorScheme}
        logoImageUrl={logoImageUrl}
        mediaQueries={mediaQueries}
        menuColorScheme={menuColorScheme}
        onAuthenticateEditMode={onAuthenticateEditMode}
        onNavigate={onNavigate}
        pathname={pathname}
      />
    );

  const getAccountUrl = () =>
    (editModeEnabled && !builderHasAuthenticated) || !authState.isAuthenticated
      ? `${baseStoreUrl}/account/login`
      : `${baseStoreUrl}/account/details`;

  const getCartUrl = () => `${baseStoreUrl}/checkout/cart`;

  return (
    <WsSectionContainer
      colorScheme={headerColorScheme}
      config={config}
      containerId="webstore-menu-target"
      isFirstComponent={sort === 1}
      mediaQueries={mediaQueries}
      padding="0px 10px"
      position={showHeaderAlways ? 'sticky' : null}
      themeConfig={themeConfig}
      {...rest}
    >
      {isTabletOrSmaller ? (
        <AppFlexbox
          style={{
            position: 'sticky',
            top: 0,
            flex: 1,
            justifyContent: 'space-between',
            placeItems: 'center',
            alignItems:
              config.desktopLogoPosition ===
                DESKTOP_LOGO_POSITION_ENUM.MIDDLE_LEFT ||
              config.desktopLogoPosition ===
                DESKTOP_LOGO_POSITION_ENUM.MIDDLE_CENTER ||
              isDrawerMenu
                ? 'center'
                : 'start',
            margin: 'auto',
            width: '100%',
            gap: 5
          }}
        >
          <AppFlexbox style={{ flex: 1, alignItems: 'center' }}>
            {renderDrawer()}

            {config.mobileLogoPosition ===
              HEADER_MOBILE_LOGO_POSITION_ENUM.LEFT && renderLogo()}
          </AppFlexbox>

          <AppStack
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection:
                config.desktopLogoPosition ===
                  DESKTOP_LOGO_POSITION_ENUM.TOP_CENTER && flipLogoOnDesktop
                  ? 'column-reverse'
                  : 'column'
            }}
          >
            {config.mobileLogoPosition ===
              HEADER_MOBILE_LOGO_POSITION_ENUM.CENTER && renderLogo()}
          </AppStack>

          <AppFlexbox style={{ gap: 5, flex: 1, justifyContent: 'end' }}>
            {/* <ActionIcon
              color="dark"
              component={Link}
              onClick={onNavigate}
              radius="lg"
              size="lg"
              to={`${baseStoreUrl}/search`}
              variant="subtle"
            >
              <Search color={headerColorScheme.textColor} />
            </ActionIcon> */}

            <ActionIcon
              color="dark"
              component={Link}
              onClick={onNavigate}
              radius="lg"
              size="lg"
              to={getAccountUrl()}
              variant="subtle"
            >
              <User color={headerColorScheme.textColor} />
            </ActionIcon>
            <Indicator
              disabled={productCount === 0}
              inline
              label={productCount}
              size={16}
            >
              <ActionIcon
                color="dark"
                component={Link}
                onClick={onNavigate}
                radius="lg"
                size="lg"
                to={getCartUrl()}
                variant="subtle"
              >
                <ShoppingCart color={headerColorScheme.textColor} />
              </ActionIcon>
            </Indicator>
          </AppFlexbox>
        </AppFlexbox>
      ) : (
        <AppFlexbox
          style={{
            position: 'sticky',
            top: 0,
            flex: 1,
            justifyContent: 'space-between',
            placeItems: 'center',
            alignItems:
              config.desktopLogoPosition ===
                DESKTOP_LOGO_POSITION_ENUM.MIDDLE_LEFT ||
              config.desktopLogoPosition ===
                DESKTOP_LOGO_POSITION_ENUM.MIDDLE_CENTER ||
              isDrawerMenu
                ? 'center'
                : 'start',
            margin: 'auto',
            width: '100%',
            gap: 5
          }}
        >
          {config.desktopLogoPosition ===
            DESKTOP_LOGO_POSITION_ENUM.TOP_CENTER ||
          config.desktopLogoPosition ===
            DESKTOP_LOGO_POSITION_ENUM.MIDDLE_CENTER ? (
            <>
              <AppFlexbox style={{ flex: 1, alignItems: 'center' }}>
                {renderDrawer()}

                {config.desktopLogoPosition ===
                DESKTOP_LOGO_POSITION_ENUM.TOP_CENTER ? (
                  <></>
                ) : // <ActionIcon
                //   color="dark"
                //   component={Link}
                //   onClick={onNavigate}
                //   radius="lg"
                //   size="lg"
                //   to={`${baseStoreUrl}/search`}
                //   variant="subtle"
                // >
                //   <Search color={headerColorScheme.textColor} />
                // </ActionIcon>
                flipLogoOnDesktop &&
                  config.desktopLogoPosition ===
                    DESKTOP_LOGO_POSITION_ENUM.MIDDLE_CENTER &&
                  !isDrawerMenu ? (
                  renderLogo()
                ) : (
                  renderMenu()
                )}
              </AppFlexbox>

              <AppStack
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection:
                    config.desktopLogoPosition ===
                      DESKTOP_LOGO_POSITION_ENUM.TOP_CENTER && flipLogoOnDesktop
                      ? 'column-reverse'
                      : 'column'
                }}
              >
                {(config.desktopLogoPosition ===
                  DESKTOP_LOGO_POSITION_ENUM.TOP_CENTER ||
                  !flipLogoOnDesktop ||
                  isDrawerMenu) &&
                  renderLogo()}
                {(config.desktopLogoPosition !==
                  DESKTOP_LOGO_POSITION_ENUM.MIDDLE_CENTER ||
                  flipLogoOnDesktop) &&
                  renderMenu()}
              </AppStack>

              <AppFlexbox style={{ gap: 5, flex: 1, justifyContent: 'end' }}>
                {config.desktopLogoPosition !==
                  DESKTOP_LOGO_POSITION_ENUM.TOP_CENTER && (
                  <></>
                  // <ActionIcon
                  //   color="dark"
                  //   component={Link}
                  //   onClick={onNavigate}
                  //   radius="lg"
                  //   size="lg"
                  //   to={`${baseStoreUrl}/search`}
                  //   variant="subtle"
                  // >
                  //   <Search color={headerColorScheme.textColor} />
                  // </ActionIcon>
                )}

                <ActionIcon
                  color="dark"
                  component={Link}
                  onClick={onNavigate}
                  radius="lg"
                  size="lg"
                  to={getAccountUrl()}
                  variant="subtle"
                >
                  <User color={headerColorScheme.textColor} />
                </ActionIcon>
                <Indicator
                  disabled={productCount === 0}
                  inline
                  label={productCount}
                  size={16}
                >
                  <ActionIcon
                    color="dark"
                    component={Link}
                    onClick={onNavigate}
                    radius="lg"
                    size="lg"
                    to={getCartUrl()}
                    variant="subtle"
                  >
                    <ShoppingCart color={headerColorScheme.textColor} />
                  </ActionIcon>
                </Indicator>
              </AppFlexbox>
            </>
          ) : (
            <>
              <AppFlexbox
                style={{
                  flexDirection:
                    config.desktopLogoPosition ===
                      DESKTOP_LOGO_POSITION_ENUM.TOP_LEFT && !isDrawerMenu
                      ? flipLogoOnDesktop
                        ? 'column'
                        : 'column-reverse'
                      : flipLogoOnDesktop || isDrawerMenu
                      ? 'row'
                      : 'row-reverse',
                  alignItems:
                    config.desktopLogoPosition ===
                      DESKTOP_LOGO_POSITION_ENUM.TOP_LEFT && !isDrawerMenu
                      ? 'start'
                      : 'center'
                }}
              >
                {renderDrawer()}
                {renderMenu()}
                {renderLogo()}
              </AppFlexbox>

              <AppFlexbox style={{ gap: 5, flex: 1, justifyContent: 'end' }}>
                {/* <ActionIcon
                  color="dark"
                  component={Link}
                  onClick={onNavigate}
                  radius="lg"
                  size="lg"
                  to={`${baseStoreUrl}/search`}
                  variant="subtle"
                >
                  <Search color={headerColorScheme.textColor} />
                </ActionIcon> */}

                <ActionIcon
                  color="dark"
                  component={Link}
                  onClick={onNavigate}
                  radius="lg"
                  size="lg"
                  to={getAccountUrl()}
                  variant="subtle"
                >
                  <User color={headerColorScheme.textColor} />
                </ActionIcon>
                <Indicator
                  disabled={productCount === 0}
                  inline
                  label={productCount}
                  size={16}
                >
                  <ActionIcon
                    color="dark"
                    component={Link}
                    onClick={onNavigate}
                    radius="lg"
                    size="lg"
                    to={getCartUrl()}
                    variant="subtle"
                  >
                    <ShoppingCart color={headerColorScheme.textColor} />
                  </ActionIcon>
                </Indicator>
              </AppFlexbox>
            </>
          )}
        </AppFlexbox>
      )}
    </WsSectionContainer>
  );
};

WsHeader.propTypes = {
  baseStoreUrl: PropTypes.string,
  builderHasAuthenticated: PropTypes.bool,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreMenus: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  logoImageUrl: PropTypes.string,
  mediaQueries: PropTypes.object,
  onAuthenticateEditMode: PropTypes.func,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  scrollUpVisible: PropTypes.bool,
  selectedSectionBlock: PropTypes.number,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsHeader;
