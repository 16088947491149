import { useLocation, useNavigate } from 'react-router-dom';

const useProductSearchFilter = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const filterSearchParams = new URLSearchParams(search);
  const searchParam = filterSearchParams.get('search');
  const locationParam = filterSearchParams.get('location');
  const categoryParam = filterSearchParams.get('category');
  const vendorParam = filterSearchParams.get('vendor');
  const sortParam = filterSearchParams.get('sort');
  const minPriceParam = filterSearchParams.get('minPrice');
  const maxPriceParam = filterSearchParams.get('maxPrice');
  const pageIndexParam = filterSearchParams.get('page');

  const filter = {
    search: searchParam ?? '',
    location: locationParam ?? null,
    category: categoryParam ?? null,
    vendor: vendorParam ?? null,
    sort: sortParam ?? null,
    minPrice: minPriceParam ?? null,
    maxPrice: maxPriceParam ?? null
  };

  const hasSearched =
    !!filter.search ||
    !!filter.location ||
    !!filter.category ||
    !!filter.vendor ||
    !!filter.sort ||
    !!filter.minPrice ||
    !!filter.maxPrice;

  const resetFilter = () => {
    navigate(pathname, { replace: true });
  };

  const objectToParams = (obj) => {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        params.set(key, obj[key]);
      }
    });
    return params.toString();
  };

  const onFilterChange = (params) => {
    const newParams = objectToParams({ ...filter, ...params });
    navigate(`${pathname}?${newParams}`, { replace: true });
  };

  return {
    filter,
    hasSearched,
    resetFilter,
    onFilterChange,
    pageIndex: pageIndexParam ? parseInt(pageIndexParam, 10) : 1
  };
};

const getVendorBranding = (ecomVendor) => ({
  primaryColor: ecomVendor?.primaryColor ?? '#FFF',
  primaryContrastColor: ecomVendor?.primaryColor
    ? ecomVendor?.primaryContrastColor ?? '#000'
    : '#000',
  secondaryColor: ecomVendor?.secondaryColor ?? '#000',
  secondaryAltColor: ecomVendor?.secondaryAltColor ?? '#FFF',
  secondaryContrastColor: ecomVendor?.secondaryColor
    ? ecomVendor?.secondaryContrastColor ?? '#000'
    : '#000'
});

export { useProductSearchFilter, getVendorBranding };
